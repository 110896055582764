/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */
import { MutableRefObject, ReactElement, SetStateAction, useEffect, useRef, useState } from 'react';

interface ISlideSetting {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  settings: typeof settings;
  sliderRef: ReactElement | MutableRefObject<null> | null;
  activeSlide: number;
}

export const useSlideSettings = (isMobile: boolean): ISlideSetting => {
  const [activeSlide, setActiveSlide] = useState(0);
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 15000,
    centerMode: true,
    speed: 15,
    variableWidth: true,
    arrows: false,
    beforeChange: (_: number, next: SetStateAction<number>) => setActiveSlide(next),
  };

  // below istanbul comment added to ignore the below useEffect code from the code coverage.
  // because inside useEffect, hacks are used to support tab functionality for the tile slider.
  // and we can't write the test cases for the below hacking code. Check tp-2221.
  /* istanbul ignore next */
  useEffect(() => {
    if (isMobile) {
      const handleKeyUp = (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
          event.preventDefault();
          const slickDots = document.querySelector('.slick-dots');
          if (slickDots) {
            const lastLi = slickDots.lastElementChild;
            if (lastLi) {
              const button = lastLi.querySelector('button');
              if (button) {
                button.id = 'slick-dot-3';
              }
            }
          }

          const activateNextElement0 = document.getElementById('tile-slider-next-0');
          if (activateNextElement0?.getAttribute('tabindex') !== '0') {
            activateNextElement0?.setAttribute('tabindex', '0');
          }
          const nextElement = document.getElementById(`tile-slider-next-${activeSlide}`);
          const backElement = document.getElementById(`tile-slider-back-${activeSlide}`);
          const isNextActiveElement = nextElement === document.activeElement;
          const isBackActiveElement = backElement === document.activeElement;

          if (!event.shiftKey && isNextActiveElement) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sliderRef?.current?.slickNext();
            const element = document.getElementById('tile-slider-back-1');
            if (element) {
              element.setAttribute('tabindex', '-1');
            }
          } else if (event.shiftKey) {
            if (
              isBackActiveElement ||
              document.activeElement?.id === `tile-slider-next-1` ||
              document.activeElement?.id === `tile-slider-next-0`
            ) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              sliderRef?.current?.slickPrev();
              const prevElement = document.getElementById('tile-slider-back-1');
              if (prevElement) {
                prevElement.setAttribute('tabindex', '0');
              }
              const nextElement0 = document.getElementById('tile-slider-next-0');
              if (nextElement0?.getAttribute('tabindex') !== '-1') {
                nextElement0?.setAttribute('tabindex', '-1');
              }
            }
            if (document.activeElement?.id === 'slick-dot-3') {
              window.scrollTo({
                top: window.scrollY - 500,
                behavior: 'smooth',
              });
            }
          }
        }
      };
      document.addEventListener('keyup', handleKeyUp);
      return () => {
        document.removeEventListener('keyup', handleKeyUp);
      };
    }
    return () => {};
  }, [activeSlide, isMobile, sliderRef]);

  return { settings, sliderRef, activeSlide };
};
