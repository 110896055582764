/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { memo, ReactElement, useEffect, useMemo, useState } from 'react';
import { SidePanel, DateField, Dropdown } from '@liaison/liaison-ui';
import { Box, Grid, Typography, FormControl, InputLabel, FormHelperText, InputBase, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { nameSpace, quarterTermCommunityCollegeIds } from 'transferPlanner/constants/general';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { sxSidePanel } from 'pages/Pages.styles';
import { selectAcademicHistory, selectLoading } from 'store/academicHistory/academicHistory.selectors';
import { selectCollegesLookup } from 'store/common/commonInfo.selectors';
import { DISABLE_AUTO_FILL } from 'constants/field';
import { TAcademicHistoryField } from 'store/academicHistory/academicHistory.slice';
import { getCommunityCollegeOptions, ICommunityCollegeOptions, postAcademicHistory } from 'utils/AcademicHistory.utils';
import { setUi } from 'store/ui/ui.slice';
import { selectCollegeAction } from 'store/ui/ui.selectors';
import { sanitizePayload } from 'utils/utilities';
import { getAcademicHistoryLookUps, setTitle } from 'utils/commonUtils';
import { Spinner } from 'components/Spinner';
import { communityCollegeSchema } from 'transferPlanner/pages/TspEnrollment/TspEnrollment.validation';
import type { TCollegeFormError } from 'userProfile/pages/AcademicHistory/AcademicHistoryForm/TAcademicHistory';

const AddCollege = (): ReactElement => {
  const dispatch = useDispatch();
  const sidebar = useSelector(selectCollegeAction);
  const { t } = useTranslation(nameSpace);
  const [childPosition, setChildPosition] = useState(0);
  const isLoading = useSelector(selectLoading);
  const groupName = 'colleges';
  const communityColleges = useSelector(selectCollegesLookup);
  const academicHistoryData = useSelector(selectAcademicHistory);
  const communityCollegesList = useMemo(() => getCommunityCollegeOptions(communityColleges || []), [communityColleges]);
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    register,
    reset,
  } = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(communityCollegeSchema, { abortEarly: false })(...args),
  });

  const collegeErrors = errors as unknown as TCollegeFormError;

  useEffect(() => {
    if (academicHistoryData) {
      reset({});
      setChildPosition(academicHistoryData?.colleges?.length || childPosition);
    }
  }, [dispatch, reset, academicHistoryData, childPosition]);

  useEffect(() => {
    if (!communityColleges) dispatch(getAcademicHistoryLookUps('college', '/alternateId/Assist'));
  }, [dispatch, communityColleges]);

  const handleCollegeListChange = (collegeDetails: ICommunityCollegeOptions | null) => {
    setValue(`${groupName}.${childPosition}.graduated`, 'No');
    setValue(`${groupName}.${childPosition}.alternateId`, collegeDetails?.alternateId);
    setValue(`${groupName}.${childPosition}.ceeb`, collegeDetails?.ceeb);
    setValue(`${groupName}.${childPosition}.address`, collegeDetails?.address);
    if (quarterTermCommunityCollegeIds.includes(collegeDetails?.collegeId || '')) {
      /* istanbul ignore next */
      setValue(`${groupName}.${childPosition}.termType`, {
        code: 'QUARTER',
        displayName: 'Quarter',
      });
    } else {
      setValue(`${groupName}.${childPosition}.termType`, {
        code: 'SEMESTER',
        displayName: 'Semester',
      });
    }
  };

  const onSubmit = (payloadData: TAcademicHistoryField) => {
    const updatedData = payloadData;
    const selectedData = [...(academicHistoryData?.[groupName] || [])];
    if (selectedData != null) {
      if (updatedData?.[groupName]?.[childPosition] !== undefined) {
        selectedData[childPosition] = updatedData?.[groupName]?.[childPosition];
      }
    }

    let payload = academicHistoryData;
    if (selectedData[childPosition]?.primary) {
      const updatedColleges = selectedData?.map((collegeData, i) => {
        let primaryStatus = false;
        if (i === childPosition) primaryStatus = true;
        return { ...collegeData, primary: primaryStatus };
      });

      payload = {
        ...academicHistoryData,
        [groupName]: updatedColleges,
      };
    } else {
      payload = {
        ...academicHistoryData,
        [groupName]: selectedData,
      };
    }

    dispatch(
      postAcademicHistory(sanitizePayload(Object.assign(payload)), () => {
        dispatch(setUi({ name: 'collegeSidebar', state: { open: false, success: true } }));
        setTitle(t('dashboard.pageTitle'));
      })
    );
  };

  const closePanel = () => {
    dispatch(setUi({ name: 'collegeSidebar', state: { open: false } }));
    setTitle(t('dashboard.pageTitle'));
  };

  return (
    <>
      {isLoading && <Spinner backdrop data-testid="spinner" />}
      <Box>
        <SidePanel
          open={sidebar?.open}
          onClose={closePanel}
          title={t('colleges.add')}
          isBackdropClickEnabled={true}
          footerButtonConfig={{
            primary: {
              title: t('save_label'),
              props: {
                'aria-label': t('save_label'),
                variant: 'contained',
                disabled: !isValid,
                onClick: handleSubmit(onSubmit),
              },
            },
            tertiary: {
              title: t('cancel_label'),
              props: {
                'aria-label': t('cancel_label'),
                onClick: closePanel,
              },
            },
          }}
          sx={sxSidePanel}
        >
          <form>
            <FormControl fullWidth required error={!!collegeErrors?.[groupName]?.[childPosition]?.name}>
              <InputLabel htmlFor={t('college.name')}>{t('college.name')}</InputLabel>
              <Controller
                render={({ field: { onChange, ...field } }) => (
                  <Dropdown
                    {...field}
                    id={t('college.name')}
                    options={communityCollegesList}
                    onChange={(option: ICommunityCollegeOptions | null) => {
                      handleCollegeListChange(option);
                      return onChange(option?.id ?? '');
                    }}
                    inputProps={{
                      'aria-label': t('college.name'),
                      autoComplete: 'off',
                    }}
                  />
                )}
                control={control}
                name={`${groupName}.${childPosition}.name`}
              />
              <FormHelperText role="alert" id="name-error">
                {collegeErrors?.[groupName]?.[childPosition]?.name?.message || t('college.nameHelpertext')}
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth error={!!collegeErrors?.[groupName]?.[childPosition]?.studentId}>
              <InputLabel htmlFor="studentId">{t('college.communityCollegeId')}</InputLabel>
              <InputBase
                inputProps={{
                  'aria-label': t('college.communityCollegeId'),
                  'aria-describedby': 'studentId-error',
                  ...DISABLE_AUTO_FILL,
                }}
                {...register(`${groupName}.${childPosition}.studentId`)}
              />
              <FormHelperText role="alert" id="studentId-error">
                {collegeErrors?.[groupName]?.[childPosition]?.studentId?.message}
              </FormHelperText>
            </FormControl>

            <Grid container spacing={1} sx={{ mb: 2 }}>
              <Grid item xs={10} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle6">{t('college.primary')}</Typography>
                <Typography variant="body3">{t('college.note')}</Typography>
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Controller
                  control={control}
                  name={`${groupName}.${childPosition}.primary`}
                  defaultValue={false}
                  render={({ field: { onChange, value, ...field } }) => {
                    return (
                      <Switch
                        {...field}
                        checked={value}
                        onChange={(_event, val) => {
                          return onChange(val);
                        }}
                        inputProps={{
                          'aria-label': t('college.primary'),
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            <InputBase
              defaultValue={false}
              inputProps={{ type: 'hidden' }}
              {...register(`${groupName}.${childPosition}.graduated`)}
            />

            <Controller
              name={`${groupName}.${childPosition}.startDate`}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <FormControl fullWidth required error={!!collegeErrors?.[groupName]?.[childPosition]?.startDate}>
                  <InputLabel htmlFor="startDate">{t('college.startDate')}</InputLabel>
                  <DateField
                    {...field}
                    ref={ref}
                    error={!!error}
                    id="startDate"
                    hidePart="day"
                    onChange={dateVal => field.onChange(dateVal)}
                    aria-describedby="startDate-error"
                    accessibilityLabelPrefix={t('college.startDate')}
                  />
                  <FormHelperText role="alert" id="startDate-error">
                    {collegeErrors?.[groupName]?.[childPosition]?.startDate?.message}
                  </FormHelperText>
                </FormControl>
              )}
              control={control}
            />
          </form>
        </SidePanel>
      </Box>
    </>
  );
};

export default memo(AddCollege);
