/*
 * Copyright 2022-2024 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TError = string | null;

export type TVariant = {
  variant: string;
  fileSize: number;
  height?: number;
  width?: number;
  ephemeralURL: string;
};

export type TAttachedTo = {
  id: string;
  path: string;
  resource: string;
  type: string;
};

export interface IMediaDoc {
  id?: string;
  order?: number;
  variant?: string;
  type?: {
    code: string;
    displayName: string;
  };
  name?: string;
  extension?: string;
  size?: number;
  sizeDisplay?: string;
  mediaType?: string;
  contentType?: string;
  description?: string;
  uploadedTimestamp?: string;
  updatedTimestamp?: string;
  shareable?: boolean;
  ephemeralURL?: string;
  externalProvider?: string;
  externalURL?: string;
  attachedCount?: number;
  attachedTo?: TAttachedTo[];
  variants?: TVariant[];
  imageUrl?: string;
  courseTitle?: string;
  degreeType?: string;
}

export interface IMediaDocs {
  profileId: string;
  documents: IMediaDoc[];
  sortOrder: { code: string; displayName: string };
}

export type TSectionName =
  | 'highSchools'
  | 'hsCourse'
  | 'colleges'
  | 'collegeCourse'
  | 'degrees'
  | 'experiences'
  | 'honorsOrAwards';

export type TPendingAttachments = { [key in TSectionName]: string[] };

export interface IMediaDocsState {
  loading: boolean;
  selectedMediaDoc: IMediaDoc | null;
  mediaDocsData: IMediaDocs | null;
  pendingAttachments: Partial<TPendingAttachments> | null;
  error: TError;
}

export const initialState: IMediaDocsState = {
  loading: false,
  mediaDocsData: null,
  selectedMediaDoc: null,
  pendingAttachments: null,
  error: null,
};

const mediaDocumentsSlice = createSlice({
  name: 'mediaDocuments',
  initialState,
  reducers: {
    getMediaDocsStart: (state): IMediaDocsState => ({
      ...state,
      loading: true,
      error: null,
    }),
    getMediaDocsSuccess: (state, action: PayloadAction<IMediaDocs>): IMediaDocsState => ({
      ...state,
      loading: false,
      mediaDocsData: action.payload,
    }),
    getMediaDocsFailure: (state, action: PayloadAction<TError>): IMediaDocsState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    initiateUploadMediaDocStart: (state): IMediaDocsState => ({
      ...state,
      loading: false,
      error: null,
    }),
    initiateUploadMediaDocSuccess: (state): IMediaDocsState => ({
      ...state,
      loading: false,
      error: null,
    }),
    initiateUploadMediaDocFailure: (state, action: PayloadAction<TError>): IMediaDocsState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    uploadMediaDocStart: (state): IMediaDocsState => ({
      ...state,
      loading: false,
      error: null,
    }),
    uploadMediaDocSuccess: (state): IMediaDocsState => ({
      ...state,
      loading: false,
      error: null,
    }),
    uploadMediaDocFailure: (state, action: PayloadAction<TError>): IMediaDocsState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    updateMediaDocUploadStatusStart: (state): IMediaDocsState => ({
      ...state,
      loading: false,
      error: null,
    }),
    updateMediaDocUploadStatusSuccess: (state): IMediaDocsState => ({
      ...state,
      loading: false,
      error: null,
    }),
    updateMediaDocUploadStatusFailure: (state, action: PayloadAction<TError>): IMediaDocsState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    getMediaDocStart: (state): IMediaDocsState => ({
      ...state,
      loading: false,
      error: null,
    }),
    getMediaDocSuccess: (state, action: PayloadAction<IMediaDoc | null>): IMediaDocsState => ({
      ...state,
      loading: false,
      selectedMediaDoc: action.payload,
    }),
    getMediaDocFailure: (state, action: PayloadAction<TError>): IMediaDocsState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    updateMediaDocStart: (state): IMediaDocsState => ({
      ...state,
      loading: true,
      error: null,
    }),
    updateMediaDocSuccess: (state): IMediaDocsState => ({
      ...state,
      loading: false,
      error: null,
    }),
    updateMediaDocFailure: (state, action: PayloadAction<TError>): IMediaDocsState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    deleteMediaDocStart: (state): IMediaDocsState => ({
      ...state,
      loading: true,
      error: null,
    }),
    deleteMediaDocSuccess: (state): IMediaDocsState => ({
      ...state,
      loading: false,
      error: null,
    }),
    deleteMediaDocFailure: (state, action: PayloadAction<TError>): IMediaDocsState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    setSelectedMediaDoc: (state, action: PayloadAction<IMediaDoc | null>): IMediaDocsState => ({
      ...state,
      selectedMediaDoc: action.payload,
    }),
    setPendingAttachments: (state, action: PayloadAction<Partial<TPendingAttachments>>): IMediaDocsState => ({
      ...state,
      pendingAttachments: { ...(state.pendingAttachments ?? {}), ...action.payload },
    }),
    resetPendingAttachments: (state): IMediaDocsState => ({
      ...state,
      pendingAttachments: null,
    }),
  },
});

export const {
  getMediaDocsStart,
  getMediaDocsSuccess,
  getMediaDocsFailure,
  initiateUploadMediaDocStart,
  initiateUploadMediaDocSuccess,
  initiateUploadMediaDocFailure,
  uploadMediaDocStart,
  uploadMediaDocSuccess,
  uploadMediaDocFailure,
  updateMediaDocUploadStatusStart,
  updateMediaDocUploadStatusSuccess,
  updateMediaDocUploadStatusFailure,
  updateMediaDocStart,
  updateMediaDocSuccess,
  updateMediaDocFailure,
  getMediaDocStart,
  getMediaDocSuccess,
  getMediaDocFailure,
  deleteMediaDocStart,
  deleteMediaDocSuccess,
  deleteMediaDocFailure,
  setSelectedMediaDoc,
  setPendingAttachments,
  resetPendingAttachments,
} = mediaDocumentsSlice.actions;

export default mediaDocumentsSlice.reducer;
