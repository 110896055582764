/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from '@mui/material';

import notAvailable from 'assets/notAvailable.png';
import { sxCard } from 'pages/Pages.styles';
import { sxSectionsItem } from '../LivingProfile.styles';

const NotAvailablePage = (): ReactElement => {
  const { t } = useTranslation();

  const handleClick = () => {
    window.open('http://liaisonedu.com/', '_self');
  };

  return (
    <Box sx={{ px: { xs: '1rem', sm: '3.5rem', md: '6.5rem' }, py: '1.5rem' }}>
      <Box
        sx={{
          ...sxSectionsItem,
          p: { xs: 5, sm: 10 },
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          component="img"
          sx={{
            maxWidth: { xs: 250, sm: 413 },
            px: 4,
            borderBottom: '1px solid #B0BBCC',
          }}
          alt={t('livingProfile.notAvailable.p1')}
          src={notAvailable}
        />
        <Typography variant="h2" gutterBottom sx={{ mt: 2 }}>
          Oops!
        </Typography>
        <Typography variant="body3" sx={{ textAlign: 'center' }}>
          {t('livingProfile.notAvailable.p1')}
        </Typography>
        <Typography variant="body3" sx={{ textAlign: 'center' }}>
          {t('livingProfile.notAvailable.p2')}
        </Typography>
        <Box sx={{ mt: 5, width: { sm: '100%', md: '90%', lg: '70%' } }}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={0} sx={{ ...sxCard, p: 3 }}>
            <Grid item xs={8}>
              <Typography variant="body3">{t('livingProfile.notAvailable.learnMoreText')}</Typography>
            </Grid>
            <Grid item container xs={3.5} justifyContent="flex-end">
              <Button variant="contained" color="secondary" onClick={handleClick}>
                {t('livingProfile.notAvailable.learnMore')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(NotAvailablePage);
