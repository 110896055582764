/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { Fragment, ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'redux/hooks';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, Typography, Button, Stack, Skeleton, useMediaQuery, Theme } from '@mui/material';
import { IconStar } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import noAddedProgramYet from 'assets/noAddedProgramYet.png';
import { nameSpace } from 'transferPlanner/constants/general';
import { PROGRAM_PROGRESS, RESEARCH_CAMPUS_AND_PROGRAMS } from 'transferPlanner/constants/routeNames';
import {
  selectActiveFollowedPrograms,
  selectProgramCart,
} from 'transferPlanner/store/programCart/programCart.selectors';
import { selectActiveTspEnrollment } from 'transferPlanner/store/enrollment/enrollment.selectors';
import { encryptWithEncode } from 'utils/forgeUtils';
import { ProgramCard } from './ProgramCard';

const maxNumberOfProgramsToDisplay = 3;

export const MyPrograms = (): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const navigate = useNavigate();
  const { loading } = useSelector(selectProgramCart);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const activeFollowedPrograms = useSelector(selectActiveFollowedPrograms);
  const activeTspEnrollmentId = useAppSelector(selectActiveTspEnrollment)?.program?.id;
  const filteredActiveTspEnrollment = useMemo(() => {
    const activeTspEnrollData = activeFollowedPrograms?.find(
      followProgram => activeTspEnrollmentId === followProgram?.program?.id
    );
    return activeTspEnrollData?.program?.id
      ? { ...activeTspEnrollData, program: { ...activeTspEnrollData?.program, isEnrolled: true } }
      : null;
  }, [activeFollowedPrograms, activeTspEnrollmentId]);

  const sortedPrograms = useMemo(
    () =>
      activeFollowedPrograms
        ?.filter(followProgram => activeTspEnrollmentId !== followProgram?.program?.id)
        .sort((a, b) => a.program.name.localeCompare(b.program.name)),
    [activeFollowedPrograms, activeTspEnrollmentId]
  );

  const programsToDisplay = useMemo(() => {
    if (filteredActiveTspEnrollment?.program?.id) {
      return [filteredActiveTspEnrollment, ...sortedPrograms]?.slice(0, maxNumberOfProgramsToDisplay);
    }
    return sortedPrograms?.slice(0, maxNumberOfProgramsToDisplay);
  }, [filteredActiveTspEnrollment, sortedPrograms]);

  const explorePrograms = () => {
    if (programsToDisplay?.length) {
      /* istanbul ignore next */
      navigate(`/${PROGRAM_PROGRESS}/${encryptWithEncode(`${activeFollowedPrograms?.[0]?.program.id || ''}`)}`);
    } else {
      navigate(`/${RESEARCH_CAMPUS_AND_PROGRAMS}`);
    }
    window.scrollTo(0, 0);
  };

  return (
    <Grid container sx={{ p: 2 }}>
      <Grid item xs={12}>
        <Box sx={{ mb: 1 }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={8.5} sm={7.5}>
              <Stack direction="row" alignItems="center">
                <IconStar
                  sx={{
                    fontSize: 32,
                    color: '#FFA000',
                    bgcolor: '#FFF8E1',
                    p: 0.8,
                    borderRadius: 0.5,
                    mr: 1,
                  }}
                />
                <Typography variant="subtitle1" component="div">
                  {t('followed_programs_label')}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs justifyContent="flex-end">
              {programsToDisplay?.length ? (
                <Stack direction="row" justifyContent="end">
                  <Button
                    size="small"
                    onClick={explorePrograms}
                    variant="outlined"
                    aria-label={t('followed_program_view_label')}
                  >
                    {t('view_label')}
                  </Button>
                </Stack>
              ) : null}
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {loading ? (
        <Skeleton data-testid="skeleton" variant="rectangular" width="100%" height={286} sx={{ pt: 2 }} />
      ) : (
        <Grid item xs={12}>
          {programsToDisplay?.length ? (
            <>
              {programsToDisplay.map(({ program }) => (
                <Fragment key={program.id}>
                  <ProgramCard program={program} />
                </Fragment>
              ))}
            </>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <img style={{ width: '175px' }} src={noAddedProgramYet} alt={t('programs.notFollowing')} />
              <Typography variant="body2" gutterBottom sx={{ fontWeight: 600 }}>
                {t('programs.notFollowing')}
              </Typography>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {t('programs.notFollowing.track')}
              </Typography>
              <Button variant="contained" sx={{ mt: 2 }} onClick={explorePrograms}>
                {t('programs.explore_programs')}
              </Button>
            </Box>
          )}
          {/* added below empty element for the dashboard tile slider, check tp-2221 */}
          {isMobile && <Box id="tile-slider-next-0" tabIndex={0} />}
        </Grid>
      )}
    </Grid>
  );
};
