/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import {
  Avatar,
  AppBar,
  Box,
  Dialog,
  DialogContent,
  Grid,
  dialogClasses,
  Button,
  useMediaQuery,
  Toolbar,
  Typography,
  Theme,
  SvgIcon,
  InputLabel,
} from '@mui/material';
import { IconRemoveCircle, Input } from '@liaison/liaison-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RadioControl } from 'components/RadioControl';
import { setUi } from 'store/ui/ui.slice';
import { StyledDivider, sxPrimaryButton } from 'pages/Pages.styles';
import { nameSpace } from 'transferPlanner/constants/general';
import { selectEnrollment, selectActiveTspEnrollment } from 'transferPlanner/store/enrollment/enrollment.selectors';
import { postEnrollment } from 'transferPlanner/pages/TspEnrollment/TspEnrollment.utils';
import { DISABLE_AUTO_FILL, MAX_BIG_LENGTH_FIELD } from 'constants/field';
import { defaultValues, reasonOptions, composePayload, noPlanningOptions } from './TspRequestRelease.utils';
import { validationSchema } from './TspRequestRelease.validation';
import { Confirmation } from './Confirmation';

type TTspRequestReleaseProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const TspRequestRelease = ({ setIsOpen }: TTspRequestReleaseProps): ReactElement => {
  const {
    control,
    formState: { isValid },
    getValues,
  } = useForm({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(validationSchema, { abortEarly: false }),
  });

  const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
  const { t } = useTranslation(nameSpace);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const tspEnrollment = useSelector(selectEnrollment);
  const activeTspEnrollment = useSelector(selectActiveTspEnrollment);
  const dispatch = useDispatch();
  const { reason, noPlanningReason, otherReason } = getValues();

  const onSubmit = () => {
    if (tspEnrollment && activeTspEnrollment) {
      /* istanbul ignore next */
      const payloadReason = reason === reasonOptions[1].val ? `${reason} ${noPlanningReason}` : reason;
      const payload = composePayload(tspEnrollment, activeTspEnrollment, payloadReason, otherReason);

      dispatch(
        postEnrollment(payload, () => {
          setIsOpen(false);
          dispatch(
            setUi({
              name: 'succesSnackbar',
              state: { open: true, message: t('tspRequestRelease.snackMessage'), hideTitle: true },
            })
          );
        })
      );
    }
  };

  return (
    <Dialog
      {...(!isMobile ? { role: 'main' } : {})}
      fullScreen={isMobile}
      fullWidth
      maxWidth="xl"
      sx={{
        backdropFilter: 'blur(4px)',
        [`.${dialogClasses.paper}`]: {
          borderRadius: '1rem',
        },
      }}
      open
      aria-labelledby="tspRequestRelease"
      aria-describedby="tspRequestRelease"
    >
      {isMobile && (
        <AppBar
          sx={{
            position: 'relative',
            bgcolor: (theme: Theme) => theme.palette.custom.drawer,
            color: (theme: Theme) => theme.palette.secondary.contrastText,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Toolbar>
            <Avatar sx={{ bgcolor: '#000', mr: 1 }}>
              <SvgIcon component={IconRemoveCircle} inheritViewBox />
            </Avatar>
            <Box>
              <Typography variant="subtitle1">{t('tspRequestRelease.header')}</Typography>
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <DialogContent sx={{ p: 0, height: '100vh' }}>
        <Grid container sx={{ minHeight: '100%' }}>
          {!isMobile && (
            <Grid
              item
              xs={3}
              sx={{
                bgcolor: (theme: Theme) => theme.palette.custom.drawer,
                color: (theme: Theme) => theme.palette.secondary.contrastText,
              }}
            >
              <Box sx={{ m: 2 }}>
                <Box>
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    {t('tspRequestRelease.instruction')}
                  </Typography>
                </Box>
                <StyledDivider />
                <Box sx={{ mt: 4, mr: -4.5 }}>
                  <Grid container justifyContent="flex-end" alignItems="center">
                    <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'row' }}>
                      <Typography variant="subtitle5" sx={{ textAlign: 'end' }}>
                        {t('tspRequestRelease.header')}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid container item xs={2} justifyContent="end">
                      <Avatar sx={{ bgcolor: '#000' }}>
                        <SvgIcon component={IconRemoveCircle} inheritViewBox />
                      </Avatar>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid item xs={12} md={9}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                minWidth: 'auto',
              }}
            >
              <Box sx={{ flex: 1, padding: '1.5rem', overflow: 'auto', maxHeight: '82vh' }}>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid container direction="column" item xs={1} />
                  <Grid item sm={10}>
                    {!isMobile && (
                      <>
                        <Grid item>
                          <Typography variant="h1">{t('tspRequestRelease.header')}</Typography>
                        </Grid>
                        <Grid item>
                          <StyledDivider />
                        </Grid>
                      </>
                    )}
                    <Grid item>
                      {isConfirmation ? (
                        <Confirmation />
                      ) : (
                        <Box sx={{ pt: { xs: '0.8rem', md: '1rem' } }}>
                          <InputLabel id="tspRequestRelease" required>
                            {t('tspRequestRelease.question')}
                          </InputLabel>
                          <Box sx={{ mt: '1rem' }}>
                            <Controller
                              name="reason"
                              render={({ field: { ref, onChange, ...field } }) => (
                                <>
                                  <RadioControl
                                    {...field}
                                    inputRef={ref}
                                    id="tspRequestRelease.reasonOptions1"
                                    options={reasonOptions.slice(0, 2)}
                                    onChange={event => {
                                      onChange(event.target.value);
                                    }}
                                  />
                                  {field.value === reasonOptions[1].val && (
                                    <Box sx={{ px: 3, py: 1, bgcolor: (theme: Theme) => theme.palette.grey[100] }}>
                                      <InputLabel required>{t('tspRequestRelease.noPlanning.label')}</InputLabel>
                                      <Controller
                                        name="noPlanningReason"
                                        render={({
                                          field: {
                                            ref: noPlanningRef,
                                            onChange: noPlanningOnChange,
                                            ...noPlanningField
                                          },
                                        }) => (
                                          <RadioControl
                                            {...noPlanningField}
                                            inputRef={noPlanningRef}
                                            id="tspRequestRelease.noPlanning.reasonOptions"
                                            options={noPlanningOptions}
                                            onChange={event => {
                                              noPlanningOnChange(event.target.value);
                                            }}
                                          />
                                        )}
                                        control={control}
                                      />
                                    </Box>
                                  )}
                                  <RadioControl
                                    {...field}
                                    inputRef={ref}
                                    id="tspRequestRelease.reasonOptions2"
                                    options={reasonOptions.slice(2)}
                                    onChange={event => {
                                      onChange(event.target.value);
                                    }}
                                  />
                                  {field.value === reasonOptions[5].val && (
                                    <Controller
                                      name="otherReason"
                                      render={({
                                        field: { ref: inputRef, onChange: inputOnChange, ...inputField },
                                      }) => (
                                        <Input
                                          {...inputField}
                                          inputRef={inputRef}
                                          sx={{ display: 'block', ml: '4.5rem', mt: '-1.9rem' }}
                                          placeholder="Other reason"
                                          onChange={event => {
                                            inputOnChange(event.target.value);
                                          }}
                                          inputProps={{
                                            'aria-label': 'otherReason',
                                            maxLength: MAX_BIG_LENGTH_FIELD,
                                            ...DISABLE_AUTO_FILL,
                                          }}
                                        />
                                      )}
                                      control={control}
                                    />
                                  )}
                                </>
                              )}
                              control={control}
                            />
                          </Box>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '1rem',
                  width: '100%',
                  borderTop: `0.0625rem solid `,
                  borderColor: theme => theme.palette.divider,
                }}
              >
                <Button
                  color="error"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  aria-label={t('cancel')}
                >
                  {t('cancel')}
                </Button>
                <Button
                  color="primary"
                  disabled={!isValid}
                  sx={sxPrimaryButton}
                  variant="contained"
                  onClick={isConfirmation ? () => onSubmit() : () => setIsConfirmation(true)}
                  aria-label={t(isConfirmation ? 'submit_label' : 'continue_label')}
                >
                  {t(isConfirmation ? 'submit_label' : 'continue_label')}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
