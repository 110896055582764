/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ForwardedRef, ReactElement, memo, useCallback } from 'react';
import { Grid, IconButton, Stack, Typography, Box, Card, CardContent } from '@mui/material';
import { IconCaretRight, CustomChip, IconTrophy } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import { IRequirements } from 'transferPlanner/store/genEdProcess/genEdProcess.slice';
import { nameSpace } from 'transferPlanner/constants/general';
import useToggleTabIndex from 'transferPlanner/hooks/useToggleTabIndex';
import { genEdStatusConfig, getChipStatus, IGenEdAccordianBodyProps } from './GeneralEducation.utils';

type TStatusChipProps = {
  statusCode: string;
  statusLabel?: string;
};
export const StatusChip = memo(({ statusCode, statusLabel }: TStatusChipProps): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const { elementRef } = useToggleTabIndex(-1);
  const currentStatus = genEdStatusConfig[statusCode as keyof typeof genEdStatusConfig];

  return (
    <CustomChip
      sx={{ border: `1px solid ${currentStatus.border}` }}
      icon={currentStatus.icon}
      label={statusLabel || t(currentStatus.status)}
      variant="square"
      txtColor="#383D45"
      bgColor={currentStatus.bgColor}
      ref={elementRef as ForwardedRef<HTMLDivElement>}
    />
  );
});

const GenEdAccordianBody = ({
  config,
  categoryDetail,
  onViewDetailsClicked,
}: IGenEdAccordianBodyProps): ReactElement => {
  const { t } = useTranslation(nameSpace);

  const getCourseTitle = useCallback(
    (requirementDetail: IRequirements | undefined) => {
      if (requirementDetail) {
        if (requirementDetail?.optOut) {
          return t('opted_out_label');
        }
        return (
          requirementDetail?.course?.title ||
          requirementDetail?.test?.classification?.displayName ||
          t('generalEducation.notCompleted')
        );
      }
      return t('generalEducation.notCompleted');
    },
    [t]
  );

  return (
    <Grid container spacing={1}>
      {(config?.requirements || [])?.map((requirement, index) => {
        const currentIndex = index;
        const requirementDetail = categoryDetail?.requirements?.[index];
        const categoryName = requirement?.name ? `${requirement?.code} - ${requirement?.name}` : config?.category;
        const sidePanelTitle = requirementDetail?.course
          ? t('generalEducation.courseTitle')
          : t('generalEducation.testTitle');
        const courseTitle = getCourseTitle(requirementDetail);
        const chipStatus = getChipStatus(requirementDetail);
        const completedCourse =
          courseTitle !== t('opted_out_label') && courseTitle !== t('generalEducation.notCompleted');
        const isGoldenFour = requirement?.flags?.includes('GOLDEN_4');
        return (
          <Grid item md={4} sm={6} key={`${requirement?.code}-${currentIndex}`} sx={{ width: '100%' }}>
            <Card
              variant="outlined"
              sx={{
                background: courseTitle === t('opted_out_label') ? '#EBEFF6' : '',
                borderRadius: '0.5rem',
                boxShadow: completedCourse
                  ? '0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06)'
                  : '',
              }}
            >
              <CardContent>
                <Stack
                  direction="row"
                  sx={{ width: { xs: '90%', sm: '90%', md: '90%', lg: '90%', xl: '100%' }, columnGap: 0.5 }}
                >
                  {isGoldenFour && (
                    <IconTrophy
                      aria-label={t('generalEducation.goldenFour.icon.label')}
                      style={{
                        backgroundColor: '#F3D03E',
                        borderRadius: 0,
                        width: '1.4rem',
                        height: '1.4rem',
                        padding: '0.3rem',
                      }}
                      tabIndex={-1}
                    />
                  )}
                  <StatusChip statusCode={chipStatus} statusLabel={categoryName} />
                </Stack>
                <Stack direction="row">
                  <Stack direction="column">
                    <Typography sx={{ mt: 1 }} variant="subtitle5">
                      {courseTitle}
                    </Typography>
                    <Typography sx={{ mt: 1 }} variant="caption">
                      {requirementDetail?.course?.code ||
                        (requirementDetail?.test?.type?.code && `${requirementDetail?.test?.type?.code} Exam`) ||
                        t('notApplicable.label')}
                    </Typography>
                  </Stack>
                  {(requirementDetail?.course?.id || requirementDetail?.test?.id) && (
                    <Box sx={{ display: 'flex', marginLeft: 'auto' }}>
                      <IconButton
                        aria-label="viewdetails"
                        onClick={() => {
                          onViewDetailsClicked(sidePanelTitle, 'CourseDetails', false, requirementDetail);
                        }}
                      >
                        <IconCaretRight />
                      </IconButton>
                    </Box>
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default memo(GenEdAccordianBody);
