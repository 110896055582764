/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';

import type { TAchievement, TExperience } from 'userProfile/store/profileView/profileView.slice';
import { sxSectionsItem } from '../LivingProfile.styles';
import { ItemCard } from './ItemCard';

type TCardSectionProps = {
  title: string;
  items?: (TExperience | TAchievement)[];
  toggleDrawer: (item?: TAchievement | TExperience, toggle?: string) => void;
};

export const CardSection = ({ title, items, toggleDrawer }: TCardSectionProps): ReactElement => {
  return (
    <Box sx={{ ...sxSectionsItem, p: 3 }}>
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography variant="h2" sx={{ pb: 3 }}>
            {title}
          </Typography>
          <Grid container spacing={2}>
            {items?.map(item => (
              <ItemCard key={item?.id} item={item} toggleDrawer={toggleDrawer} />
            ))}
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};
