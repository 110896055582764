/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useCallback } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectFeatures } from 'store/features/features.selectors';
import { csuNameSpace } from 'utils/utilities';
import { isFeatureActive } from 'utils/features.utils';
import { DASHBOARD, RESEARCH_CAMPUS_AND_PROGRAMS, SKIP_CURRENT_ROUTE } from 'transferPlanner/constants/routeNames';
import { setUi } from 'store/ui/ui.slice';
import { groupName } from '../Questionnaire.utils';
import { eligibleIcon, ineligibleIcon, InfoBox, ResultBox } from './Result.utils';

interface IResultProps {
  eligible: boolean;
}

export const Result = ({ eligible }: IResultProps): ReactElement => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const features = useSelector(selectFeatures);
  const onExploreClick = () => navigate(`/${RESEARCH_CAMPUS_AND_PROGRAMS}`, SKIP_CURRENT_ROUTE);
  const questionnaire = isFeatureActive(features, 'HIDE_JULY_TP_ANSWERS');

  const setOpenSidebar = useCallback(
    (name?: string) => () => {
      if (name) {
        navigate(`/${DASHBOARD}`, SKIP_CURRENT_ROUTE);
        dispatch(setUi({ name, state: { open: true } }));
      }
    },
    [navigate, dispatch]
  );

  const bgcolor = eligible ? '#E8F5E9' : '#FFEBEE';

  const borderColor = eligible ? '#144618' : '#891515';

  const icon = eligible ? eligibleIcon : ineligibleIcon;

  const mainText = eligible
    ? t(`${groupName}.resultEligible`, csuNameSpace)
    : t(`${groupName}.resultIneligible`, csuNameSpace);

  return (
    <Box sx={{ mx: { xs: '0.8rem', sm: '2rem', md: '2rem', lg: questionnaire ? 0 : '6rem' }, height: '64vh' }}>
      <Grid container item direction={{ xs: 'column', md: 'row', lg: 'row' }}>
        <Grid item xs={12}>
          <ResultBox bgcolor={bgcolor} borderColor={borderColor} icon={icon} mainText={mainText} />
        </Grid>
        <Grid item>
          <Box>
            <Typography variant="h3" sx={{ mt: '1rem', mb: '0.5rem' }}>
              {t(`${groupName}.next`, csuNameSpace)}
            </Typography>
          </Box>
        </Grid>
        <Grid container item spacing={2} direction={{ xs: 'column', md: 'row' }}>
          <Grid item xs={6}>
            <InfoBox
              text={t(`${groupName}.alreadyEnrolled`, csuNameSpace)}
              buttonLabel={t('add_college_label', csuNameSpace)}
              onLinkClick={setOpenSidebar('collegeSidebar')}
            />
          </Grid>
          <Grid item xs={6}>
            <InfoBox
              text={t(`${groupName}.startResearching`, csuNameSpace)}
              buttonLabel={t('explore_label', csuNameSpace)}
              onLinkClick={onExploreClick}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
