/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */
/* eslint-disable no-param-reassign */
import { API } from 'constants/api';
import { t } from 'i18next';
import axios from 'axios';
import type { AnyAction, Dispatch } from 'redux';
import type { TAppThunk } from 'redux/store';
import {
  academicHistoryStart,
  academicHistorySuccess,
  academicHistoryFailure,
  TAcademicHistoryField,
  ICollege,
  IHighSchool,
  ITerm,
  TTermsAndCourse,
  TCourseExtensionObj,
  ICollegeCourse,
  collegeCourseExtensionStart,
  collegeCourseExtensionSuccess,
  collegeCourseExtensionFailure,
  TCourseExtensionPayload,
  TGradeLevelAndCourse,
  THighSchoolCourse,
  Idegree,
} from 'store/academicHistory/academicHistory.slice';
import { Ioption, isEmpty, sanitizePayload } from 'utils/utilities';
import { getLoggedUser } from 'utils/keyCloakUtils';
import { setUi } from 'store/ui/ui.slice';
import {
  ICollegeCommon,
  TCoursesOption,
  getCourseApiFailure,
  getCourseApiStart,
  getCourseApiSuccess,
} from 'store/common/commonInfo.slice';
import { ASSIST_URL } from 'transferPlanner/constants/api';
import { TAutocompleteOption } from '@liaison/liaison-ui';
import { TPendingAttachments, resetPendingAttachments } from 'userProfile/store/mediaDocuments/mediaDocuments.slice';
import { attachMediaDocs } from 'userProfile/pages/MediaDocuments/MediaDocuments.utils';
import {
  postChildData as postAccomplishmentsAndExperience,
  attachSkills,
} from 'userProfile/pages/AccomplishmentsAndExperiences/AccomplishmentsAndExperiences.utils';
import {
  TAccomplishmentsAndExperienceField,
  TCompetency,
  TSkillsAttachments,
  resetSkillsAttachments,
} from 'userProfile/store/accomplishmentAndExperience/accomplishmentAndExperience.slice';

interface IViolation {
  field: string;
  message: string;
  userDisplay: boolean;
}

export const getAcademicHistory =
  (successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(academicHistoryStart());
      const { data } = await axios.get(`${API?.academicHistory}${getLoggedUser()}`);
      dispatch(academicHistorySuccess(data));
      successCallback?.();
    } catch (err) {
      dispatch(academicHistoryFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const handlePendingAttachments = (
  payload: TAcademicHistoryField,
  data: TAcademicHistoryField,
  dispatch: Dispatch<AnyAction>,
  pendingAttachments: Partial<TPendingAttachments>
): void => {
  const dispatchResetPendingAttachments =
    (shouldReset = false) =>
    () => {
      if (shouldReset) {
        dispatch(resetPendingAttachments());
      }
    };
  // 1) new College
  if (pendingAttachments.colleges?.length) {
    const newCollegeInPayload = payload.colleges?.find(college => !college.id);
    if (newCollegeInPayload) {
      const newCollegeInResponce = data.colleges?.find(
        college =>
          college.name === newCollegeInPayload.name &&
          college.address?.country.code === newCollegeInPayload.address?.country.code
      );
      // only one new Degree may be added at a time with a new College, so if one is added, it will always be degrees[0].
      const newDegreeWithAttachments = pendingAttachments.degrees?.length && newCollegeInResponce?.degrees?.[0];
      dispatch(
        attachMediaDocs(
          {
            resource: 'academicHistory',
            tag: `colleges/${newCollegeInResponce?.id}`,
            documentIds: [...pendingAttachments.colleges],
          },
          dispatchResetPendingAttachments(!newDegreeWithAttachments),
          dispatchResetPendingAttachments(!newDegreeWithAttachments)
        )
      );
      // 2) new College with new Degree simultaneously
      if (newDegreeWithAttachments) {
        setTimeout(() => {
          dispatch(
            attachMediaDocs(
              {
                resource: 'academicHistory',
                tag: `colleges/${newCollegeInResponce?.id}/degrees/${newCollegeInResponce?.degrees?.[0]?.id}`,
                documentIds: [...(pendingAttachments.degrees ?? [])],
              },
              dispatchResetPendingAttachments(true),
              dispatchResetPendingAttachments(true)
            )
          );
        }, 1000);
      }
      return;
    }
  }
  // 3) new High School
  if (pendingAttachments.highSchools?.length) {
    const newHighSchoolInPayload = payload.highSchools?.find(highSchool => !highSchool.id);
    if (newHighSchoolInPayload) {
      const newHighSchoolInResponce = data.highSchools?.find(
        highSchool => highSchool.ceeb === newHighSchoolInPayload.ceeb
      );
      // only one new HS Course may be added at a time with a new High School, so if one is added, it will always be gradeLevels[0].courses[0].
      const newHsCourseWithAttachments =
        pendingAttachments.hsCourse?.length && newHighSchoolInResponce?.gradeLevels?.[0]?.courses?.[0];
      dispatch(
        attachMediaDocs(
          {
            resource: 'academicHistory',
            tag: `highSchools/${newHighSchoolInResponce?.id}`,
            documentIds: [...pendingAttachments.highSchools],
          },
          dispatchResetPendingAttachments(!newHsCourseWithAttachments),
          dispatchResetPendingAttachments(!newHsCourseWithAttachments)
        )
      );
      // 4) new High School with new HS Course simultaneously
      if (newHsCourseWithAttachments) {
        setTimeout(() => {
          dispatch(
            attachMediaDocs(
              {
                resource: 'academicHistory',
                tag: `highSchools/${newHighSchoolInResponce?.id}/gradeLevels/${newHighSchoolInResponce?.gradeLevels?.[0]?.id}/courses/${newHighSchoolInResponce?.gradeLevels?.[0]?.courses?.[0]?.id}`,
                documentIds: [...(pendingAttachments.hsCourse ?? [])],
              },
              dispatchResetPendingAttachments(true),
              dispatchResetPendingAttachments(true)
            )
          );
        }, 1000);
      }
      return;
    }
  }
  // 5) just a new College Course
  if (pendingAttachments.collegeCourse?.length) {
    const newCollegeCourseInPayload = payload.colleges?.reduce((acc, cur) => {
      cur.terms?.forEach(term => {
        term.courses?.forEach(course => {
          if (!course.id) {
            acc = { ...course };
          }
        });
      });
      return acc;
    }, {}) as ICollegeCourse;
    if (newCollegeCourseInPayload.code) {
      const tag = data.colleges?.reduce((acc, cur) => {
        cur.terms?.forEach(term => {
          term.courses?.forEach(course => {
            if (
              course.title === newCollegeCourseInPayload.title &&
              course.code === newCollegeCourseInPayload.code &&
              course.credits === newCollegeCourseInPayload.credits &&
              course.grade === newCollegeCourseInPayload.grade
            ) {
              acc = `colleges/${cur.id}/terms/${term.id}/courses/${course.id}`;
            }
          });
        });
        return acc;
      }, '');
      dispatch(
        attachMediaDocs(
          {
            resource: 'academicHistory',
            tag,
            documentIds: [...pendingAttachments.collegeCourse],
          },
          dispatchResetPendingAttachments(true),
          dispatchResetPendingAttachments(true)
        )
      );
      return;
    }
  }
  // 6) just a new HS Course
  if (pendingAttachments.hsCourse?.length) {
    const newHsCourseInPayload = payload.highSchools?.reduce((acc, cur) => {
      cur.gradeLevels?.forEach(gradeLevel => {
        gradeLevel.courses?.forEach(course => {
          if (!course.id) {
            acc = { ...course };
          }
        });
      });
      return acc;
    }, {}) as THighSchoolCourse;
    if (newHsCourseInPayload.title) {
      const tag = data.highSchools?.reduce((acc, cur) => {
        cur.gradeLevels?.forEach(gradeLevel => {
          gradeLevel.courses?.forEach(course => {
            if (
              course.title === newHsCourseInPayload.title &&
              course.classification?.code === newHsCourseInPayload.classification?.code &&
              course.grade?.overall === newHsCourseInPayload.grade?.overall
            ) {
              acc = `highSchools/${cur.id}/gradeLevels/${gradeLevel.id}/courses/${course.id}`;
            }
          });
        });
        return acc;
      }, '');
      dispatch(
        attachMediaDocs(
          {
            resource: 'academicHistory',
            tag,
            documentIds: [...pendingAttachments.hsCourse],
          },
          dispatchResetPendingAttachments(true),
          dispatchResetPendingAttachments(true)
        )
      );
      return;
    }
  }
  // 7) just a new College Degree
  if (pendingAttachments.degrees?.length) {
    const newDegreeInPayload = payload.colleges?.reduce((acc, cur) => {
      cur.degrees?.forEach(degree => {
        if (!degree.id) {
          acc = { ...degree };
        }
      });
      return acc;
    }, {}) as Idegree;
    if (newDegreeInPayload.degreeStatus) {
      const tag = data.colleges?.reduce((acc, cur) => {
        cur.degrees?.forEach(degree => {
          if (
            degree.degreeStatus.code === newDegreeInPayload.degreeStatus.code &&
            degree.type.code === newDegreeInPayload.type.code &&
            degree.endDate === newDegreeInPayload.endDate
          ) {
            acc = `colleges/${cur.id}/degrees/${degree.id}`;
          }
        });
        return acc;
      }, '');
      dispatch(
        attachMediaDocs(
          {
            resource: 'academicHistory',
            tag,
            documentIds: [...pendingAttachments.degrees],
          },
          dispatchResetPendingAttachments(true),
          dispatchResetPendingAttachments(true)
        )
      );
    }
  }
};

export const handleSkillsAttachments = async (
  payload: TAcademicHistoryField,
  data: TAcademicHistoryField,
  dispatch: Dispatch<AnyAction>,
  skillsAttachments: TSkillsAttachments[],
  competencies: TCompetency[]
): Promise<void> => {
  const dispatchResetSkillsAttachments = () => {
    dispatch(resetSkillsAttachments());
  };

  const getCompetencyIds = (competenciesToAttach?: TCompetency[]): string[] => {
    return (
      competenciesToAttach?.map(({ competency }) => competencies?.find(c => c.competency === competency)?.id ?? '') ??
      []
    );
  };

  // attach skills to existing high school or college
  const institutionSection = skillsAttachments.find(s => s.tag.split('/').length === 2);
  const institutionSectionType = institutionSection?.tag.split('/')[0] as 'highSchools' | 'colleges';

  if (institutionSection) {
    const isNewInstitutionSection = institutionSection?.tag.split('/')[1] === 'undefined';
    let sectionTag = institutionSection?.tag;

    // attach skills to new high school or college
    if (isNewInstitutionSection) {
      const newInstitutionInPayload = (payload[institutionSectionType] as (IHighSchool | ICollege)[])?.find(
        item => !item.id
      );
      const newInstitutionInResponse = (data[institutionSectionType] as (IHighSchool | ICollege)[])?.find(
        item => item.name === newInstitutionInPayload?.name
      );
      sectionTag = newInstitutionInResponse ? `${institutionSectionType}/${newInstitutionInResponse?.id}` : sectionTag;
    }

    const modifiedSkillsAttachments = {
      resource: institutionSection.resource,
      tag: sectionTag,
      competencyIds: getCompetencyIds(institutionSection?.competencies),
    };

    await dispatch(
      attachSkills(modifiedSkillsAttachments, dispatchResetSkillsAttachments, dispatchResetSkillsAttachments)
    );
  }

  // attach skills to existing course
  const courseSection = skillsAttachments?.find(s => s.tag.split('/').includes('courses'));
  const courseSectionType = courseSection?.tag.split('/')[0] as 'highSchools' | 'colleges';
  const courseSectionPeriodType = courseSectionType === 'highSchools' ? 'gradeLevels' : 'terms';

  if (courseSection) {
    const isNewCourseSection = courseSection.tag.split('/').includes('undefined');
    let courseSectionTag = courseSection.tag;

    // attach skills to new course
    if (isNewCourseSection) {
      const courseIdsInPayload = (payload[courseSectionType] as (ICollege | IHighSchool)[])?.reduce<string[]>(
        (acc, cur) => {
          const periods = cur[courseSectionPeriodType as keyof typeof cur];
          if (Array.isArray(periods)) {
            periods.forEach(period => {
              period.courses?.forEach((course: THighSchoolCourse | ICollegeCourse) => {
                if (course.id) acc.push(course.id);
              });
            });
          }
          return acc;
        },
        []
      ) as string[];

      courseSectionTag = (data[courseSectionType] as (ICollege | IHighSchool)[])?.reduce((acc, cur) => {
        const periods = cur[courseSectionPeriodType as keyof typeof cur];
        if (Array.isArray(periods)) {
          periods.forEach(period => {
            period.courses?.forEach((course: THighSchoolCourse | ICollegeCourse) => {
              if (course.id && !courseIdsInPayload.includes(course.id)) {
                acc = `${courseSectionType}/${cur.id}/${courseSectionPeriodType}/${period.id}/courses/${course.id}`;
              }
            });
          });
        }
        return acc;
      }, '') as string;
    }

    const courseSkillsAttachments = {
      resource: courseSection.resource,
      tag: courseSectionTag,
      competencyIds: getCompetencyIds(courseSection?.competencies),
    };

    await dispatch(
      attachSkills(courseSkillsAttachments, dispatchResetSkillsAttachments, dispatchResetSkillsAttachments)
    );
  }

  // attach skills to existing degree
  const degreeSection = skillsAttachments?.find(s => s.tag.split('/').includes('degrees'));

  if (degreeSection) {
    const isNewDegreSection = degreeSection.tag.split('/').includes('undefined');
    let degreeSectionTag = degreeSection.tag;

    // attach skills to new degree
    if (isNewDegreSection) {
      const newDegreeInPayload = payload.colleges?.reduce((acc, cur) => {
        cur.degrees?.forEach(degree => {
          if (!degree.id) {
            acc = { ...degree };
          }
        });
        return acc;
      }, {}) as Idegree;

      if (newDegreeInPayload.degreeStatus) {
        degreeSectionTag = data.colleges?.reduce((acc, cur) => {
          cur.degrees?.forEach(degree => {
            if (
              degree.degreeStatus.code === newDegreeInPayload.degreeStatus.code &&
              degree.type.code === newDegreeInPayload.type.code
            ) {
              acc = `colleges/${cur.id}/degrees/${degree.id}`;
            }
          });
          return acc;
        }, '') as string;
      }
    }

    const degreeSkillsAttachments = {
      resource: degreeSection.resource,
      tag: degreeSectionTag,
      competencyIds: getCompetencyIds(degreeSection?.competencies),
    };

    await dispatch(
      attachSkills(degreeSkillsAttachments, dispatchResetSkillsAttachments, dispatchResetSkillsAttachments)
    );
  }
};

export const postAcademicHistory =
  (
    payload: TAcademicHistoryField,
    successCallback?: (response: TAcademicHistoryField) => void,
    revertData?: boolean
  ): TAppThunk =>
  async (dispatch, getState) => {
    const { pendingAttachments } = getState().mediaDocuments;
    const { skillsAttachments, accomplishmentsAndExperienceData } = getState().accomplishmentAndExperience;
    try {
      const loggedUserId = getLoggedUser();
      dispatch(academicHistoryStart());
      const modifiedPayload = {
        ...payload,
        profileId: loggedUserId,
      };
      const { data } = await axios.post(`${API?.academicHistory}${loggedUserId}`, modifiedPayload);
      dispatch(academicHistorySuccess(data));

      if (revertData) {
        dispatch(setUi({ name: 'apiStatus', state: { failure: true, failureMessage: t('error.genericErrorMsg') } }));
        return;
      }
      if (pendingAttachments) {
        handlePendingAttachments(payload, data, dispatch, pendingAttachments);
      }
      if (skillsAttachments) {
        const newCompetencies: TCompetency[] = [];
        skillsAttachments?.forEach(({ competencies }) => {
          if (competencies?.length) {
            competencies.forEach(({ competency }) => {
              if (!accomplishmentsAndExperienceData?.competencies?.find(c => c.competency === competency)) {
                newCompetencies.push({ competency });
              }
            });
          }
        });
        const modifiedAccomplishmentsAndExperiencePayload = {
          ...accomplishmentsAndExperienceData,
          competencies: [...(accomplishmentsAndExperienceData?.competencies ?? []), ...newCompetencies],
          profileId: loggedUserId,
        };
        dispatch(
          postAccomplishmentsAndExperience(
            sanitizePayload(
              Object.assign(modifiedAccomplishmentsAndExperiencePayload as TAccomplishmentsAndExperienceField)
            ),
            (savedData?: TAccomplishmentsAndExperienceField) => {
              handleSkillsAttachments(payload, data, dispatch, skillsAttachments, savedData?.competencies ?? []);
            },
            false
          )
        );
      }
      if (!pendingAttachments && !skillsAttachments) {
        dispatch(
          setUi({ name: 'succesSnackbar', state: { open: true, message: t('success_message'), hideTitle: true } })
        );
      }
      successCallback?.(data);
    } catch (err) {
      const displaySpecificMessage = err?.response?.data?.violations?.find(
        (violation: IViolation) => violation.userDisplay
      );
      dispatch(
        setUi({ name: 'apiStatus', state: { failure: true, failureMessage: displaySpecificMessage?.message ?? '' } })
      );
      dispatch(academicHistoryFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
      if (pendingAttachments) {
        dispatch(resetPendingAttachments());
      }
    }
  };

export const getCourses =
  (alternateId: number | undefined, searchTerm: string, term: string, year: string): TAppThunk =>
  async dispatch => {
    try {
      dispatch(getCourseApiStart());
      const { data } = await axios.get(`${ASSIST_URL}/colleges/${alternateId}/courses`, {
        params: { searchTerm, term, year },
      });
      dispatch(getCourseApiSuccess(data));
    } catch (err) {
      dispatch(getCourseApiFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const getAllCourseExtensions = (): TAppThunk => async dispatch => {
  try {
    dispatch(collegeCourseExtensionStart());
    const { data } = await axios.get(`${API?.collegeCourseExtension}/${getLoggedUser()}`);
    dispatch(collegeCourseExtensionSuccess(data));
  } catch (err) {
    dispatch(collegeCourseExtensionFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  }
};

export const postCourseExtensions =
  (payload: TCourseExtensionPayload, successCallback?: () => void, failureCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(collegeCourseExtensionStart());
      const { data } = await axios.post(`${API?.collegeCourseExtension}/${getLoggedUser()}`, payload);
      dispatch(collegeCourseExtensionSuccess(data));
      successCallback?.();
    } catch (err) {
      dispatch(collegeCourseExtensionFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
      failureCallback?.();
    }
  };

interface IgrandChildInformation {
  isOpenChildDrawer: boolean;
  isAddNew: boolean;
  titleName: string;
  childPosition: number;
  parent: string;
  parentPosition: number;
  prefix: string;
  coursePosition: number;
}

interface IchildDrawerInformation {
  isOpenChildDrawer: boolean;
  isAddNew: boolean;
  titleName: string;
  childPosition: number;
  parent: string;
  showCourses: boolean;
}

export const updateAcademicYear = (currentTerm: ITerm): ITerm => {
  return {
    ...currentTerm,
    academicYear: parseInt(currentTerm.academicYear.toString(), 10),
  };
};

export const findTermExist = (data: ICollege, term: ITerm, childPosition: number): boolean => {
  const currData = data?.terms?.[childPosition];

  return (
    term?.completionStatus?.code === currData?.completionStatus?.code &&
    term?.academicYear === parseInt(currData?.academicYear.toString(), 10)
  );
};

/* istanbul ignore next */
export const generateGrandChildPayload = (
  data: TAcademicHistoryField | TTermsAndCourse | TGradeLevelAndCourse,
  grandChildDrawerInformation: IgrandChildInformation,
  childDrawerInformation: IchildDrawerInformation,
  academicHistoryData: TAcademicHistoryField | null,
  parentFormData: TAcademicHistoryField | null
): TAcademicHistoryField => {
  const isAddNew = grandChildDrawerInformation?.isAddNew;
  const parentName = grandChildDrawerInformation?.parent as keyof TAcademicHistoryField;
  const parentPosition: number = grandChildDrawerInformation?.parentPosition;
  const childPosition = grandChildDrawerInformation?.childPosition;
  const coursePosition = grandChildDrawerInformation?.coursePosition;

  let payload;
  if (
    (data as TTermsAndCourse)?.term &&
    (data as TTermsAndCourse)?.course &&
    (data as TTermsAndCourse)?.termPosition >= 0 &&
    (data as TTermsAndCourse)?.coursePosition >= 0
  ) {
    const newData = { ...data } as TTermsAndCourse;
    const currentData = [...(academicHistoryData?.[parentName] || [])] as unknown as ICollege[];

    const currentCollege = currentData?.[parentPosition];
    const pendingCourse = {
      ...newData,
      collegeId: currentCollege?.id,
      collegeName: currentCollege.name,
      collegePosition: parentPosition,
    };
    localStorage.setItem('pendingCourses', JSON.stringify(pendingCourse));
    const allCourses = [...(currentData[parentPosition]?.terms?.[newData.termPosition]?.courses || [])];
    allCourses[newData.coursePosition] = newData.course;

    const allTerms = [...(currentData[parentPosition]?.terms || [])];
    allTerms[newData.termPosition] = newData.term;
    allTerms[newData.termPosition] = {
      ...newData.term,
      courses: allCourses,
    };

    currentData[parentPosition] = {
      ...currentData[parentPosition],
      terms: allTerms,
    };
    payload = {
      ...academicHistoryData,
      [parentName]: currentData,
    };
  } else if (
    (data as TGradeLevelAndCourse)?.gradeLevel &&
    (data as TGradeLevelAndCourse)?.hscourse &&
    (data as TGradeLevelAndCourse)?.gradeLevelPosition >= 0 &&
    (data as TGradeLevelAndCourse)?.coursePosition >= 0
  ) {
    const newData = { ...data } as TGradeLevelAndCourse;
    // "termType" and "summer" added in the HighSchoolCourseForm.tsx
    // for validation in the AcademicHistoryForm.validation.ts (when(['termType', 'summer'])
    // setValue(`${coursePrefix}.grade.termType`, data?.termType?.code);
    // setValue(`${coursePrefix}.grade.summer`, 'summer');
    // so shoud be deleted here as not expected in the data model on BE
    delete newData.hscourse?.grade?.termType;
    delete newData.hscourse?.grade?.summer;
    let currentData = [...(academicHistoryData?.[parentName] || [])] as unknown as IHighSchool[];
    if (childDrawerInformation?.isAddNew) {
      currentData = [
        ...currentData,
        parentFormData?.[parentName as keyof typeof parentFormData]?.[parentPosition] as IHighSchool,
      ];
    }

    const allCourses = [...(currentData[parentPosition]?.gradeLevels?.[newData.gradeLevelPosition]?.courses || [])];
    allCourses[newData.coursePosition] = newData.hscourse;

    const allGradeLevels = [...(currentData[parentPosition]?.gradeLevels || [])];
    allGradeLevels[newData.gradeLevelPosition] = newData.gradeLevel;
    allGradeLevels[newData.gradeLevelPosition] = {
      ...newData.gradeLevel,
      courses: allCourses,
    };

    currentData[parentPosition] = {
      ...currentData[parentPosition],
      gradeLevels: allGradeLevels,
      termType: newData.termType,
    };
    payload = {
      ...academicHistoryData,
      [parentName]: currentData,
    };
  } else {
    let currentData = [...(academicHistoryData?.[parentName] || [])];
    if (childDrawerInformation?.isAddNew) {
      currentData = [
        ...(currentData as unknown as IHighSchool[] | ICollege[]),
        parentFormData?.[parentName]?.[parentPosition] as unknown as IHighSchool[] | ICollege[],
      ] as unknown as IHighSchool[] | ICollege[];
    } else {
      const parentData = parentFormData?.[parentName]?.[parentPosition];
      if (parentData && Object.keys(parentData).length !== 0) {
        currentData[parentPosition] = parentData;
      } else {
        currentData[parentPosition] = academicHistoryData?.[parentName]?.[parentPosition] as unknown as
          | IHighSchool
          | ICollege;
      }
    }

    let selectedDetails = currentData?.[parentPosition] as ICollege;
    if (parentName === 'colleges' && (data as ICollege)?.terms) {
      if (selectedDetails?.terms) {
        const duplicateTerm = selectedDetails?.terms?.find(term =>
          findTermExist(data as ICollege, term, childPosition)
        );
        const termsList = [...(selectedDetails?.terms || [])];
        if (!duplicateTerm) {
          termsList[childPosition] = updateAcademicYear((data as ICollege)?.terms?.[childPosition]);
        } else {
          const duplicateIndex = selectedDetails?.terms.findIndex((term: ITerm) =>
            findTermExist(data as ICollege, term, childPosition)
          );

          const selectedTerm = selectedDetails?.terms?.[duplicateIndex];
          const currentCourse = (data as ICollege)?.terms?.[childPosition]?.courses?.[coursePosition];
          const modifiedCourses = [...(selectedTerm.courses || [])];
          if (!isAddNew) {
            modifiedCourses[coursePosition] = currentCourse as ICollegeCourse;
          } else {
            modifiedCourses.push(currentCourse as ICollegeCourse);
          }

          termsList[duplicateIndex] = {
            ...selectedTerm,
            courses: modifiedCourses,
          };
        }
        selectedDetails = { ...selectedDetails, terms: termsList };
      } else {
        const termsList = (data as ICollege)?.terms;
        const currentTerm = termsList?.[childPosition];
        termsList[childPosition] = updateAcademicYear(currentTerm);
        selectedDetails = { ...selectedDetails, terms: termsList };
      }
    }

    if (selectedDetails && selectedDetails?.degrees) {
      const degreesList = [...(selectedDetails?.degrees || [])];
      degreesList[childPosition] = (data as ICollege)?.degrees?.[childPosition] as Idegree;
      selectedDetails = { ...selectedDetails, degrees: degreesList };
    } else {
      const degreesList = (data as ICollege)?.degrees;
      selectedDetails = { ...selectedDetails, degrees: degreesList };
    }

    /* TODO : Remove commented lines - if doesn't break the college courses and the highschool grades - TP-2900 */

    // if (selectedDetails && selectedDetails?.courses) {
    //   const coursesList = [...(selectedDetails?.courses || [])];
    //   if (parentName === 'highSchools') {
    //     const currentCourse = data?.courses?.[childPosition];
    //     coursesList[childPosition] = {
    //       ...currentCourse,
    //       academicYear: parseInt(currentCourse.academicYear, 10),
    //     };
    //   }
    //   selectedDetails = { ...selectedDetails, courses: coursesList };
    // } else {
    //   const coursesList = data?.courses;
    //   if (parentName === 'highSchools') {
    //     const currentCourse = coursesList?.[childPosition];
    //     coursesList[childPosition] = {
    //       ...currentCourse,
    //       academicYear: parseInt(currentCourse.academicYear, 10),
    //     };
    //   }
    //   selectedDetails = { ...selectedDetails, courses: coursesList };
    // }

    currentData[parentPosition] = selectedDetails;

    payload = academicHistoryData;
    if (currentData[parentPosition]?.primary) {
      const updatedColleges = currentData?.map((collegeData, i) => {
        let primaryStatus = false;
        if (i === parentPosition) primaryStatus = true;
        return { ...collegeData, primary: primaryStatus };
      });

      payload = {
        ...academicHistoryData,
        [parentName]: updatedColleges,
      };
    } else {
      payload = {
        ...academicHistoryData,
        [parentName]: currentData,
      };
    }
  }

  return payload;
};

export const modifyResponse = (response: TAcademicHistoryField): TAcademicHistoryField => {
  const booleanFiedsSection = ['highSchools', 'colleges'];
  let updatedResponse = {};
  Object.keys(response)?.map(ahKey => {
    let modifiedData = response?.[ahKey as keyof typeof response];
    if (booleanFiedsSection.includes(ahKey)) {
      modifiedData = modifiedData?.map(sectionDetail => {
        const copiedSection = {
          ...sectionDetail,
        };
        const graduated = copiedSection?.graduated;
        if (!isEmpty(graduated)) {
          copiedSection.graduated = graduated ? 'Yes' : 'No';
        }
        return copiedSection as ICollege;
      });
    }

    const currentSection = `${ahKey}`;
    updatedResponse = { ...updatedResponse, [currentSection]: modifiedData };
    return updatedResponse;
  });

  return updatedResponse;
};

export const getCollegeOptions = (options: ICollege[]): ICollege[] => {
  return options?.map(option => ({
    ...option,
    id: option?.collegeCode || option?.name?.longName,
    text: option?.name?.longName,
  }));
};

export const getSchoolOptions = (options: IHighSchool[]): IHighSchool[] => {
  return options?.map(option => ({
    ...option,
    id: option?.ceeb,
    text: option?.name,
  }));
};

export interface ICommunityCollegeOptions {
  id: string;
  text: string;
  ceeb?: string;
  collegeId?: string;
  alternateId?: { code: number; source: string };
  address?: {
    city?: string;
    address1?: string;
    country: Ioption;
    region: Ioption;
  };
}

export const getCommunityCollegeOptions = (options: ICollegeCommon[] | null): ICommunityCollegeOptions[] | [] => {
  if (!options) {
    return [];
  }
  return options?.map(option => ({
    id: option?.name?.longName,
    text: option?.name?.longName,
    collegeId: option?.collegeCode,
    ceeb: option?.ceeb,
    address: option?.address,
    alternateId: option?.alternateId,
  }));
};

export const autoCompleteLoading = { id: 'Loading', text: 'Loading...' };
export const autoCompleteManualyAdd = { id: 'addManually', text: 'addManually' };

export const modifiedSchoolListing = (option: IHighSchool): string => {
  const selectedRegion = option?.address?.country?.code === 'US' ? option?.address?.region?.displayName : '';
  return option?.address ? `${option?.address?.city}, ${selectedRegion}`.replace(/,\s*$/, '').toUpperCase() : '';
};

export const modifiedCollegeListing = (option: ICollegeCommon): string => {
  const selectedRegion = option?.address?.country?.code === 'US' ? option?.address?.region?.displayName : '';
  return `${selectedRegion}`.replace(/,\s*$/, '').toUpperCase();
};

export const studentIdMaxLength = 15;

export const degreeStatuses = [
  {
    val: 'DEGREE_IN_PROGRESS',
    text: 'Degree in Progress',
  },
  {
    val: 'AWARDED',
    text: 'Degree Awarded',
  },
];

export const getCollegeCourseOptions = (options: TCoursesOption[]): TAutocompleteOption<string>[] => {
  return options?.map(option => ({
    ...option,
    id: `${option?.courseNumber} - ${option?.courseTitle}`,
    text: `${option?.coursePrefix}${option?.courseNumber} - ${option?.courseTitle}`,
  }));
};

export const preparePayloadForExtension = (coursesExtensions: TCourseExtensionObj[]): TCourseExtensionPayload => {
  return {
    profileId: getLoggedUser(),
    tenants: ['csu'],
    lastUpdatedTimestamp: new Date().toISOString(),
    collegesTermsCoursesExtensions: coursesExtensions,
  };
};

export const prepareCourseExtensionsPayload = (
  currentCourse: TCourseExtensionObj,
  coursesExtensions: TCourseExtensionObj[] | undefined
): TCourseExtensionPayload => {
  let finalCoursesExtensions: TCourseExtensionObj[];
  if (coursesExtensions) {
    finalCoursesExtensions = [...coursesExtensions];
    const idx = finalCoursesExtensions.findIndex(coursesExt => coursesExt.id === currentCourse.id);
    if (idx >= 0) {
      finalCoursesExtensions[idx] = currentCourse;
    } else {
      finalCoursesExtensions.push(currentCourse);
    }
  } else {
    finalCoursesExtensions = [currentCourse];
  }

  return preparePayloadForExtension(finalCoursesExtensions) as TCourseExtensionPayload;
};

export const getCourseExtensionsPayload = (
  academicHistoryPayload: TAcademicHistoryField,
  pendingCourse: TTermsAndCourse,
  coursesExtensions: TCourseExtensionObj[] | undefined
): TCourseExtensionPayload | undefined => {
  const college = academicHistoryPayload.colleges?.[pendingCourse.collegePosition as number];
  const modifiedTerm = college?.terms?.find(
    term =>
      term.id === pendingCourse.term.id ||
      (`${term?.academicYear}` === `${pendingCourse?.term?.academicYear}` &&
        term?.type?.code === pendingCourse?.term?.type?.code)
  ) as ITerm;

  const modifiedCourse = modifiedTerm?.courses?.find(
    course =>
      course.id === pendingCourse?.course?.id ||
      (course.code === pendingCourse.course.code && course.title === pendingCourse.course.title)
  ) as ICollegeCourse;

  if (pendingCourse.course.id) {
    let extensionCourse = coursesExtensions?.find(coursesExt => coursesExt.id === pendingCourse.course.id);

    const geCertificationCode =
      'geCertificationCode' in pendingCourse
        ? pendingCourse?.geCertificationCode
        : extensionCourse?.geCertificationCode;

    const geCertificationCodes =
      'geCertificationCodes' in pendingCourse
        ? pendingCourse?.geCertificationCodes
        : extensionCourse?.geCertificationCodes;

    if (extensionCourse) {
      extensionCourse = {
        ...extensionCourse,
        ...modifiedCourse,
        transferable: pendingCourse.transferable,
        geCertificationCode,
        geCertificationCodes,
      };
      return prepareCourseExtensionsPayload(extensionCourse, coursesExtensions);
    }
  }

  const extensionPayload = {
    ...modifiedCourse,
    collegeId: college?.id,
    termId: modifiedTerm?.id,
    transferable: pendingCourse.transferable,
    geCertificationCode: pendingCourse?.geCertificationCode,
    geCertificationCodes: pendingCourse?.geCertificationCodes,
  };

  return prepareCourseExtensionsPayload(extensionPayload as TCourseExtensionObj, coursesExtensions);
};

export const findNewCoursePosition = (
  payload: TAcademicHistoryField,
  childPosition: number,
  parentPosition: number,
  parentSelector: string
): number => {
  const parent = payload[parentSelector as 'highSchools' | 'colleges'];
  if (parentSelector === 'colleges') {
    const collegesCourses = (parent as ICollege[])?.[parentPosition]?.terms?.[childPosition]?.courses;
    return collegesCourses?.length || 0;
  }
  if (parentSelector === 'highSchools') {
    const highSchoolCourses = (parent as IHighSchool[])?.[parentPosition]?.gradeLevels?.[childPosition]?.courses;
    return highSchoolCourses?.length || 0;
  }

  return 0;
};

export const findNextCourseGrade = (
  requestData: TAcademicHistoryField,
  parent: string,
  parentPosition: number,
  prefix: string,
  childPosition: number,
  coursePosition: number
): string | undefined => {
  const parentSelector = requestData[parent as 'highSchools' | 'colleges'];
  if (parent === 'colleges' && prefix === 'terms') {
    return (parentSelector as ICollege[])?.[parentPosition]?.terms?.[childPosition]?.courses?.[coursePosition + 1]
      ?.grade;
  }
  return '';
};

export const filterCourseExtensions = (
  coursesExtensions: TCourseExtensionObj[] | undefined,
  removeCourses: ICollegeCourse[]
): TCourseExtensionPayload | null => {
  if (coursesExtensions && removeCourses) {
    const remainingCourses = coursesExtensions.filter(
      extension => removeCourses.findIndex(course => course.id === extension.id) < 0
    );

    return preparePayloadForExtension(remainingCourses) as TCourseExtensionPayload;
  }
  return null;
};
