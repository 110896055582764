/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, ReactNode } from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme, PaletteOptions } from '@mui/material';
import { ThemeProvider as LiaisonThemeProvider } from '@liaison/liaison-ui';
import { useSelector } from 'react-redux';
import { selectTenantConfig } from 'store/common/commonInfo.selectors';
import overrideComponents from './theme/overrideComponents';

type TThemeProps = {
  children: ReactNode;
};

function ThemeProvider({ children }: TThemeProps): ReactElement {
  const tenantConfig = useSelector(selectTenantConfig);
  return (
    <LiaisonThemeProvider customPalette={tenantConfig?.palette as PaletteOptions}>
      <MuiThemeProvider theme={outerTheme => overrideComponents(createTheme(outerTheme))}>{children}</MuiThemeProvider>
    </LiaisonThemeProvider>
  );
}

export default ThemeProvider;
