/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { forwardRef, ReactElement } from 'react';
import en from 'react-phone-number-input/locale/en';

import { Input } from '@liaison/liaison-ui';

import { StyledPhoneInput } from './PhoneField.styles';

export interface IPhoneFieldProps {
  id: string;
  name: string;
  value: string;
  onChange: (value: string) => void;
  label: string;
  limitMaxLength?: boolean;
  placeholder: string;
}

const InputComponent = forwardRef((props, ref) => {
  return <Input {...props} inputRef={ref} fullWidth />;
});

const PhoneField = forwardRef(
  ({ label, ...rest }: IPhoneFieldProps, ref): ReactElement => (
    <StyledPhoneInput
      {...rest}
      ref={ref as unknown as undefined}
      labels={{ ...en, country: `${label} select country` }}
      international
      countryCallingCodeEditable={false}
      defaultCountry="US"
      inputComponent={InputComponent}
    />
  )
);

export default PhoneField;
