/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */
import { IFeature } from 'store/features/features.slice';
import { isFeatureActive } from 'utils/features.utils';
import {
  PERSONAL_INFORMATION,
  BIOGRAPHICAL_INFORMATION,
  ACADEMIC_GOALS,
  CAREER_GOALS,
  ACCOMPLISHMENT_EXPERIENCE,
  ACADEMIC_HISTORY,
  MEDIA_DOCUMENTS,
} from 'userProfile/constants/routeNames';

export const ProfileMenus = [
  {
    label: 'Personal Information',
    icon: 'IconUserProfile',
    location: PERSONAL_INFORMATION.path,
  },
  {
    label: 'Biographical Information',
    icon: 'IconAcademics',
    location: BIOGRAPHICAL_INFORMATION.path,
  },
  {
    label: 'Career Goals',
    icon: 'IconMap',
    location: CAREER_GOALS.path,
  },
  {
    label: 'Academic Goals',
    icon: 'IconSchool',
    location: ACADEMIC_GOALS.path,
  },
  {
    label: 'Academic History',
    icon: 'IconDocument',
    location: ACADEMIC_HISTORY.path,
  },
  {
    label: 'Achievements & Experiences',
    icon: 'IconAward',
    location: ACCOMPLISHMENT_EXPERIENCE.path,
  },
  {
    label: 'Media & Documents',
    icon: 'IconDocument',
    location: MEDIA_DOCUMENTS.path,
  },
];

export const getProfileMenus = (features: IFeature[] | null): typeof ProfileMenus => [
  ...(isFeatureActive(features, PERSONAL_INFORMATION.featureName)
    ? [
        {
          label: 'Personal Information',
          icon: 'IconUserProfile',
          location: PERSONAL_INFORMATION.path,
        },
      ]
    : []),
  ...(isFeatureActive(features, BIOGRAPHICAL_INFORMATION.featureName)
    ? [
        {
          label: 'Biographical Information',
          icon: 'IconAcademics',
          location: BIOGRAPHICAL_INFORMATION.path,
        },
      ]
    : []),
  ...(isFeatureActive(features, CAREER_GOALS.featureName)
    ? [
        {
          label: 'Career Goals',
          icon: 'IconMap',
          location: CAREER_GOALS.path,
        },
      ]
    : []),
  ...(isFeatureActive(features, ACADEMIC_GOALS.featureName)
    ? [
        {
          label: 'Academic Goals',
          icon: 'IconSchool',
          location: ACADEMIC_GOALS.path,
        },
      ]
    : []),
  ...(isFeatureActive(features, ACADEMIC_HISTORY.featureName)
    ? [
        {
          label: 'Academic History',
          icon: 'IconDocument',
          location: ACADEMIC_HISTORY.path,
        },
      ]
    : []),
  ...(isFeatureActive(features, ACCOMPLISHMENT_EXPERIENCE.featureName)
    ? [
        {
          label: 'Achievements & Experiences',
          icon: 'IconAward',
          location: ACCOMPLISHMENT_EXPERIENCE.path,
        },
      ]
    : []),
  ...(isFeatureActive(features, MEDIA_DOCUMENTS.featureName)
    ? [
        {
          label: 'Media & Documents',
          icon: 'IconFiles',
          location: MEDIA_DOCUMENTS.path,
        },
      ]
    : []),
];
