/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { memo, ReactElement } from 'react';
import { Grid, Box, Typography, useMediaQuery, Theme } from '@mui/material';
import { CustomChip } from '@liaison/liaison-ui';
import { nameSpace } from 'transferPlanner/constants/general';
import { useTranslation } from 'react-i18next';
import { ICourseDetailsProps, generateCourseOrTestPanelDetails, ICourseSidePanel } from '../GeneralEducation.utils';

const CourseDetails = ({ data }: ICourseDetailsProps): ReactElement => {
  const { title, courseOrTestData }: ICourseSidePanel = generateCourseOrTestPanelDetails(data);
  const { t } = useTranslation(nameSpace);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const getChipUtilityClass = (status: string): ReactElement => {
    if (status === 'COMPLETED') {
      return (
        <CustomChip
          sx={{ border: '1px solid #E3F2FD' }}
          icon="check"
          label={t('completed_label')}
          variant="square"
          txtColor="#383D45"
          bgColor="#CDDC39"
        />
      );
    }
    return (
      <CustomChip
        sx={{ border: '1px solid #E3F2FD', fontSize: isMobile ? '0.7rem' : 'inherit' }}
        icon="halfCircle"
        label={t('inprogress_label')}
        variant="square"
        txtColor="#383D45"
        bgColor="#E3F2FD"
      />
    );
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Typography variant="subtitle5">{title}</Typography>
      </Box>
      <Grid container>
        {courseOrTestData &&
          courseOrTestData.map(({ label, value }) => {
            return (
              <Grid container direction="row" key={label}>
                <Grid item md={6} sm={6} xs={6} sx={{ mt: '1rem' }}>
                  <Typography component="span" variant="subtitle4">
                    {label}:
                  </Typography>
                </Grid>
                <Grid item md={6} sm={6} xs={6} sx={{ mt: '1rem' }}>
                  <Typography component="span" variant="body2">
                    {data?.course?.title && label === 'Status' ? getChipUtilityClass(value) : value}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default memo(CourseDetails);
