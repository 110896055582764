/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { TAppThunk } from 'redux/store';
import { t } from 'i18next';
import axios from 'axios';

import { API } from 'constants/api';
import { featuresStart, featuresSuccess, featuresFailure, IFeature } from 'store/features/features.slice';

export const getFeatures = (): TAppThunk => async dispatch => {
  try {
    dispatch(featuresStart());
    const { data } = await axios.get(API?.features);
    dispatch(featuresSuccess(data));
  } catch (err) {
    dispatch(featuresFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  }
};

export type TFeatureObject = {
  feature: {
    featureName: string;
    path: string;
  };
  element: JSX.Element;
};

export type TRouteObject = {
  path: string;
  element: JSX.Element;
};

export const isFeatureActive = (features: IFeature[] | null, featureName: string): boolean => {
  const featureInList = features?.find(feature => feature.name?.toLowerCase().includes(featureName.toLowerCase()));

  return !featureInList;
};

export const buildFeatureRouteObject = (
  features: IFeature[] | null,
  featureRouteObject: TFeatureObject
): TRouteObject[] | [] =>
  isFeatureActive(features, featureRouteObject.feature.featureName)
    ? [{ path: featureRouteObject.feature.path, element: featureRouteObject.element }]
    : [];
