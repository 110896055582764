/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import React, { type DragEvent, type ReactElement } from 'react';
import type { TFunction } from 'react-i18next';
import { Controller, Control, type FieldValues } from 'react-hook-form';
import {
  Box,
  IconButton,
  Grid,
  Paper,
  Switch,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from '@mui/material';
import { IconArrowUp, IconArrowDown } from '@liaison/liaison-ui';
import merge from 'lodash.merge';

import { textEllipsis, wrapText } from 'userProfile/components/ViewBuilder/ViewBuilder.styles';
import { tableStyles } from 'pages/Pages.styles';
import { formatDateToMonthAndYear } from 'utils/commonUtils';
import { getThumbUrl } from 'userProfile/pages/MediaDocuments/MediaDocuments.utils';
import type { IMediaDoc } from 'userProfile/store/mediaDocuments/mediaDocuments.slice';
import type { TProfileView, TProfileViewSections } from 'userProfile/store/profileView/profileView.slice';

export const DETAILS = 'DETAILS';
export const VISIBILITY = 'VISIBILITY';

export type TSettingsError = {
  bio: { message: string };
};

export const contactSubsections = ['emails', 'phones', 'addresses'] as const;

export const swapDocs = ({
  profileView,
  formData,
  sourceDocumentIndex,
  sourceDocumentNewIndex,
  setIsOrderChanged,
}: {
  profileView: TProfileView;
  formData: FieldValues;
  sourceDocumentIndex: number;
  sourceDocumentNewIndex: number;
  setIsOrderChanged: (value: React.SetStateAction<boolean>) => void;
}): TProfileView => {
  const newProfileView = JSON.parse(JSON.stringify(profileView)) as TProfileView;
  const newDocuments = newProfileView.mediaAndDocuments.data;
  [newDocuments[sourceDocumentIndex], newDocuments[sourceDocumentNewIndex]] = [
    newDocuments[sourceDocumentNewIndex],
    newDocuments[sourceDocumentIndex],
  ];
  newDocuments[sourceDocumentIndex].order = sourceDocumentIndex;
  newDocuments[sourceDocumentNewIndex].order = sourceDocumentNewIndex;
  newProfileView.mediaAndDocuments.data = newDocuments;
  setIsOrderChanged(true);

  return merge({}, profileView, formData, newProfileView);
};

type TChangeOrder<T1, T2> = (value: T1, param: T2) => () => void;
type TChangeSectionOrder = TChangeOrder<1 | -1, string>;
type TChangeDocumentOrder = TChangeOrder<1 | -1, number>;

export const makeArrows = (
  changeOrder: TChangeSectionOrder | TChangeDocumentOrder,
  sectionName: string,
  order: number | null = null
): ReactElement => {
  const param = (order ?? sectionName) as never;
  return (
    <Grid item xs="auto" container>
      <Grid item xs={12} container alignItems="flex-end">
        <IconButton sx={{ p: 0 }} aria-label={`up ${sectionName}`} onClick={changeOrder(-1, param)}>
          <IconArrowUp style={{ fontSize: 11 }} />
        </IconButton>
      </Grid>
      <Grid item xs={12} container alignItems="flex-start">
        <IconButton sx={{ p: 0 }} aria-label={`down ${sectionName}`} onClick={changeOrder(1, param)}>
          <IconArrowDown style={{ fontSize: 11 }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export const buildMediaAndDocumentsRow = (
  mediaDoc: IMediaDoc,
  index: number,
  control: Control<FieldValues, unknown>,
  prefix: string,
  checkParent: (section: string) => void,
  isMobile: boolean,
  changeDocumentOrder: TChangeDocumentOrder,
  handleOnDocDrop: (e: DragEvent, targetDocumentOrder: number) => void
): ReactElement => {
  /* istanbul ignore next */
  const { variants, id, order, name = '', sizeDisplay = '', mediaType = '' } = mediaDoc;
  return (
    <TableRow
      key={id}
      draggable
      onDragStart={e => {
        /* istanbul ignore next */
        e.dataTransfer.setData('sourceDocumentOrder', JSON.stringify(order));
      }}
      onDrop={e => {
        e.stopPropagation();
        handleOnDocDrop(e, order as number);
      }}
      onDragOver={e => {
        e.preventDefault();
      }}
      data-testid={`doc-row-${order}`}
    >
      <TableCell align="left">
        <Grid container columnSpacing={2} alignItems="center">
          {makeArrows(changeDocumentOrder, name, order)}
          <Grid item xs="auto">
            <Box
              component="img"
              src={getThumbUrl(variants)}
              alt={`${name} picture`}
              sx={{
                height: { xs: 45, sm: 60, md: 70 },
                width: { xs: 45, sm: 60, md: 70 },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            />
          </Grid>
          <Grid item container xs>
            <Grid item xs={12}>
              <Typography
                sx={{
                  wordBreak: 'break-word',
                  typography: { xs: 'body3', sm: 'body2' },
                  ...(isMobile ? { ...wrapText, ...textEllipsis } : {}),
                }}
              >
                {name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" color="text.secondary" textTransform="uppercase">
                {`${sizeDisplay} ${`${sizeDisplay && mediaType && '•'}`} ${mediaType}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="right">
        <Controller
          control={control}
          name={`${prefix}.data.${index}.hidden`}
          defaultValue={true}
          render={({ field: { onChange, value, ...field } }) => {
            return (
              <Switch
                {...field}
                checked={!value}
                onChange={(_e, val) => {
                  setTimeout(() => {
                    checkParent(prefix);
                  }, 10);
                  return onChange(!val);
                }}
                inputProps={{
                  'aria-label': `hide ${name}`,
                }}
                color="primary"
              />
            );
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export const buildPersonalInformationRow = (
  displayName: string,
  name: 'emails' | 'addresses' | 'phones' | undefined,
  section: 'contact' | 'socialMedia',
  profileView: TProfileView | null,
  control: Control<FieldValues, unknown>,
  setValue: (name: string, value: unknown) => void,
  checkParent: (section: string) => void
): ReactElement => {
  const linkedInIndex =
    section === 'socialMedia'
      ? profileView?.personalInformation?.data?.[section]?.findIndex(item => item.type.displayName === displayName) ??
        -1
      : -1;

  let controllerName = '';
  if (section === 'contact' && name && name in (profileView?.personalInformation?.data?.[section] ?? {})) {
    controllerName = `personalInformation.data.${section}.${name}.hidden`;
  } else if (linkedInIndex !== -1) {
    controllerName = `personalInformation.data.${section}.${linkedInIndex}.hidden`;
  }

  return (
    <TableRow key={name}>
      <TableCell align="left">
        <Typography sx={{ ml: 2 }} variant="body2">
          {displayName}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Controller
          control={control}
          name={controllerName}
          defaultValue={undefined}
          render={({ field: { onChange, value, ...field } }) => {
            return (
              <Switch
                {...field}
                checked={value !== undefined ? !value : false}
                onChange={(_e, val) => {
                  setTimeout(() => {
                    checkParent('personalInformation');
                  }, 10);
                  if (section === 'contact') {
                    profileView?.personalInformation?.data?.[section]?.[
                      name as 'emails' | 'addresses' | 'phones'
                    ]?.forEach((_item, i) => {
                      setValue(`personalInformation.data.${section}.${name}.${i}.hidden`, !val);
                    });
                  }
                  if (section === 'socialMedia') {
                    if (linkedInIndex !== -1) {
                      setValue(`personalInformation.data.${section}.${linkedInIndex}.hidden`, !val);
                    }
                  }
                  return onChange(!val);
                }}
                inputProps={{
                  'aria-label': `hide ${displayName}`,
                }}
                color="primary"
                disabled={
                  section === 'socialMedia'
                    ? displayName === 'LinkedIn' && linkedInIndex === -1
                    : !(name && name in (profileView?.personalInformation?.data?.[section] ?? {}))
                }
              />
            );
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export const buildPersonalInformationSection = (
  title: string,
  name: string,
  profileView: TProfileView | null,
  control: Control<FieldValues, unknown>,
  setValue: (name: string, value: unknown) => void,
  checkSection: (section: string, value: boolean) => void,
  checkParent: (section: string) => void
): ReactElement => {
  return (
    <TableContainer component={Paper}>
      <Table
        aria-label="table header"
        sx={{
          ...tableStyles.font,
          borderCollapse: 'unset',
        }}
        tabIndex={0}
      >
        <TableHead>
          <TableRow sx={tableStyles.header}>
            <TableCell align="left">
              <Typography variant="subtitle4">{title}</Typography>
            </TableCell>
            <TableCell align="right">
              <Controller
                control={control}
                name={`${name}.hidden`}
                defaultValue={true}
                render={({ field: { onChange, value, ...field } }) => {
                  return (
                    <Switch
                      {...field}
                      checked={!value}
                      onChange={(_e, val) => {
                        checkSection?.(name, val);
                        return onChange(!val);
                      }}
                      inputProps={{
                        'aria-label': `hide ${name}`,
                      }}
                      color="primary"
                    />
                  );
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            [`& .${tableCellClasses.root}`]: {
              py: 1,
            },
          }}
        >
          {buildPersonalInformationRow('Address', 'addresses', 'contact', profileView, control, setValue, checkParent)}
          {buildPersonalInformationRow('Email', 'emails', 'contact', profileView, control, setValue, checkParent)}
          {buildPersonalInformationRow('Phone', 'phones', 'contact', profileView, control, setValue, checkParent)}
          {buildPersonalInformationRow(
            'LinkedIn',
            undefined,
            'socialMedia',
            profileView,
            control,
            setValue,
            checkParent
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const buildRow = (
  displayName: string,
  index: number,
  control: Control<FieldValues, unknown>,
  prefix: string,
  checkParent?: (section: string) => void,
  checkAcademicHistory?: () => void,
  testDate?: string,
  testStatus?: string,
  t?: TFunction<'lp', undefined>
): ReactElement => (
  <TableRow key={displayName}>
    <TableCell align="left">
      <Typography sx={{ ml: 2, wordBreak: 'break-word' }} variant="body2">
        •&nbsp;&nbsp;&nbsp;{displayName}
      </Typography>
      {testDate && (
        <Typography variant="caption" component="div" sx={{ ml: 4.2 }}>
          {t?.(testStatus === 'PLANNED' ? 'tests.beTakenOn' : 'tests.takenOn')} {formatDateToMonthAndYear(testDate)}
        </Typography>
      )}
    </TableCell>
    <TableCell align="right">
      <Controller
        control={control}
        name={`${prefix}.data.${index}.hidden`}
        defaultValue={true}
        render={({ field: { onChange, value, ...field } }) => {
          return (
            <Switch
              {...field}
              checked={!value}
              onChange={(_e, val) => {
                setTimeout(() => {
                  checkParent?.(prefix);
                  if (prefix.includes('academicHistory')) {
                    checkAcademicHistory?.();
                  }
                }, 10);
                return onChange(!val);
              }}
              inputProps={{
                'aria-label': `hide ${displayName}`,
              }}
              color="primary"
            />
          );
        }}
      />
    </TableCell>
  </TableRow>
);

type TBuildSectionArgs = {
  title: string;
  name: TProfileViewSections;
  control: Control<FieldValues, unknown>;
  order: number;
  changeOrder: (value: 1 | -1, sectionName: string) => () => void;
  handleOnDrop: (e: DragEvent, targetSectionName: string, targetSectionOrder: number) => void;
  profileView?: TProfileView | null;
  checkSection?: (section: string, value: boolean) => void;
  checkParent?: (section: string) => void;
};

export const buildSection = ({
  title,
  name,
  control,
  order,
  changeOrder,
  handleOnDrop,
  profileView,
  checkParent,
  checkSection = () => {},
}: TBuildSectionArgs): ReactElement => (
  <TableContainer component={Paper}>
    <Table
      aria-label="table header"
      sx={{
        ...tableStyles.font,
        borderCollapse: 'unset',
        '&:hover': { cursor: 'move' },
      }}
      tabIndex={0}
      draggable
      onDragStart={e => {
        e.dataTransfer.setData('item', JSON.stringify({ sourceSectionName: name, sourceSectionOrder: order }));
      }}
      onDrop={e => handleOnDrop(e, name, order)}
      onDragOver={e => {
        e.preventDefault();
      }}
    >
      <TableHead>
        <TableRow sx={tableStyles.header}>
          <TableCell align="left">
            <Grid container>
              {makeArrows(changeOrder, name)}
              <Grid item xs container alignItems="center">
                <Typography variant="subtitle4">{title}</Typography>
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="right">
            <Controller
              control={control}
              name={`${name}.hidden`}
              defaultValue={true}
              render={({ field: { onChange, value, ...field } }) => {
                return (
                  <Switch
                    {...field}
                    checked={!value}
                    onChange={(_e, val) => {
                      checkSection?.(name, val);
                      return onChange(!val);
                    }}
                    inputProps={{
                      'aria-label': `hide ${name}`,
                    }}
                    color="primary"
                  />
                );
              }}
            />
          </TableCell>
        </TableRow>
      </TableHead>
      {profileView && (
        <TableBody
          sx={{
            [`& .${tableCellClasses.root}`]: {
              py: 1,
            },
          }}
        >
          {profileView?.[name as 'experiences' | 'achievements']?.data.map((item, i) =>
            buildRow(item.name, i, control, name, checkParent)
          )}
        </TableBody>
      )}
    </Table>
  </TableContainer>
);
