/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { IconCheckCircle, IconAlertCritical } from '@liaison/liaison-ui';

const StyledInfoBox = styled('div')(({ theme: { palette } }) => ({
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  backgroundColor: 'white',
  color: palette.primary.main,
  border: '1px solid #642F6C',
  borderRadius: '4px',
  padding: '1rem',
  paddingTop: '0.5rem',
  alignItems: 'flex-start',
}));

interface IResultBoxProps {
  bgcolor: string;
  borderColor: string;
  icon: ReactElement;
  mainText: string;
}

export const ResultBox = ({ bgcolor, borderColor, icon, mainText }: IResultBoxProps): ReactElement => (
  <Box
    sx={{
      bgcolor,
      border: '1px solid',
      borderRadius: '4px',
      borderColor,
      display: 'flex',
      justifyContent: { md: 'center', lg: 'flex-start' },
      alignItems: 'center',
      mt: { xs: '1rem', md: 0 },
      py: '0.5rem',
      px: { xs: '0.2rem', md: '0.5rem' },
    }}
  >
    <Box
      sx={{
        mt: '0.5rem',
        mx: { xs: '0.25rem', md: '1rem' },
        justifyContent: 'center',
      }}
    >
      {icon}
    </Box>
    <Box>
      <Typography
        component="h2"
        sx={{
          typography: { xs: 'subtitle4', sm: 'subtitle2' },
        }}
      >
        {mainText}
      </Typography>
    </Box>
  </Box>
);

interface IInfoBoxProps {
  text: string;
  buttonLabel: string;
  onLinkClick?: () => void;
}

export const InfoBox = ({ text, buttonLabel, onLinkClick }: IInfoBoxProps): ReactElement => (
  <StyledInfoBox
    sx={{
      height: { xs: 'auto', md: '88px' },
      paddingRight: { xs: '0.3rem', md: '1rem' },
    }}
  >
    <Typography
      variant="body3"
      component="p"
      color="text.primary"
      sx={{
        margin: '5px 0px 5px 5px',
      }}
    >
      {text}
    </Typography>
    <Button onClick={onLinkClick} color="error" variant="text" sx={{ ml: '0.3rem', textTransform: 'inherit' }}>
      {buttonLabel}
    </Button>
  </StyledInfoBox>
);

export const eligibleIcon = <IconCheckCircle style={{ color: '#144618' }} fontSize="large" />;

export const ineligibleIcon = <IconAlertCritical style={{ color: '#891515' }} fontSize="large" />;
