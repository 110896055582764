/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { t } from 'i18next';
import axios from 'axios';
import {
  programCartStart,
  programCartSuccess,
  programCartFailure,
  followedProgramsStart,
  followedProgramsFailure,
  followedProgramsSuccess,
  IProgramsOfInterest,
  IProgram,
  TFollowedProgramModified,
  IFollowedProgramsResponse,
  followedActualProgramsSuccess,
  ISelectedProgram,
  IProgramCartResponse,
} from 'transferPlanner/store/programCart/programCart.slice';
import { TAppThunk } from 'redux/store';
import { API } from 'transferPlanner/constants/api';
import { getLoggedUser } from 'utils/keyCloakUtils';
import { TProgramWithPosition } from 'transferPlanner/pages/CampusesAndPrograms/Programs/ProgramCard/ProgramCard.styles';
import { TFetchProgramDetailsResponse } from 'transferPlanner/pages/CampusesAndPrograms/Programs/ProgramCardsLazyScroll/ProgramCardsLazyScroll.utils';
import { ITspEnrollment } from 'transferPlanner/store/enrollment/enrollment.slice';
import { ITspEligibility } from 'transferPlanner/store/eligibility/eligibility.slice';
import {
  tspEligibleFlagsFailure,
  tspEligibleFlagsStart,
  tspEligibleFlagsSuccess,
} from 'transferPlanner/store/programs/programs.slice';

export const INELIGIBLE_CODE = 'INELIGIBLE';
export const REQUEST_RELEASE_CODE = 'REQUEST_RELEASE';
export const REVOKED_CODE = 'REVOKED';
export const NOT_ELIGIBLE_CODE = 'NOT_ELIGIBLE';
export const ENROLLMENT_ERROR_CODE = 'ENROLLMENT_ERROR';

export const getProgramCart = (): TAppThunk => async dispatch => {
  try {
    const loggedUserId = getLoggedUser();
    dispatch(programCartStart());
    const { data } = await axios.get(`${API.programCart}/${loggedUserId}`);
    dispatch(programCartSuccess(data));
  } catch (err) {
    dispatch(programCartFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  }
};

export const formatFollowedPrograms = (
  followedPrograms: IFollowedProgramsResponse
): TFollowedProgramModified[] | null => {
  let programsList = [] as TFollowedProgramModified[];
  if (followedPrograms?.programsOfInterest && followedPrograms.programsOfInterest.length) {
    followedPrograms?.programsOfInterest?.map((campusDetail: IProgramsOfInterest) => {
      const { campus, programs } = campusDetail;
      programsList = [
        ...programsList,
        ...programs.map((program: IProgram) => {
          return {
            program,
            campus,
          };
        }),
      ];
      return programsList;
    });
    return programsList?.sort((firstElement, secondElement) =>
      firstElement.program.name.localeCompare(secondElement.program.name)
    );
  }
  return null;
};

export const getFollowedPrograms =
  (successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      const loggedUserId = getLoggedUser();
      dispatch(followedProgramsStart());
      const { data } = await axios.get(`${API.getFollowedPrograms}/${loggedUserId}`);
      dispatch(followedActualProgramsSuccess(data));
      dispatch(followedProgramsSuccess(formatFollowedPrograms(data)));
      successCallback?.();
    } catch (err) {
      dispatch(followedProgramsFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const postFollowedProgram =
  (data: TFollowedProgramModified, successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      const loggedUserId = getLoggedUser();
      const { data: response } = await axios.post(`${API.getFollowedPrograms}/${loggedUserId}/follow`, data);
      dispatch(followedActualProgramsSuccess(response));
      dispatch(followedProgramsSuccess(formatFollowedPrograms(response)));
      successCallback?.();
    } catch (err) {
      dispatch(followedProgramsFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const postUnFollowProgram =
  (unfollowProgram: TFollowedProgramModified, successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      const req = {
        program: {
          id: unfollowProgram.program.id,
          name: unfollowProgram.program.name,
        },
        campus: { ...unfollowProgram.campus },
      };
      const loggedUserId = getLoggedUser();
      const { data } = await axios.post(`${API.getFollowedPrograms}/${loggedUserId}/unfollow`, req);
      dispatch(followedActualProgramsSuccess(data));
      dispatch(followedProgramsSuccess(formatFollowedPrograms(data)));
      successCallback?.();
    } catch (err) {
      dispatch(followedProgramsFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

type TProgramUnion = TProgramWithPosition | TFetchProgramDetailsResponse;

export const prepareFollowProgramPayload = (
  programData: TProgramUnion,
  isFromModal?: boolean
): TFollowedProgramModified => {
  return {
    program: {
      id: programData?.id,
      name: programData?.name,
      adtSimilar: (programData as TProgramWithPosition)?.adtSimilar,
      moKey: programData?.moKey,
    },
    campus: {
      id: isFromModal
        ? (programData as TFetchProgramDetailsResponse)?.campus?.id
        : (programData as TProgramWithPosition)?.campusId,
      alternateId: {
        code: isFromModal
          ? (programData as TFetchProgramDetailsResponse)?.campus?.alternateId
          : (programData as TProgramWithPosition)?.campusAlternateId,
        source: 'Assist',
      },
      name: isFromModal
        ? (programData as TFetchProgramDetailsResponse)?.campus?.name
        : (programData as TProgramWithPosition)?.campusName,
    },
  };
};

export const getEnrolledProgram = (
  tspEnrollment: ITspEnrollment[],
  selectedPrograms: ISelectedProgram[] | TFollowedProgramModified[]
): ISelectedProgram | TFollowedProgramModified | void => {
  const enrolledProgram = selectedPrograms?.find(selected => {
    return tspEnrollment?.some(
      enroll =>
        enroll.program.id === selected.program.id && enroll.enrollmentStatus?.status.code === REQUEST_RELEASE_CODE
    );
  });
  return enrolledProgram;
};

/* istanbul ignore next */
export const checkIsAllButtonDisabled = (
  tspEnrollment: ITspEnrollment[] = [],
  tspEligibility: ITspEligibility[] | null,
  selectedPrograms: ISelectedProgram[],
  followedProgram?: TFollowedProgramModified[]
): boolean => {
  if (
    tspEnrollment?.some(enroll => enroll.active) ||
    tspEnrollment?.some(enroll => enroll.enrollmentStatus?.status.code === REQUEST_RELEASE_CODE) ||
    !tspEligibility ||
    tspEligibility[0]?.status.code === INELIGIBLE_CODE
  ) {
    return true;
  }

  let disableSelected = false;
  let disableFollowed = false;
  selectedPrograms?.forEach(selected => {
    const programEnrollmentStatus = tspEnrollment?.find(
      enroll => enroll.program.id === selected.program.id
    )?.enrollmentStatus;

    if (
      programEnrollmentStatus?.status?.code === REVOKED_CODE &&
      programEnrollmentStatus?.reason?.code === NOT_ELIGIBLE_CODE
    ) {
      disableSelected = true;
    }
  });
  followedProgram?.forEach(selected => {
    const programEnrollmentStatus = tspEnrollment?.find(
      enroll => enroll.program.id === selected.program.id
    )?.enrollmentStatus;

    if (
      programEnrollmentStatus?.status?.code === REVOKED_CODE &&
      programEnrollmentStatus?.reason?.code === NOT_ELIGIBLE_CODE
    ) {
      disableFollowed = true;
    }
  });

  return disableSelected || disableFollowed;
};

/* istanbul ignore next */
export const fetchTspEligibleFlags = async (queryParams = ''): Promise<IProgram[]> => {
  try {
    const { data } = await axios.get(`${API.programCartDetails}?ids=${queryParams}`);
    return data;
  } catch {
    return [];
  }
};

export const postProgramCart =
  (selectedPrograms: ISelectedProgram[], onSuccess: () => void): TAppThunk =>
  async dispatch => {
    try {
      const loggedUserId = getLoggedUser();
      const payload: IProgramCartResponse = {
        profileId: loggedUserId,
        selectedPrograms,
      };
      dispatch(programCartStart());
      const { data } = await axios.post(`${API.programCart}/${loggedUserId}`, payload);
      dispatch(programCartSuccess(data));
      dispatch(
        getFollowedPrograms(() => {
          onSuccess();
        })
      );
    } catch (err) {
      dispatch(programCartFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const findUnFollowedProgramIndex = (
  currentProgramId: number,
  followedPrograms: TFollowedProgramModified[] | null
): number => {
  return (followedPrograms || [])?.findIndex(
    ({ program }) => program.tracking?.active && program.id === currentProgramId
  );
};

export const getTspEligibleFlags =
  (queryParams = '', successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(tspEligibleFlagsStart());
      const { data } = await axios.get(`${API.programCartDetails}?ids=${queryParams}`);
      dispatch(tspEligibleFlagsSuccess(data));
      successCallback?.();
    } catch (err) {
      dispatch(tspEligibleFlagsFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };
