/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectAchievements } from 'userProfile/store/profileView/profileView.selectors';
import type { TAchievement } from 'userProfile/store/profileView/profileView.slice';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { displayDate, getButtonColor } from 'utils/utilities';
import { SidePanel, ToggleButton } from '@liaison/liaison-ui';
import { CardSection } from '../Common/CardSection';
import { DOCUMENTS, EXPERIENCE_DESCRIPTION } from '../LivingProfile.utils';
import { Documents } from '../Common/Documents';

export const Achievements = (): ReactElement => {
  const achievements = useSelector(selectAchievements);
  const { t } = useTranslation();
  const title = t('livingProfile.sections.achievements');

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedAchievement, setSelectedAchievement] = useState<TAchievement | undefined>();
  const [selectedToggle, setSelectedToggle] = useState<string | undefined>(DOCUMENTS);

  const toggleDrawer = (achievement?: TAchievement): void => {
    setIsDrawerOpen(!isDrawerOpen);
    setSelectedAchievement(achievement);
  };

  const onChangeToggle = (value: string) => {
    setSelectedToggle(value);
  };

  const sidePanelContent = (
    <>
      <Card variant="outlined" sx={{ borderRadius: '0.5rem' }}>
        <CardContent sx={{ position: 'relative' }}>
          <Stack direction="row">
            <Stack spacing={0.5} padding={0.5}>
              <Typography variant="subtitle3" sx={{ wordBreak: 'break-all' }}>
                {selectedAchievement?.name}
              </Typography>
              <Typography variant="caption" sx={{ wordBreak: 'break-all' }}>
                {selectedAchievement?.source}
              </Typography>
              <Typography variant="caption" color="black" sx={{ wordBreak: 'break-all' }}>
                {displayDate(selectedAchievement?.receivedDate as string)}
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>

      <Box sx={{ mx: -2, my: 3 }}>
        <ToggleButton
          options={[
            {
              text: t('livingProfile.toggle.mediaDocs'),
              val: DOCUMENTS,
            },
            {
              text: t('livingProfile.toggle.description'),
              val: EXPERIENCE_DESCRIPTION,
            },
          ]}
          onChange={onChangeToggle as (value: string | number | boolean) => void}
          selectedValue={selectedToggle}
        />
      </Box>

      {selectedToggle === EXPERIENCE_DESCRIPTION && (
        <>
          <Typography sx={{ px: 0.5, pb: 2, wordWrap: 'break-word' }}>
            {' '}
            {selectedAchievement?.description || t('livingProfile.sections.experiences.noDescription')}
          </Typography>
        </>
      )}

      {selectedToggle === DOCUMENTS && (
        <>
          <Typography variant="h2" sx={{ px: 0.5, pb: 2 }}>
            {t('livingProfile.sections.mediaAndDocuments')}
          </Typography>
          <Documents attachments={selectedAchievement?.attachments} useSections={false} />
        </>
      )}
    </>
  );

  return (
    <>
      <CardSection title={title} items={achievements} toggleDrawer={toggleDrawer as () => void} />

      <SidePanel
        size="small"
        title={t('livingProfile.sections.achievements.title')}
        open={isDrawerOpen}
        onClose={() => toggleDrawer()}
        footerButtonConfig={{
          tertiary: {
            title: t('cancel_label'),
            props: {
              color: getButtonColor(),
              'aria-label': t('cancel_label'),
              onClick: () => toggleDrawer(),
            },
          },
        }}
      >
        {sidePanelContent}
      </SidePanel>
    </>
  );
};
