/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { useCallback, useEffect, useRef, useState, Dispatch, SetStateAction } from 'react';
import type { AnyAction, Dispatch as ReduxDispatch } from 'redux';
import type { TAppThunk } from 'redux/store';
import type { FieldValues, UseFormGetValues } from 'react-hook-form';

import type { IhighSchool, ICollegeCommon, ISchoolOfInterest } from 'store/common/commonInfo.slice';
import { resetlookupData } from 'store/common/commonInfo.slice';
import { ahLookupApiLimit } from 'constants/general';
import { autoCompleteManualyAdd, autoCompleteLoading } from 'utils/AcademicHistory.utils';

type TOption = {
  id: string;
  text: string;
};

type TItemAndOption = (IhighSchool | ICollegeCommon | ISchoolOfInterest) & TOption;

export type TuseOptionsScrollParams = {
  lookup: TItemAndOption[] | null;
  getOptions: <T>(options: T[]) => T[];
  getValues: UseFormGetValues<FieldValues>;
  dispatch: ReduxDispatch<AnyAction>;
  getLookUps: (
    endPoint: string,
    queryParams?: string,
    prefix?: string,
    controllerSignal?: {},
    successCallback?: () => void
  ) => TAppThunk;
  entity: string;
  allowManualAdd: boolean;
  searchKeyLength: number;
  compareFn:
    | ((
        a: IhighSchool | ICollegeCommon | ISchoolOfInterest,
        b: IhighSchool | ICollegeCommon | ISchoolOfInterest
      ) => number)
    | null;
};

type TuseOptionsScrollReturn = {
  filteredOptions: TOption[];
  setFilteredOptions: Dispatch<SetStateAction<TOption[]>>;
  showApiLoading: boolean;
  showAdd: boolean;
  setShowAdd: Dispatch<SetStateAction<boolean>>;
  handleScroll: (event: React.SyntheticEvent) => void;
  handleSearch: (searchParam: string, minSearchLength?: number) => void;
};

export const useOptionsScroll = ({
  lookup,
  getOptions,
  getValues,
  dispatch,
  getLookUps,
  entity,
  allowManualAdd,
  searchKeyLength = 2,
  compareFn = null,
}: TuseOptionsScrollParams): TuseOptionsScrollReturn => {
  const [filteredOptions, setFilteredOptions] = useState<{ id: string; text: string }[]>([]);
  const [stopScroll, setStopScroll] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [apiProcessed, setApiProcessed] = useState(false);
  const [page, setPage] = useState(-1);
  const [showApiLoading, setShowApiLoading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const abortController = useRef(null) as unknown as { current: AbortController };

  useEffect(() => {
    if (page === 0 && !lookup?.length) {
      setFilteredOptions([]);
    }
  }, [page, lookup]);

  /* istanbul ignore next */
  useEffect(() => {
    if (apiProcessed && searchKey.length > searchKeyLength && lookup) {
      setApiProcessed(false);
      setIsNextPageLoading(false);
      setShowApiLoading(false);
      if (lookup?.length) {
        const updatedLookupData = getOptions(lookup);
        filteredOptions.splice(filteredOptions.length - 1, 1);
        const combinedData = [...filteredOptions, ...updatedLookupData] as TItemAndOption[];
        const modifiedData = compareFn ? combinedData.sort(compareFn) : combinedData;
        if (updatedLookupData.length < ahLookupApiLimit) {
          if (allowManualAdd) {
            setFilteredOptions([...modifiedData, autoCompleteManualyAdd]);
          } else {
            setFilteredOptions([...modifiedData]);
          }
          setStopScroll(true);
        } else {
          setFilteredOptions(modifiedData);
          setStopScroll(false);
        }
      } else {
        const newOptions = filteredOptions.slice(0, -1);
        if (newOptions?.length) {
          if (allowManualAdd) {
            setFilteredOptions([...newOptions, autoCompleteManualyAdd]);
          } else {
            setFilteredOptions([...newOptions]);
          }
        } else {
          setFilteredOptions(newOptions);
        }
        setStopScroll(true);
      }
    }
  }, [lookup, searchKey, apiProcessed, filteredOptions, getOptions, allowManualAdd, searchKeyLength, compareFn]);

  /* istanbul ignore next */
  const getList = useCallback(
    (searchParam: string, pageNum?: number) => {
      if (!isNextPageLoading || pageNum) {
        if (pageNum !== -1) {
          setIsNextPageLoading(true);
          setFilteredOptions([...filteredOptions, autoCompleteLoading]);
        }
        const nextPage = pageNum ? pageNum + 1 : page + 1;
        setPage(nextPage);
        const formValues = getValues();
        if (!formValues?.country?.code) {
          setShowApiLoading(false);
          return;
        }
        abortController?.current?.abort();
        abortController.current = new AbortController();
        const controllerSignal = { signal: abortController.current.signal };
        const requestJson = {
          count: String(ahLookupApiLimit),
          countryCode: formValues?.country?.code,
          from: String(nextPage * ahLookupApiLimit),
          searchTerm: searchParam,
        };
        dispatch(
          getLookUps(entity, `?${new URLSearchParams(requestJson).toString()}`, '', controllerSignal, () => {
            setIsNextPageLoading(false);
            setApiProcessed(true);
          })
        );
      }
    },
    [isNextPageLoading, page, getValues, dispatch, getLookUps, entity, filteredOptions]
  );

  /* istanbul ignore next */
  const handleScroll = useCallback(
    (event: React.SyntheticEvent) => {
      if (!stopScroll) {
        const listboxNode = event.currentTarget;
        const position = listboxNode.scrollTop + listboxNode.clientHeight;
        if (listboxNode.scrollHeight - position <= 100) {
          getList(searchKey);
        }
      }
    },
    [getList, searchKey, stopScroll]
  );

  const handleSearch = (searchParam: string, minSearchLength = 2) => {
    setPage(-1);
    setFilteredOptions([]);
    setSearchKey(searchParam);
    if (searchParam?.length > minSearchLength) {
      setIsNextPageLoading(false);
      setStopScroll(false);
      setShowAdd(true);
      setShowApiLoading(true);
      getList(searchParam, -1);
    } else {
      dispatch(resetlookupData([entity]));
      setShowAdd(false);
    }
  };

  return { filteredOptions, setFilteredOptions, showApiLoading, showAdd, setShowAdd, handleScroll, handleSearch };
};
