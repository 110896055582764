/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { API } from 'constants/api';
import { t } from 'i18next';
import { TAppThunk } from 'redux/store';
import {
  alternateIdLinkingApiStart,
  alternateIdLinkingApiSuccess,
  alternateIdLinkingApiFailure,
  IAlternateIdLinking,
  IApplicationCycles,
} from 'store/common/commonInfo.slice';
import { getLoggedUser } from 'utils/keyCloakUtils';
import { setUi } from 'store/ui/ui.slice';
import axios from 'axios';

export const groupName = 'alternateIdLinking';

export const sxButton = {
  color: '#ffffff',
} as const;

export const getAlternateIdLinking =
  (email: string): TAppThunk =>
  async dispatch => {
    try {
      dispatch(alternateIdLinkingApiStart());
      const { data } = await axios.get(`${API?.alternateIdLinking}/${getLoggedUser()}/${email}`);
      dispatch(alternateIdLinkingApiSuccess(data));
    } catch (err) {
      dispatch(alternateIdLinkingApiFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const postAlternateIdLinking =
  (email: string, payload: IAlternateIdLinking, successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(alternateIdLinkingApiStart());
      const { data } = await axios.post(`${API?.alternateIdLinking}/${getLoggedUser()}/${email}`, payload);
      dispatch(alternateIdLinkingApiSuccess(data));
      successCallback?.();
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      dispatch(alternateIdLinkingApiFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const getUniqueCasApplications = (casNames: IApplicationCycles[]): (IApplicationCycles | undefined)[] =>
  Array.from(new Set(casNames.map(a => a.casName))).map(casName => casNames.find(a => a.casName === casName));
