/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  type Theme,
} from '@mui/material';
import { getButtonColor, trimHeader, extractYear } from 'utils/utilities';
import {
  CollapsibleSection as Accordion,
  Icon,
  IconFiles,
  SidePanel,
  ToggleButton,
  IconLightbulb,
  IconCancel,
} from '@liaison/liaison-ui';

import { ReactComponent as EmptyCoursesIcon } from 'assets/svgs/empty-courses-icon.svg';
import { StyledButton, sxCard, sxSidePanel, sxSidePanelMobile } from 'pages/Pages.styles';
import { formatDateToMonthAndYear } from 'utils/commonUtils';
import {
  selectProfileView,
  selectAcademicHistory,
  selectStandardizedTests,
} from 'userProfile/store/profileView/profileView.selectors';
import { displayTestData } from 'userProfile/components/ViewBuilder/Cards/TestCard.utils';
import type { TAcademicInstituition, TStandardizedTest } from 'userProfile/store/profileView/profileView.slice';
import type { Idegree, ITerm, TGradeLevel } from 'store/academicHistory/academicHistory.slice';

import { MultiValuedView } from 'userProfile/components/ViewBuilder/CardView';
import {
  sxButtonCompetenciesProfileView,
  sxButtonProfileView,
} from 'userProfile/components/AttachedFiles/AttachedFiles.styles';
import { Documents } from '../Common/Documents';
import { sxSectionsItem } from '../LivingProfile.styles';
import { COURSES, DOCUMENTS, DEGREES, formatKey, getAttachedCount } from '../LivingProfile.utils';
import { AcademicInstitutionCard } from './AcademicInstitutionCard';
import { StandardizedTestCard } from './StandardizedTestCard';
import { Degrees } from './Degrees';
import { CollegeCourses } from './CollegeCourses';
import { HighSchoolCourses } from './HighSchoolCourses';

export const AcademicHistory = (): ReactElement => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedToggle, setSelectedToggle] = useState<string | undefined>(DOCUMENTS);
  const [selectedInstitution, setSelectedInstitution] = useState<TAcademicInstituition>();
  const [isTestsDrawerOpen, setIsTestsDrawerOpen] = useState(false);
  const [selectedTest, setSelectedTest] = useState<TStandardizedTest>();
  const [showCompetencyView, setShowCompetencyView] = useState(false);
  const profileView = useSelector(selectProfileView);
  const academicHistory = useSelector(selectAcademicHistory);
  const academicHistoryToDisplay = useMemo(
    () => academicHistory?.filter(schoolAttended => !schoolAttended.hidden),
    [academicHistory]
  );
  const standardizedTests = useSelector(selectStandardizedTests);
  const standardizedTestsToDisplay = useMemo(
    () => standardizedTests?.filter(test => !test.hidden),
    [standardizedTests]
  );
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const { t } = useTranslation();
  const title = t('livingProfile.sections.academicHistory');
  const sxForMobileSidePanel = isMobile ? { ...sxSidePanel, ...sxSidePanelMobile } : sxSidePanel;
  const isSchoolsAttendedHidden = !!profileView?.academicHistory?.schoolAttended?.hidden;
  const isStandardizedTestsHidden = !!profileView?.academicHistory?.standardizedTests?.hidden;

  const addressResponse = selectedInstitution?.address;
  const address = `${
    addressResponse?.region?.displayName !== 'N/A' ? addressResponse?.region?.displayName : addressResponse?.city
  }, ${addressResponse?.country?.displayName}`;

  const toggleDrawer = (institution?: TAcademicInstituition, toggle?: string): void => {
    setIsDrawerOpen(!isDrawerOpen);
    setSelectedInstitution(institution);
    setSelectedToggle(toggle);
    setShowCompetencyView(false);
  };

  const toggleStandardizedTestsDrawer = (test?: TStandardizedTest): void => {
    setIsTestsDrawerOpen(!isTestsDrawerOpen);
    setSelectedTest(test);
  };

  const onChangeToggle = (value: string) => {
    setSelectedToggle(value);
  };

  const handleCompetencyButtonClick = () => {
    setShowCompetencyView(!showCompetencyView);
  };

  const competencyValues = selectedInstitution?.competencyAttachments?.map(attachment => attachment.competency) || [];

  return (
    <Box sx={{ ...sxSectionsItem, p: 3 }}>
      <Stack direction="column" spacing={isSchoolsAttendedHidden ? 0 : 2}>
        <Box>
          <Typography variant="h2" sx={{ pb: 3 }}>
            {title}
          </Typography>
          {!isSchoolsAttendedHidden && (
            <Grid container spacing={2}>
              {(academicHistoryToDisplay || []).map(institution => (
                <AcademicInstitutionCard key={institution.id} institution={institution} toggleDrawer={toggleDrawer} />
              ))}
            </Grid>
          )}
        </Box>

        {!isStandardizedTestsHidden && (
          <Accordion
            expanded
            title={t('livingProfile.sections.academicHistory.standardizedTests')}
            groupId="standardizedTests"
          >
            <Grid container spacing={2}>
              {(standardizedTestsToDisplay || [])
                .filter(standardizedTest => !standardizedTest.hidden)
                .map(test => (
                  <StandardizedTestCard key={test.id} test={test} toggleDrawer={toggleStandardizedTestsDrawer} />
                ))}
            </Grid>
          </Accordion>
        )}
      </Stack>

      <SidePanel
        size="small"
        title={selectedInstitution?.name as string}
        open={isDrawerOpen}
        onClose={() => toggleDrawer()}
        isBackdropClickEnabled={true}
        sx={sxForMobileSidePanel}
        footerButtonConfig={{
          tertiary: {
            title: t('close_label'),
            props: {
              color: getButtonColor(),
              'aria-label': t('close_label'),
              onClick: () => toggleDrawer(),
            },
          },
        }}
      >
        <Card variant="outlined" sx={{ borderRadius: '0.5rem' }}>
          <CardContent sx={{ position: 'relative' }}>
            <Stack direction="row" alignItems="center">
              <Box>
                <IconButton sx={{ p: 0.9 }} size="extra-large" aria-label="IconCollege">
                  <Icon sx={{ color: theme => theme.palette.grey[500] }}>IconSchool</Icon>
                </IconButton>
              </Box>
              <Stack spacing={0.5} padding={0.5}>
                <Typography variant="subtitle3">{trimHeader(selectedInstitution?.name || '')}</Typography>
                <Typography variant="caption">{address}</Typography>
                <Typography variant="caption" color="black">
                  {selectedInstitution?.startDate && `${extractYear(selectedInstitution.startDate)}`}
                  {selectedInstitution?.endDate
                    ? ` - ${extractYear(selectedInstitution?.endDate)}`
                    : ` - ${t('livingProfile.present')}`}
                </Typography>
              </Stack>
              <Box sx={{ flexGrow: 1 }} />
              {!!selectedInstitution?.attachedCount && (
                <StyledButton
                  variant="contained"
                  startIcon={<IconFiles />}
                  size="medium"
                  fullWidth
                  aria-label="attachments"
                  sx={{ ...sxButtonProfileView, width: '50px', marginLeft: '10px' }}
                  onClick={() => setSelectedToggle(DOCUMENTS)}
                >
                  {getAttachedCount(selectedInstitution?.attachedCount)}
                </StyledButton>
              )}
              {!!selectedInstitution?.competencyAttachedCount && (
                <StyledButton
                  variant="contained"
                  startIcon={showCompetencyView ? <IconCancel /> : <IconLightbulb />}
                  size="medium"
                  fullWidth
                  aria-label="attachments"
                  sx={{ ...sxButtonCompetenciesProfileView, width: '50px', marginLeft: '10px' }}
                  onClick={handleCompetencyButtonClick}
                >
                  {getAttachedCount(selectedInstitution?.competencyAttachedCount)}
                </StyledButton>
              )}
            </Stack>
          </CardContent>
          {showCompetencyView && (
            <Box sx={{ ml: 3 }}>
              <Typography sx={{ mt: 4 }} variant="subtitle3">
                {t('competenciesAndSkills.competencies')}
              </Typography>
              <MultiValuedView multiValued={competencyValues} />
            </Box>
          )}
        </Card>

        <Box sx={{ mx: -2, my: 3 }}>
          <ToggleButton
            options={[
              {
                text: t('livingProfile.toggle.courses'),
                val: COURSES,
              },
              ...(selectedInstitution?.type.code === 'COLLEGE'
                ? [
                    {
                      text: t('livingProfile.toggle.degrees'),
                      val: DEGREES,
                    },
                  ]
                : []),
              {
                text: t('livingProfile.toggle.mediaDocs'),
                val: DOCUMENTS,
              },
            ]}
            onChange={onChangeToggle as (value: string | number | boolean) => void}
            selectedValue={selectedToggle}
          />
        </Box>

        {selectedToggle === COURSES && (
          <>
            <Typography variant="h2" sx={{ px: 0.5, pb: 2 }}>
              {t('academicHistory.course')}
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              {
                /* eslint-disable no-nested-ternary */
                selectedInstitution?.gradeLevels ? (
                  <HighSchoolCourses gradeLevels={selectedInstitution?.gradeLevels as unknown as TGradeLevel[]} />
                ) : selectedInstitution?.terms ? (
                  <CollegeCourses terms={selectedInstitution?.terms as ITerm[]} />
                ) : (
                  <Stack justifyContent="center" alignItems="center">
                    <EmptyCoursesIcon />
                    <Typography variant="body2" color="textSecondary">
                      {t('livingProfile.noData')}
                    </Typography>
                  </Stack>
                )
              }
            </Grid>
          </>
        )}

        {selectedToggle === DOCUMENTS && (
          <>
            <Typography variant="h2" sx={{ px: 0.5, pb: 2 }}>
              {t('livingProfile.sections.mediaAndDocuments')}
            </Typography>
            <Documents attachments={selectedInstitution?.attachments} useSections={true} />
          </>
        )}

        {selectedToggle === DEGREES && (
          <>
            <Typography variant="h2" sx={{ px: 0.5, pb: 2 }}>
              {t('livingProfile.sections.academicHistory.degrees')}
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              {selectedInstitution?.degrees ? (
                <Degrees degrees={selectedInstitution.degrees as unknown as Idegree[]} />
              ) : (
                <Stack justifyContent="center" alignItems="center">
                  <EmptyCoursesIcon />
                  <Typography variant="body2" color="textSecondary">
                    {t('livingProfile.noData')}
                  </Typography>
                </Stack>
              )}
            </Grid>
          </>
        )}
      </SidePanel>

      <SidePanel
        size="small"
        title={t('tests.details')}
        open={isTestsDrawerOpen}
        onClose={() => toggleStandardizedTestsDrawer()}
        isBackdropClickEnabled={true}
        sx={sxForMobileSidePanel}
        footerButtonConfig={{
          tertiary: {
            title: t('close_label'),
            props: {
              color: getButtonColor(),
              'aria-label': t('close_label'),
              onClick: () => toggleStandardizedTestsDrawer(),
            },
          },
        }}
      >
        <Card variant="outlined" sx={sxCard}>
          <CardContent>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton sx={{ p: 0.9 }} size="extra-large" aria-label="IconFileTest">
                  <Icon sx={{ color: theme => theme.palette.grey[500] }}>IconFileTest</Icon>
                </IconButton>
                <Typography variant="subtitle3" sx={{ overflowWrap: 'anywhere' }}>
                  {displayTestData(selectedTest)}
                </Typography>
              </Box>
            </Stack>
            <Box sx={{ mt: 0.5, display: 'flex', alignItems: 'center' }}>
              <Box>
                <Typography variant="caption" component="div">
                  {t(selectedTest?.status.code === 'PLANNED' ? 'tests.beTakenOn' : 'tests.takenOn')}{' '}
                  {formatDateToMonthAndYear(selectedTest?.testDate)}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
        {selectedTest?.scores && (
          <TableContainer component={Paper} sx={{ mt: 2, border: '1px solid #dde3ed' }}>
            <Table aria-label="score table">
              <TableHead>
                <TableRow sx={{ backgroundColor: '#dde3ed' }}>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    {t('livingProfile.sections.academicHistory.standardizedTests.scores')}
                  </TableCell>
                  <TableCell aria-hidden="true" />
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(selectedTest.scores).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        fontWeight: formatKey(key).toLowerCase().includes('total') ? 'bold' : 'normal',
                        border: '0.5px solid #dde3ed',
                      }}
                    >
                      {formatKey(key)}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: '0.5px solid #dde3ed',
                      }}
                    >
                      {value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </SidePanel>
    </Box>
  );
};
