import { TAutocompleteOption } from '@liaison/liaison-ui';
import type { ICollege, ITerm, TGradeLevel } from 'store/academicHistory/academicHistory.slice';

export interface ICollegeCourseProps {
  data: ICollege | null;
  isNewCourse: boolean;
  childPosition?: number;
  deleteCourse?:
    | ((
        childPosition: number,
        parent: string,
        parentPosition: number,
        selector: string,
        coursePosition?: number,
        tag?: string
      ) => void)
    | null;
  parentPosition?: number;
  coursePosition?: number;
  setNewParentIndex?: (index: number) => void;
  setNewChildIndex?: (index: number) => void;
}

export const prepareTermsOptions = (terms: ITerm[] | undefined): TAutocompleteOption<string>[] => {
  const termsOptions: TAutocompleteOption<string>[] = [];

  terms?.forEach((term, index) => {
    const { type, completionStatus, academicYear, id } = term;
    if (type?.displayName) {
      termsOptions.push({
        text: `${type.displayName.toUpperCase()} ${academicYear}`,
        id: JSON.stringify({
          id,
          type,
          completionStatus,
          academicYear,
        }),
        termPosition: index,
      });
    }
  });

  return termsOptions;
};

export const prepareGradeLevelsOptions = (gradeLevels: TGradeLevel[] | undefined): TAutocompleteOption<string>[] => {
  const gradeLevelsOptions: TAutocompleteOption<string>[] = [];

  gradeLevels?.forEach(({ type, completionStatus, academicYear, id }, index) => {
    if (type?.displayName) {
      gradeLevelsOptions.push({
        text: `${type.displayName} ${academicYear}`,
        id: JSON.stringify({
          id,
          type,
          completionStatus,
          academicYear,
        }),
        gradeLevelPosition: index,
      });
    }
  });

  return gradeLevelsOptions;
};

export const findSelectedGradeLevel = (level: TGradeLevel): TAutocompleteOption<string> | null => {
  if (level) {
    const { type, academicYear } = level;
    if (type?.displayName) {
      return {
        text: `${type.displayName} ${academicYear}`,
        id: JSON.stringify({
          type,
          academicYear,
        }),
      };
    }
  }
  return null;
};

export const findSelectedTerm = (term: ITerm): TAutocompleteOption<string> | null => {
  if (term) {
    const { type, completionStatus, academicYear, id } = term;
    if (type?.displayName) {
      return {
        text: `${type.displayName.toUpperCase()} ${academicYear}`,
        id: JSON.stringify({
          id,
          type,
          completionStatus,
          academicYear,
        }),
      };
    }
  }
  return null;
};
