/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useEffect } from 'react';
import {
  Button,
  Grid,
  LinearProgress,
  Stack,
  Typography,
  Box,
  CardContent,
  Card,
  Skeleton,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'transferPlanner/constants/general';
import { useDispatch, useSelector } from 'react-redux';
import { selectGeneralEducationRequirements } from 'store/common/commonInfo.selectors';
import {
  selectGenEdProcessData,
  selectAchievedMilestone,
  selectGenEdLoading,
} from 'transferPlanner/store/genEdProcess/genEdProcess.selectors';
import { IconListCheck, CircularProgress } from '@liaison/liaison-ui';
import { getMilestoneIcon } from 'transferPlanner/pages/GeneralEducation/AboutGenEd/About';
import { TGenEdReq } from 'store/common/commonInfo.slice';
import { IGenEdCategories } from 'transferPlanner/store/genEdProcess/genEdProcess.slice';
import { getGeneralEducationRequirements } from 'transferPlanner/components/GeneralEducationReqTable/GeneralEducationReqTable.utils';
import { GENERAL_EDUCATION } from 'transferPlanner/constants/routeNames';
import {
  getAreaBDetails,
  getGenEdProcess,
  getUnitsRequired,
} from 'transferPlanner/pages/GeneralEducation/GeneralEducation.utils';

const GeProcess = (): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const achievedMilestone = useSelector(selectAchievedMilestone);
  const genEdProcessData = useSelector(selectGenEdProcessData);
  const genEdCategories = genEdProcessData?.genEdCategories as IGenEdCategories[];
  const genEdReqData = useSelector(selectGeneralEducationRequirements);
  const isLoading = useSelector(selectGenEdLoading);

  useEffect(() => {
    if (!genEdReqData) {
      dispatch(getGeneralEducationRequirements());
    }
  }, [dispatch, genEdReqData]);

  useEffect(() => {
    dispatch(getGenEdProcess());
  }, [dispatch]);

  return (
    <Box sx={{ p: 2 }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <IconListCheck
              sx={{
                fontSize: 32,
                color: '#1B5E20',
                bgcolor: '#E8F5E9',
                p: 0.8,
                borderRadius: 0.5,
                mr: 1,
              }}
            />
            <Typography variant="subtitle1" component="div">
              {t('generalEducation.dashboard.heading')}
            </Typography>
          </Stack>
        </Grid>
        <Grid item>
          {/* added below empty element for the dashboard tile slider, check tp-2221 */}
          {isMobile && <Box id="tile-slider-back-1" tabIndex={0} />}
          <Button
            size="small"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate(`/${GENERAL_EDUCATION}`);
            }}
            variant="outlined"
            aria-label={t('gen_progress_view_label')}
          >
            {t('view_label')}
          </Button>
          {/* added below empty element for the dashboard tile slider, check tp-2221 */}
          {isMobile && <Box id="tile-slider-next-1" tabIndex={0} />}
        </Grid>
      </Grid>
      {isLoading ? (
        <Skeleton data-testid="skeleton" variant="rectangular" width="100%" height={286} sx={{ pt: 2 }} />
      ) : (
        <>
          <Grid container>
            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'flex-start' }}>
              {getMilestoneIcon(achievedMilestone?.name?.code, achievedMilestone?.name?.displayName || 'empty')}
            </Grid>
            <Grid item xs={9}>
              <Stack sx={{ height: '100%' }} direction="column" justifyContent="center">
                <LinearProgress
                  sx={{ mt: 1 }}
                  aria-label="LinearProgress"
                  color="inherit"
                  value={genEdProcessData?.totalCompletionPercentage || 0}
                  variant="determinate"
                />
                <Typography sx={{ mt: 1 }} variant="body2">
                  {`${genEdProcessData?.totalUnitsEarned || 0}/${getUnitsRequired(genEdProcessData, genEdReqData)} ${t(
                    'generalEducationReqTable.units'
                  )}`}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            {genEdReqData?.map((config: TGenEdReq, index: number) => {
              const categoryDetail = genEdCategories?.[index];
              const areaBDetails = getAreaBDetails(categoryDetail);
              return (
                <Grid item xs={6} key={config?.code}>
                  <Card variant="outlined" sx={{ color: 'text.primary', borderRadius: 2, border: '1px solid #CAD2E1' }}>
                    <CardContent sx={{ py: 1.5, '&:last-child': { pb: 1.5 } }}>
                      <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Typography variant="body2">{config?.code}</Typography>
                        </Grid>
                        <Grid item>
                          <CircularProgress
                            progress={categoryDetail?.completionPercentage || 0}
                            barColor="#b6bac0"
                            size="small"
                            strokeColor="#43A047"
                            thickness="large"
                            withRoundedStroke
                            showCheckIcon={!areaBDetails?.areaBStatus}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default memo(GeProcess);
