/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */
import { useEffect, useState } from 'react';

export function useFixedItemInView(): {
  isFixed: boolean;
} {
  const [isFixed, setIsFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setIsFixed(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return { isFixed };
}
