/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import * as yup from 'yup';
import { t } from 'i18next';
import { csuNameSpace } from 'utils/utilities';
import { notApplyReasonAnswers } from './Questionnaire.utils';

export const validationSchema = yup.object().shape({
  graduate: yup.string().oneOf(['Yes', 'No']),
  applied: yup.string().when('graduate', {
    is: (graduate: string) => graduate === 'Yes',
    then: yup.string().oneOf(['Yes', 'No']),
  }),
  notApplyReason: yup.string().when('graduate', {
    is: (graduate: string) => graduate === 'Yes',
    then: yup.string().oneOf(notApplyReasonAnswers.map(({ val }) => val)),
  }),
  otherReason: yup.string().when('notApplyReason', {
    is: (notApplyReason: string) => notApplyReason === 'other',
    then: yup.string().max(255),
  }),
  planToTransfer: yup.number().when('graduate', {
    is: (graduate: string) => graduate === 'Yes',
    then: yup.number().when('$version', (version, field) => {
      return version === 1
        ? field
            .min(1, t('Transfer-Success-Pathway-Questionnaire.error.planToTransfer_required', csuNameSpace))
            .required(t('Transfer-Success-Pathway-Questionnaire.error.planToTransfer_required', csuNameSpace))
        : field;
    }),
  }),
});
