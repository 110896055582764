/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, PropsWithChildren } from 'react';
import {
  Box,
  styled,
  Grid,
  FormHelperText,
  Button,
  Divider,
  IconButton,
  tableHeadClasses,
  tableCellClasses,
  Toolbar,
  ButtonProps,
  Typography,
  Checkbox,
  svgIconClasses,
  type Theme,
} from '@mui/material';
import { sidePanelClasses, IconTrash, IconAdd } from '@liaison/liaison-ui';
import { getButtonColor, isCsuTenant } from 'utils/utilities';

export const StyledPageGrid = styled(Grid)(({ theme: { breakpoints } }) => ({
  width: '100%',
  backgroundColor: 'white',
  borderTopLeftRadius: '1.5rem',
  padding: '2rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [breakpoints.only('xs')]: {
    padding: '1rem',
    borderTopLeftRadius: '0rem',
    marginBottom: '4.5rem',
  },
  boxShadow: '0px 0px 26px rgba(0, 0, 0, 0.25)',
}));

export const StyledFormHelperText = styled(FormHelperText)(() => ({
  position: 'static',
  bottom: '0',
}));

export const StyledButton = ({ children, onClick, ...otherProps }: ButtonProps): ReactElement => {
  return (
    <Button variant="ghost" color={getButtonColor()} onClick={onClick} {...otherProps}>
      {children}
    </Button>
  );
};

export const StyledAddButton = ({ children, onClick, ...otherProps }: ButtonProps): ReactElement => {
  return (
    <Button variant="ghost" startIcon={<IconAdd />} onClick={onClick} {...otherProps}>
      {children}
    </Button>
  );
};

export const StyledContainedButton = ({ children, onClick, ...otherProps }: ButtonProps): ReactElement => {
  return (
    <Button
      variant="contained"
      fullWidth
      {...(isCsuTenant()
        ? {}
        : {
            sx: {
              bgcolor: 'secondary.background',
              color: 'primary.title',
              [`&:hover, &:focus, &:active`]: {
                bgcolor: '#E3F2FD !important',
              },
            },
          })}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export const StyledDeleteButton = ({
  children,
  onClick,
  ...otherProps
}: PropsWithChildren<{ onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined }>): ReactElement => {
  return (
    <Button variant="ghost" color="error" startIcon={<IconTrash />} onClick={onClick} {...otherProps}>
      {children}
    </Button>
  );
};

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginLeft: '0.625rem',
  backgroundColor: theme.palette.secondary.background,
  color: theme.palette.grey[900],
  boxShadow:
    '0px 2px 1px -1px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.07), 0px 1px 3px 0px rgba(0, 0, 0, 0.06)',
}));

export const sxIconButton = {
  backgroundColor: (theme: Theme) => theme.palette.secondary.background,
  color: (theme: Theme) => theme.palette.grey[900],
  boxShadow:
    '0px 2px 1px -1px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.07), 0px 1px 3px 0px rgba(0, 0, 0, 0.06)',
  padding: '0.5rem',
  marginBottom: '0.1rem',
} as const;

export const StyledDivider = styled(Divider)(({ theme }) => ({
  borderStyle: 'solid',
  margin: '1.5rem 0 1.5rem 0',
  display: 'block',
  borderColor: theme.palette.grey[500],
}));

export const sxPrimaryButton = {
  bgcolor: '#0076A8',
  color: '#ffffff',
  [`&:hover, &:focus, &:active`]: {
    bgcolor: '#0076A8',
  },
} as const;

export const StyledBoxContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}));

export const StyledBoxFooterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem',
  width: '100%',
  borderTop: `0.0625rem solid ${theme.palette.divider}`,
}));

export const StyledPrimaryButton = styled(Button)(() => ({
  borderRadius: '2px',
  background: '#B39DDB',
  boxShadow: '0px 0px 1px rgba(255, 255, 255, 0.5)',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
  border: 0,
  fontSize: '0.75rem',
  textTransform: 'capitalize',
  '&:disabled': {
    color: '#000000',
  },
}));

export const sxSidePanel = {
  [`& .${sidePanelClasses.header}, button[aria-label^=Close] svg`]: {
    color: () => (isCsuTenant() ? 'inherit' : ['secondary.title', 'primary.title']),
  },
  [`& .${sidePanelClasses.header}`]: {
    background: (theme: Theme) =>
      isCsuTenant() ? theme.palette.background.paper : ['secondary.title', 'primary.title'],
    color: (theme: Theme) => (isCsuTenant() ? theme.palette.text.primary : ['secondary.title', 'primary.title']),
  },
} as const;

export const sxSidePanelMobile = {
  [`& .${sidePanelClasses.header}`]: {
    color: (theme: Theme) => (isCsuTenant() ? theme.palette.common.black : theme.palette.common.white),
    backgroundColor: (theme: Theme) => (isCsuTenant() ? theme.palette.background.paper : theme.palette.custom.drawer),
  },
} as const;

export const linkStyle = {
  color: '#642F6C',
};

export const sxCard = {
  borderRadius: 2,
  boxShadow:
    '0px 1px 10px 0px rgba(0, 0, 0, 0.06), 0px 4px 5px 0px rgba(0, 0, 0, 0.07), 0px 2px 4px -1px rgba(0, 0, 0, 0.10)',
} as const;

export const textOverflowStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
} as const;

export const tableStyles = {
  font: {
    [`& .${tableCellClasses.root}`]: {
      fontSize: '0.87rem',
    },
  },
  headerFont: {
    [`& .${tableHeadClasses.root} .${tableCellClasses.root}`]: {
      fontFamily: 'Proxima Nova Semibold',
    },
  },
  header: {
    backgroundColor: '#DDE3ED',
  },
  rowShadow: {
    boxShadow: 'inset 0px -1px 0px #E0E0E0',
  },
};

export const StyledToolBar = styled(Toolbar)(({ theme: { breakpoints, palette } }) => ({
  color: isCsuTenant() ? palette.common.black : palette.common.white,
  backgroundColor: palette.primary.main,
  [breakpoints.only('xs')]: {
    backgroundColor: isCsuTenant() ? palette.background.paper : palette.custom.drawer,
  },
}));

export const StyledAvatarGrid = styled(Grid)(({ theme: { palette } }) => ({
  width: 'fit-content',
  position: 'static',
  padding: '10px',
  color: palette.primary.contrastText,
  borderBottom: `1px solid ${palette.grey[500]}`,
  marginTop: '3rem',
}));

export const VisuallyHiddenTypography = styled(Typography)(() => ({
  position: 'absolute',
  width: '0px',
  height: '0px',
  padding: '0',
  overflow: 'hidden',
}));

export const StyledCheckbox = styled(Checkbox)(({ theme: { palette } }) => ({
  [`& .${svgIconClasses.root}`]: { color: palette.secondary.main },
}));
