/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export default {
  addressTypes: [
    {
      code: 'CURRENT',
      displayName: 'Current',
    },
    {
      code: 'PERMANENT',
      displayName: 'Permanent',
    },
  ],
  emailTypes: [
    {
      code: 'HOME',
      displayName: 'Home',
    },
    {
      code: 'WORK',
      displayName: 'Work',
    },
    {
      code: 'SCHOOL',
      displayName: 'School',
    },
  ],
  mobileTypes: [
    {
      code: 'HOME',
      displayName: 'Home',
    },
    {
      code: 'MOBILE',
      displayName: 'Mobile',
    },
    {
      code: 'WORK',
      displayName: 'Work',
    },
    {
      code: 'SCHOOL',
      displayName: 'School',
    },
  ],
  stateList: [
    {
      code: 'UM',
      displayName: 'United States Minor Outlying Islands',
    },
    {
      code: 'CA',
      displayName: 'California',
    },
    {
      code: 'MA',
      displayName: 'Massachusetts',
    },
    {
      code: 'MI',
      displayName: 'Michigan',
    },
    {
      code: 'TX',
      displayName: 'Texas',
    },
    {
      code: 'UA-46',
      displayName: 'Lvivska oblast',
    },
    {
      code: 'IN-KA',
      displayName: 'Karnataka',
    },
  ],

  countries: [
    { code: 'US', displayName: 'United States', order: 10 },
    { code: 'AF', displayName: 'Afghanistan', order: 20 },
    { code: 'AX', displayName: 'Åland Islands', order: 30 },
    { code: 'AL', displayName: 'Albania', order: 40 },
    { code: 'IN', displayName: 'India', order: 1040 },
    { code: 'UA', displayName: 'Ukraine', order: 2370 },
  ],
  countyList: [
    {
      code: 'BARNSTABLE',
      displayName: 'Barnstable County',
    },
    {
      code: 'AK013',
      displayName: 'Aleutians East Borough',
    },
    {
      code: 'BERKSHIRE',
      displayName: 'Berkshire County',
    },
    {
      code: 'AK016',
      displayName: 'Aleutians West Census Area',
    },
    {
      code: 'MA021',
      displayName: 'Norfolk County',
    },
    {
      code: 'MD510',
      displayName: 'Baltimore City',
    },
    {
      code: 'MA017',
      displayName: 'Middlesex County',
    },
  ],
  residenceYearOptions: [
    {
      code: 'LESS_THEN_ONE',
      displayName: 'Less than 1 year',
    },
    {
      code: 'ONE_TWO',
      displayName: '1-2 years',
    },
    {
      code: 'TWO_THREE',
      displayName: '2-3 years',
    },
    {
      code: 'THREE_FIVE',
      displayName: '3-5 years',
    },
    {
      code: 'FIVE_TEN',
      displayName: '5-10 years',
    },
    {
      code: 'MORE_THEN_TEN',
      displayName: 'More than 10 years',
    },
  ],
  genderOptions: [
    {
      code: 'FEMALE',
      displayName: 'Female',
    },
    {
      code: 'MALE',
      displayName: 'Male',
    },
    {
      code: 'NON_BINARY',
      displayName: 'X (nonbinary or another legal sex)',
    },
  ],
  pronounsOptions: [
    {
      code: 'SHE_HER_HERS',
      displayName: 'She/Her/Hers',
    },
    {
      code: 'HE_HIM_HIS',
      displayName: 'He/Him/His',
    },
    {
      code: 'THEY_THEM_THEIR',
      displayName: 'They/Them/Their',
    },
    {
      code: 'OTHER',
      displayName: 'Not Listed',
    },
  ],
  usCitizenshipStatusOptions: [
    {
      code: 'US_CITIZEN',
      displayName: 'U.S. citizen or US national',
    },
    {
      code: 'US_PERMANENT_RESIDENT',
      displayName: 'U.S. permanent resident',
    },
    {
      code: 'US_REFUGEE_OR_ASYLUM',
      displayName: 'U.S. refugee or asylum',
    },
    {
      code: 'DACA_UNDOCUMENTED_OR_OTHER',
      displayName: 'DACA, undocumented, Deferred Enforced Departure or Temporary Protected Status',
    },
    {
      code: 'NON_US_CITIZEN',
      displayName: 'Citizen of non-U.S. country',
    },
  ],
  answerOptions: [
    { val: 'YES', text: 'Yes' },
    { val: 'NO', text: 'No' },
  ],
  radioDefaultOptions: [
    { text: 'Yes', val: 'Yes' },
    { text: 'No', val: 'No' },
  ],
  militaryStatusOptions: [
    {
      code: 'ACTIVE_DUTY',
      displayName: 'On Active Duty',
    },
    {
      code: 'VETERAN',
      displayName: 'Veteran',
    },
    {
      code: 'NATIONAL_GUARD',
      displayName: 'Member of National Guard',
    },
    {
      code: 'RESERVE',
      displayName: 'Member of Reserve',
    },
    {
      code: 'DEPENDENT',
      displayName: 'Military Dependent',
    },
    {
      code: 'NOT_A_MEMBER',
      displayName: 'Not a Member of the Military',
    },
    {
      code: 'OTHER',
      displayName: 'Not Listed',
    },
  ],
  militaryBranchOptions: [
    {
      code: 'AIR_FORCE',
      displayName: 'Air Force',
    },
    {
      code: 'ARMY',
      displayName: 'Army',
    },
    {
      code: 'COAST_GUARD',
      displayName: 'Coast Guard',
    },
    {
      code: 'MARINE_CORPS',
      displayName: 'Marine Corps',
    },
    {
      code: 'NAVY',
      displayName: 'Navy',
    },
    {
      code: 'SPACE_FORCE',
      displayName: 'Space Force',
    },
    {
      code: 'NA',
      displayName: 'N/A',
    },
  ],
  militaryDischargeOptions: [
    {
      code: 'YES',
      displayName: 'Yes',
    },
    {
      code: 'NO',
      displayName: 'No',
    },
    {
      code: 'I_DID_NOT_SERVE',
      displayName: 'I did not serve',
    },
  ],
  HighestLevelEducation: [
    {
      code: 'TWELFTH_GRADE_OR_LESS',
      displayName: '12th grade or less',
    },
    {
      code: 'GRADUATE_HIGH_SCHOOL_OR_EQUIVALENT',
      displayName: 'Graduated high school or equivalent',
    },
    {
      code: 'SOME_COLLEGE_NO_DEGREE',
      displayName: 'Some college, no degree',
    },
    {
      code: 'ASSOCIATES_DEGREE',
      displayName: 'Associates degree',
    },
    {
      code: 'BACHELORS_DEGREE',
      displayName: 'Bachelors degree',
    },
    {
      code: 'POST_GRADUATE_DEGREE',
      displayName: 'Post-graduate degree',
    },
  ],
  ModalityOfEducation: [
    {
      code: 'ONLINE',
      displayName: 'Online',
    },
    {
      code: 'ON_CAMPUS',
      displayName: 'On Campus',
    },
    {
      code: 'HYBRID',
      displayName: 'Hybrid',
    },
  ],
  ProgramType: [
    {
      code: 'FULL_TIME',
      displayName: 'Full-time',
    },
    {
      code: 'PART_TIME',
      displayName: 'Part-time',
    },
  ],
  TypesOfEmployment: [
    {
      code: 'FULL_TIME',
      displayName: 'Full-time',
    },
    {
      code: 'PART_TIME',
      displayName: 'Part-time',
    },
    {
      code: 'INTERNSHIP',
      displayName: 'Internship',
    },
    {
      code: 'CONTRACT_TEMPORARY',
      displayName: 'Contract/Temporary',
    },
    {
      code: 'VOLUNTEER',
      displayName: 'Volunteer',
    },
    {
      code: 'OTHER',
      displayName: 'Other',
    },
  ],
  TypesOfDegreeSeeking: [
    {
      code: 'NON_DEGREE',
      displayName: 'Non-Degree / Certificate',
    },
    {
      code: 'ASSOCIATES',
      displayName: 'Associates',
    },
    {
      code: 'BACHELORS',
      displayName: 'Bachelors',
    },
    {
      code: 'MASTERS',
      displayName: 'Masters',
    },
    {
      code: 'DOCTORAL',
      displayName: 'Doctoral',
    },
  ],
  TypesOfAcademicInterests: [
    {
      code: 'COLLEGES',
      displayName: 'Colleges',
    },
    {
      code: 'GRADUATE_SCHOOLS',
      displayName: 'Graduate Schools',
    },
  ],
  TypesOfRolesInterest: [
    {
      code: 'INTERN',
      displayName: 'Intern',
    },
    {
      code: 'ENTRY_LEVEL',
      displayName: 'Entry-level',
    },
    {
      code: 'INTERMEDIATE',
      displayName: 'Intermediate Staff',
    },
    {
      code: 'EXPERIENCED',
      displayName: 'Experienced Staff',
    },
    {
      code: 'MANAGER',
      displayName: 'Manager',
    },
    {
      code: 'SENIOR_MANAGER',
      displayName: 'Senior Manager',
    },
    {
      code: 'EXECUTIVE',
      displayName: 'Executive',
    },
    {
      code: 'OTHER',
      displayName: 'Other',
    },
  ],
  TypesOfStudies: [
    {
      code: 'ACCOUNTING',
      displayName: 'Accounting',
      order: 1,
    },
    {
      code: 'AIRLINES_AVIATION',
      displayName: 'Airlines/Aviation',
      order: 2,
    },
    {
      code: 'ALTERNATIVE_DISPUTE_RESOLUTION',
      displayName: 'Alternative Dispute Resolution',
      order: 3,
    },
  ],
  TypesOfVisa: [
    {
      code: 'A-1',
      displayName: 'A-1 Diplomat and Immediate Family',
      order: 1,
    },
    {
      code: 'A-2',
      displayName: 'A-2 Other Government Official and Immediate Family',
      order: 2,
    },
    {
      code: 'A-3',
      displayName: 'A-3 Personal Employee of A1 or A2',
      order: 3,
    },
    {
      code: 'B-1',
      displayName: 'B-1 Visitor for Business',
      order: 4,
    },
    {
      code: 'B-2',
      displayName: 'B-2 Pleasure or Prospect or Exchange',
      order: 5,
    },
    {
      code: 'C-1',
      displayName: 'C-1 Immediate and Continuous Transit',
      order: 6,
    },
    {
      code: 'C-2',
      displayName: 'C-2 Transit to and from UN HQ',
      order: 7,
    },
    {
      code: 'C-3',
      displayName: 'C-3 Accredited Foreign Official Transit',
      order: 8,
    },
    {
      code: 'D',
      displayName: 'D Crewman',
      order: 9,
    },
    {
      code: 'E-1',
      displayName: 'E-1 Treaty Trader and Immediate Family',
      order: 10,
    },
    {
      code: 'E-2',
      displayName: 'E-2 Treaty Investor and Immediate Family',
      order: 11,
    },
    {
      code: 'E-3',
      displayName: 'E-3 Specialty Occupation',
      order: 12,
    },
    {
      code: 'E-3D',
      displayName: 'E-3D Dependent',
      order: 13,
    },
    {
      code: 'E-3R',
      displayName: 'E-3R Returning E-3',
      order: 14,
    },
    {
      code: 'F-1',
      displayName: 'F-1 Student',
      order: 15,
    },
    {
      code: 'F-2',
      displayName: 'F-2 Dependents of F-1',
      order: 16,
    },
    {
      code: 'G-1',
      displayName: 'G-1 Principal Rcg and Immediate Family',
      order: 17,
    },
    {
      code: 'G-2',
      displayName: 'G-2 Accredited Rcg and Immediate Family',
      order: 18,
    },
    {
      code: 'G-3',
      displayName: 'G-3 Principal Unrcg and Immediate Family',
      order: 19,
    },
    {
      code: 'G-4',
      displayName: 'G-4 Officer Employee and Immediate Family',
      order: 20,
    },
    {
      code: 'G-5',
      displayName: 'G-5 Personal Employee of G-1 through G-4',
      order: 21,
    },
    {
      code: 'H-1A',
      displayName: 'H-1A Registered Nurse',
      order: 22,
    },
    {
      code: 'H-1B',
      displayName: 'H-1B Temp Specialty Occupation',
      order: 23,
    },
    {
      code: 'H-2A',
      displayName: 'H-2A Temp Agricultural Worker',
      order: 24,
    },
    {
      code: 'H-2B',
      displayName: 'H-2B Other Temp Worker',
      order: 25,
    },
    {
      code: 'H-3',
      displayName: 'H-3 Trainee',
      order: 26,
    },
    {
      code: 'H-4',
      displayName: 'H-4 Dependents H-1 through H-3',
      order: 27,
    },
    {
      code: 'I',
      displayName: 'I Media Representative and Immediate Family',
      order: 28,
    },
    {
      code: 'I-94A',
      displayName: 'I-94 Asylum Granted',
      order: 29,
    },
    {
      code: 'I-94C',
      displayName: 'I-94 Cuban-Haitian Entrant',
      order: 30,
    },
    {
      code: 'I-94P',
      displayName: 'I-94 Parolee',
      order: 31,
    },
    {
      code: 'I-94R',
      displayName: 'I-94 Refugee',
      order: 32,
    },
    {
      code: 'I-94V',
      displayName: 'I-94 Victim of human trafficking',
      order: 33,
    },
    {
      code: 'J-1',
      displayName: 'J-1 Exchange Visitor',
      order: 34,
    },
    {
      code: 'J-2',
      displayName: 'J-2 Dependents of J-1',
      order: 35,
    },
    {
      code: 'K-1',
      displayName: 'K-1 Fiance of a US Citizen',
      order: 36,
    },
    {
      code: 'K-2',
      displayName: 'K-2 Children of K-1',
      order: 37,
    },
    {
      code: 'K-3',
      displayName: 'K-3 Spouse of a US Citizen',
      order: 38,
    },
    {
      code: 'K-4',
      displayName: 'K-4 Child of K-3',
      order: 39,
    },
    {
      code: 'L-1',
      displayName: 'L-1 Intracompany Transferee',
      order: 40,
    },
    {
      code: 'L-2',
      displayName: 'L-2 Dependents of L-1',
      order: 41,
    },
    {
      code: 'M-1',
      displayName: 'M-1 Student Voc NonAcademic',
      order: 42,
    },
    {
      code: 'M-2',
      displayName: 'M-2 Dependents of M-1',
      order: 43,
    },
    {
      code: 'NATO',
      displayName: 'NATO Foreign military personnel',
      order: 44,
    },
    {
      code: 'O-1',
      displayName: 'O-1 Extraordinary Ability',
      order: 45,
    },
    {
      code: 'O-2',
      displayName: 'O-2 Accompanying O-1',
      order: 46,
    },
    {
      code: 'O-3',
      displayName: 'O-3 Dependents of O-1 or O-2',
      order: 47,
    },
    {
      code: 'P-1',
      displayName: 'P-1 Athlete or Entertainment Group',
      order: 48,
    },
    {
      code: 'P-2',
      displayName: 'P-2 Artist or Entertainer Exchange',
      order: 49,
    },
    {
      code: 'P-3',
      displayName: 'P-3 Artist or Entertainer Cultural',
      order: 50,
    },
    {
      code: 'P-4',
      displayName: 'P-4 Dependents P-1 through P-3',
      order: 51,
    },
    {
      code: 'Q-1',
      displayName: 'Q-1 International Cultural Exchange Program',
      order: 52,
    },
    {
      code: 'R-1',
      displayName: 'R-1 Religious Worker',
      order: 53,
    },
    {
      code: 'R-2',
      displayName: 'R-2 Dependents of R-1',
      order: 54,
    },
    {
      code: 'T',
      displayName: 'T - Victim of human trafficking',
      order: 55,
    },
    {
      code: 'TD',
      displayName: 'TD Dependents of TN',
      order: 56,
    },
    {
      code: 'TN',
      displayName: 'TN Canadian or Mexican Business',
      order: 57,
    },
    {
      code: 'U',
      displayName: 'U nonimmigrant visa',
      order: 58,
    },
    {
      code: 'U-1',
      displayName: 'U-1 Dependent of U Visa Holder',
      order: 59,
    },
    {
      code: 'V',
      displayName: 'V Spouse and Children of a Lawful Permanent Resident',
      order: 60,
    },
    {
      code: 'V-WB',
      displayName: 'Visa Waiver WB',
      order: 61,
    },
    {
      code: 'V-WT',
      displayName: 'Visa Waiver WT',
      order: 62,
    },
    {
      code: 'OTHER',
      displayName: 'Other',
      order: 63,
    },
  ],
  relationship: [
    {
      code: 'MOTHER',
      displayName: 'Mother',
    },
    {
      code: 'FATHER',
      displayName: 'Father',
    },
    {
      code: 'STEPMOTHER',
      displayName: 'Stepmother',
    },
    {
      code: 'STEPFATHER',
      displayName: 'Stepfather',
    },
    {
      code: 'FOSTER_PARENT',
      displayName: 'Foster parent',
    },
    {
      code: 'GUARDIAN',
      displayName: 'Guardian',
    },
    {
      code: 'OTHER',
      displayName: 'Other',
    },
  ],
  occupationOptions: [
    {
      code: '1',
      displayName: 'Accountant',
      order: 20,
    },
    {
      code: '2',
      displayName: 'Administrative Assistant, Clerical, Secretary or Typist',
      order: 30,
    },
    {
      code: '3',
      displayName: 'Airline Flight Attendant',
      order: 40,
    },
    {
      code: '4',
      displayName: 'Airplane Pilot',
      order: 50,
    },
    {
      code: '5',
      displayName: 'Analyst',
      order: 60,
    },
    {
      code: '6',
      displayName: 'Architect',
      order: 70,
    },
    {
      code: '7',
      displayName: 'Armed Forces-Enlisted',
      order: 80,
    },
    {
      code: '8',
      displayName: 'Armed Forces-Officer',
      order: 90,
    },
    {
      code: '9',
      displayName: 'Artist',
      order: 100,
    },
    {
      code: '10',
      displayName: 'Auditor',
      order: 110,
    },
    {
      code: '11',
      displayName: 'Author',
      order: 120,
    },
    {
      code: '12',
      displayName: 'Banker',
      order: 130,
    },
    {
      code: '13',
      displayName: 'Barber/Hairstylist',
      order: 140,
    },
    {
      code: '14',
      displayName: 'Bartender',
      order: 150,
    },
    {
      code: '15',
      displayName: 'Bookkeeper',
      order: 160,
    },
    {
      code: '16',
      displayName: 'Bricklayer',
      order: 170,
    },
    {
      code: '17',
      displayName: 'Bus Driver',
      order: 180,
    },
    {
      code: '18',
      displayName: 'Business Executive',
      order: 190,
    },
    {
      code: '19',
      displayName: 'Business Owner',
      order: 200,
    },
    {
      code: '20',
      displayName: 'Buyer',
      order: 210,
    },
    {
      code: '21',
      displayName: 'Carpenter',
      order: 220,
    },
    {
      code: '22',
      displayName: 'Cashier',
      order: 230,
    },
    {
      code: '23',
      displayName: 'Chemist',
      order: 240,
    },
    {
      code: '24',
      displayName: 'Clergy',
      order: 250,
    },
    {
      code: '25',
      displayName: 'Clerical Worker',
      order: 260,
    },
    {
      code: '26',
      displayName: 'Clerk',
      order: 270,
    },
    {
      code: '27',
      displayName: 'Computer Software Developers or Managers',
      order: 280,
    },
    {
      code: '28',
      displayName: 'Construction',
      order: 290,
    },
    {
      code: '29',
      displayName: 'Cook / Chef',
      order: 300,
    },
    {
      code: '30',
      displayName: 'Counselor (school, employment, rehabilitation, etc.)',
      order: 310,
    },
    {
      code: '31',
      displayName: 'Craftsman/woman (including carpenter, bricklayer, etc)',
      order: 320,
    },
    {
      code: '32',
      displayName: 'Dental Hygienist',
      order: 330,
    },
    {
      code: '33',
      displayName: 'Dental Laboratory Technician',
      order: 340,
    },
    {
      code: '34',
      displayName: 'Dentist',
      order: 350,
    },
    {
      code: '35',
      displayName: 'Dietician',
      order: 360,
    },
    {
      code: '36',
      displayName: 'Domestic Worker',
      order: 370,
    },
    {
      code: '37',
      displayName: 'Editor (any media outlet)',
      order: 380,
    },
    {
      code: '38',
      displayName: 'Educator (below college level)',
      order: 390,
    },
    {
      code: '39',
      displayName: 'Educator (college level)',
      order: 400,
    },
    {
      code: '40',
      displayName: 'Electrician',
      order: 410,
    },
    {
      code: '41',
      displayName: 'Engineer',
      order: 420,
    },
    {
      code: '42',
      displayName: 'Entertainer',
      order: 430,
    },
    {
      code: '43',
      displayName: 'Express mail messenger/Mail clerk',
      order: 440,
    },
    {
      code: '44',
      displayName: 'Farm Laborer',
      order: 450,
    },
    {
      code: '45',
      displayName: 'Farm Manager',
      order: 460,
    },
    {
      code: '46',
      displayName: 'Farm Owner',
      order: 470,
    },
    {
      code: '47',
      displayName: 'Fisherman',
      order: 480,
    },
    {
      code: '48',
      displayName: 'Florist',
      order: 490,
    },
    {
      code: '49',
      displayName: 'Foreman',
      order: 500,
    },
    {
      code: '50',
      displayName: 'Funeral Director / Embalmer / Funeral Parlor Owner',
      order: 510,
    },
    {
      code: '51',
      displayName: 'Gardener',
      order: 520,
    },
    {
      code: '52',
      displayName: 'General Office Worker',
      order: 530,
    },
    {
      code: '53',
      displayName: 'Geologist',
      order: 540,
    },
    {
      code: '54',
      displayName: 'Government Official',
      order: 550,
    },
    {
      code: '55',
      displayName: 'Health Related Assistant (Medical, Dental, etc.)',
      order: 560,
    },
    {
      code: '56',
      displayName: 'Health Technician',
      order: 570,
    },
    {
      code: '57',
      displayName: 'Home Parent',
      order: 580,
    },
    {
      code: '58',
      displayName: 'Hospital Administrator',
      order: 590,
    },
    {
      code: '59',
      displayName: 'Insulation Specialist',
      order: 600,
    },
    {
      code: '60',
      displayName: 'Insurance Agent',
      order: 610,
    },
    {
      code: '61',
      displayName: 'Janitor',
      order: 620,
    },
    {
      code: '62',
      displayName: 'Journalist/news reporter',
      order: 630,
    },
    {
      code: '63',
      displayName: 'Laboratory Technician',
      order: 640,
    },
    {
      code: '64',
      displayName: 'Laborer',
      order: 650,
    },
    {
      code: '65',
      displayName: 'Lawyer',
      order: 660,
    },
    {
      code: '66',
      displayName: 'Librarian',
      order: 670,
    },
    {
      code: '67',
      displayName: 'Linguist',
      order: 680,
    },
    {
      code: '68',
      displayName: 'Lumber - worker/clerk',
      order: 690,
    },
    {
      code: '69',
      displayName: 'Machine Operator',
      order: 700,
    },
    {
      code: '70',
      displayName: 'Machinist',
      order: 710,
    },
    {
      code: '71',
      displayName: 'Maintenance worker',
      order: 720,
    },
    {
      code: '72',
      displayName: 'Mathematician, Physicist or computer scientist',
      order: 730,
    },
    {
      code: '73',
      displayName: 'Mechanic',
      order: 740,
    },
    {
      code: '74',
      displayName: 'Not Reported',
      order: 750,
    },
    {
      code: '75',
      displayName: 'Nurse',
      order: 760,
    },
    {
      code: '76',
      displayName: 'Office Manager',
      order: 770,
    },
    {
      code: '77',
      displayName: 'Other',
      order: 780,
    },
    {
      code: '78',
      displayName: 'Painter',
      order: 790,
    },
    {
      code: '79',
      displayName: 'Pharmacist',
      order: 800,
    },
    {
      code: '80',
      displayName: 'Photographer',
      order: 810,
    },
    {
      code: '81',
      displayName: 'Physician',
      order: 820,
    },
    {
      code: '82',
      displayName: 'Postal Worker',
      order: 830,
    },
    {
      code: '83',
      displayName: 'Physical Therapist',
      order: 831,
    },
    {
      code: '84',
      displayName: 'Physical Therapist Assistant',
      order: 832,
    },
    {
      code: '85',
      displayName: 'Physician Assistant',
      order: 833,
    },
    {
      code: '86',
      displayName: 'Production Manager',
      order: 840,
    },
    {
      code: '87',
      displayName: 'Professional (not classified elsewhere, M.S., M.A., or higher academic degree, or equivalent)',
      order: 850,
    },
    {
      code: '88',
      displayName: 'Professional Athlete/Fitness Instructor/Coach',
      order: 860,
    },
    {
      code: '89',
      displayName: 'Professional Health Specialist (Podiatrist, Optometrist, Chiropractor, Speech Pathologist, etc.)',
      order: 870,
    },
    {
      code: '90',
      displayName: 'Protective Services (Police, Fire or other Security Occupation)',
      order: 880,
    },
    {
      code: '91',
      displayName: 'Psychologist/Psychiatrist',
      order: 890,
    },
    {
      code: '92',
      displayName: 'Publicity/Public relations writer / Lobbyist',
      order: 900,
    },
    {
      code: '93',
      displayName: 'Radio or Television Broadcaster/Radio Operator / Radio Personality',
      order: 910,
    },
    {
      code: '94)',
      displayName: 'Repairman (Plumber, Electrician, HVAC, etc.)',
      order: 920,
    },
    {
      code: '95',
      displayName: 'Research Technician',
      order: 930,
    },
    {
      code: '96',
      displayName: 'Retired',
      order: 940,
    },
    {
      code: '97',
      displayName: 'Sales Clerk',
      order: 950,
    },
    {
      code: '98',
      displayName: 'Salesman/Saleswoman',
      order: 960,
    },
    {
      code: '99',
      displayName: 'Scientist',
      order: 970,
    },
    {
      code: '100',
      displayName: 'Seamstress',
      order: 980,
    },
    {
      code: '101',
      displayName: 'Service Worker',
      order: 990,
    },
    {
      code: '102',
      displayName: 'Small Business Owner',
      order: 1000,
    },
    {
      code: '103',
      displayName: 'Social Worker/Welfare Worker',
      order: 1010,
    },
    {
      code: '104',
      displayName: 'Supervisor',
      order: 1020,
    },
    {
      code: '105',
      displayName: 'Systems Analyst',
      order: 1030,
    },
    {
      code: '106',
      displayName: 'Technician or Semi-Professional (not classified elsewhere)',
      order: 1040,
    },
    {
      code: '107',
      displayName: 'Transport or equipment operator',
      order: 1050,
    },
    {
      code: '108',
      displayName: 'Truck Driver',
      order: 1060,
    },
    {
      code: '109',
      displayName: 'Unemployed',
      order: 1070,
    },
    {
      code: '110',
      displayName: 'Unknown',
      order: 1080,
    },
    {
      code: '111',
      displayName: 'Veterinarian',
      order: 1090,
    },
    {
      code: '112',
      displayName: 'Waiter/Waitress',
      order: 1100,
    },
    {
      code: '113',
      displayName: 'X-ray / Ultra sound / MRI technician',
      order: 1110,
    },
  ],
  educationLevelOptions: [
    {
      code: 'LESS_THAN_HIGH_SCHOOL',
      displayName: 'Less than high school',
    },
    {
      code: 'HIGH_SCHOOL',
      displayName: 'High School Graduate (high school diploma or equivalent)',
    },
    {
      code: 'COLLEGE',
      displayName: 'Some college, but no degree',
    },
    {
      code: 'BACHELOR',
      displayName: 'Bachelor Degree (BA, BS, etc.)',
    },
    {
      code: 'ASSOCIATE',
      displayName: "Associate's Degree or Certificate",
    },
    {
      code: 'GRADUATE_SCHOOL',
      displayName: 'Some graduate school, but no degree',
    },
    {
      code: 'MASTERS',
      displayName: "Master's Degree",
    },
    {
      code: 'DOCTORATE',
      displayName: 'Doctorate or Professional Degree',
    },
    {
      code: 'DONT_KNOW',
      displayName: "Don't know",
    },
  ],

  proficiencyLevels: [
    {
      code: 'BEGINNER',
      displayName: 'Beginner',
    },
    {
      code: 'INTERMEDIATE',
      displayName: 'Intermediate',
    },
    {
      code: 'ADVANCED',
      displayName: 'Advanced',
    },
    {
      code: 'NATIVE',
      displayName: 'Native',
    },
  ],
  achievementTypes: [
    {
      code: 'AWARDS',
      displayName: 'Awards',
    },
    {
      code: 'HONORS',
      displayName: 'Honors',
    },
    {
      code: 'PRESENTATIONS',
      displayName: 'Presentations',
    },
    {
      code: 'PUBLICATIONS',
      displayName: 'Publications',
    },
    {
      code: 'SCHOLARSHIPS',
      displayName: 'Scholarships',
    },
  ],
  typeOfExperience: [
    {
      code: 'EXTRACURRICULAR_ACTIVITIES',
      displayName: 'Extracurricular Activities',
    },
    {
      code: 'HEALTHCARE_EXPERIENCE',
      displayName: 'Healthcare Experience',
    },
    {
      code: 'LEADERSHIP_EXPERIENCE',
      displayName: 'Leadership Experience',
    },
    {
      code: 'Non-Healthcare Employment',
      displayName: 'Non-Healthcare Employment',
    },
    {
      code: 'PATIENT_CARE_EXPERIENCE',
      displayName: 'Patient Care Experience',
    },
    {
      code: 'RESEARCH',
      displayName: 'Research',
    },
    {
      code: 'SHADOWING',
      displayName: 'Shadowing',
    },
    {
      code: 'TEACHING_EXPERIENCE',
      displayName: 'Teaching Experience ',
    },
    {
      code: 'VOLUNTEER',
      displayName: 'Volunteer',
    },
  ],
  highSchoolCourseType: [
    {
      code: 'NONE',
      displayName: 'None',
    },
    {
      code: 'ADVANCED_PLACEMENT',
      displayName: 'Advanced Placement',
    },
    {
      code: 'INTERNATIONAL_BACCALAUREATE',
      displayName: 'International Baccalaureate',
    },
    {
      code: 'HONORS',
      displayName: 'Honors',
    },
  ],
  highSchoolGradeLevel: [
    {
      code: 'SEVENTH',
      displayName: '7th',
    },
    {
      code: 'EIGHTH',
      displayName: '8th',
    },
    {
      code: 'NINTH',
      displayName: '9th',
    },
    {
      code: 'TENTH',
      displayName: '10th',
    },
    {
      code: 'ELEVENTH',
      displayName: '11th',
    },
    {
      code: 'TWELFTH',
      displayName: '12th',
    },
  ],
  highSchoolTermType: [
    {
      code: 'QUARTER',
      displayName: 'Quarter',
    },
    {
      code: 'SEMESTER',
      displayName: 'Semester',
    },
    {
      code: 'TRIMESTER',
      displayName: 'Trimester',
    },
    {
      code: 'FULL_YEAR',
      displayName: 'Full Year',
    },
  ],
  usGrade: [
    {
      code: 'A_PLUS',
      displayName: 'A+',
    },
    {
      code: 'A',
      displayName: 'A',
    },
    {
      code: 'A_MINUS',
      displayName: 'A-',
    },
    {
      code: 'AB',
      displayName: 'AB',
    },
    {
      code: 'B_PLUS',
      displayName: 'B+',
    },
    {
      code: 'B',
      displayName: 'B',
    },
    {
      code: 'B_MINUS',
      displayName: 'B-',
    },
    {
      code: 'BC',
      displayName: 'BC',
    },
    {
      code: 'C_PLUS',
      displayName: 'C+',
    },
    {
      code: 'C',
      displayName: 'C',
    },
    {
      code: 'C_MINUS',
      displayName: 'C-',
    },
    {
      code: 'CD',
      displayName: 'CD',
    },
    {
      code: 'D_PLUS',
      displayName: 'D+',
    },
    {
      code: 'D',
      displayName: 'D',
    },
    {
      code: 'D_MINUS',
      displayName: 'D-',
    },
    {
      code: 'E',
      displayName: 'E',
    },
    {
      code: 'F',
      displayName: 'F',
    },
    {
      code: 'P',
      displayName: 'P',
    },
    {
      code: 'IN_PROGRESS',
      displayName: 'In Progress',
    },
    {
      code: 'PLANNED',
      displayName: 'Planned',
    },
    {
      code: 'NO_COURSE',
      displayName: 'No Course',
    },
    {
      code: 'PASS',
      displayName: 'Pass',
    },
    {
      code: 'NO_PASS',
      displayName: 'No Pass',
    },
    {
      code: 'FAIL',
      displayName: 'Fail',
    },
    {
      code: 'NONE',
      displayName: 'None',
    },
  ],
  collegeTermType: [
    { code: 'QUARTER', displayName: 'Quarter' },
    { code: 'SEMESTER', displayName: 'Semester' },
    { code: 'TRIMESTER', displayName: 'Trimester' },
  ],
  termOptions: [
    { code: 'WINTER', displayName: 'Winter' },
    { code: 'SPRING', displayName: 'Spring' },
    { code: 'SUMMER_1', displayName: 'Summer 1' },
    { code: 'SUMMER_2', displayName: 'Summer 2' },
    { code: 'FALL', displayName: 'Fall' },
    { code: 'INTERIM', displayName: 'Interim' },
  ],
  completionStatus: [
    { val: 'PLANNED', text: 'Planned' },
    { val: 'IN_PROGRESS', text: 'In Progress' },
    { val: 'COMPLETED', text: 'Completed' },
  ],
};
