/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, Fragment, memo, useMemo, useEffect, useState } from 'react';
import { InputBase, Typography, FormControl, Grid, InputLabel, Box, Button, FormHelperText } from '@mui/material';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { TPersonalInfoField } from 'userProfile/store/personalInfo/personalInfo.slice';
import { AddressViewCard } from 'userProfile/components/ViewBuilder/CardView';
import { Dropdown, Alert, IconAdd, IconRemove } from '@liaison/liaison-ui';
import { PhoneField } from 'components/PhoneField';
import { getDropDownOptions, isEmpty } from 'utils/utilities';
import MasterData from 'userProfile/constants/master';
import { linkStyle, StyledAddButton, StyledFormHelperText, StyledIconButton } from 'pages/Pages.styles';
import { DISABLE_AUTO_FILL, MAX_BIG_LENGTH_FIELD } from 'constants/field';
import { DEFAULT_EMAIL_MOBILE } from 'userProfile/constants/general';
import { useTranslation } from 'react-i18next';
import { keycloak } from 'constants/keyCloak';
import { MY_ACCOUNT } from 'transferPlanner/constants/routeNames';
import {
  getFilteredEmailOptions,
  getPrefferedEmailIndex,
  getPrefferedPhoneIndex,
  validateDuplicates,
} from '../Personalnformation.utils';
import type { TPersonalInfoFormErrors } from '../PersonalInformationForm.validation';

interface IPersonalInfoProps {
  data: TPersonalInfoField | null;
  openChildDrawer?: (isAddNew: boolean, childPosition: number) => void;
  isProfileSetup?: boolean;
}

const ContactInformation = (props: IPersonalInfoProps): ReactElement => {
  const { data, openChildDrawer, isProfileSetup = false } = props;
  const {
    register,
    control,
    watch,
    formState: { errors: formErrors, isValid },
    setValue,
    trigger,
    getValues,
  } = useFormContext();
  const errors = formErrors as unknown as TPersonalInfoFormErrors;

  const defaultEmailValue = { email: '', type: {} };
  const defaultPhoneValue = { phone: '', type: {} };
  const groupName = 'contact';
  const { t } = useTranslation();
  const navigate = useNavigate();
  const watchedFieldsList = watch([`${groupName}.emails`, `${groupName}.phones`]);
  const [hidePhonePlusButton, setHidePhonePlusButton] = useState(false);
  const [hideEmailPlusButton, setHideEmailPlusButton] = useState(false);
  let emailsCount = 0;
  let phonesCount = 0;

  const emailsList = useFieldArray({
    control,
    name: `${groupName}.emails`,
  });

  const phoneNumbersList = useFieldArray({
    control,
    name: `${groupName}.phones`,
  });
  const emailOptions = useMemo(() => getDropDownOptions(MasterData.emailTypes), []);
  const mobileOptions = useMemo(() => getDropDownOptions(MasterData.mobileTypes), []);
  const addresses = data?.contact?.addresses;

  useEffect(() => {
    if (data?.[groupName]) {
      const phones = [...(data?.contact?.phones || [])];
      const prefferedPhoneIndex = getPrefferedPhoneIndex(phones);
      if (prefferedPhoneIndex) {
        phones.unshift(phones[prefferedPhoneIndex]);
        phones.splice(prefferedPhoneIndex + 1, 1);
      }
      setValue('contact.phones', phones);

      const emails = [...(data?.contact?.emails || [])];
      const prefferedEmailndex = getPrefferedEmailIndex(emails);
      if (prefferedEmailndex) {
        emails.unshift(emails[prefferedEmailndex]);
        emails.splice(prefferedEmailndex + 1, 1);
      }
      setValue('contact.emails', emails);
      if (data?.contact?.emails?.length === emailOptions?.length) {
        setHideEmailPlusButton(true);
      }

      if (data?.contact?.phones?.length === mobileOptions?.length) {
        setHidePhonePlusButton(true);
      }
      setValue('contact.addresses', data?.contact?.addresses);
    } else {
      const defaultValues = { ...DEFAULT_EMAIL_MOBILE };
      defaultValues.contact.emails[0].email = keycloak?.tokenParsed?.email;
      setValue(groupName, defaultValues.contact);
    }
  }, [data, setValue, emailOptions, mobileOptions]);

  const phoneAddMoreClick = () => {
    if (phonesCount <= mobileOptions.length) {
      phoneNumbersList?.append(defaultPhoneValue);
      if (phonesCount + 1 === mobileOptions.length) {
        setHidePhonePlusButton(true);
      }
    }
  };

  const emailAddMoreClick = () => {
    if (emailsCount <= emailOptions.length) {
      emailsList?.append(defaultEmailValue);
      if (emailsCount + 1 === emailOptions.length) {
        setHideEmailPlusButton(true);
      }
    }
  };

  const EmailContent = (emailIndex: number) => {
    emailsCount += 1;
    const primaryEmail = emailIndex === 0;
    const additionalEmails = emailIndex === 1;

    const uniqueEmailExists = validateDuplicates(
      getValues('contact.emails'),
      (emails: Record<string, string>) => emails.email
    );

    return (
      <>
        {primaryEmail && (
          <FormControl fullWidth required>
            <Box sx={{ mt: 1, mb: 1, display: 'flex', flexDirection: 'row' }}>
              <InputLabel htmlFor="Email">{t('contactInfo.primary_email')}</InputLabel>
              {!uniqueEmailExists && (
                <FormHelperText role="alert" sx={{ color: theme => theme.palette.error.main, ml: 1 }}>
                  {t('contactInfo.email.duplicate_err')}
                </FormHelperText>
              )}
            </Box>
            {!isProfileSetup && (
              <Alert type="warning" size="mini">
                {t('contactInfo.primary_email_note')}
                <Button
                  onClick={() => {
                    navigate(`/${MY_ACCOUNT}`);
                    window.scrollTo(0, 0);
                  }}
                  sx={{ textAlign: 'left', my: { sm: '0.6rem' } }}
                  style={linkStyle}
                >
                  {t('account.myAccount')}.
                </Button>
              </Alert>
            )}
          </FormControl>
        )}
        {additionalEmails && (
          <FormControl fullWidth>
            <Box sx={{ mt: 1, mb: -1, display: 'flex', flexDirection: 'row' }}>
              <InputLabel htmlFor="Email">{t('contactInfo.additional_email')}</InputLabel>
            </Box>
          </FormControl>
        )}
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: isProfileSetup ? 1 : 0.9 }}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <FormControl
                  fullWidth
                  required={primaryEmail}
                  error={!!errors?.[groupName]?.emails?.[emailIndex]?.email}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="caption" component="label" color="text.secondary">
                      {t('contactInfo.email_placeholder')}
                    </Typography>
                    <InputBase
                      placeholder={t('contactInfo.emails')}
                      inputProps={{
                        'aria-label': t('contactInfo.email_placeholder'),
                        maxLength: MAX_BIG_LENGTH_FIELD,
                        ...DISABLE_AUTO_FILL,
                      }}
                      disabled={primaryEmail}
                      {...register(`${groupName}.emails.${emailIndex}.email`, {
                        onChange: e => {
                          if (e.target.value === '') {
                            trigger(`${groupName}.emails.${emailIndex}.type.code`);
                            if (isEmpty(getValues(`${groupName}.emails.${emailIndex}.type.code`))) {
                              setValue(`${groupName}.emails.${emailIndex}.id`, undefined);
                            }
                          }
                        },
                      })}
                      onPaste={e => {
                        e.preventDefault();
                      }}
                    />
                  </Box>
                  <FormHelperText role="alert">
                    {errors?.[groupName]?.emails?.[emailIndex]?.email?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  required={primaryEmail}
                  error={!!errors?.[groupName]?.emails?.[emailIndex]?.type?.code}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="caption" component="label" color="text.secondary">
                      {t('contactInfo.emailType')}
                    </Typography>

                    <Controller
                      render={({ field: { ref, onChange, ...field } }) => (
                        <Dropdown
                          id={`emailtype-${emailIndex}`}
                          {...field}
                          inputRef={ref}
                          options={getFilteredEmailOptions(emailIndex, watchedFieldsList[0], emailOptions)}
                          fullWidth
                          inputProps={{
                            'aria-label': t('contactInfo.emailType'),
                            ...DISABLE_AUTO_FILL,
                          }}
                          onChange={option => {
                            if (isEmpty(option?.id)) {
                              trigger(`${groupName}.emails.${emailIndex}.email`);
                              if (isEmpty(getValues(`${groupName}.emails.${emailIndex}.email`))) {
                                setValue(`${groupName}.emails.${emailIndex}.id`, undefined);
                              }
                            }
                            setValue(`${groupName}.emails.${emailIndex}.type.displayName`, option?.text);
                            return onChange(option?.id ?? '');
                          }}
                          placeholder={t('contactInfo.type')}
                        />
                      )}
                      control={control}
                      name={`${groupName}.emails.${emailIndex}.type.code`}
                    />
                  </Box>
                  <StyledFormHelperText role="alert">
                    {errors?.[groupName]?.emails?.[emailIndex]?.type?.code?.message}
                  </StyledFormHelperText>
                </FormControl>
                <InputBase
                  inputProps={{ type: 'hidden' }}
                  {...register(`${groupName}.emails.${emailIndex}.type.displayName`)}
                />
              </Grid>
              {isProfileSetup && (
                <InputBase
                  inputProps={{ type: 'hidden' }}
                  {...register(`${groupName}.emails.${emailIndex}.preferred`)}
                  defaultValue={true}
                />
              )}
            </Grid>
          </Box>
          {!isProfileSetup && !primaryEmail && (
            <Box sx={{ flex: 0.1, display: 'flex', alignItems: 'center' }}>
              {additionalEmails ? (
                <>
                  {!hideEmailPlusButton ? (
                    <StyledIconButton
                      size="small"
                      aria-label={t('contactInfo.addEmailAddress')}
                      onClick={emailAddMoreClick}
                    >
                      <IconAdd />
                    </StyledIconButton>
                  ) : (
                    <Box sx={{ visibility: 'hidden' }}>
                      <StyledIconButton size="small" aria-label={t('contactInfo.addEmailAddress')}>
                        <IconAdd />
                      </StyledIconButton>
                    </Box>
                  )}
                </>
              ) : (
                <StyledIconButton
                  size="small"
                  aria-label={t('contactInfo.removeEmailAddress')}
                  onClick={() => {
                    setHideEmailPlusButton(false);
                    emailsList?.remove(emailIndex);
                  }}
                >
                  <IconRemove />
                </StyledIconButton>
              )}
            </Box>
          )}
        </Box>
      </>
    );
  };

  const PhoneContent = (phoneIndex: number) => {
    phonesCount += 1;
    const primaryPhone = phoneIndex === 0;
    const additionalPhoneNumbers = phoneIndex === 1;

    const uniquePhoneExists = validateDuplicates(
      getValues('contact.phones'),
      (phones: Record<string, string>) => phones.phone
    );
    return (
      <>
        {primaryPhone && (
          <FormControl fullWidth required>
            <Box sx={{ mt: 1, mb: -1, display: 'flex', flexDirection: 'row' }}>
              <InputLabel htmlFor="phoneNumber">{t('contactInfo.primary_phones')}</InputLabel>
              {!uniquePhoneExists && (
                <FormHelperText role="alert" sx={{ color: theme => theme.palette.error.main, ml: 1 }}>
                  {t('contactInfo.phone.duplicate_err')}
                </FormHelperText>
              )}
            </Box>
          </FormControl>
        )}
        {additionalPhoneNumbers && (
          <FormControl fullWidth>
            <Box sx={{ mt: 1, mb: -1, display: 'flex', flexDirection: 'row' }}>
              <InputLabel htmlFor="phoneNumber">{t('contactInfo.additional_phones')}</InputLabel>
            </Box>
          </FormControl>
        )}
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: isProfileSetup ? 1 : 0.9 }}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <FormControl
                  fullWidth
                  required={primaryPhone}
                  error={!!errors?.[groupName]?.phones?.[phoneIndex]?.phone}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      variant="caption"
                      component="label"
                      color="text.secondary"
                      sx={{ position: 'relative', left: '3.75rem' }}
                    >
                      {t('contactInfo.phoneNumber')}
                    </Typography>
                    <Controller
                      render={({ field }) => (
                        <PhoneField
                          label={t('contactInfo.phones')}
                          placeholder={t('contactInfo.phone_placeholder')}
                          {...field}
                          id={`phoneNumber-${phoneIndex}`}
                          onChange={option => {
                            if (isEmpty(option)) {
                              trigger(`${groupName}.phones.${phoneIndex}.type.code`);
                              if (isEmpty(getValues(`${groupName}.phones.${phoneIndex}.type.code`))) {
                                setValue(`${groupName}.phones.${phoneIndex}.id`, undefined);
                              }
                            }
                            field.onChange(option || '');
                          }}
                          limitMaxLength={true}
                        />
                      )}
                      name={`${groupName}.phones.${phoneIndex}.phone`}
                      control={control}
                    />
                  </Box>
                  <FormHelperText role="alert" id={`phoneNumber-error-${phoneIndex}`}>
                    {errors?.[groupName]?.phones?.[phoneIndex]?.phone?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  required={primaryPhone}
                  error={!!errors?.[groupName]?.phones?.[phoneIndex]?.type?.code}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="caption" component="label" color="text.secondary">
                      {t('contactInfo.phoneType')}
                    </Typography>
                    <Controller
                      render={({ field: { ref, onChange, ...field } }) => (
                        <Dropdown
                          id={`phoneType-${phoneIndex}`}
                          {...field}
                          inputRef={ref}
                          options={mobileOptions}
                          onChange={option => {
                            if (isEmpty(option?.id)) {
                              trigger(`${groupName}.phones.${phoneIndex}.phone`);
                              if (isEmpty(getValues(`${groupName}.phones.${phoneIndex}.phone`))) {
                                setValue(`${groupName}.phones.${phoneIndex}.id`, undefined);
                              }
                            }
                            setValue(`${groupName}.phones.${phoneIndex}.type.displayName`, option?.text);
                            return onChange(option?.id ?? '');
                          }}
                          placeholder={t('contactInfo.type')}
                          inputProps={{
                            'aria-label': t('contactInfo.phoneType'),
                            ...DISABLE_AUTO_FILL,
                          }}
                        />
                      )}
                      control={control}
                      name={`${groupName}.phones.${phoneIndex}.type.code`}
                    />
                  </Box>
                  <StyledFormHelperText role="alert" id={`${groupName}.phones.${phoneIndex}.phoneType-error`}>
                    {errors?.[groupName]?.phones?.[phoneIndex]?.type?.code?.message}
                  </StyledFormHelperText>
                </FormControl>
                <InputBase
                  inputProps={{ type: 'hidden' }}
                  {...register(`${groupName}.phones.${phoneIndex}.type.displayName`)}
                />
              </Grid>
              {isProfileSetup && (
                <InputBase
                  inputProps={{ type: 'hidden' }}
                  {...register(`${groupName}.phones.${phoneIndex}.preferred`)}
                  defaultValue={true}
                />
              )}
            </Grid>
          </Box>
          {!isProfileSetup && !primaryPhone && (
            <Box sx={{ flex: 0.1, display: 'flex', alignItems: 'center' }}>
              {additionalPhoneNumbers ? (
                <>
                  {!hidePhonePlusButton ? (
                    <StyledIconButton
                      size="small"
                      aria-label={t('contactInfo.addPhoneNumber')}
                      onClick={phoneAddMoreClick}
                    >
                      <IconAdd />
                    </StyledIconButton>
                  ) : (
                    <Box sx={{ visibility: 'hidden' }}>
                      <StyledIconButton size="small" aria-label={t('contactInfo.addPhoneNumber')}>
                        <IconAdd />
                      </StyledIconButton>
                    </Box>
                  )}
                </>
              ) : (
                <StyledIconButton
                  size="small"
                  aria-label={t('contactInfo.removePhoneNumber')}
                  onClick={() => {
                    setHidePhonePlusButton(false);
                    phoneNumbersList?.remove(phoneIndex);
                  }}
                >
                  <IconRemove />
                </StyledIconButton>
              )}
            </Box>
          )}
        </Box>
      </>
    );
  };

  return (
    <Grid sx={{ mt: '2rem' }}>
      <Typography
        variant="h2"
        sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
      >
        {t('contactInfo.title')}
      </Typography>
      {isProfileSetup
        ? EmailContent(0)
        : emailsList?.fields?.map((email, index) => (
            <Fragment key={email?.id}>
              {EmailContent(index)}
              {emailsList.fields.length === 1 && EmailContent(index + 1)}
            </Fragment>
          ))}
      {isProfileSetup
        ? PhoneContent(0)
        : phoneNumbersList?.fields?.map((phone, index) => (
            <Fragment key={phone?.id}>
              {PhoneContent(index)}
              {phoneNumbersList.fields.length === 1 && PhoneContent(index + 1)}
            </Fragment>
          ))}
      {JSON.stringify(data?.[groupName]?.addresses) && (
        <InputBase
          inputProps={{ type: 'hidden' }}
          {...register(`${groupName}.addresses`)}
          defaultValue={JSON.stringify(data?.[groupName]?.addresses)}
        />
      )}
      {!isProfileSetup && (
        <Box sx={{ mt: '1rem' }}>
          <AddressViewCard addresses={addresses} mdLayout={12} openChildDrawer={openChildDrawer} />
          {(addresses || [])?.length !== MasterData.addressTypes?.length && (
            <Box sx={{ justifyContent: 'center', display: 'flex' }}>
              <StyledAddButton
                onClick={() => openChildDrawer?.(true, addresses?.length || 0)}
                disabled={!isValid}
                sx={{ textTransform: 'inherit' }}
              >
                {t('contactInfo.addAddress')}
              </StyledAddButton>
            </Box>
          )}
        </Box>
      )}
    </Grid>
  );
};

export default memo(ContactInformation);
