/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { memo, ReactElement, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Typography, FormControl, FormHelperText, InputLabel, Box, Grid } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { DateField, MultiSelect, type TMultiSelectOption } from '@liaison/liaison-ui';
import MasterData from 'userProfile/constants/master';
import type { TCareerGoalsInfoField } from 'userProfile/store/careerInfo/careerInfo.slice';
import {
  changeDropDownOptions,
  getDropDownOptions,
  getMultiSelectColor,
  type IoptionModified,
  type Ioption,
} from 'utils/utilities';
import { selectIndustryOfInterestLookup } from 'store/common/commonInfo.selectors';
import { useTranslation } from 'react-i18next';
import { handleEmptyLookup } from '../CareerGoals.utils';

interface TCareerGoalsInfoProps {
  data: TCareerGoalsInfoField | null;
}

type TFormErrors = {
  startDate: { message: string };
};
const GoalInformationForm = ({ data }: TCareerGoalsInfoProps): ReactElement => {
  const {
    control,
    reset,
    formState: { errors: formErrors },
  } = useFormContext();
  const errors = formErrors as unknown as TFormErrors;
  const { t } = useTranslation();
  const industryOfInterestLookup = useSelector(selectIndustryOfInterestLookup);
  const typesOfEmploymentOptions = useMemo(() => getDropDownOptions(MasterData?.TypesOfEmployment), []);
  const typesOfRolesInterestOptions = useMemo(() => getDropDownOptions(MasterData?.TypesOfRolesInterest), []);
  const industryOfInterestOptions = useMemo(
    () => getDropDownOptions(industryOfInterestLookup || handleEmptyLookup(data)),
    [data, industryOfInterestLookup]
  );

  useEffect(() => {
    if (data) {
      reset(data);
    }
    return () => reset({});
  }, [reset, data]);

  const onChangeOptions = (option: TMultiSelectOption[] | null) => {
    const allIds = option?.map((item: TMultiSelectOption) => {
      const { id, text } = item;
      return { id, text };
    });
    return changeDropDownOptions(allIds as IoptionModified[]);
  };

  return (
    <Box>
      <Typography
        variant="h2"
        sx={{
          textTransform: 'uppercase',
          mb: '0.625rem',
          letterSpacing: '0.6px',
          fontSize: '1.125rem',
        }}
      >
        Career Goals
      </Typography>
      <FormControl fullWidth>
        <Grid container spacing={2}>
          <Controller
            name="startDate"
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Grid item sm={12} spacing={10}>
                <FormControl fullWidth error={!!errors?.startDate}>
                  <InputLabel sx={{ mb: 1 }} htmlFor="startDate">
                    {t('careerGoal.startDate')}
                  </InputLabel>
                  <DateField
                    {...field}
                    ref={ref}
                    error={!!error}
                    id="startDate"
                    hidePart="day"
                    aria-describedby="startDate-error"
                    accessibilityLabelPrefix={t('careerGoal.startDate')}
                  />
                  <FormHelperText role="alert" id="startDate-error">
                    {errors?.startDate?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
            )}
            control={control}
          />
        </Grid>
      </FormControl>
      <FormControl fullWidth sx={{ mt: -1 }}>
        <InputLabel htmlFor="type">{t('careerGoal.type')}</InputLabel>
        <Controller
          render={({ field: { onChange, ...field } }) => {
            const selectedIds = field?.value?.map((item: Ioption) => item?.code);
            return (
              <MultiSelect
                {...field}
                id="type"
                value={selectedIds}
                title={t('careerGoal.type')}
                options={typesOfEmploymentOptions}
                onChange={option => {
                  return onChange(onChangeOptions(option));
                }}
                aria-label={t('careerGoal.type')}
                colorTheme={getMultiSelectColor()}
              />
            );
          }}
          control={control}
          name="type"
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel htmlFor="roles">{t('careerGoal.roles')}</InputLabel>
        <Controller
          render={({ field: { onChange, ...field } }) => {
            const selectedIds = field?.value?.map((item: Ioption) => item?.code);
            return (
              <MultiSelect
                {...field}
                id="roles"
                value={selectedIds}
                title={t('careerGoal.roles')}
                options={typesOfRolesInterestOptions}
                onChange={option => {
                  return onChange(onChangeOptions(option));
                }}
                aria-label={t('careerGoal.roles')}
                colorTheme={getMultiSelectColor()}
              />
            );
          }}
          control={control}
          name="roles"
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel htmlFor="industries">{t('careerGoal.industries')}</InputLabel>
        <Controller
          render={({ field: { onChange, ...field } }) => {
            const selectedIds = field?.value?.map((item: Ioption) => item?.code);
            return (
              <MultiSelect
                {...field}
                id="industries"
                title={t('careerGoal.industries')}
                value={selectedIds}
                options={industryOfInterestOptions}
                onChange={option => {
                  return onChange(onChangeOptions(option));
                }}
                aria-label={t('careerGoal.industries')}
                colorTheme={getMultiSelectColor()}
              />
            );
          }}
          control={control}
          name="industries"
        />
      </FormControl>
    </Box>
  );
};

export default memo(GoalInformationForm);
