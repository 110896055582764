/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useEffect } from 'react';
import { Box, Typography, FormHelperText, FormControl, InputLabel } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { nameSpace } from 'transferPlanner/constants/general';
import { Dropdown } from '@liaison/liaison-ui';
import { selectAdditionalDetailsLoading } from 'transferPlanner/store/additionalDetails/additionalDetails.selectors';
import { Spinner } from 'components/Spinner';
import { StyledDivider } from 'pages/Pages.styles';
import { ITpAdditionalDetails } from 'transferPlanner/store/additionalDetails/additionalDetails.slice';
import { raceOptions, Race, educationLevel, usMilitoryStatus } from '../TpAdditionalDetails.utils';

type TBiographicalInformationFormError = {
  biographicInformationExtensions: {
    guardian1EducationLevel: { code: { message: string } };
    guardian2EducationLevel: { code: { message: string } };
    extendedMilitaryStatus: { code: { message: string } };
  };
  raceEthnicitySummary: { code: { message: string } };
};

type TAdtInformation = {
  data?: ITpAdditionalDetails | null;
};
const BiographicalInformationForm = ({ data }: TAdtInformation): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const isAdditionalDetailsLoading = useSelector(selectAdditionalDetailsLoading);
  const {
    control,
    formState: { errors },
    setValue,
    reset,
  } = useFormContext();

  const biErrors = errors as unknown as TBiographicalInformationFormError;

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [reset, data]);

  return (
    <>
      {isAdditionalDetailsLoading && <Spinner backdrop />}
      <Box>
        <Typography sx={{ mb: 2 }} variant="h1">
          {t('tpAdditionalInfo.additionalInfo')}
        </Typography>
        <Typography sx={{ mb: 2, fontFamily: 'Proxima Nova Regular' }} variant="h2">
          {t('tpAdditionalInfo.biographicalInfo')}
        </Typography>
        <StyledDivider />

        <Typography sx={{ mt: 2, mb: 2 }} variant="h3">
          {t('tpAdditionalInfo.educationLevel.heading')}
        </Typography>
        <Box sx={{ pt: { xs: '1rem', md: '1.25rem' } }}>
          <FormControl
            fullWidth
            required
            error={!!biErrors?.biographicInformationExtensions?.guardian1EducationLevel?.code}
          >
            <InputLabel htmlFor="guardian1EducationLevel">{t('tpAdditionalInfo.educationLevel.question1')}</InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  id="guardian1EducationLevel"
                  {...field}
                  options={educationLevel}
                  onChange={(option: Race | null) => {
                    setValue('biographicInformationExtensions.guardian1EducationLevel.displayName', option?.text ?? '');
                    return onChange(option?.id ?? '');
                  }}
                  inputProps={{
                    'aria-label': `${t('tpAdditionalInfo.educationLevelQuestion.label')}-1`,
                    'aria-describedby': 'guardian1EducationLevelError',
                    autoComplete: 'off',
                  }}
                />
              )}
              control={control}
              name="biographicInformationExtensions.guardian1EducationLevel.code"
            />
            <FormHelperText role="alert" id="guardian1EducationLevelError">
              {biErrors?.biographicInformationExtensions?.guardian1EducationLevel?.code?.message as string}
            </FormHelperText>
          </FormControl>
        </Box>
        <Box>
          <FormControl
            fullWidth
            required
            error={!!biErrors?.biographicInformationExtensions?.guardian2EducationLevel?.code}
          >
            <InputLabel htmlFor="guardian2EducationLevel">{t('tpAdditionalInfo.educationLevel.question2')}</InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  id="guardian2EducationLevel"
                  {...field}
                  options={educationLevel}
                  onChange={(option: Race | null) => {
                    setValue('biographicInformationExtensions.guardian2EducationLevel.displayName', option?.text ?? '');
                    return onChange(option?.id ?? '');
                  }}
                  inputProps={{
                    'aria-label': `${t('tpAdditionalInfo.educationLevelQuestion.label')}-2`,
                    'aria-describedby': 'guardian2EducationLevelError',
                    autoComplete: 'off',
                  }}
                />
              )}
              control={control}
              name="biographicInformationExtensions.guardian2EducationLevel.code"
            />
            <FormHelperText role="alert" id="guardian2EducationLevelError">
              {biErrors?.biographicInformationExtensions?.guardian2EducationLevel?.code?.message as string}
            </FormHelperText>
          </FormControl>
        </Box>
        <StyledDivider />

        <Typography sx={{ mt: 2, mb: 2 }} variant="h3">
          {t('tpAdditionalInfo.militaryStatus.heading')}
        </Typography>
        <Box sx={{ pt: { xs: '1rem', md: '1.25rem' } }}>
          <FormControl
            fullWidth
            required
            error={!!biErrors?.biographicInformationExtensions?.extendedMilitaryStatus?.code}
          >
            <InputLabel htmlFor="extendedMilitaryStatus">{t('tpAdditionalInfo.militaryStatus.question1')}</InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  id="extendedMilitaryStatus"
                  {...field}
                  options={usMilitoryStatus}
                  onChange={(option: Race | null) => {
                    setValue('biographicInformationExtensions.extendedMilitaryStatus.displayName', option?.text ?? '');
                    return onChange(option?.id ?? '');
                  }}
                  inputProps={{
                    'aria-label': t('tpAdditionalInfo.militaryStatus.label'),
                    'aria-describedby': 'extendedMilitaryStatusError',
                    autoComplete: 'off',
                  }}
                />
              )}
              control={control}
              name="biographicInformationExtensions.extendedMilitaryStatus.code"
            />
            <FormHelperText role="alert" id="extendedMilitaryStatusError">
              {biErrors?.biographicInformationExtensions?.extendedMilitaryStatus?.code?.message as string}
            </FormHelperText>
          </FormControl>
        </Box>
        <StyledDivider />

        <Typography sx={{ mt: 2, mb: 2 }} variant="h3">
          {t('tpAdditionalInfo.race')}
        </Typography>
        <Box sx={{ pt: { xs: '1rem', md: '1.25rem' } }}>
          <FormControl fullWidth required error={!!biErrors?.raceEthnicitySummary?.code}>
            <InputLabel htmlFor="raceField">{t('tpAdditionalInfo.race.question')}</InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  id="raceField"
                  {...field}
                  options={raceOptions}
                  onChange={(option: Race | null) => {
                    setValue('raceEthnicitySummary.displayName', option?.text ?? '');
                    return onChange(option?.id ?? '');
                  }}
                  inputProps={{
                    'aria-label': t('tpAdditionalInfo.race'),
                    'aria-describedby': 'raceEthnicitySummaryError',
                    autoComplete: 'off',
                  }}
                />
              )}
              control={control}
              name="raceEthnicitySummary.code"
            />
            <FormHelperText role="alert" id="raceEthnicitySummaryError">
              {biErrors?.raceEthnicitySummary?.code?.message as string}
            </FormHelperText>
          </FormControl>
        </Box>
      </Box>
    </>
  );
};

export default memo(BiographicalInformationForm);
