/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useEffect, useMemo, useState } from 'react';
import { Typography, FormControl, InputLabel, Box, InputBase } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { TBiographicalInfoField } from 'userProfile/store/biographicalInfo/biographicalInfo.slice';
import { Dropdown } from '@liaison/liaison-ui';
import { RadioControl } from 'components/RadioControl';
import { getDropDownOptions } from 'utils/utilities';
import { selectCountriesLookup } from 'store/common/commonInfo.selectors';
import MasterData from 'userProfile/constants/master';
import { DISABLE_AUTO_FILL } from 'constants/field';
import { StyledFormHelperText } from 'pages/Pages.styles';
import { useTranslation } from 'react-i18next';
import type { TBiographicFormErrors } from '../BiographicalInformationForm.validation';

interface IBiographicalInfoProps {
  data: TBiographicalInfoField | null;
}

const CitizenshipInformationForm = ({ data }: IBiographicalInfoProps): ReactElement => {
  const {
    control,
    reset,
    watch,
    getValues,
    setValue,
    register,
    formState: { errors: formErrors },
  } = useFormContext();
  const errors = formErrors as unknown as TBiographicFormErrors;
  const groupName = 'citizenship';
  const usCitizen = 'US_CITIZEN';
  const { t } = useTranslation();
  const countriesLookup = useSelector(selectCountriesLookup);
  const usCitizenshipOptions = useMemo(() => getDropDownOptions(MasterData?.usCitizenshipStatusOptions), []);
  const countryOptions = useMemo(() => getDropDownOptions(countriesLookup || []), [countriesLookup]);
  const radioDefaultOptions = useMemo(() => MasterData?.radioDefaultOptions, []);
  const dualCitizenshipVal = watch(`${groupName}.dualCitizenship`) || getValues(`${groupName}.dualCitizenship`);
  const [countriesList, setCountriesList] = useState(countryOptions);
  const [secondCountryList, setSecondCountryList] = useState(countriesList);
  const [countryFieldDisabled, setCountryFieldDisabled] = useState(false);
  const countryOfCitizenshipValue = watch(`${groupName}.country.code`);
  const usCitizenshipStatusValue = watch(`${groupName}.status.code`);
  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [reset, data]);

  useEffect(() => {
    if (usCitizenshipStatusValue === usCitizen) {
      setCountryFieldDisabled(true);
    }

    if (usCitizenshipStatusValue !== usCitizen) {
      setCountriesList(countryOptions.filter(country => country.id !== 'US'));
    } else {
      setCountriesList(countryOptions);
    }
  }, [usCitizenshipStatusValue, countryOptions]);

  useEffect(() => {
    if (countryOfCitizenshipValue) {
      const secondCountryOfCitizenshipValue = getValues(`${groupName}.secondCountry.code`);
      if (secondCountryOfCitizenshipValue === countryOfCitizenshipValue) {
        setValue(`${groupName}.secondCountry`, { code: '', displayValue: '' });
      }
      setSecondCountryList(countriesList.filter(country => country.id !== countryOfCitizenshipValue));
    } else {
      setValue(`${groupName}.secondCountry`, { code: '', displayValue: '' });
    }
  }, [countryOfCitizenshipValue, countriesList, getValues, setValue]);

  return (
    <Box sx={{ mt: '2rem' }}>
      <Typography
        variant="h2"
        sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
      >
        {t('citizenshipInfo.title')}
      </Typography>
      <FormControl fullWidth>
        <InputLabel htmlFor="usCitizenshipStatus">{t('citizenshipInfo.status')}</InputLabel>
        <Controller
          render={({ field: { onChange, ...field } }) => (
            <Dropdown
              {...field}
              id="usCitizenshipStatus"
              options={usCitizenshipOptions}
              fullWidth
              inputProps={{
                'aria-describedby': 'usCitizenshipStatus-error',
                'aria-label': t('citizenshipInfo.status'),
                ...DISABLE_AUTO_FILL,
              }}
              onChange={option => {
                if (option?.id === usCitizen) {
                  setValue(`${groupName}.country.code`, 'US');
                  setValue(`${groupName}.country.displayName`, 'United States');
                  setCountryFieldDisabled(true);
                } else {
                  setValue(`${groupName}.country.code`, '');
                  setValue(`${groupName}.country.displayName`, '');
                  setCountryFieldDisabled(false);
                }
                setValue(`${groupName}.status.displayName`, option?.text);
                return onChange(option?.id ?? null);
              }}
            />
          )}
          control={control}
          name={`${groupName}.status.code`}
        />
        <StyledFormHelperText role="alert" id="usCitizenshipStatus-error">
          {errors?.[groupName]?.status?.code?.message}
        </StyledFormHelperText>
      </FormControl>
      <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.status.displayName`)} />
      <FormControl fullWidth>
        <InputLabel htmlFor="countryOfCitizenship">{t('citizenshipInfo.country')}</InputLabel>
        <Controller
          render={({ field: { onChange, ...field } }) => (
            <Dropdown
              {...field}
              id="countryOfCitizenship"
              options={countriesList}
              fullWidth
              inputProps={{
                'aria-label': t('citizenshipInfo.country'),
                ...DISABLE_AUTO_FILL,
              }}
              onChange={option => {
                setValue(`${groupName}.country.displayName`, option?.text);
                return onChange(option?.id ?? null);
              }}
              data-testid="countryOfCitizenshipTestId"
              disabled={countryFieldDisabled}
            />
          )}
          control={control}
          name={`${groupName}.country.code`}
        />
      </FormControl>
      <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.country.displayName`)} />
      <FormControl fullWidth>
        <InputLabel htmlFor="dualCitizenship">{t('citizenshipInfo.dualCitizenship')}</InputLabel>
        <Controller
          name={`${groupName}.dualCitizenship`}
          render={({ field: { ref, ...field } }) => (
            <RadioControl
              {...field}
              inputRef={ref}
              id="dualCitizenship"
              options={radioDefaultOptions}
              inline={true}
              error={!!errors?.[groupName]?.dualCitizenship?.message}
              aria-describedby="dualCitizenship-error"
            />
          )}
          control={control}
          defaultValue=""
        />
        <StyledFormHelperText role="alert" id="dualCitizenship-error">
          {errors?.[groupName]?.dualCitizenship?.message}
        </StyledFormHelperText>
      </FormControl>
      {dualCitizenshipVal === 'Yes' && (
        <>
          <FormControl fullWidth>
            <InputLabel htmlFor="secondCountryOfCitizenship">{t('citizenshipInfo.secondCountry')}</InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  {...field}
                  id="secondCountryOfCitizenship"
                  options={secondCountryList}
                  fullWidth
                  disabled={!countryOfCitizenshipValue}
                  inputProps={{
                    'aria-label': t('citizenshipInfo.secondCountry'),
                    ...DISABLE_AUTO_FILL,
                  }}
                  onChange={option => {
                    setValue(`${groupName}.secondCountry.displayName`, option?.text);
                    return onChange(option?.id ?? null);
                  }}
                />
              )}
              control={control}
              name={`${groupName}.secondCountry.code`}
            />
          </FormControl>
          <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.secondCountry.displayName`)} />
        </>
      )}
    </Box>
  );
};

export default memo(CitizenshipInformationForm);
