/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@liaison/liaison-ui';

import { useAppSelector } from 'redux/hooks';
import { selectFeatures } from 'store/features/features.selectors';
import { getProfileMenus } from './ProfileMenus';
import { StyledSectionBox } from './UserNav.styles';

function UserNav(): ReactElement {
  const { pathname } = useLocation();
  const [activePage, setActivePage] = useState(pathname.replace(/^\//, ''));
  const prefix = localStorage.getItem('tenant');
  const navigate = useNavigate();
  const features = useAppSelector(selectFeatures);

  const profileMenus = getProfileMenus(features);

  const handleItemClick = (location: string) => {
    setActivePage(location);
    navigate(`/${location}`);
    window.scrollTo(0, 0);
  };

  return (
    <Grid container item direction="column" justifyContent="space-between" sx={{ marginTop: '1rem' }}>
      {profileMenus?.map(menu => {
        return (
          <StyledSectionBox
            onClick={() => handleItemClick(menu?.location)}
            key={menu?.label}
            className={activePage === menu.location ? `${prefix}-buttonActive` : `${prefix}-button`}
            aria-current={activePage === menu.location}
          >
            <Icon
              aria-label={menu?.label}
              sx={{
                padding: '0.2rem',
                fontSize: '2rem',
                color: theme => theme.palette.primary.contrastText,
              }}
            >
              {menu?.icon}
            </Icon>
            <Typography
              align="left"
              variant="body1"
              color="text"
              sx={{
                margin: '5px 0px 5px 10px',
              }}
              aria-label={menu?.label}
            >
              {menu?.label}
            </Typography>
          </StyledSectionBox>
        );
      })}
    </Grid>
  );
}

export default memo(UserNav);
