/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */
import React, { memo, ReactElement, useEffect, useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography,
  FormControl,
  FormHelperText,
  InputLabel,
  Box,
  Grid,
  Switch,
  Divider,
  InputBase,
} from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { SchoolsOfInterestsCard } from 'userProfile/components/ViewBuilder/CardView';
import { DateField, Dropdown, MultiSelect, TMultiSelectOption } from '@liaison/liaison-ui';
import { TAcademicGoalsInfoField } from 'userProfile/store/academicInfo/academicInfo.slice';
import { DISABLE_AUTO_FILL } from 'constants/field';
import {
  changeDropDownOptions,
  getCheckboxColor,
  getDropDownOptions,
  getMultiSelectColor,
  type IoptionModified,
} from 'utils/utilities';
import { getLookUps } from 'utils/commonUtils';
import MasterData from 'userProfile/constants/master';
import { MultiSelectWithSidePanel } from 'userProfile/components/MultiSelectAreasOfStudy';
import { useTranslation } from 'react-i18next';
import { StyledAddButton } from 'pages/Pages.styles';
import {
  selectFieldsOfStudyLookup,
  selectCountriesLookup,
  selectAreasOfStudyLookup,
} from 'store/common/commonInfo.selectors';
import type { Ioption } from 'store/common/commonInfo.slice';
import { RadioControl } from 'components/RadioControl';
import NumberFormat from 'react-number-format';
import { handleEmptyLookup, getLocationInterestsFormData } from '../AcademicGoals.utils';

interface IAcademicGoalsInfoProps {
  data: TAcademicGoalsInfoField | null;
  openChildDrawer: (isAddNew: boolean, childPosition: number, selector?: string, formTitle?: string) => void;
  deleteRecord: (childPosition: number) => void;
}

type TGoalsFormErrors = {
  collegeGPA: { message: string };
  highSchoolGPA: { message: string };
  startDate: { message: string };
  studiedAbroad: { message: string };
};

const typesOfEmploymentOptions = getDropDownOptions(MasterData?.ModalityOfEducation);
const typesOfProgramType = getDropDownOptions(MasterData?.ProgramType);
const educationLevelOptions = getDropDownOptions(MasterData?.HighestLevelEducation);
const typesOfDegreeSeekingOptions = getDropDownOptions(MasterData?.TypesOfDegreeSeeking);
const typesOfAcademicInterestsOptions = getDropDownOptions(MasterData?.TypesOfAcademicInterests);
const radioDefaultOptions = MasterData?.radioDefaultOptions;

const GoalInformationForm = ({ data, openChildDrawer, deleteRecord }: IAcademicGoalsInfoProps): ReactElement => {
  const [countries, setCountries] = useState<{ [key: string]: IoptionModified[] }>({});
  const {
    control,
    reset,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fieldsOfStudyLookup = useSelector(selectFieldsOfStudyLookup);
  const areasOfStudyLookup = useSelector(selectAreasOfStudyLookup);
  const countriesLookup = useSelector(selectCountriesLookup);
  const countryOptions = useMemo(() => getDropDownOptions(countriesLookup || []), [countriesLookup]);
  const fieldsOfStudyOptions = useMemo(
    () => getDropDownOptions(fieldsOfStudyLookup || handleEmptyLookup(data)),
    [fieldsOfStudyLookup, data]
  );
  const areasOfStudyOptions = useMemo(() => {
    const options = getDropDownOptions(areasOfStudyLookup || handleEmptyLookup(data));
    return options.sort((a, b) => a?.text.localeCompare(b?.text));
  }, [areasOfStudyLookup, data]);

  const selectedCountries = watch('locationInterests');
  const formFields = watch('fields') as Ioption[];
  const fieldsOfStudyCodes = useMemo(() => formFields?.map(obj => obj.code), [formFields]);
  const fieldsOfStudyDisplay = useMemo(() => formFields?.map(obj => obj.displayName), [formFields]);
  const getStateList = useCallback(
    async (countryId: string) => {
      const countryStates = (await dispatch(getLookUps('states', `?countryCode=${countryId}`))) as unknown as Ioption[];
      const countryStatesOptions = getDropDownOptions(countryStates || []);
      setCountries(prevState => ({ ...prevState, [countryId]: countryStatesOptions }));
    },
    [dispatch, setCountries]
  );

  const goalsFormErrors = errors as unknown as TGoalsFormErrors;
  useEffect(() => {
    if (data) {
      let locationInterests = {};
      if (data?.locationInterests) {
        locationInterests = getLocationInterestsFormData(data?.locationInterests, getStateList);
      }
      reset({
        ...data,
        ...locationInterests,
      });
    }
    return () => reset({});
  }, [reset, getStateList, data]);

  const onChangeOptions = (option: TMultiSelectOption[] | null) => {
    const allIds = option?.map((item: TMultiSelectOption) => {
      const { id, text } = item;
      return { id, text };
    });
    return changeDropDownOptions(allIds as IoptionModified[]);
  };
  /* istanbul ignore next */
  const onChangeOptionsAreasOfStudy = (option: TMultiSelectOption[] | null) => {
    const allIds = option?.map((item: TMultiSelectOption) => {
      const { id, text } = item;
      return { id, text, field: { code: (item as unknown as Ioption).fieldsOfStudyCode } };
    });
    return changeDropDownOptions(allIds as IoptionModified[]);
  };
  const onChangeCountryOptions = (options: TMultiSelectOption[] | null) => {
    const countryId = options?.[options.length - 1]?.id;
    if (countryId && !(countryId in countries)) {
      setCountries({ ...countries, [countryId]: [] });
      getStateList(countryId);
    }
    return options?.map((option: TMultiSelectOption) => ({
      code: option.id,
      displayName: option.text,
    }));
  };
  return (
    <Box>
      <Typography
        variant="h2"
        sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
      >
        {t('academicGoalVisibleToInstitutions.title')}
      </Typography>
      <Grid container spacing={1} sx={{ mb: 2 }}>
        <Grid item xs={9}>
          <Typography variant="subtitle4">{t('academicGoalVisibleToInstitutions.subtitle')}</Typography>
          <FormHelperText sx={{ whiteSpace: 'initial' }} role="alert" id="name-error">
            {t('academicGoalVisibleToInstitutions.helper')}
          </FormHelperText>
        </Grid>
        <Grid item xs={3} justifyContent="flex-end">
          <Controller
            control={control}
            name="visible"
            defaultValue={false}
            render={({ field: { onChange, value, ...field } }) => {
              return (
                <Switch
                  color={getCheckboxColor()}
                  {...field}
                  checked={value === 'Yes'}
                  onChange={(_event, val) => {
                    return onChange(val ? 'Yes' : 'No');
                  }}
                  inputProps={{
                    'aria-label': 'visible to institutions',
                  }}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Divider sx={{ borderStyle: 'solid', mt: '0.7rem', mb: '1rem' }} flexItem />
      <Typography
        variant="h2"
        sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
      >
        {t('academicGoal.educationalProgress.title')}
      </Typography>
      <FormControl fullWidth sx={{ mt: -1 }}>
        <InputLabel htmlFor="highestLevelEdu"> {t('highestLevelEdu')}</InputLabel>
        <Controller
          render={({ field: { onChange, ...field } }) => {
            return (
              <Dropdown
                {...field}
                id="highestLevelEdu"
                options={educationLevelOptions}
                fullWidth
                inputProps={{
                  'aria-label': 'educationalLevel',
                  'aria-describedby': 'educationalLevel-error',
                  ...DISABLE_AUTO_FILL,
                }}
                onChange={option => {
                  setValue(`educationalLevel.displayName`, option?.text);
                  return onChange(option?.id ?? '');
                }}
              />
            );
          }}
          control={control}
          name="educationalLevel.code"
        />
      </FormControl>
      <InputBase inputProps={{ type: 'hidden' }} {...register('educationalLevel.displayName')} />
      <Grid container spacing={2}>
        <Controller
          name="highSchoolGPA"
          render={({ field: { onChange, ...field } }) => (
            <Grid item sm={12}>
              <FormControl fullWidth error={!!goalsFormErrors?.highSchoolGPA}>
                <InputLabel sx={{ mb: 0.5 }} htmlFor="highSchoolGPA">
                  {t('academicGoal.highSchoolGPA')}
                </InputLabel>
                <Typography variant="caption" sx={{ whiteSpace: 'initial', mb: 1 }}>
                  {t('academicGoal.highSchoolGPA.helper')}
                </Typography>
                <NumberFormat
                  {...field}
                  decimalScale={2}
                  decimalSeparator="."
                  allowNegative={false}
                  fixedDecimalScale={false}
                  customInput={InputBase}
                  onValueChange={values => onChange(values?.floatValue)}
                  inputProps={{ 'aria-label': t('academicGoal.highSchoolGPA') }}
                />
                <FormHelperText role="alert" id="highSchoolGPA-error">
                  {goalsFormErrors?.highSchoolGPA?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
          control={control}
        />
      </Grid>
      <Grid container spacing={2}>
        <Controller
          name="collegeGPA"
          render={({ field: { onChange, ...field } }) => (
            <Grid item sm={12}>
              <FormControl fullWidth error={!!goalsFormErrors?.collegeGPA}>
                <InputLabel sx={{ mb: 0.5 }} htmlFor="collegeGPA">
                  {t('academicGoal.collegeGPA')}
                </InputLabel>
                <Typography variant="caption" sx={{ whiteSpace: 'initial', mb: 1 }}>
                  {t('academicGoal.collegeGPA.helper')}
                </Typography>
                <NumberFormat
                  {...field}
                  decimalScale={2}
                  decimalSeparator="."
                  allowNegative={false}
                  fixedDecimalScale={false}
                  customInput={InputBase}
                  onValueChange={values => onChange(values?.floatValue)}
                  inputProps={{ 'aria-label': t('academicGoal.collegeGPA') }}
                />
                <FormHelperText role="alert" id="collegeGPA-error">
                  {goalsFormErrors?.collegeGPA?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
          control={control}
        />
      </Grid>
      <Divider sx={{ borderStyle: 'solid', mt: '0.7rem', mb: '1rem' }} flexItem />
      <Typography
        variant="h2"
        sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
      >
        {t('academicGoal.title')}
      </Typography>
      <Grid container spacing={2}>
        <Controller
          name="startDate"
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Grid item sm={12}>
              <FormControl fullWidth error={!!goalsFormErrors?.startDate}>
                <InputLabel sx={{ mb: 1 }} htmlFor="startDate">
                  {t('academicGoal.startDate')}
                </InputLabel>
                <DateField
                  {...field}
                  ref={ref}
                  error={!!error}
                  id="startDate"
                  hidePart="day"
                  aria-describedby="startDate-error"
                  accessibilityLabelPrefix={t('academicGoal.startDate')}
                />
                <FormHelperText role="alert" id="startDate-error">
                  {goalsFormErrors?.startDate?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
          control={control}
        />
      </Grid>
      <FormControl fullWidth sx={{ mt: -1 }}>
        <InputLabel htmlFor="modalityOfEducation"> {t('academicGoal.modalityOfEducation')}</InputLabel>
        <Controller
          // eslint-disable-next-line no-unused-vars
          render={({ field: { ref, onChange, ...field } }) => {
            const selectedIds = field?.value?.map((item: Ioption) => item?.code);
            return (
              <MultiSelect
                {...field}
                id="modalityOfEducation"
                value={selectedIds}
                title={t('academicGoal.modalityOfEducation')}
                options={typesOfEmploymentOptions}
                onChange={option => {
                  return onChange(onChangeOptions(option));
                }}
                aria-label={t('academicGoal.modalityOfEducation')}
                colorTheme={getMultiSelectColor()}
              />
            );
          }}
          control={control}
          name="type"
        />
      </FormControl>
      <FormControl fullWidth sx={{ mt: -1 }}>
        <InputLabel htmlFor="programType"> {t('academicGoal.programType')}</InputLabel>
        <Controller
          // eslint-disable-next-line no-unused-vars
          render={({ field: { ref, onChange, ...field } }) => {
            const selectedIds = field?.value?.map((item: Ioption) => item?.code);
            return (
              <MultiSelect
                {...field}
                id="programType"
                value={selectedIds}
                title={t('academicGoal.programType')}
                options={typesOfProgramType}
                onChange={option => {
                  return onChange(onChangeOptions(option));
                }}
                aria-label={t('academicGoal.programType')}
                colorTheme={getMultiSelectColor()}
              />
            );
          }}
          control={control}
          name="programType"
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel htmlFor="degrees">{t('academicGoal.degrees')}</InputLabel>
        <Controller
          // eslint-disable-next-line no-unused-vars
          render={({ field: { ref, onChange, ...field } }) => {
            const selectedIds = field?.value?.map((item: Ioption) => item?.code);
            return (
              <MultiSelect
                {...field}
                id="degrees"
                title={t('academicGoal.degrees')}
                options={typesOfDegreeSeekingOptions}
                value={selectedIds}
                onChange={option => {
                  return onChange(onChangeOptions(option));
                }}
                aria-label={t('academicGoal.degrees')}
                colorTheme={getMultiSelectColor()}
              />
            );
          }}
          control={control}
          name="degrees"
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel htmlFor="degrees">{t('academicGoal.academicInterests')}</InputLabel>
        <Controller
          // eslint-disable-next-line no-unused-vars
          render={({ field: { ref, onChange, ...field } }) => {
            const selectedIds = field?.value?.map((item: Ioption) => item?.code);
            return (
              <MultiSelect
                {...field}
                id="academicInterests"
                title={t('academicGoal.academicInterests')}
                options={typesOfAcademicInterestsOptions}
                value={selectedIds}
                onChange={option => {
                  return onChange(onChangeOptions(option));
                }}
                aria-label={t('academicGoal.academicInterests')}
                colorTheme={getMultiSelectColor()}
              />
            );
          }}
          control={control}
          name="academicInterests"
        />
      </FormControl>
      <Divider sx={{ borderStyle: 'solid', mt: '0.7rem', mb: '1rem' }} flexItem />
      <Typography
        variant="h2"
        sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
      >
        {t('interests.title')}
      </Typography>
      <FormControl fullWidth>
        <InputLabel htmlFor="fields">{t('interests.fieldsOfStudy')}</InputLabel>
        <Controller
          // eslint-disable-next-line no-unused-vars
          render={({ field: { ref, onChange, ...field } }) => {
            const selectedIds = field?.value?.map((item: Ioption) => item?.code);
            return (
              <MultiSelect
                {...field}
                id="fields"
                title={t('interests.fieldsOfStudy')}
                value={selectedIds}
                options={fieldsOfStudyOptions}
                onChange={option => {
                  return onChange(onChangeOptions(option));
                }}
                aria-label={t('interests.fieldsOfStudy')}
                colorTheme={getMultiSelectColor()}
              />
            );
          }}
          control={control}
          name="fields"
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel htmlFor="areasOfStudy">{t('academicGoal.areasOfStudy')}</InputLabel>
        <Controller
          // eslint-disable-next-line no-unused-vars
          render={({ field: { ref, onChange, ...field } }) => {
            const selectedIds = field?.value?.map((item: Ioption) => item?.code);
            return (
              <MultiSelectWithSidePanel
                {...field}
                id="areasOfStudy"
                fieldsOfStudyCodes={fieldsOfStudyCodes}
                fieldsOfStudyDisplay={fieldsOfStudyDisplay}
                title={t('academicGoal.areasOfStudy')}
                value={selectedIds}
                options={areasOfStudyOptions}
                onChange={option => {
                  return onChange(onChangeOptionsAreasOfStudy(option));
                }}
                aria-label={t('academicGoal.areasOfStudy')}
                colorTheme={getMultiSelectColor()}
              />
            );
          }}
          control={control}
          name="areas"
        />
      </FormControl>
      <Divider sx={{ borderStyle: 'solid', mt: '0.7rem', mb: '1rem' }} flexItem />
      <Typography
        variant="h2"
        sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
      >
        {t('academicGoal.studyArea')}
      </Typography>
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="studiedAbroad">{t('academicGoal.internationalStudent')}</InputLabel>
            <Controller
              name="studiedAbroad"
              render={({ field: { ref, ...field } }) => (
                <RadioControl
                  {...field}
                  inputRef={ref}
                  id="studiedAbroad"
                  options={radioDefaultOptions}
                  inline={true}
                  error={!!goalsFormErrors?.studiedAbroad?.message}
                  aria-describedby="studiedAbroad-error"
                />
              )}
              control={control}
              defaultValue=""
            />
            <FormHelperText role="alert" id="studiedAbroad-error">
              {goalsFormErrors?.studiedAbroad?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      {JSON.stringify(data?.schools) && (
        <InputBase
          inputProps={{ type: 'hidden' }}
          {...register('schools')}
          defaultValue={JSON.stringify(data?.schools)}
        />
      )}
      <FormControl fullWidth>
        <InputLabel htmlFor="fields">{t('academicGoal.locationInterests')}</InputLabel>
        <Controller
          // eslint-disable-next-line no-unused-vars
          render={({ field: { ref, onChange, ...field } }) => {
            const selectedIds = field?.value?.map((item: Ioption) => item?.code);
            return (
              <MultiSelect
                {...field}
                id="locationInterests"
                title={t('academicGoal.locationInterests')}
                value={selectedIds}
                options={countryOptions}
                onChange={option => {
                  return onChange(onChangeCountryOptions(option));
                }}
                aria-label={t('academicGoal.locationInterests')}
                colorTheme={getMultiSelectColor()}
              />
            );
          }}
          control={control}
          name="locationInterests"
        />
      </FormControl>
      <Box sx={{ bgcolor: '#EBEFF6', mt: 0, mb: 1 }}>
        {selectedCountries?.map(({ code, displayName }: Ioption, i: number) => {
          const stateOptions = countries[code] || [];
          return (
            <FormControl
              key={code}
              fullWidth
              sx={{ p: '1rem', pb: 0, ...(i !== selectedCountries.length - 1 && { mb: 0 }) }}
            >
              <InputLabel htmlFor={`locationInterests-${code}`}>{`${displayName} - ${t(
                'academicGoal.locationInterests.region'
              )}`}</InputLabel>
              <Controller
                // eslint-disable-next-line no-unused-vars
                render={({ field: { ref, onChange, ...field } }) => {
                  const selectedStateIds = field?.value?.map((item: Ioption) => item?.code);
                  return (
                    <MultiSelect
                      {...field}
                      id={`locationInterests-${code}`}
                      title={`${displayName} - ${t('academicGoal.locationInterests.region')}`}
                      value={selectedStateIds}
                      options={stateOptions}
                      onChange={option => {
                        return onChange(onChangeOptions(option));
                      }}
                      aria-label={`${displayName} - ${t('academicGoal.locationInterests.region')}`}
                      colorTheme={getMultiSelectColor()}
                    />
                  );
                }}
                control={control}
                name={`locationInterests-${code}`}
              />
            </FormControl>
          );
        })}
      </Box>
      <Box>
        <InputLabel htmlFor="schoolsOfInterest">{t('academicGoal.schoolsOfInterest')}</InputLabel>
        {data && data?.schools && (
          <SchoolsOfInterestsCard schools={data?.schools} deleteRecord={deleteRecord} displayDeleteIcon={true} />
        )}
        <Box sx={{ mt: '1rem', justifyContent: 'center', display: 'flex' }}>
          <StyledAddButton onClick={() => openChildDrawer(true, data?.schools?.length || 0)}>
            {t('academicGoal.addSchool')}
          </StyledAddButton>
        </Box>
      </Box>
    </Box>
  );
};

export default memo(GoalInformationForm);
