/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { useState, ReactElement, KeyboardEvent, Fragment, useEffect } from 'react';
import { Box, Container, List, ListItem, ListItemText, Divider, Typography, Theme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'transferPlanner/constants/general';
import { selectGeneralEducationRequirements, selectIsLoading } from 'store/common/commonInfo.selectors';
import { useSelector } from 'react-redux';
import { TGenEdReq, TReq } from 'store/common/commonInfo.slice';
import { Spinner } from 'components/Spinner';
import { IRequirements } from 'transferPlanner/store/genEdProcess/genEdProcess.slice';
import { alphabets, bulletColors } from '../GeneralEducation.utils';

interface ISubjectRequirement {
  openSidePanel: (
    pageTitle: string,
    screenName: string,
    showSaveButton: boolean,
    courseDetails?: IRequirements
  ) => void;
  closeSidePanel: () => void;
}

export interface ICardData {
  alphabet: string;
  header: string;
  paragraph: string;
  items: string[];
  bulletColor: string;
}

const SubjectRequirement = ({ openSidePanel, closeSidePanel }: ISubjectRequirement): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const [selectedCircle, setSelectedCircle] = useState<string>('A');
  const [usingKeyboard, setUsingKeyboard] = useState<boolean>(false);
  const [isIconFocused, setIsIconFocused] = useState<boolean>(false);
  const genEdReqData = useSelector(selectGeneralEducationRequirements);
  const isLoading = useSelector(selectIsLoading);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const cardData = genEdReqData?.map(({ category, requirements, code }: TGenEdReq, index: number) => {
    return {
      alphabet: alphabets[index],
      header: `${code || ''}: ${category || ''}`,
      paragraph: t(`generalEducation.subjectRequirement.Circle${alphabets[index]}`),
      items: requirements?.map(({ code: codes, name }: TReq) => [`${codes || ''} - ${name || category || ''}`]),
      bulletColor: bulletColors[index],
    };
  }) as unknown as ICardData[];

  useEffect(() => {
    const handleKeyDown = (event: globalThis.KeyboardEvent) => {
      if (event.key === 'Tab' || event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
        setUsingKeyboard(true);
      }
    };

    /* istanbul ignore next */
    const handleMouseMove = () => {
      setUsingKeyboard(false);
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleCircleClick = (alphabet: string): void => {
    setSelectedCircle(alphabet);
  };

  const handleKeyboardNavigation = (event: KeyboardEvent<SVGSVGElement>): void => {
    if (cardData?.length) {
      const index = cardData.findIndex(data => data.alphabet === selectedCircle);
      let nextIndex;
      if (event.key === 'ArrowRight') {
        nextIndex = (index + 1) % cardData.length;
        setSelectedCircle(cardData[nextIndex].alphabet);
      } else if (event.key === 'ArrowLeft') {
        nextIndex = (index - 1 + cardData.length) % cardData.length;
        setSelectedCircle(cardData[nextIndex].alphabet);
      }
    }
  };

  return (
    <>
      {isLoading && <Spinner backdrop data-testid="spinner" />}
      <Container maxWidth="md" sx={{ p: 0 }}>
        <Typography tabIndex={0} sx={{ mb: 2 }}>
          {t('generalEducation.subjectRequirement.sentenceOne')}
        </Typography>
        <Typography tabIndex={0} sx={{ mb: 2 }}>
          {t('generalEducation.subjectRequirement.sentenceTwo')}
          <Typography
            component="span"
            sx={{
              textDecoration: 'underline',
              color: 'blue',
              cursor: 'pointer',
            }}
            tabIndex={0}
            aria-label={t('generalEducation.subjectRequirement.link')}
            onClick={() => {
              closeSidePanel();
              openSidePanel(t('generalEducation.courseAssignmentsHeading'), 'CourseAssignment', true);
            }}
          >
            {t('generalEducation.subjectRequirement.link')}
          </Typography>{' '}
          {t('generalEducation.subjectRequirement.sentenceThree')}
        </Typography>
        <Typography tabIndex={0} sx={{ mb: 2 }}>
          {t('generalEducation.subjectRequirement.sentenceFour')}
        </Typography>
        <Divider sx={{ marginY: '1rem' }} />
        {cardData?.length > 0 && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {cardData.map(data => {
              const { alphabet, bulletColor } = data as ICardData;
              const isSelected = selectedCircle === alphabet;
              const circleColor = isSelected ? bulletColor : '#ffffff';
              const borderColor = isSelected ? '#ffffff' : '#cccccc';
              const size = isMobile ? 50 : 65;
              const fontSize = isMobile ? 16 : 22;
              const isTabKey = isSelected ? '2px solid blue' : 'none';
              const isFocused = isIconFocused ? usingKeyboard : false;

              return (
                <svg
                  key={alphabet}
                  width={size}
                  height={size}
                  onClick={() => handleCircleClick(alphabet)}
                  onFocus={() => setIsIconFocused(true)}
                  onBlur={() => setIsIconFocused(false)}
                  onKeyDown={handleKeyboardNavigation}
                  role="button"
                  tabIndex={isSelected ? 0 : -1}
                  aria-label={`Select circle ${t(alphabet)}`}
                  id={`circle-${alphabet}`}
                  data-testid={`circle-${alphabet}`}
                  style={{
                    cursor: 'pointer',
                    outline: isFocused ? isTabKey : 'none',
                  }}
                >
                  <circle
                    cx="50%"
                    cy="50%"
                    r="calc(50% - 10px)"
                    fill={isSelected ? circleColor : '#ffffff'}
                    stroke={isSelected ? 'none' : borderColor}
                    strokeWidth={isSelected ? '0' : 2}
                  />
                  <text
                    x="50%"
                    y="53%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fontSize={fontSize}
                    fontWeight="bold"
                    fill={isSelected ? '#ffffff' : '#cccccc'}
                  >
                    {t(alphabet)}
                  </text>
                </svg>
              );
            })}
          </Box>
        )}
        {cardData?.length > 0 && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {cardData.map(data => {
              const { alphabet, header, items, bulletColor, paragraph } = data;
              if (selectedCircle !== alphabet) return null;

              return (
                <Box
                  key={alphabet}
                  sx={{
                    margin: '0.8rem',
                  }}
                >
                  <Box
                    component="div"
                    sx={{ fontSize: isMobile ? '1.2rem' : '1.3rem', fontWeight: 600 }}
                    id={`header-${alphabet}`}
                    aria-label={t(header)}
                    tabIndex={0}
                    role="heading"
                    aria-level={2}
                  >
                    {t(header)}
                  </Box>
                  <Typography sx={{ mt: '.5rem' }} aria-label={paragraph} tabIndex={0} role="paragraph">
                    {paragraph}
                  </Typography>
                  <List sx={{ margin: '0rem -1rem' }}>
                    {items.map((item, index) => (
                      <Fragment key={item}>
                        <ListItem
                          sx={{ padding: '0rem 1rem' }}
                          aria-describedby={t(`header-${alphabet}-option-${index + 1}`)}
                        >
                          <Typography component="span" sx={{ color: bulletColor, mr: '8px', fontSize: '1.8rem' }}>
                            ●
                          </Typography>
                          <ListItemText
                            primary={t(item)}
                            tabIndex={0}
                            aria-label={t(item)}
                            primaryTypographyProps={{ sx: { fontWeight: 600 } }}
                          />
                        </ListItem>
                      </Fragment>
                    ))}
                  </List>
                </Box>
              );
            })}
          </Box>
        )}
      </Container>
    </>
  );
};

export default SubjectRequirement;
