/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext, Controller, FormProvider, useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  FormControl,
  InputLabel,
  InputBase,
  FormHelperText,
  Typography,
  Switch,
  Divider,
  Button,
} from '@mui/material';
import { Alert, Dropdown, IconCaretDown, SidePanel, TAutocompleteOption, TpAutocomplete } from '@liaison/liaison-ui';
import moment from 'moment';

import { DISABLE_AUTO_FILL, FIELD_LENGTH_200, FIELD_LENGTH_250 } from 'constants/field';
import MasterData from 'userProfile/constants/master';
import { TGradeLevel, IHighSchool } from 'store/academicHistory/academicHistory.slice';
import { setUi } from 'store/ui/ui.slice';
import { getButtonColor, getCheckboxColor, getDropDownOptions, isCsuTenant } from 'utils/utilities';
import { StyledDeleteButton, sxSidePanel } from 'pages/Pages.styles';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { gradeMapping, gradeSummerMapping } from 'userProfile/constants/general';
import { selectSubjects } from 'store/common/commonInfo.selectors';
import { getLookUps } from 'utils/commonUtils';
import { AttachedFiles } from 'userProfile/components/AttachedFiles';
import { SkillsMultiselect } from 'userProfile/components/SkillsMutliselect';
import { selectSectionMediaDocs } from 'userProfile/store/mediaDocuments/mediaDocuments.selectors';
import HighSchoolGradeLevelForm from './HighSchoolGradeLevelForm';
import { findSelectedGradeLevel, prepareGradeLevelsOptions } from './AcademicHistoryForm.utils';
import { gradeLevelValidationSchema } from '../AcademicHistoryForm.validation';

interface IGradeList {
  name: string;
  title: string;
  parent: string;
}

interface IHighSchoolCourseProps {
  data: IHighSchool | null;
  isNewCourse: boolean;
  childPosition?: number;
  deleteCourse?:
    | ((
        childPosition: number,
        parent: string,
        parentPosition: number,
        selector: string,
        coursePosition?: number,
        tag?: string
      ) => void)
    | null;
  parentPosition?: number;
  coursePosition?: number;
}

type THighSchoolCourseErrors = {
  code: { message: string };
  title: { message: string };
  subject: {
    code: { message: string };
  };
  classification: {
    code: { message: string };
  };
  grade: {
    overall: { message: string };
    data: {
      [key: string]: { code: { message: string } };
    };
  };
  credits: { message: string };
};

const gradesList = (parentName: string | undefined): IGradeList[] =>
  gradeMapping?.filter(item => item.parent === parentName);

const gradesSummerList = (parentName: string | undefined): IGradeList[] =>
  gradeSummerMapping?.filter(item => item.parent === parentName);

const groupName = 'highSchools';
const coursePrefix = 'hscourse';

const highSchoolGradeOptions = getDropDownOptions(MasterData?.usGrade);
const highSchoolCourseTypeOptions = getDropDownOptions(MasterData?.highSchoolCourseType);

const HighSchoolCourseForm = ({
  data,
  childPosition = 0,
  deleteCourse,
  parentPosition = 0,
  coursePosition = 0,
  isNewCourse,
}: IHighSchoolCourseProps): ReactElement => {
  const gradeLevelFormMethods = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(gradeLevelValidationSchema, { abortEarly: false })(...args),
    context: { formName: 'gradeLevels' },
  });

  const {
    control,
    reset,
    register,
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
  } = useFormContext();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fromYear = data?.startDate ? moment(data?.startDate, 'YYYY-MMM').year() : 1990;
  const toYear = data?.endDate ? moment(data?.endDate, 'YYYY-MMM').year() : 2100;
  const [showAlert, setShowAlert] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [startGradeValidation, setStartGradeValidation] = useState(false);
  const [isOpenGradePanel, setIsOpenGradePanel] = useState(false);
  const [isGradeCleared, setIsGradeCleared] = useState(false);
  const [isSummerCourse, setIsSummerCourse] = useState(false);
  const [listOfGrades, setListOfGrades] = useState(gradesList(data?.termType?.code));
  const subjectsLookup = useSelector(selectSubjects);
  const courseErrors = errors?.[coursePrefix] as unknown as THighSchoolCourseErrors;
  const subjectOptions = useMemo(() => getDropDownOptions(subjectsLookup || []), [subjectsLookup]);
  const gradeLevels = useMemo(() => data?.gradeLevels, [data]);
  const gradeLevelsOptions = useMemo(() => prepareGradeLevelsOptions(gradeLevels), [gradeLevels]);
  const watchGradeLevel = getValues('gradeLevel');
  const selectedGradeLevel = useMemo(() => findSelectedGradeLevel(watchGradeLevel as TGradeLevel), [watchGradeLevel]);
  const courseId = getValues(`${coursePrefix}.id`);
  const sectionMediaDocs = useSelector(state => selectSectionMediaDocs(state, courseId));
  const tag = `highSchools/${data?.id}/gradeLevels/${gradeLevels?.[childPosition]?.id}/courses/${gradeLevels?.[childPosition]?.courses?.[coursePosition]?.id}`;

  useEffect(() => {
    if (!subjectsLookup) {
      dispatch(getLookUps('subjects'));
    }
  }, [dispatch, subjectsLookup]);

  /* istanbul ignore next */
  useEffect(() => {
    if (gradeLevels) {
      const currentGradeLevel = { ...(gradeLevels?.[childPosition] || {}) };
      const currentCourse = { ...(gradeLevels?.[childPosition]?.courses?.[coursePosition] || {}) };
      delete currentGradeLevel.courses;
      reset({
        gradeLevel: currentGradeLevel,
        gradeLevelPosition: childPosition,
        hscourse: !isNewCourse && Object.keys(currentCourse).length ? currentCourse : null,
        coursePosition,
      });
      if (
        !isNewCourse &&
        Object.keys(currentCourse.grade?.data || {}).some(gradeName => gradeName.includes('summer'))
      ) {
        setListOfGrades(gradesSummerList(data?.termType?.code));
        setIsSummerCourse(true);
        setValue(`${coursePrefix}.grade.summer`, 'summer');
      }
    } else {
      reset({});
    }
    return () => {
      reset({});
    };
  }, [reset, gradeLevels, childPosition, coursePosition, data?.termType?.code, isNewCourse, setValue]);

  useEffect(() => {
    if (data?.termType?.code) {
      // `${coursePrefix}.grade.termType` added for validation in the AcademicHistoryForm.validation.ts (.when(['termType', 'summer'])
      setValue(`${coursePrefix}.grade.termType`, data?.termType?.code);
      setValue('termType', data?.termType);
    }
  }, [data, setValue]);

  useEffect(() => {
    if (startGradeValidation) {
      listOfGrades.forEach(grade => {
        setValue(`${coursePrefix}.grade.data.${grade.name}.code`, null);
      });
      setTimeout(() => {
        trigger(`${coursePrefix}.grade.data`);
      }, 0);
      setStartGradeValidation(false);
    }
  }, [startGradeValidation, listOfGrades, setValue, trigger]);

  const closeGradePanel = () => {
    setIsOpenGradePanel(false);
  };

  const onGradeLevelFormSubmit = (gradeLevel: TGradeLevel) => {
    setValue('gradeLevelPosition', (gradeLevels || []).length);
    dispatch(setUi({ name: 'gradeLevelPosition', state: { index: (gradeLevels || []).length } }));
    setValue('gradeLevel', { ...gradeLevel });
    setValue('coursePosition', 0);
    setIsGradeCleared(false);
    closeGradePanel();
    setTimeout(() => {
      trigger('gradeLevel');
    }, 10);
  };

  const otherCoursesUnderSameGradeLevel = () => {
    const gradeLevelPosition = getValues('gradeLevelPosition');
    const otherCourses = { ...(gradeLevels?.[gradeLevelPosition] || {}) }?.courses;
    return (
      <>
        {selectedGradeLevel && otherCourses?.length ? (
          <Alert icon={false} type="info" sx={{ mb: '1rem' }} onClose={() => setShowBanner(false)}>
            <Typography component="div" variant="body3">
              {t('colleges.course.existingMsg')} <b>{selectedGradeLevel?.text}</b> {t('academicHistory.gradeLevel')}
            </Typography>
            {otherCourses?.map(({ title, credits, grade, classification }) => (
              <Typography
                key={`${title}-${credits}-${grade?.overall}`}
                component="div"
                variant="body2"
              >{`${classification?.displayName} - ${title}`}</Typography>
            ))}
          </Alert>
        ) : null}
      </>
    );
  };

  const handleGradePanel = () => {
    return (
      <FormProvider {...gradeLevelFormMethods}>
        <form>
          <SidePanel
            size="small"
            open={isOpenGradePanel}
            onClose={closeGradePanel}
            title={t('academicHistory.addGrade')}
            footerButtonConfig={{
              primary: {
                title: t('save_label'),
                props: {
                  'aria-label': t('save_label'),
                  color: getButtonColor(),
                  variant: 'contained',
                  disabled: !gradeLevelFormMethods.formState.isValid,
                  onClick: gradeLevelFormMethods.handleSubmit(onGradeLevelFormSubmit as SubmitHandler<FieldValues>),
                },
              },
              tertiary: {
                title: t('cancel_label'),
                props: {
                  color: getButtonColor(),
                  'aria-label': t('cancel_label'),
                  onClick: closeGradePanel,
                },
              },
            }}
            sx={sxSidePanel}
          >
            <HighSchoolGradeLevelForm
              highSchoolStartYear={fromYear}
              highSchoolEndYear={toYear}
              gradeLevelList={gradeLevelsOptions}
            />
          </SidePanel>
        </form>
      </FormProvider>
    );
  };

  const noOptionsText = <Typography sx={{ m: '1rem' }}>{t('academicHistory.noGradeLevel')}</Typography>;

  const addNewOptionButton = (
    <Box sx={{ textAlign: 'center' }}>
      <Divider sx={{ borderStyle: 'solid' }} />
      <Button
        tabIndex={0}
        variant="text"
        color={getButtonColor()}
        sx={{
          textDecoration: 'none',
          textTransform: 'uppercase',
          mt: '1rem',
          mb: '1.563rem',
        }}
        onClick={() => {
          setIsOpenGradePanel(true);
        }}
        onMouseDown={e => {
          e.preventDefault();
        }}
      >
        {t('academicHistory.addNewGrade')}
      </Button>
    </Box>
  );

  return (
    <>
      {showBanner && otherCoursesUnderSameGradeLevel()}
      <FormControl fullWidth>
        <InputLabel htmlFor="highSchool">{t('highSchool.highSchool_label')}</InputLabel>
        <InputBase
          inputProps={{
            'aria-describedby': 'name-error',
            'aria-label': t('highSchool.highSchool_label'),
            maxLength: FIELD_LENGTH_200,
            ...DISABLE_AUTO_FILL,
          }}
          disabled
          value={data?.name}
        />
      </FormControl>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormControl fullWidth error={!!courseErrors?.subject?.code}>
            <InputLabel htmlFor="subject">{t('colleges.course.subject')}</InputLabel>
            <Controller
              render={({ field: { ref, onChange, ...field } }) => (
                <Dropdown
                  id="subject"
                  {...field}
                  inputRef={ref}
                  options={subjectOptions}
                  onChange={option => {
                    setValue(`${coursePrefix}.subject.displayName`, option?.text);
                    return onChange(option?.id ?? '');
                  }}
                  inputProps={{
                    'aria-label': t('colleges.course.subject'),
                    'aria-describedby': 'subject-error',
                    ...DISABLE_AUTO_FILL,
                  }}
                />
              )}
              control={control}
              name={`${coursePrefix}.subject.code`}
            />
            <FormHelperText role="alert" id="subject-error">
              {courseErrors?.subject?.code?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth required error={isGradeCleared}>
            <InputLabel htmlFor="gradeLevel">{t('academicHistory.gradeLevel')}</InputLabel>
            <TpAutocomplete
              id="gradeLevel"
              options={gradeLevelsOptions}
              disabled={!isNewCourse}
              onChange={(_event, option) => {
                const value = option as TAutocompleteOption<string>;
                if (value?.id) {
                  setValue('gradeLevelPosition', value?.gradeLevelPosition);
                  dispatch(setUi({ name: 'gradeLevelPosition', state: { index: value?.gradeLevelPosition } }));
                  setValue('gradeLevel', JSON.parse(value?.id));
                  setValue(
                    'coursePosition',
                    isNewCourse
                      ? (gradeLevels?.[value?.gradeLevelPosition as number]?.courses || []).length
                      : coursePosition
                  );
                  setIsGradeCleared(false);
                } else {
                  setValue('gradeLevel', {});
                  setIsGradeCleared(true);
                }
                setTimeout(() => {
                  trigger('gradeLevel');
                }, 10);
              }}
              value={selectedGradeLevel}
              placeholder={t('academicHistory.gradeLevelPlaceholder')}
              noOptionsText={noOptionsText}
              addNewOptionButton={addNewOptionButton}
              popupIcon={<IconCaretDown />}
              title={t('academicHistory.gradeLevel')}
            />
            <FormHelperText role="alert" id="grade-error">
              {isGradeCleared && t('school.error.course.grade.required')}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <FormControl fullWidth required error={!!courseErrors?.title}>
        <InputLabel htmlFor="courseTitle">{t('academicHistory.courseName')}</InputLabel>
        <InputBase
          id="courseTitle"
          inputProps={{
            'aria-label': t('academicHistory.courseName'),
            maxLength: FIELD_LENGTH_250,
            ...DISABLE_AUTO_FILL,
          }}
          {...register(`${coursePrefix}.title`, {
            onChange: () => {
              setValue(`${coursePrefix}.source`, { code: 'APPLICANT', displayName: 'Applicant' });
            },
          })}
        />
        <FormHelperText role="alert" id="name-error">
          {courseErrors?.title?.message}
        </FormHelperText>
      </FormControl>
      <Box>
        <FormControl fullWidth required error={!!courseErrors?.classification?.code}>
          <InputLabel htmlFor="classification">{t('highSchool.courseType')}</InputLabel>
          <Controller
            render={({ field: { ref, onChange, ...field } }) => (
              <Dropdown
                id="classification"
                {...field}
                inputRef={ref}
                options={highSchoolCourseTypeOptions}
                onChange={option => {
                  setValue(`${coursePrefix}.classification.displayName`, option?.text);
                  return onChange(option?.id ?? '');
                }}
                inputProps={{
                  'aria-label': t('highSchool.courseType'),
                  ...DISABLE_AUTO_FILL,
                }}
              />
            )}
            control={control}
            name={`${coursePrefix}.classification.code`}
          />
          <FormHelperText role="alert" id="classification-error">
            {courseErrors?.classification?.code?.message}
          </FormHelperText>
        </FormControl>
      </Box>
      <Grid container spacing={1} sx={{ mb: 2 }}>
        <Grid item xs={9}>
          <Typography variant="subtitle4">{t('highSchool.summerCourse')}</Typography>
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Switch
            color={getCheckboxColor()}
            checked={isSummerCourse}
            onChange={(_event, val) => {
              setIsSummerCourse(val);
              setValue(`${coursePrefix}.grade.data`, null);
              if (val) {
                setListOfGrades(gradesSummerList(data?.termType?.code));
                // "summer" added for validation in the AcademicHistoryForm.validation.ts (.when(['termType', 'summer'])
                setValue(`${coursePrefix}.grade.summer`, 'summer');
              } else {
                setListOfGrades(gradesList(data?.termType?.code));
                setValue(`${coursePrefix}.grade.summer`, 'noSummer');
              }
              setStartGradeValidation(true);
            }}
            inputProps={{
              'aria-label': t('highSchool.summerCourse'),
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        {listOfGrades?.map(grade => (
          <Grid item xs={3} key={grade.title}>
            <FormControl fullWidth required error={!!courseErrors?.grade?.data?.[grade.name]}>
              <InputLabel htmlFor={`${grade.title}-received`}>{grade.title}</InputLabel>
              <Controller
                render={({ field: { ref, onChange, ...field } }) => (
                  <Dropdown
                    id={`${grade.title}-received`}
                    {...field}
                    inputRef={ref}
                    options={highSchoolGradeOptions}
                    onChange={option => {
                      setValue(`${coursePrefix}.grade.data.${grade.name}.displayName`, option?.text);
                      return onChange(option?.id ?? '');
                    }}
                    inputProps={{
                      'aria-label': grade.title,
                      ...DISABLE_AUTO_FILL,
                    }}
                  />
                )}
                control={control}
                name={`${coursePrefix}.grade.data.${grade.name}.code`}
              />
              <FormHelperText role="alert">{courseErrors?.grade?.data?.[grade.name]?.code.message}</FormHelperText>
              <FormHelperText role="note" sx={{ whiteSpace: 'normal' }}>
                {t('colleges.course.grade.helper')}
              </FormHelperText>
            </FormControl>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <FormControl fullWidth required error={!!courseErrors?.grade?.overall}>
            <InputLabel htmlFor="overall">{t('highSchool.overall')}</InputLabel>
            <InputBase
              id="overall"
              inputProps={{
                'aria-label': t('highSchool.overall'),
                'aria-describedby': 'overall-helper',
                ...DISABLE_AUTO_FILL,
              }}
              {...register(`${coursePrefix}.grade.overall`)}
            />
            <FormHelperText role="alert" id="overall-error">
              {courseErrors?.grade?.overall?.message}
            </FormHelperText>
            <FormHelperText role="note" id="overall-helper" sx={{ whiteSpace: 'normal' }}>
              {t('colleges.course.grade.helper')}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth error={!!courseErrors?.credits}>
            <InputLabel htmlFor="credits">{t('highschool.course.credits')}</InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => {
                return (
                  <NumberFormat
                    {...field}
                    decimalScale={2}
                    decimalSeparator="."
                    allowNegative={false}
                    fixedDecimalScale={false}
                    customInput={InputBase}
                    onValueChange={values => onChange(values?.floatValue)}
                    inputProps={{ 'aria-label': t('highschool.course.credits') }}
                  />
                );
              }}
              name={`${coursePrefix}.credits`}
              control={control}
            />
            <FormHelperText role="note" id="credits-helper" sx={{ whiteSpace: 'normal' }}>
              {t('colleges.course.credits.helper')}
            </FormHelperText>
            <FormHelperText role="alert" id="credits-error" sx={{ whiteSpace: 'normal' }}>
              {courseErrors?.credits?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <AttachedFiles sectionName="hsCourse" tag={tag} disabled={!isValid} />
      {!isCsuTenant() && <SkillsMultiselect tag={tag} disabled={!isValid} />}
      {deleteCourse && (
        <Box
          sx={{
            mt: '1rem',
            mb: '1rem',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <StyledDeleteButton onClick={() => setShowAlert(true)}>
            {t('highSchool.removeHighSchoolCourse')}
          </StyledDeleteButton>
        </Box>
      )}
      <ConfirmationDialog
        open={showAlert}
        text={t('highSchool.deleteCourse')}
        confirmationText={t('academicHistory.deleteContent')}
        onClose={() => {
          setShowAlert(false);
        }}
        footerButtonConfig={{
          primary: {
            title: t('remove_label'),
            props: {
              onClick: () => {
                setShowAlert(false);
                deleteCourse?.(
                  childPosition || 0,
                  groupName,
                  parentPosition,
                  'gradeLevels',
                  coursePosition,
                  sectionMediaDocs?.length ? tag : undefined
                );
              },
            },
          },
          tertiary: {
            title: t('cancel_label'),
          },
        }}
      />
      {handleGradePanel()}
    </>
  );
};

export default HighSchoolCourseForm;
