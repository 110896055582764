/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import React, { memo, useEffect, type ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, FormProvider, SubmitHandler, useForm, type FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel,
  Typography,
  IconButton,
} from '@mui/material';
import { IconCancel, Textarea } from '@liaison/liaison-ui';
import { selectAllProfileViews, selectSavedProfileView } from 'userProfile/store/profileView/profileView.selectors';
import { DISABLE_AUTO_FILL, FIELD_LENGTH_100, MAX_HUGE_LENGTH_FIELD } from 'constants/field';
import {
  getAllProfileViews,
  getProfileView,
  postProfileView,
  getDialogTitleAndDescription,
  CREATE_NEW,
  SAVE_AS,
  RENAME,
  type TProfileDialogType,
} from 'userProfile/pages/LivingProfile/LivingProfile.utils';
import type { TProfileView } from 'userProfile/store/profileView/profileView.slice';
import { getButtonColor } from 'utils/utilities';
import { defaultValues, getValidationSchema } from './ProfileDialog.validation';

interface IProfileDialog {
  open: TProfileDialogType | '';
  setIsOpen: (value: TProfileDialogType | '') => void;
}

function ProfileDialog({ open: type, setIsOpen }: IProfileDialog): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const profileView = useSelector(selectSavedProfileView);
  const allProfileViews = useSelector(selectAllProfileViews);

  const methods = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) =>
      yupResolver(getValidationSchema(allProfileViews as TProfileView[]), { abortEarly: false })(...args),
    defaultValues,
  });
  const {
    register,
    control,
    reset,
    formState: { errors, isValid },
    handleSubmit,
  } = methods;

  const { dialogTitle, dialogDescription } = getDialogTitleAndDescription(type);

  useEffect(() => {
    if (type === RENAME) {
      reset({ name: profileView?.name, description: profileView?.description || '' });
    }
  }, [type, profileView, reset]);

  const handleCloseProfileDialog = () => {
    setIsOpen('');
    reset(defaultValues);
  };

  const onSubmit = (data: TProfileView) => {
    // eslint-disable-next-line no-unused-vars
    const { id, defaultView, ...rest } = profileView || {};
    const payload = {
      ...rest,
      ...data,
    };

    if (type === CREATE_NEW) {
      if (defaultView) {
        dispatch(
          postProfileView(payload, profileViewIdToSetAsCurrent => {
            dispatch(getAllProfileViews(profileViewIdToSetAsCurrent, false));
          })
        );
        handleCloseProfileDialog();
      } else {
        dispatch(
          getProfileView('', defaultProfileView => {
            // eslint-disable-next-line  no-unused-vars
            const { id: defaultId, defaultView: defaultDefaultView, ...restData } = defaultProfileView || {};
            const updatedPayload = {
              ...restData,
              ...data,
            };
            dispatch(
              postProfileView(updatedPayload, profileViewIdToSetAsCurrent => {
                dispatch(getAllProfileViews(profileViewIdToSetAsCurrent, false));
              })
            );
          })
        );
        handleCloseProfileDialog();
      }
    } else if (type === SAVE_AS) {
      dispatch(
        postProfileView(payload, profileViewIdToSetAsCurrent => {
          dispatch(getAllProfileViews(profileViewIdToSetAsCurrent, false));
        })
      );
      handleCloseProfileDialog();
    } else if (type === RENAME) {
      dispatch(
        postProfileView({ ...profileView, ...data }, profileViewIdToSetAsCurrent => {
          dispatch(getAllProfileViews(profileViewIdToSetAsCurrent, false));
        })
      );
      handleCloseProfileDialog();
    }
  };

  return (
    <Dialog
      open={!!type}
      disableEscapeKeyDown
      role="document"
      scroll="body"
      aria-labelledby="dialog-modal-title"
      aria-describedby="dialog-modal-description"
      maxWidth="sm"
      fullWidth
      sx={{ [`.${dialogClasses.paper}`]: { borderRadius: '1rem' } }}
    >
      <Box
        sx={{
          display: 'flex',
          py: '1rem',
          px: ['1rem', '1.5rem', '2.5rem'],
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography id="dialog-modal-title" variant="h4" component="h1">
          {dialogTitle}
        </Typography>
        <IconButton aria-label="close" size="small" onClick={handleCloseProfileDialog} sx={{ height: 'fit-content' }}>
          <IconCancel />
        </IconButton>
      </Box>
      <Divider sx={{ borderStyle: 'solid' }} />
      <DialogContent sx={{ py: '2rem', px: ['1rem', '1.5rem', '2.5rem'] }}>
        <Typography variant="body2" id="dialog-modal-description" pb={2}>
          {dialogDescription}
        </Typography>
        <FormProvider {...methods}>
          <FormControl fullWidth required error={!!errors?.name}>
            <InputLabel htmlFor="name" sx={{ mt: 1 }}>
              {t('livingProfile.createProfile.profileName')}
            </InputLabel>
            <InputBase
              inputProps={{
                'aria-label': t('livingProfile.createProfile.profileName'),
                'aria-describedby': 'name-error',
                maxLength: FIELD_LENGTH_100,
                ...DISABLE_AUTO_FILL,
              }}
              {...register('name')}
              fullWidth={true}
            />
            <FormHelperText role="alert" id="name-error">
              {errors?.name?.message as string}
            </FormHelperText>
          </FormControl>
          <Controller
            name="description"
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth error={!!errors?.description}>
                <InputLabel htmlFor="description">{t('livingProfile.createProfile.profileDescription')}</InputLabel>
                <Textarea
                  {...field}
                  error={!!error}
                  id="description"
                  defaultValue=""
                  aria-describedby="description-error"
                  maxChars={MAX_HUGE_LENGTH_FIELD}
                  inputProps={{
                    maxLength: MAX_HUGE_LENGTH_FIELD,
                    maxRows: 8,
                    'aria-label': t('livingProfile.createProfile.profileDescription'),
                  }}
                />
                <FormHelperText role="alert" id="description-error">
                  {errors?.description?.message}
                </FormHelperText>
              </FormControl>
            )}
            control={control}
          />
        </FormProvider>
      </DialogContent>
      <Divider sx={{ borderStyle: 'solid' }} />
      <DialogActions
        sx={{
          justifyContent: 'space-between',
          py: '1rem',
          px: ['1rem', '1.5rem', '2.5rem'],
        }}
      >
        <Button onClick={handleCloseProfileDialog} variant="text" color={getButtonColor()}>
          {t('cancel_label')}
        </Button>
        <Button
          onClick={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
          variant="contained"
          color={getButtonColor()}
          disabled={!isValid}
        >
          {t('save_label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(ProfileDialog);
