import { Components, Theme, InputBaseClasses } from '@mui/material';
import { ComponentNameToClassKey, OverridesStyleRules } from '@mui/material/styles/overrides';

export default ({ components }: Theme): Components => ({
  MuiInputBase: {
    styleOverrides: {
      ...components?.MuiInputBase?.styleOverrides,
      root: {
        ...(components?.MuiInputBase?.styleOverrides?.root as Partial<
          OverridesStyleRules<ComponentNameToClassKey['MuiInputBase'], 'MuiInputBase', Theme>
        >),
        maxWidth: '100%',
      },
      input: {
        ...(components?.MuiInputBase?.styleOverrides?.input as Partial<
          OverridesStyleRules<ComponentNameToClassKey['MuiInputBase'], 'MuiInputBase', Theme>
        >),
        maxWidth: '100% !important',
      },
    } as Partial<OverridesStyleRules<keyof InputBaseClasses, 'MuiInputBase', unknown>> | undefined,
  },
});
