/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'transferPlanner/constants/general';
import congrats from 'assets/congrats.png';
import Confetti from 'react-confetti';

interface TspCongratulationsProps {
  startConfetti: boolean;
}
const TspCongratulations = ({ startConfetti }: TspCongratulationsProps): ReactElement => {
  const { t } = useTranslation(nameSpace);

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
      tabIndex={0}
      style={{ maxHeight: 'calc(100% + 2rem)' }}
    >
      <Confetti
        confettiSource={{ x: 0, y: 0, w: window.innerWidth, h: window.innerHeight }}
        numberOfPieces={250}
        recycle={startConfetti}
      />
      <Grid item xs={5}>
        <img src={congrats} alt="Congratulations graphic" />
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h1">{t('enrollmentCongratulations.heading')}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          {t('enrollmentCongratulations.body')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default memo(TspCongratulations);
