/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ForwardedRef, ReactElement, memo } from 'react';
import { Card, CardActions, Button, Typography, Grid, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import { CustomChip, IconStar } from '@liaison/liaison-ui';
import {
  IProgram,
  ISelectedProgram,
  TFollowedProgramModified,
} from 'transferPlanner/store/programCart/programCart.slice';
import { selectTspEnrollmentPeriod } from 'transferPlanner/store/enrollment/enrollment.selectors';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/hooks';
import { csuNameSpace } from 'utils/utilities';
import useToggleTabIndex from 'transferPlanner/hooks/useToggleTabIndex';
import { cardStyles, programNameBtnLink } from '../MyProgramCart.styles';
import Menu from '../Menu/Menu';

interface IMyProgram {
  isSavedProgram: boolean;
  selectedProgram: ISelectedProgram | TFollowedProgramModified | null;
  activeEnrolledProgramId: number | undefined;
  handleClick: (menu: string, selectedProgram: ISelectedProgram) => void;
  enrollBtnDisabled: boolean;
  tspEligibleFlags?: IProgram[] | undefined | null;
  hasError?: boolean;
}

const MyProgramCard = ({
  isSavedProgram,
  selectedProgram,
  activeEnrolledProgramId,
  handleClick,
  enrollBtnDisabled,
  tspEligibleFlags,
  hasError = false,
}: IMyProgram): ReactElement => {
  const { t } = useTranslation(csuNameSpace.ns);
  const { elementRef } = useToggleTabIndex(-1);
  const isTspEnrollmentOpen = useAppSelector(selectTspEnrollmentPeriod)?.enrollmentOpen || false;
  const isFollowed = selectedProgram?.program?.tracking?.active;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const followedCardWidth = isMobile ? 9.5 : 10;
  const borderColorName = hasError ? '#B71C1C' : '#8E9AAB';
  const enrollButtonDisabled =
    enrollBtnDisabled || !tspEligibleFlags?.find(program => program.id === selectedProgram?.program?.id)?.tspEligible;
  const isProgramEnrolled = activeEnrolledProgramId && selectedProgram?.program?.id === activeEnrolledProgramId;

  return (
    <>
      {selectedProgram && (
        <Card data-testid="my-programs-card" sx={{ ...cardStyles, borderColor: borderColorName }}>
          <Grid container direction="row" sx={{ width: '100%', justifyContent: 'center' }}>
            {isFollowed && (
              <Grid
                item
                xs={isMobile ? 1.5 : 1}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <IconStar
                  aria-label="followedIcon"
                  sx={{ color: theme => theme.palette.warning.dark, height: 'fit-content', ml: '.5rem' }}
                />
              </Grid>
            )}
            <Grid
              item
              xs={isFollowed ? followedCardWidth : 11}
              sx={{ display: 'flex', alignItems: 'center', ml: isFollowed ? '0.6rem' : 0 }}
            >
              <Grid container direction="column">
                {isProgramEnrolled && (
                  <Grid item md={6} sm={6} xs={6}>
                    <CustomChip
                      label={t('programs.enrolled.label')}
                      icon="check"
                      variant="square"
                      iconColor="white"
                      bgColor="#2E7D32"
                      txtColor="white"
                      size="small"
                      sx={{ fontSize: '0.75rem' }}
                      ref={elementRef as ForwardedRef<HTMLDivElement>}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  md={6}
                  sm={4}
                  xs={4}
                  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Button
                    variant="text"
                    color="inherit"
                    size="large"
                    sx={{
                      ...programNameBtnLink,
                      p: 0,
                      '&:focus': {
                        border: '2px solid blue',
                      },
                    }}
                    title={selectedProgram.program.name}
                    onClick={() => handleClick('Program Details', selectedProgram)}
                    tabIndex={0}
                  >
                    {selectedProgram.program.name}
                  </Button>
                </Grid>
                <Grid item md={6} sm={6} xs={6}>
                  <Typography variant="caption" component="div">
                    {selectedProgram.campus.name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <CardActions>
            {isTspEnrollmentOpen && (
              <Button
                id={`enroll_btn_${selectedProgram.campus.id}`}
                variant="contained"
                onClick={() => handleClick('Enroll', selectedProgram)}
                sx={{ mt: '0.15rem', pointerEvents: 'auto' }}
                disabled={enrollButtonDisabled}
              >
                {t('enroll_label')}
              </Button>
            )}
            <Menu onClick={handleClick} currentProgram={selectedProgram} isSavedProgram={isSavedProgram} />
          </CardActions>
        </Card>
      )}
    </>
  );
};

export default memo(MyProgramCard);
