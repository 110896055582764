/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Typography, Button } from '@mui/material';
import { IconAlertTriangle } from '@liaison/liaison-ui';
import { t } from 'i18next';
import { BannerLayout } from 'components/BannerLayout';
import useDeadlineExtensionBanner from 'hooks/useDeadlineExtensionBanner/useDeadlineExtensionBanner';
import { useFixedItemInView } from 'hooks/useFixedItemInView';
import { StyledRootBox, StyledAlert, StyledBox } from './ImpersonateLayout.styles';

export interface IImpersonateLayoutProps {
  userName: string;
  handleExit: () => void;
  impersonationToken: boolean;
  fullWidth?: boolean;
}

const ImpersonateLayout = ({
  userName,
  handleExit,
  fullWidth = false,
  impersonationToken = false,
}: IImpersonateLayoutProps): ReactElement => {
  const fullpage = fullWidth.toString();
  const { isFixed } = useFixedItemInView();
  const shouldDisplayTheBanner = useDeadlineExtensionBanner();

  return (
    <StyledRootBox
      fullpage={fullpage}
      data-testid="impersonateLayout"
      shoulddisplaythebanner={shouldDisplayTheBanner ? 'true' : ''}
      impersonationtoken={impersonationToken.toString()}
    >
      <StyledBox fullpage={fullpage} scrollposition={isFixed}>
        {impersonationToken && (
          <StyledAlert
            fullpage={fullpage}
            severity="warning"
            icon={<IconAlertTriangle fontSize="inherit" color="error" sx={{ ml: 3 }} />}
          >
            <Typography align="center" component="div" variant="body1" data-testid="impersonateMessage">
              {`${t('impersonation_message1')} ${userName}${t('impersonation_message2')} `}
              <Button color="inherit" size="large" onClick={handleExit} data-testid="exitImpersonationAction">
                {`${t('end.impersonation')}`}
              </Button>
            </Typography>
          </StyledAlert>
        )}
        {shouldDisplayTheBanner && <BannerLayout isImpersonateLayout={impersonationToken} />}
      </StyledBox>
    </StyledRootBox>
  );
};

export default ImpersonateLayout;
