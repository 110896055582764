/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TError = string | null;

export interface IFeature {
  name: string;
  description: string;
}

export interface IFeaturesState {
  loading: boolean;
  features: IFeature[] | null;
  error: TError;
}

export const initialState: IFeaturesState = {
  loading: false,
  features: null,
  error: null,
};

const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    featuresStart: (state): IFeaturesState => ({
      ...state,
      loading: true,
      error: null,
    }),
    featuresSuccess: (state, action: PayloadAction<IFeature[]>): IFeaturesState => ({
      ...state,
      loading: false,
      features: action.payload,
    }),
    featuresFailure: (state, action: PayloadAction<TError>): IFeaturesState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    featuresReset: () => initialState,
  },
});

export const { featuresStart, featuresSuccess, featuresFailure, featuresReset } = featuresSlice.actions;

export default featuresSlice.reducer;
