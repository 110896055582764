/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Box, Card, CardContent, CardMedia, IconButton, Stack, Typography } from '@mui/material';
import { Icon, IconLocationSolid, IconPhoneSolid, IconMailSolid } from '@liaison/liaison-ui';
import { UserAvatar } from 'userProfile/components/UserAvatar';
import profileBg from 'assets/profile_bg.png';
import { selectProfileView, selectPersonalInformation } from 'userProfile/store/profileView/profileView.selectors';
import { sxSectionsItem } from '../LivingProfile.styles';
import { getFirstName } from '../LivingProfile.utils';

export const Intro = (): ReactElement => {
  const profileData = useSelector(selectProfileView);
  const bannerImage = profileData?.poster?.ephemeralURL || profileBg;
  const personalInfo = useSelector(selectPersonalInformation);

  const firstName = getFirstName(
    personalInfo?.personal?.useGivenName,
    personalInfo?.personal?.givenName,
    personalInfo?.personal?.preferredName
  );

  const lastName = personalInfo?.personal?.familyName || '';
  const phone =
    personalInfo?.contact?.phones?.find(p => p.preferred)?.phone || personalInfo?.contact?.phones?.[0]?.phone;
  const email =
    personalInfo?.contact?.emails?.find(e => e.preferred)?.email || personalInfo?.contact?.emails?.[0]?.email;
  const address =
    personalInfo?.contact?.addresses?.find(a => a.type.code === 'CURRENT') ||
    personalInfo?.contact?.addresses?.find(a => a.type.code === 'PERMANENT');
  const addressToDisplay = address
    ? `${address.region?.displayName !== 'N/A' ? address.region?.displayName : address.city}, ${
        address.country?.displayName
      }`
    : null;
  const linkedInUrl = personalInfo?.socialMedia?.find(s => s.type.code === 'LINKEDIN')?.url;

  const { emails, phones, addresses } = personalInfo?.contact || {};
  const isEmailHidden = emails?.every((item: { hidden: boolean }) => item.hidden);
  const isPhoneHidden = phones?.every((item: { hidden: boolean }) => item.hidden);
  const isAddressHidden = addresses?.every((item: { hidden: boolean }) => item.hidden);
  const linkedInIndex = personalInfo?.socialMedia?.findIndex(item => item.type.code === 'LINKEDIN') ?? -1;
  const isLinkedInHidden = personalInfo?.socialMedia?.[linkedInIndex]?.hidden;

  return (
    <Card sx={{ ...sxSectionsItem }}>
      <CardMedia component="img" image={bannerImage} height={420} alt="Paella dish" />
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: '-10em' }}>
          <UserAvatar
            firstName={firstName}
            lastName={lastName}
            width="12.5rem"
            height="12.5rem"
            hideName
            ephemeralURL={profileData?.avatar?.ephemeralURL}
            dontGetData
          />
        </Box>
        <Stack spacing={2} sx={{ p: 2, pb: 0 }}>
          <Typography variant="h1">{`${firstName}${lastName ? ` ${lastName}` : ''}`}</Typography>
          <Typography variant="body2">{profileData?.bio}</Typography>
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center" spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
              {address && !isAddressHidden && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <IconLocationSolid fontSize="small" color="secondary" />
                  <Typography variant="body3">{addressToDisplay}</Typography>
                </Stack>
              )}
              {phone && !isPhoneHidden && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <IconPhoneSolid fontSize="small" color="secondary" />
                  <Typography variant="body3">{phone}</Typography>
                </Stack>
              )}
              {email && !isEmailHidden && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <IconMailSolid fontSize="small" color="secondary" />
                  <Typography variant="body3">{email}</Typography>
                </Stack>
              )}
            </Stack>
            {linkedInUrl && !isLinkedInHidden && (
              <IconButton
                href={linkedInUrl}
                target="_blank"
                aria-label="linkedin"
                size="extra-large"
                sx={{
                  color: theme => theme.palette.primary.main,
                  marginLeft: { xs: 0, sm: 'auto' },
                }}
              >
                <Icon>IconLinkedinCircle</Icon>
              </IconButton>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
