/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SidePanel, CollapsibleSection as Accordion } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import { sxSidePanel } from 'pages/Pages.styles';
import {
  selectAcademicGoals,
  selectAcademicGoalsLocalData,
  selectLoading,
} from 'userProfile/store/academicInfo/academicInfo.selectors';
import {
  academicGoalsInfoSaveLocalData,
  academicGoalsInfoResetLocalData,
  TAcademicGoalsInfoField,
} from 'userProfile/store/academicInfo/academicInfo.slice';
import { ViewBuilder } from 'userProfile/components/ViewBuilder';
import { PageTitle } from 'components/PageTitle';
import { Spinner } from 'components/Spinner';
import { getButtonColor, sanitizePayload } from 'utils/utilities';
import { getFieldsOfStudyLookUps, getLookUps, setTitle } from 'utils/commonUtils';
import {
  selectFieldsOfStudyLookup,
  selectCountriesLookup,
  selectAreasOfStudyLookup,
} from 'store/common/commonInfo.selectors';
import config from 'userProfile/appConfig/academicGoals.json';
import type { ISchoolOfInterest } from 'store/common/commonInfo.slice';
import { GoalInformationForm } from './AcademicGoalsForm';
import {
  getAcademicGoals,
  postAcademicGoals,
  lookupsEndPoint,
  getPayload,
  lookupsEndPointAreasOfStudy,
} from './AcademicGoals.utils';
import { validationChildSchema, validationSchema } from './AcademicGoalsForm.validation';
import SchoolsOfInterestsForm from './AcademicGoalsForm/SchoolsOfInterestsForm';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';

const AcademicGoals = (): ReactElement => {
  const { t } = useTranslation();
  const [isOpenRightSideDrawer, setIsOpenRightSideDrawer] = useState(false);
  const [deleteIndexPosition, setDeleteIndexPosition] = useState<number | null>(null);
  const [academicGoalsData, setAcademicGoalsData] = useState(useSelector(selectAcademicGoals));
  const [childDrawerInformation, setChildDrawerInformation] = useState({
    isOpenChildDrawer: false,
    isAddNew: false,
    titleName: t('academicGoal.addSchool'),
    childPosition: 0,
  });
  const academicGoalsActualData = useSelector(selectAcademicGoals);
  const academicGoalsLocalData = useSelector(selectAcademicGoalsLocalData);
  const dispatch = useDispatch();
  const fieldsOfStudyLookup = useSelector(selectFieldsOfStudyLookup);
  const areasOfStudyLookup = useSelector(selectAreasOfStudyLookup);
  const countriesLookup = useSelector(selectCountriesLookup);

  const isLoading = useSelector(selectLoading);
  const methods = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: async (...args) => yupResolver(validationSchema, { abortEarly: false })(...args),
  });

  const addSchoolMethods = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(validationChildSchema, { abortEarly: false })(...args),
  });

  useEffect(() => {
    setTitle(t('academicGoal.pageTitle'));
  }, [t]);

  useEffect(() => {
    dispatch(getAcademicGoals());
  }, [dispatch, childDrawerInformation]);

  useEffect(() => {
    if (!fieldsOfStudyLookup) {
      dispatch(getFieldsOfStudyLookUps(lookupsEndPoint));
    }
  }, [dispatch, fieldsOfStudyLookup]);

  useEffect(() => {
    if (!areasOfStudyLookup) {
      dispatch(getFieldsOfStudyLookUps(lookupsEndPointAreasOfStudy));
    }
  }, [dispatch, areasOfStudyLookup]);

  useEffect(() => {
    if (!countriesLookup) {
      dispatch(getLookUps('countries'));
    }
  }, [dispatch, countriesLookup]);

  useEffect(() => {
    if (academicGoalsLocalData) {
      const modifiedAcademicGoalsData = getPayload(
        {
          ...academicGoalsLocalData,
          schools: [...(academicGoalsActualData?.schools || [])],
        },
        true
      );
      setAcademicGoalsData(modifiedAcademicGoalsData);
    } else {
      setAcademicGoalsData(academicGoalsActualData);
    }
  }, [academicGoalsActualData, academicGoalsLocalData]);

  const openDrawer = () => {
    setIsOpenRightSideDrawer(true);
  };

  const openChildDrawer = (isAddNew = false, childPosition = academicGoalsData?.schools?.length || 0) => {
    if (isOpenRightSideDrawer) {
      dispatch(academicGoalsInfoSaveLocalData(methods.getValues()));
    }
    setChildDrawerInformation({
      ...childDrawerInformation,
      isOpenChildDrawer: true,
      isAddNew,
      titleName: t('academicGoal.addSchool'),
      childPosition,
    });
  };

  const closeParent = () => {
    setIsOpenRightSideDrawer(false);
    dispatch(academicGoalsInfoResetLocalData());
  };

  const closeChild = () => {
    setChildDrawerInformation({
      ...childDrawerInformation,
      isOpenChildDrawer: false,
    });
  };

  const onSubmit = (data: TAcademicGoalsInfoField) => {
    const payload = getPayload(data, true);

    dispatch(
      postAcademicGoals(sanitizePayload(JSON.parse(JSON.stringify(payload))), () => {
        setIsOpenRightSideDrawer(false);
      })
    );
  };
  /* istanbul ignore next */
  const onSubmitChildDrawer = (data: TAcademicGoalsInfoField) => {
    const schools = [...(academicGoalsData?.schools || [])];

    if (data?.schools) {
      schools[childDrawerInformation.childPosition] = data.schools as unknown as ISchoolOfInterest;
    }

    const payload = {
      ...academicGoalsData,
      schools,
    };

    dispatch(
      postAcademicGoals(sanitizePayload(Object.assign(payload)), () => {
        closeChild();
        closeParent();
      })
    );
  };

  /* istanbul ignore next */
  const showConfirmationDeleteDialog = (indexPosition: number) => {
    setDeleteIndexPosition(indexPosition);
  };

  /* istanbul ignore next */
  const deleteRecord = (indexPosition: number | null) => {
    const updatedData = methods.getValues();
    const schools = [...(updatedData?.schools || [])];

    schools.splice(indexPosition as number, 1);
    const payload = getPayload(
      {
        ...updatedData,
        schools,
      },
      true
    );
    dispatch(academicGoalsInfoSaveLocalData(payload));

    dispatch(
      postAcademicGoals(sanitizePayload(Object.assign(payload)), () => {
        closeChild();
      })
    );
  };

  const { sections } = config;

  return (
    <Grid container>
      {isLoading && <Spinner backdrop />}
      <Box sx={{ width: '100%' }}>
        <PageTitle title={t('academicGoal.pageTitle')} handleClick={openDrawer} />
        <FormProvider {...methods}>
          <form>
            <SidePanel
              size="small"
              title={t('academicGoal.pageTitle')}
              open={isOpenRightSideDrawer}
              isBackdropClickEnabled={true}
              onClose={closeParent}
              footerButtonConfig={{
                primary: {
                  title: t('save_label'),
                  props: {
                    'aria-label': t('save_label'),
                    color: getButtonColor(),
                    variant: 'contained',
                    disabled: !methods.formState.isValid,
                    onClick: methods.handleSubmit(onSubmit),
                  },
                },
                tertiary: {
                  title: t('cancel_label'),
                  props: {
                    'aria-label': t('cancel_label'),
                    color: getButtonColor(),
                    onClick: closeParent,
                  },
                },
              }}
              sx={sxSidePanel}
            >
              <GoalInformationForm
                data={academicGoalsData || null}
                openChildDrawer={openChildDrawer}
                deleteRecord={showConfirmationDeleteDialog}
              />
            </SidePanel>
          </form>
        </FormProvider>
        <FormProvider {...addSchoolMethods}>
          <form>
            <SidePanel
              size="small"
              title={t('academicGoal.addSchool')}
              isBackdropClickEnabled
              open={childDrawerInformation.isOpenChildDrawer}
              onClose={closeChild}
              footerButtonConfig={{
                primary: {
                  title: t('save_label'),
                  props: {
                    'aria-label': t('save_label'),
                    color: getButtonColor(),
                    variant: 'contained',
                    disabled: !addSchoolMethods.formState.isValid,
                    onClick: addSchoolMethods.handleSubmit(onSubmitChildDrawer),
                  },
                },
                tertiary: {
                  title: t('cancel_label'),
                  props: {
                    'aria-label': t('cancel_label'),
                    color: getButtonColor(),
                    onClick: closeChild,
                  },
                },
              }}
              sx={sxSidePanel}
            >
              {childDrawerInformation.isOpenChildDrawer && (
                <SchoolsOfInterestsForm data={academicGoalsActualData || null} />
              )}
            </SidePanel>
          </form>
        </FormProvider>
        {Object.keys(sections).map(section => {
          const accordionValues = Object(sections)[section];
          const responseData = Object(academicGoalsActualData);

          return (
            <React.Fragment key={section}>
              <Box sx={{ mb: '1rem', mt: '1rem' }}>
                <Accordion expanded title={t(`${section}.title`).toUpperCase()} groupId="">
                  <ViewBuilder
                    fieldDetails={accordionValues?.fields}
                    responseData={responseData}
                    openChildDrawer={openChildDrawer}
                    cardView={accordionValues?.cardView || false}
                    localeKey={section}
                    selector=""
                    formTitle=""
                  />
                </Accordion>
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
      <ConfirmationDialog
        open={deleteIndexPosition !== null}
        text={t('academicGoal.delete_title')}
        confirmationText={t('academicGoal.delete_content')}
        onClose={() => {
          setDeleteIndexPosition(null);
        }}
        footerButtonConfig={{
          primary: {
            title: t('remove_label'),
            props: {
              onClick: () => {
                deleteRecord(deleteIndexPosition);
                setDeleteIndexPosition(null);
              },
            },
          },
          tertiary: {
            title: t('cancel_label'),
          },
        }}
      />
    </Grid>
  );
};

export default memo(AcademicGoals);
