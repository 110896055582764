/*
 * Copyright 2022-2024 Liaison International. All Rights Reserved
 */

import React, {
  type Dispatch,
  type SetStateAction,
  type LegacyRef,
  type ReactElement,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import AvatarEditor from 'react-avatar-editor';
import { Button, Paper, Typography, Grid, Slider, Box, Stack } from '@mui/material';
import { IconAdd, IconRemove } from '@liaison/liaison-ui';

import { initialRotate, initialZoom, initialPosition, isEdited } from './ImageEditor.utils';

export type TImageEditorProps = {
  image: string;
  setIsEdited: Dispatch<SetStateAction<boolean>>;
  editorRef?: LegacyRef<AvatarEditor>;
  width?: number;
  height?: number;
  borderRadius?: number;
};

export const ImageEditor = ({
  image,
  setIsEdited,
  editorRef,
  width,
  height,
  borderRadius,
}: TImageEditorProps): ReactElement => {
  const { t } = useTranslation();
  const [rotate, setRotate] = useState(initialRotate);
  const [zoom, setZoom] = useState(initialZoom);
  const [position, setPosition] = useState(initialPosition);

  const resetEdit = useCallback(() => {
    setRotate(0);
    setZoom(1);
    setPosition(initialPosition);
    setIsEdited(false);
  }, [setIsEdited]);

  return (
    <Paper
      sx={{
        boxShadow: 'none',
      }}
      aria-label="image editor container"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AvatarEditor
              ref={editorRef}
              image={image}
              {...(borderRadius ? { borderRadius } : {})}
              {...(width ? { width } : {})}
              {...(height ? { height } : {})}
              style={{ width: '100%', height: 'auto' }}
              rotate={rotate}
              scale={zoom}
              position={position}
              onPositionChange={
                /* istanbul ignore next */
                newPosition => {
                  setPosition(newPosition);
                  setIsEdited(isEdited(rotate, zoom, newPosition));
                }
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography>{t('imageEditor.zoom')}</Typography>
          <Stack spacing={2} direction="row" alignItems="center">
            <IconRemove sx={{ color: 'secondary.text' }} />
            <Slider
              value={zoom}
              min={0.3}
              max={3}
              step={0.1}
              aria-label={t('imageEditor.zoom')}
              onChange={(_e, zoomLevel) => {
                const newZoom = Number(zoomLevel);
                setZoom(newZoom);
                setIsEdited(isEdited(rotate, newZoom, position));
              }}
              color="primary"
            />
            <IconAdd sx={{ color: 'secondary.text' }} />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography>{t('imageEditor.rotate')}</Typography>
          <Stack spacing={2} direction="row" alignItems="center">
            <IconRemove sx={{ color: 'secondary.text' }} />
            <Slider
              value={rotate}
              min={-180}
              max={180}
              step={1}
              aria-label={t('imageEditor.rotate')}
              onChange={(_e, rotateLevel) => {
                const newRotate = Math.min(180, Math.max(-180, Number(rotateLevel)));
                setRotate(newRotate);
                setIsEdited(isEdited(newRotate, zoom, position));
              }}
              color="primary"
            />
            <IconAdd sx={{ color: 'secondary.text' }} />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button variant="contained" color="primary" onClick={resetEdit}>
              {t('clear_label')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
