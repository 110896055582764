/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import axios from 'axios';

import { API } from 'transferPlanner/constants/api';

export type TAgreementsResponce = {
  communityCollegeAlternateId: number;
  communityCollegeName: string;
  archivedArtifactUrl: string;
};

export const fetchAgreements = async (programId: number, queryParams = ''): Promise<TAgreementsResponce[]> => {
  try {
    const { data } = await axios.get(
      `${API.programCartDetails}/${programId}/agreements?collegeAlternateIds=${queryParams}`
    );

    return data;
  } catch {
    return [];
  }
};
