/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { memo, ReactElement, useEffect, useMemo } from 'react';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import {
  IconGoldenFour,
  IconWrittenCommunication,
  IconCriticalThinking,
  IconMathematics,
  IconOralCommunication,
} from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'transferPlanner/constants/general';
import { selectGeneralEducationRequirements } from 'store/common/commonInfo.selectors';
import { useSelector } from 'react-redux';
import { pickGoldenFourCourses } from '../GeneralEducation.utils';

interface IMappingGoldenFourCourses {
  [key: string]: { icon: string; caption: string };
}

const GoldenFour = (): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const genEdReqData = useSelector(selectGeneralEducationRequirements);
  const mappingGoldenFourCourses: IMappingGoldenFourCourses = {
    A1: { icon: 'G4Chat', caption: 'Examples: oral, public speaking, argumentation & debate' },
    A2: { icon: 'G4Edit', caption: 'Examples: composition, composition & literature' },
    A3: { icon: 'G4Star', caption: 'Examples: critical thinking, logic, argumentation & debate' },
    B4: { icon: 'G4Calc', caption: 'Examples: statistics, college algebra, precalculus, calculus' },
  };
  const goldenFourCodes = Object.keys(mappingGoldenFourCourses);
  const goldenFourCourses = useMemo(
    () => pickGoldenFourCourses(genEdReqData, goldenFourCodes),
    [genEdReqData, goldenFourCodes]
  );

  /* istanbul ignore next */
  useEffect(() => {
    if (isMobile) {
      const elements = document.getElementsByClassName('AppuxSidePanel-content');
      if (elements?.length > 0) {
        elements[0]?.setAttribute('aria-hidden', 'true');
      }
    }
  }, [isMobile]);

  return (
    <>
      <Box sx={{ pb: '2rem' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <IconGoldenFour aria-label="icon award" />

          <Typography sx={{ pl: '0.5rem' }} variant="h2">
            {t('generalEducation.goldenFour.header')}
          </Typography>
        </Box>
        <Typography sx={{ pt: '1rem' }} variant="body1">
          {t('generalEducation.goldenFour.header_caption')}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h3" sx={{ pb: '1rem' }}>
          {t('generalEducation.goldenFour.course_title')}
        </Typography>
        {goldenFourCourses?.map(requirement => {
          const { icon, caption } = mappingGoldenFourCourses[requirement.code];
          return (
            <Box
              sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', pb: '1rem' }}
              key={`${requirement.code} - ${requirement.name}`}
            >
              {icon === 'G4Chat' && <IconOralCommunication sx={{ fontSize: 35 }} aria-label="icon chat" />}
              {icon === 'G4Edit' && <IconWrittenCommunication sx={{ fontSize: 35 }} aria-label="icon edit" />}
              {icon === 'G4Star' && <IconCriticalThinking sx={{ fontSize: 35 }} aria-label="icon star" />}
              {icon === 'G4Calc' && <IconMathematics sx={{ fontSize: 35 }} aria-label="icon calc" />}

              <Box sx={{ pl: '0.5rem' }}>
                <Typography variant="h4" sx={{ mb: '-0.375rem' }}>
                  {`${requirement.code} - ${requirement.name}`}
                </Typography>
                <Typography variant="caption">{caption}</Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default memo(GoldenFour);
