/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { useMemo, ReactElement } from 'react';
import { Grid, Box, Typography, Card, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { IconCaretRight, IconPhone, IconPhoneCircle, IconRemoveCircle, Icon } from '@liaison/liaison-ui';
import { IRelationship } from 'userProfile/store/biographicalInfo/biographicalInfo.slice';
import { IAddress, IEmail, IPhone, ISocialMedia } from 'userProfile/store/personalInfo/personalInfo.slice';
import { ReactComponent as LinkedInIcon } from 'assets/svgs/in-icon-square.svg';
import {
  IExperience,
  IHonorsAndAward,
  ILanguage,
} from 'userProfile/store/accomplishmentAndExperience/accomplishmentAndExperience.slice';
import { ICheckboxGroupResponse } from 'components/CheckboxGroup/CheckboxGroup';
import { t } from 'i18next';
import { StyledPrimaryButton, sxCard } from 'pages/Pages.styles';
import type { ILocationInterest, ISchoolOfInterest } from 'userProfile/store/academicInfo/academicInfo.slice';
import { ExperienceCard } from './Cards/ExperienceCard';
import { HonorsAndAwardsCard } from './Cards/HonorsAndAwardsCard';
import { getAddressString, getRelationshipResidency, getRelationshipSubTitle } from './ViewBuilder.utils';
import { textEllipsis, wrapText } from './ViewBuilder.styles';

export const StyledTypographyForTitle = styled(Typography)(() => ({
  fontFamily: 'Proxima Nova Semibold',
  fontSize: '0.75rem',
}));

export const StyledTypographyForValue = styled(Typography)(() => ({
  fontSize: '0.75rem',
  lineHeight: '120%',
}));

interface IRelationshipCardProps {
  relationships: IRelationship[];
  smallLayout?: number;
  xtraSmallLayout?: number;
  openChildDrawer?: (isAddNew: boolean, childPosition: number, parentSelector: string, formTitle: string) => void;
  isOpenFromForm?: number;
  isFormValid: boolean;
}
interface IHonorsAndAwardsCardProps {
  honorsAndAwards: IHonorsAndAward[];
  isOpenFromForm?: number;
  openChildDrawer?: (isAddNew: boolean, childPosition: number, parentSelector: string, formTitle: string) => void;
  selector: string;
  formTitle: string;
}

export const RelationshipCard = ({
  relationships,
  xtraSmallLayout = 12,
  smallLayout = 6,
  openChildDrawer,
  isOpenFromForm = 1,
  isFormValid,
}: IRelationshipCardProps): ReactElement => {
  return (
    <Grid container spacing={2}>
      {relationships.map((relationship, position) => {
        return (
          <Grid
            item
            xs={xtraSmallLayout}
            sm={smallLayout}
            key={relationship?.relationship?.code + relationship?.givenName}
          >
            <Card variant="outlined" sx={sxCard}>
              <Box
                sx={{
                  p: '1rem',
                  borderBottom: '1px solid #DBDBDB',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  pointerEvents: isFormValid ? '' : 'none',
                }}
                onClick={() => {
                  if (isFormValid) {
                    openChildDrawer?.(false, position, '', '');
                  }
                }}
              >
                <Box sx={{ flex: 0.9 }}>
                  <Typography
                    variant="subtitle4"
                    sx={{ width: '90%', overflowWrap: 'anywhere' }}
                  >{`${relationship.givenName} ${relationship.familyName}`}</Typography>
                  <Typography variant="caption" sx={{ pt: '0.3rem', display: 'block' }}>
                    {getRelationshipSubTitle(relationship)}
                  </Typography>
                </Box>
                <Box sx={{ flex: 0.1 }}>
                  <IconButton
                    id={`${relationship?.relationship?.code}-${isOpenFromForm}-${position}`}
                    aria-label="View Relationship"
                    role="button"
                  >
                    <IconCaretRight />
                  </IconButton>
                </Box>
              </Box>

              <Box sx={{ p: '1rem' }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>
                    <StyledTypographyForTitle>Education</StyledTypographyForTitle>
                    <StyledTypographyForValue>
                      {relationship?.educationLevel?.displayName || ''}
                    </StyledTypographyForValue>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <StyledTypographyForTitle>Residency</StyledTypographyForTitle>
                    <StyledTypographyForValue>{getRelationshipResidency(relationship)}</StyledTypographyForValue>
                  </Grid>
                </Grid>
                <Box sx={{ pt: '1rem' }} />
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6}>
                    <StyledTypographyForTitle>Occupation</StyledTypographyForTitle>
                    <StyledTypographyForValue>{relationship?.occupation?.displayName}</StyledTypographyForValue>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <StyledTypographyForTitle>Primary Residency</StyledTypographyForTitle>
                    <StyledTypographyForValue>{relationship.inPrimaryHousehold || ''}</StyledTypographyForValue>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

interface IAddressViewCardProps {
  addresses: IAddress[] | undefined;
  xsLayout?: number;
  smLayout?: number;
  mdLayout?: number;
  lgLayout?: number;
  isOpenFromForm?: number;
  openChildDrawer?: (isAddNew: boolean, childPosition: number, selector: string, formTitle: string) => void;
}
export const AddressViewCard = (props: IAddressViewCardProps): ReactElement => {
  const {
    addresses,
    xsLayout = 12,
    smLayout = 12,
    mdLayout = 12,
    lgLayout = 6,
    openChildDrawer,
    isOpenFromForm = 1,
  } = props;
  return (
    <Grid container spacing={2} sx={{ mb: '0.75rem' }}>
      {(addresses || []).map((address, position) => {
        return (
          <Grid key={address?.type?.code} item xs={xsLayout} sm={smLayout} md={mdLayout} lg={lgLayout}>
            <Card variant="outlined" sx={sxCard}>
              <Box sx={{ p: '1rem' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    pb: '0.5rem',
                  }}
                  data-testid={address.type}
                  onClick={() => openChildDrawer?.(false, position, 'contactInfo.editAddress', '')}
                >
                  <Box sx={{ flex: 0.9 }}>
                    <Typography variant="subtitle3">{`${address.type?.displayName} ${t(
                      'contactInfo.address'
                    )}`}</Typography>
                  </Box>
                  <Box sx={{ flex: 0.1, display: 'flex', justifyContent: 'center' }}>
                    <IconButton
                      id={`${address?.type?.code}-${isOpenFromForm}-${position}`}
                      aria-label={address?.type?.code}
                      role="button"
                    >
                      <IconCaretRight />
                    </IconButton>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Box>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem', width: '95%', overflowWrap: 'anywhere' }}>
                      {getAddressString(address, 'line1')}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem', width: '95%', overflowWrap: 'anywhere' }}>
                      {getAddressString(address, 'line2')}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                      {getAddressString(address, 'line3')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

interface ISocialMediaViewCardProps {
  socialMedia: ISocialMedia[] | undefined;
  xsLayout?: number;
  smLayout?: number;
  mdLayout?: number;
  lgLayout?: number;
  toggleDrawer?: () => void;
}
export const SocialMediaViewCard = (props: ISocialMediaViewCardProps): ReactElement => {
  const { socialMedia, xsLayout = 12, smLayout = 12, mdLayout = 12, lgLayout = 6, toggleDrawer } = props;
  return (
    <Grid container spacing={2} sx={{ mb: 3 }}>
      {(socialMedia || []).map(social => {
        return (
          <Grid key={social?.type?.code} item xs={xsLayout} sm={smLayout} md={mdLayout} lg={lgLayout}>
            <Card variant="outlined" sx={{ p: 2, cursor: 'pointer', ...sxCard }}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="nowrap"
                spacing={1}
                data-testid={social.type}
                onClick={() => toggleDrawer?.()}
              >
                <Grid item xs={10} container direction="row" alignItems="center" flexWrap="nowrap" spacing={2}>
                  <Grid item xs="auto" container>
                    {social.type?.code === 'LINKEDIN' && (
                      <LinkedInIcon aria-label="LinkedIn Icon" height={40} width={40} />
                    )}
                    {social.type?.code === 'CUSTOM' && (
                      <Icon aria-label={social?.type?.code} sx={{ width: 40, height: 40 }}>
                        IconLink
                      </Icon>
                    )}
                  </Grid>
                  <Grid item xs zeroMinWidth>
                    <Typography variant="subtitle3">{social.type?.displayName}</Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }} noWrap>
                      {social.url}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2} container justifyContent="end">
                  <IconButton id={`${social?.type?.code}`} aria-label={social?.type?.code} role="button">
                    <IconCaretRight />
                  </IconButton>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

interface IPhoneProps {
  phones: IPhone[];
}
export const PhoneView = ({ phones }: IPhoneProps): ReactElement => {
  return (
    <>
      {phones.map(item => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }} key={`${item.phone}-${item.type?.code}`}>
            <Box sx={{ mr: '0.5rem' }}>
              {item.type?.code === 'MOBILE' ? (
                <IconPhoneCircle sx={{ fontSize: '1rem' }} />
              ) : (
                <IconPhone sx={{ fontSize: '1rem' }} />
              )}
            </Box>
            <Typography variant="body2" display="block" gutterBottom data-testid="phone">
              {item.phone}
            </Typography>
            <Typography
              variant="body2"
              display="block"
              gutterBottom
              data-testid="phoneType"
              sx={{ fontSize: '0.75rem', justifyContent: 'center', ml: '0.5rem' }}
            >
              {item.type?.displayName}
            </Typography>
            {item.preferred && (
              <StyledPrimaryButton
                size="small"
                variant="outlined"
                disabled
                sx={{ ml: '0.5rem', mb: '0.2rem' }}
                startIcon={<Icon sx={{ color: theme => theme.palette.secondary.contrastText }}>IconCheckCircle</Icon>}
              >
                {t('primary_label') as string}
              </StyledPrimaryButton>
            )}
          </Box>
        );
      })}
    </>
  );
};

interface IEmailProps {
  emails: IEmail[];
}
export const EmailView = ({ emails }: IEmailProps): ReactElement => {
  return (
    <>
      {emails.map(item => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }} key={item?.type?.code}>
            <Typography
              variant="body2"
              display="block"
              gutterBottom
              data-testid="emailAddress"
              sx={{ overflowWrap: 'anywhere' }}
            >
              {item?.email}
            </Typography>
            <Typography
              variant="body2"
              display="block"
              gutterBottom
              data-testid="emailType"
              sx={{ fontSize: '0.75rem', justifyContent: 'center', ml: '0.5rem' }}
            >
              {item.type?.displayName}
            </Typography>
            {item.preferred && (
              <StyledPrimaryButton
                size="small"
                variant="outlined"
                disabled
                sx={{ ml: '0.5rem', mb: '0.2rem' }}
                startIcon={<Icon sx={{ color: theme => theme.palette.secondary.contrastText }}>IconCheckCircle</Icon>}
              >
                {t('primary_label') as string}
              </StyledPrimaryButton>
            )}
          </Box>
        );
      })}
    </>
  );
};

interface IMultiValued {
  multiValued?: string[] | { displayName: string }[];
}

export const MultiValuedView = ({ multiValued = [] }: IMultiValued): ReactElement => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      {multiValued.map(item => {
        const value = typeof item === 'object' ? item.displayName : item;
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#75757d',
              mr: '0.625rem',
              mb: '0.75rem',
              px: '0.75rem',
              py: '0.375rem',
              borderRadius: '1rem',
            }}
            key={value}
          >
            <Typography variant="body2" display="block" sx={{ fontSize: '0.75rem', color: 'white' }}>
              {value}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export const HonorsAndAwardsList = ({
  honorsAndAwards,
  openChildDrawer,
  selector,
  formTitle,
  isOpenFromForm,
}: IHonorsAndAwardsCardProps): ReactElement => {
  return (
    <Grid container spacing={1}>
      {honorsAndAwards.map((honorOrAward, position) => {
        const key = position + 1;
        return (
          <HonorsAndAwardsCard
            key={key}
            honorOrAward={honorOrAward}
            position={position}
            openChildDrawer={openChildDrawer}
            isOpenFromForm={isOpenFromForm}
            selector={selector}
            formTitle={formTitle}
          />
        );
      })}
    </Grid>
  );
};

interface ILanguagePops {
  languages: ILanguage[];
}
export const LanguageView = ({ languages }: ILanguagePops): ReactElement => {
  return (
    <>
      {languages.map(item => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }} key={item?.language?.displayName}>
            <Typography
              variant="body2"
              display="block"
              gutterBottom
              data-testid="emailAddress"
              sx={{ overflowWrap: 'anywhere' }}
            >
              {item?.language?.displayName}
            </Typography>
            <Typography
              variant="body2"
              display="block"
              gutterBottom
              data-testid="emailType"
              sx={{ fontSize: '0.75rem', justifyContent: 'center', ml: '0.5rem' }}
            >
              {item?.proficiencyLevel?.displayName}
            </Typography>
          </Box>
        );
      })}
    </>
  );
};

interface IExperienceListPops {
  experiences: IExperience[];
  xtraSmallLayout?: number;
  mediumLayout?: number;
  openChildDrawer?: (isAddNew: boolean, childPosition: number, parentSelector: string, formTitle: string) => void;
  selector: string;
  formTitle: string;
}
export const ExperienceList = ({
  experiences,
  xtraSmallLayout = 12,
  mediumLayout = 6,
  openChildDrawer,
  selector,
  formTitle,
}: IExperienceListPops): ReactElement => {
  return (
    <Grid container spacing={1}>
      {experiences.map((experience, position) => {
        const key = position + 1;
        return (
          <Grid item xs={xtraSmallLayout} md={mediumLayout} key={key}>
            <ExperienceCard
              experience={experience}
              position={position}
              openChildDrawer={openChildDrawer}
              selector={selector}
              formTitle={formTitle}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

interface IEthnicityViewProps {
  ethnicities: ICheckboxGroupResponse[];
}
export const EthnicityView = ({ ethnicities }: IEthnicityViewProps): ReactElement => {
  const ethnicity =
    ethnicities?.reduce((result, option) => {
      return `${result && `${result},`}${option.otherText ? option.otherText : option.displayName}`;
    }, '') ?? '';
  return (
    <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }} display="block" gutterBottom>
      {ethnicity}
    </Typography>
  );
};

interface IRaceViewProps {
  races: ICheckboxGroupResponse[];
  xtraSmallLayout?: number;
  smallLayout?: number;
  mediumLayout?: number;
}
const getClassification = (classification: ICheckboxGroupResponse[]): string => {
  return (
    classification?.reduce((result, option) => {
      return `${result && `${result},`}${option.otherText ? option.otherText : option.displayName}`;
    }, '') ?? ''
  );
};
export const RaceCardView = ({
  races,
  xtraSmallLayout = 12,
  smallLayout = 6,
  mediumLayout = 4,
}: IRaceViewProps): ReactElement => {
  return (
    <Grid container sx={{ mb: '1rem' }}>
      {races.map(race => {
        return (
          <Grid
            item
            xs={xtraSmallLayout}
            sm={smallLayout}
            md={mediumLayout}
            key={race?.code}
            sx={{
              border: '1px solid #DBDBDB',
              mr: '-1px',
              mb: '-1px',
            }}
          >
            <Box
              sx={{
                p: '1rem',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="subtitle3"
                sx={{ ...wrapText, fontSize: '0.75rem', ...textEllipsis }}
              >{`${race.displayName}`}</Typography>
              {race.otherText && (
                <Typography
                  variant="caption"
                  sx={{ pt: '0.3rem', ...wrapText, ...textEllipsis }}
                >{`${race.otherText}`}</Typography>
              )}
              {race.classifications && (
                <Typography variant="caption" sx={{ pt: '0.3rem', ...wrapText, ...textEllipsis }}>
                  {getClassification(race.classifications)}
                </Typography>
              )}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

interface ILocationsOfInterestCardProps {
  locationInterests: ILocationInterest[];
  xsLayout?: number;
  smLayout?: number;
  mdLayout?: number;
  lgLayout?: number;
}
export const LocationsOfInterestCard = ({
  locationInterests,
  xsLayout = 12,
  smLayout = 12,
  mdLayout = 12,
  lgLayout = 6,
}: ILocationsOfInterestCardProps): ReactElement => {
  const locations = useMemo(() => {
    return locationInterests?.reduce((acc, cur) => {
      const { country, region } = cur;
      const c = country?.displayName || '';
      const r = region?.displayName;

      if (!(c in acc)) {
        acc[c] = [];
      }
      if (r) acc[c].push(r);

      return acc;
    }, {} as { [key: string]: string[] });
  }, [locationInterests]);

  return (
    <Grid container spacing={1} alignItems="stretch" mb="1.5rem">
      {Object.entries(locations)?.map(([country, regions]) => {
        return (
          <Grid item display="flex" xs={xsLayout} sm={smLayout} md={mdLayout} lg={lgLayout} key={country}>
            <Card variant="outlined" sx={{ p: '1rem', flex: 1, ...sxCard }}>
              <Typography variant="subtitle4">{country}</Typography>
              <Typography variant="caption" sx={{ pt: '0.3rem', display: 'block', ...wrapText }}>
                {regions?.join(', ')}
              </Typography>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

interface ISchoolsOfInterestsCardPops {
  schools: ISchoolOfInterest[];
  xsLayout?: number;
  smLayout?: number;
  mdLayout?: number;
  lgLayout?: number;
  deleteRecord?: (childPosition: number) => void;
  displayDeleteIcon?: boolean;
}
export const SchoolsOfInterestsCard = ({
  schools,
  deleteRecord,
  displayDeleteIcon,
  xsLayout = 12,
  smLayout = 12,
  mdLayout = 12,
  lgLayout = 6,
}: ISchoolsOfInterestsCardPops): ReactElement => {
  return (
    <Grid container spacing={1} mb={displayDeleteIcon ? 0 : '1.5rem'}>
      {schools.map((school: ISchoolOfInterest, position: number) => {
        return (
          <Grid item xs={xsLayout} sm={smLayout} md={mdLayout} lg={lgLayout} key={school?.name}>
            <Card variant="outlined" sx={sxCard}>
              <Box
                sx={{
                  p: '1rem',
                  borderBottom: '1px solid #DBDBDB',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ flex: 0.9 }}>
                  <Typography variant="subtitle4">{school.name}</Typography>
                  <Typography variant="caption" sx={{ pt: '0.3rem', display: 'block', ...wrapText }}>
                    {`${school.address?.city}, ${school.address?.region?.code} `}
                  </Typography>
                </Box>
                {displayDeleteIcon && (
                  <Box sx={{ flex: 0.1 }}>
                    <IconButton
                      id={school?.name}
                      aria-label={school?.name}
                      role="button"
                      color="error"
                      onClick={() => deleteRecord?.(position)}
                    >
                      <IconRemoveCircle />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};
