/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TError = string | null;
export interface IAdtDetails {
  communityCollegeAssistId: number;
  programTMCKey: number;
  isSimilar: boolean;
}
export interface IProgram {
  id: number;
  name: string;
  moKey?: string;
  tspEligible?: boolean;
  adtSimilar?: boolean;
  tracking?: {
    active: boolean;
    followedTimestamp: string;
    unfollowedTimestamp: string | undefined | null;
  };
  adtSimilarity?: IAdtDetails[];
}

interface ICampus {
  id: number;
  alternateId: {
    code: number;
    source: string;
  };
  name: string;
}

export interface ISelectedProgram {
  program: IProgram;
  campus: ICampus;
}

export interface IProgramCartResponse {
  profileId: string;
  selectedPrograms: ISelectedProgram[];
}

export type TFollowedProgramModified = {
  program: IProgram;
  campus: ICampus;
};

export interface IProgramsOfInterest {
  campus: ICampus;
  programs: IProgram[];
}

export interface IFollowedProgramsResponse {
  profileId: string;
  programsOfInterest: IProgramsOfInterest[];
}

export interface IProgramCartState {
  loading: boolean;
  programCart: IProgramCartResponse | null;
  followedPrograms: TFollowedProgramModified[] | null;
  followedActualPrograms: IFollowedProgramsResponse | null;
  unFollowedProgramIndex: number | null;
  error: TError;
}

export const initialState: IProgramCartState = {
  loading: false,
  programCart: null,
  followedPrograms: null,
  followedActualPrograms: null,
  unFollowedProgramIndex: null,
  error: null,
};

const programCartSlice = createSlice({
  name: 'programCart',
  initialState,
  reducers: {
    programCartStart: (state): IProgramCartState => ({
      ...state,
      loading: true,
      error: null,
    }),
    programCartSuccess: (state, action: PayloadAction<IProgramCartResponse>): IProgramCartState => ({
      ...state,
      loading: false,
      programCart: action.payload,
    }),
    programCartFailure: (state, action: PayloadAction<TError>): IProgramCartState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    followedProgramsStart: (state): IProgramCartState => ({
      ...state,
      loading: true,
      error: null,
    }),
    followedProgramsSuccess: (state, action: PayloadAction<TFollowedProgramModified[] | null>): IProgramCartState => ({
      ...state,
      loading: false,
      followedPrograms: action.payload,
      unFollowedProgramIndex: null,
    }),
    followedActualProgramsSuccess: (state, action: PayloadAction<IFollowedProgramsResponse>): IProgramCartState => ({
      ...state,
      loading: false,
      followedActualPrograms: action.payload,
      unFollowedProgramIndex: null,
    }),
    updateUnFollowedProgramIndex: (state, action: PayloadAction<number | null>): IProgramCartState => ({
      ...state,
      unFollowedProgramIndex: action.payload,
    }),
    followedProgramsFailure: (state, action: PayloadAction<TError>): IProgramCartState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  programCartStart,
  programCartSuccess,
  programCartFailure,
  followedProgramsStart,
  followedProgramsFailure,
  followedProgramsSuccess,
  followedActualProgramsSuccess,
  updateUnFollowedProgramIndex,
} = programCartSlice.actions;

export default programCartSlice.reducer;
