/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export const currentApp = localStorage.getItem('tenant') || 'liaison';
const { ABOUT_LIVING_PROFILE_URL, HELP_CENTER_URL, PRIVACY_POLICY_URL } = window.env;

export const EXTERNAL_URLS = {
  fbUrl: 'https://www.facebook.com/',
  twitterUrl: 'https://twitter.com/',
  linkedInUrl: 'https://www.linkedin.com/',
  youtubeUrl: 'https://www.youtube.com/',
  aboutLP: ABOUT_LIVING_PROFILE_URL,
  helpCenter: HELP_CENTER_URL,
  privacyPolicy: PRIVACY_POLICY_URL,
};
export const DEFAULT_COUNTRY = 'US';
export const DOB_FORMAT = 'YYYY-MM-DD';

export const KEYCLOAK = {
  liaison: {
    publicToPrivateTarget: '/linking',
    keycloakLoginPage: '/',
    keycloakEmailVerified: '/email-verified',
    keycloakRealM: 'lp-student',
    keycloakClientId: 'lp-student',
    keycloakLpActivationClientId: 'lp-student-activation',
  },
  csu: {
    publicToPrivateTarget: '/terms',
    keycloakLoginPage: '/',
    keycloakEmailVerified: '/email-verified',
    keycloakRealM: 'lp-student',
    keycloakClientId: 'tp-csu-student',
    keycloakLpActivationClientId: 'lp-student-activation',
  },
};

export const skipRoutesForLogin = ['terms', 'linking', 'profile-setup', 'onboarding'];

export const ahLookupApiLimit = 30;
export const defaultSessionTimeoutMins = 1;
