/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useMemo, useCallback } from 'react';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { selectTspEnrollment, selectTspEnrollmentPeriod } from 'transferPlanner/store/enrollment/enrollment.selectors';
import { nameSpace } from 'transferPlanner/constants/general';
import { setUi } from 'store/ui/ui.slice';
import { ACADEMIC_HISTORY } from 'userProfile/constants/routeNames';
import { TSP_QUESTIONNAIRE } from 'transferPlanner/constants/routeNames';
import { InfoBox } from '../../../InfoBox';
import { isEligibleToShowQuestionnaire } from '../../../Dashboard.utils';

export const WhatsNext = (): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tspEnrollment = useSelector(selectTspEnrollment);
  const isTspEnrollmentOpen = useSelector(selectTspEnrollmentPeriod)?.enrollmentOpen;

  const onClick = () => navigate(`/${ACADEMIC_HISTORY.path}`);

  const setOpenSidebar = useCallback(() => {
    dispatch(setUi({ name: 'collegeSidebar', state: { open: true } }));
  }, [dispatch]);

  const showQuestionnaire = useMemo(() => isEligibleToShowQuestionnaire(tspEnrollment), [tspEnrollment]);

  return (
    <Grid item container xs={12} rowSpacing={2} justifyContent="space-between">
      <Grid item xs={12}>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 600,
            fontSize: 14,
            mb: { sm: 1, md: 1, lg: 0, xl: 0 },
            mt: { sm: 2, md: 2, lg: 0, xl: 0 },
          }}
          role="heading"
        >
          {t('whats_next_label')}
        </Typography>
      </Grid>
      {isTspEnrollmentOpen ? (
        showQuestionnaire && (
          <InfoBox
            isWelcomeModal={false}
            infoText={t('dashboard.checkEligibility')}
            buttonText1={t('begin_questionnaire_label')}
            onClick1={() => navigate(`/${TSP_QUESTIONNAIRE}`)}
          />
        )
      ) : (
        <InfoBox
          isWelcomeModal={false}
          infoText={t('dashboard.alreadyEnrolled')}
          buttonText1={t('add_college_label')}
          buttonText2={t('edit_academic_history_label')}
          onClick1={setOpenSidebar}
          onClick2={onClick}
        />
      )}

      <InfoBox
        isWelcomeModal={false}
        infoText={t('dashboard.newTerm')}
        buttonText1={t('go_to_academic_history_label')}
        onClick1={onClick}
      />
    </Grid>
  );
};
