/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';
import { IPersonalInfoState } from './personalInfo.slice';

const personalInfo = (state: TRootState): IPersonalInfoState => state.personalInfo;

export const selectPersonalInformation = createSelector(
  personalInfo,
  (stateData: IPersonalInfoState) => stateData?.personalInfoData
);

export const selectPersonalInformationLocalData = createSelector(
  personalInfo,
  (stateData: IPersonalInfoState) => stateData?.personalInfoLocalData
);

export const selectLoading = createSelector(personalInfo, (stateData: IPersonalInfoState) => stateData?.loading);

export const selectUserName = createSelector(personalInfo, (stateData: IPersonalInfoState) => {
  const userFirstName =
    stateData?.personalInfoData?.personal?.preferredName || stateData?.personalInfoData?.personal?.givenName || '';
  const userLastName = stateData?.personalInfoData?.personal?.familyName || '';
  const userFullName = `${userFirstName} ${userLastName}`.trim();
  return { userFirstName, userLastName, userFullName };
});
