import { AutocompleteProps, createFilterOptions, FilterOptionsState } from '@mui/material';

export type TMultiSelectOption = {
  id: string;
  text: string;
  newTag?: boolean;
  fieldsOfStudyCode?: string;
};
export const defaultGetOptionLabel = (option: TMultiSelectOption): string => option.text;

export interface IMultiSelectAutocompleteProps
  extends Omit<
    AutocompleteProps<TMultiSelectOption, true, boolean, boolean, 'div'>,
    'value' | 'options' | 'onChange' | 'renderInput'
  > {
  value: string[] | null;
  options: TMultiSelectOption[];
  fieldsOfStudyCodes: string[] | undefined;
  fieldsOfStudyDisplay: string[] | undefined;
  onChange: (option: TMultiSelectOption[] | null) => void;
  title: string;
  maxOptionSelection?: number;
  colorTheme?: 'primary' | 'secondary';
}

export const getOptionsByIds = (options: TMultiSelectOption[], ids: string[] | null): TMultiSelectOption[] =>
  ids?.reduce((result, id) => {
    const optionById = options.find(option => option.id === id);
    return optionById ? [...result, optionById] : result;
  }, [] as TMultiSelectOption[]) ?? [];

export const customFilterOptions = (
  allOptions: TMultiSelectOption[],
  params: FilterOptionsState<TMultiSelectOption>
): TMultiSelectOption[] => {
  const filtered = createFilterOptions<TMultiSelectOption>()(allOptions, params);
  const { inputValue } = params;
  const isExisting = allOptions?.some(
    (option: TMultiSelectOption) => inputValue.toLowerCase() === option.text.toLowerCase()
  );
  /* istanbul ignore next */
  if (inputValue !== '' && !isExisting) {
    filtered.push({
      id: inputValue,
      text: inputValue,
      newTag: true,
    });
  }

  return filtered as TMultiSelectOption[];
};

export const isOptionEqualToValue = (option: TMultiSelectOption, selected: TMultiSelectOption): boolean =>
  option.id === selected.id;
