/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Theme } from '@mui/material';

export const sxInfoBox = (theme: Theme, isWelcomeModal: boolean) =>
  ({
    bgcolor: isWelcomeModal ? theme.palette.secondary.contrastText : '#EDE7F6',
    border: isWelcomeModal ? '1px solid #642F6C' : '1px solid  #311B92',
    borderRadius: 1,
    p: isWelcomeModal ? { xs: 1, sm: 2, md: 2, lg: 2 } : { xs: 1, md: 2, lg: 2 },
    flex: isWelcomeModal ? 1 : 0,
    margin: isWelcomeModal ? '0.5rem' : 0,
  } as const);
