/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';
import { IFeaturesState } from './features.slice';

const selectFeaturesState = (state: TRootState): IFeaturesState => state.features;

export const selectFeatures = createSelector(
  selectFeaturesState,
  (featuresState: IFeaturesState) => featuresState?.features
);

export const selectFeaturesLoading = createSelector(
  selectFeaturesState,
  (featuresState: IFeaturesState) => featuresState?.loading
);
