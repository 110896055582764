/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, Fragment, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider, FieldValues, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import {
  Grid,
  Box,
  Card,
  CardContent,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Theme } from '@mui/system';
import { IconEdit, CollapsibleSection as Accordion, SidePanel } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';

import { StyledAddButton, sxSidePanel, sxCard, tableStyles } from 'pages/Pages.styles';
import { postAcademicHistory } from 'utils/AcademicHistory.utils';
import { getButtonColor, sanitizePayload } from 'utils/utilities';
import { selectAcademicHistory } from 'store/academicHistory/academicHistory.selectors';
import type { IHighSchool, TGradeLevel, THighSchoolCourse } from 'store/academicHistory/academicHistory.slice';
import { gradeLevelValidationSchema } from 'userProfile/pages/AcademicHistory/AcademicHistoryForm.validation';
import HighSchoolGradeLevelForm from 'userProfile/pages/AcademicHistory/AcademicHistoryForm/HighSchoolGradeLevelForm';
import { prepareGradeLevelsOptions } from 'userProfile/pages/AcademicHistory/AcademicHistoryForm/AcademicHistoryForm.utils';

type TCourseCardProps = {
  course: THighSchoolCourse;
  isEditable: boolean;
  onClick?: () => void;
};

const CourseCard = ({ course, isEditable = false, onClick }: TCourseCardProps): ReactElement => {
  return (
    <Card role="listitem" variant="outlined" sx={{ ...sxCard, my: 1, color: 'text.primary' }}>
      <CardContent
        sx={{
          py: 1,
          '&:last-child': {
            pb: 1,
          },
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={isEditable ? 11 : 12} container>
            <Typography variant="subtitle7">{course.title}</Typography>
          </Grid>
          {isEditable && (
            <Grid item xs={1} container>
              <IconButton aria-label={`Edit ${course.title}`} data-testid="edit-course-icon" onClick={onClick}>
                <IconEdit style={{ fontSize: 14 }} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

interface IHighSchoolsCourses {
  data?: IHighSchool;
  groupName: string;
  childPosition: number;
  openGrandChildDrawer?: (
    isAddNew: boolean,
    childPosition: number,
    parentSelector: string,
    formTitle: string,
    parentPosition?: number,
    sectionPrefix?: string,
    courseIndex?: number,
    isEditAnother?: boolean
  ) => void;
}

const HignSchoolCourses = ({
  data,
  groupName,
  childPosition,
  openGrandChildDrawer,
}: IHighSchoolsCourses): ReactElement => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const dispatch = useDispatch();
  const [isOpenGradePanel, setIsOpenGradePanel] = useState(false);
  const [selectedGradeLevel, setSelectedGradeLevel] = useState<TGradeLevel>();
  const academicHistoryData = useSelector(selectAcademicHistory);

  const gradeLevelsList = useMemo(() => data?.gradeLevels, [data]);
  const fromYear = data?.startDate ? moment(data?.startDate, 'YYYY-MMM').year() : 1990;
  const toYear = data?.endDate ? moment(data?.endDate, 'YYYY-MMM').year() : 2100;
  const gradeLevelsOptions = useMemo(() => prepareGradeLevelsOptions(gradeLevelsList), [gradeLevelsList]);

  const gradeLevelFormMethods = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(gradeLevelValidationSchema, { abortEarly: false })(...args),
    context: { formName: 'gradeLevels' },
  });

  const closeGradePanel = () => {
    setIsOpenGradePanel(false);
  };
  /* istanbul ignore next */
  const onGradeLevelFormSubmit = () => {
    const currentGradeLevel = gradeLevelFormMethods.getValues() as TGradeLevel;
    const highSchools = [...(academicHistoryData?.highSchools || [])] as unknown as IHighSchool[];
    const allGradeLevels = [...(highSchools[childPosition]?.gradeLevels || [])];
    const currentGradeLevelIndex = allGradeLevels.findIndex(gradeLevel => gradeLevel.id === currentGradeLevel.id);
    allGradeLevels[currentGradeLevelIndex] = currentGradeLevel;
    highSchools[childPosition] = {
      ...highSchools[childPosition],
      gradeLevels: allGradeLevels,
    };
    const payload = {
      ...academicHistoryData,
      highSchools,
    };
    dispatch(
      postAcademicHistory(sanitizePayload(Object.assign(payload)), () => {
        closeGradePanel();
      })
    );
  };

  const handleGradePanel = () => {
    return (
      <FormProvider {...gradeLevelFormMethods}>
        <form>
          <SidePanel
            size="small"
            open={isOpenGradePanel}
            onClose={closeGradePanel}
            title={t('highschool.editGrade')}
            footerButtonConfig={{
              primary: {
                title: t('save_label'),
                props: {
                  'aria-label': t('save_label'),
                  color: getButtonColor(),
                  variant: 'contained',
                  disabled: !gradeLevelFormMethods.formState.isValid,
                  onClick: gradeLevelFormMethods.handleSubmit(onGradeLevelFormSubmit as SubmitHandler<FieldValues>),
                },
              },
              tertiary: {
                title: t('cancel_label'),
                props: {
                  'aria-label': t('cancel_label'),
                  color: getButtonColor(),
                  onClick: closeGradePanel,
                },
              },
            }}
            sx={sxSidePanel}
          >
            <HighSchoolGradeLevelForm
              highSchoolStartYear={fromYear}
              highSchoolEndYear={toYear}
              gradeLevelList={gradeLevelsOptions}
              selectedGradeLevel={selectedGradeLevel}
            />
          </SidePanel>
        </form>
      </FormProvider>
    );
  };

  return (
    <Grid container spacing={2}>
      {gradeLevelsList?.map((gradeLevel, index) => {
        const level = `${gradeLevel?.type?.displayName} ${gradeLevel?.academicYear}`;
        return (
          <React.Fragment key={level}>
            <Grid item xs={12} sx={{ mt: '.5rem' }}>
              <Accordion
                expanded
                groupId={level}
                title={`${level} Grade`}
                actions={[
                  {
                    icon: <IconEdit />,
                    label: `${t('highschool.editGrade')} ${level}`,
                    size: 'small',
                    onClick: () => {
                      setIsOpenGradePanel(true);
                      setSelectedGradeLevel(gradeLevel);
                    },
                  },
                ]}
              >
                <>
                  {isMobile ? (
                    (gradeLevel?.courses || []).map((course: THighSchoolCourse, courseIndex: number) => (
                      <Fragment key={course?.id}>
                        {course && (
                          <CourseCard
                            course={course}
                            isEditable={true}
                            onClick={() =>
                              openGrandChildDrawer?.(
                                false,
                                index || 0,
                                groupName,
                                t('highSchool.editSchoolCourse'),
                                childPosition,
                                'gradeLevels',
                                courseIndex,
                                false
                              )
                            }
                          />
                        )}
                      </Fragment>
                    ))
                  ) : (
                    <TableContainer component={Paper}>
                      <Table
                        aria-label="table header"
                        sx={{
                          ...tableStyles.headerFont,
                          ...tableStyles.font,
                          borderCollapse: 'unset',
                        }}
                      >
                        <TableHead>
                          <TableRow sx={tableStyles.header}>
                            <TableCell align="left">Course</TableCell>
                            <TableCell width="50" align="right" aria-hidden="true" />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(gradeLevel?.courses || []).map((course: THighSchoolCourse, courseIndex: number) => {
                            return (
                              <TableRow key={`${course.title}-${course.grade?.overall}`} sx={tableStyles.rowShadow}>
                                <TableCell align="left">{course.title}</TableCell>
                                <TableCell width="50" align="right">
                                  <IconButton
                                    aria-label={t('highSchool.editSchoolCourse')}
                                    onClick={() =>
                                      openGrandChildDrawer?.(
                                        false,
                                        index || 0,
                                        groupName,
                                        t('highSchool.editSchoolCourse'),
                                        childPosition,
                                        'gradeLevels',
                                        courseIndex,
                                        false
                                      )
                                    }
                                    size="small"
                                  >
                                    <IconEdit />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  <Box sx={{ display: 'flex', justifyContent: 'center', pt: '2rem' }}>
                    <StyledAddButton
                      aria-label={t('academicHistory.addCourse')}
                      onClick={() =>
                        openGrandChildDrawer?.(
                          true,
                          index,
                          groupName,
                          t('academicHistory.addCourse'),
                          childPosition,
                          'gradeLevels',
                          gradeLevel?.courses?.length,
                          false
                        )
                      }
                    >
                      {t('academicHistory.addCourse')}
                    </StyledAddButton>
                  </Box>
                </>
              </Accordion>
            </Grid>
          </React.Fragment>
        );
      })}
      {handleGradePanel()}
    </Grid>
  );
};

export default HignSchoolCourses;
