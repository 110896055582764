/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect, useMemo, useState, useCallback } from 'react';
import { Grid, Typography, Box, FormControl, InputLabel, InputBase, FormHelperText } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { TBiographicalInfoField } from 'userProfile/store/biographicalInfo/biographicalInfo.slice';
import { RelationshipCard } from 'userProfile/components/ViewBuilder/CardView';
import { Dropdown } from '@liaison/liaison-ui';
import { RadioControl } from 'components/RadioControl';
import { emptyOptions, getDropDownOptions, Ioption } from 'utils/utilities';
import { getLookUps } from 'utils/commonUtils';
import { useFormContext, Controller } from 'react-hook-form';
import MasterData from 'userProfile/constants/master';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { DISABLE_ADDRESS_AUTO_FILL, DISABLE_AUTO_FILL, FIELD_LENGTH_100 } from 'constants/field';
import { StyledAddButton, StyledDeleteButton } from 'pages/Pages.styles';
import {
  selectOccupationLookup,
  selectCountriesLookup,
  selectStatesLookup,
  selectCountiesLookup,
} from 'store/common/commonInfo.selectors';
import { resetlookupData } from 'store/common/commonInfo.slice';
import { useTranslation } from 'react-i18next';
import type { TBiographicFormErrors } from '../BiographicalInformationForm.validation';

interface IBiographicalInfoProps {
  data: TBiographicalInfoField | null;
  openChildDrawer: (isAddNew: boolean, childPosition: number) => void;
  isOpenChildDrawer?: boolean;
  childPosition?: number;
  deleteRelationship?: ((childPosition: number) => void) | null;
}

export const handleEmptyLookup = (data: TBiographicalInfoField | null, position: number): Ioption[] => {
  if (!data) return emptyOptions;
  return [data?.relationships?.[position]?.occupation || emptyOptions[0]] as unknown as Ioption[];
};

const RelationshipInformation = ({
  data,
  openChildDrawer,
  isOpenChildDrawer,
  childPosition = 0,
  deleteRelationship,
}: IBiographicalInfoProps): ReactElement => {
  const [showAlert, setShowAlert] = useState(false);
  const {
    control,
    reset,
    register,
    setValue,
    formState: { errors: formErrors, dirtyFields, isValid },
    watch,
  } = useFormContext();
  const errors = formErrors as unknown as TBiographicFormErrors;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const groupName = 'relationships';
  const occupationLookup = useSelector(selectOccupationLookup);
  const relationshipsToStudentOptions = useMemo(() => getDropDownOptions(MasterData.relationship), []);
  const legalSexOptions = useMemo(() => getDropDownOptions(MasterData.genderOptions), []);
  const radioDefaultOptions = useMemo(() => MasterData?.radioDefaultOptions, []);
  const livingOptions = useMemo(() => MasterData?.radioDefaultOptions, []);
  const occupationOptions = useMemo(
    () => getDropDownOptions(occupationLookup || handleEmptyLookup(data, childPosition)),
    [data, occupationLookup, childPosition]
  );
  const educationLevelOptions = useMemo(() => getDropDownOptions(MasterData.educationLevelOptions), []);

  const watchCountry = watch(`${groupName}.${childPosition}.address.country.code`);
  const watchState = watch(`${groupName}.${childPosition}.address.region.code`);

  const countriesLookup = useSelector(selectCountriesLookup);
  const statesLookup = useSelector(selectStatesLookup);
  const countiesLookup = useSelector(selectCountiesLookup);

  const getStateList = useCallback(
    (countryId: string | undefined) => {
      dispatch(getLookUps('states', `?countryCode=${countryId}`));
    },
    [dispatch]
  );

  const getCountyList = useCallback(
    (stateId: string | undefined) => {
      dispatch(getLookUps('counties', `?stateCode=${stateId}`));
    },
    [dispatch]
  );

  const countryOptions = useMemo(() => getDropDownOptions(countriesLookup || []), [countriesLookup]);
  const stateOptions = useMemo(() => getDropDownOptions(statesLookup || []), [statesLookup]);
  const countyOptions = useMemo(() => getDropDownOptions(countiesLookup || []), [countiesLookup]);

  useEffect(() => {
    if (data) {
      reset(data);
      if (isOpenChildDrawer && data?.[groupName] && data?.[groupName]?.[childPosition]) {
        const address = data?.[groupName]?.[childPosition];
        const countryCode = address?.address?.country?.code;
        const stateCode = address?.address?.region?.code;

        if (countryCode) {
          getStateList(countryCode);
        }
        if (stateCode) {
          getCountyList(stateCode);
        }
      }
    }
    return () => reset({});
  }, [reset, data, getStateList, getCountyList, childPosition, isOpenChildDrawer]);

  useEffect(() => {
    if (dirtyFields?.[groupName]?.[childPosition]?.address?.country) {
      setValue(`${groupName}.${childPosition}.address.region`, emptyOptions[0], { shouldValidate: true });
      setValue(`${groupName}.${childPosition}.address.county`, emptyOptions[0]);

      if (watchCountry) {
        getStateList(watchCountry);
      } else {
        dispatch(resetlookupData(['states', 'counties']));
      }
    }
  }, [watchCountry, getStateList, setValue, childPosition, dispatch, dirtyFields]);

  useEffect(() => {
    if (dirtyFields?.[groupName]?.[childPosition]?.address?.region) {
      setValue(`${groupName}.${childPosition}.address.county`, emptyOptions[0]);
      if (watchState) {
        getCountyList(watchState);
      } else {
        dispatch(resetlookupData(['counties']));
      }
    }
  }, [watchState, getCountyList, setValue, childPosition, dispatch, dirtyFields]);

  return (
    <Grid sx={!isOpenChildDrawer ? { mt: '2rem' } : null}>
      {!isOpenChildDrawer ? (
        <Box>
          <Typography
            variant="h2"
            sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
          >
            {t('relationship.title')}
          </Typography>
          {data && data?.relationships && (
            <RelationshipCard
              relationships={data?.relationships}
              smallLayout={12}
              xtraSmallLayout={12}
              openChildDrawer={openChildDrawer}
              isFormValid={isValid}
            />
          )}
          {JSON.stringify(data?.[groupName]) && (
            <InputBase
              inputProps={{ type: 'hidden' }}
              {...register(`${groupName}`)}
              defaultValue={JSON.stringify(data?.[groupName])}
            />
          )}

          <Box sx={{ mt: '1rem', justifyContent: 'center', display: 'flex' }}>
            <StyledAddButton
              onClick={() => openChildDrawer(true, data?.relationships?.length || 0)}
              disabled={!isValid}
            >
              {t('relationship.add')}
            </StyledAddButton>
          </Box>
        </Box>
      ) : (
        <Box>
          <FormControl fullWidth required error={!!errors?.[groupName]?.[childPosition]?.relationship?.code}>
            <InputLabel htmlFor="relationship">{t('relationship.relationshipType')}</InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  id="relationship"
                  {...field}
                  options={relationshipsToStudentOptions}
                  fullWidth
                  inputProps={{
                    'aria-describedby': 'relationship-error',
                    'aria-label': t('relationship.relationshipType'),
                    ...DISABLE_AUTO_FILL,
                  }}
                  onChange={option => {
                    setValue(`${groupName}.${childPosition}.relationship.displayName`, option?.text);
                    return onChange(option?.id ?? '');
                  }}
                />
              )}
              control={control}
              name={`${groupName}.${childPosition}.relationship.code`}
            />
            <FormHelperText role="alert" id="relationship-error">
              {errors?.[groupName]?.[childPosition]?.relationship?.code?.message}
            </FormHelperText>
          </FormControl>
          <InputBase
            inputProps={{ type: 'hidden' }}
            {...register(`${groupName}.${childPosition}.relationship.displayName`)}
          />
          <Grid container spacing={1}>
            <Grid item xs={6} md={6}>
              <FormControl fullWidth required error={!!errors?.[groupName]?.[childPosition]?.givenName}>
                <InputLabel htmlFor="givenName">{t('relationship.givenName')}</InputLabel>
                <InputBase
                  autoComplete="Off"
                  inputProps={{
                    'aria-describedby': 'givenNameRel-error',
                    'aria-label': t('relationship.givenName'),
                    maxLength: FIELD_LENGTH_100,
                    ...DISABLE_AUTO_FILL,
                  }}
                  {...register(`${groupName}.${childPosition}.givenName`)}
                />
                <FormHelperText role="alert" id="givenNameRel-error">
                  {errors?.[groupName]?.[childPosition]?.givenName?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl fullWidth required error={!!errors?.[groupName]?.[childPosition]?.familyName}>
                <InputLabel htmlFor="familyName">{t('relationship.familyName')}</InputLabel>
                <InputBase
                  inputProps={{
                    'aria-describedby': 'familyNameRel-error',
                    'aria-label': t('relationship.familyName'),
                    maxLength: FIELD_LENGTH_100,
                    ...DISABLE_AUTO_FILL,
                  }}
                  {...register(`${groupName}.${childPosition}.familyName`)}
                />
                <FormHelperText role="alert" id="familyNameRel-error">
                  {errors?.[groupName]?.[childPosition]?.familyName?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <FormControl fullWidth>
            <InputLabel htmlFor="legalSexRel">{t('relationship.legalSexRel')}</InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  id="legalSexRel"
                  {...field}
                  options={legalSexOptions}
                  fullWidth
                  inputProps={{
                    ...DISABLE_AUTO_FILL,
                  }}
                  onChange={option => {
                    setValue(`${groupName}.${childPosition}.gender.legalSex.displayName`, option?.text);
                    return onChange(option?.id ?? null);
                  }}
                />
              )}
              control={control}
              name={`${groupName}.${childPosition}.gender.legalSex.code`}
            />
          </FormControl>
          <InputBase
            inputProps={{ type: 'hidden' }}
            {...register(`${groupName}.${childPosition}.gender.legalSex.displayName`)}
          />
          <FormControl fullWidth>
            <InputLabel htmlFor="living">{t('relationship.living')}</InputLabel>
            <Controller
              name={`${groupName}.${childPosition}.living`}
              render={({ field: { ref, ...field } }) => (
                <RadioControl
                  {...field}
                  inputRef={ref}
                  id="living"
                  options={livingOptions}
                  aria-describedby="living-error"
                  inline={true}
                />
              )}
              control={control}
              defaultValue=""
            />
            <FormHelperText role="alert" id="living-error">
              {errors?.[groupName]?.[childPosition]?.living?.message}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="occupation">{t('relationship.occupation')}</InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  id="occupation"
                  {...field}
                  options={occupationOptions}
                  fullWidth
                  inputProps={{
                    'aria-label': t('relationship.occupation'),
                    ...DISABLE_AUTO_FILL,
                  }}
                  onChange={option => {
                    onChange(option?.id ?? null);
                    setValue(`${groupName}.${childPosition}.occupation.displayName`, option?.text);
                  }}
                />
              )}
              control={control}
              name={`${groupName}.${childPosition}.occupation.code`}
            />
          </FormControl>
          <InputBase
            inputProps={{ type: 'hidden' }}
            {...register(`${groupName}.${childPosition}.occupation.displayName`)}
          />
          <InputLabel sx={{ mt: 1 }} htmlFor="">
            {t('relationship.residency')}
          </InputLabel>
          <Grid container spacing={1}>
            <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field: { onChange, ...field } }) => (
                    <Dropdown
                      id="countryOfResidence"
                      {...field}
                      options={countryOptions}
                      onChange={option => {
                        setValue(`${groupName}.${childPosition}.address.country.displayName`, option?.text);
                        return onChange(option?.id ?? null);
                      }}
                      placeholder={t('address.country')}
                      inputProps={{
                        'aria-label': t('address.country'),
                        ...DISABLE_ADDRESS_AUTO_FILL,
                      }}
                    />
                  )}
                  control={control}
                  name={`${groupName}.${childPosition}.address.country.code`}
                />
              </FormControl>
              <InputBase
                inputProps={{ type: 'hidden' }}
                {...register(`${groupName}.${childPosition}.address.country.displayName`)}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field: { onChange, ...field } }) => (
                    <Dropdown
                      id="stateOfResidence"
                      {...field}
                      options={stateOptions}
                      onChange={option => {
                        setValue(`${groupName}.${childPosition}.address.region.displayName`, option?.text);
                        return onChange(option?.id ?? null);
                      }}
                      placeholder={t('address.region')}
                      inputProps={{
                        'aria-label': t('address.region'),
                        ...DISABLE_ADDRESS_AUTO_FILL,
                      }}
                    />
                  )}
                  control={control}
                  name={`${groupName}.${childPosition}.address.region.code`}
                />
              </FormControl>
              <InputBase
                inputProps={{ type: 'hidden' }}
                {...register(`${groupName}.${childPosition}.address.region.displayName`)}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <FormControl fullWidth>
                <Controller
                  render={({ field: { onChange, ...field } }) => (
                    <Dropdown
                      id="countyOfResidence"
                      {...field}
                      options={countyOptions}
                      onChange={option => {
                        setValue(`${groupName}.${childPosition}.address.county.displayName`, option?.text);
                        return onChange(option?.id ?? null);
                      }}
                      placeholder={t('address.county')}
                      inputProps={{
                        'aria-label': t('address.county'),
                        ...DISABLE_ADDRESS_AUTO_FILL,
                      }}
                    />
                  )}
                  control={control}
                  name={`${groupName}.${childPosition}.address.county.code`}
                />
              </FormControl>
              <InputBase
                inputProps={{ type: 'hidden' }}
                {...register(`${groupName}.${childPosition}.address.county.displayName`)}
              />
            </Grid>
          </Grid>
          <FormControl fullWidth>
            <InputLabel htmlFor="educationLevel">{t('relationship.educationLevel')}</InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  id="educationLevel"
                  {...field}
                  options={educationLevelOptions}
                  fullWidth
                  inputProps={{
                    'aria-label': t('relationship.educationLevel'),
                  }}
                  onChange={option => {
                    setValue(`${groupName}.${childPosition}.educationLevel.displayName`, option?.text);
                    return onChange(option?.id ?? null);
                  }}
                />
              )}
              control={control}
              name={`${groupName}.${childPosition}.educationLevel.code`}
            />
          </FormControl>
          <InputBase
            inputProps={{ type: 'hidden' }}
            {...register(`${groupName}.${childPosition}.educationLevel.displayName`)}
          />
          <FormControl fullWidth>
            <InputLabel htmlFor="inPrimaryHousehold">{t('relationship.inPrimaryHousehold')}</InputLabel>
            <Controller
              name={`${groupName}.${childPosition}.inPrimaryHousehold`}
              render={({ field: { ref, ...field } }) => (
                <RadioControl
                  {...field}
                  inputRef={ref}
                  id={t('relationship.inPrimaryHousehold')}
                  options={radioDefaultOptions}
                  aria-describedby="inPrimaryHousehold-error"
                  inline={true}
                />
              )}
              control={control}
              defaultValue=""
            />
            <FormHelperText role="alert" id="inPrimaryHousehold-error">
              {errors?.[groupName]?.[childPosition]?.inPrimaryHousehold?.message}
            </FormHelperText>
          </FormControl>
          {deleteRelationship && (
            <Box sx={{ mt: '1rem', justifyContent: 'center', display: 'flex' }}>
              <StyledDeleteButton onClick={() => setShowAlert(true)}>{t('relationship.remove')}</StyledDeleteButton>
            </Box>
          )}
          <ConfirmationDialog
            open={showAlert}
            text={t('relationship.delete_title')}
            confirmationText={t('relationship.delete_content')}
            onClose={() => {
              setShowAlert(false);
            }}
            footerButtonConfig={{
              primary: {
                title: t('remove_label'),
                props: {
                  onClick: () => {
                    setShowAlert(false);
                    deleteRelationship?.(childPosition || 0);
                  },
                },
              },
              tertiary: {
                title: t('cancel_label'),
              },
            }}
          />
        </Box>
      )}
    </Grid>
  );
};

export default RelationshipInformation;
