/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { SidePanel } from '@liaison/liaison-ui';

import { setTitle } from 'utils/commonUtils';
import { sxSidePanel } from 'pages/Pages.styles';

const RequirementsListItem = ({ text }: { text: string }) => (
  <ListItem sx={{ py: 0.1, alignItems: 'flex-start' }}>
    <ListItemIcon sx={{ color: 'primary.title', fontSize: 15 }}>●</ListItemIcon>
    <ListItemText sx={{ ml: -4, mt: -0.15 }} primary={text} />
  </ListItem>
);

type TLearnMoreProps = {
  isOpen: boolean;
  setIsOpen: (value: SetStateAction<boolean>) => void;
};

const LearnMore = ({ isOpen, setIsOpen }: TLearnMoreProps): ReactElement => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setTitle(t('mediaDocuments.fileUpload.requirements.title'));
    }
  }, [isOpen, t]);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <SidePanel
      size="small"
      open={isOpen}
      onClose={onClose}
      title={t('mediaDocuments.fileUpload.requirements.title')}
      sx={sxSidePanel}
    >
      <Grid container rowSpacing={1} alignItems="center" sx={{ overflowX: 'hidden' }}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h2">
            {t('mediaDocuments.fileUpload.requirements.text1')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom variant="body1">
            {t('mediaDocuments.fileUpload.requirements.text2')}
          </Typography>
          <List tabIndex={0}>
            <RequirementsListItem text={t('mediaDocuments.fileUpload.requirements.images')} />
            <RequirementsListItem text={t('mediaDocuments.fileUpload.requirements.videos')} />
            <RequirementsListItem text={t('mediaDocuments.fileUpload.requirements.audio')} />
            <RequirementsListItem text={t('mediaDocuments.fileUpload.requirements.documents')} />
            <RequirementsListItem text={t('mediaDocuments.fileUpload.requirements.fileSizes')} />
            <RequirementsListItem text={t('mediaDocuments.fileUpload.requirements.linkedMedia')} />
            <ListItem sx={{ ml: 3, py: 0 }}>
              <List>
                <RequirementsListItem text={t('mediaDocuments.fileUpload.requirements.linkedMedia.note')} />
              </List>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </SidePanel>
  );
};

export default memo(LearnMore);
