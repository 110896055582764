/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { TAppThunk } from 'redux/store';
import {
  genEdProcessStart,
  genEdProcessSuccess,
  genEdProcessFailure,
  IGenEdCategories,
  IRequirements,
  genEdMilestonesStart,
  genEdMilestoneSuccess,
  genEdMilestonesFailure,
  IGenEdProcess,
  coursesAndTestsStart,
  coursesAndTestsSuccess,
  coursesAndTestsFailure,
  ICoursesAndTests,
  TGenEdCourse,
  TGenEdTest,
  IFormattedCoursesAndTests,
  IGenEdMilestone,
  gpaCalculationSuccess,
  gpaCalculationStart,
  gpaCalculationFailure,
} from 'transferPlanner/store/genEdProcess/genEdProcess.slice';
import { t } from 'i18next';
import axios from 'axios';
import { TGenEdReq, TReq } from 'store/common/commonInfo.slice';
import { API } from 'transferPlanner/constants/api';
import { getLoggedUser } from 'utils/keyCloakUtils';
import { displayDate, csuNameSpace } from 'utils/utilities';
import type { ICollege, TAcademicHistoryField } from 'store/academicHistory/academicHistory.slice';
import type { TStandardTests } from 'store/standardTests/standardTests.slice';
import type { IFeature } from 'store/features/features.slice';
import { isFeatureActive } from 'utils/features.utils';
import { GENERAL_EDUCATION, TIMELINE_CALCULATOR, TRANSFER_PROGRESS } from 'transferPlanner/constants/routeNames';

const genEdTransferCalculatorMenu = {
  label: t('generalEducation.timelineCalculator', csuNameSpace),
  location: TIMELINE_CALCULATOR.path,
  ariaLabel: t('generalEducation.timelineCalculator', csuNameSpace),
};

export const genEdMenusList = [
  {
    label: t('generalEducation.heading', csuNameSpace),
    location: GENERAL_EDUCATION,
    ariaLabel: t('generalEducation.heading', csuNameSpace),
  },
  {
    label: t('generalEducation.transferProgress', csuNameSpace),
    location: TRANSFER_PROGRESS,
    ariaLabel: t('generalEducation.transferProgress', csuNameSpace),
  },
];

export interface IGenEdMenu {
  label: string;
  location: string;
  ariaLabel: string;
}

export const getGeneralEducationMenus = (features: IFeature[] | null): IGenEdMenu[] => [
  ...genEdMenusList,
  ...(isFeatureActive(features, 'HIDE_TRANSFER_TIMELINE_CALCULATOR') ? [genEdTransferCalculatorMenu] : []),
];

export interface IGenEdAccordianHeaderProps {
  config: TGenEdReq;
  categoryDetail?: IGenEdCategories;
}

export interface IGenEdAccordianBodyProps extends IGenEdAccordianHeaderProps {
  onViewDetailsClicked: (
    pageTitle: string,
    screenName: string,
    showSaveButton: boolean,
    courseDetails?: IRequirements
  ) => void;
}

export interface IAboutProps {
  onViewDetailsClicked: (
    pageTitle: string,
    screenName: string,
    showSaveButton: boolean,
    courseDetails?: IRequirements
  ) => void;
}

export interface ICourseDetailsProps {
  data?: IRequirements;
}

export interface ICourseTestPanelObject {
  label: string;
  value: string;
}

export interface ICourseSidePanel {
  title: string;
  courseOrTestData: ICourseTestPanelObject[];
}

export const faqList = [
  {
    label: 'generalEducation.questionOne',
    pageTitle: 'Gen Ed Subject Requirements',
    screenName: 'SubjectRequirement',
  },
  {
    label: 'generalEducation.questionTwo',
    pageTitle: 'The Golden Four',
    screenName: 'GoldenFour',
  },
];

export const generateCourseOrTestPanelDetails = (data?: IRequirements): ICourseSidePanel => {
  let title = '';
  const hasCourseData = Boolean(data?.course);
  let courseOrTestData: ICourseTestPanelObject[] = [];

  if (hasCourseData) {
    title = `${data?.course?.code} - ${data?.course?.title}`;
    courseOrTestData = [
      { label: t('generalEducation.collegeName'), value: data?.course?.collegeName || '' },
      { label: t('generalEducation.subject'), value: data?.course?.subject?.displayName || '' },
      {
        label: t('generalEducation.semester'),
        value: `${data?.course?.termType?.displayName} ${data?.course?.termAcademicYear}`,
      },
      {
        label: t('generalEducation.status'),
        value: data?.course?.termCompletionStatus?.code || '',
      },
      { label: t('generalEducation.units'), value: (data?.course?.credits as string) || '' },
    ];
    if (data?.course?.termCompletionStatus?.code === 'COMPLETED') {
      courseOrTestData.push({ label: t('generalEducation.grade'), value: data?.course?.grade || '' });
    }
  } else {
    const typeCode = data?.test?.type.code;
    const displayName = data?.test?.classification.displayName;

    title = typeCode && typeCode.length > 0 ? `${typeCode} - ${displayName}` : `${displayName}`;
    courseOrTestData = [
      {
        label: t('generalEducation.dateTaken'),
        value: displayDate(`${data?.test?.testDate}`),
      },
      { label: t('generalEducation.score'), value: `${data?.test?.scores?.total}` },
      { label: t('generalEducation.credits'), value: `${data?.unitsEarned}` },
    ];
  }

  return { title, courseOrTestData };
};

export const getGenEdProcess = (): TAppThunk => async dispatch => {
  try {
    dispatch(genEdProcessStart());
    const loggedUserId = getLoggedUser();
    const { data } = await axios.get(`${API.geRequirementFulfillment}/${loggedUserId}`);
    dispatch(genEdProcessSuccess(data));
  } catch (err) {
    dispatch(genEdProcessFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  }
};

export const pickGoldenFourCourses = (genEdRequirements: TGenEdReq[] | null, goldenFourCodes: string[]): TReq[] => {
  let courses: TReq[] = [];
  genEdRequirements?.forEach(element => {
    let temp: TReq[] = [];
    if (element.requirements) {
      temp = element.requirements.filter(course => goldenFourCodes.includes(course.code));
    }
    courses = [...courses, ...temp];
  });
  return courses;
};

export const getGenEdMilestoneProcess = (): TAppThunk => async dispatch => {
  try {
    dispatch(genEdMilestonesStart());
    const loggedUserId = getLoggedUser();
    const { data } = await axios.get(`${API.geRequirementFulfillment}/${loggedUserId}/milestones`);
    dispatch(genEdMilestoneSuccess(data));
  } catch (err) {
    dispatch(genEdMilestonesFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  }
};

export const postGenEdMilestoneModalStatus =
  (data: IGenEdMilestone[]): TAppThunk =>
  async dispatch => {
    try {
      const loggedUserId = getLoggedUser();
      const { data: response } = await axios.post(`${API.geRequirementFulfillment}/${loggedUserId}/milestones`, data);
      dispatch(genEdMilestoneSuccess(response));
    } catch (err) {
      dispatch(genEdMilestonesFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const bulletColors = ['#AE2573', '#2E7D32', '#0277BD', '#E65100', '#00838F', '#D32F2F'];
export const alphabets = ['A', 'B', 'C', 'D', 'E', 'F'];

export const generateDefaultAssignments = (
  genEdRequirements: TGenEdReq[],
  genEdGetData: IGenEdCategories[]
): IGenEdCategories[] => {
  return genEdRequirements?.map(({ code, category, requirements, courseUnits: unitsRequired }, i) => {
    let additionalFields = {};
    if (genEdGetData?.[i]) {
      const currentAssignment = genEdGetData?.[i];
      additionalFields = {
        unitsEarned: currentAssignment?.unitsEarned,
        unitsCompleted: currentAssignment?.unitsCompleted,
        completionPercentage: currentAssignment?.completionPercentage,
        requirements: currentAssignment?.requirements,
      };
    }

    return {
      code,
      category,
      unitsRequired,
      requirements,
      ...additionalFields,
    };
  });
};

export const postGenEdProcess =
  (payload: IGenEdProcess, successCallback?: () => void, handleApiFailure?: (msg: string) => void): TAppThunk =>
  async dispatch => {
    try {
      const loggedUserId = getLoggedUser();
      dispatch(genEdProcessStart());
      const modifiedPayload = {
        profileId: loggedUserId,
        ...payload,
      };
      const { data } = await axios.post(`${API?.geRequirementFulfillment}/${loggedUserId}`, modifiedPayload);
      dispatch(genEdProcessSuccess(data));
      dispatch(getGenEdMilestoneProcess());
      successCallback?.();
    } catch (err) {
      handleApiFailure?.(err?.response?.data?.message);
      dispatch(genEdProcessFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const formatCourseAndTestResponse = (courseAndTestData: ICoursesAndTests): IFormattedCoursesAndTests[] => {
  const formattedCourse =
    courseAndTestData?.courses?.map((course: TGenEdCourse) => {
      return {
        id: course.id,
        text: course?.code ? `${course?.code} - ${course.title || ''}` : course.title || '',
        course,
      };
    }) || [];

  const formattedTest =
    courseAndTestData?.tests?.map((test: TGenEdTest) => {
      return {
        id: test.id,
        text: `${test.type.code} - ${test.classification.displayName}`,
        test,
      };
    }) || [];

  return [...formattedCourse, ...formattedTest].sort((a, b) =>
    a?.text.toLowerCase() > b?.text.toLowerCase() ? 1 : -1
  );
};

export const getCoursesAndTests =
  (academicHistoryPayload: TAcademicHistoryField | null, standardTests: TStandardTests | null | ''): TAppThunk =>
  async dispatch => {
    try {
      if (academicHistoryPayload?.colleges || (standardTests && standardTests?.tests)) {
        const modifiedPayload = {
          academicHistory: {
            colleges: academicHistoryPayload?.colleges,
          },
          standardizedTests: { tests: standardTests ? standardTests?.tests : null },
        };

        dispatch(coursesAndTestsStart());
        const loggedUserId = getLoggedUser();
        const { data } = await axios.post(
          `${API?.geRequirementFulfillment}/${loggedUserId}/testsAndCourses`,
          modifiedPayload
        );
        dispatch(coursesAndTestsSuccess(formatCourseAndTestResponse(data)));
      }
    } catch (err) {
      dispatch(coursesAndTestsFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const genEdStatusConfig = {
  notComplete: {
    border: '#CC0B2A',
    bgColor: '#FFFFFF',
    icon: 'fullCircle',
    status: 'notComplete_label',
  },
  inprogress: {
    border: '#E3F2FD',
    bgColor: '#E3F2FD',
    icon: 'halfCircle',
    status: 'inprogress_label',
  },
  completed: {
    border: '#CDDC39',
    bgColor: '#CDDC39',
    icon: 'check',
    status: 'completed_label',
  },
  goldenFour: {
    border: '#FFC107',
    bgColor: '#FFC107',
    icon: 'trophy',
    status: 'generalEducation.goldenFour.label',
  },
  optOut: {
    border: '#FFFFFF',
    bgColor: '#FFFFFF',
    icon: '',
    status: 'opt_out_label',
  },
};

export const getChipStatus = (requirements: IRequirements | undefined): string => {
  if (requirements) {
    if (requirements?.optOut) {
      return 'optOut';
    }
    if (
      requirements?.course?.termCompletionStatus?.code === 'IN_PROGRESS' ||
      requirements?.course?.termCompletionStatus?.code === 'PLANNED' ||
      requirements?.test?.status?.code === 'PLANNED'
    )
      return 'inprogress';

    if (
      requirements?.course?.termCompletionStatus?.code === 'COMPLETED' ||
      requirements?.test?.status?.code === 'TAKEN'
    )
      return 'completed';
  }
  return 'notComplete';
};

export type TAreaBdetails = {
  areaBStatus: boolean | undefined;
  bRequirements: { [key: string]: string } | undefined;
};

export const getAreaBDetails = (categoryDetail: IGenEdCategories | undefined): TAreaBdetails => {
  const areaBrequirement = categoryDetail?.requirements;
  const bRequirements = areaBrequirement?.reduce((acc, elem) => {
    acc[elem.code] = getChipStatus(elem);
    return acc;
  }, {} as { [index: string]: string });
  const bRequirementValues = bRequirements && Object.values(bRequirements);
  const areaBStatus = bRequirementValues?.includes('optOut') || bRequirementValues?.includes('notComplete');
  return {
    areaBStatus,
    bRequirements,
  };
};

export const getUnitsRequired = (genEdProcessData: IGenEdProcess | null, genEdReqData: TGenEdReq[] | null): number => {
  if (genEdProcessData?.totalUnitsRequired) {
    return genEdProcessData?.totalUnitsRequired;
  }
  return (
    genEdReqData?.reduce((accumulator, arrayItem) => {
      return accumulator + arrayItem.courseUnits;
    }, 0) || 0
  );
};

const convertGraduateStringToBoolean = (
  academicHistoryData: TAcademicHistoryField | null
): TAcademicHistoryField | null => {
  return {
    ...academicHistoryData,
    colleges: academicHistoryData?.colleges
      ?.map((college: ICollege) => {
        return {
          ...college,
          graduated: (college?.graduated as unknown as string) === 'Yes',
        };
      })
      ?.filter((college: ICollege) => college?.terms?.length > 0),
    // all colleges don't have terms, So if don't pass colleges with terms then post api will throw an error.
    // So leave above filter until this issue got resolved from backend.
  };
};

export const getGpaCalculation = (): TAppThunk => async (dispatch, getState) => {
  try {
    const loggedUserId = getLoggedUser();
    dispatch(gpaCalculationStart());
    // calling post api only and removing get api by commenting the code below as per requirement, will enable it whenever require.
    // const { data } = await axios.get(`${API.gpaCalculationEndPoint}/${loggedUserId}`);
    // const isTrasferGpaHasData = data && Object.keys(data?.transferGPA)?.length;
    // if (!data || (isTrasferGpaHasData && data?.transferGPA?.status?.code === 'OUTDATED') || !isTrasferGpaHasData) {
    //   try {
    const { data: response } = await axios.post(`${API.gpaCalculationEndPoint}/${loggedUserId}/calculate`, {
      profileId: loggedUserId,
      academicHistory: convertGraduateStringToBoolean(getState()?.academicHistory?.academicHistoryData),
      standardizedTests: getState()?.standardTests?.standardTests || null,
    });
    dispatch(gpaCalculationSuccess(response));
  } catch (err) {
    dispatch(gpaCalculationFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  }
  // }
  // else {
  //   dispatch(gpaCalculationSuccess(data));
  // }
  // }
  // catch (err) {
  //   dispatch(gpaCalculationFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  // }
};
