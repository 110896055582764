/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo } from 'react';
import { IconButton, AppBar, Toolbar, useMediaQuery, Theme, Box, Grid, Stack } from '@mui/material';
import { Icon } from '@liaison/liaison-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setUi } from 'store/ui/ui.slice';
import { selectTenantConfig } from 'store/common/commonInfo.selectors';
import { nameSpace } from 'transferPlanner/constants/general';
import { useFixedItemInView } from 'hooks/useFixedItemInView';
import MyProgramsButton from '../MyProgramsButton/MyProgramsButton';

const Header = (): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const dispatch = useDispatch();
  const tenantConfig = useSelector(selectTenantConfig);

  const { logoMobile, logoNoBG } = tenantConfig || {};
  const { isFixed } = useFixedItemInView();

  const handleMenuClick = () => {
    dispatch(setUi({ name: 'sidebar', state: { open: true } }));
  };

  return (
    <>
      {isMobile ? (
        <AppBar position={isFixed ? 'fixed' : 'static'}>
          <Toolbar sx={{ bgcolor: theme => theme.palette.common.white }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <IconButton
                  size="large"
                  edge="start"
                  sx={{ color: theme => theme.palette.common.black }}
                  aria-label="open drawer"
                  onClick={handleMenuClick}
                >
                  <Icon>IconMenu</Icon>
                </IconButton>
                <img
                  src={logoMobile}
                  alt="logo"
                  height="36"
                  aria-hidden="true"
                  style={{ width: '100%' }}
                  aria-label={t('logo_label')}
                />
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end',
                  alignItems: 'end',
                  m: '0.5rem',
                }}
              >
                <MyProgramsButton />
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      ) : (
        <AppBar
          position="static"
          sx={{
            bgcolor: 'inherit',
            boxShadow: 'none',
          }}
        >
          <Toolbar
            variant="dense"
            sx={{
              height: '60px',
              ml: '3.5rem',
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Box sx={{ width: '10vw' }} component="a" tabIndex={0}>
                  <img src={logoNoBG} alt="logo" height="30" aria-label={t('logo_label')} />
                </Box>
              </Grid>
              <Grid item>
                <MyProgramsButton />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default memo(Header);
