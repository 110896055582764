/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { API } from 'constants/api';
import { t } from 'i18next';
import type { TAppThunk } from 'redux/store';
import {
  academicGoalsInfoStart,
  academicGoalsInfoSuccess,
  academicGoalsInfoFailure,
  type TAcademicGoalsInfoField,
  type ISchoolOfInterest,
  type ILocationInterest,
} from 'userProfile/store/academicInfo/academicInfo.slice';
import { setUi } from 'store/ui/ui.slice';
import axios from 'axios';
import type { ICollegeCommon, IhighSchool, Ioption } from 'store/common/commonInfo.slice';
import { getLoggedUser } from 'utils/keyCloakUtils';
import { isEmpty } from 'utils/utilities';

export const lookupsEndPoint = 'fields';
export const lookupsEndPointAreasOfStudy = 'areas';

const emptyOptions = [{ code: '', displayName: '' }] as Ioption[];

export const handleEmptyLookup = (data: TAcademicGoalsInfoField | null): Ioption[] => {
  if (!data) return emptyOptions;
  return [data?.fields || emptyOptions] as unknown as Ioption[];
};

export const compareFn = (
  school1: IhighSchool | ICollegeCommon | ISchoolOfInterest,
  school2: IhighSchool | ICollegeCommon | ISchoolOfInterest
): number => (school1.name as string).localeCompare(school2.name as string);

export const getSchoolOfInterestOptions = (options: ISchoolOfInterest[]): ISchoolOfInterest[] => {
  return options?.map(option => ({
    ...option,
    id: option?.ipedsId,
    text: option?.name,
  }));
};

export const getAcademicGoals = (): TAppThunk => async dispatch => {
  try {
    dispatch(academicGoalsInfoStart());
    const { data } = await axios.get(`${API?.profileInformation}${getLoggedUser()}/academicGoals`);
    dispatch(academicGoalsInfoSuccess(data));
  } catch (err) {
    /* istanbul ignore next */
    dispatch(academicGoalsInfoFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  }
};
/* istanbul ignore next */
export const getLocationInterestsFormData = (
  locationInterests: ILocationInterest[],
  getStateList: (countryId: string) => void
): TAcademicGoalsInfoField => {
  return locationInterests.reduce((acc, cur) => {
    const { country, region } = cur;
    const countryId = country?.code;

    if (!acc.locationInterests) {
      acc.locationInterests = [];
    }

    if (country && !acc.locationInterests.find(c => c.code === countryId)) {
      acc.locationInterests.push(country);
    }

    if (countryId) {
      const key = `locationInterests-${countryId}`;
      if (!(key in acc)) {
        getStateList(countryId);
        acc[key] = [];
      }
      if (region) acc[key].push(region);
    }
    return acc;
  }, {} as { [key: string]: Ioption[] });
};

export const modifyBooleanFields = (response: TAcademicGoalsInfoField): TAcademicGoalsInfoField => {
  const studiedAbroad = response?.studiedAbroad;
  const visible = response?.visible;
  if (!isEmpty(studiedAbroad)) {
    if (response.studiedAbroad) {
      response.studiedAbroad = 'Yes';
    } else {
      response.studiedAbroad = 'No';
    }
  }

  if (!isEmpty(visible)) {
    if (response.visible) {
      response.visible = 'Yes';
    } else {
      response.visible = 'No';
    }
  }

  return { ...response };
};

export const getPayload = (
  data: TAcademicGoalsInfoField,
  includeLocationInterests: boolean
): TAcademicGoalsInfoField => {
  const locationInterests: ILocationInterest[] = [];
  const countries = data?.locationInterests as unknown as Ioption[];
  countries?.forEach((country: Ioption) => {
    const countryId = country?.code;
    const key = `locationInterests-${countryId}`;

    if (key in data) {
      locationInterests.push({
        country,
        region: undefined,
      });

      if (includeLocationInterests && data[key]?.length > 0) {
        data[key].forEach((region: Ioption) => {
          locationInterests.push({
            country,
            region,
          });
        });
        // eslint-disable-next-line no-param-reassign
        delete data[key];
      }
    }
  });

  if (includeLocationInterests) {
    Object.keys(data).forEach(key => {
      if (key.startsWith('locationInterests-')) {
        // eslint-disable-next-line no-param-reassign
        delete data[key];
      }
    });
  }

  const payload = {
    ...data,
  };

  if (includeLocationInterests && locationInterests.length > 0) {
    payload.locationInterests = locationInterests;
  }

  return payload;
};

export const postAcademicGoals =
  (payload: TAcademicGoalsInfoField, successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      const loggedUserId = getLoggedUser();
      dispatch(academicGoalsInfoStart());
      const modifiedPayload = {
        ...payload,
        profileId: loggedUserId,
      };
      const { data } = await axios.post(`${API?.profileInformation}${loggedUserId}/academicGoals`, modifiedPayload);
      dispatch(academicGoalsInfoSuccess(data));
      dispatch(
        setUi({ name: 'succesSnackbar', state: { open: true, message: t('success_message'), hideTitle: true } })
      );
      successCallback?.();
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      dispatch(academicGoalsInfoFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };
