/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { API } from 'constants/api';
import { t } from 'i18next';
import { TAppThunk } from 'redux/store';
import {
  careerGoalsInfoStart,
  careerGoalsInfoSuccess,
  careerGoalsInfoFailure,
  TCareerGoalsInfoField,
} from 'userProfile/store/careerInfo/careerInfo.slice';
import axios from 'axios';
import { Ioption } from 'store/common/commonInfo.slice';
import { setUi } from 'store/ui/ui.slice';
import { getLoggedUser } from 'utils/keyCloakUtils';

export const lookupsEndPoint = 'industries';

const emptyOptions = [{ code: '', displayName: '' }] as Ioption[];

export const handleEmptyLookup = (data: TCareerGoalsInfoField | null): Ioption[] => {
  if (!data) return emptyOptions;
  return [data?.industries || emptyOptions] as unknown as Ioption[];
};

export const getCareerGoals = (): TAppThunk => async dispatch => {
  try {
    dispatch(careerGoalsInfoStart());
    const { data } = await axios.get(`${API?.profileInformation}${getLoggedUser()}/careerGoals`);
    dispatch(careerGoalsInfoSuccess(data));
  } catch (err) {
    dispatch(careerGoalsInfoFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  }
};

export const postCareerGoals =
  (payload: TCareerGoalsInfoField, successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      const loggedUserId = getLoggedUser();
      dispatch(careerGoalsInfoStart());
      const modifiedPayload = {
        ...payload,
        profileId: loggedUserId,
      };
      const { data } = await axios.post(`${API?.profileInformation}${loggedUserId}/careerGoals`, modifiedPayload);
      dispatch(careerGoalsInfoSuccess(data));
      dispatch(
        setUi({ name: 'succesSnackbar', state: { open: true, message: t('success_message'), hideTitle: true } })
      );
      successCallback?.();
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      dispatch(careerGoalsInfoFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };
