/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect } from 'react';
import { Grid, useMediaQuery, Theme } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Footer } from '@liaison/liaison-ui';
import { getPersonalInformation } from 'userProfile/pages/PersonalInformation/Personalnformation.utils';
import { selectPersonalInformation } from 'userProfile/store/personalInfo/personalInfo.selectors';
import { selectTspEnrollmentPeriod, selectTspEnrollment } from 'transferPlanner/store/enrollment/enrollment.selectors';
import { selectTspEligibility } from 'transferPlanner/store/eligibility/eligibility.selectors';
import { getEnrollment, getTspEnrollmentPeriod } from 'transferPlanner/pages/TspEnrollment/TspEnrollment.utils';
import { getEligibility } from 'transferPlanner/pages/Questionnaire/Questionnaire.utils';
import {
  isTspEnrolled,
  isTspEnrollmentPending,
  isRevokedAndNotEligibleToEnroll,
} from 'transferPlanner/pages/Dashboard/MyStatus/MyStatus.utils';
import { DASHBOARD, TSP_ENROLLMENT_SUCCESS, TSP_QUESTIONNAIRE } from 'transferPlanner/constants/routeNames';
import { getMenuItems } from 'transferPlanner/constants/menuItems';
import { selectFeatures } from 'store/features/features.selectors';
import { selectTenantConfig } from 'store/common/commonInfo.selectors';
import { skipEnrollmentPages } from 'transferPlanner/constants/general';
import { footerColor } from 'transferPlanner/constants/styles';
import { isFeatureActive } from 'utils/features.utils';
import { getQuestionnaireConfig } from 'transferPlanner/pages/Questionnaire/DynamicQuestionnaire.utils';
import { Header } from '../Header';
import { UserNav } from '../UserNav';
import { MobileFooter } from '../MobileFooter';
import { StyledPageGrid } from './PageLayout.styles';

const PageLayout = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const features = useSelector(selectFeatures);
  const { footerLinks = [], socialMediaLinks = [], copyright = '' } = useSelector(selectTenantConfig) || {};
  const personalInformation = useSelector(selectPersonalInformation);
  const tspEnrollmentPeriod = useSelector(selectTspEnrollmentPeriod);
  const isEnrollmentOpen = useSelector(selectTspEnrollmentPeriod)?.enrollmentOpen;
  const tspEnrollment = useSelector(selectTspEnrollment);
  const tspEligibility = useSelector(selectTspEligibility);

  const menuItems = getMenuItems(features);

  useEffect(() => {
    if (!tspEnrollmentPeriod) {
      dispatch(getTspEnrollmentPeriod());
    }
  }, [dispatch, tspEnrollmentPeriod]);

  useEffect(() => {
    if (!tspEnrollment) dispatch(getEnrollment());
  }, [dispatch, tspEnrollment]);

  useEffect(() => {
    if (!personalInformation) {
      dispatch(getPersonalInformation());
    }
  }, [dispatch, personalInformation]);

  useEffect(() => {
    if (!tspEligibility) dispatch(getEligibility());
  }, [dispatch, tspEligibility]);

  useEffect(() => {
    if (typeof isEnrollmentOpen === 'boolean' && isEnrollmentOpen === false && skipEnrollmentPages.includes(pathname)) {
      navigate(`/${DASHBOARD}`);
    }
  }, [isEnrollmentOpen, navigate, pathname]);

  /* istanbul ignore next */
  useEffect(() => {
    if (tspEnrollment) {
      if (
        pathname === `/${TSP_QUESTIONNAIRE}` &&
        (isTspEnrolled(tspEnrollment) ||
          isRevokedAndNotEligibleToEnroll(tspEnrollment) ||
          isTspEnrollmentPending(tspEnrollment))
      ) {
        navigate(`/${DASHBOARD}`);
      }

      if (
        pathname === `/${TSP_ENROLLMENT_SUCCESS}` &&
        (!isTspEnrolled(tspEnrollment) || isTspEnrollmentPending(tspEnrollment))
      ) {
        navigate(`/${DASHBOARD}`);
      }
    }
  }, [tspEnrollment, navigate, pathname]);

  useEffect(() => {
    if (
      isFeatureActive(features, 'HIDE_JULY_TP_ANSWERS') &&
      isFeatureActive(features, 'DISABLE_DYNAMIC_QUESTIONNAIRE')
    ) {
      dispatch(getQuestionnaireConfig());
    }
  }, [dispatch, features]);

  return (
    <>
      {(pathname === `/${DASHBOARD}` || (tspEnrollment && tspEligibility)) && (
        <Grid container direction="column">
          <Header />
          <Grid sx={{ height: 'calc( 100vh - 48px )' }} container component="main">
            <Grid
              container
              component="section"
              sx={{
                bgcolor: isMobile ? theme => theme.palette.background.paper : 'inherit',
                padding: ['0 0 0 0', '0 0 0 80px', '0 0 0 80px'],
                flexDirection: 'row',
                flexWrap: 'nowrap',
              }}
            >
              {!isMobile && (
                <>
                  <Grid
                    item
                    sm={3.5}
                    md={3}
                    lg={2.26}
                    container
                    direction="column"
                    alignItems="center"
                    sx={{ mt: '0.5rem' }}
                  >
                    <UserNav />
                  </Grid>
                  <Grid item lg={0.02} />
                </>
              )}
              <StyledPageGrid item xs={12} sm={8.5} md={9} lg={9.72}>
                <Outlet />
                <Footer
                  copyrightDangerouslySetInnerHTML={{
                    __html: copyright,
                  }}
                  footerLinks={footerLinks}
                  socialLinks={socialMediaLinks}
                  svgIconProps={{ sx: { color: (theme: Theme) => theme.palette.text.primary } }}
                  sxTextProps={{ fontSize: '0.75rem', color: `${footerColor} !important` }}
                />
              </StyledPageGrid>
            </Grid>
          </Grid>
          {isMobile && <MobileFooter footerSectionMenus={menuItems} />}
        </Grid>
      )}
    </>
  );
};

export default PageLayout;
