import React, { ReactElement, Suspense } from 'react';
import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from 'components/ThemeProvider';
import { isLiaisonTenant } from 'utils/utilities';
import { LivingProfilePageLayout } from 'userProfile/components/LivingProfilePageLayout';
import { LivingProfile } from 'userProfile/pages/LivingProfile';
/* istanbul ignore next */
const LpStudentActivation = React.lazy(() => import('../KeyCloakRedirections/LpStudentActivation'));

const routes = {
  path: '/',
  children: [
    { path: 'lp-student-activation', element: <LpStudentActivation /> },
    ...(isLiaisonTenant()
      ? [
          {
            element: <LivingProfilePageLayout />,
            children: [{ path: 'shared-profile-view/:id', element: <LivingProfile /> }],
          },
        ]
      : []),
  ],
};

const WithoutKeycloakProvider = (): ReactElement => {
  const routing = useRoutes([routes]);

  return (
    <ThemeProvider>
      <CssBaseline />
      <Suspense>{routing}</Suspense>
    </ThemeProvider>
  );
};

export default WithoutKeycloakProvider;
