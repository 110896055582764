/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { memo, ReactElement, useState, useEffect, SetStateAction, Dispatch } from 'react';
import { Box, Card, CardContent, Divider, Grid, IconButton, Typography, Avatar, Stack, Radio } from '@mui/material';
import { Icon } from '@liaison/liaison-ui';
import { formatDateToMonthAndYear } from 'utils/commonUtils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { nameSpace } from 'transferPlanner/constants/general';
import type { ICollege, TAcademicHistoryField } from 'store/academicHistory/academicHistory.slice';
import { selectAcademicHistory } from 'store/academicHistory/academicHistory.selectors';
import { sxCard } from 'pages/Pages.styles';
import CommunityCollegeForm from './CommunityCollegeForm';
import { useFormData } from '../ContextForm';

interface ICommunityCollegeProps {
  college: ICollege;
  ahData: TAcademicHistoryField | null;
  setIsEditCollege: Dispatch<SetStateAction<boolean>>;
  isEditCollege: boolean;
  currentIndex: number;
  disableEdit: boolean;
}

const CommunityCollegeCard = ({
  college,
  ahData,
  currentIndex,
  setIsEditCollege,
  isEditCollege,
  disableEdit,
}: ICommunityCollegeProps): ReactElement => {
  const { setFormValues, data } = useFormData();
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const { t } = useTranslation(nameSpace);
  const [isEdit, setIsEdit] = useState(false);
  const academicHistoryData = useSelector(selectAcademicHistory);
  const formData = data?.formData;

  useEffect(() => {
    if (academicHistoryData) setIsEdit(false);
  }, [academicHistoryData]);

  useEffect(() => {
    if (typeof data?.selectedId === 'number' && isInitialLoading) {
      setIsInitialLoading(false);
      setFormValues({ ...data, enabled: true });
    }

    /* istanbul ignore next */
    if (data?.closeForm) {
      setIsEdit(false);
      setIsEditCollege(false);
    }
  }, [data, setFormValues, isInitialLoading, setIsEditCollege]);

  return (
    <>
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={1.5} sm={1} sx={{ display: 'flex', alignItems: 'center' }}>
          <Radio
            disabled={disableEdit || isEdit || isEditCollege}
            checked={data?.selectedId === currentIndex}
            color="secondary"
            onChange={() => {
              const communityCollege = {
                alternateId: college?.alternateId,
                name: college?.name,
              };
              const updatedFormData = { ...formData, communityCollege };
              setFormValues({
                ...data,
                formData: updatedFormData,
                enabled: true,
                selectedId: currentIndex,
                ccData: college,
              });
            }}
            value={currentIndex}
            name="radio-buttons"
            inputProps={{ 'aria-label': college?.name as string }}
          />
        </Grid>
        <Grid item xs={10.5} sm={11}>
          <Grid container>
            <Grid item sm={12}>
              <Card variant="outlined" sx={sxCard}>
                <CardContent
                  sx={{
                    p: 2,
                    '&:last-child': {
                      pb: 2,
                    },
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={10} sm={10} md={11} lg={11} sx={{ mr: '0.5rem', display: 'flex' }}>
                      <Box>
                        <Box>
                          <Typography variant="subtitle3" sx={{ overflowWrap: 'anywhere' }}>
                            {college?.name as string}
                          </Typography>
                        </Box>
                        <Typography variant="subtitle8">
                          {college?.address?.region?.displayName}, {college?.address?.country?.displayName}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={1.2} sm={1.2} md={0.7} sx={{ margin: 'auto', height: '60%' }}>
                      <Stack flexDirection="column" alignItems="flex-end" justifyContent="flex-end">
                        <IconButton
                          disabled={disableEdit || isEdit || isEditCollege}
                          edge="start"
                          onClick={() => {
                            setIsEditCollege(true);
                            setIsEdit(true);
                            setFormValues({ ...data, enabled: false, closeForm: false, selectedId: null });
                          }}
                          size="small"
                          aria-label="Edit College"
                        >
                          <Avatar
                            sx={{
                              width: 30,
                              height: 30,
                              bgcolor: '#E1F5FE',
                              '&:disabled': {
                                bgcolor: 'red',
                              },
                            }}
                          >
                            <Icon sx={{ color: '#0076A8' }}>IconEditSolid</Icon>
                          </Avatar>
                        </IconButton>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Divider sx={{ borderStyle: 'solid', mt: 1, mb: 1 }} />
                  <Stack direction="row" alignItems="center">
                    <Typography variant="caption" component="div">
                      {t('college.startDate')}: {formatDateToMonthAndYear(college?.startDate) || 'N/A'}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isEdit && <CommunityCollegeForm ahData={ahData} childPosition={college?.uniqueId} />}
    </>
  );
};

export default memo(CommunityCollegeCard);
