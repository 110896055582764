/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useMemo, useEffect, useCallback } from 'react';
import { FormControl, Grid, Typography, InputLabel, InputBase } from '@mui/material';
import { Dropdown } from '@liaison/liaison-ui';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import { TBiographicalInfoField } from 'userProfile/store/biographicalInfo/biographicalInfo.slice';
import { getDropDownOptions, emptyOptions } from 'utils/utilities';
import { getLookUps } from 'utils/commonUtils';
import { DEFAULT_COUNTRY } from 'constants/general';
import { DISABLE_ADDRESS_AUTO_FILL, DISABLE_AUTO_FILL } from 'constants/field';
import {
  selectCountriesLookup,
  selectResidencyStatesLookup,
  selectResidencyCountiesLookup,
} from 'store/common/commonInfo.selectors';
import { resetlookupData } from 'store/common/commonInfo.slice';
import MasterData from 'userProfile/constants/master';
import { useTranslation } from 'react-i18next';

interface IBiographicalInfoProps {
  data: TBiographicalInfoField | null;
}
const ResidencyInformation = ({ data }: IBiographicalInfoProps): ReactElement => {
  const { control, reset, register, watch, setValue, getFieldState } = useFormContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const groupName = 'residency';
  const countriesLookup = useSelector(selectCountriesLookup);
  const statesLookup = useSelector(selectResidencyStatesLookup);
  const countiesLookup = useSelector(selectResidencyCountiesLookup);

  const countryOptions = useMemo(() => getDropDownOptions(countriesLookup || []), [countriesLookup]);
  const stateOptions = useMemo(() => getDropDownOptions(statesLookup || []), [statesLookup]);
  const countyOptions = useMemo(() => getDropDownOptions(countiesLookup || []), [countiesLookup]);
  const residenceYearOptions = useMemo(() => getDropDownOptions(MasterData.residenceYearOptions), []);
  const legalCountryOfResidenceValue = watch(`${groupName}.address.country.code`);
  const watchState = watch(`${groupName}.address.region.code`);
  const getStateList = useCallback(
    (countryId: string | undefined) => {
      dispatch(getLookUps('states', `?countryCode=${countryId}`, 'residency'));
    },
    [dispatch]
  );

  const getCountyList = useCallback(
    (stateId: string | undefined) => {
      dispatch(getLookUps('counties', `?stateCode=${stateId}`, 'residency'));
    },
    [dispatch]
  );
  /* istanbul ignore next */
  useEffect(() => {
    if (data) {
      reset(data);
      if (data?.[groupName]) {
        const residencyInfo = data?.[groupName];
        const countryCode = residencyInfo?.address?.country?.code;
        const stateCode = residencyInfo?.address?.region?.code;

        if (countryCode) {
          getStateList(countryCode);
        }
        if (stateCode) {
          getCountyList(stateCode);
        }
      }
    }
  }, [reset, data, getStateList, getCountyList]);
  /* istanbul ignore next */
  useEffect(() => {
    if (
      getFieldState(`${groupName}.address.country`).isDirty ||
      getFieldState(`${groupName}.address.country`).isTouched
    ) {
      setValue(`${groupName}.address.region`, emptyOptions[0], { shouldValidate: true });
      setValue(`${groupName}.address.county`, emptyOptions[0]);

      if (legalCountryOfResidenceValue) {
        getStateList(legalCountryOfResidenceValue);
      }
      dispatch(resetlookupData(['residency_states', 'residency_counties']));
    }
  }, [legalCountryOfResidenceValue, getStateList, setValue, dispatch, getFieldState]);
  /* istanbul ignore next */
  useEffect(() => {
    if (
      getFieldState(`${groupName}.address.region`).isDirty ||
      getFieldState(`${groupName}.address.region`).isTouched
    ) {
      setValue(`${groupName}.address.county`, emptyOptions[0]);
      if (watchState) {
        getCountyList(watchState);
      } else {
        dispatch(resetlookupData(['residency_counties']));
      }
    }
  }, [watchState, getCountyList, setValue, dispatch, getFieldState]);

  return (
    <Grid sx={{ mt: '2rem' }}>
      <Typography
        variant="h2"
        sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
      >
        {t('residencyInfo.title')}
      </Typography>
      <FormControl fullWidth>
        <InputLabel htmlFor="legalCountryOfResidence">{t('residencyInfo.country')}</InputLabel>
        <Controller
          render={({ field: { onChange, ...field } }) => (
            <Dropdown
              id="legalCountryOfResidence"
              {...field}
              options={countryOptions}
              fullWidth
              inputProps={{
                'aria-label': t('residencyInfo.country'),
                ...DISABLE_AUTO_FILL,
              }}
              onChange={option => {
                setValue(`${groupName}.address.country.displayName`, option?.text, { shouldTouch: true });
                return onChange(option?.id ?? null);
              }}
            />
          )}
          control={control}
          name={`${groupName}.address.country.code`}
        />
      </FormControl>
      <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.address.country.displayName`)} />
      {legalCountryOfResidenceValue === DEFAULT_COUNTRY && (
        <>
          <FormControl fullWidth>
            <InputLabel htmlFor="legalStateOfResidence">{t('residencyInfo.region')}</InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  id="legalStateOfResidence"
                  {...field}
                  options={stateOptions}
                  fullWidth
                  inputProps={{
                    'aria-label': t('residencyInfo.region'),
                    ...DISABLE_ADDRESS_AUTO_FILL,
                  }}
                  onChange={option => {
                    setValue(`${groupName}.address.region.displayName`, option?.text, { shouldTouch: true });
                    return onChange(option?.id ?? null);
                  }}
                />
              )}
              control={control}
              name={`${groupName}.address.region.code`}
            />
          </FormControl>
          <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.address.region.displayName`)} />
          <FormControl fullWidth>
            <InputLabel htmlFor="legalCountyOfResidence">{t('residencyInfo.county')}</InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  id="legalCountyOfResidence"
                  {...field}
                  options={countyOptions}
                  fullWidth
                  inputProps={{
                    'aria-label': t('residencyInfo.county'),
                    ...DISABLE_ADDRESS_AUTO_FILL,
                  }}
                  onChange={option => {
                    setValue(`${groupName}.address.county.displayName`, option?.text);
                    return onChange(option?.id ?? null);
                  }}
                />
              )}
              control={control}
              name={`${groupName}.address.county.code`}
            />
          </FormControl>
          <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.address.county.displayName`)} />
          <FormControl fullWidth>
            <InputLabel htmlFor="numberOfYearsInUS">{t('residencyInfo.yearsInUS')}</InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                // Kept this comment code only for reference to rollback
                // <NumericField {...field} autoComplete="off" id="numberOfYearsInUS" format="##" onChange={onChange} />
                <Dropdown
                  id="numberOfYearsInUS"
                  autoComplete="off"
                  {...field}
                  options={residenceYearOptions}
                  fullWidth
                  inputProps={{
                    'aria-label': t('residencyInfo.yearsInUS'),
                    ...DISABLE_AUTO_FILL,
                  }}
                  onChange={option => {
                    setValue(`${groupName}.yearsInUS.displayName`, option?.text);
                    return onChange(option?.id ?? null);
                  }}
                />
              )}
              control={control}
              name={`${groupName}.yearsInUS.code`}
            />
          </FormControl>
          <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.yearsInUS.displayName`)} />
          <FormControl fullWidth>
            <InputLabel htmlFor="numberOfYearsInState">{t('residencyInfo.yearsInRegion')}</InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  id="numberOfYearsInState"
                  autoComplete="off"
                  {...field}
                  options={residenceYearOptions}
                  fullWidth
                  inputProps={{
                    'aria-label': t('residencyInfo.yearsInRegion'),
                    ...DISABLE_ADDRESS_AUTO_FILL,
                  }}
                  onChange={option => {
                    setValue(`${groupName}.yearsInRegion.displayName`, option?.text);
                    return onChange(option?.id ?? null);
                  }}
                />
              )}
              control={control}
              name={`${groupName}.yearsInRegion.code`}
            />
          </FormControl>
          <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.yearsInRegion.displayName`)} />
          <FormControl fullWidth>
            <InputLabel htmlFor="numberOfYearsInCounty">{t('residencyInfo.yearsInCounty')}</InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  id="numberOfYearsInCounty"
                  autoComplete="off"
                  {...field}
                  options={residenceYearOptions}
                  fullWidth
                  inputProps={{
                    'aria-label': t('residencyInfo.yearsInCounty'),
                    ...DISABLE_ADDRESS_AUTO_FILL,
                  }}
                  onChange={option => {
                    setValue(`${groupName}.yearsInCounty.displayName`, option?.text);
                    return onChange(option?.id ?? null);
                  }}
                />
              )}
              control={control}
              name={`${groupName}.yearsInCounty.code`}
            />
          </FormControl>
          <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.yearsInCounty.displayName`)} />
        </>
      )}
    </Grid>
  );
};
export default ResidencyInformation;
