/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */
import React, { ReactElement } from 'react';
import { Box, Grid, Stack, Typography, Card, CardContent, IconButton } from '@mui/material';
import { Icon, IconCaretRight } from '@liaison/liaison-ui';
import { trimHeader, extractYear, formatExperienceDateRange } from 'utils/utilities';
import { sxCard, textOverflowStyle } from 'pages/Pages.styles';
import { TAcademicInstituition } from 'userProfile/store/profileView/profileView.slice';
import { MAX_LENGTH_COMMUNITY_VIEW, FIELD_LENGTH_60 } from 'constants/field';
import { COURSES, DOCUMENTS } from '../LivingProfile.utils';

type TAcademicInstitutionProps = {
  institution: TAcademicInstituition;
  toggleDrawer: (institution: TAcademicInstituition, toggle?: string) => void;
};

export const AcademicInstitutionCard = ({ institution, toggleDrawer }: TAcademicInstitutionProps): ReactElement => {
  const addressResponse = institution?.address;
  const address = `${
    addressResponse?.region?.displayName !== 'N/A' ? addressResponse?.region?.displayName : addressResponse?.city
  }, ${addressResponse?.country?.displayName}`;

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} key={institution.id}>
      <Card
        variant="outlined"
        sx={{
          ...sxCard,
          height: { xs: '120px', sm: '100px' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <CardContent
          sx={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Stack direction="row" alignItems="center" spacing={2} sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Icon sx={{ color: theme => theme.palette.grey[500] }}>
                {institution?.type?.code === 'COLLEGE' ? 'IconCollege' : 'IconSchool'}
              </Icon>
            </Box>
            <Stack spacing={0.5} sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle3" sx={{ ...textOverflowStyle, whiteSpace: 'normal' }}>
                {trimHeader(institution?.name || '', FIELD_LENGTH_60)}
              </Typography>
              <Typography variant="caption" sx={textOverflowStyle}>
                {trimHeader(address, MAX_LENGTH_COMMUNITY_VIEW)}
              </Typography>
              <Typography variant="caption" color="black" noWrap sx={textOverflowStyle}>
                {formatExperienceDateRange(extractYear(institution.startDate), extractYear(institution.endDate))}
              </Typography>
            </Stack>
          </Stack>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              mt: { xs: '4rem', sm: '2rem' },
              ml: '-4rem',
            }}
          >
            {!!institution?.attachedCount && (
              <IconButton
                sx={{ p: 0.5 }}
                size="medium"
                aria-label="attachments"
                onClick={() => toggleDrawer(institution, DOCUMENTS)}
              >
                <Icon sx={{ color: '#311B92' }}>IconFiles</Icon>
              </IconButton>
            )}
            {!!institution?.competencyAttachedCount && (
              <IconButton
                sx={{ p: 0.5 }}
                size="medium"
                aria-label={trimHeader(institution?.name || '')}
                onClick={() => toggleDrawer(institution, DOCUMENTS)}
              >
                <Icon sx={{ color: '#0277BD' }}>IconLightbulb</Icon>
              </IconButton>
            )}
            <IconButton
              sx={{ p: 0.5 }}
              role="button"
              aria-label="open school details"
              onClick={() => toggleDrawer(institution, COURSES)}
            >
              <IconCaretRight />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};
