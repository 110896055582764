/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import Keycloak from 'keycloak-js';
import { KEYCLOAK, currentApp } from 'constants/general';

export const keyCloakAuthUrl = window.env.OIDC_AUTH_SERVER_HOST;

export const {
  [currentApp as keyof typeof KEYCLOAK]: {
    publicToPrivateTarget,
    keycloakLoginPage,
    keycloakEmailVerified,
    keycloakRealM,
    keycloakLpActivationClientId,
  },
} = KEYCLOAK;
export const redirectUrl = window.location.origin + publicToPrivateTarget;
export const loginPageUrl = window.location.origin + keycloakLoginPage;
export const emailVerifiedUrl = window.location.origin + keycloakEmailVerified;

export const keycloakCasToLP = new Keycloak({
  url: keyCloakAuthUrl,
  realm: KEYCLOAK?.[currentApp as keyof typeof KEYCLOAK]?.keycloakRealM,
  clientId: KEYCLOAK?.[currentApp as keyof typeof KEYCLOAK]?.keycloakLpActivationClientId,
});

export const keycloak = new Keycloak({
  url: keyCloakAuthUrl,
  realm: KEYCLOAK?.[currentApp as keyof typeof KEYCLOAK]?.keycloakRealM,
  clientId: KEYCLOAK?.[currentApp as keyof typeof KEYCLOAK]?.keycloakClientId,
});

const routesWithoutLogin = ['/impersonation', '/email-verified', '/404-not-found', '/logout'];

export const initOptions = {
  pkceMethod: 'S256',
  onLoad:
    localStorage.getItem('impersonation') || routesWithoutLogin.includes(window.location.pathname)
      ? 'check-sso'
      : 'login-required',
  checkLoginIframe: false,
};
