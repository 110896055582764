/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useCallback } from 'react';
import { Typography, Grid, Button } from '@mui/material';
import noAddedProgramYet from 'assets/noAddedProgramYet.png';
import { sanitize } from 'dompurify';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'transferPlanner/constants/general';
import { setUi } from 'store/ui/ui.slice';
import { useDispatch } from 'react-redux';
import { setTitle } from 'utils/commonUtils';

interface IInstructionViewProps {
  instruction: string;
}

export const InstructionView = ({ instruction }: IInstructionViewProps): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const dispatch = useDispatch();

  const setOpenCollegeSidebar = useCallback(() => {
    dispatch(setUi({ name: 'collegeSidebar', state: { open: true } }));
    setTitle(t('colleges.addPanelTitle'));
  }, [dispatch, t]);

  return (
    <>
      {instruction.length ? (
        <>
          <Typography
            sx={{ mt: 1.5, typography: { xs: 'body3', sm: 'body3', md: 'body2' } }}
            dangerouslySetInnerHTML={{
              __html: sanitize(t(instruction)),
            }}
          />
          {instruction?.includes('statusReadyToEnrollmentInstruction1') && (
            <Typography variant="body2" sx={{ mt: 1.5 }}>
              <Button onClick={setOpenCollegeSidebar} color="error" variant="text">
                {t('dashboard.statusReadyToEnrollmentInstructionLink')}
              </Button>{' '}
              {t('dashboard.statusReadyToEnrollmentInstruction2')}
            </Typography>
          )}
        </>
      ) : (
        <Grid container alignItems="center" justifyContent="center">
          <img style={{ width: '40%' }} src={noAddedProgramYet} alt="noAddedProgramYet" />
        </Grid>
      )}
    </>
  );
};
