/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Grid, Typography, Tooltip, IconButton } from '@mui/material';
import { SidePanel, IconAlertTriangle, IconInfoSolid } from '@liaison/liaison-ui';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { sxSidePanel } from 'pages/Pages.styles';
import { nameSpace } from 'transferPlanner/constants/general';
import { TP_ADDITIONAL_DETAILS } from 'transferPlanner/constants/routeNames';
import { ACADEMIC_HISTORY } from 'userProfile/constants/routeNames';
import { findTspAgreementDeadLine, type ITimeLineInfo } from './TimelineCalculator.utils';
import { InfoBox } from '../Dashboard/InfoBox';

type TTimelineProfileInfoPanel = {
  sidePanelInformation: {
    isSidePanelOpen: boolean;
    title: string;
  };
  closeSidePanel: () => void;
  profileInfo: ITimeLineInfo | null;
};

export const TimelineProfileInfoPanel = ({
  sidePanelInformation,
  closeSidePanel,
  profileInfo,
}: TTimelineProfileInfoPanel): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const navigate = useNavigate();

  let intendedTransferDate = '';
  let tspAgreementDeadLine = 'N/A';
  let unitsCompleted = 0;
  let unitsEarned = 0;
  let plannedEnrollment = '';
  let a2B2Completed = '';
  if (profileInfo) {
    const { additionalDetails, generalEducation, transferGPA } = profileInfo;

    intendedTransferDate = `${additionalDetails?.intendedTransferTerm?.displayName || ''} ${
      additionalDetails?.intendedTransferYear || ''
    }`;
    tspAgreementDeadLine = findTspAgreementDeadLine(profileInfo);
    unitsCompleted = transferGPA?.unitsCompleted || 0;
    unitsEarned = transferGPA?.unitsEarned || 0;
    plannedEnrollment = additionalDetails?.plannedEnrollmentStatus?.displayName || '';
    a2B2Completed = generalEducation?.a2b4Complete === true ? 'Yes' : 'No';
  }
  return (
    <SidePanel
      title={sidePanelInformation.title}
      open={sidePanelInformation.isSidePanelOpen}
      isBackdropClickEnabled={true}
      onClose={closeSidePanel}
      sx={sxSidePanel}
    >
      <Grid container display="flex" sx={{ height: '100%' }}>
        <Grid container item direction="row" rowSpacing={1}>
          <Grid item xs={12} sx={{ mb: '0.5rem' }}>
            <Typography variant="body3">
              <Trans
                t={t}
                i18nKey="timelineProfileInfo.description"
                components={{
                  link1: (
                    <Typography
                      variant="body3"
                      component="span"
                      tabIndex={0}
                      onClick={() => navigate(`/${TP_ADDITIONAL_DETAILS.path}`)}
                      aria-label={t('transferCalculator.redirectToAdditiDetails')}
                      sx={{
                        textDecoration: 'underline',
                        color: theme => theme.palette.secondary.main,
                        cursor: 'pointer',
                        fontWeight: 'bold',
                      }}
                    />
                  ),
                }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle6" color="secondary.text" sx={{ fontSize: '0.625rem' }}>
              {t('timelineProfileInfo.intendedTransferDate')}
            </Typography>
            <Typography variant="body3">{intendedTransferDate}</Typography>
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle6" color="secondary.text" sx={{ fontSize: '0.625rem' }}>
                {t('timelineProfileInfo.tspAgreementDeadline')}
              </Typography>
              {tspAgreementDeadLine !== 'N/A' && (
                <Tooltip title={t('timelineProfileInfo.tspAgreementDeadlineTooltip')}>
                  <IconButton
                    size="small"
                    sx={{ p: '0.1rem' }}
                    aria-label={`${t('timelineProfileInfo.tspAgreementDeadline')} info ${t(
                      'timelineProfileInfo.tspAgreementDeadlineTooltip'
                    )}`}
                  >
                    <IconInfoSolid />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            <Typography variant="body3">{tspAgreementDeadLine}</Typography>
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle6" color="secondary.text" sx={{ fontSize: '0.625rem' }}>
                {t('timelineProfileInfo.transferUnitsCompleted')}
              </Typography>
              {unitsEarned === 0 && (
                <Tooltip title={t('timelineProfileInfo.transferUnitsZeroWarning')}>
                  <IconButton
                    color="warning"
                    size="small"
                    sx={{ p: '0.1rem' }}
                    aria-label={`${t('timelineProfileInfo.transferUnitsCompleted')} warning ${t(
                      'timelineProfileInfo.transferUnitsZeroWarning'
                    )}`}
                  >
                    <IconAlertTriangle />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            <Typography variant="body3">{unitsCompleted}</Typography>
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle6" color="secondary.text" sx={{ fontSize: '0.625rem' }}>
              {t('timelineProfileInfo.enrollmentStatus')}
            </Typography>
            <Typography variant="body3">{plannedEnrollment}</Typography>
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle6" color="secondary.text" sx={{ fontSize: '0.625rem' }}>
                {t('timelineProfileInfo.a2B4Completed')}
              </Typography>
              {unitsEarned === 0 && (
                <Tooltip title={t('timelineProfileInfo.transferUnitsZeroWarning')}>
                  <IconButton
                    color="warning"
                    size="small"
                    sx={{ p: '0.1rem' }}
                    aria-label={`${t('timelineProfileInfo.a2B4Completed')} warning ${t(
                      'timelineProfileInfo.transferUnitsZeroWarning'
                    )}`}
                  >
                    <IconAlertTriangle />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            <Typography variant="body3">{a2B2Completed}</Typography>
          </Grid>
        </Grid>

        <Grid container item alignContent="flex-end" rowSpacing={1}>
          <Grid item xs={12}>
            <InfoBox
              isWelcomeModal={false}
              fullWidth={true}
              infoText={t('timelineProfileInfo.additionalDetailsLinkHeader')}
              buttonText1={t('timelineProfileInfo.additionalDetailsLinkButton')}
              onClick1={() => navigate(`/${TP_ADDITIONAL_DETAILS.path}`)}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoBox
              isWelcomeModal={false}
              fullWidth={true}
              infoText={
                unitsCompleted
                  ? t('timelineProfileInfo.academicHistoryLinkHeader2')
                  : t('timelineProfileInfo.academicHistoryLinkHeader1')
              }
              buttonText1={t('timelineProfileInfo.academicHistoryLinButton')}
              onClick1={() => navigate(`/${ACADEMIC_HISTORY.path}`)}
            />
          </Grid>
        </Grid>
      </Grid>
    </SidePanel>
  );
};
