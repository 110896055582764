type TTest = {
  type?: {
    displayName?: string;
  };
  classification?: {
    displayName?: string;
  };
  method?: {
    displayName?: string;
  };
};
export const displayTestData = (test: TTest | undefined): string => {
  let renderedText = '';

  if (test?.type?.displayName) {
    renderedText += test.type.displayName;
  }

  if (test?.type?.displayName && (test?.classification?.displayName || test?.method?.displayName)) {
    renderedText += ' - ';
  }

  if (test?.classification?.displayName) {
    renderedText += test.classification.displayName;
  }

  if (test?.classification?.displayName && test?.method?.displayName) {
    renderedText += ' - ';
  }

  if (test?.method?.displayName) {
    renderedText += test.method.displayName;
  }

  return renderedText;
};
