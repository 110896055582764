/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { FC, ReactElement } from 'react';
import { Box, SvgIcon, ListItemText, Typography, List, ListItem } from '@mui/material';
import { IconCheck } from '@liaison/liaison-ui';
import { sxListItemText, sxBox } from 'userProfile/components/PasswordValidationCheck/PasswordValidationCheck.styles';
import { headingMessageDefault, headingMessageSuccess } from './PasswordValidationCheck.data';

export interface IFailedValidationTypes {
  min?: string;
  lowercase?: string;
  uppercase?: string;
  number?: string;
  special?: string;
}

interface IRules {
  failed: boolean;
  message: string;
  type: string;
}

export interface IPasswordValidationCheckProps {
  failedValidationTypes: IFailedValidationTypes;
  isSubmitted: boolean;
  isEmpty: boolean;
  id: string;
}

interface IPrintMessage {
  message: string;
  failed?: boolean;
  type: string;
}

const printCheckIcon = (
  <SvgIcon fontSize="small" aria-label="checked" sx={{ pl: '0.313rem', color: 'success.main' }}>
    <IconCheck />
  </SvgIcon>
);

const printMessageSuccess: FC<IPrintMessage> = ({ message, type }) => {
  return (
    <ListItemText
      id={type}
      role="status"
      aria-label={`${message} checked.`}
      primary={
        <>
          {message} {printCheckIcon}
        </>
      }
      sx={sxListItemText}
    />
  );
};

const printMessageError: FC<IPrintMessage> = ({ message, type }) => {
  return (
    <ListItemText
      id={type}
      role="status"
      aria-label={`${message} not checked.`}
      primary={`${message}`}
      sx={{ color: 'error.main', ...sxListItemText }}
    />
  );
};

const printMessageValidated: FC<IPrintMessage> = ({ failed, message, type }) => {
  return (failed ? printMessageError : printMessageSuccess)({ message, type });
};

const PasswordValidationCheck = ({
  id,
  isEmpty,
  isSubmitted,
  failedValidationTypes,
}: IPasswordValidationCheckProps): ReactElement => {
  const rules: IRules[] = [
    { failed: !!failedValidationTypes?.min, message: 'Minimum of 8 characters', type: 'min' },
    { failed: !!failedValidationTypes?.lowercase, message: '1 lowercase letter', type: 'lowercase' },
    { failed: !!failedValidationTypes?.uppercase, message: '1 uppercase letter', type: 'uppercase' },
    { failed: !!failedValidationTypes?.number, message: '1 number', type: 'number' },
    { failed: !!failedValidationTypes?.special, message: '1 special character', type: 'special' },
  ];
  const typesList = rules.reduce((accumulator, { type }) => `${accumulator + type} `, '');

  const printMessageStale: FC<IPrintMessage> = ({ message, type, failed }) => {
    return (
      <ListItemText
        role="status"
        id={type}
        aria-label={`${message}.`}
        primary={
          <>
            {message} {((failedValidationTypes && !failed) || (!isEmpty && !failedValidationTypes)) && printCheckIcon}
          </>
        }
        sx={sxListItemText}
      />
    );
  };

  const headingMessage = isSubmitted && !failedValidationTypes ? headingMessageSuccess : headingMessageDefault;

  return (
    <Box id={id} sx={sxBox}>
      <Typography
        id={`${id}-validationMessage`}
        variant="body3"
        role="alert"
        aria-labelledby={`${id}-validationMessage ${typesList}`}
      >
        {`${headingMessage}:`}
      </Typography>
      <List sx={{ py: 0 }}>
        {rules.map(({ message, type, failed }) => (
          <ListItem key={message} sx={{ p: '0 1.5rem', listStyle: 'disc' }}>
            {(isSubmitted ? printMessageValidated : printMessageStale)({ message, failed, type })}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default PasswordValidationCheck;
