/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  dialogClasses,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconCancel } from '@liaison/liaison-ui';
import { ReactComponent as CheckCircleGreenIcon } from 'assets/svgs/check-circle-green-icon.svg';
import { nameSpace } from 'transferPlanner/constants/general';
import SaveAndFollowButton from 'transferPlanner/components/SaveAndFollowButton/SaveAndFollowButton';
import { Link } from 'react-router-dom';
import { TP_ADDITIONAL_DETAILS } from 'transferPlanner/constants/routeNames';

type TSimilarConfirmationProps = {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  onFollow: () => void;
  isDisabledFollowButton?: boolean;
};

export const SimilarConfirmation = ({
  open,
  onClose,
  onSave,
  onFollow,
  isDisabledFollowButton = false,
}: TSimilarConfirmationProps): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      role="dialog"
      scroll="body"
      aria-labelledby="dialog-modal-title"
      aria-describedby="dialog-modal-title"
      sx={{ [`.${dialogClasses.paper}`]: { borderRadius: '1rem' } }}
      onClick={e => e.stopPropagation()}
    >
      <Box
        sx={{
          display: 'flex',
          py: '0.5rem',
          px: ['1rem', '1.5rem', '2rem'],
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CheckCircleGreenIcon width={32} aria-label="icon success" />
          </Box>
          <DialogTitle id="dialog-modal-title">{t('programs.confirmation.similar.header')}</DialogTitle>
        </Box>
        <IconButton aria-label="close" size="small" onClick={() => onClose()} sx={{ height: 'fit-content' }}>
          <IconCancel
            sx={{
              color: theme => theme.palette.grey[500],
            }}
          />
        </IconButton>
      </Box>
      <Grid container component="main">
        <Grid item xs={12} sm={12}>
          <DialogContent tabIndex={0} id="dialog-modal-title" sx={{ pb: '0' }}>
            <Typography variant="body2" gutterBottom>
              {t('programs.confirmation.similar')}
              <Link to={`/${TP_ADDITIONAL_DETAILS.path}`}>{t('programs.confirmation.similarMsgLink')}</Link>
              {t('programs.programSavedAndFollowLastWord')}
            </Typography>
          </DialogContent>
          <Box
            sx={{
              display: isMobile ? 'block' : 'flex',
              justifyContent: 'space-between',
              pt: '.7rem',
              pb: '1.5rem',
              px: '1rem',
            }}
          >
            <SaveAndFollowButton
              label={t('programs.confirmation.followProgramHeader')}
              description={t('programs.confirmation.followProgramPara')}
              onClick={() => onFollow()}
              sx={isMobile ? { mb: '1rem' } : { mr: '1rem' }}
              disabled={isDisabledFollowButton}
            />
            <SaveAndFollowButton
              label={t('programs.confirmation.saveForLaterHeader')}
              description={t('programs.confirmation.saveForLaterPara')}
              onClick={() => onSave()}
              isSaveIcon={true}
            />
          </Box>
        </Grid>
      </Grid>
      <DialogActions
        sx={{
          justifyContent: 'center',
          py: '1rem',
          px: ['1rem', '1.5rem', '2.5rem'],
          borderTop: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Button
          variant="text"
          onClick={() => onClose()}
          sx={{
            color: '#0076A8',
          }}
        >
          {t('cancel_label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
