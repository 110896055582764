/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import { t as _t } from 'i18next';

import profileBg from 'assets/profile_bg.png';

import { DEFAULT, EFileSource } from '../ImageList/ImageList.utils';

export const acceptedFileFormats = {
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'image/bmp': ['.bmp'],
  'image/tga': ['.tga'],
  'image/heic': ['.heic'],
  'image/heif': ['.heif'],
};

export const POSTER_PICTURE = 'POSTER_PICTURE';

export const acceptedPosterImageFormats = Object.keys(acceptedFileFormats);

export const DEFAULT_POSTER_IMAGE = {
  id: DEFAULT,
  variant: 'original',
  name: 'Default Image',
  extension: 'PNG',
  mediaType: 'IMAGE',
  contentType: 'image/png',
  description: DEFAULT,
  ephemeralURL: profileBg,
  variants: [
    {
      variant: 'thumb',
      ephemeralURL: profileBg,
      fileSize: 15176,
    },
  ],
  size: 0,
  sizeDisplay: '',
  shareable: false,
  attachedCount: 0,
  uploadedTimestamp: '',
  updatedTimestamp: '',
  hidden: false,
};

export const fileSourceOptions = [
  { text: _t('mediaDocuments.avatarUpload.existing'), val: EFileSource.EXISTING },
  { text: _t('mediaDocuments.avatarUpload.new'), val: EFileSource.NEW },
];
