/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useEffect, useMemo } from 'react';
import { Typography, FormControl, FormHelperText, InputBase, InputLabel } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import type { TBiographicalInfoField } from 'userProfile/store/biographicalInfo/biographicalInfo.slice';
import { Dropdown } from '@liaison/liaison-ui';
import MasterData from 'userProfile/constants/master';
import { DISABLE_AUTO_FILL, MAX_BIG_LENGTH_FIELD } from 'constants/field';
import { getDropDownOptions } from 'utils/utilities';
import { StyledFormHelperText } from 'pages/Pages.styles';
import { useTranslation } from 'react-i18next';
import type { TBiographicFormErrors } from '../BiographicalInformationForm.validation';

interface IBiographicalInfoProps {
  data: TBiographicalInfoField | null;
  isProfileSetup?: boolean;
}

const GenderInformationForm = ({ data, isProfileSetup = false }: IBiographicalInfoProps): ReactElement => {
  const {
    control,
    register,
    setValue,
    formState: { errors: formErrors },
    reset,
  } = useFormContext();
  const errors = formErrors as unknown as TBiographicFormErrors;
  const groupName = 'gender';
  const { t } = useTranslation();

  const genderOptions = useMemo(() => getDropDownOptions(MasterData.genderOptions), []);
  const pronounsOptions = useMemo(() => getDropDownOptions(MasterData.pronounsOptions), []);

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [reset, data]);

  return (
    <>
      <Typography
        variant="h2"
        sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
      >
        {t('gender.title')}
      </Typography>
      <FormControl fullWidth error={!!errors?.[groupName]?.legalSex}>
        <InputLabel htmlFor="legalSex"> {t('gender.legalSex')}</InputLabel>
        <Controller
          render={({ field: { onChange, ...field } }) => (
            <Dropdown
              {...field}
              id="legalSex"
              options={genderOptions}
              fullWidth
              inputProps={{
                'aria-label': t('gender.legalSex'),
                'aria-describedby': 'legalSex-error',
                ...DISABLE_AUTO_FILL,
              }}
              onChange={option => {
                setValue(`${groupName}.legalSex.displayName`, option?.text);
                return onChange(option?.id ?? '');
              }}
            />
          )}
          control={control}
          name={`${groupName}.legalSex.code`}
        />
        <StyledFormHelperText id="legalSex-error" role="alert">
          {errors?.[groupName]?.legalSex?.code?.message}
        </StyledFormHelperText>
      </FormControl>
      <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.legalSex.displayName`)} />
      {!isProfileSetup && (
        <>
          <FormControl fullWidth error={!!errors?.[groupName]?.genderIdentity}>
            <InputLabel htmlFor="Name">{t('gender.identityAs')}</InputLabel>
            <InputBase
              inputProps={{
                'aria-label': t('gender.identityAs'),
                'aria-describedby': 'genderIdentity-error',
                maxLength: MAX_BIG_LENGTH_FIELD,
                ...DISABLE_AUTO_FILL,
              }}
              {...register(`${groupName}.genderIdentity`)}
            />
            <FormHelperText role="alert" id="genderIdentity-error">
              {errors?.[groupName]?.genderIdentity?.message}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="pronouns"> {t('gender.pronouns')}</InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                <>
                  <Dropdown
                    {...field}
                    id="pronouns"
                    options={pronounsOptions}
                    fullWidth
                    inputProps={{
                      'aria-describedby': 'pronouns-error',
                      ...DISABLE_AUTO_FILL,
                    }}
                    onChange={option => {
                      setValue(`${groupName}.pronouns.displayName`, option?.text);
                      return onChange(option?.id ?? '');
                    }}
                  />
                  {field.value === 'OTHER' && (
                    <>
                      <FormControl
                        fullWidth
                        error={!!errors?.[groupName]?.pronouns.otherText}
                        sx={{ marginTop: '1rem' }}
                      >
                        <InputLabel htmlFor="pronounsOtherText">{t('gender.pronouns.otherText')}</InputLabel>
                        <InputBase
                          inputProps={{
                            'aria-label': t('gender.pronouns.otherText'),
                            'aria-describedby': 'gender.pronouns.otherText',
                            maxLength: MAX_BIG_LENGTH_FIELD,
                            ...DISABLE_AUTO_FILL,
                          }}
                          {...register(`${groupName}.pronouns.otherText`)}
                        />
                        <FormHelperText role="alert" id="pronouns.otherText-error">
                          {errors?.[groupName]?.pronouns.otherText?.message}
                        </FormHelperText>
                      </FormControl>
                    </>
                  )}
                </>
              )}
              control={control}
              name={`${groupName}.pronouns.code`}
            />
            <StyledFormHelperText id="pronouns-error" role="alert">
              {errors?.[groupName]?.pronouns?.code?.message}
            </StyledFormHelperText>
          </FormControl>
          <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.pronouns.displayName`)} />
        </>
      )}
    </>
  );
};

export default memo(GenderInformationForm);
