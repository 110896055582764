/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Typography, Card, CardContent, Divider } from '@mui/material';
import { Icon, IconFiles, IconLightbulb, IconCancel } from '@liaison/liaison-ui';

import type { Idegree } from 'store/academicHistory/academicHistory.slice';
import { displayDate } from 'utils/utilities';
import { MultiValuedView } from 'userProfile/components/ViewBuilder/CardView';
import { StyledButton } from 'pages/Pages.styles';
import {
  sxButtonCompetenciesProfileView,
  sxButtonProfileView,
} from 'userProfile/components/AttachedFiles/AttachedFiles.styles';
import { getAttachedCount, handleCompetencyButtonClick } from '../LivingProfile.utils';

export const Degrees = ({ degrees }: { degrees: Idegree[] }): ReactElement => {
  const { t } = useTranslation();
  const [showCompetencyView, setShowCompetencyView] = useState<{ [key: string]: boolean }>({});

  return (
    <>
      {degrees?.map((degree: Idegree) => (
        <Grid item xs={12} sm={12} key={degree.id}>
          <Card variant="outlined" sx={{ borderRadius: 2 }}>
            <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
              <Grid container spacing={0} sx={{ p: 1 }}>
                <Grid item xs={10} sm={10} md={11} lg={11} sx={{ mr: '0.5rem', display: 'flex' }}>
                  <Box pr={1}>
                    <Icon sx={{ color: theme => theme.palette.grey[500], m: '0.3rem' }} aria-label="IconCollege">
                      IconGraduationCap
                    </Icon>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Typography variant="subtitle3" sx={{ overflowWrap: 'anywhere' }}>
                        {degree?.type?.displayName}
                      </Typography>
                    </Box>
                    <Box sx={{ mt: -0.5 }}>
                      <Typography variant="caption" sx={{ overflowWrap: 'anywhere' }}>
                        {degree?.degreeStatus.displayName}
                      </Typography>
                    </Box>
                    <Box sx={{ mt: -0.5 }}>
                      <Typography variant="subtitle8" sx={{ mt: 0.8 }} component="div">
                        {displayDate(degree?.startDate as string, 'MMMM YYYY')}
                        {' - '}
                        {degree?.endDate
                          ? displayDate(degree?.endDate as string, 'MMMM YYYY')
                          : t('colleges.inprogress')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {!!degree.attachedCount && (
                      <StyledButton
                        variant="contained"
                        startIcon={<IconFiles />}
                        size="medium"
                        fullWidth
                        aria-label="attachments"
                        sx={{ ...sxButtonProfileView, width: '50px', marginLeft: '10px' }}
                      >
                        {getAttachedCount(degree?.attachedCount)}
                      </StyledButton>
                    )}
                    {!!degree.competencyAttachedCount && (
                      <StyledButton
                        variant="contained"
                        startIcon={showCompetencyView[degree.id] ? <IconCancel /> : <IconLightbulb />}
                        size="medium"
                        fullWidth
                        aria-label="attachments"
                        sx={{ ...sxButtonCompetenciesProfileView, width: '50px', marginLeft: '10px' }}
                        onClick={() => handleCompetencyButtonClick(degree.id, setShowCompetencyView)}
                      >
                        {getAttachedCount(degree?.competencyAttachedCount)}
                      </StyledButton>
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Divider
                sx={{
                  borderStyle: 'solid',
                  mb: '0.7rem',
                  borderColor: theme => theme.palette.grey[500],
                }}
                flexItem
              />
              <Grid container spacing={2} sx={{ pl: 2, pr: 2, pb: 2, pt: 0 }}>
                <Grid item xs={3}>
                  <Typography variant="subtitle8" color="text.secondary" sx={{ textTransform: 'uppercase' }}>
                    {t('colleges.majorssWithBraket')}
                  </Typography>
                </Grid>
                <Grid item xs={9} sx={{ py: 0 }}>
                  <Typography sx={{ overflowWrap: 'anywhere' }} variant="subtitle7">
                    <Box component="ul" style={{ padding: 0, margin: 0 }}>
                      {degree?.majors?.map(major => (
                        <Box component="li" key={major.id}>
                          {major.displayName}
                        </Box>
                      ))}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
              {degree?.minors?.length > 0 ? (
                <Grid container spacing={2} sx={{ pl: 2, pr: 2, pb: 2, pt: 0 }}>
                  <Grid item xs={3}>
                    <Typography variant="subtitle8" color="text.secondary" sx={{ textTransform: 'uppercase' }}>
                      {t('colleges.minorsWithBraket')}
                    </Typography>
                  </Grid>
                  <Grid item xs={9} sx={{ py: 0 }}>
                    <Typography sx={{ overflowWrap: 'anywhere' }} variant="subtitle7">
                      <Box component="ul" style={{ padding: 0, margin: 0 }}>
                        {degree?.minors?.map(minor => (
                          <Box component="li" key={minor.id}>
                            {minor.displayName}
                          </Box>
                        ))}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}
              {showCompetencyView[degree.id] && (
                <Box sx={{ ml: 3 }}>
                  <Typography sx={{ mt: 4 }} variant="subtitle3">
                    {t('competenciesAndSkills.competencies')}
                  </Typography>
                  <MultiValuedView
                    multiValued={degree?.competencyAttachments?.map(attachment => attachment.competency) || []}
                  />
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </>
  );
};
