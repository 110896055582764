/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export const {
  PIS_URL: BASEURL,
  GLS_URL: GLSURL,
  OIDC_AUTH_SERVER_HOST,
  AH_URL: AHURL,
  S3_BUCKET_URL: S3URL,
  AUTH_URL: AUTHURL,
  AG_URL: AGURL,
  ST_URL: STURL,
  DC_URL: DCURL,
  ASSIST_URL,
  TSP_URL,
} = window.env;

export const API = {
  keycloakAuth: OIDC_AUTH_SERVER_HOST,
  profileInformation: `${BASEURL}/profile/`,
  staticLookups: `${GLSURL}/static/`,
  lookups: `${GLSURL}/`,
  alternateIdLinking: `${BASEURL}/alternateIdLinking`,
  s3Url: `${S3URL}/files`,
  auth: `${AUTHURL}/`,
  academicHistory: `${AHURL}/profile/`,
  applicantDetails: `${AGURL}/applicantdetails`,
  features: `${BASEURL}/featureFlags`,
  testResults: `${STURL}/`,
  collegeCourseExtension: `${TSP_URL}/collegeCourseExtension`,
  mediaDocuments: `${DCURL}/profile/`,
  profileView: `${AGURL}/profileview/`,
  timelineinfo: `${TSP_URL}/timelineInfo/`,
};
