/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import academicGoalsInfoReducer from 'userProfile/store/academicInfo/academicInfo.slice';
import careercGoalsInfoReducer from 'userProfile/store/careerInfo/careerInfo.slice';
import personalInfoReducer from 'userProfile/store/personalInfo/personalInfo.slice';
import biographicalInfoReducer from 'userProfile/store/biographicalInfo/biographicalInfo.slice';
import accomplishmentAndExperienceReducer from 'userProfile/store/accomplishmentAndExperience/accomplishmentAndExperience.slice';
import mediaDocumentsReducer from 'userProfile/store//mediaDocuments/mediaDocuments.slice';
import profileViewReducer from 'userProfile/store/profileView/profileView.slice';
import authReducer from 'userProfile/store/auth/auth.slice';

export default {
  personalInfo: personalInfoReducer,
  biographicalInfo: biographicalInfoReducer,
  academicGoalsInfo: academicGoalsInfoReducer,
  careerGoalsInfo: careercGoalsInfoReducer,
  accomplishmentAndExperience: accomplishmentAndExperienceReducer,
  mediaDocuments: mediaDocumentsReducer,
  profileView: profileViewReducer,
  auth: authReducer,
};
