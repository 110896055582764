/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { TAppThunk } from 'redux/store';
import { t } from 'i18next';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { loginPageUrl } from 'constants/keyCloak';
import moment from 'moment';
import { doLogout, getLoggedUser } from 'utils/keyCloakUtils';
import { GLSURL, API, AGURL } from 'constants/api';
import {
  lookupApiStart,
  lookupApiSuccess,
  lookupApiFailure,
  tenantApiStart,
  tenantApiSuccess,
  tenantApiFailure,
  obFlowStart,
  obFlowSuccess,
  obFlowFailure,
  IObData,
} from 'store/common/commonInfo.slice';
import { KeycloakPromise } from 'keycloak-js';

export const { TOKEN_EXPIRATION_IN_SEC } = window.env;

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryCondition: error => {
    return error.code === 'ECONNABORTED' || error?.response?.status === 500;
  },
  retryDelay: retryCount => retryCount * 1000,
});

export const getLookUps =
  (endPoint: string, queryParams = '', prefix = '', controllerSignal = {}): TAppThunk =>
  async dispatch => {
    try {
      dispatch(lookupApiStart());
      const { data } = await axios.get(`${GLSURL}/static/${endPoint}${queryParams}`, controllerSignal);
      const lookupData = {
        data,
        endPoint: prefix ? `${prefix}_${endPoint}` : endPoint,
      };
      dispatch(lookupApiSuccess(lookupData));
      return data;
    } catch (err) {
      dispatch(lookupApiFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
      return null;
    }
  };

export const getAcademicHistoryLookUps =
  (endPoint: string, queryParams = '', prefix = '', controllerSignal = {}, successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(lookupApiStart());
      const { data } = await axios.get(`${GLSURL}/${endPoint}${queryParams}`, controllerSignal);
      const lookupData = {
        data,
        endPoint: prefix ? `${prefix}_${endPoint}` : endPoint,
      };
      dispatch(lookupApiSuccess(lookupData));
      successCallback?.();
    } catch (err) {
      dispatch(lookupApiFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const getFieldsOfStudyLookUps =
  (endPoint: string, queryParams = '', prefix = '', controllerSignal = {}, successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(lookupApiStart());
      const { data } = await axios.get(`${GLSURL}/subject/${endPoint}${queryParams}`, controllerSignal);
      const lookupData = {
        data,
        endPoint: prefix ? `${prefix}_${endPoint}` : endPoint,
      };
      dispatch(lookupApiSuccess(lookupData));
      successCallback?.();
    } catch (err) {
      dispatch(lookupApiFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const getTenantConfig = (): TAppThunk => async dispatch => {
  try {
    const tenant = localStorage.getItem('tenant') || 'liaison';
    dispatch(tenantApiStart());
    const { data } = await axios.get(`${API?.s3Url}/${tenant}-tenant-config.json`);
    localStorage.setItem('tenantConfig', JSON.stringify(data));
    dispatch(tenantApiSuccess(data));
  } catch (err) {
    dispatch(tenantApiFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  }
};

export const getObData =
  (token: string, successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      const tenant = localStorage.getItem('tenant') || 'liaison';
      dispatch(obFlowStart());
      const { data } = await axios.get(`${AGURL}/onboarding/${getLoggedUser()}/tenant/${tenant}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 10000,
      });
      dispatch(obFlowSuccess(data));
      successCallback?.();
    } catch (err) {
      if (err?.code === 'ECONNABORTED') {
        dispatch(obFlowFailure('Request timed out. Please try again.'));
      } else if (err?.response?.status === 401) {
        doLogout(loginPageUrl);
      } else {
        dispatch(obFlowFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
      }
    }
  };

export const getModifiedObFlow = (obData: IObData | null): IObData => {
  const stepsList = obData?.nextSteps || [];
  const [, ...rest] = stepsList;
  const modifiedObData = {
    ...obData,
    nextSteps: rest,
  };

  return modifiedObData;
};

export const formatDateToMonthAndYear = (inputDate: string | undefined): string | undefined =>
  inputDate && moment(inputDate).format('MMM-YYYY');

export const getUrlParameterByName = (queryParams: string, name: string): string => {
  const extracted = queryParams.match(new RegExp(`[?&]${encodeURIComponent(name)}=([^&#]*)`));
  return extracted ? decodeURIComponent(extracted[1]) : '';
};

export const handleExit = (): KeycloakPromise<void, void> => doLogout();

/* istanbul ignore next */
export const setTitle = (title: string): void => {
  if (typeof title !== 'string') {
    throw new Error('Title should be an string');
  }
  document.title = title;
};
