/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Box, Grid, InputBase, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  '& input': {
    borderRadius: '1rem',
    paddingLeft: '2rem',
    backgroundColor: '#fff',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid lightgrey`,
    fontSize: 14,
    '&:focus': {
      boxShadow: '0px 0px 0px 3px rgba(3, 102, 214, 0.3)',
    },
  },
  '& .MuiInputAdornment-positionStart': {
    position: 'absolute',
    left: '0.25rem',
    paddingLeft: '0.2rem',
  },
}));

export const StyledImpersonationGrid = styled(Grid)<{
  impersonation: string;
  tspdeadlineextensiontime: string;
  iscampusesandprograms: string;
}>(({ theme: { breakpoints }, impersonation, tspdeadlineextensiontime, iscampusesandprograms }) => {
  const iscampusesandprogramsVal = iscampusesandprograms === 'true';
  const isBannerNImpersonationTrue = tspdeadlineextensiontime ? '0.375rem' : '0.25rem';
  const onlyBannerIsTrue = tspdeadlineextensiontime ? '0.375rem' : 0;
  const isCampusesAndProgramsForBanner = tspdeadlineextensiontime && iscampusesandprogramsVal ? '2.188rem' : 0;
  return {
    bgcolor: '#ECECEC',
    [breakpoints.up('sm')]: {
      paddingLeft: '3.75rem',
      marginTop: impersonation === 'true' ? '0.625rem' : isCampusesAndProgramsForBanner,
    },
    [breakpoints.down('sm')]: {
      marginTop: impersonation === 'true' ? isBannerNImpersonationTrue : onlyBannerIsTrue,
    },
  };
});

export const StyledGrid = styled(Grid)(({ theme: { breakpoints } }) => ({
  bgcolor: '#ECECEC',
  [breakpoints.up('sm')]: {
    paddingLeft: '3.75rem',
  },
}));

export const StyledBox = styled(Box)(({ theme: { breakpoints } }) => ({
  height: 'calc(100vh - 48px)',
  width: '100%',
  overflow: 'hidden',
  [breakpoints.up('md')]: {
    borderTopLeftRadius: '1.5rem',
  },
}));

export const leftContainer = {
  bgcolor: '#ECECEC',
  maxHeight: 'calc(100vh - 48px)',
  display: { md: 'block', xs: 'none' },
};

export const CampusListStyle = () =>
  ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    border: '1px solid #CAD2E1',
    borderRadius: '0.5rem',
    bgcolor: '#ffffff',
    my: '0.5rem',
  } as const);

export const selectedCampusStyle = {
  border: '1px solid #CC0B2A',
  bgcolor: '#E2CFD2',
};

export const selectedToggle = (theme: Theme) =>
  ({
    color: theme.palette.text.primary,
    bgcolor: '#FFFFFF',
    '&:hover': { bgcolor: '#FFFFFF' },
    m: '2px',
    borderRadius: '0.5rem',
  } as const);

export const unSelectedToggle = (theme: Theme) =>
  ({
    color: theme.palette.text.primary,
    bgcolor: theme.palette.grey[200],
    boxShadow: 'none',
    borderRadius: '0.5rem',
    m: '2px',
    '&:hover': { bgcolor: 'transparent' },
  } as const);

export const searchBarSmallerDivice = (theme: Theme) =>
  ({
    display: { md: 'none', lg: 'none', xs: 'block' },
    position: 'absolute',
    top: 'calc(100% - 85px)',
    bgcolor: '#ECECEC',
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
    width: 'calc(100% - 3.65rem)',
    height: '80%',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  } as const);

export const divider = {
  borderStyle: 'solid',
  borderWidth: '2px',
  width: '20%',
  borderColor: '#2F2F2F',
  opacity: '0.5',
  margin: 'auto',
} as const;

export const absoluteToggleView = (theme: Theme) =>
  ({
    position: 'absolute',
    top: '3.5rem',
    [theme.breakpoints.down('md')]: {
      width: '87%',
    },
    display: { md: 'none', lg: 'none', xs: 'block' },
  } as const);

export const StyledAbsoluteToggleViewBox = styled(Box)<{
  impersonation: string;
  tspdeadlineextensiontime: string;
}>(({ theme: { breakpoints }, impersonation, tspdeadlineextensiontime }) => {
  const topPositionWhenImpersonation = tspdeadlineextensiontime ? '11.5rem' : '7rem';
  const topPositionWhenOnlyBanner = tspdeadlineextensiontime ? '8.5rem' : '11.5rem';
  const isAnyTopPositionTrue = impersonation === 'true' ? topPositionWhenImpersonation : topPositionWhenOnlyBanner;
  const topSize = impersonation !== 'true' && tspdeadlineextensiontime !== 'true' ? '3.5rem' : isAnyTopPositionTrue;
  return {
    position: 'absolute',
    [breakpoints.down('md')]: {
      width: '100%',
    },
    top: impersonation === 'true' ? '7.5rem' : '3.5rem',
    [breakpoints.down('sm')]: {
      display: 'block',
      top: topSize,
    },
    [breakpoints.up('md')]: {
      display: 'none',
    },
  };
});

export const markerStyle = {
  border: '1px solid white',
  borderRadius: '50%',
  height: 10,
  width: 10,
  cursor: 'pointer',
  zIndex: 10,
};

export const infoWindowStyle = (theme: Theme) =>
  ({
    position: 'relative',
    width: '24rem',
    [theme.breakpoints.up('md')]: {
      transform: 'translate(-50%, calc(-100% - 24px))',
    },
    [theme.breakpoints.down('md')]: {
      width: '20rem',
      transform: 'translate(-50%, calc(-100% - 94px))',
      '& img': {
        maxHeight: '15rem',
      },
    },
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: '1rem',
    fontSize: '0.87rem',
    zIndex: 100,
    borderRadius: '1rem',
  } as const);

export const sxButton = {
  mt: '1rem',
} as const;

export const sxBoxLocationLink = {
  display: 'flex',
  alignItems: 'center',
  pb: '1rem',
} as const;

export const sxIcon = {
  pl: '0',
  fontSize: '1rem',
  opacity: '0.7',
} as const;
