/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo } from 'react';
import { setUi } from 'store/ui/ui.slice';
import { useSelector, useDispatch } from 'react-redux';
import { selectProgramCart } from 'store/ui/ui.selectors';
import { MyProgramsCart } from 'transferPlanner/components/MyProgramsCart';

const Cart = (): ReactElement => {
  const dispatch = useDispatch();
  const programCart = useSelector(selectProgramCart);
  const handleProgramCart = (open: boolean) => {
    dispatch(setUi({ name: 'programCart', state: { open, prevTitle: '' } }));
  };

  return (
    <>
      {programCart?.open && (
        <MyProgramsCart isOpen={programCart.open} setIsOpen={handleProgramCart} prevTitle={programCart.prevTitle} />
      )}
    </>
  );
};

export default memo(Cart);
