/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { memo, useEffect, ReactElement, KeyboardEvent, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconClipboardContent, IconAcademics, IconDocument } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import { setUi } from 'store/ui/ui.slice';
import { nameSpace } from 'transferPlanner/constants/general';
import { resetProgram } from 'transferPlanner/store/programs/programs.slice';
import { setTitle } from 'utils/commonUtils';
import { DASHBOARD, PROGRAM_PROGRESS } from 'transferPlanner/constants/routeNames';
import { FormDialog, type TFormHandler } from 'transferPlanner/components/FormDialog';
import { selectAdditionalDetails } from 'transferPlanner/store/additionalDetails/additionalDetails.selectors';
import {
  selectFollowedPrograms,
  selectActiveFollowedPrograms,
} from 'transferPlanner/store/programCart/programCart.selectors';
import { getFollowedPrograms } from 'transferPlanner/components/MyProgramsCart/MyProgramsCart.utils';
import { Box, Theme, Typography } from '@mui/material';
import { encryptWithEncode } from 'utils/forgeUtils';
import { sanitizePayload } from 'utils/utilities';
import {
  postTpAdditionalDetails,
  getTpAdditionalDetails,
  BIOGRAPHIC_INFORMATION,
  ADT_INFORMATION,
} from './TpAdditionalDetails.utils';
import { BiographicalInformationForm } from './BiographicalInformationForm';
import { AcademicInformationForm } from './AcademicInformationForm';
import {
  adtInformationSchema,
  raceEthnicitySummarySchema,
  adtInformationDefaultValues,
  raceEthnicitySummaryDefaultValues,
} from './TpAdditionalDetails.validation';

const TpAdditionalDetails = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(nameSpace);
  const followedPrograms = useSelector(selectFollowedPrograms);
  const activeFollowedPrograms = useSelector(selectActiveFollowedPrograms);
  const { additionalDetails } = useSelector(selectAdditionalDetails);
  const formRef = useRef<TFormHandler>(null);

  setTitle(t('tpAdditionalInfo.pageTitle'));

  useEffect(() => {
    if (!followedPrograms) {
      dispatch(getFollowedPrograms());
    }
  }, [followedPrograms, dispatch]);

  const raceEthnicitySummaryFormMethods = useForm({
    mode: 'all',
    resolver: async (...args) => yupResolver(raceEthnicitySummarySchema, { abortEarly: false })(...args),
    defaultValues: raceEthnicitySummaryDefaultValues,
  });

  const adtInformationFormMethods = useForm({
    mode: 'all',
    resolver: async (...args) => yupResolver(adtInformationSchema, { abortEarly: false })(...args),
    defaultValues: adtInformationDefaultValues,
  });

  useEffect(() => {
    dispatch(getTpAdditionalDetails());
    dispatch(resetProgram());
  }, [dispatch]);

  /* istanbul ignore next */
  const openFollowProgram = () => {
    const firstActiveProgramId = activeFollowedPrograms?.[0]?.program?.id;
    navigate(`/${PROGRAM_PROGRESS}/${encryptWithEncode(firstActiveProgramId?.toString())}`);
    window.scrollTo(0, 0);
    dispatch(setUi({ name: 'succesSnackbar', state: { open: false, message: '' } }));
  };

  /* istanbul ignore next */
  const handleFollowProgram = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      openFollowProgram();
    }
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (additionalDetails) {
      const {
        adtInformation,
        plannedEnrollmentStatus,
        raceEthnicitySummary,
        biographicInformationExtensions,
        academicInformation,
      } = additionalDetails;

      if (raceEthnicitySummary) {
        raceEthnicitySummaryFormMethods.reset({ raceEthnicitySummary, biographicInformationExtensions });
      }

      adtInformationFormMethods.reset({
        ...(adtInformation
          ? {
              adtInformation,
              degree: adtInformation?.[0]?.communityCollege?.name ? 'Yes' : 'No',
              secondDegree: adtInformation?.[1]?.communityCollege?.name ? 'Yes' : 'No',
            }
          : adtInformationDefaultValues),
        ...(plannedEnrollmentStatus && { plannedEnrollmentStatus }),
        ...(academicInformation && { academicInformation }),
      });
    }
  }, [additionalDetails, raceEthnicitySummaryFormMethods, adtInformationFormMethods]);

  const handleSaveAdditionalDetails = (formToSave: string, closeDialog = false) => {
    const formData =
      formToSave === BIOGRAPHIC_INFORMATION
        ? {
            ...additionalDetails,
            ...(raceEthnicitySummaryFormMethods.formState.isValid && raceEthnicitySummaryFormMethods.getValues()),
          }
        : {
            ...additionalDetails,
            ...(adtInformationFormMethods.formState.isValid && adtInformationFormMethods.getValues()),
            degree: null,
            secondDegree: null,
          };

    dispatch(
      postTpAdditionalDetails(sanitizePayload(Object.assign(formData)), () => {
        /* istanbul ignore next */
        if (closeDialog) {
          navigate(`/${DASHBOARD}`);
          dispatch(
            setUi({
              name: 'succesSnackbar',
              state: {
                open: true,
                autoHideDuration: 3600000,
                isManualClose: true,
                message: (
                  <>
                    <Box>
                      <Typography variant="subtitle7" tabIndex={0}>
                        {t('tpAdditionalInfo.saved')}
                      </Typography>
                    </Box>
                    {activeFollowedPrograms?.length > 0 && (
                      <Box sx={{ mt: 0.5 }}>
                        <Typography variant="subtitle7" tabIndex={0} display="inline" id="common-success-snackbar">
                          {t('additionalDetails.addInfoWarning1')}
                        </Typography>
                        <Typography
                          onClick={() => openFollowProgram()}
                          onKeyDown={handleFollowProgram}
                          sx={{
                            mr: 0.5,
                            ml: 0.5,
                            color: (theme: Theme) => theme.palette.success,
                            background: (theme: Theme) => theme.palette.common.white,
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            fontWeight: 'bold',
                          }}
                          paragraph
                          component="div"
                          variant="subtitle7"
                          display="inline"
                          tabIndex={0}
                        >
                          {t('programs.programFollowLink')}
                        </Typography>
                        <Typography variant="subtitle7" display="inline">
                          {t('additionalDetails.addInfoWarning2')}
                        </Typography>
                      </Box>
                    )}
                  </>
                ),
                hideTitle: true,
              },
            })
          );
        } else {
          formRef?.current?.goToNextTab();
        }
      })
    );
  };

  /* istanbul ignore next */
  const menuTabs = [
    {
      id: 0,
      name: t('tpAdditionalInfo.additionalInfo'),
      icon: IconClipboardContent,
      renderComponent: () => (
        <FormProvider {...raceEthnicitySummaryFormMethods}>
          <BiographicalInformationForm />
        </FormProvider>
      ),
      children: [0, 1],
      nextButton: {
        label: t('save_continue_label'),
        disabled: !raceEthnicitySummaryFormMethods.formState.isValid,
        onClick: () => handleSaveAdditionalDetails(BIOGRAPHIC_INFORMATION),
      },
    },
    {
      id: 0,
      name: t('tpAdditionalInfo.biographicalInfo'),
      icon: IconAcademics,
      renderComponent: () => (
        <FormProvider {...raceEthnicitySummaryFormMethods}>
          <BiographicalInformationForm />
        </FormProvider>
      ),
      parent: 0,
      nextButton: {
        label: t('save_continue_label'),
        disabled: !raceEthnicitySummaryFormMethods.formState.isValid,
        onClick: () => handleSaveAdditionalDetails(BIOGRAPHIC_INFORMATION),
      },
    },
    {
      id: 1,
      name: t('tpAdditionalInfo.academicInfo'),
      icon: IconDocument,
      renderComponent: () => (
        <FormProvider {...adtInformationFormMethods}>
          <AcademicInformationForm />
        </FormProvider>
      ),
      parent: 0,
      nextButton: {
        label: t('save_continue_label'),
        disabled: !adtInformationFormMethods.formState.isValid,
        onClick: () => handleSaveAdditionalDetails(ADT_INFORMATION, true),
      },
    },
  ];

  return (
    <FormDialog
      dialogTitle={t('tpAdditionalInfo.welcome')}
      dialogDescription={t('tpAdditionalInfo.welcomeDescription')}
      menuTabs={menuTabs}
      onClose={() => navigate(`/${DASHBOARD}`)}
      additionalDetails={additionalDetails}
      ref={formRef}
    />
  );
};

export default memo(TpAdditionalDetails);
