/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { formControlLabelClasses, gridClasses } from '@mui/material';

export const sxCheckboxGroup = {
  ml: 0,
  width: '100%',
  [`& .${formControlLabelClasses.root}`]: {
    ml: 0,
  },
  [`& .${gridClasses.root}.${gridClasses.item}`]: {
    pl: 0,
    pt: '0.5rem',
  },
  overflow: 'hidden',
} as const;

export const sxCheckboxChildGroup = {
  p: '1rem',
  bgcolor: '#EBEFF6',
  mt: 0,
  mr: '1rem',
} as const;
