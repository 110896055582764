/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Typography, useMediaQuery, Theme } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';

import { ModalView } from 'components/ModalView';
import { nameSpace } from 'transferPlanner/constants/general';
import { selectTspEnrollmentPeriod } from 'transferPlanner/store/enrollment/enrollment.selectors';

export const WelcomeSection = (): ReactElement => {
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation(nameSpace);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const tspEnrollmentPeriod = useSelector(selectTspEnrollmentPeriod);
  const isTspEnrollmentOpen = tspEnrollmentPeriod?.enrollmentOpen;

  const FullInfo = () => (
    <>
      <Typography gutterBottom variant="body2">
        <Trans t={t}>{isTspEnrollmentOpen ? 'dashboard.welcomeBody1' : 'dashboard.welcomeBody3'}</Trans>
      </Typography>
      <Typography variant="body2">
        <Trans t={t}>{isTspEnrollmentOpen ? 'dashboard.welcomeBody2' : 'dashboard.welcomeBody4'}</Trans>
      </Typography>
    </>
  );

  return (
    <>
      <Box sx={{ color: theme => theme.palette.text.primary, mt: { xs: 2, sm: 0 } }}>
        <Typography gutterBottom component="h1" sx={{ typography: { xs: 'h4', sm: 'h4', md: 'h2' } }}>
          {t('dashboard.welcomeHeader')}
        </Typography>
        <Typography gutterBottom variant="body2" fontSize={14}>
          <Trans t={t}>{isTspEnrollmentOpen ? 'dashboard.welcomeBody1' : 'dashboard.welcomeBody3'}</Trans>
        </Typography>
        {isMobile ? (
          <>
            {showMore && (
              <Typography variant="body2">
                <Trans t={t}>{isTspEnrollmentOpen ? 'dashboard.welcomeBody2' : 'dashboard.welcomeBody4'}</Trans>
              </Typography>
            )}
            <Button
              size="large"
              onClick={() => setShowMore(value => !value)}
              sx={{ textTransform: 'capitalize' }}
              aria-label={t(showMore ? 'read_less_aria_label' : 'read_more_aria_label')}
            >
              {t(showMore ? 'read_less_label' : 'read_more_label')}
            </Button>
          </>
        ) : (
          <Button
            size="large"
            onClick={() => setShowMore(true)}
            sx={{ textTransform: 'capitalize', color: '#1565C0 !important' }}
            aria-label={t('read_more_aria_label')}
          >
            {t('read_more_label')}
          </Button>
        )}
      </Box>
      {!isMobile && (
        <ModalView
          open={showMore}
          header={t('dashboard.welcomeHeader')}
          footerButtonConfig={{
            tertiary: {
              title: t('close_label'),
              props: {
                'aria-label': 'main close button',
                onClick: () => {
                  setShowMore(false);
                },
              },
            },
          }}
        >
          <FullInfo />
        </ModalView>
      )}
    </>
  );
};
