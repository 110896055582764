/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  dialogClasses,
  useMediaQuery,
  Theme,
  Toolbar,
  AppBar,
  IconButton,
  Box,
  Typography,
  DialogContent,
  Grid,
  Divider,
  Button,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'components/Spinner';
import { DASHBOARD, SKIP_CURRENT_ROUTE } from 'transferPlanner/constants/routeNames';
import { selectEligibility, selectQuestionnaireConfig } from 'transferPlanner/store/eligibility/eligibility.selectors';
import completeSetupBg from 'assets/svgs/completeSetupBg.svg';
import { ReactComponent as TransferIcon } from 'assets/svgs/transfer-icon.svg';
import { nameSpace } from 'transferPlanner/constants/general';
import { StyledDivider } from 'pages/Pages.styles';
import { setTitle } from 'utils/commonUtils';
import { type IResponseDetails, sanitizePayload } from 'utils/utilities';
import { type IEligibility } from 'transferPlanner/store/eligibility/eligibility.slice';
import { groupName, postEligibility } from './Questionnaire.utils';
import { Result } from './Result';
import FormBuilder from './FormBuilder/FormBuilder';
import {
  type TQuestion,
  type TQuestionnaireConfig,
  composePayload,
  getFormValues,
  inEligibilityCheck,
} from './DynamicQuestionnaire.utils';
import { useDynamicValidation } from './yupSchemaCreator';

export const DynamicQuestionnaire = (): ReactElement => {
  const [isResult, setIsResult] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation(nameSpace);
  const isExtraSmall = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const isExtrtaLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const { eligibility, loading } = useSelector(selectEligibility);
  const questionnaireConfig = useSelector(selectQuestionnaireConfig);
  const data = useMemo(
    () => getFormValues(eligibility, questionnaireConfig?.version as number),
    [eligibility, questionnaireConfig]
  );
  setTitle(t('Transfer-Success-Pathway-Questionnaire.pageTitle'));

  const methods = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    resolver: async (...args) => yupResolver(validationSchema, { abortEarly: false })(...args),
  });

  const {
    formState: { isValid },
    getValues,
    reset,
  } = methods;

  const { validationSchema } = useDynamicValidation(getValues(), questionnaireConfig?.questions as TQuestion[]);

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  const onClose = () => navigate(`/${DASHBOARD}`, SKIP_CURRENT_ROUTE);

  const onSubmit = (formData: Record<string, string>) => {
    const payload = composePayload(questionnaireConfig as TQuestionnaireConfig, formData, eligibility?.tspEligibility);
    dispatch(
      postEligibility(sanitizePayload(payload as unknown as IResponseDetails) as unknown as IEligibility, () =>
        setIsResult(true)
      )
    );
  };

  const leftSection = () => {
    return (
      <Grid item xs={3.25}>
        <Box
          sx={{
            height: '93vh',
            background: `url(${completeSetupBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              m: '2.5rem 1rem 0 1.5rem',
              display: 'flex',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: theme => theme.palette.secondary.contrastText,
              }}
            >
              {t(`${groupName}.header1`)}
            </Typography>
          </Box>
          <Divider
            sx={{
              borderStyle: 'solid',
              m: '1rem 1rem 1.5rem 1.5rem',
              display: 'block',
            }}
          />
          <Box
            sx={{
              mr: '-5rem',
              px: '3rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              id="questionnaire-eligibility"
              variant="subtitle5"
              sx={{
                color: theme => theme.palette.secondary.contrastText,
                textAlign: 'right',
              }}
            >
              {t(`${groupName}.eligibility`)}
            </Typography>
            <Box
              sx={{
                ml: '1rem',
                display: 'flex',
              }}
            >
              <TransferIcon aria-label="icon transfer" />
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  };

  const renderFooter = () => {
    return (
      <AppBar
        role="presentation"
        position={isExtraSmall ? 'fixed' : 'sticky'}
        sx={{
          top: 'auto',
          bottom: 0,
          bgcolor: '#ffffff',
          boxShadow: 'none',
          borderTop: '0.0625rem solid',
          borderColor: (theme: Theme) => theme.palette.divider,
        }}
      >
        <Toolbar
          sx={{
            border: 'none',
            display: 'flex',
            justifyContent: isResult ? 'flex-end' : 'space-between',
            alignItems: 'center',
          }}
        >
          {!isResult && (
            <>
              <Box sx={{ width: 'auto' }}>
                <Button onClick={onClose} color="error" variant="text">
                  {t('cancel_label')}
                </Button>
              </Box>
              <Box sx={{ width: 'auto' }}>
                <Button onClick={methods.handleSubmit(onSubmit)} variant="contained" disabled={!isValid}>
                  {t('submit_label')}
                </Button>
              </Box>
            </>
          )}
          {isResult && (
            <Box sx={{ width: 'auto' }}>
              <Button onClick={onClose} color="secondary" variant="contained">
                {t('go_to_dashboard_label')}
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    );
  };
  return (
    <main>
      <Dialog
        open
        disableEscapeKeyDown
        role="main"
        fullWidth
        fullScreen={isExtraSmall}
        maxWidth="lg"
        sx={{
          backdropFilter: 'blur(4px)',
          [`.${dialogClasses.paper}`]: {
            borderRadius: '1rem',
          },
        }}
        aria-label="questionnaire-modal"
        aria-labelledby="questionnaire-eligibility"
        aria-describedby="questionnaire-eligibility"
      >
        {isLarge && (
          <AppBar
            role="presentation"
            sx={{
              position: 'relative',
              background: `url(${completeSetupBg})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Toolbar>
              {isExtraSmall && (
                <IconButton edge="start" color="inherit" aria-label="close">
                  <TransferIcon aria-label="icon transfer" />
                </IconButton>
              )}
              <Box>
                <Typography
                  id="questionnaire-eligibility"
                  sx={{
                    color: theme => theme.palette.secondary.contrastText,
                    textAlign: { xs: 'left', sm: 'center', md: 'right' },
                    typography: { xs: 'subtitle1', sm: 'subtitle5' },
                  }}
                >
                  {t(`${groupName}.eligibility`)}
                </Typography>
              </Box>
            </Toolbar>
          </AppBar>
        )}
        <DialogContent sx={{ p: 0, height: '92vh', overflow: 'hidden' }}>
          <Grid container sx={{ minHeight: '100%' }}>
            {isExtrtaLarge && leftSection()}
            <Grid item xs={isExtrtaLarge ? 8.75 : 12} justifyContent="center" alignItems="center">
              <Box sx={{ height: '100%' }}>
                {isExtrtaLarge && (
                  <AppBar
                    role="presentation"
                    position="sticky"
                    sx={{
                      mt: '1.25rem',
                      bgcolor: 'transparent',
                      boxShadow: 'none',
                    }}
                  >
                    <Toolbar
                      sx={{
                        border: 'none',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography id="questionnaire-information-1" variant="h1">
                        {t(`${groupName}.questionnaire`)}
                      </Typography>
                    </Toolbar>
                    <Box>
                      <StyledDivider sx={{ p: 0, my: 0, mx: '7.5rem' }} />
                    </Box>
                  </AppBar>
                )}
                <Box
                  sx={{
                    p: 0,
                    m: 0,
                    ml: 0,
                    display: 'flex',
                    alignItems: loading ? 'center' : 'flex-start',
                    justifyContent: 'center',
                    minWidth: 'auto',
                    position: { md: 'unset', xl: 'relative' },
                    minHeight: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mt: 0,
                      pt: '1rem',
                      overflow: loading ? 'hidden' : 'auto',
                      flexDirection: 'column',
                      height: {
                        xs: 'calc(90vh - 73px)',
                        sm: 'calc(90vh - 100px)',
                        md: 'calc(90vh - 130px)',
                        lg: 'calc(90vh - 155px)',
                        xl: 'calc(80vh - 70px)',
                      },
                    }}
                  >
                    {!isExtrtaLarge && (
                      <Typography
                        sx={{ mt: { md: '1rem' }, mb: { md: 1 } }}
                        id="questionnaire-information-1"
                        variant="h1"
                        textAlign="center"
                      >
                        {t(`${groupName}.questionnaire`)}
                      </Typography>
                    )}
                    {loading ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner />
                      </Box>
                    ) : (
                      <Box
                        sx={
                          isExtraSmall
                            ? {
                                pb: { xs: '0rem', sm: '6rem', md: '6rem', lg: '6rem' },
                                overflowY: 'auto',
                                pl: 2,
                                pr: 2,
                              }
                            : { width: '80%', height: '100%' }
                        }
                      >
                        {isResult && (
                          <Result
                            eligible={inEligibilityCheck(questionnaireConfig as TQuestionnaireConfig, getValues())}
                          />
                        )}
                        {!isResult && (
                          <FormProvider {...methods}>
                            <form>
                              <FormBuilder config={questionnaireConfig} methods={methods} />
                            </form>
                          </FormProvider>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
                {renderFooter()}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </main>
  );
};
