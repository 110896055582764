/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { GOOGLE_MAP_API_KEY, googleMapZoomLevel } from 'transferPlanner/constants/general';
import { useAppDispatch } from 'redux/hooks';

import { setSelectedCampusIds, setIsEligible } from 'transferPlanner/store/searchProgram/searchProgram.slice';
import Marker from './Marker';
import { ICampus, ICoordinates } from '../CampusesAndPrograms.utils';
import { EViewType } from '../ToggleView';

interface IGoogleMap {
  data: ICampus[];
  onClickMap: (idx: number) => void;
  center: ICoordinates;
  setType: (type: EViewType) => void;
  setZoomLevel: (level: number) => void;
  focusedItemIndex?: number | undefined;
}
const GoogleMap = (props: IGoogleMap): ReactElement => {
  const { data, onClickMap, center, setType, setZoomLevel, focusedItemIndex } = props;
  const dispatch = useAppDispatch();

  /* istanbul ignore next */
  const onChildClick = (key: string) => {
    const index = data.findIndex(e => e.name === key);
    onClickMap(index);
  };

  useEffect(() => {
    dispatch(setSelectedCampusIds([]));
    dispatch(setIsEligible(false));
  }, [dispatch]);

  /* istanbul ignore next */
  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: GOOGLE_MAP_API_KEY || '',
      }}
      center={center}
      defaultZoom={googleMapZoomLevel}
      onChildClick={onChildClick}
      onClick={() => onClickMap(-1)}
      options={maps => ({
        zoomControl: true,
        zoomControlOptions: {
          position: maps.ControlPosition.TOP_RIGHT,
        },
      })}
      onZoomAnimationEnd={level => {
        setZoomLevel(level);
      }}
    >
      {data.map(campus => {
        return (
          <Marker
            key={campus.name}
            title={campus.name}
            lat={campus?.contact?.latitude}
            lng={campus?.contact?.longitude}
            show={campus.show || false}
            campus={campus}
            setType={setType}
            focusedItemIndex={focusedItemIndex}
          />
        );
      })}
    </GoogleMapReact>
  );
};
export default GoogleMap;
