/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import * as yup from 'yup';
import { t } from 'i18next';
import { csuNameSpace } from 'utils/utilities';
import { ITpAdditionalDetails } from 'transferPlanner/store/additionalDetails/additionalDetails.slice';

export const adtInformationDefaultValues: ITpAdditionalDetails & { degree: string; secondDegree: string } = {
  degree: 'No',
  secondDegree: 'No',
  adtInformation: [
    {
      communityCollege: { name: '' },
      program: { name: '' },
    },
    {
      communityCollege: { name: '' },
      program: { name: '' },
    },
  ],
  plannedEnrollmentStatus: undefined,
};

export const raceEthnicitySummaryDefaultValues: ITpAdditionalDetails = {
  raceEthnicitySummary: undefined,
};

export const raceEthnicitySummarySchema = yup.object().shape({
  biographicInformationExtensions: yup.object().shape({
    guardian1EducationLevel: yup.object().shape({
      code: yup.string().required(t('tpAdditionalInfo.parentEducationRequired', csuNameSpace)),
    }),
    guardian2EducationLevel: yup.object().shape({
      code: yup.string().required(t('tpAdditionalInfo.parentEducationRequired', csuNameSpace)),
    }),
    extendedMilitaryStatus: yup.object().shape({
      code: yup.string().required(t('tpAdditionalInfo.militoryStatusRequired', csuNameSpace)),
    }),
  }),
  raceEthnicitySummary: yup.object().shape({
    code: yup.string().required(t('tpAdditionalInfo.raceEthnicityRequired', csuNameSpace)),
  }),
});

export const adtInformationSchema = yup.object().shape({
  degree: yup.string().oneOf(['Yes', 'No']),
  secondDegree: yup.string().oneOf(['Yes', 'No']),
  adtInformation: yup.array().when('degree', {
    is: (val: string) => {
      return val === 'Yes';
    },
    then: yup
      .array()
      .of(
        yup.object().shape({
          communityCollege: yup.object().shape({
            name: yup.string().test({
              name: 'communityCollege1',
              message: t('tpAdditionalInfo.communityCollegeRequired', csuNameSpace),
              test: (val, { path }) => {
                if (path !== 'adtInformation[0].communityCollege.name') {
                  return true;
                }
                return !!val;
              },
            }),
          }),
          program: yup.object().shape({
            name: yup.string().test({
              name: 'program1',
              message: t('tpAdditionalInfo.programRequired', csuNameSpace),
              test: (val, { path }) => {
                if (path !== 'adtInformation[0].program.name') {
                  return true;
                }
                return !!val;
              },
            }),
          }),
        })
      )
      .when('secondDegree', {
        is: (val: string) => {
          return val === 'Yes';
        },
        then: yup.array().of(
          yup.object().shape({
            communityCollege: yup.object().shape({
              name: yup.string().test({
                name: 'communityCollege2',
                message: t('tpAdditionalInfo.communityCollegeRequired', csuNameSpace),
                test: (val1, { path }) => {
                  if (path !== 'adtInformation[1].communityCollege.name') {
                    return true;
                  }
                  return !!val1;
                },
              }),
            }),
            program: yup.object().shape({
              name: yup.string().test({
                name: 'program2',
                message: t('tpAdditionalInfo.programRequired', csuNameSpace),
                test: (val2, { path }) => {
                  if (path !== 'adtInformation[1].program.name') {
                    return true;
                  }
                  return !!val2;
                },
              }),
            }),
          })
        ),
      }),
  }),
  plannedEnrollmentStatus: yup.object().shape({
    code: yup.string().required(t('tpAdditionalInfo.enrollmentStatusRequired', csuNameSpace)),
  }),
  academicInformation: yup.object().shape({
    intendedTransferTerm: yup.object().shape({
      code: yup.string().required(t('tpAdditionalInfo.transferTermRequired', csuNameSpace)),
    }),
    intendedTransferYear: yup.string().required(t('tpAdditionalInfo.transferYearRequired', csuNameSpace)),
  }),
});
