/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TError = string | null;

type TResetToken = { token: string };

type IApplicationDetailsResponseData = {
  applicantEmailAddress: string | undefined;
  applicantId: string | undefined;
  cycleId: number | undefined;
  applicantFirstName: string | undefined;
  applicantLastName: string | undefined;
  source: string | undefined;
  studentAccountStatus: string | undefined;
};

export interface IAuthState {
  authenticationToken: string | null;
  accountDetailsLoading: boolean;
  accountDetails: IApplicationDetailsResponseData | null;
  accountDetailsError: TError;
}

export const initialState: IAuthState = {
  authenticationToken: null,
  accountDetailsLoading: false,
  accountDetails: null,
  accountDetailsError: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticationTokenSet: (state, action: PayloadAction<TResetToken>): IAuthState => ({
      ...state,
      authenticationToken: action.payload.token,
    }),
    accountDetailsStart: (state): IAuthState => ({
      ...state,
      accountDetailsLoading: true,
      accountDetailsError: null,
    }),
    accountDetailsSuccess: (state, action: PayloadAction<IApplicationDetailsResponseData>): IAuthState => ({
      ...state,
      accountDetailsLoading: false,
      accountDetails: action.payload,
    }),
    accountDetailsFailure: (state, action: PayloadAction<TError>): IAuthState => ({
      ...state,
      accountDetailsLoading: false,
      accountDetailsError: action.payload,
    }),
    resetAuth: (): IAuthState => initialState,
  },
});

export const { authenticationTokenSet, accountDetailsStart, accountDetailsSuccess, accountDetailsFailure, resetAuth } =
  authSlice.actions;

export default authSlice.reducer;
