/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { Component, ReactNode } from 'react';

import { ErrorSummary } from 'components/ErrorSummary';
import { t } from 'i18next';

interface IErrorBoundaryProps {
  children: ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
    };

    this.handleErrorSummaryClose = this.handleErrorSummaryClose.bind(this);
  }

  public static getDerivedStateFromError(): IErrorBoundaryState {
    return { hasError: true };
  }

  public handleErrorSummaryClose(): void {
    this.setState({ hasError: false });
  }

  public render(): React.ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;

    return (
      <>
        {children}
        <ErrorSummary open={hasError} onClose={this.handleErrorSummaryClose}>{`${t(
          'error.genericErrorMsg'
        )}`}</ErrorSummary>
      </>
    );
  }
}

export default ErrorBoundary;
