/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect, useState, useMemo } from 'react';
import { Grid, Box, FormControl, InputLabel, InputBase, FormHelperText, Typography, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import type { ICollege } from 'store/academicHistory/academicHistory.slice';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { DISABLE_AUTO_FILL_AUTOCOMPLETE } from 'constants/field';
import { DateField, Dropdown, IconAdd, IconRemove } from '@liaison/liaison-ui';
import { RadioControl } from 'components/RadioControl';
import { getLookUps } from 'utils/commonUtils';
import { selectcollegeDegreeTypes, selectcollegeMajorTypes } from 'store/common/commonInfo.selectors';
import { type FieldError, type Ioption, getDropDownOptions, isCsuTenant } from 'utils/utilities';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { StyledDeleteButton, StyledIconButton } from 'pages/Pages.styles';
import { degreeStatuses } from 'utils/AcademicHistory.utils';
import { AttachedFiles } from 'userProfile/components/AttachedFiles';
import { SkillsMultiselect } from 'userProfile/components/SkillsMutliselect';
import { selectSectionMediaDocs } from 'userProfile/store/mediaDocuments/mediaDocuments.selectors';

interface ICollegeDegree {
  data: ICollege | null;
  childPosition?: number;
  deleteDegree?:
    | ((
        childPosition: number,
        parent: string,
        parentPosition: number,
        selector: string,
        coursePosition?: number,
        tag?: string
      ) => void)
    | null;
  parentPosition?: number;
  prefix: string;
}

interface ErrorStructure {
  degreeStatus?: FieldError;
  type: {
    code: FieldError;
  };
  startDate: FieldError;
  endDate: FieldError;
  majors: { [key: number]: { code: FieldError } };
  minors: { [key: number]: { code: FieldError } };
}

type TErrorsType = {
  [prefix: string]: {
    [key: number]: ErrorStructure;
  };
};

const CollegeDegreeForm = ({
  data,
  childPosition = 0,
  deleteDegree,
  parentPosition = 0,
  prefix,
}: ICollegeDegree): ReactElement => {
  const { t } = useTranslation();
  const degrees = data?.degrees;
  const currentDegree = degrees?.[childPosition];
  const [showAlert, setShowAlert] = useState(false);
  const [hideMajorsPlusButton, setHideMajorsPlusButton] = useState(false);
  const [hideMinorsPlusButton, setHideMinorsPlusButton] = useState(false);
  const dispatch = useDispatch();
  const collegeDegreeTypesLookup = useSelector(selectcollegeDegreeTypes);
  const collegeMajorTypesLookup = useSelector(selectcollegeMajorTypes);
  const collegeDegreeTypesOptions = useMemo(
    () => getDropDownOptions((collegeDegreeTypesLookup as unknown as Ioption[]) || []),
    [collegeDegreeTypesLookup]
  );
  const collegeMajorTypesOptions = useMemo(
    () => getDropDownOptions((collegeMajorTypesLookup as unknown as Ioption[]) || []),
    [collegeMajorTypesLookup]
  );
  const sectionMediaDocs = useSelector(state => selectSectionMediaDocs(state, currentDegree?.id));

  const {
    control,
    register,
    formState: { errors: formErrors, isValid },
    setValue,
    reset,
    watch,
    getValues,
    trigger,
  } = useFormContext();

  const errors = formErrors as unknown as TErrorsType;

  const fieldPosition = `${prefix}.${childPosition}`;
  const majorsList = useFieldArray({
    control,
    name: `${fieldPosition}.majors`,
  });
  const minorsList = useFieldArray({
    control,
    name: `${fieldPosition}.minors`,
  });

  useEffect(() => {
    if (currentDegree) {
      reset({ degrees });
    }
    return () => reset({ degrees: [] });
  }, [reset, degrees, currentDegree]);

  useEffect(() => {
    if (!currentDegree) {
      setValue(`${fieldPosition}.majors`, [{ code: '', displayName: '' }]);
      setValue(`${fieldPosition}.minors`, [{ code: '', displayName: '' }]);
    }
    if (currentDegree) {
      setTimeout(() => {
        if (!currentDegree?.majors) setValue(`${fieldPosition}.majors`, [{ code: '', displayName: '' }]);
        if (!currentDegree?.minors) setValue(`${fieldPosition}.minors`, [{ code: '', displayName: '' }]);
      }, 100);
    }
  }, [fieldPosition, setValue, currentDegree]);

  useEffect(() => {
    if (!collegeDegreeTypesLookup) {
      dispatch(getLookUps('collegeDegreeTypes'));
    }
  }, [dispatch, collegeDegreeTypesLookup]);

  useEffect(() => {
    if (!collegeMajorTypesLookup) {
      dispatch(getLookUps('collegeMajorTypes'));
    }
  }, [dispatch, collegeMajorTypesLookup]);

  const watchDegreeStatusId =
    watch(`${prefix}.${childPosition}.degreeStatus.code`) || getValues(`${prefix}.${childPosition}.degreeStatus.code`);

  useEffect(() => {
    if (watchDegreeStatusId && getValues(`${prefix}.${childPosition}.endDate`)) {
      trigger(`${prefix}.${childPosition}.endDate`);
    }
  }, [trigger, watchDegreeStatusId, childPosition, prefix, getValues]);

  const tag = `colleges/${data?.id}/degrees/${currentDegree?.id}`;

  return (
    <>
      <Box>
        <Typography component="div" variant="subtitle5" sx={{ mb: '1rem' }}>
          {t('colleges.degree.heading')}
        </Typography>

        <FormControl fullWidth required>
          <InputLabel htmlFor="degreeStatus">{t('colleges.degreeStatus')}</InputLabel>
          <Controller
            name={`${prefix}.${childPosition}.degreeStatus.code`}
            render={({ field: { ref, ...field } }) => (
              <RadioControl
                {...field}
                inputRef={ref}
                id={`degreeStatus-${childPosition}`}
                options={degreeStatuses}
                error={!!errors?.[prefix]?.[childPosition]?.degreeStatus?.message}
                aria-describedby="degreeStatus-error"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const displayName =
                    event?.target?.value === 'DEGREE_IN_PROGRESS'
                      ? t('colleges.degree.inprogress.val')
                      : t('colleges.degree.awarded.val');
                  setValue(`${prefix}.${childPosition}.degreeStatus.displayName`, displayName);
                  field.onChange(event?.target?.value);
                }}
              />
            )}
            control={control}
            defaultValue=""
          />
          <FormHelperText role="alert" id="degreeStatus-error">
            {errors?.[prefix]?.[childPosition]?.degreeStatus?.message}
          </FormHelperText>
        </FormControl>
        <InputBase inputProps={{ type: 'hidden' }} {...register(`${prefix}.${childPosition}.degreeStatus.code`)} />

        <FormControl fullWidth required error={!!errors?.[prefix]?.[childPosition]?.type?.code}>
          <InputLabel htmlFor="typeOfDegree">{t('colleges.typeOfDegree')}</InputLabel>
          <Controller
            render={({ field: { onChange, ...field } }) => (
              <Dropdown
                id="type"
                {...field}
                options={collegeDegreeTypesOptions}
                onChange={option => {
                  setValue(`${prefix}.${childPosition}.type.displayName`, option?.text);
                  return onChange(option?.id ?? '');
                }}
                inputProps={{
                  'aria-label': t('colleges.typeOfDegree'),
                  'aria-describedby': 'type-error',
                  ...DISABLE_AUTO_FILL_AUTOCOMPLETE,
                }}
              />
            )}
            control={control}
            name={`${prefix}.${childPosition}.type.code`}
          />
          <FormHelperText role="alert" id="type-error">
            {errors?.[prefix]?.[childPosition]?.type?.code?.message}
          </FormHelperText>
        </FormControl>
        <InputBase inputProps={{ type: 'hidden' }} {...register(`${prefix}.${childPosition}.type.displayName`)} />

        {watchDegreeStatusId && (
          <>
            <FormControl fullWidth margin="none">
              <Controller
                name={`${prefix}.${childPosition}.startDate`}
                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!errors?.[prefix]?.[childPosition]?.startDate}>
                    <InputLabel htmlFor="startDate">{t('academicHistory.startDate')}</InputLabel>
                    <DateField
                      {...field}
                      ref={ref}
                      error={!!error}
                      id="startDate"
                      hidePart="day"
                      onChange={dateVal => {
                        trigger(`${prefix}.${childPosition}.endDate`);
                        return field.onChange(dateVal || '');
                      }}
                      inputProps={{
                        'aria-label': t('academicHistory.startDate'),
                      }}
                      aria-describedby="startDate-error"
                      accessibilityLabelPrefix={t('academicHistory.startDate')}
                    />
                    <FormHelperText role="alert" id="startDate-error">
                      {errors?.[prefix]?.[childPosition]?.startDate?.message}
                    </FormHelperText>
                  </FormControl>
                )}
                control={control}
              />
            </FormControl>
            <FormControl fullWidth margin="none">
              <Controller
                name={`${prefix}.${childPosition}.endDate`}
                render={({ field: { ref, ...field }, fieldState: { error } }) => {
                  const label =
                    watchDegreeStatusId === 'AWARDED' ? t('colleges.degree.awarded') : t('colleges.degree.inprogress');
                  return (
                    <FormControl fullWidth required error={!!errors?.[prefix]?.[childPosition]?.endDate}>
                      <InputLabel htmlFor="endDate" required>
                        {label}
                      </InputLabel>
                      <DateField
                        {...field}
                        ref={ref}
                        error={!!error}
                        id="endDate"
                        onChange={dateVal => {
                          trigger(`${prefix}.${childPosition}.startDate`);
                          return field.onChange(dateVal || '');
                        }}
                        {...(watchDegreeStatusId === 'AWARDED' ? { hidePart: 'day' } : {})}
                        aria-describedby="date-error"
                        accessibilityLabelPrefix={label}
                        inputProps={{
                          'aria-label': label,
                        }}
                      />
                      <FormHelperText role="alert" id="date-error">
                        {errors?.[prefix]?.[childPosition]?.endDate?.message}
                      </FormHelperText>
                    </FormControl>
                  );
                }}
                control={control}
              />
            </FormControl>
          </>
        )}
      </Box>

      <Divider sx={{ borderStyle: 'solid', mt: '1rem', mb: '1rem' }} flexItem />

      <Typography component="div" variant="subtitle5" sx={{ mb: '1rem' }}>
        {t('colleges.degree.majorMinor.heading')}
      </Typography>
      {majorsList?.fields?.map((majors, index) => (
        <React.Fragment key={majors?.id}>
          {index === 0 && (
            <FormControl fullWidth>
              <Box sx={{ mb: -1, display: 'flex', flexDirection: 'row' }}>
                <InputLabel htmlFor="majors">{t('colleges.majorssWithBraket')}</InputLabel>
              </Box>
            </FormControl>
          )}
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: 0.9 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl fullWidth error={!!errors?.[prefix]?.[childPosition]?.majors?.[index]?.code}>
                    <Controller
                      render={({ field: { onChange, ...field } }) => (
                        <Dropdown
                          id={`majors.${index}.displayName`}
                          {...field}
                          options={collegeMajorTypesOptions}
                          onChange={option => {
                            if (option) {
                              setValue(`${prefix}.${childPosition}.majors.${index}.displayName`, option?.text);
                            } else {
                              setValue(`${prefix}.${childPosition}.majors.${index}`, {
                                displayName: null,
                                id: null,
                              });
                            }
                            return onChange(option?.id ?? '');
                          }}
                          inputProps={{
                            'aria-label': `majors.${index}.displayName`,
                            'aria-describedby': `majors.${index}-error`,
                            ...DISABLE_AUTO_FILL_AUTOCOMPLETE,
                          }}
                        />
                      )}
                      control={control}
                      name={`${prefix}.${childPosition}.majors.${index}.code`}
                    />
                    <FormHelperText role="alert" id={`majors.${index}-error`}>
                      {errors?.[prefix]?.[childPosition]?.majors?.[index]?.code?.message}
                    </FormHelperText>
                  </FormControl>
                  <InputBase
                    inputProps={{ type: 'hidden' }}
                    {...register(`${prefix}.${childPosition}.majors.${index}.displayName`)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ display: 'flex', flex: 0.1, alignItems: 'center', mt: -1.65 }}>
              {index === 0 && (
                <>
                  {!hideMajorsPlusButton ? (
                    <StyledIconButton
                      size="small"
                      aria-label={`${t('add_label')} ${t('colleges.majors')}`}
                      onClick={() => majorsList?.append({ code: '', displayName: '' })}
                    >
                      <IconAdd />
                    </StyledIconButton>
                  ) : (
                    <Box sx={{ visibility: 'hidden' }}>
                      <StyledIconButton size="small" aria-label={`${t('add_label')} ${t('colleges.majors')}`}>
                        <IconAdd />
                      </StyledIconButton>
                    </Box>
                  )}
                </>
              )}

              {index !== 0 && (
                <StyledIconButton
                  size="small"
                  aria-label={`${t('remove_label')} ${t('colleges.majors')}`}
                  onClick={() => {
                    setHideMajorsPlusButton(false);
                    majorsList?.remove(index);
                  }}
                >
                  <IconRemove />
                </StyledIconButton>
              )}
            </Box>
          </Box>
        </React.Fragment>
      ))}

      {minorsList?.fields?.map((minors, index) => (
        <React.Fragment key={minors?.id}>
          {index === 0 && (
            <FormControl fullWidth>
              <Box sx={{ mb: -1, display: 'flex', flexDirection: 'row' }}>
                <InputLabel htmlFor="minors">{t('colleges.minorsWithBraket')}</InputLabel>
              </Box>
            </FormControl>
          )}
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: 0.9 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl fullWidth error={!!errors?.[prefix]?.[childPosition]?.minors?.[index]?.code}>
                    <Controller
                      render={({ field: { onChange, ...field } }) => (
                        <Dropdown
                          id={`minors.${index}.displayName`}
                          {...field}
                          options={collegeMajorTypesOptions}
                          onChange={option => {
                            if (option) {
                              setValue(`${prefix}.${childPosition}.minors.${index}.displayName`, option?.text);
                            } else {
                              setValue(`${prefix}.${childPosition}.minors.${index}`, {
                                displayName: null,
                                id: null,
                              });
                            }
                            return onChange(option?.id ?? '');
                          }}
                          inputProps={{
                            'aria-label': `minors.${index}.displayName`,
                            'aria-describedby': `minors.${index}-error`,
                            ...DISABLE_AUTO_FILL_AUTOCOMPLETE,
                          }}
                        />
                      )}
                      control={control}
                      name={`${prefix}.${childPosition}.minors.${index}.code`}
                    />
                    <FormHelperText role="alert" id={`minors.${index}-error`}>
                      {errors?.[prefix]?.[childPosition]?.minors?.[index]?.code?.message}
                    </FormHelperText>
                  </FormControl>
                  <InputBase
                    inputProps={{ type: 'hidden' }}
                    {...register(`${prefix}.${childPosition}.minors.${index}.displayName`)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ display: 'flex', flex: 0.1, alignItems: 'center', mt: -1.65 }}>
              {index === 0 && (
                <>
                  {!hideMinorsPlusButton ? (
                    <StyledIconButton
                      size="small"
                      aria-label={`${t('add_label')} ${t('colleges.minors')}`}
                      onClick={() => minorsList?.append({ code: '', displayName: '' })}
                    >
                      <IconAdd />
                    </StyledIconButton>
                  ) : (
                    <Box sx={{ visibility: 'hidden' }}>
                      <StyledIconButton size="small" aria-label={`${t('add_label')} ${t('colleges.minors')}`}>
                        <IconAdd />
                      </StyledIconButton>
                    </Box>
                  )}
                </>
              )}

              {index !== 0 && (
                <StyledIconButton
                  size="small"
                  aria-label={`${t('remove_label')} ${t('colleges.minors')}`}
                  onClick={() => {
                    setHideMinorsPlusButton(false);
                    minorsList?.remove(index);
                  }}
                >
                  <IconRemove />
                </StyledIconButton>
              )}
            </Box>
          </Box>
        </React.Fragment>
      ))}
      <AttachedFiles sectionName="degrees" tag={tag} disabled={!isValid} />
      {!isCsuTenant() && <SkillsMultiselect tag={tag} disabled={!isValid} />}
      {deleteDegree && (
        <Box sx={{ justifyContent: 'center', display: 'flex' }}>
          <StyledDeleteButton onClick={() => setShowAlert(true)}>
            {`${t('remove_label')} ${t('colleges.degree')}`}
          </StyledDeleteButton>
        </Box>
      )}
      <ConfirmationDialog
        open={showAlert}
        text={t('colleges.deleteCollegeDegree')}
        confirmationText={t('academicHistory.deleteContent')}
        onClose={() => {
          setShowAlert(false);
        }}
        footerButtonConfig={{
          primary: {
            title: t('remove_label'),
            props: {
              onClick: () => {
                setShowAlert(false);
                deleteDegree?.(
                  childPosition || 0,
                  'colleges',
                  parentPosition,
                  'degrees',
                  0,
                  sectionMediaDocs?.length ? tag : undefined
                );
              },
            },
          },
          tertiary: {
            title: t('cancel_label'),
          },
        }}
      />
    </>
  );
};

export default CollegeDegreeForm;
