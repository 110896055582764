/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';

import { TRootState } from 'redux/store';

import { IAuthState } from './auth.slice';

const selectAuth = (state: TRootState): IAuthState => state.accountAuth;

export const selectIsAuthenticated = createSelector(selectAuth, (auth: IAuthState) => !!auth.authenticationToken);

export const selectAuthenticatedToken = createSelector(selectAuth, (auth: IAuthState) => auth.authenticationToken);

export const selectAccountDetailsLoading = createSelector(selectAuth, (auth: IAuthState) => auth.accountDetailsLoading);

export const selectAccountDetailsError = createSelector(selectAuth, (auth: IAuthState) => auth.accountDetailsError);

export const selectAccountDetailsData = createSelector(selectAuth, (auth: IAuthState) => auth.accountDetails);
