/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { KeycloakPromise } from 'keycloak-js';
import {
  keycloak,
  redirectUrl,
  keyCloakAuthUrl,
  keycloakLoginPage,
  keycloakEmailVerified,
  keycloakRealM,
  keycloakLpActivationClientId,
  loginPageUrl,
} from 'constants/keyCloak';

export const keyCloakSignUpUrl = (): string => {
  const returnUrl = encodeURIComponent(
    `${window.location?.origin}/${keycloakEmailVerified}?loginUrl=${window.location?.origin}${keycloakLoginPage}`
  );
  return `${keyCloakAuthUrl}/realms/${keycloakRealM}/protocol/openid-connect/registrations?client_id=${keycloakLpActivationClientId}&response_type=code&scope=openid email&redirect_uri=${returnUrl}&kc_locale=en`;
};

export const getLoggedUserId = (): string | null => localStorage.getItem('kc_user_token');

export const doLogin = (): KeycloakPromise<void, void> => keycloak?.login({ redirectUri: redirectUrl });

export const doLoginWithoutRedirect = (): KeycloakPromise<void, void> => keycloak.login();

export const doLogout = (redirectPageUrl?: string): KeycloakPromise<void, void> => {
  sessionStorage.clear();
  localStorage.clear();
  return keycloak?.logout({ redirectUri: redirectPageUrl || loginPageUrl });
};

export const doLogoutWithoutRedirect = (): KeycloakPromise<void, void> => {
  localStorage.clear();
  return keycloak?.logout();
};

export const getToken = (): string => keycloak?.token || '';

export const isLoggedIn = (): boolean => !!keycloak.token;

export const isAuthenticated = (): boolean => keycloak?.authenticated || false;

export const getLoggedUser = (): string => keycloak?.tokenParsed?.['LP-STUDENT-ID'] || getLoggedUserId();
