/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect, useMemo, memo, useState, MouseEvent } from 'react';
import { Grid, Typography, Box, Button, IconButton, Theme, useMediaQuery } from '@mui/material';
import { MenuActions, IconStar, IconTrash, IconListCheck, IconEllipsisVertical, Alert } from '@liaison/liaison-ui';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectActiveFollowedPrograms,
  selectSelectedProgramCart,
} from 'transferPlanner/store/programCart/programCart.selectors';
import { selectTspEnrollment } from 'transferPlanner/store/enrollment/enrollment.selectors';
import {
  selectAdditionalDetails,
  selectAdtInformation,
} from 'transferPlanner/store/additionalDetails/additionalDetails.selectors';
import { StyledDivider } from 'pages/Pages.styles';
import { allowedEnrollmentStatuses, nameSpace } from 'transferPlanner/constants/general';
import { selectCommunityColleges, selectAcademicHistory } from 'store/academicHistory/academicHistory.selectors';
import { AdditionalAgreements } from 'transferPlanner/components/AdditionalAgreements';
import { RESEARCH_CAMPUS_AND_PROGRAMS } from 'transferPlanner/constants/routeNames';
import { ProgramView } from 'transferPlanner/pages/CampusesAndPrograms/Programs/ProgramView';
import { TspRequestRelease } from 'transferPlanner/pages/TspRequestRelease';
import { Spinner } from 'components/Spinner';
import {
  fetchProgramDetails,
  TFetchProgramDetailsResponse,
} from 'transferPlanner/pages/CampusesAndPrograms/Programs/ProgramCardsLazyScroll/ProgramCardsLazyScroll.utils';
import { getAcademicHistory } from 'utils/AcademicHistory.utils';
import {
  getTspEligibleFlags,
  postProgramCart,
  postUnFollowProgram,
} from 'transferPlanner/components/MyProgramsCart/MyProgramsCart.utils';
import { useAppSelector } from 'redux/hooks';
import { setTitle } from 'utils/commonUtils';
import Cart from 'transferPlanner/components/MyProgramsCart/Cart';
import EmptySlate from 'transferPlanner/components/EmptySlate/EmptySlate';
import { AddCollege } from 'transferPlanner/components/AddCollege';
import {
  updateUnFollowedProgramIndex,
  type ISelectedProgram,
  type IAdtDetails,
} from 'transferPlanner/store/programCart/programCart.slice';
import { decryptWithDecode } from 'utils/forgeUtils';
import noFollowedProgram from 'assets/noFollowedProgram.png';
import GpaCalculationErrorAlert from 'transferPlanner/components/GpaCalculationErrorAlert/GpaCalculationErrorAlert';
import { gpaCalculationSelector } from 'transferPlanner/store/genEdProcess/genEdProcess.selectors';

import { AgreementsCard } from 'transferPlanner/components/AdditionalAgreements/AgreementsCard';
import useTooltip from 'transferPlanner/hooks/useTooltip';
import { selectTspEligibleFlags } from 'transferPlanner/store/programs/programs.selectors';
import { getEnrollment } from '../TspEnrollment/TspEnrollment.utils';
import EnrollmentDetails from './EnrollmentDetails';
import { EViewType } from '../CampusesAndPrograms/ToggleView';
import { getGpaCalculation } from '../GeneralEducation/GeneralEducation.utils';

import { getTpAdditionalDetails } from '../TpAdditionalDetails/TpAdditionalDetails.utils';
import ProgramProgressStatus from './ProgramProgressStatus';
import { IProgramAccordionStatus, processSimilarityObj } from './ProgramProgress.utils';

const ProgramProgress = (): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [programProgressStatus, setProgramProgressStatus] = useState<IProgramAccordionStatus[]>([]);

  const { id: programId } = useParams();
  const id = decryptWithDecode(programId || '');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [programDetails, setProgramDetails] = useState<TFetchProgramDetailsResponse>();
  const [isDetailViewOpen, setIsDetailViewOpen] = useState(false);
  const [isTspRequestReleaseOpen, setIsTspRequestReleaseOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const activeFollowedPrograms = useSelector(selectActiveFollowedPrograms);
  const programCart = useAppSelector(selectSelectedProgramCart);
  const tspEnrollment = useSelector(selectTspEnrollment);
  const additionalCommunityColleges = useSelector(selectCommunityColleges);
  const academicHistoryData = useSelector(selectAcademicHistory);
  const gpaCalculation = useSelector(gpaCalculationSelector);
  const { additionalDetails } = useSelector(selectAdditionalDetails);
  const tspEligibleFlags = useAppSelector(selectTspEligibleFlags);

  const adtInformation = useSelector(selectAdtInformation);
  const currentFollowedProgram = useMemo(
    () => (activeFollowedPrograms || [])?.find(({ program }) => `${program.id}` === id),
    [activeFollowedPrograms, id]
  );

  const currentFollowedProgramIndex = useMemo(
    () => (activeFollowedPrograms || [])?.findIndex(({ program }) => `${program.id}` === id),
    [activeFollowedPrograms, id]
  );
  const enrollmentData = useMemo(
    () => (tspEnrollment || []).find(enrollment => `${enrollment?.program?.id}` === id),
    [tspEnrollment, id]
  );
  const isProgramDeleted = useMemo(
    () => (tspEligibleFlags || []).some(enrollment => enrollment?.id === currentFollowedProgram?.program?.id),
    [currentFollowedProgram, tspEligibleFlags]
  );

  const programTooltipMsg = programDetails ? '' : t('programProgress.programDetails_tooltip');
  const { RenderTooltip } = useTooltip(programTooltipMsg, programTooltipMsg);

  const adtSimilar = currentFollowedProgram?.program?.adtSimilar;
  const isEnrolled = allowedEnrollmentStatuses.includes(enrollmentData?.enrollmentStatus?.status?.code || '');

  useEffect(() => {
    if (!additionalDetails) dispatch(getTpAdditionalDetails());
  }, [dispatch, additionalDetails]);

  useEffect(() => {
    setTitle(t('program_progress_title'));
  }, [t]);

  useEffect(() => {
    if (academicHistoryData && !gpaCalculation) {
      dispatch(getGpaCalculation());
    }
  }, [dispatch, academicHistoryData, gpaCalculation]);

  useEffect(() => {
    if (!tspEnrollment) dispatch(getEnrollment());
  }, [dispatch, tspEnrollment]);

  useEffect(() => {
    if (!academicHistoryData) dispatch(getAcademicHistory());
  }, [dispatch, academicHistoryData]);

  useEffect(() => {
    if (currentFollowedProgram && adtInformation) {
      const currentProgramProgressStatus = processSimilarityObj(
        currentFollowedProgram.program?.adtSimilarity as IAdtDetails[],
        adtInformation
      );
      setProgramProgressStatus(currentProgramProgressStatus);
    }
  }, [currentFollowedProgram, adtInformation]);

  useEffect(() => {
    if (currentFollowedProgram) {
      dispatch(getTspEligibleFlags(currentFollowedProgram?.program?.id.toString()));
    }
  }, [currentFollowedProgram, dispatch]);

  const explorePrograms = () => {
    navigate(`/${RESEARCH_CAMPUS_AND_PROGRAMS}`, { state: { type: EViewType.program } });
    window.scrollTo(0, 0);
  };

  const handleReleaseFromTsp = () => {
    setIsTspRequestReleaseOpen(true);
  };

  const onMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const successCallback = () => {
    setIsLoading(false);
    dispatch(updateUnFollowedProgramIndex(currentFollowedProgramIndex));
  };

  const loadProgramDetails = async (selectedProgram: ISelectedProgram) => {
    setIsLoading(true);
    try {
      const programDetailsResponse = await fetchProgramDetails({
        campusesId: selectedProgram.campus.id,
        programId: selectedProgram.program.id,
      });

      setProgramDetails(programDetailsResponse);
      setIsDetailViewOpen(true);
      setIsLoading(false);
    } catch {
      /* istanbul ignore next */
      setIsDetailViewOpen(false);
    }
  };

  return (
    <>
      {isLoading && <Spinner backdrop />}
      <GpaCalculationErrorAlert />
      {currentFollowedProgram ? (
        <Box>
          {!isProgramDeleted && (
            <Alert
              icon
              type="warning"
              sx={{ mb: '1rem' }}
              size="default"
              title={t('programProgress.programDetails_alert')}
            />
          )}
          <Grid container alignItems="center" justifyContent="space-between" spacing={1} sx={{ mb: '1rem' }}>
            <Grid item sm={8} sx={{ width: !isMobile ? 'inherit' : '80%' }}>
              <Typography
                variant="h1"
                sx={
                  isMobile
                    ? {
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxHeight: '3.6rem',
                      }
                    : { fontWeight: 700 }
                }
              >
                {currentFollowedProgram?.program?.name}
              </Typography>
            </Grid>
            <Grid item sm={4}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                {!isProgramDeleted && RenderTooltip()}
                {!isMobile ? (
                  <Button
                    variant="ghost"
                    onClick={() => loadProgramDetails(currentFollowedProgram)}
                    disabled={!isProgramDeleted}
                    sx={{ ml: '.3rem' }}
                  >
                    {t('programProgress.programDetails_btn')}
                  </Button>
                ) : (
                  <IconButton
                    onClick={() => loadProgramDetails(currentFollowedProgram)}
                    sx={{ cursor: 'pointer', pt: '.3rem', ml: '.3rem' }}
                    aria-label={t('programProgress.programDetails_btn')}
                    data-testid="btnPrgDetails"
                    size="small"
                    disabled={!isProgramDeleted}
                  >
                    <IconListCheck />
                  </IconButton>
                )}
                <IconButton onClick={onMenuClick} aria-label="menu" size="small">
                  <IconEllipsisVertical />
                </IconButton>
                <MenuActions
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  onClose={() => {
                    /* istanbul ignore next */
                    setAnchorEl(null);
                  }}
                  menuItems={[
                    {
                      icon: <IconStar data-testid="unfollow" />,
                      text: 'Unfollow',
                      disabled: isEnrolled,
                      onClick: () => {
                        setIsLoading(true);
                        dispatch(
                          postUnFollowProgram(currentFollowedProgram, () => {
                            successCallback();
                          })
                        );
                        setAnchorEl(null);
                      },
                    },
                    {
                      icon: <IconTrash data-testid="remove" />,
                      text: 'Remove',
                      disabled: isEnrolled,
                      onClick: () => {
                        setIsLoading(true);
                        const selectedPayload: ISelectedProgram[] =
                          programCart?.filter(e => e.program.id !== currentFollowedProgram.program.id) || [];
                        dispatch(
                          postProgramCart(selectedPayload, () => {
                            successCallback();
                          })
                        );
                        setAnchorEl(null);
                      },
                    },
                  ]}
                />
              </Box>
            </Grid>
            <Grid item sm={8}>
              <Typography variant="body1">{currentFollowedProgram?.campus?.name}</Typography>
            </Grid>
          </Grid>
          <Box>
            {typeof adtSimilar === 'boolean' && programProgressStatus && (
              <ProgramProgressStatus currentProgramStatus={programProgressStatus} adtInformation={adtInformation} />
            )}
          </Box>

          <Grid container sx={{ mt: '1rem', mb: '1rem' }}>
            <Grid item sm={12}>
              <Typography component="div" variant="subtitle3" sx={{ mb: '1rem' }}>
                {adtSimilar ? t('programProgress.adtCourseworkTitle') : t('programProgress.title')}
              </Typography>
              <Typography component="span" variant="body2" sx={{ pt: '1rem' }}>
                {adtSimilar ? t('programProgress.adtInstructionalText') : t('programProgress.instructionalText')}
                {t('programProgress.instructionalText2')}
              </Typography>
            </Grid>
          </Grid>
          {adtSimilar && programProgressStatus && (
            <>
              {programProgressStatus?.map(adtItem => {
                return (
                  adtItem.isSimilar && (
                    <Box sx={{ mb: '0.7rem' }} key={`${adtItem?.programName}-${currentFollowedProgram.program.name}`}>
                      <AgreementsCard
                        campusName={currentFollowedProgram.program.name}
                        campusAddress={currentFollowedProgram.campus.name}
                        communityCollegeName={adtItem?.programName}
                        communityCollegeAddress={adtItem?.collegeName}
                        artifactUrl="https://help.liaisonedu.com/CSU_Transfer_Planner_Student_Help_Center/Navigating_CSU_Transfer_Planner/ADT_Agreements"
                        isADTCard={true}
                      />
                    </Box>
                  )
                );
              })}
              <StyledDivider />
            </>
          )}
          {enrollmentData && <EnrollmentDetails enrollmentData={enrollmentData} />}
          <AdditionalAgreements
            followedProgram={currentFollowedProgram}
            additionalCommunityColleges={additionalCommunityColleges}
            isEnrolled={isEnrolled}
          />
          {enrollmentData && isEnrolled && (
            <>
              <Grid item xs={12}>
                <StyledDivider />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  color="error"
                  sx={{ borderRadius: 24 }}
                  fullWidth
                  variant="outlined"
                  onClick={handleReleaseFromTsp}
                >
                  {t('enrollmentDetails.releaseFromTsp')}
                </Button>
              </Grid>
            </>
          )}
        </Box>
      ) : (
        <>
          <EmptySlate
            imageSource={noFollowedProgram}
            title={t('programProgress.noFollowedProgram')}
            buttonText={t('programs.explore_programs')}
            onClick={explorePrograms}
          />
        </>
      )}
      {programDetails && (
        <ProgramView programDetails={programDetails} isOpen={isDetailViewOpen} setIsOpen={setIsDetailViewOpen} />
      )}
      {isTspRequestReleaseOpen && <TspRequestRelease setIsOpen={setIsTspRequestReleaseOpen} />}
      <AddCollege />
      <Cart />
    </>
  );
};

export default memo(ProgramProgress);
