/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import {
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  InputBase,
  FormHelperText,
  IconButton,
  Card,
  CardActions,
  Divider,
  CardActionArea,
  Switch,
} from '@mui/material';
import {
  DateField,
  Dropdown,
  IconRemoveCircle,
  IconCaretRight,
  IconSchool,
  type TDropdownOption,
} from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import Pluralize from 'pluralize';
import NumberFormat from 'react-number-format';
import { TAcademicHistoryField, IHighSchool } from 'store/academicHistory/academicHistory.slice';
import { HighSchoolsCard } from 'userProfile/components/ViewBuilder/Cards/HighSchoolsCard';
import { useFormContext, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
  DISABLE_AUTO_FILL,
  DISABLE_AUTO_FILL_AUTOCOMPLETE,
  FIELD_LENGTH_250,
  DISABLE_ADDRESS_FILLING,
  MAX_MEDIUM_LENGTH_FIELD,
} from 'constants/field';
import { StyledDeleteButton, StyledAddButton } from 'pages/Pages.styles';
import { RadioControl } from 'components/RadioControl';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { SearchableAutoComplete } from 'components/SearchableAutoComplete';
import MasterData from 'userProfile/constants/master';
import { getCheckboxColor, getDropDownOptions, getOptionDisabled, type FieldError, isCsuTenant } from 'utils/utilities';
import { getAcademicHistoryLookUps, getLookUps } from 'utils/commonUtils';
import { selectHighSchoolsLookup, selectCountriesLookup, selectStatesLookup } from 'store/common/commonInfo.selectors';
import { IhighSchool, resetlookupData } from 'store/common/commonInfo.slice';
import HignSchoolCourses from 'userProfile/components/ViewBuilder/Cards/HignSchoolCourses';
import { getSchoolOptions, modifiedSchoolListing } from 'utils/AcademicHistory.utils';
import { useOptionsScroll } from 'hooks/useOptionsScroll';
import { AttachedFiles } from 'userProfile/components/AttachedFiles';
import { SkillsMultiselect } from 'userProfile/components/SkillsMutliselect';

interface IHighSchoolProps {
  data: TAcademicHistoryField | null;
  openChildDrawer: (
    isAddNew: boolean,
    childPosition: number,
    parentSelector: string,
    formTitle: string,
    showCourse?: boolean
  ) => void;
  isOpenChildDrawer?: boolean;
  childPosition?: number;
  deleteSchool?: ((childPosition: number, parent: string, tag?: string) => void) | null;
  formTitle?: string;
  openGrandChildDrawer?: (
    isAddNew: boolean,
    childPosition: number,
    parentSelector: string,
    formTitle: string,
    parentPosition?: number,
    sectionPrefix?: string
  ) => void;
  showCourses: boolean;
  closeChildDrawer?: () => void;
}

interface ErrorStructure {
  name: FieldError;
  country: {
    code: FieldError;
  };
  address: {
    country: {
      code: FieldError;
    };
    region: {
      code: FieldError;
    };
    city: FieldError;
  };
  startDate: FieldError;
  endDate: FieldError;
  graduated: FieldError;
  graduationDate: FieldError;
  gpa: FieldError;
  termType: {
    code: FieldError;
  };
}

type TErrorsType = {
  [prefix: string]: {
    country: { code: FieldError };
    [key: number]: ErrorStructure;
  };
};

const groupName = 'highSchools';

const schoolTermTypeOptions = getDropDownOptions(MasterData?.highSchoolTermType);

const HighSchoolForm = ({
  data,
  openChildDrawer,
  isOpenChildDrawer,
  childPosition = 0,
  deleteSchool,
  formTitle,
  openGrandChildDrawer,
  showCourses,
}: IHighSchoolProps): ReactElement => {
  const { t } = useTranslation();
  const formData = data?.[groupName]?.[childPosition];

  const courseLength = formData?.gradeLevels?.reduce(
    (accumulator, current) => accumulator + (current?.courses?.length as number),
    0
  );
  const schoolCardSize = deleteSchool ? 12 : 11;
  const [showAddressField, setShowAddressField] = useState(false);
  const [showAddress, setShowAddress] = useState(!!formData?.address || false);
  const [firstLoading, setFirstLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedAddress, setSelectedAddress] = useState(formData?.address);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const highSchoolsLookup = useSelector(selectHighSchoolsLookup);
  const countriesLookup = useSelector(selectCountriesLookup);
  const statesLookup = useSelector(selectStatesLookup);
  const addressPrefix = `${groupName}.${childPosition}.address`;

  const {
    control,
    reset,
    register,
    formState: { errors: formErrors, isValid, dirtyFields },
    watch,
    getValues,
    setValue,
    getFieldState,
    trigger,
  } = useFormContext();

  const errors = formErrors as unknown as TErrorsType;

  const { filteredOptions, setFilteredOptions, showApiLoading, showAdd, setShowAdd, handleScroll, handleSearch } =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useOptionsScroll({
      lookup: highSchoolsLookup as (IhighSchool & { id: string; text: string })[],
      getOptions: getSchoolOptions as <T>(options: T[]) => T[],
      getValues,
      dispatch,
      getLookUps: getAcademicHistoryLookUps,
      entity: 'highSchool',
      allowManualAdd: true,
    });

  const radioDefaultOptions = useMemo(() => MasterData?.radioDefaultOptions, []);
  const watchCountry = watch(`${addressPrefix}.country.code`);
  const governingDistrictOptions = useMemo(() => getDropDownOptions(statesLookup || []), [statesLookup]);

  const hasGraduated = getValues(`highSchools[${childPosition}].graduated`);

  useEffect(() => {
    return () => {
      dispatch(resetlookupData(['highSchool']));
    };
  }, [dispatch]);

  useEffect(() => {
    if (data && !showCourses) {
      setSelectedOptions(data?.[groupName]?.[childPosition] || {});
      setFirstLoading(true);
      reset(data);
    }
    return () => reset({});
  }, [reset, data, childPosition, showCourses]);

  useEffect(() => {
    if (selectedOptions) {
      if ((selectedOptions as IHighSchool)?.type) {
        delete (selectedOptions as IHighSchool)?.type;
      }
      if (!firstLoading) {
        setValue(`highSchools.[${childPosition}]`, selectedOptions);
      } else {
        setFirstLoading(false);
      }
    }
  }, [selectedOptions, setValue, childPosition, firstLoading]);

  const abortStateController = useRef(null) as unknown as { current: AbortController };
  /* istanbul ignore next */
  const getStateList = useCallback(
    (countryId: string | undefined) => {
      /* istanbul ignore next */
      abortStateController?.current?.abort();
      abortStateController.current = new AbortController();
      const controllerSignal = { signal: abortStateController.current.signal };
      dispatch(getLookUps('states', `?countryCode=${countryId}`, '', controllerSignal));
    },
    [dispatch, abortStateController]
  );

  useEffect(() => {
    if (getFieldState(`${addressPrefix}.country`).isDirty || getFieldState(`${addressPrefix}.country`).isTouched) {
      /* istanbul ignore next */
      dispatch(resetlookupData(['states', 'counties']));
      if (watchCountry) {
        getStateList(watchCountry);
      }
    }
  }, [watchCountry, addressPrefix, dispatch, getFieldState, getStateList, dirtyFields]);

  /* istanbul ignore next */
  const deleteAddressCard = () => {
    setShowAddress(false);
    reset({
      highSchools: [],
      country: { code: 'US' },
    });
    dispatch(resetlookupData(['highSchool']));
    setShowAdd(false);
  };

  /* istanbul ignore next */
  const onHighSchoolSelection = (option: IHighSchool) => {
    const seletedOption = option;
    delete seletedOption?.id;
    delete seletedOption?.text;
    setSelectedAddress(option?.address);
    setSelectedOptions(seletedOption);
    trigger(`${groupName}.${childPosition}.name`);
    setShowAddress(true);
  };
  const countryOptions = useMemo(() => getDropDownOptions(countriesLookup || []), [countriesLookup]);

  const modifiedOption = (
    renderprops: React.HTMLAttributes<HTMLLIElement>,
    option: IHighSchool | TDropdownOption<string>
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const updatedOption: IHighSchool = option as IHighSchool | TDropdownOption<string>;
    if (option?.id === 'addManually') {
      return (
        <Box
          component="li"
          key={updatedOption?.name}
          {...renderprops}
          sx={{ display: 'flex', justifyContent: 'space-between !important' }}
        >
          <Box>
            <Typography variant="subtitle4">{t('highSchool.cantFindSchool')}</Typography>
          </Box>
          <StyledAddButton sx={{ fontSize: '0.625rem', mt: 1 }} size="small" onClick={() => setShowAddressField(true)}>
            {t('highSchool.addNewSchool')}
          </StyledAddButton>
        </Box>
      );
    }
    return (
      <Grid
        container
        spacing={0}
        component="li"
        key={`${updatedOption?.name || option?.text}-${updatedOption?.ceeb}`}
        {...renderprops}
      >
        <Grid item xs={12}>
          <Typography variant="subtitle4" sx={{ overflowWrap: 'anywhere' }}>
            {`${updatedOption?.name || option?.text}${updatedOption?.ceeb ? ` - ${updatedOption?.ceeb}` : ''}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body3">{modifiedSchoolListing(updatedOption)}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {showCourses && (
        <HignSchoolCourses
          data={formData}
          groupName={groupName}
          childPosition={childPosition}
          openGrandChildDrawer={openGrandChildDrawer}
        />
      )}

      {!showCourses && (
        <>
          {!isOpenChildDrawer ? (
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px' }}
              >
                {t('highSchool.title')}
              </Typography>
              {data && data?.highSchools && (
                <HighSchoolsCard
                  selector="highSchools"
                  responseData={data?.highSchools}
                  smallLayout={12}
                  xtraSmallLayout={12}
                  openChildDrawer={openChildDrawer}
                  openGrandChildDrawer={openGrandChildDrawer}
                />
              )}

              {JSON.stringify(data?.[groupName]) && (
                <InputBase
                  inputProps={{ type: 'hidden' }}
                  {...register(`${groupName}`)}
                  defaultValue={JSON.stringify(data?.[groupName])}
                />
              )}

              <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                <StyledAddButton
                  onClick={() => openChildDrawer(true, data?.highSchools?.length || 0, groupName, formTitle as string)}
                >
                  {t('add_label')}
                </StyledAddButton>
              </Box>
            </Box>
          ) : (
            <Box>
              {!showAddress && !showAddressField && (
                <>
                  <Grid container>
                    <FormControl required fullWidth error={!!errors?.[groupName]?.country?.code}>
                      <InputLabel htmlFor="name">{t('address.country')}</InputLabel>
                      <Controller
                        render={({ field: { ref, onChange, ...field } }) => (
                          <Dropdown
                            id="country"
                            {...field}
                            inputRef={ref}
                            options={countryOptions}
                            onChange={option => {
                              dispatch(resetlookupData(['highSchool']));
                              return onChange(option?.id ?? '');
                            }}
                            inputProps={{
                              'aria-label': t('address.country'),
                              'aria-describedby': 'country-error',
                              ...DISABLE_ADDRESS_FILLING,
                            }}
                          />
                        )}
                        control={control}
                        name="country.code"
                      />
                      <FormHelperText role="alert" id="country-error">
                        {errors?.[groupName]?.country?.code?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <FormControl fullWidth required error={!!errors?.[groupName]?.[childPosition]?.name}>
                    <InputLabel htmlFor="schoolname">{t('highSchool.searchHighSchool')}</InputLabel>
                    <Controller
                      render={({ field: { onChange, name } }) => (
                        <SearchableAutoComplete
                          id="schoolname"
                          options={filteredOptions}
                          onChange={option => {
                            if (option && option?.id !== 'Loading' && option?.id !== 'addManually') {
                              onHighSchoolSelection(option as IHighSchool);
                              return onChange(option?.id ?? null);
                            }
                            return '';
                          }}
                          name={name}
                          getOptionDisabled={(option: TDropdownOption<string>) =>
                            getOptionDisabled('ceeb', data?.[groupName] as never[])(option as unknown as never)
                          }
                          handleSearch={handleSearch}
                          handleScroll={handleScroll}
                          inputProps={{
                            'aria-label': t('highSchool.searchHighSchool'),
                            placeholder: t('highSchool.searchSchoolPlaceHolder'),
                          }}
                          onClose={() => {
                            setShowAdd(false);
                            setFilteredOptions([]);
                            dispatch(resetlookupData(['highSchool']));
                          }}
                          renderOption={modifiedOption}
                          noOptionsText={
                            showAdd ? (
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                flexDirection="column"
                              >
                                {showApiLoading
                                  ? `${t('academicHistory.loading')}...`
                                  : t('highSchool.highSchoolSearchNoResult')}
                                {!showApiLoading && (
                                  <StyledAddButton
                                    tabIndex={0}
                                    sx={{ fontSize: '0.625rem', mt: 1 }}
                                    size="small"
                                    onClick={() => setShowAddressField(true)}
                                  >
                                    {t('highSchool.addNewSchool')}
                                  </StyledAddButton>
                                )}
                              </Box>
                            ) : (
                              t('academicHistory.minimumSearchlabel')
                            )
                          }
                        />
                      )}
                      control={control}
                      name={`${groupName}.${childPosition}.name`}
                    />
                  </FormControl>
                  <Divider sx={{ borderStyle: 'solid', mt: '0.7rem', mb: '1rem' }} flexItem />
                </>
              )}
              {showAddress && selectedAddress && (
                <Card sx={{ mb: 2, border: theme => `1px solid ${theme.palette.grey[300]}` }}>
                  <Box sx={{ p: 2 }}>
                    <Grid container>
                      <Grid item xs={schoolCardSize}>
                        <Box sx={{ display: 'flex' }}>
                          <Typography gutterBottom variant="subtitle3">
                            {formData?.name || (selectedOptions as IHighSchool)?.name} &#8226;&nbsp;
                          </Typography>
                          <Typography variant="subtitle6" color="text.secondary">
                            {t('academicHistory.ceeb')}: {formData?.ceeb || (selectedOptions as IHighSchool)?.ceeb}
                          </Typography>
                        </Box>
                        <Box sx={{ mt: 0.5 }}>
                          <Typography variant="caption" component="div">
                            {selectedAddress?.city}, {selectedAddress?.region?.code} {selectedAddress?.postalCode}
                          </Typography>
                          <Typography variant="caption">{selectedAddress?.country?.displayName}</Typography>
                        </Box>
                      </Grid>
                      {!deleteSchool && (
                        <Grid item xs={1} sx={{ display: 'flex' }}>
                          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton onClick={deleteAddressCard} edge="start" aria-label="delete Icon" color="error">
                              <IconRemoveCircle />
                            </IconButton>
                          </CardActions>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Card>
              )}
              {showAddressField && (
                <>
                  <FormControl fullWidth required error={!!errors?.[groupName]?.[childPosition]?.name}>
                    <InputLabel htmlFor="name">{t('highSchool.highSchoolName')}</InputLabel>
                    <InputBase
                      inputProps={{
                        'aria-describedby': 'name-error',
                        'aria-label': t('highSchool.highSchoolName'),
                        maxLength: FIELD_LENGTH_250,
                        ...DISABLE_AUTO_FILL,
                      }}
                      {...register(`${groupName}.${childPosition}.name`)}
                    />
                    <FormHelperText role="alert" id="name-error">
                      {errors?.[groupName]?.[childPosition]?.name?.message}
                    </FormHelperText>
                  </FormControl>

                  <Box>
                    <FormControl
                      required
                      fullWidth
                      error={!!errors?.[groupName]?.[childPosition]?.address?.country?.code}
                    >
                      <InputLabel htmlFor="name">{t('address.country')}</InputLabel>
                      <Controller
                        render={({ field: { onChange, ...field } }) => (
                          <Dropdown
                            id="country"
                            {...field}
                            options={countryOptions}
                            onChange={option => {
                              setValue(`${addressPrefix}.country.displayName`, option?.text);
                              return onChange(option?.id ?? '');
                            }}
                            inputProps={{
                              'aria-label': t('address.country'),
                              'aria-describedby': 'country-error',
                              ...DISABLE_ADDRESS_FILLING,
                            }}
                          />
                        )}
                        control={control}
                        name={`${addressPrefix}.country.code`}
                      />
                      <FormHelperText role="alert" id="country-error">
                        {errors?.[groupName]?.[childPosition]?.address?.country?.code?.message}
                      </FormHelperText>
                    </FormControl>
                    <InputBase inputProps={{ type: 'hidden' }} {...register(`${addressPrefix}.country.displayName`)} />
                  </Box>
                  <Grid container spacing={1}>
                    <Grid item xs={6} md={6}>
                      <FormControl
                        fullWidth
                        required
                        error={!!errors?.[groupName]?.[childPosition]?.address?.region?.code}
                      >
                        <InputLabel htmlFor="name">{t('address.region')}</InputLabel>
                        <Controller
                          render={({ field: { onChange, ...field } }) => (
                            <Dropdown
                              id="region"
                              {...field}
                              options={governingDistrictOptions}
                              onChange={option => {
                                setValue(`${addressPrefix}.region.displayName`, option?.text);
                                return onChange(option?.id ?? '');
                              }}
                              inputProps={{
                                'aria-label': t('address.region'),
                                'aria-describedby': 'region-error',
                                ...DISABLE_ADDRESS_FILLING,
                              }}
                            />
                          )}
                          control={control}
                          name={`${addressPrefix}.region.code`}
                        />
                        <FormHelperText role="alert" id="region-error">
                          {errors?.[groupName]?.[childPosition]?.address?.region?.code?.message}
                        </FormHelperText>
                      </FormControl>
                      <InputBase inputProps={{ type: 'hidden' }} {...register(`${addressPrefix}.region.displayName`)} />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <FormControl fullWidth error={!!errors?.[groupName]?.[childPosition]?.address?.city}>
                        <InputLabel htmlFor="name">{t('address.city')}</InputLabel>
                        <InputBase
                          inputProps={{
                            'aria-label': t('address.city'),
                            'aria-describedby': 'city-error',
                            ...DISABLE_ADDRESS_FILLING,
                            maxLength: MAX_MEDIUM_LENGTH_FIELD,
                          }}
                          {...register(`${addressPrefix}.city`)}
                        />
                        <FormHelperText role="alert" id="city-error">
                          {errors?.[groupName]?.[childPosition]?.address?.city?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              )}
              {(showAddress || showAddressField) && (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={9}>
                      <Typography variant="subtitle4">{t('academicHistory.primaryLabel')}</Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Controller
                        control={control}
                        name={`${groupName}.${childPosition}.primary`}
                        defaultValue={false}
                        render={({ field: { onChange, value, ...field } }) => {
                          return (
                            <Switch
                              color={getCheckboxColor()}
                              {...field}
                              checked={value}
                              onChange={(_event, val) => onChange(val)}
                              inputProps={{
                                'aria-label': t('academicHistory.primaryLabel'),
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <Controller
                        name={`${groupName}.${childPosition}.startDate`}
                        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                          <FormControl fullWidth required error={!!errors?.[groupName]?.[childPosition]?.startDate}>
                            <InputLabel htmlFor="startDate">{t('academicHistory.startDate')}</InputLabel>
                            <DateField
                              {...field}
                              ref={ref}
                              error={!!error}
                              id="startDate"
                              hidePart="day"
                              onChange={dateVal => {
                                field.onChange(dateVal);
                                setTimeout(() => {
                                  trigger(`${groupName}.${childPosition}.endDate`);
                                  trigger(`${groupName}.${childPosition}.graduationDate`);
                                }, 0);
                              }}
                              aria-describedby="startDate-error"
                              accessibilityLabelPrefix={t('academicHistory.startDate')}
                            />
                            <FormHelperText role="alert" id="startDate-error">
                              {errors?.[groupName]?.[childPosition]?.startDate?.message}
                            </FormHelperText>
                          </FormControl>
                        )}
                        control={control}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <Controller
                        name={`${groupName}.${childPosition}.endDate`}
                        render={({ field: { ref, ...field }, fieldState: { error } }) => (
                          <FormControl fullWidth error={!!errors?.[groupName]?.[childPosition]?.endDate}>
                            <InputLabel htmlFor="endDate">{t('academicHistory.endDate')}</InputLabel>
                            <DateField
                              {...field}
                              ref={ref}
                              error={!!error}
                              id="endDate"
                              hidePart="day"
                              aria-describedby="endDate-error"
                              accessibilityLabelPrefix={t('academicHistory.endDate')}
                            />
                            <FormHelperText role="alert" id="endDate-error">
                              {errors?.[groupName]?.[childPosition]?.endDate?.message}
                            </FormHelperText>
                          </FormControl>
                        )}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                  <FormControl fullWidth required>
                    <InputLabel htmlFor={`${t('academicHistory.hasGraduated')}?`}>
                      {t('academicHistory.hasGraduated')}?
                    </InputLabel>
                    <Controller
                      name={`${groupName}.${childPosition}.graduated`}
                      render={({ field: { ref, ...field } }) => (
                        <RadioControl
                          {...field}
                          inputRef={ref}
                          id={`${t('academicHistory.hasGraduated')}-${childPosition}`}
                          options={radioDefaultOptions}
                          inline={true}
                          error={!!errors?.[groupName]?.[childPosition]?.graduated?.message}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            trigger(`${groupName}.${childPosition}.graduationDate`);
                            field.onChange(event?.target?.value);
                          }}
                        />
                      )}
                      control={control}
                      defaultValue=""
                    />
                    <FormHelperText role="alert" id="graduated-error">
                      {errors?.[groupName]?.[childPosition]?.graduated?.message}
                    </FormHelperText>
                  </FormControl>
                  <Grid container spacing={2}>
                    <Controller
                      name={`${groupName}.${childPosition}.graduationDate`}
                      render={({ field: { ref, ...field }, fieldState: { error } }) => (
                        <Grid item sm={12}>
                          <FormControl fullWidth error={!!errors?.[groupName]?.[childPosition]?.graduationDate}>
                            <InputLabel htmlFor="graduationDate">
                              {t('academicHistory.expectedOrGraduationDate')}
                            </InputLabel>
                            <DateField
                              {...field}
                              ref={ref}
                              error={!!error}
                              id="graduationDate"
                              {...(hasGraduated === 'Yes' ? { hidePart: 'day' } : {})}
                              aria-describedby="graduationDate-error"
                              accessibilityLabelPrefix={t('academicHistory.expectedOrGraduationDate')}
                            />
                            <FormHelperText role="alert" id="graduationDate-error">
                              {errors?.[groupName]?.[childPosition]?.graduationDate?.message}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      )}
                      control={control}
                    />
                  </Grid>

                  <FormControl fullWidth error={!!errors?.[groupName]?.[childPosition]?.gpa}>
                    <InputLabel htmlFor="name">
                      {t('academicHistory.gpa')} ({t('academicHistory.gpaMaxScore')})
                    </InputLabel>
                    <Controller
                      render={({ field: { onChange, ...field } }) => {
                        return (
                          <NumberFormat
                            {...field}
                            decimalScale={2}
                            decimalSeparator="."
                            allowNegative={false}
                            fixedDecimalScale={false}
                            customInput={InputBase}
                            onValueChange={values => onChange(values?.floatValue)}
                            inputProps={{ 'aria-label': t('academicHistory.gpa') }}
                          />
                        );
                      }}
                      name={`${groupName}.${childPosition}.gpa`}
                      control={control}
                    />

                    <FormHelperText role="alert" id="gpa-error">
                      {errors?.[groupName]?.[childPosition]?.gpa?.message}
                    </FormHelperText>
                  </FormControl>

                  <FormControl fullWidth required error={!!errors?.[groupName]?.[childPosition]?.termType?.code}>
                    <InputLabel htmlFor="termType">{t('academicHistory.termType')}</InputLabel>
                    <Controller
                      render={({ field: { ref, onChange, ...field } }) => (
                        <Dropdown
                          id="termType"
                          {...field}
                          inputRef={ref}
                          options={schoolTermTypeOptions}
                          onChange={option => {
                            setValue(`${groupName}.${childPosition}.termType.displayName`, option?.text);
                            return onChange(option?.id ?? '');
                          }}
                          inputProps={{
                            'aria-label': t('academicHistory.termType'),
                            'aria-describedby': 'termType-error',
                            ...DISABLE_AUTO_FILL_AUTOCOMPLETE,
                          }}
                          disabled={Boolean(courseLength)}
                        />
                      )}
                      control={control}
                      name={`${groupName}.${childPosition}.termType.code`}
                    />
                    <FormHelperText role="alert" id="termType-error">
                      {errors?.[groupName]?.[childPosition]?.termType?.code?.message}
                    </FormHelperText>
                  </FormControl>

                  <Divider sx={{ borderStyle: 'solid', mt: '0.7rem', mb: '2rem' }} flexItem />

                  <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
                    {courseLength && (
                      <Grid item xs={12} sm={12}>
                        <Card variant="outlined">
                          <CardActionArea
                            onClick={() =>
                              openChildDrawer?.(false, childPosition, groupName, t('academicHistory.course'), true)
                            }
                            sx={{
                              p: '1rem',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Grid container alignItems="center">
                              <Grid item xs={1.5} md={1}>
                                <Box id="courseIcon" aria-label={t('academicHistory.courseIcon')} role="img">
                                  <IconSchool sx={{ color: 'primary.light' }} />
                                </Box>
                              </Grid>
                              <Grid item xs={9.5} md={10}>
                                <Typography variant="subtitle2">{t('academicHistory.course')}</Typography>
                                <Box sx={{ mt: 0.5, display: 'flex', alignItems: 'center' }}>
                                  <Typography variant="body3">
                                    {Pluralize(t('academicHistory.course'), courseLength, true)}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={1}>
                                <Box id="courseCount" aria-label={t('academicHistory.courseCount')} role="button">
                                  <IconCaretRight />
                                </Box>
                              </Grid>
                            </Grid>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    )}

                    {!courseLength && (
                      <StyledAddButton
                        sx={{ fontSize: '0.625rem' }}
                        size="small"
                        onClick={() =>
                          openGrandChildDrawer?.(true, courseLength || 0, groupName, '', childPosition, 'gradeLevels')
                        }
                        disabled={!isValid}
                      >
                        {t('academicHistory.addCourse')}
                      </StyledAddButton>
                    )}
                  </Grid>
                  <AttachedFiles sectionName="highSchools" tag={`highSchools/${formData?.id}`} disabled={!isValid} />
                  {!isCsuTenant() && <SkillsMultiselect tag={`highSchools/${formData?.id}`} disabled={!isValid} />}
                </>
              )}
              {deleteSchool && (
                <Box sx={{ mt: '1rem', mb: '1rem', justifyContent: 'center', display: 'flex' }}>
                  <InputBase
                    inputProps={{ type: 'hidden' }}
                    {...register(`${groupName}.${childPosition}.address`)}
                    defaultValue={JSON.stringify(data?.highSchools?.[childPosition]?.address || {})}
                  />
                  <InputBase
                    inputProps={{ type: 'hidden' }}
                    {...register(`${groupName}.${childPosition}.name`)}
                    defaultValue={JSON.stringify(data?.highSchools?.[childPosition]?.name || {})}
                  />
                  <StyledDeleteButton onClick={() => setShowAlert(true)}>
                    {t('highSchool.removeHighSchool')}
                  </StyledDeleteButton>
                </Box>
              )}
              <ConfirmationDialog
                open={showAlert}
                text={t('highSchool.deleteTitle')}
                confirmationText={t('academicHistory.deleteContent')}
                onClose={() => {
                  setShowAlert(false);
                }}
                footerButtonConfig={{
                  primary: {
                    title: t('remove_label'),
                    props: {
                      onClick: () => {
                        setShowAlert(false);
                        deleteSchool?.(childPosition || 0, groupName, `highSchools/${formData?.id}`);
                      },
                    },
                  },
                  tertiary: {
                    title: t('cancel_label'),
                  },
                }}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default HighSchoolForm;
