import { autocompleteClasses, chipClasses, Box, Popper, InputBase, Chip, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAutocompletePopper = styled(Box)(
  ({ theme }) =>
    ({
      [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: '1rem',
      },
      [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: theme.palette.common.white,
        padding: 0,
        maxHeight: 'none',
        [`& .${autocompleteClasses.option}`]: {
          minHeight: 'auto',
          alignItems: 'flex-start',
          padding: '0.625rem',
          '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
          },
          [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
            backgroundColor: theme.palette.text.secondary,
            color: theme.palette.common.white,
          },
        },
      },
      [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
      },
    } as const)
);

export const StyledPopper = styled(Popper)(
  ({ theme }) =>
    ({
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
      borderRadius: '0.5rem',
      zIndex: theme.zIndex.modal,
      backgroundColor: theme.palette.common.white,
    } as const)
);

export const StyledInput = styled(InputBase)(
  ({ theme }) =>
    ({
      width: '100%',
      borderBottom: `1px solid ${theme.palette.grey[500]}`,
      [theme.breakpoints.only('xs')]: {
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
      },
      [theme.breakpoints.up('sm')]: {
        padding: '1rem',
        '& .MuiInputAdornment-positionStart': {
          paddingLeft: '1rem',
        },
        '& .MuiInputAdornment-positionEnd': {
          paddingRight: '1rem',
        },
      },
      '& input': {
        borderRadius: '1rem',
        backgroundColor: theme.palette.common.white,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: `1px solid ${theme.palette.grey[500]}`,
        fontSize: 14,
        '&:focus': {
          boxShadow: '0px 0px 0px 3px rgba(3, 102, 214, 0.3)',
        },
      },
    } as const)
);

export const StyledChip = styled(Chip)(
  ({ theme }) =>
    ({
      marginRight: '0.3rem',
      marginBottom: '0.3rem',
      backgroundColor: `${theme.palette.grey[700]} !important`,
      [`& .${chipClasses.label}`]: {
        color: theme.palette.common.white,
        fontWeight: 600,
        fontSize: '1rem',
      },
      '& .MuiChip-deleteIcon': {
        fontSize: '1.25rem',
        marginRight: '0.5rem',
      },
    } as const)
);
export const listBoxStyle = () =>
  ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mx: '0.2rem',
  } as const);

export const newTagStyle = (isMobile: boolean) =>
  ({
    display: 'flex',
    justifyContent: 'center !important',
    ...(isMobile
      ? {
          border: (theme: Theme) => `1px solid ${theme.palette.primary.dark}`,
          borderRadius: '0.5rem',
        }
      : { borderTop: (theme: Theme) => `1px solid ${theme.palette.divider}` }),
  } as const);

export const tagContainer = () =>
  ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  } as const);

export const ancherBtnMode = () =>
  ({
    flex: 0.1,
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    my: '0.3rem',
  } as const);
