/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { nameSpace } from 'transferPlanner/constants/general';

export const Confirmation = (): ReactElement => {
  const { t } = useTranslation(nameSpace);

  return (
    <>
      <Typography
        id="tspRequestRelease"
        variant="subtitle4"
        component="div"
        sx={{
          mt: '1rem',
        }}
      >
        {t('tspRequestRelease.confirmation1')}
      </Typography>
      <Typography
        variant="subtitle4"
        component="div"
        sx={{
          mt: '2rem',
        }}
      >
        {t('tspRequestRelease.confirmation2')}
      </Typography>
    </>
  );
};
