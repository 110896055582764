/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';

import { TRootState } from 'redux/store';
import { ICommonInfoState } from './commonInfo.slice';

const commonInfo = (state: TRootState): ICommonInfoState => state.commonInfo;

export const selectIndustryOfInterestLookup = createSelector(
  commonInfo,
  (stateData: ICommonInfoState) => stateData?.industries
);

export const selectFieldsOfStudyLookup = createSelector(commonInfo, (stateData: ICommonInfoState) => stateData?.fields);
export const selectAreasOfStudyLookup = createSelector(commonInfo, (stateData: ICommonInfoState) => stateData?.areas);

export const selectLoading = createSelector(commonInfo, (stateData: ICommonInfoState) => stateData?.loading);

export const selectOccupationLookup = createSelector(
  commonInfo,
  (stateData: ICommonInfoState) => stateData?.occupation
);

export const selectVisaTypesLookup = createSelector(commonInfo, (stateData: ICommonInfoState) => stateData?.visaTypes);

export const selectExperienceTypesLookup = createSelector(
  commonInfo,
  (stateData: ICommonInfoState) => stateData?.experiences
);

export const selectAchievementEnumTypes = createSelector(
  commonInfo,
  (stateData: ICommonInfoState) => stateData?.achievements?.AchievementType
);

export const selectCountriesLookup = createSelector(commonInfo, (stateData: ICommonInfoState) => stateData?.countries);

export const selectStatesLookup = createSelector(commonInfo, (stateData: ICommonInfoState) => stateData?.states);

export const selectCountiesLookup = createSelector(commonInfo, (stateData: ICommonInfoState) => stateData?.counties);

export const selectHighSchoolsLookup = createSelector(
  commonInfo,
  (stateData: ICommonInfoState) => stateData?.highSchool
);

export const selectSchoolsOfInterestsLookup = createSelector(
  commonInfo,
  (stateData: ICommonInfoState) => stateData?.schoolsOfInterest
);

export const selectCollegesLookup = createSelector(commonInfo, (stateData: ICommonInfoState) => stateData?.college);

export const selectResidencyStatesLookup = createSelector(
  commonInfo,
  (stateData: ICommonInfoState) => stateData?.residency_states
);

export const selectResidencyCountiesLookup = createSelector(
  commonInfo,
  (stateData: ICommonInfoState) => stateData?.residency_counties
);

export const selectLanguagesLookup = createSelector(commonInfo, (stateData: ICommonInfoState) => stateData?.languages);

export const selectSkillsLookup = createSelector(commonInfo, (stateData: ICommonInfoState) => stateData?.skills);

export const selectcollegeDegreeTypes = createSelector(
  commonInfo,
  (stateData: ICommonInfoState) => stateData?.collegeDegreeTypes
);

export const selectcollegeMajorTypes = createSelector(
  commonInfo,
  (stateData: ICommonInfoState) => stateData?.collegeMajorTypes
);

export const selectSubjects = createSelector(commonInfo, (stateData: ICommonInfoState) => stateData?.subjects);
export const selectRacesLookup = createSelector(commonInfo, (stateData: ICommonInfoState) => stateData?.races);
export const selectEthnicityLookup = createSelector(commonInfo, (stateData: ICommonInfoState) => stateData?.ethnicity);

export const selectTenantConfig = createSelector(commonInfo, (stateData: ICommonInfoState) => stateData?.tenantConfig);

export const selectAlternateIDLinkingData = createSelector(
  commonInfo,
  (stateData: ICommonInfoState) => stateData?.alternateIdLinking
);

export const selectTermsData = createSelector(commonInfo, (stateData: ICommonInfoState) => stateData?.terms);
export const selectObData = createSelector(commonInfo, (stateData: ICommonInfoState) => stateData?.obData);
export const selectIsFirstLogin = createSelector(commonInfo, (stateData: ICommonInfoState) => stateData?.isFirstLogin);

export const selectGeneralEducationRequirements = createSelector(
  commonInfo,
  (stateData: ICommonInfoState) => stateData?.generalEducationRequirements
);

export const selectCollegeCourses = createSelector(
  commonInfo,
  (stateData: ICommonInfoState) => stateData?.collegeCourses
);

export const selectIsLoading = createSelector(commonInfo, (stateData: ICommonInfoState) => stateData?.loading);
