/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import * as yup from 'yup';
import moment from 'moment';
import { t } from 'i18next';

import { DOB_FORMAT } from 'constants/general';
import {
  AP_ID_REG,
  TOEFL_ID_REG,
  IELTS_ID_REG,
  TRF_NUMBER_REG,
  GRE_ID_REG,
  GMAT_ID_REG,
  ACT_ID_REG,
  MCAT_ID_REG,
  SAT_ID_REG,
} from 'constants/regex';

import { getLoggedUser } from 'utils/keyCloakUtils';
import { min, max, today, sanitizePayload, unEscapeRegEx } from 'utils/utilities';
import type { TStandardTests } from 'store/standardTests/standardTests.slice';
import {
  IELTSConfigMock,
  GREConfigMock,
  GRESubjectConfigMock,
  GMATConfigMock,
  ACTConfigMock,
  MCATConfigMock,
  PTEConfigMock,
  LSATConfigMock,
  SATConfigMock,
  APConfigMock,
  CLEPConfigMock,
  IBConfigMock,
} from 'mocks/standardTestsMocks';

export type TOption = { id: string | number; text: string };
export type TRadioOptions = { val: string; text: string }[];

export const groupName = 'tests';
export const apExamGroupName = 'apExam';
export const clepExamGroupName = 'clepExam';
export const ibExamGroupName = 'ibExam';
export const toeflExamGroupName = 'toeflExam';
export const greSubjectExamGroupName = 'greSubjectExam';

export const COMMON_FIELDS = 'COMMON_FIELDS';
export const AP = 'AP';
export const CLEP = 'CLEP';
export const IB = 'IB';
export const TOEFL = 'TOEFL';
export const IELTS = 'IELTS';
export const GRE = 'GRE';
export const GRE_SUBJECT = 'GRE_SUBJECT';
export const GMAT = 'GMAT';
export const ACT = 'ACT';
export const MCAT = 'MCAT';
export const PTE = 'PTE';
export const LSAT = 'LSAT';
export const SAT = 'SAT';

const typeCodeMap: Record<string, { regex: RegExp; errorField: string }> = {
  AP: {
    regex: AP_ID_REG,
    errorField: 'apid',
  },
  TOEFL: {
    regex: TOEFL_ID_REG,
    errorField: 'toeflid',
  },
  IELTS: {
    regex: IELTS_ID_REG,
    errorField: 'candidateNumber',
  },
  GRE: {
    regex: GRE_ID_REG,
    errorField: 'etsId',
  },
  GRE_SUBJECT: {
    regex: GRE_ID_REG,
    errorField: 'etsId',
  },
  GMAT: {
    regex: GMAT_ID_REG,
    errorField: 'gmat',
  },
  ACT: {
    regex: ACT_ID_REG,
    errorField: 'act',
  },
  MCAT: {
    regex: MCAT_ID_REG,
    errorField: 'mcat',
  },
  PTE: {
    regex: ACT_ID_REG,
    errorField: 'pte',
  },
  SAT: {
    regex: SAT_ID_REG,
    errorField: 'sat',
  },
};

const isDateFormatValid = (value: string): boolean => {
  return moment(value, value.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid();
};

export const createNumberOptions = (minNumber = 0, maxNumber = 0, step = 1): { id: number; text: string }[] =>
  Array((maxNumber - minNumber) / step + 1)
    .fill(0)
    .map((_el, i, array) => ({
      id: minNumber + (i !== 0 || i !== array.length - 1 ? i * step : 0),
      text: String(minNumber + (i !== 0 || i !== array.length - 1 ? i * step : 0)),
    }));

export const scoreValidator = (
  value: string | undefined,
  testContext: yup.TestContext
): boolean | yup.ValidationError => {
  type TScoresKeys = keyof TScores;
  const { path, createError, from } = testContext as yup.TestContext & {
    from: {
      value: { validationRules: TScores; type: { code: string }; scores: { [key in TScoresKeys]: string } };
    }[];
  };

  const {
    value: { validationRules },
  } = from[1];

  const scoreName = path.split('.')[1] as TScoresKeys;
  const rule = validationRules?.[scoreName];

  if (rule) {
    const error = {
      message: t(`Please enter a score between ${rule.minimum} and ${rule.maximum} in increments of ${rule.increment}`),
    };

    if (!value) {
      return rule.required ? createError(error) : true;
    }
    /* istanbul ignore next */
    if (
      Number(value) < rule.minimum ||
      Number(value) > rule.maximum ||
      !RegExp(new RegExp(unEscapeRegEx(((rule as TScore).pattern as string) || '^[0-9]+$'))).test(value)
    ) {
      return createError(error);
    }
    const testName = from[1]?.value?.type?.code;
    /* istanbul ignore next */
    if (testName === SAT && scoreName === 'total') {
      const totalValue = from[1].value.scores.total;
      if (totalValue !== undefined) {
        const readAndWriteValue = from[1].value?.scores?.readAndWrite;
        const mathValue = from[1].value?.scores?.math;

        const totalValidationError = {
          message: t((rule as TSATScores['total'])?.errorMessage?.sum),
        };
        if (readAndWriteValue !== '' && mathValue !== '') {
          const sum = Number(readAndWriteValue) + Number(mathValue);
          if (sum !== Number(totalValue)) {
            return createError(totalValidationError);
          }
        }
      }
    }
  }

  return true;
};

export const validationSchema = yup.object().shape({
  type: yup.object().shape({
    code: yup.string().required(),
  }),
  classification: yup.object().when(['type', 'status'], {
    is: (type: { code: string }, status: { code: string }) =>
      type?.code === AP ||
      type?.code === IB ||
      type?.code === CLEP ||
      (type?.code === GRE_SUBJECT && status?.code === 'TAKEN'),
    then: yup.object().shape({
      code: yup.string().required(),
    }),
  }),
  method: yup.object().when(['type', 'status'], {
    is: (type: { code: string }, status: { code: string }) => type?.code === TOEFL && status?.code === 'TAKEN',
    then: yup.object().shape({
      code: yup.string().required(),
    }),
  }),
  status: yup.object().shape({
    code: yup.string().required(),
  }),
  testId: yup.string().test({
    name: 'is correct testId',
    test(val, ctx) {
      const typeCode = ctx?.parent?.type?.code;
      /* istanbul ignore next */
      if (typeCodeMap[typeCode]) {
        if (val && !val.match(RegExp(typeCodeMap[typeCode].regex))) {
          return ctx.createError({
            message: t(`tests.error.${typeCodeMap[typeCode].errorField}`),
          });
        }
      }

      return true;
    },
  }),
  trfNumber: yup.string().test({
    name: 'is correct TRF Number',
    test(val, ctx) {
      const typeCode = ctx?.parent?.type?.code;
      if (typeCode === IELTS) {
        if (val && !val.match(RegExp(TRF_NUMBER_REG))) {
          return ctx.createError({ message: t('tests.error.trfNumber') });
        }
      }
      return true;
    },
  }),
  testDate: yup
    .string()
    .when('status', {
      is: (status: { code: string }) => status?.code === 'TAKEN' || status?.code === 'NOT_RECEIVED',
      then: yup
        .string()
        .test('Is date valid', t('error.invalidDate'), value => {
          if (!value) return false;
          return isDateFormatValid(value);
        })
        .test('Min Date', t('error.minDate'), value => {
          if (value && isDateFormatValid(value)) {
            return new Date(value).getTime() > new Date(new Date(min).setHours(0)).getTime();
          }
          return false;
        })
        .test('Max date', t('error.maxDate'), value => {
          if (value && isDateFormatValid(value)) {
            return new Date(value).getTime() <= today.getTime();
          }
          return false;
        }),
    })
    .when('status', {
      is: (status: { code: string }) => status?.code === 'PLANNED',
      then: yup
        .string()
        .test('Is date valid', t('error.invalidDate'), value => {
          if (!value) return false;
          return isDateFormatValid(value);
        })
        .test('Min Date', t('error.futureDate'), value => {
          if (value && isDateFormatValid(value)) {
            return new Date(value).getTime() > today.getTime();
          }
          return false;
        })
        .test('Max date', t('error.dateRange'), value => {
          if (value && isDateFormatValid(value)) {
            return new Date(value).getTime() < new Date(new Date(max).setHours(0)).getTime();
          }
          return false;
        }),
    }),
  scores: yup
    .object()
    .when(['status', 'type', 'noScore'], {
      is: (status: { code: string }, type: { code: string }, noScore: boolean) =>
        !noScore && status?.code === 'TAKEN' && (type?.code === AP || type?.code === CLEP || type?.code === IB),
      then: yup.object().shape({
        total: yup.string().test('score-value-validator', scoreValidator),
      }),
    })
    .when(['status', 'type'], {
      is: (status: { code: string }, type: { code: string }) =>
        status?.code === 'TAKEN' && (type?.code === TOEFL || type?.code === IELTS),
      then: yup.object().shape({
        reading: yup.string().test('score-value-validator', scoreValidator),
        writing: yup.string().test('score-value-validator', scoreValidator),
        listening: yup.string().test('score-value-validator', scoreValidator),
        structureAndWrittenExpression: yup.string().test('score-value-validator', scoreValidator),
        speaking: yup.string().test('score-value-validator', scoreValidator),
        total: yup.string().test('score-value-validator', scoreValidator),
      }),
    })
    .when(['status', 'type'], {
      is: (status: { code: string }, type: { code: string }) => status?.code === 'TAKEN' && type?.code === GRE,
      then: yup.object().shape({
        quantitative: yup.string().test('score-value-validator', scoreValidator),
        quantitativePercentile: yup.string().test('score-value-validator', scoreValidator),
        speaking: yup.string().test('score-value-validator', scoreValidator),
        speakingPercentile: yup.string().test('score-value-validator', scoreValidator),
        writing: yup.string().test('score-value-validator', scoreValidator),
        writingPercentile: yup.string().test('score-value-validator', scoreValidator),
      }),
    })
    .when(['status', 'type'], {
      is: (status: { code: string }, type: { code: string }) => status?.code === 'TAKEN' && type?.code === GRE_SUBJECT,
      then: yup.object().shape({
        total: yup.string().test('score-value-validator', scoreValidator),
        totalPercentile: yup.string().test('score-value-validator', scoreValidator),
      }),
    })
    .when(['status', 'type'], {
      is: (status: { code: string }, type: { code: string }) => status?.code === 'TAKEN' && type?.code === GMAT,
      then: yup.object().shape({
        integratedReasoning: yup.string().test('score-value-validator', scoreValidator),
        integratedReasoningPercentile: yup.string().test('score-value-validator', scoreValidator),
        quantitative: yup.string().test('score-value-validator', scoreValidator),
        quantitativePercentile: yup.string().test('score-value-validator', scoreValidator),
        speaking: yup.string().test('score-value-validator', scoreValidator),
        speakingPercentile: yup.string().test('score-value-validator', scoreValidator),
        total: yup.string().test('score-value-validator', scoreValidator),
        totalPercentile: yup.string().test('score-value-validator', scoreValidator),
        writing: yup.string().test('score-value-validator', scoreValidator),
        writingPercentile: yup.string().test('score-value-validator', scoreValidator),
      }),
    })
    .when(['status', 'type'], {
      is: (status: { code: string }, type: { code: string }) => status?.code === 'TAKEN' && type?.code === ACT,
      then: yup.object().shape({
        english: yup.string().test('score-value-validator', scoreValidator),
        writing: yup.string().test('score-value-validator', scoreValidator),
        reading: yup.string().test('score-value-validator', scoreValidator),
        math: yup.string().test('score-value-validator', scoreValidator),
        total: yup.string().test('score-value-validator', scoreValidator),
        science: yup.string().test('score-value-validator', scoreValidator),
      }),
    })
    .when(['status', 'type'], {
      is: (status: { code: string }, type: { code: string }) => status?.code === 'TAKEN' && type?.code === MCAT,
      then: yup.object().shape({
        cpbs: yup.string().test('score-value-validator', scoreValidator),
        cars: yup.string().test('score-value-validator', scoreValidator),
        bbfl: yup.string().test('score-value-validator', scoreValidator),
        psbb: yup.string().test('score-value-validator', scoreValidator),
        total: yup.string().test('score-value-validator', scoreValidator),
      }),
    })
    .when(['status', 'type'], {
      is: (status: { code: string }, type: { code: string }) => status?.code === 'TAKEN' && type?.code === PTE,
      then: yup.object().shape({
        listening: yup.string().test('score-value-validator', scoreValidator),
        speaking: yup.string().test('score-value-validator', scoreValidator),
        reading: yup.string().test('score-value-validator', scoreValidator),
        writing: yup.string().test('score-value-validator', scoreValidator),
        total: yup.string().test('score-value-validator', scoreValidator),
        grammar: yup.string().test('score-value-validator', scoreValidator),
        spelling: yup.string().test('score-value-validator', scoreValidator),
        oralFluency: yup.string().test('score-value-validator', scoreValidator),
        vocabulary: yup.string().test('score-value-validator', scoreValidator),
        pronunciation: yup.string().test('score-value-validator', scoreValidator),
        writtenDisclosure: yup.string().test('score-value-validator', scoreValidator),
      }),
    })
    .when(['status', 'type'], {
      is: (status: { code: string }, type: { code: string }) => status?.code === 'TAKEN' && type?.code === LSAT,
      then: yup.object().shape({
        total: yup.string().test('score-value-validator', scoreValidator),
      }),
    })
    .when(['status', 'type'], {
      is: (status: { code: string }, type: { code: string }) => status?.code === 'TAKEN' && type?.code === SAT,
      then: yup.object().shape({
        readAndWrite: yup.string().test('score-value-validator', scoreValidator),
        math: yup.string().test('score-value-validator', scoreValidator),
        reading: yup.string().test('score-value-validator', scoreValidator),
        analysis: yup.string().test('score-value-validator', scoreValidator),
        writing: yup.string().test('score-value-validator', scoreValidator),
        total: yup.string().test('score-value-validator', scoreValidator),
      }),
    }),
});

type ArrayElement<ArrayType extends readonly unknown[] | undefined> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

export const composePayload = (
  data: Partial<ArrayElement<TStandardTests['tests']>> & { noScore?: boolean; validationRules?: TTOEFLScores },
  standardTests: TStandardTests['tests'],
  childPosition: number,
  isAddNew: boolean
): TStandardTests => {
  const newTest = { ...data };
  delete newTest.noScore;
  delete newTest.validationRules;
  if (data.noScore !== undefined && data.noScore) {
    newTest.status = {
      code: 'NOT_RECEIVED',
      displayName: 'Score Not Yet Received',
    };
    delete newTest.scores;
  }
  if (data.status?.code === 'PLANNED') {
    delete newTest.scores;
  }
  const payload = { [groupName]: [...(standardTests || [])] };
  const position = isAddNew ? standardTests?.length || 0 : childPosition;

  payload[groupName][position] = newTest as ArrayElement<TStandardTests['tests']>;

  return {
    ...sanitizePayload(Object.assign(payload)),
    profileId: getLoggedUser(),
  };
};

export type TScoresLabels = {
  reading: string;
  writing: string;
  structureAndWrittenExpression?: string;
  listening: string;
  speaking: string;
  total: string;
};

export type TScore = {
  displayName: string;
  increment: number;
  maximum: number;
  minimum: number;
  required: boolean;
  pattern?: string;
};

export type TTOEFLScores = {
  reading: TScore;
  writing: TScore;
  structureAndWrittenExpression?: TScore;
  listening: TScore;
  speaking?: TScore;
  total: TScore;
};

type TIELTSDependentSchema = typeof IELTSConfigMock.jsonSchema.properties.dependentSchemas[0];

export type TIELTSScores = Required<TIELTSDependentSchema['properties']['scores']['properties']>;

type TGREDependentSchema = typeof GREConfigMock.jsonSchema.properties.dependentSchemas[0];

export type TGREScores = Required<TGREDependentSchema['properties']['scores']['properties']>;

type TGRESubjectDependentSchema = typeof GRESubjectConfigMock.jsonSchema.properties.dependentSchemas[0];

export type TGRESubjectScores = Required<TGRESubjectDependentSchema['properties']['scores']['properties']>;

type TGMATDependentSchema = typeof GMATConfigMock.jsonSchema.properties.dependentSchemas[0];

export type TGMATScores = Required<TGMATDependentSchema['properties']['scores']['properties']>;

type TACTDependentSchema = typeof ACTConfigMock.jsonSchema.properties.dependentSchemas[0];

export type TACTScores = Required<TACTDependentSchema['properties']['scores']['properties']>;

type TMCATDependentSchema = typeof MCATConfigMock.jsonSchema.properties.dependentSchemas[0];

export type TMCATScores = Required<TMCATDependentSchema['properties']['scores']['properties']>;

type PTEDependentSchema = typeof PTEConfigMock.jsonSchema.properties.dependentSchemas[0];

export type TPTEScores = Required<PTEDependentSchema['properties']['scores']['properties']>;

type LSATDependentSchema = typeof LSATConfigMock.jsonSchema.properties.dependentSchemas[0];

export type TLSATScores = Required<LSATDependentSchema['properties']['scores']['properties']>;

type SATDependentSchema = typeof SATConfigMock.jsonSchema.properties.dependentSchemas[0];

export type TSATScores = Required<SATDependentSchema['properties']['scores']['properties']>;

type APDependentSchema = typeof APConfigMock.jsonSchema.properties.dependentSchemas[0];

export type TAPScores = Required<APDependentSchema['properties']['scores']['properties']>;

type CLEPDependentSchema = typeof CLEPConfigMock.jsonSchema.properties.dependentSchemas[0];

export type TCLEPScores = Required<CLEPDependentSchema['properties']['scores']['properties']>;

type IBDependentSchema = typeof IBConfigMock.jsonSchema.properties.dependentSchemas[0];

export type TIBScores = Required<IBDependentSchema['properties']['scores']['properties']>;

type TScores = TTOEFLScores &
  TIELTSScores &
  TGREScores &
  TGRESubjectScores &
  TGMATScores &
  TACTScores &
  TMCATScores &
  TPTEScores &
  TLSATScores &
  TSATScores &
  TAPScores &
  TCLEPScores &
  TIBScores;
