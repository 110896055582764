/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect } from 'react';
import { FormControl, FormHelperText, InputBase, InputLabel } from '@mui/material';
import { Alert } from '@liaison/liaison-ui';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateEmailReset } from 'userProfile/store/auth/auth.slice';
import { selectAuthError, selectLoggedUserData } from 'userProfile/store/auth/auth.selectors';

type TEmailError = {
  email: { message: string };
  confirmEmail: { message: string };
};
const UpdateEmailForm = (): ReactElement => {
  const { t } = useTranslation();
  const authError = useSelector(selectAuthError);
  const dispatch = useDispatch();
  const loggedUserData = useSelector(selectLoggedUserData);
  const socialLinks = loggedUserData?.socialLinks;

  const {
    register,
    trigger,
    getValues,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    return () => {
      dispatch(updateEmailReset());
    };
  }, [dispatch]);

  return (
    <>
      <Alert sx={{ mb: 2 }} type="warning" size="mini">
        {socialLinks?.length ? t('account.socialAccount_warning') : t('account.normalAccount_warning')}
      </Alert>
      {authError && (
        <Alert sx={{ mb: 2 }} type="error" size="mini">
          {authError}
        </Alert>
      )}
      <FormControl fullWidth required error={!!errors?.email}>
        <InputLabel htmlFor="email">{t('account.newEmail')}</InputLabel>

        <InputBase
          placeholder={t('account.newEmail')}
          inputProps={{
            'aria-label': t('account.newEmail'),
            'aria-describedby': 'email-error',
          }}
          {...register('email', {
            onChange: () => {
              if (getValues('confirmEmail') !== '') trigger('confirmEmail');
            },
          })}
          onPaste={e => {
            e.preventDefault();
          }}
          autoComplete="off"
        />
        <FormHelperText role="alert" id="email-error">
          {(errors as unknown as TEmailError)?.email?.message}
        </FormHelperText>
      </FormControl>

      <FormControl fullWidth required error={!!errors?.confirmEmail}>
        <InputLabel htmlFor="confirmEmail">{t('account.confirmEmail')}</InputLabel>
        <InputBase
          placeholder={t('account.confirmEmail')}
          inputProps={{
            'aria-label': t('account.confirmEmail'),
            'aria-describedby': 'confirmEmail-error',
          }}
          {...register('confirmEmail', {
            onChange: () => {
              if (getValues('email') !== '') trigger('email');
            },
          })}
          onPaste={e => {
            e.preventDefault();
          }}
          autoComplete="off"
        />
        <FormHelperText role="alert" id="confirmEmail-error">
          {(errors as unknown as TEmailError)?.confirmEmail?.message}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default UpdateEmailForm;
