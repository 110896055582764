/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  dialogClasses,
  DialogContent,
  Grid,
  Typography,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'transferPlanner/constants/general';
import SaveAndFollowButton from 'transferPlanner/components/SaveAndFollowButton/SaveAndFollowButton';

type TAddProgramModalProps = {
  open: boolean;
  onClose: () => void;
  addProgram: () => void;
  onFollow: () => void;
  isDisabledFollowButton?: boolean;
};

export const AddProgramModal = ({
  open,
  onClose,
  addProgram,
  onFollow,
  isDisabledFollowButton = false,
}: TAddProgramModalProps): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      role="dialog"
      scroll="body"
      aria-labelledby="dialog-modal-title"
      aria-describedby="dialog-modal-title"
      sx={{ [`.${dialogClasses.paper}`]: { borderRadius: '1rem' } }}
      onClick={e => e.stopPropagation()}
    >
      <Box
        sx={{
          display: 'flex',
          py: '0.5rem',
          px: ['1rem', '1.5rem', '2rem'],
          alignItems: 'center',
          borderBottom: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        <DialogTitle
          id="dialog-modal-title"
          sx={{
            px: '0rem',
          }}
        >
          {t('programs.confirmation.addProgramModal.header')}
        </DialogTitle>
      </Box>
      <Grid container component="main">
        <Grid item xs={12} sm={12}>
          <DialogContent
            tabIndex={0}
            id="dialog-modal-title"
            sx={{
              pb: '0rem',
            }}
          >
            <Typography variant="body2" gutterBottom>
              {t('programs.confirmation.addProgramModal')}
            </Typography>
          </DialogContent>
          <Box sx={{ display: isMobile ? 'block' : 'flex', justifyContent: 'space-between', py: '.7rem', px: '1rem' }}>
            <SaveAndFollowButton
              label={t('programs.confirmation.followProgramHeader')}
              description={t('programs.confirmation.followProgramPara')}
              onClick={() => onFollow()}
              sx={isMobile ? { mb: '1rem' } : { mr: '1rem' }}
              disabled={isDisabledFollowButton}
            />
            <SaveAndFollowButton
              label={t('programs.confirmation.saveForLaterHeader')}
              description={t('programs.confirmation.saveForLaterPara')}
              onClick={() => {
                addProgram();
                onClose();
              }}
              isSaveIcon={true}
            />
          </Box>
        </Grid>
      </Grid>
      <DialogActions
        sx={{
          justifyContent: 'center',
          py: '1rem',
          px: ['1rem', '1.5rem', '2.5rem'],
          borderTop: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Button
          variant="text"
          onClick={() => onClose()}
          sx={{
            color: '#0076A8',
          }}
        >
          {t('cancel_label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
