/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { styled } from '@mui/system';

const PREFIX = 'userNav';

export const classes = {
  button: `${PREFIX}-root`,
  buttonActive: `${PREFIX}-buttonActive`,
};

export const CustomButton = styled('button')(({ theme: { palette } }) => ({
  [`&.${classes.button}`]: {
    height: '3rem',
    width: '100%',
    display: 'flex',
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    color: palette.text.primary,
    border: '1px solid #CAD2E1',
    borderRadius: '8px',
    margin: '10px 8px 0 0',
    padding: '10px 14px',
    fontWeight: 'bold',
    listStyle: 'none',
    cursor: 'pointer',
  },
  [`&.${classes.buttonActive}`]: {
    backgroundColor: '#642F6C',
    borderRadius: '8px',
  },
}));
