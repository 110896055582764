/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  MenuItem,
  Grid,
  useMediaQuery,
  Theme,
  avatarClasses,
  menuItemClasses,
  listItemIconClasses,
} from '@mui/material';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { doLogout, getLoggedUserId } from 'utils/keyCloakUtils';
import { loginPageUrl } from 'constants/keyCloak';
import {
  IconSendOut,
  IconShield,
  IconUserProfile,
  ISidebarMenuItem,
  ISidebarMenuItemData,
  MenuActions,
  Sidebar as LeftSideBar,
} from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import { footerMenuIcons, bodyMenuItems, header } from 'constants/sideBarMenuItems';
import { selectSidebar } from 'store/ui/ui.selectors';
import { setUi } from 'store/ui/ui.slice';
import { selectUserName, selectPersonalInformation } from 'userProfile/store/personalInfo/personalInfo.selectors';
import { UserAvatar } from 'userProfile/components/UserAvatar';
import { MY_ACCOUNT, PRIVACY_SECURITY } from 'transferPlanner/constants/routeNames';
import { isCsuTenant } from 'utils/utilities';
import circleNameBg from 'assets/svgs/circle-name-bg.svg';
import { selectTenantConfig } from 'store/common/commonInfo.selectors';
import { useImpersonation } from 'hooks/useImpersonation';
import { ImpersonateLayout } from 'components/ImpersonateLayout';
import { handleExit } from 'utils/commonUtils';
import useDeadlineExtensionBanner from 'hooks/useDeadlineExtensionBanner/useDeadlineExtensionBanner';
import { selectActiveTab } from './MainNavigation.utils';
import { identifyUser } from '../../chameleon-config';

const MainNavigation = (): ReactElement => {
  const tenant = (localStorage.getItem('tenant') ?? 'csu') as keyof typeof bodyMenuItems;
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null | undefined>(null);
  const [bodyMenu, setBodyMenu] = useState<ISidebarMenuItem[]>(bodyMenuItems[tenant]);
  const [footerMenu, setFooterMenu] = useState<ISidebarMenuItem[]>(footerMenuIcons);
  const [previousSelection, setPreviousSelection] = useState('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const personalInfo = useSelector(selectPersonalInformation);
  const { userFirstName, userLastName, userFullName } = useSelector(selectUserName);
  const impersonationToken = useImpersonation();
  const sidebar = useSelector(selectSidebar);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const activePage = pathname.replace(/^\//, '');
  const tenantConfig = useSelector(selectTenantConfig);

  const shouldDisplayTheBanner = useDeadlineExtensionBanner();

  const { footerLinks } = tenantConfig || { footerLinks: undefined };
  const helpCenterLink = useMemo(() => footerLinks?.find(item => item.text === 'Help Center')?.link, [footerLinks]);
  const getChameleonData = useCallback(() => {
    if (personalInfo?.personal?.preferredName) {
      return personalInfo?.personal?.preferredName;
    }
    return personalInfo?.personal?.givenName;
  }, [personalInfo]);

  const footerMenuModified = useMemo(
    () =>
      footerMenuIcons.map(menu => {
        const tempMenu = { ...menu };
        if (menu.label === 'myAccount') {
          tempMenu.label = userFullName;
        }
        return tempMenu;
      }),
    [userFullName]
  );

  const closeSidebar = useCallback(() => {
    dispatch(setUi({ name: 'sidebar', state: { open: false } }));
  }, [dispatch]);

  useEffect(() => {
    const loggedUserId = getLoggedUserId()?.toString();
    if (loggedUserId) {
      identifyUser(loggedUserId, getChameleonData());
    }
  }, [getChameleonData]);

  useEffect(() => {
    if (!isMobile) {
      closeSidebar();
    }
    setAnchorEl(null);
  }, [closeSidebar, isMobile]);

  useEffect(() => {
    setFooterMenu(footerMenuModified);
  }, [footerMenuModified]);

  const updateCurrentMenuItems = useCallback((selectedMenu: string) => {
    setPreviousSelection(selectedMenu);
    setBodyMenu(prevBodyMenu => {
      return prevBodyMenu.map(menu => {
        const tempMenu = { ...menu };
        if (menu.data?.path === selectedMenu) {
          tempMenu.active = true;
        } else {
          tempMenu.active = false;
        }
        return tempMenu;
      });
    });

    setFooterMenu(prevFooterMenu => {
      return prevFooterMenu.map(menu => {
        const tempMenu = { ...menu };
        if (menu.data?.path === selectedMenu) {
          tempMenu.active = true;
          if (menu.data?.path === 'userinfo') {
            tempMenu.isCustom = false;
          }
        } else {
          tempMenu.active = false;
          if (menu.data?.path === 'userinfo') {
            tempMenu.isCustom = true;
          }
        }
        return tempMenu;
      });
    });
  }, []);

  useEffect(() => {
    if (activePage) {
      updateCurrentMenuItems(selectActiveTab(activePage));
    }
  }, [activePage, updateCurrentMenuItems]);

  const handleMenuClose = () => {
    setAnchorEl(null);
    closeSidebar();
    updateCurrentMenuItems('userinfo');
  };

  const handleMenuClick = (menuItem: ISidebarMenuItemData | null, ref: HTMLDivElement | null | undefined) => {
    const currentPath = menuItem?.path;
    if (currentPath) {
      if (currentPath === 'helpcenter') {
        updateCurrentMenuItems(previousSelection);
        window.open(helpCenterLink, '_blank');
      } else if (currentPath === 'userinfo') {
        setAnchorEl(ref);
      } else {
        navigate(currentPath as string);
        window.scrollTo(0, 0);
        closeSidebar();
        updateCurrentMenuItems(currentPath as string);
      }
    }
  };

  const renderProfileInfo = () => {
    return (
      <MenuItem divider>
        <UserAvatar
          firstName={userFirstName}
          lastName={userLastName}
          width="2.5rem"
          height="2.5rem"
          initialsFontSize="1.5rem"
          hideName
        />
        <Box pl={1}>
          <Typography fontWeight="bold" variant="body2">
            {userFullName}
          </Typography>
        </Box>
      </MenuItem>
    );
  };

  return (
    <Grid
      container
      direction="column"
      sx={
        impersonationToken
          ? { '@media (max-width: 599px)': { marginTop: shouldDisplayTheBanner ? '4rem' : '-1rem' } }
          : {}
      }
    >
      <ImpersonateLayout userName={userFullName} handleExit={handleExit} impersonationToken={impersonationToken} />
      {(!isMobile || sidebar.open) && (
        <>
          <LeftSideBar
            disableToggle={true}
            verticallyCenteredMenus={true}
            footerMenuItems={footerMenu}
            onMenuClick={handleMenuClick}
            bodyMenuItems={bodyMenu}
            header={header[tenant]}
            expanded={sidebar.open}
            isMobile={isMobile}
            setOpenSidebar={() => {
              closeSidebar();
            }}
            {...(isCsuTenant() && {
              logo: 'CSU',
              customColors: {
                headerBackground: ((theme: Theme) => theme.palette.common.white) as unknown as string,
                background: ((theme: Theme) => theme.palette.custom.drawer) as unknown as string,
                activeBackground: ((theme: Theme) => theme.palette.common.white) as unknown as string,
                color: ((theme: Theme) => theme.palette.common.white) as unknown as string,
                activeColor: '#2F2F2F',
                textColor: ((theme: Theme) => theme.palette.common.white) as unknown as string,
                accessibilty: {
                  default: '#FFFFFF',
                  selectedMenuFocusOutline: '#000000',
                },
              },
            })}
            {...(isCsuTenant() && !isMobile ? { renderCustomHeader: () => <></> } : {})}
          />
          <MenuActions
            sx={{
              [`.${avatarClasses.root}`]: { backgroundImage: `url(${circleNameBg})`, backgroundColor: 'black' },
              [`& .${menuItemClasses.root}`]: {
                '& p': {
                  maxWidth: '11.56rem',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                },
                '&.Mui-focusVisible': {
                  backgroundColor: 'text.secondary',
                  color: 'common.white',
                  [`& .${listItemIconClasses.root}`]: {
                    color: 'common.white',
                  },
                },
              },
            }}
            role="main"
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null);
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            renderCustomItem={renderProfileInfo}
            menuItems={[
              {
                icon: <IconUserProfile />,
                text: t('account.myAccount'),
                onClick: () => {
                  navigate(`/${MY_ACCOUNT}`);
                  handleMenuClose();
                },
              },
              {
                icon: <IconShield />,
                text: t('privacy.heading'),
                onClick: () => {
                  navigate(`/${PRIVACY_SECURITY}`);
                  handleMenuClose();
                },
              },
              {
                icon: <IconSendOut />,
                text: t('logOut'),
                onClick: () => {
                  doLogout(loginPageUrl);
                },
              },
            ]}
          />
        </>
      )}
      <Outlet />
    </Grid>
  );
};

export default MainNavigation;
