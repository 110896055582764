/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledRootBox = styled(Box)<{
  isimpersonatelayout: string;
  isinviewport: string;
  shoulddisplaythebanner: string;
}>(({ theme: { breakpoints }, isimpersonatelayout, isinviewport, shoulddisplaythebanner }) => {
  const topSize = shoulddisplaythebanner === 'true' && isimpersonatelayout === 'true' ? '1.7rem' : '0rem';
  return {
    [breakpoints.up('md')]: {
      top: topSize,
      zIndex: 11,
      width: '100%',
      position: isinviewport === 'true' ? 'fixed' : 'static',
    },
  };
});
