/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect } from 'react';
import { FormControl, InputLabel, FormHelperText } from '@mui/material';
import { PasswordInput, Alert } from '@liaison/liaison-ui';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FIELD_LENGTH_250 } from 'constants/field';
import { updatePasswordReset } from 'userProfile/store/auth/auth.slice';
import { PasswordValidationCheck } from 'userProfile/components/PasswordValidationCheck';
import { IFailedValidationTypes } from 'userProfile/components/PasswordValidationCheck/PasswordValidationCheck';
import { selectAuthError } from 'userProfile/store/auth/auth.selectors';

const UpdatePasswordForm = (): ReactElement => {
  const { t } = useTranslation();
  const authError = useSelector(selectAuthError);
  const dispatch = useDispatch();

  const {
    register,
    trigger,
    watch,
    getValues,
    formState: { errors, isValid },
  } = useFormContext();

  useEffect(() => {
    return () => {
      dispatch(updatePasswordReset());
    };
  }, [dispatch]);

  const watchNewPassword = watch('newPassword');

  useEffect(() => {
    if (watchNewPassword && !isValid && getValues('confirmNewPassword')) trigger('confirmNewPassword');
  }, [isValid, watchNewPassword, trigger, getValues]);

  return (
    <>
      <Alert sx={{ mb: 2 }} type="warning" size="mini">
        {t('account.changePasswordWarning')}
      </Alert>
      {authError && (
        <Alert sx={{ mb: 2 }} type="error" size="mini">
          {authError}
        </Alert>
      )}
      <FormControl fullWidth required error={!!errors?.currentPassword}>
        <InputLabel htmlFor="currentPassword">{t('account.currentPassword')}</InputLabel>
        <PasswordInput
          id="currentPassword"
          autoComplete="new-password"
          aria-describedby="currentPassword-error"
          maxLength={FIELD_LENGTH_250}
          inputProps={{ 'aria-label': t('account.currentPassword') }}
          {...register('currentPassword')}
        />
        <FormHelperText role="alert" id="currentPassword-error">
          {errors?.currentPassword?.message as string}
        </FormHelperText>
      </FormControl>

      <FormControl required error={!!errors.newPassword} fullWidth sx={{ mb: '1rem' }}>
        <InputLabel htmlFor="newPassword">{t('account.newPassword')}</InputLabel>
        <PasswordInput
          id="newPassword"
          autoComplete="new-password"
          maxLength={FIELD_LENGTH_250}
          inputProps={{ 'aria-label': t('account.newPassword') }}
          {...register('newPassword')}
        />
        <PasswordValidationCheck
          id="passwordValidation"
          isEmpty={!getValues('newPassword')}
          isSubmitted={getValues('newPassword')}
          failedValidationTypes={errors?.newPassword?.types as IFailedValidationTypes}
        />
      </FormControl>

      <FormControl fullWidth required error={!!errors?.confirmNewPassword}>
        <InputLabel htmlFor="confirmNewPassword">{t('account.confirmNewPassword')}</InputLabel>
        <PasswordInput
          id="confirmNewPassword"
          autoComplete="new-password"
          aria-describedby="confirmNewPassword-error"
          maxLength={FIELD_LENGTH_250}
          inputProps={{ 'aria-label': t('account.confirmNewPassword') }}
          {...register('confirmNewPassword')}
        />
        <FormHelperText role="alert" id="confirmNewPassword-error">
          {errors?.confirmNewPassword?.message as string}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default UpdatePasswordForm;
