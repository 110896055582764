/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Divider,
  Stack,
  FormHelperText,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dropdown, TDropdownOption } from '@liaison/liaison-ui';

import { Spinner } from 'components/Spinner';
import { StyledDivider, sxCard } from 'pages/Pages.styles';
import { formatDateToMonthAndYear } from 'utils/commonUtils';
import { nameSpace } from 'transferPlanner/constants/general';
import { AgreementUrlButton } from 'transferPlanner/components/AgreementUrlButton';
import { getProgramAgreement } from 'transferPlanner/pages/CampusesAndPrograms/CampusesAndPrograms.utils';
import { getEnrollStartingYears } from 'transferPlanner/pages/TspEnrollment/TspEnrollment.utils';
import { selectAgreement, selectProgramLoading } from 'transferPlanner/store/programs/programs.selectors';
import { selectFeatures } from 'store/features/features.selectors';
import { isFeatureActive } from 'utils/features.utils';
import { useFormData } from '../ContextForm';

interface IEnrollProps {
  heading: string;
  subHeading: string;
  details: string;
}

const EnrollCard = ({ heading, subHeading, details }: IEnrollProps): ReactElement => {
  return (
    <Card
      variant="outlined"
      sx={{
        mt: 2,
        ...sxCard,
      }}
    >
      <CardContent
        sx={{
          p: 2,
          '&:last-child': {
            pb: 2,
          },
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} sx={{ mr: '0.5rem', display: 'flex' }}>
            <Box>
              <Box>
                <Typography variant="subtitle3" sx={{ overflowWrap: 'anywhere' }}>
                  {heading}
                </Typography>
              </Box>
              <Typography variant="subtitle8">{subHeading}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ borderStyle: 'solid', mt: 1, mb: 1 }} />
        <Stack direction="row" alignItems="center">
          <Typography variant="caption" component="div">
            {details}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

const CompleteEnrollment = (): ReactElement => {
  const [agreementStartDate, setAgreementStartDate] = useState('');
  const [dateModified, setDateModified] = useState(false);
  const [agreementUrl, setAgreementUrl] = useState('');
  const { t } = useTranslation(nameSpace);
  const { data, setFormValues } = useFormData();
  const agreementData = useSelector(selectAgreement);
  const isApiLoading = useSelector(selectProgramLoading);
  const features = useSelector(selectFeatures);
  const isHideJulyAnswersActive = isFeatureActive(features, 'HIDE_JULY_TP_ANSWERS');
  const currEnrollStartingYear = getEnrollStartingYears(isHideJulyAnswersActive);
  const { collegeAddress, formData, ccData, programField } = data;
  const alternateId = formData?.communityCollege?.alternateId?.code || ccData?.alternateId?.code;
  const dispatch = useDispatch();

  useEffect(() => {
    if (programField && alternateId) {
      dispatch(getProgramAgreement(programField, alternateId));
    }
  }, [dispatch, alternateId, programField]);

  useEffect(() => {
    setAgreementUrl(agreementData?.archivedArtifactUrl || '');
  }, [agreementData]);

  const handleAggreementChange = (option: TDropdownOption<string>) => {
    const splitedOptions = option.id.split(' ');

    const transferRequirements = {
      ...formData?.transferRequirements,
      agreementURL: agreementUrl,
    };
    const modifiedPayload = {
      academicYear: Number(splitedOptions[1]),
      termType: {
        code: splitedOptions[0],
        displayName: splitedOptions[0],
      },
    };
    const updatedFormData = {
      ...formData,
      expectedStartingTerm: modifiedPayload,
      transferRequirements,
      active: true,
    };
    setFormValues({
      ...data,
      formData: updatedFormData,
      enabled: true,
    });
  };

  return (
    <Box>
      {isApiLoading && <Spinner backdrop />}
      <Typography sx={{ mb: 1 }} variant="h1">
        {t('completeEnrollment.title')}
      </Typography>
      <Typography variant="body2">{t('completeEnrollment.description')}</Typography>
      <StyledDivider />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle4">{t('enrollmentCompletion.university')}</Typography>
          <EnrollCard
            heading={formData?.campus?.name}
            subHeading={`${collegeAddress?.city} ${collegeAddress?.region}, ${collegeAddress?.postalCode}`}
            details={formData?.program?.name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle4">{t('enrollmentCompletion.communityCollege')}</Typography>
          <EnrollCard
            heading={formData?.communityCollege?.name || ccData?.name}
            subHeading={`${ccData?.address?.city || '-'}, ${ccData?.address?.postalCode || '-'}`}
            details={`${t('college.startDate')}: ${formatDateToMonthAndYear(ccData?.startDate)}`}
          />
        </Grid>
      </Grid>
      <StyledDivider />
      <FormControl required fullWidth error={!agreementStartDate && dateModified}>
        <InputLabel htmlFor="agreementStartDate">{t('enrollmentCompletion.agreementStartTerm')}</InputLabel>
        <Dropdown
          id="agreementStartDate"
          options={currEnrollStartingYear}
          onChange={option => {
            setDateModified(true);
            setAgreementStartDate(option?.id || '');
            if (option?.id) {
              handleAggreementChange(option);
            } else {
              setFormValues({ ...data, enabled: false });
            }
          }}
          value={agreementStartDate}
          name="agreementStartDate"
        />
        <FormHelperText
          sx={{
            color: '#000000 !important',
            whiteSpace: 'normal',
          }}
        >
          {t('enrollmentCompletion.body1')}
        </FormHelperText>
        {!agreementStartDate && dateModified && (
          <FormHelperText role="alert" id="agreementStartDateError">
            {t('agreementStartDate.error.name.required')}
          </FormHelperText>
        )}
      </FormControl>
      <Typography gutterBottom sx={{ mt: 1 }} variant="body2">
        {t('enrollmentCompletion.body2')}
      </Typography>
      <Typography variant="body2">{t('enrollmentCompletion.body3')}</Typography>
      {agreementUrl && (
        <Grid container>
          <AgreementUrlButton agreementUrl={agreementUrl} />
          <Grid item sm={6} />
        </Grid>
      )}
    </Box>
  );
};

export default memo(CompleteEnrollment);
