/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, {
  ReactElement,
  memo,
  useState,
  useEffect,
  ElementType,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  IconButton,
  AppBar,
  useMediaQuery,
  Theme,
  Box,
  Grid,
  Button,
  Dialog,
  dialogClasses,
  DialogContent,
  Typography,
  Avatar,
  SvgIcon,
  Tabs,
  Tab,
  Stack,
} from '@mui/material';
import { IconCheck } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import { getButtonColor, isCsuTenant } from 'utils/utilities';
import { StyledDivider, StyledBoxContainer, StyledBoxFooterContainer, StyledToolBar } from 'pages/Pages.styles';
import completeSetupBg from 'assets/svgs/completeSetupBg.svg';
import { nameSpace } from 'transferPlanner/constants/general';
import { ITpAdditionalDetails } from 'transferPlanner/store/additionalDetails/additionalDetails.slice';
import { Spinner } from 'components/Spinner';

interface IFormDialog {
  disableEscapeKeyDown?: boolean;
  dialogTitle?: string;
  dialogDescription?: string;
  menuTabs: ITab[];
  onClose?: () => void;
  additionalDetails?: ITpAdditionalDetails | null;
  isLoading?: boolean;
}

interface ITab {
  id: number;
  name: string;
  icon: ElementType;
  bgColor?: string;
  disabled?: boolean;
  completed?: boolean;
  nextButton: {
    label: string;
    disabled: boolean;
    onClick: () => void;
  };
  renderComponent: () => ReactElement;
  parent?: number;
  children?: number[];
}

const tabColors = {
  default: '#D4D4D4',
  current: '#2F2F2F',
  completed: '#C8E6C9',
};

export type TFormHandler = {
  goToNextTab: () => void;
};

const FormDialog = forwardRef<TFormHandler, IFormDialog>(
  (
    {
      dialogTitle,
      dialogDescription,
      menuTabs,
      disableEscapeKeyDown = false,
      onClose,
      additionalDetails = null,
      isLoading = false,
    },
    ref
  ): ReactElement => {
    const divRef = useRef<HTMLDivElement>(null);

    const scrollToElement = () => {
      const { current } = divRef;
      if (current !== null) {
        current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    const [tabs, setTabs] = useState(menuTabs);
    const [activeTabIndex, setActiveTab] = useState(0);
    const smallDevices = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const { t } = useTranslation(nameSpace);

    const activeTab = menuTabs.find(tab => tab.id === activeTabIndex);
    const isNextButtonDisabled = menuTabs.find(tab => tab.id === activeTabIndex)?.nextButton.disabled;

    useEffect(() => {
      setTabs(prevTabs =>
        prevTabs?.map(tab => {
          const tempTab = { ...tab };
          if (tab.id === activeTabIndex || additionalDetails) {
            tempTab.bgColor = tabColors.current;
            tempTab.disabled = false;
            tempTab.completed = false;
          } else if (tab.id < activeTabIndex) {
            const isParent = tab.children?.includes(activeTabIndex);
            tempTab.bgColor = isParent ? tabColors.current : tabColors.completed;
            tempTab.icon = isParent ? tempTab.icon : IconCheck;
            tempTab.disabled = false;
            tempTab.completed = !isParent;
          }
          return tempTab;
        })
      );
    }, [activeTabIndex, additionalDetails]);

    const handleNextButtonClick = () => {
      activeTab?.nextButton?.onClick();
    };

    useImperativeHandle(ref, () => ({
      goToNextTab: () => {
        setActiveTab(activeTabIndex + 1);
        scrollToElement();
      },
    }));

    const handlePreviousClick = () => {
      setActiveTab(activeTabIndex - 1);
      scrollToElement();
    };

    if (!activeTab) return <></>;

    return (
      <Dialog
        fullScreen={smallDevices}
        fullWidth
        maxWidth="xl"
        open
        aria-labelledby="dialog-modal-title"
        aria-describedby="dialog-modal-description"
        disableEscapeKeyDown={disableEscapeKeyDown}
        sx={{ [`.${dialogClasses.paper}`]: !smallDevices ? { borderRadius: '1rem' } : {} }}
      >
        {isLoading && <Spinner backdrop />}
        {smallDevices && (
          <AppBar sx={{ position: 'relative', bgcolor: (theme: Theme) => theme.palette.custom.drawer }}>
            <StyledToolBar>
              <IconButton edge="start" color="inherit" aria-label="close">
                <Avatar sx={{ bgcolor: activeTab.bgColor }}>
                  <SvgIcon component={activeTab.icon} inheritViewBox />
                </Avatar>
              </IconButton>
              <Box>
                <Typography variant="h6" component="div">
                  {activeTab.name}
                </Typography>
              </Box>
            </StyledToolBar>
          </AppBar>
        )}
        <DialogContent sx={{ p: 0, height: '100vh' }} id="dialog-modal-title">
          <Grid container wrap="nowrap" sx={{ height: '100%' }}>
            {!smallDevices && (
              <Grid
                item
                xs={3}
                sx={{
                  position: 'relative',
                  background: (theme: Theme) =>
                    isCsuTenant() ? `url(${completeSetupBg})` : theme.palette.primary.background,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  color: (theme: Theme) => theme.palette.secondary.contrastText,
                }}
              >
                <Box sx={{ m: { sm: 3, md: 4 } }}>
                  <Box>
                    <Typography variant="h1" sx={{ fontSize: { sm: '1rem', md: '1.1rem', lg: '1.7rem' } }}>
                      {dialogTitle}
                    </Typography>
                    <Typography
                      id="dialog-modal-description"
                      variant="body1"
                      sx={{ mt: { md: 2, lg: 4 }, fontSize: { sm: '.5rem', md: '.8rem', lg: '1rem' } }}
                    >
                      {dialogDescription}
                    </Typography>
                  </Box>
                  <StyledDivider
                    sx={{ margin: { sm: '.5rem 0 .5rem 0', md: '.5rem 0 .5rem 0', lg: '1.5rem 0 1.5rem 0' } }}
                  />
                  <Box>
                    <Tabs
                      orientation="vertical"
                      value={activeTabIndex}
                      TabIndicatorProps={{ sx: { display: 'none' } }}
                      /* istanbul ignore next */
                      onChange={(_event, newValue) => setActiveTab(newValue)}
                      aria-label="Menu Tabs"
                      sx={{
                        overflow: 'visible',
                        '& button': { color: (theme: Theme) => theme.palette.secondary.contrastText },
                        '& button:hover': { color: (theme: Theme) => theme.palette.secondary.contrastText },
                        '& button:disabled': { color: (theme: Theme) => theme.palette.secondary.contrastText },
                        '& button.Mui-selected': { color: (theme: Theme) => theme.palette.secondary.contrastText },
                        '& .MuiTabs-scroller': { overflow: 'visible !important' },
                        '& .MuiTab-root': { pb: '5px' },
                      }}
                    >
                      {tabs?.map(tab => {
                        const { id, name, disabled, completed, bgColor, icon, parent } = tab;
                        const isChild = parent !== undefined;
                        const isDisabled = disabled === undefined || disabled;
                        const color = completed ? '#45A041' : '#FFFFFF';
                        return (
                          <Tab
                            aria-label={name}
                            key={`${id}${isChild ? '' : '-parent'}`}
                            value={id}
                            disabled={isDisabled && bgColor !== tabColors.current}
                            sx={{
                              pointerEvents: 'auto',
                              ...(isChild ? { px: 0.5 } : { left: '56px', mb: 0, px: 0, overflow: 'visible' }),
                            }}
                            label={
                              <Grid
                                container
                                wrap="nowrap"
                                direction="row"
                                alignItems="center"
                                justifyContent="end"
                                spacing={isChild ? 1 : 2}
                              >
                                <Grid item sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'row' }}>
                                  <Typography
                                    tabIndex={isDisabled && bgColor !== tabColors.current ? -1 : 0}
                                    sx={{
                                      textAlign: 'end',
                                      textTransform: isChild ? 'none' : 'uppercase',
                                      color: isDisabled ? tabColors.default : '#FFFFFF',
                                      fontSize: {
                                        sm: isChild ? '0.5rem' : '0.6rem',
                                        md: isChild ? '0.6rem' : '.7rem',
                                        lg: isChild ? '0.87rem' : '1rem',
                                      },
                                    }}
                                    variant={isChild ? 'body3' : 'subtitle5'}
                                  >
                                    {name}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs="auto"
                                  sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'row' }}
                                >
                                  <Avatar
                                    tabIndex={isDisabled && bgColor !== tabColors.current ? -1 : 0}
                                    role="group"
                                    aria-label={`${completed ? 'section complete' : 'section incomplete'}`}
                                    sx={{
                                      bgcolor: bgColor ?? tabColors.default,
                                      border: completed ? '2px solid #45A041' : '',
                                      ...(isChild ? { width: 24, height: 24 } : { width: 48, height: 48 }),
                                    }}
                                  >
                                    <SvgIcon
                                      component={icon}
                                      inheritViewBox
                                      sx={{ color, fontSize: isChild ? 14 : 32 }}
                                    />
                                  </Avatar>
                                </Grid>
                              </Grid>
                            }
                          />
                        );
                      })}
                    </Tabs>
                  </Box>
                </Box>
              </Grid>
            )}
            <Grid item xs={12} md={9}>
              <StyledBoxContainer>
                <Box sx={{ flex: 1, px: 3, py: 5, overflow: 'auto' }}>
                  <Grid container direction="row" justifyContent="center" ref={divRef}>
                    <Grid item sm={10}>
                      {activeTab.renderComponent()}
                    </Grid>
                  </Grid>
                </Box>
                <StyledBoxFooterContainer sx={{ px: smallDevices ? 2 : 4 }}>
                  {onClose && (
                    <Button onClick={onClose} aria-label={t('cancel')}>
                      {t('cancel')}
                    </Button>
                  )}
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={!onClose && activeTabIndex === 0 ? 'flex-end' : 'space-between'}
                    flex={onClose ? undefined : 1}
                    spacing={smallDevices ? 1 : 2}
                  >
                    {activeTabIndex !== 0 && (
                      <Button
                        variant={onClose ? 'contained' : 'text'}
                        color={onClose ? 'primary' : 'secondary'}
                        onClick={handlePreviousClick}
                        aria-label={t('previous_label')}
                      >
                        {t('previous_label')}
                      </Button>
                    )}
                    <Button
                      disabled={isNextButtonDisabled}
                      variant="contained"
                      color={getButtonColor()}
                      onClick={handleNextButtonClick}
                      aria-label={activeTab.nextButton.label || t('nextStep_label')}
                    >
                      {activeTab.nextButton.label || t('nextStep_label')}
                    </Button>
                  </Stack>
                </StyledBoxFooterContainer>
              </StyledBoxContainer>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
);

export default memo(FormDialog);
