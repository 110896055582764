/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { IProgram, ISelectedProgram } from 'transferPlanner/store/programCart/programCart.slice';
import { useTranslation } from 'react-i18next';
import { csuNameSpace } from 'utils/utilities';
import { useAppSelector } from 'redux/hooks';
import { selectSelectedProgramCart } from 'transferPlanner/store/programCart/programCart.selectors';
import MyProgramCard from './MyProgramCard/MyProgramCard';

interface ISavedPrograms {
  selectedProgramList?: ISelectedProgram[] | null;
  activeEnrolledProgramId: number | undefined;
  handleClick: (name: string, selectedProgram?: ISelectedProgram) => void;
  enrollBtnDisabled: boolean;
  tspEligibleFlags?: IProgram[] | null;
}

const SavedPrograms = ({
  selectedProgramList,
  activeEnrolledProgramId,
  handleClick,
  enrollBtnDisabled,
  tspEligibleFlags,
}: ISavedPrograms): ReactElement => {
  const { t } = useTranslation(csuNameSpace.ns);
  const programCart = useAppSelector(selectSelectedProgramCart);

  return (
    <Box sx={{ py: '1.5rem' }}>
      <Typography component="h2" variant="h6">
        {t('programs.saved_title')}
      </Typography>
      {selectedProgramList && selectedProgramList.length > 0 ? (
        selectedProgramList.map((selectedProgram: ISelectedProgram) => (
          <React.Fragment key={selectedProgram.program.id}>
            <MyProgramCard
              isSavedProgram={true}
              activeEnrolledProgramId={activeEnrolledProgramId}
              handleClick={handleClick}
              selectedProgram={selectedProgram}
              enrollBtnDisabled={enrollBtnDisabled}
              tspEligibleFlags={tspEligibleFlags}
            />
          </React.Fragment>
        ))
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mt: '1rem' }}>
          {programCart?.length ? (
            <Typography variant="body3" sx={{ textAlign: 'center' }}>
              {t('programs.no_saved_but_followed')}
            </Typography>
          ) : (
            <>
              <Typography variant="body3" sx={{ textAlign: 'center' }}>
                {t('programs.no_saved_program')}
              </Typography>
              <Button
                variant="contained"
                sx={{ mt: '1rem' }}
                onClick={() => {
                  handleClick('Explore');
                }}
              >
                {t('programs.explore_programs')}
              </Button>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default memo(SavedPrograms);
