/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect, useState } from 'react';
import { Avatar, Grid, IconButton } from '@mui/material';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'transferPlanner/constants/general';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectScrollLetter } from 'transferPlanner/store/searchProgram/searchProgram.selectors';
import { setNavigationLetter } from 'transferPlanner/store/searchProgram/searchProgram.slice';
import { sxPrimaryButton } from 'pages/Pages.styles';
import { sxBoxWrapper } from './LetterNavigation.styles';

const alphabetArray = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

/* istanbul ignore next */
const setNavigationLetterDebounced = debounce((value, dispatch) => {
  dispatch(setNavigationLetter(value));
}, 750);

export type TLetterNavigationProps = {
  foundLetters?: string[];
};

export const LetterNavigation = ({ foundLetters = [...alphabetArray] }: TLetterNavigationProps): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const [innerLetter, setInnerLetter] = useState('A');
  const scrollLetter = useAppSelector(selectScrollLetter);
  const dispatch = useAppDispatch();

  const onLetterChange = (value: string) => {
    setNavigationLetterDebounced(value, dispatch);
    setInnerLetter(value);
  };

  useEffect(() => {
    if (scrollLetter) {
      setInnerLetter(scrollLetter);
    }
  }, [scrollLetter]);

  return (
    <Grid
      item
      container
      sx={sxBoxWrapper}
      direction={{ xs: 'column', sm: 'row', md: 'row' }}
      justifyContent="space-evenly"
      alignItems="center"
    >
      {alphabetArray.map(letter => {
        const bgcolor = letter === innerLetter ? sxPrimaryButton.bgcolor : '#fff';
        const foundLetterColor = foundLetters.includes(letter) ? '#2F2F2F' : '#D4D4D4';
        const color = letter === innerLetter ? '#fff' : foundLetterColor;
        const isLetterDisabled = !foundLetters.includes(letter) || letter === innerLetter;
        return (
          <IconButton
            key={letter}
            disabled={isLetterDisabled}
            onClick={() => onLetterChange(letter)}
            sx={{ m: 0, p: 0 }}
            aria-label={`${t('campusesAndProgram.programs.alpha.click.text')} ${letter}`}
            id={`letter-navigation-${sxPrimaryButton.bgcolor === bgcolor ? letter : ''}`}
            className={`letter-navigation-${letter}`}
          >
            <Avatar
              sx={{
                color,
                bgcolor,
                fontSize: { xs: '11px', sm: '13px', md: '14px', lg: '16px' },
                fontWeight: 600,
                width: { xs: 19, sm: 19, md: 21, lg: 28, xl: 32 },
                height: { xs: 19, sm: 19, md: 21, lg: 28, xl: 32 },
              }}
            >
              {letter}
            </Avatar>
          </IconButton>
        );
      })}
    </Grid>
  );
};
