/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Snackbar } from '@liaison/liaison-ui';
import { selectApiStatus } from 'store/ui/ui.selectors';
import { setUi } from 'store/ui/ui.slice';

const ApiStatusHandler = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { success, failure, failureMessage, hideTitle } = useAppSelector(selectApiStatus);

  const closeAlert = () => {
    dispatch(
      setUi({
        name: 'apiStatus',
        state: { success: false, failure: false, failureMessage: '', hideTitle: false },
      })
    );
  };

  return (
    <Snackbar
      type={success ? 'success' : 'error'}
      title={!hideTitle ? t(success ? 'success_label' : 'failure_title') : undefined}
      open={failure || success}
      onClose={closeAlert}
    >
      {failureMessage || t(success ? 'success_message' : 'failure_message')}
    </Snackbar>
  );
};

export default ApiStatusHandler;
