/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';
import { IProfileViewState } from './profileView.slice';

const profileView = (state: TRootState): IProfileViewState => state.profileView;

export const selectLoading = createSelector(profileView, (stateData: IProfileViewState) => stateData?.loading);
export const selectError = createSelector(profileView, (stateData: IProfileViewState) => stateData?.error);
export const selectUpdateLinkLoading = createSelector(
  profileView,
  (stateData: IProfileViewState) => stateData?.updateLinkLoading
);

export const selectAllProfileViews = createSelector(
  profileView,
  (stateData: IProfileViewState) => stateData?.profileViews?.views
);

export const selectCurrentProfileViewId = createSelector(
  profileView,
  (stateData: IProfileViewState) => stateData?.currentProfileViewId || localStorage.getItem('currentProfileViewId')
);

export const selectProfileView = createSelector(
  profileView,
  (stateData: IProfileViewState) => stateData?.profileViewData
);

export const selectSavedProfileView = createSelector(
  profileView,
  (stateData: IProfileViewState) => stateData?.profileViewSavedData
);

export const selectPersonalInformation = createSelector(
  selectProfileView,
  profileViewData => profileViewData?.personalInformation?.data
);

export const selectAcademicHistory = createSelector(
  selectProfileView,
  profileViewData => profileViewData?.academicHistory?.schoolAttended.data
);

export const selectExperiences = createSelector(selectProfileView, profileViewData =>
  profileViewData?.experiences?.data.filter(item => !item.hidden)
);

export const selectAchievements = createSelector(selectProfileView, profileViewData =>
  profileViewData?.achievements?.data.filter(item => !item.hidden)
);

export const selectStandardizedTests = createSelector(
  selectProfileView,
  profileViewData => profileViewData?.academicHistory?.standardizedTests?.data
);

export const selectLanguages = createSelector(selectProfileView, profileViewData =>
  profileViewData?.languages?.data?.map(item => item.language.displayName)
);

export const selectCompetencies = createSelector(selectProfileView, profileViewData =>
  profileViewData?.competencies?.data?.map(item => item.competency)
);

export const selectMediaAndDocuments = createSelector(
  selectProfileView,
  profileViewData => profileViewData?.mediaAndDocuments?.data
);

export const selectActiveSharedViewId = createSelector(
  selectProfileView,
  profileViewData => profileViewData?.sharedViews?.find(view => view.active)?.id
);
