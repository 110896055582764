/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { t } from 'i18next';
import { TAppThunk } from 'redux/store';
import axios from 'axios';
import { genEdReqApiStart, genEdReqApiSuccess, genEdReqApiFailure } from 'store/common/commonInfo.slice';
import { API } from 'transferPlanner/constants/api';

export const getGeneralEducationRequirements = (): TAppThunk => async dispatch => {
  try {
    dispatch(genEdReqApiStart());
    const { data } = await axios.get(API.generalEducationRequirements);
    dispatch(genEdReqApiSuccess(data));
  } catch (err) {
    dispatch(genEdReqApiFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  }
};
