import chmln from '@chamaeleonidae/chmln';

const { CHAMELEON_CONFIG_KEY } = window.env;

chmln.init(CHAMELEON_CONFIG_KEY, {
  fastUrl: 'https://fast.chameleon.io/',
});

export function identifyUser(userId: string | undefined, preferredName: string | undefined): void {
  chmln.identify(userId, { name: preferredName });
}
