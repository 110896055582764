/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { sxInfoBox } from './InfoBox.style';

type TInfoBox = {
  isWelcomeModal?: boolean;
  fullWidth?: boolean;
  infoText: string;
  buttonText1?: string;
  buttonText2?: string;
  onClick1?: () => void;
  onClick2?: () => void;
};

const InfoBox = ({
  isWelcomeModal = false,
  fullWidth = false,
  infoText,
  buttonText1,
  buttonText2,
  onClick1,
  onClick2,
}: TInfoBox): ReactElement => {
  const linkStyle = {
    color: isWelcomeModal ? '#642F6C' : '#311B92',
  };

  return (
    <Grid
      xs={!isWelcomeModal && !fullWidth && 5.8}
      item
      container
      justifyContent="space-between"
      sx={theme => sxInfoBox(theme, isWelcomeModal)}
    >
      <Grid item xs={12}>
        <Typography variant="body2" sx={{ fontSize: 14 }}>
          {infoText}
        </Typography>
      </Grid>
      {buttonText1 && (
        <Grid item xs={12}>
          <Button style={linkStyle} onClick={onClick1} sx={{ mb: { sm: 2, md: 0 }, textTransform: 'inherit' }}>
            {buttonText1}
          </Button>
        </Grid>
      )}
      {buttonText2 && (
        <Grid item xs={12}>
          <Button style={linkStyle} onClick={onClick2} sx={{ mb: { sm: 2, md: 0 }, textTransform: 'inherit' }}>
            {buttonText2}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default InfoBox;
