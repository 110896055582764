/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { modifyBooleanFields } from 'userProfile/pages/AcademicGoals/AcademicGoals.utils';
import type { IAddress } from '../personalInfo/personalInfo.slice';

type TError = string | null;

export interface IEntity {
  id?: string;
  code: string;
  displayName: string;
}

export interface TGoalInformation {
  goalInformation: TAcademicGoalsInfoField;
  fields?: IEntity[] | null;
}

export interface ISchoolOfInterest {
  ipedsId: string;
  name: string;
  address?: IAddress;
}

export interface ILocationInterest {
  id?: string;
  country?: {
    code: string;
    displayName: string;
  };
  region?: {
    code: string;
    displayName: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TAcademicGoalsInfoField = { [key: string]: any } & {
  startDate?: string;
  type?: IEntity[] | null;
  degrees?: IEntity[] | null;
  fields?: IEntity[] | null;
  areas?: IEntity[] | null;
  studiedAbroad?: string | null;
  visible?: string | null;
  schools?: ISchoolOfInterest[] | null;
  locationInterests?: ILocationInterest[] | null;
};

export interface IAcademicGoalsInfoState {
  loading: boolean;
  academicGoalsInfoData: TAcademicGoalsInfoField | null;
  academicGoalsInfoLocalData: TAcademicGoalsInfoField | null;
  error: TError;
}

export const initialState: IAcademicGoalsInfoState = {
  loading: false,
  academicGoalsInfoData: null,
  academicGoalsInfoLocalData: null,
  error: null,
};

const academicGoalsInfoSlice = createSlice({
  name: 'academicGoalsInfo',
  initialState,
  reducers: {
    academicGoalsInfoStart: (state): IAcademicGoalsInfoState => ({
      ...state,
      loading: true,
      error: null,
    }),
    academicGoalsInfoSuccess: (state, action: PayloadAction<TAcademicGoalsInfoField>): IAcademicGoalsInfoState => ({
      ...state,
      loading: false,
      academicGoalsInfoData: modifyBooleanFields(action.payload),
    }),
    academicGoalsInfoSaveLocalData: (
      state,
      action: PayloadAction<TAcademicGoalsInfoField>
    ): IAcademicGoalsInfoState => ({
      ...state,
      loading: false,
      academicGoalsInfoLocalData: action.payload,
    }),
    academicGoalsInfoResetLocalData: (state): IAcademicGoalsInfoState => ({
      ...state,
      loading: false,
      academicGoalsInfoLocalData: null,
    }),
    academicGoalsInfoFailure: (state, action: PayloadAction<TError>): IAcademicGoalsInfoState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  academicGoalsInfoStart,
  academicGoalsInfoSuccess,
  academicGoalsInfoFailure,
  academicGoalsInfoSaveLocalData,
  academicGoalsInfoResetLocalData,
} = academicGoalsInfoSlice.actions;

export default academicGoalsInfoSlice.reducer;
