/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  Box,
  InputBase,
  Typography,
  InputLabel,
  FormControl,
  Grid,
  FormHelperText,
  Stack,
  Divider,
} from '@mui/material';
import { DateField } from '@liaison/liaison-ui';
import { RadioControl } from 'components/RadioControl';
import MasterData from 'userProfile/constants/master';
import { DISABLE_AUTO_FILL, MAX_MEDIUM_LENGTH_FIELD, MAX_SMALL_LENGTH_FIELD } from 'constants/field';
import type { TPersonalInfoField } from 'userProfile/store/personalInfo/personalInfo.slice';
import { useTranslation } from 'react-i18next';
import type { TPersonalInfoFormErrors } from '../PersonalInformationForm.validation';

interface IPersonalInfoProps {
  data: TPersonalInfoField | null;
  isProfileSetup?: boolean;
}
const PersonalInformationForm = ({ data, isProfileSetup = false }: IPersonalInfoProps): ReactElement => {
  const { t } = useTranslation();
  const {
    register,
    control,
    setValue,
    watch,
    formState: { errors: formErrors },
  } = useFormContext();
  const errors = formErrors as unknown as TPersonalInfoFormErrors;
  const groupName = 'personal';

  const hasFormerName = watch(`${groupName}.hasFormerName`) === 'Yes';
  const hasPreferredName = watch(`${groupName}.hasPreferredName`) === 'Yes';

  useEffect(() => {
    if (data?.[groupName]) {
      setValue(groupName, data[groupName]);
      setTimeout(() => {
        setValue(
          `${groupName}.hasFormerName`,
          data?.[groupName]?.alternateGivenName || data?.[groupName]?.alternateFamilyName ? 'Yes' : 'No'
        );
        setValue(`${groupName}.hasPreferredName`, data?.[groupName]?.preferredName ? 'Yes' : 'No');
      }, 100);
    }
  }, [setValue, data]);

  return (
    <Box>
      <Stack spacing={2} divider={<Divider orientation="horizontal" flexItem />}>
        <Box>
          <Typography
            variant="h2"
            sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
          >
            {t('personalInfo.legalName')}
          </Typography>
          <FormControl fullWidth required error={!!errors?.[groupName]?.givenName}>
            <InputLabel htmlFor="givenName">{t('personalInfo.givenName')}</InputLabel>
            <InputBase
              placeholder={t('personalInfo.givenName')}
              inputProps={{
                'aria-label': t('personalInfo.givenName'),
                'aria-describedby': 'givenName-error',
                maxLength: MAX_MEDIUM_LENGTH_FIELD,
                ...DISABLE_AUTO_FILL,
              }}
              {...register(`${groupName}.givenName`)}
              fullWidth={true}
            />
            <FormHelperText role="alert" id="givenName-error">
              {errors?.[groupName]?.givenName?.message}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth error={!!errors?.[groupName]?.middleName}>
            <InputLabel htmlFor="middleName">{t('personalInfo.middleName')}</InputLabel>
            <InputBase
              placeholder={t('personalInfo.middleName')}
              inputProps={{
                'aria-label': t('personalInfo.middleName'),
                'aria-describedby': 'middleName-error',
                maxLength: MAX_MEDIUM_LENGTH_FIELD,
                ...DISABLE_AUTO_FILL,
              }}
              {...register(`${groupName}.middleName`)}
            />
            <FormHelperText role="alert" id="middleName-error">
              {errors?.[groupName]?.middleName?.message}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth required error={!!errors?.[groupName]?.familyName}>
            <InputLabel htmlFor="familyName">{t('personalInfo.familyName')}</InputLabel>
            <InputBase
              placeholder={t('personalInfo.familyName')}
              inputProps={{
                'aria-label': t('personalInfo.familyName'),
                'aria-describedby': 'familyName-error',
                maxLength: MAX_MEDIUM_LENGTH_FIELD,
                ...DISABLE_AUTO_FILL,
              }}
              {...register(`${groupName}.familyName`)}
              fullWidth={true}
            />
            <FormHelperText role="alert" id="familyName-error">
              {errors?.[groupName]?.familyName?.message}
            </FormHelperText>
          </FormControl>

          {!isProfileSetup && (
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <FormControl fullWidth error={!!errors?.[groupName]?.salutation}>
                  <InputLabel htmlFor="salutation">{t('personalInfo.salutation')}</InputLabel>
                  <InputBase
                    placeholder={t('personalInfo.salutation')}
                    inputProps={{
                      'aria-label': t('personalInfo.salutation'),
                      'aria-describedby': 'salutation-error',
                      maxLength: MAX_SMALL_LENGTH_FIELD,
                      ...DISABLE_AUTO_FILL,
                    }}
                    {...register(`${groupName}.salutation`)}
                  />
                  <FormHelperText role="alert" id="salutation-error">
                    {errors?.[groupName]?.salutation?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl fullWidth error={!!errors?.[groupName]?.suffix}>
                  <InputLabel htmlFor="suffix">
                    {`${t('personalInfo.suffix')}
                ${t('personalInfo.suffix.example')}`}
                  </InputLabel>
                  <InputBase
                    placeholder={t('personalInfo.suffix')}
                    inputProps={{
                      'aria-label': t('personalInfo.suffix'),
                      'aria-describedby': 'suffix-error',
                      maxLength: MAX_MEDIUM_LENGTH_FIELD,
                      ...DISABLE_AUTO_FILL,
                    }}
                    {...register(`${groupName}.suffix`)}
                  />
                  <FormHelperText role="alert" id="suffix-error">
                    {errors?.[groupName]?.suffix?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Controller
              name={`${groupName}.birthInformation.birthDate`}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Grid item sm={12}>
                  <FormControl fullWidth required error={!!errors?.[groupName]?.birthInformation?.birthDate}>
                    <InputLabel htmlFor="birthDate">{t('personalInfo.birthDate')}</InputLabel>
                    <DateField
                      {...field}
                      ref={ref}
                      error={!!error}
                      id="birthDate"
                      aria-describedby="birthDate-error"
                      accessibilityLabelPrefix={t('personalInfo.birthDate')}
                    />
                    <FormHelperText role="alert" id="birthDate-error">
                      {errors?.[groupName]?.birthInformation?.birthDate?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}
              control={control}
            />
          </Grid>
        </Box>
        <Box>
          <Typography
            variant="h2"
            sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
          >
            {t('personalInfo.formerName')}
          </Typography>
          <FormControl fullWidth required error={!!errors?.[groupName]?.hasFormerName}>
            <InputLabel htmlFor="hasFormerName" tabIndex={0}>
              {t(`personalInfo.formerName.question`)}
            </InputLabel>
            <Controller
              name={`${groupName}.hasFormerName`}
              render={({ field: { ref, onChange, ...field } }) => (
                <RadioControl
                  {...field}
                  inputRef={ref}
                  id="hasFormerName"
                  aria-describedby="hasFormerName-error"
                  options={MasterData.radioDefaultOptions}
                  onChange={event => {
                    onChange(event.target.value);
                  }}
                />
              )}
              control={control}
            />
            <FormHelperText role="alert" id="hasFormerNameError">
              {errors?.[groupName]?.hasFormerName?.message as string}
            </FormHelperText>
          </FormControl>
          {hasFormerName && (
            <>
              <FormControl fullWidth required error={!!errors?.[groupName]?.alternateGivenName}>
                <InputLabel htmlFor="alternateGivenName">{t('personalInfo.alternateGivenName')} </InputLabel>
                <InputBase
                  placeholder={t('personalInfo.alternateGivenName')}
                  inputProps={{
                    'aria-label': t('personalInfo.alternateGivenName'),
                    'aria-describedby': 'alternateGivenName-error',
                    maxLength: MAX_MEDIUM_LENGTH_FIELD,
                    ...DISABLE_AUTO_FILL,
                  }}
                  {...register(`${groupName}.alternateGivenName`)}
                  fullWidth={true}
                />
                <FormHelperText role="alert" id="alternateGivenName-error">
                  {errors?.[groupName]?.alternateGivenName?.message}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth error={!!errors?.[groupName]?.alternateMiddleName}>
                <InputLabel htmlFor="alternateMiddleName">{t('personalInfo.alternateMiddleName')}</InputLabel>
                <InputBase
                  placeholder={t('personalInfo.alternateMiddleName')}
                  inputProps={{
                    'aria-label': t('personalInfo.alternateMiddleName'),
                    'aria-describedby': 'alternateMiddleName-error',
                    maxLength: MAX_MEDIUM_LENGTH_FIELD,
                    ...DISABLE_AUTO_FILL,
                  }}
                  {...register(`${groupName}.alternateMiddleName`)}
                  fullWidth={true}
                />
                <FormHelperText role="alert" id="alternateMiddleName-error">
                  {errors?.[groupName]?.alternateMiddleName?.message}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth required error={!!errors?.[groupName]?.alternateFamilyName}>
                <InputLabel htmlFor="alternateFamilyName">{t('personalInfo.alternateFamilyName')}</InputLabel>
                <InputBase
                  placeholder={t('personalInfo.alternateFamilyName')}
                  inputProps={{
                    'aria-label': t('personalInfo.alternateFamilyName'),
                    'aria-describedby': 'alternateFamilyName-error',
                    maxLength: MAX_MEDIUM_LENGTH_FIELD,
                    ...DISABLE_AUTO_FILL,
                  }}
                  {...register(`${groupName}.alternateFamilyName`)}
                  fullWidth={true}
                />
                <FormHelperText role="alert" id="alternateFamilyName-error">
                  {errors?.[groupName]?.alternateFamilyName?.message}
                </FormHelperText>
              </FormControl>
            </>
          )}
        </Box>
        <Box>
          <Typography
            variant="h2"
            sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
          >
            {t('personalInfo.myName')}
          </Typography>
          <FormControl fullWidth required error={!!errors?.[groupName]?.hasPreferredName}>
            <InputLabel htmlFor="hasPreferredName" required tabIndex={0}>
              {t(`personalInfo.myName.question`)}
            </InputLabel>
            <Controller
              name={`${groupName}.hasPreferredName`}
              render={({ field: { ref, onChange, ...field } }) => (
                <RadioControl
                  {...field}
                  inputRef={ref}
                  id="hasPreferredName"
                  aria-describedby="hasPreferredName-error"
                  options={MasterData.radioDefaultOptions}
                  onChange={event => {
                    onChange(event.target.value);
                  }}
                />
              )}
              control={control}
            />
            <FormHelperText role="alert" id="hasPreferredNameError">
              {errors?.[groupName]?.hasPreferredName?.message as string}
            </FormHelperText>
          </FormControl>
          {hasPreferredName && (
            <>
              <FormControl fullWidth required error={!!errors?.[groupName]?.preferredName}>
                <InputLabel htmlFor="preferredName">{t('personalInfo.preferredName')}</InputLabel>
                <InputBase
                  placeholder={t('personalInfo.preferredName')}
                  inputProps={{
                    'aria-label': t('personalInfo.preferredName'),
                    'aria-describedby': 'preferredName-error',
                    maxLength: MAX_MEDIUM_LENGTH_FIELD,
                    ...DISABLE_AUTO_FILL,
                  }}
                  {...register(`${groupName}.preferredName`)}
                />
                <FormHelperText role="alert" id="preferredName-error">
                  {errors?.[groupName]?.preferredName?.message}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth error={!!errors?.[groupName]?.preferredMiddleName}>
                <InputLabel htmlFor="preferredMiddleName">{t('personalInfo.preferredMiddleName')}</InputLabel>
                <InputBase
                  placeholder={t('personalInfo.preferredMiddleName')}
                  inputProps={{
                    'aria-label': t('personalInfo.preferredMiddleName'),
                    'aria-describedby': 'preferredMiddleName-error',
                    maxLength: MAX_MEDIUM_LENGTH_FIELD,
                    ...DISABLE_AUTO_FILL,
                  }}
                  {...register(`${groupName}.preferredMiddleName`)}
                />
                <FormHelperText role="alert" id="preferredMiddleName-error">
                  {errors?.[groupName]?.preferredMiddleName?.message}
                </FormHelperText>
              </FormControl>
            </>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default memo(PersonalInformationForm);
