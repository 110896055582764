/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  dialogClasses,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Toolbar,
  Typography,
  Theme,
  InputLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '@liaison/liaison-ui';
import { DASHBOARD, SKIP_CURRENT_ROUTE } from 'transferPlanner/constants/routeNames';
import { StyledDivider } from 'pages/Pages.styles';
import { ReactComponent as TransferIcon } from 'assets/svgs/transfer-icon.svg';
import MasterData from 'userProfile/constants/master';
import { Spinner } from 'components/Spinner';
import { RadioControl } from 'components/RadioControl';
import { DISABLE_AUTO_FILL, MAX_BIG_LENGTH_FIELD } from 'constants/field';
import { selectEligibility } from 'transferPlanner/store/eligibility/eligibility.selectors';
import completeSetupBg from 'assets/svgs/completeSetupBg.svg';
import { nameSpace } from 'transferPlanner/constants/general';
import { setTitle } from 'utils/commonUtils';
import {
  applyFormValues,
  composePayload,
  defaultValues,
  groupName,
  notApplyReasonAnswers,
  getEligibility,
  postEligibility,
  versionYear,
  currentQuestionnaire,
} from './Questionnaire.utils';
import { validationSchema } from './Questionnaire.validation';
import { Result } from './Result';

export const QuestionnaireV2 = (): ReactElement => {
  const {
    control,
    setValue,
    watch,
    getValues,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(validationSchema, { abortEarly: false }),
    context: { version: 2 },
  });

  const [isResult, setIsResult] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(nameSpace);

  const isExtrtaLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const isExtraSmall = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  const { eligibility, loading } = useSelector(selectEligibility);

  const { graduate } = getValues();

  setTitle(t('Transfer-Success-Pathway-Questionnaire.pageTitle'));

  useEffect(() => {
    dispatch(getEligibility());
  }, [dispatch]);

  useEffect(() => {
    if (eligibility) {
      const questionnaire = currentQuestionnaire(2, eligibility?.tspEligibility);
      applyFormValues({ setValue, questions: questionnaire?.[0]?.questions || [] });
    }
  }, [eligibility, setValue]);

  const onClose = () => navigate(`/${DASHBOARD}`, SKIP_CURRENT_ROUTE);

  const isGraduate = watch('graduate') === 'Yes';

  const onSubmit = useCallback(() => {
    dispatch(
      postEligibility(
        composePayload({
          eligible: isGraduate,
          graduate,
          applied: watch('applied'),
          notApplyReason: watch('notApplyReason'),
          otherReason: watch('otherReason'),
          questionnaireVersion: 2,
          eligibility: eligibility?.tspEligibility,
          planToTransfer: '',
        }),
        () => setIsResult(true)
      )
    );
  }, [dispatch, graduate, isGraduate, watch, eligibility]);

  return (
    <main>
      <Dialog
        open
        disableEscapeKeyDown
        role="main"
        fullWidth
        fullScreen={isExtraSmall}
        maxWidth="lg"
        sx={{
          backdropFilter: 'blur(4px)',
          [`.${dialogClasses.paper}`]: {
            borderRadius: '1rem',
          },
        }}
        aria-label="questionnaire-modal"
        aria-labelledby="questionnaire-eligibility"
        aria-describedby="questionnaire-eligibility"
      >
        {isLarge && (
          <AppBar
            role="presentation"
            sx={{
              position: 'relative',
              background: `url(${completeSetupBg})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Toolbar>
              {isExtraSmall && (
                <IconButton edge="start" color="inherit" aria-label="close">
                  <TransferIcon aria-label="icon transfer" />
                </IconButton>
              )}
              <Box>
                <Typography
                  id="questionnaire-eligibility"
                  sx={{
                    color: theme => theme.palette.secondary.contrastText,
                    textAlign: { xs: 'left', sm: 'center', md: 'right' },
                    typography: { xs: 'subtitle1', sm: 'subtitle5' },
                  }}
                >
                  {t(`${groupName}.eligibility`)}
                </Typography>
              </Box>
            </Toolbar>
          </AppBar>
        )}
        <DialogContent sx={{ p: 0, height: '92vh', overflow: 'hidden' }}>
          <Grid container sx={{ minHeight: '100%' }}>
            {isExtrtaLarge && (
              <Grid item xs={3.25}>
                <Box
                  sx={{
                    height: '93vh',
                    background: `url(${completeSetupBg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      m: '2.5rem 1rem 0 1.5rem',
                      display: 'flex',
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: theme => theme.palette.secondary.contrastText,
                      }}
                    >
                      {t(`${groupName}.header1`)}
                    </Typography>
                  </Box>
                  <Divider
                    sx={{
                      borderStyle: 'solid',
                      m: '1rem 1rem 1.5rem 1.5rem',
                      display: 'block',
                    }}
                  />
                  <Box
                    sx={{
                      mr: '-5rem',
                      px: '3rem',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      id="questionnaire-eligibility"
                      variant="subtitle5"
                      sx={{
                        color: theme => theme.palette.secondary.contrastText,
                        textAlign: 'right',
                      }}
                    >
                      {t(`${groupName}.eligibility`)}
                    </Typography>
                    <Box
                      sx={{
                        ml: '1rem',
                        display: 'flex',
                      }}
                    >
                      <TransferIcon aria-label="icon transfer" />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
            <Grid item xs={isExtrtaLarge ? 8.75 : 12} justifyContent="center" alignItems="center">
              <Box sx={{ height: '100%' }}>
                {isExtrtaLarge && (
                  <AppBar
                    role="presentation"
                    position="sticky"
                    sx={{
                      mt: '1.25rem',
                      bgcolor: 'transparent',
                      boxShadow: 'none',
                    }}
                  >
                    <Toolbar
                      sx={{
                        border: 'none',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography id="questionnaire-information-1" variant="h1">
                        {t(`${groupName}.questionnaire`)}
                      </Typography>
                    </Toolbar>
                    <Box>
                      <StyledDivider sx={{ p: 0, my: 0, mx: '7.5rem' }} />
                    </Box>
                  </AppBar>
                )}
                <Box
                  sx={{
                    p: 0,
                    m: 0,
                    ml: 0,
                    display: 'flex',
                    alignItems: loading ? 'center' : 'flex-start',
                    justifyContent: 'center',
                    minWidth: 'auto',
                    position: { md: 'unset', xl: 'relative' },
                    minHeight: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mt: 0,
                      pt: '1rem',
                      overflow: loading ? 'hidden' : 'auto',
                      flexDirection: 'column',
                      height: {
                        xs: 'calc(90vh - 73px)',
                        sm: 'calc(90vh - 100px)',
                        md: 'calc(90vh - 130px)',
                        lg: isGraduate ? 'calc(90vh - 155px)' : 'auto',
                        xl: isGraduate ? 'calc(80vh - 70px)' : 'auto',
                      },
                    }}
                  >
                    {!isExtrtaLarge && (
                      <Typography
                        sx={{ mt: { md: '1rem' }, mb: { md: 1 } }}
                        id="questionnaire-information-1"
                        variant="h1"
                        textAlign="center"
                      >
                        {t(`${groupName}.questionnaire`)}
                      </Typography>
                    )}
                    {loading ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner />
                      </Box>
                    ) : (
                      <Box
                        sx={
                          isExtraSmall
                            ? {
                                pb: { xs: '0rem', sm: '6rem', md: '6rem', lg: '6rem' },
                                overflowY: 'auto',
                                pl: 2,
                                pr: 2,
                              }
                            : { width: '80%', height: '100%' }
                        }
                      >
                        {isResult && <Result eligible={isGraduate} />}
                        {!isResult && (
                          <>
                            <Typography variant="subtitle4" sx={{ display: 'block' }}>
                              {t(`${groupName}.V2.instructionalText`)}
                            </Typography>
                            <Box sx={{ ml: '0.5rem', mt: { xs: '1.35rem', lg: '1rem' } }}>
                              <InputLabel required sx={{ ml: '-0.5rem' }}>
                                {t(`${groupName}.graduate`)}
                              </InputLabel>
                              <List sx={{ listStyleType: 'disc', pl: 3, pt: 0 }}>
                                <ListItem disablePadding sx={{ display: 'list-item' }}>
                                  <ListItemText
                                    primary={
                                      <Typography variant="subtitle7">
                                        {t(`${groupName}.graduate.statements1`)} {versionYear.v2}.
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                                <ListItem disablePadding sx={{ display: 'list-item' }}>
                                  <ListItemText
                                    primary={
                                      <Typography variant="subtitle7" sx={{ display: 'block' }}>
                                        {t(`${groupName}.graduate.statements2and3Combained`, { year: versionYear.v1 })}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                              </List>
                              <Controller
                                name="graduate"
                                render={({ field: { ref, onChange, ...field } }) => (
                                  <RadioControl
                                    {...field}
                                    inputRef={ref}
                                    id={`${groupName}.graduate`}
                                    options={MasterData.radioDefaultOptions}
                                    onChange={event => {
                                      onChange(event.target.value);
                                    }}
                                  />
                                )}
                                control={control}
                              />
                            </Box>
                            {isGraduate && (
                              <>
                                <Box sx={{ pt: { xs: '1rem', md: '1.25rem' }, ml: '0.5rem' }}>
                                  <InputLabel required sx={{ ml: '-0.5rem' }}>
                                    {t(`${groupName}.applied`)}
                                  </InputLabel>
                                  <Controller
                                    name="applied"
                                    render={({ field: { ref, onChange, ...field } }) => (
                                      <RadioControl
                                        {...field}
                                        inputRef={ref}
                                        id={`${groupName}.applied`}
                                        options={MasterData.radioDefaultOptions}
                                        onChange={event => {
                                          onChange(event.target.value);
                                        }}
                                      />
                                    )}
                                    control={control}
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    minHeight: { xs: '12.5rem', md: '11.5rem' },
                                    pt: { xs: '1rem', md: '1.25rem' },
                                    ml: '0.5rem',
                                    mb: '2rem',
                                  }}
                                >
                                  <InputLabel required sx={{ ml: '-0.5rem' }}>
                                    {t(`${groupName}.notApplyReason`)}
                                  </InputLabel>
                                  <Controller
                                    name="notApplyReason"
                                    render={({ field: { ref, onChange, ...field } }) => (
                                      <>
                                        <RadioControl
                                          {...field}
                                          inputRef={ref}
                                          id={`${groupName}.notApplyReason`}
                                          options={notApplyReasonAnswers}
                                          onChange={event => {
                                            onChange(event.target.value);
                                          }}
                                        />
                                        {field.value === 'other' && (
                                          <Controller
                                            name="otherReason"
                                            render={({
                                              field: { ref: inputRef, onChange: inputOnChange, ...inputField },
                                            }) => (
                                              <Input
                                                {...inputField}
                                                inputRef={inputRef}
                                                sx={{ display: 'block', ml: '4.5rem', mt: '-1.9rem' }}
                                                placeholder="Other reason"
                                                onChange={event => {
                                                  inputOnChange(event.target.value);
                                                }}
                                                inputProps={{
                                                  'aria-label': 'otherReason',
                                                  maxLength: MAX_BIG_LENGTH_FIELD,
                                                  ...DISABLE_AUTO_FILL,
                                                }}
                                              />
                                            )}
                                            control={control}
                                          />
                                        )}
                                      </>
                                    )}
                                    control={control}
                                  />
                                </Box>
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
                <AppBar
                  role="presentation"
                  position={isExtraSmall ? 'fixed' : 'sticky'}
                  sx={{
                    top: 'auto',
                    bottom: 0,
                    bgcolor: '#ffffff',
                    boxShadow: 'none',
                    borderTop: '0.0625rem solid',
                    borderColor: (theme: Theme) => theme.palette.divider,
                  }}
                >
                  <Toolbar
                    sx={{
                      border: 'none',
                      display: 'flex',
                      justifyContent: isResult ? 'flex-end' : 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {!isResult && (
                      <>
                        <Box sx={{ width: 'auto' }}>
                          <Button onClick={onClose} color="error" variant="text">
                            {t('cancel_label')}
                          </Button>
                        </Box>
                        <Box sx={{ width: 'auto' }}>
                          <Button onClick={onSubmit} variant="contained" disabled={!isValid}>
                            {t('submit_label')}
                          </Button>
                        </Box>
                      </>
                    )}
                    {isResult && (
                      <Box sx={{ width: 'auto' }}>
                        <Button onClick={onClose} color="secondary" variant="contained">
                          {t('go_to_dashboard_label')}
                        </Button>
                      </Box>
                    )}
                  </Toolbar>
                </AppBar>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </main>
  );
};
