/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useState, useEffect, useMemo } from 'react';
import { Typography, Box, Checkbox, Divider, Stack, IconButton } from '@mui/material';
import { Icon, SidePanel } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useImpersonation } from 'hooks/useImpersonation';
import { useForm, FormProvider, SubmitHandler, FieldValues } from 'react-hook-form';

import { StyledButton, sxSidePanel } from 'pages/Pages.styles';
import { getTermsData, postTermsData } from 'pages/Terms/Terms.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginPageUrl } from 'constants/keyCloak';
import { doLogout } from 'utils/keyCloakUtils';
import { selectTermsData, selectLoading } from 'store/common/commonInfo.selectors';
import { ITerms } from 'store/common/commonInfo.slice';
import { selectLoggedUserData, selectLoading as selectAuthLoading } from 'userProfile/store/auth/auth.selectors';
import { TAuthField } from 'userProfile/store/auth/auth.slice';
import { Spinner } from 'components/Spinner';
import { getButtonColor, getTenant } from 'utils/utilities';
import { setTitle } from 'utils/commonUtils';
import UpdatePasswordForm from './UpdatePasswordForm';
import { validationSchema } from './UpdatePasswordForm.validation';
import { gdprStatus, getLoggedUserDetails, updatePassword } from '../ManageAccount.util';
import { sxIconHover, StyledTooltip, sxTypography, TextAndPhoneTooltipInfo } from './Privacy.utils';

type IUpdatePassword = {
  currentPassword: string | undefined;
  newPassword: string | undefined;
  confirmNewPassword?: string | undefined;
};

const Privacy = (): ReactElement => {
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);
  const termsData = useSelector(selectTermsData);
  const isLoading = useSelector(selectLoading);
  const authLoading = useSelector(selectAuthLoading);
  const [tooltipOpen, setTooltipOpen] = useState({ gdpr: false, textAndPhone: false });
  const impersonation = useImpersonation();
  const dispatch = useDispatch();
  const [isOpenSidePanel, setIsOpenSidePanel] = useState(false);
  const loggedUserData = useSelector(selectLoggedUserData);
  const { t } = useTranslation();
  const tenantName = getTenant();

  const isSocialLogin = useMemo(() => Boolean(loggedUserData?.socialLinks?.length), [loggedUserData]);

  useEffect(() => {
    setTitle(t('privacy.pageTitle'));
    if (tenantName !== 'liaison') {
      dispatch(getTermsData(tenantName));
    }
    if (!loggedUserData) {
      dispatch(getLoggedUserDetails());
    }
  }, [dispatch, loggedUserData, tenantName, t]);

  const methods = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(validationSchema, { abortEarly: false })(...args),
  });

  const closeSidepanel = () => {
    setIsOpenSidePanel(false);
    setTitle(t('privacy.pageTitle'));
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckBoxChecked(event.target.checked);
    const updatedData = {
      ...termsData,
      textPhoneAuthorization: {
        accepted: event.target.checked,
      },
    };
    dispatch(postTermsData(updatedData as ITerms));
  };

  const onSubmit = (data: IUpdatePassword) => {
    const modifiedData = {
      ...data,
      temporary: false,
    };
    delete modifiedData.confirmNewPassword;

    dispatch(
      updatePassword(modifiedData, () => {
        closeSidepanel();
        doLogout(loginPageUrl);
      })
    );
  };

  const handleTooltipClose = (location: string) => {
    setTooltipOpen({ ...tooltipOpen, [location]: false });
  };

  const handleTooltipOpen = (location: string) => {
    setTooltipOpen({ ...tooltipOpen, [location]: true });
  };

  return (
    <Box>
      {(isLoading || authLoading) && <Spinner backdrop />}
      <Typography sx={{ fontWeight: 'bold', fontSize: [18, 24, 24] }} component="h1" variant="h3">
        {t('privacy.heading')}
      </Typography>
      <Typography variant="subtitle7">{t('privacy.instruction')}</Typography>
      <Stack direction="row" sx={{ mt: 2 }} alignItems="center" gap={1}>
        <Typography sx={sxTypography} gap={1} gutterBottom>
          <Typography variant="subtitle7" component="span" sx={{ textTransform: 'uppercase', verticalAlign: 'middle' }}>
            {t('privacy.europeanUnionText')}
          </Typography>
          <StyledTooltip
            open={tooltipOpen.gdpr}
            componentsProps={{ tooltip: { role: 'button' } }}
            title={<>{t('privacy.europeanUnionTooltip')}</>}
            onClose={() => handleTooltipClose('gdpr')}
          >
            <IconButton
              onMouseOver={() => handleTooltipOpen('gdpr')}
              onClick={() => handleTooltipOpen('gdpr')}
              sx={sxIconHover}
              size="small"
              edge="start"
              color="inherit"
              aria-label={`${t('privacy.showMoreInfo')} ${t('privacy.europeanUnionText')}`}
              role="button"
            >
              <Icon>IconInfoSolid</Icon>
            </IconButton>
          </StyledTooltip>
        </Typography>
        <Typography component="span" variant="subtitle2" sx={{ textTransform: 'uppercase' }}>
          {gdprStatus(loggedUserData as TAuthField)}
        </Typography>
      </Stack>
      {tenantName !== 'liaison' && (
        <>
          <Divider sx={{ width: '100%', mt: 2, mb: 2 }} />
          <Stack direction="row" sx={{ mt: 2 }} alignItems="center" gap={1}>
            <Typography sx={sxTypography} gap={1} gutterBottom>
              <Typography
                variant="subtitle7"
                component="span"
                sx={{ textTransform: 'uppercase', verticalAlign: 'middle' }}
              >
                {t('terms.textAndPhone')}
              </Typography>
              <StyledTooltip
                open={tooltipOpen.textAndPhone}
                componentsProps={{ tooltip: { role: 'button' } }}
                title={<TextAndPhoneTooltipInfo />}
                onClose={() => handleTooltipClose('textAndPhone')}
              >
                <IconButton
                  onMouseOver={() => handleTooltipOpen('textAndPhone')}
                  onClick={() => handleTooltipOpen('textAndPhone')}
                  sx={sxIconHover}
                  size="small"
                  edge="start"
                  color="inherit"
                  aria-label={`${t('privacy.showMoreInfo')} ${t('terms.textAndPhone')}`}
                >
                  <Icon>IconInfoSolid</Icon>
                </IconButton>
              </StyledTooltip>
            </Typography>
            <Checkbox
              checked={termsData?.textPhoneAuthorization?.accepted || checkBoxChecked}
              onChange={handleCheckBoxChange}
              inputProps={{
                'aria-label': t('terms.textAndPhone'),
                role: 'checkbox',
              }}
            />
          </Stack>
        </>
      )}
      <Divider sx={{ width: '100%', mt: 2, mb: 2 }} />
      <Stack direction="row" sx={{ mt: 2, verticalAlign: 'middle' }} alignItems="center" gap={1}>
        <Typography
          variant="subtitle5"
          component="span"
          sx={{ flex: 0.95, textTransform: 'uppercase', color: isSocialLogin ? '#AFBBCC' : '#383D45' }}
          display="block"
          gutterBottom
        >
          {t(isSocialLogin ? 'account.passwordNotSetUp' : 'account.password')}
        </Typography>
        <StyledButton
          disabled={impersonation || isSocialLogin}
          onClick={() => {
            setIsOpenSidePanel(true);
            setTitle(t('account.changepasswordPageTitle'));
          }}
        >
          {t('account.changepassword')}
        </StyledButton>
      </Stack>
      <FormProvider {...methods}>
        <form>
          <SidePanel
            open={isOpenSidePanel}
            onClose={closeSidepanel}
            title={t('account.changepassword')}
            isBackdropClickEnabled={true}
            footerButtonConfig={{
              primary: {
                title: t('updateBtn_label'),
                props: {
                  'aria-label': t('updateBtn_label'),
                  variant: 'contained',
                  disabled: !methods.formState.isValid,
                  onClick: methods.handleSubmit(onSubmit as SubmitHandler<FieldValues>),
                  color: getButtonColor(),
                },
              },
              tertiary: {
                title: t('cancel_label'),
                props: {
                  'aria-label': t('cancel_label'),
                  color: getButtonColor(),
                  onClick: closeSidepanel,
                },
              },
            }}
            sx={sxSidePanel}
          >
            <UpdatePasswordForm />
          </SidePanel>
        </form>
      </FormProvider>
    </Box>
  );
};

export default memo(Privacy);
