/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { useSelector } from 'react-redux';
import { selectAuthenticatedToken } from '../../store/auth/auth.selectors';

export const useImpersonation = (): boolean => {
  const authToken = useSelector(selectAuthenticatedToken) || localStorage.getItem('kc_impersonation_token');
  return !!authToken;
};
