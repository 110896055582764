/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';

import { ISearchProgramState, EViewMode } from './searchProgram.slice';

const selectSearchProgram = (state: TRootState): ISearchProgramState => state.searchProgram;

export const selectSelectedPrograms = createSelector(
  selectSearchProgram,
  (searchProgram: ISearchProgramState) => searchProgram.selectedPrograms
);

export const selectSearchQuery = createSelector(
  selectSearchProgram,
  (searchProgram: ISearchProgramState) => searchProgram.searchQuery
);

export const selectScrollLetter = createSelector(
  selectSearchProgram,
  (searchProgram: ISearchProgramState) => searchProgram.scrollLetter
);

export const selectNavigationLetter = createSelector(
  selectSearchProgram,
  (searchProgram: ISearchProgramState) => searchProgram.navigationLetter
);

export const selectCampusIds = createSelector(
  selectSearchProgram,
  (searchProgram: ISearchProgramState) => searchProgram.selectedCampusIds
);

export const selectIsEligible = createSelector(
  selectSearchProgram,
  (searchProgram: ISearchProgramState) => searchProgram.isEligible
);

export const selectNeedScroll = createSelector(
  selectSearchProgram,
  (searchProgram: ISearchProgramState) => searchProgram.needScroll
);

export const selectViewMode = createSelector(
  selectSearchProgram,
  (searchProgram: ISearchProgramState) => searchProgram.viewMode
);

export const selectIsListView = createSelector(selectViewMode, (viewMode: EViewMode) => viewMode === EViewMode.list);
