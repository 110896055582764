/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Grid, Box, Typography, Card, CardContent, Divider, IconButton, Stack, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import { IHighSchool } from 'store/academicHistory/academicHistory.slice';
import { Icon, IconEdit } from '@liaison/liaison-ui';
import Pluralize from 'pluralize';
import { StyledButton, StyledAddButton, sxCard, StyledContainedButton } from 'pages/Pages.styles';
import { trimHeader, displayDate } from 'utils/utilities';
import { useTranslation } from 'react-i18next';

interface IHighSchoolCardButtonProps {
  courseLength: number;
  position: number;
  responseDetail: IHighSchool;
  selector: string;
  openChildDrawer?: (
    isAddNew: boolean,
    childPosition: number,
    parentSelector: string,
    formTitle: string,
    showCourse?: boolean
  ) => void;
  openGrandChildDrawer?: (
    isAddNew: boolean,
    childPosition: number,
    parentSelector: string,
    formTitle: string,
    parentPosition?: number,
    sectionPrefix?: string
  ) => void;
}

const HighSchoolCardButtons = ({
  courseLength,
  position,
  responseDetail,
  selector,
  openChildDrawer,
  openGrandChildDrawer,
}: IHighSchoolCardButtonProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  return (
    <>
      <Grid item xs={isMobile ? 10 : 4.5} sm={4.5} md={2.6} lg={2.1} sx={{ height: '60%' }}>
        <Stack direction="row" justifyContent={isMobile ? 'center' : 'end'} sx={{ mt: '0.7rem' }}>
          {courseLength ? (
            <StyledButton
              sx={{ fontSize: '0.625rem' }}
              size="small"
              fullWidth={isMobile}
              onClick={() => openChildDrawer?.(false, position, selector, responseDetail.name, true)}
            >
              {Pluralize(t('academicHistory.course'), courseLength, true)}
            </StyledButton>
          ) : (
            <StyledAddButton
              sx={{ fontSize: '0.58rem' }}
              size="small"
              fullWidth={isMobile}
              onClick={() => openGrandChildDrawer?.(true, 0, selector, '', position)}
            >
              {t('academicHistory.addCourse')}
            </StyledAddButton>
          )}
        </Stack>
      </Grid>

      <Grid item xs={isMobile ? 1 : 1.2} sm={1.2} md={0.7} sx={{ margin: 'auto', height: '60%', mt: '0.4rem' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton
            aria-label="righsideschooledit"
            size="small"
            onClick={() => openChildDrawer?.(false, position, selector, t('highSchool.editSchool'))}
          >
            <IconEdit />
          </IconButton>
        </Box>
      </Grid>
    </>
  );
};

interface IHighSchoolsCardProps {
  responseData: IHighSchool[];
  smallLayout?: number;
  xtraSmallLayout?: number;
  openChildDrawer?: (
    isAddNew: boolean,
    childPosition: number,
    parentSelector: string,
    formTitle: string,
    showCourse?: boolean
  ) => void;
  selector: string;
  openGrandChildDrawer?: (
    isAddNew: boolean,
    childPosition: number,
    parentSelector: string,
    formTitle: string,
    parentPosition?: number
  ) => void;
}

export const HighSchoolsCard = ({
  responseData,
  xtraSmallLayout = 12,
  smallLayout = 12,
  openChildDrawer,
  selector,
  openGrandChildDrawer,
}: IHighSchoolsCardProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  return (
    <Grid container spacing={2}>
      {(responseData || []).map((responseDetail, position) => {
        const addressResponse = responseDetail?.address;
        const address = `${
          addressResponse?.region?.displayName !== 'N/A' ? addressResponse?.region?.displayName : addressResponse?.city
        }, ${addressResponse?.country?.displayName}`;
        const courseLength = responseDetail?.gradeLevels?.reduce(
          (accumulator, current) => accumulator + (current?.courses?.length as number),
          0
        );
        return (
          <Grid
            item
            xs={xtraSmallLayout}
            sm={smallLayout}
            key={`${responseDetail?.ceeb}-${responseDetail?.name}-${courseLength}-${responseDetail?.graduated}`}
            sx={{ mt: position === 0 ? '1rem' : '' }}
          >
            <Card variant="outlined" sx={sxCard}>
              <CardContent
                sx={{
                  '&:last-child': {
                    pb: '1rem',
                  },
                }}
              >
                <Grid container spacing={0}>
                  <Grid item xs={6} sm={6} md={8.5} lg={9} sx={{ mr: '0.5rem', display: 'flex' }}>
                    <Box>
                      <IconButton
                        sx={{ p: 0.9 }}
                        size="extra-large"
                        aria-label={trimHeader(responseDetail?.name || '')}
                      >
                        <Icon sx={{ color: theme => theme.palette.grey[500] }}>IconSchool</Icon>
                      </IconButton>
                    </Box>
                    <Box>
                      <Box>
                        <Typography component="h1" sx={{ overflowWrap: 'anywhere', fontWeight: '800' }}>
                          {trimHeader(responseDetail?.name || '')}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="caption" sx={{ overflowWrap: 'anywhere' }}>
                          {address}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  {!isMobile && (
                    <HighSchoolCardButtons
                      courseLength={courseLength}
                      position={position}
                      responseDetail={responseDetail}
                      selector={selector}
                      openChildDrawer={openChildDrawer}
                      openGrandChildDrawer={openGrandChildDrawer}
                    />
                  )}
                </Grid>
                <Divider
                  sx={{
                    borderStyle: 'solid',
                    mt: '0.7rem',
                    mb: '0.7rem',
                    borderColor: theme => theme.palette.grey[500],
                  }}
                  flexItem
                />

                <Box sx={{ mt: 0.5, display: 'flex', alignItems: 'center' }}>
                  <Box>
                    <Typography variant="subtitle7" sx={{ textTransform: 'none' }} display="block">
                      {`${t('academicHistory.graduated')} ${
                        responseDetail?.graduated === 'Yes'
                          ? `${
                              responseDetail?.graduationDate ? `: ${displayDate(responseDetail?.graduationDate)}` : ''
                            }`
                          : ': No'
                      }`}
                    </Typography>
                  </Box>
                </Box>
                {isMobile && (
                  <Grid container spacing={1} sx={{ mt: '0.7rem' }}>
                    <HighSchoolCardButtons
                      courseLength={courseLength}
                      position={position}
                      responseDetail={responseDetail}
                      selector={selector}
                      openChildDrawer={openChildDrawer}
                      openGrandChildDrawer={openGrandChildDrawer}
                    />
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        );
      })}
      {!responseData?.length && (
        <Grid container sx={{ mt: 2 }} spacing={1} direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <Typography variant="subtitle7">{t('highschool.noResult')}</Typography>
          </Grid>
          <Grid item>
            <StyledContainedButton
              onClick={() => openChildDrawer?.(true, responseData?.length || 0, selector, t('highSchool.add'))}
            >
              {t('highSchool.add')}
            </StyledContainedButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
