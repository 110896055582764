/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Grid, Box, Typography, Card, CardContent, IconButton, Tooltip } from '@mui/material';
import { Icon, IconEdit } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'redux/hooks';
import { formatDateToMonthAndYear } from 'utils/commonUtils';
import { selectStandardTests } from 'store/standardTests/standardTests.selectors';
import { StyledContainedButton, sxCard } from 'pages/Pages.styles';
import type { TStandardTests } from 'store/standardTests/standardTests.slice';

import { displayTestData } from './TestCard.utils';

interface ITestCardProps {
  openChildDrawer?: (isAddNew: boolean, childPosition: number, parentSelector: string, formTitle: string) => void;
  selector: string;
}
export const TestCard = ({ openChildDrawer, selector }: ITestCardProps): ReactElement => {
  const { t } = useTranslation();
  const standardTests = useAppSelector(selectStandardTests);
  const tests = (standardTests as TStandardTests)?.tests;

  if (!tests?.length) {
    return (
      <Grid container spacing={1} direction="column" justifyContent="center" alignItems="center">
        <Grid item>
          <Typography variant="subtitle7">{t('tests.noResult')}</Typography>
        </Grid>
        <Grid item>
          <StyledContainedButton onClick={() => openChildDrawer?.(true, tests?.length || 0, selector, '')}>
            {t('tests.addTest')}
          </StyledContainedButton>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {tests.map((test, position) => {
        return (
          <Grid item xs={12} sm={12} key={test.id} sx={{ mt: position === 0 ? '1rem' : '' }}>
            <Card variant="outlined" sx={sxCard}>
              <CardContent
                sx={{
                  '&:last-child': {
                    pb: 2,
                  },
                }}
              >
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Icon
                        sx={{ color: theme => theme.palette.grey[500], m: '0.3rem' }}
                        aria-label={displayTestData(test)}
                      >
                        IconFileTest
                      </Icon>
                      <Typography component="h1">{displayTestData(test)}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={1.9} sx={{ margin: 'auto' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Tooltip title={t('tests.edit')}>
                        <IconButton
                          aria-label={`Edit ${t(displayTestData(test))}`}
                          size="small"
                          onClick={() => {
                            openChildDrawer?.(false, position, selector, '');
                          }}
                        >
                          <IconEdit />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
                <Box sx={{ mt: 0.5, display: 'flex', alignItems: 'center' }}>
                  <Typography variant="caption" component="div">
                    {t(test.status.code === 'PLANNED' ? 'tests.beTakenOn' : 'tests.takenOn')}{' '}
                    {formatDateToMonthAndYear(test.testDate)}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};
