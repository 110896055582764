/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useState, useEffect } from 'react';
import { Button, Theme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD, SKIP_CURRENT_ROUTE } from 'transferPlanner/constants/routeNames';
import { nameSpace } from 'transferPlanner/constants/general';
import { useTranslation } from 'react-i18next';
import { sxPrimaryButton, StyledBoxFooterContainer } from 'pages/Pages.styles';
import { useFormData } from './ContextForm';

export interface IFooterProps {
  handleNextButtonClick: () => void;
  handlePreviousClick: () => void;
  handleCompleteClick: () => void;
  activeTab: number;
  success: boolean;
}

const EnrollmentFooter = ({
  handleNextButtonClick,
  handlePreviousClick,
  activeTab,
  handleCompleteClick,
  success,
}: IFooterProps): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const navigate = useNavigate();
  const { data } = useFormData();
  const [enableButton, setEnableButton] = useState(data?.enabled || false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      setEnableButton(data?.enabled);
    }
  }, [data]);

  return (
    <StyledBoxFooterContainer justifyContent="flex-end">
      {success ? (
        <>
          <Button color="secondary" aria-hidden={true} />
          <Button
            sx={sxPrimaryButton}
            variant="contained"
            onClick={() => navigate(`/${DASHBOARD}`, SKIP_CURRENT_ROUTE)}
            aria-label={t('returnToDashboard_label')}
          >
            {t('returnToDashboard_label')}
          </Button>
        </>
      ) : (
        <>
          {activeTab === 0 ? (
            <Button onClick={() => navigate(`/${DASHBOARD}`)} aria-label={t('cancel')}>
              {t('cancel')}
            </Button>
          ) : (
            <Button onClick={handlePreviousClick} aria-label={t('previousStep_label')}>
              {t('previousStep_label')}
            </Button>
          )}

          {activeTab === 2 ? (
            <Button
              disabled={!enableButton}
              sx={{ mr: isMobile ? 0 : 3 }}
              variant="contained"
              onClick={() => {
                handleCompleteClick();
              }}
              aria-label={isMobile ? t('enrollmentAlert.completeEnrollment') : t('enrollmentAlert.complete')}
            >
              {isMobile ? t('enrollmentAlert.completeEnrollment') : t('enrollmentAlert.complete')}
            </Button>
          ) : (
            <Button
              disabled={!enableButton}
              sx={{ mr: 2 }}
              variant="contained"
              onClick={() => {
                setEnableButton(false);
                handleNextButtonClick();
              }}
              aria-label={t('nextStep_label')}
            >
              {t('nextStep_label')}
            </Button>
          )}
        </>
      )}
    </StyledBoxFooterContainer>
  );
};

export default memo(EnrollmentFooter);
