/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { RESEARCH_CAMPUS_AND_PROGRAMS, TP_ADDITIONAL_DETAILS } from 'transferPlanner/constants/routeNames';
import { isFeatureActive } from 'utils/features.utils';
import { IFeature } from 'store/features/features.slice';

export interface IMenuItem {
  icon: string;
  label: string;
  subLabel?: string;
  location?: string;
  action?: string;
  mailTo?: string;
  pageTitle?: string;
}

export const getMenuItems = (features: IFeature[] | null): IMenuItem[] => [
  {
    icon: 'IconSearch',
    label: 'Research Universities & Programs',
    location: RESEARCH_CAMPUS_AND_PROGRAMS,
  },
  ...(isFeatureActive(features, TP_ADDITIONAL_DETAILS.featureName)
    ? [
        {
          icon: 'IconClipboardContent',
          label: 'More About Me',
          subLabel: 'Additional Details',
          location: TP_ADDITIONAL_DETAILS.path,
        },
      ]
    : []),
  {
    icon: 'IconChatBubblesSolid',
    label: 'Ask a General Question',
    mailTo: 'csutransferplanner@calstate.edu',
  },
];
