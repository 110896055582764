/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Stack, Typography, Card, CardContent, IconButton } from '@mui/material';
import { Icon, IconCaretRight } from '@liaison/liaison-ui';
import { sxCard } from 'pages/Pages.styles';
import { TStandardizedTest } from 'userProfile/store/profileView/profileView.slice';
import { displayTestData } from 'userProfile/components/ViewBuilder/Cards/TestCard.utils';
import { formatDateToMonthAndYear } from 'utils/commonUtils';

type TTestCardProps = {
  test: TStandardizedTest;
  toggleDrawer: (test?: TStandardizedTest, toggle?: string) => void;
};

export const StandardizedTestCard = ({ test, toggleDrawer }: TTestCardProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} key={test.id}>
      <Card variant="outlined" sx={sxCard}>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton sx={{ p: 0.9 }} size="extra-large" aria-label="IconFileTest">
                <Icon sx={{ color: theme => theme.palette.grey[500] }}>IconFileTest</Icon>
              </IconButton>
              <Typography variant="subtitle3" sx={{ overflowWrap: 'anywhere' }}>
                {displayTestData(test)}
              </Typography>
            </Box>
            <IconButton sx={{ p: 0.9 }} role="button" aria-label="Open test card" onClick={() => toggleDrawer(test)}>
              <IconCaretRight />
            </IconButton>
          </Stack>
          <Box sx={{ mt: 0.5, display: 'flex', alignItems: 'center' }}>
            <Box>
              <Typography variant="caption" component="div">
                {t(test.status.code === 'PLANNED' ? 'tests.beTakenOn' : 'tests.takenOn')}{' '}
                {formatDateToMonthAndYear(test.testDate)}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};
