/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  commonFieldsConfigMock,
  APConfigMock,
  CLEPConfigMock,
  IBConfigMock,
  TOEFLConfigMock,
  IELTSConfigMock,
  GREConfigMock,
  GRESubjectConfigMock,
  standardTestsMock,
  GMATConfigMock,
  ACTConfigMock,
  MCATConfigMock,
  PTEConfigMock,
  LSATConfigMock,
  SATConfigMock,
} from 'mocks/standardTestsMocks';

type TError = string | null;

export type TCommonFields = typeof commonFieldsConfigMock;
export type TAPConfig = typeof APConfigMock;
export type TCLEPConfig = typeof CLEPConfigMock;
export type TIBConfig = typeof IBConfigMock;
export type TTOEFLConfig = typeof TOEFLConfigMock;
export type TIELTSConfig = typeof IELTSConfigMock;
export type TGREConfig = typeof GREConfigMock;
export type TGRESubjectConfig = typeof GRESubjectConfigMock;
export type TGMATConfig = typeof GMATConfigMock;
export type TACTConfig = typeof ACTConfigMock;
export type TMCATConfig = typeof MCATConfigMock;
export type TPTEConfig = typeof PTEConfigMock;
export type TLSATConfig = typeof LSATConfigMock;
export type TSATConfig = typeof SATConfigMock;
export type TStandardTests = Partial<typeof standardTestsMock>;
export type TTOEFLScoresSchema = TTOEFLConfig['jsonSchema']['properties']['dependentSchemas'][0 | 1];

export type TStandardTestsStateKeys =
  | 'COMMON_FIELDS'
  | 'AP'
  | 'CLEP'
  | 'IB'
  | 'TOEFL'
  | 'IELTS'
  | 'GRE'
  | 'GRE_SUBJECT'
  | 'GMAT'
  | 'ACT'
  | 'MCAT'
  | 'PTE'
  | 'LSAT'
  | 'SAT';

export interface IStandardTestsState {
  loading: boolean;
  COMMON_FIELDS: TCommonFields | null;
  AP: TAPConfig | null;
  CLEP: TCLEPConfig | null;
  IB: TIBConfig | null;
  TOEFL: TTOEFLConfig | null;
  IELTS: TIELTSConfig | null;
  GRE: TGREConfig | null;
  GRE_SUBJECT: TGRESubjectConfig | null;
  GMAT: TGMATConfig | null;
  ACT: TACTConfig | null;
  MCAT: TMCATConfig | null;
  PTE: TPTEConfig | null;
  LSAT: TLSATConfig | null;
  SAT: TSATConfig | null;
  standardTests: TStandardTests | null | '';
  error: TError;
}

export const initialState: IStandardTestsState = {
  loading: false,
  COMMON_FIELDS: null,
  AP: null,
  CLEP: null,
  IB: null,
  TOEFL: null,
  IELTS: null,
  GRE: null,
  GRE_SUBJECT: null,
  GMAT: null,
  ACT: null,
  MCAT: null,
  PTE: null,
  LSAT: null,
  SAT: null,
  standardTests: null,
  error: null,
};

const standardTestsSlice = createSlice({
  name: 'standardTests',
  initialState,
  reducers: {
    standardTestsConfigStart: (state): IStandardTestsState => ({
      ...state,
      loading: true,
      error: null,
    }),
    standardTestsConfigSuccess: (state, action): IStandardTestsState => ({
      ...state,
      loading: false,
      [action.payload.testType]: action.payload.data,
    }),
    standardTestsConfigFailure: (state, action: PayloadAction<TError>): IStandardTestsState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    standardTestsConfigReset: () => initialState,
    standardTestsStart: (state): IStandardTestsState => ({
      ...state,
      loading: true,
      error: null,
    }),
    standardTestsSuccess: (state, action: PayloadAction<TStandardTests>): IStandardTestsState => ({
      ...state,
      loading: false,
      standardTests: action.payload,
    }),
    standardTestsFailure: (state, action: PayloadAction<TError>): IStandardTestsState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  standardTestsConfigStart,
  standardTestsConfigSuccess,
  standardTestsConfigFailure,
  standardTestsConfigReset,
  standardTestsStart,
  standardTestsSuccess,
  standardTestsFailure,
} = standardTestsSlice.actions;

export default standardTestsSlice.reducer;
