/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import { Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import { TAppThunk } from 'redux/store';
import { t } from 'i18next';
import { API } from 'constants/api';
import { setUi } from 'store/ui/ui.slice';
import {
  profileViewStart,
  profileViewSuccess,
  profileViewFailure,
  setSavedProfileView,
  setCurrentProfileViewId,
  allProfileViewsStart,
  allProfileViewsSuccess,
  allProfileViewsFailure,
  updateLinkStart,
  updateLinkSuccess,
  updateLinkFailure,
  type TProfileView,
} from 'userProfile/store/profileView/profileView.slice';
import { getLoggedUser } from 'utils/keyCloakUtils';

export const CREATE_NEW = 'CREATE_NEW';
export const SAVE_AS = 'SAVE_AS';
export const RENAME = 'RENAME';

export type TProfileDialogType = typeof CREATE_NEW | typeof SAVE_AS | typeof RENAME;

export const INSTITUTION = 'INSTITUTION';
export const DOCUMENTS = 'DOCUMENTS';
export const COURSES = 'COURSES';
export const DEGREES = 'DEGREES';
export const EXPERIENCE_DESCRIPTION = 'DESCRIPTION';

type TOrganizationAddress = {
  city?: string;
  region: { code: string };
};

type TOrganization = {
  name?: string;
  address?: TOrganizationAddress;
};

export const fileFormatIcons: Record<string, string> = {
  IMAGE: 'IconImageSolid',
  VIDEO: 'IconPlaySolid',
  AUDIO: 'IconMusicSolid',
  DOCUMENT: 'IconTextSolid',
  OFFICE: 'IconPageSolid',
  OTHER: 'IconPageSolid',
  MODEL: 'IconPageSolid',
};

export const getProfileView =
  (id?: string | null, onSuccess?: (data: TProfileView) => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(profileViewStart());
      const { data } = await axios.get(`${API?.profileView}${getLoggedUser()}${id ? `?id=${id}` : ''}`);
      dispatch(profileViewSuccess(data));
      dispatch(setSavedProfileView(data));
      onSuccess?.(data);
    } catch (err) {
      dispatch(profileViewFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const setSelectedProfileViewId =
  (id: string, dispatchGetProfileView = true): TAppThunk =>
  async dispatch => {
    if (dispatchGetProfileView) {
      dispatch(getProfileView(id));
    }
    dispatch(setCurrentProfileViewId(id));
    localStorage.setItem('currentProfileViewId', id);
  };

export const getAllProfileViews =
  (currentProfileViewId: string | null, dispatchGetProfileView = true): TAppThunk =>
  async dispatch => {
    try {
      dispatch(allProfileViewsStart());
      const { data } = await axios.get(`${API?.profileView}${getLoggedUser()}/all`);
      dispatch(allProfileViewsSuccess(data));
      dispatch(
        setSelectedProfileViewId(
          currentProfileViewId || data.views?.find((item: TProfileView) => item.defaultView)?.id,
          dispatchGetProfileView
        )
      );
    } catch (err) {
      dispatch(allProfileViewsFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const postProfileView =
  (payload: TProfileView, onSuccess?: (id: string) => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(profileViewStart());
      const { data } = await axios.post(`${API?.profileView}${getLoggedUser()}`, payload);
      dispatch(profileViewSuccess(data));
      dispatch(setSavedProfileView(data));
      onSuccess?.(data.id);
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      dispatch(profileViewFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const deleteProfileView =
  (id: string, onSuccess?: () => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(profileViewStart());
      await axios.delete(`${API?.profileView}${getLoggedUser()}/${id}`);
      dispatch(getAllProfileViews(null));
      onSuccess?.();
    } catch (err) {
      dispatch(profileViewFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const updateSharedViews =
  (payload: { id?: string }): TAppThunk =>
  async (dispatch, getState) => {
    try {
      const id = payload.id || getState().profileView.profileViewData?.sharedViews?.[0]?.id;
      dispatch(updateLinkStart());
      const { data } = await axios.put(`${API?.profileView}${getLoggedUser()}/link`, { id });
      dispatch(updateLinkSuccess(data));
      dispatch(
        setUi({
          name: 'succesSnackbar',
          state: { open: true, message: t('livingProfile.shareMyProfile.updateLinkMessage'), hideTitle: true },
        })
      );
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      dispatch(updateLinkFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const unpublishLink =
  (payload: { id?: string }): TAppThunk =>
  async (dispatch, getState) => {
    try {
      const id = payload.id || getState().profileView.profileViewData?.sharedViews?.[0]?.id;
      dispatch(updateLinkStart());
      const { data } = await axios.put(`${API?.profileView}${getLoggedUser()}/updateLink`, { id });
      dispatch(updateLinkSuccess(data));
      dispatch(
        setUi({
          name: 'succesSnackbar',
          state: { open: true, message: t('livingProfile.shareMyProfile.unpublishLinkMessage'), hideTitle: true },
        })
      );
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      dispatch(updateLinkFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const getSharedProfileView =
  (id?: string): TAppThunk =>
  async dispatch => {
    try {
      dispatch(profileViewStart());
      const { data } = await axios.get(`${API?.profileView}readonly?id=${id}`);
      dispatch(profileViewSuccess(data));
    } catch (err) {
      dispatch(profileViewFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const formatOrganizationDetails = (organization?: TOrganization): string => {
  const { name, address } = organization || {};
  if (!name && !address) {
    return '';
  }
  if (name && address) {
    return `${name}, ${address?.city ?? ''} ${address.region.code}`;
  }
  if (name) {
    return name;
  }

  return `${address?.city ?? ''} ${address?.region.code}`;
};

export const getFirstName = (
  useGivenName: boolean | undefined,
  givenName: string | undefined,
  preferredName: string | undefined
): string => {
  if (useGivenName) {
    return givenName || '';
  }
  return preferredName || givenName || '';
};

export const formatKey = (key: string): string => {
  return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
};

export const getDialogTitleAndDescription = (
  type: TProfileDialogType | ''
): { dialogTitle: string; dialogDescription: string } => {
  let dialogTitle = '';
  let dialogDescription = '';

  switch (type) {
    case SAVE_AS: {
      dialogTitle = t('livingProfile.saveAsProfile.title');
      dialogDescription = t('livingProfile.saveAsProfile.description');
      break;
    }
    case RENAME: {
      dialogTitle = t('livingProfile.renameProfile.title');
      dialogDescription = t('livingProfile.renameProfile.description');
      break;
    }
    case CREATE_NEW: {
      dialogTitle = t('livingProfile.createProfile.title');
      dialogDescription = t('livingProfile.createProfile.description');
      break;
    }
    default:
      break;
  }

  return { dialogTitle, dialogDescription };
};

export const getAttachedCount = (attachedCount: number): string => {
  return attachedCount > 99 ? '99+' : attachedCount.toString();
};

export const handleCompetencyButtonClick = (
  id: string,
  setShowCompetencyView: Dispatch<SetStateAction<{ [key: string]: boolean }>>
): void => {
  setShowCompetencyView(prevState => {
    const newState = {
      ...prevState,
      [id]: !prevState[id],
    };
    return newState;
  });
};
