/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useMemo, useEffect, useState, useCallback } from 'react';
import { AppBar, Grid, IconButton, Typography, styled, Theme, useMediaQuery, Divider, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Accordion, IconArrowLeft, Footer } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import { MyProgramsButton } from 'transferPlanner/components/MyProgramsButton';
import { DASHBOARD, PROGRAM_PROGRESS } from 'transferPlanner/constants/routeNames';
import { nameSpace } from 'transferPlanner/constants/general';
import { useNavigate, Outlet, useParams, useLocation } from 'react-router-dom';
import { tspDeadlineExtensionTimestamp } from 'transferPlanner/store/enrollment/enrollment.selectors';
import { useImpersonation } from 'hooks/useImpersonation';
import { StyledImpersonationGrid } from 'transferPlanner/pages/CampusesAndPrograms/CampusesAndPrograms.styles';
import { GenEdUserNav, FollowedProgramNav } from 'transferPlanner/components/UserNav/GenEdUserNav';
import { FooterSection } from 'components/FooterSection';
import {
  getGenEdMilestoneProcess,
  getGeneralEducationMenus,
} from 'transferPlanner/pages/GeneralEducation/GeneralEducation.utils';
import { updateUnFollowedProgramIndex } from 'transferPlanner/store/programCart/programCart.slice';
import {
  selectFollowedPrograms,
  selectActiveFollowedPrograms,
  selectUnFollowedProgramIndex,
} from 'transferPlanner/store/programCart/programCart.selectors';
import { setUi } from 'store/ui/ui.slice';
import { StyledToolBar } from 'pages/Pages.styles';
import { encryptWithEncode, decryptWithDecode } from 'utils/forgeUtils';
import { selectTenantConfig } from 'store/common/commonInfo.selectors';
import { selectAcademicHistory } from 'store/academicHistory/academicHistory.selectors';
import { footerColor } from 'transferPlanner/constants/styles';
import { selectFeatures } from 'store/features/features.selectors';
import { selectMilestonesData } from 'transferPlanner/store/genEdProcess/genEdProcess.selectors';
import { useFixedItemInView } from 'hooks/useFixedItemInView';
import useDeadlineExtensionBanner from 'hooks/useDeadlineExtensionBanner/useDeadlineExtensionBanner';
import { getFollowedPrograms } from '../MyProgramsCart/MyProgramsCart.utils';
import MilestoneCompletedModal from '../MilestoneCompletedModal/MilestoneCompletedModal';

export const StyledPageGrid = styled(Grid)(({ theme: { breakpoints } }) => ({
  width: '100%',
  backgroundColor: 'white',
  borderTopLeftRadius: '1rem',
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  border: '2px solid #B5BCC9',
  [breakpoints.only('xs')]: {
    padding: '1rem',
    borderTopLeftRadius: '0rem',
    marginBottom: '4.5rem',
    border: 0,
  },
  boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06);',
}));

const GeneralEducation = (): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation(nameSpace);
  const { footerLinks = [], socialMediaLinks = [], copyright = '' } = useSelector(selectTenantConfig) || {};
  const impersonationToken = useImpersonation();
  const tspDeadlineExtensionTime = useSelector(tspDeadlineExtensionTimestamp);
  const followedPrograms = useSelector(selectFollowedPrograms);
  const activeFollowedPrograms = useSelector(selectActiveFollowedPrograms);
  const unFollowedProgramIndex = useSelector(selectUnFollowedProgramIndex);
  const academicHistoryData = useSelector(selectAcademicHistory);
  const milestoneData = useSelector(selectMilestonesData);
  const features = useSelector(selectFeatures);
  const { isFixed } = useFixedItemInView();
  const shouldDisplayTheBanner = useDeadlineExtensionBanner();

  const genEdMenus = getGeneralEducationMenus(features);
  const dispatch = useDispatch();
  const { id: param } = useParams();
  /* istanbul ignore next */
  const programId = useMemo(() => decryptWithDecode(param || ''), [param]);
  const [activeProgram, setActiveProgram] = useState<string>(programId);
  const { pathname } = useLocation();
  const isProgramProgressScreen = useMemo(() => pathname.startsWith(`/${PROGRAM_PROGRESS}`), [pathname]);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const isImpersonated = useMemo(() => (!!impersonationToken).toString(), [impersonationToken]);

  useEffect(() => {
    if (academicHistoryData) {
      dispatch(getGenEdMilestoneProcess());
    }
  }, [dispatch, academicHistoryData]);

  useEffect(() => {
    if (!followedPrograms) {
      dispatch(getFollowedPrograms());
    }
  }, [followedPrograms, dispatch]);

  const handleItemClick = useCallback(
    (id: string) => {
      setActiveProgram(id);
      dispatch(setUi({ name: 'footerModal', state: { isOpen: false } }));
      navigate(`/${PROGRAM_PROGRESS}/${encryptWithEncode(id)}`);
      window.scrollTo(0, 0);
    },
    [navigate, dispatch]
  );

  /* istanbul ignore next */
  useEffect(() => {
    if (isProgramProgressScreen) {
      if (
        unFollowedProgramIndex != null &&
        unFollowedProgramIndex >= 0 &&
        unFollowedProgramIndex <= activeFollowedPrograms?.length
      ) {
        const index = activeFollowedPrograms?.length === unFollowedProgramIndex ? 0 : unFollowedProgramIndex;
        handleItemClick(`${activeFollowedPrograms?.[index]?.program.id || ''}`);
        dispatch(updateUnFollowedProgramIndex(null));
      }

      if (programId && activeProgram !== programId) {
        handleItemClick(programId);
      }

      if (!programId && activeFollowedPrograms?.length) {
        handleItemClick(`${activeFollowedPrograms?.[0]?.program.id || ''}`);
      }
    } else {
      setActiveProgram('');
    }
  }, [
    isProgramProgressScreen,
    programId,
    handleItemClick,
    activeFollowedPrograms,
    activeProgram,
    unFollowedProgramIndex,
    dispatch,
  ]);

  return (
    <>
      {milestoneData && <MilestoneCompletedModal milestones={milestoneData} />}
      <StyledImpersonationGrid
        impersonation={isImpersonated}
        tspdeadlineextensiontime={tspDeadlineExtensionTime || ''}
        iscampusesandprograms="true"
      >
        <AppBar position={isMobile && (impersonationToken || shouldDisplayTheBanner) && isFixed ? 'fixed' : 'static'}>
          <StyledToolBar variant="dense">
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid
                item
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <IconButton
                  edge="start"
                  aria-label="back to dashboard"
                  sx={{
                    ml: '-1.1rem',
                    mr: { xs: -0.5, sm: 'auto' },
                    color: (theme: Theme) => theme.palette.common.black,
                  }}
                  onClick={() => {
                    navigate(`/${DASHBOARD}`);
                  }}
                >
                  <IconArrowLeft />
                </IconButton>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ flexGrow: 1, display: { sm: 'block' } }}
                  role="heading"
                  aria-level={1}
                >
                  {t('generalEducation.transferPlan')}
                </Typography>
              </Grid>
              <Grid item>
                <MyProgramsButton />
              </Grid>
            </Grid>
          </StyledToolBar>
        </AppBar>
      </StyledImpersonationGrid>

      <Grid container direction="column">
        <Grid sx={{ height: 'calc( 105vh - 48px )' }} container direction="row" component="main">
          <Grid
            container
            component="section"
            sx={{
              bgcolor: theme => theme.palette.primary.main,
              padding: ['0 0 0 0', '0 0 0 80px', '0 0 0 80px'],
              flexDirection: 'row',
              flexWrap: 'nowrap',
            }}
          >
            {!isMobile ? (
              <Grid container direction="column" sx={{ width: '25%', mr: 2, mt: 1 }}>
                <Typography variant="h5" sx={{ mb: 2 }} aria-level={2}>
                  {t('generalEducation.transferPlan_heading')}
                </Typography>
                <Box>
                  <Accordion
                    sx={{
                      background: 'none',
                      border: 0,
                      '& .MuiAccordionSummary-root': { p: 0, minHeight: '1rem' },
                      '& .MuiAccordionDetails-root': { p: 0 },
                      '& .MuiAccordionSummary-content': { m: 0 },
                    }}
                    accordionHeader={
                      <Typography variant="h6" aria-level={3}>
                        {t('overview_label')}
                      </Typography>
                    }
                    accordionSummary={<GenEdUserNav menusList={genEdMenus} />}
                    expanded
                    label={t('overview_label')}
                  />
                </Box>

                <Divider sx={{ borderStyle: 'solid', mt: 1, mb: 1 }} flexItem />

                <Box>
                  <Accordion
                    sx={{
                      background: 'none',
                      border: 0,
                      '& .MuiAccordionSummary-root': { p: 0, minHeight: '1rem' },
                      '& .MuiAccordionDetails-root': { p: 0 },
                      '& .MuiAccordionSummary-content': { m: 0 },
                    }}
                    accordionHeader={
                      <Typography variant="h6" aria-level={3}>
                        {t('followed.programs')} ({activeFollowedPrograms?.length || 0})
                      </Typography>
                    }
                    accordionSummary={
                      <FollowedProgramNav
                        followedPrograms={activeFollowedPrograms}
                        activeProgram={activeProgram}
                        handleItemClick={handleItemClick}
                      />
                    }
                    expanded
                    label={`${t('followed.programs')} (${activeFollowedPrograms?.length || 0})`}
                    id="followedProgramsId"
                  />
                </Box>
              </Grid>
            ) : null}
            <StyledPageGrid>
              <Outlet />
              <Footer
                copyrightDangerouslySetInnerHTML={{
                  __html: copyright,
                }}
                footerLinks={footerLinks}
                socialLinks={socialMediaLinks}
                svgIconProps={{ sx: { color: (theme: Theme) => theme.palette.text.primary } }}
                sxTextProps={{ fontSize: '0.75rem', color: `${footerColor} !important` }}
              />
            </StyledPageGrid>
          </Grid>
        </Grid>
        {isMobile && (
          <FooterSection title={t('generalEducation.transferPlan')} showCancel={true} footerSectionMenus={[]}>
            <Box>
              <Accordion
                sx={{
                  background: 'none',
                  border: 0,
                  '& .MuiAccordionSummary-root': { p: 0, minHeight: '1rem' },
                  '& .MuiAccordionDetails-root': { p: 0 },
                  '& .MuiAccordionSummary-content': { m: 0 },
                }}
                accordionHeader={
                  <Typography variant="subtitle2" component="span">
                    {t('overview_label')}
                  </Typography>
                }
                accordionSummary={<GenEdUserNav menusList={genEdMenus} />}
                expanded
                label={t('overview_label')}
              />
            </Box>

            <Divider sx={{ borderStyle: 'solid', mt: 1, mb: 1 }} flexItem />

            <Box>
              <Accordion
                sx={{
                  background: 'none',
                  border: 0,
                  '& .MuiAccordionSummary-root': { p: 0, minHeight: '1rem' },
                  '& .MuiAccordionDetails-root': { p: 0 },
                  '& .MuiAccordionSummary-content': { m: 0 },
                }}
                accordionHeader={
                  <Typography variant="subtitle2" component="span">
                    {t('followed.programs')} ({activeFollowedPrograms?.length || 0})
                  </Typography>
                }
                accordionSummary={
                  <FollowedProgramNav
                    followedPrograms={activeFollowedPrograms}
                    activeProgram={activeProgram}
                    handleItemClick={handleItemClick}
                  />
                }
                expanded
                label={`${t('followed.programs')} (${activeFollowedPrograms?.length || 0})`}
                id="followedProgramsId"
              />
            </Box>
          </FooterSection>
        )}
      </Grid>
    </>
  );
};

export default GeneralEducation;
