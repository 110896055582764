/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';
import { IProgramCartState, ISelectedProgram, TFollowedProgramModified } from './programCart.slice';

export const selectProgramCart = (state: TRootState): IProgramCartState => state.programCart;

export const selectSelectedProgramCart = createSelector(
  selectProgramCart,
  (programCartState: IProgramCartState) => programCartState?.programCart?.selectedPrograms
);

export const selectFollowedPrograms = createSelector(
  selectProgramCart,
  (programCartState: IProgramCartState) => programCartState?.followedPrograms
);

export const selectActiveFollowedPrograms = createSelector(selectProgramCart, (programCartState: IProgramCartState) =>
  (programCartState?.followedPrograms || [])?.filter(({ program }) => program?.tracking?.active)
);

export const selectFollowedActualPrograms = createSelector(
  selectProgramCart,
  (programCartState: IProgramCartState) => programCartState?.followedActualPrograms
);

export const selectInActiveSelectedPrograms = createSelector(
  selectProgramCart,
  (programCartState: IProgramCartState): ISelectedProgram[] => {
    let filteredProgramCart = [];
    const programCart = programCartState?.programCart?.selectedPrograms;
    if (!programCartState?.followedPrograms) {
      filteredProgramCart = [...(programCart || [])];
      return filteredProgramCart;
    }
    const activeFollowedPrograms = programCartState?.followedPrograms?.filter(
      ({ program }) => program?.tracking?.active
    );
    filteredProgramCart = (programCart || [])?.filter(savedPrograms => {
      const index = activeFollowedPrograms?.findIndex(
        (activeFollowedProgram: TFollowedProgramModified) =>
          activeFollowedProgram.program.id === savedPrograms.program.id
      );
      return index === -1;
    });
    return filteredProgramCart;
  }
);

export const selectUnFollowedProgramIndex = createSelector(
  selectProgramCart,
  (programCartState: IProgramCartState) => programCartState?.unFollowedProgramIndex
);
