/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */
import React, { ReactElement, useState } from 'react';
import { Grid, Box, Typography, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CollapsibleSection as Accordion, IconFiles, IconLightbulb, IconCancel } from '@liaison/liaison-ui';
import { StyledButton } from 'pages/Pages.styles';
import {
  sxButtonCompetenciesProfileView,
  sxButtonProfileView,
} from 'userProfile/components/AttachedFiles/AttachedFiles.styles';
import { MultiValuedView } from 'userProfile/components/ViewBuilder/CardView';
import type { TGradeLevel, THighSchoolCourse } from 'store/academicHistory/academicHistory.slice';
import { getAttachedCount, handleCompetencyButtonClick } from '../LivingProfile.utils';

export const HighSchoolCourses = ({ gradeLevels }: { gradeLevels: TGradeLevel[] }): ReactElement => {
  const { t } = useTranslation();
  const [showCompetencyView, setShowCompetencyView] = useState<{ [key: string]: boolean }>({});

  return (
    <>
      {gradeLevels?.map((gradeLevel: TGradeLevel) => (
        <React.Fragment key={gradeLevel.id}>
          <Grid item xs={12} sx={{ mt: '.5rem' }}>
            <Accordion
              expanded
              groupId={gradeLevel.id as string}
              title={`${gradeLevel?.type?.displayName} ${gradeLevel?.academicYear} ${t('grade')}`}
            >
              <Box>
                {gradeLevel?.courses?.map((course: THighSchoolCourse) => (
                  <Grid item xs={12} sm={12} key={`${course.title}-${course.grade?.overall}`} sx={{ mb: 2 }}>
                    <Card variant="outlined" sx={{ borderRadius: 2 }}>
                      <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                        <Grid container spacing={0} sx={{ p: 1 }}>
                          <Grid item xs={10} sm={10} md={11} lg={11} sx={{ mr: '0.5rem', display: 'flex' }}>
                            <Box sx={{ flex: 1 }}>
                              <Typography variant="subtitle3" sx={{ overflowWrap: 'anywhere' }}>
                                {course.title}
                              </Typography>
                              <Box sx={{ mt: -0.5 }}>
                                <Typography variant="caption" sx={{ overflowWrap: 'anywhere' }}>
                                  {course.grade?.overall
                                    ? `${t('grade')}: ${course.grade?.overall}`
                                    : `${t('grade')}: ${t('noGradeProvided')}`}
                                </Typography>
                              </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                              {!!course.attachedCount && (
                                <StyledButton
                                  variant="contained"
                                  startIcon={<IconFiles />}
                                  size="medium"
                                  fullWidth
                                  aria-label="attachments"
                                  sx={{ ...sxButtonProfileView, width: '50px', marginLeft: '10px' }}
                                >
                                  {getAttachedCount(course?.attachedCount)}
                                </StyledButton>
                              )}
                              {!!course.competencyAttachedCount && (
                                <StyledButton
                                  variant="contained"
                                  startIcon={showCompetencyView[course.id] ? <IconCancel /> : <IconLightbulb />}
                                  size="medium"
                                  fullWidth
                                  aria-label="attachments"
                                  sx={{ ...sxButtonCompetenciesProfileView, width: '50px', marginLeft: '10px' }}
                                  onClick={() => handleCompetencyButtonClick(course.id, setShowCompetencyView)}
                                >
                                  {getAttachedCount(course?.competencyAttachedCount)}
                                </StyledButton>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                        {showCompetencyView[course.id] && (
                          <Grid item xs={12}>
                            <Box sx={{ mt: 1, ml: 1 }}>
                              <Typography sx={{ mt: 2 }} variant="subtitle3">
                                {t('competenciesAndSkills.competencies')}
                              </Typography>
                              <MultiValuedView
                                multiValued={
                                  course?.competencyAttachments?.map(attachment => attachment.competency) || []
                                }
                              />
                            </Box>
                          </Grid>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Box>
            </Accordion>
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
};
