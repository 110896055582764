/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { type ReactElement } from 'react';
import { Grid, useMediaQuery, type Theme } from '@mui/material';
import { useLocation } from 'react-router';
import { Outlet } from 'react-router-dom';

import { FooterButtons } from 'userProfile/pages/LivingProfile/FooterButtons';
import { LivingProfileHeader } from '../LivingProfileHeader';

const LivingProfilePageLayout = (): ReactElement => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const location = useLocation();
  const isSharedProfileView = location.pathname.includes('shared-profile-view');

  return (
    <Grid container direction="column">
      <LivingProfileHeader />
      <Grid
        sx={{ height: '100vh', mt: { xs: isSharedProfileView ? '56px' : 0, sm: '88px' } }}
        container
        component="main"
        spacing={0}
      >
        <Grid
          item
          xs={12}
          component="section"
          sx={{
            bgcolor: theme => theme.palette.primary.main,
            padding: ['0 0 0 0', isSharedProfileView ? '0 0 0 0' : '0 0 0 60px'],
          }}
        >
          <Outlet />
        </Grid>
      </Grid>
      {isMobile && !isSharedProfileView && <FooterButtons />}
    </Grid>
  );
};

export default LivingProfilePageLayout;
