/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { useState, createContext, useContext } from 'react';

export const FormContext = createContext<unknown | undefined>(undefined);

interface NewPartyProviderProps {
  children: React.ReactNode;
  defaultValue?: Record<string, unknown>;
}
export default function FormProvider({ children, defaultValue = {} }: NewPartyProviderProps): React.ReactElement {
  const [data, setData] = useState(defaultValue);

  const setFormValues = (values: {}) => {
    setData(prevValues => ({
      ...prevValues,
      ...values,
    }));
  };

  return <FormContext.Provider value={{ data, setFormValues }}>{children}</FormContext.Provider>;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useFormData = (): React.Context => useContext(FormContext);
