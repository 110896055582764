/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import AvatarEditor from 'react-avatar-editor';
import invert from 'lodash.invert';
import heic2any from 'heic2any';
import {
  InputLabel,
  Grid,
  TableContainer,
  formControlLabelClasses,
  useMediaQuery,
  Typography,
  type Theme,
} from '@mui/material';
import { FileDropzone, SidePanel } from '@liaison/liaison-ui';
import { sxSidePanel, sxSidePanelMobile } from 'pages/Pages.styles';
import { RadioControl } from 'components/RadioControl';
import { getButtonColor } from 'utils/utilities';
import { Spinner } from 'components/Spinner';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { setUi } from 'store/ui/ui.slice';
import { selectAvatarSidebar } from 'store/ui/ui.selectors';
import { selectMediaDocs } from 'userProfile/store/mediaDocuments/mediaDocuments.selectors';
import { ISO_DATE_REG } from 'constants/regex';
import {
  getMediaDocs,
  fileValidator,
  mediaTypes,
  initiateUploadMediaDoc,
  uploadMediaDoc,
  updateMediaDocUploadStatus,
  uploadStatus,
  updateMediaDoc,
  deleteMediaDoc,
  type IFileRejection,
} from 'userProfile/pages/MediaDocuments/MediaDocuments.utils';
import type { IMediaDoc } from 'userProfile/store/mediaDocuments/mediaDocuments.slice';
import { selectPersonalInformation } from 'userProfile/store/personalInfo/personalInfo.selectors';
import { postPersonalInformation } from 'userProfile/pages/PersonalInformation/Personalnformation.utils';
import { appleFormats } from 'userProfile/constants/general';

import { ImageEditor } from '../ImageEditor';
import { ImageList } from '../ImageList';
import { EFileSource } from '../ImageList/ImageList.utils';
import {
  acceptedFileFormats,
  PROFILE_PICTURE,
  acceptedAvatarImageFormats,
  DEFAULT_AVATAR,
  fileSourceOptions,
} from './AvatarSidePanel.utils';

type TAvatarFormData = {
  fileSource: EFileSource;
  imageFileId: string;
};

export const AvatarSidePanel = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { open } = useSelector(selectAvatarSidebar);
  const mediaDocs = useSelector(selectMediaDocs);
  const personalInformation = useSelector(selectPersonalInformation);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const sxForMobileSidePanel = isMobile ? { ...sxSidePanel, ...sxSidePanelMobile } : sxSidePanel;
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [image, setImage] = useState('');
  const [isEdited, setIsEdited] = useState(false);
  const [imageType, setImageType] = useState<string | undefined>('');
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [selectedMediaDoc, setSelectedMediaDoc] = useState<IMediaDoc>();

  const editorRef = useRef(null);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      fileSource: EFileSource.EXISTING,
      imageFileId: DEFAULT_AVATAR.id,
    },
  });
  const { control, watch, setValue, register } = methods;

  const watchAvatarFileId = watch('imageFileId');

  useEffect(() => {
    if (personalInformation?.avatarFileId && mediaDocs?.some(doc => doc.id === personalInformation?.avatarFileId)) {
      setValue('imageFileId', personalInformation.avatarFileId);
    } else {
      setValue('imageFileId', DEFAULT_AVATAR.id);
    }
  }, [personalInformation, mediaDocs, setValue]);

  const isFromExistingMedia = watch('fileSource') === EFileSource.EXISTING;

  const otherAvatarOptions = useMemo(
    /* istanbul ignore next */
    () => [
      ...(mediaDocs || []).filter(
        m =>
          m.shareable &&
          m.name &&
          m.type?.code !== PROFILE_PICTURE &&
          acceptedAvatarImageFormats.includes(m.contentType as string)
      ),
    ],
    [mediaDocs]
  );
  const previouslySelectedAvatarOptions = useMemo(
    /* istanbul ignore next */
    () => [...(mediaDocs || []).filter(m => m.type?.code === PROFILE_PICTURE), DEFAULT_AVATAR],
    [mediaDocs]
  );

  const reset = () => {
    setUploadedFiles([]);
    setImage('');
    setImageType('');
    setIsEdited(false);
  };

  const onClose = () => {
    /* istanbul ignore next */
    if (isFromExistingMedia && image) {
      reset();
      return;
    }
    dispatch(setUi({ name: 'avatarSidebar', state: { open: false } }));
    reset();
    setValue('fileSource', EFileSource.EXISTING);
    if (personalInformation?.avatarFileId) {
      setValue('imageFileId', personalInformation.avatarFileId);
    } else {
      setValue('imageFileId', DEFAULT_AVATAR.id);
    }
  };

  const dispatchSuccessSnackbar = (message: string, hideTitle: boolean) => {
    dispatch(
      setUi({
        name: 'succesSnackbar',
        state: { open: true, message, hideTitle },
      })
    );
  };

  const onSubmit = ({ imageFileId }: TAvatarFormData) => {
    if (imageFileId === DEFAULT_AVATAR.id) {
      const newPersonalInformation = { ...personalInformation };
      delete newPersonalInformation.avatarFileId;
      dispatch(
        postPersonalInformation(newPersonalInformation, () => {
          onClose();
          dispatchSuccessSnackbar(t('success_message'), true);
        })
      );
      return;
    }
    const payload = {
      ...personalInformation,
      avatarFileId: imageFileId,
    };

    const mediaDoc = mediaDocs?.find(m => m.id === imageFileId);

    dispatch(
      updateMediaDoc(
        {
          ...mediaDoc,
          type: {
            code: PROFILE_PICTURE,
            displayName: 'Profile Picture',
          },
        },
        undefined,
        false,
        () => {
          dispatch(
            postPersonalInformation(payload, () => {
              onClose();
              dispatchSuccessSnackbar(t('success_message'), true);
            })
          );
        }
      )
    );
  };

  const handleInitiateFileUpload = async (acceptedFiles: File[], fileRejections: IFileRejection[]) => {
    if (fileRejections?.length) {
      /* istanbul ignore next */
      dispatch(
        setUi({
          name: 'errorSnackbar',
          state: {
            open: true,
            title: t('mediaDocuments.fileUpload.error'),
            message: fileRejections[0].errors[0].message,
          },
        })
      );
      /* istanbul ignore next */
      return;
    }
    let newFile = acceptedFiles[0];
    if (appleFormats.includes(acceptedFiles[0].type)) {
      setIsFileUploading(true);
      const blob = (await heic2any({ blob: acceptedFiles[0], toType: 'image/jpeg', quality: 0.5 })) as Blob;
      /* istanbul ignore next */
      newFile = new File([blob], `${acceptedFiles[0].name.split('.').shift()}.jpeg`, {
        type: blob.type,
      });
      /* istanbul ignore next */
      setIsFileUploading(false);
    }
    setUploadedFiles([newFile]);
    const url = URL.createObjectURL(newFile);
    setImage(url);
  };

  const onEdit = async (doc: IMediaDoc) => {
    const originalDoc = doc as Required<IMediaDoc>;
    const res = await fetch(originalDoc.ephemeralURL);
    const resBlob = await res.blob();
    let blob = resBlob;
    if (appleFormats.includes(resBlob.type)) {
      setIsFileUploading(true);
      blob = (await heic2any({ blob: resBlob, toType: 'image/jpeg', quality: 0.5 })) as Blob;
      /* istanbul ignore next */
      setIsFileUploading(false);
    }
    const newName = `${originalDoc.name.replace(ISO_DATE_REG, '').split('.').shift()}.${blob.type.split('/').pop()}`;
    const file = new File([blob], newName, { type: blob.type });
    setUploadedFiles([file]);
    setImageType(doc.type?.code);
    const url = URL.createObjectURL(file);
    setImage(url);
  };

  const onDelete = (doc: IMediaDoc) => {
    setSelectedMediaDoc(doc);
    setShowConfirmationDialog(true);
  };

  const deleteFile = () => {
    dispatch(
      deleteMediaDoc(selectedMediaDoc?.id as string, () => {
        dispatch(
          getMediaDocs(() => {
            if (watchAvatarFileId === selectedMediaDoc?.id) {
              const newPersonalInformation = { ...personalInformation };
              delete newPersonalInformation.avatarFileId;
              dispatch(postPersonalInformation(newPersonalInformation));
            }
            dispatchSuccessSnackbar(t('mediaDocuments.fileDelete.success'), true);
            if (selectedMediaDoc?.id === personalInformation?.avatarFileId) {
              /* istanbul ignore next */
              setValue('imageFileId', DEFAULT_AVATAR.id);
            }
          })
        );
      })
    );
    setShowConfirmationDialog(false);
  };

  const handleSave = async () => {
    const dataUrl = (editorRef?.current as unknown as AvatarEditor)?.getImageScaledToCanvas().toDataURL();
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    const originalFile = uploadedFiles[0];
    const name = originalFile?.name?.split('.').shift();
    const extension = originalFile?.name?.split('.').pop();
    /* istanbul ignore next */
    const contentType = originalFile.type || invert(acceptedFileFormats)[`.${extension}`];
    const isoTime = new Date().toISOString();
    const fileName = `${name}_${isoTime}.${extension}`;
    const file = new File([blob], fileName, { type: originalFile.type });
    const payload = {
      fileName,
      size: file.size,
      contentType,
      mediaType: mediaTypes[contentType.split('/')[1]],
    };
    setIsFileUploading(true);
    dispatch(
      /* istanbul ignore next */
      initiateUploadMediaDoc(
        payload,
        /* istanbul ignore next */
        (id, ephemeralURL) => {
          dispatch(
            uploadMediaDoc(
              file,
              ephemeralURL,
              () => {
                dispatch(
                  updateMediaDocUploadStatus(id, uploadStatus.SUCCESS, () => {
                    dispatch(
                      updateMediaDoc(
                        {
                          id,
                          name: fileName,
                          description: `Avatar ${isoTime}`,
                          shareable: false,
                          type: {
                            code: PROFILE_PICTURE,
                            displayName: 'Profile Picture',
                          },
                        },
                        undefined,
                        false,
                        () => {
                          setIsFileUploading(false);
                          dispatch(
                            getMediaDocs(() => {
                              onClose();
                              dispatch(postPersonalInformation({ ...personalInformation, avatarFileId: id }));
                            })
                          );
                        }
                      )
                    );
                  })
                );
              },
              () => {
                dispatch(updateMediaDocUploadStatus(id, uploadStatus.FAILED));
              }
            )
          );
        }
      )
    );
  };

  return (
    <SidePanel
      size="small"
      title={t('profileImage.title')}
      open={open}
      onClose={onClose}
      isBackdropClickEnabled={true}
      sx={sxForMobileSidePanel}
      footerButtonConfig={{
        primary: {
          title: t('save_label'),
          props: {
            'aria-label': t('save_label'),
            variant: 'contained',
            disabled:
              !methods.formState.isValid ||
              (!image && watchAvatarFileId === personalInformation?.avatarFileId) ||
              (!image && !personalInformation?.avatarFileId && watchAvatarFileId === DEFAULT_AVATAR.id) ||
              (!isFromExistingMedia && !uploadedFiles.length) ||
              /* istanbul ignore next */
              (isFromExistingMedia && imageType === PROFILE_PICTURE && !isEdited),
            color: getButtonColor(),
            onClick: isFromExistingMedia && !image ? methods.handleSubmit(onSubmit) : handleSave,
          },
        },
        tertiary: {
          title: t('cancel_label'),
          props: {
            color: getButtonColor(),
            'aria-label': t('cancel_label'),
            onClick: onClose,
          },
        },
      }}
    >
      {isFileUploading && <Spinner backdrop />}
      <FormProvider {...methods}>
        <form>
          <Grid container rowSpacing={2}>
            <Grid item container xs={12} rowSpacing={2} alignItems="center">
              <Grid item xs={12}>
                <Controller
                  name="fileSource"
                  render={({ field: { ref, onChange, ...field } }) => (
                    <RadioControl
                      {...field}
                      inputRef={ref}
                      id="fileSource"
                      options={fileSourceOptions}
                      onChange={event => {
                        setUploadedFiles([]);
                        setImage('');
                        onChange(event.target.value);
                      }}
                      inline
                      sx={{
                        [`& .${formControlLabelClasses.root}`]: {
                          flexGrow: 1,
                          justifyContent: 'center',
                          [`& .${formControlLabelClasses.label}`]: {
                            fontWeight: 800,
                          },
                        },
                      }}
                    />
                  )}
                  control={control}
                  defaultValue={EFileSource.EXISTING}
                />
              </Grid>
              <Grid item xs={12}>
                {isFromExistingMedia && !image && (
                  <TableContainer>
                    <InputLabel htmlFor="avatarFileId" sx={{ mt: 3, mb: 1 }}>
                      {t('mediaDocuments.avatarUpload.label.chooseExisting')}
                    </InputLabel>
                    <ImageList
                      options={previouslySelectedAvatarOptions}
                      imageFileId={watchAvatarFileId}
                      register={register}
                      hasRadioButton
                      onEdit={onEdit}
                      onDelete={onDelete}
                    />
                    <InputLabel htmlFor="avatarFileId" sx={{ mt: 3, mb: 1 }}>
                      {t('mediaDocuments.avatarUpload.label.chooseFromLibrary')}
                    </InputLabel>
                    <ImageList options={otherAvatarOptions} onEdit={onEdit} />
                  </TableContainer>
                )}
                {!isFromExistingMedia && !uploadedFiles.length && (
                  <>
                    <Typography variant="body2">{t('profileImage.instructions')}</Typography>
                    <FileDropzone
                      acceptedFileFormats={acceptedFileFormats}
                      handleChange={handleInitiateFileUpload}
                      customValidator={fileValidator}
                      uploadedFiles={uploadedFiles}
                      previewFiles
                      maxFiles={1}
                    />
                  </>
                )}
                {!!image && (
                  <ImageEditor
                    image={image}
                    setIsEdited={setIsEdited}
                    editorRef={editorRef}
                    width={200}
                    height={200}
                    borderRadius={100}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <ConfirmationDialog
        open={showConfirmationDialog}
        text={t('mediaDocuments.fileDelete.message', { fileName: selectedMediaDoc?.name })}
        confirmationText={t('mediaDocuments.fileDelete.confirmationText')}
        onClose={() => {
          setShowConfirmationDialog(false);
        }}
        footerButtonConfig={{
          primary: {
            title: t('delete_label'),
            props: {
              onClick: deleteFile,
            },
          },
          tertiary: {
            title: t('cancel_label'),
          },
        }}
      />
    </SidePanel>
  );
};
