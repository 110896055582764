/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { useState, ReactElement, useEffect } from 'react';
import { IconCancel, IconTrash } from '@liaison/liaison-ui';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
  IconButton,
  ButtonProps,
} from '@mui/material';
import { sanitize } from 'dompurify';
import { DEFAULT_SANITIZE_CONFIG } from 'constants/sanitize';

type TButtonConfig = {
  title: string;
  props?: ButtonProps;
};
interface IFooterProps {
  primary?: TButtonConfig;
  tertiary?: TButtonConfig;
}

interface IConfirmationDialogProps {
  open: boolean;
  header?: string;
  text: string;
  info?: string;
  confirmationText?: string;
  onClose?: (flag: boolean) => void;
  footerButtonConfig?: IFooterProps;
}

const ConfirmationDialog = ({
  open,
  header,
  text,
  info,
  confirmationText,
  onClose,
  footerButtonConfig: { primary, tertiary } = {},
}: IConfirmationDialogProps): React.ReactElement => {
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    if (open) setIsConfirmed(false);
  }, [open]);

  const handleClose = () => {
    onClose?.(!open);
  };

  const handleCheckboxClick = () => {
    setIsConfirmed(!isConfirmed);
  };

  const getButton = ({ title, ...props }: ButtonProps & { title: string }): ReactElement => (
    <Button {...props}>{title}</Button>
  );

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      role="document"
      scroll="body"
      aria-labelledby="dialog-modal-title"
      aria-describedby="dialog-modal-description"
      maxWidth="sm"
      fullWidth
      sx={{ [`.${dialogClasses.paper}`]: { borderRadius: '1rem' } }}
    >
      <Box
        sx={{
          display: 'flex',
          py: '1rem',
          px: ['1rem', '1.5rem', '2.5rem'],
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography id="dialog-modal-title" variant="h4" component="h1">
          {header || 'Are you sure?'}
        </Typography>
        {onClose && (
          <IconButton aria-label="close" size="small" onClick={handleClose} sx={{ height: 'fit-content' }}>
            <IconCancel />
          </IconButton>
        )}
      </Box>
      <Divider sx={{ borderStyle: 'solid' }} />
      <DialogContent sx={{ py: '2rem', px: ['1rem', '1.5rem', '2.5rem'] }}>
        <Typography
          variant="body2"
          id="dialog-modal-description"
          dangerouslySetInnerHTML={{ __html: sanitize(text, DEFAULT_SANITIZE_CONFIG) }}
        />
        {confirmationText && (
          <>
            <Typography
              variant="body2"
              id="dialog-modal-state"
              dangerouslySetInnerHTML={{
                __html: sanitize(info || 'This action cannot be undone.', DEFAULT_SANITIZE_CONFIG),
              }}
              sx={{ mb: '1rem', fontWeight: 'bold' }}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id="confirmation_checkbox"
                    name="confirmation_checkbox"
                    checked={isConfirmed}
                    onClick={handleCheckboxClick}
                  />
                }
                label={confirmationText}
                sx={{ ml: 0 }}
              />
            </FormGroup>
          </>
        )}
      </DialogContent>
      <Divider sx={{ borderStyle: 'solid' }} />
      <DialogActions
        sx={{
          justifyContent: tertiary && primary ? 'space-between' : 'flex-end',
          py: '1rem',
          px: ['1rem', '1.5rem', '2.5rem'],
        }}
      >
        {tertiary &&
          getButton({
            color: 'error',
            variant: 'text',
            title: tertiary.title,
            onClick: handleClose,
            ...tertiary.props,
          })}
        {primary &&
          getButton({
            color: 'error',
            variant: 'contained',
            title: primary.title,
            disabled: !!confirmationText && !isConfirmed,
            startIcon: <IconTrash />,
            sx: {
              marginLeft: '1rem',
              ...primary.props?.sx,
            },
            ...primary.props,
          })}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
