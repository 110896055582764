/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo } from 'react';
import { Typography, Box, IconButton, Tooltip, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'transferPlanner/constants/general';
import { CircularProgress, IconAlertTriangleColor } from '@liaison/liaison-ui';
import { getAreaBDetails, IGenEdAccordianHeaderProps } from './GeneralEducation.utils';

const GenEdAccordianHeading = ({ config, categoryDetail }: IGenEdAccordianHeaderProps): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const isAreaB = config?.code === 'Area B';
  let bRequirements;
  let areaBStatus;
  if (isAreaB) {
    const areaBDetails = getAreaBDetails(categoryDetail);
    bRequirements = areaBDetails.bRequirements;
    areaBStatus = areaBDetails.areaBStatus;
  }
  const progressValue = `${categoryDetail?.unitsEarned || 0}/${config?.courseUnits} ${t(
    'generalEducationReqTable.units'
  )}`;
  return (
    <Grid container spacing={1}>
      <Grid item xs={6.5} sm={9} md={8} lg={9} xl={9.5}>
        <Typography variant="subtitle4">{`${config?.code} - ${config?.category}`}</Typography>
      </Grid>
      <Grid item xs={5.5} sm={3} md={3.5} lg={2.5} xl={2} sx={{ display: { xs: 'flex', sm: 'block' } }}>
        <Box sx={{ display: 'flex', alignItems: 'center', float: 'right' }}>
          {categoryDetail?.code === 'Area B' &&
            (bRequirements?.B3 === 'optOut' || bRequirements?.B3 === 'notComplete') &&
            config?.courseUnits <= (categoryDetail?.unitsEarned || 0) && (
              <Tooltip title={t('generalEducation.b3LabActivity.alert_message')} placement="top">
                <IconButton sx={{ p: 0, pr: 1.2 }}>
                  <IconAlertTriangleColor />
                </IconButton>
              </Tooltip>
            )}
          <CircularProgress
            barColor="#b6bac0"
            progress={categoryDetail?.completionPercentage || 0}
            size="small"
            strokeColor="#43A047"
            thickness="large"
            withRoundedStroke
            showCheckIcon={!areaBStatus}
          />
          <Typography sx={{ ml: 2 }} variant="subtitle4" aria-label={progressValue} tabIndex={0}>
            {progressValue}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default memo(GenEdAccordianHeading);
