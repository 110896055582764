/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { sxCard } from 'pages/Pages.styles';
import { CSSProperties } from 'react';

export const sxDashboardItem = {
  ...sxCard,
  borderRadius: 4,
  bgcolor: 'secondary.contrastText',
  overflow: 'hidden',
};
export const sxSlider = (): Record<string, CSSProperties> => ({
  // .slick-slide > div
  [`&& .slick-slide > div`]: {
    margin: '0 10px',
  },

  // .slick-track
  [`&& .slick-track`]: {
    width: '3500px !important', // It's advisable to avoid !important if possible
  },

  // .slick-slide
  [`&& .slick-slide`]: {
    width: 'calc(100vw - 60px)',
  },

  // .slick-list
  [`&& .slick-list`]: {
    margin: '0 -10px',
  },

  // .slick-dots li button
  [`&& .slick-dots li button`]: {
    display: 'block',
    width: '12px !important', // It's advisable to avoid !important if possible
    height: '12px !important', // It's advisable to avoid !important if possible
    padding: '0 !important', // It's advisable to avoid !important if possible
    border: 'none',
    borderRadius: '100%',
    backgroundColor: '#8996a7 !important', // It's advisable to avoid !important if possible
    textIndent: '-9999px',
  },

  // .slick-dots li.slick-active button
  [`&& .slick-dots li.slick-active button`]: {
    backgroundColor: '#2e7d32 !important', // It's advisable to avoid !important if possible
    outline: 'none !important', // It's advisable to avoid !important if possible
  },

  // .slick-dots li button:focus-visible:not(:active)
  [`&& .slick-dots li button:focus-visible:not(:active)`]: {
    outline: '2px solid #0000008a',
    outlineOffset: '2px',
  },
});
