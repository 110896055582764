/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useMemo, useEffect } from 'react';
import { FormControl, Grid, Typography, InputLabel, FormHelperText, InputBase } from '@mui/material';
import { Dropdown, DateField } from '@liaison/liaison-ui';
import { useFormContext, Controller } from 'react-hook-form';
import { TBiographicalInfoField } from 'userProfile/store/biographicalInfo/biographicalInfo.slice';
import { getDropDownOptions } from 'utils/utilities';
import MasterData from 'userProfile/constants/master';
import { useTranslation } from 'react-i18next';
import { DISABLE_AUTO_FILL, MAX_BIG_LENGTH_FIELD } from 'constants/field';
import type { TBiographicFormErrors } from '../BiographicalInformationForm.validation';

interface IBiographicalInfoProps {
  data: TBiographicalInfoField | null;
}
const MilitaryInformation = ({ data }: IBiographicalInfoProps): ReactElement => {
  const {
    control,
    reset,
    setValue,
    register,
    formState: { errors: formErrors },
    trigger,
  } = useFormContext();
  const errors = formErrors as unknown as TBiographicFormErrors;

  const groupName = 'military';
  const { t } = useTranslation();
  const militaryStatusOptions = useMemo(() => getDropDownOptions(MasterData.militaryStatusOptions), []);
  const militaryBranchOptions = useMemo(() => getDropDownOptions(MasterData.militaryBranchOptions), []);
  const militaryDischargeOptions = useMemo(() => getDropDownOptions(MasterData.militaryDischargeOptions), []);

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [reset, data]);

  return (
    <Grid sx={{ mt: '2rem' }}>
      <Typography
        variant="h2"
        sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
      >
        {t('militaryStatus.title')}
      </Typography>
      <FormControl fullWidth>
        <InputLabel htmlFor="usMilitaryStatus">{t('militaryStatus.status')}</InputLabel>
        <Controller
          render={({ field: { onChange, ...field } }) => (
            <>
              <Dropdown
                id="usMilitaryStatus"
                {...field}
                options={militaryStatusOptions}
                fullWidth
                inputProps={{
                  'aria-label': t('militaryStatus.status'),
                }}
                onChange={option => {
                  setValue(`${groupName}.status.displayName`, option?.text);
                  return onChange(option?.id ?? null);
                }}
              />
              {field.value === 'OTHER' && (
                <>
                  <FormControl fullWidth error={!!errors?.[groupName]?.status?.otherText} sx={{ marginTop: '1rem' }}>
                    <InputLabel htmlFor="militaryOtherText">{t('militaryStatus.otherText')}</InputLabel>
                    <InputBase
                      inputProps={{
                        'aria-label': t('militaryStatus.otherText'),
                        maxLength: MAX_BIG_LENGTH_FIELD,
                        ...DISABLE_AUTO_FILL,
                      }}
                      {...register(`${groupName}.status.otherText`)}
                    />
                    <FormHelperText role="alert" id="pronouns.otherText-error">
                      {errors?.[groupName]?.status?.otherText?.message}
                    </FormHelperText>
                  </FormControl>
                </>
              )}
            </>
          )}
          control={control}
          name={`${groupName}.status.code`}
        />
      </FormControl>
      <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.status.displayName`)} />
      <FormControl fullWidth>
        <InputLabel htmlFor="usMilitaryBranch">{t('militaryStatus.branch')}</InputLabel>
        <Controller
          render={({ field: { onChange, ...field } }) => (
            <Dropdown
              id="usMilitaryBranch"
              {...field}
              options={militaryBranchOptions}
              fullWidth
              inputProps={{
                'aria-label': t('militaryStatus.branch'),
              }}
              onChange={option => {
                setValue(`${groupName}.branch.displayName`, option?.text);
                return onChange(option?.id ?? null);
              }}
            />
          )}
          control={control}
          name={`${groupName}.branch.code`}
        />
      </FormControl>
      <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.branch.displayName`)} />
      <Grid container spacing={2}>
        <Controller
          name={`${groupName}.serviceFrom`}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Grid item sm={12}>
              <FormControl fullWidth error={!!errors?.[groupName]?.serviceFrom}>
                <InputLabel htmlFor="serviceFrom">{t('militaryStatus.serviceFrom')}</InputLabel>
                <DateField
                  {...field}
                  ref={ref}
                  error={!!error}
                  id="serviceFrom"
                  onChange={dateVal => {
                    field.onChange(dateVal);
                    setTimeout(() => {
                      if (!errors?.[groupName]?.serviceFrom) {
                        trigger(`${groupName}.serviceTo`);
                      }
                    }, 0);
                  }}
                  aria-describedby="serviceFrom-error"
                  aria-label={t('militaryStatus.serviceFrom')}
                  accessibilityLabelPrefix={t('militaryStatus.serviceFrom')}
                />
                <FormHelperText role="alert" id="serviceFrom-error">
                  {errors?.[groupName]?.serviceFrom?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
          control={control}
        />
      </Grid>
      <Grid container spacing={2}>
        <Controller
          name={`${groupName}.serviceTo`}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Grid item sm={12}>
              <FormControl fullWidth error={!!errors?.[groupName]?.serviceTo}>
                <InputLabel htmlFor="serviceTo">{t('militaryStatus.serviceTo')}</InputLabel>
                <DateField
                  {...field}
                  ref={ref}
                  error={!!error}
                  id="serviceTo"
                  aria-describedby="serviceTo-error"
                  aria-label={t('militaryStatus.serviceTo')}
                  accessibilityLabelPrefix={t('militaryStatus.serviceTo')}
                />
                <FormHelperText role="alert" id="serviceTo-error">
                  {errors?.[groupName]?.serviceTo?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
          control={control}
        />
      </Grid>
      <FormControl fullWidth>
        <InputLabel htmlFor="dischargeStatus">{t('militaryStatus.dischargeStatus')}</InputLabel>
        <Controller
          render={({ field: { onChange, ...field } }) => (
            <Dropdown
              id="dischargeStatus"
              {...field}
              options={militaryDischargeOptions}
              fullWidth
              inputProps={{
                'aria-label': t('militaryStatus.dischargeStatus'),
              }}
              onChange={option => {
                setValue(`${groupName}.dischargeStatus.displayName`, option?.text);
                return onChange(option?.id ?? null);
              }}
            />
          )}
          control={control}
          name={`${groupName}.dischargeStatus.code`}
        />
      </FormControl>
      <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.dischargeStatus.displayName`)} />
    </Grid>
  );
};
export default MilitaryInformation;
