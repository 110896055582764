/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, Stack, Divider } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SidePanel, CollapsibleSection as Accordion } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import { sxSidePanel } from 'pages/Pages.styles';
import {
  personalInfoResetLocalData,
  personalInfoSaveLocalData,
  TPersonalInfoField,
} from 'userProfile/store/personalInfo/personalInfo.slice';
import { getButtonColor, sanitizePayload } from 'utils/utilities';
import { getLookUps, setTitle } from 'utils/commonUtils';
import { selectCountriesLookup } from 'store/common/commonInfo.selectors';
import { Address } from 'userProfile/components/Address';
import { PageTitle } from 'components/PageTitle';
import { Spinner } from 'components/Spinner';
import { ViewBuilder } from 'userProfile/components/ViewBuilder';
import {
  selectPersonalInformation,
  selectPersonalInformationLocalData,
  selectLoading,
} from 'userProfile/store/personalInfo/personalInfo.selectors';
import { DEFAULT_NAME, DEFAULT_EMAIL_MOBILE } from 'userProfile/constants/general';
import { resetlookupData } from 'store/common/commonInfo.slice';
import config from 'userProfile/appConfig/personalInfo.json';
import { PersonalInformationForm, ContactInformationForm, SocialMediaForm } from './PersonalInformationForm';
import {
  getPersonalInformation,
  postPersonalInformation,
  postPersonalInformationForAddress,
} from './Personalnformation.utils';
import { validationSchema, validationSchemaAddress } from './PersonalInformationForm.validation';

const PersonalInformation = (): ReactElement => {
  const [isOpenRightSideDrawer, setIsOpenRightSideDrawer] = useState(false);
  const [childDrawerInformation, setChildDrawerInformation] = useState({
    isOpenChildDrawer: false,
    isAddNew: false,
    titleName: 'contactInfo.addAddress',
    childPosition: 0,
  });
  const [personalInformationData, setPersonalInformationData] = useState(useSelector(selectPersonalInformation));
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const personalInformationActualData = useSelector(selectPersonalInformation);
  const personalInformationLocalData = useSelector(selectPersonalInformationLocalData);
  const countriesLookup = useSelector(selectCountriesLookup);
  const isLoading = useSelector(selectLoading);
  const { sections } = config;
  const methods = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(validationSchema, { abortEarly: false })(...args),
    defaultValues: { ...DEFAULT_NAME, ...DEFAULT_EMAIL_MOBILE },
  });

  const methods2 = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(validationSchemaAddress, { abortEarly: false })(...args),
  });

  useEffect(() => {
    setTitle(t('personalInfo.title'));
  }, [dispatch, t]);

  useEffect(() => {
    dispatch(getPersonalInformation());
  }, [dispatch]);

  useEffect(() => {
    if (!countriesLookup) {
      setTimeout(() => dispatch(getLookUps('countries')), 100);
    }
  }, [dispatch, countriesLookup]);

  useEffect(() => {
    if (personalInformationLocalData) {
      setPersonalInformationData({
        ...personalInformationLocalData,
        contact: {
          ...personalInformationLocalData.contact,
          addresses: personalInformationActualData?.contact?.addresses,
        },
      });
    } else {
      setPersonalInformationData(personalInformationActualData);
    }
  }, [personalInformationActualData, personalInformationLocalData]);

  const toggleDrawer = () => {
    setIsOpenRightSideDrawer(true);
    setTitle(t('personalInfo.editTitle'));
  };

  const openChildDrawer = (isNew: boolean, childPosition: number, titleName = 'contactInfo.addAddress') => {
    if (isOpenRightSideDrawer) {
      const formValues = { ...methods.getValues() };
      const localData = {
        ...formValues,
        contact: {
          emails: [...formValues.contact.emails],
          phones: [...formValues.contact.phones],
          addresses: personalInformationActualData?.contact?.addresses,
        },
      };
      dispatch(personalInfoSaveLocalData(localData as TPersonalInfoField));
    }
    setChildDrawerInformation({
      ...childDrawerInformation,
      isOpenChildDrawer: true,
      isAddNew: isNew,
      titleName,
      childPosition,
    });
    setTitle(t('address.addPageTitle'));
  };

  const onSubmit = (data: TPersonalInfoField) => {
    // eslint-disable-next-line no-unused-vars
    const { hasFormerName, hasPreferredName, ...personal } = data?.personal || {};
    const payload = {
      ...data,
      personal,
      avatarFileId: personalInformationActualData?.avatarFileId,
    };
    dispatch(
      postPersonalInformation(sanitizePayload(Object.assign(payload)), () => {
        setIsOpenRightSideDrawer(false);
        setTitle(t('personalInfo.title'));
      })
    );
  };

  const closeParent = () => {
    setIsOpenRightSideDrawer(false);
    dispatch(personalInfoResetLocalData());
    setTitle(t('personalInfo.title'));
  };

  const closeChild = () => {
    setChildDrawerInformation({
      ...childDrawerInformation,
      isOpenChildDrawer: false,
    });
    setTitle(t('personalInfo.editTitle'));
    methods.trigger();
    dispatch(resetlookupData(['states', 'counties']));
  };

  const onSubmitChildDrawer = (data: TPersonalInfoField) => {
    const addresses = [...(personalInformationActualData?.contact?.addresses || [])];

    if (addresses.length > 0) {
      if (data?.contact?.addresses?.[childDrawerInformation.childPosition] !== undefined) {
        addresses[childDrawerInformation.childPosition] = data.contact.addresses[childDrawerInformation.childPosition];
      }
    } else {
      addresses.push(data?.contact?.addresses?.[childDrawerInformation?.childPosition] || {});
    }

    let closeParentDrawer = false;
    let payload;
    /* istanbul ignore if */
    if (!personalInformationActualData) {
      const formValues = methods.getValues();
      payload = {
        ...formValues,
        contact: {
          ...formValues?.contact,
          emails: formValues?.contact?.emails || [],
          phones: formValues?.contact?.phones || [],
          addresses,
        },
      };
      closeParentDrawer = true;
    } else {
      payload = {
        ...personalInformationActualData,
        contact: {
          ...personalInformationActualData?.contact,
          emails: personalInformationActualData?.contact?.emails || [],
          phones: personalInformationActualData?.contact?.phones || [],
          addresses,
        },
      };
    }

    dispatch(
      postPersonalInformationForAddress(sanitizePayload(Object.assign(payload)), () => {
        if (closeParentDrawer) {
          closeParent();
        }
        closeChild();
      })
    );
  };
  const deleteAddress = (indexPosition: number) => {
    const addresses = [...(personalInformationActualData?.contact?.addresses || [])];

    if (addresses.length > 0) {
      addresses.splice(indexPosition, 1);
    }

    const payload = {
      ...personalInformationActualData,
      contact: {
        ...personalInformationActualData?.contact,
        emails: personalInformationActualData?.contact?.emails || [],
        phones: personalInformationActualData?.contact?.phones || [],
        addresses,
      },
    };

    dispatch(
      postPersonalInformationForAddress(sanitizePayload(Object.assign(payload)), () => {
        setChildDrawerInformation({
          ...childDrawerInformation,
          isOpenChildDrawer: false,
        });
        setTitle(t('personalInfo.editTitle'));
      })
    );
  };
  return (
    <Grid container>
      {isLoading && <Spinner backdrop />}
      <Box sx={{ width: '100%' }}>
        <PageTitle title={t('personalInfo.title')} handleClick={toggleDrawer} />
        <FormProvider {...methods}>
          <form>
            <SidePanel
              size="small"
              open={isOpenRightSideDrawer}
              onClose={closeParent}
              title={t('personalInfo.title')}
              isBackdropClickEnabled={true}
              footerButtonConfig={{
                primary: {
                  title: t('save_label'),
                  props: {
                    'aria-label': t('save_label'),
                    variant: 'contained',
                    color: getButtonColor(),
                    disabled: !methods.formState.isValid,
                    onClick: methods.handleSubmit(onSubmit),
                  },
                },
                tertiary: {
                  title: t('cancel_label'),
                  props: {
                    color: getButtonColor(),
                    'aria-label': t('cancel_label'),
                    onClick: closeParent,
                  },
                },
              }}
              sx={sxSidePanel}
            >
              <Stack spacing={4} divider={<Divider orientation="horizontal" flexItem />}>
                <PersonalInformationForm data={personalInformationData || null} />
                <ContactInformationForm data={personalInformationData} openChildDrawer={openChildDrawer} />
                <SocialMediaForm data={personalInformationData || null} />
              </Stack>
            </SidePanel>
          </form>
        </FormProvider>

        <FormProvider {...methods2}>
          <form>
            <SidePanel
              size="small"
              title={t(childDrawerInformation.titleName)}
              open={childDrawerInformation.isOpenChildDrawer}
              isBackdropClickEnabled={true}
              onClose={closeChild}
              footerButtonConfig={{
                primary: {
                  title: t('save_label'),
                  props: {
                    'aria-label': t('save_label'),
                    color: getButtonColor(),
                    variant: 'contained',
                    disabled: !methods2.formState.isValid,
                    onClick: methods2.handleSubmit(onSubmitChildDrawer),
                  },
                },
                tertiary: {
                  title: t('cancel_label'),
                  props: {
                    color: getButtonColor(),
                    onClick: closeChild,
                    'aria-label': t('cancel_label'),
                  },
                },
              }}
              sx={sxSidePanel}
            >
              {childDrawerInformation.isOpenChildDrawer && (
                <Address
                  data={personalInformationData as { [key: string]: unknown }}
                  openChildDrawer={openChildDrawer}
                  childPosition={childDrawerInformation.childPosition}
                  deleteAddress={!childDrawerInformation.isAddNew ? deleteAddress : null}
                />
              )}
            </SidePanel>
          </form>
        </FormProvider>
        {Object.keys(sections).map((key, index) => {
          const accordianValues = Object(sections)[key];
          const responseData = Object(personalInformationActualData);
          const keyId = index + 1;
          const title = t(
            `${accordianValues.title}${accordianValues?.displayTitle ? `.${accordianValues?.displayTitle}` : '.title'}`
          );
          return (
            <React.Fragment key={keyId}>
              <Box sx={{ mb: '1rem', mt: '1rem' }}>
                <Accordion expanded title={title.toUpperCase()} groupId="">
                  <ViewBuilder
                    fieldDetails={accordianValues?.fields}
                    responseData={responseData}
                    openChildDrawer={openChildDrawer}
                    toggleDrawer={toggleDrawer}
                    localeKey={accordianValues.title}
                    selector=""
                    formTitle=""
                  />
                </Accordion>
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
    </Grid>
  );
};

export default memo(PersonalInformation);
