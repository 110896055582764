/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect, useMemo } from 'react';
import { Grid, Box, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, type TDropdownOption } from '@liaison/liaison-ui';
import { useFormContext, Controller } from 'react-hook-form';
import { DISABLE_ADDRESS_FILLING } from 'constants/field';
import { useTranslation } from 'react-i18next';
import type { TAcademicGoalsInfoField } from 'userProfile/store/academicInfo/academicInfo.slice';
import { ISchoolOfInterest, resetlookupData } from 'store/common/commonInfo.slice';
import { SearchableAutoComplete } from 'components/SearchableAutoComplete';
import { selectCountriesLookup, selectSchoolsOfInterestsLookup } from 'store/common/commonInfo.selectors';
import { useOptionsScroll } from 'hooks/useOptionsScroll';
import { getAcademicHistoryLookUps, getLookUps } from 'utils/commonUtils';
import { getDropDownOptions, getOptionDisabled } from 'utils/utilities';
import { getSchoolOfInterestOptions, compareFn } from '../AcademicGoals.utils';

interface ISchoolsOfInterestsFormProps {
  data: TAcademicGoalsInfoField | null;
}

type TFormErrors = {
  country: { code: { message: string } };
};
const SchoolsOfInterestsForm = ({ data }: ISchoolsOfInterestsFormProps): ReactElement => {
  const {
    control,
    getValues,
    formState: { errors: formErrors },
    reset,
  } = useFormContext();
  const errors = formErrors as unknown as TFormErrors;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const schoolsOfInterestLookup = useSelector(selectSchoolsOfInterestsLookup);
  const countriesLookup = useSelector(selectCountriesLookup);
  const countryOptions = useMemo(() => getDropDownOptions(countriesLookup || []), [countriesLookup]);

  const { filteredOptions, setFilteredOptions, showApiLoading, showAdd, setShowAdd, handleScroll, handleSearch } =
    useOptionsScroll({
      lookup: schoolsOfInterestLookup as (ISchoolOfInterest & { id: string; text: string })[],
      getOptions: getSchoolOfInterestOptions as <T>(options: T[]) => T[],
      getValues,
      dispatch,
      getLookUps: getAcademicHistoryLookUps,
      entity: 'schoolsOfInterest',
      allowManualAdd: false,
      searchKeyLength: 1,
      compareFn,
    });

  useEffect(() => {
    if (!countriesLookup) {
      dispatch(getLookUps('countries'));
    }
  }, [dispatch, countriesLookup]);

  useEffect(() => {
    if (data) {
      reset(data);
    }
    return () => reset({});
  }, [reset, data]);

  return (
    <Grid>
      <Box>
        <FormControl required fullWidth error={!!errors?.country?.code}>
          <InputLabel htmlFor="country">{t('address.country')}</InputLabel>
          <Controller
            render={({ field: { ref, onChange, ...field } }) => (
              <Dropdown
                id="country"
                {...field}
                inputRef={ref}
                options={countryOptions}
                onChange={option => {
                  dispatch(resetlookupData(['schoolsOfInterest']));
                  return onChange(option?.id);
                }}
                inputProps={{
                  'aria-label': t('address.country'),
                  'aria-describedby': 'country-error',
                  ...DISABLE_ADDRESS_FILLING,
                }}
              />
            )}
            defaultValue="US"
            control={control}
            name="country.code"
          />
          <FormHelperText role="alert" id="country-error">
            {errors?.country?.code?.message}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth required>
          <InputLabel htmlFor="school">{t('academicGoal.searchSchools')}</InputLabel>
          <Controller
            render={({ field: { onChange, name } }) => (
              <SearchableAutoComplete
                id="school"
                options={filteredOptions}
                onChange={option => {
                  if (
                    option &&
                    (option as unknown as ISchoolOfInterest).ipedsId !== 'Loading' &&
                    (option as unknown as ISchoolOfInterest).ipedsId !== 'addManually'
                  ) {
                    // eslint-disable-next-line no-unused-vars
                    const { text, id, ...restOptions } = option;
                    return onChange(restOptions);
                  }
                  return '';
                }}
                name={name}
                getOptionDisabled={(option: TDropdownOption<string>) =>
                  getOptionDisabled(
                    'ipedsId',
                    data?.schools as ISchoolOfInterest[]
                  )(option as unknown as ISchoolOfInterest)
                }
                handleSearch={searchParam => handleSearch(searchParam, 1)}
                handleScroll={handleScroll}
                inputProps={{
                  'aria-label': t('academicGoal.searchSchoolsPlaceholder'),
                  placeholder: t('academicGoal.searchSchoolsPlaceholder'),
                }}
                onClose={() => {
                  setShowAdd(false);
                  setFilteredOptions([]);
                  dispatch(resetlookupData(['schoolsOfInterest']));
                }}
                noOptionsText={
                  showAdd ? (
                    <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection="column">
                      {showApiLoading ? `${t('academicHistory.loading')}...` : t('no_results_found')}
                    </Box>
                  ) : (
                    t('academicGoals.minimumSearchlabel')
                  )
                }
              />
            )}
            control={control}
            name="schools"
          />
        </FormControl>
      </Box>
    </Grid>
  );
};

export default SchoolsOfInterestsForm;
