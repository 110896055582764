/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';

import { StatusSection } from './StatusSection';
import { TipsCard } from './TipsCard';

export const Welcome = (): ReactElement => {
  return (
    <Grid container spacing={2} sx={{ p: { xs: 0, sm: 2 } }}>
      <Grid item container md={12} lg={7.5}>
        <StatusSection />
      </Grid>
      <Grid item container md={12} lg={4.5} alignItems="stretch">
        <TipsCard />
      </Grid>
    </Grid>
  );
};
