/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { Fragment, ReactElement, memo, useEffect } from 'react';
import { Box, InputBase, Typography, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ISocialMedia, TPersonalInfoField } from 'userProfile/store/personalInfo/personalInfo.slice';
import { DISABLE_AUTO_FILL } from 'constants/field';
import { useTranslation } from 'react-i18next';
import { socialMedia } from '../Personalnformation.utils';
import type { TPersonalInfoFormErrors } from '../PersonalInformationForm.validation';

interface ISocialMediaProps {
  data: TPersonalInfoField | null;
}
const SocialMediaForm = ({ data }: ISocialMediaProps): ReactElement => {
  const {
    register,
    setValue,
    watch,
    formState: { errors: formErrors },
  } = useFormContext();
  const errors = formErrors as unknown as TPersonalInfoFormErrors;
  const groupName = 'socialMedia';
  const { t } = useTranslation();

  useEffect(() => {
    if (data?.[groupName]) {
      const socialMediaArray: ISocialMedia[] = [];
      Object.keys(socialMedia).forEach(i => {
        const social = data?.[groupName]?.find(item => item?.type?.code === socialMedia[i].code);
        socialMediaArray.push(social || { url: '' });
      });
      setValue(`${groupName}`, socialMediaArray);
    }
  }, [setValue, data]);

  return (
    <Box>
      <Typography
        variant="h2"
        sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
      >
        {t('socialMedia.title')}
      </Typography>
      {Object.keys(socialMedia).map(i => {
        return (
          <Fragment key={i}>
            <FormControl fullWidth error={!!errors?.[groupName]?.[i as unknown as number]?.url}>
              <InputLabel htmlFor={socialMedia[i].code}>{t(`${socialMedia[i].displayName}`)}</InputLabel>
              <InputBase
                placeholder={t(`${[groupName]}.${[socialMedia[i].code]}.placeholder`) as string}
                inputProps={{
                  'aria-label': t(`${socialMedia[i].code}`),
                  'aria-describedby': `${socialMedia[i].code}-error`,
                  ...DISABLE_AUTO_FILL,
                }}
                {...register(`${groupName}.[${i}].url`, {
                  onChange: e => {
                    const { value } = e.target;
                    if (value) {
                      setValue(`${groupName}.[${i}].url`, value);
                    } else {
                      setValue(`${groupName}.[${i}]`, null);
                    }
                  },
                })}
              />
              <FormHelperText role="alert" id={`${socialMedia[i].code}-error`}>
                {errors?.[groupName]?.[i as unknown as number]?.url?.message}
              </FormHelperText>
            </FormControl>
            {watch(`${groupName}.[${i}].url`) && (
              <InputBase
                inputProps={{ type: 'hidden' }}
                {...register(`${groupName}.[${i}].type`, {
                  value: socialMedia[i],
                })}
              />
            )}
          </Fragment>
        );
      })}
    </Box>
  );
};

export default memo(SocialMediaForm);
