/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Components, Theme } from '@mui/material';
import ProximaNovaRegular from 'assets/fonts/proximanova-reg-webfont.woff';
import ProximaNovaBold from 'assets/fonts/proximanova-bold-webfont.woff';
import ProximaNovaSemibold from 'assets/fonts/proximanova-sbold-webfont.woff';

export default (theme: Theme): Components => ({
  MuiCssBaseline: {
    styleOverrides: `

    @font-face {
      font-family: 'Proxima Nova Regular';
      font-style: normal;
      font-display: swap;
      font-weight: normal;
      src: local('Proxima Nova Regular'), url(${ProximaNovaRegular}) format('woff');
    }

    @font-face {
      font-family: 'Proxima Nova Bold';
      font-style: normal;
      font-display: swap;
      font-weight: bold;
      src: local('Proxima Nova Bold'), url(${ProximaNovaBold}) format('woff');
    }

    @font-face {
      font-family: 'Proxima Nova Semibold';
      font-style: normal;
      font-display: swap;
      font-weight: normal;
      src: local('Proxima Nova Semibold'), url(${ProximaNovaSemibold}) format('woff');
    }

    a {
      color: ${theme.palette.primary.main};
    }
    
    * {
      -webkit-text-fill-color: unset !important;
    }
    `,
  },
});
