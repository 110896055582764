/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */
import { API } from 'transferPlanner/constants/api';
import { t } from 'i18next';
import axios from 'axios';
import type { TAppThunk } from 'redux/store';
import { getLoggedUser } from 'utils/keyCloakUtils';
import {
  eligibilityStart,
  eligibilityQuestionnaireConfigSuccess,
  eligibilityFailure,
  type IEligibility,
  type ITspEligibility,
} from 'transferPlanner/store/eligibility/eligibility.slice';

export const getQuestionnaireConfig =
  (successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(eligibilityStart());
      const { data } = await axios.get(`${API.questionnaireConfig}`);
      dispatch(eligibilityQuestionnaireConfigSuccess(data));
      successCallback?.();
    } catch (err) {
      dispatch(eligibilityFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export type TMeta = {
  code: string;
  type: string;
  format: string | null;
  displayName: string;
  requiredError?: string;
  formatError?: string;
};

export type TAnswer = {
  code: string;
  order: number;
  customEntry: boolean | null;
  displayName: string;
  value?: string;
};

export type TQuestion = {
  code: string;
  meta: TMeta;
  order: number;
  answers: TAnswer[];
  required: boolean;
  displayName: string;
  subDisplayName?: string;
  dependencies?: string[];
};

export type TQuestionnaireConfig = {
  version: number;
  ineligibility: string[];
  questions: TQuestion[];
};

export const getCurrentQuestionnaire = (
  version: number,
  eligibility: ITspEligibility[] | undefined
): ITspEligibility => {
  return eligibility?.find((current: ITspEligibility) => {
    return current.questionnaireVersion === version;
  }) as ITspEligibility;
};

export const getCustomEntryCode = (questionCode: string, answer: TAnswer): string => `${questionCode}~${answer?.code}`;

export const getFormValues = (eligibility: IEligibility | null, version: number): Record<string, string> | null => {
  const eligibleQuestionnaire = getCurrentQuestionnaire(version, eligibility?.tspEligibility);
  if (eligibleQuestionnaire) {
    const { questions } = eligibleQuestionnaire;
    const data: Record<string, string> = {};

    if (questions && questions.length) {
      questions.forEach(question => {
        data[question.code] = question.answer.code;
        if (question.answer.displayName === 'Other') {
          data[getCustomEntryCode(question.code, question.answer as TAnswer)] = question.answer.value;
        }
      });
    }
    return data;
  }
  return null;
};

export const showDependent = (dependencies: string[] | undefined, formData: Record<string, string>): boolean => {
  if (dependencies) {
    const formValues = Object.values(formData);
    return dependencies.every(dependent => formValues.includes(dependent));
  }
  return true;
};

export const inEligibilityCheck = (configurations: TQuestionnaireConfig, data: Record<string, string>): boolean => {
  const formValues = Object.values(data);
  return !configurations.ineligibility?.some(inEligibleValue => formValues.includes(inEligibleValue));
};

const getStatus = (isEligible: boolean) => {
  return {
    code: isEligible ? 'ELIGIBLE' : 'INELIGIBLE',
    displayName: isEligible ? 'Eligible' : 'Ineligible',
  };
};

const getReason = (isEligible: boolean) => {
  return {
    code: isEligible ? 'CRITERIA_MET' : 'CRITERIA_NOT_MET',
    displayName: isEligible ? 'Criteria Met' : 'Criteria Not Met',
  };
};

export const composePayload = (
  configurations: TQuestionnaireConfig,
  data: Record<string, string>,
  eligibility: ITspEligibility[] | undefined
): IEligibility => {
  const { questions } = configurations;
  const isEligible = inEligibilityCheck(configurations, data);
  const currentIsoDate = new Date().toISOString();
  const questionnaire = questions.map(singleQuestion => {
    // eslint-disable-next-line no-unused-vars
    const { answers, subDisplayName, dependencies, ...restQuestionProps } = singleQuestion;
    const {
      meta: { code: questionType },
    } = restQuestionProps;

    if (questionType === 'TEXT') {
      return {
        ...restQuestionProps,
        answer: {
          value: data[restQuestionProps.code],
          displayName: data[restQuestionProps.code],
        },
      };
    }

    const answerObj = answers.find(answer => answer.code === data[restQuestionProps.code]);
    if (!answerObj) {
      return null;
    }

    const customValue = answerObj?.customEntry
      ? data[getCustomEntryCode(restQuestionProps.code, answerObj)]
      : answerObj?.displayName;

    return {
      ...restQuestionProps,
      answer: {
        code: answerObj?.code,
        value: customValue,
        displayName: answerObj?.displayName,
      },
    };
  });

  const existingQuestinare =
    (eligibility?.filter((current: ITspEligibility) => {
      return current.questionnaireVersion !== configurations.version;
    }) as ITspEligibility[]) || [];

  const currentQuestionnaire = {
    questionnaireVersion: configurations.version,
    lastUpdatedTimestamp: currentIsoDate,
    status: getStatus(isEligible),
    reason: getReason(isEligible),
    questions: questionnaire,
  };

  return {
    profileId: getLoggedUser(),
    lastUpdatedTimestamp: currentIsoDate,
    lastUpdatedByTenant: 'csu',
    tspEligibility: [...existingQuestinare, currentQuestionnaire] as ITspEligibility[],
  };
};
