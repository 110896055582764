/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TError = string | null;

interface ICalculator {
  intentedTransferTerm?: number;
  plannedEnrollUnit?: number;
  summerEnrollUnit?: number;
}

export interface ICalculatorState {
  loading: boolean;
  calculator: ICalculator | null;
  error: TError;
}

export const initialState: ICalculatorState = {
  loading: false,
  calculator: null,
  error: null,
};

const timelineCalculationSlice = createSlice({
  name: 'timelineCalculation',
  initialState,
  reducers: {
    setCalculation: (state, action: PayloadAction<ICalculator>): ICalculatorState => ({
      ...state,
      loading: false,
      calculator: action.payload,
    }),
  },
});

export const { setCalculation } = timelineCalculationSlice.actions;

export default timelineCalculationSlice.reducer;
