/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React from 'react';
import {
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  dialogClasses,
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
  Theme,
  InputLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';
import { IconCancel, IconAlertTriangleColor } from '@liaison/liaison-ui';
import { nameSpace } from 'transferPlanner/constants/general';
import { DEFAULT_SANITIZE_CONFIG } from 'constants/sanitize';
import SaveAndFollowButton from 'transferPlanner/components/SaveAndFollowButton/SaveAndFollowButton';
import { TP_ADDITIONAL_DETAILS } from 'transferPlanner/constants/routeNames';
import { Link } from 'react-router-dom';

type TDissimilarConfirmationProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onFollow: () => void;
  isDisabledFollowButton?: boolean;
};

export const DissimilarConfirmation = ({
  open,
  onClose,
  onConfirm,
  onFollow,
  isDisabledFollowButton = false,
}: TDissimilarConfirmationProps): React.ReactElement => {
  const { t } = useTranslation(nameSpace);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const onCancel = () => {
    onClose();
  };

  const onSave = () => {
    onConfirm();
    onCancel();
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      role="dialog"
      scroll="body"
      aria-labelledby="dialog-modal-title"
      aria-describedby="dialog-modal-title"
      sx={{ [`.${dialogClasses.paper}`]: { borderRadius: '1rem' } }}
      onClick={e => e.stopPropagation()}
    >
      <Box
        sx={{
          display: 'flex',
          py: '0.5rem',
          px: ['1rem', '1.5rem', '2rem'],
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconAlertTriangleColor fontSize="large" />
          <DialogTitle id="dialog-modal-title" style={{ fontSize: isMobile ? '1rem' : 'inherit' }}>
            {t('programs.confirmation.dissimilar.header')}
          </DialogTitle>
        </Box>
        <IconButton aria-label="close" size="small" onClick={onCancel} sx={{ height: 'fit-content' }}>
          <IconCancel
            sx={{
              color: theme => theme.palette.grey[500],
            }}
          />
        </IconButton>
      </Box>
      <Grid container component="main">
        <Grid item xs={12}>
          <DialogContent tabIndex={0}>
            <Typography variant="body2" gutterBottom>
              {`${t('programs.confirmation.dissimilar1')} `}
              <Link to={`/${TP_ADDITIONAL_DETAILS.path}`}>{t('programs.confirmation.similarMsgLink')}</Link>
              {t('programs.programSavedAndFollowLastWord')}
              <Typography
                variant="body2"
                display="inline"
                dangerouslySetInnerHTML={{
                  __html: sanitize(t('programs.confirmation.dissimilar2'), DEFAULT_SANITIZE_CONFIG),
                }}
              />
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t('programs.confirmation.dissimilar3')}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t('programs.confirmation.dissimilar4')}
            </Typography>
            <Box sx={{ pt: '1rem' }}>
              <InputLabel>{t(`programs.confirmation.dissimilar5`)}</InputLabel>
            </Box>
            <Box sx={{ display: isMobile ? 'block' : 'flex', justifyContent: 'space-between', py: '.7rem' }}>
              <SaveAndFollowButton
                label={t('programs.confirmation.followProgramHeader')}
                description={t('programs.confirmation.followProgramPara')}
                onClick={() => onFollow()}
                sx={isMobile ? { mb: '1rem' } : { mr: '1rem' }}
                disabled={isDisabledFollowButton}
              />
              <SaveAndFollowButton
                label={t('programs.confirmation.saveForLaterHeader')}
                description={t('programs.confirmation.saveForLaterPara')}
                onClick={() => onSave()}
                isSaveIcon={true}
              />
            </Box>
          </DialogContent>
        </Grid>
      </Grid>
      <DialogActions
        sx={{
          justifyContent: 'center',
          py: '1rem',
          px: ['1rem', '1.5rem', '2.5rem'],
          borderTop: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Button onClick={onCancel} variant="text">
          {t('cancel_label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
