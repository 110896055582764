/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { modifyResponse } from 'utils/AcademicHistory.utils';

type TError = string | null;

export interface IEnum {
  id?: string;
  code: string;
  displayName: string;
}

type TCompetencyAttachment = {
  id: string;
  competency: string;
  updatedTimestamp: string;
  institutionName: string;
  section: {
    code: string;
    displayName: string;
  };
};

export type ICollegeCourse = {
  id: string;
  code: string;
  title: string;
  competencyAttachments: TCompetencyAttachment[];
  subject: IEnum;
  attachedCount: number;
  competencyAttachedCount: number;
  grade?: string;
  normalizedGrade?: IEnum;
  credits?: number;
  convertedGrade?: string;
  source?: IEnum;
};

export type ITerm = {
  id: string;
  type: IEnum;
  completionStatus: IEnum;
  academicYear: number;
  courses?: ICollegeCourse[];
};

export type THighSchoolCourseGrade = {
  overall: string;
  normalizedGrade?: IEnum;
  data?: { [key: string]: IEnum };
  summer?: string;
  termType?: IEnum;
};

export type THighSchoolCourse = {
  id: string;
  code?: string;
  title?: string;
  attachedCount: number;
  competencyAttachedCount: number;
  competencyAttachments: TCompetencyAttachment[];
  subject?: IEnum;
  classification?: IEnum;
  grade?: THighSchoolCourseGrade;
  credits?: number;
};

export type TGradeLevel = {
  id?: string;
  completionStatus?: IEnum;
  type?: IEnum;
  academicYear: number;
  startDate?: string;
  endDate?: string;
  gpa?: number;
  courses?: THighSchoolCourse[];
  competencyAttachments: TCompetencyAttachment[];
  attachedCount: number;
  competencyAttachedCount: number;
};

interface IAddressDetails {
  address1?: string;
  address2?: string;
  city: string;
  postalCode?: string;
  country: IEnum;
  region: IEnum;
  county?: IEnum;
}

export interface IHighSchool {
  text?: string;
  id?: string;
  name: string;
  type?: string;
  ceeb: string;
  address: IAddressDetails;
  startDate: string;
  endDate: string;
  graduated: string | boolean;
  graduationDate: string;
  gpa?: number;
  primary?: boolean;
  termType: IEnum;
  gradeLevels: TGradeLevel[];
}

export interface ICollege {
  node?: {
    level: number;
  };
  name?: { longName?: string };
  alternateId?: { code: number; source: string };
  ceeb?: string;
  collegeCode?: string;
  transcriptCode?: string;
  termType?: IEnum;
  address?: IAddressDetails;
  graduated?: boolean;
  startDate?: string;
  endDate?: string;
  gpa?: number;
  degrees?: Idegree[];
  uniqueId?: number;
  foreign?: boolean;
  medical?: boolean;
  ipeds?: string;
  mdb?: string;
  primary?: boolean;
  text?: string;
  id?: string;
  terms: ITerm[];
}

export interface Idegree {
  id: string;
  type: IEnum;
  startDate?: string;
  endDate: string;
  attachedCount: number;
  competencyAttachedCount: number;
  competencyAttachments: TCompetencyAttachment[];
  majors: IEnum[];
  minors: IEnum[];
  degreeStatus: IEnum;
}

export interface TAcademicHistoryField {
  highSchools?: IHighSchool[] | null;
  colleges?: ICollege[] | null;
}

export type TGradeLevelAndCourse = {
  hscourse: THighSchoolCourse;
  gradeLevelPosition: number;
  coursePosition: number;
  gradeLevel: TGradeLevel;
  termType: IEnum;
};

export type TTermsAndCourse = {
  coursePosition: number;
  termPosition: number;
  course: ICollegeCourse;
  term: ITerm;
  transferable: boolean;
  collegeName?: string;
  collegeId?: string;
  collegePosition?: number;
  geCertificationCode?: string;
  geCertificationCodes?: string[];
};
export type TCourseExtensionObj = {
  id: string;
  collegeId: string;
  termId: string;
  transferable: boolean;
  code: string;
  title: string;
  subject: IEnum;
  grade?: string;
  credits?: number;
  normalizedGrade?: IEnum;
  source?: IEnum;
  geCertificationCode?: string;
  geCertificationCodes?: string[];
};
export type TCourseExtensionPayload = {
  profileId?: string;
  lastUpdatedTimestamp?: string;
  tenants?: string[];
  collegesTermsCoursesExtensions?: TCourseExtensionObj[];
};
export interface IAcademicHistoryState {
  loading: boolean;
  academicHistoryData: TAcademicHistoryField | null;
  academicHistoryLocalData: TAcademicHistoryField | null;
  collegesCourseExtensionData: TCourseExtensionPayload | null;
  error: TError;
}
export const initialState: IAcademicHistoryState = {
  loading: false,
  academicHistoryData: null,
  academicHistoryLocalData: null,
  collegesCourseExtensionData: null,
  error: null,
};

const academicHistorySlice = createSlice({
  name: 'academicHistoryInfo',
  initialState,
  reducers: {
    academicHistoryStart: (state): IAcademicHistoryState => ({
      ...state,
      loading: true,
      error: null,
    }),
    academicHistorySuccess: (state, action: PayloadAction<TAcademicHistoryField>): IAcademicHistoryState => ({
      ...state,
      loading: false,
      academicHistoryData: modifyResponse(action.payload),
      academicHistoryLocalData: null,
    }),
    academicHistorySaveLocalData: (state, action: PayloadAction<TAcademicHistoryField>): IAcademicHistoryState => ({
      ...state,
      loading: false,
      academicHistoryLocalData: action.payload,
    }),
    academicHistoryResetLocalData: (state): IAcademicHistoryState => ({
      ...state,
      loading: false,
      academicHistoryLocalData: null,
    }),
    academicHistoryFailure: (state, action: PayloadAction<TError>): IAcademicHistoryState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    collegeCourseExtensionStart: (state): IAcademicHistoryState => ({
      ...state,
      loading: true,
      error: null,
    }),
    collegeCourseExtensionSuccess: (state, action: PayloadAction<TCourseExtensionPayload>): IAcademicHistoryState => ({
      ...state,
      loading: false,
      collegesCourseExtensionData: action.payload,
    }),
    collegeCourseExtensionFailure: (state, action: PayloadAction<TError>): IAcademicHistoryState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  academicHistoryStart,
  academicHistorySuccess,
  academicHistorySaveLocalData,
  academicHistoryResetLocalData,
  academicHistoryFailure,
  collegeCourseExtensionStart,
  collegeCourseExtensionSuccess,
  collegeCourseExtensionFailure,
} = academicHistorySlice.actions;

export default academicHistorySlice.reducer;
