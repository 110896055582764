/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export const sxIcon = (bgcolor: string, color: string) =>
  ({
    height: 32,
    width: 32,
    bgcolor,
    color,
  } as const);
