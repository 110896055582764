/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Dispatch, SetStateAction, useState } from 'react';
import { ITerm } from 'store/academicHistory/academicHistory.slice';

type TuseTermIndex = {
  newTermIndex: number;
  setNewTerm: Dispatch<SetStateAction<ITerm | undefined>>;
};

export const useTermIndex = (termList: ITerm[] | undefined): TuseTermIndex => {
  const [newTerm, setNewTerm] = useState<ITerm>();
  let newTermIndex = -1;
  if (termList?.length && newTerm) {
    newTermIndex = termList.findIndex(
      term =>
        term.academicYear === Number(newTerm?.academicYear) &&
        term.completionStatus.code === newTerm.completionStatus.code &&
        term.type.code === newTerm.type.code
    );
  }
  return { newTermIndex, setNewTerm };
};
