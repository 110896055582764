/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Grid, InputLabel, Typography, formControlClasses } from '@mui/material';
import { Input, IconLink, IconRefresh, SidePanel } from '@liaison/liaison-ui';

import { sxSidePanel } from 'pages/Pages.styles';
import { selectShareProfileSidebar } from 'store/ui/ui.selectors';
import { setUi } from 'store/ui/ui.slice';
import { selectActiveSharedViewId, selectUpdateLinkLoading } from 'userProfile/store/profileView/profileView.selectors';
import { unpublishLink, updateSharedViews } from '../LivingProfile.utils';

export const ShareProfileSidePanel = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { open } = useSelector(selectShareProfileSidebar);
  const sharedViewId = useSelector(selectActiveSharedViewId);
  const updateLinkLoading = useSelector(selectUpdateLinkLoading);

  const link = useMemo(
    () => (sharedViewId ? `${window.location.origin}/shared-profile-view/${sharedViewId}` : ''),
    [sharedViewId]
  );

  const onClose = () => {
    dispatch(setUi({ name: 'shareProfileSidebar', state: { open: false } }));
  };

  const copyLink = () => {
    navigator.clipboard.writeText(link);
  };

  const updateLink = () => {
    dispatch(updateSharedViews({ id: sharedViewId }));
  };

  const unpublishExistingLink = () => {
    dispatch(unpublishLink({ id: sharedViewId }));
  };

  const isButtonDisabled = !sharedViewId || updateLinkLoading || link === '';

  return (
    <SidePanel
      size="small"
      open={open}
      onClose={onClose}
      title={t('livingProfile.shareMyProfile')}
      isBackdropClickEnabled
      footerButtonConfig={{
        tertiary: {
          title: t('close_label'),
          props: {
            'aria-label': t('close_label'),
            color: 'primary',
            onClick: onClose,
          },
        },
      }}
      sx={sxSidePanel}
    >
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Typography variant="body2">{t('livingProfile.shareMyProfile.instruction')}</Typography>
        </Grid>
        <Grid item container xs={12} alignItems="flex-end" justifyContent="space-between" rowSpacing={1}>
          <Grid
            item
            xs={12}
            sm={9.5}
            lg={9.8}
            xl={10.3}
            sx={{
              [`& .${formControlClasses.marginNormal}`]: {
                mb: 0,
              },
            }}
          >
            <InputLabel htmlFor="link">{t('livingProfile.shareMyProfile.shareLink')}</InputLabel>
            <Input
              id="link"
              fullWidth
              disabled
              value={link}
              inputProps={{
                'aria-label': t('livingProfile.shareMyProfile.shareLink'),
              }}
            />
          </Grid>
          <Grid item>
            <Button
              startIcon={<IconLink />}
              disabled={isButtonDisabled}
              color="primary"
              onClick={copyLink}
              variant="contained"
            >
              {t('livingProfile.shareMyProfile.copy')}
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}
        >
          <Button size="large" startIcon={<IconRefresh />} onClick={updateLink} color="error">
            {t('livingProfile.shareMyProfile.updateLink')}
          </Button>
          <Button
            disabled={link === ''}
            size="large"
            startIcon={<IconRefresh />}
            onClick={unpublishExistingLink}
            color="error"
          >
            {t('livingProfile.shareMyProfile.unpublishLink')}
          </Button>
        </Grid>
      </Grid>
    </SidePanel>
  );
};
