/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { useRef, useEffect } from 'react';

export const useFirstRenderStatus = (): boolean => {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
};
