/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';

import { Checkbox, FormControlLabel, CheckboxProps, InputBase, InputLabel, FormControl, Grid } from '@mui/material';
import { MAX_BIG_LENGTH_FIELD } from 'constants/field';
import { getCheckboxColor } from 'utils/utilities';
import { sxCheckboxChildGroup, sxCheckboxGroup } from './CheckboxGroup.style';

export type TCheckboxGroupOption = {
  code: string;
  displayName: string;
  otherText?: string;
  classifications?: {
    code: string;
    displayName: string;
    otherText?: string;
  }[];
};

export interface ICheckboxGroupResponse {
  code: string;
  displayName: string;
  otherText?: string;
  classifications?: {
    code: string;
    displayName: string;
    otherText?: string;
  }[];
}

export interface ICheckboxGroupProps extends CheckboxProps {
  name: string;
  parentName?: string | undefined;
  value: ICheckboxGroupResponse[];
  checkboxGroupValue?: ICheckboxGroupResponse[];
  options: TCheckboxGroupOption[];
  error?: boolean;
  layout?: number;
  onCheckboxChange: (name: string, value: ICheckboxGroupResponse[], parentName?: string) => void;
}

const CheckboxGroup: React.FC<ICheckboxGroupProps> = (props: ICheckboxGroupProps): ReactElement => {
  const { options, disabled, name, parentName, error, value, onCheckboxChange, sx, layout = 12, ...rest } = props;

  const isChecked = (code: string): boolean => value.some(option => option.code === code);

  const handleCheck = (index: number, checked: boolean) => {
    const selectedValue = value;
    const { code, displayName } = options[index];
    return checked ? [...selectedValue, { code, displayName }] : selectedValue?.filter(option => option.code !== code);
  };

  const handleClassifications = (identifier: string, checkBoxData: ICheckboxGroupResponse[], parent = '') => {
    const idx = value.findIndex(option => option.code === identifier);
    value[idx].classifications = checkBoxData.length ? checkBoxData : undefined;
    onCheckboxChange(parent, value);
  };

  const renderInput = (identifier: string, code: string, otherText: string, parent: string) => {
    return (
      <FormControl fullWidth sx={{ p: '1rem', mb: 0, bgcolor: '#EBEFF6' }}>
        <InputLabel htmlFor={`${code}-text-input`}>{otherText}</InputLabel>
        <InputBase
          id={`${code}-text-input`}
          placeholder="Other"
          value={value.find(option => option.code === code)?.otherText || ''}
          inputProps={{ 'aria-label': `${code} Other`, maxLength: MAX_BIG_LENGTH_FIELD }}
          onChange={e => {
            const idx = value.findIndex(option => option.code === code);
            value[idx].otherText = e.target.value;
            onCheckboxChange(identifier, value, parent);
          }}
        />
      </FormControl>
    );
  };

  const renderClassifications = (parent: string, code: string, classificationsOptions: TCheckboxGroupOption[]) => {
    const classificationValues = value.find(option => option.code === code)?.classifications;
    return (
      <CheckboxGroup
        value={(classificationValues || []) as ICheckboxGroupResponse[]}
        checkboxGroupValue={value}
        id={`${code}-classification`}
        name={code}
        parentName={parent}
        onCheckboxChange={handleClassifications}
        options={classificationsOptions as unknown as TCheckboxGroupOption[]}
        sx={sxCheckboxChildGroup}
        layout={6}
      />
    );
  };
  return (
    <Grid container sx={{ ...sxCheckboxGroup, ...sx }} spacing={2}>
      {options.map(({ code, displayName, otherText, classifications }, index) => {
        return (
          <Grid key={`${displayName}-${code}`} item xs={layout}>
            <FormControlLabel
              key={code}
              value={code}
              control={
                <Checkbox
                  {...rest}
                  id={code}
                  onChange={e => onCheckboxChange(name, handleCheck(index, e.target.checked), parentName)}
                  checked={isChecked(code)}
                  inputProps={{
                    'aria-label': `${displayName}-label`,
                  }}
                  color={error ? 'error' : getCheckboxColor()}
                  sx={{ my: 0, alignSelf: 'flex-start' }}
                />
              }
              label={displayName}
              disabled={disabled}
              sx={{ wordBreak: 'break-word', my: '.125rem' }}
            />
            {isChecked(code) && otherText && renderInput(name, code, otherText, parentName as string)}
            {isChecked(code) && classifications && renderClassifications(name, code, classifications)}
          </Grid>
        );
      })}
    </Grid>
  );
};
export default CheckboxGroup;
