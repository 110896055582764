/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { KeyboardEvent } from 'react';

const getKeyboardFocusableElements = (element: HTMLElement): Element[] => {
  const nodeList = element.querySelectorAll(
    'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
  );

  return Array.from(nodeList).filter(el => !el.hasAttribute('disabled'));
};

export const isKeyboardFocusOut = (e: KeyboardEvent): boolean => {
  const focusableElements = getKeyboardFocusableElements(e.currentTarget as HTMLElement);
  const firstElementShiftTab = e.shiftKey && e.key === 'Tab' && focusableElements[0] === e.target;
  const lastElementTab = !e.shiftKey && e.key === 'Tab' && focusableElements[focusableElements.length - 1] === e.target;

  return firstElementShiftTab || lastElementTab;
};
