/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useEffect } from 'react';
import { Box, useMediaQuery, Theme } from '@mui/material';
import { Slider } from '@liaison/liaison-ui';
import { AddCollege } from 'transferPlanner/components/AddCollege';
import { useSlideSettings } from 'hooks/useSlideSettings';
import GpaCalculationErrorAlert from 'transferPlanner/components/GpaCalculationErrorAlert/GpaCalculationErrorAlert';
import { useTransferProgress } from 'transferPlanner/hooks/useTransferProgress';
import MilestoneCompletedModal from 'transferPlanner/components/MilestoneCompletedModal/MilestoneCompletedModal';
import { selectCollegeAction } from 'store/ui/ui.selectors';
import { useSelector } from 'react-redux';
import { setTitle } from 'utils/commonUtils';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'transferPlanner/constants/general';
import Cart from 'transferPlanner/components/MyProgramsCart/Cart';
import { Welcome } from './Welcome';
import { MyCourses } from './MyCourses';
import { MyPrograms } from './MyPrograms';
import { GeProcess } from './GeProcess';
import { MyStatus } from './MyStatus/MyStatus';
import { sxDashboardItem, sxSlider } from './Dashboard.styles';
import TransferProgressTile from './TansferProgressTile/TransferProgressTile';
import { WelcomeModal } from './WelcomeModal';

const Dashboard = (): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const { getActiveSteps, gpaCalculation, isLoading, milestoneData } = useTransferProgress(true);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const sidebar = useSelector(selectCollegeAction);
  const { settings, sliderRef } = useSlideSettings(isMobile);

  useEffect(() => {
    setTitle(t('dashboard.pageTitle'));
  }, [t]);

  useEffect(() => {
    const elements = document.getElementsByClassName('slick-slide');
    if (isMobile && elements) {
      Array.from(elements).forEach((el: Element) => {
        if (el.getAttribute('aria-hidden')) {
          el.removeAttribute('aria-hidden');
        }
      });
    }
  }, [isMobile]);

  return (
    <>
      <WelcomeModal />
      <GpaCalculationErrorAlert />
      {milestoneData && <MilestoneCompletedModal milestones={milestoneData} />}
      <Box sx={{ mt: 1.5, px: 2, mb: 2 }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: 'repeat(auto-fit, minmax(300px, 1fr));', lg: 'repeat(6, 1fr)' },
            columnGap: 2,
            rowGap: 3,
            gridTemplateAreas: {
              xs: `
                "welcome"
                "programs"
                "progress"
                "transfer"
                "explore"
                "courses"
                `,
              sm: `
                "welcome"
                "programs"
                "progress"
                "transfer"
                "explore"
                "courses"
                `,
              md: `
                "welcome"
                "programs"
                "progress"
                "transfer"
                "explore"
                "courses"
                `,
              lg: `
                "welcome welcome welcome welcome welcome welcome"
                "programs programs progress progress transfer transfer"
                "explore explore courses courses courses courses"
                `,
            },
          }}
        >
          <Box sx={{ ...(isMobile ? {} : sxDashboardItem), gridArea: 'welcome' }}>
            <Welcome />
          </Box>
          {isMobile ? (
            <Slider {...settings} ref={sliderRef} sx={sxSlider()}>
              <Box sx={{ ...sxDashboardItem, gridArea: 'programs', height: '23rem', overflowY: 'auto' }}>
                <MyPrograms />
              </Box>
              <Box sx={{ ...sxDashboardItem, gridArea: 'progress', height: '23rem', overflowY: 'auto' }}>
                <GeProcess />
              </Box>
              <Box sx={{ ...sxDashboardItem, gridArea: 'transfer', height: '23rem', overflowY: 'auto' }}>
                <TransferProgressTile
                  getActiveSteps={getActiveSteps}
                  gpaCalculation={gpaCalculation}
                  isLoading={isLoading}
                />
              </Box>
            </Slider>
          ) : (
            <>
              <Box sx={{ ...sxDashboardItem, gridArea: 'programs' }}>
                <MyPrograms />
              </Box>
              <Box sx={{ ...sxDashboardItem, gridArea: 'progress' }}>
                <GeProcess />
              </Box>
              <Box sx={{ ...sxDashboardItem, gridArea: 'transfer' }}>
                <TransferProgressTile
                  getActiveSteps={getActiveSteps}
                  gpaCalculation={gpaCalculation}
                  isLoading={isLoading}
                />
              </Box>
            </>
          )}

          <Box sx={{ ...sxDashboardItem, gridArea: 'explore' }}>
            <MyStatus />
          </Box>
          <Box sx={{ ...sxDashboardItem, gridArea: 'courses' }}>
            <MyCourses />
          </Box>
        </Box>
      </Box>
      {sidebar?.open && <AddCollege />}
      <Cart />
    </>
  );
};

export default memo(Dashboard);
