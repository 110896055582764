/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo } from 'react';
import { Typography, Button, Box } from '@mui/material';
import { Alert } from '@liaison/liaison-ui';
import { useTranslation, Trans } from 'react-i18next';
import { TP_ADDITIONAL_DETAILS } from 'transferPlanner/constants/routeNames';
import { useNavigate } from 'react-router-dom';
import { nameSpace } from 'transferPlanner/constants/general';
import { linkStyle } from 'pages/Pages.styles';
import { IProgramStatus } from './ProgramProgress.utils';

const ProgramProgressStatus = ({ currentProgramStatus, adtInformation }: IProgramStatus): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const navigate = useNavigate();

  const allDissimilar =
    currentProgramStatus?.filter(item => item.isSimilar === false).length === adtInformation?.length;
  const title = allDissimilar
    ? t('programProgress.deSimilarProgram_title')
    : t('programProgress.status.similarProgram_title');

  return (
    <Alert expandable={true} title={title} type={allDissimilar ? 'warning' : 'success'}>
      <Box>
        <Typography component="span" variant="body3">
          <Trans
            t={t}
            i18nKey={
              allDissimilar
                ? 'programProgress.status.dissimilar_description'
                : 'programProgress.status.similar_description'
            }
            components={{
              link1: (
                <Button
                  onClick={() => {
                    navigate(`/${TP_ADDITIONAL_DETAILS.path}`);
                    window.scrollTo(0, 0);
                  }}
                  style={linkStyle}
                  sx={{ paddingX: '0rem', paddingY: '0rem' }}
                />
              ),
            }}
          />
        </Typography>
        <Box component="ul" sx={{ mt: 0, pl: '1rem' }}>
          {currentProgramStatus?.map(
            accSummaryObj =>
              (allDissimilar || accSummaryObj.isSimilar) && (
                <React.Fragment key={accSummaryObj.pid}>
                  <Box component="li">
                    <Typography
                      variant="body3"
                      dangerouslySetInnerHTML={{
                        __html: t('programProgress.status.listitem', {
                          program: accSummaryObj.programName?.toUpperCase(),
                          college: accSummaryObj.collegeName?.toUpperCase(),
                        }),
                      }}
                    />
                  </Box>
                </React.Fragment>
              )
          )}
        </Box>
      </Box>
    </Alert>
  );
};
export default memo(ProgramProgressStatus);
