/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { useMediaQuery, Theme, Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomChip, IconArrowLeft, IconLink } from '@liaison/liaison-ui';

import { nameSpace } from 'transferPlanner/constants/general';
import { StyledDivider, sxCard } from 'pages/Pages.styles';

export type TAgreementsCardProps = {
  campusName?: string;
  campusAddress?: string;
  communityCollegeName?: string;
  communityCollegeAddress?: string;
  artifactUrl?: string;
  isADTCard?: boolean;
};

export const AgreementsCard = ({
  campusName = '',
  campusAddress = '',
  communityCollegeName = '',
  communityCollegeAddress = '',
  artifactUrl = '',
  isADTCard = false,
}: TAgreementsCardProps): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Card variant="outlined" sx={sxCard}>
      <CardContent
        sx={{
          py: 1.5,
          '&:last-child': {
            pb: 1.5,
          },
        }}
      >
        <Grid container columnSpacing={3.4} justifyContent="space-between">
          <Grid item xs={5} sm={4.2} sx={{ display: 'flex' }}>
            <Box>
              {isADTCard && (
                <CustomChip size="small" type="info" label={t('enrollmentDetails.csuProgram')} variant="square" />
              )}
              <Box>
                <Typography variant="subtitle3" sx={{ overflowWrap: 'anywhere' }}>
                  {campusName}
                </Typography>
              </Box>
              <Typography variant="subtitle8" sx={{ color: '#75757D' }}>
                {campusAddress}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={0.8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <IconArrowLeft sx={{ color: '#8E9AAB', fontSize: '1.3rem' }} />
          </Grid>
          <Grid item xs={5} sm={3.5} sx={{ display: 'flex' }}>
            <Box>
              {isADTCard && (
                <CustomChip size="small" type="info" label={t('enrollmentDetails.adtProgram')} variant="square" />
              )}
              <Box>
                <Typography variant="subtitle3" sx={{ overflowWrap: 'anywhere' }}>
                  {communityCollegeName}
                </Typography>
              </Box>
              <Typography variant="subtitle8" sx={{ color: '#75757D' }}>
                {communityCollegeAddress}
              </Typography>
            </Box>
          </Grid>
          {isMobile && (
            <Grid item xs={12}>
              <StyledDivider />
            </Grid>
          )}
          <Grid item xs={12} md={3} sm={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
              sx={{ height: 32, borderRadius: 24, fontSize: '0.61rem' }}
              variant="contained"
              startIcon={<IconLink />}
              href={artifactUrl}
              target="_blank"
              rel="noopener"
              className="buttonHref"
            >
              {t(isADTCard ? 'enrollmentDetails.viewADTDetails' : 'enrollmentDetails.viewAgreement').toUpperCase()}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
