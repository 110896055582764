/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export const DISABLED_CHARACTERS_REG = /^[a-zA-Z0-9_ "')(ƒ-]*[\x20-\x7F\x91-\x94\xC0-\xFF\u0100-\u017F]*$/;
export const ONLY_TEXT_REG = /^[a-zA-Z.]+$/;
export const EMAIL_REG = /^$|^[^@<>[\]()\\,"]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,10})$/;
export const CONTAIN_CHARACTERS_REG = /[\W_]+/;
export const CONTAIN_NUMBER_REG = '.*\\d+.*';
export const CONTAIN_LOWER_CASE_REG = /[a-z]/;
export const CONTAIN_UPPER_CASE_REG = /[A-Z]/;
export const NAME_REGEX = /^[\da-zA-Z-.' ,)(\u00C0-\u00FF\u0100-\u017F\u0180-\u024F]*$/gm;

export const TEXT_REGEX =
  /^[a-zA-Z\d._ "'’)(ƒ-]*[\u0020-\u007F\u0091-\u0094\u00C0-\u00FF\u0100-\u017F\u0180-\u024F‘’“”‑–—]*$/gm;

export const US_POSTAL_CODE_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;
export const GRADE_REGEX = /^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$|^[a-zA-Z]{1}[a-zA-Z-+]{0,1}$/;
export const AP_ID_REG = /^[a-zA-Z0-9]{8}$/;
export const TOEFL_ID_REG = /^(\d{7}|\d{8}|\d{16})$/;
export const IELTS_ID_REG = /^\d{6}$/;
export const TRF_NUMBER_REG = /^[A-Za-z0-9]{15,18}$/;
export const GRE_ID_REG = /^\d{7}$/;
export const GMAT_ID_REG = /^\d{1,20}$/;
export const ACT_ID_REG = /^[a-zA-Z0-9]{1,20}$/;
export const MCAT_ID_REG = /^\d{8}$/;
export const SAT_ID_REG = /^\d{1,9}$/;
export const MD_URL_REG = /^(https?:\/\/)?((www\.)?((youtube|soundcloud|vimeo|sketchfab)\.com)|(youtu\.be))\/.+$/;
export const URL_REG = /^(https?:\/\/)?([\w.-]+)\.([a-z.]{2,6})(\/[\w.-]*)*(\?[\w%&=]*)?\/?$/;
export const LINKEDIN_URL_REG = /^(https?:\/\/)?([\w.]*)linkedin\.com\/in\/([\w-]*)\/?$/;
export const ISO_DATE_REG = /_\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/gi;
