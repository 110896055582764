/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import * as yup from 'yup';
import moment from 'moment';
import { DOB_FORMAT } from 'constants/general';
import { min, max, yearValidator } from 'utils/utilities';
import { t } from 'i18next';
import { GRADE_REGEX } from 'constants/regex';
import { studentIdMaxLength } from 'utils/AcademicHistory.utils';
import type { TTermsAndCourse } from 'store/academicHistory/academicHistory.slice';

const today = new Date();

interface TestContextExtended {
  from: {
    value: TTermsAndCourse;
  }[];
}

export const validationSchema = yup.object().shape({
  highSchools: yup.array().of(
    yup.object().shape({
      name: yup.string().trim().required(t('school.error.name.required')),
      address: yup.object().when('name', {
        is: (name: string) => name?.length !== 0,
        then: yup.object().shape({
          country: yup.object().shape({
            code: yup.string().required(t('address.error.country_required')),
          }),
          region: yup.object().shape({
            code: yup.string().required(t('address.error.region_required')),
          }),
        }),
      }),
      startDate: yup
        .string()
        .required(t('common.error.startDate.required'))
        .test('Validate date', t('common.error.date.invalid'), value => {
          if (!value) return true;
          return moment(value, value.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid();
        })
        .test('Min Date', t('common.error.date.minDate'), value => {
          if (value) {
            return new Date(new Date(min).setHours(0)).getTime() < new Date(value).getTime();
          }
          return true;
        })
        .test('Max date', t('common.error.date.maxDate'), value => {
          if (value) {
            return today.getTime() >= new Date(value).getTime();
          }
          return true;
        }),
      endDate: yup
        .string()
        .test('Is date valid', t('common.error.date.invalid'), value => {
          if (!value) return true;
          return moment(value, value.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid();
        })
        .test('Is date in correct range', t('common.error.date.yearRange'), value => {
          if (value) {
            return yearValidator(value);
          }
          return true;
        })
        .test('match', t('common.error.date.endDate'), function callback(value) {
          const { parent } = this;
          const startDate = parent?.startDate;
          if (value) {
            const endDate = new Date(new Date(value).getFullYear(), new Date(value).getMonth() + 1, 1);
            if (
              endDate &&
              startDate &&
              moment(startDate, startDate.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid()
            ) {
              return endDate.getTime() > new Date(startDate).getTime();
            }
          }
          return true;
        }),
      termType: yup.object().shape({
        code: yup.string().required(t('colleges.error.termType.required')),
      }),
      gpa: yup
        .number()
        .min(0.1, t('academicHistory.error.gpa.minVal'))
        .max(4, t('academicHistory.error.gpa.maxVal'))
        .nullable(true)
        .transform((_, val) => (val === Number(val) ? val : null)),
      graduated: yup.string().required(t('academicHistory.error.graduated.required')),
      graduationDate: yup
        .string()
        .test('Is date valid', t('common.error.date.invalid'), value => {
          if (!value) return true;
          return moment(value, value.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid();
        })
        .test('Is date in correct range', t('common.error.date.yearRange'), value => {
          if (value) {
            return yearValidator(value);
          }
          return true;
        })
        .test('match', t('academicHistory.error.graduationDate.condition'), function callback(value) {
          const { parent } = this;
          const startDate = parent?.startDate;
          if (value) {
            const graduationDateVal = new Date(value);
            if (
              graduationDateVal &&
              startDate &&
              moment(startDate, startDate.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid()
            ) {
              return graduationDateVal.getTime() > new Date(startDate).getTime();
            }
          }
          return true;
        })
        .test(
          'graduation date must be in the past',
          t('academicHistory.error.graduationDate.past'),
          function callback(value) {
            const { parent } = this;
            const graduated = parent?.graduated;
            if (graduated === 'Yes' && value) {
              const currentDate = new Date();
              const graduationDateVal = new Date(value);
              return graduationDateVal.getTime() < currentDate.getTime();
            }
            return true;
          }
        )
        .test(
          'graduation date must be in the future',
          t('academicHistory.error.graduationDate.future'),
          function callback(value) {
            const { parent } = this;
            const graduated = parent?.graduated;
            if (graduated === 'No' && value) {
              const currentDate = new Date();
              const graduationDateVal = new Date(value);
              return graduationDateVal.getTime() > currentDate.getTime();
            }
            return true;
          }
        ),
    })
  ),
  colleges: yup.array().of(
    yup.object().shape({
      name: yup.string().trim().required(t('colleges.error.name.required')),
      address: yup.object().when('name', {
        is: (name: string) => name?.length !== 0,
        then: yup.object().shape({
          country: yup.object().shape({
            code: yup.string().required(t('address.error.country_required')),
          }),
          region: yup.object().shape({
            code: yup.string().required(t('address.error.region_required')),
          }),
        }),
      }),
      studentId: yup
        .string()
        .matches(RegExp(/^[0-9a-zA-Z ]*$/gm), t('error.notAllowed'))
        .max(studentIdMaxLength, t('academicHistory.error.StudentId')),
      graduated: yup.string().required(t('academicHistory.error.graduated.required')),
      graduationDate: yup
        .string()
        .test('Validate date', t('common.error.date.invalid'), value => {
          if (!value) return true;
          return moment(value, value.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid();
        })
        .test('Is date in correct range', t('common.error.date.yearRange'), value => {
          if (value) {
            return yearValidator(value);
          }
          return true;
        })
        .test('match', t('academicHistory.error.graduationDate.condition'), function callback(value) {
          const { parent } = this;
          const startDate = parent?.startDate;
          if (value) {
            const graduationDateVal = new Date(value);
            if (
              graduationDateVal &&
              startDate &&
              moment(startDate, startDate.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid()
            ) {
              return graduationDateVal.getTime() > new Date(startDate).getTime();
            }
          }
          return true;
        })
        .test(
          'graduation date must be in the past',
          t('academicHistory.error.graduationDate.past'),
          function callback(value) {
            const { parent } = this;
            const graduated = parent?.graduated;
            if (graduated === 'Yes' && value) {
              const currentDate = new Date();
              const graduationDateVal = new Date(value);
              return graduationDateVal.getTime() < currentDate.getTime();
            }
            return true;
          }
        )
        .test(
          'graduation date must be in the future',
          t('academicHistory.error.graduationDate.future'),
          function callback(value) {
            const { parent } = this;
            const graduated = parent?.graduated;
            if (graduated === 'No' && value) {
              const currentDate = new Date();
              const graduationDateVal = new Date(value);
              return graduationDateVal.getTime() > currentDate.getTime();
            }
            return true;
          }
        ),
      termType: yup.object().shape({
        code: yup.string().required(t('colleges.error.termType.required')),
      }),
      gpa: yup
        .number()
        .min(0.1, t('academicHistory.error.gpa.minVal'))
        .max(4, t('academicHistory.error.gpa.maxVal'))
        .nullable(true)
        .transform((_, val) => (val === Number(val) ? val : null)),
      startDate: yup
        .string()
        .required(t('common.error.startDate.required'))
        .test('Validate date', t('error.invalidDate'), value => {
          if (!value) return true;
          return moment(value, value.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid();
        })
        .test('Min Date', t('error.minDate'), value => {
          if (value) {
            return new Date(new Date(min).setHours(0)).getTime() < new Date(value).getTime();
          }
          return true;
        })
        .test('Max date', t('error.dateRange'), value => {
          if (value) {
            return new Date(new Date(max).setHours(0)).getTime() >= new Date(value).getTime();
          }
          return true;
        }),
      endDate: yup
        .string()
        .test('Is date valid', t('error.invalidDate'), value => {
          if (!value) return true;
          return moment(value, value.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid();
        })
        .test('Is date in correct range', t('common.error.date.yearRange'), value => {
          if (value) {
            return yearValidator(value);
          }
          return true;
        })
        .test('match', t('common.error.date.endDate'), function callback(value) {
          const { parent } = this;
          const startDate = parent?.startDate;
          if (value) {
            const endDate = new Date(new Date(value).getFullYear(), new Date(value).getMonth() + 1, 1);
            if (
              endDate &&
              startDate &&
              moment(startDate, startDate.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid()
            ) {
              return endDate.getTime() > new Date(startDate).getTime();
            }
          }
          return true;
        }),
    })
  ),
});

export const gradeLevelValidationSchema = yup.object().shape({
  type: yup.object().shape({
    code: yup.string().when('$formName', (formName, field) => {
      return formName === 'gradeLevels' ? field.required(t('school.error.course.grade.required')) : field;
    }),
  }),
  academicYear: yup.string().when('$formName', (formName, field) => {
    return formName === 'gradeLevels' ? field.required(t('colleges.error.course.term.year.required')) : field;
  }),
  gpa: yup.number().when('$formName', (formName, field) => {
    return formName === 'gradeLevels'
      ? field
          .min(0.1, t('academicHistory.error.gpa.minVal'))
          .max(4, t('academicHistory.error.gpa.maxVal'))
          .nullable(true)
          .transform((_: number, val: number) => (val === Number(val) ? val : null))
      : field;
  }),
  startDate: yup
    .string()
    .test('Validate date', t('common.error.date.invalid'), value => {
      if (!value) return true;
      return moment(value, value.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid();
    })
    .test(
      'Start Date must be within Grade Level Year',
      t('academicHistory.gradeLevel.error.date.startDate.withinGradeLevelYear'),
      (val, ctx) => {
        if (val) {
          const academicYear = ctx.parent?.academicYear;
          const startYear = val.split('-')[0];
          if (startYear && academicYear) {
            return startYear === academicYear;
          }
        }
        return true;
      }
    ),
  endDate: yup
    .string()
    .test('Is date valid', t('common.error.date.invalid'), value => {
      if (!value) return true;
      return moment(value, value.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid();
    })
    .test('End date cannot be before start date', t('common.error.date.endDate'), (val, ctx) => {
      if (val) {
        const startDate = ctx.parent?.startDate;
        const endDate = new Date(new Date(val).getFullYear(), new Date(val).getMonth() + 1, 1);
        if (endDate && startDate && moment(startDate, 'YYYY-MM', true).isValid()) {
          return endDate.getTime() > new Date(startDate).getTime();
        }
      }
      return true;
    })
    .test(
      'End date should be within or after Academic Year',
      t('academicHistory.gradeLevel.error.date.endDate.withinAcademicYear'),
      (val, ctx) => {
        if (val) {
          const startDate = ctx.parent?.startDate;
          if (!(startDate && yearValidator(startDate))) {
            const academicYear = ctx.parent?.academicYear;
            const endYear = val.split('-')[0];
            if (endYear && academicYear) {
              return Number(endYear) >= Number(academicYear);
            }
          }
        }
        return true;
      }
    ),
});

export const termValidationSchema = yup.object().shape({
  type: yup.object().shape({
    code: yup.string().when('$formName', (formName, field) => {
      return formName === 'terms' ? field.required(t('colleges.error.course.term.required')) : field;
    }),
  }),
  academicYear: yup.string().when('$formName', (formName, field) => {
    return formName === 'terms' ? field.required(t('colleges.error.course.term.year.required')) : field;
  }),
  completionStatus: yup.object().shape({
    code: yup.string().when('$formName', (formName, field) => {
      return formName === 'terms' ? field.required(t('colleges.error.course.term.completionStatus.required')) : field;
    }),
  }),
});

export const childValidionSchema = yup.object().shape({
  hscourse: yup.object().shape({
    title: yup
      .string()
      .trim()
      .when('$formName', (formName, field) => {
        return formName === 'gradeLevels' ? field.required(t('colleges.error.course.name.required')) : field;
      }),
    classification: yup.object().shape({
      code: yup.string().when('$formName', (formName, field) => {
        return formName === 'gradeLevels' ? field.required(t('degree.error.courseType.required')) : field;
      }),
    }),
    credits: yup
      .number()
      .min(0.1, t('school.error.course.minimumCredit.condition'))
      .max(99.75, t('colleges.error.course.maximumCredit.condition'))
      .nullable(true)
      .transform((_, val) => (val === Number(val) ? val : null)),
    grade: yup
      .object()
      .when('$formName', (formName, field) => {
        return formName === 'gradeLevels'
          ? field.shape({
              overall: yup
                .string()
                .trim()
                .test('To check overall required', t('colleges.error.course.grade.required'), value => !!value)
                .test('To check is valid overall', t('colleges.error.course.grade.invalid'), value => {
                  if (value) {
                    return GRADE_REGEX.test(value);
                  }
                  return false;
                }),
              data: yup
                .object()
                // "termType" and "summer" added in HighSchoolCourseForm.tsx
                // setValue(`${coursePrefix}.grade.termType`, data?.termType?.code);
                // setValue(`${coursePrefix}.grade.summer`, 'summer');
                .when(['termType', 'summer'], {
                  is: (termType: string, summer: string) => termType === 'QUARTER' && summer !== 'summer',
                  then: yup.object().shape({
                    quarter1: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.quarter1.required'))
                        .required(t('collegesGrades.error.quarter1.required')),
                    }),
                    quarter2: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.quarter2.required'))
                        .required(t('collegesGrades.error.quarter2.required')),
                    }),
                    quarter3: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.quarter3.required'))
                        .required(t('collegesGrades.error.quarter3.required')),
                    }),
                    quarter4: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.quarter4.required'))
                        .required(t('collegesGrades.error.quarter4.required')),
                    }),
                  }),
                })
                .when(['termType', 'summer'], {
                  is: (termType: string, summer: string) => termType === 'QUARTER' && summer === 'summer',
                  then: yup.object().shape({
                    summer1: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.summer1.required'))
                        .required(t('collegesGrades.error.summer1.required')),
                    }),
                    summer2: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.summer2.required'))
                        .required(t('collegesGrades.error.summer2.required')),
                    }),
                    summer3: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.summer3.required'))
                        .required(t('collegesGrades.error.summer3.required')),
                    }),
                    summer4: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.summer4.required'))
                        .required(t('collegesGrades.error.summer4.required')),
                    }),
                  }),
                })
                .when(['termType', 'summer'], {
                  is: (termType: string, summer: string) => termType === 'SEMESTER' && summer !== 'summer',
                  then: yup.object().shape({
                    fall: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.fall.required'))
                        .required(t('collegesGrades.error.fall.required')),
                    }),
                    spring: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.spring.required'))
                        .required(t('collegesGrades.error.spring.required')),
                    }),
                  }),
                })
                .when(['termType', 'summer'], {
                  is: (termType: string, summer: string) => termType === 'SEMESTER' && summer === 'summer',
                  then: yup.object().shape({
                    summer1: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.summer1.required'))
                        .required(t('collegesGrades.error.summer1.required')),
                    }),
                    summer2: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.summer2.required'))
                        .required(t('collegesGrades.error.summer2.required')),
                    }),
                  }),
                })
                .when(['termType', 'summer'], {
                  is: (termType: string, summer: string) => termType === 'TRIMESTER' && summer !== 'summer',
                  then: yup.object().shape({
                    fall: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.fall.required'))
                        .required(t('collegesGrades.error.fall.required')),
                    }),
                    winter: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.winter.required'))
                        .required(t('collegesGrades.error.winter.required')),
                    }),
                    spring: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.spring.required'))
                        .required(t('collegesGrades.error.spring.required')),
                    }),
                  }),
                })
                .when(['termType', 'summer'], {
                  is: (termType: string, summer: string) => termType === 'TRIMESTER' && summer === 'summer',
                  then: yup.object().shape({
                    summer1: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.summer1.required'))
                        .required(t('collegesGrades.error.summer1.required')),
                    }),
                    summer2: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.summer2.required'))
                        .required(t('collegesGrades.error.summer2.required')),
                    }),
                    summer3: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.summer3.required'))
                        .required(t('collegesGrades.error.summer3.required')),
                    }),
                  }),
                })
                .when(['termType', 'summer'], {
                  is: (termType: string, summer: string) => termType === 'FULL_YEAR' && summer !== 'summer',
                  then: yup.object().shape({
                    fullYear: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.fullYear.required'))
                        .required(t('collegesGrades.error.fullYear.required')),
                    }),
                  }),
                })
                .when(['termType', 'summer'], {
                  is: (termType: string, summer: string) => termType === 'FULL_YEAR' && summer === 'summer',
                  then: yup.object().shape({
                    summer1: yup.object().shape({
                      code: yup
                        .string()
                        .typeError(t('collegesGrades.error.summer1.required'))
                        .required(t('collegesGrades.error.summer1.required')),
                    }),
                  }),
                }),
            })
          : field;
      })
      .default({}),
  }),
  degrees: yup.array().of(
    yup.object().shape({
      type: yup.object().shape({
        code: yup.string().required(t('degree.error.courseType.required')),
      }),
      endDate: yup
        .string()
        .required(t('common.error.required'))
        .test('Validate date', t('error.invalidDate'), value => {
          if (!value) return true;
          return moment(value, value.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid();
        })
        .test(
          'Date cannot be greater than 2100',
          t('degree.error.inprogressFutureDate.message'),
          function degreeInprogressAndFeatureDate(value) {
            if (value && this.parent?.degreeStatus?.code === 'DEGREE_IN_PROGRESS') {
              return new Date(new Date(max).setHours(0)).getTime() >= new Date(value).getTime();
            }
            return true;
          }
        )
        .test(
          'Date cannot be less than 1900',
          t('degree.error.awardedPastDate.message'),
          function degreeAwardedAndPastDate(value) {
            if (value && this.parent?.degreeStatus?.code === 'AWARDED') {
              return new Date(new Date(min).setHours(0)).getTime() < new Date(value).getTime();
            }
            return true;
          }
        )
        .test('Degree Inprogress', t('collgees.degree.inprogress.error'), function degreeInprogress(value) {
          if (value && this.parent?.degreeStatus?.code === 'DEGREE_IN_PROGRESS') {
            const updatedVal = value.split('-').length === 3 ? value : `${value}-01`;
            return today.getTime() <= new Date(updatedVal).getTime();
          }
          return true;
        })
        .test('Degree Awarded', t('collgees.degree.awarded.error'), function degreeAwarded(value) {
          if (value && this.parent?.degreeStatus?.code === 'AWARDED') {
            const updatedVal = value.split('-').length === 3 ? value : `${value}-01`;
            return today.getTime() >= new Date(updatedVal).getTime();
          }
          return true;
        })
        .test(
          'End date cannot be before start date',
          t('degree.error.endDateStartDate.message'),
          function endDateValidation(value) {
            if (value && this.parent?.startDate) {
              return new Date(value).getTime() >= new Date(this.parent?.startDate).getTime();
            }
            return true;
          }
        ),
      startDate: yup
        .string()
        .test('Validate start date must be in the past', t('error.invalidDate'), value => {
          if (!value) return true;
          return moment(value, value.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid();
        })
        .test('Date cannot be less than 1900', t('degree.error.awardedPastDate.message'), function pastDate(value) {
          if (value) {
            return new Date(new Date(min).setHours(0)).getTime() < new Date(value).getTime();
          }
          return true;
        })
        .test('Date cannot be in the future', t('degree.error.startDate.message'), function restrictFutureDate(value) {
          if (value) {
            return new Date(new Date(today).setHours(0)).getTime() > new Date(value).getTime();
          }
          return true;
        })
        .test(
          'Start date cannot be after end date',
          t('degree.error.startDateEndDate.message'),
          function startDateValidation(value) {
            if (value && this.parent?.endDate) {
              return new Date(value).getTime() <= new Date(this.parent?.endDate).getTime();
            }
            return true;
          }
        ),
    })
  ),
  course: yup.object().shape({
    code: yup
      .string()
      .trim()
      .when('$formName', (formName, field) => {
        return formName === 'terms' ? field.required(t('colleges.error.course.number.required')) : field;
      }),
    title: yup
      .string()
      .trim()
      .when('$formName', (formName, field) => {
        return formName === 'terms' ? field.required(t('colleges.error.course.name.required')) : field;
      }),
    credits: yup
      .number()
      .when('$formName', (formName, field) => {
        return formName === 'terms' ? field.required(t('colleges.error.course.credit.required')) : field;
      })
      .min(0, t('colleges.error.course.minimumCredit.condition'))
      .max(99.75, t('colleges.error.course.maximumCredit.condition'))
      .nullable(true)
      .transform((_, val) => (val === Number(val) ? val : null)),
    grade: yup
      .string()
      .trim()
      .test('To check grade required', t('colleges.error.course.grade.required'), function callback(value, context) {
        const { from } = context as unknown as TestContextExtended;
        const parentFormData = from[1].value;
        if (parentFormData?.term?.completionStatus?.code === 'COMPLETED' && !value) {
          return false;
        }
        return true;
      })
      .test('To check is valid grade', t('colleges.error.course.grade.invalid'), function callback(value, context) {
        const { from } = context as unknown as TestContextExtended;
        const parentFormData = from[1].value;
        if (parentFormData?.term?.completionStatus?.code === 'COMPLETED') {
          if (value) {
            return value === 'None' || GRADE_REGEX.test(value);
          }
          return false;
        }
        return true;
      }),
  }),
  term: termValidationSchema,
  gradeLevel: gradeLevelValidationSchema,
});
