/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TError = string | null;

export interface IWrapper {
  code: string;
  displayName: string;
}

export type TGenEdCourse = {
  id: string;
  collegeId: string;
  collegeName?: string;
  termId?: string;
  termType: IWrapper;
  termAcademicYear: number;
  termCompletionStatus: IWrapper;
  code?: string;
  title?: string;
  subject?: IWrapper;
  grade?: string;
  normalizedGrade?: IWrapper;
  credits: number | string;
  source: IWrapper;
};

export type TGenEdTest = {
  id: string;
  type: IWrapper;
  testId: string;
  status: IWrapper;
  testDate: string;
  classification: IWrapper;
  verified: boolean;
  source: IWrapper;
  scores?: { total: string | number };
};

export interface IRequirements {
  code: string;
  name?: string;
  optOut?: boolean;
  unitsEarned?: number;
  course?: TGenEdCourse;
  test?: TGenEdTest;
}

export interface IGenEdCategories {
  code: string;
  category: string;
  completionPercentage?: number;
  unitsEarned?: number;
  unitsCompleted?: number;
  unitsRequired: number;
  requirements?: IRequirements[];
}

export interface IGenEdRequirementsConfig {
  genEdCategories?: IGenEdCategories[];
}

export interface IGenEdProcess {
  profileId?: string;
  lastUpdatedByTenant?: string;
  totalCompletionPercentage?: number;
  totalUnitsEarned?: number;
  totalUnitsRequired?: number;
  genEdCategories?: IGenEdCategories[];
}

export interface ICoursesAndTests {
  courses?: TGenEdCourse[];
  tests?: TGenEdTest[];
}

export interface IFormattedCoursesAndTests extends ICoursesAndTests {
  id: string;
  text: string;
  course?: TGenEdCourse;
  test?: TGenEdTest;
}

export interface IGpaCalculation {
  profileId: string;
  lastUpdatedTimestamp: string;
  tenants: string[];
  lastUpdatedByTenant: string;
  transferGPA: {
    status: {
      code: string;
      displayName: string;
    };
    gpa: number;
    unitsAttempted: number;
    unitsEarned: number;
    unitsCompleted: number;
    qualityPoints: number;
  };
}

export interface IGenEdProcessState {
  loading: boolean;
  genEd: IGenEdProcess | null;
  genEdMilestones: IGenEdMilestone[] | null;
  coursesAndTests: ICoursesAndTests[] | null;
  gpaCalculation: IGpaCalculation | null;
  error: TError;
}

export const initialState: IGenEdProcessState = {
  loading: false,
  genEd: null,
  genEdMilestones: null,
  coursesAndTests: null,
  gpaCalculation: null,
  error: null,
};

export interface IGenEdMilestone {
  name: IWrapper;
  status: string;
  hasBeenShown?: boolean;
  hasBeenAwarded?: boolean;
  displayPriority: number;
  isCurrentBadge?: boolean;
}

const genEdProcessSlice = createSlice({
  name: 'genEdProcess',
  initialState,
  reducers: {
    genEdProcessStart: (state): IGenEdProcessState => ({
      ...state,
      loading: true,
      error: null,
    }),
    genEdProcessSuccess: (state, action: PayloadAction<IGenEdProcess>): IGenEdProcessState => ({
      ...state,
      loading: false,
      genEd: action.payload,
    }),
    genEdProcessFailure: (state, action: PayloadAction<TError>): IGenEdProcessState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    genEdMilestonesStart: (state): IGenEdProcessState => ({
      ...state,
      loading: true,
      error: null,
    }),
    genEdMilestoneSuccess: (state, action: PayloadAction<IGenEdMilestone[]>): IGenEdProcessState => ({
      ...state,
      loading: false,
      genEdMilestones: action.payload,
    }),
    genEdMilestonesFailure: (state, action: PayloadAction<TError>): IGenEdProcessState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    coursesAndTestsStart: (state): IGenEdProcessState => ({
      ...state,
      loading: true,
      error: null,
    }),
    coursesAndTestsSuccess: (state, action: PayloadAction<IFormattedCoursesAndTests[]>): IGenEdProcessState => ({
      ...state,
      loading: false,
      coursesAndTests: action.payload,
    }),
    coursesAndTestsFailure: (state, action: PayloadAction<TError>): IGenEdProcessState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    gpaCalculationStart: (state): IGenEdProcessState => ({
      ...state,
      loading: true,
      error: null,
    }),
    gpaCalculationSuccess: (state, action: PayloadAction<IGpaCalculation>): IGenEdProcessState => ({
      ...state,
      loading: false,
      gpaCalculation: action.payload,
      error: null,
    }),
    gpaCalculationFailure: (state, action: PayloadAction<TError>): IGenEdProcessState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    genEdProcessReset: () => initialState,
  },
});

export const {
  genEdProcessStart,
  genEdProcessSuccess,
  genEdProcessFailure,
  genEdProcessReset,
  genEdMilestonesStart,
  genEdMilestoneSuccess,
  genEdMilestonesFailure,
  coursesAndTestsStart,
  coursesAndTestsSuccess,
  coursesAndTestsFailure,
  gpaCalculationSuccess,
  gpaCalculationStart,
  gpaCalculationFailure,
} = genEdProcessSlice.actions;

export default genEdProcessSlice.reducer;
