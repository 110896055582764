/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo } from 'react';
import {
  Box,
  Button,
  Grid,
  Skeleton,
  Stack,
  Typography,
  Step,
  StepLabel,
  Stepper,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconTransfer } from '@liaison/liaison-ui';
import { nameSpace } from 'transferPlanner/constants/general';
import { useNavigate } from 'react-router-dom';
import { TRANSFER_PROGRESS } from 'transferPlanner/constants/routeNames';
import { IGpaCalculation } from 'transferPlanner/store/genEdProcess/genEdProcess.slice';

interface ITransferProgressTileProps {
  getActiveSteps: () => number;
  gpaCalculation: IGpaCalculation | null;
  isLoading: boolean;
}

const TransferProgressTile = memo(
  ({ getActiveSteps, gpaCalculation, isLoading }: ITransferProgressTileProps): ReactElement => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
    const { t } = useTranslation(nameSpace);
    const navigate = useNavigate();
    const activeSteps = getActiveSteps();

    return (
      <Box sx={{ p: 2 }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Stack direction="row" alignItems="center" sx={{ mb: isMobile ? 0.5 : '0rem' }}>
              <IconTransfer
                sx={{
                  borderRadius: 0.5,
                  fontSize: 32,
                  p: 0.8,
                  color: '#CC0B2A',
                  bgcolor: '#D9485F4D',
                  mr: 1,
                }}
              />
              <Typography variant="subtitle1" component="div">
                {t('transferProgress.header')}
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Button
              size="small"
              onClick={() => navigate(`/${TRANSFER_PROGRESS}`)}
              variant="outlined"
              aria-label={t('transfer_progress_view_label')}
              tabIndex={0}
              data-testid="transferProgressTileViewDetails"
            >
              {t('view_label')}
            </Button>
          </Grid>
        </Grid>
        {isLoading ? (
          <Skeleton data-testid="skeleton" variant="rectangular" width="100%" height={286} sx={{ pt: 2 }} />
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: 6,
                transform: isMobile ? 'translateX(-0.6rem)' : 'inherit',
              }}
            >
              <Typography variant="subtitle7" sx={{ mb: 1.5, pl: 1.5 }}>
                {t('transferProgress.nextStep')}
              </Typography>
              <Stepper
                activeStep={Math.floor(activeSteps)}
                orientation="horizontal"
                alternativeLabel={true}
                sx={{
                  width: '100%',
                  '& .MuiStepConnector-root': {
                    left: `calc(-50% + ${isMobile ? '-10' : '-19'}%)`,
                    right: `calc(50% + -19%)`,
                    '@media (min-width:1200px) and (max-width: 1400px)': {
                      left: `calc(-50% + ${isMobile ? '-10' : '-11'}%)`,
                      right: `calc(50% + -19%)`,
                    },
                    '@media (min-width:900px) and (max-width: 1199px)': {
                      left: `calc(-50% + ${isMobile ? '-10' : '-28'}%)`,
                      right: `calc(50% + -13%)`,
                    },
                  },
                }}
              >
                <Step data-testid="stepper1">
                  <StepLabel sx={{ display: 'flex', flexDirection: 'column', width: 'min-content' }}>
                    <Typography variant="buttonSmall">
                      {activeSteps >= 1.4 ? t('transferProgress.stepper2') : t('transferProgress.stepper1')}
                    </Typography>
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel sx={{ display: 'flex', flexDirection: 'column', width: 'min-content', marginLeft: '60%' }}>
                    <Typography variant="buttonSmall">
                      {activeSteps >= 1.4 ? t('transferProgress.stepper3') : t('transferProgress.stepper2')}
                    </Typography>
                  </StepLabel>
                </Step>
              </Stepper>
            </Box>
            <Typography
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 10,
                border: '1px solid #aaa',
                padding: '1.3rem 1rem',
                borderRadius: '0.5rem',
                alignItems: 'center',
                fontSize: '1.5rem',
                fontWeight: '600',
              }}
            >
              {t('transferProgress.transferGpa')}
              <Typography
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 600,
                }}
                role="presentation"
              >
                {gpaCalculation?.transferGPA?.gpa ? gpaCalculation.transferGPA.gpa.toFixed(2) : 'N/A'}
              </Typography>
            </Typography>
          </>
        )}
      </Box>
    );
  }
);

export default TransferProgressTile;
