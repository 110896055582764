/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React from 'react';
import {
  DASHBOARD,
  TSP_QUESTIONNAIRE,
  RESEARCH_CAMPUS_AND_PROGRAMS,
  TSP_ENROLLMENT,
  TSP_ENROLLMENT_SUCCESS,
  TP_ADDITIONAL_DETAILS,
  GENERAL_EDUCATION,
  PROGRAM_PROGRESS,
  TRANSFER_PROGRESS,
  TIMELINE_CALCULATOR,
} from 'transferPlanner/constants/routeNames';
import { PageLayout as TransferPlannerPageLayout } from 'transferPlanner/components/PageLayout';
import GeneralEducationLayout from 'transferPlanner/components/PageLayout/GeneralEducationLayout';
import { Dashboard } from 'transferPlanner/pages/Dashboard';
import { Questionnaire, QuestionnaireV2, DynamicQuestionnaire } from 'transferPlanner/pages/Questionnaire';
import { TpAdditionalDetails } from 'transferPlanner/pages/TpAdditionalDetails';
import { CampusesAndPrograms } from 'transferPlanner/pages/CampusesAndPrograms';
import { TspEnrollment } from 'transferPlanner/pages/TspEnrollment';
import { ProgramProgress } from 'transferPlanner/pages/ProgramProgress';
import { GeneralEducation } from 'transferPlanner/pages/GeneralEducation';
import FormProvider from 'transferPlanner/pages/TspEnrollment/ContextForm';
import { buildFeatureRouteObject, isFeatureActive, TRouteObject } from 'utils/features.utils';
import { TimelineCalculator } from 'transferPlanner/pages/TimelineCalculator';
import { IFeature } from 'store/features/features.slice';
import { TransferProgress } from './pages/GeneralEducation/TransferProgress';

const getQuestionnaireElement = (features: IFeature[] | null) => {
  if (isFeatureActive(features, 'HIDE_JULY_TP_ANSWERS')) {
    /* istanbul ignore next */
    if (!isFeatureActive(features, 'DISABLE_DYNAMIC_QUESTIONNAIRE')) {
      return <QuestionnaireV2 />;
    }
    return <DynamicQuestionnaire />;
  }
  return <Questionnaire />;
};

export const transferPlannerRoutes = (
  features: IFeature[] | null
): {
  element: JSX.Element;
  children?: TRouteObject[];
  path?: string;
}[] => [
  ...[
    {
      element: <TransferPlannerPageLayout />,
      children: [
        {
          path: DASHBOARD,
          element: <Dashboard />,
        },
        {
          path: TSP_QUESTIONNAIRE,
          element: getQuestionnaireElement(features),
        },
        {
          path: TSP_ENROLLMENT,
          element: (
            <FormProvider>
              <TspEnrollment />
            </FormProvider>
          ),
        },
        {
          path: TSP_ENROLLMENT_SUCCESS,
          element: (
            <FormProvider>
              <TspEnrollment success={true} />
            </FormProvider>
          ),
        },
        ...buildFeatureRouteObject(features, {
          feature: TP_ADDITIONAL_DETAILS,
          element: <TpAdditionalDetails />,
        }),
      ],
    },
  ],
  {
    path: RESEARCH_CAMPUS_AND_PROGRAMS,
    element: <CampusesAndPrograms />,
  },
  {
    element: <GeneralEducationLayout />,
    children: [
      {
        path: GENERAL_EDUCATION,
        element: <GeneralEducation />,
      },
      {
        path: PROGRAM_PROGRESS,
        element: <ProgramProgress />,
      },
      {
        path: TRANSFER_PROGRESS,
        element: <TransferProgress />,
      },
      {
        path: `${PROGRAM_PROGRESS}/:id`,
        element: <ProgramProgress />,
      },
      ...buildFeatureRouteObject(features, {
        feature: TIMELINE_CALCULATOR,
        element: <TimelineCalculator />,
      }),
    ],
  },
];
