/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';

const selectUi = (state: TRootState): TRootState['ui'] => state.ui;

export const selectSidebar = createSelector(selectUi, (stateData: TRootState['ui']) => stateData?.sidebar);

export const selectKeycloakInitStatus = createSelector(
  selectUi,
  (stateData: TRootState['ui']) => stateData?.keycloakInitiated
);

export const selectCollegeAction = createSelector(selectUi, (stateData: TRootState['ui']) => stateData?.collegeSidebar);

export const selectSuccesSnackbar = createSelector(
  selectUi,
  (stateData: TRootState['ui']) => stateData?.succesSnackbar
);

export const selectErrorSnackbar = createSelector(selectUi, (stateData: TRootState['ui']) => stateData?.errorSnackbar);

export const selectGenEdReqSidebar = createSelector(
  selectUi,
  (stateData: TRootState['ui']) => stateData?.genEdReqSidebar
);

export const selectProfileViewSidebar = createSelector(
  selectUi,
  (stateData: TRootState['ui']) => stateData?.profileViewSidebar
);

export const selectShareProfileSidebar = createSelector(
  selectUi,
  (stateData: TRootState['ui']) => stateData?.shareProfileSidebar
);

export const selectAvatarSidebar = createSelector(selectUi, (stateData: TRootState['ui']) => stateData?.avatarSidebar);

export const selectPosterSidebar = createSelector(selectUi, (stateData: TRootState['ui']) => stateData?.posterSidebar);

export const selectApiStatus = createSelector(selectUi, (stateData: TRootState['ui']) => stateData?.apiStatus);

export const selectWelcomeModal = createSelector(selectUi, (stateData: TRootState['ui']) => stateData?.welcomeModal);

export const selectFooterModal = createSelector(selectUi, (stateData: TRootState['ui']) => stateData?.footerModal);

export const selectGradeLevelPosition = createSelector(
  selectUi,
  (stateData: TRootState['ui']) => stateData?.gradeLevelPosition
);

export const selectProgramCart = createSelector(selectUi, (stateData: TRootState['ui']) => stateData?.programCart);
