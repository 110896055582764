/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ChangeEvent, ReactElement } from 'react';
import { FormControl, InputLabel, Typography, InputBase, FormHelperText } from '@mui/material';
import { Dropdown } from '@liaison/liaison-ui';
import { Controller, type FieldValues, type UseFormReturn } from 'react-hook-form';
import { RadioControl } from 'components/RadioControl';
import type { IoptionModified } from 'utils/utilities';
import { TQuestion, TAnswer } from '../DynamicQuestionnaire.utils';

type TFormMethods = {
  methods: UseFormReturn<FieldValues>;
};
export interface IAnswerModified {
  val: string;
  text: string;
  order?: number;
  customEntry?: string;
}

export const getRadioOptions = (options: TAnswer[]): IAnswerModified[] =>
  JSON.parse(JSON.stringify(options).split('"code":').join('"val":').split('"displayName":').join('"text":'));

export const getDropDownOptions = (options: TAnswer[]): IoptionModified[] =>
  JSON.parse(JSON.stringify(options).split('"code":').join('"id":').split('"displayName":').join('"text":'));

const renderSubLabel = (subDisplayName: string | undefined) => {
  return (
    <>
      {subDisplayName && (
        <Typography
          variant="subtitle7"
          dangerouslySetInnerHTML={{
            __html: subDisplayName,
          }}
          sx={{ lineHeight: '1.8' }}
        />
      )}
    </>
  );
};
export const RadioField = ({
  code,
  required,
  displayName,
  subDisplayName,
  answers,
  methods: { control },
}: TQuestion & TFormMethods): ReactElement => {
  const radioOptions = getRadioOptions(answers);

  return (
    <FormControl fullWidth required={required}>
      <InputLabel htmlFor={code}>{displayName}</InputLabel>
      {renderSubLabel(subDisplayName)}
      <Controller
        control={control}
        name={code}
        defaultValue=""
        render={({ field: { ref, onChange, ...field } }) => (
          <RadioControl
            {...field}
            inputRef={ref}
            id={code}
            options={radioOptions}
            onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
              onChange(event, checked);
            }}
          />
        )}
      />
    </FormControl>
  );
};

export const DropdownField = ({
  code,
  required,
  displayName,
  subDisplayName,
  answers,
  methods: {
    control,
    formState: { errors },
  },
}: TQuestion & TFormMethods): ReactElement => {
  const dropdownOptions = getDropDownOptions(answers);
  return (
    <FormControl fullWidth required={required} error={!!errors?.[code]}>
      <InputLabel htmlFor={code}>{displayName}</InputLabel>
      {renderSubLabel(subDisplayName)}
      <Controller
        control={control}
        name={code}
        render={({ field: { ref, onChange, ...field } }) => (
          <Dropdown
            {...field}
            inputRef={ref}
            fullWidth
            id={code}
            options={dropdownOptions}
            onChange={option => {
              onChange(option?.id ?? '');
            }}
            inputProps={{
              'aria-describedby': `${code}-error`,
            }}
          />
        )}
      />
      <FormHelperText role="alert" id={`${code}-error`}>
        {(errors?.[code]?.message || '') as string}
      </FormHelperText>
    </FormControl>
  );
};

export const TextField = ({
  code,
  displayName,
  subDisplayName,
  required,
  isCustomEntry,
  methods: {
    register,
    formState: { errors },
  },
}: {
  code: string;
  displayName: string;
  required?: boolean;
  subDisplayName?: string;
  isCustomEntry?: boolean;
} & TFormMethods): ReactElement => {
  return (
    <FormControl fullWidth required={required} error={!!errors?.[code]}>
      {!isCustomEntry && (
        <>
          <InputLabel htmlFor={code}>{displayName}</InputLabel>
          {renderSubLabel(subDisplayName)}
        </>
      )}
      <InputBase
        placeholder={displayName}
        inputProps={{
          'aria-label': displayName,
          'aria-describedby': `${code}-error`,
          maxLength: 50,
        }}
        {...register(code)}
        sx={{ display: 'block' }}
      />
      <FormHelperText role="alert" id={`${code}-error`}>
        {(errors?.[code]?.message || '') as string}
      </FormHelperText>
    </FormControl>
  );
};
