/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { useState, Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setUi } from 'store/ui/ui.slice';
import { selectAdditionalDetails } from 'transferPlanner/store/additionalDetails/additionalDetails.selectors';
import { fetchSimilarityFlag } from 'transferPlanner/pages/CampusesAndPrograms/Programs/ProgramCardsLazyScroll/ProgramCardsLazyScroll.utils';

type TUseProgramSimilarity = {
  addProgram: () => Promise<void>;
  showDissimilarConfirmation: boolean;
  showSimilarConfirmation: boolean;
  isAddProgramModalOpen: boolean;
  setShowDissimilarConfirmation: Dispatch<SetStateAction<boolean>>;
  setShowSimilarConfirmation: Dispatch<SetStateAction<boolean>>;
  setIsAddProgramModalOpen: Dispatch<SetStateAction<boolean>>;
};

export const useProgramSimilarity = (moKey?: string): TUseProgramSimilarity => {
  const [showDissimilarConfirmation, setShowDissimilarConfirmation] = useState(false);
  const [showSimilarConfirmation, setShowSimilarConfirmation] = useState(false);
  const [isAddProgramModalOpen, setIsAddProgramModalOpen] = useState(false);

  const { additionalDetails } = useSelector(selectAdditionalDetails);

  const isAdtProgram = !!additionalDetails?.adtInformation?.length;

  const dispatch = useDispatch();

  const addProgram = async () => {
    if (!isAdtProgram) {
      setIsAddProgramModalOpen(true);
      return;
    }
    try {
      if (moKey) {
        const isSimilar = await fetchSimilarityFlag(moKey);
        setShowDissimilarConfirmation(!isSimilar);
        if (isSimilar) {
          setShowSimilarConfirmation(true);
        }
      } else {
        setShowDissimilarConfirmation(true);
      }
    } catch {
      /* istanbul ignore next */
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
    }
  };

  return {
    addProgram,
    showDissimilarConfirmation,
    showSimilarConfirmation,
    setShowDissimilarConfirmation,
    setShowSimilarConfirmation,
    setIsAddProgramModalOpen,
    isAddProgramModalOpen,
  };
};
