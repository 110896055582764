/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  tableRowClasses,
  tableCellClasses,
  Typography,
  Stack,
} from '@mui/material';
import { CollapsibleSection as Accordion, MediaViewer } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EmptyMediaIcon } from 'assets/svgs/empty-media-icon.svg';
import { tableStyles } from 'pages/Pages.styles';
import { textEllipsis, wrapText } from 'userProfile/components/ViewBuilder/ViewBuilder.styles';
import { convertMediaDocsToFilePreviews, getThumbUrl } from 'userProfile/pages/MediaDocuments/MediaDocuments.utils';
import type { IMediaDoc } from 'userProfile/store/mediaDocuments/mediaDocuments.slice';

import { INSTITUTION, COURSES, DEGREES } from '../LivingProfile.utils';

type TAttachmentsTableProps = {
  onToggleMediaDocPreview: (index: number) => void;
  attachments?: IMediaDoc[];
};

const AttachmentsTable = ({ onToggleMediaDocPreview, attachments = [] }: TAttachmentsTableProps): ReactElement => {
  return (
    <Table
      aria-label="table header"
      sx={{
        ...tableStyles.headerFont,
        [`.${tableRowClasses.root}:not(:last-child) .${tableCellClasses.root}`]: {
          borderBottom: '1px solid',
          borderColor: theme => theme.palette.grey[300],
        },
        ...tableStyles.font,
        border: '1px solid',
        borderColor: theme => theme.palette.grey[300],
        borderRadius: '0.5rem',
        borderCollapse: 'separate',
      }}
    >
      <TableBody>
        {attachments.map(attachment => {
          return (
            <TableRow
              key={attachment.id}
              onClick={() => onToggleMediaDocPreview(attachments.findIndex(a => a.id === attachment.id))}
              sx={{
                cursor: 'pointer',
              }}
            >
              <TableCell align="left">
                <Grid container columnSpacing={1} alignItems="center">
                  <Grid item xs="auto">
                    <Box
                      component="img"
                      src={getThumbUrl(attachment.variants)}
                      alt={`${attachment.name} picture`}
                      sx={{
                        width: { xs: 150, sm: 270 },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                        borderRadius: 1,
                      }}
                    />
                  </Grid>
                  <Grid item container xs>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          wordBreak: 'break-word',
                          typography: { xs: 'body3', sm: 'subtitle3' },
                          ...wrapText,
                          ...textEllipsis,
                        }}
                      >
                        {attachment.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" color="text.secondary" textTransform="uppercase">
                        {`${attachment.sizeDisplay || ''} ${`${
                          attachment.sizeDisplay && attachment.mediaType ? '•' : ''
                        }`} ${attachment.mediaType || ''}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

type TAttachment = IMediaDoc & {
  section?: {
    code: string;
    displayName: string;
  };
  hidden: boolean;
};

interface DocumentsProps {
  attachments?: TAttachment[];
  useSections: boolean;
}

export const Documents = ({ attachments, useSections }: DocumentsProps): ReactElement => {
  const sections = [INSTITUTION, COURSES, DEGREES];

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [selectedThumbnail, setSelectedThumbnail] = useState<number>(0);
  const { t } = useTranslation();

  const attachmentsToDisplay = attachments?.filter(attachment => !attachment.hidden && attachment.shareable);

  const attachmentGroups = useSections
    ? attachmentsToDisplay?.reduce((acc, attachment) => {
        const key = attachment.section?.code || 'DEFAULT_SECTION';
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(attachment);
        return acc;
      }, {} as { [key: string]: IMediaDoc[] })
    : undefined;

  const attachmentFilePreviews = useSections
    ? sections.reduce((acc, section) => {
        if (attachmentGroups?.[section]) {
          acc.push(...attachmentGroups[section]);
        }
        return acc;
      }, [] as IMediaDoc[])
    : attachmentsToDisplay || [];

  const onToggleMediaDocPreview = (index: number) => {
    setSelectedThumbnail(index);
    setIsPreviewOpen(!isPreviewOpen);
  };

  if (!attachmentsToDisplay?.length) {
    return (
      <Stack justifyContent="center" alignItems="center" spacing={3} py={2}>
        <EmptyMediaIcon />
        <Typography variant="body2" color="textSecondary">
          {t('livingProfile.noData')}
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      {useSections ? (
        sections.map(section =>
          attachmentGroups?.[section] ? (
            <React.Fragment key={section}>
              <Grid item xs={12} sx={{ my: '.5rem' }}>
                <Accordion expanded groupId={section} title={section}>
                  <AttachmentsTable
                    onToggleMediaDocPreview={onToggleMediaDocPreview}
                    attachments={attachmentGroups?.[section]}
                  />
                </Accordion>
              </Grid>
            </React.Fragment>
          ) : null
        )
      ) : (
        <Grid item xs={12} sx={{ my: '.5rem' }}>
          <AttachmentsTable onToggleMediaDocPreview={onToggleMediaDocPreview} attachments={attachmentsToDisplay} />
        </Grid>
      )}
      <Dialog open={isPreviewOpen} fullWidth maxWidth="xl">
        <DialogContent
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
          }}
        >
          <MediaViewer
            files={convertMediaDocsToFilePreviews(attachmentFilePreviews)}
            open={isPreviewOpen}
            sx={{
              flex: 1,
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
            }}
            currentPage={selectedThumbnail}
            onClose={onToggleMediaDocPreview as () => void}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
