/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { InputBase, FormControl, Grid, Box, FormHelperText } from '@mui/material';
import { Dropdown } from '@liaison/liaison-ui';
import { useFormContext, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getDropDownOptions } from 'utils/utilities';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { getLookUps } from 'utils/commonUtils';
import { getFilteredAddressOptions } from 'userProfile/pages/PersonalInformation/Personalnformation.utils';
import { selectCountriesLookup, selectStatesLookup, selectCountiesLookup } from 'store/common/commonInfo.selectors';
import { resetlookupData } from 'store/common/commonInfo.slice';
import type { IAddress, IContactInfo } from 'userProfile/store/personalInfo/personalInfo.slice';
import {
  DISABLE_ADDRESS_AUTO_FILL,
  MAX_BIG_LENGTH_FIELD,
  MAX_MEDIUM_LENGTH_FIELD,
  MAX_SMALL_LENGTH_FIELD,
} from 'constants/field';
import MasterData from 'userProfile/constants/master';
import { useTranslation } from 'react-i18next';
import { StyledDeleteButton } from 'pages/Pages.styles';
import type { IExperience } from 'userProfile/store/accomplishmentAndExperience/accomplishmentAndExperience.slice';

type GenericObject = { [key: string]: unknown };

type TAddressErrors = {
  contact: {
    addresses: {
      type: { code: { message: string } };
      address1: { message: string };
      address2: { message: string };
      country: { code: { message: string } };
      region: { code: { message: string } };
      city: { message: string };
      postalCode: { message: string };
    }[];
  };
  experiences: {
    addresses: {
      type: { code: { message: string } };
      address1: { message: string };
      address2: { message: string };
      country: { code: { message: string } };
      region: { code: { message: string } };
      city: { message: string };
      postalCode: { message: string };
    }[];
  };
};

export interface IAddressProps {
  data: GenericObject | null;
  openChildDrawer?: (isAddNew: boolean, childPosition: number) => void;
  isOpenChildDrawer?: boolean;
  childPosition?: number;
  deleteAddress?: ((childPosition: number) => void) | null;
  groupName?: keyof TAddressErrors;
  locator?: string;
  requiredFields?: string[];
}
const emptyOptions = { code: '', displayName: '' };

const Address = (props: IAddressProps): ReactElement => {
  const {
    data,
    childPosition = 0,
    deleteAddress,
    groupName = 'contact',
    locator = `${groupName}.addresses.${childPosition}`,
    requiredFields = ['addressType', 'address1', 'city', 'region', 'country', 'postalCode'],
  } = props;
  const {
    register,
    control,
    reset,
    setValue,
    getFieldState,
    formState: { errors: formErrors, dirtyFields },
    trigger,
    watch,
    getValues,
  } = useFormContext();

  const errors = formErrors as unknown as TAddressErrors;
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const watchCountry = watch(`${locator}.country.code`);
  const watchState = watch(`${locator}.region.code`);

  const countriesLookup = useSelector(selectCountriesLookup);
  const statesLookup = useSelector(selectStatesLookup);
  const countiesLookup = useSelector(selectCountiesLookup);

  const abortStateController = useRef<AbortController>();
  const getStateList = useCallback(
    (countryId: string | undefined) => {
      abortStateController?.current?.abort();
      abortStateController.current = new AbortController();
      const controllerSignal = { signal: abortStateController.current.signal };
      dispatch(getLookUps('states', `?countryCode=${countryId}`, '', controllerSignal));
    },
    [dispatch, abortStateController]
  );

  const abortCountyController = useRef<AbortController>();
  const getCountyList = useCallback(
    (stateId: string | undefined) => {
      abortCountyController?.current?.abort();
      abortCountyController.current = new AbortController();
      const controllerSignal = { signal: abortCountyController.current.signal };
      dispatch(getLookUps('counties', `?stateCode=${stateId}`, '', controllerSignal));
    },
    [dispatch, abortCountyController]
  );

  const addressOptions = useMemo(() => getDropDownOptions(MasterData.addressTypes), []);
  const countryOptions = useMemo(() => getDropDownOptions(countriesLookup || []), [countriesLookup]);
  const governingDistrictOptions = useMemo(() => getDropDownOptions(statesLookup || []), [statesLookup]);
  const countyOptions = useMemo(() => getDropDownOptions(countiesLookup || []), [countiesLookup]);

  const getAddressFromData = useCallback((): IAddress => {
    if (locator.startsWith('contact')) {
      const contactInfo = data?.contact as IContactInfo;
      if (contactInfo?.addresses && contactInfo?.addresses[childPosition]) {
        return contactInfo?.addresses[childPosition];
      }
    } else if (locator.startsWith('experiences')) {
      const experiencesData = data?.experiences as IExperience[];

      if (
        experiencesData &&
        experiencesData[childPosition] &&
        experiencesData?.[childPosition].organization &&
        experiencesData?.[childPosition]?.organization?.address
      ) {
        return experiencesData[childPosition].organization.address;
      }
    }
    return {};
  }, [data, childPosition, locator]);

  useEffect(() => {
    if (data) {
      reset(data);
      const address = getAddressFromData();
      if (Object.keys(address).length > 0) {
        const countryCode = address?.country?.code;
        const stateCode = address?.region?.code;

        if (countryCode) {
          getStateList(countryCode);
        }
        if (stateCode) {
          getCountyList(stateCode);
        }
      }
    }
    return () => reset({});
  }, [reset, data, getStateList, getCountyList, childPosition, groupName, locator, getAddressFromData]);

  useEffect(() => {
    if (getFieldState(`${locator}.country`).isDirty || getFieldState(`${locator}.country`).isTouched) {
      if (getValues(`${locator}.region.code`)) {
        setValue(`${locator}.region`, emptyOptions, { shouldValidate: true });
      } else {
        setValue(`${locator}.region`, emptyOptions);
      }
      setValue(`${locator}.county`, emptyOptions);

      if (watchCountry) {
        dispatch(resetlookupData(['states', 'counties']));
        getStateList(watchCountry);
      } else {
        dispatch(resetlookupData(['states', 'counties']));
      }
    }
  }, [
    watchCountry,
    getStateList,
    setValue,
    childPosition,
    dispatch,
    dirtyFields,
    getValues,
    getFieldState,
    groupName,
    locator,
  ]);

  useEffect(() => {
    if (getFieldState(`${locator}.region`).isDirty || getFieldState(`${locator}.region`).isTouched) {
      setValue(`${locator}.county`, emptyOptions);
      if (watchState) {
        dispatch(resetlookupData(['counties']));
        getCountyList(watchState);
      } else {
        dispatch(resetlookupData(['counties']));
      }
    }
  }, [watchState, getCountyList, setValue, childPosition, dispatch, getFieldState, groupName, locator]);

  return (
    <Grid sx={{ mt: 1 }}>
      {locator.startsWith('contact') && (
        <FormControl fullWidth required error={!!errors?.[groupName]?.addresses?.[childPosition].type?.code}>
          <Controller
            render={({ field: { onChange, ...field } }) => (
              <Dropdown
                {...field}
                id="addressType"
                options={getFilteredAddressOptions(
                  childPosition,
                  (data?.contact as IContactInfo)?.addresses,
                  addressOptions
                )}
                onChange={option => {
                  setValue(`${groupName}.addresses.${childPosition}.type.displayName`, option?.text);
                  return onChange(option?.id ?? '');
                }}
                placeholder={requiredFields.includes('addressType') ? 'Type*' : 'Type'}
                inputProps={{
                  'data-testid': 'addressType',
                  'aria-label': t('address.addressType'),
                  'aria-describedby': 'addressType-error',
                }}
              />
            )}
            control={control}
            name={`${locator}.type.code`}
          />
          <FormHelperText role="alert" id="addressType-error">
            {errors?.[groupName]?.addresses?.[childPosition].type?.code?.message}
          </FormHelperText>
        </FormControl>
      )}
      <InputBase inputProps={{ type: 'hidden' }} {...register(`${locator}.type.displayName`)} />
      <InputBase inputProps={{ type: 'hidden' }} {...register(`${locator}.id`)} />
      <FormControl fullWidth error={!!errors?.[groupName]?.addresses?.[childPosition].address1}>
        <InputBase
          placeholder={requiredFields.includes('address1') ? `${t('address.address1')}*` : t('address.address1')}
          inputProps={{
            'aria-label': t('address.address1'),
            'aria-describedby': 'address1-error',
            'data-testid': 'address1',
            ...DISABLE_ADDRESS_AUTO_FILL,
            maxLength: MAX_BIG_LENGTH_FIELD,
          }}
          {...register(`${locator}.address1`)}
        />
        <FormHelperText role="alert" id="address1-error">
          {errors?.[groupName]?.addresses?.[childPosition].address1?.message}
        </FormHelperText>
      </FormControl>
      <FormControl fullWidth error={!!errors?.[groupName]?.addresses?.[childPosition].address2}>
        <InputBase
          placeholder={t('address.address2')}
          inputProps={{
            'aria-label': t('address.address2'),
            'aria-describedby': 'address2-error',
            'data-testid': 'address2',
            ...DISABLE_ADDRESS_AUTO_FILL,
            maxLength: MAX_BIG_LENGTH_FIELD,
          }}
          {...register(`${locator}.address2`)}
        />
        <FormHelperText role="alert" id="address2-error">
          {errors?.[groupName]?.addresses?.[childPosition].address2?.message}
        </FormHelperText>
      </FormControl>
      <Grid container>
        <FormControl fullWidth error={!!errors?.[groupName]?.addresses?.[childPosition]?.country?.code}>
          <Controller
            render={({ field: { onChange, ...field } }) => (
              <Dropdown
                id="country"
                {...field}
                options={countryOptions}
                onChange={option => {
                  if (getValues(`${locator}.postalCode`) !== '') {
                    trigger(`${locator}.postalCode`);
                  }
                  setValue(`${locator}.country.displayName`, option?.text);
                  return onChange(option?.id ?? '');
                }}
                placeholder={requiredFields.includes('country') ? `${t('address.country')}*` : t('address.country')}
                inputProps={{
                  'aria-label': t('address.country'),
                  'aria-describedby': 'country-error',
                  ...DISABLE_ADDRESS_AUTO_FILL,
                }}
              />
            )}
            control={control}
            name={`${locator}.country.code`}
          />
          <FormHelperText role="alert" id="country-error">
            {errors?.[groupName]?.addresses?.[childPosition]?.country?.code?.message}
          </FormHelperText>
        </FormControl>
        <InputBase inputProps={{ type: 'hidden' }} {...register(`${locator}.country.displayName`)} />
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6} md={6}>
          <FormControl fullWidth error={!!errors?.[groupName]?.addresses?.[childPosition].city}>
            <InputBase
              placeholder={requiredFields.includes('city') ? `${t('address.city')}*` : t('address.city')}
              inputProps={{
                'aria-label': t('address.city'),
                'aria-describedby': 'city-error',
                ...DISABLE_ADDRESS_AUTO_FILL,
                maxLength: MAX_MEDIUM_LENGTH_FIELD,
              }}
              {...register(`${locator}.city`)}
            />
            <FormHelperText role="alert" id="city-error">
              {errors?.[groupName]?.addresses?.[childPosition].city?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={6}>
          <FormControl fullWidth error={!!errors?.[groupName]?.addresses?.[childPosition]?.region?.code}>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  id="region"
                  {...field}
                  options={governingDistrictOptions}
                  onChange={option => {
                    setValue(`${locator}.region.displayName`, option?.text);
                    return onChange(option?.id ?? '');
                  }}
                  placeholder={requiredFields.includes('region') ? `${t('address.region')}*` : t('address.region')}
                  inputProps={{
                    'aria-label': t('address.region'),
                    'aria-describedby': 'region-error',
                    ...DISABLE_ADDRESS_AUTO_FILL,
                  }}
                />
              )}
              control={control}
              name={`${locator}.region.code`}
            />
            <FormHelperText role="alert" id="region-error">
              {errors?.[groupName]?.addresses?.[childPosition]?.region?.code?.message}
            </FormHelperText>
          </FormControl>
          <InputBase inputProps={{ type: 'hidden' }} {...register(`${locator}.region.displayName`)} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6} md={6}>
          <FormControl fullWidth>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  id="countyValueId"
                  {...field}
                  options={countyOptions}
                  onChange={option => {
                    setValue(`${locator}.county.displayName`, option?.text);
                    return onChange(option?.id ?? null);
                  }}
                  placeholder={t('address.county')}
                  inputProps={{
                    'aria-label': t('address.county'),
                    ...DISABLE_ADDRESS_AUTO_FILL,
                  }}
                />
              )}
              control={control}
              name={`${locator}.county.code`}
            />
          </FormControl>
          <InputBase inputProps={{ type: 'hidden' }} {...register(`${locator}.county.displayName`)} />
        </Grid>
        <Grid item xs={6} md={6}>
          <FormControl fullWidth error={!!errors?.[groupName]?.addresses?.[childPosition].postalCode}>
            <InputBase
              placeholder={
                requiredFields.includes('postalCode') ? `${t('address.postalCode')}*` : t('address.postalCode')
              }
              autoComplete="off"
              inputProps={{
                'aria-label': t('address.postalCode'),
                'aria-describedby': 'postalCode-error',
                maxLength: MAX_SMALL_LENGTH_FIELD,
                ...DISABLE_ADDRESS_AUTO_FILL,
              }}
              {...register(`${locator}.postalCode`)}
            />
            <FormHelperText role="alert" id="postalCode-error">
              {errors?.[groupName]?.addresses?.[childPosition].postalCode?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      {deleteAddress && (
        <Box sx={{ mt: '1rem', justifyContent: 'center', display: 'flex' }}>
          <StyledDeleteButton onClick={() => setShowAlert(true)}>{t('address.removeAddress')}</StyledDeleteButton>
        </Box>
      )}
      <ConfirmationDialog
        open={showAlert}
        text={t('address.deleteTitle')}
        confirmationText={t('address.deleteContent')}
        onClose={() => {
          setShowAlert(false);
        }}
        footerButtonConfig={{
          primary: {
            title: t('remove_label'),
            props: {
              onClick: () => {
                setShowAlert(false);
                deleteAddress?.(childPosition || 0);
              },
            },
          },
          tertiary: {
            title: t('cancel_label'),
          },
        }}
      />
    </Grid>
  );
};

export default Address;
