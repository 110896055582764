/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export const sxProgramImgBox = {
  height: '30vh',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '8px',
};

export const sxCampusImgBox = {
  height: '10vh',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '8px',
  mb: '1rem',
};

export const sxContactsIcon = {
  color: '#CC0B2A',
  height: '20px',
  mr: '10px',
};

export const sxContactsTypography = {
  display: 'flex',
  alignItems: 'center',
  lineHeight: '1.5rem',
  mb: '0.5rem',
};

export const sxTransferRequirement = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: '0.5rem',
};
