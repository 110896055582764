/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useRef, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, useMediaQuery, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

import { ReactComponent as CarouselLeftButton } from 'assets/svgs/carousel-left-button.svg';
import { ReactComponent as CarouselRightButton } from 'assets/svgs/carousel-right-button.svg';
import tips1 from 'assets/transfer_tips_1.jpg';
import tips2 from 'assets/transfer_tips_2.jpg';
import tips3 from 'assets/transfer_tips_3.jpg';
import tips4 from 'assets/transfer_tips_4.jpg';
import tips5 from 'assets/transfer_tips_5.jpg';
import tips6 from 'assets/transfer_tips_6.jpg';
import tips7 from 'assets/transfer_tips_7.jpg';
import { useTimedIndex } from 'hooks/useTimedIndex';
import { useSwipe } from 'hooks/useSwipe';
import { nameSpace } from 'transferPlanner/constants/general';
import { StepperDots } from 'components/StepperDots';
import { sxDashboardItem } from '../../Dashboard.styles';

const tipsDotLabel = [
  'tip 1 English and math courses',
  'tip 2 30 units a year',
  'tip 3 requirements vary',
  'tip 4 entering courses',
  'tip 5 transfer centers',
  'tip 6 check local colleges',
  'tip 7 study abroad',
];

const tips = [
  { image: tips1, content: 'dashboard.tipsContent1', alt: tipsDotLabel[0] },
  { image: tips2, content: 'dashboard.tipsContent2', alt: tipsDotLabel[1] },
  { image: tips3, content: 'dashboard.tipsContent3', alt: tipsDotLabel[2] },
  { image: tips4, content: 'dashboard.tipsContent4', alt: tipsDotLabel[3] },
  { image: tips5, content: 'dashboard.tipsContent5', alt: tipsDotLabel[4] },
  { image: tips6, content: 'dashboard.tipsContent6', alt: tipsDotLabel[5] },
  { image: tips7, content: 'dashboard.tipsContent7', alt: tipsDotLabel[6] },
];

const tipsLastIndex = tips.length - 1;
const interval = 15000;

/* istanbul ignore next */
const swipeDebounced = debounce((resetIndex, value) => {
  resetIndex(value);
}, 500);

const CarouselButtonStyle = { backgroundColor: 'white', borderRadius: '2rem' };

export const TipsCard = (): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const [contentIndex, setContentIndex] = useState(0);
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const [currentIndex, resetIndex] = useTimedIndex({ maxIndex: tipsLastIndex, interval });
  const swipeContainerRef = useRef<HTMLDivElement>(null);
  /* istanbul ignore next */
  const onSwipeLeft = () => swipeDebounced(resetIndex, currentIndex === 0 ? tipsLastIndex : currentIndex - 1);
  /* istanbul ignore next */
  const onSwipeRight = () => swipeDebounced(resetIndex, currentIndex < tipsLastIndex ? currentIndex + 1 : 0);

  useSwipe({ ref: swipeContainerRef, onSwipeLeft, onSwipeRight });

  return (
    <Card
      sx={{
        ...sxDashboardItem,
        ...(!isSmall ? { border: '1px solid #CAD2E1', borderRadius: 2, boxShadow: 'none' } : {}),
      }}
      ref={swipeContainerRef}
    >
      {!isSmall && (
        <>
          <Box
            sx={{
              mb: -13,
              height: 175,
            }}
          >
            <img
              style={{
                opacity: contentIndex === currentIndex ? '1' : '0.8',
                transition: 'opacity 0.2s ease-in-out',
                objectFit: 'cover',
              }}
              width="100%"
              height="100%"
              src={tips[currentIndex].image}
              alt={tips[currentIndex].alt}
              onLoad={() => setContentIndex(currentIndex)}
            />
          </Box>
          <Box sx={{ px: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Button
              size="small"
              sx={{ '&:hover': { opacity: 0.75 } }}
              aria-label="carousel back button"
              onClick={onSwipeLeft}
            >
              <CarouselLeftButton style={CarouselButtonStyle} />
            </Button>
            <Button
              size="small"
              sx={{ '&:hover': { opacity: 0.75 } }}
              aria-label="carousel next button"
              onClick={onSwipeRight}
            >
              <CarouselRightButton style={CarouselButtonStyle} />
            </Button>
          </Box>
        </>
      )}
      <CardContent sx={{ mt: { md: 0, lg: 11.5 }, height: { md: 'auto', lg: 165 } }}>
        <Typography variant="h2">{t('dashboard.tipsHeader')}</Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {t(tips[isSmall ? currentIndex : contentIndex].content)}
        </Typography>
      </CardContent>
      <CardActions>
        <StepperDots
          steps={tips.length}
          activeStep={isSmall ? currentIndex : contentIndex}
          onStepChange={resetIndex}
          tipsDotLabel={tipsDotLabel}
        />
      </CardActions>
    </Card>
  );
};
