/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo } from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { Icon } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';

import { nameSpace } from 'transferPlanner/constants/general';

type TAgreementUrlButtonProps = {
  agreementUrl: string;
};

const AgreementUrlButton = ({ agreementUrl }: TAgreementUrlButtonProps): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const handleViewPdf = () => {
    window.open(agreementUrl, '_blank');
  };

  return (
    <Grid
      item
      container
      xs={12}
      sm={8}
      md={6}
      sx={{
        bgcolor: 'secondary.main',
        borderRadius: 2,
        color: 'secondary.contrastText',
        border: '1px solid #003865',
        p: 2,
        mt: 3,
        cursor: 'pointer',
      }}
      onClick={handleViewPdf}
      role="button"
      aria-label="view pdf"
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item sm={1.5}>
          <Box sx={{ maxWidth: '100%' }}>
            <Icon>IconFeedSolid</Icon>
          </Box>
        </Grid>
        <Grid item xs={9} sm={9.5}>
          <Typography variant="subtitle4" component="div" sx={{ color: '#fff' }}>
            {t('enrollmentCompletion.majorPrerequesties')}
          </Typography>
          <Typography variant="caption" sx={{ color: '#fff' }}>
            ({t('enrollmentCompletion.helpertext')})
          </Typography>
        </Grid>
        <Grid item xs={1} sm={0.8}>
          <Icon sx={{ p: 0, color: '#fff' }} aria-label="view major PDF">
            IconCaretRight
          </Icon>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(AgreementUrlButton);
