/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { IconEdit } from '@liaison/liaison-ui';
import { StyledIconButton } from 'pages/Pages.styles';

export interface IPageTitle {
  title: string;
  description?: string;
  handleClick?: () => void;
}

const PageTitle = (props: IPageTitle): ReactElement => {
  const { title, description, handleClick } = props;

  return (
    <>
      <Box sx={{ display: 'flex', marginBottom: '10px' }}>
        <Typography
          sx={{ fontWeight: 'bold', margin: 'auto', flexGrow: 1, fontSize: [18, 24, 24] }}
          component="h1"
          variant="h3"
        >
          {title}
        </Typography>
        {handleClick && (
          <StyledIconButton onClick={handleClick} aria-label={`Edit ${title}`} data-testid="avatar-box">
            <IconEdit style={{ fontSize: 16 }} />
          </StyledIconButton>
        )}
      </Box>
      {description && (
        <Typography sx={{ mb: 2 }} component="h2" variant="body2">
          {description}
        </Typography>
      )}
      <Divider data-testid="divider-line" />
    </>
  );
};

export default memo(PageTitle);
