/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';
import { ICalculatorState } from './timelineCalculation.slice';

const timelineCalculator = (state: TRootState): ICalculatorState => state.timelineCalculation;

export const selectCalculator = createSelector(
  timelineCalculator,
  (stateData: ICalculatorState) => stateData?.calculator
);

export const selectCalculatorLoading = createSelector(
  timelineCalculator,
  (stateData: ICalculatorState) => stateData?.loading
);
