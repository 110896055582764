/*
 * Copyright 2022-2024 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';

import type { TRootState } from 'redux/store';
import type { IMediaDocsState, TSectionName } from './mediaDocuments.slice';

const mediaDocuments = (state: TRootState): IMediaDocsState => state.mediaDocuments;

export const selectLoading = createSelector(mediaDocuments, (stateData: IMediaDocsState) => stateData?.loading);

export const selectMediaDocs = createSelector(
  mediaDocuments,
  (stateData: IMediaDocsState) => stateData?.mediaDocsData?.documents
);

export const selectMediaDocsSortOrder = createSelector(
  mediaDocuments,
  (stateData: IMediaDocsState) => stateData?.mediaDocsData?.sortOrder
);

export const selectSectionMediaDocs = createSelector(
  [selectMediaDocs, (state, sectionId) => sectionId],
  (mediaDocs, sectionId) => mediaDocs?.filter(doc => doc.attachedTo?.some(attach => attach.id === sectionId))
);

export const selectSelectedMediaDoc = createSelector(
  mediaDocuments,
  (stateData: IMediaDocsState) => stateData?.selectedMediaDoc
);

export const selectPendingDocIds = createSelector(
  [mediaDocuments, (state, sectionName: TSectionName) => sectionName],
  (stateData: IMediaDocsState, sectionName) => stateData?.pendingAttachments?.[sectionName] || null
);

export const selectPendingMediaDocs = createSelector([selectMediaDocs, selectPendingDocIds], (mediaDocs, pendingIds) =>
  pendingIds ? mediaDocs?.filter(doc => pendingIds?.includes(doc.id as string)) : null
);
