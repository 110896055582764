/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';
import { IProgramsState } from './programs.slice';

const programs = (state: TRootState): IProgramsState => state.programs;

export const selectPrograms = createSelector(programs, (stateData: IProgramsState) => stateData?.programs);

export const selectProgramLoading = createSelector(programs, (stateData: IProgramsState) => stateData?.loading);

export const selectAgreement = createSelector(programs, (stateData: IProgramsState) => stateData?.programAgreement);

export const selectTspEligibleFlags = createSelector(
  programs,
  (stateData: IProgramsState) => stateData?.tspEligibleFlags
);
