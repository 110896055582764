/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo } from 'react';
import { Grid, IconButton, AppBar, Toolbar, useMediaQuery, Theme, Box } from '@mui/material';
import { Icon } from '@liaison/liaison-ui';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'transferPlanner/constants/general';
import { selectTenantConfig } from 'store/common/commonInfo.selectors';
import { isCsuTenant } from 'utils/utilities';
import { useFixedItemInView } from 'hooks/useFixedItemInView';
import { StyledToolBar } from 'pages/Pages.styles';

interface ISidebarProps {
  onMenuClick?: () => void;
}

function Header({ onMenuClick }: ISidebarProps): ReactElement {
  const { t } = useTranslation(nameSpace);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const tenantConfig = useSelector(selectTenantConfig);
  const { logo, logoMobile, logoNoBG } = tenantConfig || {};
  const { isFixed } = useFixedItemInView();
  const handleMenuClick = () => {
    onMenuClick?.();
  };

  return (
    <>
      {isMobile ? (
        <AppBar position={isFixed ? 'fixed' : 'static'}>
          <StyledToolBar>
            <Box
              component="div"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton
                size="large"
                edge="start"
                aria-label="open drawer"
                onClick={handleMenuClick}
                sx={{ color: theme => (isCsuTenant() ? theme.palette.common.black : theme.palette.common.white) }}
              >
                <Icon>IconMenu</Icon>
              </IconButton>
              <Box
                component="img"
                sx={{
                  height: 36,
                }}
                src={logoMobile}
                aria-hidden="true"
                alt="logo"
              />
            </Box>
          </StyledToolBar>
        </AppBar>
      ) : (
        <AppBar
          position="static"
          sx={{
            bgcolor: theme => theme.palette.primary.main,
          }}
        >
          <Toolbar
            variant="dense"
            sx={{
              height: '60px',
              ml: '3rem',
            }}
          >
            <Grid container component="div" direction="row" justifyContent="space-between">
              <Grid item display="flex" alignItems="center" component="a" tabIndex={0}>
                <Box
                  component="img"
                  sx={{
                    height: isCsuTenant() ? 30 : 42,
                  }}
                  src={isCsuTenant() ? logoNoBG : logo}
                  alt="logo"
                  aria-label={isCsuTenant() ? t('logo_label') : logo?.split('/').pop()}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
}

export default memo(Header);
