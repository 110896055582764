/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, Stack } from '@mui/material';
import { IconLinkedinCircle, SidePanel } from '@liaison/liaison-ui';
import { useForm, FormProvider, SubmitHandler, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { ReactComponent as GoogleIconColored } from 'assets/svgs/google.svg';
import { sxSidePanel, StyledButton } from 'pages/Pages.styles';
import { doLogout } from 'utils/keyCloakUtils';
import { setTitle } from 'utils/commonUtils';
import { loginPageUrl } from 'constants/keyCloak';
import { Spinner } from 'components/Spinner';
import {
  selectPersonalInformation,
  selectLoading as selectPisLoading,
} from 'userProfile/store/personalInfo/personalInfo.selectors';
import {
  getPersonalInformation,
  updateAccountEmail,
  postPersonalInformation,
  type TUpdateEmail,
} from 'userProfile/pages/PersonalInformation/Personalnformation.utils';
import { selectLoggedUserData, selectLoading } from 'userProfile/store/auth/auth.selectors';
import { getButtonColor } from 'utils/utilities';

import { getLoggedUserDetails, updateEmail } from '../ManageAccount.util';
import UpdateEmailForm from './UpdateEmailForm';
import { validationSchema } from './UpdateEmailForm.validation';

const GoogleIcon = <GoogleIconColored style={{ width: '20px', height: '20px' }} />;
const LinkedinIcon = <IconLinkedinCircle style={{ fontSize: 20, color: '#027fb5' }} />;

const iconMap: Record<string, JSX.Element> = {
  google: GoogleIcon,
  'linkedin-openid-connect': LinkedinIcon,
};

const MyAccount = (): ReactElement => {
  const loggedUserData = useSelector(selectLoggedUserData);
  const socialLinks = loggedUserData?.socialLinks;
  const dispatch = useDispatch();
  const [isOpenSidePanel, setIsOpenSidePanel] = useState(false);
  const isLoading = useSelector(selectLoading);
  const isPisLoading = useSelector(selectPisLoading);
  const personalInformationData = useSelector(selectPersonalInformation);
  const { t } = useTranslation();

  useEffect(() => {
    if (!loggedUserData) {
      dispatch(getLoggedUserDetails());
    }
  }, [dispatch, loggedUserData]);

  useEffect(() => {
    if (!personalInformationData) {
      dispatch(getPersonalInformation());
    }
  }, [dispatch, personalInformationData]);

  useEffect(() => {
    setTitle(t('account.pageTitle'));
  }, [t]);

  const methods = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(validationSchema, { abortEarly: false })(...args),
  });

  const closeSidepanel = () => {
    setIsOpenSidePanel(false);
    setTitle(t('account.pageTitle'));
  };

  const onSubmit = (data: TUpdateEmail) => {
    const modifiedData = {
      ...data,
      verified: false,
      ...(socialLinks?.length ? { unlinkSocialLogin: true } : {}),
    };
    delete modifiedData.confirmEmail;
    dispatch(
      updateEmail(modifiedData, () => {
        const updatedPayload = updateAccountEmail(personalInformationData, modifiedData.email);
        dispatch(
          postPersonalInformation(
            updatedPayload,
            () => {
              closeSidepanel();
              doLogout(loginPageUrl);
            },
            () => {
              const revertData = {
                ...modifiedData,
                email: loggedUserData?.email || '',
                verified: true,
              };
              dispatch(updateEmail(revertData));
            }
          )
        );
      })
    );
  };

  return (
    <Box>
      {(isLoading || isPisLoading) && <Spinner data-testid="spinner" backdrop />}
      <Typography sx={{ fontWeight: 'bold', fontSize: [18, 24, 24] }} component="h1" variant="h3">
        {t('account.myAccount')}
      </Typography>
      <Typography variant="subtitle7">{t('account.instruction')}</Typography>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Typography variant="subtitle4" role="heading" component="h2">
          {t('account.accountInformation')}
        </Typography>
      </Box>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={1}>
          <Box sx={{ flex: 0.32, opacity: '0.8' }}>
            <Typography variant="button">{t('contactInfo.emails')}</Typography>
          </Box>
          {socialLinks?.map(socialLink => (
            <React.Fragment key={socialLink?.identityProvider}>
              {iconMap[socialLink?.identityProvider?.toLowerCase()]}
            </React.Fragment>
          ))}
          <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }}>
            {loggedUserData?.email}
          </Typography>
        </Stack>
        {loggedUserData?.email && (
          <StyledButton
            sx={{ height: 'auto' }}
            onClick={() => {
              setIsOpenSidePanel(true);
              setTitle(t('account.changeEmail'));
            }}
          >
            {t('account.changeEmail')}
          </StyledButton>
        )}
      </Stack>
      <FormProvider {...methods}>
        <form>
          <SidePanel
            open={isOpenSidePanel}
            onClose={closeSidepanel}
            title={t('account.changeEmail')}
            isBackdropClickEnabled={true}
            footerButtonConfig={{
              primary: {
                title: t('updateBtn_label'),
                props: {
                  'aria-label': t('updateBtn_label'),
                  variant: 'contained',
                  disabled: !methods.formState.isValid,
                  onClick: methods.handleSubmit(onSubmit as SubmitHandler<FieldValues>),
                  color: getButtonColor(),
                },
              },
              tertiary: {
                title: t('cancel_label'),
                props: {
                  'aria-label': t('cancel_label'),
                  color: getButtonColor(),
                  onClick: closeSidepanel,
                },
              },
            }}
            sx={sxSidePanel}
          >
            <UpdateEmailForm />
          </SidePanel>
        </form>
      </FormProvider>
    </Box>
  );
};

export default memo(MyAccount);
