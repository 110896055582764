/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AvatarGroup, Avatar, Badge, Box, Typography } from '@mui/material';
import { Icon } from '@liaison/liaison-ui';
import { getInitialFromName } from 'utils/utilities';
import circleNameBg from 'assets/svgs/circle-name-bg.svg';
import { setUi } from 'store/ui/ui.slice';
import { selectMediaDocs } from 'userProfile/store/mediaDocuments/mediaDocuments.selectors';
import { getPersonalInformation } from 'userProfile/pages/PersonalInformation/Personalnformation.utils';
import { selectPersonalInformation } from 'userProfile/store/personalInfo/personalInfo.selectors';
import type { IMediaDoc, TVariant } from 'userProfile/store/mediaDocuments/mediaDocuments.slice';
import { getMediaDocs, getThumbUrl } from 'userProfile/pages/MediaDocuments/MediaDocuments.utils';
import { useTranslation } from 'react-i18next';
import { AvatarSidePanel } from '../AvatarSidePanel';

export type TUserAvatarProps = {
  firstName?: string;
  lastName?: string;
  hideName?: boolean;
  editable?: boolean;
  width?: string;
  height?: string;
  initialsFontSize?: string;
  dontGetData?: boolean;
  ephemeralURL?: TVariant['ephemeralURL'];
};

type TAvatarImageProps = {
  width: string;
  height: string;
  initials: string;
  initialsFontSize: string;
  avatar?: IMediaDoc;
  ephemeralURL?: TVariant['ephemeralURL'];
};

const AvatarImage = ({ width, height, initials, initialsFontSize, avatar, ephemeralURL }: TAvatarImageProps) => {
  const { t } = useTranslation();
  const imageSource = ephemeralURL || avatar;
  const image = ephemeralURL || (avatar ? getThumbUrl(avatar.variants) || avatar.ephemeralURL : circleNameBg);

  return (
    <Avatar
      sx={{
        ...(imageSource ? {} : { backgroundImage: `url(${circleNameBg})` }),
        backgroundColor: 'black',
        height,
        width,
        fontSize: initialsFontSize,
      }}
      {...(imageSource ? { src: image } : {})}
      alt={t('mediaDocuments.profilePicture.label')}
    >
      {!imageSource && initials}
    </Avatar>
  );
};

const UserAvatar = ({
  firstName = '',
  lastName = '',
  width = '8.75rem',
  height = '8.75rem',
  initialsFontSize = '2.75rem',
  hideName = false,
  editable = false,
  dontGetData = false,
  ephemeralURL,
}: TUserAvatarProps): ReactElement => {
  const dispatch = useDispatch();
  const mediaDocs = useSelector(selectMediaDocs);
  const personalInformation = useSelector(selectPersonalInformation);

  useEffect(() => {
    if (!dontGetData && !personalInformation) {
      dispatch(getPersonalInformation());
    }
  }, [dispatch, dontGetData, personalInformation]);

  useEffect(() => {
    if (!dontGetData && !mediaDocs) {
      dispatch(getMediaDocs());
    }
  }, [dispatch, dontGetData, mediaDocs]);

  const avatar = mediaDocs?.find(m => m.id === personalInformation?.avatarFileId);
  const openSidePanel = useCallback(() => {
    return () => dispatch(setUi({ name: 'avatarSidebar', state: { open: true } }));
  }, [dispatch]);

  return (
    <>
      <Box>
        <AvatarGroup sx={{ justifyContent: 'center' }}>
          {editable ? (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              sx={{ pl: 1 }}
              badgeContent={
                <Avatar
                  sx={theme => ({
                    height: 32,
                    width: 32,
                    border: `2px solid ${theme.palette.background.paper}`,
                    backgroundColor: theme.palette.secondary.background,
                    cursor: 'pointer',
                  })}
                >
                  <Icon sx={{ color: '#615911' }} fontSize="small">
                    IconCameraSolid
                  </Icon>
                </Avatar>
              }
              onClick={openSidePanel()}
            >
              <AvatarImage
                width={width}
                height={height}
                avatar={avatar}
                initials={getInitialFromName(firstName, lastName)}
                initialsFontSize={initialsFontSize}
                ephemeralURL={ephemeralURL}
              />
            </Badge>
          ) : (
            <AvatarImage
              width={width}
              height={height}
              avatar={avatar}
              initials={getInitialFromName(firstName, lastName)}
              initialsFontSize={initialsFontSize}
              ephemeralURL={ephemeralURL}
            />
          )}
        </AvatarGroup>
        {!hideName && (
          <Typography
            variant="h2"
            component="div"
            sx={{ textAlign: 'center', marginTop: '0.5rem', marginBottom: '.1rem', overflowWrap: 'anywhere' }}
          >
            {`${firstName}${lastName ? ` ${lastName}` : ''}`}
          </Typography>
        )}
      </Box>
      {editable && <AvatarSidePanel />}
    </>
  );
};

export default memo(UserAvatar);
