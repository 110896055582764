/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Card, Typography, Grid, Box, IconButton } from '@mui/material';
import { IconCaretRight, IconFiles } from '@liaison/liaison-ui';

import { StyledButton, sxCard } from 'pages/Pages.styles';
import type { IHonorsAndAward } from 'userProfile/store/accomplishmentAndExperience/accomplishmentAndExperience.slice';
import { selectSectionMediaDocs } from 'userProfile/store/mediaDocuments/mediaDocuments.selectors';
import { sxButtonProfileView } from 'userProfile/components/AttachedFiles/AttachedFiles.styles';

import { textEllipsis, wrapText } from '../ViewBuilder.styles';

interface IHonorsAndAwardsCardProps {
  honorOrAward: IHonorsAndAward;
  isOpenFromForm?: number;
  position: number;
  openChildDrawer?: (isAddNew: boolean, childPosition: number, parentSelector: string, formTitle: string) => void;
  selector: string;
  formTitle: string;
}

export const HonorsAndAwardsCard: React.FC<IHonorsAndAwardsCardProps> = ({
  honorOrAward: { id, name },
  position,
  openChildDrawer,
  selector,
  isOpenFromForm,
  formTitle,
}: IHonorsAndAwardsCardProps): ReactElement => {
  const sectionMediaDocs = useSelector(state => selectSectionMediaDocs(state, id));
  return (
    <Grid item xs={12} md={6} key={`${name}_${id}`}>
      <Card variant="outlined" sx={sxCard}>
        <Box
          sx={{
            p: '1rem',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          onClick={() => openChildDrawer?.(false, position, selector, formTitle)}
        >
          <Box sx={{ flex: 0.9 }}>
            <Typography variant="subtitle3" sx={{ ...textEllipsis, ...wrapText }}>{`${name || ''}`}</Typography>
          </Box>
          {!!sectionMediaDocs?.length && (
            <StyledButton
              variant="contained"
              startIcon={<IconFiles />}
              size="medium"
              fullWidth
              aria-label="attachments"
              sx={{ ...sxButtonProfileView, width: '50px', marginLeft: '10px' }}
              onClick={() => openChildDrawer?.(false, position, selector, formTitle)}
            >
              {sectionMediaDocs?.length > 99 ? '99+' : sectionMediaDocs?.length}
            </StyledButton>
          )}
          <Box sx={{ flex: 0.1 }}>
            <IconButton id={`${name}-${isOpenFromForm}-${position}`} aria-label={name} role="button">
              <IconCaretRight />
            </IconButton>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};
