/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import axios from 'axios';
import { API } from 'constants/api';
import { getPossibleTermsList } from 'hooks/useTransferTimelineService';
import { TAppThunk } from 'redux/store';
import {
  timelineInfoStart,
  timelineInfoSuccess,
  timelineInfoFailure,
} from 'transferPlanner/store/timelineInfo/timelineInfo.slice';
import Pluralize from 'pluralize';
import { t } from 'i18next';
import { capitalizeText, csuNameSpace } from 'utils/utilities';
import { getLoggedUser } from 'utils/keyCloakUtils';

interface IEnrollment {
  active: boolean;
  expectedStartingTerm: {
    academicYear: number;
    termType: {
      code: string;
      displayName: string;
    };
  };
}

interface IAdditionalDetails {
  intendedTransferTerm: {
    code: string;
    displayName: string;
  };
  plannedEnrollmentStatus: {
    code: string;
    displayName: string;
  };
  intendedTransferYear: string;
}

export interface ITimeLineInfo {
  profileId: string;
  additionalDetails: IAdditionalDetails;
  transferGPA: {
    unitsEarned: number;
    unitsCompleted: number;
  };
  generalEducation: {
    a2b4Complete: boolean;
  };
  enrollment: IEnrollment;
}

export const getTransferCalcBase = (): TAppThunk => async dispatch => {
  try {
    dispatch(timelineInfoStart());
    const loggedUserId = getLoggedUser();
    const { data } = await axios.get(`${API.timelineinfo}${loggedUserId}`);
    dispatch(timelineInfoSuccess(data));
  } catch (err) {
    dispatch(timelineInfoFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  }
};

export const findTspAgreementDeadLine = (calculatorBase: ITimeLineInfo | null): string => {
  const enrollment = calculatorBase?.enrollment;
  if (enrollment?.active) {
    return capitalizeText(
      `${enrollment?.expectedStartingTerm?.termType?.displayName} ${enrollment?.expectedStartingTerm?.academicYear + 3}`
    );
  }
  return t('notApplicable.label', csuNameSpace);
};

const convertTermStringToNumber = (stringVal: string): number => {
  const inputString = stringVal?.toLowerCase();
  const convertedTerm = Number(
    !inputString || inputString === t('notApplicable.label', csuNameSpace).toLowerCase()
      ? 0
      : inputString.replace('spring ', '1').replace('fall ', '1')
  );
  return inputString?.includes('fall') ? convertedTerm + 0.5 : convertedTerm;
};

type TTermStatus = { msg?: string; successflag?: boolean; isReadyToTransfer?: boolean };
export const findTransferStatus = (
  readyToTransfer: string,
  intendedTransfer: string,
  tspAgreementDeadLine?: string
): TTermStatus => {
  const readyToTransferDate = convertTermStringToNumber(readyToTransfer);
  const intendedTransferDate = convertTermStringToNumber(intendedTransfer);
  const tspAgreementDeadLineDate = convertTermStringToNumber(tspAgreementDeadLine || t('notApplicable.label'));
  let termStatus = {};
  if (
    (tspAgreementDeadLineDate && tspAgreementDeadLineDate < readyToTransferDate) ||
    (tspAgreementDeadLineDate &&
      tspAgreementDeadLineDate < readyToTransferDate &&
      intendedTransferDate < readyToTransferDate &&
      intendedTransferDate < tspAgreementDeadLineDate)
  ) {
    termStatus = { msg: t('transferCalculator.termStatusDeadLineMsg', csuNameSpace), successflag: false };
  } else if (readyToTransferDate === intendedTransferDate) {
    termStatus = { msg: t('transferCalculator.metPlannedTransferDate', csuNameSpace), successflag: true };
  } else if (readyToTransferDate < intendedTransferDate) {
    termStatus = { msg: t('transferCalculator.aheadTransferDate', csuNameSpace), successflag: true };
  } else {
    termStatus = { msg: t('transferCalculator.afterIntendedTransferDate', csuNameSpace), successflag: false };
  }

  if (
    (tspAgreementDeadLineDate && readyToTransferDate > tspAgreementDeadLineDate) ||
    readyToTransferDate > intendedTransferDate
  ) {
    termStatus = { ...termStatus, isReadyToTransfer: false };
  } else if (
    (tspAgreementDeadLineDate && readyToTransferDate <= tspAgreementDeadLineDate) ||
    readyToTransferDate <= intendedTransferDate
  ) {
    termStatus = { ...termStatus, isReadyToTransfer: true };
  }

  return termStatus;
};

export const formatTransferDateValue = (value: number): string => {
  const termList = getPossibleTermsList();
  return termList[value];
};

export const formatUnitValue = (value: number): string => {
  return Pluralize(t('unit_label', csuNameSpace), value, true);
};

export const findIntendedTransferKeyByValue = (intendedTransferTerm: string): number => {
  const termList = getPossibleTermsList();
  return termList.findIndex((_currentValue, index): boolean => {
    return termList[index] === intendedTransferTerm;
  });
};
