/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  dialogClasses,
  Box,
  Divider,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconCancel } from '@liaison/liaison-ui';

interface IDialog {
  open: boolean;
  countdown: number;
  onLogout: () => void;
  onContinue: () => void;
}
const SessionTimeoutDialog = ({ open, countdown, onLogout, onContinue }: IDialog): ReactElement => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      role="document"
      scroll="body"
      aria-labelledby="dialog-modal-title"
      aria-describedby="dialog-modal-description"
      maxWidth="sm"
      fullWidth
      sx={{ [`.${dialogClasses.paper}`]: { borderRadius: '1rem' } }}
    >
      <Box
        sx={{
          display: 'flex',
          py: '1rem',
          px: ['1rem', '1.5rem', '2.5rem'],
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography id="dialog-modal-title" variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
          {countdown ? t('session.expiry_title') : t('session.expired_title')}
        </Typography>
        <IconButton aria-label="close" size="small" onClick={onLogout} sx={{ height: 'fit-content' }}>
          <IconCancel />
        </IconButton>
      </Box>
      <Divider sx={{ borderStyle: 'solid' }} />
      <DialogContent sx={{ py: '2rem', px: ['1rem', '1.5rem', '2.5rem'] }}>
        <Typography variant="body2" id="dialog-modal-description">
          {countdown ? t('session.countdown_message', { count: countdown }) : t('session.timeout_message')}
        </Typography>
        <Typography variant="subtitle3" component="p" sx={{ pt: '1rem', fontWeight: 'bold' }}>
          {countdown ? t('session.continueSession_message') : t('session.timeoutInfo_message')}
        </Typography>
      </DialogContent>
      <Divider sx={{ borderStyle: 'solid' }} />
      <DialogActions
        sx={{
          justifyContent: countdown ? 'space-between' : 'flex-end',
          py: '1rem',
          px: ['1rem', '1.5rem', '2.5rem'],
        }}
      >
        {countdown ? (
          <>
            <Button onClick={onLogout} color="error">
              {t('session.backToAdmission_btn')}
            </Button>
            <Button onClick={onContinue} variant="contained" color="secondary">
              {t('session.continueSession_btn')}
            </Button>
          </>
        ) : (
          <Button onClick={onLogout} color="error" variant="contained">
            {t('session.backToAdmission_btn')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SessionTimeoutDialog;
