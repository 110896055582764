/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */
import React, { ReactElement, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import { DocumentThumbnail, MediaViewer } from '@liaison/liaison-ui';

import { selectMediaAndDocuments } from 'userProfile/store/profileView/profileView.selectors';
import { convertMediaDocsToFilePreviews, getThumbUrl } from 'userProfile/pages/MediaDocuments/MediaDocuments.utils';

import { sxSectionsItem } from '../LivingProfile.styles';

export const MediaAndDocuments = (): ReactElement => {
  const mediaDocs = useSelector(selectMediaAndDocuments);
  const mediaDocsToDisplay = useMemo(() => mediaDocs?.filter(item => !item.hidden), [mediaDocs]);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [selectedThumbnail, setSelectedThumbnail] = useState(0);
  const { t } = useTranslation();

  const onToggleMediaDocPreview = (index: number) => {
    setSelectedThumbnail(index);
    setIsPreviewOpen(!isPreviewOpen);
  };

  return (
    <Box sx={{ ...sxSectionsItem, p: 3 }}>
      <Stack spacing={3}>
        <Typography variant="h2">{t('livingProfile.sections.mediaAndDocuments')}</Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(270px, 1fr))',
            gap: '30px',
          }}
        >
          {mediaDocsToDisplay?.map((mediaDoc, index) => (
            <Box key={mediaDoc.id} onClick={() => onToggleMediaDocPreview(index)}>
              <DocumentThumbnail imageUrl={getThumbUrl(mediaDoc.variants)} />
            </Box>
          ))}
        </Box>
      </Stack>
      <Dialog open={isPreviewOpen} fullWidth maxWidth="xl" aria-labelledby="dialog-modal-title">
        <DialogContent
          id="dialog-modal-title"
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
          }}
        >
          <MediaViewer
            files={convertMediaDocsToFilePreviews(mediaDocsToDisplay)}
            open={isPreviewOpen}
            sx={{
              flex: 1,
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
            }}
            currentPage={selectedThumbnail}
            onClose={onToggleMediaDocPreview as () => void}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
