/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect } from 'react';
import { Grid, Typography, useMediaQuery, Theme, Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Search } from '@liaison/liaison-ui';
import debounce from 'lodash.debounce';

import { useAppSelector } from 'redux/hooks';
import { nameSpace } from 'transferPlanner/constants/general';
import { setSearchQuery } from 'transferPlanner/store/searchProgram/searchProgram.slice';
import { selectTspEnrollmentPeriod } from 'transferPlanner/store/enrollment/enrollment.selectors';
import useTooltip from 'transferPlanner/hooks/useTooltip';

export const searchDebounced = debounce((value, dispatch) => {
  dispatch(setSearchQuery(value));
}, 1000);

type TProgramSearchHeaderProps = {
  total?: number;
};

export const ProgramSearchHeader = ({ total = 0 }: TProgramSearchHeaderProps): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const isTabletOrDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.only('sm'));
  const isTspEnrollmentOpen = useAppSelector(selectTspEnrollmentPeriod)?.enrollmentOpen || false;
  const dispatch = useDispatch();
  const { RenderTooltip } = useTooltip(
    <Trans t={t}>
      <Typography gutterBottom variant="body2">
        <Trans t={t}>
          {isTspEnrollmentOpen ? 'programs.page.instruction_open' : 'programs.page.instruction_close1'}
        </Trans>
      </Typography>
      {!isTspEnrollmentOpen && <Typography variant="body2">{t('programs.page.instruction_close2')}</Typography>}
    </Trans>,
    t('programs.instructions')
  );

  useEffect(() => {
    return () => {
      dispatch(setSearchQuery(''));
    };
  }, [dispatch]);

  const searchHandler = (value: string) => {
    searchDebounced(value, dispatch);
  };

  return (
    <Grid container justifyContent="space-between" direction="column">
      <Grid container item justifyContent="space-between">
        <Grid item container={!isTabletOrDesktop} justifyContent="space-between">
          <Stack direction="row">
            <Typography variant="h1" sx={{ fontSize: { sm: '1.2rem', md: '1.8rem' } }}>
              {t('programs.page.title')} ({total})
            </Typography>
            {isTablet && RenderTooltip()}
          </Stack>
        </Grid>
        <Grid
          item
          container={!isTabletOrDesktop}
          justifyContent="space-between"
          sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
        >
          <Search
            expanded
            searchOffset={1}
            searchHandler={searchHandler}
            inputProps={{ 'aria-label': 'search programs; Search result updated' }}
            sx={{
              mr: '.5rem',
              flex: ['1 0', 'initial'],
              width: [null, '300px'],
            }}
          />
        </Grid>
      </Grid>
      <Grid item sx={{ display: { xs: 'block', sm: 'none', md: 'block', lg: 'block', xl: 'block' }, mt: '1rem' }}>
        <Typography gutterBottom variant="body2">
          <Trans t={t}>
            {isTspEnrollmentOpen ? 'programs.page.instruction_open' : 'programs.page.instruction_close1'}
          </Trans>
        </Typography>
        {!isTspEnrollmentOpen && <Typography variant="body2">{t('programs.page.instruction_close2')}</Typography>}
      </Grid>
    </Grid>
  );
};
