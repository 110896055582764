/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import lp from 'userProfile/locales/en/lp.en.json';
import tp from 'transferPlanner/locales/en/tp.en.json';

const resources = {
  en: { lp, tp },
};

export const availableLanguages = Object.keys(resources);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    defaultNS: 'lp',
    fallbackLng: 'en',
    ns: ['lp', 'tp'],
    react: {
      useSuspense: true,
    },
  });
