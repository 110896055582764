/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */
import React, { KeyboardEvent, memo, ReactElement, useState } from 'react';
import { Grid, Box, Typography, Divider, useMediaQuery, Theme } from '@mui/material';
import { TFunction, useTranslation } from 'react-i18next';
import { nameSpace } from 'transferPlanner/constants/general';
import {
  Csu6Units,
  Csu12Units,
  Csu18Units,
  Csu39Units,
  CsuA2Completed,
  CsuB4Completed,
  CsuGolden4,
  CsuEmpty,
} from 'transferPlanner/assets/svgs/genEd';
import { Icon } from '@liaison/liaison-ui';
import { useSelector } from 'react-redux';
import { selectMilestonesData } from 'transferPlanner/store/genEdProcess/genEdProcess.selectors';
import { classes, CustomButton } from '../GenEd.styles';
import { faqList, IAboutProps } from '../GeneralEducation.utils';
import MileStoneModal from './MileStoneModal';

export const getMilestoneIcon = (
  code: string | undefined,
  milestoneName: string,
  milestoneOpacity = '1',
  toggleModal?: (code: string) => void,
  t?: TFunction<'tp', undefined>
): ReactElement => {
  const milestoneIcons = {
    '6_UNITS_COMPLETE': Csu6Units,
    A2_COMPLETE: CsuA2Completed,
    '12_UNITS_COMPLETE': Csu12Units,
    B4_COMPLETE: CsuB4Completed,
    '18_UNITS_COMPLETE': Csu18Units,
    '39_UNITS_COMPLETE': Csu39Units,
    GOLDEN_4_COMPLETE: CsuGolden4,
  };

  const IconComponent = milestoneIcons[code as keyof typeof milestoneIcons] || CsuEmpty;

  const handleOnClick = () => {
    if (milestoneOpacity === '1' && code && toggleModal) toggleModal(code);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (milestoneOpacity === '1' && code && event.code === 'Enter' && toggleModal) {
      toggleModal(code);
    }
  };

  return (
    <IconComponent
      aria-label={
        t
          ? `${t?.('generalEducation.badgeIconFirstPart')} ${milestoneName} ${t?.(
              'generalEducation.badgeIconSecondPart'
            )}`
          : milestoneName
      }
      color="inherit"
      style={{
        opacity: milestoneOpacity,
        padding: 0,
        width: '100%',
        cursor: toggleModal && milestoneOpacity === '1' ? 'pointer' : 'inherit',
      }}
      tabIndex={toggleModal && milestoneOpacity === '1' ? 0 : -1}
      onClick={handleOnClick}
      onKeyUp={handleKeyPress}
    />
  );
};

const About = ({ onViewDetailsClicked }: IAboutProps): ReactElement => {
  const [openStates, setOpenStates] = useState<{ [key: string]: boolean }>({});
  const { t } = useTranslation(nameSpace);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const milestoneData = useSelector(selectMilestonesData) || [];

  /* istanbul ignore next */
  const toggleModal = (code: string) => {
    setOpenStates(prevStates => ({
      ...prevStates,
      [code]: !prevStates[code],
    }));
  };

  return (
    <>
      <Box>
        <Box sx={{ pb: '1rem' }}>
          <Box sx={{ ml: isMobile ? 2 : 1 }}>
            <Typography component="h1" variant="subtitle1">
              {t('generalEducation.milestones')}
            </Typography>
          </Box>
          <Grid container alignItems="center">
            {milestoneData?.map(milestone => {
              const milestoneOpacity = milestone.status === 'INCOMPLETE' ? '0.25' : '1';
              const words = milestone.name.displayName.split(' ');
              const status = words.pop();
              const displayName = words.join(' ');

              return (
                <Grid item xs={3} md={3} key={milestone.name.displayName}>
                  {milestone.status === 'COMPLETE' && (
                    <MileStoneModal
                      code={milestone.name.code}
                      openStates={openStates[milestone.name.code] || false}
                      toggleModal={toggleModal}
                      milestoneOpacity={milestoneOpacity}
                    />
                  )}
                  <Grid item>
                    {getMilestoneIcon(
                      milestone.name.code,
                      milestone.name.displayName,
                      milestoneOpacity,
                      toggleModal,
                      t
                    )}
                  </Grid>
                  <Grid item>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant={isMobile ? 'caption' : 'subtitle8'}>{displayName}</Typography>
                      <Typography variant={isMobile ? 'caption' : 'subtitle8'}>{status}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Divider sx={{ my: 1, border: '1px solid #8E9AAB' }} />
        </Box>
        <Box sx={{ pb: '2rem' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ml: 1 }}>
            <Typography component="h1" variant="subtitle1">
              {t('generalEducation.faq')}
            </Typography>
          </Box>
          <Box sx={{ ml: 1 }}>
            {faqList.map(item => (
              <CustomButton
                key={item.screenName}
                className={`${classes.button}`}
                onClick={() => {
                  onViewDetailsClicked(item.pageTitle, item?.screenName, false);
                }}
              >
                <Typography
                  align="left"
                  variant="subtitle4"
                  sx={{
                    margin: '5px 0px 5px 10px',
                    width: '100%',
                  }}
                >
                  {t(item?.label)}
                </Typography>
                <Icon sx={{ color: '#8E9AAB' }}>IconCaretRight</Icon>
              </CustomButton>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default memo(About);
