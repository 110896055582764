/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Tooltip, ListItem, List, TooltipProps, tooltipClasses, listItemClasses, Stack, Theme } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { getNameSpace } from 'utils/utilities';

export const sxTypography = {
  display: 'flex',
  flex: 0.95,
  opacity: '0.8',
  alignItems: 'center',
} as const;

export const sxIconHover = {
  color: (theme: Theme) => theme.palette.common.black,
  '&:hover': {
    color: '#1565C0',
    backgroundColor: 'transparent',
  },
} as const;

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip placement="bottom-start" arrow classes={{ popper: className }} {...props} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#EBEFF6',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 477,
    backgroundColor: (theme: Theme) => theme.palette.common.black,
    color: (theme: Theme) => theme.palette.common.white,
    borderRadius: '2px',
  },
});

export const TextAndPhoneTooltipInfo = (): ReactElement => {
  const { t } = useTranslation();
  const nameSpace = getNameSpace();
  return (
    <Stack justifyContent="space-between">
      {t('privacy.textAndPhoneTooltipHeader', nameSpace)}
      <List
        sx={{
          listStyleType: 'disc',
          pl: 2,
          py: 0,
          [`& .${listItemClasses.root}`]: {
            display: 'list-item',
            pl: 0,
          },
        }}
      >
        <ListItem>{t('privacy.textAndPhoneTooltip1', nameSpace)}</ListItem>
        <ListItem>{t('privacy.textAndPhoneTooltip2', nameSpace)}</ListItem>
        <ListItem>{t('privacy.textAndPhoneTooltip3', nameSpace)}</ListItem>
        <ListItem>{t('privacy.textAndPhoneTooltip4', nameSpace)}</ListItem>
        <ListItem>{t('privacy.textAndPhoneTooltip5', nameSpace)}</ListItem>
        <ListItem>{t('privacy.textAndPhoneTooltip6', nameSpace)}</ListItem>
      </List>
    </Stack>
  );
};
