/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */
import React, { ReactElement } from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { csuNameSpace } from 'utils/utilities';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { selectUserName } from 'userProfile/store/personalInfo/personalInfo.selectors';
import { selectTspEnrollmentPeriod } from 'transferPlanner/store/enrollment/enrollment.selectors';
import { selectWelcomeModal } from 'store/ui/ui.selectors';
import { setUi } from 'store/ui/ui.slice';
import { RESEARCH_CAMPUS_AND_PROGRAMS, TSP_QUESTIONNAIRE } from 'transferPlanner/constants/routeNames';
import { InfoBox } from '../InfoBox';

const WelcomeModal = (): ReactElement => {
  const { t } = useTranslation(csuNameSpace.ns);
  const isTspEnrollmentOpen = useAppSelector(selectTspEnrollmentPeriod)?.enrollmentOpen;
  const { open } = useAppSelector(selectWelcomeModal);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userFirstName } = useAppSelector(selectUserName);

  const goToNextStep = (nextLocation: string) => {
    dispatch(setUi({ name: 'welcomeModal', state: { open: false } }));
    navigate(`/${nextLocation}`);
  };

  const openCollegeForm = () => {
    dispatch(setUi({ name: 'welcomeModal', state: { open: false } }));
    dispatch(setUi({ name: 'collegeSidebar', state: { open: true } }));
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle
        sx={{
          bgcolor: theme => theme.palette.custom.drawer,
          color: theme => theme.palette.secondary.contrastText,
          textAlign: 'center',
          padding: '30px',
          fontSize: '24px',
        }}
      >
        {t('welcome.modal.header')}
      </DialogTitle>
      <DialogContent sx={{ bgcolor: theme => theme.palette.grey[50] }}>
        <Typography variant="body1" sx={{ fontWeight: '900', fontSize: '24px', margin: '1rem', textAlign: 'center' }}>
          {t('welcome_label')}, {userFirstName}!
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          {t(isTspEnrollmentOpen ? 'welcome.modal.content1' : 'welcome.modal.content2')}
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: '900', fontSize: '20px', margin: '1rem 0.5rem' }}>
          {t('whats_next_label')}
        </Typography>
        <Grid container justifyContent="space-between" direction={{ xs: 'column', sm: 'row' }}>
          {isTspEnrollmentOpen && (
            <InfoBox
              isWelcomeModal={true}
              infoText={t('welcome.modal.questionnaire.text')}
              buttonText1={t('welcome.modal.questionnaire.button')}
              onClick1={() => goToNextStep(TSP_QUESTIONNAIRE)}
            />
          )}
          <InfoBox
            isWelcomeModal={true}
            infoText={t('welcome.modal.add_college.text')}
            buttonText1={t('welcome.modal.add_college.button')}
            onClick1={openCollegeForm}
          />
          <InfoBox
            isWelcomeModal={true}
            infoText={t('welcome.modal.explore_program.text')}
            buttonText1={t('welcome.modal.explore_program.button')}
            onClick1={() => goToNextStep(RESEARCH_CAMPUS_AND_PROGRAMS)}
          />
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ bgcolor: theme => theme.palette.grey[50], p: '1rem', justifyContent: 'center' }}>
        <Button
          onClick={() => {
            dispatch(setUi({ name: 'welcomeModal', state: { open: false } }));
          }}
          variant="text"
          style={{
            color: '#C20008',
          }}
        >
          {t('welcome.modal.continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WelcomeModal;
