/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */
import { ProfileMenus } from 'userProfile/components/UserNav/ProfileMenus';
import { PERSONAL_INFORMATION } from 'userProfile/constants/routeNames';

const accountMenus = [
  {
    icon: 'IconUserProfile',
    location: 'my-account',
  },
  {
    icon: 'IconShield',
    location: 'privacy-security',
  },
];

export const selectActiveTab = (path: string): string => {
  if (ProfileMenus.some(menu => menu.location === path)) {
    return PERSONAL_INFORMATION.path;
  }
  if (accountMenus.some(menu => menu.location === path)) {
    return 'userinfo';
  }
  return path;
};
