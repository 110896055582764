/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Components } from '@mui/material';

export default (): Components => ({
  MuiFormControl: {
    styleOverrides: {
      root: {
        marginTop: 0,
      },
      marginNormal: {
        marginBottom: '0.63rem',
      },
    },
    defaultProps: {
      margin: 'normal',
    },
  },
});
