/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TError = string | null;

export interface IPersonalInfo {
  givenName?: string;
  middleName?: string;
  familyName?: string;
  hasFormerName?: string;
  alternateGivenName?: string;
  alternateMiddleName?: string;
  alternateFamilyName?: string;
  hasPreferredName?: string;
  preferredName?: string;
  preferredMiddleName?: string;
  salutation?: string;
  suffix?: string;
  birthInformation?: {
    birthDate: string;
  };
}
export interface IEmail {
  email: string;
  type?: {
    code: string;
    displayName: string;
  };
  preferred?: boolean;
}
export interface IPhone {
  phone: string;
  type?: {
    code: string;
    displayName: string;
  };
  preferred?: boolean;
}
export interface IAddress {
  type?: {
    code: string;
    displayName: string;
  };
  address1?: string;
  address2?: string;
  city?: string;
  country?: {
    code: string;
    displayName: string;
  };
  region?: {
    code: string;
    displayName: string;
  };
  postalCode?: string;
  county?: {
    code: string;
    displayName: string;
  };
}
export interface IContactInfo {
  emails?: IEmail[];
  phones?: IPhone[];
  addresses?: IAddress[];
}
export interface ISocialMedia {
  url?: string;
  type?: {
    code: string;
    displayName: string;
  };
}
export interface TPersonalInfoField {
  personal?: IPersonalInfo | null;
  contact?: IContactInfo | null;
  socialMedia?: ISocialMedia[] | null;
  profileId?: string;
  avatarFileId?: string;
}

export interface IPersonalInfoState {
  loading: boolean;
  personalInfoData: TPersonalInfoField | null;
  personalInfoLocalData: TPersonalInfoField | null;
  error: TError;
}

export const initialState: IPersonalInfoState = {
  loading: false,
  personalInfoData: null,
  personalInfoLocalData: null,
  error: null,
};

const personalInfoSlice = createSlice({
  name: 'personalInfo',
  initialState,
  reducers: {
    personalInfoStart: (state): IPersonalInfoState => ({
      ...state,
      loading: true,
      error: null,
    }),
    personalInfoSuccess: (state, action: PayloadAction<TPersonalInfoField>): IPersonalInfoState => ({
      ...state,
      loading: false,
      personalInfoData: action.payload,
      personalInfoLocalData: null,
    }),
    personalInfoSaveLocalData: (state, action: PayloadAction<TPersonalInfoField>): IPersonalInfoState => ({
      ...state,
      loading: false,
      personalInfoLocalData: action.payload,
    }),
    personalInfoResetLocalData: (state): IPersonalInfoState => ({
      ...state,
      loading: false,
      personalInfoLocalData: null,
    }),
    personalInfoAddressSuccess: (state, action: PayloadAction<TPersonalInfoField>): IPersonalInfoState => ({
      ...state,
      loading: false,
      personalInfoData: action.payload,
    }),
    personalInfoFailure: (state, action: PayloadAction<TError>): IPersonalInfoState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  personalInfoStart,
  personalInfoSuccess,
  personalInfoFailure,
  personalInfoSaveLocalData,
  personalInfoResetLocalData,
  personalInfoAddressSuccess,
} = personalInfoSlice.actions;

export default personalInfoSlice.reducer;
