/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Backdrop, CircularProgress, CircularProgressProps, Box } from '@mui/material';
import { VisuallyHiddenTypography } from 'pages/Pages.styles';
import { useTranslation } from 'react-i18next';

interface ISpinnerProps extends CircularProgressProps {
  customColor?: string;
  backdrop?: boolean;
}

const SimpleSpinner = ({
  size = 80,
  thickness = 2,
  customColor = 'secondary.main',
  ...props
}: ISpinnerProps): ReactElement => {
  return (
    <CircularProgress
      aria-label="Loading"
      data-testid="spinner"
      sx={{
        color: customColor,
      }}
      size={size}
      thickness={thickness}
      {...props}
    />
  );
};

export const Spinner = ({ backdrop = false, ...props }: ISpinnerProps): ReactElement => {
  const { t } = useTranslation();

  return backdrop ? (
    <Box component="div" role="alert" aria-live="assertive">
      <Backdrop sx={{ zIndex: theme => theme.zIndex.drawer + 1 }} open>
        <SimpleSpinner {...props} />
      </Backdrop>
      <VisuallyHiddenTypography className="visually-hidden" aria-hidden="false">
        {t('loading_label')}
      </VisuallyHiddenTypography>
    </Box>
  ) : (
    <Box component="div" role="alert" aria-live="assertive">
      <SimpleSpinner {...props} />
      <VisuallyHiddenTypography className="visually-hidden" aria-hidden="false">
        {t('loading_label')}
      </VisuallyHiddenTypography>
    </Box>
  );
};
