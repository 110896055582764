/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICampusContact, IPrograms } from 'transferPlanner/pages/CampusesAndPrograms/CampusesAndPrograms.utils';

type TError = string | null;

export interface IProgramResponse {
  id: number;
  alternateId: number;
  name: string;
  contact: ICampusContact;
  programs: IPrograms[];
}

export interface IProgramAgreement {
  communityCollegeAlternateId: number;
  communityCollegeName: string;
  archivedArtifactUrl: string;
}

export interface IProgramsState {
  loading: boolean;
  programs: IPrograms[] | null;
  programAgreement: IProgramAgreement | null;
  tspEligibleFlags: IPrograms[] | null;
  error: TError;
}

export const initialState: IProgramsState = {
  loading: false,
  programs: null,
  programAgreement: null,
  tspEligibleFlags: null,
  error: null,
};

const programSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    programsStart: (state): IProgramsState => ({
      ...state,
      loading: true,
      error: null,
    }),
    programsSuccess: (state, action: PayloadAction<IPrograms[]>): IProgramsState => ({
      ...state,
      loading: false,
      programs: action.payload,
    }),
    programsFailure: (state, action: PayloadAction<TError>): IProgramsState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    fetchAgreementStart: (state): IProgramsState => ({
      ...state,
      loading: true,
      error: null,
    }),
    fetchAgreementSuccess: (state, action: PayloadAction<IProgramAgreement>): IProgramsState => ({
      ...state,
      loading: false,
      programAgreement: action.payload,
    }),
    fetchAgreementFailure: (state, action: PayloadAction<TError>): IProgramsState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    tspEligibleFlagsStart: (state): IProgramsState => ({
      ...state,
      loading: true,
      error: null,
    }),
    tspEligibleFlagsSuccess: (state, action: PayloadAction<IPrograms[]>): IProgramsState => ({
      ...state,
      loading: false,
      tspEligibleFlags: action.payload,
    }),
    tspEligibleFlagsFailure: (state, action: PayloadAction<TError>): IProgramsState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    resetProgram: (state): IProgramsState => ({
      ...state,
      loading: false,
      programs: null,
      error: null,
    }),
  },
});

export const {
  programsStart,
  programsFailure,
  programsSuccess,
  resetProgram,
  fetchAgreementFailure,
  fetchAgreementStart,
  fetchAgreementSuccess,
  tspEligibleFlagsStart,
  tspEligibleFlagsSuccess,
  tspEligibleFlagsFailure,
} = programSlice.actions;

export default programSlice.reducer;
