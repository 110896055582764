/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { typographyClasses, Theme } from '@mui/material';

export const sxListItemText = {
  my: 0,
  display: 'list-item',
  [`> .${typographyClasses.root}`]: {
    fontSize: (theme: Theme) => theme.typography.body3.fontSize,
  },
} as const;

export const sxBox = {
  mt: '.5rem',
  textAlign: 'left',
  '& svg': {
    height: '.8rem',
  },
  color: 'text.primary',
  lineHeight: '.8rem',
} as const;
