/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export const PERSONAL_INFORMATION = { featureName: 'PERSONAL_INFO', path: 'personal-information' };
export const BIOGRAPHICAL_INFORMATION = { featureName: 'BIOGRAPHICAL_INFO', path: 'biographical-information' };
export const ACADEMIC_GOALS = { featureName: 'ACADEMIC_GOALS', path: 'academic-goals' };
export const CAREER_GOALS = { featureName: 'CAREER_GOALS', path: 'career-goals' };
export const ACCOMPLISHMENT_EXPERIENCE = {
  featureName: 'ACHIEVEMENTS',
  path: 'accomplishment-experience',
};
export const ACADEMIC_HISTORY = { featureName: 'ACADEMIC_HISTORY', path: 'academic-history' };
export const MEDIA_DOCUMENTS = { featureName: 'MEDIA_DOCUMENTS', path: 'media-documents' };
export const LIVING_PROFILE = { featureName: 'LIVING_PROFILE', path: 'profile-view' };
