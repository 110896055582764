/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'transferPlanner/constants/general';
import { selectUserName } from 'userProfile/store/personalInfo/personalInfo.selectors';
import { selectIsFirstLogin } from 'store/common/commonInfo.selectors';

export const WelcomeBack = (): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const isFirstLogin = useSelector(selectIsFirstLogin);
  const { userFirstName } = useSelector(selectUserName);

  return (
    <Box>
      <Typography variant="body1" display="inline" sx={{ fontWeight: 400, fontSize: 24 }}>
        {t(isFirstLogin ? 'welcome_label' : 'welcome_back_label')},
      </Typography>
      <Typography
        variant="body1"
        display="inline"
        sx={{ fontWeight: 600, fontSize: 24, color: '#CC0B2A', wordWrap: 'break-word' }}
      >
        {` ${userFirstName}`}!
      </Typography>
      {!isFirstLogin && (
        <Typography variant="body3" display="block" sx={{ mt: 1 }}>
          {t('continue_planning_label')}
        </Typography>
      )}
    </Box>
  );
};
