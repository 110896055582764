/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';
import { IGenEdProcessState } from './genEdProcess.slice';

const selectGenEdProcessState = (state: TRootState): IGenEdProcessState => state.genEd;

export const selectGenEdProcessData = createSelector(
  selectGenEdProcessState,
  (genEdProcessState: IGenEdProcessState) => genEdProcessState?.genEd
);

export const selectGenEdCoursesAndTests = createSelector(
  selectGenEdProcessState,
  (genEdProcessState: IGenEdProcessState) => genEdProcessState?.coursesAndTests
);

export const selectGenEdLoading = createSelector(
  selectGenEdProcessState,
  (stateData: IGenEdProcessState) => stateData?.loading
);

export const selectMilestonesData = createSelector(
  selectGenEdProcessState,
  (stateData: IGenEdProcessState) => stateData?.genEdMilestones
);

export const selectAchievedMilestone = createSelector(selectGenEdProcessState, (stateData: IGenEdProcessState) => {
  const completedMilestones = (stateData?.genEdMilestones || []).filter(milestone => milestone.status === 'COMPLETE');
  if (completedMilestones.length) {
    const sorted = completedMilestones.sort((a, b) => b.displayPriority - a.displayPriority);
    return sorted[0];
  }
  return undefined;
});

export const gpaCalculationSelector = createSelector(selectGenEdProcessState, (stateData: IGenEdProcessState) => {
  return stateData?.gpaCalculation;
});
