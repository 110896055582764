/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import * as yup from 'yup';
import { t } from 'i18next';
import { MAX_BIG_LENGTH_FIELD, MIN_LENGTH_FIELD } from 'constants/field';
import {
  CONTAIN_CHARACTERS_REG,
  CONTAIN_LOWER_CASE_REG,
  CONTAIN_NUMBER_REG,
  CONTAIN_UPPER_CASE_REG,
} from 'constants/regex';

export const validationSchema = yup.object({
  currentPassword: yup.string().required(t('account.error.currentPassword_required')),
  newPassword: yup
    .string()
    .trim()
    .required()
    .min(MIN_LENGTH_FIELD)
    .max(MAX_BIG_LENGTH_FIELD)
    .matches(RegExp(CONTAIN_UPPER_CASE_REG), { name: 'uppercase' })
    .matches(RegExp(CONTAIN_LOWER_CASE_REG), { name: 'lowercase' })
    .matches(RegExp(CONTAIN_NUMBER_REG), { name: 'number' })
    .matches(RegExp(CONTAIN_CHARACTERS_REG), { name: 'special' }),
  confirmNewPassword: yup
    .string()
    .required(t('account.error.confirmPassword_required'))
    .oneOf([yup.ref('newPassword'), null], t('account.error.password_match')),
});
