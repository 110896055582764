/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { styled } from '@mui/system';

export const StyledSectionBox = styled('button')(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  display: 'flex',
  alignItems: 'center',
  margin: '3px 0px',
  padding: '2px 5px',
  listStyle: 'none',
  backgroundColor: 'transparent',
  borderRadius: 0,
  border: 0,
  cursor: 'pointer',
  [`&.liaison-button`]: {
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.2)',
      borderRadius: '5px',
    },
  },
  [`&.liaison-buttonActive`]: {
    backgroundColor: 'rgba(255,255,255,0.2)',
    borderRadius: '5px',
    fontWeight: 'bold',
  },

  [`&.csu-button`]: {
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.2)',
      borderRadius: '5px',
    },
  },
  [`&.csu-buttonActive`]: {
    backgroundColor: 'rgba(204, 11, 42, 0.1)',
    border: '1px solid #CC0B2A',
    borderRadius: '5px',
    fontWeight: 'bold',
    cursor: 'auto',
  },
}));
