/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import { TOTAL_UNITS, INTENDED_TRANSFER_YEAR_LIMIT } from 'transferPlanner/constants/general';

const termTypes = ['Spring', 'Fall'];

export const getPossibleTermsList = (): string[] => {
  const today = new Date();
  const startYear = today.getFullYear();
  const currentTerm = today.getMonth() >= 5 ? termTypes[1] : termTypes[0];
  const endYear =
    today.getFullYear() +
    (currentTerm === termTypes[0] ? INTENDED_TRANSFER_YEAR_LIMIT - 1 : INTENDED_TRANSFER_YEAR_LIMIT);

  const possibleTerms: string[] = [];
  for (let year = startYear; year <= endYear; year += 1) {
    if (year === startYear && currentTerm === termTypes[1]) {
      possibleTerms.push(`${termTypes[1]} ${year}`);
    } else if (year === endYear && currentTerm === termTypes[1]) {
      possibleTerms.push(`${termTypes[0]} ${year}`);
    } else {
      termTypes.forEach(term => {
        possibleTerms.push(`${term} ${year}`);
      });
    }
  }
  return possibleTerms;
};

type TUseTransferTimelineProps = {
  unitsCompleted: number;
  unitsPerSemester: number;
  unitsPerSummer?: number;
};

type TUseTransferTimelineResponse = {
  readyToTransfer: string;
  readyToApply: string;
};

export const useTransferTimelineService = ({
  unitsCompleted,
  unitsPerSemester,
  unitsPerSummer = 0,
}: TUseTransferTimelineProps): TUseTransferTimelineResponse => {
  const remainingUnitsToCover = TOTAL_UNITS - unitsCompleted;
  const today = new Date();
  const startYear = today.getFullYear();
  const readyToApplyMonths = ['August', 'October'];
  const currentTerm = today.getMonth() >= 5 ? termTypes[1] : termTypes[0];

  const getReadyToTransferTerm = (): string => {
    const termTypesWithSummer = unitsPerSummer > 0 ? ['Spring', 'Summer', 'Fall'] : ['Spring', 'Fall'];
    let readyToTransfer = '';
    let year = startYear;
    let totalUnits = 0;
    if (currentTerm === termTypes[1]) {
      totalUnits += unitsPerSemester;
      year += 1;
      readyToTransfer = `${termTypes[0]} ${year}`;
    }
    while (totalUnits < remainingUnitsToCover) {
      for (let i = 0; i < Object.keys(termTypesWithSummer).length; i += 1) {
        const term = termTypesWithSummer[i];
        totalUnits += term === 'Summer' ? unitsPerSummer : unitsPerSemester;
        if (totalUnits >= remainingUnitsToCover) {
          readyToTransfer = term === 'Fall' ? `${termTypes[0]} ${year + 1}` : `${termTypes[1]} ${year}`;
          break;
        }
      }
      year += 1;
    }
    return readyToTransfer;
  };

  const readyToTransfer = getReadyToTransferTerm();

  let readyToApply = '';
  const readyToTransferParts = readyToTransfer.split(' ');
  if (readyToTransferParts.length > 1) {
    readyToApply =
      readyToTransferParts[0] === termTypes[0]
        ? `${readyToApplyMonths[0]} ${parseInt(readyToTransferParts[1], 10) - 1}`
        : `${readyToApplyMonths[1]} ${parseInt(readyToTransferParts[1], 10) - 1}`;
  }

  return { readyToTransfer, readyToApply };
};
