/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo } from 'react';
import { Grid, Divider, Box, Typography } from '@mui/material';
import { dateFields, displayDate } from 'utils/utilities';
import { useTranslation } from 'react-i18next';
import { getFieldValue, IFieldDetails, hideDependantFields } from './ViewBuilder.utils';
import { HighSchoolsCard } from './Cards/HighSchoolsCard';
import { CollegeCard } from './Cards/CollegeCard';
import { TestCard } from './Cards/TestCard';
import {
  AddressViewCard,
  EmailView,
  PhoneView,
  RelationshipCard,
  MultiValuedView,
  HonorsAndAwardsList,
  LanguageView,
  ExperienceList,
  EthnicityView,
  RaceCardView,
  SchoolsOfInterestsCard,
  LocationsOfInterestCard,
  SocialMediaViewCard,
} from './CardView';

const noSeperatorNeeded = ['addresses'];

const ViewBuilder = (props: IFieldDetails): ReactElement => {
  const {
    fieldDetails,
    responseData,
    cardView,
    toggleDrawer,
    openChildDrawer,
    selector,
    formTitle,
    openGrandChildDrawer,
    localeKey,
  } = props;
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      {cardView ? (
        <Grid container sx={{ pl: '1rem', mt: '1rem' }}>
          {responseData && responseData?.relationships && (
            <RelationshipCard
              isOpenFromForm={0}
              relationships={responseData?.relationships}
              openChildDrawer={openChildDrawer}
              isFormValid
            />
          )}

          {selector === 'honorsOrAwards' && responseData?.honorsOrAwards && (
            <HonorsAndAwardsList
              isOpenFromForm={0}
              honorsAndAwards={responseData?.honorsOrAwards}
              openChildDrawer={openChildDrawer}
              selector={selector}
              formTitle={formTitle}
            />
          )}
          {selector === 'experiences' && responseData?.experiences && (
            <ExperienceList
              experiences={responseData?.experiences}
              openChildDrawer={openChildDrawer}
              selector={selector}
              formTitle={formTitle}
            />
          )}
          {selector && responseData && selector === 'highSchools' && (
            <HighSchoolsCard
              selector={selector}
              responseData={responseData?.[selector]}
              openChildDrawer={openChildDrawer}
              openGrandChildDrawer={openGrandChildDrawer}
            />
          )}
          {selector && responseData && selector === 'colleges' && (
            <CollegeCard
              selector={selector}
              responseData={responseData?.[selector]}
              openChildDrawer={openChildDrawer}
              openGrandChildDrawer={openGrandChildDrawer}
            />
          )}
          {selector && selector === 'tests' && <TestCard selector={selector} openChildDrawer={openChildDrawer} />}
        </Grid>
      ) : (
        <>
          {Object.keys(fieldDetails || {}).map(fieldKey => {
            const fieldObj = Object(fieldDetails)[fieldKey];

            let value;
            if (fieldObj.otherIdentifier) {
              value = getFieldValue(Object(responseData), fieldObj.otherIdentifier);
            }
            if (!value) {
              value = getFieldValue(Object(responseData), fieldObj.identifier);
            }

            if (fieldObj?.dependant && hideDependantFields(Object(responseData), fieldObj)) {
              return null;
            }
            const format = fieldKey === 'startDate' ? 'MMMM YYYY' : 'MMMM D, YYYY';

            return (
              <React.Fragment key={fieldKey}>
                <Grid item xs={12} sm={12}>
                  <Box
                    sx={{
                      mt: '10px',
                      display: 'flex',
                      width: ['100%', '100%', '100%'],
                      flexDirection: ['column', 'row', 'row'],
                      justifyContent: 'center',
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        flex: 0.32,
                        opacity: '1',
                        display: 'flex',
                      }}
                    >
                      <Typography variant="button" display="block" gutterBottom color="text.secondary">
                        {t(`${localeKey}.${fieldKey}`)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: 0.68,
                        mt: ['0.2rem', '0rem', '0rem'],
                      }}
                    >
                      {Array.isArray(value) ? (
                        <>
                          {fieldKey === 'addresses' && (
                            <AddressViewCard addresses={value} isOpenFromForm={0} openChildDrawer={openChildDrawer} />
                          )}
                          {fieldKey === 'locationsOfInterest' && <LocationsOfInterestCard locationInterests={value} />}
                          {fieldKey === 'schoolsOfInterests' && <SchoolsOfInterestsCard schools={value} />}
                          {fieldKey === 'phones' && <PhoneView phones={value} />}
                          {fieldKey === 'emails' && <EmailView emails={value} />}
                          {fieldKey === 'socialMedia' && (
                            <SocialMediaViewCard socialMedia={value} toggleDrawer={toggleDrawer} />
                          )}
                          {fieldKey === 'competencies' && (
                            <MultiValuedView multiValued={value?.map(item => item.competency)} />
                          )}
                          {(fieldKey === 'modalityOfEducation' ||
                            fieldKey === 'educationalLevel' ||
                            fieldKey === 'programType' ||
                            fieldKey === 'degrees' ||
                            fieldKey === 'fieldsOfStudy' ||
                            fieldKey === 'areasOfStudy' ||
                            fieldKey === 'academicInterests' ||
                            fieldKey === 'type' ||
                            fieldKey === 'roles' ||
                            fieldKey === 'industries') && <MultiValuedView multiValued={value} />}
                          {fieldKey === 'languages' && <LanguageView languages={value} />}
                          {fieldKey === 'ethnicities' && <EthnicityView ethnicities={value} />}
                          {fieldKey === 'races' && <RaceCardView races={value} />}
                        </>
                      ) : (
                        <Typography
                          variant="body2"
                          sx={{ overflowWrap: 'anywhere' }}
                          display="block"
                          gutterBottom
                          data-testid={fieldKey}
                        >
                          {dateFields.indexOf(fieldKey) > -1 && value ? displayDate(value, format) : value}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Grid>
                {!noSeperatorNeeded.includes(fieldKey) && <Divider sx={{ width: '100%', ml: 2 }} />}
              </React.Fragment>
            );
          })}
        </>
      )}
    </Grid>
  );
};

export default memo(ViewBuilder);
