/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TError = string | null;

export interface TAuthField {
  username: string;
  email: string;
  enabled: boolean;
  emailVerified: boolean;
  attributes: {
    terms_and_conditions: string[];
    'LP-STUDENT-ID': string[];
    GDPR?: string[];
  };
  socialLinks?: {
    identityProvider: string;
    userId: string;
    userName: string;
  }[];
}

export interface TUpdateField {
  temporary: boolean;
  currentPassword: string;
  newPassword: string;
}

export interface IAuthState {
  loading: boolean;
  loggedUserData: TAuthField | null;
  updateData: TUpdateField | null;
  error: TError;
}

export const initialState: IAuthState = {
  loading: false,
  loggedUserData: null,
  updateData: null,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loggedUserInfoStart: (state): IAuthState => ({
      ...state,
      loading: true,
      error: null,
    }),
    loggedUserInfoSuccess: (state, action: PayloadAction<TAuthField>): IAuthState => ({
      ...state,
      loading: false,
      loggedUserData: action.payload,
    }),
    loggedUserInfoFailure: (state, action: PayloadAction<TError>): IAuthState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    updatePasswordStart: (state): IAuthState => ({
      ...state,
      loading: true,
      error: null,
    }),
    updatePasswordSuccess: (state, action: PayloadAction<TUpdateField>): IAuthState => ({
      ...state,
      loading: false,
      updateData: action.payload,
    }),
    updatePasswordFailure: (state, action: PayloadAction<TError>): IAuthState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    updatePasswordReset: (state): IAuthState => ({
      ...state,
      loading: false,
      updateData: null,
      error: null,
    }),
    updateEmailStart: (state): IAuthState => ({
      ...state,
      loading: true,
      error: null,
    }),
    updateEmailSuccess: (state, action: PayloadAction<TUpdateField>): IAuthState => ({
      ...state,
      loading: false,
      updateData: action.payload,
    }),
    updateEmailFailure: (state, action: PayloadAction<TError>): IAuthState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    updateEmailReset: (state): IAuthState => ({
      ...state,
      loading: false,
      updateData: null,
      error: null,
    }),
  },
});

export const {
  loggedUserInfoStart,
  loggedUserInfoSuccess,
  loggedUserInfoFailure,
  updatePasswordStart,
  updatePasswordSuccess,
  updatePasswordFailure,
  updatePasswordReset,
  updateEmailStart,
  updateEmailSuccess,
  updateEmailFailure,
  updateEmailReset,
} = authSlice.actions;

export default authSlice.reducer;
