/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, ReactNode } from 'react';
import { Grid, Box, Stack, Button, IconButton, Divider, Drawer, Typography } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Icon, IconCaretDownDouble } from '@liaison/liaison-ui';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectFooterModal } from 'store/ui/ui.selectors';
import { getNameSpace, isCsuTenant } from 'utils/utilities';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { setUi } from 'store/ui/ui.slice';
import { UserAvatar } from 'userProfile/components/UserAvatar';
import { AvatarSidePanel } from 'userProfile/components/AvatarSidePanel';
import { selectPersonalInformation } from 'userProfile/store/personalInfo/personalInfo.selectors';
import { StyledButton } from 'pages/Pages.styles';
import {
  sxFooterBox,
  sxHeadingMenu,
  sxDrawerBox,
  sxListItem,
  sxMenuListItem,
  sxCancelButton,
} from './FooterSection.styles';

export type TfooterSection = {
  label: string;
  icon?: string;
  ariaLabel?: string;
  location?: string;
  onClick?: () => void;
};

interface IfooterSectionProps {
  footerSectionMenus: TfooterSection[];
  title?: string;
  children?: ReactNode;
  showCancel?: boolean;
}

const nameSpace = getNameSpace();

const FooterSection = ({ footerSectionMenus, title, children, showCancel }: IfooterSectionProps): ReactElement => {
  const { t } = useTranslation();
  const { isOpen } = useAppSelector(selectFooterModal);
  const personalInfo = useAppSelector(selectPersonalInformation);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  const firstName = personalInfo?.personal?.preferredName || personalInfo?.personal?.givenName || '';
  const lastName = personalInfo?.personal?.familyName || '';
  const email =
    personalInfo?.contact?.emails?.find(e => e.preferred)?.email || personalInfo?.contact?.emails?.[0]?.email;

  const toggleDrawer = (newOpen: boolean) => () => {
    dispatch(setUi({ name: 'footerModal', state: { isOpen: newOpen } }));
  };

  const handleMenuClick = (location: string) => {
    dispatch(setUi({ name: 'footerModal', state: { isOpen: false } }));
    navigate(`/${location}/${id || ''}`);
    window.scrollTo(0, 0);
  };

  const openSidePanel = () => {
    dispatch(setUi({ name: 'avatarSidebar', state: { open: true } }));
  };

  return (
    <Box sx={sxFooterBox}>
      <Grid container direction="row">
        <Grid item xs={1} />
        <Grid item xs={10}>
          <StyledButton
            color="secondary"
            onClick={toggleDrawer(true)}
            size="large"
            fullWidth
            startIcon={<FormatListBulletedIcon />}
          >
            {title || t('sections_label', nameSpace)}
          </StyledButton>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Drawer
        role="contentinfo"
        PaperProps={{ sx: { ...sxDrawerBox } }}
        anchor="bottom"
        open={isOpen}
        onClose={toggleDrawer(false)}
      >
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: 'flex',
              py: 2,
              bgcolor: theme => (isCsuTenant() ? theme.palette.background.paper : theme.palette.primary.main),
            }}
          >
            <Typography sx={sxHeadingMenu} component="h2" variant="h3">
              {title || t('sections_label', nameSpace)}
            </Typography>
            <IconButton
              onClick={toggleDrawer(false)}
              aria-label="close section bar"
              sx={{
                color: theme => theme.palette.primary.contrastText,
              }}
            >
              <IconCaretDownDouble />
            </IconButton>
          </Box>
          <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
            <Stack spacing={1} direction="row" alignItems="center" sx={{ minWidth: 0 }}>
              <UserAvatar
                firstName={firstName}
                lastName={lastName}
                width="3rem"
                height="3rem"
                initialsFontSize="1.75rem"
                hideName
              />
              <Stack sx={{ minWidth: 0 }}>
                <Typography variant="subtitle3" component="span" noWrap>{`${firstName}${
                  lastName ? ` ${lastName}` : ''
                }`}</Typography>
                <Typography variant="body3" noWrap>
                  {email}
                </Typography>
              </Stack>
            </Stack>
            <Stack>
              <IconButton
                onClick={openSidePanel}
                aria-label={t('profileImage.title', nameSpace)}
                sx={{
                  color: theme => theme.palette.primary.contrastText,
                  border: '1px solid',
                }}
              >
                <Icon fontSize="small">IconCamera</Icon>
              </IconButton>
            </Stack>
          </Stack>
          <Divider sx={{ borderStyle: 'solid', pb: 2 }} />
          <Box sx={{ p: 1 }}>
            {children}
            {footerSectionMenus.map(menu => {
              return (
                <Box
                  sx={sxMenuListItem}
                  onClick={menu.onClick ? menu.onClick : () => handleMenuClick(menu?.location || '')}
                  key={menu?.label}
                >
                  {menu?.icon && (
                    <Icon
                      aria-label={menu?.ariaLabel || menu?.label || menu?.icon}
                      sx={{
                        padding: '0.2rem',
                        fontSize: '2rem',
                        color: theme => theme.palette.primary.contrastText,
                      }}
                    >
                      {menu?.icon}
                    </Icon>
                  )}
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={sxListItem}
                    aria-label={menu?.ariaLabel || menu?.label || menu?.icon}
                    tabIndex={0}
                  >
                    {menu?.label}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          {showCancel && (
            <Box sx={sxCancelButton}>
              <Button color="secondary" onClick={toggleDrawer(false)} size="medium" variant="text">
                <Typography variant="subtitle7">{t('cancel_label', nameSpace)}</Typography>
              </Button>
            </Box>
          )}
        </Box>
      </Drawer>
      <AvatarSidePanel />
    </Box>
  );
};

export default FooterSection;
