/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@liaison/liaison-ui';

import { getMenuItems, IMenuItem } from 'transferPlanner/constants/menuItems';
import { useAppSelector } from 'redux/hooks';
import { selectFeatures } from 'store/features/features.selectors';
import { mailTo } from 'utils/utilities';

import { WelcomeSection } from 'transferPlanner/pages/Dashboard';
import { classes, StyledNavButton } from './UserNav.styles';

const UserNav = (): ReactElement => {
  const navigate = useNavigate();

  const features = useAppSelector(selectFeatures);
  const menuItems = getMenuItems(features);

  const handleItemClick = (location: string) => {
    navigate(`/${location}`);
    window.scrollTo(0, 0);
  };

  const onClickHandler = (menu: IMenuItem) => () => {
    if (menu?.location) handleItemClick(menu?.location);
    if (menu?.mailTo) mailTo(menu?.mailTo);
  };

  return (
    <Grid container item direction="column" justifyContent="space-between">
      <WelcomeSection />
      <Divider sx={{ mt: 2, mb: 1.5, border: '0.1px solid #AFBBCC' }} />
      {menuItems.map(menu => {
        return (
          <StyledNavButton onClick={onClickHandler(menu)} key={menu.label} className={classes.button}>
            <Icon style={{ color: '#CC0B2A' }}>{menu?.icon}</Icon>
            <Typography
              align="left"
              variant="subtitle7"
              component="div"
              sx={{
                margin: '5px 0px 5px 10px',
                width: '100%',
              }}
              role="presentation"
            >
              {menu.label}
              {menu.subLabel && (
                <Typography
                  align="left"
                  variant="subtitle8"
                  component="span"
                  sx={{
                    marginTop: '5px',
                    display: 'block',
                  }}
                >
                  {menu?.subLabel}
                </Typography>
              )}
            </Typography>
            <KeyboardArrowRightIcon />
          </StyledNavButton>
        );
      })}
    </Grid>
  );
};

export default memo(UserNav);
