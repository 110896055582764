/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  Grid,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
  Theme,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { TFollowedProgramModified } from 'transferPlanner/store/programCart/programCart.slice';
import { truncate } from 'utils/utilities';
import { IconCaretRight } from '@liaison/liaison-ui';
import { setUi } from 'store/ui/ui.slice';
import { useAppDispatch } from 'redux/hooks';

type TMenuSection = { label: string; ariaLabel?: string; location?: string; onClick?: () => void };

interface IMenuSectionProps {
  menusList: TMenuSection[];
}

export const GenEdUserNav = ({ menusList }: IMenuSectionProps): ReactElement => {
  const { pathname } = useLocation();
  const [activePage, setActivePage] = useState('');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setActivePage(pathname.replace(/^\//, ''));
  }, [pathname]);

  const handleItemClick = (location: string | undefined) => {
    if (location) {
      dispatch(setUi({ name: 'footerModal', state: { isOpen: false } }));
      setActivePage(location);
      navigate(`/${location}`);
      window.scrollTo(0, 0);
    }
  };

  return (
    <List sx={{ width: '100%' }} aria-label="Side Menu">
      {menusList?.map(menu => {
        return (
          <ListItem disablePadding key={menu.label} sx={{ mt: 0.5 }}>
            <ListItemButton
              aria-label={menu?.ariaLabel}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: '#E3F2FD',
                  border: '1px solid #1565C0',
                  borderRadius: 2,
                },
                [`&.Mui-focusVisible`]: {
                  backgroundColor: 'unset',
                  border: ({ palette }: Theme) => `2px solid ${palette.secondary.dark}`,
                },
              }}
              selected={menu.location === activePage}
              onClick={() => handleItemClick(menu.location)}
            >
              <Typography variant="subtitle7">{menu.label}</Typography>
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

const activeCard = {
  bgcolor: '#E3F2FD',
  border: '1px solid #1565C0',
};

interface IFollowedProgramListProps {
  followedPrograms: TFollowedProgramModified[] | null;
  activeProgram: string;
  handleItemClick: (id: string) => void;
}

export const FollowedProgramNav = ({
  followedPrograms,
  activeProgram,
  handleItemClick,
}: IFollowedProgramListProps): ReactElement => {
  return (
    <>
      {followedPrograms &&
        followedPrograms?.map(followedProgram => {
          return (
            <Card
              key={followedProgram?.program?.name}
              data-testid="followed-program-card"
              variant="outlined"
              sx={{ mt: 1, border: 'none' }}
              onClick={() => handleItemClick(`${followedProgram?.program?.id}`)}
            >
              <CardActionArea
                sx={{
                  ...(activeProgram === `${followedProgram?.program?.id}` ? activeCard : {}),
                  borderRadius: 1,
                }}
              >
                <Grid container sx={{ p: 1 }}>
                  <Grid item xs={10.5} md={10}>
                    <Typography variant="subtitle3">{truncate(followedProgram?.program?.name, 30)}</Typography>
                    <Box sx={{ mt: 0.5, display: 'flex', alignItems: 'center' }}>
                      <Typography variant="caption">{followedProgram.campus.name}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={1.5} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Stack justifyContent="center">
                      <IconCaretRight />
                    </Stack>
                  </Grid>
                </Grid>
              </CardActionArea>
            </Card>
          );
        })}
    </>
  );
};
