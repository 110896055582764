/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { IconDocuments, IconUpload } from '@liaison/liaison-ui';

import MediaDocumentForm from 'userProfile/pages/MediaDocuments/MediaDocumentForm';
import { getMediaDocs } from 'userProfile/pages/MediaDocuments/MediaDocuments.utils';
import { selectMediaDocs, selectSectionMediaDocs } from 'userProfile/store/mediaDocuments/mediaDocuments.selectors';
import type { TSectionName } from 'userProfile/store/mediaDocuments/mediaDocuments.slice';
import { selectFeatures } from 'store/features/features.selectors';
import { isFeatureActive } from 'utils/features.utils';
import { MEDIA_DOCUMENTS } from 'userProfile/constants/routeNames';
import { sxButton } from './AttachedFiles.styles';
import { FilesSidePanel } from './FilesSidePanel';
import { FileList } from './FileList';

type TAttachedFilesProps = {
  sectionName: TSectionName;
  tag: string;
  disabled?: boolean;
};

export const AttachedFiles = ({ sectionName, tag, disabled = false }: TAttachedFilesProps): ReactElement | null => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDocumentFormOpen, setIsDocumentFormOpen] = useState(false);
  const [isFilesSidePanelOpen, setIsFilesSidePanelOpen] = useState(false);
  const features = useSelector(selectFeatures);
  const isMediaDocumentsActive = isFeatureActive(features, MEDIA_DOCUMENTS.featureName);
  const allMediaDocs = useSelector(selectMediaDocs);
  const sectionId = tag.split('/').pop();
  const sectionMediaDocs = useSelector(state => selectSectionMediaDocs(state, sectionId));

  useEffect(() => {
    if (!allMediaDocs) {
      dispatch(getMediaDocs());
    }
  }, [allMediaDocs, dispatch]);

  if (!isMediaDocumentsActive) {
    return null;
  }

  return (
    <>
      <Divider sx={{ borderStyle: 'solid', my: 2 }} flexItem />
      <Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" component="p" sx={{ mb: 1 }}>
            {t('mediaDocuments.attaching.header')}
          </Typography>
          <Typography variant="body3" component="p" sx={{ mb: 1 }}>
            {t('mediaDocuments.attaching.subtitle')}
          </Typography>
          <Typography variant="caption" component="p" sx={{ mb: 1 }}>
            {t('mediaDocuments.attaching.instruction')}
          </Typography>
          <Grid container columnSpacing={2} justifyContent="center" alignItems="center">
            <Grid item container xs={6} justifyContent="center">
              <Button
                sx={sxButton}
                fullWidth
                variant="contained"
                startIcon={<IconDocuments />}
                onClick={() => setIsFilesSidePanelOpen(true)}
                disabled={disabled}
              >
                {t('mediaDocuments.attaching.library')}
              </Button>
            </Grid>
            <Grid item container xs={6} justifyContent="center">
              <Button
                sx={sxButton}
                fullWidth
                variant="contained"
                startIcon={<IconUpload />}
                onClick={() => setIsDocumentFormOpen(true)}
                disabled={disabled}
              >
                {t('mediaDocuments.attaching.device')}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <FileList mediaDocs={sectionMediaDocs} sectionName={sectionName} tag={tag} />
        <MediaDocumentForm
          isFormOpen={isDocumentFormOpen}
          setIsFormOpen={setIsDocumentFormOpen}
          isNewFile
          sectionName={sectionName}
          tag={tag}
        />
        <FilesSidePanel
          isOpen={isFilesSidePanelOpen}
          setIsOpen={setIsFilesSidePanelOpen}
          sectionName={sectionName}
          tag={tag}
          mediaDocs={sectionMediaDocs}
        />
      </Box>
      <Divider sx={{ borderStyle: 'solid', my: 2 }} flexItem />
    </>
  );
};
