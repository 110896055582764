/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import forge from 'node-forge';

const masterPassword = String(window.env.LP_ENCRYPTION_MASTER_KEY);
const keySize = 32;
const iterations = 1024;
const saltLength = 16;
const ivLength = 12;
const tagLength = 16;

export const encrypt = (data: string): string => {
  const salt = forge.random.getBytesSync(saltLength);
  const iv = forge.random.getBytesSync(ivLength);
  const key = forge.pkcs5.pbkdf2(masterPassword, salt, iterations, keySize, 'sha256');
  const cipher = forge.cipher.createCipher('AES-GCM', key);
  cipher.start({ iv, tagLength: tagLength * 8 });
  cipher.update(forge.util.createBuffer(data));
  cipher.finish();
  const encrypted = cipher.output.bytes();
  const tag = cipher.mode.tag.bytes();
  return forge.util.encode64(salt + iv + encrypted + tag);
};

/* istanbul ignore next */
export const decrypt = (encrypted: string): string => {
  const decoded = forge.util.binary.base64.decode(encrypted);
  const salt = forge.util.createBuffer(decoded.slice(0, saltLength));
  const iv = forge.util.createBuffer(decoded.slice(saltLength, saltLength + ivLength));
  const ciphertext = forge.util.createBuffer(decoded.slice(saltLength + ivLength, decoded.length - tagLength));
  const tag = forge.util.createBuffer(decoded.slice(saltLength + ivLength + ciphertext.bytes().length));
  const key = forge.pkcs5.pbkdf2(masterPassword, salt.bytes(), iterations, keySize, 'sha256');
  const decipher = forge.cipher.createDecipher('AES-GCM', key);
  decipher?.start({ iv, tagLength: tagLength * 8, tag });
  decipher.update(ciphertext);
  const pass = decipher.finish();
  if (pass) {
    return decipher.output.toString();
  }
  throw new Error(`Failed to decode`);
};

/* istanbul ignore next */
export const encryptWithEncode = (data: string): string => {
  try {
    return encodeURIComponent(encrypt(encodeURIComponent(data)));
  } catch {
    return data;
  }
};

export const decryptWithDecode = (data: string): string => {
  try {
    return decodeURIComponent(decrypt(decodeURIComponent(data)));
  } catch {
    return data;
  }
};
