/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import { useMemo } from 'react';
import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';
import { tspDeadlineExtensionTimestamp } from 'transferPlanner/store/enrollment/enrollment.selectors';
import 'moment-timezone';
import { isCsuTenant } from 'utils/utilities';

const useDeadlineExtensionBanner = (): Moment | null => {
  const tspDeadlineExtensionTime = useSelector(tspDeadlineExtensionTimestamp);

  const shouldDisplayTheBanner: Moment | null = useMemo(() => {
    if (isCsuTenant() && tspDeadlineExtensionTime) {
      const currentDateTimePT = moment().tz('America/Los_Angeles');
      const tspDeadlineExtensionDateNTime = moment.tz(tspDeadlineExtensionTime, 'America/Los_Angeles');
      if (tspDeadlineExtensionDateNTime?.isSameOrAfter(currentDateTimePT)) {
        return tspDeadlineExtensionDateNTime;
      }
    }
    return null;
  }, [tspDeadlineExtensionTime]);

  return shouldDisplayTheBanner;
};

export default useDeadlineExtensionBanner;
