/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import axios from 'axios';
import { API } from 'constants/api';
import { t } from 'i18next';
import { TAppThunk } from 'redux/store';
import {
  loggedUserInfoStart,
  loggedUserInfoSuccess,
  loggedUserInfoFailure,
  updatePasswordStart,
  updatePasswordSuccess,
  updatePasswordFailure,
  TAuthField,
  updateEmailStart,
  updateEmailSuccess,
  updateEmailFailure,
} from 'userProfile/store/auth/auth.slice';
import { setUi } from 'store/ui/ui.slice';

export const getLoggedUserDetails = (): TAppThunk => async dispatch => {
  try {
    dispatch(loggedUserInfoStart());
    const { data } = await axios.get(`${API?.auth}users/loggedUser`);
    dispatch(loggedUserInfoSuccess(data));
  } catch (err) {
    dispatch(loggedUserInfoFailure(err?.response?.data?.message));
  }
};

type TUpdatePassword = {
  currentPassword: string | undefined;
  newPassword: string | undefined;
  temporary: boolean | undefined;
};

export const updatePassword =
  (payload: TUpdatePassword, successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(updatePasswordStart());
      const { data } = await axios.post(`${API?.auth}updatePassword`, payload);
      dispatch(updatePasswordSuccess(data));
      successCallback?.();
    } catch (err) {
      dispatch(updatePasswordFailure(err?.response?.data?.message));
      if (!err?.response?.data?.message) {
        dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      }
    }
  };

export const gdprStatus = (loggedUserData: TAuthField): string => {
  return loggedUserData?.attributes?.GDPR?.[0] === 'true' ? 'YES' : 'NO';
};

type TUpdateEmail = {
  email: string;
  verified: boolean;
};

export const updateEmail =
  (payload: TUpdateEmail, successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(updateEmailStart());
      const { data } = await axios.post(`${API?.auth}updateUserEmail`, payload);
      dispatch(updateEmailSuccess(data));
      successCallback?.();
    } catch (err) {
      dispatch(updateEmailFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
      if (!err?.response?.data?.message) {
        dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      }
    }
  };
