/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './i18n';
import { ErrorBoundary } from './components/ErrorBoundary';
import store, { persistor } from './redux/store';
import { Root } from './pages/Root';
import './index.css';

const root = createRoot(document.getElementById('root') as HTMLElement);
const metaThemeColor = document.querySelector<HTMLElement>("meta[name='theme-color']");
const metaThemeColorForWindows = document.querySelector<HTMLElement>("meta[name='msapplication-navbutton-color']");
const metaThemeColorForApple = document.querySelector<HTMLElement>(
  "meta[name='apple-mobile-web-app-status-bar-style']"
);

if (metaThemeColor && metaThemeColorForWindows && metaThemeColorForApple) {
  const themeColor = JSON.parse(window.env.DOMAINS)[window.location.host] === 'csu' ? '#C20008' : '#12274E';
  metaThemeColor.setAttribute('content', themeColor);
  metaThemeColorForWindows.setAttribute('content', themeColor);
  metaThemeColorForApple.setAttribute('content', themeColor);
}

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename="/">
        <ErrorBoundary>
          <Root />
        </ErrorBoundary>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
