/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TQuestionnaireConfig } from 'transferPlanner/pages/Questionnaire/DynamicQuestionnaire.utils';

type TError = string | null;

export type Question = {
  code: string;
  order: number;
  meta: {
    code: string;
    displayName: string;
    type: string;
    format: string;
  };
  required: boolean;
  displayName: string;
  answer: {
    code: string;
    value: string;
    displayName: string;
  };
};

export interface ITspEligibility {
  questionnaireVersion: number;
  status: { code: 'ELIGIBLE' | 'INELIGIBLE'; displayName: 'Eligible' | 'Ineligible' };
  reason: { code: 'CRITERIA_MET' | 'CRITERIA_NOT_MET'; displayName: 'Criteria Met' | 'Criteria Not Met' };
  lastUpdatedTimestamp: string;
  questions: Question[];
}

export interface IEligibility {
  profileId: string;
  lastUpdatedTimestamp: string;
  lastUpdatedByTenant: string;
  tspEligibility: ITspEligibility[];
}

export interface IEligibilityState {
  loading: boolean;
  eligibility: IEligibility | null;
  questionnaireConfig: TQuestionnaireConfig | null;
  error: TError;
}

export const initialState: IEligibilityState = {
  loading: false,
  eligibility: null,
  questionnaireConfig: null,
  error: null,
};

const eligibilitySlice = createSlice({
  name: 'eligibility',
  initialState,
  reducers: {
    eligibilityStart: (state): IEligibilityState => ({
      ...state,
      loading: true,
      error: null,
    }),
    eligibilitySuccess: (state, action: PayloadAction<IEligibility>): IEligibilityState => ({
      ...state,
      loading: false,
      eligibility: action.payload,
    }),
    eligibilityQuestionnaireConfigSuccess: (state, action: PayloadAction<TQuestionnaireConfig>): IEligibilityState => ({
      ...state,
      loading: false,
      questionnaireConfig: action.payload,
    }),
    eligibilityFailure: (state, action: PayloadAction<TError>): IEligibilityState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    eligibilityReset: () => initialState,
  },
});

export const {
  eligibilityStart,
  eligibilitySuccess,
  eligibilityQuestionnaireConfigSuccess,
  eligibilityFailure,
  eligibilityReset,
} = eligibilitySlice.actions;

export default eligibilitySlice.reducer;
