/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { SvgIconProps } from '@mui/material';
import { IconClipboardContent } from '@liaison/liaison-ui';
import { TAppThunk } from 'redux/store';
import { getLoggedUser } from 'utils/keyCloakUtils';
import axios from 'axios';
import { API } from 'transferPlanner/constants/api';
import { t } from 'i18next';
import {
  additionalDetailsStart,
  additionalDetailsSuccess,
  additionalDetailsFailure,
  ITpAdditionalDetails,
} from 'transferPlanner/store/additionalDetails/additionalDetails.slice';
import {
  programsStart,
  programsFailure,
  programsSuccess,
  Programs,
} from 'transferPlanner/store/adtPrograms/programs.slice';
import { setUi } from 'store/ui/ui.slice';
import { csuNameSpace } from 'utils/utilities';
import { getFollowedPrograms } from 'transferPlanner/components/MyProgramsCart/MyProgramsCart.utils';

export const BIOGRAPHIC_INFORMATION = 'BIOGRAPHIC_INFORMATION';
export const ADT_INFORMATION = 'ADT_INFORMATION';

export interface Race {
  id: string;
  text: string;
  displayName?: string;
  code?: string;
  order?: number;
}

export interface EnrollmentStatus {
  val: string;
  text: string;
  displayName?: string;
  code?: string;
  order?: number;
}

interface Tab {
  id: number;
  name: string;
  icon: React.FC<SvgIconProps<'svg', {}>>;
  bgColor: string;
}

export const mobileTabHeadings = [t('tpAdditionalInfo.pageTitle', csuNameSpace)];

export const tab: Tab = {
  id: 0,
  name: t('tpAdditionalInfo.additionalInfo', csuNameSpace),
  icon: IconClipboardContent,
  bgColor: '#2F2F2F',
};

export const getProgramsByCollege =
  (i: string, ceeb: string): TAppThunk =>
  async dispatch => {
    try {
      dispatch(programsStart());
      const { data } = await axios.get(`${API.adtColleges}/programs?ceebValue=${ceeb}`);
      dispatch(programsSuccess({ i, data }));
    } catch (err) {
      dispatch(programsFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const getProgramOptions = (programOptions: Programs): Programs => {
  return Object.entries(programOptions).reduce((acc, [index, options]) => {
    acc[index] = options?.map(option => ({
      ...option,
      id: option?.name,
      text: option?.name,
    }));
    return acc;
  }, {} as Programs);
};

export const raceOptions: Race[] = [
  { id: 'AI', text: 'American Indian or Alaska Native' },
  { id: 'AS', text: 'Asian' },
  { id: 'BL', text: 'Black or African-American' },
  { id: 'HI', text: 'Hispanic or Latino' },
  { id: 'PI', text: 'Native Hawaiian or Other Pacific Islander' },
  { id: 'WH', text: 'White' },
  { id: 'TWO_OR_MORE', text: 'Two or more races/ethnicities' },
  { id: 'DECLINE', text: 'Decline to state' },
];

export const enrollmentStatusOptions: EnrollmentStatus[] = [
  { code: 'BELOW_HALF_TIME', displayName: 'Below half-time (1-5.9 semester units)' },
  { code: 'HALF_TIME', displayName: 'Half-time (6-8.9 semester units)' },
  { code: 'THREE_QUARTER_TIME', displayName: 'Three-quarter-time (9-11.9 semester units)' },
  { code: 'FULL_TIME', displayName: 'Full-time (12 or more semester units)' },
].map(option => ({
  ...option,
  val: option?.code as string,
  text: option?.displayName as string,
}));

export const postTpAdditionalDetails =
  (payload: ITpAdditionalDetails, successCallback?: () => void): TAppThunk =>
  async dispatch => {
    const loggedUserId = getLoggedUser();
    try {
      const modifiedPayload = {
        additionalDetails: {
          ...payload,
          ...(payload.adtInformation && {
            adtInformation: [
              ...(payload.adtInformation.length > 0 ? [payload.adtInformation[0]] : []),
              ...(payload.adtInformation.length > 1 ? [payload.adtInformation[1]] : []),
            ],
          }),
        },
        profileId: loggedUserId,
      };
      dispatch(additionalDetailsStart());
      const { data } = await axios.post(`${API?.additionalDetails}/${loggedUserId}`, modifiedPayload);
      dispatch(additionalDetailsSuccess(data));
      dispatch(getFollowedPrograms());
      successCallback?.();
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      dispatch(additionalDetailsFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const getTpAdditionalDetails =
  (successCallback?: () => void): TAppThunk =>
  async dispatch => {
    const loggedUserId = getLoggedUser();
    try {
      dispatch(additionalDetailsStart());
      const { data } = await axios.get(`${API?.additionalDetails}/${loggedUserId}`);
      dispatch(additionalDetailsSuccess(data));
      successCallback?.();
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      dispatch(additionalDetailsFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const educationLevel = [
  { id: 'NO_HIGH_SCHOOL', text: 'No High School' },
  { id: 'SOME_HIGH_SCHOOL', text: 'Some High School' },
  { id: 'HIGH_SCHOOL_GRADUATE', text: 'High School Graduate' },
  { id: 'SOME_COLLEGE', text: 'Some College' },
  { id: 'TWO_YEAR_COLLEGE_GRADUATE', text: '2-year College Graduate' },
  { id: 'FOUR_YEAR_COLLEGE_GRADUATE', text: '4-year College Graduate' },
  { id: 'POSTGRADUATE', text: 'Postgraduate' },
  { id: 'DOES_NOT_APPLY', text: 'Does Not Apply' },
];

export const usMilitoryStatus = [
  { id: 'NO', text: 'No. I have not served in the US Military' },
  { id: 'ACTIVE_DUTY', text: 'Yes. I am currently serving on Active Duty' },
  { id: 'NATIONAL_GUARD', text: 'Yes. I am currently serving in the National Guard' },
  { id: 'RESERVES', text: 'Yes. I am currently serving in the Reserves' },
  { id: 'DISCHARGED', text: 'Yes. I served in the US Military, but I have been discharged' },
];

export const intendedTransferTermList = [
  { id: 'FALL', text: 'Fall' },
  { id: 'SPRING', text: 'Spring' },
];
