/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useCallback } from 'react';
import { Grid, Box, Typography, Card, CardContent, Divider, IconButton, Stack, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';

import type { ICollege, Idegree } from 'store/academicHistory/academicHistory.slice';
import { Icon, IconEdit } from '@liaison/liaison-ui';
import Pluralize from 'pluralize';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useAppSelector } from 'redux/hooks';
import { selectFeatures } from 'store/features/features.selectors';
import { isFeatureActive } from 'utils/features.utils';
import { trimHeader } from 'utils/utilities';
import { setTitle } from 'utils/commonUtils';
import { StyledButton, StyledAddButton, StyledPrimaryButton, sxCard, StyledContainedButton } from 'pages/Pages.styles';
import { findNoneGradeFromTerm } from '../ViewBuilder.utils';

interface ICollegeCardButtonProps {
  isCourseWorkActive?: boolean;
  courseLength: number;
  position: number;
  responseDetail: ICollege | undefined;
  selector: string;

  openChildDrawer?: (
    isAddNew: boolean,
    childPosition: number,
    parentSelector: string,
    formTitle: string,
    showCourse?: boolean
  ) => void;
  openGrandChildDrawer?: (
    isAddNew: boolean,
    childPosition: number,
    parentSelector: string,
    formTitle: string,
    parentPosition?: number,
    sectionPrefix?: string
  ) => void;
}

const CollegeCardButtons = ({
  isCourseWorkActive,
  courseLength,
  position,
  responseDetail,
  selector,
  openChildDrawer,
  openGrandChildDrawer,
}: ICollegeCardButtonProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  const termsList = responseDetail?.terms;
  const isNoneGradeAvailable = findNoneGradeFromTerm(termsList);
  const additionalCss = isNoneGradeAvailable
    ? {
        background: '#FFEBEE',
        '&:hover, &:active, &:focus': { backgroundColor: '#FFEBEE' },
      }
    : '';
  return (
    <>
      {isCourseWorkActive && (
        <Grid item xs={isMobile ? 10 : 4.5} sm={4.5} md={2.6} lg={2.1} sx={{ height: '60%' }}>
          <Stack direction="row" justifyContent={isMobile ? 'center' : 'end'} sx={{ mt: '0.7rem' }}>
            {courseLength ? (
              <StyledButton
                aria-label={
                  isNoneGradeAvailable ? t('colleges.course.noneGradeCourses') : t('colleges.course.viewAllCourses')
                }
                fullWidth={isMobile}
                sx={{ fontSize: '0.625rem', ...additionalCss }}
                size="small"
                onClick={() => openChildDrawer?.(false, position, selector, responseDetail?.name as string, true)}
              >
                {Pluralize(t('academicHistory.course'), courseLength, true)}
              </StyledButton>
            ) : (
              <StyledAddButton
                sx={{ fontSize: '0.58rem' }}
                size="small"
                fullWidth={isMobile}
                onClick={() =>
                  openGrandChildDrawer?.(
                    true,
                    responseDetail?.terms?.length || 0,
                    selector,
                    t('colleges.addCollegeCourse'),
                    position,
                    'terms'
                  )
                }
              >
                {t('academicHistory.addCourse')}
              </StyledAddButton>
            )}
          </Stack>
        </Grid>
      )}

      <Grid item xs={isMobile ? 1 : 1.2} sm={1.2} md={0.7} sx={{ margin: 'auto', height: '60%', mt: '0.4rem' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton
            aria-label={t('colleges.editCollege')}
            size="small"
            onClick={() => {
              openChildDrawer?.(false, position, selector, t('colleges.editCollege'));
              setTitle(t('colleges.editPageTitle'));
            }}
          >
            <IconEdit />
          </IconButton>
        </Box>
      </Grid>
    </>
  );
};

interface ICollegeCardProps {
  responseData: ICollege[];
  smallLayout?: number;
  xtraSmallLayout?: number;
  openChildDrawer?: (
    isAddNew: boolean,
    childPosition: number,
    parentSelector: string,
    formTitle: string,
    showCourse?: boolean
  ) => void;
  selector: string;
  openGrandChildDrawer?: (
    isAddNew: boolean,
    childPosition: number,
    parentSelector: string,
    formTitle: string,
    parentPosition?: number,
    sectionPrefix?: string
  ) => void;
}

export const CollegeCard = ({
  responseData,
  xtraSmallLayout = 12,
  smallLayout = 12,
  openChildDrawer,
  openGrandChildDrawer,
  selector,
}: ICollegeCardProps): ReactElement => {
  const { t } = useTranslation();
  const features = useAppSelector(selectFeatures);
  const isCourseWorkActive = isFeatureActive(features, 'COLLEGE_COURSEWORK');
  const formatDate = (inputDate: string) => inputDate && moment(inputDate).format('MMMM YYYY');
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const formatedDegrees = useCallback((degreesList: Idegree[]) => {
    return [...degreesList].sort((firstElement, secondElement) =>
      firstElement.degreeStatus.code.localeCompare(secondElement.degreeStatus.code)
    );
  }, []);

  return (
    <Grid container spacing={2}>
      {(responseData || []).map((responseDetail, position) => {
        const formatedDegreeData = formatedDegrees(responseDetail?.degrees || []);
        const addressResponse = responseDetail?.address;
        const termsList = responseDetail?.terms;
        const courseLength = termsList?.reduce((count, current) => count + (current?.courses || [])?.length, 0);
        const degreesLength = responseDetail?.degrees?.length;
        const regionOrCity =
          addressResponse?.region?.displayName !== 'N/A'
            ? addressResponse?.region?.displayName
            : addressResponse?.city || 'N/A';

        const collegeLocation = regionOrCity + (regionOrCity ? ', ' : '') + addressResponse?.country?.displayName;

        return (
          <Grid
            item
            xs={xtraSmallLayout}
            sm={smallLayout}
            key={`${responseDetail?.ceeb}-${responseDetail?.name}-${courseLength}-${degreesLength}`}
            sx={{ mt: position === 0 ? '1rem' : '' }}
          >
            <Card
              variant="outlined"
              sx={{
                borderRadius: '0.5rem',
                boxShadow:
                  '0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06)',
              }}
            >
              <CardContent
                sx={{
                  '&:last-child': {
                    pb: '1rem',
                  },
                }}
              >
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={isMobile ? 12 : 5}
                    sm={isCourseWorkActive ? 5 : 10}
                    md={isCourseWorkActive ? 8.5 : 11}
                    lg={isCourseWorkActive ? 9 : 11}
                    sx={{ mr: '0.5rem', display: 'flex' }}
                  >
                    <Box>
                      <Icon
                        sx={{ color: theme => theme.palette.grey[500], m: '0.3rem', mr: '0.7rem' }}
                        aria-label={trimHeader((responseDetail?.name as unknown as string) || '')}
                      >
                        IconCollege
                      </Icon>
                    </Box>
                    <Box>
                      <Box>
                        <Typography component="h1" sx={{ overflowWrap: 'anywhere', fontWeight: '800' }}>
                          {trimHeader((responseDetail?.name as unknown as string) || '')}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="caption" sx={{ overflowWrap: 'anywhere' }}>
                          {collegeLocation}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  {!isMobile && (
                    <CollegeCardButtons
                      isCourseWorkActive={isCourseWorkActive}
                      courseLength={courseLength}
                      position={position}
                      responseDetail={responseDetail}
                      selector={selector}
                      openChildDrawer={openChildDrawer}
                      openGrandChildDrawer={openGrandChildDrawer}
                    />
                  )}
                </Grid>

                <Divider
                  sx={{
                    borderStyle: 'solid',
                    mt: '0.7rem',
                    mb: '0.7rem',
                    borderColor: theme => theme.palette.grey[500],
                  }}
                  flexItem
                />
                <Box sx={{ mt: 0.5, display: 'flex', alignItems: 'center' }}>
                  {responseDetail?.primary && (
                    <>
                      <StyledPrimaryButton
                        size="small"
                        variant="outlined"
                        disabled
                        startIcon={
                          <Icon sx={{ color: theme => theme.palette.secondary.contrastText }}>IconCheckCircle</Icon>
                        }
                      >
                        {t('primary_label')}
                      </StyledPrimaryButton>
                      <Divider
                        variant="middle"
                        sx={{ borderStyle: 'solid', mt: '1rem', mr: 2.25, ml: 2.25 }}
                        orientation="vertical"
                        flexItem
                      />
                    </>
                  )}

                  <Box>
                    {formatedDegreeData?.map(degree => (
                      <Typography key={degree.id} variant="subtitle6" sx={{ textTransform: 'none' }} display="block">
                        {degree.type?.displayName} - {degree.degreeStatus?.displayName}
                        {degree.degreeStatus?.code === 'AWARDED' && `: ${formatDate(degree?.endDate as string)}`}
                      </Typography>
                    ))}

                    {formatedDegreeData.length < 1 && (
                      <Typography variant="subtitle6" sx={{ textTransform: 'none' }} display="block">
                        {t('colleges.noDegree')}
                      </Typography>
                    )}

                    <Typography variant="body3" sx={{ mt: 0.8 }} component="div">
                      {formatDate(responseDetail?.startDate as string) || 'N/A'}
                      {' • '}
                      {responseDetail?.endDate
                        ? formatDate(responseDetail?.endDate as string)
                        : t('colleges.inprogress')}
                    </Typography>
                  </Box>
                </Box>
                {isMobile && (
                  <Grid container spacing={1} sx={{ mt: '0.7rem' }}>
                    <CollegeCardButtons
                      isCourseWorkActive={isCourseWorkActive}
                      courseLength={courseLength}
                      position={position}
                      responseDetail={responseDetail}
                      selector={selector}
                      openChildDrawer={openChildDrawer}
                      openGrandChildDrawer={openGrandChildDrawer}
                    />
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        );
      })}

      {!responseData?.length && (
        <Grid container sx={{ mt: 2 }} spacing={1} direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <Typography variant="subtitle7">{t('colleges.noResult')}</Typography>
          </Grid>
          <Grid item>
            <StyledContainedButton
              onClick={() => openChildDrawer?.(true, responseData?.length || 0, selector, t('colleges.add'))}
            >
              {t('colleges.addCollege')}
            </StyledContainedButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

interface IDegreeCard {
  degree: Idegree;
  degreeIndex: number;
  childPosition: number;
  selector: string;
  openGrandChildDrawer?: (
    isAddNew: boolean,
    childPosition: number,
    parentSelector: string,
    formTitle?: string,
    parentPosition?: number,
    sectionPrefix?: string
  ) => void;
}

export const DegreeCard = ({
  degree,
  degreeIndex,
  childPosition,
  selector,
  openGrandChildDrawer,
}: IDegreeCard): ReactElement => {
  const { t } = useTranslation();
  const firstMajor = degree?.majors?.[0]?.displayName;
  const secondMajor = degree?.majors?.[1]?.displayName;
  const firstMinor = degree?.minors?.[0]?.displayName;
  return (
    <Grid item xs={12} sm={12} key={`${degree?.startDate}-${degree?.type?.code}`}>
      <Card variant="outlined" sx={sxCard}>
        <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
          <Grid container spacing={0} sx={{ p: 1 }}>
            <Grid item xs={10} sm={10} md={11} lg={11} sx={{ mr: '0.5rem', display: 'flex' }}>
              <Box>
                <Icon sx={{ color: theme => theme.palette.grey[500], m: '0.3rem' }} aria-label="IconCollege">
                  IconGraduationCap
                </Icon>
              </Box>
              <Box>
                <Box>
                  <Typography variant="subtitle3" sx={{ overflowWrap: 'anywhere' }}>
                    {degree?.type?.displayName}
                  </Typography>
                </Box>
                <Box sx={{ mt: -0.5 }}>
                  <Typography variant="caption" sx={{ overflowWrap: 'anywhere' }}>
                    {degree?.degreeStatus.displayName}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={1.2} sm={1.2} md={0.7} sx={{ margin: 'auto', height: '60%' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                }}
              >
                <IconButton
                  aria-label={`IconEditCollegeDegree ${degree?.type?.displayName}`}
                  size="small"
                  onClick={() => {
                    openGrandChildDrawer?.(
                      false,
                      degreeIndex || 0,
                      selector,
                      t('colleges.editDegree'),
                      childPosition,
                      'degrees'
                    );
                    setTitle(t('colleges.editDegreesTitle'));
                  }}
                >
                  <IconEdit />
                </IconButton>
              </Box>
            </Grid>
          </Grid>

          <Divider
            sx={{
              borderStyle: 'solid',
              mb: '0.7rem',
              borderColor: theme => theme.palette.grey[500],
            }}
            flexItem
          />
          <Grid container spacing={2} sx={{ pl: 2, pr: 2, pb: secondMajor ? 1 : 2, pt: 1 }}>
            <Grid item xs={3}>
              <Typography variant="subtitle8" sx={{ textTransform: 'uppercase' }}>
                {t('colleges.major')}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography sx={{ overflowWrap: 'anywhere' }} variant="subtitle7">
                {firstMajor || t('colleges.major.notEntered')}
              </Typography>
            </Grid>
          </Grid>
          {secondMajor && (
            <Grid container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 0 }}>
              <Grid item xs={3}>
                <Typography variant="subtitle8" sx={{ textTransform: 'uppercase' }}>
                  {t('colleges.secondMajor')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography sx={{ overflowWrap: 'anywhere' }} variant="subtitle7">
                  {secondMajor}
                </Typography>
              </Grid>
            </Grid>
          )}
          {firstMinor && (
            <Grid container spacing={2} sx={{ pl: 2, pr: 2, pb: 2, pt: 0 }}>
              <Grid item xs={3}>
                <Typography variant="subtitle8" sx={{ textTransform: 'uppercase' }}>
                  {t('colleges.minor')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography sx={{ overflowWrap: 'anywhere' }} variant="subtitle7">
                  {firstMinor}
                </Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};
