/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { API } from 'constants/api';
import { t } from 'i18next';
import { TAppThunk } from 'redux/store';
import { isEmpty } from 'utils/utilities';
import {
  biographicalInfoStart,
  biographicalInfoSuccess,
  biographicalInfoFailure,
  TBiographicalInfoField,
  biographicalInfoRelationshipSuccess,
} from 'userProfile/store/biographicalInfo/biographicalInfo.slice';
import { getLoggedUser } from 'utils/keyCloakUtils';
import { setUi } from 'store/ui/ui.slice';
import axios from 'axios';

export const getBiographicalInformation = (): TAppThunk => async dispatch => {
  try {
    dispatch(biographicalInfoStart());
    const { data } = await axios.get(`${API?.profileInformation}${getLoggedUser()}/biographicInformation`);
    dispatch(biographicalInfoSuccess(data));
  } catch (err) {
    dispatch(biographicalInfoFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  }
};

export const postBiographicalInformation =
  (payload: TBiographicalInfoField, successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      const loggedUserId = getLoggedUser();
      dispatch(biographicalInfoStart());
      const modifiedPayload = {
        ...payload,
        profileId: loggedUserId,
      };
      const { data } = await axios.post(
        `${API?.profileInformation}${loggedUserId}/biographicInformation`,
        modifiedPayload
      );
      dispatch(biographicalInfoSuccess(data));
      dispatch(
        setUi({ name: 'succesSnackbar', state: { open: true, message: t('success_message'), hideTitle: true } })
      );
      successCallback?.();
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      dispatch(biographicalInfoFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const postBiographicalRelationshipInformation =
  (payload: TBiographicalInfoField, successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      const loggedUserId = getLoggedUser();
      dispatch(biographicalInfoStart());
      const modifiedPayload = {
        ...payload,
        profileId: loggedUserId,
      };
      const { data } = await axios.post(
        `${API?.profileInformation}${loggedUserId}/biographicInformation`,
        modifiedPayload
      );
      dispatch(biographicalInfoRelationshipSuccess(data));
      dispatch(
        setUi({ name: 'succesSnackbar', state: { open: true, message: t('success_message'), hideTitle: true } })
      );
      successCallback?.();
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      dispatch(biographicalInfoFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const modifyBooleanFields = (response: TBiographicalInfoField): TBiographicalInfoField => {
  const dualCitizenship = response?.citizenship?.dualCitizenship;
  let relationships = response?.relationships || [];
  if (!isEmpty(dualCitizenship)) {
    if (response.citizenship) {
      response.citizenship.dualCitizenship = dualCitizenship ? 'Yes' : 'No';
    }
  }
  relationships = relationships?.map(relation => {
    const copyRelation = {
      ...relation,
    };
    const living = copyRelation?.living;
    const inPrimaryHousehold = copyRelation?.inPrimaryHousehold;
    if (!isEmpty(living)) {
      copyRelation.living = living ? 'Yes' : 'No';
    }
    if (!isEmpty(inPrimaryHousehold)) {
      copyRelation.inPrimaryHousehold = inPrimaryHousehold ? 'Yes' : 'No';
    }
    return copyRelation;
  });

  return { ...response, relationships };
};
