/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Alert as MuiAlert, AlertProps, Button, IconButton, SvgIcon, Typography } from '@mui/material';
import { IconCancel } from '@liaison/liaison-ui';
import React, { ForwardedRef, forwardRef, isValidElement, ReactElement } from 'react';

interface IAlertProps extends Omit<AlertProps, 'action'> {
  actionPlacement?: 'vertical' | 'horizontal';
  actionText?: string;
  onAction?: () => void;
  noClose?: boolean;
}

const Alert = forwardRef(
  (
    {
      severity = 'success',
      actionPlacement = 'horizontal',
      onClose,
      children,
      actionText,
      onAction,
      noClose,
      closeText,
      ...rest
    }: IAlertProps,
    ref: ForwardedRef<HTMLDivElement>
  ): ReactElement => {
    const getActionButton = () => (
      <Button variant="text" color={severity} onClick={onAction}>
        {actionText}
      </Button>
    );

    const getCloseButton = () => (
      <IconButton size="extra-small" aria-label={closeText || 'close'} color={severity} onClick={onClose}>
        <SvgIcon>
          <IconCancel />
        </SvgIcon>
      </IconButton>
    );

    const getActions = () => (
      <>
        {actionText && actionPlacement === 'horizontal' && getActionButton()}
        {!noClose && getCloseButton()}
      </>
    );

    return (
      <MuiAlert {...rest} ref={ref} severity={severity} action={getActions()}>
        {isValidElement(children) ? (
          children
        ) : (
          <Typography component="div" variant="body3">
            {children}
          </Typography>
        )}
        {actionText && actionPlacement === 'vertical' && getActionButton()}
      </MuiAlert>
    );
  }
);

export default Alert;
