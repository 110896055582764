/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { profileViewMock, profileViewsMock } from 'mocks/profileMock';

type TError = string | null;

export type TProfileViews = typeof profileViewsMock;
export type TProfileView = typeof profileViewMock;
export type TAcademicInstituition = TProfileView['academicHistory']['schoolAttended']['data'][number];
export type TStandardizedTest = TProfileView['academicHistory']['standardizedTests']['data'][number];
export type TExperience = TProfileView['experiences']['data'][number];
export type TAchievement = TProfileView['achievements']['data'][number];
export type TSharedViews = TProfileView['sharedViews'];
export type TProfileViewSections =
  | 'personalInformation'
  | 'academicHistory'
  | 'experiences'
  | 'achievements'
  | 'languages'
  | 'competencies'
  | 'mediaAndDocuments';

export interface IProfileViewState {
  loading: boolean;
  updateLinkLoading: boolean;
  profileViews: TProfileViews | null;
  currentProfileViewId: string | null;
  profileViewData: TProfileView | null;
  profileViewSavedData: TProfileView | null;
  error: TError;
  updateLinkError: TError;
}

export const initialState: IProfileViewState = {
  loading: false,
  updateLinkLoading: false,
  profileViews: null,
  currentProfileViewId: null,
  profileViewData: null,
  profileViewSavedData: null,
  error: null,
  updateLinkError: null,
};

const profileViewSlice = createSlice({
  name: 'profileView',
  initialState,
  reducers: {
    profileViewStart: (state): IProfileViewState => ({
      ...state,
      loading: true,
      error: null,
    }),
    profileViewSuccess: (state, action: PayloadAction<TProfileView>): IProfileViewState => ({
      ...state,
      loading: false,
      profileViewData: action.payload,
    }),
    profileViewFailure: (state, action: PayloadAction<TError>): IProfileViewState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    setSavedProfileView: (state, action: PayloadAction<TProfileView>): IProfileViewState => ({
      ...state,
      profileViewSavedData: action.payload,
    }),
    allProfileViewsStart: (state): IProfileViewState => ({
      ...state,
      loading: true,
      error: null,
    }),
    allProfileViewsSuccess: (state, action: PayloadAction<TProfileViews>): IProfileViewState => ({
      ...state,
      loading: false,
      profileViews: action.payload,
    }),
    allProfileViewsFailure: (state, action: PayloadAction<TError>): IProfileViewState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    setCurrentProfileViewId: (state, action: PayloadAction<string>): IProfileViewState => ({
      ...state,
      currentProfileViewId: action.payload,
    }),
    updateLinkStart: (state): IProfileViewState => ({
      ...state,
      updateLinkLoading: true,
      updateLinkError: null,
    }),
    updateLinkSuccess: (state, action: PayloadAction<TSharedViews>): IProfileViewState => ({
      ...state,
      updateLinkLoading: false,
      profileViewData: { ...(state.profileViewData as TProfileView), sharedViews: action.payload },
    }),
    updateLinkFailure: (state, action: PayloadAction<TError>): IProfileViewState => ({
      ...state,
      updateLinkLoading: false,
      updateLinkError: action.payload,
    }),
  },
});

export const {
  profileViewStart,
  profileViewSuccess,
  profileViewFailure,
  setSavedProfileView,
  allProfileViewsStart,
  allProfileViewsSuccess,
  allProfileViewsFailure,
  setCurrentProfileViewId,
  updateLinkStart,
  updateLinkSuccess,
  updateLinkFailure,
} = profileViewSlice.actions;

export default profileViewSlice.reducer;
