/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

// TODO: temp solution! Should be in tenat-config.json
export const tpBgColor = 'rgba(47, 47, 47, 0.09)';

// below colors are not available in the color pallete
export const hoverAndFocusColor = '#005E86';
export const activeAndClickColor = '#003865';
export const footerColor = '#383D45';
