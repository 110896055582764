/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Theme } from '@mui/material';
import { styled } from '@mui/system';

export const sxButton = {
  bgcolor: '#E9F7FF',
  color: '#0076C0',
  height: 34,
  [`&:hover, &:focus, &:active`]: {
    bgcolor: '#E9F7FF',
  },
} as const;

export const sxFooterBox = {
  bgcolor: 'rgba(255, 255, 255, 0.5)',
  backdropFilter: 'blur(4px)',
  borderTop: '1px solid #CAD2E1',
  position: 'fixed',
  width: '100%',
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  p: 1.5,
} as const;

export const sxHeadingMenu = {
  fontWeight: 'bold',
  margin: 'auto',
  flexGrow: 1,
  fontSize: 24,
  color: (theme: Theme) => theme.palette.primary.contrastText,
} as const;

export const sxDrawerBox = {
  minHeight: '100%',
  bgcolor: (theme: Theme) => theme.palette.primary.main,
  color: 'white',
  borderTopLeftRadius: '1.5rem',
  borderTopRightRadius: '1.5rem',
} as const;

export const StyledNavBox = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'white',
  color: palette.text.primary,
  border: '1px solid rgba(47, 47, 47, 0.5)',
  borderRadius: '8px',
  marginTop: '1rem',
  padding: '1rem',
  fontWeight: 'bold',
  listStyle: 'none',
  cursor: 'pointer',
}));

export const StyledInfoBox = styled('div')(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  rowGap: '0.5rem',
  backgroundColor: palette.secondary.contrastText,
  color: palette.text.primary,
  border: '1px solid #642F6C',
  borderRadius: '8px',
  marginTop: '1rem',
  padding: '1rem',
}));
