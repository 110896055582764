/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';
import type { TTOEFLConfig } from 'store/standardTests/standardTests.slice';
import type { IStandardTestsState, TStandardTests, TStandardTestsStateKeys } from './standardTests.slice';

export const selectStandardTestsState = (state: TRootState): IStandardTestsState => state.standardTests;

export const selectStandardTests = createSelector(
  selectStandardTestsState,
  (standardTestsState: IStandardTestsState) => standardTestsState?.standardTests
);

export const selectTestTypes = createSelector(selectStandardTests, standardTests =>
  (standardTests as TStandardTests)?.tests?.map(({ type: { code, displayName } }) => ({
    id: code,
    text: displayName,
  }))
);

export const selectTest = createSelector(
  [selectStandardTests, (state, position) => position],
  (standardTests, position = 0) => {
    return (standardTests as TStandardTests)?.tests?.[position];
  }
);

export const selectTestConfig = createSelector(
  [selectStandardTestsState, (state, testType: TStandardTestsStateKeys) => testType],
  (standardTestsState, testType) => standardTestsState?.[testType]
);

export const selectCommonFieldsConfig = createSelector(
  selectStandardTestsState,
  (standardTestsState: IStandardTestsState) => standardTestsState?.COMMON_FIELDS
);

export const selectTestCategories = createSelector(
  selectCommonFieldsConfig,
  commonFieldsConfig =>
    commonFieldsConfig?.jsonSchema.properties.categories?.enum.map(({ code, displayName }) => ({
      id: code,
      text: displayName,
    })) || []
);

export const selectCategoryTestTypes = createSelector(
  [selectCommonFieldsConfig, (state, testCategoryCode) => testCategoryCode],
  (commonFieldsConfig, testCategoryCode) => {
    const testCategory = commonFieldsConfig?.jsonSchema.properties.categories?.enum.find(
      category => category.code === testCategoryCode
    );
    const filteredTypes = commonFieldsConfig?.jsonSchema.properties.type.enum
      .filter(type => type.categories.includes(testCategory?.code as string))
      .map(({ code, displayName }) => ({
        id: code,
        text: displayName,
      }));
    return filteredTypes;
  }
);

export const selectAllTestTypes = createSelector(selectCommonFieldsConfig, commonFieldsConfig =>
  commonFieldsConfig?.jsonSchema.properties.type?.enum.map(({ code, displayName }) => ({
    id: code,
    text: displayName,
  }))
);

export const selectIsTakenOptions = createSelector(selectCommonFieldsConfig, commonFieldsConfig =>
  commonFieldsConfig?.jsonSchema.properties.status?.enum.map(({ code, displayName }) => ({
    val: code,
    text: displayName,
  }))
);

export const selectTestExams = createSelector(
  [selectStandardTestsState, (state, testType: 'AP' | 'CLEP' | 'IB' | 'GRE_SUBJECT') => testType],
  (standardTestsState, testType) => {
    const testConfig = standardTestsState?.[testType];
    return testConfig?.jsonSchema.properties.classification?.enum.map(({ code, displayName }) => ({
      id: code,
      text: displayName,
    }));
  }
);

export const selectTestMethods = createSelector(
  [selectStandardTestsState, (state, testType: 'TOEFL') => testType],
  (standardTestsState, testType) => {
    const testConfig = standardTestsState?.[testType];
    return testConfig?.jsonSchema?.properties?.dependentSchemas[3]?.properties?.method?.enum.map(
      ({ code, displayName }) => ({
        id: code,
        text: displayName,
      })
    );
  }
);

export const selectTOEFLScores = createSelector(
  [state => selectTestConfig(state, 'TOEFL'), (_state, method: 'INTERNET_BASED' | 'PAPER_BASED') => method],
  (toeflTestConfig, method: 'INTERNET_BASED' | 'PAPER_BASED') => {
    const dependentSchemas = toeflTestConfig?.jsonSchema.properties.dependentSchemas as Array<
      TTOEFLConfig['jsonSchema']['properties']['dependentSchemas'][0 | 1]
    >;
    const scores =
      dependentSchemas?.find(
        schema =>
          (schema as TTOEFLConfig['jsonSchema']['properties']['dependentSchemas'][0 | 1]).criteria.method?.enum[0]
            .code === method
      )?.properties?.scores?.properties || {};
    return scores;
  }
);

export const selectScores = createSelector(
  [selectStandardTestsState, (state, testType: Exclude<TStandardTestsStateKeys, 'COMMON_FIELDS'>) => testType],
  (standardTestsState, testType) =>
    standardTestsState?.[testType]?.jsonSchema?.properties?.dependentSchemas?.[0]?.properties?.scores?.properties
);
