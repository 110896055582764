/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Grid, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { selectFollowedPrograms } from 'transferPlanner/store/programCart/programCart.selectors';
import { useSelector, useDispatch } from 'react-redux';
import { nameSpace } from 'transferPlanner/constants/general';
import debounce from 'lodash.debounce';
import {
  selectNavigationLetter,
  selectSearchQuery,
  selectCampusIds,
  selectIsEligible,
  selectScrollLetter,
} from 'transferPlanner/store/searchProgram/searchProgram.selectors';
import { IProgram, setScrollLetter } from 'transferPlanner/store/searchProgram/searchProgram.slice';
import { useAppSelector } from 'redux/hooks';
import { getFollowedPrograms } from 'transferPlanner/components/MyProgramsCart/MyProgramsCart.utils';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeGrid } from 'react-window';
import { ProgramSearchHeader } from './ProgramSearchHeader';
import { LetterNavigation } from './LetterNavigation';
import { ProgramCardsLazyScroll } from './ProgramCardsLazyScroll';
import { fetchPrograms, TCountsByFirstCharacter } from './ProgramCardsLazyScroll/ProgramCardsLazyScroll.utils';
import {
  buildFoundLettersArray,
  compareLetters,
  findAlphabetValue,
  getLastAlphabet,
  sumOfLetterValuesFromIndexToEnd,
} from './ProgramsSearchView.utils';

export const ProgramsSearchView = (): ReactElement => {
  const isMobileOrSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { t } = useTranslation(nameSpace);

  const [programs, setPrograms] = useState<IProgram[] | []>([]);
  const [isNextPageAvailable, setIsNextPageAvailable] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [firstCharacterCounts, setFirstCharacterCounts] = useState<TCountsByFirstCharacter | []>([]);
  const [columnCount, setColumnCount] = useState(1);
  const [firstLoading, setFirstLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(0);
  const [isResizingScreeen, setIsResizingScreeen] = useState(false);
  const [width, setwidth] = useState<number>(isMobileOrSmall ? window.innerWidth : 1200);

  const gridRef = useRef<FixedSizeGrid>(null);
  const infiniteLoaderRef = useRef(null);
  const hasMountedRef = useRef(false);
  const disableLetterScrolllRef = useRef(false);
  const scrollToRef = useRef<number>(0);
  const sameLetterRef = useRef(false);

  const dispatch = useDispatch();
  const followedPrograms = useSelector(selectFollowedPrograms);
  const searchQuery = useAppSelector(selectSearchQuery);
  const currentNavigationLetter = useAppSelector(selectNavigationLetter) || 'A';
  const campusIds = useAppSelector(selectCampusIds);
  const isEligible = useAppSelector(selectIsEligible);
  const scrollLetter = useAppSelector(selectScrollLetter);

  const foundLetters = useMemo(() => buildFoundLettersArray(firstCharacterCounts), [firstCharacterCounts]);
  const firstLetterParam = useMemo(() => `&firstLetter=${currentNavigationLetter}`, [currentNavigationLetter]);
  const searchParam = useMemo(() => (searchQuery ? `&searchTerm=${searchQuery}` : ''), [searchQuery]);
  const campusParam = useMemo(() => (campusIds?.length ? `&campus=${campusIds.join('&campus=')}` : ''), [campusIds]);
  const eligibleParam = useMemo(() => (isEligible ? `&eligible=${isEligible}` : ''), [isEligible]);

  // Bunch of javascript hacks are used to support reverse back scrolling.
  // So writing test cases are not possible, So adding "istanbul ignore next" comment to remove it from test coverage.
  // Check tp-597 or this link https://github.com/Liaison-Intl/LP-Student/pull/1543#discussion_r1533985631
  /* istanbul ignore next */
  const rowCount = useMemo(
    () =>
      loading
        ? programs?.length + 1
        : sumOfLetterValuesFromIndexToEnd(currentNavigationLetter, firstCharacterCounts, totalCount, foundLetters) +
            (programs as IProgram[])?.reduce((count: number) => {
              return count + 1;
            }, 0) || 0,
    [currentNavigationLetter, firstCharacterCounts, foundLetters, loading, programs, totalCount]
  );

  // below function calculate the number of data we want to fetch
  /* istanbul ignore next */
  const size = useMemo(() => {
    if (isMobileOrSmall) {
      return 30;
    }
    if (columnCount >= 4) {
      return 80;
    }
    return 60;
  }, [columnCount, isMobileOrSmall]);

  const isManualClick = currentNavigationLetter !== (foundLetters[0] || 'A') && firstCharacterCounts.length;

  useEffect(() => {
    if (!followedPrograms) {
      dispatch(getFollowedPrograms());
    }
  }, [followedPrograms, dispatch]);

  // below useEffect function calculate the numbers of card to display
  /* istanbul ignore next */
  useEffect(() => {
    if (width >= 1600) {
      setColumnCount(4);
    } else if (width >= 1024) {
      setColumnCount(3);
    } else if (width >= 768) {
      setColumnCount(2);
    } else setColumnCount(1);
  }, [width]);

  // below useEffect function calculate the screen size when user resize the screen
  /* istanbul ignore next */
  useEffect(() => {
    const containerRef = document.getElementById('program-card-lazy-scroll-container');

    const getWidth = () => {
      if (containerRef) {
        setwidth(containerRef.clientWidth);
      }
    };

    getWidth();
    const handleResize = () => {
      getWidth();
      setIsResizingScreeen(true);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // below useEffect function call the api first time
  /* istanbul ignore next */
  useEffect(() => {
    (async () => {
      if (hasMountedRef.current) {
        if (infiniteLoaderRef.current) {
          (infiniteLoaderRef.current as typeof InfiniteLoader['prototype']).resetloadMoreItemsCache();
        }
      }
      hasMountedRef.current = true;
      setFirstLoading(true);
      const sizeCount = isManualClick
        ? findAlphabetValue(firstCharacterCounts, getLastAlphabet(undefined, currentNavigationLetter, foundLetters))
        : size;
      const firstLetterReqPar = isManualClick
        ? `&firstLetter=${getLastAlphabet(undefined, currentNavigationLetter, foundLetters)}`
        : firstLetterParam;
      try {
        const {
          content = [],
          countsByFirstCharacter,
          hasNextPage,
          total,
        } = await fetchPrograms(
          `?size=${sizeCount}${firstLetterReqPar}${searchParam}${campusParam}${eligibleParam}&includeCounts=true`
        );
        if (isMobileOrSmall) {
          setPrograms(content);
        } else {
          const data = [...content];
          compareLetters(data, columnCount);
          setPrograms(data);
        }
        setIsNextPageAvailable(hasNextPage);
        setTotalCount(total);
        setFirstCharacterCounts(countsByFirstCharacter);

        setFirstLoading(false);
        setPageSize(isManualClick ? 0 : 1);
        if (isResizingScreeen) setIsResizingScreeen(false);
      } catch (error) {
        setIsNextPageAvailable(false);
        setFirstLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [
    campusIds,
    campusParam,
    currentNavigationLetter,
    eligibleParam,
    firstLetterParam,
    isEligible,
    isMobileOrSmall,
    isResizingScreeen,
    searchParam,
    searchQuery,
    size,
  ]);

  // below function call the api when user scroll
  /* istanbul ignore next */
  const loadMoreItems = debounce(async () => {
    if (isNextPageAvailable && !loading) {
      setLoading(true);
      const pageReqParam = pageSize ? `page=${pageSize}&` : '';
      try {
        const { content, hasNextPage } = await fetchPrograms(
          `?${pageReqParam}size=${size}${firstLetterParam}${searchParam}${campusParam}${eligibleParam}`
        );
        setIsNextPageAvailable(hasNextPage);
        setPrograms(() => {
          const updatedprograms: IProgram[] = [...programs, ...content];
          if (isMobileOrSmall) {
            return updatedprograms;
          }
          compareLetters(updatedprograms, columnCount);
          return updatedprograms;
        });

        setPageSize(prevPageSize => prevPageSize + 1);
        setLoading(false);
      } catch (error) {
        setIsNextPageAvailable(false);
        setLoading(false);
      }
    }
  }, 0);

  /* istanbul ignore next */
  useEffect(() => {
    if (firstCharacterCounts?.length && foundLetters?.length && (searchQuery || searchParam)) {
      dispatch(setScrollLetter(foundLetters[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstCharacterCounts, searchQuery, searchParam]);

  // below function set the letter when user scroll
  /* istanbul ignore next */
  const setScrollingLetter = useCallback(
    (direction = 'backward'): void => {
      foundLetters.forEach(alpha => {
        const currentAlphabet = document.getElementById(`starting-alphabet-${alpha}`);
        if (currentAlphabet) {
          const observer = new IntersectionObserver(
            entries => {
              entries.forEach(entry => {
                if (entry.isIntersecting) {
                  if (direction === 'forward') {
                    if (scrollLetter !== alpha) dispatch(setScrollLetter(alpha));
                  } else if (
                    direction === 'backward' &&
                    alpha !== foundLetters[0] &&
                    currentNavigationLetter !== foundLetters[0] &&
                    !sameLetterRef.current
                  ) {
                    const previousLetter = getLastAlphabet(undefined, alpha, foundLetters);
                    if (previousLetter && scrollLetter !== previousLetter) {
                      dispatch(setScrollLetter(previousLetter));
                    }
                  }
                }
              });
            },
            { rootMargin: '0px' }
          );
          observer.observe(currentAlphabet);
          return () => observer.unobserve(currentAlphabet);
        }
        return () => {};
      });
    },
    [currentNavigationLetter, dispatch, foundLetters, scrollLetter]
  );

  // below useEffect code contains reverse back scroll code
  /* istanbul ignore next */
  useEffect(() => {
    const lazyScrollElement = document.getElementById('program-card-lazy-scroll-container');

    if (lazyScrollElement && foundLetters?.length) {
      // below function code contains reverse back scroll main logic
      const setLetterManually = debounce(() => {
        const isActiveClass = lazyScrollElement?.classList.contains('active-scroll-offset');
        if (!disableLetterScrolllRef.current && isActiveClass) {
          foundLetters.forEach(alpha => {
            const element = document.getElementById(`letter-navigation-${alpha}`);
            if (element && alpha !== foundLetters[0]) {
              const previewsAlphabet = getLastAlphabet(`starting-alphabet-${alpha}`, undefined, foundLetters);
              if (previewsAlphabet) {
                const previewsElement = document.querySelector(`.letter-navigation-${previewsAlphabet}`);
                if (previewsElement) {
                  disableLetterScrolllRef.current = true;
                  (previewsElement as HTMLDivElement)?.click();
                  if (disableLetterScrolllRef.current) {
                    setTimeout(() => {
                      disableLetterScrolllRef.current = false;
                    }, 5000);
                  }
                }
              }
            }
          });
        }
      }, 200);

      // below function code contains reverse back scroll for mobile
      const handleTouchMove = debounce((event: TouchEvent) => {
        const touchY = event.touches[0]?.clientY;
        const startY = lazyScrollElement?.getBoundingClientRect().top || 0;
        if (touchY !== undefined && startY !== undefined) {
          const scrollDirection = touchY > startY ? 'backward' : 'forward';
          if (scrollDirection === 'backward' && currentNavigationLetter !== foundLetters[0]) {
            setLetterManually();
          }
        }
      }, 700);

      // below function code contains reverse back scroll for desktop
      const handleWheel = (event: { deltaY: number }) => {
        const scrollDirection = event.deltaY > 0 ? 'forward' : 'backward';
        if (scrollDirection === 'backward') {
          setLetterManually();
        } else if (scrollDirection === 'forward') {
          setScrollingLetter('forward');
        }
      };

      lazyScrollElement.addEventListener('touchmove', handleTouchMove);
      lazyScrollElement.addEventListener('wheel', handleWheel);

      return () => {
        lazyScrollElement.removeEventListener('touchmove', handleTouchMove);
        lazyScrollElement.removeEventListener('wheel', handleWheel);
      };
    }

    return () => {};
  }, [currentNavigationLetter, foundLetters, isMobileOrSmall, scrollLetter, setScrollingLetter]);

  // below useEffect function contains scroll user to end of programs list
  /* istanbul ignore next */
  useEffect(() => {
    if (
      gridRef.current &&
      currentNavigationLetter !== foundLetters[0] &&
      programs?.length &&
      !firstLoading &&
      !searchParam &&
      !searchQuery
    ) {
      const timeout = setTimeout(() => {
        if (gridRef.current) {
          const rowIndex = programs.length / columnCount;
          gridRef.current?.scrollToItem({
            rowIndex,
          });
          scrollToRef.current = rowIndex;
        }
      }, 0);
      return () => clearTimeout(timeout);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoading]);

  // below useEffect function contains scroll user to specific index
  /* istanbul ignore next */
  useEffect(() => {
    if (
      gridRef.current &&
      currentNavigationLetter !== foundLetters[0] &&
      programs?.length &&
      scrollToRef.current &&
      !searchParam &&
      !searchQuery
    ) {
      const timeout = setTimeout(() => {
        if (gridRef.current) {
          gridRef.current?.scrollToItem({
            rowIndex:
              scrollToRef.current +
              ((!isMobileOrSmall && (window.innerHeight <= 640 || window.innerHeight >= 550)) || isMobileOrSmall
                ? 1
                : 3),
          });
          scrollToRef.current = 0;
        }
      }, 0);
      return () => clearTimeout(timeout);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRef, scrollToRef, programs]);

  // below function contains scroll position code
  /* istanbul ignore next */
  const handleScroll = useCallback(
    ({ verticalScrollDirection, scrollTop }: { verticalScrollDirection: string; scrollTop: number }) => {
      const container = document.getElementById('program-card-lazy-scroll-container');
      if (scrollTop === 0) {
        container?.classList.add('active-scroll-offset');
      } else if (scrollTop > 0) {
        if (scrollTop <= 25 && !sameLetterRef.current) {
          sameLetterRef.current = true;
        } else if (scrollTop > 25 && sameLetterRef.current) {
          sameLetterRef.current = false;
        }
        setScrollingLetter(verticalScrollDirection);
        container?.classList.remove('active-scroll-offset');
      }
    },
    [setScrollingLetter]
  );

  return (
    <Box
      sx={{
        p: '1.5rem 1.5rem 1rem',
        bgcolor: '#fff',
        pt: { xs: '3.5rem', sm: '.3rem', md: '1.5rem' },
        px: { xs: '0.6rem', sm: '1rem', md: '1.5rem', lg: '1.5rem' },
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={11.3} sm={12}>
          <ProgramSearchHeader total={totalCount} />
          <Stack direction="column" sx={{ mt: { xs: 1.5 } }} spacing={{ sm: 2, md: 2, lg: 3, xl: 4 }}>
            {!isMobileOrSmall && <LetterNavigation foundLetters={foundLetters} />}
            {!programs?.length && !firstLoading && (
              <Box
                sx={{
                  height: 'calc(100vh - 265px)',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="h5"
                  component="p"
                  role="alert"
                  aria-live="assertive"
                  textAlign="center"
                  sx={{ color: '#75757D' }}
                >
                  {t('programs.page.notFound')}
                </Typography>
              </Box>
            )}
            <ProgramCardsLazyScroll
              loadMoreItems={loadMoreItems}
              programs={programs}
              columnCount={columnCount}
              rowCount={rowCount}
              width={width}
              infiniteLoaderRef={infiniteLoaderRef}
              gridRef={gridRef}
              firstLoading={firstLoading}
              loading={loading}
              isNextPageAvailable={isNextPageAvailable}
              firstCharacterCounts={firstCharacterCounts}
              handleScroll={handleScroll}
            />
          </Stack>
        </Grid>
        {isMobileOrSmall && (
          <Grid item container xs={0.7}>
            <LetterNavigation foundLetters={foundLetters} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
