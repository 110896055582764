/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Box, Button, Divider, Typography, Link } from '@mui/material';
import React, { MouseEvent, ReactElement, useEffect } from 'react';

import { IconLink, IconLocation } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';

import { nameSpace } from 'transferPlanner/constants/general';
import { EViewType } from '../ToggleView';
import { infoWindowStyle, sxButton, sxBoxLocationLink, sxIcon } from '../CampusesAndPrograms.styles';
import { getAddressString, ICampus } from '../CampusesAndPrograms.utils';

interface IInfoWindow {
  campus: ICampus;
  setType: (type: EViewType) => void;
  focusedItemIndex?: number | undefined;
}
const InfoWindow = ({ campus, setType, focusedItemIndex }: IInfoWindow): ReactElement => {
  const { t } = useTranslation(nameSpace);

  const onViewProgramsClick = () => {
    setType(EViewType.program);
  };

  useEffect(() => {
    if (focusedItemIndex) {
      const element: HTMLElement | null = document.getElementById('information-window');
      /* istanbul ignore next */
      if (element) {
        element.focus();
      }
    }
  }, [focusedItemIndex]);

  return (
    <Box
      component="div"
      sx={infoWindowStyle}
      onClick={(e: MouseEvent) => e.stopPropagation()}
      id="information-window"
      tabIndex={0}
      data-testid="information-window"
    >
      <Typography variant="h6" aria-level={2} tabIndex={0}>
        {campus.name}
      </Typography>
      <Divider sx={{ borderStyle: 'solid', my: '0.5rem' }} />
      <Box component="div" tabIndex={0}>
        <img src={campus.branding[0]?.configValue} alt={`${campus.name} logo`} style={{ width: '100%' }} />
      </Box>
      <Box sx={{ ...sxBoxLocationLink, pt: '1rem' }}>
        <IconLocation sx={sxIcon} />
        <Typography variant="subtitle4" sx={{ pl: '0.5rem' }}>
          {getAddressString(campus.contact)}
        </Typography>
      </Box>
      <Box sx={{ ...sxBoxLocationLink }}>
        <IconLink sx={sxIcon} />
        <Link variant="body2" href={campus.contact.website} target="_blank" underline="none">
          <Typography variant="subtitle4" sx={{ pl: '0.5rem' }} color="text.primary">
            {campus.contact.website}
          </Typography>
        </Link>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          onClick={onViewProgramsClick}
          sx={sxButton}
          id="map_information-card-view_programs_label"
        >
          {t('view_programs_label')}
        </Button>
      </Box>
    </Box>
  );
};

export default InfoWindow;
