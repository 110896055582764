/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { TAppThunk } from 'redux/store';
import {
  programsStart,
  programsFailure,
  programsSuccess,
  fetchAgreementStart,
  fetchAgreementSuccess,
  fetchAgreementFailure,
} from 'transferPlanner/store/programs/programs.slice';
import { campusesStart, campusesFailure, campusesSuccess } from 'transferPlanner/store/campuses/campuses.slice';
import { t } from 'i18next';
import { API } from 'transferPlanner/constants/api';
import { ASSIST_URL } from 'constants/api';
import axios from 'axios';

export interface ICampusContact {
  address1?: string;
  phones?: {
    phone: string;
  }[];
  city: string;
  postalCode: string;
  website?: string;
  email?: string;
  latitude?: number;
  longitude?: number;
  county?: {
    code: string;
    displayName: string;
  };
  region: {
    code: string;
    displayName: string;
  };
  country: {
    code: string;
    displayName: string;
  };
}
export interface ICampus {
  id: number;
  alternateId: number;
  name: string;
  contact: ICampusContact;
  branding: {
    configName: string;
    configValue: string;
  }[];
  show?: boolean;
  checked?: boolean;
}
export interface ICoordinates {
  lat: number;
  lng: number;
}

export const getCampuses = (): TAppThunk => async dispatch => {
  try {
    dispatch(campusesStart());
    const { data } = await axios.get(API.getCampuses);
    dispatch(campusesSuccess(data));
  } catch (err) {
    dispatch(campusesFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  }
};

export const getAddressString = (address: ICampusContact): string => {
  let addressString = '';

  if (address?.city) {
    addressString = address.city;
  }
  if (address?.region?.displayName) {
    addressString += addressString ? `, ${address?.region?.code}` : address?.region?.code;
  }
  if (address?.postalCode) {
    addressString += addressString ? ` ${address?.postalCode}` : address?.postalCode;
  }
  return addressString;
};

export const getProgramsByCampus =
  (campusId: number): TAppThunk =>
  async dispatch => {
    try {
      dispatch(programsStart());
      const { data } = await axios.get(`${API.getCampuses}/${campusId}/programs`);
      dispatch(programsSuccess(data));
    } catch (err) {
      dispatch(programsFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export interface IPrograms {
  id: number;
  name: string;
  programCode: string;
  tspEligible: boolean;
  lastUpdatedTimestamp: string;
  degreeType: string;
  enrollCapacity: number;
  minGPA: number;
  majorPreReqGPA: number;
  minGrade: {
    code: string;
    displayName: string;
  };
  transferableUnits: number;
  postalCode: string;
  moKey?: string;
  format: string;
  status: string;
  requirements: string;
}

export const getProgramAgreement =
  (programId: number, campusAlternateId: number): TAppThunk =>
  async dispatch => {
    try {
      dispatch(fetchAgreementStart());
      const { data } = await axios.get(`${ASSIST_URL}/programs/${programId}/colleges/${campusAlternateId}/agreement`);
      dispatch(fetchAgreementSuccess(data));
    } catch (err) {
      dispatch(fetchAgreementFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };
