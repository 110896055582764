/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import * as yup from 'yup';
import { t } from 'i18next';
import { MAX_HUGE_LENGTH_FIELD } from 'constants/field';
import { TEXT_REGEX } from 'constants/regex';
import { TProfileView } from 'userProfile/store/profileView/profileView.slice';

export const defaultValues = {
  name: '',
  description: '',
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getValidationSchema = (allProfileViews: TProfileView[]) =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(t('mediaDocuments.fileUpload.error.name'))
      .matches(RegExp(TEXT_REGEX), t('error.notAllowed'))
      .test('unique', t('error.duplicateName'), function uniqueTest(value) {
        const { path, createError } = this;
        const isDuplicate = allProfileViews?.some(view => view.name === value);
        return isDuplicate ? createError({ path, message: t('livingProfile.duplicate.viewName') }) : true;
      }),
    description: yup.string().max(MAX_HUGE_LENGTH_FIELD).matches(RegExp(TEXT_REGEX), t('error.notAllowed')),
  });
