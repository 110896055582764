/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, Stack, Typography, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Icon, IconCaretRight } from '@liaison/liaison-ui';
import { useNavigate } from 'react-router-dom';
import { selectCampuses } from 'transferPlanner/store/campuses/campuses.selectors';
import { ReactComponent as CheckCircleGreenIcon } from 'assets/svgs/check-circle-green-icon.svg';
import { useAppSelector } from 'redux/hooks';
import { CAMPUS_EVENT_LINK, nameSpace } from 'transferPlanner/constants/general';
import {
  selectTspEligibility,
  selectQuestionnaireConfig,
} from 'transferPlanner/store/eligibility/eligibility.selectors';
import {
  selectActiveTspEnrollment,
  selectTspEnrollment,
  selectTspEnrollmentPeriod,
} from 'transferPlanner/store/enrollment/enrollment.selectors';
import { InfoCard } from 'transferPlanner/components/InfoCard';
import {
  ICampus,
  ICampusContact,
  getCampuses,
} from 'transferPlanner/pages/CampusesAndPrograms/CampusesAndPrograms.utils';
import { ITspEnrollment } from 'transferPlanner/store/enrollment/enrollment.slice';
import { ITspEligibility } from 'transferPlanner/store/eligibility/eligibility.slice';
import tpMarketing from 'assets/tp_marketing_tile.png';
import { selectFeatures } from 'store/features/features.selectors';
import { activeAndClickColor, hoverAndFocusColor } from 'transferPlanner/constants/styles';
import { InstructionView } from './InstructionView';
import {
  IStatusConfig,
  getStatusConfig,
  isNotEligibleToEnroll,
  isTspEnrolled,
  isTspEnrollmentPending,
  activeEligibility,
} from './MyStatus.utils';
import { StyledBoxWithImage, StyledBox } from './MyStatus.styles';

export const MyStatus = (): ReactElement => {
  const dispatch = useDispatch();
  const campuses = useAppSelector(selectCampuses);
  const [statusConfig, setStatusConfig] = useState<IStatusConfig>({
    title: '',
    titleColor: '',
    instruction: '',
    buttonText: '',
    buttonActionLink: '',
  });
  const [statusTitleIcon, setStatusTitleIcon] = useState(<></>);
  const [campusContact, setCampusContact] = useState({ email: '', link: '' });
  const [showInfoCard, setShowInfoCard] = useState(false);
  const [currentEligibility, setCurrentEligibility] = useState<ITspEligibility | null>(null);
  const navigate = useNavigate();
  const tspEligibility = useAppSelector(selectTspEligibility) as ITspEligibility[];
  const activeTspEnrollment = useAppSelector(selectActiveTspEnrollment);
  const tspEnrollment = useAppSelector(selectTspEnrollment) as ITspEnrollment[];
  const isTspEnrollmentOpen = useAppSelector(selectTspEnrollmentPeriod)?.enrollmentOpen || false;
  const questionnaireConfig = useAppSelector(selectQuestionnaireConfig);

  const { t } = useTranslation(nameSpace);
  const features = useAppSelector(selectFeatures);

  useEffect(() => {
    setCurrentEligibility(activeEligibility(tspEligibility, features, questionnaireConfig?.version));
  }, [tspEligibility, features, questionnaireConfig]);

  useEffect(() => {
    if (!campuses) {
      dispatch(getCampuses());
    }
  }, [dispatch, campuses]);

  useEffect(() => {
    const isQuestionnaire = !!currentEligibility;
    const isEnrolled = isTspEnrolled(tspEnrollment);
    const isNotEligible = isNotEligibleToEnroll(currentEligibility ? [currentEligibility] : [], tspEnrollment);
    const isEnrolledPending = isTspEnrollmentPending(tspEnrollment);

    setStatusConfig(
      getStatusConfig({
        isQuestionnaire,
        isNotEligible,
        isEnrolled,
        isEnrolledPending,
        isTspEnrollmentOpen,
      })
    );

    setStatusTitleIcon(() => {
      if (isEnrolledPending || (isQuestionnaire && !isEnrolled) || (!isQuestionnaire && !isEnrolled)) {
        return (
          <Icon data-testid="iconAlertTriangleColor" style={{ fontSize: 48 }}>
            IconAlertTriangleColor
          </Icon>
        );
      }

      if (isNotEligible) {
        return (
          <Icon data-testid="iconAlertTriangle" style={{ fontSize: 48, color: '#CC0B2A' }}>
            IconAlertTriangle
          </Icon>
        );
      }
      return (
        <Box>
          <CheckCircleGreenIcon data-testid="iconEnrolled" aria-label="icon enrolled" />
        </Box>
      );
    });

    setShowInfoCard(isEnrolled && !isNotEligible && !isEnrolledPending && !!activeTspEnrollment);
  }, [tspEnrollment, currentEligibility, setStatusConfig, activeTspEnrollment, isTspEnrollmentOpen]);

  useEffect(() => {
    if (campuses && activeTspEnrollment) {
      const enrollmentCampusId = activeTspEnrollment?.campus?.id;
      /* istanbul ignore next */
      const selectedCampus = campuses.find(campus => campus.id === enrollmentCampusId) || ({} as ICampus);
      const { website, email } = selectedCampus?.contact as ICampusContact;
      setCampusContact({ email: email as string, link: website as string });
    }
  }, [activeTspEnrollment, campuses]);

  return (
    <>
      {statusConfig.enableCSUTile ? (
        <>
          <StyledBoxWithImage background={`url(${tpMarketing})`} sx={{ p: '1.5rem' }}>
            <StyledBox>
              <Typography variant="subtitle7">{t('dashboard.statusCSUMarketing')}</Typography>
              <IconButton
                aria-label={t('view_csu_campus_event_aria_label')}
                size="small"
                sx={{
                  color: 'secondary.contrastText',
                  bgcolor: 'secondary.main',
                  '&:hover': { bgcolor: hoverAndFocusColor },
                  '&:focus': {
                    border: `2px solid ${hoverAndFocusColor}`,
                  },
                  '&:active': { bgcolor: activeAndClickColor },
                }}
                onClick={() => {
                  window.open(CAMPUS_EVENT_LINK, '_blank', 'noopener,noreferrer');
                }}
                disableRipple
              >
                <IconCaretRight />
              </IconButton>
            </StyledBox>
          </StyledBoxWithImage>
        </>
      ) : (
        <Card>
          <CardContent sx={{ height: 'calc( 100% - 50px )' }}>
            <Typography variant="subtitle1" component="div">
              {t('dashboard.statusHeader')}
            </Typography>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={3} sx={{ my: '1rem' }}>
              {statusTitleIcon}
              <Typography variant="subtitle1" component="div" sx={{ mt: 1.5, color: statusConfig.titleColor }}>
                {t(statusConfig.title)}
              </Typography>
            </Stack>
            {showInfoCard ? (
              <InfoCard
                showDetails
                heading={activeTspEnrollment?.campus.name || ''}
                subHeading={activeTspEnrollment?.program.name || ''}
                details={campusContact}
              />
            ) : (
              <InstructionView instruction={statusConfig.instruction} />
            )}
          </CardContent>
          <CardActions>
            <Button
              sx={{ height: 32, borderRadius: 24 }}
              type="submit"
              fullWidth
              variant="contained"
              onClick={() => {
                if (activeTspEnrollment?.program.id && statusConfig.buttonText === 'view_full_details') {
                  navigate(`/${statusConfig.buttonActionLink}/${activeTspEnrollment?.program.id.toString()}`);
                } else navigate(`/${statusConfig.buttonActionLink}`);
              }}
            >
              {t(statusConfig.buttonText).toUpperCase()}
            </Button>
          </CardActions>
        </Card>
      )}
    </>
  );
};
