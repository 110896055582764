/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { IProgram } from 'transferPlanner/store/searchProgram/searchProgram.slice';
import {
  TCountsByFirstCharacter,
  TFetchProgramDetailsResponse,
} from './ProgramCardsLazyScroll/ProgramCardsLazyScroll.utils';
import { ISelectedProgram } from '../../../store/programCart/programCart.slice';

export const buildFoundLettersArray = (countsByFirstCharacter: TCountsByFirstCharacter = []): string[] =>
  countsByFirstCharacter.map(el => el[0] || 'A');

export const convertDetailedToIProgram = (program: TFetchProgramDetailsResponse): IProgram => {
  return {
    id: program.id,
    name: program.name,
    campusId: program.campus.id,
    campusAlternateId: program.campus.alternateId,
    campusName: program.campus.name,
    moKey: program?.moKey,
  };
};

export const getSelectedPayload = (
  selectedPrograms: ISelectedProgram[] = [],
  program: IProgram
): ISelectedProgram[] => {
  const newSelectedProgram: ISelectedProgram = {
    program: {
      id: program.id,
      name: program.name,
      moKey: program?.moKey,
    },
    campus: {
      id: program.campusId,
      name: program.campusName,
      alternateId: {
        code: program.campusAlternateId,
        source: 'Assist',
      },
    },
  };

  return [newSelectedProgram, ...selectedPrograms];
};

export const getLastAlphabet = (
  elementId: string | undefined,
  alphabet: string | undefined,
  foundLetters: string[]
): string | undefined => {
  if (elementId && foundLetters?.length) {
    const alphabetId = elementId.split('-')[2];
    const currentIndex = foundLetters.indexOf(alphabetId);
    const lastIndex = Math.max(currentIndex - 1, 0);
    return foundLetters[lastIndex];
  }
  if (alphabet && foundLetters?.length) {
    const currentIndex = foundLetters.indexOf(alphabet);
    const lastIndex = Math.max(currentIndex - 1, 0);
    return foundLetters[lastIndex];
  }
  return undefined;
};

export const findAlphabetValue = (
  alphabetPair: TCountsByFirstCharacter | [],
  targetAlphabet: string | undefined
): number | null => {
  const resultPair = alphabetPair.find(pair => pair[0] === targetAlphabet);
  return resultPair ? resultPair[1] : null;
};

const getNonNullData = (updatedprograms: IProgram[], index: number): string => {
  if (updatedprograms[index] !== null) return updatedprograms[index]?.name.charAt(0);
  return getNonNullData(updatedprograms, index - 1);
};

export const compareLetters = (updatedprograms: IProgram[], columnCount: number): void => {
  for (let index = 0; index < updatedprograms.length; index += 1) {
    const previousLetter = getNonNullData(updatedprograms, index - 1);
    const currentLetter = updatedprograms[index]?.name?.charAt(0);
    if (
      index !== 0 &&
      previousLetter &&
      currentLetter &&
      previousLetter !== currentLetter &&
      index % columnCount !== 0
    ) {
      updatedprograms.splice(index, 0, null as unknown as IProgram);
    }
  }
};

export const sumOfLetterValuesFromIndexToEnd = (
  alphabet: string,
  data: TCountsByFirstCharacter | [],
  totalCount: number,
  foundLetters: string[]
): number => {
  if (data?.length) {
    const currentAlphabet = alphabet === 'Z' ? getLastAlphabet(undefined, alphabet, foundLetters) : alphabet;
    const startIndex = data.findIndex(pair => pair[0] === currentAlphabet);
    if (startIndex === -1 || alphabet === 'A') {
      /* istanbul ignore next */
      return totalCount;
    }

    let sum = 0;
    for (let i = startIndex; i < data.length; i += 1) {
      sum += data[i][1];
    }
    return sum;
  }
  return totalCount;
};
