/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { styled } from '@mui/system';

const PREFIX = 'userNav';

export const classes = {
  button: `${PREFIX}-root`,
  buttonActive: `${PREFIX}-buttonActive`,
};

export const StyledNavButton = styled('button')(({ theme: { palette } }) => ({
  [`&.${classes.button}`]: {
    height: '4.5rem',
    display: 'flex',
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    color: palette.text.primary,
    border: '1px solid rgba(47, 47, 47, 0.5)',
    borderRadius: '8px',
    margin: '8px 0',
    padding: '10px 14px',
    fontWeight: 'bold',
    listStyle: 'none',
    cursor: 'pointer',
  },
  [`&.${classes.buttonActive}`]: {
    backgroundColor: '#642F6C',
    borderRadius: '8px',
  },
}));

export const StyledInfoBox = styled('div')(({ theme: { palette } }) => ({
  flexDirection: 'column',
  alignItems: 'flex-start',
  backgroundColor: 'white',
  color: palette.text.primary,
  border: '1px solid #642F6C',
  borderRadius: '8px',
  margin: '8px 0',
  padding: '10px 22px 10px 16px',
  fontWeight: 'bold',
}));
