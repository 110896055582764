/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectCompetencies } from 'userProfile/store/profileView/profileView.selectors';
import { SimpleSection } from '../Common';

export const CompetenciesSkills = (): ReactElement => {
  const competenciesSkills = useSelector(selectCompetencies);
  const { t } = useTranslation();
  const title = t('livingProfile.sections.competenciesSkills');

  return <SimpleSection sectionTitle={title} pills={competenciesSkills} />;
};
