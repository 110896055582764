/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Table, TableCell, TableContainer, TableHead, TableRow, TableBody } from '@mui/material';
import { tableStyles } from 'pages/Pages.styles';
import { IMediaDoc } from 'userProfile/store/mediaDocuments/mediaDocuments.slice';
import MediaDocumentListItem from './MediaDocumentListItem';

type TMediaDocumentListViewProps = {
  mediaDocs?: IMediaDoc[];
  onToggleDrawer: (e: SyntheticEvent, mediaDoc: IMediaDoc | null) => void;
  onClickDeleteMediaDoc: (mediaDoc: IMediaDoc) => void;
  onToggleMediaDocPreview: (index: number) => void;
};

const MediaDocumentListView = ({
  mediaDocs,
  onToggleDrawer,
  onClickDeleteMediaDoc,
  onToggleMediaDocPreview,
}: TMediaDocumentListViewProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table
          aria-label="table header"
          sx={{
            ...tableStyles.font,
            borderCollapse: 'unset',
          }}
        >
          <TableHead>
            <TableRow sx={tableStyles.header}>
              <TableCell width={500} align="left">
                {t('mediaDocuments.listView.file')}
              </TableCell>
              <TableCell width={110} align="left">
                {t('mediaDocuments.listView.attached')}
              </TableCell>
              <TableCell width={30} align="left">
                {t('mediaDocuments.listView.date')}
              </TableCell>
              <TableCell width={10} align="left" aria-hidden="true" />
            </TableRow>
          </TableHead>
          <TableBody>
            {mediaDocs?.map((mediaDoc, index) => (
              <MediaDocumentListItem
                key={mediaDoc.id}
                mediaDoc={mediaDoc}
                onToggleDrawer={onToggleDrawer}
                onClickDeleteMediaDoc={onClickDeleteMediaDoc}
                onToggleMediaDocPreview={onToggleMediaDocPreview}
                index={index}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MediaDocumentListView;
