/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Theme,
  Typography,
  useMediaQuery,
  Skeleton,
} from '@mui/material';
import { IconClipboardContent, IconAlertTriangle, IconCheckCircle } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';

import type { ICollegeCourse, ITerm } from 'store/academicHistory/academicHistory.slice';
import noCoursesYet from 'assets/noAddedProgramYet.png';
import { nameSpace } from 'transferPlanner/constants/general';
import { ACADEMIC_HISTORY } from 'userProfile/constants/routeNames';
import { StyledTooltip } from 'userProfile/pages/ManageAccount/Privacy/Privacy.utils';
import { getAcademicHistory } from 'utils/AcademicHistory.utils';
import {
  selectAcademicHistory,
  selectCollegesTerms,
  academicHistory,
} from 'store/academicHistory/academicHistory.selectors';
import { tableStyles } from 'pages/Pages.styles';
import EmptySlate from 'transferPlanner/components/EmptySlate/EmptySlate';
import { CourseCard } from 'transferPlanner/components/CourseCard';

export const MyCourses = (): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const navigate = useNavigate();
  const [tooltipLocation, setTooltipLocation] = useState<string>();
  const dispatch = useDispatch();

  const academicHistoryData = useSelector(selectAcademicHistory);
  const { loading } = useSelector(academicHistory);
  const collegesTerms: ITerm[] | null = useSelector(selectCollegesTerms);
  const recentCollegeCourses = collegesTerms?.flatMap(term => term?.courses) || [];
  const isCoursesExist = Boolean(collegesTerms?.[0]?.courses?.length);
  const isCompletionStatusSame = collegesTerms?.every((term, _index, array) => {
    return term.completionStatus.code === array[0].completionStatus.code;
  });
  const onClick = () => navigate(`/${ACADEMIC_HISTORY.path}`);

  useEffect(() => {
    if (!academicHistoryData) dispatch(getAcademicHistory());
  }, [dispatch, academicHistoryData]);

  return (
    <Grid container sx={{ px: 3, py: 2 }}>
      <Grid item xs={12}>
        <Box sx={{ mb: { xs: 1, sm: 3 } }}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item container xs={0.7} sm={0.7} md={0.5} lg={0.3} xl={0.3}>
              <IconClipboardContent
                sx={{
                  fontSize: 32,
                  color: '#0277BD',
                  bgcolor: '#E1F5FE',
                  p: 0.8,
                  borderRadius: 0.5,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={7} md={7.5} lg={8.1} xl={8.1} sx={{ px: 4 }}>
              {isCoursesExist && collegesTerms?.[0] && (
                <Typography variant="subtitle1" component="div" sx={{ fontWeight: 600 }}>
                  {collegesTerms?.[0]?.type.displayName} {collegesTerms?.[0]?.academicYear}{' '}
                  {!isMobile &&
                    isCompletionStatusSame &&
                    `•
                  ${collegesTerms?.[0]?.completionStatus.displayName}`}
                </Typography>
              )}
              {!isCoursesExist && (
                <Typography variant="subtitle1" component="div" sx={{ fontWeight: 600 }}>
                  {t('empty_course_label')}
                </Typography>
              )}
            </Grid>
            <Grid item container xs={5} sm={3.5} md={3} lg={3} xl={3} justifyContent="end">
              {isCoursesExist && (
                <Button
                  size={isMobile ? 'small' : 'medium'}
                  onClick={onClick}
                  variant="outlined"
                  aria-label={t('view_all_course_aria_label')}
                >
                  {t('view_all_label')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {loading ? (
        <Skeleton data-testid="skeleton" variant="rectangular" width="100%" height={330} sx={{ pt: 2 }} />
      ) : (
        <Grid item xs={12} role={isCoursesExist ? 'list' : ''}>
          {isCoursesExist ? (
            <>
              {!isMobile && (
                <Box sx={{ height: { sm: '12rem', md: '15rem' }, overflow: 'auto' }}>
                  <TableContainer component={Paper}>
                    <Table
                      aria-label="table header"
                      sx={{
                        ...tableStyles.font,
                        borderCollapse: 'unset',
                      }}
                    >
                      <TableHead>
                        <TableRow sx={tableStyles.header}>
                          <TableCell align="left" aria-hidden="true" />
                          <TableCell align="left">Course Code</TableCell>
                          <TableCell align="left">Course Title</TableCell>
                          <TableCell align="left">Credits</TableCell>
                          <TableCell align="left">Grade</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {recentCollegeCourses &&
                          recentCollegeCourses?.map((course: ICollegeCourse | undefined, courseIndex: number) => {
                            const tooltipMapping = `${course?.code}-${courseIndex}`;
                            return (
                              <TableRow key={course?.code} sx={tableStyles.rowShadow}>
                                <TableCell size="small" align="left">
                                  <StyledTooltip
                                    open={tooltipMapping === tooltipLocation}
                                    componentsProps={{ tooltip: { role: 'button' } }}
                                    title={
                                      <>
                                        {course?.source?.code === 'ASSIST'
                                          ? t('colleges.course.source.assist.tooltip')
                                          : t('colleges.course.source.manual.tooltip')}
                                      </>
                                    }
                                    onClose={() => setTooltipLocation(undefined)}
                                  >
                                    <IconButton
                                      onMouseOver={() => setTooltipLocation(tooltipMapping)}
                                      onClick={() => setTooltipLocation(tooltipMapping)}
                                      size="small"
                                      edge="start"
                                      color={course?.source?.code === 'ASSIST' ? 'success' : 'warning'}
                                      aria-label="icon manual or verified course"
                                    >
                                      {course?.source?.code === 'ASSIST' ? <IconCheckCircle /> : <IconAlertTriangle />}
                                    </IconButton>
                                  </StyledTooltip>
                                </TableCell>
                                <TableCell align="left">{course?.code}</TableCell>
                                <TableCell align="left">{course?.title}</TableCell>
                                <TableCell align="left">{course?.credits}</TableCell>
                                <TableCell align="left">{course?.grade}</TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
              {isMobile &&
                recentCollegeCourses &&
                recentCollegeCourses?.map((course: ICollegeCourse | undefined) => (
                  <Fragment key={course?.id}>{course && <CourseCard course={course} />}</Fragment>
                ))}
            </>
          ) : (
            <EmptySlate
              imageSource={noCoursesYet}
              title={t('colleges.course.noAdded')}
              imgStyle={{ width: '7rem' }}
              buttonText={t('go_to_academic_history_label')}
              onClick={onClick}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};
