/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Theme } from '@mui/material';

export const sxButton = {
  bgcolor: '#E9F7FF',
  color: '#0D47A1',
  [`&:hover, &:focus, &:active`]: {
    bgcolor: '#E9F7FF',
  },
} as const;

export const sxFooterBox = {
  bgcolor: 'rgba(255, 255, 255, 0.5)',
  backdropFilter: 'blur(4px)',
  borderTop: '1px solid #5676CF',
  position: 'fixed',
  width: '100%',
  bottom: 0,
  color: '#0076C0',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  p: 1.5,
  zIndex: 3,
} as const;

export const sxListItem = {
  fontFamily: 'Proxima Nova Semibold',
  fontSize: '1rem',
  fontWeight: 'bold',
  m: '0.313rem 0 0.313rem 1rem',
  color: (theme: Theme) => theme.palette.primary.contrastText,
} as const;

export const sxHeadingMenu = {
  fontWeight: 'bold',
  margin: 'auto',
  flexGrow: 1,
  fontSize: 24,
  color: (theme: Theme) => theme.palette.primary.contrastText,
} as const;

export const sxDrawerBox = {
  minHeight: '40%',
  bgcolor: (theme: Theme) => theme.palette.primary.main,
  color: (theme: Theme) => theme.palette.primary.contrastText,
  borderTopLeftRadius: '1.5rem',
  borderTopRightRadius: '1.5rem',
  overflow: 'hidden',
} as const;

export const sxMenuListItem = {
  display: 'flex',
  bgcolor: 'transparent',
  color: 'white',
  borderRadius: 0,
  margin: '3px -10px',
  padding: '0.3em',
  fontWeight: 'bold',
  listStyle: 'none',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    bgcolor: 'rgba(255,255,255,0.2)',
    borderRadius: '5px',
  },
} as const;

export const sxCancelButton = {
  display: 'flex',
  alignItems: 'center',
  padding: '1rem',
  borderTop: (theme: Theme): string => `1px solid ${theme.palette.divider}`,
  justifyContent: 'center',
} as const;
