/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectExperiences } from 'userProfile/store/profileView/profileView.selectors';

import { SidePanel, ToggleButton } from '@liaison/liaison-ui';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import type { TExperience } from 'userProfile/store/profileView/profileView.slice';
import { formatExperienceDateRange, getButtonColor } from 'utils/utilities';
import { CardSection } from '../Common/CardSection';
import { DOCUMENTS, EXPERIENCE_DESCRIPTION, formatOrganizationDetails } from '../LivingProfile.utils';
import { Documents } from '../Common/Documents';

export const Experiences = (): ReactElement => {
  const experiences = useSelector(selectExperiences);
  const { t } = useTranslation();
  const title = t('livingProfile.sections.experiences');

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState<TExperience | undefined>();
  const [selectedToggle, setSelectedToggle] = useState<string | undefined>(DOCUMENTS);

  const toggleDrawer = (experience?: TExperience): void => {
    setIsDrawerOpen(!isDrawerOpen);
    setSelectedExperience(experience);
  };

  const onChangeToggle = (value: string) => {
    setSelectedToggle(value);
  };

  const sidePanelContent = (
    <>
      <Card variant="outlined" sx={{ borderRadius: '0.5rem' }}>
        <CardContent sx={{ position: 'relative' }}>
          <Stack direction="row">
            <Stack spacing={0.5} padding={0.5}>
              <Typography variant="subtitle3" sx={{ wordBreak: 'break-all' }}>
                {selectedExperience?.name}
              </Typography>
              <Typography variant="caption" sx={{ wordBreak: 'break-all' }}>
                {formatOrganizationDetails(selectedExperience?.organization)}
              </Typography>
              <Typography variant="caption" color="black" sx={{ wordBreak: 'break-all' }}>
                {formatExperienceDateRange(selectedExperience?.startDate, selectedExperience?.endDate)}
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>

      <Box sx={{ mx: -2, my: 3 }}>
        <ToggleButton
          options={[
            {
              text: t('livingProfile.toggle.mediaDocs'),
              val: DOCUMENTS,
            },
            {
              text: t('livingProfile.toggle.description'),
              val: EXPERIENCE_DESCRIPTION,
            },
          ]}
          onChange={onChangeToggle as (value: string | number | boolean) => void}
          selectedValue={selectedToggle}
        />
      </Box>

      {selectedToggle === EXPERIENCE_DESCRIPTION && (
        <>
          <Typography sx={{ px: 0.5, pb: 2, wordWrap: 'break-word' }}>
            {selectedExperience?.description || t('livingProfile.sections.experiences.noDescription')}
          </Typography>
        </>
      )}

      {selectedToggle === DOCUMENTS && (
        <>
          <Typography variant="h2" sx={{ px: 0.5, pb: 2 }}>
            {t('livingProfile.sections.mediaAndDocuments')}
          </Typography>
          <Documents attachments={selectedExperience?.attachments} useSections={false} />
        </>
      )}
    </>
  );

  return (
    <>
      <CardSection title={title} items={experiences} toggleDrawer={toggleDrawer as () => void} />

      <SidePanel
        size="small"
        title={t('livingProfile.sections.experiences.title')}
        open={isDrawerOpen}
        onClose={() => toggleDrawer()}
        footerButtonConfig={{
          tertiary: {
            title: t('cancel_label'),
            props: {
              color: getButtonColor(),
              'aria-label': t('cancel_label'),
              onClick: () => toggleDrawer(),
            },
          },
        }}
      >
        {sidePanelContent}
      </SidePanel>
    </>
  );
};
