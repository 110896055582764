/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export const sxButton = {
  bgcolor: '#E3F2FD',
  color: '#1565C0',
  [`&:hover, &:focus, &:active`]: {
    bgcolor: '#E3F2FD !important',
  },
} as const;

export const sxButtonProfileView = {
  bgcolor: '#EDE7F6',
  color: '#383D45',
  [`&:hover, &:focus, &:active`]: {
    bgcolor: '#EDE7F6 !important',
  },
} as const;

export const sxButtonCompetenciesProfileView = {
  bgcolor: '#e1f5fe',
  color: '#383D45',
  [`&:hover, &:focus, &:active`]: {
    bgcolor: '#e1f5fe !important',
  },
} as const;
