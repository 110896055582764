/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  dialogClasses,
  Button,
  Divider,
  Grid,
  Box,
  useMediaQuery,
  Theme,
  Portal,
  Link,
  IconButton,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { IconCancel, IconAdd, IconLocation, IconLink, IconTrash, Snackbar } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';

import { formatGPA, csuNameSpace } from 'utils/utilities';
import {
  postFollowedProgram,
  prepareFollowProgramPayload,
  findUnFollowedProgramIndex,
  postProgramCart,
} from 'transferPlanner/components/MyProgramsCart/MyProgramsCart.utils';
import { useAppSelector } from 'redux/hooks';
import { useProgramSimilarity } from 'transferPlanner/hooks/useProgramSimilarity';
import {
  selectSelectedProgramCart,
  selectActiveFollowedPrograms,
} from 'transferPlanner/store/programCart/programCart.selectors';
import { ISelectedProgram, updateUnFollowedProgramIndex } from 'transferPlanner/store/programCart/programCart.slice';
import { EXTENDED_SANITIZE_CONFIG } from 'constants/sanitize';
import { TFetchProgramDetailsResponse } from '../ProgramCardsLazyScroll/ProgramCardsLazyScroll.utils';
import {
  sxCampusImgBox,
  sxContactsIcon,
  sxContactsTypography,
  sxProgramImgBox,
  sxTransferRequirement,
} from './ProgramView.styles';
import { convertDetailedToIProgram, getSelectedPayload } from '../ProgramsSearchView.utils';
import { AddProgramModal, DissimilarConfirmation, SimilarConfirmation } from '../Confirmations';

export interface ISnackbarState {
  isVisible: boolean;
  isProgramAdded?: boolean;
  type?: string;
  title?: string;
  message?: string;
}
interface IProgramViewProps {
  programDetails: TFetchProgramDetailsResponse;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsSuccessSnackbar?: Dispatch<SetStateAction<ISnackbarState>>;
  isDisabledFollowButton?: boolean;
  setFollowedProgramId?: Dispatch<SetStateAction<number | undefined>>;
}

const BACKGROUND_IMAGE = 'backgroundImage';
const THUMBNAIL_IMAGE = 'thumbnailImage';

export const ProgramView = ({
  programDetails,
  isOpen,
  setIsOpen,
  setIsSuccessSnackbar,
  isDisabledFollowButton = false,
  setFollowedProgramId,
}: IProgramViewProps): ReactElement => {
  const { t } = useTranslation(csuNameSpace.ns);
  const dispatch = useDispatch();
  const selectedPrograms = useAppSelector(selectSelectedProgramCart);
  const activeFollowedPrograms = useAppSelector(selectActiveFollowedPrograms);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const [isRemovedSnackbar, setIsRemovedSnackbar] = useState(false);
  const [isProgramSelected, setIsProgramSelected] = useState(
    !!selectedPrograms?.find(e => e.program.id === programDetails?.id)
  );

  useEffect(() => {
    setIsProgramSelected(!!selectedPrograms?.find(e => e.program.id === programDetails.id));
  }, [selectedPrograms, programDetails.id]);

  const onClose = () => {
    setIsOpen(false);
  };

  const {
    addProgram,
    showDissimilarConfirmation,
    showSimilarConfirmation,
    setShowDissimilarConfirmation,
    setShowSimilarConfirmation,
    isAddProgramModalOpen,
    setIsAddProgramModalOpen,
  } = useProgramSimilarity(programDetails.moKey);

  /* istanbul ignore next */
  const removeProgram = () => {
    const selectedPayload: ISelectedProgram[] = selectedPrograms?.filter(e => e.program.id !== programDetails.id) || [];
    dispatch(
      postProgramCart(selectedPayload, () => {
        setIsRemovedSnackbar(true);
        dispatch(updateUnFollowedProgramIndex(findUnFollowedProgramIndex(programDetails.id, activeFollowedPrograms)));
      })
    );
  };

  const saveProgram = () => {
    dispatch(
      postProgramCart(getSelectedPayload(selectedPrograms, convertDetailedToIProgram(programDetails)), () => {
        setIsSuccessSnackbar?.({
          isVisible: true,
          isProgramAdded: true,
          type: 'success',
          title: t('program.program_added_title'),
          message: t('program.program_added_text2'),
        });
      })
    );
  };

  const followProgram = (adtSimilar?: boolean) => {
    dispatch(
      postFollowedProgram(prepareFollowProgramPayload({ ...programDetails, adtSimilar }, true), () => {
        setFollowedProgramId?.(programDetails?.id);
        dispatch(
          postProgramCart(getSelectedPayload(selectedPrograms, convertDetailedToIProgram(programDetails)), () => {
            setIsSuccessSnackbar?.({
              isVisible: true,
              isProgramAdded: false,
              type: 'success',
              title: t('program.program_followed_title'),
              message: t('program.program_followed_text'),
            });
          })
        );
      })
    );
  };

  return (
    <>
      <Portal>
        <Snackbar
          type="error"
          title={t('program.program_removed_title')}
          open={isRemovedSnackbar}
          /* istanbul ignore next */
          onClose={() => setIsRemovedSnackbar(false)}
          data-testid="remove-program-snackbar"
        >
          {programDetails.name} {t('program.program_removed_text')}
        </Snackbar>
        <DissimilarConfirmation
          open={showDissimilarConfirmation}
          onClose={() => {
            setShowDissimilarConfirmation(false);
          }}
          onConfirm={() => {
            setShowDissimilarConfirmation(false);
            saveProgram();
          }}
          onFollow={() => {
            setShowDissimilarConfirmation(false);
            followProgram(false);
          }}
          isDisabledFollowButton={isDisabledFollowButton}
        />
        <SimilarConfirmation
          open={showSimilarConfirmation}
          onClose={() => {
            /* istanbul ignore next */
            setShowSimilarConfirmation(false);
          }}
          onSave={() => {
            setShowSimilarConfirmation(false);
            saveProgram();
          }}
          onFollow={() => {
            setShowSimilarConfirmation(false);
            followProgram(true);
          }}
          isDisabledFollowButton={isDisabledFollowButton}
        />
        <AddProgramModal
          open={isAddProgramModalOpen}
          onClose={() => {
            setIsAddProgramModalOpen(false);
          }}
          addProgram={() => {
            setIsAddProgramModalOpen(false);
            saveProgram();
          }}
          onFollow={() => {
            setIsAddProgramModalOpen(false);
            followProgram();
          }}
          isDisabledFollowButton={isDisabledFollowButton}
        />
      </Portal>
      <Dialog
        open={isOpen}
        maxWidth="lg"
        fullWidth
        sx={{ [`.${dialogClasses.paper}`]: { maxHeight: '90vh', borderRadius: '1rem' } }}
      >
        <DialogTitle>
          <Typography variant="body1" sx={{ fontSize: '24px', fontWeight: '900' }}>
            {programDetails.name}
          </Typography>
          <Typography variant="body1">{programDetails.campus.name}</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            cursor: 'pointer',
          }}
        >
          <IconCancel />
        </IconButton>
        <DialogContent>
          <Grid container tabIndex={0}>
            <Grid item xs={12} md={9}>
              <Box
                sx={{
                  ...sxProgramImgBox,
                  backgroundImage: `url(${
                    programDetails.branding?.find(config => config.configName === BACKGROUND_IMAGE)?.configValue
                  })`,
                }}
              />
              <Typography
                sx={{ mt: '1rem', mb: '1rem', '& a': { color: 'black' }, overflowWrap: 'anywhere' }}
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: sanitize(programDetails.description, EXTENDED_SANITIZE_CONFIG),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={!isMobile ? { pl: '1rem' } : {}}>
              {!isMobile && (
                <Box sx={{ mb: '1rem' }}>
                  <Box
                    sx={{
                      ...sxCampusImgBox,
                      backgroundImage: `url(${
                        programDetails.campus.branding?.find(config => config.configName === THUMBNAIL_IMAGE)
                          ?.configValue
                      })`,
                    }}
                  />
                  <Typography variant="body2" sx={sxContactsTypography}>
                    <IconLocation sx={sxContactsIcon} />
                    {programDetails.campus?.contact?.address1}, {programDetails.campus?.contact?.city}{' '}
                    {programDetails.campus?.contact?.region.code}, {programDetails.campus?.contact?.postalCode}
                  </Typography>
                  <Link rel="noopener" target="_blank" href={programDetails.campus?.contact?.website} color="inherit">
                    <Typography variant="body2" sx={sxContactsTypography}>
                      <IconLink sx={sxContactsIcon} />
                      {programDetails.campus?.contact?.website}
                    </Typography>
                  </Link>
                </Box>
              )}
              <Divider />
              <Box sx={{ mt: '1rem', mb: '1rem' }}>
                <Typography component="h1" variant="body2" sx={{ fontWeight: 'bold', mb: '0.5rem' }} role="heading">
                  {t('generalEducationRequirements.title')}
                </Typography>
                <Typography variant="body2" sx={sxTransferRequirement}>
                  {t('generalEducationRequirements.requirements1')}
                </Typography>
                <Typography variant="body2" sx={sxTransferRequirement}>
                  {t('generalEducationRequirements.requirements2')}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ mt: '1rem', mb: '1rem' }}>
                <Typography component="h1" variant="body2" sx={{ fontWeight: 'bold', mb: '0.5rem' }} role="heading">
                  {t('programs.transferRequirements')}
                </Typography>
                <Typography variant="body2" sx={sxTransferRequirement}>
                  {t('programs.overallGPA')}
                  <span>{formatGPA(programDetails.minGPA)}</span>
                </Typography>
                <Typography variant="body2" sx={sxTransferRequirement}>
                  {t('programs.majorPrerequisiteGPA')}
                  <span>{formatGPA(programDetails.majorPreReqGPA)}</span>
                </Typography>
                <Typography variant="body2" sx={sxTransferRequirement}>
                  {t('programs.minimumGradeForPrerequisiteCourses')}
                  <span>{programDetails.minGrade?.displayName}</span>
                </Typography>
                <Typography variant="body2" sx={sxTransferRequirement}>
                  {t('programSelection.condition')}
                </Typography>
              </Box>
              <Divider />
              <Box sx={{ mt: '1rem' }}>
                <Typography component="h1" variant="body2" sx={{ fontWeight: 'bold', mb: '0.5rem' }} role="heading">
                  {t('otherInformation_label')}
                </Typography>
                <Typography
                  sx={{ overflowWrap: 'anywhere' }}
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(programDetails.requirements, EXTENDED_SANITIZE_CONFIG),
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', p: '1rem' }}>
          <Button onClick={onClose} color="error" variant="text">
            {t('close_label')}
          </Button>
          {isProgramSelected ? (
            <Button
              onClick={removeProgram}
              aria-label={t('program.remove_program')}
              color="error"
              variant="contained"
              startIcon={<IconTrash />}
            >
              {t('program.remove_program')}
            </Button>
          ) : (
            <Button
              onClick={() => addProgram()}
              aria-label={t('program.add_program')}
              variant="contained"
              startIcon={<IconAdd />}
            >
              {t('program.add_program')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
