/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { IconSchool, IconTarget, IconOut } from '@liaison/liaison-ui';
import { TAppThunk } from 'redux/store';
import { getLoggedUser } from 'utils/keyCloakUtils';
import axios from 'axios';
import { API } from 'transferPlanner/constants/api';
import { t } from 'i18next';
import {
  enrollmentStart,
  enrollmentSuccess,
  enrollmentFailure,
  ITspEnrollment,
  tspEnrollmentPeriodStart,
  tspEnrollmentPeriodSuccess,
  tspEnrollmentPeriodFailure,
} from 'transferPlanner/store/enrollment/enrollment.slice';
import { setUi } from 'store/ui/ui.slice';
import { csuNameSpace } from 'utils/utilities';
import { allowedEnrollmentStatuses } from 'transferPlanner/constants/general';
import type { TAcademicHistoryField, ICollege } from 'store/academicHistory/academicHistory.slice';
import { ICampus, IPrograms } from '../CampusesAndPrograms/CampusesAndPrograms.utils';

export const mobileTabHeadings = [
  t('programSelection.mobileTitle', csuNameSpace),
  t('communityCollege.mobileTitle', csuNameSpace),
  t('completeEnrollment.mobileTitle', csuNameSpace),
];

export const tabColors = {
  default: '#D4D4D4',
  current: '#2F2F2F',
  completed: '#C8E6C9',
};

export const tspEnrollmentMenus = [
  {
    id: 0,
    name: t('programSelection.title', csuNameSpace),
    icon: IconSchool,
    bgColor: tabColors.current,
    disabled: false,
    completed: false,
  },
  {
    id: 1,
    name: t('communityCollege.title', csuNameSpace),
    icon: IconTarget,
    bgColor: tabColors.default,
    disabled: true,
    completed: false,
  },
  {
    id: 2,
    name: t('completeEnrollment.title', csuNameSpace),
    icon: IconOut,
    bgColor: tabColors.default,
    disabled: true,
    completed: false,
  },
];

export interface ICampusOptionModified {
  id: number;
  alternateId?: {
    code: number;
    source: string;
  };
  name?: string;
  text: string;
  address?: {
    country?: string;
    region?: string;
    city?: string;
    postalCode?: string;
  };
}

export const getCampusOptions = (options: ICampus[]): ICampusOptionModified[] => {
  return options?.map(currentOption => {
    const country = currentOption?.contact?.country?.code;
    const city = currentOption?.contact?.city;
    const postalCode = currentOption?.contact?.postalCode;
    const region = currentOption?.contact?.region?.code;

    return {
      id: currentOption?.id,
      alternateId: {
        code: currentOption?.alternateId,
        source: 'Assist',
      },
      name: currentOption?.name,
      text: currentOption?.name,
      address: { country, region, city, postalCode },
    };
  });
};

export interface IProgramOptionModified {
  id?: number;
  name?: string;
  text?: string;
  moKey?: string;
}

export interface IEnrollmentDate {
  id: string;
  text: string;
}

export interface ICustomizedState {
  campus?: {
    id: number;
    alternateId: number;
  };
  programId?: number;
}

export const getProgramOptions = (programOptions: IPrograms[]): IProgramOptionModified[] => {
  const programsList = [] as IProgramOptionModified[];
  programOptions?.filter(currentOption => {
    if (currentOption?.tspEligible) {
      programsList.push({
        id: currentOption?.id,
        name: currentOption?.name,
        text: currentOption?.name,
        moKey: currentOption.moKey,
      });
    }
    return true;
  });

  return programsList;
};

export const getEnrollment = (): TAppThunk => async dispatch => {
  try {
    const loggedUserId = getLoggedUser();
    dispatch(enrollmentStart());
    const { data } = await axios.get(`${API.enrollment}/${loggedUserId}`);
    dispatch(enrollmentSuccess(data || {}));
  } catch (err) {
    dispatch(enrollmentFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  }
};

export const getTspEnrollmentPeriod = (): TAppThunk => async dispatch => {
  try {
    const loggedUserId = getLoggedUser();
    dispatch(tspEnrollmentPeriodStart());
    const { data } = await axios.get(`${API.tspEnrollmentPeriod}/${loggedUserId}`);
    dispatch(tspEnrollmentPeriodSuccess(data));
  } catch (err) {
    dispatch(tspEnrollmentPeriodFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  }
};

export const filterCommunityColleges = (ahData: TAcademicHistoryField): ICollege[] => {
  const colleges = ahData?.colleges || [];
  const filteredCommunityColleges = [] as ICollege[];
  colleges?.map((college, id) => {
    if (college?.alternateId?.code) {
      filteredCommunityColleges.push({ ...college, uniqueId: id });
    }
    return true;
  });

  return filteredCommunityColleges;
};

export const getEnrollStartingYears = (isHideJulyAnswersActive: boolean): IEnrollmentDate[] => {
  return isHideJulyAnswersActive ? [{ id: 'FALL 2024', text: 'FALL 2024' }] : [{ id: 'FALL 2023', text: 'FALL 2023' }];
};

export const postEnrollment =
  (payload: ITspEnrollment[], successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      const loggedUserId = getLoggedUser();
      dispatch(enrollmentStart());
      const modifiedPayload = {
        tspEnrollment: payload,
        profileId: loggedUserId,
      };
      const { data } = await axios.post(`${API?.enrollment}/${loggedUserId}`, modifiedPayload);
      dispatch(enrollmentSuccess(data));
      successCallback?.();
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      dispatch(enrollmentFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const fetchProgramById = (id: number, programs: IPrograms[]): IPrograms | {} => {
  return programs?.find((program: IPrograms) => program.id === id) || {};
};

export const findNotAllowedTspStatus = (tspEnrollment: ITspEnrollment[]): ITspEnrollment | undefined => {
  return tspEnrollment.find((enrollData: ITspEnrollment) => {
    const enrollmentStatus = enrollData?.enrollmentStatus?.status?.code || '';
    if (allowedEnrollmentStatuses.includes(enrollmentStatus)) return true;
    if (enrollmentStatus === 'REVOKED' && enrollData?.enrollmentStatus?.reason?.code === 'NOT_ELIGIBLE') {
      return true;
    }
    return false;
  });
};
