/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';
import { ICampusesState } from './campuses.slice';

const campuses = (state: TRootState): ICampusesState => state.campuses;

export const selectCampuses = createSelector(campuses, (stateData: ICampusesState) => stateData?.campuses);

export const selectCampusesLoading = createSelector(campuses, (stateData: ICampusesState) => stateData?.loading);
