/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { memo, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledDivider } from 'pages/Pages.styles';
import { Alert, Icon } from '@liaison/liaison-ui';
import { getAcademicHistory } from 'utils/AcademicHistory.utils';
import { selectAcademicHistory, selectLoading } from 'store/academicHistory/academicHistory.selectors';
import { useSelector, useDispatch } from 'react-redux';
import { nameSpace } from 'transferPlanner/constants/general';
import { filterCommunityColleges } from 'transferPlanner/pages/TspEnrollment/TspEnrollment.utils';
import type { ICollege } from 'store/academicHistory/academicHistory.slice';
import { getAcademicHistoryLookUps } from 'utils/commonUtils';
import { Spinner } from 'components/Spinner';
import { selectCollegesLookup } from 'store/common/commonInfo.selectors';
import CommunityCollegeCard from './CommunityCollegeCard';
import CommunityCollegeForm from './CommunityCollegeForm';
import { useFormData } from '../ContextForm';

const CommunityColleges = () => {
  const { setFormValues, data } = useFormData();
  const dispatch = useDispatch();
  const [colleges, setColleges] = useState<ICollege[]>([]);
  const [showCollege, setShowCollege] = useState(false);
  const [isEditCollege, setIsEditCollege] = useState(false);
  const { t } = useTranslation(nameSpace);
  const academicHistoryData = useSelector(selectAcademicHistory);
  const communityColleges = useSelector(selectCollegesLookup);
  const isLoading = useSelector(selectLoading);

  useEffect(() => {
    dispatch(getAcademicHistory());
  }, [dispatch]);

  useEffect(() => {
    if (!communityColleges) dispatch(getAcademicHistoryLookUps('college', '/alternateId/Assist'));
  }, [dispatch, communityColleges]);

  useEffect(() => {
    if (academicHistoryData) {
      const collegesList = filterCommunityColleges(academicHistoryData);
      setColleges(collegesList);
      setShowCollege(false);
      setIsEditCollege(false);
    }
  }, [academicHistoryData]);

  useEffect(() => {
    if (data?.closeForm) {
      setShowCollege(false);
    }
  }, [data]);

  return (
    <>
      {isLoading && <Spinner data-testid="spinner" backdrop />}
      <Box>
        <Typography sx={{ mb: 2 }} variant="h1">
          {t('communityCollege.title')}
        </Typography>
        <Typography variant="body2">{t('communityCollege.description')}</Typography>
        <StyledDivider />
        {!colleges.length && (
          <Alert sx={{ mb: 2 }} type="warning" size="mini">
            {t('communityCollege.noColleges')}
          </Alert>
        )}

        {showCollege && (
          <CommunityCollegeForm ahData={academicHistoryData} childPosition={academicHistoryData?.colleges?.length} />
        )}

        {!showCollege && !isEditCollege && (
          <Grid container justifyContent="center">
            <Button
              color="primary"
              sx={{
                mb: 2,
                backgroundColor: '#E3F2FD',
                '&:hover': { backgroundColor: '#E3F2FD' },
                color: '#383D45',
              }}
              variant="contained"
              onClick={() => {
                setShowCollege(true);
                setFormValues({ ...data, enabled: false, closeForm: false, selectedId: null });
              }}
              aria-label={t('communityCollege.add')}
              startIcon={<Icon>IconAdd</Icon>}
            >
              {t('communityCollege.add')}
            </Button>
          </Grid>
        )}

        {colleges?.map((college, i) => (
          <CommunityCollegeCard
            key={`${college.name} - ${college?.alternateId?.code} - ${college.startDate}`}
            college={college}
            ahData={academicHistoryData}
            currentIndex={i}
            setIsEditCollege={setIsEditCollege}
            isEditCollege={isEditCollege}
            disableEdit={showCollege}
          />
        ))}
      </Box>
    </>
  );
};

export default memo(CommunityColleges);
