/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import * as yup from 'yup';
import { MAX_BIG_LENGTH_FIELD } from 'constants/field';

export const validationSchema = yup.object().shape({
  bio: yup.string().trim().max(MAX_BIG_LENGTH_FIELD),
});
