/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, Box, FormControl, InputLabel, InputBase, FormHelperText } from '@mui/material';
import { TAccomplishmentsAndExperienceField } from 'userProfile/store/accomplishmentAndExperience/accomplishmentAndExperience.slice';
import { HonorsAndAwardsList } from 'userProfile/components/ViewBuilder/CardView';
import { useFormContext, Controller } from 'react-hook-form';
import { DISABLE_AUTO_FILL, FIELD_LENGTH_200, TEXT_AREA_LENGTH_FIELD } from 'constants/field';
import { DateField, Dropdown, Textarea } from '@liaison/liaison-ui';
import { StyledDeleteButton, StyledAddButton, StyledFormHelperText } from 'pages/Pages.styles';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { getDropDownOptions, isCsuTenant } from 'utils/utilities';
import { useTranslation } from 'react-i18next';
import { AttachedFiles } from 'userProfile/components/AttachedFiles';
import { SkillsMultiselect } from 'userProfile/components/SkillsMutliselect';
import { selectSectionMediaDocs } from 'userProfile/store/mediaDocuments/mediaDocuments.selectors';
import { selectAchievementEnumTypes } from 'store/common/commonInfo.selectors';
import MasterData from 'userProfile/constants/master';

const groupName = 'honorsOrAwards';
interface IHonorsAndAwardsProps {
  data: TAccomplishmentsAndExperienceField | null;
  openChildDrawer: (isAddNew: boolean, childPosition: number, selector: string, formTitle: string) => void;
  isOpenChildDrawer?: boolean;
  childPosition?: number;
  deleteRecord?: ((childPosition: number, tag?: string) => void) | null;
  selector: string;
  formTitle: string;
}

type TFormErrors = {
  honorsOrAwards: {
    type: { code: { message: string } };
    name: { message: string };
    source: { message: string };
    receivedDate: { message: string };
    description: { message: string };
  }[];
};

const HonorsAndAward = ({
  data,
  openChildDrawer,
  isOpenChildDrawer,
  childPosition = 0,
  deleteRecord,
  selector,
  formTitle,
}: IHonorsAndAwardsProps): ReactElement => {
  const [showAlert, setShowAlert] = useState(false);
  const {
    control,
    reset,
    register,
    setValue,
    formState: { errors: formErrors, isValid },
  } = useFormContext();
  const errors = formErrors as unknown as TFormErrors;
  const { t } = useTranslation();
  const achievementId = data?.[groupName]?.[childPosition]?.id;
  const sectionMediaDocs = useSelector(state => selectSectionMediaDocs(state, achievementId));
  const achievementEnumTypes = useSelector(selectAchievementEnumTypes);
  const tag = `honorsOrAwards/${achievementId}`;

  const achievementTypeOptions = useMemo(
    () => getDropDownOptions(achievementEnumTypes || MasterData.achievementTypes),
    [achievementEnumTypes]
  );

  useEffect(() => {
    if (data) {
      reset(data);
    }
    return () => reset({});
  }, [reset, data]);

  return (
    <Grid>
      {!isOpenChildDrawer ? (
        <Box>
          <Typography
            variant="subtitle1"
            component="h1"
            sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
          >
            {t('honorsOrAwards.title')}
          </Typography>
          {data && data?.honorsOrAwards && (
            <HonorsAndAwardsList
              honorsAndAwards={data?.honorsOrAwards}
              openChildDrawer={openChildDrawer}
              selector={selector}
              formTitle={formTitle}
            />
          )}

          {JSON.stringify(data?.[groupName]) && (
            <InputBase
              inputProps={{ type: 'hidden' }}
              {...register(`${groupName}`)}
              defaultValue={JSON.stringify(data?.[groupName])}
            />
          )}

          <Box sx={{ justifyContent: 'center', display: 'flex' }}>
            <StyledAddButton
              aria-label={t('honorsOrAwards.add_label')}
              onClick={() =>
                openChildDrawer(true, data?.honorsOrAwards?.length || 0, selector, t(`${groupName}.addAchievement`))
              }
            >
              {t('add_label')}
            </StyledAddButton>
          </Box>
        </Box>
      ) : (
        <Box>
          <FormControl fullWidth required error={!!errors?.[groupName]?.[childPosition]?.type?.code}>
            <InputLabel htmlFor="type">{t('honorsOrAwards.type')}</InputLabel>
            <Controller
              render={({ field: { ref, onChange, ...field } }) => (
                <Dropdown
                  id="type"
                  {...field}
                  inputRef={ref}
                  options={achievementTypeOptions}
                  fullWidth
                  inputProps={{
                    'aria-label': t('honorsOrAwards.type'),
                    'aria-describedby': 'type-error',
                    ...DISABLE_AUTO_FILL,
                  }}
                  onChange={option => {
                    setValue(`${groupName}.${childPosition}.type.displayName`, option?.text);
                    onChange(option?.id ?? '');
                  }}
                />
              )}
              control={control}
              name={`${groupName}.${childPosition}.type.code`}
            />
            <StyledFormHelperText role="alert" id="type-error">
              {errors?.[groupName]?.[childPosition]?.type?.code?.message}
            </StyledFormHelperText>
          </FormControl>
          <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.${childPosition}.type.displayName`)} />
          <FormControl fullWidth required error={!!errors?.[groupName]?.[childPosition]?.name}>
            <InputLabel htmlFor="name">{t('honorsOrAwards.name')}</InputLabel>
            <InputBase
              inputProps={{
                'aria-describedby': 'name-error',
                'aria-label': t('honorsOrAwards.achievementName'),
                maxLength: FIELD_LENGTH_200,
                ...DISABLE_AUTO_FILL,
              }}
              {...register(`${groupName}.${childPosition}.name`)}
            />
            <FormHelperText role="alert" id="name-error">
              {errors?.[groupName]?.[childPosition]?.name?.message}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth required error={!!errors?.[groupName]?.[childPosition]?.source}>
            <InputLabel htmlFor="source">{t('honorsOrAwards.source')}</InputLabel>
            <InputBase
              inputProps={{
                'aria-describedby': 'source-error',
                'aria-label': t('honorsOrAwards.source'),
                maxLength: FIELD_LENGTH_200,
                ...DISABLE_AUTO_FILL,
              }}
              {...register(`${groupName}.${childPosition}.source`)}
            />
            <FormHelperText role="alert" id="source-error">
              {errors?.[groupName]?.[childPosition]?.source?.message}
            </FormHelperText>
          </FormControl>
          <Grid container spacing={2}>
            <Controller
              name={`${groupName}.${childPosition}.receivedDate`}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Grid item sm={12}>
                  <FormControl fullWidth error={!!errors?.[groupName]?.[childPosition]?.receivedDate}>
                    <InputLabel htmlFor="receivedDate">{t('honorsOrAwards.dateReceived')}</InputLabel>
                    <DateField
                      {...field}
                      ref={ref}
                      error={!!error}
                      id="receivedDate"
                      aria-describedby="receivedDate-error"
                      accessibilityLabelPrefix={t('honorsOrAwards.dateReceived')}
                    />
                    <FormHelperText role="alert" id="receivedDate-error">
                      {errors?.[groupName]?.[childPosition]?.receivedDate?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}
              control={control}
            />
          </Grid>
          <Controller
            name={`${groupName}.${childPosition}.description`}
            render={({ field, fieldState: { error } }) => (
              <Grid item sm={12}>
                <FormControl fullWidth error={!!errors?.[groupName]?.[childPosition]?.description}>
                  <InputLabel htmlFor="description">{t('honorsOrAwards.description')}</InputLabel>
                  <Textarea
                    {...field}
                    error={!!error}
                    id="description"
                    maxChars={TEXT_AREA_LENGTH_FIELD}
                    inputProps={{
                      maxLength: TEXT_AREA_LENGTH_FIELD,
                      maxRows: 1,
                      'aria-label': t('honorsOrAwards.description'),
                    }}
                  />
                  <FormHelperText role="alert">
                    {errors?.[groupName]?.[childPosition]?.description?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
            )}
            control={control}
            defaultValue=""
          />
          <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.${childPosition}.id`)} />
          <AttachedFiles sectionName="honorsOrAwards" tag={tag} disabled={!isValid} />
          {!isCsuTenant() && <SkillsMultiselect tag={tag} disabled={!isValid} />}
          {deleteRecord && (
            <Box sx={{ mt: '1rem', justifyContent: 'center', display: 'flex' }}>
              <StyledDeleteButton onClick={() => setShowAlert(true)}>{t('honorsOrAwards.remove')}</StyledDeleteButton>
            </Box>
          )}
          <ConfirmationDialog
            open={showAlert}
            text={t('honorsOrAwards.delete_title')}
            confirmationText={t('honorsOrAwards.delete_content')}
            onClose={() => {
              setShowAlert(false);
            }}
            footerButtonConfig={{
              primary: {
                title: t('remove_label'),
                props: {
                  onClick: () => {
                    setShowAlert(false);
                    deleteRecord?.(childPosition || 0, sectionMediaDocs?.length ? tag : undefined);
                  },
                },
              },
              tertiary: {
                title: t('cancel_label'),
              },
            }}
          />
        </Box>
      )}
    </Grid>
  );
};

export default HonorsAndAward;
