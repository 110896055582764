/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TError = string | null;

export interface IProgram {
  id: number;
  name: string;
  campusId: number;
  campusAlternateId: number;
  campusName: string;
  tspEligible?: boolean;
  moKey?: string;
  adtSimilar?: boolean;
}

export const enum EViewMode {
  card,
  list,
}
export interface ISearchProgramState {
  selectedPrograms: IProgram[] | undefined;
  selectedProgramsLoading: boolean;
  selectedProgramsError: TError;
  isMyProgramsOpen: boolean;
  searchQuery: string;
  scrollLetter: string;
  selectedCampusIds: number[];
  isEligible: boolean;
  navigationLetter: string;
  needScroll: boolean;
  viewMode: EViewMode;
}

export const initialState: ISearchProgramState = {
  selectedPrograms: undefined,
  selectedProgramsLoading: false,
  selectedProgramsError: null,
  isMyProgramsOpen: false,
  searchQuery: '',
  scrollLetter: '',
  navigationLetter: '',
  selectedCampusIds: [],
  isEligible: false,
  needScroll: false,
  viewMode: EViewMode.card,
};

const searchProgramSlice = createSlice({
  name: 'searchProgram',
  initialState,
  reducers: {
    getSelectedProgramsStart: (state): ISearchProgramState => ({
      ...state,
      selectedProgramsLoading: true,
      selectedProgramsError: null,
    }),
    getSelectedProgramsSuccess: (state, action: PayloadAction<{ programs: IProgram[] }>): ISearchProgramState => ({
      ...state,
      selectedProgramsLoading: false,
      selectedPrograms: action.payload.programs,
    }),
    getSelectedProgramsFailure: (state, action: PayloadAction<TError>): ISearchProgramState => ({
      ...state,
      selectedProgramsLoading: false,
      selectedProgramsError: action.payload,
    }),
    toggleMyPrograms: (state, action: PayloadAction<boolean>): ISearchProgramState => ({
      ...state,
      isMyProgramsOpen: action.payload,
    }),
    setSearchQuery: (state, action: PayloadAction<string>): ISearchProgramState => ({
      ...state,
      navigationLetter: 'A',
      scrollLetter: 'A',
      searchQuery: action.payload,
    }),
    setScrollLetter: (state, action: PayloadAction<string>): ISearchProgramState => ({
      ...state,
      scrollLetter: action.payload,
    }),
    setNavigationLetter: (state, action: PayloadAction<string>): ISearchProgramState => ({
      ...state,
      navigationLetter: action.payload,
      needScroll: state.navigationLetter === action.payload ? !state.needScroll : state.needScroll,
    }),
    setSelectedCampusIds: (state, action: PayloadAction<number[] | undefined>): ISearchProgramState => ({
      ...state,
      navigationLetter: 'A',
      scrollLetter: 'A',
      selectedCampusIds: action.payload ?? [],
    }),
    setIsEligible: (state, action: PayloadAction<boolean>): ISearchProgramState => ({
      ...state,
      navigationLetter: 'A',
      scrollLetter: 'A',
      isEligible: action.payload,
    }),
    toggleIsEligible: (state): ISearchProgramState => ({
      ...state,
      navigationLetter: 'A',
      scrollLetter: 'A',
      isEligible: !state.isEligible,
    }),
    toggleViewMode: (state): ISearchProgramState => ({
      ...state,
      viewMode: state.viewMode === EViewMode.card ? EViewMode.list : EViewMode.card,
    }),
    setViewMode: (state, action: PayloadAction<EViewMode>): ISearchProgramState => ({
      ...state,
      viewMode: action.payload,
    }),
    resetSearchProgram: (): ISearchProgramState => initialState,
  },
});

export const {
  getSelectedProgramsStart,
  getSelectedProgramsSuccess,
  getSelectedProgramsFailure,
  toggleMyPrograms,
  setSearchQuery,
  setScrollLetter,
  setNavigationLetter,
  setSelectedCampusIds,
  setIsEligible,
  toggleIsEligible,
  toggleViewMode,
  setViewMode,
  resetSearchProgram,
} = searchProgramSlice.actions;

export default searchProgramSlice.reducer;
