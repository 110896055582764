/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { API } from 'transferPlanner/constants/api';
import { t } from 'i18next';
import axios from 'axios';
import { TAppThunk } from 'redux/store';
import { UseFormSetValue } from 'react-hook-form';
import { setUi } from 'store/ui/ui.slice';
import { getLoggedUser } from 'utils/keyCloakUtils';

import {
  eligibilityStart,
  eligibilitySuccess,
  eligibilityFailure,
  IEligibility,
  Question,
  ITspEligibility,
} from 'transferPlanner/store/eligibility/eligibility.slice';

export const groupName = 'Transfer-Success-Pathway-Questionnaire';

export const versionYear = {
  v0: 2022,
  v1: 2023,
  v2: 2024,
};

export const defaultValues = {
  graduate: '',
  applied: '',
  notApplyReason: '',
  otherReason: '',
  planToTransfer: 0,
};

export const notApplyReasonAnswers = [
  { val: 'Not eligible for admission', text: 'Not eligible for admission' },
  { val: 'Financial reasons', text: 'Financial reasons' },
  { val: 'Personal reasons', text: 'Personal reasons' },
  { val: 'Undecided major or career path', text: 'Undecided major or career path' },
  { val: 'other', text: 'Other:' },
];

export const planToTransferOptions = [
  {
    id: 1,
    text: 'Spring 2024',
  },
  {
    id: 2,
    text: 'Fall 2024',
  },
  {
    id: 3,
    text: 'Spring 2025',
  },
  {
    id: 4,
    text: 'Fall 2025',
  },
  {
    id: 5,
    text: 'Spring 2026',
  },
  {
    id: 6,
    text: 'Fall 2026',
  },
];

const whyNotApplyCSUCodes = {
  [notApplyReasonAnswers[0].val]: 'whyNotApplyCSU-1',
  [notApplyReasonAnswers[1].val]: 'whyNotApplyCSU-2',
  [notApplyReasonAnswers[2].val]: 'whyNotApplyCSU-3',
  [notApplyReasonAnswers[3].val]: 'whyNotApplyCSU-4',
  [notApplyReasonAnswers[4].val]: 'whyNotApplyCSU-5',
};

const whenApplyCSUCodes = {
  [planToTransferOptions[0].text]: 'whenApplyCSU-1',
  [planToTransferOptions[1].text]: 'whenApplyCSU-2',
  [planToTransferOptions[2].text]: 'whenApplyCSU-3',
  [planToTransferOptions[3].text]: 'whenApplyCSU-4',
  [planToTransferOptions[4].text]: 'whenApplyCSU-5',
  [planToTransferOptions[5].text]: 'whenApplyCSU-6',
};

export const applyFormValues = ({
  setValue,
  questions,
}: {
  setValue: UseFormSetValue<{
    graduate: string;
    applied: string;
    notApplyReason: string;
    otherReason: string;
    planToTransfer: number;
  }>;
  questions: Question[];
}): void => {
  if (questions[0]) {
    setValue('graduate', questions[0].answer.value);
    if (questions[0].answer.value === 'Yes') {
      setValue('applied', questions[1].answer.value);
      setValue('notApplyReason', questions[2].answer.value);
      /* istanbul ignore next */
      if (questions[2].answer.code === whyNotApplyCSUCodes[notApplyReasonAnswers[4].val]) {
        setValue('notApplyReason', 'other');
        setValue('otherReason', questions[2].answer.value);
      }
    }
  }
};

export const currentQuestionnaire = (version: number, eligibility: ITspEligibility[]): ITspEligibility[] => {
  return eligibility?.filter((current: ITspEligibility) => {
    return current.questionnaireVersion === version;
  }) as ITspEligibility[];
};

type QuestionnairePaylod = {
  eligible: boolean;
  graduate: string;
  applied: string;
  notApplyReason: string;
  otherReason: string;
  questionnaireVersion: number;
  eligibility: ITspEligibility[] | undefined;
  planToTransfer: string;
};

export const composePayload = ({
  eligible,
  graduate,
  applied,
  notApplyReason,
  otherReason,
  questionnaireVersion,
  eligibility,
  planToTransfer,
}: QuestionnairePaylod): IEligibility => {
  const eligibleQuestion = [
    {
      code: 'previousCSUApp',
      order: 2,
      meta: {
        type: 'TEXT',
        format: 'string',
        code: 'RADIO',
        displayName: 'RADIO',
      },
      required: true,
      displayName: 'Have you applied to a California State University through Cal State Apply?',
      answer: {
        code: applied === 'Yes' ? 'previousCSUApp-1' : 'previousCSUApp-2',
        value: applied,
        displayName: applied,
      },
    },
    {
      code: 'whyNotApplyCSU',
      order: 3,
      meta: {
        type: 'TEXT',
        format: 'string',
        code: 'RADIO',
        displayName: 'RADIO',
      },
      required: true,
      displayName: "Why didn't you apply to or attend a California State University?",
      answer: {
        code: whyNotApplyCSUCodes[notApplyReason],
        value: notApplyReason === 'other' ? otherReason : notApplyReason,
        displayName: notApplyReason === 'other' ? 'Other' : notApplyReason,
      },
    },
  ];

  const planToTransferPayload = planToTransfer !== '' && {
    code: 'whenApplyCSU',
    order: 4,
    meta: {
      type: 'TEXT',
      format: 'string',
      code: 'DROPDOWN',
      displayName: 'DROPDOWN',
    },
    required: true,
    displayName: 'When do you plan to transfer to a California State University?',
    answer: {
      code: whenApplyCSUCodes[planToTransfer],
      value: planToTransfer,
      displayName: planToTransfer,
    },
  };

  if (planToTransferPayload) eligibleQuestion.push(planToTransferPayload);

  const existingQuestinare =
    (eligibility?.filter((current: ITspEligibility) => {
      return current.questionnaireVersion !== questionnaireVersion;
    }) as ITspEligibility[]) || [];

  const currentIsoDate = new Date().toISOString();

  const currentQuestinare = [
    {
      questionnaireVersion,
      status: {
        code: eligible ? 'ELIGIBLE' : 'INELIGIBLE',
        displayName: eligible ? 'Eligible' : 'Ineligible',
      },
      reason: {
        code: eligible ? 'CRITERIA_MET' : 'CRITERIA_NOT_MET',
        displayName: eligible ? 'Criteria Met' : 'Criteria Not Met',
      },
      lastUpdatedTimestamp: currentIsoDate,
      questions: [
        {
          code: 'hasGraduatedHS',
          order: 1,
          meta: {
            type: 'TEXT',
            format: 'string',
            code: 'RADIO',
            displayName: 'RADIO',
          },
          required: true,
          displayName: 'Will you graduate or do you plan to leave high school in 2023?',
          answer: {
            code: graduate === 'Yes' ? 'hasGraduatedHS-1' : 'hasGraduatedHS-2',
            value: graduate,
            displayName: graduate,
          },
        },
        ...(eligible ? eligibleQuestion : []),
      ],
    },
  ];

  const combinedQuestionnaire = [...existingQuestinare, ...currentQuestinare];

  return {
    profileId: getLoggedUser(),
    lastUpdatedTimestamp: currentIsoDate,
    lastUpdatedByTenant: 'csu',
    tspEligibility: combinedQuestionnaire as ITspEligibility[],
  };
};

export const postEligibility =
  (payload: IEligibility, onSuccess: () => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(eligibilityStart());
      const { data } = await axios.post(`${API.eligibility}/${getLoggedUser()}`, payload);
      dispatch(eligibilitySuccess(data));
      onSuccess();
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      dispatch(eligibilityFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const getEligibility =
  (successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(eligibilityStart());
      const { data } = await axios.get(`${API.eligibility}/${getLoggedUser()}`);
      /* istanbul ignore next */
      dispatch(eligibilitySuccess(data || {}));
      successCallback?.();
    } catch (err) {
      dispatch(eligibilityFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const gapYearLink = 'https://blog.collegeboard.org/what-gap-year';
