/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';
import { ITimelineInfoState } from './timelineInfo.slice';

const timelineInfo = (state: TRootState): ITimelineInfoState => state.timelineInfo;

export const selectTimelineInfo = createSelector(
  timelineInfo,
  (stateData: ITimelineInfoState) => stateData?.calculatorBase
);

export const selectTimelineInfoLoading = createSelector(
  timelineInfo,
  (stateData: ITimelineInfoState) => stateData?.loading
);
