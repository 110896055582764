/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { RefObject, useState, useEffect } from 'react';

const isTouchDevice = () => 'ontouchstart' in window;

type UseSwipeOptionsType = {
  ref: RefObject<HTMLElement>;
  thresholdPX?: number;
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
};

export const useSwipe = ({
  ref,
  thresholdPX = 5,
  onSwipeLeft = () => {},
  onSwipeRight = () => {},
}: UseSwipeOptionsType): void => {
  const [x1, setX1] = useState(0);

  useEffect(() => {
    const onTouchStart = (event: TouchEvent) => {
      setX1(event.changedTouches[0].clientX);
    };
    const onTouchEnd = (event: TouchEvent) => {
      if (event.changedTouches?.length > 0) {
        const x2 = event.changedTouches[0].clientX;
        if (Math.abs(x2 - x1) > thresholdPX) {
          if (x2 > x1) {
            onSwipeLeft();
          } else {
            onSwipeRight();
          }
        }
      }
    };
    const currentElement = ref.current;
    if (currentElement && isTouchDevice()) {
      currentElement.addEventListener('touchstart', onTouchStart);
      currentElement.addEventListener('touchend', onTouchEnd);
    }

    return () => {
      if (currentElement && isTouchDevice()) {
        currentElement.removeEventListener('touchstart', onTouchStart);
        currentElement.removeEventListener('touchend', onTouchEnd);
      }
    };
  }, [onSwipeLeft, onSwipeRight, ref, thresholdPX, x1]);
};
