/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { IconCaretRight, IconSave, IconStar } from '@liaison/liaison-ui';
import { selectActiveFollowedPrograms } from 'transferPlanner/store/programCart/programCart.selectors';
import { useSelector } from 'react-redux';
import { nameSpace, MAX_FOLLOWED_PROGRAMS } from 'transferPlanner/constants/general';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';

interface ISaveAndFollowButton {
  label: string;
  description: string;
  isSaveIcon?: boolean;
  onClick: () => void;
  sx?: Record<string, unknown> | undefined;
  disabled?: boolean;
}

const SaveAndFollowButton = ({
  label,
  description,
  onClick,
  sx = {},
  isSaveIcon = false,
  disabled = false,
}: ISaveAndFollowButton): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const activeFollowedPrograms = useSelector(selectActiveFollowedPrograms);
  const isAllowedFollowedProgramsLength = activeFollowedPrograms?.length === MAX_FOLLOWED_PROGRAMS;
  const isOneOfThemTrue = disabled || isAllowedFollowedProgramsLength;
  const alreadyFollowingFivePrograms = isAllowedFollowedProgramsLength
    ? t('programs.confirmation.followProgramDisableMsg')
    : '';
  const alreadyFollowingMessage = disabled
    ? t('programs.confirmation.followProgramDisableMsg2')
    : alreadyFollowingFivePrograms;
  const higherPriorityTooltipMsg =
    !disabled && isAllowedFollowedProgramsLength ? alreadyFollowingFivePrograms : alreadyFollowingMessage;
  const disableBackgroundColor = isOneOfThemTrue ? '#cbcbcb' : '#ffff';
  const disableCursorPointer = isOneOfThemTrue ? 'not-allowed' : 'pointer';
  return (
    <Tooltip title={isSaveIcon ? '' : higherPriorityTooltipMsg}>
      <Box
        component="button"
        sx={{
          border: '1px solid #d4d3d3',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: isMobile ? '100%' : '20rem',
          cursor: isSaveIcon ? 'pointer' : disableCursorPointer,
          borderRadius: '0.5rem',
          padding: '0.5rem',
          backgroundColor: isSaveIcon ? '#ffff' : disableBackgroundColor,
          ...sx,
        }}
        onClick={onClick}
        role="group"
        aria-label={label}
        data-testid={label}
        tabIndex={0}
        disabled={isSaveIcon ? false : isOneOfThemTrue}
      >
        <Box sx={{ px: '.5rem', opacity: disabled ? '.3' : '1' }}>
          {isSaveIcon ? <IconSave style={{ color: '#429cc0' }} /> : <IconStar style={{ color: '#429cc0' }} />}
        </Box>
        <Box sx={{ textAlign: 'initial', color: theme => theme.palette.common.black }}>
          <Typography component="div" variant="subtitle2" aria-label={label}>
            {label}
          </Typography>
          <Typography component="p" variant="caption" aria-label={description} tabIndex={0}>
            {description}
          </Typography>
        </Box>
        <Box>
          <IconButton aria-label={label} size="small" sx={{ height: 'fit-content' }} disabled={disabled}>
            <IconCaretRight />
          </IconButton>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default SaveAndFollowButton;
