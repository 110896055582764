/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */
import React, { ReactElement, useMemo, useEffect, useState } from 'react';
import { useFormContext, Controller, useForm, FormProvider, SubmitHandler, FieldValues } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Box,
  FormControl,
  InputLabel,
  InputBase,
  FormHelperText,
  AutocompleteInputChangeReason,
  Typography,
  Divider,
  Button,
  Switch,
  Theme,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import {
  Dropdown,
  IconCaretDown,
  SidePanel,
  TAutocompleteOption,
  Alert,
  TpAutocomplete,
  Icon,
  IconAdd,
} from '@liaison/liaison-ui';
import moment from 'moment';
import { DISABLE_AUTO_FILL, FIELD_LENGTH_200, FIELD_LENGTH_80, FIELD_LENGTH_20 } from 'constants/field';
import { getLookUps } from 'utils/commonUtils';
import { ICollege, ITerm } from 'store/academicHistory/academicHistory.slice';
import { selectSubjects, selectCollegeCourses, selectIsLoading } from 'store/common/commonInfo.selectors';
import {
  selectAcademicHistory,
  selectCoursesExtensions,
  selectAcademicHistoryLocalData,
} from 'store/academicHistory/academicHistory.selectors';
import { getButtonColor, getCheckboxColor, getDropDownOptions, isCsuTenant, sanitizePayload } from 'utils/utilities';
import { StyledDeleteButton, sxSidePanel } from 'pages/Pages.styles';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { yupResolver } from '@hookform/resolvers/yup';
import debounce from 'lodash.debounce';
import { useTermIndex } from 'hooks/useTermIndex';
import { useCollegeIndex } from 'hooks/useCollegeIndex';
import { getCollegeCourseOptions, getCourses, postAcademicHistory } from 'utils/AcademicHistory.utils';
import { getCourseApiStart, resetlookupData } from 'store/common/commonInfo.slice';
import { selectSectionMediaDocs } from 'userProfile/store/mediaDocuments/mediaDocuments.selectors';
import { AttachedFiles } from 'userProfile/components/AttachedFiles';
import { SkillsMultiselect } from 'userProfile/components/SkillsMutliselect';
import { setSkillsAttachments } from 'userProfile/store/accomplishmentAndExperience/accomplishmentAndExperience.slice';
import { selectSkillsAttachments } from 'userProfile/store/accomplishmentAndExperience/accomplishmentAndExperience.selectors';
import { prepareTermsOptions, findSelectedTerm, ICollegeCourseProps } from './AcademicHistoryForm.utils';
import { termValidationSchema } from '../AcademicHistoryForm.validation';
import CollegeTermForm from './CollegeTermForm';

const coursePrefix = 'course';

type TCourseErrors = {
  subject: {
    code: { message: string };
  };
  code: { message: string };
  title: { message: string };
  credits: { message: string };
  grade: { message: string };
  convertedGrade: { message: string };
};

const CourseNumberAndName = (): ReactElement => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();
  const courseErrors = errors?.[coursePrefix] as unknown as TCourseErrors;
  const watchTerm = getValues('term');
  const enableCourseFields = !!(watchTerm?.type?.code && watchTerm?.academicYear);
  return (
    <Grid container spacing={1}>
      <Grid item xs={6} md={6}>
        <FormControl fullWidth required error={!!courseErrors?.code}>
          <InputLabel htmlFor="courseNumber">{t('colleges.course.number')}</InputLabel>
          <InputBase
            inputProps={{
              'aria-label': t('colleges.course.number'),
              maxLength: FIELD_LENGTH_20,
              ...DISABLE_AUTO_FILL,
            }}
            disabled={!enableCourseFields}
            {...register(`${coursePrefix}.code`)}
          />
          <FormHelperText role="alert" id="courseNumber-helper">
            {t('colleges.course.number.helper')}
          </FormHelperText>
          <FormHelperText role="alert" id="courseNumber-error">
            {courseErrors?.code?.message}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6} md={6}>
        <FormControl fullWidth required error={!!courseErrors?.title}>
          <InputLabel htmlFor="courseName">{t('academicHistory.courseName')}</InputLabel>
          <InputBase
            inputProps={{
              'aria-label': t('academicHistory.courseName'),
              maxLength: FIELD_LENGTH_80,
              ...DISABLE_AUTO_FILL,
            }}
            disabled={!enableCourseFields}
            {...register(`${coursePrefix}.title`, {
              onChange: () => {
                setValue(`${coursePrefix}.source`, { code: 'APPLICANT', displayName: 'Applicant' });
              },
            })}
          />
          <FormHelperText role="alert" id="courseName-helper">
            {t('colleges.course.courseName.helper')}
          </FormHelperText>
          <FormHelperText role="alert" id="courseName-error">
            {courseErrors?.title?.message}
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

const CollegeCourseForm = ({
  data,
  childPosition = 0,
  deleteCourse,
  parentPosition = 0,
  coursePosition = 0,
  isNewCourse,
  setNewParentIndex,
  setNewChildIndex,
}: ICollegeCourseProps): ReactElement => {
  const methods = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(termValidationSchema, { abortEarly: false })(...args),
    context: { formName: 'terms' },
  });

  const {
    control,
    reset,
    register,
    formState: { errors, isValid },
    setValue,
    watch,
    getValues,
    trigger,
    clearErrors,
    setFocus,
  } = useFormContext();

  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const dispatch = useDispatch();
  const fromYear = data?.startDate ? moment(data?.startDate, 'YYYY-MMM').year() : 1990;
  const toYear = data?.endDate ? moment(data?.endDate, 'YYYY-MMM').year() : 2100;
  const alternateId = data?.alternateId?.code;
  const isCommunityCollege = alternateId && data?.alternateId?.source === 'Assist';
  const [showAlert, setShowAlert] = useState(false);
  const [showGrade, setShowGrade] = useState(false);
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [isOpenTermPanel, setIsOpenTermPanel] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [showDirectionBanner, setShowDirectionBanner] = useState(true);
  const [isTermCleared, setIsTermCleared] = useState(false);
  const [isCourseCleared, setIsCourseCleared] = useState(false);
  const subjectsLookup = useSelector(selectSubjects);
  const collegeCourses = useSelector(selectCollegeCourses);
  const isLoading = useSelector(selectIsLoading);
  const coursesExtensions = useSelector(selectCoursesExtensions);
  const academicHistoryData = useSelector(selectAcademicHistory);
  const academicHistoryLocalData = useSelector(selectAcademicHistoryLocalData);
  const skillsAttachments = useSelector(selectSkillsAttachments);
  const courseErrors = errors?.[coursePrefix] as unknown as TCourseErrors;
  const terms = useMemo(() => data?.terms, [data]);
  const subjectOptions = useMemo(() => getDropDownOptions(subjectsLookup || []), [subjectsLookup]);
  const courseOptions = useMemo(() => getCollegeCourseOptions(collegeCourses || []), [collegeCourses]);
  const watchCompletionStatus = watch(`term.completionStatus.code`) || getValues(`term.completionStatus.code`);
  const termsOptions = useMemo(() => prepareTermsOptions(terms), [terms]);
  const watchTerm = getValues('term');
  const selectedTerm = useMemo(() => findSelectedTerm(watchTerm as ITerm), [watchTerm]);
  const enableCourseFields = !!(watchTerm?.type?.code && watchTerm?.academicYear);
  const gradeValue = watch(`${coursePrefix}.grade`);
  const selectedCourse =
    getValues('course.code') && getValues('course.title')
      ? {
          id: `${getValues('course.code')} - ${getValues('course.title')}`,
          text: `${getValues('course.code')} - ${getValues('course.title')}`,
        }
      : null;
  const source = getValues(`${coursePrefix}.source.code`);
  const [isManualCourse, setIsManualCourse] = useState<boolean>(false);
  const courseId = getValues('course')?.id;
  const sectionMediaDocs = useSelector(state => selectSectionMediaDocs(state, courseId));

  const { newCollegeIndex, setNewCollegeIndex, setNewCollege } = useCollegeIndex(academicHistoryData?.colleges);
  const { newTermIndex, setNewTerm } = useTermIndex(terms);
  const prefferedTermPosition = useMemo(() => {
    /* istanbul ignore next */
    if (newTermIndex > -1) {
      return newTermIndex;
    }
    return childPosition;
  }, [newTermIndex, childPosition]);

  useEffect(() => {
    if (terms) {
      const currentTerm = { ...(terms?.[prefferedTermPosition] || {}) };
      const currentCourse = { ...(terms?.[prefferedTermPosition]?.courses?.[coursePosition] || {}) };
      const extensionObject = coursesExtensions?.find(courseExt => courseExt.id === currentCourse.id);
      delete currentTerm.courses;
      reset({
        term: currentTerm,
        termPosition: prefferedTermPosition,
        course: Object.keys(currentCourse).length ? currentCourse : null,
        coursePosition,
        transferable: extensionObject?.transferable || false,
      });
    } else {
      reset({});
    }
    return () => {
      reset({});
    };
  }, [reset, terms, prefferedTermPosition, coursePosition, coursesExtensions]);

  useEffect(() => {
    if (watchCompletionStatus === 'COMPLETED') {
      setShowGrade(true);
    } else {
      setShowGrade(false);
    }
  }, [watchCompletionStatus]);

  useEffect(() => {
    setIsManualCourse(source === 'APPLICANT');
  }, [source]);

  useEffect(() => {
    if (terms && !!isCommunityCollege && isNewCourse) {
      setValue('transferable', true);
    }
  }, [isCommunityCollege, isNewCourse, setValue, terms]);

  useEffect(() => {
    if (!subjectsLookup) {
      dispatch(getLookUps('subjects'));
    }
  }, [dispatch, subjectsLookup]);

  // This logic works when creating term without even created college.
  // Generally When new college created, index will be created in locally and same exist in local state.
  // But once after college created in the backend, the newly created college might be in new index based on sorting.
  // Then UI will render wrong college information based on the local state.
  // To solve this issue, we are updating new index to the local state.
  useEffect(() => {
    /* istanbul ignore next */
    if (newCollegeIndex > -1) {
      setNewParentIndex?.(newCollegeIndex);
      setNewCollege(undefined);
      setNewCollegeIndex(-1);
    }
  }, [newCollegeIndex, setNewParentIndex, setNewCollege, setNewCollegeIndex]);

  useEffect(() => {
    /* istanbul ignore next */
    if (newTermIndex > -1) {
      setNewChildIndex?.(newTermIndex);
      setNewTerm(undefined);
    }
  }, [newTermIndex, setNewChildIndex, setNewTerm]);

  const closeTermPanel = () => {
    setIsOpenTermPanel(false);
  };

  const onSubmit = (termData: ITerm) => {
    const colleges = [...(academicHistoryData?.colleges || [])] as unknown as ICollege[];
    const collegesLocalData = [...(academicHistoryLocalData?.colleges || [])] as unknown as ICollege[];
    const allTerms = [...(colleges[parentPosition]?.terms || [])];
    const currentTermIndex = allTerms.length;
    setNewTerm(termData);
    setIsTermCleared(false);
    allTerms[currentTermIndex] = { ...termData, courses: [] };
    const currentCollegeData = collegesLocalData[parentPosition] || colleges[parentPosition];
    if (!currentCollegeData.id) {
      setNewCollege(currentCollegeData);
    }
    colleges[parentPosition] = {
      ...currentCollegeData,
      terms: allTerms,
    };
    const payload = {
      ...academicHistoryData,
      colleges,
    };
    dispatch(
      postAcademicHistory(sanitizePayload(Object.assign(payload)), () => {
        closeTermPanel();
        setTimeout(() => {
          if (Object.keys(getValues('course') || []).length) {
            setFocus(`${coursePrefix}.subject.code`);
          }
        }, 10);
      })
    );
  };

  const handleOpen = (event: React.SyntheticEvent) => {
    if ((event as React.ChangeEvent<HTMLInputElement>).target.value !== '') {
      setOpenAutocomplete(true);
    }
    resetlookupData('collegeCourses');
  };

  const onSearchCourse = debounce(
    (_event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
      if (value && reason === 'input') {
        dispatch(getCourses(alternateId, value, watchTerm.type.code, watchTerm.academicYear));
      } else {
        resetlookupData('collegeCourses');
      }
    },
    500
  );

  const otherCoursesUnderSameTerm = () => {
    const termPosition = getValues('termPosition');
    let otherCourses = { ...(terms?.[termPosition] || {}) }?.courses;
    otherCourses = otherCourses?.filter(
      course => course.code !== getValues('course.code') && course.title !== getValues('course.title')
    );
    return (
      <>
        {selectedTerm && otherCourses?.length ? (
          <Alert icon={false} role="presentation" type="info" sx={{ mb: '1rem' }} onClose={() => setShowBanner(false)}>
            <Typography component="div" variant="body3">
              {t('colleges.course.existingMsg')} <b>{selectedTerm?.text}</b>
            </Typography>
            {otherCourses?.map(course => {
              return (
                <Typography
                  key={course.id}
                  component="div"
                  variant="body2"
                >{`${course.code} - ${course.title}`}</Typography>
              );
            })}
          </Alert>
        ) : null}
      </>
    );
  };

  const handleTermPanel = () => {
    return (
      <FormProvider {...methods}>
        <form>
          <SidePanel
            size="small"
            open={isOpenTermPanel}
            onClose={closeTermPanel}
            title={t(`colleges.add${data?.termType?.code}`)}
            footerButtonConfig={{
              primary: {
                title: t('save_label'),
                props: {
                  'aria-label': t('save_label'),
                  color: getButtonColor(),
                  variant: 'contained',
                  disabled: !methods.formState.isValid,
                  onClick: methods.handleSubmit(onSubmit as SubmitHandler<FieldValues>),
                },
              },
              tertiary: {
                title: t('cancel_label'),
                props: {
                  color: getButtonColor(),
                  'aria-label': t('cancel_label'),
                  onClick: closeTermPanel,
                },
              },
            }}
            sx={sxSidePanel}
          >
            <CollegeTermForm
              collegeStartYear={fromYear}
              collegeEndYear={toYear}
              termList={termsOptions}
              collegeTermType={data?.termType}
            />
          </SidePanel>
        </form>
      </FormProvider>
    );
  };

  const handleClickAddTerm = () => {
    setIsOpenTermPanel(true);
    dispatch(setSkillsAttachments(skillsAttachments.slice(0, -1)));
  };

  const createFocus = (field: string) => {
    setTimeout(() => {
      setFocus(`${coursePrefix}.${field}`);
    }, 100);
  };

  const noOptionsText = <Typography sx={{ m: '1rem' }}>{t('manual.course.msg')}</Typography>;

  const addNewOptionButton = (
    <Box sx={{ textAlign: 'center' }}>
      <Divider sx={{ borderStyle: 'solid' }} />
      <Button
        tabIndex={0}
        variant="text"
        color="secondary"
        sx={{
          textDecoration: 'none',
          textTransform: 'uppercase',
          mt: '1rem',
          mb: '1.563rem',
        }}
        onClick={() => {
          setIsManualCourse(true);
          createFocus('code');
          if (courseErrors?.title) {
            clearErrors();
          }
        }}
        onKeyDown={e => {
          if (e.code === 'Enter' || e.code === 'Space') {
            setIsManualCourse(true);
            if (courseErrors?.title) {
              clearErrors();
            }
            createFocus('code');
          } else {
            setOpenAutocomplete(false);
            createFocus('credits');
          }
        }}
      >
        {t('manual.course.button.name')}
      </Button>
    </Box>
  );

  const noneGradeCss =
    gradeValue === 'None'
      ? {
          color: (theme: Theme) => theme.palette.error.main,
          [`&.Mui-focused`]: {
            color: (theme: Theme) => theme.palette.error.main,
          },
        }
      : {};

  const tag = `colleges/${data?.id}/terms/${terms?.[prefferedTermPosition]?.id}/courses/${terms?.[prefferedTermPosition]?.courses?.[coursePosition]?.id}`;

  return (
    <>
      {showDirectionBanner && (
        <Alert
          role="presentation"
          icon={false}
          type="warning"
          sx={{ mb: '1rem' }}
          onClose={() => setShowDirectionBanner(false)}
        >
          <Typography component="div" variant="body3">
            {t('colleges.course.directionText')}
          </Typography>
        </Alert>
      )}
      {showBanner && otherCoursesUnderSameTerm()}
      <FormControl fullWidth>
        <InputLabel htmlFor="name">{t('colleges.name')}</InputLabel>
        <InputBase
          inputProps={{
            'aria-label': 'collegename',
            maxLength: FIELD_LENGTH_200,
            ...DISABLE_AUTO_FILL,
          }}
          disabled
          value={data?.name}
        />
      </FormControl>
      <Grid container spacing={1}>
        <Grid item xs={6} md={6}>
          <FormControl fullWidth required error={isTermCleared}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <InputLabel htmlFor="term">{t('colleges.course.term')}</InputLabel>
              {isNewCourse && (
                <>
                  {isMobile ? (
                    <IconButton
                      sx={{ p: '0.25rem' }}
                      size="extra-small"
                      onClick={() => setIsOpenTermPanel(true)}
                      aria-label={t('colleges.course.addTerm')}
                    >
                      <IconAdd />
                    </IconButton>
                  ) : (
                    <Button variant="text" color={getButtonColor()} onClick={handleClickAddTerm}>
                      ADD
                    </Button>
                  )}
                </>
              )}
            </Box>
            <Dropdown
              id="term"
              fullWidth
              options={termsOptions}
              disabled={!isNewCourse}
              onChange={option => {
                const value = option as TAutocompleteOption<string>;
                if (value?.id) {
                  setValue('termPosition', value?.termPosition);
                  setValue('term', JSON.parse(value?.id));
                  setValue(
                    'coursePosition',
                    isNewCourse ? (terms?.[value?.termPosition as number]?.courses || []).length : coursePosition
                  );
                  setIsTermCleared(false);
                  setFocus(`${coursePrefix}.subject.code`);
                } else {
                  setValue('term', {});
                  setIsTermCleared(true);
                }

                trigger('term');
              }}
              value={selectedTerm?.id as string}
              placeholder={t('colleges.course.term_placeholder')}
            />
            <FormHelperText role="alert" id="term-error">
              {isTermCleared && t('colleges.error.course.term.required')}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={6}>
          <FormControl fullWidth error={!!courseErrors?.subject?.code}>
            <InputLabel htmlFor="subject">{t('colleges.course.subject')}</InputLabel>
            <Controller
              render={({ field: { ref, onChange, ...field } }) => (
                <Dropdown
                  id="subject"
                  fullWidth
                  {...field}
                  inputRef={ref}
                  options={subjectOptions}
                  onChange={option => {
                    setValue(`${coursePrefix}.subject.displayName`, option?.text);
                    return onChange(option?.id ?? '');
                  }}
                  inputProps={{
                    'aria-label': t('colleges.course.subject'),
                    'aria-describedby': 'subject-error',
                    ...DISABLE_AUTO_FILL,
                  }}
                  disabled={!enableCourseFields}
                  noOptionsText={t('colleges.course.noOptions')}
                />
              )}
              control={control}
              name={`${coursePrefix}.subject.code`}
            />
            <FormHelperText role="alert" id="subject-error">
              {courseErrors?.subject?.code?.message}
            </FormHelperText>
          </FormControl>
          <InputBase inputProps={{ type: 'hidden' }} {...register(`${coursePrefix}.subject.displayName`)} />
        </Grid>
      </Grid>
      {isCsuTenant() && isCommunityCollege ? (
        <>
          {!isManualCourse ? (
            <FormControl fullWidth required error={isCourseCleared}>
              <InputLabel htmlFor="title">{t('academicHistory.courseName')}</InputLabel>
              <TpAutocomplete
                id="title"
                open={openAutocomplete}
                value={selectedCourse}
                suggestionsLabel={`${t('colleges.course.suggestedCourse')} ${data?.name}:`}
                options={courseOptions}
                onChange={(_event, option) => {
                  const value = option as TAutocompleteOption<string>;
                  setValue(`${coursePrefix}.code`, value ? `${value?.coursePrefix}${value?.courseNumber}` : '');
                  setValue(`${coursePrefix}.title`, value?.courseTitle || '');
                  if (value?.courseUnitsMin) {
                    setValue(`${coursePrefix}.credits`, value?.courseUnitsMin);
                  }
                  setValue('geCertificationCode', value?.geCertificationCode || '');
                  setValue('geCertificationCodes', value?.geCertificationCodes || [value?.geCertificationCode]);
                  setValue(`${coursePrefix}.source`, {
                    code: 'ASSIST',
                    displayName: 'Assist',
                  });
                  setIsCourseCleared(value === null);
                  if (value === null) {
                    trigger([`${coursePrefix}.code`, `${coursePrefix}.title`]);
                  } else {
                    trigger(`${coursePrefix}.credits`);
                  }
                }}
                popupIcon={<IconCaretDown />}
                onOpen={handleOpen}
                onInputChange={(_event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
                  dispatch(getCourseApiStart());
                  onSearchCourse(_event, value, reason);
                }}
                loading={isLoading}
                disabled={!enableCourseFields}
                customInputProps={{
                  inputProps: {
                    maxLength: FIELD_LENGTH_80,
                  },
                }}
                noOptionsText={noOptionsText}
                addNewOptionButton={addNewOptionButton}
              />
              <FormHelperText role="alert" id="title-helper">
                {t('colleges.course.course.helper')}
              </FormHelperText>
              <FormHelperText role="alert" id="title-error">
                {isCourseCleared && t('colleges.error.course.required')}
              </FormHelperText>
            </FormControl>
          ) : (
            <CourseNumberAndName />
          )}
        </>
      ) : (
        <CourseNumberAndName />
      )}

      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl fullWidth required error={!!courseErrors?.credits}>
            <InputLabel htmlFor="credits">{t('colleges.course.credits')}</InputLabel>
            <Controller
              render={({ field: { ref, onChange, ...field } }) => {
                return (
                  <NumberFormat
                    {...field}
                    inputRef={ref}
                    decimalScale={2}
                    decimalSeparator="."
                    allowNegative={false}
                    fixedDecimalScale={false}
                    customInput={InputBase}
                    onValueChange={values => onChange(values?.floatValue)}
                    inputProps={{ 'aria-label': t('colleges.course.credits') }}
                    disabled={!enableCourseFields}
                  />
                );
              }}
              name={`${coursePrefix}.credits`}
              control={control}
            />
            <FormHelperText role="alert" id="credits-helper" sx={{ whiteSpace: 'normal' }}>
              {t('colleges.course.credits.helper')}
            </FormHelperText>
            <FormHelperText role="alert" id="credits-error" sx={{ whiteSpace: 'normal' }}>
              {courseErrors?.credits?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        {showGrade && (
          <>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth required error={!!courseErrors?.grade}>
                <InputLabel htmlFor="grade" sx={{ ...noneGradeCss }}>
                  {gradeValue === 'None' && (
                    <Icon
                      aria-label={t('colleges.course.noneGradeWarning')}
                      sx={{
                        color: theme => theme.palette.error.main,
                        fontSize: 15,
                        fontWeight: 'bold',
                        mr: 0.8,
                        mb: -0.25,
                      }}
                    >
                      IconAlertCritical
                    </Icon>
                  )}
                  {t('colleges.course.grade')}
                </InputLabel>
                <InputBase
                  inputProps={{
                    'aria-label': t('colleges.course.grade'),
                    maxLength: FIELD_LENGTH_200,
                    ...DISABLE_AUTO_FILL,
                  }}
                  {...register(`${coursePrefix}.grade`, {
                    onChange: () => {
                      if (getValues(`${coursePrefix}.normalizedGrade`)) {
                        setValue(`${coursePrefix}.normalizedGrade`, undefined);
                      }
                    },
                  })}
                />
                <FormHelperText role="alert" sx={{ whiteSpace: 'normal' }}>
                  {t('colleges.course.grade.helper')}
                </FormHelperText>
                <FormHelperText role="alert" sx={{ whiteSpace: 'normal' }}>
                  {courseErrors?.grade?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>

      {isCsuTenant() && (
        <FormControl fullWidth>
          <Controller
            name="transferable"
            defaultValue={false}
            render={({ field: { value, ...field } }) => (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <InputLabel>{t('colleges.course.transferable')}</InputLabel>
                <Switch
                  {...field}
                  checked={value}
                  color={getCheckboxColor()}
                  disabled={!!isCommunityCollege && !isManualCourse}
                  inputProps={{ 'aria-label': t('colleges.course.transferable') }}
                />
              </Box>
            )}
            control={control}
          />
        </FormControl>
      )}
      <AttachedFiles sectionName="collegeCourse" tag={tag} disabled={!isValid} />
      {!isCsuTenant() && <SkillsMultiselect tag={tag} disabled={!isValid} />}
      {deleteCourse && (
        <Box sx={{ mt: '1rem', mb: '1rem', justifyContent: 'center', display: 'flex' }}>
          <StyledDeleteButton onClick={() => setShowAlert(true)}>{t('colleges.course.remove')}</StyledDeleteButton>
        </Box>
      )}

      <ConfirmationDialog
        open={showAlert}
        text={t('colleges.deleteCollegeCourse')}
        confirmationText={t('academicHistory.deleteContent')}
        onClose={() => {
          setShowAlert(false);
        }}
        footerButtonConfig={{
          primary: {
            title: t('remove_label'),
            props: {
              onClick: () => {
                setShowAlert(false);
                deleteCourse?.(
                  prefferedTermPosition || 0,
                  'colleges',
                  parentPosition,
                  'terms',
                  coursePosition,
                  sectionMediaDocs?.length ? tag : undefined
                );
              },
            },
          },
          tertiary: {
            title: t('cancel_label'),
          },
        }}
      />
      {handleTermPanel()}
    </>
  );
};

export default CollegeCourseForm;
