/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { Icon } from '@liaison/liaison-ui';
import { IconButton } from '@mui/material';
import { StyledTooltip, sxIconHover } from 'userProfile/pages/ManageAccount/Privacy/Privacy.utils';

interface ITooltipHook {
  RenderTooltip: () => ReactElement;
  setTooltipOpen: Dispatch<SetStateAction<{ instruction: boolean }>>;
  handleTooltip: (location: string, flag: boolean) => void;
  tooltipOpen: { instruction: boolean };
}

const useTooltip = (title: ReactElement | string, ariaLabel: string): ITooltipHook => {
  const [tooltipOpen, setTooltipOpen] = useState({ instruction: false });

  const handleTooltip = (location: string, flag: boolean) => {
    setTooltipOpen({ ...tooltipOpen, [location]: flag });
  };

  const RenderTooltip = (): ReactElement => (
    <StyledTooltip
      open={tooltipOpen.instruction}
      componentsProps={{ tooltip: { role: 'button' } }}
      title={title}
      onClose={() => handleTooltip('instruction', false)}
    >
      <IconButton
        data-testid="icon info"
        onMouseOver={() => handleTooltip('instruction', true)}
        onFocus={() => handleTooltip('instruction', true)}
        sx={[sxIconHover, { p: 0, ml: '0.3rem' }]}
        size="small"
        edge="start"
        aria-label={ariaLabel}
      >
        <Icon>IconInfoSolid</Icon>
      </IconButton>
    </StyledTooltip>
  );

  return { RenderTooltip, setTooltipOpen, handleTooltip, tooltipOpen };
};

export default useTooltip;
