/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICampus } from 'transferPlanner/pages/CampusesAndPrograms/CampusesAndPrograms.utils';

type TError = string | null;

export interface ICampusesState {
  loading: boolean;
  campuses: ICampus[] | null;
  error: TError;
}

export const initialState: ICampusesState = {
  loading: false,
  campuses: null,
  error: null,
};

const campusesSlice = createSlice({
  name: 'campuses',
  initialState,
  reducers: {
    campusesStart: (state): ICampusesState => ({
      ...state,
      loading: true,
      error: null,
    }),
    campusesSuccess: (state, action: PayloadAction<ICampus[]>): ICampusesState => ({
      ...state,
      loading: false,
      campuses: action.payload,
    }),
    campusesFailure: (state, action: PayloadAction<TError>): ICampusesState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const { campusesStart, campusesSuccess, campusesFailure } = campusesSlice.actions;

export default campusesSlice.reducer;
