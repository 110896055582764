/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useMemo, useEffect, useState } from 'react';
import { Grid, FormControl, InputLabel, InputBase, FormHelperText, Typography, Box } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { Dropdown, TAutocompleteOption, Alert, IconInfoSquare } from '@liaison/liaison-ui';
import { RadioControl } from 'components/RadioControl';
import { useTranslation } from 'react-i18next';
import { getDropDownOptions, rangeOfYearsDropDown } from 'utils/utilities';
import MasterData from 'userProfile/constants/master';
import { DISABLE_AUTO_FILL } from 'constants/field';
import { StyledDeleteButton, StyledFormHelperText } from 'pages/Pages.styles';
import { IEnum, ITerm } from 'store/academicHistory/academicHistory.slice';
import { ConfirmationDialog } from 'components/ConfirmationDialog';

interface ICollegeTermForm {
  selectedTerm?: ITerm | undefined;
  collegeStartYear: number;
  collegeEndYear: number;
  termList: TAutocompleteOption<string>[];
  collegeTermType: IEnum | undefined;
  deleteTerm?: ((childPosition: number) => void) | null;
}

type TTermError = {
  type: { code: { message: string } };
  academicYear: { message: string };
  completionStatus: { message: string };
};
const CollegeTermForm = ({
  selectedTerm,
  collegeStartYear,
  collegeEndYear,
  termList,
  collegeTermType,
  deleteTerm,
}: ICollegeTermForm): ReactElement => {
  const {
    control,
    register,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useFormContext();
  const { t } = useTranslation();
  const [showBanner, setShowBanner] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showCompletionAlert, setShowCompletionAlert] = useState(false);
  const isEditForm = !!selectedTerm;
  const termErrors = errors as unknown as TTermError;
  const watchType = watch('type.displayName');
  const watchCompletionStatus = watch('completionStatus.code');
  const watchYear = watch('academicYear');
  const rangeOfYearsDropDownOptions = useMemo(
    () => rangeOfYearsDropDown(collegeStartYear, collegeEndYear),
    [collegeStartYear, collegeEndYear]
  );
  const termOptions = useMemo(() => getDropDownOptions(MasterData?.termOptions), []);
  const newTermList = useMemo(() => {
    if (isEditForm) {
      return termList?.filter(term => {
        const termValues = term.text?.split(' ');
        const currentAcademicYear = termValues.pop();
        const currentTermType = termValues.join(' ');
        return (
          currentTermType !== selectedTerm?.type?.displayName.toUpperCase() ||
          currentAcademicYear !== selectedTerm?.academicYear.toString()
        );
      });
    }
    return termList;
  }, [termList, isEditForm, selectedTerm]);

  useEffect(() => {
    if (selectedTerm) {
      reset(selectedTerm);
    } else {
      reset({});
    }
    return () => reset({});
  }, [reset, selectedTerm]);

  useEffect(() => {
    if (watchCompletionStatus === 'COMPLETED' && isEditForm) {
      setShowCompletionAlert(true);
    } else {
      setShowCompletionAlert(false);
    }
  }, [watchCompletionStatus, isEditForm]);

  const isDuplicateTerm = (termType: string | undefined, academicYear: string | undefined): boolean => {
    let duplicate = false;
    if (termType && academicYear) {
      duplicate = newTermList?.some(term => {
        const termValues = term.text?.split(' ');
        const currentAcademicYear = termValues.pop();
        const currentTermType = termValues.join(' ');
        return currentTermType === termType.toUpperCase() && currentAcademicYear === academicYear.toString();
      });
    }
    setShowBanner(duplicate);
    return duplicate;
  };
  return (
    <>
      {showBanner ? (
        <Alert icon={false} type="warning" sx={{ mb: '1rem' }} onClose={() => setShowBanner(false)}>
          <Typography component="div" variant="body3">
            {t('colleges.course.term.duplicate')}
          </Typography>
        </Alert>
      ) : null}

      {showCompletionAlert && (
        <Alert
          sx={{ mb: '1rem' }}
          severity="info"
          icon={<IconInfoSquare fontSize="medium" sx={{ bgColor: '#311B92', display: 'flex', alignItem: 'center' }} />}
        >
          <Box>
            <Typography variant="subtitle7">{t('colleges.course.term.completeAlert')}</Typography>
          </Box>
        </Alert>
      )}
      <Grid container spacing={1}>
        <Grid item xs={6} md={6}>
          <FormControl fullWidth required error={!!termErrors?.type?.code}>
            <InputLabel htmlFor="type">{t(`colleges.course.term.${collegeTermType?.code}`)}</InputLabel>
            <Controller
              render={({ field: { ref, onChange, ...field } }) => (
                <Dropdown
                  id="type"
                  {...field}
                  inputRef={ref}
                  options={termOptions}
                  onChange={option => {
                    if (!isDuplicateTerm(option?.text, watchYear)) {
                      setValue(`type.displayName`, option?.text);
                      return onChange(option?.id ?? '');
                    }
                    return onChange('');
                  }}
                  inputProps={{
                    'aria-label': t(`colleges.course.term.${collegeTermType?.code}`),
                    'aria-describedby': 'term-error',
                    ...DISABLE_AUTO_FILL,
                  }}
                />
              )}
              control={control}
              name="type.code"
            />
            <FormHelperText role="alert" id="type-error">
              {termErrors?.type?.code?.message}
            </FormHelperText>
          </FormControl>
          <InputBase inputProps={{ type: 'hidden' }} {...register('type.displayName')} />
        </Grid>
        <Grid item xs={6} md={6}>
          <FormControl fullWidth required error={!!termErrors?.academicYear}>
            <InputLabel htmlFor="academicYear">{t('colleges.course.term.academicYear')}</InputLabel>
            <Controller
              render={({ field: { ref, onChange, ...field } }) => (
                <Dropdown
                  id="academicYear"
                  {...field}
                  inputRef={ref}
                  value={field.value ? `${field.value}` : field.value}
                  options={rangeOfYearsDropDownOptions}
                  onChange={option => {
                    if (!isDuplicateTerm(watchType, option?.id)) {
                      return onChange(option?.id ?? '');
                    }
                    return onChange('');
                  }}
                  inputProps={{
                    'aria-label': t('colleges.course.term.academicYear'),
                    'aria-describedby': 'year-error',
                    ...DISABLE_AUTO_FILL,
                  }}
                />
              )}
              control={control}
              name="academicYear"
            />
            <FormHelperText role="alert" id="academicYear-error">
              {t(
                showBanner && !!termErrors?.academicYear
                  ? 'colleges.course.term.academicYear.alreadyTaken'
                  : termErrors?.academicYear?.message
              )}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>

      <FormControl fullWidth required>
        <InputLabel htmlFor="completionStatus">{t('colleges.course.term.completionStatus')}?</InputLabel>
        <Controller
          name="completionStatus.code"
          render={({ field: { ref, ...field } }) => (
            <RadioControl
              {...field}
              inputRef={ref}
              id="completionStatus"
              options={MasterData.completionStatus}
              error={!!termErrors?.completionStatus?.message}
              aria-describedby="completionStatus-error"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const displayName = MasterData.completionStatus.find(data => data.val === event?.target?.value)?.text;
                setValue('completionStatus.displayName', displayName);
                field.onChange(event?.target?.value);
              }}
            />
          )}
          control={control}
          defaultValue=""
        />
        <StyledFormHelperText role="alert" id="graduated-error">
          {termErrors?.completionStatus?.message}
        </StyledFormHelperText>
      </FormControl>
      <InputBase inputProps={{ type: 'hidden' }} {...register(`completionStatus.displayName`)} />
      {deleteTerm && (
        <Box sx={{ mt: '1rem', justifyContent: 'center', display: 'flex' }}>
          <StyledDeleteButton onClick={() => setShowAlert(true)}>
            {t('colleges.course.term.deleteBtn', { termType: collegeTermType?.displayName })}
          </StyledDeleteButton>
        </Box>
      )}
      <ConfirmationDialog
        open={showAlert}
        text={t('colleges.course.term.deleteTitle', {
          term: `"${selectedTerm?.type?.displayName} ${selectedTerm?.academicYear} ${collegeTermType?.displayName}"?`,
        })}
        info={t('colleges.course.term.deleteInfo', {
          termType: collegeTermType?.displayName,
          courseCount: selectedTerm?.courses?.length,
        })}
        confirmationText={t('colleges.course.term.deleteConfirmationText', { termType: collegeTermType?.displayName })}
        onClose={() => {
          setShowAlert(false);
        }}
        footerButtonConfig={{
          primary: {
            title: t('delete_label'),
            props: {
              onClick: () => {
                setShowAlert(false);
                deleteTerm?.(0);
              },
            },
          },
          tertiary: {
            title: t('cancel_label'),
          },
        }}
      />
    </>
  );
};
export default CollegeTermForm;
