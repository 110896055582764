/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React from 'react';
import {
  PERSONAL_INFORMATION,
  BIOGRAPHICAL_INFORMATION,
  ACADEMIC_GOALS,
  CAREER_GOALS,
  ACCOMPLISHMENT_EXPERIENCE,
  ACADEMIC_HISTORY,
  MEDIA_DOCUMENTS,
  LIVING_PROFILE,
} from 'userProfile/constants/routeNames';
import { PageLayout as UserProfilePageLayout } from 'userProfile/components/PageLayout';
import { PersonalInformation } from 'userProfile/pages/PersonalInformation';
import { BiographicalInformation } from 'userProfile/pages/BiographicalInformation';
import { AcademicGoals } from 'userProfile/pages/AcademicGoals';
import { CareerGoals } from 'userProfile/pages/CareerGoals';
import { AccomplishmentsAndExperiences } from 'userProfile/pages/AccomplishmentsAndExperiences';
import { AcademicHistory } from 'userProfile/pages/AcademicHistory';
import { MediaDocuments } from 'userProfile/pages/MediaDocuments';
import { LivingProfile } from 'userProfile/pages/LivingProfile';
import { MyAccount, Privacy } from 'userProfile/pages/ManageAccount';
import { ManageAccountLayout } from 'userProfile/components/ManageAccountLayout';
import { IFeature } from 'store/features/features.slice';
import { buildFeatureRouteObject, TRouteObject } from 'utils/features.utils';
import { LivingProfilePageLayout } from './components/LivingProfilePageLayout';

export const lpStudentRoutes = (
  features: IFeature[] | null
): {
  element: JSX.Element;
  children: TRouteObject[];
}[] => [
  {
    element: <UserProfilePageLayout />,
    children: [
      ...buildFeatureRouteObject(features, {
        feature: PERSONAL_INFORMATION,
        element: <PersonalInformation />,
      }),
      ...buildFeatureRouteObject(features, {
        feature: BIOGRAPHICAL_INFORMATION,
        element: <BiographicalInformation />,
      }),
      ...buildFeatureRouteObject(features, {
        feature: ACADEMIC_GOALS,
        element: <AcademicGoals />,
      }),
      ...buildFeatureRouteObject(features, {
        feature: CAREER_GOALS,
        element: <CareerGoals />,
      }),
      ...buildFeatureRouteObject(features, {
        feature: ACCOMPLISHMENT_EXPERIENCE,
        element: <AccomplishmentsAndExperiences />,
      }),
      ...buildFeatureRouteObject(features, {
        feature: ACADEMIC_HISTORY,
        element: <AcademicHistory />,
      }),
      ...buildFeatureRouteObject(features, {
        feature: MEDIA_DOCUMENTS,
        element: <MediaDocuments />,
      }),
    ],
  },
  {
    element: <ManageAccountLayout />,
    children: [
      {
        path: 'my-account',
        element: <MyAccount />,
      },
      {
        path: 'privacy-security',
        element: <Privacy />,
      },
    ],
  },
];

export const lpStudentOnlyRoutes = (
  features: IFeature[] | null
): {
  element: JSX.Element;
  children: TRouteObject[];
}[] => [
  {
    element: <LivingProfilePageLayout />,
    children: [
      ...buildFeatureRouteObject(features, {
        feature: LIVING_PROFILE,
        element: <LivingProfile />,
      }),
    ],
  },
];
