/*
 * Copyright 2022-2024 Liaison International. All Rights Reserved
 */

export const initialRotate = 0;

export const initialZoom = 1;

export const initialPosition = { x: 0.5, y: 0.5 };

export const isEdited = (rotate: number, zoom: number, position: typeof initialPosition): boolean =>
  !(
    rotate === initialRotate &&
    zoom === initialZoom &&
    position.x === initialPosition.x &&
    position.y === initialPosition.y
  );
