/*
 * Copyright 2022-2024 Liaison International. All Rights Reserved
 */

import React, { ReactElement, SyntheticEvent, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  Theme,
  Tooltip,
  DialogContent,
  Dialog,
  Grid,
  InputLabel,
  formControlClasses,
} from '@mui/material';
import {
  Dropdown,
  IconAdd,
  IconCards,
  IconList,
  IconShare,
  IconPadlockLocked,
  ToggleButton,
  MediaViewer,
  Search,
} from '@liaison/liaison-ui';

import { PageTitle } from 'components/PageTitle';
import { Spinner } from 'components/Spinner';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { StyledIconButton } from 'pages/Pages.styles';
import { setTitle } from 'utils/commonUtils';
import { setUi } from 'store/ui/ui.slice';
import {
  selectMediaDocs,
  selectSelectedMediaDoc,
  selectLoading,
  selectMediaDocsSortOrder,
} from 'userProfile/store/mediaDocuments/mediaDocuments.selectors';
import { IMediaDoc, setSelectedMediaDoc } from 'userProfile/store/mediaDocuments/mediaDocuments.slice';
import { StyledTooltip } from '../ManageAccount/Privacy/Privacy.utils';
import MediaDocumentForm from './MediaDocumentForm';
import MediaDocumentCardView from './MediaDocumentCardView';
import MediaDocumentListView from './MediaDocumentListView';
import {
  getMediaDocs,
  deleteMediaDoc,
  SHAREABLE,
  PRIVATE,
  EViewMode,
  convertMediaDocsToFilePreviews,
  getSearchPredicate,
  sortOptions,
  changeSortOrder,
} from './MediaDocuments.utils';

const MediaDocuments = (): ReactElement => {
  const [isDocumentFormOpen, setIsDocumentFormOpen] = useState(false);
  const [selectedToggle, setSelectedToggle] = useState(SHAREABLE);
  const [searchValue, setSearchValue] = useState('');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isNewFile, setIsNewFile] = useState(true);
  const mediaDocs = useSelector(selectMediaDocs);
  const sortOrder = useSelector(selectMediaDocsSortOrder);
  const selectedMediaDoc = useSelector(selectSelectedMediaDoc) as IMediaDoc;
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState<EViewMode>(EViewMode.card);
  const isTabletOrDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [selectedThumbnail, setSelectedThumbnail] = useState<number>(0);

  const mediaDocsToDisplay = useMemo(
    () =>
      mediaDocs?.filter(mediaDoc =>
        selectedToggle === SHAREABLE
          ? mediaDoc.shareable && getSearchPredicate(mediaDoc, searchValue)
          : !mediaDoc.shareable && getSearchPredicate(mediaDoc, searchValue)
      ) as IMediaDoc[],
    [mediaDocs, searchValue, selectedToggle]
  );

  useEffect(() => {
    dispatch(getMediaDocs());
  }, [dispatch]);

  useEffect(() => {
    if (!isDocumentFormOpen) {
      setTitle(t('mediaDocuments.pageTitle'));
    }
  }, [isDocumentFormOpen, t]);

  useEffect(() => {
    if (viewMode === EViewMode.list && !isTabletOrDesktop) {
      setViewMode(EViewMode.card);
    }
  }, [isTabletOrDesktop, viewMode]);

  const handleChange = (value: string) => {
    setSelectedToggle(value);
  };

  const filterData = (value: string) => {
    setSearchValue(value);
  };

  const openDocumentForm = (_e?: SyntheticEvent, selectedFile: IMediaDoc | null = null) => {
    setIsDocumentFormOpen(true);
    dispatch(setSelectedMediaDoc(selectedFile));
    setIsNewFile(!selectedFile);
  };

  /* istanbul ignore next */
  const onToggleMediaDocPreview = (index: number) => {
    setSelectedThumbnail(index);
    setIsPreviewOpen(!isPreviewOpen);
  };

  const dispatchSuccessSnackbar = (message: string, hideTitle: boolean) => {
    dispatch(
      setUi({
        name: 'succesSnackbar',
        state: { open: true, message, hideTitle },
      })
    );
  };

  const onClickDeleteMediaDoc = (mediaDoc: IMediaDoc) => {
    dispatch(setSelectedMediaDoc(mediaDoc));
    setShowConfirmationDialog(true);
  };

  const deleteFile = () => {
    dispatch(
      deleteMediaDoc(selectedMediaDoc?.id as string, () => {
        dispatch(
          getMediaDocs(() => {
            dispatchSuccessSnackbar(t('mediaDocuments.fileDelete.success'), true);
          })
        );
      })
    );
    setShowConfirmationDialog(false);
  };

  const sortLayout = (
    <>
      <Grid item xs="auto">
        <InputLabel htmlFor="sort">{t('mediaDocuments.sortBy')}</InputLabel>
      </Grid>
      <Grid
        item
        xs="auto"
        sx={{
          [`& .${formControlClasses.marginNormal}`]: {
            mb: 0,
          },
        }}
      >
        <Dropdown
          id="sort"
          value={sortOrder?.code}
          options={sortOptions}
          onChange={option => {
            if (option) {
              dispatch(changeSortOrder(option.id));
            }
          }}
          inputProps={{
            'aria-label': t('mediaDocuments.sortBy'),
          }}
        />
      </Grid>
    </>
  );

  return (
    <Box>
      {loading && <Spinner backdrop />}
      <PageTitle title={t('mediaDocuments.pageTitle')} description={t('mediaDocuments.pageDescription')} />
      <Box sx={{ pt: 2 }}>
        <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
          <Grid item xs={12} sm={4}>
            <Box sx={{ ml: { xs: 0, sm: -2 }, width: { sm: 250, md: 300 } }}>
              <ToggleButton
                options={[
                  {
                    icon: <IconShare sx={{ mr: 1 }} />,
                    text: t('mediaDocuments.shareable'),
                    val: SHAREABLE,
                  },
                  {
                    icon: <IconPadlockLocked sx={{ mr: 1 }} />,
                    text: t('mediaDocuments.private'),
                    val: PRIVATE,
                  },
                ]}
                onChange={handleChange as (value: string | number | boolean) => void}
                selectedValue={selectedToggle}
              />
            </Box>
          </Grid>
          <Grid item container xs={12} sm={7} justifyContent={{ xs: 'center', sm: 'flex-end' }} alignItems="center">
            <Grid item xs={11.5} sm={6}>
              <Search searchHandler={filterData} expanded fullWidth searchOffset={2} />
            </Grid>
            {isTabletOrDesktop && (
              <Grid item>
                <StyledTooltip
                  placement="left-start"
                  componentsProps={{ tooltip: { role: 'button' } }}
                  title={
                    <>
                      {t('mediaDocuments.tooltip', {
                        mode: viewMode === EViewMode.card ? 'list' : 'card',
                      })}
                    </>
                  }
                >
                  <IconButton
                    onClick={() => setViewMode(viewMode === EViewMode.card ? EViewMode.list : EViewMode.card)}
                    aria-label={t('mediaDocuments.tooltip', {
                      mode: viewMode === EViewMode.card ? 'list' : 'card',
                    })}
                  >
                    {viewMode === EViewMode.card ? <IconList /> : <IconCards />}
                  </IconButton>
                </StyledTooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 4, mt: 2 }}>
        <Grid item xs="auto" container alignItems="center" spacing={2}>
          <Grid item xs="auto">
            <Typography variant="subtitle2" component="h3" sx={{ textTransform: 'uppercase' }}>
              {selectedToggle === SHAREABLE ? t('mediaDocuments.shareableFiles') : t('mediaDocuments.privateFiles')}
            </Typography>
          </Grid>
          {isTabletOrDesktop && (
            <Grid item xs="auto" container alignItems="center" spacing={1}>
              {sortLayout}
            </Grid>
          )}
        </Grid>
        <Grid item xs="auto">
          <Tooltip title={t('mediaDocuments.addFile')}>
            <StyledIconButton
              size="small"
              edge="end"
              aria-label={t('mediaDocuments.addFile')}
              onClick={openDocumentForm}
            >
              <IconAdd />
            </StyledIconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {!isTabletOrDesktop && (
        <Grid container alignItems="center" sx={{ mb: 3, mt: -4 }} spacing={1}>
          {sortLayout}
        </Grid>
      )}
      <Typography variant="body2" sx={{ mb: 1.5, mt: -2 }}>
        {selectedToggle === SHAREABLE
          ? t('mediaDocuments.shareableFilesDescription')
          : t('mediaDocuments.privateFilesDescription')}
      </Typography>
      {viewMode === EViewMode.card ? (
        <MediaDocumentCardView
          mediaDocs={mediaDocsToDisplay}
          onToggleDrawer={openDocumentForm}
          onClickDeleteMediaDoc={onClickDeleteMediaDoc}
          onToggleMediaDocPreview={onToggleMediaDocPreview}
        />
      ) : (
        <MediaDocumentListView
          mediaDocs={mediaDocsToDisplay}
          onToggleDrawer={openDocumentForm}
          onClickDeleteMediaDoc={onClickDeleteMediaDoc}
          onToggleMediaDocPreview={onToggleMediaDocPreview}
        />
      )}
      <ConfirmationDialog
        open={showConfirmationDialog}
        text={t('mediaDocuments.fileDelete.message', { fileName: selectedMediaDoc?.name })}
        confirmationText={t('mediaDocuments.fileDelete.confirmationText')}
        onClose={() => {
          setShowConfirmationDialog(false);
        }}
        footerButtonConfig={{
          primary: {
            title: t('delete_label'),
            props: {
              onClick: deleteFile,
            },
          },
          tertiary: {
            title: t('cancel_label'),
          },
        }}
      />
      <Dialog open={isPreviewOpen} fullWidth maxWidth="xl">
        <DialogContent
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
          }}
        >
          <MediaViewer
            files={convertMediaDocsToFilePreviews(mediaDocsToDisplay)}
            open={isPreviewOpen}
            sx={{
              flex: 1,
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
            }}
            currentPage={selectedThumbnail}
            onClose={onToggleMediaDocPreview as () => void}
          />
        </DialogContent>
      </Dialog>
      <MediaDocumentForm isFormOpen={isDocumentFormOpen} setIsFormOpen={setIsDocumentFormOpen} isNewFile={isNewFile} />
    </Box>
  );
};

export default memo(MediaDocuments);
