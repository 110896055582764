/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Grid, useMediaQuery, Theme } from '@mui/material';

import { sxDashboardItem } from '../../Dashboard.styles';
import { WelcomeBack } from './WelcomeBack';
import { Overview } from './Overview';
import { WhatsNext } from './WhatsNext';

export const StatusSection = (): ReactElement => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  return (
    <Grid item container sx={{ ...(isMobile ? { ...sxDashboardItem, pl: 2, pt: 2 } : {}) }}>
      <Grid item xs={12}>
        <WelcomeBack />
      </Grid>
      <Grid item container xs={12}>
        <Overview />
      </Grid>
      {!isMobile && <WhatsNext />}
    </Grid>
  );
};
