/*
 * Copyright 2022-2023 Liaison International. All Rights ReserselectErrorSnackbarved
 */

import React, { ReactElement } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { t } from 'i18next';
import { Box } from '@mui/material';
import { Snackbar } from '@liaison/liaison-ui';
import { selectErrorSnackbar } from 'store/ui/ui.selectors';
import { setUi } from 'store/ui/ui.slice';

export const ErrorSnackbar = (): ReactElement => {
  const dispatch = useAppDispatch();
  const errorSnackbar = useAppSelector(selectErrorSnackbar);

  const closeAlert = () => {
    dispatch(
      setUi({
        name: 'errorSnackbar',
        state: { open: false, message: '', action: null, hideTitle: !!errorSnackbar?.hideTitle },
      })
    );
  };

  return (
    <Snackbar
      open={errorSnackbar?.open}
      onClose={closeAlert}
      type="error"
      title={!errorSnackbar?.hideTitle ? t('error_label') : undefined}
    >
      {errorSnackbar?.message}
      {errorSnackbar?.action && <Box pt={1}>{errorSnackbar.action}</Box>}
    </Snackbar>
  );
};
