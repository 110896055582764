/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
  Grid,
} from '@mui/material';
import { IconTrash } from '@liaison/liaison-ui';

import {
  setPendingAttachments,
  type IMediaDoc,
  type TSectionName,
} from 'userProfile/store/mediaDocuments/mediaDocuments.slice';
import { selectPendingMediaDocs } from 'userProfile/store/mediaDocuments/mediaDocuments.selectors';
import { resourceMap } from 'utils/utilities';
import { tableStyles } from 'pages/Pages.styles';
import { textEllipsis, wrapText } from 'userProfile/components/ViewBuilder/ViewBuilder.styles';
import { attachMediaDocs, getSectionName, getThumbUrl } from 'userProfile/pages/MediaDocuments/MediaDocuments.utils';
import { ConfirmationDialog } from 'components/ConfirmationDialog';

export type TFilesListProps = {
  sectionName: TSectionName;
  tag: string;
  mediaDocs?: IMediaDoc[];
};

export const FileList = ({ sectionName, mediaDocs, tag }: TFilesListProps): ReactElement => {
  const { t } = useTranslation();
  const isSectionIdExist = tag?.split('/').pop() !== 'undefined';
  const dispatch = useDispatch();
  const pendingMediaDocs = useSelector(state => selectPendingMediaDocs(state, sectionName));
  const attachedFiles = pendingMediaDocs || mediaDocs;

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [documentIdToDelete, setDocumentIdToDelete] = useState<string | null>(null);

  const showDeleteConfirmation = (idToDelete: string) => {
    setIsConfirmationOpen(true);
    setDocumentIdToDelete(idToDelete);
  };

  const unattachDocument = (idToDelete: string | null) => {
    if (isSectionIdExist) {
      dispatch(
        attachMediaDocs({
          resource: resourceMap[sectionName],
          documentIds: attachedFiles?.filter(doc => doc.id !== idToDelete).map(doc => doc.id as string),
          tag,
        })
      );
    } else {
      const fileIdsToKeep = attachedFiles?.filter(doc => doc.id !== idToDelete).map(doc => doc.id as string);
      dispatch(setPendingAttachments({ [sectionName]: fileIdsToKeep }));
    }

    setIsConfirmationOpen(false);
    setDocumentIdToDelete(null);
  };

  return (
    <>
      <TableContainer>
        {Boolean(attachedFiles?.length) && (
          <Table
            aria-label="table header"
            sx={{
              ...tableStyles.font,
              borderCollapse: 'unset',
              border: '1px solid',
              borderColor: theme => theme.palette.grey[300],
              borderRadius: 1,
              mt: 0.5,
            }}
            tabIndex={0}
          >
            <TableHead>
              <TableRow sx={tableStyles.header}>
                <TableCell align="left">{`${t(
                  pendingMediaDocs ? 'mediaDocuments.pending.list' : 'mediaDocuments.attaching.list'
                )} (${attachedFiles?.length})`}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  py: 1,
                },
              }}
            >
              {attachedFiles?.map(mediaDoc => {
                const { variants, id, name = '', sizeDisplay = '', mediaType = '' } = mediaDoc;
                return (
                  <TableRow key={id}>
                    <TableCell align="left" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Grid container columnSpacing={1} alignItems="center">
                        <Grid item xs="auto">
                          <Box
                            component="img"
                            src={getThumbUrl(variants)}
                            alt={`${name} picture`}
                            sx={{
                              height: { xs: 30, sm: 40 },
                              width: { xs: 30, sm: 40 },
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              overflow: 'hidden',
                            }}
                          />
                        </Grid>
                        <Grid item container xs>
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                wordBreak: 'break-word',
                                typography: { xs: 'body3', sm: 'subtitle3' },
                                ...wrapText,
                                ...textEllipsis,
                              }}
                            >
                              {name}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="caption" color="text.secondary" textTransform="uppercase">
                              {`${sizeDisplay} ${`${sizeDisplay && mediaType && '•'}`} ${mediaType}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <IconTrash
                        data-testid="delete-icon"
                        color="error"
                        sx={{ fontSize: '22px', cursor: 'pointer' }}
                        onClick={() => {
                          if (mediaDoc.id) {
                            if (isSectionIdExist) {
                              showDeleteConfirmation(mediaDoc.id);
                            } else {
                              unattachDocument(mediaDoc.id);
                            }
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {isSectionIdExist && (
        <ConfirmationDialog
          open={isConfirmationOpen}
          text={t('mediaDocuments.removing.title', { card: getSectionName(sectionName) })}
          confirmationText={t('mediaDocuments.removing.content')}
          onClose={() => {
            setIsConfirmationOpen(false);
            setDocumentIdToDelete(null);
          }}
          footerButtonConfig={{
            primary: {
              title: t('remove_label'),
              props: {
                onClick: () => unattachDocument(documentIdToDelete),
              },
            },
            tertiary: {
              title: t('cancel_label'),
              props: {
                onClick: () => {
                  setIsConfirmationOpen(false);
                  setDocumentIdToDelete(null);
                },
              },
            },
          }}
        />
      )}
    </>
  );
};
