/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconTransfer, SidePanel } from '@liaison/liaison-ui';
import { Box, Button, Divider, Grid, Step, StepLabel, Stepper, Theme, Typography, useMediaQuery } from '@mui/material';
import { setTitle } from 'utils/commonUtils';
import { Spinner } from 'components/Spinner';
import { ACADEMIC_HISTORY } from 'userProfile/constants/routeNames';
import { nameSpace } from 'transferPlanner/constants/general';
import { Trans, useTranslation } from 'react-i18next';
import { useTransferProgress } from 'transferPlanner/hooks/useTransferProgress';
import { sanitize } from 'dompurify';
import { DEFAULT_SANITIZE_CONFIG } from 'constants/sanitize';
import GpaCalculationErrorAlert from 'transferPlanner/components/GpaCalculationErrorAlert/GpaCalculationErrorAlert';
import { useFirstRenderStatus } from 'hooks/useFirstRenderStatus';
import Cart from 'transferPlanner/components/MyProgramsCart/Cart';
import { sxSidePanel } from 'pages/Pages.styles';
import About from '../AboutGenEd/About';
import { GoldenFour, SubjectRequirement } from '../AboutGenEd';

const TransferProgress = (): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const navigate = useNavigate();
  const firstRender = useFirstRenderStatus();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const {
    getActiveSteps,
    gpaCalculation,
    isLoading,
    unitsEarnedOrCompleted,
    isFeatureActiveForGPA,
    transferUnitLabel,
  } = useTransferProgress();
  const [sidePanelInformation, setSidePanelInformation] = useState<{
    isSidePanelOpen: boolean;
    title: string;
    screenName: string;
  }>({
    isSidePanelOpen: false,
    title: '',
    screenName: '',
  });

  const closeSidePanel = () => {
    setSidePanelInformation({
      ...sidePanelInformation,
      isSidePanelOpen: false,
      screenName: '',
    });
  };

  const openSidePanel = useCallback((title: string, screenName = 'About') => {
    setSidePanelInformation(prevState => ({
      ...prevState,
      isSidePanelOpen: true,
      title,
      screenName,
    }));
    setTitle(title);
  }, []);

  /* istanbul ignore next */
  const onBackClicked = () => {
    if (sidePanelInformation.screenName === 'GoldenFour' || sidePanelInformation.screenName === 'SubjectRequirement') {
      openSidePanel(t('generalEducation.aboutTitle'), 'About');
    } else {
      closeSidePanel();
    }
  };

  useEffect(() => {
    if (firstRender) {
      setTitle(t('transferProgress.header'));
    }
  }, [firstRender, t]);

  return (
    <>
      {isLoading && <Spinner backdrop data-testid="loading-spinner" />}
      <GpaCalculationErrorAlert />
      <SidePanel
        title={sidePanelInformation?.title || ''}
        open={sidePanelInformation.isSidePanelOpen}
        isBackdropClickEnabled={true}
        onBackClick={sidePanelInformation.screenName === 'About' ? undefined : onBackClicked}
        onClose={closeSidePanel}
        footerButtonConfig={{
          justifyContent: 'center',
          tertiary: {
            title: t('close_label'),
            props: {
              'aria-label': t('close_label'),
              variant: 'text',
              onClick: closeSidePanel,
            },
          },
        }}
        sx={sxSidePanel}
      >
        {sidePanelInformation.screenName === 'About' && <About onViewDetailsClicked={openSidePanel} />}
        {sidePanelInformation.screenName === 'GoldenFour' && <GoldenFour />}
        {sidePanelInformation.screenName === 'SubjectRequirement' && (
          /* istanbul ignore next */
          <SubjectRequirement openSidePanel={openSidePanel} closeSidePanel={closeSidePanel} />
        )}
      </SidePanel>
      <Box>
        <Typography variant="h2" sx={{ mb: '1.5rem' }}>
          {t('transferProgress.header')}
        </Typography>
        <Grid container sx={{ display: 'flex' }}>
          <Grid
            item
            xs={12}
            sm={4}
            lg={3}
            sx={{ display: 'flex', p: isMobile ? '1rem 0rem' : '0' }}
            alignItems="center"
          >
            <IconTransfer
              sx={{
                borderRadius: 2,
                fontSize: 75,
                p: 2.5,
                color: theme => theme.palette.primary.border,
                bgcolor: '#D9485F4D',
                mr: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'row' : 'column',
                alignItems: isMobile ? 'center' : 'baseline',
              }}
            >
              <Typography
                component="span"
                sx={{ fontSize: isMobile ? '1rem' : '.8rem', maxWidth: isMobile ? '48%' : '6rem' }}
              >
                {t(transferUnitLabel)}
              </Typography>
              <Typography component="span" sx={{ fontSize: isMobile ? '3.5rem' : '2rem', lineHeight: '1' }}>
                {unitsEarnedOrCompleted.toFixed(2) || 0}
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={8}
            lg={9}
            sx={{
              backgroundColor: theme => theme.palette.grey[100],
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Stepper
              activeStep={getActiveSteps()}
              orientation="horizontal"
              alternativeLabel={true}
              sx={{ width: '100%' }}
            >
              <Step data-testid="stepper1">
                <StepLabel sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="buttonSmall" display="block">
                    {t('transferProgress.stepper1')}
                  </Typography>
                  <Typography
                    onClick={() => openSidePanel(t('generalEducation.aboutTitle'))}
                    sx={{
                      fontSize: '.625rem',
                      fontWeight: 700,
                      color: '#1565C0',
                      cursor: 'pointer',
                    }}
                    tabIndex={0}
                  >
                    {t('transferProgress.whatIsThis')}
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="buttonSmall" display="block">
                    {t('transferProgress.stepper2')}
                  </Typography>
                  <Typography
                    onClick={() => openSidePanel(t('generalEducation.aboutTitle'))}
                    sx={{
                      fontSize: '.625rem',
                      fontWeight: 700,
                      color: '#1565C0',
                      cursor: 'pointer',
                    }}
                    tabIndex={0}
                  >
                    {t('transferProgress.whatIsThis')}
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="buttonSmall" display="block">
                    {t('transferProgress.stepper3')}
                  </Typography>
                  <Typography variant="buttonSmall" display="block">
                    {t('transferProgress.mail2Counselor')}
                  </Typography>
                </StepLabel>
              </Step>
            </Stepper>
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: '#676767', margin: '1.5rem 0rem' }} />

        <Grid
          container
          spacing={2}
          sx={{ display: 'flex', flexDirection: isMobile ? 'row' : 'row-reverse', mt: '1rem' }}
        >
          <Grid item xs={12} sm={3.3}>
            <Box
              sx={{
                backgroundColor: theme => theme.palette.accent.background,
                padding: '.7rem',
                border: theme => `1px solid ${theme.palette.accent.border}`,
                borderRadius: '.3rem',
              }}
            >
              <Typography sx={{ fontSize: '1.5rem' }}>
                {t('transferProgress.transferGpa').toUpperCase()}
                <Typography variant="h2" component="span">
                  {gpaCalculation?.transferGPA?.gpa ? gpaCalculation.transferGPA.gpa.toFixed(2) : 'N/A'}
                </Typography>
              </Typography>
              <Typography variant="body3" display="block">
                {t('transferProgress.unitsUsedInGPA')}
                {gpaCalculation?.transferGPA?.unitsAttempted?.toFixed(2) || 0}
              </Typography>
              <Typography variant="body3">
                {t('transferProgress.qualityPoints')} {gpaCalculation?.transferGPA?.qualityPoints?.toFixed(2) || 0}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8.7}>
            <Typography variant="body2" gutterBottom sx={{ mb: '1rem' }}>
              <Trans t={t}>transferProgress.msg1</Trans>
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ mb: '1rem' }}
              dangerouslySetInnerHTML={{
                __html: sanitize(t('transferProgress.msg2'), DEFAULT_SANITIZE_CONFIG),
              }}
            />
            {isFeatureActiveForGPA ? (
              <Typography variant="body2" gutterBottom sx={{ mb: '1rem' }}>
                <Trans t={t}>transferProgress.msg7</Trans>
              </Typography>
            ) : (
              <>
                <Typography variant="body2" gutterBottom sx={{ mb: '1rem' }}>
                  <Trans t={t}>transferProgress.msg3</Trans>
                </Typography>
                <Typography variant="body2" gutterBottom sx={{ mb: '1rem' }}>
                  <Trans t={t}>transferProgress.totalTransferUnits</Trans>{' '}
                  {gpaCalculation?.transferGPA?.unitsEarned?.toFixed(2) || 0}
                </Typography>
                <Typography variant="body2" gutterBottom sx={{ mb: '1rem' }}>
                  <Trans t={t}>transferProgress.msg6</Trans>
                </Typography>
              </>
            )}

            <Typography component="div">{t('transferProgress.msg4')}</Typography>
            <Typography component="ul">
              <Typography component="li">{t('transferProgress.msg4SubMsg1')}</Typography>
              <Typography component="li">{t('transferProgress.msg4SubMsg2')}</Typography>
              <Typography component="li">{t('transferProgress.msg4SubMsg3')}</Typography>
            </Typography>

            <Divider sx={{ borderColor: '#676767', margin: '1.5rem 0rem' }} />
            <Typography component="div">{t('transferProgress.msg5')}</Typography>

            <Button
              color="secondary"
              variant="outlined"
              sx={{ mt: '1.5rem', cursor: 'pointer' }}
              onClick={() => navigate(`/${ACADEMIC_HISTORY.path}`)}
            >
              {t('transferProgress.goToAcademicHistory')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Cart />
    </>
  );
};

export default TransferProgress;
