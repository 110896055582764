/* istanbul ignore file */
import React, { ReactElement, useState } from 'react';

import { Box, Typography, ClickAwayListener, IconButton, Button, type Theme } from '@mui/material';
import { IconAdd, IconCancel, IconCancelTransparent } from '@liaison/liaison-ui';

import { TMultiSelectOption } from '../MultiSelectWithSidePanel.utils';
import { StyledChip, tagContainer, ancherBtnMode } from '../MultiSelectWithSidePanel.styles';

type TTagsProps = {
  selectedOptions: TMultiSelectOption[];
  limitTags: number;
  multiSelectRef: React.MutableRefObject<HTMLInputElement | undefined>;
  open: boolean;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
  onDelete: (option: TMultiSelectOption[] | null | undefined) => void;
  isMobileView?: boolean;
  title: string;
  colorTheme?: 'primary' | 'secondary';
};

const Tags = ({
  selectedOptions,
  limitTags,
  multiSelectRef,
  open,
  handleClick,
  handleClose,
  onDelete,
  isMobileView = false,
  title,
  colorTheme = 'primary',
}: TTagsProps): ReactElement => {
  const [showAllTags, setShowAllTags] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setShowAllTags(false)}>
      <Box sx={tagContainer} ref={multiSelectRef} onClick={handleClick}>
        <Box sx={{ flex: isMobileView ? 1 : 0.9 }}>
          {selectedOptions?.map(
            (selectedOption, index) =>
              (index < limitTags || showAllTags || isMobileView) && (
                <StyledChip
                  label={selectedOption.text}
                  key={selectedOption.text}
                  aria-label={selectedOption.text}
                  data-testid={`${title}-tags`}
                  onDelete={e => {
                    onDelete(
                      selectedOptions.filter(option => {
                        return option !== selectedOption;
                      })
                    );
                    e.stopPropagation();
                  }}
                  deleteIcon={<IconCancelTransparent aria-label="CancelIcon" />}
                />
              )
          )}
          {!isMobileView && (
            <>
              {selectedOptions.length === 0 && <Typography variant="caption">Select one or more</Typography>}
              {selectedOptions.length > limitTags && !showAllTags && (
                <Button
                  disableRipple
                  sx={{ display: 'contents' }}
                  onClick={e => {
                    setShowAllTags(true);
                    e.stopPropagation();
                  }}
                >
                  <StyledChip label={`+${selectedOptions.length - limitTags}`} />
                </Button>
              )}
            </>
          )}
        </Box>
        {!isMobileView && (
          <Box sx={ancherBtnMode}>
            {!open ? (
              <IconButton
                color={colorTheme}
                size="small"
                sx={{
                  bgcolor: (theme: Theme) => theme.palette[colorTheme].background,
                }}
                aria-label={`Open ${title}`}
              >
                <IconAdd />
              </IconButton>
            ) : (
              <IconButton
                disableRipple
                size="small"
                sx={{ bgcolor: (theme: Theme) => theme.palette[colorTheme].dark }}
                onClick={e => {
                  handleClose();
                  e.stopPropagation();
                }}
                aria-label={`Close ${title}`}
              >
                <IconCancel sx={{ color: 'white' }} />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default Tags;
