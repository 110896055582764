/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { styled, Grid } from '@mui/material';

export const StyledPageGrid = styled(Grid)(({ theme: { breakpoints } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [breakpoints.only('xs')]: {
    marginBottom: '4.5rem',
  },
}));
