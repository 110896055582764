/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';

import { TRootState } from 'redux/store';
import { ICareerGoalsInfoState } from './careerInfo.slice';

const careerGoalsInfo = (state: TRootState): ICareerGoalsInfoState => state.careerGoalsInfo;

export const selectCareerGoals = createSelector(
  careerGoalsInfo,
  (stateData: ICareerGoalsInfoState) => stateData?.careerGoalsInfoData
);

export const selectLoading = createSelector(careerGoalsInfo, (stateData: ICareerGoalsInfoState) => stateData?.loading);
