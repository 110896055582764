/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo } from 'react';
import { useMediaQuery, Theme, Typography, Box, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { selectTenantConfig } from 'store/common/commonInfo.selectors';
import { Footer } from '@liaison/liaison-ui';
import { sanitize } from 'dompurify';
import { DEFAULT_SANITIZE_CONFIG } from 'constants/sanitize';
import { csuNameSpace, isCsuTenant, isLiaisonTenant } from 'utils/utilities';
import { useTranslation } from 'react-i18next';
import { StyledImg, StyledBox, StyledGridBackground, StyledOutlet, StyledGrid } from './BackgroundImgLayout.styles';

const BackgroundImgLayout = (): ReactElement => {
  const {
    logo = '',
    logoMobile = '',
    backgroundImage = '',
    footerLinks = [],
    socialMediaLinks = [],
    copyright = '',
    instructionalText,
  } = useSelector(selectTenantConfig) || {};
  const isMobileOrTable = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { t } = useTranslation(csuNameSpace.ns);

  return (
    <>
      <StyledGridBackground
        container
        item
        direction="row"
        justifyContent="space-between"
        background={`url(${backgroundImage})`}
      >
        <StyledGrid container item md={8} xs={12} direction="column" justifyContent="space-between">
          <Box display="flex" flexGrow={1} component="aside" aria-label="logo-container">
            <StyledImg
              src={isLiaisonTenant() ? logoMobile : logo}
              alt={`The-California-State-University-${isCsuTenant() ? 'Transfer-Planner' : 'Living-Profile'}-Logo`}
              height="auto"
              tabIndex={0}
            />
          </Box>
          {!isMobileOrTable && (
            <StyledBox>
              {isCsuTenant() && (
                <Box sx={{ pl: '1.5rem' }} component="aside" aria-label="instruction-container">
                  <Typography variant="h2" sx={{ color: '#ffffff', lineHeight: '2.5rem' }}>
                    {t('instructionalText.title')}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: '#ffffff' }}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(instructionalText?.keyClockLanding || '', DEFAULT_SANITIZE_CONFIG),
                    }}
                  />
                  <Divider sx={{ borderStyle: 'solid', borderColor: '#A6A6A6', mt: '1rem' }} />
                </Box>
              )}

              <Footer
                copyrightDangerouslySetInnerHTML={{
                  __html: copyright,
                }}
                footerLinks={footerLinks}
                socialLinks={socialMediaLinks}
                sxTextProps={{ color: '#FFFFFF !important', fontSize: '0.75rem' }}
              />
            </StyledBox>
          )}
        </StyledGrid>
        {isCsuTenant() && (
          <Box sx={{ px: '1.5rem', backgroundColor: '#ffffff', display: { md: 'none' } }} component="aside">
            <Typography variant="h2" sx={{ lineHeight: '2.5rem' }}>
              {t('instructionalText.title')}
            </Typography>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: sanitize(instructionalText?.keyClockLanding || '', DEFAULT_SANITIZE_CONFIG),
              }}
            />
            <Divider sx={{ borderStyle: 'solid', borderColor: '#A6A6A6', mt: '1rem' }} />
          </Box>
        )}

        <StyledOutlet item md={4} xs={12} justifyContent="center">
          <Outlet />
        </StyledOutlet>
      </StyledGridBackground>
      {isMobileOrTable && (
        <StyledBox sx={{ background: 'white' }}>
          <Footer
            copyrightDangerouslySetInnerHTML={{
              __html: copyright,
            }}
            applyLightContrastText
            footerLinks={footerLinks}
            socialLinks={socialMediaLinks}
            sxTextProps={{ fontSize: '0.75rem', color: '#75757d !important' }}
          />
        </StyledBox>
      )}
    </>
  );
};

export default memo(BackgroundImgLayout);
