/*
 * Copyright 2022-2024 Liaison International. All Rights Reserved
 */

/* eslint-disable no-param-reassign */
import { API } from 'constants/api';
import type { AnyAction, Dispatch } from 'redux';
import axios from 'axios';
import { TAppThunk, TRootState } from 'redux/store';
import { t } from 'i18next';

import {
  getMediaDocsStart,
  getMediaDocsSuccess,
  getMediaDocsFailure,
  initiateUploadMediaDocStart,
  initiateUploadMediaDocSuccess,
  initiateUploadMediaDocFailure,
  uploadMediaDocStart,
  uploadMediaDocSuccess,
  uploadMediaDocFailure,
  updateMediaDocUploadStatusStart,
  updateMediaDocUploadStatusSuccess,
  updateMediaDocUploadStatusFailure,
  updateMediaDocStart,
  updateMediaDocSuccess,
  updateMediaDocFailure,
  getMediaDocStart,
  getMediaDocSuccess,
  getMediaDocFailure,
  IMediaDoc,
  deleteMediaDocStart,
  deleteMediaDocSuccess,
  deleteMediaDocFailure,
  setSelectedMediaDoc,
  TVariant,
  setPendingAttachments,
  TSectionName,
} from 'userProfile/store/mediaDocuments/mediaDocuments.slice';
import { setUi } from 'store/ui/ui.slice';
import { getLoggedUser } from 'utils/keyCloakUtils';
import type { IMediaViewer } from '@liaison/liaison-ui/dist/components/MediaViewer/MediaViewer.utils';

export const PRIVATE = 'PRIVATE';
export const SHAREABLE = 'SHAREABLE';

export const sortOptions = [
  { id: 'UPLOAD_DATE', text: 'Date uploaded' },
  { id: 'ALPHABETICAL', text: 'Alphabetical' },
  { id: 'FILE_TYPE', text: 'File Type' },
];

export enum ErrorCode {
  FileInvalidType = 'file-invalid-type',
  FileTooLarge = 'file-too-large',
  FileTooSmall = 'file-too-small',
  TooManyFiles = 'too-many-files',
}

export interface IFileError {
  message: string;
  code: ErrorCode | string;
}

export interface IFileRejection {
  file: File;
  errors: IFileError[];
}

export enum MediaType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  DOCUMENT = 'DOCUMENT',
  MODEL = 'MODEL',
  OFFICE = 'OFFICE',
  OTHER = 'OTHER',
}

export const mediaTypes: Record<string, MediaType> = {
  jpg: MediaType.IMAGE,
  jpeg: MediaType.IMAGE,
  png: MediaType.IMAGE,
  gif: MediaType.IMAGE,
  tif: MediaType.IMAGE,
  tiff: MediaType.IMAGE,
  bmp: MediaType.IMAGE,
  tga: MediaType.IMAGE,
  heic: MediaType.IMAGE,
  heif: MediaType.IMAGE,
  m4v: MediaType.VIDEO,
  mov: MediaType.VIDEO,
  mp4: MediaType.VIDEO,
  wmv: MediaType.VIDEO,
  flv: MediaType.VIDEO,
  asf: MediaType.VIDEO,
  mpeg: MediaType.VIDEO,
  mpg: MediaType.VIDEO,
  mkv: MediaType.VIDEO,
  mp3: MediaType.AUDIO,
  wma: MediaType.AUDIO,
  ogg: MediaType.AUDIO,
  flac: MediaType.AUDIO,
  pdf: MediaType.DOCUMENT,
  txt: MediaType.OFFICE,
  doc: MediaType.OFFICE,
  docx: MediaType.OFFICE,
  ppt: MediaType.OFFICE,
  pptx: MediaType.OFFICE,
  xls: MediaType.OFFICE,
  xlsx: MediaType.OFFICE,
  rtf: MediaType.OFFICE,
  zip: MediaType.OTHER,
};

export const acceptedFileFormats: { [key: string]: string[] } = {
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
  'image/tif': ['.tif'],
  'image/tiff': ['.tiff'],
  'image/bmp': ['.bmp'],
  'image/tga': ['.tga'],
  'image/heic': ['.heic'],
  'image/heif': ['.heif'],
  'video/m4v': ['.m4v'],
  'video/mov': ['.mov'],
  'video/mp4': ['.mp4'],
  'video/wmv': ['.wmv'],
  'video/flv': ['.flv'],
  'video/asf': ['.asf'],
  'video/mpeg': ['.mpeg'],
  'video/mpg': ['.mpg'],
  'video/mkv': ['.mkv'],
  'audio/mp3': ['.mp3'],
  'audio/wma': ['.wma'],
  'audio/ogg': ['.ogg'],
  'audio/flac': ['.flac'],
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/rtf': ['.rtf'],
  'text/plain': ['.txt'],
  'application/zip': ['.zip'],
};

export const uploadStatus = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

// bytes in megabytes (MB) 1024*1024
export const MB = 1048576;

export const fileValidator = (file: File): IFileError | null => {
  if (file.type.startsWith('image/') && file.size > 5 * MB) {
    return {
      message: t('mediaDocuments.fileUpload.error.fileSize', { fileType: 'Image', maxFileSize: '5MB' }),
      code: ErrorCode.FileTooLarge,
    };
  }
  if ((file.type.startsWith('application/') || file.type.startsWith('text/')) && file.size > 10 * MB) {
    return {
      message: t('mediaDocuments.fileUpload.error.fileSize', { fileType: 'DOCUMENT', maxFileSize: '10MB' }),
      code: ErrorCode.FileTooLarge,
    };
  }
  if (file.type.startsWith('audio/') && file.size > 30 * MB) {
    return {
      message: t('mediaDocuments.fileUpload.error.fileSize', { fileType: 'Audio', maxFileSize: '30MB' }),
      code: ErrorCode.FileTooLarge,
    };
  }
  if (file.type.startsWith('video/') && file.size > 250 * MB) {
    return {
      message: t('mediaDocuments.fileUpload.error.fileSize', { fileType: 'Video', maxFileSize: '250MB' }),
      code: ErrorCode.FileTooLarge,
    };
  }
  return null;
};

export const enum EViewMode {
  card,
  list,
}

export const getThumbUrl = (variants: TVariant[] | undefined): string | undefined =>
  variants?.find(v => v.variant === 'thumb')?.ephemeralURL;

export const getEphemeralURL = (media: IMediaDoc | undefined): string | undefined => {
  if (!media) return undefined;

  const { mediaType, variants, externalURL, ephemeralURL, extension } = media;

  if (externalURL) {
    return externalURL;
  }
  if (mediaType === 'DOCUMENT') {
    return ephemeralURL;
  }
  if (mediaType === 'IMAGE') {
    if (extension === 'TGA' || extension === 'TIFF') {
      return variants?.find(v => v.variant === 'display')?.ephemeralURL;
    }
    return ephemeralURL;
  }
  if (mediaType === 'VIDEO') {
    if (variants) {
      return variants?.find(v => v.variant === 'display')?.ephemeralURL;
    }
    return ephemeralURL;
  }
  if (mediaType === 'AUDIO' || mediaType === 'OFFICE') {
    return variants?.find(v => v.variant === 'display')?.ephemeralURL;
  }
  return undefined;
};

export const convertMediaDocsToFilePreviews = (mediaDocs: IMediaDoc[] | undefined): IMediaViewer[] => {
  return (mediaDocs || []).map(doc => ({
    thumbnailUrl: getThumbUrl(doc.variants) || '',
    url: getEphemeralURL(doc) || '',
    fileName: doc.name || 'Unknown',
    fileDescription: doc.description || '',
    fileType: doc.extension || 'external_link',
    fileSize: doc.size ? String(doc.size) : '',
  }));
};

export const changeSortOrder =
  (order: string, successCallback?: () => void, failureCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(getMediaDocsStart());
      const { data } = await axios.put(`${API?.mediaDocuments}${getLoggedUser()}/order/${order}`);
      dispatch(getMediaDocsSuccess(data));
      successCallback?.();
    } catch (err) {
      const errorMessage = err?.response?.data?.message;
      const failureMessage = errorMessage ?? t('error.genericErrorMsg');
      dispatch(setUi({ name: 'apiStatus', state: { failure: true, failureMessage, hideTitle: !!errorMessage } }));
      dispatch(getMediaDocsFailure(failureMessage));
      failureCallback?.();
    }
  };

export const getMediaDocs =
  (successCallback?: () => void, failureCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(getMediaDocsStart());
      const { data } = await axios.get(`${API?.mediaDocuments}${getLoggedUser()}`);
      dispatch(getMediaDocsSuccess(data));
      successCallback?.();
    } catch (err) {
      const errorMessage = err?.response?.data?.message;
      const failureMessage = errorMessage ?? t('error.genericErrorMsg');
      dispatch(setUi({ name: 'apiStatus', state: { failure: true, failureMessage, hideTitle: !!errorMessage } }));
      dispatch(getMediaDocsFailure(failureMessage));
      failureCallback?.();
    }
  };

export const initiateUploadMediaDoc =
  (
    payload: IMediaDoc,
    successCallback?: (id: string, ephemeralURL: string) => void,
    failureCallback?: () => void
  ): TAppThunk =>
  async dispatch => {
    try {
      const loggedUserId = getLoggedUser();
      dispatch(initiateUploadMediaDocStart());
      const {
        data: { id, ephemeralURL },
      } = await axios.post(`${API?.mediaDocuments}${loggedUserId}/documents`, payload);
      dispatch(initiateUploadMediaDocSuccess());
      successCallback?.(id, ephemeralURL);
    } catch (err) {
      const errorMessage = err?.response?.data?.message;
      const failureMessage = errorMessage ?? t('error.genericErrorMsg');
      dispatch(setUi({ name: 'apiStatus', state: { failure: true, failureMessage, hideTitle: !!errorMessage } }));
      dispatch(initiateUploadMediaDocFailure(failureMessage));
      failureCallback?.();
    }
  };

export const attachMediaDocs =
  (
    payload: {
      tag?: string;
      documentIds?: string[];
      updateChildNodes?: boolean;
      resource?: string;
    },
    successCallback?: () => void,
    failureCallback?: () => void
  ): TAppThunk =>
  async dispatch => {
    const { tag, documentIds = [], updateChildNodes = false, resource } = payload;
    const modifiedPayload = { resource, tag, documentIds, ...(updateChildNodes ? { updateChildNodes } : {}) };
    try {
      const loggedUserId = getLoggedUser();
      const { data } = await axios.post(`${API?.mediaDocuments}${loggedUserId}/attach`, modifiedPayload);
      dispatch(getMediaDocsSuccess(data));
      dispatch(
        setUi({
          name: 'succesSnackbar',
          state: { open: true, message: t('success_message'), hideTitle: true },
        })
      );
      successCallback?.();
    } catch {
      dispatch(
        setUi({
          name: 'apiStatus',
          state: { failure: true, failureMessage: t('mediaDocuments.attaching.error'), hideTitle: true },
        })
      );
      failureCallback?.();
    }
  };

export const handleAttach = (
  state: TRootState,
  dispatch: Dispatch<AnyAction>,
  tag: string,
  resource: string,
  id: string
): void => {
  const alreadyAttachedDocIds = state.mediaDocuments.mediaDocsData?.documents
    ? (state.mediaDocuments.mediaDocsData?.documents
        .filter(doc => doc.attachedTo?.some(attach => attach.id === tag.split('/').pop()))
        .map(doc => doc.id) as string[])
    : [];
  dispatch(attachMediaDocs({ tag, resource, documentIds: [...alreadyAttachedDocIds, id] }));
};

export const postFileUrl =
  (
    payload: IMediaDoc & { tag?: string; resource?: string },
    sectionName?: TSectionName,
    shouldPostpone = false,
    successCallback?: () => void,
    failureCallback?: () => void
  ): TAppThunk =>
  async (dispatch, getState) => {
    try {
      const loggedUserId = getLoggedUser();
      dispatch(initiateUploadMediaDocStart());
      const { tag, resource, ...modifiedPayload } = payload;
      const {
        data: { id },
      } = await axios.post(`${API?.mediaDocuments}${loggedUserId}/documents`, modifiedPayload);

      if (shouldPostpone && sectionName) {
        const { pendingAttachments } = getState().mediaDocuments;
        dispatch(
          setPendingAttachments({ [sectionName as string]: [...(pendingAttachments?.[sectionName] ?? []), id] })
        );
        successCallback?.();
      } else if (tag && resource) {
        handleAttach(getState(), dispatch, tag, resource, id);
      } else {
        successCallback?.();
      }
    } catch (err) {
      const errorMessage = err?.response?.data?.message;
      const failureMessage = errorMessage ?? t('error.genericErrorMsg');
      dispatch(setUi({ name: 'apiStatus', state: { failure: true, failureMessage, hideTitle: !!errorMessage } }));
      dispatch(initiateUploadMediaDocFailure(failureMessage));
      failureCallback?.();
    }
  };

export const uploadMediaDoc =
  (file: File, ephemeralURL: string, successCallback?: () => void, failureCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(uploadMediaDocStart());
      await axios.put(ephemeralURL, file, {
        transformRequest: [
          /* istanbul ignore next */
          (data, headers) => {
            if (headers) {
              delete headers.Authorization;
              headers['Content-Type'] = file.type;
            }

            return data;
          },
        ],
      });
      dispatch(uploadMediaDocSuccess());
      successCallback?.();
    } catch (err) {
      const errorMessage = err?.response?.data?.message;
      const failureMessage = errorMessage ?? t('error.genericErrorMsg');
      dispatch(setUi({ name: 'apiStatus', state: { failure: true, failureMessage, hideTitle: !!errorMessage } }));
      dispatch(uploadMediaDocFailure(failureMessage));
      failureCallback?.();
    }
  };

export const updateMediaDocUploadStatus =
  (id: string, status: string, successCallback?: () => void, failureCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      const loggedUserId = getLoggedUser();
      dispatch(updateMediaDocUploadStatusStart());
      const { data } = await axios.put(`${API?.mediaDocuments}${loggedUserId}/documents/${id}/uploadStatus/${status}`);
      dispatch(updateMediaDocUploadStatusSuccess());
      dispatch(setSelectedMediaDoc(data));
      successCallback?.();
    } catch (err) {
      const errorMessage = err?.response?.data?.message;
      const failureMessage = errorMessage ?? t('error.genericErrorMsg');
      dispatch(setUi({ name: 'apiStatus', state: { failure: true, failureMessage, hideTitle: !!errorMessage } }));
      dispatch(updateMediaDocUploadStatusFailure(failureMessage));
      failureCallback?.();
    }
  };

const getMediaDocData = async (
  url: string,
  dispatch: Dispatch<AnyAction>,
  signal: AbortSignal,
  failureCallback?: () => void
  // eslint-disable-next-line consistent-return
) => {
  try {
    const response = await axios.get(url, { signal });
    return response.data;
  } catch (err) {
    /* istanbul ignore next */
    if (!signal.aborted) {
      const errorMessage = err?.response?.data?.message;
      const failureMessage = errorMessage ?? t('error.genericErrorMsg');
      dispatch(setUi({ name: 'apiStatus', state: { failure: true, failureMessage, hideTitle: !!errorMessage } }));
      dispatch(getMediaDocFailure(failureMessage));
      failureCallback?.();
    }
  }
};

export const getMediaDoc =
  (signal: AbortSignal, successCallback?: () => void, failureCallback?: () => void): TAppThunk =>
  async (dispatch, getState) => {
    const loggedUserId = getLoggedUser();
    const id = getState().mediaDocuments.selectedMediaDoc?.id as string;
    const url = `${API?.mediaDocuments}${loggedUserId}/documents/${id}`;
    dispatch(getMediaDocStart());
    let data = await getMediaDocData(url, dispatch, signal, failureCallback);
    dispatch(getMediaDocSuccess(data));
    successCallback?.();
    let thumbExist = data?.variants?.some((v: IMediaDoc) => v?.variant === 'thumb');
    /* istanbul ignore next */
    while (!thumbExist && !signal.aborted) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise(resolve => setTimeout(resolve, 750));
      // eslint-disable-next-line no-await-in-loop
      data = await getMediaDocData(url, dispatch, signal, failureCallback);
      thumbExist = data?.variants?.some((v: IMediaDoc) => v?.variant === 'thumb');
      if (thumbExist) {
        dispatch(getMediaDocSuccess(data));
      }
    }
  };

export const updateMediaDoc =
  (
    payload: IMediaDoc & { tag?: string; resource?: string },
    sectionName?: TSectionName,
    shouldPostpone = false,
    successCallback?: () => void,
    failureCallback?: () => void
  ): TAppThunk =>
  async (dispatch, getState) => {
    try {
      const loggedUserId = getLoggedUser();
      dispatch(updateMediaDocStart());
      const id = payload.id || (getState().mediaDocuments.selectedMediaDoc?.id as string);
      const { tag, resource, ...modifiedPayload } = payload;
      await axios.put(`${API?.mediaDocuments}${loggedUserId}/documents/${id}`, { ...modifiedPayload, id });
      dispatch(updateMediaDocSuccess());
      if (shouldPostpone && sectionName) {
        const { pendingAttachments } = getState().mediaDocuments;
        dispatch(
          setPendingAttachments({ [sectionName as string]: [...(pendingAttachments?.[sectionName] ?? []), id] })
        );
        successCallback?.();
      } else if (tag && resource) {
        handleAttach(getState(), dispatch, tag, resource, id);
      } else {
        successCallback?.();
      }
    } catch (err) {
      const errorMessage = err?.response?.data?.message;
      const failureMessage = errorMessage ?? t('error.genericErrorMsg');
      dispatch(setUi({ name: 'apiStatus', state: { failure: true, failureMessage, hideTitle: !!errorMessage } }));
      dispatch(updateMediaDocFailure(failureMessage));
      failureCallback?.();
    }
  };

export const deleteMediaDoc =
  (id: string, successCallback?: () => void, failureCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      const loggedUserId = getLoggedUser();
      dispatch(deleteMediaDocStart());
      await axios.delete(`${API?.mediaDocuments}${loggedUserId}/documents/${id}`);
      dispatch(deleteMediaDocSuccess());
      successCallback?.();
    } catch (err) {
      const errorMessage = err?.response?.data?.message;
      const failureMessage = errorMessage ?? t('error.genericErrorMsg');
      dispatch(setUi({ name: 'apiStatus', state: { failure: true, failureMessage, hideTitle: !!errorMessage } }));
      dispatch(deleteMediaDocFailure(failureMessage));
      failureCallback?.();
    }
  };

export const downloadMediaDoc =
  (mediaDoc: IMediaDoc, successCallback?: () => void, failureCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      const res = await axios.get(mediaDoc.ephemeralURL as string, {
        responseType: 'blob',
        transformRequest: [
          /* istanbul ignore next */
          (data, headers) => {
            if (headers) {
              delete headers.Authorization;
            }

            return data;
          },
        ],
      });

      const blob = new Blob([res.data], { type: mediaDoc.contentType });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = mediaDoc.name as string;

      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);

      successCallback?.();
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true, failureMessage: err.message } }));
      failureCallback?.();
    }
  };

export const getSearchPredicate = (mediaDoc: IMediaDoc, searchValue: string): boolean => {
  if (!mediaDoc.name && !mediaDoc.description) {
    return true;
  }
  const value = searchValue.toLowerCase();
  return mediaDoc.name?.toLowerCase().includes(value) || mediaDoc.description?.toLowerCase().includes(value) || false;
};

export const getSectionName = (sectionName: string): string => {
  switch (sectionName) {
    case 'colleges':
      return 'college';
    case 'highSchools':
      return 'high school';
    case 'experiences':
      return 'experience';
    case 'honorsOrAwards':
      return 'honor or award';
    default:
      return 'unknown';
  }
};
