/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useEffect, useMemo } from 'react';
import { Typography, FormControl, FormHelperText, InputBase, InputLabel, Box, Grid } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { TBiographicalInfoField } from 'userProfile/store/biographicalInfo/biographicalInfo.slice';
import { Dropdown, DateField } from '@liaison/liaison-ui';
import { getDropDownOptions, emptyOptions, Ioption } from 'utils/utilities';
import { selectVisaTypesLookup } from 'store/common/commonInfo.selectors';
import { useTranslation } from 'react-i18next';
import type { TBiographicFormErrors } from '../BiographicalInformationForm.validation';

interface IBiographicalInfoProps {
  data: TBiographicalInfoField | null;
}

export const handleEmptyLookup = (data: TBiographicalInfoField | null): Ioption[] => {
  if (!data) return emptyOptions;
  return [data?.visa?.visaType || emptyOptions[0]] as unknown as Ioption[];
};

const VisaInformationForm = ({ data }: IBiographicalInfoProps): ReactElement => {
  const {
    control,
    reset,
    register,
    setValue,
    formState: { errors: formErrors },
    trigger,
  } = useFormContext();
  const errors = formErrors as unknown as TBiographicFormErrors;
  const groupName = 'visa';
  const { t } = useTranslation();
  const visaTypesLookup = useSelector(selectVisaTypesLookup);
  const typesOfVisaOptions = useMemo(
    () => getDropDownOptions(visaTypesLookup || handleEmptyLookup(data)),
    [data, visaTypesLookup]
  );

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [reset, data]);

  return (
    <Box sx={{ mt: '2rem' }}>
      <Typography
        variant="h2"
        sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
      >
        {t('visaInfo.title')}
      </Typography>
      <FormControl fullWidth>
        <InputLabel htmlFor="visaType"> {t('visaInfo.type')}</InputLabel>
        <Controller
          render={({ field: { onChange, ...field } }) => (
            <Dropdown
              {...field}
              id="visaType"
              options={typesOfVisaOptions}
              fullWidth
              inputProps={{
                'aria-label': t('visaInfo.type'),
              }}
              onChange={option => {
                setValue(`${groupName}.type.displayName`, option?.text);
                return onChange(option?.id ?? '');
              }}
            />
          )}
          control={control}
          name={`${groupName}.type.code`}
        />
      </FormControl>
      <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.type.displayName`)} />
      <FormControl fullWidth error={!!errors?.[groupName]?.issuer}>
        <InputLabel htmlFor="Name">{t('visaInfo.issuer')}</InputLabel>
        <InputBase
          autoComplete="off"
          inputProps={{
            'aria-label': t('visaInfo.issuer'),
            'aria-describedby': 'visaIssuer-error',
            maxLength: 250,
          }}
          {...register(`${groupName}.issuer`)}
        />
        <FormHelperText role="alert" id="visaIssuer-error">
          {errors?.[groupName]?.issuer?.message}
        </FormHelperText>
      </FormControl>
      <Grid container spacing={2}>
        <Controller
          name={`${groupName}.validFrom`}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Grid item sm={12}>
              <FormControl fullWidth error={!!errors?.[groupName]?.validFrom}>
                <InputLabel htmlFor="validFrom">{t('visaInfo.validFrom')}</InputLabel>
                <DateField
                  {...field}
                  ref={ref}
                  error={!!error}
                  id="validFrom"
                  aria-describedby="validFrom-error"
                  onChange={dateVal => {
                    field.onChange(dateVal);
                    setTimeout(() => {
                      if (!errors?.[groupName]?.validFrom) {
                        trigger(`${groupName}.validTo`);
                      }
                    }, 0);
                  }}
                  aria-label={t('visaInfo.validFrom')}
                  accessibilityLabelPrefix={t('visaInfo.validFrom')}
                />
                <FormHelperText role="alert" id="validFrom-error">
                  {errors?.[groupName]?.validFrom?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
          control={control}
        />
      </Grid>
      <Grid container spacing={2}>
        <Controller
          name={`${groupName}.validTo`}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Grid item sm={12}>
              <FormControl fullWidth error={!!errors?.[groupName]?.validTo}>
                <InputLabel htmlFor="validTo">{t('visaInfo.validTo')}</InputLabel>
                <DateField
                  {...field}
                  ref={ref}
                  error={!!error}
                  id="validTo"
                  aria-describedby="validTo-error"
                  aria-label={t('visaInfo.validTo')}
                  accessibilityLabelPrefix={t('visaInfo.validTo')}
                />
                <FormHelperText role="alert" id="validTo-error">
                  {errors?.[groupName]?.validTo?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
          control={control}
        />
      </Grid>
    </Box>
  );
};

export default memo(VisaInformationForm);
