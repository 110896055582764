/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SidePanel, CollapsibleSection as Accordion, IconAdd } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import { selectFeatures } from 'store/features/features.selectors';
import { sxSidePanel } from 'pages/Pages.styles';
import {
  selectBiographicalInformation,
  selectLoading,
  selectBiographicalInformationLocalData,
} from 'userProfile/store/biographicalInfo/biographicalInfo.selectors';
import {
  TBiographicalInfoField,
  biographicalInfoSaveLocalData,
  biographicalInfoResetLocalData,
} from 'userProfile/store/biographicalInfo/biographicalInfo.slice';
import {
  selectCountriesLookup,
  selectOccupationLookup,
  selectVisaTypesLookup,
  selectRacesLookup,
  selectEthnicityLookup,
} from 'store/common/commonInfo.selectors';
import { ViewBuilder } from 'userProfile/components/ViewBuilder';
import { PageTitle } from 'components/PageTitle';
import { Spinner } from 'components/Spinner';
import { getButtonColor, sanitizePayload } from 'utils/utilities';
import { getLookUps, setTitle } from 'utils/commonUtils';
import { isFeatureActive } from 'utils/features.utils';
import { resetlookupData } from 'store/common/commonInfo.slice';
import config from 'userProfile/appConfig/biographicInfo.json';
import type { IIconSectionProps } from '@liaison/liaison-ui/dist/components/SectionHeader';
import {
  BackgroundInformationForm,
  CitizenshipInformationForm,
  GenderInformationForm,
  MilitaryInformationForm,
  ResidencyInformationForm,
  VisaInformationForm,
} from './BiographicalInformationForm';
import {
  getBiographicalInformation,
  postBiographicalInformation,
  postBiographicalRelationshipInformation,
} from './BiographicalInformation.utils';
import { validationSchema } from './BiographicalInformationForm.validation';
import RelationshipInformationForm from './BiographicalInformationForm/RelationshipInformationForm';
import RaceAndEthnicityForm from './BiographicalInformationForm/RaceAndEthnicityForm';

const BiographicalInformation = (): ReactElement => {
  const [isOpenRightSideDrawer, setIsOpenRightSideDrawer] = useState(false);
  const [biographicalInformationData, setBiographicalInformationData] = useState(
    useSelector(selectBiographicalInformation)
  );
  const { sections } = config;
  const { t } = useTranslation();
  const countriesLookup = useSelector(selectCountriesLookup);
  const visaTypesLookup = useSelector(selectVisaTypesLookup);
  const occupationLookup = useSelector(selectOccupationLookup);
  const racesLookup = useSelector(selectRacesLookup);
  const ethnicityLookup = useSelector(selectEthnicityLookup);
  const features = useSelector(selectFeatures);
  const isRelationshipActive = isFeatureActive(features, 'RELATIONSHIP');

  const [childDrawerInformation, setChildDrawerInformation] = useState({
    isOpenChildDrawer: false,
    isAddNew: false,
    titleName: 'Add Relat',
    childPosition: 0,
  });
  const dispatch = useDispatch();
  const biographicalInformationActualData = useSelector(selectBiographicalInformation);
  const biographicalInformationLocalData = useSelector(selectBiographicalInformationLocalData);
  const isLoading = useSelector(selectLoading);
  const methods = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(validationSchema, { abortEarly: false })(...args),
  });

  const methods2 = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(validationSchema, { abortEarly: false })(...args),
  });

  useEffect(() => {
    setTitle(t('biographicInfo.title'));
  }, [t]);

  useEffect(() => {
    dispatch(getBiographicalInformation());
  }, [dispatch]);

  useEffect(() => {
    if (!countriesLookup) {
      dispatch(getLookUps('countries'));
    }
  }, [dispatch, countriesLookup]);

  useEffect(() => {
    if (!occupationLookup) {
      dispatch(getLookUps('occupation'));
    }
  }, [dispatch, occupationLookup]);

  useEffect(() => {
    if (!visaTypesLookup) {
      dispatch(getLookUps('visaTypes'));
    }
  }, [dispatch, visaTypesLookup]);

  useEffect(() => {
    if (!racesLookup) {
      dispatch(getLookUps('races'));
    }
  }, [dispatch, racesLookup]);

  useEffect(() => {
    if (!ethnicityLookup) {
      dispatch(getLookUps('ethnicity'));
    }
  }, [dispatch, ethnicityLookup]);

  useEffect(() => {
    if (biographicalInformationLocalData) {
      if (!childDrawerInformation.isOpenChildDrawer) {
        setBiographicalInformationData({
          ...biographicalInformationLocalData,
          relationships: biographicalInformationActualData?.relationships,
        });
      }
    } else {
      setBiographicalInformationData(biographicalInformationActualData);
    }
  }, [biographicalInformationActualData, biographicalInformationLocalData, childDrawerInformation]);

  const openDrawer = () => {
    setIsOpenRightSideDrawer(true);
  };

  const openChildDrawer = (isNew?: boolean, childPosition?: number) => {
    if (isOpenRightSideDrawer) {
      dispatch(biographicalInfoSaveLocalData(methods.getValues()));
    }
    setChildDrawerInformation({
      ...childDrawerInformation,
      isOpenChildDrawer: true,
      isAddNew: isNew === undefined ? true : isNew,
      titleName: 'Add Relat',
      childPosition:
        childPosition === undefined ? biographicalInformationData?.relationships?.length || 0 : childPosition,
    });
  };

  const childProperties = (addmore = false) => {
    if (addmore) {
      return {
        actions: [
          {
            icon: <IconAdd />,
            label: t('relationship.add'),
            size: 'small',
            color: 'primary',
            onClick: openChildDrawer,
          },
        ] as IIconSectionProps[],
      };
    }
    return {};
  };

  const closeParent = () => {
    setIsOpenRightSideDrawer(false);
    dispatch(biographicalInfoResetLocalData());
    dispatch(resetlookupData(['residency_states', 'residency_counties']));
  };
  const closeChild = () => {
    setChildDrawerInformation({
      ...childDrawerInformation,
      isOpenChildDrawer: false,
    });
    methods.trigger();
    dispatch(resetlookupData(['states', 'counties']));
  };

  const onSubmit = (data: TBiographicalInfoField) => {
    dispatch(
      postBiographicalInformation(sanitizePayload(Object.assign(data)), () => {
        closeParent();
      })
    );
  };

  const onSubmitChildDrawer = (data: TBiographicalInfoField) => {
    const relationships = [...(biographicalInformationActualData?.relationships || [])];

    if (relationships != null) {
      if (data?.relationships?.[childDrawerInformation.childPosition] !== undefined) {
        relationships[childDrawerInformation.childPosition] = data.relationships[childDrawerInformation.childPosition];
      }
    }

    const payload = {
      ...biographicalInformationActualData,
      relationships,
    };

    dispatch(
      postBiographicalRelationshipInformation(sanitizePayload(Object.assign(payload)), () => {
        closeChild();
      })
    );
  };

  const deleteRelationship = (indexPosition: number) => {
    const relationships = [...(biographicalInformationActualData?.relationships || [])];

    if (relationships != null) {
      relationships.splice(indexPosition, 1);
    }

    const payload = {
      ...biographicalInformationActualData,
      relationships,
    };
    dispatch(
      postBiographicalRelationshipInformation(sanitizePayload(Object.assign(payload)), () => {
        setChildDrawerInformation({
          ...childDrawerInformation,
          isOpenChildDrawer: false,
        });
      })
    );
  };

  return (
    <Grid container>
      {isLoading && <Spinner backdrop />}
      <Box sx={{ width: '100%' }}>
        <PageTitle title={t('biographicInfo.title')} handleClick={openDrawer} />
        <FormProvider {...methods}>
          <form>
            <SidePanel
              size="small"
              open={isOpenRightSideDrawer}
              onClose={closeParent}
              title={t('biographicInfo.title')}
              isBackdropClickEnabled={true}
              footerButtonConfig={{
                primary: {
                  title: t('save_label'),
                  props: {
                    'aria-label': t('save_label'),
                    color: getButtonColor(),
                    variant: 'contained',
                    disabled: !methods.formState.isValid,
                    onClick: methods.handleSubmit(onSubmit),
                  },
                },
                tertiary: {
                  title: t('cancel_label'),
                  props: {
                    'aria-label': t('cancel_label'),
                    color: getButtonColor(),
                    onClick: closeParent,
                  },
                },
              }}
              sx={sxSidePanel}
            >
              <GenderInformationForm data={biographicalInformationData || null} />
              <CitizenshipInformationForm data={biographicalInformationData || null} />
              <ResidencyInformationForm data={biographicalInformationData || null} />
              <VisaInformationForm data={biographicalInformationData || null} />
              <MilitaryInformationForm data={biographicalInformationData || null} />
              <BackgroundInformationForm data={biographicalInformationData || null} />
              <RaceAndEthnicityForm data={biographicalInformationData || null} />
              {isRelationshipActive && (
                <RelationshipInformationForm
                  data={biographicalInformationData || null}
                  openChildDrawer={openChildDrawer}
                  isOpenChildDrawer={false}
                  childPosition={childDrawerInformation?.childPosition}
                />
              )}
            </SidePanel>
          </form>
        </FormProvider>
        <FormProvider {...methods2}>
          <form>
            <SidePanel
              size="small"
              title={t(`relationship.${childDrawerInformation.isAddNew ? 'add' : 'edit'}`)}
              isBackdropClickEnabled={true}
              open={childDrawerInformation.isOpenChildDrawer}
              onClose={closeChild}
              footerButtonConfig={{
                primary: {
                  title: 'Save',
                  props: {
                    'aria-label': 'Save',
                    color: getButtonColor(),
                    variant: 'contained',
                    disabled: !methods2.formState.isValid,
                    onClick: methods2.handleSubmit(onSubmitChildDrawer),
                  },
                },
                tertiary: {
                  title: 'Cancel',
                  props: {
                    'aria-label': 'Cancel',
                    color: getButtonColor(),
                    onClick: closeChild,
                  },
                },
              }}
              sx={sxSidePanel}
            >
              {childDrawerInformation.isOpenChildDrawer && (
                <RelationshipInformationForm
                  data={biographicalInformationData || null}
                  openChildDrawer={openChildDrawer}
                  isOpenChildDrawer={true}
                  childPosition={childDrawerInformation.childPosition}
                  deleteRelationship={!childDrawerInformation.isAddNew ? deleteRelationship : null}
                />
              )}
            </SidePanel>
          </form>
        </FormProvider>

        {Object.keys(sections).map(section => {
          if (!isRelationshipActive && section === 'familyInformation') {
            return true;
          }
          const accordionValues = Object(sections)[section];
          const responseData = Object(biographicalInformationActualData);
          return (
            <React.Fragment key={section}>
              <Box sx={{ mb: '1rem', mt: '1rem' }}>
                <Accordion
                  expanded
                  groupId=""
                  title={t(`${accordionValues.title}.title`).toUpperCase()}
                  {...childProperties(accordionValues?.addMore)}
                >
                  <ViewBuilder
                    fieldDetails={accordionValues?.fields}
                    responseData={responseData}
                    cardView={accordionValues?.cardView || false}
                    openChildDrawer={openChildDrawer}
                    localeKey={accordionValues.title}
                    selector=""
                    formTitle=""
                  />
                </Accordion>
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
    </Grid>
  );
};

export default memo(BiographicalInformation);
