/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, {
  ReactElement,
  useEffect,
  useState,
  SetStateAction,
  memo,
  CSSProperties,
  useMemo,
  useCallback,
  MouseEvent,
} from 'react';
import { Box, Grid, Portal, Theme, Typography, useMediaQuery } from '@mui/material';
import { FixedSizeGrid, GridOnScrollProps, areEqual } from 'react-window';
import { useNavigate } from 'react-router-dom';
import { Snackbar } from '@liaison/liaison-ui';
import InfiniteLoader from 'react-window-infinite-loader';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { selectActiveFollowedPrograms } from 'transferPlanner/store/programCart/programCart.selectors';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { setTitle } from 'utils/commonUtils';
import { IProgram } from 'transferPlanner/store/searchProgram/searchProgram.slice';
import { nameSpace } from 'transferPlanner/constants/general';
import { PROGRAM_PROGRESS } from 'transferPlanner/constants/routeNames';
import { setUi } from 'store/ui/ui.slice';
import { selectProgramCart } from 'store/ui/ui.selectors';
import { Spinner } from 'components/Spinner';

import { ProgramCard } from '../ProgramCard';
import { type ISnackbarState, ProgramView } from '../ProgramView';
import {
  TCountsByFirstCharacter,
  TFetchProgramDetailsResponse,
  fetchProgramDetails,
} from './ProgramCardsLazyScroll.utils';
import { findAlphabetValue } from '../ProgramsSearchView.utils';

export interface ListItemProps {
  columnIndex: number;
  rowIndex: number;
  style: CSSProperties;
  data: {
    columnCount: number;
    programs: IProgram[];
    firstCharacterCounts: [] | TCountsByFirstCharacter;
  };
}

const ListItem = memo(({ columnIndex, rowIndex, style, data }: ListItemProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [programDetails, setProgramDetails] = useState<TFetchProgramDetailsResponse>();
  const [isDetailViewOpen, setIsDetailViewOpen] = useState(false);
  const [followedProgramId, setFollowedProgramId] = useState<SetStateAction<number | undefined>>(undefined);
  const [isSuccessSnackbar, setIsSuccessSnackbar] = useState<ISnackbarState>({
    isVisible: false,
    isProgramAdded: false,
  });
  const [prevTitle, setPrevTitle] = useState(document.title);
  const [isFocused, setIsFocused] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeFollowedPrograms = useSelector(selectActiveFollowedPrograms);
  const programCart = useSelector(selectProgramCart);

  const { t } = useTranslation(nameSpace);

  const itemIndex = rowIndex * data.columnCount + columnIndex;
  const item = data.programs[itemIndex];

  const getIsDisabledFollowButton = useCallback(
    (id: number) => activeFollowedPrograms?.some(({ program }) => program?.id === id),
    [activeFollowedPrograms]
  );

  /* istanbul ignore next */
  const closeSnackbar = () => {
    setIsSuccessSnackbar({ isVisible: false, isProgramAdded: false });
    setFollowedProgramId(undefined);
    setIsFocused(false);
  };

  const handleProgramCart = (open = true) => {
    dispatch(setUi({ name: 'programCart', state: { open, prevTitle } }));
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (programCart?.open) {
      setTitle(t('myPrograms.pageTitle'));
      closeSnackbar();
      setIsDetailViewOpen(false);
    } else {
      setPrevTitle(document.title);
    }
  }, [programCart, t]);

  /* istanbul ignore next */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const element = document.getElementById('save-and-follow-program-snackbar');
      if (element && event.key === 'Tab') {
        element.focus();
        setIsFocused(true);
      }
    };
    if (!isFocused) document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isFocused]);

  const handleProgramKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') handleProgramCart();
  };

  /* istanbul ignore next */
  const handleNavigateKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      if (isSuccessSnackbar.isProgramAdded) {
        handleProgramCart();
      } else {
        navigate(`/${PROGRAM_PROGRESS}/${followedProgramId}`);
      }
    }
  };

  const loadProgramDetails = async (_event: MouseEvent, program: IProgram) => {
    try {
      const programDetailsResponse = await fetchProgramDetails({ campusesId: program.campusId, programId: program.id });

      setProgramDetails(programDetailsResponse);
      setIsDetailViewOpen(true);
    } catch {
      /* istanbul ignore next */
      setIsDetailViewOpen(false);
    }
  };

  /* istanbul ignore next */
  const newAphabetValue = useMemo((): number | null => {
    if (item) {
      const previousLetter = data.programs[itemIndex - 1]?.name.charAt(0);
      const currentLetter = item?.name.charAt(0);
      if (previousLetter !== currentLetter) {
        return findAlphabetValue(data.firstCharacterCounts, currentLetter);
      }
    }
    return null;
  }, [data.firstCharacterCounts, data.programs, item, itemIndex]);

  let content: IProgram;
  if (item) {
    content = item;
  } else return null;

  return (
    <>
      <Portal>
        <Snackbar
          type="success"
          icon={true}
          title={
            isSuccessSnackbar.isProgramAdded ? t('programs.programSavedHeader') : t('programs.programFollowHeader')
          }
          open={isSuccessSnackbar.isVisible}
          onClose={() => closeSnackbar()}
          sx={
            isMobile ? { width: '100%', left: '0', right: '0', maxWidth: '100%' } : { width: '68%', maxWidth: '100%' }
          }
          data-testid="SaveAndFollowProgramSnackBar"
        >
          <Typography
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: isMobile ? 'column' : 'row',
            }}
          >
            <Typography
              component="span"
              sx={{ mr: isMobile ? '0vw' : '10vw', fontSize: isMobile ? '.8rem' : 'inherit' }}
              id="save-and-follow-program-snackbar"
              tabIndex={0}
            >
              {isSuccessSnackbar.isProgramAdded
                ? `${t('programs.programSavedMsg')} `
                : `${t('programs.programFollowMsg')} `}

              <Typography
                component="span"
                onClick={() =>
                  isSuccessSnackbar.isProgramAdded
                    ? handleProgramCart()
                    : navigate(`/${PROGRAM_PROGRESS}/${followedProgramId}`)
                }
                onKeyDown={handleNavigateKeyDown}
                sx={{
                  textDecoration: 'underline',
                  color: '#1B5E20',
                  fontWeight: '500',
                  cursor: 'pointer',
                  fontSize: isMobile ? '.8rem' : 'inherit',
                }}
                tabIndex={0}
              >
                {isSuccessSnackbar.isProgramAdded ? t('programs.programSavedLink') : t('programs.programFollowLink')}
              </Typography>
              {t('programs.programSavedAndFollowLastWord')}
            </Typography>
            <Typography
              component="div"
              onClick={() => handleProgramCart()}
              onKeyDown={handleProgramKeyDown}
              sx={{
                textDecoration: 'underline',
                color: '#1B5E20',
                fontWeight: '500',
                cursor: 'pointer',
                fontSize: isMobile ? '.8rem' : 'inherit',
              }}
              tabIndex={0}
            >
              {t('programs.programSavedPrimaryLink')}
            </Typography>
          </Typography>
        </Snackbar>
      </Portal>
      <Grid
        item
        xs
        key={content?.id}
        onClick={(event: MouseEvent) => loadProgramDetails(event, content)}
        sx={{
          ...style,
          '&:hover': { cursor: 'pointer' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {newAphabetValue && (
          <Box
            component="h1"
            role="heading"
            onClick={(event: MouseEvent) => event.stopPropagation()}
            sx={{
              position: 'absolute',
              left: '0',
              top: '-1rem',
              cursor: 'default',
              fontWeight: 'bolder',
              fontSize: '1.2rem',
              backgroundColor: (theme: Theme) => theme.palette.common.white,
            }}
            id={`starting-alphabet-${content?.name.charAt(0)}`}
          >{`${content?.name.charAt(0)} (${newAphabetValue})`}</Box>
        )}
        <ProgramCard
          program={content}
          setIsSuccessSnackbar={setIsSuccessSnackbar}
          isDisabledFollowButton={getIsDisabledFollowButton(content?.id)}
          setFollowedProgramId={setFollowedProgramId}
        />
      </Grid>
      {programDetails && Object.keys(programDetails).length > 0 && (
        <ProgramView
          programDetails={programDetails}
          isOpen={isDetailViewOpen}
          setIsOpen={setIsDetailViewOpen}
          setIsSuccessSnackbar={setIsSuccessSnackbar}
          isDisabledFollowButton={getIsDisabledFollowButton(programDetails?.id)}
          setFollowedProgramId={setFollowedProgramId}
        />
      )}
    </>
  );
}, areEqual);

export type TProgramCardsLazyScrollProps = {
  infiniteLoaderRef?: React.MutableRefObject<null>;
  rowCount: number;
  loadMoreItems: (startIndex: number, stopIndex: number) => void | Promise<void>;
  programs: IProgram[];
  gridRef: React.MutableRefObject<FixedSizeGrid | null>;
  width: number;
  columnCount: number;
  firstLoading: boolean;
  loading: boolean;
  isNextPageAvailable: boolean;
  firstCharacterCounts: [] | TCountsByFirstCharacter;
  handleScroll: (props: GridOnScrollProps) => void;
};
export const ProgramCardsLazyScroll = memo(
  ({
    columnCount,
    programs,
    width,
    loadMoreItems,
    gridRef,
    infiniteLoaderRef,
    rowCount,
    firstLoading,
    loading,
    isNextPageAvailable,
    firstCharacterCounts,
    handleScroll,
  }: TProgramCardsLazyScrollProps): ReactElement => {
    const { height: windowHeight } = useWindowDimensions();
    const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.only('sm'));

    return firstLoading ? (
      <Box
        sx={{
          height: 'calc(100vh - 265px)',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </Box>
    ) : (
      <Box id="program-card-lazy-scroll-container">
        <InfiniteLoader
          isItemLoaded={index => !!programs[index]}
          itemCount={rowCount}
          loadMoreItems={loadMoreItems}
          ref={infiniteLoaderRef}
        >
          {({ onItemsRendered, ref }) => (
            <>
              <FixedSizeGrid
                className="List"
                columnCount={columnCount}
                columnWidth={width / columnCount - 5}
                height={(windowHeight || 360) - (isTablet ? 0 : 260)}
                width={width}
                rowCount={Math.ceil(rowCount / columnCount)}
                rowHeight={185}
                onItemsRendered={(gridProps: {
                  overscanRowStartIndex: number;
                  overscanRowStopIndex: number;
                  visibleRowStartIndex: number;
                  visibleRowStopIndex: number;
                }) => {
                  onItemsRendered({
                    overscanStartIndex: gridProps.overscanRowStartIndex * columnCount,
                    overscanStopIndex: gridProps.overscanRowStopIndex * columnCount,
                    visibleStartIndex: gridProps.visibleRowStartIndex * columnCount,
                    visibleStopIndex: gridProps.visibleRowStopIndex * columnCount,
                  });
                }}
                ref={(innerRef: null) => {
                  // eslint-disable-next-line no-param-reassign
                  gridRef.current = innerRef;
                  ref(innerRef);
                }}
                itemData={{ columnCount, programs, isNextPageAvailable, firstCharacterCounts }}
                onScroll={handleScroll}
              >
                {ListItem}
              </FixedSizeGrid>
              {loading && (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Spinner backdrop={true} />
                </Box>
              )}
            </>
          )}
        </InfiniteLoader>
      </Box>
    );
  }
);
