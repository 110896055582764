/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import mapIcon from 'assets/markerIcon.png';
import InformationWindow from './InformationWindow';
import { ICampus } from '../CampusesAndPrograms.utils';
import { markerStyle } from '../CampusesAndPrograms.styles';
import { EViewType } from '../ToggleView';

interface IMarker {
  show: boolean;
  campus: ICampus;
  setType: (type: EViewType) => void;
  title: string;
  lat?: number;
  lng?: number;
  focusedItemIndex?: number | undefined;
}

const Marker = ({ show, campus, setType, focusedItemIndex, ...rest }: IMarker): ReactElement => {
  return (
    <Box sx={markerStyle} data-testid="marker" {...rest}>
      <img
        src={mapIcon}
        alt={campus.name}
        style={{
          position: 'absolute',
          width: '50px',
          transform: 'translate(-50%, -50%)',
        }}
      />
      {show && <InformationWindow campus={campus} setType={setType} focusedItemIndex={focusedItemIndex} />}
    </Box>
  );
};

export default Marker;
