/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useCallback } from 'react';
import { Grid, useMediaQuery, Theme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Footer } from '@liaison/liaison-ui';
import { useDispatch, useSelector } from 'react-redux';
import { setUi } from 'store/ui/ui.slice';
import { selectFeatures } from 'store/features/features.selectors';
import { selectTenantConfig } from 'store/common/commonInfo.selectors';
import { selectUserName } from 'userProfile/store/personalInfo/personalInfo.selectors';
import { StyledPageGrid, StyledAvatarGrid } from 'pages/Pages.styles';
import { FooterSection } from 'components/FooterSection';
import { useImpersonation } from 'hooks/useImpersonation';
import { footerColor } from 'transferPlanner/constants/styles';
import { getProfileMenus } from '../UserNav/ProfileMenus';
import { Header } from '../Header';
import { UserAvatar } from '../UserAvatar';

import { UserNav } from '../UserNav';

const PageLayout = (): ReactElement => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const impersonationToken = useImpersonation();
  const features = useSelector(selectFeatures);
  const profileMenus = getProfileMenus(features);
  const { userFirstName, userLastName } = useSelector(selectUserName);

  const setOpenSidebar = useCallback(() => {
    dispatch(setUi({ name: 'sidebar', state: { open: true } }));
  }, [dispatch]);

  const tenantConfig = useSelector(selectTenantConfig);
  const { footerLinks, socialMediaLinks, copyright } = tenantConfig || {
    footerLinks: undefined,
    socialMediaLinks: undefined,
    copyright: '',
  };

  return (
    <Grid container direction="column">
      <Header onMenuClick={setOpenSidebar} />
      <Grid
        sx={{ height: 'calc( 100vh - 48px )', mt: { sm: 0, xs: impersonationToken ? 11 : 0 } }}
        container
        direction="row"
        component="main"
      >
        <Grid
          container
          component="section"
          sx={{
            bgcolor: theme => theme.palette.primary.main,
            padding: ['1px 0 0 0', '0 0 0 80px', '0 0 0 80px'],
            flexDirection: 'row',
            flexWrap: 'nowrap',
          }}
        >
          {!isMobile ? (
            <Grid
              container
              direction="column"
              alignItems="center"
              sx={{ width: '25%', marginRight: '1rem', marginTop: '-2rem' }}
            >
              <StyledAvatarGrid container item direction="column" alignItems="center">
                <UserAvatar firstName={userFirstName} lastName={userLastName} editable />
              </StyledAvatarGrid>
              <UserNav />
            </Grid>
          ) : null}
          <StyledPageGrid>
            <Outlet />
            <Footer
              copyrightDangerouslySetInnerHTML={{
                __html: copyright,
              }}
              footerLinks={footerLinks}
              socialLinks={socialMediaLinks}
              svgIconProps={{ sx: { color: (theme: Theme) => theme.palette.text.primary } }}
              sxTextProps={{ fontSize: '0.75rem', color: `${footerColor} !important` }}
            />
          </StyledPageGrid>
        </Grid>
      </Grid>
      {isMobile && <FooterSection footerSectionMenus={profileMenus} />}
    </Grid>
  );
};

export default PageLayout;
