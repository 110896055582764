/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { NAME_REGEX, US_POSTAL_CODE_REGEX, TEXT_REGEX, EMAIL_REG, URL_REG, LINKEDIN_URL_REG } from 'constants/regex';
import { DEFAULT_COUNTRY, DOB_FORMAT } from 'constants/general';
import moment from 'moment';
import * as yup from 'yup';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { t } from 'i18next';
import { MAX_BIG_LENGTH_FIELD } from 'constants/field';
import { isEmpty, min, today } from 'utils/utilities';
import type { IEmail, IPhone } from 'userProfile/store/personalInfo/personalInfo.slice';
import { validateDuplicates } from './Personalnformation.utils';

const phoneUtil = PhoneNumberUtil.getInstance();
const minDateFor13YearsOld = new Date(today.getFullYear() - 13, today.getMonth(), today.getDate());

export type TPersonalInfoFormErrors = {
  personal: {
    givenName: { message: string };
    middleName: { message: string };
    familyName: { message: string };
    salutation: { message: string };
    suffix: { message: string };
    birthInformation: { birthDate: { message: string } };
    hasFormerName: { message: string };
    alternateGivenName: { message: string };
    alternateMiddleName: { message: string };
    alternateFamilyName: { message: string };
    hasPreferredName: { message: string };
    preferredName: { message: string };
    preferredMiddleName: { message: string };
  };
  contact: {
    emails: {
      email: { message: string };
      type: { code: { message: string } };
    }[];
    phones: {
      phone: { message: string };
      type: { code: { message: string } };
    }[];
  };
  socialMedia: {
    url: { message: string };
  }[];
};
yup.addMethod(yup.array, 'unique', function callback(message, mapper = (item: unknown) => item) {
  return this.test('unique', message, function mapping(list) {
    return validateDuplicates(list, mapper);
  });
});

export const validatePhoneNumber = (
  value: string | undefined,
  context: yup.TestContext
): boolean | yup.ValidationError => {
  const { path, createError } = context;
  let isValidNumber;
  try {
    if (value && !Number.isNaN(value)) {
      const number = phoneUtil.parse(value);
      isValidNumber = phoneUtil.isValidNumber(number);
    }
  } catch (err) {
    return createError({ path, message: t('contactInfo.error.phone_invalid') });
  }

  if (value && !isValidNumber) {
    return createError({ path, message: t('contactInfo.error.phone_invalid') });
  }
  return true;
};

export const validationSchema = yup.object().shape({
  personal: yup.object().shape({
    hasFormerName: yup.string().oneOf(['Yes', 'No']),
    hasPreferredName: yup.string().oneOf(['Yes', 'No']),
    givenName: yup
      .string()
      .trim()
      .required(t('personalInfo.error.name_required'))
      .matches(RegExp(NAME_REGEX), t('error.notAllowed')),
    middleName: yup.string().trim().matches(RegExp(NAME_REGEX), t('error.notAllowed')),
    familyName: yup
      .string()
      .trim()
      .required(t('personalInfo.error.familyName_required'))
      .matches(RegExp(NAME_REGEX), t('error.notAllowed')),
    alternateGivenName: yup.string().when('hasFormerName', {
      is: (val: string) => {
        return val === 'Yes';
      },
      then: yup
        .string()
        .trim()
        .required(t('personalInfo.error.alternateGivenName_required'))
        .matches(RegExp(NAME_REGEX), t('error.notAllowed')),
    }),
    alternateMiddleName: yup.string().trim().matches(RegExp(NAME_REGEX), t('error.notAllowed')),
    alternateFamilyName: yup.string().when('hasFormerName', {
      is: (val: string) => {
        return val === 'Yes';
      },
      then: yup
        .string()
        .trim()
        .required(t('personalInfo.error.alternateFamilyName_required'))
        .matches(RegExp(NAME_REGEX), t('error.notAllowed')),
    }),
    preferredName: yup.string().when('hasPreferredName', {
      is: (val: string) => {
        return val === 'Yes';
      },
      then: yup
        .string()
        .trim()
        .required(t('personalInfo.error.preferredName_required'))
        .matches(RegExp(NAME_REGEX), t('error.notAllowed')),
    }),
    preferredMiddleName: yup.string().trim().matches(RegExp(NAME_REGEX), t('error.notAllowed')),
    salutation: yup.string().trim().matches(RegExp(NAME_REGEX), t('error.notAllowed')),
    suffix: yup.string().trim().matches(RegExp(NAME_REGEX), t('error.notAllowed')),
    birthInformation: yup.object().shape({
      birthDate: yup
        .string()
        .required(t('personalInfo.birthDate.required'))
        .matches(/^\d{4}([./-])\d{2}\1\d{2}$/, t('error.invalidDate'))
        .test('Is date valid', t('error.invalidDate'), value => {
          if (!value) return true;
          return moment(value, DOB_FORMAT, true).isValid();
        })
        .test('Min Date', t('error.minDate'), value => {
          if (value && moment(value, DOB_FORMAT, true).isValid()) {
            return new Date(new Date(min).setHours(0)).getTime() < new Date(value).getTime();
          }
          return true;
        })
        .test('Max date', t('error.maxDate'), value => {
          if (value && moment(value, DOB_FORMAT, true).isValid()) {
            return today.getTime() >= new Date(value).getTime();
          }
          return true;
        })
        .test('Min Age', t('personalInfo.minAge', { age: 13 }), value => {
          if (value && moment(value, DOB_FORMAT, true).isValid()) {
            return new Date(value).getTime() <= minDateFor13YearsOld.getTime();
          }
          return true;
        }),
    }),
  }),
  contact: yup.object().shape({
    emails: yup
      .array()
      .of(
        yup.object().shape(
          {
            email: yup
              .string()
              .trim()
              .when(['preferred', 'type.code'], {
                is: (preferred: boolean, code: string | undefined) => preferred || !isEmpty(code),
                then: yup.string().required(t('contactInfo.error.emails_required')),
              })
              .matches(RegExp(EMAIL_REG), t('contactInfo.error.emails_invalid'))
              .max(MAX_BIG_LENGTH_FIELD),
            type: yup.object().when(['preferred', 'email'], {
              is: (preferred: boolean, email: string) => preferred || !isEmpty(email),
              then: yup.object().shape({
                code: yup.string().required(t('contactInfo.error.emailType_required')),
              }),
            }),
          },
          [['email', 'type']]
        )
      )
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .unique(t('contactInfo.email.duplicate_err'), (a: IEmail) => a.email),
    phones: yup
      .array()
      .of(
        yup.object().shape(
          {
            phone: yup
              .string()
              .test('phone', validatePhoneNumber)
              .when(['preferred', 'type.code'], {
                is: (preferred: boolean, code: string | undefined) => preferred || !isEmpty(code),
                then: yup.string().required(t('contactInfo.error.phone_required')),
              }),
            type: yup.object().when(['preferred', 'phone'], {
              is: (preferred: boolean, email: string) => preferred || !isEmpty(email),
              then: yup.object().shape({
                code: yup.string().required(t('contactInfo.error.phoneType_required')),
              }),
            }),
          },
          [['phone', 'type']]
        )
      )
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .unique(t('contactInfo.phone.duplicate_err'), (a: IPhone) => a.phone),
  }),
  socialMedia: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          url: yup.string().test({
            name: 'is valid URL',
            test(val, ctx) {
              const REG_EXP = ctx.parent.type?.code === 'LINKEDIN' ? LINKEDIN_URL_REG : URL_REG;
              if (val && !val?.match(new RegExp(REG_EXP, 'i'))) {
                return ctx.createError({ message: t('socialMedia.url.error') });
              }
              return true;
            },
          }),
          type: yup.object().shape({
            code: yup.string(),
            displayName: yup.string(),
          }),
        })
        .nullable()
    )
    .min(0),
});

export const validationSchemaAddress = yup.object().shape({
  contact: yup.object().shape({
    addresses: yup.array().of(
      yup.object().shape({
        type: yup.object().shape({
          code: yup.string().required(t('address.error.addressType_required')),
        }),
        address1: yup
          .string()
          .required(t('address.error.address1_required'))
          .matches(RegExp(TEXT_REGEX), t('error.notAllowed')),
        address2: yup.string().matches(RegExp(TEXT_REGEX), t('error.notAllowed')),
        city: yup
          .string()
          .required(t('address.error.city_required'))
          .matches(RegExp(TEXT_REGEX), t('error.notAllowed')),
        postalCode: yup
          .string()
          .trim()
          .required(t('address.error.postalCode_required'))
          .when('country.code', {
            is: (country: string) => {
              return country === DEFAULT_COUNTRY;
            },
            then: yup.string().matches(RegExp(US_POSTAL_CODE_REGEX), t('error.notAllowed')),
            otherwise: yup.string().max(10, t('address.error.postalCode_max')),
          }),

        region: yup.object().shape({
          code: yup.string().required(t('address.error.region_required')),
        }),
        country: yup.object().shape({
          code: yup.string().required(t('address.error.country_required')),
        }),
      })
    ),
  }),
});
