/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    sidebar: { open: false },
    collegeSidebar: { open: false, success: false },
    succesSnackbar: { open: false, message: '', hideTitle: false, autoHideDuration: null, isManualClose: false },
    errorSnackbar: { open: false, message: '', title: '', action: null, hideTitle: false },
    genEdReqSidebar: { open: false },
    profileViewSidebar: { open: false },
    shareProfileSidebar: { open: false },
    avatarSidebar: { open: false },
    posterSidebar: { open: false },
    apiStatus: { success: false, failure: false, failureMessage: '', hideTitle: false },
    welcomeModal: { open: false },
    footerModal: { isOpen: false },
    gradeLevelPosition: { index: 0 },
    programCart: { open: false, prevTitle: '' },
    keycloakInitiated: false,
  },
  reducers: {
    setUi: (state, action) => ({
      ...state,
      [action.payload.name]: action.payload.state,
    }),
  },
});

export const { setUi } = uiSlice.actions;

export default uiSlice.reducer;
