/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo } from 'react';
import { Box, Typography } from '@mui/material';
import { IconAlertCritical } from '@liaison/liaison-ui';
import { useTranslation, Trans } from 'react-i18next';
import { csuNameSpace } from 'utils/utilities';
import {
  TFollowedProgramModified,
  ISelectedProgram,
  IProgram,
} from 'transferPlanner/store/programCart/programCart.slice';
import { MAX_FOLLOWED_PROGRAMS } from 'transferPlanner/constants/general';
import MyProgramCard from './MyProgramCard/MyProgramCard';

interface IFollowedPrograms {
  followedPrograms?: TFollowedProgramModified[] | null;
  activeEnrolledProgramId: number | undefined;
  handleClick: (name: string, selectedProgram: ISelectedProgram) => void;
  enrollBtnDisabled: boolean;
  tspEligibleFlags?: IProgram[] | null;
  hasError?: boolean;
}

const FollowedPrograms = ({
  followedPrograms,
  activeEnrolledProgramId,
  handleClick,
  enrollBtnDisabled,
  tspEligibleFlags,
  hasError,
}: IFollowedPrograms): ReactElement => {
  const { t } = useTranslation(csuNameSpace.ns);
  return (
    <Box sx={{ py: '1.5rem' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left' }}>
        {hasError && (
          <IconAlertCritical
            data-testid="icon_critical"
            style={{ color: '#891515' }}
            fontSize="small"
            sx={{ mr: '0.5rem' }}
          />
        )}
        <Typography component="h2" variant="h6">
          {t('programs.followed_title')} ({followedPrograms?.length}/{MAX_FOLLOWED_PROGRAMS})
        </Typography>
      </Box>
      {followedPrograms && followedPrograms.length > 0 ? (
        followedPrograms.map((currentProgram: TFollowedProgramModified) => (
          <React.Fragment key={currentProgram.program.id}>
            <MyProgramCard
              isSavedProgram={false}
              activeEnrolledProgramId={activeEnrolledProgramId}
              selectedProgram={currentProgram}
              handleClick={handleClick}
              enrollBtnDisabled={enrollBtnDisabled}
              tspEligibleFlags={tspEligibleFlags}
              hasError={hasError}
            />
          </React.Fragment>
        ))
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mt: '1rem' }}>
          <Typography variant="body3" sx={{ textAlign: 'center' }}>
            <Trans t={t}>programs.no_followed_program</Trans>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default memo(FollowedPrograms);
