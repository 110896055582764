/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import * as yup from 'yup';
import moment from 'moment';
import { t } from 'i18next';

import { yearValidator } from 'utils/utilities';

export const validationSchema = yup.object().shape({
  startDate: yup
    .string()
    .test('Validate date', t('error.invalidDate'), value => {
      if (value) {
        return moment(value, 'YYYY-MM', true).isValid();
      }
      return true;
    })
    .test('Is date in correct range', t('common.error.date.yearRange'), value => {
      if (value) {
        return yearValidator(value);
      }
      return true;
    }),
});
