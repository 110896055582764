/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import axios from 'axios';
import { API } from 'constants/api';
import { TAppThunk } from 'redux/store';
import { t } from 'i18next';
import { getLoggedUser } from 'utils/keyCloakUtils';
import { setUi } from 'store/ui/ui.slice';
import { termsApiFailure, termsApiStart, termsApiSuccess, ITerms } from 'store/common/commonInfo.slice';

export const getTermsData =
  (tenant: string): TAppThunk =>
  async dispatch => {
    try {
      dispatch(termsApiStart());
      const { data } = await axios.get(`${API?.profileInformation}${getLoggedUser()}/tenant/${tenant}/termsConditions`);
      dispatch(termsApiSuccess(data));
    } catch (err) {
      dispatch(termsApiFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const postTermsData =
  (payload: ITerms, successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      const loggedUserId = getLoggedUser();
      dispatch(termsApiStart());
      const modifiedPayload = {
        ...payload,
        profileId: loggedUserId,
      };
      const { data } = await axios.post(`${API?.profileInformation}${loggedUserId}/termsConditions`, modifiedPayload);
      dispatch(termsApiSuccess(data));
      successCallback?.();
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      dispatch(termsApiFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };
