/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Ioption } from 'utils/utilities';
import { modifyBooleanFields } from 'userProfile/pages/BiographicalInformation/BiographicalInformation.utils';

type TError = string | null;

export interface IGenderInfo {
  legalSex: Ioption;
  genderIdentity: string;
  pronouns: Ioption;
}

export interface ICitizenshipInfo {
  status: Ioption;
  country: Ioption;
  dualCitizenship: string;
  secondCountry: Ioption;
}
export interface IRelationship {
  relationship: Ioption;
  givenName: string;
  familyName: string;
  gender: {
    legalSex: Ioption;
  };
  living: string;
  occupation: Ioption;
  address: {
    country: Ioption;
    region: Ioption;
    county: Ioption;
  };
  educationLevel: Ioption;
  inPrimaryHousehold: string;
}
export interface IRaceOption {
  code: string;
  displayName: string;
  other?: string;
  classifications?: {
    code: string;
    displayName: string;
    other?: string;
  }[];
}
export interface IRacesAndEthnicity {
  hispanicLatino?: Ioption;
  ethnicities?: Ioption[];
  races?: IRaceOption[];
  preferredRaceIdentification?: string;
}
export interface IVisa {
  visaType?: Ioption;
  issuer?: string;
  validFrom?: string;
  validTo?: string;
}

export interface IResidencyInfo {
  address?: {
    country?: Ioption;
    county?: Ioption;
    region?: Ioption;
  };
  yearsInCounty?: Ioption;
  yearsInRegion?: Ioption;
  yearsInUS?: Ioption;
}

export interface TBiographicalInfoField {
  gender?: IGenderInfo | null;
  citizenship?: ICitizenshipInfo | null;
  relationships?: IRelationship[] | null;
  raceEthnicity?: IRacesAndEthnicity | null;
  visa?: IVisa;
  residency?: IResidencyInfo;
}

export interface IBiographicalInfoState {
  loading: boolean;
  biographicalInfoData: TBiographicalInfoField | null;
  biographicalInfoLocalData: TBiographicalInfoField | null;
  error: TError;
}

export const initialState: IBiographicalInfoState = {
  loading: false,
  biographicalInfoData: null,
  biographicalInfoLocalData: null,
  error: null,
};

const biographicalInfoSlice = createSlice({
  name: 'biographicalInfo',
  initialState,
  reducers: {
    biographicalInfoStart: (state): IBiographicalInfoState => ({
      ...state,
      loading: true,
      error: null,
    }),
    biographicalInfoSuccess: (state, action: PayloadAction<TBiographicalInfoField>): IBiographicalInfoState => ({
      ...state,
      loading: false,
      biographicalInfoData: modifyBooleanFields(action.payload),
      biographicalInfoLocalData: null,
    }),
    biographicalInfoSaveLocalData: (state, action: PayloadAction<TBiographicalInfoField>): IBiographicalInfoState => ({
      ...state,
      loading: false,
      biographicalInfoLocalData: action.payload,
    }),
    biographicalInfoResetLocalData: (state): IBiographicalInfoState => ({
      ...state,
      loading: false,
      biographicalInfoLocalData: null,
    }),
    biographicalInfoRelationshipSuccess: (
      state,
      action: PayloadAction<TBiographicalInfoField>
    ): IBiographicalInfoState => ({
      ...state,
      loading: false,
      biographicalInfoData: modifyBooleanFields(action.payload),
    }),
    biographicalInfoFailure: (state, action: PayloadAction<TError>): IBiographicalInfoState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  biographicalInfoStart,
  biographicalInfoSuccess,
  biographicalInfoSaveLocalData,
  biographicalInfoRelationshipSuccess,
  biographicalInfoFailure,
  biographicalInfoResetLocalData,
} = biographicalInfoSlice.actions;

export default biographicalInfoSlice.reducer;
