/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import * as yup from 'yup';
import { t } from 'i18next';
import { MAX_BIG_LENGTH_FIELD } from 'constants/field';
import { EMAIL_REG } from 'constants/regex';

export const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .required(t('account.error.newEmail_required'))
    .matches(RegExp(EMAIL_REG), t('contactInfo.error.emails_invalid'))
    .max(MAX_BIG_LENGTH_FIELD),
  confirmEmail: yup
    .string()
    .trim()
    .required(t('account.error.confirmEmail_required'))
    .matches(RegExp(EMAIL_REG), t('contactInfo.error.emails_invalid'))
    .max(MAX_BIG_LENGTH_FIELD)
    .oneOf([yup.ref('email'), null], t('account.error.email_match')),
});
