/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect, useState, memo } from 'react';
import { Button, Theme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconCardsSelect } from '@liaison/liaison-ui';
import { csuNameSpace } from 'utils/utilities';
import { useAppSelector } from 'redux/hooks';
import {
  selectActiveFollowedPrograms,
  selectSelectedProgramCart,
  selectInActiveSelectedPrograms,
  selectFollowedPrograms,
} from 'transferPlanner/store/programCart/programCart.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from 'utils/commonUtils';
import { setUi } from 'store/ui/ui.slice';
import { selectProgramCart } from 'store/ui/ui.selectors';
import { getProgramCart, getFollowedPrograms } from '../MyProgramsCart/MyProgramsCart.utils';

const MyProgramsButton = (): ReactElement => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const selectedPrograms = useAppSelector(selectSelectedProgramCart);
  const followedPrograms = useAppSelector(selectFollowedPrograms);
  const activeFollowedPrograms = useAppSelector(selectActiveFollowedPrograms);
  const savedInActivePrograms = useSelector(selectInActiveSelectedPrograms);
  const programCart = useSelector(selectProgramCart);
  const { t } = useTranslation(csuNameSpace.ns);
  const [prevTitle, setPrevTitle] = useState(document.title);
  const totalProgramsCount = savedInActivePrograms.length + activeFollowedPrograms.length;

  useEffect(() => {
    if (!selectedPrograms) dispatch(getProgramCart());
  }, [dispatch, selectedPrograms]);

  useEffect(() => {
    if (!followedPrograms) dispatch(getFollowedPrograms());
  }, [dispatch, followedPrograms]);

  useEffect(() => {
    if (programCart?.open) {
      setTitle(`${t('myPrograms.pageTitle')} (${totalProgramsCount || 0})`);
    } else {
      setPrevTitle(document.title);
    }
  }, [programCart, totalProgramsCount, t]);

  const handleProgramCart = (open = true) => {
    dispatch(setUi({ name: 'programCart', state: { open, prevTitle } }));
  };

  return (
    <>
      <Button
        onClick={() => handleProgramCart()}
        variant="contained"
        startIcon={<IconCardsSelect />}
        aria-label={`${t('my_programs_label')} (${totalProgramsCount || 0})`}
        sx={{ px: { xs: 0, sm: '1.5rem' } }}
      >
        {isMobile ? `(${totalProgramsCount || 0})` : `${t('my_programs_label')} (${totalProgramsCount || 0})`}
      </Button>
    </>
  );
};

export default memo(MyProgramsButton);
