/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export const cardStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  mt: '1rem',
  borderRadius: '0.5rem',
  border: '1px',
  borderStyle: 'solid',
  background: 'var(--text-white, #FFF)',
  boxShadow:
    '0px 1px 10px 0px rgba(0, 0, 0, 0.06), 0px 4px 5px 0px rgba(0, 0, 0, 0.07), 0px 2px 4px -1px rgba(0, 0, 0, 0.10)',
} as const;

export const programNameBtnLink = {
  display: 'block',
  width: '100%',
  textDecoration: 'underline',
  fontSize: '0.9rem',
  textAlign: 'left',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  mt: '0.5 rem',
} as const;
