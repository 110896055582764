/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Box, Typography, Card, CardContent } from '@mui/material';
import {
  CollapsibleSection as Accordion,
  IconAlertTriangleColor,
  IconCancel,
  IconCheckCircleColor,
  IconFiles,
  IconLightbulb,
} from '@liaison/liaison-ui';
import { StyledTooltip, sxIconHover } from 'userProfile/pages/ManageAccount/Privacy/Privacy.utils';
import { ICollegeCourse, ITerm } from 'store/academicHistory/academicHistory.slice';
import { isCsuTenant } from 'utils/utilities';
import { StyledButton } from 'pages/Pages.styles';
import {
  sxButtonCompetenciesProfileView,
  sxButtonProfileView,
} from 'userProfile/components/AttachedFiles/AttachedFiles.styles';
import { MultiValuedView } from 'userProfile/components/ViewBuilder/CardView';
import { getAttachedCount, handleCompetencyButtonClick } from '../LivingProfile.utils';

export const CollegeCourses = ({ terms }: { terms: ITerm[] }): ReactElement => {
  const [tooltipLocation, setTooltipLocation] = useState<string>();
  const [showCompetencyMap, setShowCompetencyMap] = useState<Record<string, boolean>>({});
  const { t } = useTranslation();

  return (
    <>
      {terms?.map((termDetails: ITerm) => (
        <React.Fragment key={`${termDetails?.type?.displayName} ${termDetails?.academicYear}`}>
          <Grid item xs={12} sx={{ mt: '.5rem' }}>
            <Accordion
              expanded
              groupId={`${termDetails?.type?.displayName} ${termDetails?.academicYear}`}
              title={`${termDetails?.type?.displayName} ${termDetails?.academicYear} • ${termDetails?.completionStatus?.displayName}`}
            >
              <Box>
                {termDetails?.courses?.map((course: ICollegeCourse, courseIndex: number) => {
                  const competencyValues =
                    course?.competencyAttachments?.map(attachment => attachment.competency) || [];
                  const tooltipMapping = `${course.code}-${courseIndex}`;
                  return (
                    <Grid item xs={12} sm={12} key={course.code} sx={{ mb: 2 }}>
                      <Card variant="outlined" sx={{ borderRadius: 2 }}>
                        <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                          <Grid container spacing={0} sx={{ p: 1 }}>
                            <Grid item xs={10} sm={10} md={11} lg={11} sx={{ mr: '0.5rem', display: 'flex' }}>
                              <Box sx={{ flex: 1 }}>
                                <Box>
                                  <Typography variant="subtitle3" sx={{ overflowWrap: 'anywhere' }}>
                                    {course.title}
                                  </Typography>
                                </Box>
                                <Box sx={{ mt: -0.5 }}>
                                  <Typography variant="caption" sx={{ overflowWrap: 'anywhere' }}>
                                    {`Grade: ${course.grade || 'No grade provided'}`}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                {!!course.attachedCount && (
                                  <StyledButton
                                    variant="contained"
                                    startIcon={<IconFiles />}
                                    size="medium"
                                    fullWidth
                                    aria-label="attachments"
                                    sx={{ ...sxButtonProfileView, width: '50px', marginLeft: '10px' }}
                                  >
                                    {getAttachedCount(course?.attachedCount)}
                                  </StyledButton>
                                )}
                                {!!course.competencyAttachedCount && (
                                  <StyledButton
                                    variant="contained"
                                    startIcon={showCompetencyMap[course.code] ? <IconCancel /> : <IconLightbulb />}
                                    size="medium"
                                    fullWidth
                                    aria-label="attachments"
                                    sx={{ ...sxButtonCompetenciesProfileView, width: '50px', marginLeft: '10px' }}
                                    onClick={() => handleCompetencyButtonClick(course.code, setShowCompetencyMap)}
                                  >
                                    {getAttachedCount(course?.competencyAttachedCount)}
                                  </StyledButton>
                                )}
                                {isCsuTenant() && (
                                  <StyledTooltip
                                    open={tooltipMapping === tooltipLocation}
                                    componentsProps={{ tooltip: { role: 'button' } }}
                                    title={
                                      <>
                                        {course?.source?.code === 'ASSIST'
                                          ? t('colleges.course.source.assist.tooltip')
                                          : t('colleges.course.source.manual.tooltip')}
                                      </>
                                    }
                                    onClose={() => setTooltipLocation(undefined)}
                                  >
                                    <IconButton
                                      onMouseOver={() => setTooltipLocation(tooltipMapping)}
                                      onClick={() => setTooltipLocation(tooltipMapping)}
                                      sx={sxIconHover}
                                      size="small"
                                      edge="start"
                                      color="inherit"
                                      aria-label="icon manual or verified course"
                                    >
                                      {course?.source?.code === 'ASSIST' ? (
                                        <IconCheckCircleColor />
                                      ) : (
                                        <IconAlertTriangleColor />
                                      )}
                                    </IconButton>
                                  </StyledTooltip>
                                )}
                              </Box>
                            </Grid>
                            {showCompetencyMap[course.code] && (
                              <Grid item xs={12}>
                                <Box sx={{ mt: 1, ml: 1 }}>
                                  <Typography sx={{ mt: 2 }} variant="subtitle3">
                                    {t('competenciesAndSkills.competencies')}
                                  </Typography>
                                  <MultiValuedView multiValued={competencyValues} />
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Box>
            </Accordion>
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
};
