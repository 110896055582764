/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { memo, ReactElement, useEffect } from 'react';
import { Grid, Typography, Box, useMediaQuery, Theme } from '@mui/material';
import { Icon } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { sanitize } from 'dompurify';
import { InfoCard } from 'transferPlanner/components/InfoCard';
import { AgreementUrlButton } from 'transferPlanner/components/AgreementUrlButton';
import { ITspEnrollment } from 'transferPlanner/store/enrollment/enrollment.slice';
import { selectAcademicHistory } from 'store/academicHistory/academicHistory.selectors';
import { gpaCalculationSelector } from 'transferPlanner/store/genEdProcess/genEdProcess.selectors';
import { allowedEnrollmentStatuses, nameSpace, REQUEST_RELEASE_CODE } from 'transferPlanner/constants/general';
import { StyledDivider } from 'pages/Pages.styles';
import { capitalizeText, formatGPA } from 'utils/utilities';
import { getAcademicHistory } from 'utils/AcademicHistory.utils';
import { DEFAULT_SANITIZE_CONFIG } from 'constants/sanitize';
import { getRemainingTerms } from './ProgramProgress.utils';
import { getGpaCalculation } from '../GeneralEducation/GeneralEducation.utils';

interface IEnrollmentDetails {
  enrollmentData: ITspEnrollment | undefined;
}
const EnrollmentDetails = ({ enrollmentData }: IEnrollmentDetails): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  const academicHistoryData = useSelector(selectAcademicHistory);
  const gpaCalculation = useSelector(gpaCalculationSelector);

  const campusName = enrollmentData?.campus.name || '';
  const programName = enrollmentData?.program.name || '';
  const collegeName = enrollmentData?.communityCollege?.name || '';
  const startTerm = capitalizeText(
    `${enrollmentData?.expectedStartingTerm?.termType?.displayName || ''} ${
      enrollmentData?.expectedStartingTerm?.academicYear || ''
    }`
  );
  const startDate = `Start Date: ${startTerm}`;

  const overallGpa = enrollmentData?.transferRequirements?.overallGPA || '-';
  const majorGpa = enrollmentData?.transferRequirements?.majorPreReqGPA || '-';
  const minGrade = enrollmentData?.transferRequirements?.minGrade?.displayName || '-';
  const requirements = enrollmentData?.transferRequirements?.requirements || '-';
  const agreementUrl = enrollmentData?.transferRequirements?.agreementURL || '';
  const isEnrolled = allowedEnrollmentStatuses.includes(enrollmentData?.enrollmentStatus?.status?.code || '');
  const isRequestReleased = enrollmentData?.enrollmentStatus?.status?.code === REQUEST_RELEASE_CODE;
  const isGpaHigherThanRequirement =
    (gpaCalculation?.transferGPA?.gpa || 0) >= ((enrollmentData?.transferRequirements?.overallGPA || 0) as number);

  useEffect(() => {
    if (!academicHistoryData) dispatch(getAcademicHistory());

    if (academicHistoryData) {
      dispatch(getGpaCalculation());
    }
  }, [dispatch, academicHistoryData]);

  return (
    <Grid container item sx={{ mt: '0.5rem' }}>
      {isRequestReleased && (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '1rem' }}>
          <Icon>IconAlertTriangleColor</Icon>
          <Typography variant="subtitle2" sx={{ color: '#BF5300', pl: '0.5rem' }}>
            {t('programProgress.enrollmentRequestForReleaseTitle')}
          </Typography>
        </Box>
      )}
      {isEnrolled && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '1rem' }}>
            <Icon>IconCheckCircleColor</Icon>
            <Typography variant="subtitle2" component="div" sx={{ color: '#1B5E20', pl: '0.5rem' }}>
              {t('programProgress.enrollmentSuccessTitle')}
            </Typography>
          </Box>
          <Grid item container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle3" sx={{ mb: '0.5rem' }}>
                {t('enrollmentCompletion.university')}
              </Typography>
              <InfoCard heading={campusName} subHeading={programName} showDetails={true} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle3" sx={{ mb: '0.5rem' }}>
                {t('enrollmentCompletion.communityCollege')}
              </Typography>
              <InfoCard heading={collegeName} subHeading={startDate} showDetails={true} />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <StyledDivider />
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ mt: 2, mb: 2 }} variant="subtitle3">
                {t('campusesAndProgram.transferRequirements')}
              </Typography>
              <Grid sx={{ mt: 1 }} justifyContent="space-between" container spacing={2} rowSpacing={1}>
                <Grid item xs={8}>
                  <Typography variant="body2">
                    {t('campusesAndProgram.overallGpa')} {!isMobile && t('campusesAndProgram.minimumGpa')}
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'end' }}>
                  <Box data-testid="gpa">
                    <Typography
                      component="span"
                      variant="subtitle1"
                      sx={{
                        color: isGpaHigherThanRequirement
                          ? (theme: Theme) => theme.palette.success.main
                          : (theme: Theme) => theme.palette.error.main,
                      }}
                    >
                      {gpaCalculation?.transferGPA?.gpa ? gpaCalculation.transferGPA.gpa.toFixed(2) : 'N/A'}
                    </Typography>
                    {`/${formatGPA(overallGpa as number)}`}
                  </Box>
                </Grid>
                {isMobile && (
                  <Grid item xs={12}>
                    <Typography variant="body2">{t('campusesAndProgram.minGpa')}</Typography>
                  </Grid>
                )}
                <Grid item xs={8}>
                  <Typography variant="body2">{t('campusesAndProgram.majorGpa')}</Typography>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'end' }}>
                  {formatGPA(majorGpa as number)}
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">{t('campusesAndProgram.minimumGrade')}</Typography>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'end' }}>
                  {minGrade}
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">{t('campusesAndProgram.tspStartTerm')}</Typography>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'end', textTransform: 'capitalize' }}>
                  {startTerm}
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">{t('campusesAndProgram.enrollmentLimit')}</Typography>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: 'end' }}>
                  <Box data-testid="remaining-terms">
                    <Typography component="span" variant="subtitle1">
                      {getRemainingTerms(
                        enrollmentData?.expectedStartingTerm?.termType?.displayName || '',
                        enrollmentData?.expectedStartingTerm?.academicYear || 0
                      )}
                    </Typography>
                    {` ${t('campusesAndProgram.termRemaining')}`}
                  </Box>
                </Grid>
                <Grid item xs={8} sx={{ mt: '2rem' }}>
                  <Typography variant="body2">{t('programSelection.condition')}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ mt: 2, mb: 2 }} variant="subtitle3">
                {t('campusesAndProgram.otherRequirements')}
              </Typography>
              <Typography
                sx={{ mt: 2, overflowWrap: 'anywhere' }}
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: sanitize(requirements, DEFAULT_SANITIZE_CONFIG),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledDivider />
            </Grid>
            {agreementUrl && (
              <>
                <Grid item xs={12}>
                  <Typography variant="subtitle3">{t('enrollmentDetails.learnMore')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">{t('enrollmentDetails.instruction')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <AgreementUrlButton agreementUrl={agreementUrl} />
                </Grid>
                <Grid item xs={12} sm={6} />
              </>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default memo(EnrollmentDetails);
