/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, ReactNode } from 'react';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogTitle,
  dialogClasses,
  DialogContent,
  IconButton,
  Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type TButtonConfig = {
  title: string;
  props?: ButtonProps;
};
interface IFooterProps {
  primary?: TButtonConfig;
  tertiary?: TButtonConfig;
}

interface IModalViewProps {
  children: ReactNode;
  open: boolean;
  header: string;
  footerButtonConfig: IFooterProps;
}

const getButton = ({ title, ...props }: ButtonProps & { title: string }): ReactElement => (
  <Button {...props}>{title}</Button>
);

const ModalView = ({
  children,
  open,
  header,
  footerButtonConfig: { primary, tertiary },
}: IModalViewProps): ReactElement => {
  return (
    <Dialog
      maxWidth="sm"
      scroll="body"
      open={open}
      aria-labelledby="dialog-modal-title"
      sx={{ [`.${dialogClasses.paper}`]: { borderRadius: '1rem' } }}
      fullWidth
    >
      <DialogTitle
        sx={{
          borderBottom: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        {header}
        {tertiary?.props?.onClick ? (
          <IconButton
            aria-label="close"
            onClick={tertiary?.props?.onClick}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <Grid container component="main">
        <Grid item xs={12}>
          <DialogContent tabIndex={0} sx={{ maxHeight: '75vh' }} id="dialog-modal-title">
            {children}
          </DialogContent>
        </Grid>
      </Grid>
      <DialogActions
        sx={{
          justifyContent: tertiary && primary ? 'space-between' : 'center',
          py: '1rem',
          px: ['1rem', '1.5rem', '2.5rem'],
          borderTop: theme => `1px solid ${theme.palette.divider}`,
        }}
      >
        {tertiary &&
          getButton({
            variant: 'text',
            title: tertiary.title,
            ...tertiary.props,
          })}
        {primary &&
          getButton({
            color: 'primary',
            variant: 'contained',
            title: primary.title,
            sx: {
              marginLeft: '1rem',
              ...primary.props?.sx,
            },
            ...primary.props,
          })}
      </DialogActions>
    </Dialog>
  );
};

export default ModalView;
