/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, FormControl, InputLabel, InputBase, FormHelperText, Typography } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Alert, Dropdown, DateField, TAutocompleteOption } from '@liaison/liaison-ui';

import { getDropDownOptions, rangeOfYearsDropDown } from 'utils/utilities';
import { DISABLE_AUTO_FILL } from 'constants/field';
import MasterData from 'userProfile/constants/master';
import type { TGradeLevel } from 'store/academicHistory/academicHistory.slice';

type TGradeError = {
  type: { code: { message: string } };
  academicYear: { message: string };
  startDate: { message: string };
  endDate: { message: string };
  gpa: { message: string };
};

type THighSchoolGradeLevelFormProps = {
  highSchoolStartYear: number;
  highSchoolEndYear: number;
  gradeLevelList: TAutocompleteOption<string>[];
  selectedGradeLevel?: TGradeLevel;
};

const HighSchoolGradeLevelForm = ({
  highSchoolStartYear,
  highSchoolEndYear,
  gradeLevelList,
  selectedGradeLevel,
}: THighSchoolGradeLevelFormProps): ReactElement => {
  const {
    control,
    register,
    formState: { errors },
    setValue,
    watch,
    reset,
    trigger,
  } = useFormContext();
  const { t } = useTranslation();
  const [showBanner, setShowBanner] = useState(false);
  const isEditForm = !!selectedGradeLevel;
  const gradeErrors = errors as unknown as TGradeError;
  const watchType = watch('type.displayName');
  const watchYear = watch('academicYear');
  const rangeOfYearsDropDownOptions = useMemo(
    () => rangeOfYearsDropDown(highSchoolStartYear, highSchoolEndYear),
    [highSchoolStartYear, highSchoolEndYear]
  );
  const gradeLevelOptions = useMemo(() => getDropDownOptions(MasterData?.highSchoolGradeLevel), []);
  const newGradeLevelList = useMemo(() => {
    if (isEditForm) {
      return gradeLevelList?.filter(({ text }) => {
        const levelValues = text?.split(' ');
        const currentGradeLevel = levelValues[0];
        const currentAcademicYear = levelValues[1];
        return (
          currentGradeLevel !== selectedGradeLevel?.type?.code &&
          currentAcademicYear !== selectedGradeLevel?.academicYear.toString()
        );
      });
    }
    return gradeLevelList;
  }, [gradeLevelList, isEditForm, selectedGradeLevel]);

  useEffect(() => {
    if (selectedGradeLevel) {
      reset(selectedGradeLevel);
    } else {
      reset({});
    }
    return () => reset({});
  }, [reset, selectedGradeLevel]);

  const isDuplicateLevel = (gradeLevel: string | undefined, academicYear: string | undefined): boolean => {
    let duplicate = false;
    if (gradeLevel && academicYear) {
      duplicate = newGradeLevelList?.some(({ text }) => {
        const levelValues = text?.split(' ');
        const currentGradeLevel = levelValues[0];
        const currentAcademicYear = levelValues[1];
        return currentGradeLevel === gradeLevel && currentAcademicYear === academicYear;
      });
    }
    setShowBanner(duplicate);
    return duplicate;
  };

  return (
    <>
      {showBanner ? (
        <Alert icon={false} type="warning" sx={{ mb: '1rem' }} onClose={() => setShowBanner(false)}>
          <Typography component="div" variant="body3">
            {t('highschool.course.grade.duplicate')}
          </Typography>
        </Alert>
      ) : null}
      <Grid container spacing={1}>
        <Grid item container spacing={1}>
          <Grid item xs={6}>
            <FormControl fullWidth required error={!!gradeErrors?.type}>
              <InputLabel htmlFor="type">{t('academicHistory.gradeLevel')}</InputLabel>
              <Controller
                render={({ field: { ref, onChange, ...field } }) => (
                  <Dropdown
                    id="type"
                    {...field}
                    inputRef={ref}
                    options={gradeLevelOptions}
                    onChange={option => {
                      if (!isDuplicateLevel(option?.text, watchYear)) {
                        setValue('type.displayName', option?.text);
                        return onChange(option?.id ?? '');
                      }
                      return onChange('');
                    }}
                    inputProps={{
                      'aria-label': t('academicHistory.gradeLevel'),
                      'aria-describedby': 'type-error',
                      ...DISABLE_AUTO_FILL,
                    }}
                    disabled={isEditForm}
                  />
                )}
                control={control}
                name="type.code"
              />
              <FormHelperText role="alert" id="type-error">
                {gradeErrors?.type?.code?.message}
              </FormHelperText>
            </FormControl>
            <InputBase inputProps={{ type: 'hidden' }} {...register('type.displayName')} />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth required error={!!gradeErrors?.academicYear}>
              <InputLabel htmlFor="academicYear">{t('colleges.course.term.academicYear')}</InputLabel>
              <Controller
                render={({ field: { ref, onChange, ...field } }) => (
                  <Dropdown
                    id="academicYear"
                    {...field}
                    inputRef={ref}
                    value={field.value ? String(field.value) : field.value}
                    options={rangeOfYearsDropDownOptions}
                    onChange={option => {
                      if (!isDuplicateLevel(watchType, option?.id)) {
                        setTimeout(() => {
                          trigger(['startDate', 'endDate']);
                        }, 0);
                        return onChange(option?.id ?? '');
                      }
                      return onChange('');
                    }}
                    inputProps={{
                      'aria-label': t('colleges.course.term.academicYear'),
                      'aria-describedby': 'academicYear-error',
                      ...DISABLE_AUTO_FILL,
                    }}
                    disabled={isEditForm}
                  />
                )}
                control={control}
                name="academicYear"
              />
              <FormHelperText role="alert" id="academicYear-error">
                {gradeErrors?.academicYear?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Controller
              name="startDate"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <FormControl fullWidth error={!!gradeErrors?.startDate}>
                  <InputLabel htmlFor="startDate">{t('academicHistory.startDate')}</InputLabel>
                  <DateField
                    {...field}
                    ref={ref}
                    error={!!error}
                    id="startDate"
                    hidePart="day"
                    onChange={dateVal => {
                      field.onChange(dateVal);
                      trigger('endDate');
                    }}
                    aria-describedby="startDate-error"
                    accessibilityLabelPrefix={t('academicHistory.startDate')}
                  />
                  <FormHelperText role="alert" id="startDate-error">
                    {gradeErrors?.startDate?.message}
                  </FormHelperText>
                </FormControl>
              )}
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="endDate"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <FormControl fullWidth error={!!gradeErrors?.endDate}>
                  <InputLabel htmlFor="endDate">{t('academicHistory.endDate')}</InputLabel>
                  <DateField
                    {...field}
                    ref={ref}
                    error={!!error}
                    id="endDate"
                    hidePart="day"
                    onChange={dateVal => {
                      field.onChange(dateVal);
                      trigger('startDate');
                    }}
                    aria-describedby="endDate-error"
                    accessibilityLabelPrefix={t('academicHistory.endDate')}
                  />
                  <FormHelperText role="alert" id="endDate-error">
                    {gradeErrors?.endDate?.message}
                  </FormHelperText>
                </FormControl>
              )}
              control={control}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!gradeErrors?.gpa}>
            <InputLabel htmlFor="name">
              {t('academicHistory.gpa')} ({t('academicHistory.gpaMaxScore')})
            </InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => {
                return (
                  <NumberFormat
                    {...field}
                    decimalScale={1}
                    decimalSeparator="."
                    allowNegative={false}
                    fixedDecimalScale={false}
                    customInput={InputBase}
                    onValueChange={values => onChange(values?.floatValue)}
                    inputProps={{ 'aria-label': t('academicHistory.gpa') }}
                  />
                );
              }}
              name="gpa"
              control={control}
            />
            <FormHelperText role="alert" id="gpa-error">
              {gradeErrors?.gpa?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};
export default HighSchoolGradeLevelForm;
