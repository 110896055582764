/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TError = string | null;

export interface IAdtInformation {
  communityCollege: {
    name?: string;
    alternateIds?: { id: number; source: string }[];
  };
  program: {
    name?: string;
    alternateIds?: { id: number; source: string }[];
  };
}

export interface ITpAdditionalDetails {
  adtInformation?: IAdtInformation[];
  academicInformation?: {
    intendedTransferTerm?: { code?: string; displayName?: string };
    intendedTransferYear?: string;
  };
  biographicInformationExtensions?: {
    extendedMilitaryStatus?: { code?: string; displayName?: string };
    guardian1EducationLevel?: { code?: string; displayName?: string };
    guardian2EducationLevel?: { code?: string; displayName?: string };
  };
  raceEthnicitySummary?: { code?: string; displayName?: string };
  plannedEnrollmentStatus?: { code?: string; displayName?: string };
}

export interface IAdditionalDetailsResponse {
  profileId?: string;
  lastUpdatedTimestamp?: string;
  lastUpdatedByTenant?: string;
  additionalDetails: ITpAdditionalDetails | null;
}

export interface IAdditionalDetailsState {
  loading: boolean;
  data: IAdditionalDetailsResponse;
  error: TError;
}

export const initialState: IAdditionalDetailsState = {
  loading: false,
  data: {
    additionalDetails: null,
  },
  error: null,
};

const additionalDetailsSlice = createSlice({
  name: 'additionalDetails',
  initialState,
  reducers: {
    additionalDetailsStart: (state): IAdditionalDetailsState => ({
      ...state,
      loading: true,
      error: null,
    }),
    additionalDetailsSuccess: (state, action: PayloadAction<IAdditionalDetailsResponse>): IAdditionalDetailsState => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    additionalDetailsFailure: (state, action: PayloadAction<TError>): IAdditionalDetailsState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    additionalDetailsReset: () => initialState,
  },
});

export const { additionalDetailsStart, additionalDetailsSuccess, additionalDetailsFailure, additionalDetailsReset } =
  additionalDetailsSlice.actions;

export default additionalDetailsSlice.reducer;
