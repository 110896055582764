/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Ioption } from 'utils/utilities';

type TError = string | null;

export interface IFieldsOfIndustryInterest {
  code: string;
  displayName: string;
}

export interface TGoalInformation {
  goalInformation: TCareerGoalsInfoField;
  industries: IFieldsOfIndustryInterest[] | null;
}
export interface TCareerGoalsInfoField {
  startDate?: string;
  type?: Ioption[] | null;
  roles?: Ioption[] | null;
  industries?: Ioption[] | null;
}

export interface ICareerGoalsInfoState {
  loading: boolean;
  careerGoalsInfoData: TCareerGoalsInfoField | null;
  error: TError;
}

export const initialState: ICareerGoalsInfoState = {
  loading: false,
  careerGoalsInfoData: null,
  error: null,
};

const careerGoalsInfoSlice = createSlice({
  name: 'careerGoalsInfo',
  initialState,
  reducers: {
    careerGoalsInfoStart: (state): ICareerGoalsInfoState => ({
      ...state,
      loading: true,
      error: null,
    }),
    careerGoalsInfoSuccess: (state, action: PayloadAction<TCareerGoalsInfoField>): ICareerGoalsInfoState => ({
      ...state,
      loading: false,
      careerGoalsInfoData: action.payload,
    }),
    careerGoalsInfoFailure: (state, action: PayloadAction<TError>): ICareerGoalsInfoState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const { careerGoalsInfoStart, careerGoalsInfoSuccess, careerGoalsInfoFailure } = careerGoalsInfoSlice.actions;

export default careerGoalsInfoSlice.reducer;
