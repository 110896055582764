import { useEffect } from 'react';

const useFocusSnackbar = (id?: string): void => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const element = document.getElementById(id || `common-success-snackbar`);
      if (event.key === 'Tab' && element) {
        element.focus();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [id]);
};

export default useFocusSnackbar;
