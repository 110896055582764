/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProgram } from 'transferPlanner/store/programCart/programCart.slice';

type TError = string | null;

interface IEnum {
  code: string;
  displayName: string;
}

type TReason = {
  code: string;
  displayName: string;
  other?: string;
};

export interface ITspEnrollment {
  id?: string;
  program: IProgram;
  campus: {
    id: number;
    alternateId: {
      code: number;
      source: string;
    };
    name: string;
  };
  communityCollege: {
    alternateId: {
      code: number;
      source: string;
    };
    name: string;
  };
  active?: boolean;
  expectedStartingTerm: {
    academicYear: number;
    termType: {
      code: string;
      displayName: string;
    };
  };
  enrollmentStatus?: {
    status: IEnum;
    reason?: IEnum;
    requestRelease: {
      reason: TReason;
      submittedTimestamp?: string;
    };
    enrolledTimestamp?: string;
    lastUpdatedTimestamp?: string;
  };
  transferRequirements?: {
    overallGPA: number;
    majorPreReqGPA: number;
    minGrade: IEnum;
    agreementURL: string;
    requirements: string;
  };
}

export interface IEnrollment {
  profileId: string;
  lastUpdatedTimestamp: string;
  lastUpdatedByTenant: string;
  tspEnrollment: ITspEnrollment[];
}

export interface IDatePeriod {
  academic_year: number;
  periodName: string;
  startDate: string;
  endDate: string;
}
export interface ITspEnrollmentPeriod {
  openDates: IDatePeriod[];
  enrollmentOpen: boolean;
  tspDeadlineExtensionTimestamp?: string;
}

export interface IEnrollmentState {
  loading: boolean;
  enrollment: IEnrollment | null;
  tspEnrollmentPeriod: ITspEnrollmentPeriod | null;
  error: TError;
}

export const initialState: IEnrollmentState = {
  loading: false,
  enrollment: null,
  tspEnrollmentPeriod: null,
  error: null,
};

const enrollmentSlice = createSlice({
  name: 'enrollment',
  initialState,
  reducers: {
    enrollmentStart: (state): IEnrollmentState => ({
      ...state,
      loading: true,
      error: null,
    }),
    enrollmentSuccess: (state, action: PayloadAction<IEnrollment>): IEnrollmentState => ({
      ...state,
      loading: false,
      enrollment: action.payload,
    }),
    enrollmentFailure: (state, action: PayloadAction<TError>): IEnrollmentState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    tspEnrollmentPeriodStart: (state): IEnrollmentState => ({
      ...state,
      loading: true,
      error: null,
    }),
    tspEnrollmentPeriodSuccess: (state, action: PayloadAction<ITspEnrollmentPeriod>): IEnrollmentState => ({
      ...state,
      loading: false,
      tspEnrollmentPeriod: action.payload,
    }),
    tspEnrollmentPeriodFailure: (state, action: PayloadAction<TError>): IEnrollmentState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    enrollmentReset: () => initialState,
  },
});

export const {
  enrollmentStart,
  enrollmentSuccess,
  enrollmentFailure,
  enrollmentReset,
  tspEnrollmentPeriodStart,
  tspEnrollmentPeriodSuccess,
  tspEnrollmentPeriodFailure,
} = enrollmentSlice.actions;

export default enrollmentSlice.reducer;
