/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import {
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  InputBase,
  FormHelperText,
  IconButton,
  Card,
  CardActions,
  Divider,
  Switch,
  CardActionArea,
} from '@mui/material';
import type { TAcademicHistoryField, ICollege } from 'store/academicHistory/academicHistory.slice';
import { academicHistorySaveLocalData } from 'store/academicHistory/academicHistory.slice';
import { DegreeCard } from 'userProfile/components/ViewBuilder/Cards/CollegeCard';
import { useFormContext, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Pluralize from 'pluralize';
import {
  DISABLE_AUTO_FILL,
  DISABLE_AUTO_FILL_AUTOCOMPLETE,
  FIELD_LENGTH_250,
  DISABLE_ADDRESS_FILLING,
  MAX_MEDIUM_LENGTH_FIELD,
} from 'constants/field';
import {
  DateField,
  Dropdown,
  IconRemoveCircle,
  IconSchool,
  IconCaretRight,
  IconAdd,
  type TDropdownOption,
} from '@liaison/liaison-ui';
import { StyledDeleteButton, StyledAddButton, StyledIconButton, StyledFormHelperText } from 'pages/Pages.styles';
import { RadioControl } from 'components/RadioControl';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { SearchableAutoComplete } from 'components/SearchableAutoComplete';
import MasterData from 'userProfile/constants/master';
import { getCheckboxColor, getDropDownOptions, isCsuTenant } from 'utils/utilities';
import { isFeatureActive } from 'utils/features.utils';
import { getAcademicHistoryLookUps, getLookUps, setTitle } from 'utils/commonUtils';
import { selectFeatures } from 'store/features/features.selectors';
import { selectCollegesLookup, selectCountriesLookup, selectStatesLookup } from 'store/common/commonInfo.selectors';
import { ICollegeCommon, resetlookupData } from 'store/common/commonInfo.slice';
import CollegeCourses from 'userProfile/components/ViewBuilder/Cards/CollegeCourses';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { getCollegeOptions, modifiedCollegeListing } from 'utils/AcademicHistory.utils';
import { TuseOptionsScrollParams, useOptionsScroll } from 'hooks/useOptionsScroll';
import { AttachedFiles } from 'userProfile/components/AttachedFiles';
import { SkillsMultiselect } from 'userProfile/components/SkillsMutliselect';
import type { TCollegeFormError } from './TAcademicHistory';

interface ICollegeProps {
  data: TAcademicHistoryField | null;
  openChildDrawer: (
    isAddNew: boolean,
    childPosition: number,
    parentSelector: string,
    formTitle: string,
    showCourse?: boolean
  ) => void;
  isOpenChildDrawer?: boolean;
  childPosition?: number;
  deleteProperty?: ((childPosition: number, parent: string, tag?: string) => void) | null;
  formTitle?: string;
  openGrandChildDrawer?: (
    isAddNew: boolean,
    childPosition: number,
    parentSelector: string,
    formTitle?: string,
    parentPosition?: number,
    sectionPrefix?: string
  ) => void;
  showCourses: boolean;
  closeChildDrawer?: () => void;
}

const groupName = 'colleges';
const radioDefaultOptions = MasterData?.radioDefaultOptions;
const collegeTermTypeOptions = getDropDownOptions(MasterData?.collegeTermType);

type TSelectedOption = {
  name?: string;
  longName?: string | undefined;
  ceeb?: string;
  type?: string;
};

const CollegeForm = ({
  data,
  openChildDrawer,
  isOpenChildDrawer,
  childPosition = 0,
  deleteProperty,
  formTitle,
  openGrandChildDrawer,
  showCourses,
  closeChildDrawer,
}: ICollegeProps): ReactElement => {
  const { t } = useTranslation();
  const formData = data?.[groupName]?.[childPosition];
  const degreesList = formData?.degrees;
  const degreeLength = degreesList?.length;
  const courseLength = (formData?.terms || [])?.reduce((count, current) => count + (current?.courses || [])?.length, 0);
  const collegeCardSize = deleteProperty ? 12 : 11;
  const [showAddressField, setShowAddressField] = useState(false);
  const [showAddress, setShowAddress] = useState(!!formData?.address || false);
  const [firstLoading, setFirstLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({} as TSelectedOption);
  const [selectedAddress, setSelectedAddress] = useState(formData?.address);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const collegesLookup = useSelector(selectCollegesLookup);
  const countriesLookup = useSelector(selectCountriesLookup);
  const statesLookup = useSelector(selectStatesLookup);
  const addressPrefix = `${groupName}.${childPosition}.address`;
  const features = useSelector(selectFeatures);
  const isCourseWorkActive = isFeatureActive(features, 'COLLEGE_COURSEWORK');

  const {
    control,
    reset,
    register,
    formState: { errors, isValid, dirtyFields },
    watch,
    getValues,
    setValue,
    getFieldState,
    trigger,
  } = useFormContext();

  const collegeErrors = errors as unknown as TCollegeFormError;

  const { filteredOptions, setFilteredOptions, showApiLoading, showAdd, setShowAdd, handleScroll, handleSearch } =
    useOptionsScroll({
      lookup: collegesLookup as (ICollegeCommon & { id: string; text: string })[],
      getOptions: getCollegeOptions as <T>(options: T[]) => T[],
      getValues,
      dispatch,
      getLookUps: getAcademicHistoryLookUps,
      entity: 'college',
      allowManualAdd: true,
    } as TuseOptionsScrollParams);

  const watchCountry = watch(`${addressPrefix}.country.code`);
  const watchCollegeCountry = watch(`country.code`);
  const governingDistrictOptions = useMemo(() => getDropDownOptions(statesLookup || []), [statesLookup]);

  const hasGraduated = getValues(`colleges[${childPosition}].graduated`);

  const gpaAriaLabel = `${t('academicHistory.gpa')} ${t('academicHistory.gpaMaxScore')}`;

  useEffect(() => {
    dispatch(resetlookupData(['college']));
    return () => {
      dispatch(resetlookupData(['college']));
    };
  }, [dispatch]);

  useEffect(() => {
    if (data && !showCourses) {
      setSelectedOptions((data?.[groupName]?.[childPosition] as TSelectedOption) || {});
      setFirstLoading(true);
      reset(data);
    }
    return () => reset({});
  }, [reset, data, childPosition, showCourses]);

  useEffect(() => {
    if (selectedOptions) {
      if (selectedOptions?.type) {
        delete selectedOptions?.type;
      }
      if (!firstLoading) {
        setValue(`colleges.[${childPosition}]`, selectedOptions);
      } else {
        setFirstLoading(false);
      }
    }
  }, [selectedOptions, setValue, childPosition, firstLoading]);

  const abortStateController = useRef(null) as unknown as { current: AbortController };
  const getStateList = useCallback(
    (countryId: string | undefined) => {
      abortStateController?.current?.abort();
      abortStateController.current = new AbortController();
      const controllerSignal = { signal: abortStateController.current.signal };
      dispatch(getLookUps('states', `?countryCode=${countryId}`, '', controllerSignal));
    },
    [dispatch, abortStateController]
  );

  useEffect(() => {
    if (getFieldState(`${addressPrefix}.country`).isDirty || getFieldState(`${addressPrefix}.country`).isTouched) {
      if (watchCountry) {
        dispatch(resetlookupData(['states', 'counties']));
        getStateList(watchCountry);
      } else {
        dispatch(resetlookupData(['states', 'counties']));
      }
    }
  }, [watchCountry, addressPrefix, dispatch, getFieldState, getStateList, dirtyFields]);

  /* istanbul ignore next */
  const deleteAddressCard = () => {
    setShowAddress(false);
    reset({
      colleges: [],
      country: { code: 'US' },
    });
    dispatch(resetlookupData(['college']));
  };

  /* istanbul ignore next */
  const onSelection = (option: ICollege) => {
    const { name, ceeb, address, alternateId } = option;
    const seletedOption = {
      name: name?.longName,
      ceeb,
      address,
      alternateId,
    };
    setSelectedAddress(address);
    setSelectedOptions(seletedOption);
    setShowAddress(true);
  };

  const modifiedRenderOption = (
    renderprops: React.HTMLAttributes<HTMLLIElement>,
    option: ICollege | TDropdownOption<string>
  ) => {
    if (option?.id === 'addManually') {
      return (
        <Box
          component="li"
          key={option?.text}
          {...renderprops}
          sx={{ display: 'flex', justifyContent: 'space-between !important' }}
        >
          <Box>
            <Typography variant="subtitle4">{t('colleges.cantFindCollege')}</Typography>
          </Box>
          <StyledAddButton sx={{ fontSize: '0.625rem', mt: 1 }} size="small" onClick={() => setShowAddressField(true)}>
            {t('colleges.addNewCollege')}
          </StyledAddButton>
        </Box>
      );
    }
    return (
      <Box component="li" key={option?.text} {...renderprops}>
        <Box>
          <Box>
            <Typography variant="subtitle4" sx={{ overflowWrap: 'anywhere' }}>
              {option?.text}
            </Typography>
          </Box>
          <Typography variant="body3">{modifiedCollegeListing(option as ICollegeCommon)}</Typography>
        </Box>
      </Box>
    );
  };

  const countryOptions = useMemo(() => getDropDownOptions(countriesLookup || []), [countriesLookup]);

  const collegeLocation = `${
    selectedAddress?.region?.displayName !== 'N/A'
      ? selectedAddress?.region?.displayName
      : selectedAddress?.city || 'N/A'
  }, ${selectedAddress?.country?.displayName}`;

  return (
    <>
      {showCourses && (
        <CollegeCourses
          data={formData}
          groupName={groupName}
          childPosition={childPosition}
          openGrandChildDrawer={openGrandChildDrawer}
          closeChildDrawer={closeChildDrawer}
        />
      )}

      {!showCourses && (
        <>
          {!isOpenChildDrawer ? (
            <Box>
              <Typography
                variant="subtitle1"
                sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px' }}
              >
                {t('colleges.title')}
              </Typography>
              {JSON.stringify(data?.[groupName]) && (
                <InputBase
                  inputProps={{ type: 'hidden' }}
                  {...register(`${groupName}`)}
                  defaultValue={JSON.stringify(data?.[groupName])}
                />
              )}

              <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                <StyledAddButton
                  onClick={() => openChildDrawer(true, data?.colleges?.length || 0, groupName, formTitle as string)}
                >
                  {t('add_label')}
                </StyledAddButton>
              </Box>
            </Box>
          ) : (
            <Box>
              {!showAddress && !showAddressField && (
                <>
                  <Grid container>
                    <FormControl required fullWidth error={!watchCollegeCountry}>
                      <InputLabel htmlFor="country">{t('address.country')}</InputLabel>
                      <Controller
                        render={({ field: { onChange, ...field } }) => (
                          <Dropdown
                            id="country"
                            {...field}
                            options={countryOptions}
                            onChange={option => {
                              dispatch(resetlookupData(['college']));
                              return onChange(option?.id ?? '');
                            }}
                            inputProps={{
                              'aria-label': t('address.country'),
                              'aria-describedby': 'country-error',
                              ...DISABLE_ADDRESS_FILLING,
                            }}
                          />
                        )}
                        control={control}
                        name="country.code"
                      />
                      <FormHelperText role="alert" id="country-error">
                        {!watchCollegeCountry && t('address.error.country_required')}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <FormControl fullWidth required error={!!collegeErrors?.[groupName]?.[childPosition]?.name}>
                    <InputLabel htmlFor="collegeName">{t('colleges.searchColleges')}</InputLabel>
                    <Controller
                      render={({ field: { onChange, name } }) => (
                        <SearchableAutoComplete
                          id="collegeSearch"
                          options={filteredOptions}
                          onChange={option => {
                            if (option && option?.id !== 'Loading' && option?.id !== 'addManually') {
                              onSelection(option as ICollege);
                              return onChange(option?.id ?? null);
                            }
                            return '';
                          }}
                          name={name}
                          getOptionDisabled={option => {
                            const collegeOption = option as unknown as ICollege;
                            if (
                              data?.[groupName]?.some(
                                college =>
                                  college.address?.country.code === collegeOption.address?.country.code &&
                                  college.name === collegeOption.name?.longName
                              )
                            ) {
                              return true;
                            }
                            return false;
                          }}
                          handleSearch={handleSearch}
                          handleScroll={handleScroll}
                          inputProps={{
                            'aria-label': t('colleges.searchColleges'),
                            placeholder: t('colleges.searchCollegePlaceHolder'),
                          }}
                          onClose={() => {
                            setShowAdd(false);
                            setFilteredOptions([]);
                            dispatch(resetlookupData(['college']));
                          }}
                          renderOption={modifiedRenderOption}
                          noOptionsText={
                            showAdd ? (
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                flexDirection="column"
                              >
                                {showApiLoading ? (
                                  <Typography aria-live="assertive" role="alert">
                                    {t('academicHistory.loading')}...
                                  </Typography>
                                ) : (
                                  <Typography aria-live="assertive" role="alert">
                                    {t('colleges.collegeSearchNoResult')}
                                  </Typography>
                                )}
                                {!showApiLoading && (
                                  <StyledAddButton
                                    tabIndex={0}
                                    sx={{ fontSize: '0.625rem', mt: 1 }}
                                    size="small"
                                    onClick={() => setShowAddressField(true)}
                                  >
                                    {t('colleges.addNewCollege')}
                                  </StyledAddButton>
                                )}
                              </Box>
                            ) : (
                              <Typography aria-live="assertive" role="alert">
                                {t('academicHistory.minimumSearchlabel')}
                              </Typography>
                            )
                          }
                        />
                      )}
                      control={control}
                      name={`${groupName}.${childPosition}.name`}
                    />
                  </FormControl>
                </>
              )}
              {showAddress && selectedAddress && (
                <Card sx={{ mb: 2, border: theme => `1px solid ${theme.palette.grey[300]}` }}>
                  <Box sx={{ p: 2 }}>
                    <Grid container>
                      <Grid item xs={collegeCardSize}>
                        <Box sx={{ display: 'flex' }}>
                          <Typography gutterBottom variant="subtitle3" component="h1">
                            {(formData?.name as unknown as string) || selectedOptions?.name} &#8226;&nbsp;
                          </Typography>
                          <Typography variant="subtitle6" color="text.secondary">
                            {t('academicHistory.ceeb')}: {formData?.ceeb || selectedOptions?.ceeb || 'N/A'}
                          </Typography>
                        </Box>
                        <Box sx={{ mt: 0.5 }}>
                          <Typography variant="caption" component="div">
                            {collegeLocation}
                          </Typography>
                        </Box>
                      </Grid>
                      {!deleteProperty && (
                        <Grid item xs={1} sx={{ display: 'flex' }}>
                          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton onClick={deleteAddressCard} edge="start" aria-label="delete Icon" color="error">
                              <IconRemoveCircle />
                            </IconButton>
                          </CardActions>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Card>
              )}
              {showAddressField && (
                <>
                  <FormControl fullWidth required error={!!collegeErrors?.[groupName]?.[childPosition]?.name}>
                    <InputLabel htmlFor="name">{t('colleges.name')}</InputLabel>
                    <InputBase
                      inputProps={{
                        'aria-describedby': 'name-error',
                        'aria-label': t('colleges.name'),
                        maxLength: FIELD_LENGTH_250,
                        ...DISABLE_AUTO_FILL,
                      }}
                      {...register(`${groupName}.${childPosition}.name`)}
                    />
                    <FormHelperText role="alert" id="name-error">
                      {collegeErrors?.[groupName]?.[childPosition]?.name?.message}
                    </FormHelperText>
                  </FormControl>

                  <Box>
                    <FormControl
                      required
                      fullWidth
                      error={!!collegeErrors?.[groupName]?.[childPosition]?.address?.country?.code}
                    >
                      <InputLabel htmlFor="country">{t('address.country')}</InputLabel>
                      <Controller
                        render={({ field: { onChange, ...field } }) => (
                          <Dropdown
                            id="country"
                            {...field}
                            options={countryOptions}
                            onChange={option => {
                              setValue(`${addressPrefix}.country.displayName`, option?.text);
                              return onChange(option?.id ?? '');
                            }}
                            inputProps={{
                              'aria-label': t('address.country'),
                              'aria-describedby': 'country-error',
                              ...DISABLE_ADDRESS_FILLING,
                            }}
                          />
                        )}
                        control={control}
                        name={`${addressPrefix}.country.code`}
                      />
                      <FormHelperText role="alert" id="country-error">
                        {collegeErrors?.[groupName]?.[childPosition]?.address?.country?.code?.message}
                      </FormHelperText>
                    </FormControl>
                    <InputBase inputProps={{ type: 'hidden' }} {...register(`${addressPrefix}.country.displayName`)} />
                  </Box>
                  <Grid container spacing={1}>
                    <Grid item xs={6} md={6}>
                      <FormControl
                        fullWidth
                        required
                        error={!!collegeErrors?.[groupName]?.[childPosition]?.address?.region?.code}
                      >
                        <InputLabel htmlFor="region">{t('address.region')}</InputLabel>
                        <Controller
                          render={({ field: { onChange, ...field } }) => (
                            <Dropdown
                              id="region"
                              {...field}
                              options={governingDistrictOptions}
                              onChange={option => {
                                setValue(`${addressPrefix}.region.displayName`, option?.text);
                                return onChange(option?.id ?? '');
                              }}
                              inputProps={{
                                'aria-label': t('address.region'),
                                'aria-describedby': 'region-error',
                                ...DISABLE_ADDRESS_FILLING,
                              }}
                            />
                          )}
                          control={control}
                          name={`${addressPrefix}.region.code`}
                        />
                        <FormHelperText role="alert" id="region-error">
                          {collegeErrors?.[groupName]?.[childPosition]?.address?.region?.code?.message}
                        </FormHelperText>
                      </FormControl>
                      <InputBase inputProps={{ type: 'hidden' }} {...register(`${addressPrefix}.region.displayName`)} />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <FormControl fullWidth error={!!collegeErrors?.[groupName]?.[childPosition]?.address?.city}>
                        <InputLabel htmlFor="city">{t('address.city')}</InputLabel>
                        <InputBase
                          inputProps={{
                            'aria-label': t('address.city'),
                            'aria-describedby': 'city-error',
                            ...DISABLE_ADDRESS_FILLING,
                            maxLength: MAX_MEDIUM_LENGTH_FIELD,
                          }}
                          {...register(`${addressPrefix}.city`)}
                        />
                        <FormHelperText role="alert" id="city-error">
                          {collegeErrors?.[groupName]?.[childPosition]?.address?.city?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              )}
              <Divider sx={{ borderStyle: 'solid', mt: '0.7rem', mb: '1rem' }} flexItem />
              <ConfirmationDialog
                open={showAlert}
                text={t('colleges.deleteCollege')}
                confirmationText={t('academicHistory.deleteContent')}
                onClose={() => {
                  setShowAlert(false);
                }}
                footerButtonConfig={{
                  primary: {
                    title: t('remove_label'),
                    props: {
                      onClick: () => {
                        setShowAlert(false);
                        deleteProperty?.(childPosition || 0, groupName, `colleges/${formData?.id}`);
                      },
                    },
                  },
                  tertiary: {
                    title: t('cancel_label'),
                  },
                }}
              />
            </Box>
          )}

          {(showAddress || showAddressField) && (
            <>
              <Grid container spacing={1} sx={{ mb: 2 }}>
                <Grid item sm={9}>
                  <Typography variant="subtitle4">{t('college.primaryLabel')}</Typography>
                  <FormHelperText sx={{ whiteSpace: 'initial' }} role="alert" id="name-error">
                    {t('college.primaryDetail')}
                  </FormHelperText>
                </Grid>
                <Grid item sm={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Controller
                    control={control}
                    name={`${groupName}.${childPosition}.primary`}
                    defaultValue={false}
                    render={({ field: { onChange, value, ...field } }) => {
                      return (
                        <Switch
                          {...field}
                          color={getCheckboxColor()}
                          checked={value}
                          onChange={(_event, val) => {
                            return onChange(val);
                          }}
                          inputProps={{
                            'aria-label': t('college.primaryLabel'),
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                {isCsuTenant() && (
                  <Grid item sm={12}>
                    <FormControl fullWidth error={!!collegeErrors?.[groupName]?.[childPosition]?.studentId}>
                      <InputLabel htmlFor="studentId">{t('college.communityCollegeLabel')}</InputLabel>
                      <InputBase
                        inputProps={{
                          'aria-label': t('college.communityCollegeLabel'),
                          'aria-describedby': 'studentId-error',
                          ...DISABLE_AUTO_FILL,
                        }}
                        {...register(`${groupName}.${childPosition}.studentId`)}
                      />
                      <FormHelperText role="alert" id="studentId-error">
                        {collegeErrors?.[groupName]?.[childPosition]?.studentId?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                )}

                <Grid item sm={12}>
                  <Controller
                    name={`${groupName}.${childPosition}.startDate`}
                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                      <FormControl fullWidth required error={!!collegeErrors?.[groupName]?.[childPosition]?.startDate}>
                        <InputLabel htmlFor="startDate">{t('academicHistory.startDate')}</InputLabel>
                        <DateField
                          {...field}
                          ref={ref}
                          error={!!error}
                          id="startDate"
                          hidePart="day"
                          onChange={dateVal => {
                            field.onChange(dateVal);
                            setTimeout(() => {
                              trigger(`${groupName}.${childPosition}.endDate`);
                              trigger(`${groupName}.${childPosition}.graduationDate`);
                            }, 0);
                          }}
                          aria-describedby="startDate-error"
                          aria-label="start date"
                          accessibilityLabelPrefix={t('academicHistory.startDate')}
                        />
                        <FormHelperText role="alert" id="startDate-error">
                          {collegeErrors?.[groupName]?.[childPosition]?.startDate?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                    control={control}
                  />
                </Grid>
                <Grid item sm={12}>
                  <Controller
                    name={`${groupName}.${childPosition}.endDate`}
                    render={({ field: { ref, ...field }, fieldState: { error } }) => (
                      <FormControl fullWidth error={!!collegeErrors?.[groupName]?.[childPosition]?.endDate}>
                        <InputLabel htmlFor="endDate">{t('academicHistory.endDate')}</InputLabel>
                        <DateField
                          {...field}
                          ref={ref}
                          error={!!error}
                          id="endDate"
                          hidePart="day"
                          aria-describedby="endDate-error"
                          aria-label="end date"
                          accessibilityLabelPrefix={t('academicHistory.endDate')}
                        />
                        <FormHelperText role="alert" id="endDate-error">
                          {collegeErrors?.[groupName]?.[childPosition]?.endDate?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                    control={control}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item sm={12}>
                  <FormControl fullWidth required>
                    <InputLabel htmlFor={`${t('academicHistory.hasGraduated')}?`}>
                      {t('academicHistory.hasGraduated')}?
                    </InputLabel>
                    <Controller
                      name={`${groupName}.${childPosition}.graduated`}
                      render={({ field: { ref, ...field } }) => (
                        <RadioControl
                          {...field}
                          inputRef={ref}
                          id={`${t('academicHistory.hasGraduated')}?`}
                          options={radioDefaultOptions}
                          inline={true}
                          error={!!collegeErrors?.[groupName]?.[childPosition]?.graduated?.message}
                          aria-describedby="graduated-error"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            trigger(`${groupName}.${childPosition}.graduationDate`);
                            field.onChange(event?.target?.value);
                          }}
                        />
                      )}
                      control={control}
                      defaultValue=""
                    />
                    <StyledFormHelperText role="alert" id="graduated-error">
                      {collegeErrors?.[groupName]?.[childPosition]?.graduated?.message}
                    </StyledFormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item sm={12}>
                <Controller
                  name={`${groupName}.${childPosition}.graduationDate`}
                  render={({ field: { ref, ...field }, fieldState: { error } }) => (
                    <FormControl fullWidth error={!!collegeErrors?.[groupName]?.[childPosition]?.graduationDate}>
                      <InputLabel htmlFor="graduationDate">{t('colleges.expectedOrGraduationDate')}</InputLabel>
                      <DateField
                        {...field}
                        ref={ref}
                        error={!!error}
                        id="graduationDate"
                        onChange={dateVal => {
                          field.onChange(dateVal);
                        }}
                        {...(hasGraduated === 'Yes' ? { hidePart: 'day' } : {})}
                        aria-describedby="graduationDate-error"
                        aria-label="graduation date"
                        accessibilityLabelPrefix={t('colleges.expectedOrGraduationDate')}
                      />
                      <FormHelperText role="alert" id="graduationDate-error">
                        {collegeErrors?.[groupName]?.[childPosition]?.graduationDate?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                  control={control}
                />
              </Grid>
              <FormControl fullWidth error={!!collegeErrors?.[groupName]?.[childPosition]?.gpa}>
                <InputLabel htmlFor={gpaAriaLabel}>
                  {t('academicHistory.gpa')} ({t('academicHistory.gpaMaxScore')})
                </InputLabel>
                <Controller
                  render={({ field: { onChange, ...field } }) => {
                    return (
                      <NumberFormat
                        {...field}
                        decimalScale={2}
                        decimalSeparator="."
                        allowNegative={false}
                        fixedDecimalScale={false}
                        customInput={InputBase}
                        onValueChange={values => onChange(values?.floatValue)}
                        inputProps={{ 'aria-label': gpaAriaLabel }}
                        id={gpaAriaLabel}
                      />
                    );
                  }}
                  name={`${groupName}.${childPosition}.gpa`}
                  control={control}
                />

                <FormHelperText role="alert" id="gpa-error">
                  {collegeErrors?.[groupName]?.[childPosition]?.gpa?.message}
                </FormHelperText>
              </FormControl>
              <Box>
                <FormControl fullWidth required error={!!collegeErrors?.[groupName]?.[childPosition]?.termType?.code}>
                  <InputLabel htmlFor="termType">{t('academicHistory.termType')}</InputLabel>
                  <Controller
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Dropdown
                        id="termType"
                        {...field}
                        inputRef={ref}
                        options={collegeTermTypeOptions}
                        onChange={option => {
                          setValue(`${groupName}.${childPosition}.termType.displayName`, option?.text);
                          return onChange(option?.id ?? '');
                        }}
                        inputProps={{
                          'aria-label': t('academicHistory.termType'),
                          'aria-describedby': 'termType-error',
                          ...DISABLE_AUTO_FILL_AUTOCOMPLETE,
                        }}
                      />
                    )}
                    control={control}
                    name={`${groupName}.${childPosition}.termType.code`}
                  />
                  <FormHelperText role="alert" id="termType-error">
                    {collegeErrors?.[groupName]?.[childPosition]?.termType?.code?.message}
                  </FormHelperText>
                </FormControl>
                <InputBase
                  inputProps={{ type: 'hidden' }}
                  {...register(`${groupName}.${childPosition}.termType.displayName`)}
                />
              </Box>

              <Divider sx={{ borderStyle: 'solid', mt: '1rem', mb: '1rem' }} flexItem />
              <FormControl fullWidth>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ flex: 0.9 }}>
                    <Typography variant="subtitle5" component="h1">
                      {` ${t('colleges.degrees')} `}
                    </Typography>
                  </Box>
                  <Box sx={{ flex: 0.1 }}>
                    <StyledIconButton
                      size="small"
                      disabled={!isValid}
                      onClick={event => {
                        event.stopPropagation();
                        openGrandChildDrawer?.(
                          true,
                          degreeLength || 0,
                          groupName,
                          t('colleges.addDegrees'),
                          childPosition,
                          'degrees'
                        );
                        setTitle(t('colleges.addDegreesTitle'));
                      }}
                      aria-label="Add Degree"
                    >
                      <IconAdd />
                    </StyledIconButton>
                  </Box>
                </Box>

                <Typography sx={{ mt: 1 }} variant="body2">
                  {t('colleges.degreeInstruction')}
                </Typography>
              </FormControl>
              <Grid container spacing={1}>
                {degreesList?.map((degree, index) => {
                  return (
                    <DegreeCard
                      key={`${degree?.startDate}-${degree?.type?.code}`}
                      degreeIndex={index}
                      selector="colleges"
                      childPosition={childPosition}
                      degree={degree}
                      openGrandChildDrawer={openGrandChildDrawer}
                    />
                  );
                })}
              </Grid>

              <Box sx={{ justifyContent: 'center', display: 'flex', mt: 2 }}>
                <StyledAddButton
                  aria-label="Add Degree"
                  sx={{ fontSize: '0.625rem' }}
                  size="small"
                  onClick={() => {
                    openGrandChildDrawer?.(
                      true,
                      degreeLength || 0,
                      groupName,
                      t('colleges.addDegrees'),
                      childPosition,
                      'degrees'
                    );
                    setTitle(t('colleges.addDegreesTitle'));
                  }}
                  disabled={!isValid}
                >
                  {t('colleges.addDegrees')}
                </StyledAddButton>
              </Box>
              {isCourseWorkActive && (
                <>
                  <Divider sx={{ borderStyle: 'solid', mt: '1.5rem', mb: '2rem' }} flexItem />
                  <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
                    {courseLength > 0 && (
                      <Grid item xs={12} sm={12}>
                        <Card variant="outlined">
                          <CardActionArea
                            onClick={() =>
                              openChildDrawer?.(false, childPosition, groupName, t('colleges.courses'), true)
                            }
                            sx={{
                              p: '1rem',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Grid container alignItems="center">
                              <Grid item xs={1.5} md={1}>
                                <Box id="courseIcon" aria-label="Course Icon" role="img">
                                  <IconSchool sx={{ color: 'primary.light' }} />
                                </Box>
                              </Grid>
                              <Grid item xs={9.5} md={10}>
                                <Typography variant="subtitle5">{t('colleges.courses')}</Typography>
                                <Box sx={{ mt: 0.5, display: 'flex', alignItems: 'center' }}>
                                  <Typography variant="body3">
                                    {Pluralize(t('academicHistory.course'), courseLength, true)}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={1}>
                                <Box id="courseCount" aria-label={t('colleges.collegeCoursesList')} role="button">
                                  <IconCaretRight />
                                </Box>
                              </Grid>
                            </Grid>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    )}

                    {!courseLength && (
                      <StyledAddButton
                        sx={{ fontSize: '0.625rem' }}
                        size="small"
                        onClick={() => {
                          dispatch(academicHistorySaveLocalData(getValues()));
                          openGrandChildDrawer?.(
                            true,
                            courseLength || 0,
                            groupName,
                            t('colleges.addCollegeCourse'),
                            childPosition,
                            'terms'
                          );
                        }}
                        disabled={!isValid}
                      >
                        {t('academicHistory.addCourse')}
                      </StyledAddButton>
                    )}
                  </Grid>
                </>
              )}
              <AttachedFiles sectionName="colleges" tag={`colleges/${formData?.id}`} disabled={!isValid} />
              {!isCsuTenant() && <SkillsMultiselect tag={`colleges/${formData?.id}`} disabled={!isValid} />}
              {deleteProperty && (
                <>
                  <Box sx={{ mt: '1rem', mb: '1rem', justifyContent: 'center', display: 'flex' }}>
                    <InputBase
                      inputProps={{ type: 'hidden' }}
                      {...register(`${groupName}.${childPosition}.address`)}
                      defaultValue={JSON.stringify(data?.colleges?.[childPosition]?.address || {})}
                    />
                    <InputBase
                      inputProps={{ type: 'hidden' }}
                      {...register(`${groupName}.${childPosition}.name`)}
                      defaultValue={JSON.stringify(data?.colleges?.[childPosition]?.name || {})}
                    />
                    <StyledDeleteButton onClick={() => setShowAlert(true)}>
                      {t('colleges.removeCollege')}
                    </StyledDeleteButton>
                  </Box>
                  <Divider sx={{ borderStyle: 'solid', mt: '2rem', mb: '1rem' }} flexItem />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default CollegeForm;
