/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export const APP_TITLE = 'Living Profile';

export const DEFAULT_EMAIL_MOBILE = {
  contact: {
    emails: [
      {
        email: '',
        type: {
          code: 'HOME',
          displayName: 'Home',
        },
        preferred: true,
      },
    ],
    phones: [
      {
        phone: '',
        type: {
          code: 'HOME',
          displayName: 'Home',
        },
        preferred: true,
      },
    ],
  },
};

export const DEFAULT_NAME = {
  personal: {
    hasFormerName: 'No',
    hasPreferredName: 'No',
  },
};

export const DEFAULT_LANGUAGES = {
  languages: [
    {
      language: { code: '', displayName: '' },
      proficiencyLevel: { code: '', displayName: '' },
    },
  ],
};

export const gradeMapping = [
  { name: 'fullYear', title: 'Full Year', parent: 'FULL_YEAR' },
  { name: 'fall', title: 'Fall', parent: 'SEMESTER' },
  { name: 'spring', title: 'Spring', parent: 'SEMESTER' },
  { name: 'fall', title: 'Fall', parent: 'TRIMESTER' },
  { name: 'winter', title: 'Winter', parent: 'TRIMESTER' },
  { name: 'spring', title: 'Spring', parent: 'TRIMESTER' },
  { name: 'quarter1', title: 'Quarter 1', parent: 'QUARTER' },
  { name: 'quarter2', title: 'Quarter 2', parent: 'QUARTER' },
  { name: 'quarter3', title: 'Quarter 3', parent: 'QUARTER' },
  { name: 'quarter4', title: 'Quarter 4', parent: 'QUARTER' },
  { name: 'summer1', title: 'Summer 1', parent: 'SUMMER' },
  { name: 'summer2', title: 'Summer 2', parent: 'SUMMER' },
  { name: 'summer3', title: 'Summer 3', parent: 'SUMMER' },
  { name: 'summer4', title: 'Summer 4', parent: 'SUMMER' },
];

export const gradeSummerMapping = [
  { name: 'summer1', title: 'Summer 1', parent: 'QUARTER' },
  { name: 'summer2', title: 'Summer 2', parent: 'QUARTER' },
  { name: 'summer3', title: 'Summer 3', parent: 'QUARTER' },
  { name: 'summer4', title: 'Summer 4', parent: 'QUARTER' },
  { name: 'summer1', title: 'Summer 1', parent: 'SEMESTER' },
  { name: 'summer2', title: 'Summer 2', parent: 'SEMESTER' },
  { name: 'summer1', title: 'Summer 1', parent: 'TRIMESTER' },
  { name: 'summer2', title: 'Summer 2', parent: 'TRIMESTER' },
  { name: 'summer3', title: 'Summer 3', parent: 'TRIMESTER' },
  { name: 'summer1', title: 'Summer 1', parent: 'FULL_YEAR' },
];

export const termsOrdered = ['Fall', 'Summer 2', 'Summer 1', 'Spring', 'Winter'];

export const appleFormats = ['image/heic', 'image/heif'];
