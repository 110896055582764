/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export const { ASSIST_URL, TSP_URL: BASEURL } = window.env;

export const API = {
  eligibility: `${BASEURL}/eligibility`,
  getCampuses: `${ASSIST_URL}/campuses`,
  programs: `${ASSIST_URL}/campuses/programs`,
  programCart: `${BASEURL}/programCart`,
  programCartDetails: `${ASSIST_URL}/programs`,
  enrollment: `${BASEURL}/enrollment`,
  tspEnrollmentPeriod: `${BASEURL}/enrollment/tspEnrollmentPeriodDates`,
  baseUrl: `${BASEURL}/`,
  generalEducationRequirements: `${BASEURL}/generalEducation`,
  adtColleges: `${BASEURL}/adtColleges`,
  additionalDetails: `${BASEURL}/additionalDetails`,
  geRequirementFulfillment: `${BASEURL}/geRequirementFulfillment`,
  getFollowedPrograms: `${BASEURL}/followedPrograms`,
  gpaCalculationEndPoint: `${BASEURL}/GPACalculation`,
  questionnaireConfig: `${BASEURL}/config/latest`,
};
