/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import axios from 'axios';

import { getLoggedUser } from 'utils/keyCloakUtils';
import { API } from 'transferPlanner/constants/api';
import { IProgram } from 'transferPlanner/store/searchProgram/searchProgram.slice';
import { ICampus } from 'transferPlanner/pages/CampusesAndPrograms/CampusesAndPrograms.utils';

export type TCountsByFirstCharacter = [string, number][];

type TFetchProgramsResponse = {
  content: IProgram[];
  countsByFirstCharacter: TCountsByFirstCharacter;
  hasNextPage: boolean;
  total: number;
};

export type TFetchProgramDetailsResponse = {
  id: number;
  name: string;
  programCode: string;
  description: string;
  requirements: string;
  degreeType: string;
  tspEligible: true;
  enrollCapacity: number;
  majorPreReqGPA: number;
  minGPA: number;
  minGrade: {
    code: string;
    displayName: string;
  };
  transferableUnits: number;
  postalCode: string;
  moKey: string;
  format: string;
  status: string;
  branding: IProgramBranding[];
  agreementArtifacts: IProgramAgreementArtifacts[];
  campus: ICampus;
};

interface IProgramBranding {
  configName: string;
  configValue: string;
}

interface IProgramAgreementArtifacts {
  communityCollegeAlternateId: number;
  communityCollegeName: string;
  archivedArtifactUrl: string;
}

interface IFetchProgramDetails {
  campusesId: number;
  programId: number;
}

export const fetchPrograms = async (queryParams = ''): Promise<TFetchProgramsResponse> => {
  const { data } = await axios.get(`${API.programs}${queryParams}`);

  return data;
};

export const fetchProgramDetails = async (params: IFetchProgramDetails): Promise<TFetchProgramDetailsResponse> => {
  const { data } = await axios.get(`${API.getCampuses}/${params.campusesId}/programs/${params.programId}`);

  return data;
};

export const fetchSimilarityFlag = async (moKey = ''): Promise<boolean> => {
  const { data } = await axios.get(`${API.additionalDetails}/${getLoggedUser()}/programSimilarity?moKey=${moKey}`);

  return data;
};
