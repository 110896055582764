/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Grid } from '@mui/material';
import { UseFormReturn, type FieldValues } from 'react-hook-form';
import { isEmpty } from 'utils/utilities';
import { DropdownField, RadioField, TextField } from './FormFields';
import type { TAnswer, TQuestion, TQuestionnaireConfig } from '../DynamicQuestionnaire.utils';
import { getCustomEntryCode, showDependent } from '../DynamicQuestionnaire.utils';

type TFormBuilder = {
  config: TQuestionnaireConfig | null;
  methods: UseFormReturn<FieldValues>;
};

const FormBuilder = ({ config, methods }: TFormBuilder): ReactElement => {
  const { watch, getValues } = methods;
  const watchFields = Object.keys(watch()).length ? watch() : getValues();

  const renderFormField = (question: TQuestion): ReactElement => {
    const {
      code: questionCode,
      meta: { code },
      answers,
    } = question;

    const showCustomEntry = (): boolean => {
      const val = watchFields[questionCode];
      if (!isEmpty(val)) {
        const answerObj = answers.find(answer => answer.code === val);
        return !!answerObj?.customEntry;
      }
      return false;
    };

    const getCutomField = (type?: string): string => {
      const val = watchFields[questionCode];
      const answerObj = answers.find(answer => answer.code === val);
      if (type === 'code') {
        return getCustomEntryCode(questionCode, answerObj as TAnswer);
      }
      return `${answerObj?.displayName}`;
    };

    switch (code) {
      case 'RADIO': {
        return (
          <>
            <RadioField {...question} methods={methods} />
            {showCustomEntry() && (
              <TextField
                code={getCutomField('code')}
                displayName={getCutomField()}
                methods={methods}
                isCustomEntry={true}
              />
            )}
          </>
        );
      }
      case 'DROPDOWN': {
        return (
          <>
            <DropdownField {...question} methods={methods} />
            {showCustomEntry() && (
              <TextField
                code={getCutomField('code')}
                displayName={getCutomField()}
                methods={methods}
                isCustomEntry={true}
              />
            )}
          </>
        );
      }
      case 'TEXT': {
        return <TextField {...question} methods={methods} />;
      }
      default:
        return <></>;
    }
  };

  return (
    <>
      {config?.questions.map(question => {
        const { dependencies } = question;
        return (
          <Grid key={question.code} item container>
            {showDependent(dependencies, watchFields) && renderFormField(question)}
          </Grid>
        );
      })}
    </>
  );
};

export default FormBuilder;
