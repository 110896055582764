/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Box, Alert } from '@mui/material';
import { styled } from '@mui/system';

export const StyledRootBox = styled(Box)<{
  fullpage: string;
  shoulddisplaythebanner: string;
  impersonationtoken: string;
}>(({ theme: { breakpoints }, fullpage, shoulddisplaythebanner, impersonationtoken }) => {
  const bannerTopSize = shoulddisplaythebanner === 'true' ? '5.38rem' : '';
  const impersonateTopSize = impersonationtoken === 'true' ? '4.38rem' : '';
  const isBothTopSizeTrue = shoulddisplaythebanner === 'true' && impersonationtoken === 'true';
  const topSize = isBothTopSizeTrue ? '4.38rem' : impersonateTopSize || bannerTopSize;
  return {
    [breakpoints.only('xs')]: {
      marginTop: fullpage === 'true' ? '4rem' : topSize,
    },
  };
});

export const StyledBox = styled(Box)<{
  fullpage?: string;
  scrollposition: boolean;
}>(({ theme: { breakpoints }, scrollposition = 0 }) => {
  return {
    position: scrollposition ? 'fixed' : 'static',
    width: '100%',
    left: 0,
    zIndex: 11,
    top: 0,
    [breakpoints.down('sm')]: {
      position: scrollposition ? 'fixed' : 'absolute',
      top: scrollposition ? '3.38rem' : '0 !important',
    },
  };
});

export const StyledAlert = styled(Alert)<{ fullpage: string }>(({ theme: { breakpoints }, fullpage }) => ({
  borderRadius: 0,
  borderBottom: '2px solid #F3984C',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  [breakpoints.up('sm')]: {
    marginLeft: fullpage === 'true' ? 0 : '3.8rem',
  },
  [breakpoints.only('xs')]: {
    marginLeft: 0,
  },
}));
