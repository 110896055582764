/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

export const DEFAULT = 'DEFAULT';

export const enum EFileSource {
  EXISTING = 'existing',
  NEW = 'new',
}
