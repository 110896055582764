/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SidePanel, CollapsibleSection as Accordion } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import { sxSidePanel } from 'pages/Pages.styles';
import { selectCareerGoals, selectLoading } from 'userProfile/store/careerInfo/careerInfo.selectors';
import { TCareerGoalsInfoField } from 'userProfile/store/careerInfo/careerInfo.slice';
import { ViewBuilder } from 'userProfile/components/ViewBuilder';
import { PageTitle } from 'components/PageTitle';
import { Spinner } from 'components/Spinner';
import { getButtonColor, sanitizePayload } from 'utils/utilities';
import { getLookUps, setTitle } from 'utils/commonUtils';
import { selectIndustryOfInterestLookup } from 'store/common/commonInfo.selectors';
import config from 'userProfile/appConfig/careerGoals.json';
import { GoalInformationForm } from './CareerGoalsForm';
import { getCareerGoals, postCareerGoals, lookupsEndPoint } from './CareerGoals.utils';
import { validationSchema } from './CareerGoalsForm.validation';

const CareerGoals = (): ReactElement => {
  const [isOpenRightSideDrawer, setIsOpenRightSideDrawer] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const careerGoalsData = useSelector(selectCareerGoals);
  const industryOfInterestLookup = useSelector(selectIndustryOfInterestLookup);
  const isLoading = useSelector(selectLoading);
  const methods = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: async (...args) => yupResolver(validationSchema, { abortEarly: false })(...args),
  });

  useEffect(() => {
    setTitle(t('careerGoal.pageTitle'));
  }, [t]);

  useEffect(() => {
    dispatch(getCareerGoals());
  }, [dispatch]);

  useEffect(() => {
    if (!industryOfInterestLookup) {
      dispatch(getLookUps(lookupsEndPoint));
    }
  }, [dispatch, industryOfInterestLookup]);

  const openDrawer = () => {
    setIsOpenRightSideDrawer(true);
  };

  const onSubmit = (data: TCareerGoalsInfoField) => {
    dispatch(
      postCareerGoals(sanitizePayload(JSON.parse(JSON.stringify(data))), () => {
        setIsOpenRightSideDrawer(false);
      })
    );
  };

  const closeParent = () => {
    setIsOpenRightSideDrawer(false);
  };
  const { sections } = config;

  return (
    <Grid container>
      {isLoading && <Spinner backdrop />}
      <Box sx={{ width: '100%' }}>
        <PageTitle title={t('careerGoal.pageTitle')} handleClick={openDrawer} />
        <FormProvider {...methods}>
          <form>
            <SidePanel
              size="small"
              title={t('careerGoal.pageTitle')}
              isBackdropClickEnabled={true}
              open={isOpenRightSideDrawer}
              onClose={closeParent}
              footerButtonConfig={{
                primary: {
                  title: t('save_label'),
                  props: {
                    'aria-label': t('save_label'),
                    color: getButtonColor(),
                    variant: 'contained',
                    disabled: !methods.formState.isValid,
                    onClick: methods.handleSubmit(onSubmit),
                  },
                },
                tertiary: {
                  title: t('cancel_label'),
                  props: {
                    'aria-label': t('cancel_label'),
                    color: getButtonColor(),
                    onClick: closeParent,
                  },
                },
              }}
              sx={sxSidePanel}
            >
              <GoalInformationForm data={careerGoalsData || null} />
            </SidePanel>
          </form>
        </FormProvider>
        {Object.keys(sections).map(section => {
          const accordionValues = Object(sections)[section];
          const responseData = Object(careerGoalsData);
          return (
            <React.Fragment key={section}>
              <Box sx={{ mb: '1rem', mt: '1rem' }}>
                <Accordion expanded title={t(`${section}.title`).toUpperCase()} groupId="">
                  <ViewBuilder
                    fieldDetails={accordionValues?.fields}
                    responseData={responseData}
                    cardView={false}
                    localeKey={section}
                    selector=""
                    formTitle=""
                  />
                </Accordion>
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
    </Grid>
  );
};

export default memo(CareerGoals);
