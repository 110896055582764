/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Action, combineReducers, createAction } from '@reduxjs/toolkit';
import authReducer from 'store/auth/auth.slice';
import uiReducer from 'store/ui/ui.slice';
import commonInfoReducer from 'store/common/commonInfo.slice';
import academicHistoryReducer from 'store/academicHistory/academicHistory.slice';
import featuresReducer from 'store/features/features.slice';
import standardTestsReducer from 'store/standardTests/standardTests.slice';

import lpStudentReducers from 'userProfile/store';
import transferPlannerReducers from 'transferPlanner/store';

export const resetGlobalErrorState = createAction<undefined>('applicant/resetErrors');

const combinedReducer = combineReducers({
  accountAuth: authReducer,
  ...lpStudentReducers,
  ...transferPlannerReducers,
  ui: uiReducer,
  commonInfo: commonInfoReducer,
  academicHistory: academicHistoryReducer,
  features: featuresReducer,
  standardTests: standardTestsReducer,
});

type TCombinedState = ReturnType<typeof combinedReducer>;

const resetErrorStates = (state: TCombinedState) =>
  Object.keys(state).reduce((updatedState, stateKey) => {
    const reducer = (updatedState as unknown as { [key: string]: Record<string, unknown> })[stateKey];

    return {
      ...updatedState,
      [stateKey]: Object.keys(reducer).reduce(
        (updatedReducer, reducerKey) => ({
          ...updatedReducer,
          [reducerKey]: reducerKey.endsWith('Error') ? null : updatedReducer[reducerKey],
        }),
        reducer
      ),
    };
  }, state);

const rootReducer = (state: TCombinedState | undefined, action: Action): TCombinedState => {
  if (state && action.type === resetGlobalErrorState.toString()) {
    return combinedReducer(resetErrorStates(state), action);
  }

  return combinedReducer(state, action);
};

export default rootReducer;
