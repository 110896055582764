/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, Fragment, useEffect, useCallback, useState, useMemo } from 'react';
import {
  Grid,
  IconButton,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Box,
  useMediaQuery,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/system';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import type {
  ICollegeCourse,
  ICollege,
  ITerm,
  TCourseExtensionObj,
  TCourseExtensionPayload,
} from 'store/academicHistory/academicHistory.slice';
import { selectAcademicHistory, selectCoursesExtensions } from 'store/academicHistory/academicHistory.selectors';
import {
  IconEdit,
  CollapsibleSection as Accordion,
  SidePanel,
  Icon,
  Alert,
  IconAlertTriangle,
  IconCheckCircle,
} from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import { StyledAddButton, sxCard, sxSidePanel, tableStyles } from 'pages/Pages.styles';
import { StyledTooltip } from 'userProfile/pages/ManageAccount/Privacy/Privacy.utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { termValidationSchema } from 'userProfile/pages/AcademicHistory/AcademicHistoryForm.validation';
import CollegeTermForm from 'userProfile/pages/AcademicHistory/AcademicHistoryForm/CollegeTermForm';
import { prepareTermsOptions } from 'userProfile/pages/AcademicHistory/AcademicHistoryForm/AcademicHistoryForm.utils';
import {
  filterCourseExtensions,
  postAcademicHistory,
  postCourseExtensions,
  preparePayloadForExtension,
} from 'utils/AcademicHistory.utils';
import { getButtonColor, isCsuTenant, sanitizePayload } from 'utils/utilities';
import { CourseCard } from 'transferPlanner/components/CourseCard';
import { setUi } from 'store/ui/ui.slice';

interface ICollegeCoursesProp {
  data: ICollege | undefined;
  groupName: string;
  childPosition: number;
  openGrandChildDrawer?: (
    isAddNew: boolean,
    childPosition: number,
    parentSelector: string,
    formTitle?: string,
    parentPosition?: number,
    sectionPrefix?: string,
    courseIndex?: number,
    isEditAnother?: boolean
  ) => void;
  closeChildDrawer: (() => void) | undefined;
}

const CollegeCourses = ({
  data,
  groupName,
  childPosition,
  openGrandChildDrawer,
  closeChildDrawer,
}: ICollegeCoursesProp): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tooltipLocation, setTooltipLocation] = useState<string>();
  const [isOpenTermPanel, setIsOpenTermPanel] = useState(false);
  const [selectedTerm, setselectedTerm] = useState<ITerm>();
  const [selectedTermIndex, setSelectedTermIndex] = useState<number>(0);
  const academicHistoryData = useSelector(selectAcademicHistory);
  const coursesExtensions = useSelector(selectCoursesExtensions);

  const termsList = useMemo(() => data?.terms, [data]);
  const fromyear = data?.startDate ? moment(data?.startDate, 'YYYY-MMM').year() : 1990;
  const toYear = data?.endDate ? moment(data?.endDate, 'YYYY-MMM').year() : 2100;
  const termsOptions = useMemo(() => prepareTermsOptions(termsList), [termsList]);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  const methods = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(termValidationSchema, { abortEarly: false })(...args),
    context: { formName: 'terms' },
  });

  useEffect(() => {
    if (!termsList?.length) {
      closeChildDrawer?.();
    }
  }, [termsList, closeChildDrawer]);

  const closeTermPanel = () => {
    setIsOpenTermPanel(false);
  };

  const onSubmit = () => {
    let currentTerm = methods.getValues() as ITerm;
    const colleges = [...(academicHistoryData?.colleges || [])] as unknown as ICollege[];
    const allTerms = [...(colleges[childPosition]?.terms || [])];
    const currentTermIndex = allTerms.findIndex(term => term.id === currentTerm.id);
    const { completionStatus, courses } = currentTerm;
    const exisitingTermStatus = allTerms[currentTermIndex]?.completionStatus?.code;
    if (completionStatus.code === 'COMPLETED') {
      const termCourses = courses?.map(course => {
        return { ...course, grade: course.grade || 'None' };
      });
      currentTerm = { ...currentTerm, courses: termCourses };
    } else {
      const termCourses = courses?.map(course => {
        return { ...course, grade: undefined, normalizedGrade: undefined };
      });
      currentTerm = { ...currentTerm, courses: termCourses };
    }
    allTerms[currentTermIndex] = currentTerm;
    colleges[childPosition] = {
      ...colleges[childPosition],
      terms: allTerms,
    };
    const payload = {
      ...academicHistoryData,
      colleges,
    };
    dispatch(
      postAcademicHistory(sanitizePayload(Object.assign(payload)), () => {
        if (completionStatus.code !== 'COMPLETED' && exisitingTermStatus === 'COMPLETED') {
          const updatedExtensionPayload = coursesExtensions?.map((extension: TCourseExtensionObj) => {
            if (extension?.termId === currentTerm.id) {
              const currentExtension = { ...extension };
              delete currentExtension?.grade;
              delete currentExtension?.normalizedGrade;
              return currentExtension;
            }
            return extension;
          });
          dispatch(postCourseExtensions(preparePayloadForExtension(updatedExtensionPayload as TCourseExtensionObj[])));
        }
        closeTermPanel();
      })
    );
  };

  const deleteTerm = () => {
    const colleges = [...(academicHistoryData?.colleges || [])] as unknown as ICollege[];
    const revertAcademicHistoryPayload = { ...academicHistoryData };
    const allTerms = [...(colleges[childPosition]?.terms || [])];
    let removeCourse: ICollegeCourse[] = [];

    if (allTerms.length > 0) {
      removeCourse = allTerms[selectedTermIndex].courses as ICollegeCourse[];
      allTerms.splice(selectedTermIndex, 1);
    }

    colleges[childPosition] = {
      ...colleges[childPosition],
      terms: allTerms,
    };
    const payload = {
      ...academicHistoryData,
      colleges,
    };
    dispatch(
      postAcademicHistory(sanitizePayload(Object.assign(payload)), () => {
        if (isCsuTenant()) {
          const coursesExtensionsPayload = filterCourseExtensions(coursesExtensions, removeCourse);
          if (coursesExtensionsPayload) {
            dispatch(
              postCourseExtensions(
                sanitizePayload(Object.assign(coursesExtensionsPayload)) as unknown as TCourseExtensionPayload,
                () => {
                  closeTermPanel();
                },
                () => {
                  // revert academic history in the case of postCourseExtensions failure
                  dispatch(setUi({ name: 'succesSnackbar', state: { open: false } }));
                  dispatch(
                    postAcademicHistory(sanitizePayload(Object.assign(revertAcademicHistoryPayload)), () => {}, true)
                  );
                }
              )
            );
          } else {
            closeTermPanel();
          }
        } else {
          closeTermPanel();
        }
      })
    );
  };

  const handleTermPanel = () => {
    return (
      <FormProvider {...methods}>
        <form>
          <SidePanel
            size="small"
            open={isOpenTermPanel}
            onClose={closeTermPanel}
            title={t(`colleges.edit${data?.termType?.code}`)}
            isBackdropClickEnabled={true}
            footerButtonConfig={{
              primary: {
                title: t('save_label'),
                props: {
                  'aria-label': t('save_label'),
                  color: getButtonColor(),
                  variant: 'contained',
                  disabled: !methods.formState.isValid,
                  onClick: methods.handleSubmit(onSubmit),
                },
              },
              tertiary: {
                title: t('cancel_label'),
                props: {
                  'aria-label': t('address.cancelAddress'),
                  color: getButtonColor(),
                  onClick: closeTermPanel,
                },
              },
            }}
            sx={sxSidePanel}
          >
            <CollegeTermForm
              selectedTerm={selectedTerm}
              collegeStartYear={fromyear}
              collegeEndYear={toYear}
              termList={termsOptions}
              collegeTermType={data?.termType}
              deleteTerm={deleteTerm}
            />
          </SidePanel>
        </form>
      </FormProvider>
    );
  };

  type TgradeAlert = {
    isShowAlert: boolean;
    noneGradeIndex: number;
  };

  const completedGradeAlert = useCallback((term: ITerm): TgradeAlert => {
    const { completionStatus, courses } = term;
    if (completionStatus.code === 'COMPLETED') {
      const firstMatchingGrade = courses?.findIndex(course => course.grade === 'None');
      if (firstMatchingGrade !== -1) {
        return {
          isShowAlert: true,
          noneGradeIndex: firstMatchingGrade as number,
        };
      }
    }
    return { isShowAlert: false, noneGradeIndex: -1 };
  }, []);

  return (
    <Grid container spacing={2}>
      {termsList?.map((termDetails: ITerm, index: number) => {
        const { isShowAlert, noneGradeIndex } = completedGradeAlert(termDetails);
        const totalCredits = termDetails?.courses?.reduce((total, row) => total + (row.credits || 0), 0);
        return (
          <React.Fragment key={`${termDetails?.type?.displayName} ${termDetails?.academicYear}`}>
            <Grid item xs={12} sx={{ mt: '.5rem' }} role="group">
              <Accordion
                expanded
                aria-labelledby={`${termDetails?.type?.displayName} ${termDetails?.academicYear} • ${termDetails?.completionStatus?.displayName}`}
                groupId={`${termDetails?.type?.displayName}-${termDetails?.academicYear}`}
                title={`${termDetails?.type?.displayName} ${termDetails?.academicYear} • ${termDetails?.completionStatus?.displayName}`}
                actions={[
                  {
                    icon: <IconEdit />,
                    label: `${t('colleges.editTerm')} ${termDetails?.type?.displayName} ${termDetails?.academicYear}`,
                    size: 'small',
                    onClick: () => {
                      setIsOpenTermPanel(true);
                      setselectedTerm(termDetails);
                      setSelectedTermIndex(index);
                    },
                  },
                ]}
              >
                <>
                  {isShowAlert && (
                    <Alert
                      sx={{ mb: 1 }}
                      actionText={`${t('add_label')} ${t('colleges.course.grade')}`}
                      actionVariant="text"
                      direction="horizontal"
                      icon
                      onAction={() =>
                        openGrandChildDrawer?.(
                          false,
                          index || 0,
                          groupName,
                          t('colleges.editCollegeCourse'),
                          childPosition,
                          'terms',
                          noneGradeIndex,
                          true
                        )
                      }
                      size="default"
                      type="error"
                    >
                      {t('colleges.course.term.completionStatusAlert')}
                    </Alert>
                  )}
                  {isMobile ? (
                    <>
                      {(termDetails?.courses || []).map((course: ICollegeCourse, courseIndex: number) => (
                        <Fragment key={course?.id}>
                          {course && (
                            <CourseCard
                              course={course}
                              isEditable={true}
                              onClick={() =>
                                openGrandChildDrawer?.(
                                  false,
                                  index || 0,
                                  groupName,
                                  t('colleges.editCollegeCourse'),
                                  childPosition,
                                  'terms',
                                  courseIndex,
                                  course?.grade === 'None'
                                )
                              }
                            />
                          )}
                        </Fragment>
                      ))}
                      {termDetails?.courses && termDetails?.courses.length > 0 && (
                        <Card role="listitem" variant="outlined" sx={{ ...sxCard, my: 1, color: 'text.primary' }}>
                          <CardContent sx={{ py: 1, '&:last-child': { pb: 1 } }}>
                            <Stack direction="row" justifyContent="space-between">
                              <Typography variant="subtitle3">{t('college.totalUnits')}</Typography>
                              <Typography variant="subtitle3">{totalCredits}</Typography>
                            </Stack>
                          </CardContent>
                        </Card>
                      )}
                    </>
                  ) : (
                    <TableContainer component={Paper}>
                      <Table
                        aria-label="table header"
                        sx={{
                          ...tableStyles.font,
                          borderCollapse: 'unset',
                        }}
                      >
                        <TableRow sx={tableStyles.header}>
                          {isCsuTenant() && <TableCell align="left" />}
                          <TableCell align="left">{t('colleges.course.code')}</TableCell>
                          <TableCell align="left">{t('academicHistory.courseName')}</TableCell>
                          <TableCell align="left">{t('colleges.credits')}</TableCell>
                          <TableCell align="left">{t('colleges.course.grade')}</TableCell>
                          <TableCell align="left" />
                        </TableRow>
                        <TableBody>
                          {(termDetails?.courses || []).map((course: ICollegeCourse, courseIndex: number) => {
                            const tooltipMapping = `${course.code}-${courseIndex}`;
                            return (
                              <TableRow key={course.code} sx={tableStyles.rowShadow}>
                                {isCsuTenant() && (
                                  <TableCell align="left">
                                    <StyledTooltip
                                      open={tooltipMapping === tooltipLocation}
                                      componentsProps={{ tooltip: { role: 'button' } }}
                                      title={
                                        <>
                                          {course?.source?.code === 'ASSIST'
                                            ? t('colleges.course.source.assist.tooltip')
                                            : t('colleges.course.source.manual.tooltip')}
                                        </>
                                      }
                                      onClose={() => setTooltipLocation(undefined)}
                                    >
                                      <IconButton
                                        onMouseOver={() => setTooltipLocation(tooltipMapping)}
                                        onClick={() => setTooltipLocation(tooltipMapping)}
                                        size="small"
                                        edge="start"
                                        color={course?.source?.code === 'ASSIST' ? 'success' : 'warning'}
                                        aria-label="icon manual or verified course"
                                      >
                                        {course?.source?.code === 'ASSIST' ? (
                                          <IconCheckCircle />
                                        ) : (
                                          <IconAlertTriangle />
                                        )}
                                      </IconButton>
                                    </StyledTooltip>
                                  </TableCell>
                                )}
                                <TableCell align="left">{course.code}</TableCell>
                                <TableCell align="left">{course.title}</TableCell>
                                <TableCell align="left">{course.credits}</TableCell>
                                <TableCell align="left">
                                  {course?.grade === 'None' ? (
                                    <Icon
                                      aria-label="No Grade Selected"
                                      sx={{ color: theme => theme.palette.error.main }}
                                    >
                                      IconAlertCritical
                                    </Icon>
                                  ) : (
                                    course?.grade
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  <IconButton
                                    aria-label={t('colleges.editCollegeCourse')}
                                    onClick={() =>
                                      openGrandChildDrawer?.(
                                        false,
                                        index || 0,
                                        groupName,
                                        t('colleges.editCollegeCourse'),
                                        childPosition,
                                        'terms',
                                        courseIndex,
                                        course?.grade === 'None'
                                      )
                                    }
                                    size="small"
                                  >
                                    <IconEdit />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          {termDetails?.courses && termDetails?.courses.length > 0 && (
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }} colSpan={3}>
                                {t('college.totalUnits')}
                              </TableCell>
                              <TableCell sx={{ fontWeight: 'bold' }} align="left">
                                {totalCredits}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  <Box sx={{ display: 'flex', justifyContent: 'center', pt: '2rem' }}>
                    <StyledAddButton
                      onClick={() =>
                        openGrandChildDrawer?.(
                          true,
                          index,
                          groupName,
                          t('academicHistory.addCourse'),
                          childPosition,
                          'terms',
                          termDetails?.courses?.length,
                          false
                        )
                      }
                    >
                      {t('academicHistory.addCourse')}
                    </StyledAddButton>
                  </Box>
                </>
              </Accordion>
            </Grid>
          </React.Fragment>
        );
      })}
      {handleTermPanel()}
    </Grid>
  );
};

export default CollegeCourses;
