/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';
import { IEligibility, IEligibilityState } from './eligibility.slice';

export const selectEligibility = (state: TRootState): IEligibilityState => state.eligibility;

export const selectEnrollmentEligibility = createSelector(
  selectEligibility,
  (eligibilityState: IEligibilityState) => eligibilityState?.eligibility
);

export const selectTspEligibility = createSelector(selectEnrollmentEligibility, (eligibility: IEligibility | null) => {
  if (eligibility) {
    return Object.keys(eligibility).length ? eligibility?.tspEligibility : [];
  }
  return null;
});

export const selectQuestionnaireConfig = createSelector(
  selectEligibility,
  (eligibilityState: IEligibilityState) => eligibilityState?.questionnaireConfig
);
