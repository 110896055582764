/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITimeLineInfo } from 'transferPlanner/pages/TimelineCalculator/TimelineCalculator.utils';

type TError = string | null;

export interface ITimelineInfoState {
  loading: boolean;
  calculatorBase: ITimeLineInfo | null;
  error: TError;
}

export const initialState: ITimelineInfoState = {
  loading: false,
  calculatorBase: null,
  error: null,
};

const timelineInfoSlice = createSlice({
  name: 'timelineInfo',
  initialState,
  reducers: {
    timelineInfoStart: (state): ITimelineInfoState => ({
      ...state,
      loading: true,
      error: null,
    }),
    timelineInfoSuccess: (state, action: PayloadAction<ITimeLineInfo>): ITimelineInfoState => ({
      ...state,
      loading: false,
      calculatorBase: action.payload,
    }),
    timelineInfoFailure: (state, action: PayloadAction<TError>): ITimelineInfoState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const { timelineInfoStart, timelineInfoSuccess, timelineInfoFailure } = timelineInfoSlice.actions;

export default timelineInfoSlice.reducer;
