/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';
import { IProgramsState } from './programs.slice';

const adtPrograms = (state: TRootState): IProgramsState => state.adtPrograms;

export const selectPrograms = createSelector(adtPrograms, (stateData: IProgramsState) => stateData?.programs);

export const selectProgramLoading = createSelector(adtPrograms, (stateData: IProgramsState) => stateData?.loading);
