/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import * as yup from 'yup';
import { DOB_FORMAT } from 'constants/general';
import { TEXT_REGEX } from 'constants/regex';
import moment from 'moment';
import { min, today, yearValidator } from 'utils/utilities';
import { t } from 'i18next';

export const validationSchema = yup.object().shape({
  honorsOrAwards: yup.array().of(
    yup.object().shape({
      type: yup.object().shape({
        code: yup.string().required(t('honorsOrAwards.error.type_required')),
      }),
      name: yup
        .string()
        .trim()
        .required(t('honorsOrAwards.error.name_required'))
        .matches(RegExp(TEXT_REGEX), t('error.notAllowed')),
      source: yup
        .string()
        .trim()
        .required(t('honorsOrAwards.error.source_required'))
        .matches(RegExp(TEXT_REGEX), t('error.notAllowed')),
      receivedDate: yup
        .string()
        .matches(/^\d{4}([./-])\d{2}\1\d{2}$/, t('error.invalidDate'))
        .test('Is date valid', t('error.invalidDate'), value => {
          if (!value) return true;
          return moment(value, DOB_FORMAT, true).isValid();
        })
        .test('Min Date', t('error.minDate'), value => {
          if (value && moment(value, DOB_FORMAT, true).isValid()) {
            return new Date(new Date(min).setHours(0)).getTime() < new Date(value).getTime();
          }
          return true;
        })
        .test('Max date', t('error.maxDate'), value => {
          if (value && moment(value, DOB_FORMAT, true).isValid()) {
            return today.getTime() >= new Date(value).getTime();
          }
          return true;
        }),
      description: yup.string().matches(RegExp(TEXT_REGEX), t('error.notAllowed')),
    })
  ),
  experiences: yup.array().of(
    yup.object().shape({
      type: yup.object().shape({
        code: yup.string().required(t('experiences.error.type_required')),
      }),
      name: yup
        .string()
        .required(t('experiences.error.experience_title_required'))
        .matches(RegExp(TEXT_REGEX), t('error.notAllowed')),
      description: yup.string().matches(RegExp(TEXT_REGEX), t('error.notAllowed')),
      startDate: yup
        .string()
        .required(t('common.error.startDate.required'))
        .test('Validate date', t('error.invalidDate'), value => {
          if (!value) return true;
          return moment(value, value.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid();
        })
        .test('Min Date', t('error.minDate'), value => {
          if (value) {
            return new Date(new Date(min).setHours(0)).getTime() < new Date(value).getTime();
          }
          return true;
        })
        .test('Max date', t('error.maxDate'), value => {
          if (value) {
            return today.getTime() >= new Date(value).getTime();
          }
          return true;
        }),
      endDate: yup
        .string()
        .test('Is date valid', t('error.invalidDate'), value => {
          if (!value) return true;
          return moment(value, value.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid();
        })
        .test('match', t('common.error.date.endDate'), function callback(value) {
          const { parent } = this;
          const startDate = parent?.startDate;
          if (value) {
            const endDate = new Date(new Date(value).getFullYear(), new Date(value).getMonth() + 1, 1);
            if (endDate && startDate && moment(startDate, 'YYYY-MM', true).isValid()) {
              return endDate.getTime() > new Date(startDate).getTime();
            }
          }
          return true;
        })
        .test('Is date in correct range', t('error.dateRange'), value => {
          if (value) {
            return yearValidator(value);
          }
          return true;
        }),
      organization: yup.object().shape({
        name: yup.string().matches(RegExp(TEXT_REGEX), t('error.notAllowed')),
        position: yup.string().matches(RegExp(TEXT_REGEX), t('error.notAllowed')),
      }),
      hours: yup.object().shape({
        weeklyAverage: yup
          .number()
          .min(1, t('experiences.error.weeklyAverage_invalid'))
          .max(168, t('experiences.error.weeklyAverage_max'))
          .nullable(true)
          .transform((_, val) => (val === Number(val) ? val : null))
          .test('Check Dependant', t('experiences.error.weeklyAverage_required'), function callback(value) {
            const { parent } = this;
            if (parent?.weeks && !value) {
              return false;
            }
            return true;
          }),
        weeks: yup
          .number()
          .min(1, t('experiences.error.weeks_invalid'))
          .max(2600, t('experiences.error.weekly_max'))
          .nullable(true)
          .transform((_, val) => (val === Number(val) ? val : null))
          .test('Check Dependant', t('experiences.error.weeks_required'), function callback(value) {
            const { parent } = this;
            if (parent?.weeklyAverage && !value) {
              return false;
            }
            return true;
          }),
      }),
    })
  ),
  languages: yup.array().of(
    yup
      .object()
      .shape({
        language: yup.object().shape({
          code: yup.string(),
        }),
        proficiencyLevel: yup.object().shape({
          code: yup.string(),
        }),
      })
      .test('Check Dependant Language', function callback(value) {
        if (!value?.language?.code && value?.proficiencyLevel?.code) {
          return this.createError({
            message: t('languagesAndSkills.language_required'),
            path: `${this.path}.language.code`,
          });
        }
        return true;
      })
      .test('Check Dependant Proficiency', function callback(value) {
        if (value?.language?.code && !value?.proficiencyLevel?.code) {
          return this.createError({
            message: t('languagesAndSkills.proficiency_required'),
            path: `${this.path}.proficiencyLevel.code`,
          });
        }
        return true;
      })
  ),
});

export const validationSchemaAddress = yup.object().shape({
  address1: yup.string().matches(RegExp(TEXT_REGEX), t('error.notAllowed')),
  address2: yup.string().matches(RegExp(TEXT_REGEX), t('error.notAllowed')),
  city: yup.string().matches(RegExp(TEXT_REGEX), t('error.notAllowed')),
  region: yup.object().shape({
    code: yup.string().required(t('address.error.region_required')),
  }),
  country: yup.object().shape({
    code: yup.string().required(t('address.error.country_required')),
  }),
});
