/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect, useState, memo, useMemo } from 'react';
import { Box, Grid, Typography, Theme, useMediaQuery } from '@mui/material';
import { SidePanel, Snackbar, Alert } from '@liaison/liaison-ui';
import { useNavigate } from 'react-router-dom';
import { useFirstRenderStatus } from 'hooks/useFirstRenderStatus';
import { csuNameSpace } from 'utils/utilities';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  ISelectedProgram,
  TFollowedProgramModified,
  updateUnFollowedProgramIndex,
} from 'transferPlanner/store/programCart/programCart.slice';
import { useAppSelector } from 'redux/hooks';
import {
  selectSelectedProgramCart,
  selectFollowedPrograms,
  selectActiveFollowedPrograms,
  selectInActiveSelectedPrograms,
} from 'transferPlanner/store/programCart/programCart.selectors';
import { selectTspEligibleFlags } from 'transferPlanner/store/programs/programs.selectors';
import { StyledDivider, sxSidePanel } from 'pages/Pages.styles';
import {
  selectTspEligibility,
  selectQuestionnaireConfig,
} from 'transferPlanner/store/eligibility/eligibility.selectors';
import { getEligibility } from 'transferPlanner/pages/Questionnaire/Questionnaire.utils';
import { getEnrollment, getTspEnrollmentPeriod } from 'transferPlanner/pages/TspEnrollment/TspEnrollment.utils';
import {
  selectActiveTspEnrollment,
  selectTspEnrollment,
  selectTspEnrollmentPeriod,
} from 'transferPlanner/store/enrollment/enrollment.selectors';
import { type ISnackbarState, ProgramView } from 'transferPlanner/pages/CampusesAndPrograms/Programs/ProgramView';
import {
  fetchProgramDetails,
  TFetchProgramDetailsResponse,
} from 'transferPlanner/pages/CampusesAndPrograms/Programs/ProgramCardsLazyScroll/ProgramCardsLazyScroll.utils';
import { Spinner } from 'components/Spinner';
import { PROGRAM_PROGRESS, RESEARCH_CAMPUS_AND_PROGRAMS, TSP_ENROLLMENT } from 'transferPlanner/constants/routeNames';
import { setTitle } from 'utils/commonUtils';
import { EViewType } from 'transferPlanner/pages/CampusesAndPrograms/ToggleView';
import { MAX_FOLLOWED_PROGRAMS } from 'transferPlanner/constants/general';
import useFocusSnackbar from 'transferPlanner/hooks/useFocusSnackbar';
import type { ITspEligibility } from 'transferPlanner/store/eligibility/eligibility.slice';
import { selectFeatures } from 'store/features/features.selectors';
import { activeEligibility } from 'transferPlanner/pages/Dashboard/MyStatus/MyStatus.utils';
import useTooltip from 'transferPlanner/hooks/useTooltip';
import {
  getProgramCart,
  getFollowedPrograms,
  postFollowedProgram,
  postUnFollowProgram,
  checkIsAllButtonDisabled,
  postProgramCart,
  findUnFollowedProgramIndex,
  getTspEligibleFlags,
} from './MyProgramsCart.utils';
import FollowedPrograms from './FollowedPrograms';
import SavedPrograms from './SavedPrograms';

interface IMyProgramsCart {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  prevTitle: string;
}

const MyProgramsCart = ({ isOpen, setIsOpen, prevTitle }: IMyProgramsCart): ReactElement => {
  const firstRender = useFirstRenderStatus();
  const { t } = useTranslation(csuNameSpace.ns);
  const dispatch = useDispatch();
  const programCart = useAppSelector(selectSelectedProgramCart);
  const followedPrograms = useAppSelector(selectFollowedPrograms);
  const tspEligibility = useAppSelector(selectTspEligibility);
  const tspEnrollment = useAppSelector(selectTspEnrollment);
  const activeEnrolledProgram = useAppSelector(selectActiveTspEnrollment);
  const tspEnrollmentPeriod = useAppSelector(selectTspEnrollmentPeriod);
  const isTspEnrollmentOpen = useAppSelector(selectTspEnrollmentPeriod)?.enrollmentOpen || false;
  const activeFollowedPrograms = useAppSelector(selectActiveFollowedPrograms);
  const savedInActivePrograms = useAppSelector(selectInActiveSelectedPrograms);
  const selectedProgramCart = useAppSelector(selectSelectedProgramCart);
  const tspEligibleFlags = useAppSelector(selectTspEligibleFlags);
  const features = useAppSelector(selectFeatures);
  const questionnaireConfig = useAppSelector(selectQuestionnaireConfig);

  const [isLoading, setIsLoading] = useState(false);
  const [programDetails, setProgramDetails] = useState<TFetchProgramDetailsResponse>();
  const [allEnrollDisabled, setAllEnrollDisabled] = useState(false);
  const [isDetailViewOpen, setIsDetailViewOpen] = useState(false);
  const [currentEligibility, setCurrentEligibility] = useState<ITspEligibility | null>(null);
  const [snackbarData, setSnackBarData] = useState<ISnackbarState>({
    isVisible: false,
    type: '',
    title: '',
    message: '',
  });
  const { RenderTooltip, handleTooltip, tooltipOpen } = useTooltip(
    <Trans t={t}>{t('programs.program_enrollment_open_instruction')}</Trans>,
    t('programs.program_enrollment_open_instruction_label')
  );

  useFocusSnackbar('my-cart-success');
  const savedProgramIds = useMemo(
    () => (selectedProgramCart || []).map(selected => selected.program.id),
    [selectedProgramCart]
  );

  const [showAlert, setShowAlert] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const navigate = useNavigate();

  useEffect(() => {
    if (tspEligibility) {
      setCurrentEligibility(activeEligibility(tspEligibility, features, questionnaireConfig?.version));
    } else {
      setCurrentEligibility(null);
    }
  }, [tspEligibility, features, questionnaireConfig]);

  useEffect(() => {
    if (firstRender) {
      if (!followedPrograms) dispatch(getFollowedPrograms());
      if (!programCart) dispatch(getProgramCart());
      if (!tspEnrollment) dispatch(getEnrollment());
    }
  }, [dispatch, firstRender, programCart, tspEnrollment, followedPrograms]);

  useEffect(() => {
    if (!tspEligibility) dispatch(getEligibility());
  }, [dispatch, tspEligibility]);

  useEffect(() => {
    if (!tspEnrollmentPeriod) dispatch(getTspEnrollmentPeriod());
  }, [dispatch, tspEnrollmentPeriod]);

  useEffect(() => {
    if (
      savedInActivePrograms !== undefined &&
      activeFollowedPrograms !== undefined &&
      tspEnrollment &&
      tspEligibility
    ) {
      const currentActiveEligibility = currentEligibility ? [currentEligibility] : null;
      setAllEnrollDisabled(
        checkIsAllButtonDisabled(tspEnrollment, currentActiveEligibility, savedInActivePrograms, activeFollowedPrograms)
      );
    }
  }, [tspEligibility, tspEnrollment, savedInActivePrograms, activeFollowedPrograms, currentEligibility]);

  useEffect(() => {
    if (savedProgramIds.length) dispatch(getTspEligibleFlags(savedProgramIds.join(',')));
  }, [dispatch, savedProgramIds]);

  useEffect(() => {
    if (isTspEnrollmentOpen && !tspEligibility) dispatch(getEligibility());
  }, [dispatch, isTspEnrollmentOpen, tspEligibility]);

  const loadProgramDetails = async (selectedProgram: ISelectedProgram | TFollowedProgramModified) => {
    setIsLoading(true);
    try {
      const programDetailsResponse = await fetchProgramDetails({
        campusesId: selectedProgram.campus.id,
        programId: selectedProgram.program.id,
      });

      setProgramDetails(programDetailsResponse);
      setIsDetailViewOpen(true);
      setIsLoading(false);
    } catch {
      /* istanbul ignore next */
      setIsDetailViewOpen(false);
    }
  };

  const removeProgram = (selectedProgram: ISelectedProgram | TFollowedProgramModified) => {
    setIsLoading(true);
    const selectedPayload: ISelectedProgram[] =
      programCart?.filter(e => e.program.id !== selectedProgram.program.id) || [];
    /* istanbul ignore next */
    dispatch(
      postProgramCart(selectedPayload, () => {
        setIsLoading(false);
        dispatch(
          updateUnFollowedProgramIndex(findUnFollowedProgramIndex(selectedProgram.program.id, activeFollowedPrograms))
        );
        setSnackBarData({
          isVisible: true,
          type: 'error',
          title: t('program.program_removed_title'),
          message: t('program.program_removed_text'),
        });
      })
    );
  };

  const explorePrograms = () => {
    setIsOpen(false);
    navigate(`/${RESEARCH_CAMPUS_AND_PROGRAMS}`, { state: { type: EViewType.program } });
    window.scrollTo(0, 0);
  };

  const enrollInTSP = (selectedItem: ISelectedProgram | TFollowedProgramModified) => {
    navigate(`/${TSP_ENROLLMENT}`, {
      state: {
        campus: {
          id: selectedItem?.campus?.id,
          alternateId: selectedItem?.campus?.alternateId?.code,
        },
        programId: selectedItem?.program?.id,
      },
    });
  };
  const followProgram = (selectedProgram: TFollowedProgramModified | ISelectedProgram) => {
    const activePrograms = followedPrograms?.filter(
      (followedItem: TFollowedProgramModified) => followedItem.program?.tracking?.active
    );
    if (activePrograms && activePrograms.length === MAX_FOLLOWED_PROGRAMS) {
      setShowAlert(true);
    } else {
      setIsLoading(true);
      dispatch(
        postFollowedProgram(selectedProgram, () => {
          setIsLoading(false);
          setSnackBarData({
            isVisible: true,
            type: 'success',
            title: t('program.program_followed_title'),
            message: t('program.program_followed_text'),
          });
          dispatch(getProgramCart());
        })
      );
    }
  };
  const unfollowProgram = (selectedProgram: TFollowedProgramModified | ISelectedProgram) => {
    setIsLoading(true);
    dispatch(
      postUnFollowProgram(selectedProgram, () => {
        setIsLoading(false);
        dispatch(
          updateUnFollowedProgramIndex(findUnFollowedProgramIndex(selectedProgram.program.id, activeFollowedPrograms))
        );
        setSnackBarData({
          isVisible: true,
          type: 'success',
          title: t('program.program_unfollowed_title'),
          message: t('program.program_unfollowed_text'),
        });
      })
    );
  };

  const handleClick = (menu: string, selectedProgram?: ISelectedProgram | TFollowedProgramModified) => {
    if (menu === 'Explore') {
      explorePrograms();
      return;
    }
    /* istanbul ignore next */
    if (!selectedProgram) return;
    switch (menu) {
      case 'Program Details':
        loadProgramDetails(selectedProgram);
        break;
      case 'View Progress':
        setIsOpen(false);
        setTitle(prevTitle);
        navigate(`/${PROGRAM_PROGRESS}/${selectedProgram.program.id}`);
        break;
      case 'Follow': {
        followProgram(selectedProgram);
        break;
      }
      case 'Unfollow':
        unfollowProgram(selectedProgram);
        break;
      case 'Remove':
        removeProgram(selectedProgram);
        break;
      case 'Enroll':
        enrollInTSP(selectedProgram);
        break;
      default:
        /* istanbul ignore next */
        loadProgramDetails(selectedProgram);
        break;
    }
  };

  /* istanbul ignore next */
  const handleSnackBarClose = () => setSnackBarData({ isVisible: false, type: '', title: '', message: '' });

  /* istanbul ignore next */
  const handleSnackbarClose = () => {
    handleSnackBarClose();
    handleTooltip('instruction', false);
  };

  return (
    <>
      <SidePanel
        open={isOpen}
        size="small"
        onClose={() => {
          if (!tooltipOpen.instruction && !snackbarData.isVisible) {
            setIsOpen(false);
            setTitle(prevTitle);
          } else {
            /* istanbul ignore next */
            handleSnackbarClose();
          }
        }}
        isBackdropClickEnabled={true}
        title={`${t('my_programs_label')}  (${savedInActivePrograms.length + activeFollowedPrograms.length || 0})`}
        footerButtonConfig={{
          justifyContent: 'center',
          tertiary: {
            title: t('close_label'),
            props: {
              'aria-label': t('cancel_label'),
              color: 'secondary',
              onClick: () => {
                if (!tooltipOpen.instruction) {
                  setIsOpen(false);
                  setTitle(prevTitle);
                }
              },
            },
          },
        }}
        sx={sxSidePanel}
      >
        <Grid
          sx={{
            bgcolor: '#F6F7FA',
            m: '-1rem',
            height: 'calc(100% + 2rem)',
            display: 'flex',
          }}
        >
          <>
            {isLoading && <Spinner backdrop />}
            <Snackbar
              type={snackbarData.type === 'success' ? 'success' : 'error'}
              title={snackbarData.title}
              open={snackbarData.isVisible}
              autoHideDuration={300000}
              onClose={() => handleSnackBarClose()}
              sx={isMobile ? { width: '22rem' } : { width: '25rem' }}
            >
              <Box sx={{ mt: 0.5 }}>
                <Typography variant="subtitle7" tabIndex={0} display="inline" id="my-cart-success">
                  {snackbarData.message}
                </Typography>
              </Box>
            </Snackbar>
            <Box sx={{ p: '1rem', width: '100%' }}>
              <Typography variant="subtitle7" component="div">
                <Trans t={t}>programs.cart_common_instruction</Trans>
              </Typography>
              <Typography sx={{ mt: 1.5 }} variant="subtitle7" component="div">
                <Trans t={t}>
                  {isTspEnrollmentOpen
                    ? t('programs.program_enrollment_open')
                    : t('programs.program_enrollment_closed')}
                </Trans>
                {isTspEnrollmentOpen && RenderTooltip()}
              </Typography>
              {showAlert && (
                <Alert sx={{ mb: 2, mt: '2rem' }} type="error" size="default" onClose={() => setShowAlert(false)}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body3" sx={{ textAlign: 'left' }}>
                      {t('programs.too_many_msg')}
                    </Typography>
                  </Box>
                </Alert>
              )}
              <FollowedPrograms
                followedPrograms={activeFollowedPrograms}
                activeEnrolledProgramId={activeEnrolledProgram?.program.id}
                handleClick={handleClick}
                enrollBtnDisabled={!currentEligibility || !isTspEnrollmentOpen || allEnrollDisabled}
                tspEligibleFlags={tspEligibleFlags}
                hasError={showAlert}
              />
              <StyledDivider sx={{ m: 0 }} />
              <Box sx={{ py: '0.5rem' }}>
                <SavedPrograms
                  selectedProgramList={savedInActivePrograms}
                  activeEnrolledProgramId={activeEnrolledProgram?.program.id}
                  handleClick={handleClick}
                  enrollBtnDisabled={!currentEligibility || !isTspEnrollmentOpen || allEnrollDisabled}
                  tspEligibleFlags={tspEligibleFlags}
                />
              </Box>
            </Box>
            {programDetails && (
              <ProgramView
                programDetails={programDetails}
                isOpen={isDetailViewOpen}
                setIsOpen={setIsDetailViewOpen}
                setIsSuccessSnackbar={setSnackBarData}
              />
            )}
          </>
        </Grid>
      </SidePanel>
    </>
  );
};

export default memo(MyProgramsCart);
