/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Box } from '@mui/material';
import { defaultSessionTimeoutMins } from 'constants/general';
import SessionTimeoutDialog from './SessionTimeoutDialog';

export const { IDLE_SESSION_TIMEOUT_IN_MINS, IDLE_SESSION_TIMEOUT_COUNTDOWN_IN_SECS } = window.env;
interface ISessionTimeout {
  isAuthenticated: boolean;
  logOut: () => void;
}
let countdownInterval: ReturnType<typeof setInterval>;
let timeout: ReturnType<typeof setTimeout>;

const clearSessionTimeout = () => {
  clearTimeout(timeout);
};

const clearSessionInterval = () => {
  clearInterval(countdownInterval);
};

const SessionTimeout = ({ isAuthenticated, logOut }: ISessionTimeout): ReactElement => {
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);

  const handleLogout = async () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
    logOut();
  };

  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
  };

  const onActive = () => {
    if (!timeoutModalOpen) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };

  const onIdle = () => {
    if (isAuthenticated && !timeoutModalOpen) {
      timeout = setTimeout(() => {
        let countDown = Number(IDLE_SESSION_TIMEOUT_COUNTDOWN_IN_SECS || 10);
        setTimeoutModalOpen(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            countDown -= 1;
            setTimeoutCountdown(countDown);
          } else {
            localStorage.clear();
          }
        }, 1000);
      }, 1000);
    }
  };

  useIdleTimer({
    crossTab: true,
    onIdle,
    onActive,
    timeout: Number(IDLE_SESSION_TIMEOUT_IN_MINS || defaultSessionTimeoutMins) * 60 * 1000,
    debounce: 250,
  });

  return (
    <Box>
      <SessionTimeoutDialog
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        onLogout={() => handleLogout()}
        open={timeoutModalOpen}
      />
    </Box>
  );
};

export default SessionTimeout;
