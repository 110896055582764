/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, SyntheticEvent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { Snackbar } from '@liaison/liaison-ui';
import { selectSuccesSnackbar } from 'store/ui/ui.selectors';
import { setUi } from 'store/ui/ui.slice';
import { SnackbarCloseReason } from '@mui/material';
import useFocusSnackbar from 'transferPlanner/hooks/useFocusSnackbar';
import { DASHBOARD } from 'transferPlanner/constants/routeNames';

export const SuccessSnackbar = (): ReactElement => {
  const dispatch = useAppDispatch();
  const succesSnackbar = useAppSelector(selectSuccesSnackbar);
  useFocusSnackbar();
  const { pathname } = useLocation();

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (succesSnackbar.open && (target.nodeName === 'BUTTON' || target.onclick || pathname !== `/${DASHBOARD}`)) {
        dispatch(setUi({ name: 'succesSnackbar', state: { open: false, message: '' } }));
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [dispatch, succesSnackbar, pathname]);

  const closeAlert = (event: Event | SyntheticEvent<unknown, Event>, reason: SnackbarCloseReason) => {
    if (succesSnackbar?.isManualClose && reason === 'clickaway') {
      return;
    }
    dispatch(
      setUi({
        name: 'succesSnackbar',
        state: {
          open: false,
          message: '',
          hideTitle: !!succesSnackbar?.hideTitle,
          autoHideDuration: null,
          isManualClose: false,
        },
      })
    );
  };

  return (
    <Snackbar
      open={succesSnackbar?.open}
      onClose={closeAlert}
      type="success"
      title={!succesSnackbar?.hideTitle ? t('success_label') : undefined}
      autoHideDuration={succesSnackbar?.autoHideDuration}
    >
      {succesSnackbar?.message}
    </Snackbar>
  );
};
