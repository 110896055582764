/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import * as yup from 'yup';
import { reasonOptions, noPlanningOptions } from './TspRequestRelease.utils';

export const validationSchema = yup.object().shape({
  reason: yup.string().oneOf(reasonOptions.map(({ val }) => val)),
  noPlanningReason: yup.string().when('reason', {
    is: (reason: string) => reason === reasonOptions[1].val,
    then: yup.string().oneOf(noPlanningOptions.map(({ val }) => val)),
  }),
  otherReason: yup.string().when('reason', {
    is: (reason: string) => reason === reasonOptions[5].val,
    then: yup.string().max(255),
  }),
});
