/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaletteOptions } from '@mui/material';
import { TBiographicalInfoField } from 'userProfile/store/biographicalInfo/biographicalInfo.slice';
import { TPersonalInfoField } from 'userProfile/store/personalInfo/personalInfo.slice';

type TError = string | null;

export interface Ioption {
  code: string;
  displayName: string;
  otherText?: string;
  classifications?: {
    code: string;
    displayName: string;
  }[];
  fieldsOfStudyCode?: string;
}

export interface ILookupAction {
  data: Ioption[];
  endPoint: string;
}
export interface Ireset {
  endpoint: string;
}

export interface IhighSchool {
  code: string;
  name: string;
  type: string;
  createdDate: string;
  updatedDate: string;
  address: {
    city: string;
    address1: string;
    country: Ioption;
    region: Ioption;
    Ioption: string;
  };
}

export interface ISchoolOfInterest {
  ipedsId: string;
  name: string;
  address: {
    address1: string;
    city: string;
    country: Ioption;
    region: Ioption;
  };
}

export interface ICollegeCommon {
  ceeb: string;
  name: {
    longName: string;
    shortName?: string;
  };
  alternateId?: { code: number; source: string };
  collegeCode: string;
  fice?: string;
  address: {
    city: string;
    address1?: string;
    country: Ioption;
    region: Ioption;
    Ioption?: string;
  };
}

export interface IApplicationCycles {
  casName: string;
  status: string;
  cycle: {
    id: number;
    name: string;
  };
}

export interface IAlternateIdLinking {
  email: string;
  profileId: string;
  alternateId?: string;
  alternateIdFound: boolean;
  linkingAccepted?: boolean;
  source: string;
  applicationCycles?: IApplicationCycles[];
}

export interface IFooterLinks {
  link: string;
  sortId: number;
  target: string;
  text: string;
}
export interface ISocialMediaLinks {
  text: string;
  icon: string;
  link: string;
  target: string;
  sortId: number;
}
export interface IProfileSections {
  label: string;
  icon: string;
  location: string;
}
export interface ITenantConfig {
  logo: string;
  logoMobile?: string;
  logoNoBG?: string;
  backgroundImage: string;
  footerLinks: IFooterLinks[];
  socialMediaLinks: ISocialMediaLinks[];
  copyright: string;
  palette?: PaletteOptions;
  instructionalText: {
    keyClockLanding?: string;
    createAccount?: string;
    welcome: {
      header: string;
      body: string;
    };
  };
}

export interface ITerms {
  profileId?: string;
  termsUse: {
    accepted: boolean;
  };
  textPhoneAuthorization: {
    accepted: boolean;
  };
  tenantName: string;
}

type IObParms = {
  code: string;
  displayName: string;
  routing: string;
};

export interface IObData {
  nextSteps: IObParms[];
  response?: {
    personalInformation: TPersonalInfoField;
    biographicInformation: TBiographicalInfoField;
  };
}

export type TReq = {
  code: string;
  name?: string;
  flags?: string[];
};

export type TGenEdReq = {
  code: string;
  category: string;
  courseUnits: number;
  termType?: string;
  requirements?: TReq[];
};

export type TCoursesOption = {
  coursePrefix: string;
  courseNumber: string;
  courseTitle: string;
  courseUnitsMin: number;
};

export interface ICommonInfoState {
  loading: boolean;
  industries: Ioption[] | null;
  subjects: Ioption[] | null;
  fields: Ioption[] | null;
  areas: Ioption[] | null;
  occupation: Ioption[] | null;
  visaTypes: Ioption[] | null;
  experiences: Ioption[] | null;
  achievements: { [key: string]: Ioption[] } | null;
  countries: Ioption[] | null;
  states: Ioption[] | null;
  counties: Ioption[] | null;
  residency_states: Ioption[] | null;
  residency_counties: Ioption[] | null;
  languages: Ioption[] | null;
  skills: string[] | null;
  collegeDegreeTypes: string[] | null;
  collegeMajorTypes: string[] | null;
  highSchool: IhighSchool[] | null;
  college: ICollegeCommon[] | null;
  schoolsOfInterest: ISchoolOfInterest[] | null;
  races: Ioption[] | null;
  ethnicity: Ioption[] | null;
  tenantConfig: ITenantConfig | null;
  alternateIdLinking: IAlternateIdLinking | null;
  obData: IObData | null;
  terms: ITerms | null;
  isFirstLogin: boolean;
  generalEducationRequirements: TGenEdReq[] | null;
  collegeCourses: TCoursesOption[] | null;
  error: TError;
}
export const initialState: ICommonInfoState = {
  loading: false,
  industries: null,
  subjects: null,
  occupation: null,
  visaTypes: null,
  fields: null,
  areas: null,
  experiences: null,
  achievements: null,
  countries: null,
  states: null,
  counties: null,
  residency_states: null,
  residency_counties: null,
  languages: null,
  skills: null,
  highSchool: null,
  schoolsOfInterest: null,
  college: null,
  collegeDegreeTypes: null,
  collegeMajorTypes: null,
  races: null,
  ethnicity: null,
  tenantConfig: null,
  alternateIdLinking: null,
  obData: null,
  terms: null,
  isFirstLogin: false,
  generalEducationRequirements: null,
  collegeCourses: null,
  error: null,
};

const commonInfoSlice = createSlice({
  name: 'commonInfo',
  initialState,
  reducers: {
    lookupApiStart: (state): ICommonInfoState => ({
      ...state,
      loading: true,
      error: null,
    }),
    alternateIdLinkingApiStart: (state): ICommonInfoState => ({
      ...state,
      loading: true,
      error: null,
    }),
    tenantApiStart: (state): ICommonInfoState => ({
      ...state,
      loading: true,
      error: null,
    }),
    lookupApiSuccess: (state, action: PayloadAction<ILookupAction>): ICommonInfoState => ({
      ...state,
      loading: false,
      [action.payload.endPoint]: action?.payload?.data,
    }),
    resetlookupData: (state, action: PayloadAction<string | string[]>): ICommonInfoState => {
      let dependantData: { [key: string]: unknown } = {};
      if (typeof action.payload === 'string') {
        dependantData = {
          [action.payload]: null,
        };
      } else {
        action.payload.forEach(item => {
          dependantData[item] = null;
        });
      }
      return {
        ...state,
        ...dependantData,
      };
    },
    tenantApiSuccess: (state, action: PayloadAction<ITenantConfig>): ICommonInfoState => {
      return {
        ...state,
        loading: false,
        tenantConfig: action.payload,
      };
    },
    alternateIdLinkingApiSuccess: (state, action: PayloadAction<IAlternateIdLinking>): ICommonInfoState => {
      return {
        ...state,
        loading: false,
        alternateIdLinking: action.payload,
      };
    },
    alternateIdLinkingApiFailure: (state, action: PayloadAction<TError>): ICommonInfoState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    lookupApiFailure: (state, action: PayloadAction<TError>): ICommonInfoState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    tenantApiFailure: (state, action: PayloadAction<TError>): ICommonInfoState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    termsApiStart: (state): ICommonInfoState => ({
      ...state,
      loading: true,
      error: null,
    }),
    termsApiSuccess: (state, action: PayloadAction<ITerms>): ICommonInfoState => {
      return {
        ...state,
        loading: false,
        terms: action.payload,
      };
    },
    termsApiFailure: (state, action: PayloadAction<TError>): ICommonInfoState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    obFlowStart: (state): ICommonInfoState => ({
      ...state,
      loading: true,
      error: null,
    }),
    obFlowSuccess: (state, action: PayloadAction<IObData>): ICommonInfoState => {
      return {
        ...state,
        loading: false,
        obData: action.payload,
      };
    },
    obFlowFailure: (state, action: PayloadAction<TError>): ICommonInfoState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    genEdReqApiStart: (state): ICommonInfoState => ({
      ...state,
      loading: true,
      error: null,
    }),
    genEdReqApiSuccess: (state, action: PayloadAction<TGenEdReq[]>): ICommonInfoState => {
      return {
        ...state,
        loading: false,
        generalEducationRequirements: action.payload,
      };
    },
    genEdReqApiFailure: (state, action: PayloadAction<TError>): ICommonInfoState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    getCourseApiStart: (state): ICommonInfoState => ({
      ...state,
      loading: true,
      error: null,
    }),
    getCourseApiSuccess: (state, action: PayloadAction<TCoursesOption[]>): ICommonInfoState => {
      return {
        ...state,
        loading: false,
        collegeCourses: action.payload,
      };
    },
    getCourseApiFailure: (state, action: PayloadAction<TError>): ICommonInfoState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    setIsFirstLogin: (state, action): ICommonInfoState => ({
      ...state,
      isFirstLogin: action.payload,
    }),
  },
});

export const {
  lookupApiStart,
  lookupApiSuccess,
  lookupApiFailure,
  resetlookupData,
  alternateIdLinkingApiStart,
  alternateIdLinkingApiSuccess,
  alternateIdLinkingApiFailure,
  tenantApiSuccess,
  tenantApiStart,
  tenantApiFailure,
  termsApiStart,
  termsApiSuccess,
  termsApiFailure,
  obFlowStart,
  obFlowSuccess,
  obFlowFailure,
  genEdReqApiStart,
  genEdReqApiSuccess,
  genEdReqApiFailure,
  getCourseApiStart,
  getCourseApiSuccess,
  getCourseApiFailure,
  setIsFirstLogin,
} = commonInfoSlice.actions;

export default commonInfoSlice.reducer;
