import React, { ReactElement, ReactNode, SyntheticEvent, KeyboardEvent } from 'react';
import { createPortal } from 'react-dom';
import { Snackbar, SnackbarCloseReason, SnackbarProps } from '@mui/material';

import { Alert } from 'components/Alert';

import { isKeyboardFocusOut } from './ErrorSummary.utils';

export interface IErrorSummaryProps extends Omit<SnackbarProps, 'children'> {
  children: ReactNode;
  showIcon?: ReactNode | false;
  onClose(event: Event | SyntheticEvent, reason?: SnackbarCloseReason): void;
}

function ErrorSummary({ children, showIcon, onClose, ...rest }: IErrorSummaryProps): ReactElement {
  const handleKeyDown = (e: KeyboardEvent) => {
    if (isKeyboardFocusOut(e)) {
      onClose(e, 'clickaway');
    }
  };

  return createPortal(
    <Snackbar
      open
      onClose={onClose}
      onKeyDown={handleKeyDown}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      {...rest}
    >
      <Alert
        icon={showIcon}
        variant="outlined"
        severity="error"
        onClose={onClose}
        closeText="Close Error Snackbar"
        style={{ fontSize: '0.875rem' }}
      >
        {children}
      </Alert>
    </Snackbar>,
    document.body
  );
}

export default ErrorSummary;
