/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { memo, useState, useEffect, useCallback, ReactElement } from 'react';
import {
  Typography,
  Box,
  Dialog,
  DialogContent,
  Grid,
  Avatar,
  DialogTitle,
  Button,
  DialogActions,
  SvgIcon,
  Tabs,
  Tab,
  styled,
  Theme,
  useMediaQuery,
  AppBar,
  Toolbar,
  IconButton,
  SvgIconProps,
  Link,
  dialogContentClasses,
  dialogActionsClasses,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IconSchool, IconCheck, Icon } from '@liaison/liaison-ui';
import { useFirstRenderStatus } from 'hooks/useFirstRenderStatus';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'components/Spinner';
import { nameSpace } from 'transferPlanner/constants/general';
import { StyledDivider, sxPrimaryButton, StyledBoxContainer } from 'pages/Pages.styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { TSP_ENROLLMENT_SUCCESS, DASHBOARD } from 'transferPlanner/constants/routeNames';
import { selectEnrollLoading, selectTspEnrollment } from 'transferPlanner/store/enrollment/enrollment.selectors';
import { resetProgram } from 'transferPlanner/store/programs/programs.slice';
import { selectTspEligibility } from 'transferPlanner/store/eligibility/eligibility.selectors';
import { selectCampuses } from 'transferPlanner/store/campuses/campuses.selectors';
import { selectSelectedProgramCart } from 'transferPlanner/store/programCart/programCart.selectors';
import { postProgramCart } from 'transferPlanner/components/MyProgramsCart/MyProgramsCart.utils';
import { setTitle } from 'utils/commonUtils';
import EnrollmentFooter from './EnrollmentFooter';
import {
  tspEnrollmentMenus,
  postEnrollment,
  mobileTabHeadings,
  findNotAllowedTspStatus,
  tabColors,
} from './TspEnrollment.utils';
import { CommunityColleges } from './CommunityColleges';
import { ProgramSelection } from './ProgramSelection';
import { CompleteEnrollment } from './CompleteEnrollment';
import { TspCongratulations } from './TspCongratulations';
import { useFormData } from './ContextForm';
import { getCampuses } from '../CampusesAndPrograms/CampusesAndPrograms.utils';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogContentClasses.root}`]: {
    padding: theme.spacing(2),
  },
  [`& .${dialogActionsClasses.root}`]: {
    padding: theme.spacing(2),
  },
}));

interface ISideMenuItem {
  id: number;
  name: string;
  icon: React.FC<SvgIconProps<'svg', {}>>;
  bgColor: string;
  disabled: boolean;
  completed: boolean;
}

interface ITspEnrollmentProps {
  success?: boolean;
}
const TspEnrollment = ({ success = false }: ITspEnrollmentProps): ReactElement => {
  const location = useLocation();
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [tabTitle, setTabTitle] = useState(mobileTabHeadings[0]);
  const firstRender = useFirstRenderStatus();
  const [activeTab, setActiveTab] = useState(0);
  const [openTspAlert, setOpenTspAlert] = useState(false);
  const [isSubmitDisable, setIsSubmitDisable] = useState(false);
  const tspEnrollment = useSelector(selectTspEnrollment);
  const tspEligibility = useSelector(selectTspEligibility);
  const selectedPrograms = useSelector(selectSelectedProgramCart);
  const enrollLoading = useSelector(selectEnrollLoading);
  const campuses = useSelector(selectCampuses);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, setFormValues } = useFormData();
  const { t } = useTranslation(nameSpace);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [sideTabMenu, setSideTabMenu] = useState<ISideMenuItem[]>(tspEnrollmentMenus);
  const [startConfetti, setStartConfetti] = useState(false);

  setTitle(t('tspDialog.pageTitel'));

  useEffect(() => () => localStorage.removeItem('enrollStates'), []);

  useEffect(() => {
    if (!tspEligibility || Object.keys(tspEligibility).length === 0) {
      navigate(`/${DASHBOARD}`);
    } else if (Object.keys(tspEligibility).length) {
      const latestQuestionnaire = tspEligibility?.reduce((accumulator, currentEligibility) =>
        accumulator.questionnaireVersion > currentEligibility.questionnaireVersion ? accumulator : currentEligibility
      );
      if (latestQuestionnaire?.status?.code === 'INELIGIBLE') {
        navigate(`/${DASHBOARD}`);
      }
    }
  }, [dispatch, tspEligibility, firstRender, navigate]);

  useEffect(() => {
    if (success && isInitialLoading) {
      setIsInitialLoading(false);
      setTabTitle('Congratulations!');
      const updatedMenu = sideTabMenu.map(menu => {
        const tempMenu = { ...menu };
        tempMenu.bgColor = tabColors.completed;
        tempMenu.icon = IconCheck;
        tempMenu.completed = true;
        tempMenu.disabled = true;
        return tempMenu;
      });
      setSideTabMenu(updatedMenu);
    }
  }, [success, sideTabMenu, isInitialLoading]);

  useEffect(() => {
    if (!campuses) {
      dispatch(getCampuses());
    }
  }, [dispatch, campuses]);

  useEffect(() => {
    dispatch(resetProgram());
  }, [dispatch]);

  useEffect(() => {
    if (!Object.keys(data).length) {
      if (tspEnrollment) {
        const isActive = tspEnrollment?.find(enrollData => enrollData?.active === true);
        if (location?.pathname !== `/${TSP_ENROLLMENT_SUCCESS}`) {
          const notAllowedStatus = findNotAllowedTspStatus(tspEnrollment);
          if (isActive || notAllowedStatus) {
            navigate(`/${DASHBOARD}`);
          }
        }
      }
    }
  }, [dispatch, tspEnrollment, navigate, location, data]);

  useEffect(() => {
    if (startConfetti) {
      /* istanbul ignore next */
      const timeout = setTimeout(() => {
        setStartConfetti(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [startConfetti]);

  useEffect(() => {
    if (success) {
      setStartConfetti(true);
    }
  }, [success]);

  const updateCurrentMenuItems = useCallback(
    (selectedMenu: number) => {
      setSideTabMenu(
        sideTabMenu.map((menu, i) => {
          const tempMenu = { ...menu };
          if (menu.id === selectedMenu) {
            tempMenu.bgColor = tabColors.current;
            tempMenu.disabled = true;
            tempMenu.completed = false;
            tempMenu.icon = tspEnrollmentMenus[i].icon;
          } else if (menu.id < selectedMenu) {
            tempMenu.bgColor = tabColors.completed;
            tempMenu.icon = IconCheck;
            tempMenu.disabled = false;
            tempMenu.completed = true;
          }
          return tempMenu;
        })
      );
    },
    [sideTabMenu]
  );

  const handleNextButtonClick = () => {
    const currentMenu = activeTab + 1;
    updateCurrentMenuItems(currentMenu);
    setActiveTab(currentMenu);
    setTabTitle(mobileTabHeadings[currentMenu]);
    setFormValues({ ...data, enabled: false });
  };

  const handlePreviousClick = () => {
    const currentMenu = activeTab - 1;
    updateCurrentMenuItems(currentMenu);
    setActiveTab(currentMenu);
    setTabTitle(mobileTabHeadings[currentMenu]);
    setFormValues({ ...data, enabled: true });
  };

  const handleCompleteClick = () => {
    setOpenTspAlert(true);
  };

  /* istanbul ignore next */
  const onSubmitEnrollment = () => {
    setIsSubmitDisable(true);
    const currentFormData = {
      ...data.formData,
      enrollmentStatus: {
        status: {
          code: 'ENROLLED',
          displayName: 'Automatically enrolled',
        },
      },
    };

    let payLoadData = [currentFormData];
    if (tspEnrollment) {
      payLoadData = [...tspEnrollment, ...payLoadData];
    }

    dispatch(
      postEnrollment(payLoadData, () => {
        const { campus, program } = data.formData;
        if (data?.moKey) program.moKey = data.moKey;
        const cartPayload = { campus, program };
        const selectedProgramsList = selectedPrograms || [];
        const matchedProgram = selectedProgramsList?.find(
          selectedItem => selectedItem.campus.id === campus.id && selectedItem.program.id === program.id
        );
        if (!matchedProgram) {
          const updatedPrograms = [...selectedProgramsList, cartPayload];
          dispatch(
            postProgramCart(updatedPrograms, () => {
              setOpenTspAlert(false);
              navigate(`/${TSP_ENROLLMENT_SUCCESS}`);
            })
          );
        } else {
          setOpenTspAlert(false);
          navigate(`/${TSP_ENROLLMENT_SUCCESS}`);
        }
      })
    );
  };

  return (
    <Dialog
      fullScreen={isMobile}
      fullWidth
      maxWidth="xl"
      open
      aria-labelledby="dialog-modal-title"
      aria-describedby="dialog-modal-description"
    >
      {isMobile && (
        <AppBar sx={{ position: 'relative', bgcolor: (theme: Theme) => theme.palette.custom.drawer }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="close">
              <Avatar sx={{ bgcolor: '#2F2F2F' }}>
                <IconSchool />
              </Avatar>
            </IconButton>
            <Box>
              {!success && (
                <Typography sx={{ fontSize: 14, color: 'secondary.contrastText' }} gutterBottom>
                  {t('step_label')} {activeTab + 1} of {sideTabMenu.length}
                </Typography>
              )}
              <Typography variant="h6" sx={{ color: 'secondary.contrastText' }} component="div">
                {tabTitle}
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <DialogContent id="dialog-modal-title" sx={{ p: 0, height: '100vh' }}>
        <Grid container sx={{ minHeight: '100%' }}>
          {!isMobile && (
            <Grid
              item
              xs={3}
              sx={{ background: '#CC0B2A', color: (theme: Theme) => theme.palette.secondary.contrastText }}
            >
              <Box sx={{ m: 2 }}>
                <Box>
                  <Typography variant="h1">{t('tspDialog.welcome')}!</Typography>
                  <Typography id="dialog-modal-description" variant="body1" sx={{ mt: 2 }}>
                    {t('tspDialog.welcomeDescription')}
                  </Typography>
                </Box>
                <StyledDivider />
                <Box>
                  <Tabs
                    orientation="vertical"
                    value={activeTab}
                    TabIndicatorProps={{ sx: { display: 'none' } }}
                    onChange={(_event, newValue) => setActiveTab(newValue)}
                    aria-label="TSP Enrollment Menus"
                    sx={{
                      ml: -0.5,
                      overflow: 'visible',
                      '& button': { color: (theme: Theme) => theme.palette.secondary.contrastText },
                      '& button:hover': { color: (theme: Theme) => theme.palette.secondary.contrastText },
                      '& button:disabled': { color: (theme: Theme) => theme.palette.secondary.contrastText },
                      '& button.Mui-selected': { color: (theme: Theme) => theme.palette.secondary.contrastText },
                      '& .MuiTabs-scroller': { ml: 6.5 },
                    }}
                  >
                    {sideTabMenu?.map(menu => (
                      <Tab
                        key={menu.name}
                        disabled={menu.disabled && menu.bgColor !== tabColors.current}
                        tabIndex={menu.disabled && menu.bgColor !== tabColors.current ? -1 : 0}
                        sx={{
                          minHeight: '4.5rem',
                        }}
                        label={
                          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                            <Grid item xs={9.5} sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'row' }}>
                              <Typography variant="subtitle7" sx={{ textAlign: 'end' }} aria-label={menu.name}>
                                {menu.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={0.5} />
                            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'row' }}>
                              <Avatar sx={{ bgcolor: menu.bgColor, border: menu.completed ? '2px solid #45A041' : '' }}>
                                <SvgIcon
                                  sx={{ color: menu.completed ? '#45A041' : '' }}
                                  component={menu.icon}
                                  inheritViewBox
                                />
                              </Avatar>
                            </Grid>
                          </Grid>
                        }
                        value={menu.id}
                      />
                    ))}
                  </Tabs>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid item xs={12} sm={9}>
            <StyledBoxContainer>
              <Box sx={{ flex: 1, padding: '1.5rem', overflow: 'auto', maxHeight: '82vh' }}>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item xs={2} />
                  {success ? (
                    <Grid item sm={8}>
                      <TspCongratulations startConfetti={startConfetti} />
                    </Grid>
                  ) : (
                    <Grid item sm={8}>
                      {activeTab === 0 && <ProgramSelection />}
                      {activeTab === 1 && <CommunityColleges />}
                      {activeTab === 2 && <CompleteEnrollment />}
                    </Grid>
                  )}
                  <Grid item xs={1.5} />
                </Grid>
              </Box>
              <EnrollmentFooter
                handleNextButtonClick={handleNextButtonClick}
                handlePreviousClick={handlePreviousClick}
                handleCompleteClick={handleCompleteClick}
                activeTab={activeTab}
                success={success}
              />
            </StyledBoxContainer>
          </Grid>
        </Grid>
        <BootstrapDialog role="main" aria-labelledby="customized-dialog-title" open={openTspAlert}>
          {enrollLoading && <Spinner backdrop />}
          <DialogTitle component="h1" id="customized-dialog-title" sx={{ m: 0, p: 2 }}>
            {t('enrollmentAlert.title')}
            <IconButton
              aria-label="close"
              onClick={() => setOpenTspAlert(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme: Theme) => theme.palette.grey[500],
              }}
            >
              <Icon>IconCancel</Icon>
            </IconButton>
          </DialogTitle>
          <DialogContent dividers tabIndex={0}>
            <Typography variant="body2" gutterBottom sx={{ mb: 3 }}>
              {t('enrollmentAlert.body1')}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t('enrollmentAlert.body2')}
            </Typography>
            <ul>
              <li>
                <Typography variant="body2" gutterBottom>
                  {t('enrollmentAlert.is')}
                  <Typography variant="subtitle6" gutterBottom>
                    {data?.formData?.campus?.name}
                  </Typography>
                  {t('enrollmentAlert.correctUniversity')}
                </Typography>
              </li>
              <li>
                <Typography variant="body2" gutterBottom>
                  {t('enrollmentAlert.is')}
                  <Typography variant="subtitle6" gutterBottom>
                    {data?.formData?.program?.name}
                  </Typography>
                  {t('enrollmentAlert.correctprogram')}
                </Typography>
              </li>
              <li>
                <Typography variant="body2" gutterBottom>
                  {t('enrollmentAlert.is')}
                  <Typography variant="subtitle6" gutterBottom>
                    {data?.formData?.communityCollege?.name}
                  </Typography>
                  {t('enrollmentAlert.communityCollege')}
                </Typography>
              </li>
            </ul>

            <Typography variant="body2" gutterBottom sx={{ mr: -1 }}>
              {t('enrollmentAlert.body3')}
              <Typography variant="subtitle3">
                {t('enrollmentAlert.body4')}
                <Typography variant="body2" component="span">
                  {t('enrollmentAlert.body5')}
                  <Typography variant="subtitle3">{t('enrollmentAlert.body6')}</Typography>
                </Typography>
              </Typography>
            </Typography>
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Link
              role="link"
              aria-label={t('goback_label')}
              sx={{ color: '#CC0B2A', cursor: 'pointer' }}
              underline="none"
              tabIndex={0}
              onKeyUp={event => {
                /* istanbul ignore next */
                if (event.code === 'Enter' || event.code === 'Space') {
                  setOpenTspAlert(false);
                }
              }}
              onClick={() => setOpenTspAlert(false)}
            >
              <Typography variant="subtitle7">{t('goback_label')}</Typography>
            </Link>
            <Button
              disabled={isSubmitDisable}
              onClick={onSubmitEnrollment}
              sx={sxPrimaryButton}
              size="medium"
              variant="contained"
            >
              {t('enrollmentAlert.SubmitAgreement')}
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </DialogContent>
    </Dialog>
  );
};

export default memo(TspEnrollment);
