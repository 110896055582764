/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';
import { IAuthState } from './auth.slice';

const authDetails = (state: TRootState): IAuthState => state.auth;

export const selectLoggedUserData = createSelector(authDetails, (stateData: IAuthState) => stateData?.loggedUserData);

export const selectUpdatePasswordData = createSelector(authDetails, (stateData: IAuthState) => stateData?.updateData);

export const selectLoading = createSelector(authDetails, (stateData: IAuthState) => stateData?.loading);

export const selectAuthError = createSelector(authDetails, (stateData: IAuthState) => stateData?.error);
