/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { useState, useEffect, useRef } from 'react';

type UseTimedIndexOptionsType = {
  maxIndex: number;
  interval: number;
  startIndex?: number;
};

export const useTimedIndex = ({
  maxIndex,
  startIndex = 0,
}: UseTimedIndexOptionsType): [number, (index: number) => void] => {
  const [currentIndex, setCurrentIndex] = useState(startIndex);
  const savedCallback = useRef(() => {});
  // removing below tip transfer auto play logic. check TP-2191

  const resetIndex = (index: number) => {
    // removing below tip transfer auto play logic. check TP-2191
    setCurrentIndex(index);
  };

  /* istanbul ignore next */
  const callback = () => {
    if (currentIndex === maxIndex) {
      setCurrentIndex(startIndex);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  useEffect(() => {
    savedCallback.current = callback;
  });

  // removing below tip transfer auto play logic. check TP-2191

  return [currentIndex, resetIndex];
};
