/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, forwardRef, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, Divider, Grid, CardActions, IconButton, Typography, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
// TODO: use IconAddCircleSolid from @liaison/liaison-ui last version
import { IconAddCircle, IconCheckCircleColor } from '@liaison/liaison-ui';

import { useAppSelector } from 'redux/hooks';
import { csuNameSpace, truncate, isUpperCase } from 'utils/utilities';
import { useProgramSimilarity } from 'transferPlanner/hooks/useProgramSimilarity';
import { selectIsListView } from 'transferPlanner/store/searchProgram/searchProgram.selectors';
import {
  postFollowedProgram,
  prepareFollowProgramPayload,
  postProgramCart,
} from 'transferPlanner/components/MyProgramsCart/MyProgramsCart.utils';
import {
  selectActiveFollowedPrograms,
  selectSelectedProgramCart,
} from 'transferPlanner/store/programCart/programCart.selectors';
import { sxCard, sxCardListView, TProgramWithPosition, buttonStyle } from './ProgramCard.styles';
import { getSelectedPayload } from '../ProgramsSearchView.utils';
import { DissimilarConfirmation, SimilarConfirmation, AddProgramModal } from '../Confirmations';
import { ISnackbarState } from '../ProgramView';

type TProgramCardProps = {
  program: TProgramWithPosition;
  setIsSuccessSnackbar: Dispatch<SetStateAction<ISnackbarState>>;
  isDisabledFollowButton?: boolean;
  setFollowedProgramId?: Dispatch<SetStateAction<number | undefined>>;
};

export const ProgramCard = forwardRef(
  (
    { program, setIsSuccessSnackbar, isDisabledFollowButton = false, setFollowedProgramId }: TProgramCardProps,
    ref
  ): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedPrograms = useAppSelector(selectSelectedProgramCart);
    const activeFollowedPrograms = useSelector(selectActiveFollowedPrograms);
    const isActiveFollowedOrSavedProgram = activeFollowedPrograms?.some(({ program: { id } }) => program?.id === id);
    const isListView = useAppSelector(selectIsListView);
    /* istanbul ignore next */
    const [isProgramAdded, setIsProgramAdded] = useState(!!selectedPrograms?.find(e => e.program.id === program.id));

    const iconFontSize = isListView ? 25 : 30;

    useEffect(() => {
      /* istanbul ignore next */
      setIsProgramAdded(!!selectedPrograms?.find(e => e.program.id === program.id));
    }, [selectedPrograms, program.id]);

    const {
      addProgram,
      showDissimilarConfirmation,
      showSimilarConfirmation,
      setShowDissimilarConfirmation,
      setShowSimilarConfirmation,
      isAddProgramModalOpen,
      setIsAddProgramModalOpen,
    } = useProgramSimilarity(program.moKey);

    const saveProgram = () => {
      dispatch(
        postProgramCart(getSelectedPayload(selectedPrograms, program), () => {
          setIsProgramAdded(true);
          setIsSuccessSnackbar?.({ isVisible: true, isProgramAdded: true });
        })
      );
    };

    const followProgram = (adtSimilar?: boolean) => {
      dispatch(
        postFollowedProgram(prepareFollowProgramPayload({ ...program, adtSimilar }), () => {
          setFollowedProgramId?.(program?.id);
          dispatch(
            postProgramCart(getSelectedPayload(selectedPrograms, program), () => {
              setIsProgramAdded(true);
              setIsSuccessSnackbar?.({ isVisible: true, isProgramAdded: false });
            })
          );
        })
      );
    };

    return (
      <>
        <Card
          sx={{
            ...sxCard,
            ...(isListView ? sxCardListView(program) : {}),
          }}
        >
          <Box sx={{ p: '1rem 0 0.5rem 1rem', ...(isListView ? { pt: program.first ? 2 : 0, pb: 0 } : {}) }}>
            <Grid container {...(isListView ? { alignItems: 'center' } : {})}>
              {isListView && program.first && (
                <Grid
                  item
                  container
                  xs={12.5}
                  sx={{ background: (theme: Theme) => theme.palette.grey[200], py: 0.5, pl: 2, mt: -2, ml: -2 }}
                >
                  <Grid item xs={10}>
                    <Typography variant="subtitle7" sx={{ color: 'primary.contrastText' }}>
                      {t('programs.page.title', csuNameSpace)}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle7" sx={{ color: 'primary.contrastText' }}>
                      {t('programs.filters.currentlyAccepts', csuNameSpace)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={isListView ? 10 : 11}>
                <Box sx={{ display: 'flex', pr: '1.2rem' }}>
                  <Typography ref={ref as unknown as undefined} sx={buttonStyle} variant="subtitle3" component="button">
                    {isListView ? program.name : truncate(program.name, isUpperCase(program.name) ? 43 : 60)}
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={buttonStyle} variant="caption" component="button">
                    {isListView ? program.campusName : truncate(program.campusName, 46)}
                  </Typography>
                </Box>
              </Grid>
              {isListView && (
                <Grid item xs={1}>
                  <Typography variant="subtitle7" sx={{ color: program.tspEligible ? '#007A33' : '#CC0B2A' }}>
                    {t(program.tspEligible ? 'open_label' : 'closed_label', csuNameSpace)}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={1}>
                <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {isProgramAdded || isActiveFollowedOrSavedProgram ? (
                    <IconButton
                      aria-label={`${t('view_label', csuNameSpace)} ${program.name}`}
                      sx={{ '&:hover': { bgcolor: 'transparent' } }}
                    >
                      <IconCheckCircleColor style={{ fontSize: iconFontSize }} />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={event => {
                        event.stopPropagation();
                        addProgram();
                      }}
                      aria-label={`${t('add_label', csuNameSpace)} ${program.name}`}
                      size="extra-large"
                      color="secondary"
                    >
                      <IconAddCircle />
                    </IconButton>
                  )}
                </CardActions>
              </Grid>
              {!isListView && (
                <Grid item xs={12}>
                  <Divider
                    sx={{
                      mb: 1,
                      mx: -2,
                      borderStyle: 'solid',
                    }}
                  />
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item xs={8}>
                      <Typography variant="button" sx={{ color: 'text.secondary' }}>
                        {t('programs.filters.currentlyAccepts', csuNameSpace)}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{ ml: 1 }}>
                      <Typography variant="subtitle7" sx={{ color: program.tspEligible ? '#007A33' : '#CC0B2A' }}>
                        {t(program.tspEligible ? 'open_label' : 'closed_label', csuNameSpace)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        </Card>
        <DissimilarConfirmation
          open={showDissimilarConfirmation}
          onClose={() => {
            setShowDissimilarConfirmation(false);
          }}
          onConfirm={() => {
            setShowDissimilarConfirmation(false);
            saveProgram();
          }}
          onFollow={() => {
            setShowDissimilarConfirmation(false);
            followProgram(false);
          }}
          isDisabledFollowButton={isDisabledFollowButton}
        />
        <SimilarConfirmation
          open={showSimilarConfirmation}
          onClose={() => {
            setShowSimilarConfirmation(false);
          }}
          onSave={() => {
            setShowSimilarConfirmation(false);
            saveProgram();
          }}
          onFollow={() => {
            setShowSimilarConfirmation(false);
            followProgram(true);
          }}
          isDisabledFollowButton={isDisabledFollowButton}
        />
        <AddProgramModal
          open={isAddProgramModalOpen}
          onClose={() => {
            setIsAddProgramModalOpen(false);
          }}
          addProgram={() => {
            setIsAddProgramModalOpen(false);
            saveProgram();
          }}
          onFollow={() => {
            setIsAddProgramModalOpen(false);
            followProgram();
          }}
          isDisabledFollowButton={isDisabledFollowButton}
        />
      </>
    );
  }
);
