/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  Divider,
  Typography,
  Box,
  Stack,
  IconButton,
  useMediaQuery,
  type Theme,
} from '@mui/material';
import { IconCaretRight, IconFiles } from '@liaison/liaison-ui';

import { FIELD_LENGTH_50, FIELD_LENGTH_70 } from 'constants/field';
import { trimHeader } from 'utils/utilities';
import { StyledButton, sxCard } from 'pages/Pages.styles';
import type { IExperience } from 'userProfile/store/accomplishmentAndExperience/accomplishmentAndExperience.slice';
import { selectSectionMediaDocs } from 'userProfile/store/mediaDocuments/mediaDocuments.selectors';
import { sxButtonProfileView } from 'userProfile/components/AttachedFiles/AttachedFiles.styles';

import { displayDateRange } from '../ViewBuilder.utils';
import { textEllipsis, wrapText } from '../ViewBuilder.styles';

interface IExperienceCardProps {
  experience: IExperience;
  position: number;
  selector: string;
  formTitle: string;
  openChildDrawer?: (isAddNew: boolean, childPosition: number, selector: string, formTitle: string) => void;
}

export const ExperienceCard: React.FC<IExperienceCardProps> = ({
  experience: { id, name, type, startDate, endDate, description },
  position,
  openChildDrawer,
  selector,
  formTitle,
}: IExperienceCardProps): ReactElement => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const sectionMediaDocs = useSelector(state => selectSectionMediaDocs(state, id));
  return (
    <Card variant="outlined" sx={sxCard}>
      <CardContent style={{ height: '180px', overflow: 'hidden', paddingTop: '10px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '90px',
          }}
          onClick={() => openChildDrawer?.(false, position, selector, formTitle)}
        >
          <Stack spacing={0.5} padding={0.5} flexGrow={1}>
            <Typography variant="subtitle3" sx={{ pt: '0.3rem', display: 'block', ...wrapText }}>
              {trimHeader(name, isMobile ? FIELD_LENGTH_50 : FIELD_LENGTH_70)}
            </Typography>
            <Typography variant="caption">{type.displayName}</Typography>
            <Typography variant="caption" sx={{ pt: '0.3rem' }}>
              {displayDateRange(startDate, endDate)}
            </Typography>
          </Stack>
          {!!sectionMediaDocs?.length && (
            <StyledButton
              variant="contained"
              startIcon={<IconFiles />}
              size="medium"
              fullWidth
              aria-label="attachments"
              sx={{ ...sxButtonProfileView, width: '50px', marginLeft: '10px' }}
              onClick={() => openChildDrawer?.(false, position, selector, formTitle)}
            >
              {sectionMediaDocs?.length > 99 ? '99+' : sectionMediaDocs?.length}
            </StyledButton>
          )}
          <IconButton aria-label={type.code} role="button">
            <IconCaretRight />
          </IconButton>
        </Box>
        <Divider
          sx={{
            borderStyle: 'solid',
            mt: '0.7rem',
            mb: '0.7rem',
            borderColor: theme => theme.palette.grey[500],
          }}
          flexItem
        />
        <Typography variant="caption" sx={{ pt: '0.3rem', ...wrapText, ...textEllipsis }}>
          {description || 'No description.'}
        </Typography>
      </CardContent>
    </Card>
  );
};
