/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Button, Divider, Grid, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getButtonColor, getNameSpace } from 'utils/utilities';
import { doLogout } from 'utils/keyCloakUtils';
import { loginPageUrl } from 'constants/keyCloak';
import { setTitle } from 'utils/commonUtils';

const groupName = 'profileSetup';
const nameSpace = getNameSpace();

const ProfileSetup = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate('/onboarding');
  };

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  setTitle(t(`${groupName}.pageTitle`, nameSpace));

  return (
    <Stack
      justifyContent="space-between"
      alignSelf="center"
      role="main"
      sx={{
        m: isMobile ? '1.5rem' : '2.5rem',
      }}
    >
      <Typography variant="h1" sx={{ pb: '1.5rem' }}>
        {t(`${groupName}.title`, nameSpace)}
      </Typography>
      <Typography variant="body2" sx={{ pb: '1rem' }}>
        {t(`${groupName}.instruction`, nameSpace)}
      </Typography>

      <Grid container direction="row" sx={{ mb: '1rem' }}>
        <Button
          type="submit"
          size="large"
          fullWidth
          variant="contained"
          color={getButtonColor()}
          onClick={handleContinue}
        >
          {t('continue_label', nameSpace)}
        </Button>
      </Grid>
      <Divider sx={{ borderStyle: 'solid', mt: '0.7rem', mb: '0.7rem' }} flexItem />
      <Typography
        onClick={() => doLogout(loginPageUrl)}
        gutterBottom
        variant="subtitle6"
        component="span"
        align="center"
        sx={{ color: '#1565C0', cursor: 'pointer', textDecoration: 'underline' }}
        tabIndex={0}
      >
        {t('logOut', nameSpace)}
      </Typography>
    </Stack>
  );
};

export default ProfileSetup;
