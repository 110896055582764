/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { memo, ReactElement, useRef, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  FormHelperText,
  InputBase,
  Button,
  Switch,
} from '@mui/material';
import { DateField, Dropdown } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import { nameSpace, quarterTermCommunityCollegeIds } from 'transferPlanner/constants/general';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { selectCollegesLookup } from 'store/common/commonInfo.selectors';
import { DISABLE_AUTO_FILL } from 'constants/field';
import type { ICollege, TAcademicHistoryField } from 'store/academicHistory/academicHistory.slice';
import { sxPrimaryButton } from 'pages/Pages.styles';
import { getCommunityCollegeOptions, ICommunityCollegeOptions, postAcademicHistory } from 'utils/AcademicHistory.utils';
import { sanitizePayload } from 'utils/utilities';
import { communityCollegeSchema } from '../TspEnrollment.validation';
import { useFormData } from '../ContextForm';

interface ICommunityCollegeProps {
  ahData: TAcademicHistoryField | null;
  childPosition?: number;
}

type TFormErrors = {
  colleges: {
    name: { message: string };
    studentId: { message: string };
    startDate: { message: string };
  }[];
};
const CommunityCollegeForm = ({ ahData, childPosition = 0 }: ICommunityCollegeProps): ReactElement => {
  const dispatch = useDispatch();
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const formRef = useRef<HTMLFormElement>(null);
  const { t } = useTranslation(nameSpace);
  const [selectedOptions, setSelectedOptions] = useState<ICollege | {}>({});
  const groupName = 'colleges';
  const { setFormValues, data } = useFormData();
  const formData = ahData?.[groupName]?.[childPosition];
  const communityColleges = useSelector(selectCollegesLookup);
  const communityCollegesList = useMemo(() => getCommunityCollegeOptions(communityColleges), [communityColleges]);
  const {
    handleSubmit,
    control,
    formState: { errors: formErrors, isValid },
    setValue,
    register,
    reset,
  } = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(communityCollegeSchema, { abortEarly: false })(...args),
  });

  const errors = formErrors as unknown as TFormErrors;
  useEffect(() => {
    if (ahData) {
      setSelectedOptions(ahData?.[groupName]?.[childPosition] || {});
      reset(ahData);
    }
    return () => reset({});
  }, [reset, ahData, childPosition]);

  useEffect(() => {
    if (formRef && formRef.current && isInitialLoading) {
      setTimeout(() => {
        setIsInitialLoading(false);
        formRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 50);
    }
  }, [formRef, isInitialLoading]);

  useEffect(() => {
    if (selectedOptions) setValue(`colleges.[${childPosition}]`, selectedOptions);
  }, [selectedOptions, setValue, childPosition]);

  const handleCollegeListChange = (collegeDetails: ICommunityCollegeOptions | null) => {
    if (!formData?.graduated) {
      setValue(`${groupName}.${childPosition}.graduated`, 'No');
    }
    setValue(`${groupName}.${childPosition}.alternateId`, collegeDetails?.alternateId);
    setValue(`${groupName}.${childPosition}.ceeb`, collegeDetails?.ceeb);
    setValue(`${groupName}.${childPosition}.address`, collegeDetails?.address);
    /* istanbul ignore next */
    if (quarterTermCommunityCollegeIds.includes(collegeDetails?.collegeId || '')) {
      setValue(`${groupName}.${childPosition}.termType`, {
        code: 'QUARTER',
        displayName: 'Quarter',
      });
    } else {
      setValue(`${groupName}.${childPosition}.termType`, {
        code: 'SEMESTER',
        displayName: 'Semester',
      });
    }
  };

  const onSubmit = (payloadData: TAcademicHistoryField) => {
    const updatedData = payloadData;
    const selectedData = [...(ahData?.[groupName] || [])];
    if (selectedData != null) {
      if (updatedData?.[groupName]?.[childPosition] !== undefined) {
        selectedData[childPosition] = updatedData?.[groupName]?.[childPosition];
      }
    }

    let payload = ahData;
    if (selectedData[childPosition]?.primary) {
      const updatedColleges = selectedData?.map((collegeData, i) => {
        let primaryStatus = false;
        if (i === childPosition) primaryStatus = true;
        return { ...collegeData, primary: primaryStatus };
      });

      payload = {
        ...ahData,
        [groupName]: updatedColleges,
      };
    } else {
      payload = {
        ...ahData,
        [groupName]: selectedData,
      };
    }

    /* istanbul ignore next */
    dispatch(
      postAcademicHistory(sanitizePayload(Object.assign(payload)), () => {
        setFormValues({ ...data, enabled: false });
      })
    );
  };

  return (
    <Box sx={{ mt: 2, mb: 3 }}>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth required error={!!errors?.[groupName]?.[childPosition]?.name}>
          <InputLabel htmlFor={t('college.name')}>{t('college.name')}</InputLabel>
          <Controller
            render={({ field: { onChange, ...field } }) => (
              <Dropdown
                {...field}
                id={t('college.name')}
                disabled={Boolean(formData?.name)}
                options={communityCollegesList}
                onChange={(option: ICommunityCollegeOptions | null) => {
                  handleCollegeListChange(option);
                  return onChange(option?.id);
                }}
                inputProps={{
                  'aria-label': t('college.name'),
                  autoComplete: 'off',
                }}
              />
            )}
            control={control}
            name={`${groupName}.${childPosition}.name`}
          />
          <FormHelperText role="alert" id="name-error">
            {errors?.[groupName]?.[childPosition]?.name?.message || t('college.nameHelpertext')}
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth error={!!errors?.[groupName]?.[childPosition]?.studentId}>
          <InputLabel htmlFor="studentId">{t('college.communityCollegeId')}</InputLabel>
          <InputBase
            inputProps={{
              'aria-label': t('college.communityCollegeId'),
              'aria-describedby': 'studentId-error',
              ...DISABLE_AUTO_FILL,
            }}
            {...register(`${groupName}.${childPosition}.studentId`)}
          />
          <FormHelperText role="alert" id="studentId-error">
            {errors?.[groupName]?.[childPosition]?.studentId?.message}
          </FormHelperText>
        </FormControl>

        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={10} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="subtitle6">{t('college.primary')}</Typography>
            <Typography variant="body3">{t('college.note')}</Typography>
          </Grid>
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Controller
              control={control}
              name={`${groupName}.${childPosition}.primary`}
              defaultValue={false}
              render={({ field: { onChange, value, ...field } }) => {
                return (
                  <Switch
                    {...field}
                    checked={value}
                    onChange={(_event, val) => {
                      return onChange(val);
                    }}
                    inputProps={{
                      'aria-label': t('college.primary'),
                    }}
                  />
                );
              }}
            />
          </Grid>
        </Grid>

        <InputBase
          defaultValue={false}
          inputProps={{ type: 'hidden' }}
          {...register(`${groupName}.${childPosition}.graduated`)}
        />

        <Controller
          name={`${groupName}.${childPosition}.startDate`}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <FormControl fullWidth required error={!!errors?.[groupName]?.[childPosition]?.startDate}>
              <InputLabel htmlFor="startDate">{t('college.startDate')}</InputLabel>
              <DateField
                {...field}
                ref={ref}
                error={!!error}
                id="startDate"
                hidePart="day"
                onChange={dateVal => field.onChange(dateVal)}
                aria-describedby="startDate-error"
                accessibilityLabelPrefix={t('college.startDate')}
              />
              <FormHelperText role="alert" id="startDate-error">
                {errors?.[groupName]?.[childPosition]?.startDate?.message}
              </FormHelperText>
            </FormControl>
          )}
          control={control}
        />

        <Grid container justifyContent="center">
          <Button
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => setFormValues({ ...data, closeForm: true })}
            variant="contained"
            aria-label={t('cancel_label')}
          >
            {t('cancel_label')}
          </Button>
          <Button
            disabled={!isValid}
            sx={{
              ...sxPrimaryButton,
              ml: 3,
              mt: 2,
            }}
            variant="contained"
            aria-label={t('college.save')}
            type="submit"
          >
            {t('college.save')}
          </Button>
        </Grid>
      </form>
    </Box>
  );
};

export default memo(CommunityCollegeForm);
