/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import moment from 'moment';
import PersonalInformationIcon from 'assets/svgs/personal-information.svg';
import AcademicIcon from 'assets/svgs/acadamic-icon.svg';
import AccomplishmentIcon from 'assets/svgs/accomplishment-icon.svg';
import IntegrationsIcon from 'assets/svgs/integrations-icon.svg';
import CommunityIcon from 'assets/svgs/community-icon.svg';
import MapIcon from 'assets/svgs/map-icon.svg';
import BiographIcon from 'assets/svgs/biograph-icon.svg';
import FolderIcon from 'assets/svgs/folder-icon.svg';
import NotesIcon from 'assets/svgs/notes-icon.svg';
import MediaIcon from 'assets/svgs/media-icon.svg';
import { MAX_TITLE_LENGTH } from 'constants/field';
import { ButtonPropsColorOverrides, CheckboxPropsColorOverrides } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { OverridableStringUnion } from '@mui/types';
import type { ICollegeCommon, ISchoolOfInterest } from 'store/common/commonInfo.slice';

localStorage.setItem('tenant', JSON.parse(window.env.DOMAINS)[window.location.host] || 'liaison');

export const renderIconName = (iconName: string): string => {
  switch (iconName) {
    case 'CommunityProfileIcon':
      return FolderIcon;
    case 'PersonalInformationIcon':
      return PersonalInformationIcon;
    case 'AcademicIcon':
      return AcademicIcon;
    case 'AccomplishmentIcon':
      return AccomplishmentIcon;
    case 'IntegrationsIcon':
      return IntegrationsIcon;
    case 'CommunityIcon':
      return CommunityIcon;
    case 'MapIcon':
      return MapIcon;
    case 'BiographIcon':
      return BiographIcon;
    case 'AcadamicHistory':
      return NotesIcon;
    case 'MediaIcon':
      return MediaIcon;
    default:
      return PersonalInformationIcon;
  }
};

export const dateFields = ['birthDate', 'serviceFrom', 'serviceTo', 'validFrom', 'validTo', 'startDate'];

export const displayDate = (value: string, format = 'MMMM D, YYYY'): string => moment(value).format(format);

export const extractYear = (value: string): string => moment(value).format('YYYY');

export interface Ioption {
  code: string;
  displayName: string;
}

export interface IoptionModified {
  id: string;
  text: string;
  data?: string;
  disabled?: boolean;
}

export const getDropDownOptions = (options: Ioption[]): IoptionModified[] =>
  JSON.parse(JSON.stringify(options).split('"code":').join('"id":').split('"displayName":').join('"text":'));

export const getDropDownOptionsAreasOfStudy = (options: Ioption[]): IoptionModified[] =>
  JSON.parse(JSON.stringify(options).split('"code":').join('"id":').split('"displayName":').join('"text":'));

export const changeDropDownOptions = (options: IoptionModified[]): Ioption[] =>
  JSON.parse(JSON.stringify(options).split('"id":').join('"code":').split('"text":').join('"displayName":'));

interface IObjectVal {
  prop: unknown;
}

export interface IResponseDetails {
  [key: string]: IObjectVal;
}

const booleanFields = [
  'visible',
  'dualCitizenship',
  'living',
  'inPrimaryHousehold',
  'graduated',
  'preferred',
  'studiedAbroad',
  'visible',
];
export const modifyStringToBoolean = (key: string, value: string): boolean | string => {
  if (typeof value === 'string' && booleanFields.includes(key)) {
    return value === 'Yes' || value === 'true';
  }

  return value;
};
const isArrayContainsObjects = (data: string[] | Record<string, string>[]): boolean => {
  return data?.some(item => {
    return typeof item === 'object';
  });
};
export const sanitizePayload = (payload: IResponseDetails): IResponseDetails => {
  if (!payload) {
    return payload;
  }
  const sanitizedPayload = JSON.parse(JSON.stringify({}));
  Object.keys(payload)?.forEach(key => {
    const requestObjValue = JSON.parse(JSON.stringify(payload[key] || ''));
    if (requestObjValue && Array.isArray(requestObjValue) && !isArrayContainsObjects(requestObjValue)) {
      sanitizedPayload[key] = requestObjValue;
    } else if (requestObjValue && Array.isArray(requestObjValue)) {
      const nestedArray: IResponseDetails[] = [];
      requestObjValue?.map(reqObj => {
        const modifiedObject = sanitizePayload(reqObj) ?? {};
        if (Object.keys(modifiedObject).length) {
          nestedArray.push(modifiedObject);
        }
        return true;
      });
      if (nestedArray.length) {
        sanitizedPayload[key] = nestedArray;
      }
    } else if (requestObjValue && typeof requestObjValue === 'object') {
      const nestedObj = sanitizePayload(requestObjValue) ?? {};
      if (Object.keys(nestedObj).length) {
        sanitizedPayload[key] = nestedObj;
      }
    } else if (requestObjValue !== '' && requestObjValue !== undefined && requestObjValue !== null) {
      sanitizedPayload[key] = modifyStringToBoolean(key, requestObjValue);
    } else if (typeof payload[key] === 'boolean' || typeof payload[key] === 'number') {
      sanitizedPayload[key] = payload[key];
    }
  });
  return sanitizedPayload;
};

export const isValidDate = (date: string): boolean => {
  const modifiedDate = new Date(date);
  if (Object.prototype.toString.call(modifiedDate) === '[object Date]') {
    return !Number.isNaN(modifiedDate?.getTime());
  }
  return false;
};

export const emptyOptions = [{ code: '', displayName: '' }] as Ioption[];

export const isEmpty = (data: boolean | undefined | string | null): boolean => {
  return data === undefined || data === '' || data === null;
};

interface IoptionForNumber {
  id: string;
  text: string;
  disabled?: boolean;
}

export const rangeOfYearsDropDown = (start: number, end: number): IoptionForNumber[] =>
  Array(end - start + 1)
    .fill(start)
    .map((year, index) => {
      const generatedYear = year + index;
      return {
        id: generatedYear.toString(),
        text: generatedYear.toString(),
      };
    });

export const isUpperCase = (str: string): boolean => str === str.toUpperCase();

export const truncate = (input: string, length: number): string =>
  input && input.length > length ? `${input.substring(0, length)}...` : input;

export const trimHeader = (heading: string, length = MAX_TITLE_LENGTH): string => truncate(heading, length);

export const min = '1900-01-01';
export const max = '2100-12-31';
export const today = new Date();

export const yearValidator = (value: string | undefined): boolean => {
  if (!value) return true;
  const valueInTime = new Date(new Date(value).setHours(0)).getTime();
  const minValueInTime = new Date(new Date(min).setHours(0)).getTime();
  const maxValueInTime = new Date(new Date(max).setHours(0)).getTime();
  return minValueInTime <= valueInTime && maxValueInTime >= valueInTime;
};

export const getNameSpace = (): Record<string, string> =>
  localStorage.getItem('tenant') === 'csu' ? { ns: 'tp' } : { ns: 'lp' };

export const csuNameSpace = { ns: 'tp' };

export const isCsuTenant = (): boolean => localStorage.getItem('tenant') === 'csu';

export const isLiaisonTenant = (): boolean => localStorage.getItem('tenant') === 'liaison';

export const getTenant = (): string => localStorage.getItem('tenant') || 'liaison';

export const getInitialFromName = (firstName: string, lastName: string): string => {
  const initials = (firstName ? firstName[0] : '') + (lastName ? lastName[0] : '');
  return initials.toUpperCase();
};

export const formatGPA = (input: number): number | string => (Number.isInteger(input) ? input.toFixed(1) : input);

export const mailTo = (emailAddress: string): void => {
  const mailToLink = document.createElement('a');
  mailToLink.target = '_blank';
  mailToLink.href = `mailto:${emailAddress}`;
  mailToLink.click();
};

export const unEscapeRegEx = (escapedRegExString: string): string => `${`${escapedRegExString}`.replace(/\\/g, '\\')}`;

export const getButtonColor = (): OverridableStringUnion<
  'inherit' | 'error' | 'success' | 'primary' | 'secondary' | 'info' | 'warning',
  ButtonPropsColorOverrides
> => {
  return isCsuTenant() ? 'secondary' : 'primary';
};

export const getCheckboxColor = (): OverridableStringUnion<
  'error' | 'success' | 'primary' | 'secondary' | 'info' | 'warning' | 'default',
  CheckboxPropsColorOverrides
> => {
  return isCsuTenant() ? 'secondary' : 'primary';
};

export const getMultiSelectColor = (): 'secondary' | 'primary' | undefined => {
  return isCsuTenant() ? 'secondary' : 'primary';
};

export const isTheSameStringsArray = (array1?: string[], array2?: string[]): boolean => {
  if (array1?.length !== array2?.length) {
    return false;
  }
  return array1?.sort().toString() === array2?.sort().toString();
};

export interface FieldError {
  message: string;
}

export const getOptionDisabled =
  (key: string, list: (ISchoolOfInterest | ICollegeCommon)[] | null) =>
  (option: ISchoolOfInterest | ICollegeCommon): boolean => {
    const keyVal = key as keyof (ISchoolOfInterest | ICollegeCommon);
    if (list && list?.some(item => item[keyVal] === option[keyVal])) {
      return true;
    }
    return false;
  };

export const formatExperienceDateRange = (startDate: string | undefined, endDate: string | undefined): string => {
  const startYear = startDate ? extractYear(startDate) : '';
  const endYear = endDate ? extractYear(endDate) : 'Present';
  return `${startYear} - ${endYear}`;
};

export const capitalizeText = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const resourceMap: { [key: string]: string } = {
  highSchools: 'academicHistory',
  colleges: 'academicHistory',
  hsCourse: 'academicHistory',
  collegeCourse: 'academicHistory',
  degrees: 'academicHistory',
  experiences: 'achievements',
  honorsOrAwards: 'achievements',
};
