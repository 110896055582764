/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';

import { TRootState } from 'redux/store';
import { IBiographicalInfoState } from './biographicalInfo.slice';

const biographicalInfo = (state: TRootState): IBiographicalInfoState => state.biographicalInfo;

export const selectBiographicalInformation = createSelector(
  biographicalInfo,
  (stateData: IBiographicalInfoState) => stateData?.biographicalInfoData
);

export const selectLoading = createSelector(
  biographicalInfo,
  (stateData: IBiographicalInfoState) => stateData?.loading
);

export const selectBiographicalInformationLocalData = createSelector(
  biographicalInfo,
  (stateData: IBiographicalInfoState) => stateData?.biographicalInfoLocalData
);
