/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export const textEllipsis = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
} as const;

export const wrapText = {
  width: '90%',
  overflowWrap: 'anywhere',
} as const;
