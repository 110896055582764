/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Components, Theme } from '@mui/material';

export default ({ palette }: Theme): Components => ({
  MuiCardActionArea: {
    styleOverrides: {
      root: {
        [`&.Mui-focusVisible`]: {
          bgcolor: '#fff',
          border: `2px solid ${palette.secondary.dark}`,
          borderRadius: 1,
          [`.MuiCardActionArea-focusHighlight`]: {
            opacity: 0,
          },
        },
      },
    },
  },
});
