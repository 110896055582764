/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useState, useMemo, useEffect } from 'react';
import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import { IconCaretRight, IconSearch } from '@liaison/liaison-ui';
import { getAddressString, ICampus, ICoordinates } from '../CampusesAndPrograms.utils';
import { CampusListStyle, selectedCampusStyle, StyledInput } from '../CampusesAndPrograms.styles';

interface ICampusListProps {
  campuses: ICampus[] | null;
  filteredCampuses: ICampus[];
  setCenter: (coords: ICoordinates) => void;
  onClickMap: (idx: number) => void;
  setFilteredData: (data: ICampus[]) => void;
  setFocusedItemIndex?: (d: number | undefined) => void | undefined;
  focusedItemIndex?: number | undefined;
}

const CampusList = (props: ICampusListProps): ReactElement => {
  const [searchValue, setSearchValue] = useState('');
  const {
    campuses = [],
    filteredCampuses,
    setCenter,
    onClickMap,
    setFilteredData,
    setFocusedItemIndex,
    focusedItemIndex,
  } = props;

  const filter = useMemo(
    () =>
      searchValue
        ? campuses?.filter(item => item?.name?.toLowerCase().includes(searchValue.toLowerCase())) || []
        : campuses,
    [campuses, searchValue]
  );

  useEffect(() => {
    if (campuses && campuses.length > 0) {
      /* istanbul ignore next */
      setFilteredData(filter || []);
    }
  }, [setFilteredData, filter, campuses]);

  useEffect(() => {
    if (focusedItemIndex && setFocusedItemIndex) {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
          if (document.activeElement?.id === 'map_information-card-view_programs_label') {
            const element: HTMLElement | null = document.getElementById(`campus-list-focus-caret-${focusedItemIndex}`);
            if (element) {
              element.focus();
              setFocusedItemIndex(undefined);
            }
          }
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
    return () => {};
  }, [focusedItemIndex, setFocusedItemIndex]);

  const handleOnClick = (campus: ICampus, index: number) => {
    setCenter({
      lat: campus?.contact?.latitude || 0,
      lng: campus?.contact?.longitude || 0,
    });
    onClickMap(index);
    if (setFocusedItemIndex) setFocusedItemIndex(index + 1);
  };

  return (
    <Box sx={{ p: '0.4rem', height: '100%', overflow: 'scroll', pb: '2rem' }}>
      <StyledInput
        placeholder="Search"
        value={searchValue}
        onChange={e => {
          setSearchValue(e.target.value);
        }}
        inputProps={{ 'aria-label': 'search campus' }}
        startAdornment={
          <InputAdornment position="start">
            <IconSearch sx={{ height: 15, width: 15 }} />
          </InputAdornment>
        }
      />
      {filteredCampuses?.map((campus, index) => {
        return (
          <Box
            key={campus.alternateId}
            sx={[CampusListStyle, campus.show ? selectedCampusStyle : {}]}
            onClick={() => handleOnClick(campus, index)}
            role="group"
            aria-label={`${campus.name}_${campus.alternateId}`}
            data-testid="campus-list"
          >
            <Box
              key={campus.name}
              sx={{
                my: '0.8rem',
                px: '1rem',
              }}
            >
              <Typography variant="subtitle3" sx={{ overflowWrap: 'anywhere' }}>
                {campus.name}
                {` `}
              </Typography>
              <Typography variant="caption" sx={{ overflowWrap: 'anywhere' }}>
                {getAddressString(campus?.contact)}
              </Typography>
            </Box>
            <Box sx={{ pr: '1rem' }}>
              <IconButton
                aria-label="view campus"
                size="small"
                sx={{ height: 'fit-content' }}
                aria-expanded={campus.show || false}
                id={`campus-list-focus-caret-${index + 1}`}
              >
                <IconCaretRight />
              </IconButton>
            </Box>
          </Box>
        );
      })}
      {filteredCampuses?.length === 0 && searchValue !== '' && (
        <Typography variant="body2" role="alert" aria-live="assertive" sx={{ overflowWrap: 'anywhere', p: '1rem' }}>
          No Result
        </Typography>
      )}
    </Box>
  );
};
export default CampusList;
