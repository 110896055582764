/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { t } from 'i18next';
import type { AnyAction, Dispatch } from 'redux';
import axios from 'axios';
import type { TMultiSelectOption } from '@liaison/liaison-ui';

import type { TAppThunk } from 'redux/store';
import { API } from 'userProfile/constants/api';
import {
  accomplishmentAndExperienceStart,
  accomplishmentAndExperienceSuccess,
  TAccomplishmentsAndExperienceField,
  accomplishmentAndExperienceFailure,
  ILanguage,
  accomplishmentAndExperienceChildSuccess,
  resetSkillsAttachments,
  type TCompetency,
  type TSkillsAttachments,
  type IHonorsAndAward,
  type IExperience,
} from 'userProfile/store/accomplishmentAndExperience/accomplishmentAndExperience.slice';
import { setUi } from 'store/ui/ui.slice';
import { getLoggedUser } from 'utils/keyCloakUtils';
import { lookupApiStart, lookupApiSuccess, lookupApiFailure } from 'store/common/commonInfo.slice';
import { getDropDownOptions } from 'utils/utilities';
import MasterData from 'userProfile/constants/master';
import { attachMediaDocs } from 'userProfile/pages/MediaDocuments/MediaDocuments.utils';
import { TPendingAttachments, resetPendingAttachments } from 'userProfile/store/mediaDocuments/mediaDocuments.slice';

export const getAccomplishmentsAndExperience =
  (successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(accomplishmentAndExperienceStart());
      const { data } = await axios.get(`${API?.accomplishmentAndExperience}${getLoggedUser()}`);
      dispatch(accomplishmentAndExperienceSuccess(data));
      successCallback?.();
    } catch (err) {
      dispatch(accomplishmentAndExperienceFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const postAccomplishmentsAndExperience =
  (payload: TAccomplishmentsAndExperienceField, successCallback?: () => void): TAppThunk =>
  async (dispatch, getState) => {
    try {
      const loggedUserId = getLoggedUser();
      const { competencies } = getState().accomplishmentAndExperience?.accomplishmentsAndExperienceData ?? {};
      const modifiedCompetencies = payload?.competencies?.map(
        ({ competency }) => competencies?.find((c: TCompetency) => c.competency === competency) ?? { competency }
      );
      const modifiedPayload = {
        ...payload,
        competencies: modifiedCompetencies,
        profileId: loggedUserId,
      };

      dispatch(accomplishmentAndExperienceStart());
      const { data } = await axios.post(`${API?.accomplishmentAndExperience}${loggedUserId}`, modifiedPayload);
      dispatch(accomplishmentAndExperienceSuccess(data));
      dispatch(
        setUi({ name: 'succesSnackbar', state: { open: true, message: t('success_message'), hideTitle: true } })
      );
      successCallback?.();
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      dispatch(accomplishmentAndExperienceFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const handlePendingAttachments = (
  payload: TAccomplishmentsAndExperienceField,
  data: TAccomplishmentsAndExperienceField,
  dispatch: Dispatch<AnyAction>,
  pendingAttachments: Partial<TPendingAttachments>
): void => {
  if (!pendingAttachments.honorsOrAwards?.length && !pendingAttachments.experiences?.length) {
    dispatch(resetPendingAttachments());
    return;
  }
  const newItemSectionName = payload.honorsOrAwards?.find(item => !item.id) ? 'honorsOrAwards' : 'experiences';
  const newItemName = (payload[newItemSectionName] as (IHonorsAndAward | IExperience)[])?.find(item => !item.id)?.name;
  const idsOfExistingItemsWithSameName = (payload[newItemSectionName] as (IHonorsAndAward | IExperience)[])
    .filter(item => item.name === newItemName && !!item.id)
    .map(item => item.id);
  const newItemId = (data[newItemSectionName] as (IHonorsAndAward | IExperience)[])?.find(
    item => item.name === newItemName && !idsOfExistingItemsWithSameName?.includes(item.id)
  )?.id;
  const dispatchResetPendingAttachments = () => {
    dispatch(resetPendingAttachments());
  };
  dispatch(
    attachMediaDocs(
      {
        resource: 'achievements',
        tag: `${newItemSectionName}/${newItemId}`,
        documentIds: [...(pendingAttachments[newItemSectionName] ?? [])],
      },
      dispatchResetPendingAttachments,
      dispatchResetPendingAttachments
    )
  );
};

export const attachSkills =
  (
    payload: {
      resource?: string;
      tag?: string;
      competencyIds: string[];
      updateChildNodes?: boolean;
    },
    successCallback?: () => void,
    failureCallback?: () => void
  ): TAppThunk =>
  async dispatch => {
    const { tag, competencyIds = [], updateChildNodes = false, resource } = payload;
    const modifiedPayload = {
      resource,
      tag,
      competencyIds,
      ...(updateChildNodes ? { updateChildNodes } : {}),
    };
    try {
      const loggedUserId = getLoggedUser();
      const { data } = await axios.post(`${API?.accomplishmentAndExperience}${loggedUserId}/attach`, modifiedPayload);
      dispatch(accomplishmentAndExperienceChildSuccess(data));
      dispatch(
        setUi({
          name: 'succesSnackbar',
          state: { open: true, message: t('success_message'), hideTitle: true },
        })
      );
      successCallback?.();
    } catch {
      dispatch(
        setUi({
          name: 'apiStatus',
          state: { failure: true, failureMessage: t('mediaDocuments.attaching.error'), hideTitle: true },
        })
      );
      failureCallback?.();
    }
  };

export const handleSkillsAttachments = (
  payload: TAccomplishmentsAndExperienceField,
  data: TAccomplishmentsAndExperienceField,
  dispatch: Dispatch<AnyAction>,
  skillsAttachments: TSkillsAttachments[]
): void => {
  const isNewSection = skillsAttachments?.[0].tag.split('/').pop() === 'undefined';
  let newSectionTag;
  if (isNewSection) {
    const newItemSectionName = payload.honorsOrAwards?.find(item => !item.id) ? 'honorsOrAwards' : 'experiences';
    const newItemName = (payload[newItemSectionName] as (IHonorsAndAward | IExperience)[])?.find(
      item => !item.id
    )?.name;
    const idsOfExistingItemsWithSameName = (payload[newItemSectionName] as (IHonorsAndAward | IExperience)[])
      ?.filter(item => item.name === newItemName && !!item.id)
      .map(item => item.id);
    const newItemId = (data[newItemSectionName] as (IHonorsAndAward | IExperience)[])?.find(
      item => item.name === newItemName && !idsOfExistingItemsWithSameName?.includes(item.id)
    )?.id;
    if (newItemId) {
      newSectionTag = `${newItemSectionName}/${newItemId}`;
    }
  }

  const competencyIds =
    skillsAttachments?.[0].competencies?.map(
      ({ competency }) => data?.competencies?.find(c => c.competency === competency)?.id ?? ''
    ) ?? [];
  const modifiedSkillsAttachments = {
    resource: skillsAttachments[0].resource,
    tag: newSectionTag ?? skillsAttachments[0].tag,
    competencyIds,
  };
  const dispatchResetSkillsAttachments = () => {
    dispatch(resetSkillsAttachments());
  };
  dispatch(attachSkills(modifiedSkillsAttachments, dispatchResetSkillsAttachments, dispatchResetSkillsAttachments));
};

export const postChildData =
  (
    payload: TAccomplishmentsAndExperienceField,
    successCallback?: (data?: TAccomplishmentsAndExperienceField) => void,
    attachSkillsToAccomplishments = true
  ): TAppThunk =>
  async (dispatch, getState) => {
    const { pendingAttachments } = getState().mediaDocuments;
    const { skillsAttachments } = getState().accomplishmentAndExperience;
    try {
      const loggedUserId = getLoggedUser();
      dispatch(accomplishmentAndExperienceStart());

      const newCompetencies: TCompetency[] = [];
      skillsAttachments?.forEach(({ competencies }) => {
        if (competencies?.length) {
          competencies.forEach(({ competency }) => {
            if (!payload?.competencies?.find(c => c.competency === competency)) {
              newCompetencies.push({ competency });
            }
          });
        }
      });
      const modifiedPayload = {
        ...payload,
        competencies: [...(payload?.competencies ?? []), ...newCompetencies],
        profileId: loggedUserId,
      };
      const { data } = await axios.post(`${API?.accomplishmentAndExperience}${loggedUserId}`, modifiedPayload);
      dispatch(accomplishmentAndExperienceChildSuccess(data));
      if (pendingAttachments) {
        handlePendingAttachments(payload, data, dispatch, pendingAttachments);
      }
      if (attachSkillsToAccomplishments && skillsAttachments) {
        handleSkillsAttachments(payload, data, dispatch, skillsAttachments);
      }
      if (!pendingAttachments && !skillsAttachments) {
        dispatch(
          setUi({ name: 'succesSnackbar', state: { open: true, message: t('success_message'), hideTitle: true } })
        );
      }
      successCallback?.(data);
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      dispatch(accomplishmentAndExperienceFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
      if (pendingAttachments) {
        dispatch(resetPendingAttachments());
      }
    }
  };

interface IOptionModified {
  id: string;
  text: string;
}
export const getFilteredLanguageOptions = (
  index: number,
  selectedLanguageList: ILanguage[],
  languageOptions: IOptionModified[]
): IOptionModified[] => {
  const current = selectedLanguageList?.[index]?.language?.code;
  const res = languageOptions?.filter(el => {
    return (
      (current && el.id === current) ||
      !selectedLanguageList?.find((selected: ILanguage) => {
        return selected?.language?.code === el.id;
      })
    );
  });
  return res;
};

export const getOptionsFromTags = (options: TCompetency[]): TMultiSelectOption[] => {
  return options?.map(({ competency }) => ({
    id: competency,
    text: competency,
  }));
};

export const getTotalHours = (weeklyAverage: number | undefined, weeks: number | undefined): number | '' => {
  if (weeklyAverage === undefined || weeks === undefined) {
    return '';
  }
  const commonMultiplier = 1000000;
  return (
    ((weeklyAverage || 0) * commonMultiplier * (weeks || 0) * commonMultiplier) / (commonMultiplier * commonMultiplier)
  );
};

export const getSkills =
  (queryParams = ''): TAppThunk =>
  async dispatch => {
    try {
      dispatch(lookupApiStart());
      const { data } = await axios.get(`${API?.skills}${queryParams}`);
      const lookupData = {
        data,
        endPoint: 'skills',
      };
      dispatch(lookupApiSuccess(lookupData));
    } catch (err) {
      dispatch(lookupApiFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const defaultLanguage = {
  language: { displayName: '', code: '' },
  proficiencyLevel: { displayName: '', code: '' },
};
export const groupName = 'languages';
export const proficiencyLevelOptions = getDropDownOptions(MasterData.proficiencyLevels);

export const getDropDownOptionsForAC = (options: string[]): TMultiSelectOption[] => {
  return options?.map(option => ({
    id: option,
    text: option,
  }));
};

export const getAchievementEnumTypes = (): TAppThunk => async dispatch => {
  try {
    dispatch(lookupApiStart());
    const { data } = await axios.get(`${API?.enumTypes}`);
    dispatch(lookupApiSuccess({ data, endPoint: 'achievements' }));
  } catch (err) {
    dispatch(lookupApiFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  }
};
