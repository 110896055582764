/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { memo, ReactElement, useMemo, useEffect } from 'react';
import { Box, Typography, FormControl, InputLabel, InputBase, Divider } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import MasterData from 'userProfile/constants/master';
import { useSelector } from 'react-redux';
import { RadioControl } from 'components/RadioControl';
import { CheckboxGroup } from 'components/CheckboxGroup';
import { selectEthnicityLookup, selectRacesLookup } from 'store/common/commonInfo.selectors';
import { TBiographicalInfoField } from 'userProfile/store/biographicalInfo/biographicalInfo.slice';
import { ICheckboxGroupResponse, TCheckboxGroupOption } from 'components/CheckboxGroup/CheckboxGroup';
import { StyledFormHelperText } from 'pages/Pages.styles';
import { useTranslation } from 'react-i18next';
import type { TBiographicFormErrors } from '../BiographicalInformationForm.validation';

interface IBiographicalInfoProps {
  data: TBiographicalInfoField | null;
}

const RacesAndEthnicity = ({ data }: IBiographicalInfoProps): ReactElement => {
  const {
    control,
    reset,
    setValue,
    register,
    formState: { errors: formErrors },
  } = useFormContext();
  const errors = formErrors as unknown as TBiographicFormErrors;
  const groupName = 'raceEthnicity';
  const { t } = useTranslation();
  const answerOptions = useMemo(() => MasterData?.answerOptions, []);

  useEffect(() => {
    if (data) {
      reset(data);
    }
    return () => reset({});
  }, [reset, data]);

  const racesLookup = useSelector(selectRacesLookup);
  const ethnicityLookup = useSelector(selectEthnicityLookup);

  const raceOptions = useMemo(() => racesLookup || [], [racesLookup]);
  const ethnicityOptions = useMemo(() => ethnicityLookup || [], [ethnicityLookup]);

  const handleCheckboxChange = (name: string, checkBoxData: ICheckboxGroupResponse[]) => {
    setValue(`${groupName}.${name}`, checkBoxData);
  };

  return (
    <Box sx={{ mt: '2rem' }}>
      <Typography
        variant="h2"
        sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
      >
        {t('raceEthnicity.ethnicities')}
      </Typography>
      <FormControl fullWidth>
        <InputLabel htmlFor="hispanicLatino">{t('raceEthnicity.hispanicLatino')}</InputLabel>

        <Controller
          name={`${groupName}.hispanicLatino.code`}
          render={({ field: { ref, ...field } }) => (
            <RadioControl
              {...field}
              inputRef={ref}
              id="hispanicLatino"
              options={answerOptions}
              inline={true}
              onClick={event => {
                let displayName;
                if ((event.target as HTMLInputElement).value === 'YES') {
                  displayName = 'Yes';
                } else {
                  displayName = 'No';
                }
                setValue(`${groupName}.hispanicLatino.displayName`, displayName);
              }}
              error={!!errors?.[groupName]?.hispanicLatino?.code?.message}
              aria-describedby="hispanicLatino-error"
            />
          )}
          control={control}
          defaultValue=""
        />
        <StyledFormHelperText role="alert" id="hispanicLatino-error">
          {errors?.[groupName]?.hispanicLatino?.code?.message}
        </StyledFormHelperText>
      </FormControl>
      <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.hispanicLatino.displayName`)} />
      <InputLabel htmlFor="ethnicities" sx={{ pb: '1rem' }}>
        {t('raceEthnicity.checkAllToApply')}
      </InputLabel>
      <Controller
        name={`${groupName}.ethnicities`}
        render={({ field: { ...field } }) => (
          <CheckboxGroup
            value={field.value || []}
            id="ethnicities"
            name="ethnicities"
            onCheckboxChange={handleCheckboxChange}
            options={ethnicityOptions as TCheckboxGroupOption[]}
          />
        )}
        control={control}
      />
      <Box sx={{ py: '2rem' }}>
        <Divider sx={{ borderStyle: 'solid' }} />
      </Box>

      <Typography
        variant="h2"
        sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
      >
        {t('raceEthnicity.races_title')}
      </Typography>
      <InputLabel htmlFor="races" sx={{ pb: '1rem' }}>
        {t('raceEthnicity.checkMoreThanOne')}
      </InputLabel>
      <Controller
        name={`${groupName}.races`}
        render={({ field: { ...field } }) => (
          <CheckboxGroup
            value={field.value || []}
            id="races"
            name="races"
            onCheckboxChange={handleCheckboxChange}
            options={raceOptions as TCheckboxGroupOption[]}
          />
        )}
        control={control}
      />
    </Box>
  );
};

export default memo(RacesAndEthnicity);
