/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import * as yup from 'yup';
import moment from 'moment';
import { yearValidator } from 'utils/utilities';
import { t } from 'i18next';
import { DOB_FORMAT } from 'constants/general';

export const validationSchema = yup.object().shape({
  highSchoolGPA: yup
    .number()
    .min(0.1, t('academicHistory.error.gpa.minVal'))
    .max(4, t('academicHistory.error.gpa.maxVal'))
    .nullable(true)
    .transform((_, val) => (val === Number(val) ? val : null)),
  collegeGPA: yup
    .number()
    .min(0.1, t('academicHistory.error.gpa.minVal'))
    .max(4, t('academicHistory.error.gpa.maxVal'))
    .nullable(true)
    .transform((_, val) => (val === Number(val) ? val : null)),
  startDate: yup
    .string()
    .test('Validate date', t('common.error.date.invalid'), value => {
      if (value) {
        return moment(value, value.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid();
      }
      return true;
    })
    .test('Is date in correct range', t('common.error.date.yearRange'), value => {
      if (value) {
        return yearValidator(value);
      }
      return true;
    }),
});

export const validationChildSchema = yup.object().shape({
  schools: yup.object().shape({
    name: yup.string().required(t('academicGoal.schoolValidation.required')),
  }),
  country: yup.object().shape({
    code: yup.string().required(t('academicGoal.countryValidation.required')),
  }),
});
