/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo } from 'react';
import { Navigate, useLocation, Outlet, useNavigate } from 'react-router-dom';
import { keycloakLoginPage } from 'constants/keyCloak';
import { selectObData } from 'store/common/commonInfo.selectors';
import { useSelector, useDispatch } from 'react-redux';
import { Skeleton } from '@mui/material';
import axios from 'axios';
import { useImpersonation } from 'hooks/useImpersonation';
import { ApiStatusHandler } from 'components/ApiStatusHandler';
import { skipRoutesForLogin } from 'constants/general';
import SessionTimeout from 'components/SessionTimeout/SessionTimeout';
import { doLogout } from 'utils/keyCloakUtils';
import { getObData } from 'utils/commonUtils';
import { useKeycloak } from '@react-keycloak/web';
import { SuccessSnackbar } from 'components/SuccessSnackbar';
import { ErrorSnackbar } from 'components/ErrorSnackbar';
import { getFeatures } from 'utils/features.utils';
import { selectFeatures } from 'store/features/features.selectors';

const RequireAuth = (): ReactElement => {
  const kcUserToken = localStorage.getItem('kc_user_token');
  const location = useLocation();
  const { keycloak } = useKeycloak();
  const obData = useSelector(selectObData);
  const features = useSelector(selectFeatures);
  const impersonationToken = useImpersonation();
  const isLoggedIn = impersonationToken || keycloak?.authenticated;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (isLoggedIn) {
    if (isLoggedIn && kcUserToken) {
      if (obData) {
        if (!features) dispatch(getFeatures());
        const currentPathName = location.pathname.replace(/^\//, '');
        const routingStep = obData?.nextSteps[0]?.routing;
        if (obData?.nextSteps?.length > 1) {
          if (routingStep !== currentPathName && currentPathName !== 'onboarding') {
            navigate(routingStep);
            return <></>;
          }
        } else if (skipRoutesForLogin?.includes(currentPathName)) {
          navigate(routingStep);
          return <></>;
        }
        return (
          <>
            <Outlet />
            <ApiStatusHandler />
            <SuccessSnackbar />
            <ErrorSnackbar />
            <SessionTimeout isAuthenticated={isLoggedIn} logOut={doLogout} />
          </>
        );
      }

      if (localStorage.getItem('kc_impersonation_token') && !obData) {
        const kcToken = localStorage.getItem('kc_impersonation_token');
        axios.defaults.headers.common.Authorization = `Bearer ${kcToken}`;
        dispatch(getObData(kcToken || ''));
      }

      return <Skeleton data-testid="progressbar" animation="wave" variant="rectangular" width="100vw" height="100vh" />;
    }
    return <Navigate to={keycloakLoginPage} state={{ from: location }} />;
  }
  return <></>;
};
export default memo(RequireAuth);
