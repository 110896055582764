/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import * as yup from 'yup';
import moment from 'moment';
import { DOB_FORMAT } from 'constants/general';
import { t } from 'i18next';
import { csuNameSpace, min, max } from 'utils/utilities';
import { studentIdMaxLength } from 'utils/AcademicHistory.utils';

export const programSelectionSchema = yup.object().shape({
  campusField: yup.string().required(t('campus.error.name.required', csuNameSpace)),
  programField: yup.string().required(t('program.error.name.required', csuNameSpace)),
});

export const communityCollegeSchema = yup.object().shape({
  colleges: yup.array().of(
    yup.object().shape({
      name: yup.string().required(t('colleges.error.name.required')),
      startDate: yup
        .string()
        .required(t('common.error.startDate.required'))
        .test('Validate date', t('error.invalidDate'), value => {
          if (!value) return true;
          /* istanbul ignore next */
          return moment(value, value.split('-').length === 3 ? DOB_FORMAT : 'YYYY-MM', true).isValid();
        })
        .test('Min Date', t('error.minDate'), value => {
          if (value) {
            return new Date(new Date(min).setHours(0)).getTime() < new Date(value).getTime();
          }
          return true;
        })
        .test('Max date', t('error.dateRange'), value => {
          if (value) {
            return new Date(new Date(max).setHours(0)).getTime() >= new Date(value).getTime();
          }
          return true;
        }),
      studentId: yup
        .string()
        .matches(RegExp(/^[0-9a-zA-Z ]*$/gm), t('error.notAllowed'))
        .max(studentIdMaxLength, t('academicHistory.error.StudentId')),
    })
  ),
});
