/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */
import React, { ReactElement, SyntheticEvent, useState } from 'react';
import { InputBaseComponentProps, TextField, inputClasses, autocompleteClasses } from '@mui/material';
import MuiAutocomplete, { AutocompleteCloseReason, AutocompleteProps } from '@mui/material/Autocomplete';
import { defaultGetOptionLabel, TDropdownOption } from '@liaison/liaison-ui';

export interface IAutocompleteProps
  extends Omit<
    AutocompleteProps<TDropdownOption<string>, false, boolean, false, 'div'>,
    'renderInput' | 'onChange' | 'value'
  > {
  inputProps: InputBaseComponentProps;
  label?: string;
  onChange: (value: TDropdownOption<string> | null) => void;
  name: string;
  handleSearch: (searchParam: string) => void;
  handleScroll?: (event: React.SyntheticEvent) => void;
}

const SearchableAutoComplete = ({
  label,
  onChange,
  name,
  handleSearch,
  handleScroll,
  inputProps,
  onClose,
  ...restProps
}: IAutocompleteProps): ReactElement => {
  const [eventKey, setEventKey] = useState('');
  const [open, setOpen] = useState(false);

  const handleChange = (_event: React.SyntheticEvent, item: TDropdownOption<string> | null) => {
    onChange(item);
  };

  const handleOpen = (event: SyntheticEvent) => {
    if (event?.currentTarget?.className?.includes(autocompleteClasses.popupIndicator)) {
      setOpen(true);
    }
  };

  const handleClose = (event: SyntheticEvent, reason: AutocompleteCloseReason) => {
    if (reason === 'blur' && eventKey === 'Tab') {
      return;
    }
    setOpen(false);
    onClose?.(event, reason);
  };
  return (
    <MuiAutocomplete
      onChange={handleChange}
      open={open}
      clearOnBlur={false}
      disablePortal
      fullWidth
      filterOptions={option => option}
      getOptionLabel={defaultGetOptionLabel}
      onInputChange={(event, newInputValue) => {
        setOpen(!!event && newInputValue.length > 0);
        if (event?.type === 'change') handleSearch(newInputValue);
      }}
      onOpen={handleOpen}
      onClose={handleClose}
      renderInput={params => (
        <TextField
          {...params}
          onKeyDown={event => setEventKey(event.key)}
          name={name}
          variant="standard"
          label={label || ''}
          inputProps={{
            ...params.inputProps,
            ...inputProps,
            autoComplete: 'off',
            'aria-autocomplete': 'none',
          }}
          sx={{ [`.${inputClasses.root} .${inputClasses.input}`]: { paddingLeft: '0.5rem' } }}
        />
      )}
      ListboxProps={{
        onScroll: handleScroll,
      }}
      autoHighlight={true}
      {...restProps}
    />
  );
};

export default SearchableAutoComplete;
