/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { FC } from 'react';
import { RadioControl as LCLRadioControl, IRadioControlProps } from '@liaison/liaison-ui';

const getColor = (): IRadioControlProps['color'] => {
  if (localStorage.getItem('tenant') === 'csu') {
    return 'secondary';
  }
  return 'primary';
};

const RadioControl: FC<IRadioControlProps> = (props: IRadioControlProps) => {
  return <LCLRadioControl {...props} color={getColor()} />;
};

export default RadioControl;
