/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import {
  Typography,
  Box,
  Paper,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  FormControl,
  Checkbox,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { IconInfoSquare, Dropdown, Alert } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'transferPlanner/constants/general';
import { useFormContext, Controller } from 'react-hook-form';
import { IFormattedCoursesAndTests, IGenEdCategories } from 'transferPlanner/store/genEdProcess/genEdProcess.slice';
import { selectGenEdCoursesAndTests } from 'transferPlanner/store/genEdProcess/genEdProcess.selectors';

interface IGenerateDropDownProps {
  fieldPrefix: string;
  typeOfAssignment: string;
}

const GenerateDropDown = ({ fieldPrefix, typeOfAssignment }: IGenerateDropDownProps): ReactElement => {
  const { control, setValue, getValues } = useFormContext();
  const { t } = useTranslation(nameSpace);
  const coursesAndTests = useSelector(selectGenEdCoursesAndTests);

  return (
    <FormControl fullWidth>
      <Controller
        render={({ field: { onChange, ...field } }) => {
          return (
            <Dropdown
              {...field}
              id={fieldPrefix}
              options={(coursesAndTests as IFormattedCoursesAndTests[]) || []}
              onChange={(option: IFormattedCoursesAndTests | null) => {
                const formData = getValues(fieldPrefix);
                if (option) {
                  onChange(option.id);
                  const { course, test } = option;
                  let selectedOption;
                  if (course) {
                    selectedOption = { course };
                    delete formData?.test;
                  } else {
                    selectedOption = { test };
                    delete formData?.course;
                  }
                  setValue(`${fieldPrefix}`, { ...formData, ...selectedOption, optOut: false });
                } else {
                  onChange(undefined);
                  delete formData.course;
                  delete formData.test;
                  setValue(`${fieldPrefix}`, { ...formData, optOut: null });
                }
              }}
              inputProps={{
                'aria-label': t('generalEducation.courseAssignment.placeholder'),
                autoComplete: 'off',
                placeholder: t('generalEducation.courseAssignment.placeholder'),
              }}
            />
          );
        }}
        control={control}
        name={`${fieldPrefix}.${typeOfAssignment}.id`}
      />
    </FormControl>
  );
};

interface IGenEdCourseProps {
  assignments: IGenEdCategories[];
  setShowDuplicateError: Dispatch<SetStateAction<boolean>>;
  showDuplicateError: boolean;
}

const CourseAssignment = ({
  assignments,
  showDuplicateError,
  setShowDuplicateError,
}: IGenEdCourseProps): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const validationMessageRef = useRef<null | HTMLDivElement>(null);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const { control, reset, setValue, getValues } = useFormContext();

  useEffect(() => {
    if (assignments) reset({ genEdCategories: assignments });
    return () => {
      reset();
    };
  }, [assignments, reset]);

  useEffect(() => {
    if (showDuplicateError)
      setTimeout(() => validationMessageRef.current?.scrollIntoView({ block: 'nearest', behavior: 'smooth' }), 0);
  }, [showDuplicateError]);

  return (
    <>
      <Box>
        {showDuplicateError && (
          <Box sx={{ mb: 2 }} ref={validationMessageRef}>
            <Alert
              icon
              type="error"
              sx={{ mb: '1rem' }}
              onClose={() => setShowDuplicateError(false)}
              size="default"
              title={t('generalEducation.courseAssignment.invalid')}
            >
              {t('generalEducation.courseAssignment.invalid_text')}
            </Alert>
          </Box>
        )}
        <Alert
          severity="info"
          icon={<IconInfoSquare fontSize="medium" sx={{ bgColor: '#311B92', display: 'flex', alignItem: 'center' }} />}
        >
          <Box>
            <Typography variant="subtitle7">{t('courseAssignment.detail1')}</Typography>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Typography variant="subtitle7">{t('courseAssignment.detail2')}</Typography>
          </Box>
        </Alert>
      </Box>
      <Box sx={{ mt: 2 }}>
        <TableContainer component={Paper} id="MuiTableContainer-root">
          {assignments?.map((config: IGenEdCategories, index) => {
            const currentIndex = index;
            return (
              <Table
                key={`${config?.code}-${currentIndex}`}
                aria-label="table header"
                sx={{ mt: 2, '& .MuiTableCell-root': { fontSize: '0.87rem' } }}
                tabIndex={0}
              >
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#DDE3ED' }}>
                    <TableCell colSpan={3} align="left">
                      {`${config?.code} - ${config?.category}`}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ border: '1px solid #DDE3ED' }}>
                  {(config?.requirements || [])?.map((requirement, i) => {
                    const configurationCategory = config?.category;
                    const categoryName = (
                      requirement?.name ? `${requirement?.code} ${requirement?.name}` : configurationCategory
                    )
                      .split('/')
                      .join('/ ');

                    const typeOfAssignment = requirement?.course ? 'course' : 'test';
                    return (
                      <>
                        <TableRow key={requirement?.code} sx={{ border: '1.5px solid #DDE3ED' }}>
                          <TableCell width="40%" align="left">
                            {categoryName}
                          </TableCell>
                          {!isMobile && (
                            <TableCell width="40%" align="left">
                              <GenerateDropDown
                                fieldPrefix={`genEdCategories.${index}.requirements.${i}`}
                                typeOfAssignment={typeOfAssignment}
                              />
                            </TableCell>
                          )}
                          <TableCell width={isMobile ? '30%' : '20%'} align="left">
                            <FormControl fullWidth>
                              <Box sx={{ display: 'flex', alignItems: 'center', mt: isMobile ? '4px' : '0' }}>
                                <Controller
                                  name={`genEdCategories.${index}.requirements.${i}.optOut`}
                                  control={control}
                                  render={({ field: { onChange, value, ...field } }) => (
                                    <Checkbox
                                      {...field}
                                      checked={value}
                                      onChange={e => {
                                        if (e.target.checked) {
                                          if (getValues(`genEdCategories.${index}.requirements.${i}.course`)) {
                                            setValue(`genEdCategories.${index}.requirements.${i}.course`, {
                                              id: null,
                                            });
                                          }

                                          if (getValues(`genEdCategories.${index}.requirements.${i}.test`)) {
                                            setValue(`genEdCategories.${index}.requirements.${i}.test`, {
                                              id: null,
                                            });
                                          }
                                        }
                                        return onChange(e.target.checked);
                                      }}
                                      inputProps={{
                                        'aria-label': t('opt_out_label'),
                                        role: 'checkbox',
                                      }}
                                    />
                                  )}
                                  defaultValue={false}
                                />
                                <Typography variant="body3">{t('opt_out_label')}</Typography>
                              </Box>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                        {isMobile && (
                          <TableRow key={`mobile-${requirement.code}`} sx={{ border: '1.5px solid #DDE3ED' }}>
                            <TableCell colSpan={3} align="center">
                              <GenerateDropDown
                                fieldPrefix={`genEdCategories.${index}.requirements.${i}`}
                                typeOfAssignment={typeOfAssignment}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            );
          })}
        </TableContainer>
      </Box>
    </>
  );
};

export default memo(CourseAssignment);
