/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */
import { Components, autocompleteClasses, Theme } from '@mui/material';

export default ({ palette }: Theme): Components => ({
  MuiAutocomplete: {
    styleOverrides: {
      popper: {
        zIndex: 1500,
      },
      listbox: {
        [`& .${autocompleteClasses.option}`]: {
          [`&[aria-selected="true"], &.${autocompleteClasses.focused}[aria-selected="true"] `]: {
            backgroundColor: palette.background.offWhite,
            color: palette.text.primary,
          },
        },
      },
      inputRoot: {
        paddingRight: '0 !important',
        width: '100%',
      },
      endAdornment: {
        right: '.25rem',
        top: 'unset',
      },
    },
  },
});
