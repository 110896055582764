/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export default {
  linkingOptions: [
    { val: 'Yes', text: 'Yes, link my accounts (recommended).' },
    {
      val: 'No',
      text: "No, don't link my accounts.",
    },
  ],
};
