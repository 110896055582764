/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export const { GOOGLE_MAP_API_KEY } = window.env;

export const googleMapZoomLevel = 6;

export const markerZoomOffset = {
  3: 30,
  4: 18,
  5: 9,
  6: 4.5,
  7: 2.3,
  8: 1.2,
  9: 0.61,
  10: 0.3,
  11: 0.15,
  12: 0.075,
  13: 0.038,
  14: 0.019,
  15: 0.009,
  16: 0.0045,
  17: 0.0023,
  18: 0.0012,
  19: 0.0006,
  20: 0.0003,
  21: 0.00015,
  22: 0.000074,
} as Record<number, number>;

export const nameSpace = 'tp';

export const quarterTermCommunityCollegeIds = ['004480', '001199', '012907'];

export const skipEnrollmentPages = ['/tsp', '/tsp-enrollment', '/tsp-enrollment-success'];

export const allowedEnrollmentStatuses = ['ENROLLED', 'ENROLLMENT_REVIEWED'];

export const REQUEST_RELEASE_CODE = 'REQUEST_RELEASE';

export const CAMPUS_EVENT_LINK =
  'https://www.calstate.edu/apply/choosing-a-campus/Pages/campus-events.aspx#InplviewHashabd8f3fa-fb0b-487a-acd9-1b4c9e8a73f0=FilterField1%3DCampus-FilterValue1%3DLos%20Angeles';

export const MAX_FOLLOWED_PROGRAMS = 5;

export const INTENDED_TRANSFER_YEAR_LIMIT = 10;

export const TOTAL_UNITS = 60;

export const UNITS_PER_TERM_RANGE = {
  BELOW_HALF_TIME: [1, 3, 5],
  HALF_TIME: [6, 7, 8],
  THREE_QUARTER_TIME: [9, 10, 11],
  FULL_TIME: [12, 13, 15],
};

export const UNITS_VARIENT = {
  min: 0,
  median: 1,
  max: 2,
};
