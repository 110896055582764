/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useMemo, useCallback, useEffect } from 'react';
import { Grid, Box, Typography, FormHelperText, FormControl, InputLabel, Link } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { nameSpace } from 'transferPlanner/constants/general';
import { StyledDivider } from 'pages/Pages.styles';
import { Dropdown } from '@liaison/liaison-ui';
import { selectPrograms, selectProgramLoading } from 'transferPlanner/store/adtPrograms/programs.selectors';
import { resetProgram, Program } from 'transferPlanner/store/adtPrograms/programs.slice';
import {
  selectAdditionalDetails,
  selectAdditionalDetailsLoading,
} from 'transferPlanner/store/additionalDetails/additionalDetails.selectors';
import { type IoptionModified, rangeOfYearsDropDown } from 'utils/utilities';
import { Spinner } from 'components/Spinner';
import { RadioControl } from 'components/RadioControl';
import MasterData from 'userProfile/constants/master';
import { getAcademicHistoryLookUps } from 'utils/commonUtils';
import { selectCollegesLookup } from 'store/common/commonInfo.selectors';
import { getCommunityCollegeOptions, ICommunityCollegeOptions } from 'utils/AcademicHistory.utils';
import { adtInformationDefaultValues } from '../TpAdditionalDetails.validation';
import {
  getProgramOptions,
  getProgramsByCollege,
  enrollmentStatusOptions,
  intendedTransferTermList,
} from '../TpAdditionalDetails.utils';

type TFormErrors = {
  degree: { message: 'string' };
  secondDegree: { message: 'string' };
  academicInformation: {
    intendedTransferTerm: {
      code: { message: 'string' };
    };
    intendedTransferYear: { message: 'string' };
  };
  plannedEnrollmentStatus: {
    message: 'string';
  };
  adtInformation: {
    communityCollege: {
      name: { message: 'string' };
    };
    program: {
      name: { message: 'string' };
    };
  }[];
};

const AcademicInformationForm = (): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const dispatch = useDispatch();

  const { additionalDetails } = useSelector(selectAdditionalDetails);
  const isAdditionalDetailsLoading = useSelector(selectAdditionalDetailsLoading);
  const isProgramLoading = useSelector(selectProgramLoading);
  const programs = useSelector(selectPrograms);
  const programOptions = useMemo(() => getProgramOptions(programs || {}), [programs]);
  const communityColleges = useSelector(selectCollegesLookup);
  const communityCollegeOptions = useMemo(
    () => getCommunityCollegeOptions(communityColleges || []),
    [communityColleges]
  );

  const rangeOfYearsDropDownOptions = useMemo(() => rangeOfYearsDropDown(2024, 2100), []);

  const {
    control,
    formState: { errors: formErrors },
    setValue,
    watch,
    getValues,
  } = useFormContext();

  const { adtInformation } = getValues();
  const hasDegree = watch('degree') === 'Yes';
  const hasSecondDegree = watch('secondDegree') === 'Yes';
  const program1 = watch('adtInformation[0].program.name');
  const program2 = watch('adtInformation[1].program.name');

  let programOptions1 = programOptions?.[0];
  let programOptions2 = programOptions?.[1];

  if (adtInformation?.[0]?.communityCollege?.name === adtInformation?.[1]?.communityCollege?.name) {
    programOptions1 = programOptions?.[0]?.filter(option => option?.name !== program2);
    programOptions2 = programOptions?.[1]?.filter(option => option?.name !== program1);
  }

  const errors = formErrors as unknown as TFormErrors;

  useEffect(() => {
    if (!communityColleges) dispatch(getAcademicHistoryLookUps('college', '/alternateId/Assist'));
  }, [dispatch, communityColleges]);

  useEffect(() => {
    if (additionalDetails) {
      const { adtInformation: adtInfo } = additionalDetails;

      if (communityCollegeOptions?.length && adtInfo?.length) {
        adtInfo.forEach((adt, i) => {
          if (adt?.communityCollege?.name) {
            const communityCollege = communityCollegeOptions?.find(
              college => college.id === adt.communityCollege?.name
            );
            if (communityCollege?.ceeb) {
              dispatch(getProgramsByCollege(`${i}`, communityCollege.ceeb));
            }
          }
        });
      }
    }
  }, [dispatch, additionalDetails, communityCollegeOptions]);

  const handleChangeCollege = useCallback(
    (i: string, option: ICommunityCollegeOptions | null) => {
      dispatch(resetProgram(i));
      if (option?.ceeb) {
        dispatch(getProgramsByCollege(i, option?.ceeb));

        if (option?.alternateId) {
          setValue(`adtInformation[${i}].communityCollege.alternateIds`, [
            { id: option?.alternateId.code, source: option?.alternateId.source },
            { id: option?.ceeb, source: 'CEEB' },
          ]);
        }
      } else {
        setValue(`adtInformation[${i}].communityCollege`, {
          name: '',
          alternateIds: [],
        });
      }
      setValue(`adtInformation[${i}].program`, {
        name: '',
        alternateIds: [],
      });
    },
    [dispatch, setValue]
  );

  const handleChangeProgram = useCallback(
    (i: number, option: Program | null) => {
      if (option?.id) {
        setValue(`adtInformation[${i}].program.alternateIds`, option?.alternateIds);
      } else {
        setValue(`adtInformation[${i}].program`, {
          name: '',
          alternateIds: [],
        });
      }
    },
    [setValue]
  );

  return (
    <>
      {(isProgramLoading || isAdditionalDetailsLoading) && <Spinner backdrop />}
      <Box id="dialog-modal-title">
        <Typography sx={{ mb: 2 }} variant="h1">
          {t('tpAdditionalInfo.additionalInfo')}
        </Typography>
        <Typography sx={{ mb: 2, fontFamily: 'Proxima Nova Regular' }} variant="h2">
          {t('tpAdditionalInfo.academicInfo')}
        </Typography>
        <StyledDivider />
        <Typography sx={{ mt: 2, mb: 2 }} variant="h3">
          {t('tpAdditionalInfo.degreeGoal')}
        </Typography>
        <Typography sx={{ mt: 2, display: 'block' }} variant="subtitle4">
          <Trans
            t={t}
            i18nKey="tpAdditionalInfo.degreeGoal.instructionalText"
            components={{
              adtPageLink: (
                <Link
                  href="https://www.calstate.edu/apply/transfer/pages/ccc-associate-degree-for-transfer.aspx"
                  target="_blank"
                  rel="noopener"
                />
              ),
            }}
          />
        </Typography>
        <Box sx={{ pt: { xs: '1rem', md: '1.25rem' }, ml: '0.5rem' }}>
          <FormControl fullWidth required error={!!errors?.degree}>
            <InputLabel htmlFor="degree" required sx={{ ml: '-0.5rem' }} tabIndex={0}>
              {t(`tpAdditionalInfo.degreeGoal.question`)}
            </InputLabel>
            <Controller
              name="degree"
              render={({ field: { ref, onChange, ...field } }) => (
                <RadioControl
                  {...field}
                  inputRef={ref}
                  id="degree"
                  options={MasterData.radioDefaultOptions}
                  onChange={event => {
                    onChange(event.target.value);
                    if (event.target.value === 'No') {
                      setValue('adtInformation', adtInformationDefaultValues.adtInformation);
                      setValue('secondDegree', 'No');
                    }
                  }}
                />
              )}
              control={control}
            />
            <FormHelperText role="alert" id="degreeError">
              {errors?.degree?.message as string}
            </FormHelperText>
          </FormControl>
        </Box>
        {hasDegree && (
          <>
            <StyledDivider />
            <Typography sx={{ mt: 2, mb: 2 }} variant="subtitle4" tabIndex={0}>
              {t('tpAdditionalInfo.colleges')}
            </Typography>
            <Box sx={{ pt: { xs: '1rem', md: '1.25rem' } }}>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={12} sm={12} md={5.8}>
                  <FormControl fullWidth required error={!!errors?.adtInformation?.[0]?.communityCollege?.name}>
                    <InputLabel htmlFor="communityCollegeField1" tabIndex={0}>
                      {t('tpAdditionalInfo.college.name')}
                    </InputLabel>
                    <Controller
                      render={({ field: { onChange, ...field } }) => (
                        <Dropdown
                          {...field}
                          id="communityCollegeField1"
                          options={communityCollegeOptions}
                          onChange={(option: ICommunityCollegeOptions | null) => {
                            handleChangeCollege('0', option);
                            return onChange(option?.id ?? '');
                          }}
                          inputProps={{
                            'aria-label': `${t('tpAdditionalInfo.college.name')} 1`,
                            'aria-describedby': 'communityCollegeField1Error',
                            autoComplete: 'off',
                          }}
                        />
                      )}
                      control={control}
                      name="adtInformation[0].communityCollege.name"
                    />
                    <FormHelperText role="alert" id="communityCollegeField1Error">
                      {errors?.adtInformation?.[0]?.communityCollege?.name?.message as string}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={5.8} justifyContent="space-between">
                  <FormControl fullWidth required error={!!errors?.adtInformation?.[0]?.program?.name}>
                    <InputLabel htmlFor="programField1" tabIndex={0}>
                      {t('tpAdditionalInfo.college.program')}
                    </InputLabel>
                    <Controller
                      render={({ field: { onChange, ...field } }) => (
                        <Dropdown
                          {...field}
                          id="programField1"
                          options={(programOptions1 as IoptionModified[]) ?? []}
                          onChange={option => {
                            handleChangeProgram(0, option);
                            return onChange(option?.id ?? '');
                          }}
                          inputProps={{
                            'aria-label': `${t('program_label')} 1`,
                            'aria-describedby': 'programField1Error',
                            autoComplete: 'off',
                          }}
                        />
                      )}
                      control={control}
                      name="adtInformation[0].program.name"
                    />
                    <FormHelperText role="alert" id="programField1Error">
                      {errors?.adtInformation?.[0]?.program?.name?.message as string}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ pt: { xs: '1rem', md: '1.25rem' }, ml: '0.5rem' }}>
              <FormControl fullWidth required error={!!errors?.secondDegree}>
                <InputLabel htmlFor="secondDegree" required sx={{ ml: '-0.5rem' }} tabIndex={0}>
                  {t(`tpAdditionalInfo.colleges.second`)}
                </InputLabel>
                <Controller
                  name="secondDegree"
                  render={({ field: { ref, onChange, ...field } }) => (
                    <RadioControl
                      {...field}
                      inputRef={ref}
                      id="secondDegree"
                      options={MasterData.radioDefaultOptions}
                      onChange={event => {
                        onChange(event.target.value);
                        if (event.target.value === 'No') {
                          setValue('adtInformation', [
                            adtInformation[0],
                            { ...adtInformationDefaultValues.adtInformation?.[1] },
                          ]);
                        }
                      }}
                    />
                  )}
                  control={control}
                />
                <FormHelperText role="alert" id="secondDegreeError">
                  {errors?.secondDegree?.message as string}
                </FormHelperText>
              </FormControl>
            </Box>
            {hasSecondDegree && (
              <Box sx={{ pt: { xs: '1rem', md: '1.25rem' } }}>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item xs={12} sm={12} md={5.8}>
                    <FormControl fullWidth required error={!!errors?.adtInformation?.[1]?.communityCollege?.name}>
                      <InputLabel htmlFor="communityCollegeField2" tabIndex={0}>{`${t(
                        'tpAdditionalInfo.college2.name'
                      )}`}</InputLabel>
                      <Controller
                        render={({ field: { onChange, ...field } }) => (
                          <Dropdown
                            {...field}
                            id="communityCollegeField2"
                            options={communityCollegeOptions}
                            onChange={(option: ICommunityCollegeOptions | null) => {
                              handleChangeCollege('1', option);
                              return onChange(option?.id ?? '');
                            }}
                            inputProps={{
                              'aria-label': `${t('tpAdditionalInfo.college.name')} 2`,
                              'aria-describedby': 'communityCollegeField2Error',
                              autoComplete: 'off',
                            }}
                          />
                        )}
                        control={control}
                        name="adtInformation[1].communityCollege.name"
                      />
                      <FormHelperText role="alert" id="communityCollegeField2Error">
                        {errors?.adtInformation?.[1]?.communityCollege?.name?.message as string}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5.8}>
                    <FormControl fullWidth required error={!!errors?.adtInformation?.[1]?.program?.name}>
                      <InputLabel htmlFor="programField2" tabIndex={0}>{`${t(
                        'tpAdditionalInfo.college2.program'
                      )}`}</InputLabel>
                      <Controller
                        render={({ field: { onChange, ...field } }) => (
                          <Dropdown
                            {...field}
                            id="programField2"
                            options={(programOptions2 as IoptionModified[]) ?? []}
                            onChange={option => {
                              handleChangeProgram(1, option);
                              return onChange(option?.id ?? '');
                            }}
                            inputProps={{
                              'aria-label': `${t('program_label')} 2`,
                              'aria-describedby': 'programField2Error',
                              autoComplete: 'off',
                            }}
                          />
                        )}
                        control={control}
                        name="adtInformation[1].program.name"
                      />
                      <FormHelperText role="alert" id="programField2Error">
                        {errors?.adtInformation?.[1]?.program?.name?.message as string}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        )}
        <StyledDivider />
        <Typography sx={{ mt: 2, mb: 2 }} variant="h3">
          {t('tpAdditionalInfo.plannedEnrollmentStatus')}
        </Typography>
        <Box sx={{ pt: { xs: '1rem', md: '1.25rem' }, ml: '0.5rem' }}>
          <InputLabel required sx={{ ml: '-0.5rem' }} tabIndex={0}>
            {t(`tpAdditionalInfo.plannedEnrollmentStatus.ques1`)}
          </InputLabel>
          <Grid container spacing={1} sx={{ ml: -2 }}>
            <Grid item xs={6} md={6}>
              <FormControl fullWidth required error={!!errors?.academicInformation?.intendedTransferTerm?.code}>
                <Controller
                  render={({ field: { ref, onChange, ...field } }) => (
                    <Dropdown
                      id="intendedTransferTerm"
                      {...field}
                      inputRef={ref}
                      options={intendedTransferTermList}
                      onChange={option => {
                        setValue(`academicInformation.intendedTransferTerm.displayName`, option?.text);
                        return onChange(option?.id ?? '');
                      }}
                      inputProps={{
                        'aria-label': t(`tpAdditionalInfo.intendedtransferTerm`),
                        'aria-describedby': 'term-error',
                        autoComplete: 'off',
                      }}
                    />
                  )}
                  control={control}
                  name="academicInformation.intendedTransferTerm.code"
                />
                <FormHelperText role="alert" id="term-error">
                  {errors?.academicInformation?.intendedTransferTerm?.code?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl fullWidth required error={!!errors?.academicInformation?.intendedTransferYear}>
                <Controller
                  render={({ field: { ref, onChange, ...field } }) => (
                    <Dropdown
                      id="intendedTransferYear"
                      {...field}
                      inputRef={ref}
                      value={field.value ? `${field.value}` : field.value}
                      options={rangeOfYearsDropDownOptions}
                      onChange={option => {
                        return onChange(option?.id ?? '');
                      }}
                      inputProps={{
                        'aria-label': t('tpAdditionalInfo.intendedtransferYear'),
                        'aria-describedby': 'intendedTransferYear-error',
                        autoComplete: 'off',
                      }}
                    />
                  )}
                  control={control}
                  name="academicInformation.intendedTransferYear"
                />
                <FormHelperText role="alert" id="intendedTransferYear-error">
                  {errors?.academicInformation?.intendedTransferYear?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ ml: '0.5rem' }}>
          <FormControl fullWidth required error={!!errors?.plannedEnrollmentStatus}>
            <InputLabel required sx={{ ml: '-0.5rem' }} tabIndex={0}>
              {t(`tpAdditionalInfo.plannedEnrollmentStatus.question`)}
            </InputLabel>
            <Controller
              name="plannedEnrollmentStatus.code"
              render={({ field: { ref, onChange, ...field } }) => (
                <RadioControl
                  {...field}
                  inputRef={ref}
                  id="plannedEnrollmentStatus"
                  options={enrollmentStatusOptions}
                  onChange={event => {
                    const enrollmentStatusOption = enrollmentStatusOptions.find(
                      option => option.val === event.target.value
                    );
                    setValue('plannedEnrollmentStatus.displayName', enrollmentStatusOption?.displayName);
                    return onChange(event.target.value);
                  }}
                />
              )}
              control={control}
            />
            <FormHelperText role="alert" id="EnrollmentStatusError">
              {errors?.plannedEnrollmentStatus?.message as string}
            </FormHelperText>
          </FormControl>
        </Box>
      </Box>
    </>
  );
};

export default memo(AcademicInformationForm);
