/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import moment from 'moment';
import { ITerm } from 'store/academicHistory/academicHistory.slice';
import { IRelationship, TBiographicalInfoField } from 'userProfile/store/biographicalInfo/biographicalInfo.slice';
import { IAddress, TPersonalInfoField } from 'userProfile/store/personalInfo/personalInfo.slice';
import { displayDate } from 'utils/utilities';
import type { TAcademicGoalsInfoField } from 'userProfile/store/academicInfo/academicInfo.slice';

interface IObject {
  code: string;
  displayName: string;
}

export interface IResponseDetails {
  [key: string]: {
    prop: string | IObject | undefined | null;
  };
}

export interface ICombainedInterface extends TBiographicalInfoField, TPersonalInfoField, TAcademicGoalsInfoField {}
export interface IFields {
  visible: boolean;
  label: string;
  identifier: string;
  addExtraField?: string;
}

export interface IFieldDetails {
  fieldDetails: {
    [key: string]: IFields;
  };
  responseData: ICombainedInterface;
  cardView?: boolean | null | undefined;
  toggleDrawer?: () => void;
  openChildDrawer?: (isAddNew: boolean, childPosition: number, parentSelector: string, formTitle: string) => void;
  selector: string;
  formTitle: string;
  openGrandChildDrawer?: (isAddNew: boolean, childPosition: number, parentSelector: string) => void;
  localeKey?: string;
}

const parseDataFromObject = (response: IResponseDetails, path: string): string => {
  const tokens = path?.split('.');
  let val = Object(response)[tokens[0]];
  if (tokens.length < 2) return val;
  if (val) {
    for (let i = 1; i < tokens.length; i += 1) {
      val = val?.[tokens[i]] ?? '';
    }
  }

  return val;
};

export const getFieldValue = (response: IResponseDetails, path: string): string => {
  if (Array.isArray(path)) {
    return path.map(pathVal => parseDataFromObject(response, pathVal)).join(' ');
  }

  return parseDataFromObject(response, path);
};
interface ISchemaField {
  identifier: string;
  dependant: {
    [key: string]: string;
  };
}
export const hideDependantFields = (response: IResponseDetails, schemaField: ISchemaField): boolean => {
  const dependantKey = Object.keys(schemaField?.dependant)[0];
  if (dependantKey) {
    const fieldValue = getFieldValue(response, dependantKey);
    if (fieldValue !== schemaField?.dependant[dependantKey]) {
      return true;
    }
  }
  return false;
};

export const getRelationshipSubTitle = (relationship: IRelationship): string => {
  let subTitle = '';
  if (relationship.relationship) {
    subTitle += relationship.relationship.displayName;
  }
  if (relationship.gender?.legalSex) {
    subTitle += subTitle ? ` · ${relationship.gender?.legalSex.displayName}` : relationship.gender?.legalSex;
  }
  if (relationship.living) {
    const valString: string = relationship.living === 'Yes' ? 'Living' : 'Demise';
    subTitle += subTitle ? ` · ${valString}` : `${valString}`;
  }
  return subTitle;
};

export const getRelationshipResidency = (relationship: IRelationship): string => {
  let residency = '';
  if (relationship?.address?.country?.code) {
    residency += relationship?.address?.country?.code;
  }
  if (relationship?.address?.region?.displayName) {
    residency += residency
      ? ` · ${relationship?.address?.region?.displayName}`
      : relationship?.address?.region?.displayName;
  }
  if (relationship?.address?.county?.displayName) {
    residency += residency
      ? ` · ${relationship?.address?.county?.displayName}`
      : relationship?.address?.county?.displayName;
  }
  return residency;
};

export const getAddressString = (address: IAddress, type?: string): string => {
  let addressString = '';
  if (type === 'line1') {
    if (address.address1) {
      addressString += address.address1;
    }
    if (address.address2) {
      addressString += addressString ? ` , ${address.address2}` : address.address2;
    }
  } else if (type === 'line2') {
    if (address?.city) {
      addressString += address.city;
    }
    if (address?.region?.displayName) {
      addressString += addressString ? ` , ${address?.region?.displayName}` : address?.region?.displayName;
    }
    if (address?.postalCode) {
      addressString += addressString ? ` ${address?.postalCode}` : address?.postalCode;
    }
  } else if (type === 'line3') {
    if (address?.county?.displayName) {
      addressString += address?.county?.displayName;
    }
    if (address?.country?.displayName) {
      addressString += addressString ? ` , ${address?.country?.displayName}` : address?.country?.displayName;
    }
  } else if (type === 'line4') {
    if (address?.city) {
      addressString += address.city;
    }
    if (address?.region?.displayName) {
      addressString += addressString ? ` , ${address?.region?.displayName}` : address?.region?.displayName;
    }
  } else {
    if (address?.city) {
      addressString += address.city;
    }
    if (address?.region?.displayName) {
      addressString += addressString ? ` , ${address?.region?.displayName}` : address?.region?.displayName;
    }
    if (address?.country?.displayName) {
      addressString += addressString ? ` , ${address?.country?.displayName}` : address?.country?.displayName;
    }
  }
  return addressString;
};
export const dateDifference = (startDate: string | undefined, endDate: string | undefined): string => {
  const end = endDate ? new Date(endDate) : new Date();
  const start = moment(startDate);
  const lastDayOfEndDate = new Date(end.getFullYear(), end.getMonth() + 1, 1);

  const years = moment(lastDayOfEndDate).diff(start, 'year');
  start.add(years, 'years');

  const months = moment(lastDayOfEndDate).diff(start, 'months');
  start.add(months, 'months');

  return `${years} ${years > 1 ? 'yrs' : 'yr'} ${months} ${months > 1 ? 'mos' : 'mo'} `;
};
export const displayDateRange = (startDate: string | undefined, endDate: string | undefined): string => {
  let stringDate = '';

  if (startDate) {
    stringDate += displayDate(startDate, 'MMM YYYY');
  }
  if (endDate) {
    stringDate += ` - ${displayDate(endDate, 'MMM YYYY')}, `;
  } else {
    stringDate += ` · Present, `;
  }
  stringDate += dateDifference(startDate, endDate);
  return stringDate;
};

export const findNoneGradeFromTerm = (terms: ITerm[] | undefined): ITerm | undefined =>
  terms?.find((term: ITerm) => {
    if (term?.completionStatus?.code === 'COMPLETED') {
      const firstMatchingGrade = term?.courses?.findIndex(course => course.grade === 'None');
      if (firstMatchingGrade !== -1) return true;
    }
    return false;
  });
