/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, SetStateAction, useEffect, useState, useMemo, useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import invert from 'lodash.invert';
import heic2any from 'heic2any';
import {
  Button,
  Grid,
  InputBase,
  FormControl,
  FormHelperText,
  Typography,
  InputLabel,
  LinearProgress,
  Switch,
} from '@mui/material';
import { DocumentThumbnail, FileDropzone, SidePanel, Textarea } from '@liaison/liaison-ui';

import { selectMediaDocs, selectSelectedMediaDoc } from 'userProfile/store/mediaDocuments/mediaDocuments.selectors';
import { DISABLE_AUTO_FILL, MAX_HUGE_LENGTH_FIELD, FIELD_LENGTH_100 } from 'constants/field';
import { IMediaDoc, TSectionName, setSelectedMediaDoc } from 'userProfile/store/mediaDocuments/mediaDocuments.slice';
import { sxSidePanel } from 'pages/Pages.styles';
import { setUi } from 'store/ui/ui.slice';
import { setTitle } from 'utils/commonUtils';
import { getButtonColor, getCheckboxColor, resourceMap } from 'utils/utilities';
import { RadioControl } from 'components/RadioControl';
import { TAppThunk } from 'redux/store';
import { appleFormats } from 'userProfile/constants/general';
import { validationSchema, defaultValues, EFileSource, fileSourceOptions } from './MediaDocumentForm.validation';
import {
  IFileRejection,
  acceptedFileFormats,
  deleteMediaDoc,
  fileValidator,
  getMediaDoc,
  getMediaDocs,
  getThumbUrl,
  initiateUploadMediaDoc,
  mediaTypes,
  postFileUrl,
  updateMediaDoc,
  updateMediaDocUploadStatus,
  uploadMediaDoc,
  uploadStatus,
  ErrorCode,
  MB,
} from './MediaDocuments.utils';
import LearnMore from './LearnMore';

type TMediaDocumentsFormProps = {
  isFormOpen: boolean;
  setIsFormOpen: (value: SetStateAction<boolean>) => void;
  isNewFile: boolean;
  sectionName?: TSectionName;
  tag?: string;
};

const MediaDocumentForm = ({
  isFormOpen,
  setIsFormOpen,
  isNewFile,
  sectionName,
  tag,
}: TMediaDocumentsFormProps): ReactElement => {
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isLearnMoreOpen, setIsLearnMoreOpen] = useState(false);
  const dispatch = useDispatch();
  const mediaDocs = useSelector(selectMediaDocs);
  const selectedMediaDoc = useSelector(selectSelectedMediaDoc) as IMediaDoc;
  const { t } = useTranslation();
  const abortController = useRef(null) as unknown as { current: AbortController };
  const isSectionIdExist = tag?.split('/').pop() !== 'undefined';
  const sectionParentName = tag?.split('/').shift() as string;
  const methods = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(validationSchema, { abortEarly: false })(...args),
    defaultValues,
  });
  const {
    register,
    control,
    watch,
    reset,
    formState: { errors, isValid },
    handleSubmit,
  } = methods;

  const name = watch('name');
  const isNameUnique = useMemo(
    () =>
      mediaDocs
        ?.filter(doc => (isNewFile ? doc.name : doc.name?.toLowerCase() !== selectedMediaDoc?.name?.toLowerCase()))
        .every(doc => doc.name?.toLowerCase() !== name?.toLowerCase()) ?? true,
    [isNewFile, mediaDocs, name, selectedMediaDoc?.name]
  );

  useEffect(() => {
    if (!isNewFile && selectedMediaDoc) {
      reset({
        /* istanbul ignore next */
        fileSource: !selectedMediaDoc.externalURL ? EFileSource.UPLOAD : EFileSource.URL,
        name: selectedMediaDoc.name,
        description: selectedMediaDoc.description,
        shareable: selectedMediaDoc.shareable,
        externalURL: selectedMediaDoc.externalURL,
      });
    }
  }, [selectedMediaDoc, isNewFile, reset]);

  useEffect(() => {
    if (isFormOpen) {
      setTitle(t(isNewFile ? 'mediaDocuments.addFile' : 'mediaDocuments.editFile'));
    }
  }, [isFormOpen, isNewFile, t]);

  useEffect(() => {
    abortController.current = new AbortController();
    return () => abortController.current.abort();
  }, [isFormOpen]);

  const LearnMoreButton = () => (
    <Button
      onClick={() => {
        setIsLearnMoreOpen(true);
      }}
      size="large"
      sx={{ textTransform: 'none', color: '#0D47A1 !important' }}
    >
      {t('mediaDocuments.fileUpload.learnMore')}
    </Button>
  );

  const closeForm = () => {
    setIsFormOpen(false);
    dispatch(setSelectedMediaDoc(null));
    setIsFileUploading(false);
    reset({});
  };

  const onClose = () => {
    closeForm();
    if (isNewFile && selectedMediaDoc) {
      abortController.current.abort();
      dispatch(deleteMediaDoc(selectedMediaDoc?.id as string));
    }
  };

  const dispatchErrorSnackbar = (title: string, message: string, action: ReactElement) => {
    dispatch(
      setUi({
        name: 'errorSnackbar',
        state: {
          open: true,
          title,
          message,
          action,
        },
      })
    );
  };

  const dispatchSuccessSnackbar = (message: string, hideTitle: boolean) => {
    dispatch(
      setUi({
        name: 'succesSnackbar',
        state: { open: true, message, hideTitle },
      })
    );
  };

  const cancelFileUpload = () => {
    dispatch(setSelectedMediaDoc(null));
    setIsFileUploading(false);
    setTimeout(() => {
      setIsFormOpen(false);
    }, 500);
  };

  const handleInitiateFileUpload = async (acceptedFiles: File[], fileRejections: IFileRejection[]) => {
    if (fileRejections?.length) {
      const fileRejection = fileRejections[0].errors[0];
      const errorMessage =
        fileRejection.code === ErrorCode.FileInvalidType
          ? /* istanbul ignore next */
            t('mediaDocuments.fileUpload.error.fileType', { fileName: fileRejections[0].file.name })
          : fileRejection.message;

      dispatchErrorSnackbar(t('mediaDocuments.fileUpload.error'), errorMessage, <LearnMoreButton />);
      return;
    }

    let fileToUpload = acceptedFiles[0];
    setIsFileUploading(true);
    if (appleFormats.includes(acceptedFiles[0].type)) {
      /* istanbul ignore next */
      const quality = acceptedFiles[0].type === appleFormats[1] ? 0.45 : 0.88;
      const blob = (await heic2any({ blob: acceptedFiles[0], toType: 'image/jpeg', quality })) as Blob;
      /* istanbul ignore next */
      fileToUpload = new File([blob], `${acceptedFiles[0].name.split('.').shift()}.jpeg`, {
        type: blob.type,
      });
    }
    const extension = fileToUpload?.name?.split('.').pop();
    /* istanbul ignore next */
    const contentType = fileToUpload.type || invert(acceptedFileFormats)[`.${extension}`];

    const payload = {
      fileName: fileToUpload.name,
      size: fileToUpload.size,
      contentType,
      mediaType: mediaTypes[contentType.split('/')[1]],
    };

    dispatch(
      initiateUploadMediaDoc(
        payload,
        (id, ephemeralURL) => {
          dispatch(
            uploadMediaDoc(
              fileToUpload,
              ephemeralURL,
              () => {
                dispatch(
                  updateMediaDocUploadStatus(
                    id,
                    uploadStatus.SUCCESS,
                    () => {
                      dispatch(
                        getMediaDoc(
                          abortController.current.signal,
                          () => {
                            setIsFileUploading(false);
                          },
                          cancelFileUpload
                        )
                      );
                    },
                    cancelFileUpload
                  )
                );
              },
              () => {
                dispatch(updateMediaDocUploadStatus(id, uploadStatus.FAILED));
                cancelFileUpload();
              }
            )
          );
        },
        cancelFileUpload
      )
    );
  };

  const addFile = (
    payload: IMediaDoc,
    method: (
      payload: IMediaDoc,
      sectionName?: TSectionName,
      shouldPostpone?: boolean,
      successCallback?: () => void,
      failureCallback?: () => void
    ) => TAppThunk
  ) => {
    dispatch(
      method(payload, sectionName, !isSectionIdExist, () => {
        dispatch(
          getMediaDocs(() => {
            dispatchSuccessSnackbar(t('mediaDocuments.fileUpload.success'), true);
          })
        );
      })
    );
    closeForm();
  };

  const editFile = (payload: IMediaDoc) => {
    dispatch(
      updateMediaDoc({ ...payload, id: selectedMediaDoc?.id }, undefined, false, () => {
        dispatch(
          getMediaDocs(() => {
            dispatchSuccessSnackbar(t('success_message'), true);
          })
        );
      })
    );
    closeForm();
  };

  const isUpload = watch('fileSource') !== EFileSource.URL;

  const onSubmit = (formData: Partial<typeof defaultValues>) => {
    // eslint-disable-next-line no-unused-vars
    const { fileSource, ...restFormData } = formData;
    if (isUpload) {
      delete restFormData.externalURL;
    }
    if (isNewFile) {
      abortController.current.abort();
      const payload = {
        ...restFormData,
        ...(tag ? { tag, resource: resourceMap[sectionParentName] } : {}),
      };
      const method = isUpload ? updateMediaDoc : postFileUrl;
      addFile(payload, method);
    } else {
      editFile(restFormData);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <SidePanel
            size="small"
            open={isFormOpen}
            onClose={onClose}
            title={isNewFile ? t('mediaDocuments.addFile') : t('mediaDocuments.editFile')}
            isBackdropClickEnabled={true}
            footerButtonConfig={{
              primary: {
                title: isNewFile ? t('mediaDocuments.addFile') : t('save_label'),
                props: {
                  'aria-label': isNewFile ? t('mediaDocuments.addFile') : t('save_label'),
                  variant: 'contained',
                  disabled: !isValid || !isNameUnique || (isNewFile && isUpload && !selectedMediaDoc),
                  color: getButtonColor(),
                  onClick: handleSubmit(onSubmit),
                },
              },
              tertiary: {
                title: t('cancel_label'),
                props: {
                  'aria-label': t('cancel_label'),
                  color: getButtonColor(),
                  onClick: onClose,
                },
              },
            }}
            sx={sxSidePanel}
          >
            <Grid container rowSpacing={2}>
              <Grid item container xs={12} rowSpacing={2} alignItems="center">
                <Grid item xs={12}>
                  <Controller
                    name="fileSource"
                    render={({ field: { ref, onChange, ...field } }) => (
                      <RadioControl
                        {...field}
                        inputRef={ref}
                        id="fileSource"
                        options={fileSourceOptions}
                        onChange={event => {
                          onChange(event.target.value);
                        }}
                        inline
                        disabled={!isNewFile || (isNewFile && isUpload && Boolean(selectedMediaDoc)) || isFileUploading}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      />
                    )}
                    control={control}
                    defaultValue={EFileSource.UPLOAD}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2" component="span">
                    {t('mediaDocuments.fileUpload.instruction')}
                  </Typography>
                  <LearnMoreButton />
                </Grid>
                {isUpload && (
                  <Grid item xs={12}>
                    {!selectedMediaDoc && (
                      <FileDropzone
                        acceptedFileFormats={acceptedFileFormats}
                        handleChange={handleInitiateFileUpload}
                        customValidator={fileValidator}
                        noClick={isFileUploading}
                      />
                    )}
                    {isFileUploading && <LinearProgress sx={{ my: 2 }} />}
                    {selectedMediaDoc && !selectedMediaDoc.externalURL && (
                      <DocumentThumbnail
                        type={
                          selectedMediaDoc?.extension ||
                          (selectedMediaDoc?.contentType &&
                            acceptedFileFormats[selectedMediaDoc.contentType][0].split('/').pop())
                        }
                        /* istanbul ignore next */
                        size={selectedMediaDoc.sizeDisplay || `${((selectedMediaDoc?.size ?? 0) / MB).toFixed(2)} MB`}
                        imageUrl={getThumbUrl(selectedMediaDoc.variants)}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} {...(isUpload ? { display: 'none' } : {})}>
                <FormControl fullWidth required error={Boolean(errors?.externalURL)}>
                  <InputLabel>URL</InputLabel>
                  <InputBase
                    placeholder={t('mediaDocuments.fileUpload.url.placeholder')}
                    fullWidth
                    inputProps={{
                      'aria-label': `${t('mediaDocuments.fileUpload.addUrlField')} ${t(
                        'mediaDocuments.fileUpload.url.placeholder'
                      )}`,
                      'aria-describedby': 'url-helper',
                      ...DISABLE_AUTO_FILL,
                    }}
                    {...register('externalURL')}
                  />
                  <FormHelperText error={false} id="url-helper">
                    {t('mediaDocuments.fileUpload.url.helper')}
                  </FormHelperText>
                  <FormHelperText role="alert" id="url-error">
                    {errors?.externalURL?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <>
                <FormControl fullWidth required error={!!errors?.name || !isNameUnique}>
                  <InputLabel htmlFor="name" sx={{ mt: 1 }}>
                    {t('mediaDocuments.name')}
                  </InputLabel>
                  <InputBase
                    placeholder={t('mediaDocuments.name')}
                    inputProps={{
                      'aria-label': t('mediaDocuments.name'),
                      'aria-describedby': 'name-error',
                      maxLength: FIELD_LENGTH_100,
                      ...DISABLE_AUTO_FILL,
                    }}
                    {...register('name')}
                    fullWidth={true}
                  />
                  <FormHelperText role="alert" id="name-error">
                    {
                      ((!isNameUnique && t('mediaDocuments.fileUpload.error.name.exists')) ||
                        errors?.name?.message) as string
                    }
                  </FormHelperText>
                </FormControl>
                <Controller
                  name="description"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth required error={!!errors?.description}>
                      <InputLabel htmlFor="description">{t('mediaDocuments.description')}</InputLabel>
                      <Textarea
                        {...field}
                        error={!!error}
                        id="description"
                        maxChars={MAX_HUGE_LENGTH_FIELD}
                        inputProps={{
                          'aria-label': t('mediaDocuments.description'),
                          maxLength: MAX_HUGE_LENGTH_FIELD,
                          maxRows: 8,
                        }}
                      />
                      <FormHelperText role="alert">{errors?.description?.message}</FormHelperText>
                    </FormControl>
                  )}
                  control={control}
                  defaultValue=""
                />
                <Grid container spacing={1} sx={{ mb: 2 }}>
                  <Grid item sm={9}>
                    <Typography variant="subtitle4">{t('mediaDocuments.fileVisibility')}</Typography>
                    <FormHelperText sx={{ whiteSpace: 'initial' }} role="alert" id="fileVisibility-helperText">
                      {t('mediaDocuments.fileVisibility.helperText')}
                    </FormHelperText>
                  </Grid>
                  <Grid item sm={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Controller
                      control={control}
                      name="shareable"
                      defaultValue={false}
                      render={({ field: { onChange, value, ...field } }) => {
                        return (
                          <Switch
                            color={getCheckboxColor()}
                            {...field}
                            checked={value}
                            onChange={(_event, val) => {
                              return onChange(val);
                            }}
                            inputProps={{
                              'aria-label': 'make file public',
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            </Grid>
          </SidePanel>
        </form>
      </FormProvider>
      <LearnMore isOpen={isLearnMoreOpen} setIsOpen={setIsLearnMoreOpen} />
    </>
  );
};

export default memo(MediaDocumentForm);
