/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Components, Theme, Palette, switchClasses, iconButtonClasses } from '@mui/material';

const populateIconColors = (palette: Palette) => {
  const classMap = {
    [iconButtonClasses.colorPrimary]: palette.primary,
    [iconButtonClasses.colorSecondary]: palette.secondary,
    'MuiIconButton-colorInfo': palette.info,
    'MuiIconButton-colorSuccess': palette.success,
    'MuiIconButton-colorWarning': palette.warning,
    'MuiIconButton-colorError': palette.error,
  };
  return Object.entries(classMap).reduce(
    (rules, [className, color]) => ({
      ...rules,
      [`&.${className}`]: {
        color: color.main,
      },
    }),
    {}
  );
};

export default ({ palette }: Theme): Components => ({
  MuiSwitch: {
    defaultProps: {
      color: 'secondary',
    },
    styleOverrides: {
      root: {
        padding: 0,
        width: '3.5rem',
        height: '1.75rem',
      },
      switchBase: {
        padding: '0.188rem',
        [`&.${switchClasses.checked}`]: {
          color: palette.common.white,
          transform: 'translateX(1.75rem)',
          [`+.${switchClasses.track}`]: {
            opacity: 1,
            ...populateIconColors(palette),
          },
          [`&.${switchClasses.disabled}`]: {
            color: palette.grey[100],
            [`+.${switchClasses.track}`]: {
              backgroundColor: '#66a9c6',
            },
          },
        },
        [`&.:not(.${switchClasses.checked})`]: {
          [`&.${switchClasses.disabled}`]: {
            [`+.${switchClasses.track}`]: {
              backgroundColor: palette.grey[300],
              opacity: 1,
            },
          },
        },
        [`&.Mui-focusVisible .MuiSwitch-thumb`]: {
          color: palette.secondary.main,
          border: '6px solid #fff',
        },
      },
      thumb: {
        width: '1.375rem',
        height: '1.375rem',
      },
      track: {
        width: '3.5rem',
        height: '1.75rem',
        borderRadius: '1rem',
      },
    },
  },
});
