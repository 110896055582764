/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useMemo, useState, useCallback, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  FormHelperText,
  FormControl,
  InputLabel,
  useMediaQuery,
  Theme,
  Button,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { nameSpace } from 'transferPlanner/constants/general';
import { StyledDivider, sxPrimaryButton } from 'pages/Pages.styles';
import { Dropdown } from '@liaison/liaison-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCampuses } from 'transferPlanner/store/campuses/campuses.selectors';
import { selectPrograms, selectProgramLoading } from 'transferPlanner/store/programs/programs.selectors';
import { resetProgram } from 'transferPlanner/store/programs/programs.slice';
import { RESEARCH_CAMPUS_AND_PROGRAMS } from 'transferPlanner/constants/routeNames';
import { useFirstRenderStatus } from 'hooks/useFirstRenderStatus';
import { Spinner } from 'components/Spinner';
import { type IoptionModified, formatGPA } from 'utils/utilities';
import { sanitize } from 'dompurify';
import { EXTENDED_SANITIZE_CONFIG } from 'constants/sanitize';
import { programSelectionSchema } from '../TspEnrollment.validation';
import {
  getCampusOptions,
  ICampusOptionModified,
  getProgramOptions,
  IProgramOptionModified,
  fetchProgramById,
  ICustomizedState,
} from '../TspEnrollment.utils';
import { useFormData } from '../ContextForm';
import { getProgramsByCampus, IPrograms } from '../../CampusesAndPrograms/CampusesAndPrograms.utils';

const ProgramSelection = (): ReactElement => {
  const location = useLocation();
  const firstRender = useFirstRenderStatus();
  const enrollmentStateValues = localStorage.getItem('enrollStates') || '{}';
  const parsedEnrollmentState = JSON.parse(enrollmentStateValues) as ICustomizedState;
  const storedEnrollState = Object.keys(parsedEnrollmentState).length ? parsedEnrollmentState : '';
  const enrollmentData = location?.state || storedEnrollState;
  const [programCleared, setProgramCleared] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isSetCampusChange, setIsSetCampusChange] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState<IPrograms | {}>({});
  const { t } = useTranslation(nameSpace);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const campusesData = useSelector(selectCampuses);
  const programsList = useSelector(selectPrograms);
  const isProgramLoading = useSelector(selectProgramLoading);
  const { setFormValues, data } = useFormData();
  const programsOptions = useMemo(() => getProgramOptions(programsList || []), [programsList]);
  const campusesOptions = useMemo(() => getCampusOptions(campusesData || []), [campusesData]);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isExtraSmall = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  /* istanbul ignore next */
  useEffect(() => {
    if (location?.state) {
      localStorage.setItem('enrollStates', JSON.stringify(enrollmentData));
    }
  }, [location, enrollmentData]);

  /* istanbul ignore next */
  const filterSeletedProgram = useCallback(
    (id: number) => {
      if (id) {
        setSelectedProgram(fetchProgramById(id, programsList || []));
      } else {
        setSelectedProgram({});
      }
    },
    [programsList]
  );

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(programSelectionSchema, { abortEarly: false })(...args),
  });

  useEffect(() => {
    if (
      !programCleared &&
      selectedProgram &&
      Object.keys(selectedProgram).length === 0 &&
      data &&
      data?.formData?.program?.id &&
      !isSetCampusChange
    ) {
      reset(data);
      filterSeletedProgram(data?.formData?.program?.id);
      setFormValues({ ...data, enabled: true });
    }
  }, [data, selectedProgram, filterSeletedProgram, reset, setFormValues, isSetCampusChange, setValue, programCleared]);

  const handleCampusChange = useCallback(
    (option: ICampusOptionModified | null) => {
      setIsSetCampusChange(true);
      setFormValues({ enabled: false });
      dispatch(resetProgram());
      localStorage.removeItem('enrollStates');
      location.state = null;
      window.history.replaceState(null, document.title);
      setValue('program', null);
      setValue('programField', null);
      setSelectedProgram({});
      setValue('campus', option);
      if (option?.id) dispatch(getProgramsByCampus(option?.id));
    },
    [dispatch, setFormValues, setValue, location]
  );

  const handleProgramChange = useCallback(
    (option: IProgramOptionModified | null) => {
      setValue('program', option);
      setIsSetCampusChange(false);
      if (option?.id) {
        setValue('programField', option?.id);
        filterSeletedProgram(option?.id);
        const { campusField, programField, campus, program } = getValues();
        const collegeAddress = campus?.address || data?.collegeAddress;
        let selectedCampus = campus;
        /* istanbul ignore next */
        if (!campus) selectedCampus = data?.formData?.campus;
        delete campus?.text;
        delete campus?.address;
        delete program?.text;
        /* istanbul ignore next */
        const selectedItem = fetchProgramById(option.id, programsList || []);
        const transferRequirements = {
          overallGPA: (selectedItem as IPrograms)?.minGPA,
          majorPreReqGPA: (selectedItem as IPrograms)?.majorPreReqGPA,
          minGrade: (selectedItem as IPrograms)?.minGrade,
          requirements: (selectedItem as IPrograms)?.requirements,
        };
        const modifiedData = {
          formData: {
            campus: selectedCampus,
            program,
            transferRequirements,
          },
          campusField,
          programField,
          collegeAddress,
          moKey: (selectedItem as IPrograms)?.moKey,
        };
        setFormValues({ ...data, ...modifiedData, enabled: true });
      } else {
        setValue('program', null);
        setValue('programField', null);
        setFormValues({ ...data, enabled: false });
        setSelectedProgram({});
        setProgramCleared(true);
      }
    },
    [data, filterSeletedProgram, setFormValues, setValue, getValues, programsList]
  );

  useEffect(() => {
    if (enrollmentData && firstRender) dispatch(getProgramsByCampus(enrollmentData?.campus?.id));
  }, [dispatch, enrollmentData, firstRender]);

  useEffect(() => {
    if (enrollmentData && programsOptions?.length && campusesOptions?.length && isFirstLoading) {
      setIsFirstLoading(false);
      const currentCampus =
        campusesOptions?.find((campus: ICampusOptionModified) => campus.id === enrollmentData?.campus?.id) || null;
      setValue('campus', currentCampus);
      setValue('campusField', enrollmentData?.campus?.id);
      const currentProgram =
        programsOptions?.find((program: IProgramOptionModified) => program.id === enrollmentData?.programId) || null;
      handleProgramChange(currentProgram);
    }
  }, [enrollmentData, programsOptions, setValue, campusesOptions, handleProgramChange, isFirstLoading]);

  return (
    <>
      {isProgramLoading && <Spinner backdrop />}
      <Box>
        <Typography sx={{ mb: 2 }} variant="h1">
          {t('programSelection.title')}
        </Typography>
        <Typography variant="body2">{t('programSelection.description')}</Typography>
        <StyledDivider />
        {!Object.keys(selectedProgram).length && (
          <>
            <Typography variant="body2">
              {t('programSelection.info1')}{' '}
              <Link style={{ color: '#0076A8' }} to={`/${RESEARCH_CAMPUS_AND_PROGRAMS}`}>
                {t('exploreUniversitiesPrograms')}
              </Link>{' '}
              {t('programSelection.info2')}
            </Typography>
            <Button
              sx={{ mt: 2, ...sxPrimaryButton }}
              variant="contained"
              onClick={() => navigate(`/${RESEARCH_CAMPUS_AND_PROGRAMS}`)}
              aria-label={t('exploreUniversitiesPrograms')}
            >
              {t('exploreUniversitiesPrograms')}
            </Button>
            <StyledDivider />
          </>
        )}
        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs={12} sm={12} md={5.8}>
            <FormControl fullWidth required error={!!errors?.campusField}>
              <InputLabel htmlFor={t('campus')}>{t('programSelection.campus')}</InputLabel>
              <Controller
                render={({ field: { onChange, ...field } }) => (
                  <Dropdown
                    {...field}
                    id={t('campus')}
                    options={campusesOptions}
                    onChange={(option: ICampusOptionModified | null) => {
                      handleCampusChange(option);
                      return onChange(option?.id ?? '');
                    }}
                    inputProps={{
                      'aria-label': t('campus'),
                      autoComplete: 'off',
                    }}
                  />
                )}
                control={control}
                name="campusField"
              />
              <FormHelperText role="alert" id="campusFieldError">
                {errors?.campusField?.message as string}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={5.8}>
            <FormControl fullWidth required error={!!errors?.programField}>
              <InputLabel htmlFor={t('program_label')}>{t('program_label')}</InputLabel>
              <Controller
                render={({ field: { onChange, ...field } }) => (
                  <Dropdown
                    id={t('program_label')}
                    {...field}
                    options={programsOptions as unknown as IoptionModified[]}
                    onChange={option => {
                      handleProgramChange(option);
                      return onChange(option?.id ?? '');
                    }}
                    inputProps={{
                      'aria-label': t('program_label'),
                      autoComplete: 'off',
                    }}
                  />
                )}
                control={control}
                name="programField"
              />
              <FormHelperText role="alert" id="programFieldError">
                {errors?.programField?.message as string}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        {selectedProgram && Object.keys(selectedProgram).length !== 0 && (
          <Grid container spacing={2} direction="row" justifyContent="space-between">
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12}>
                <Typography variant="subtitle3">{t('generalEducationRequirements.title')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">{t('generalEducationRequirements.requirements1')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">{t('generalEducationRequirements.requirements2')}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ mt: 2, mb: 2 }} variant="subtitle6">
                {t('campusesAndProgram.transferRequirements')}
              </Typography>
              <Grid sx={{ mt: 1 }} justifyContent="space-between" container spacing={1}>
                <Grid item xs={10}>
                  <Typography variant="body2">{t('campusesAndProgram.overallGpa')}</Typography>
                </Grid>
                <Grid item xs={2}>
                  {formatGPA((selectedProgram as IPrograms)?.minGPA) || '-'}
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body2">{t('campusesAndProgram.majorGpa')}</Typography>
                </Grid>
                <Grid item xs={2}>
                  {formatGPA((selectedProgram as IPrograms)?.majorPreReqGPA) || '-'}
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body2">{t('campusesAndProgram.minimumGrade')}</Typography>
                </Grid>
                <Grid item xs={2}>
                  {(selectedProgram as IPrograms)?.minGrade?.displayName || '-'}
                </Grid>
                <Grid item xs={10} sx={{ mt: -1 }}>
                  <Typography variant="body2">{t('programSelection.condition')}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={5.8}>
              {(isMobile || isExtraSmall) && <StyledDivider />}
              <Typography sx={{ mt: 2 }} variant="subtitle6">
                {t('otherInformation_label')}
              </Typography>
              <Typography
                sx={{ mt: 2 }}
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: sanitize((selectedProgram as IPrograms)?.requirements || '-', EXTENDED_SANITIZE_CONFIG),
                }}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default memo(ProgramSelection);
