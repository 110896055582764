/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Box, Card, CardContent, Grid, Stack, Typography, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconAlertTriangleColor, IconCheckCircleColor, IconEdit, Icon } from '@liaison/liaison-ui';

import type { ICollegeCourse } from 'store/academicHistory/academicHistory.slice';
import { nameSpace } from 'transferPlanner/constants/general';
import { isCsuTenant, truncate } from 'utils/utilities';
import { sxCard } from 'pages/Pages.styles';

type TCourseCardProps = {
  course: ICollegeCourse;
  isEditable?: boolean;
  onClick?: () => void;
};

export const CourseCard = ({
  course: { code, title, credits, grade, source },
  isEditable = false,
  onClick,
}: TCourseCardProps): ReactElement => {
  const { t } = useTranslation(nameSpace);

  return (
    <Card role="listitem" variant="outlined" sx={{ ...sxCard, my: 1, color: 'text.primary' }}>
      <CardContent
        sx={{
          py: 1,
          '&:last-child': {
            pb: 1,
          },
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={isEditable ? 11 : 12} container>
            <Grid item xs={12}>
              <Typography variant="subtitle7">
                {truncate(code, 10)} • {truncate(title, 40)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="column" alignItems="flex-start" spacing={1}>
                <Stack direction="row" alignItems="center" spacing={1.5}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="caption" sx={{ pr: 0.5 }}>
                      {t('colleges.course.credits')}
                    </Typography>
                    <Typography variant="subtitle8" sx={{ mt: '0.1rem' }}>
                      {credits || ''}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="caption" sx={{ pr: 0.5 }}>
                      {t('colleges.course.grade')}
                    </Typography>
                    {grade === 'None' ? (
                      <Icon
                        data-testid="alert-critical-icon"
                        aria-label="No Grade Selected"
                        sx={{ color: theme => theme.palette.error.main, fontSize: 13, ml: 1 }}
                      >
                        IconAlertCritical
                      </Icon>
                    ) : (
                      <Typography variant="subtitle8">{grade}</Typography>
                    )}
                  </Box>
                </Stack>
                {isCsuTenant() && (
                  <Stack direction="row" alignItems="flex-start" spacing={0.5}>
                    {source?.code === 'ASSIST' ? (
                      <IconCheckCircleColor style={{ fontSize: 13 }} />
                    ) : (
                      <IconAlertTriangleColor style={{ fontSize: 13 }} />
                    )}
                    <Typography variant="caption">
                      {source?.code === 'ASSIST'
                        ? t('colleges.course.source.assist')
                        : t('colleges.course.source.manual')}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Grid>
          </Grid>
          {isEditable && (
            <Grid item xs={1} container>
              <IconButton aria-label={`Edit ${title}`} data-testid="edit-course-icon" onClick={onClick}>
                <IconEdit style={{ fontSize: 14 }} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
