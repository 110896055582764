/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect, useState, useMemo } from 'react';
import { Divider, Grid, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from 'utils/commonUtils';
import { useNavigate } from 'react-router-dom';
import { useFirstRenderStatus } from 'hooks/useFirstRenderStatus';
import { TP_ADDITIONAL_DETAILS } from 'transferPlanner/constants/routeNames';
import { useTranslation, Trans } from 'react-i18next';
import { UNITS_PER_TERM_RANGE, nameSpace } from 'transferPlanner/constants/general';
import { Icon } from '@liaison/liaison-ui';
import {
  selectTimelineInfo,
  selectTimelineInfoLoading,
} from 'transferPlanner/store/timelineInfo/timelineInfo.selectors';
import { useTransferTimelineService } from 'hooks/useTransferTimelineService';
import Scale from 'transferPlanner/components/Scale';
import { setCalculation } from 'transferPlanner/store/timelineCalculation/timelineCalculation.slice';
import { selectCalculator } from 'transferPlanner/store/timelineCalculation/timelineCalculation.selectors';
import { Spinner } from 'components/Spinner';
import { TimelineProfileInfoPanel } from './TimelineProfileInfoPanel';
import {
  getTransferCalcBase,
  findTspAgreementDeadLine,
  findTransferStatus,
  formatTransferDateValue,
  findIntendedTransferKeyByValue,
  formatUnitValue,
} from './TimelineCalculator.utils';

const TimelineCalculator = (): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const firstRender = useFirstRenderStatus();
  const [sidePanelInformation, setSidePanelInformation] = useState<{
    isSidePanelOpen: boolean;
    title: string;
  }>({
    isSidePanelOpen: false,
    title: '',
  });

  const isMobileOrTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const calculatorBase = useSelector(selectTimelineInfo);
  const transferInfoLoading = useSelector(selectTimelineInfoLoading);
  const calculatorValues = useSelector(selectCalculator);
  const [intendedTransferDate, setIntendedTransferDate] = useState(
    formatTransferDateValue(calculatorValues?.intentedTransferTerm || 0)
  );
  const unitsCompleted = calculatorBase?.transferGPA?.unitsCompleted || 0;
  const { readyToTransfer, readyToApply } = useTransferTimelineService({
    unitsCompleted,
    unitsPerSemester: calculatorValues?.plannedEnrollUnit || 1,
    unitsPerSummer: calculatorValues?.summerEnrollUnit || 0,
  });

  const tspAgreementDeadLine = useMemo(() => findTspAgreementDeadLine(calculatorBase), [calculatorBase]);
  const transferStatus = useMemo(
    () => findTransferStatus(readyToTransfer, intendedTransferDate, tspAgreementDeadLine),
    [readyToTransfer, intendedTransferDate, tspAgreementDeadLine]
  );

  useEffect(() => {
    if (firstRender) {
      setTitle(t('generalEducation.timelineCalculator'));
      dispatch(getTransferCalcBase());
    }
  }, [firstRender, t, dispatch]);

  useEffect(() => {
    if (
      calculatorBase &&
      (!calculatorValues || Object.keys(calculatorValues)?.length < 3 || calculatorValues?.intentedTransferTerm === -1)
    ) {
      const enrollmentStatus = (calculatorBase?.additionalDetails?.plannedEnrollmentStatus?.code ||
        '') as keyof typeof UNITS_PER_TERM_RANGE;
      const unitsPerSem =
        calculatorValues?.plannedEnrollUnit || UNITS_PER_TERM_RANGE[enrollmentStatus]
          ? UNITS_PER_TERM_RANGE[enrollmentStatus][1]
          : 1;
      const intendedTransferData = `${calculatorBase?.additionalDetails?.intendedTransferTerm?.displayName} ${calculatorBase?.additionalDetails?.intendedTransferYear}`;
      setIntendedTransferDate(intendedTransferData);
      const intendedTransferVal =
        calculatorValues?.intentedTransferTerm || findIntendedTransferKeyByValue(intendedTransferData);

      dispatch(
        setCalculation({
          intentedTransferTerm: intendedTransferVal,
          plannedEnrollUnit: unitsPerSem,
          summerEnrollUnit: 0,
        })
      );
    }
  }, [calculatorBase, calculatorValues, dispatch]);

  const openSidePanel = (title: string) => {
    setSidePanelInformation(prevState => ({
      ...prevState,
      isSidePanelOpen: true,
      title,
    }));
    setTitle(title);
  };

  const closeSidePanel = () => {
    setSidePanelInformation({
      ...sidePanelInformation,
      isSidePanelOpen: false,
    });
    setTitle(t('generalEducation.timelineCalculator'));
  };

  const updateCalculatorData = (position: string, value: number) => {
    dispatch(setCalculation({ ...calculatorValues, [position]: value }));
  };

  return (
    <>
      {transferInfoLoading && <Spinner backdrop />}
      <TimelineProfileInfoPanel
        sidePanelInformation={sidePanelInformation}
        closeSidePanel={closeSidePanel}
        profileInfo={calculatorBase}
      />
      <Grid container spacing={0.5} direction="column">
        <Grid item>
          <Typography component="h1" variant="h3">
            {t('generalEducation.timelineCalculator')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            <Trans
              t={t}
              i18nKey="transferCalculator.tag"
              components={{
                link1: (
                  <Typography
                    tabIndex={0}
                    aria-label="open timeline info"
                    variant="subtitle3"
                    component="span"
                    onClick={() => openSidePanel(t('timelineProfileInfo.title'))}
                    sx={{
                      textDecoration: 'underline',
                      color: theme => theme.palette.secondary.main,
                      cursor: 'pointer',
                    }}
                  />
                ),
                link2: (
                  <Typography
                    tabIndex={0}
                    aria-label={t('transferCalculator.redirectToAdditiDetails')}
                    variant="subtitle5"
                    component="span"
                    onClick={() => navigate(`/${TP_ADDITIONAL_DETAILS.path}`)}
                    sx={{
                      textDecoration: 'underline',
                      color: theme => theme.palette.secondary.main,
                      cursor: 'pointer',
                    }}
                  />
                ),
              }}
            />
          </Typography>
        </Grid>
        <Grid item>
          <Divider sx={{ borderStyle: 'solid', mt: '.5rem', mb: '1rem' }} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={12} md={9}>
          <Stack
            alignItems="center"
            sx={{ borderRadius: 2, border: theme => `1px solid ${theme.palette.divider}`, p: 1 }}
          >
            <Stack
              aria-live="assertive"
              role="alert"
              direction={isMobileOrTablet ? 'column' : 'row'}
              alignItems="center"
              sx={{ gap: isMobileOrTablet ? '2px' : 0 }}
            >
              <Typography component="span" sx={{ mr: 0.5 }} variant="subtitle2">
                {t('transferCalculator.readyToTransfer_label')}
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  color: theme =>
                    transferStatus?.isReadyToTransfer ? theme.palette.success.dark : theme.palette.warning.dark,
                }}
              >
                {readyToTransfer}*
              </Typography>
            </Stack>
            <Typography variant="body2" sx={{ mt: 0.5 }}>
              {t('transferCalculator.intendedTransferDate')}{' '}
              {formatTransferDateValue(calculatorValues?.intentedTransferTerm || 0)}
            </Typography>
            <Divider sx={{ borderStyle: 'solid', width: '100%', mt: '.5rem', mb: '.5rem' }} />
            <Grid container spacing={0.5} alignItems="center" justifyContent="center">
              <Grid item sm={12} md={5.5}>
                <Typography variant="body3" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {t('transferCalculator.getReadyToApply')}
                  <Typography
                    component="span"
                    variant="h5"
                    sx={{
                      ml: 0.5,
                      color: theme => theme.palette.text.primary,
                    }}
                  >
                    {readyToApply}
                  </Typography>
                </Typography>
              </Grid>
              {isMobileOrTablet ? (
                <Divider sx={{ borderStyle: 'solid', width: '100%', mt: '.5rem', mb: '.5rem' }} />
              ) : (
                <Grid item md={1}>
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{ fontSize: 38, color: theme => theme.palette.divider }}
                  >
                    |
                  </Stack>
                </Grid>
              )}
              <Grid item sm={12} md={5.5}>
                <Stack alignItems="center" direction="row" justifyContent="center">
                  <Icon sx={{ mr: 1 }}>
                    {transferStatus?.successflag ? 'IconCheckCircleColor' : 'IconAlertTriangleColor'}
                  </Icon>
                  <Typography aria-live="polite" role="alert" component="span" variant="body3">
                    {transferStatus?.msg}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid item sm={12} md={9} sx={{ width: '100%' }}>
          <Grid container spacing={2} direction={isMobileOrTablet ? 'column' : 'row'}>
            <Grid item sm={12} md={6}>
              <Stack
                alignItems="center"
                direction="row"
                sx={{ borderRadius: 4, border: theme => `1px solid ${theme.palette.divider}`, p: 2 }}
              >
                <Icon
                  sx={{
                    mr: 1,
                    bgcolor: '#F4C9CF',
                    color: theme => theme.palette.primary.border,
                    p: 0.5,
                  }}
                >
                  IconTransfer
                </Icon>
                <Typography component="span" variant="subtitle4">
                  {t('transferCalculator.transferUnitsComplted')}:
                </Typography>
                <Typography component="span" variant="subtitle4" sx={{ ml: 'auto' }}>
                  {calculatorBase?.transferGPA?.unitsCompleted || 0}
                </Typography>
              </Stack>
            </Grid>
            <Grid item sm={12} md={6}>
              <Stack
                alignItems="center"
                direction="row"
                sx={{ borderRadius: 4, border: theme => `1px solid ${theme.palette.divider}`, p: 2 }}
              >
                <Icon
                  sx={{
                    mr: 1,
                    bgcolor: theme => theme.palette.info.background,
                    color: theme => theme.palette.info.border,
                    p: 0.5,
                  }}
                >
                  IconConditionsSolid
                </Icon>
                <Typography component="span" variant="subtitle4">
                  {t('transferCalculator.tspAgreementDeadLine')}:
                </Typography>
                <Typography component="span" variant="subtitle4" sx={{ ml: 'auto' }}>
                  {tspAgreementDeadLine}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          md={9}
          sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Grid container direction="column" sx={{ width: isMobile ? '86%' : '100%' }}>
            <Grid item>
              <Scale
                title={t('transferCalculator.intendedtransferTerm')}
                min={0}
                step={1}
                max={19}
                tooltipText={t('transferCalculator.intendedTransferTooltipText')}
                formatValue={formatTransferDateValue}
                getAriaValueText={formatTransferDateValue}
                valueLabelFormat={formatTransferDateValue}
                valueLabelDisplay="auto"
                initialValue={calculatorValues?.intentedTransferTerm || 0}
                color="secondary"
                onScaleChange={selectedValue => {
                  setIntendedTransferDate(formatTransferDateValue(selectedValue));
                  updateCalculatorData('intentedTransferTerm', selectedValue);
                }}
                aria-label={t('transferCalculator.intendedtransferTerm')}
              />
            </Grid>
            <Grid item>
              <Scale
                title={
                  isMobile ? t('transferCalculator.planToEnrollMobileLabel') : t('transferCalculator.planToEnroll')
                }
                min={1}
                step={1}
                max={20}
                tooltipText={t('transferCalculator.planToEnrollTooltipText')}
                formatValue={formatUnitValue}
                getAriaValueText={formatUnitValue}
                valueLabelFormat={formatUnitValue}
                valueLabelDisplay="auto"
                initialValue={calculatorValues?.plannedEnrollUnit || 1}
                color="secondary"
                onScaleChange={selectedValue => {
                  updateCalculatorData('plannedEnrollUnit', selectedValue);
                }}
                aria-label={t('transferCalculator.planToEnroll')}
              />
            </Grid>
            <Grid item>
              <Scale
                title={
                  isMobile
                    ? t('transferCalculator.planToEnrollSummerMobileLabel')
                    : t('transferCalculator.planToEnrollSummer')
                }
                min={0}
                step={1}
                max={8}
                tooltipText={t('transferCalculator.planToEnrollSummerTooltipText')}
                formatValue={formatUnitValue}
                getAriaValueText={formatUnitValue}
                valueLabelFormat={formatUnitValue}
                valueLabelDisplay="auto"
                initialValue={calculatorValues?.summerEnrollUnit || 0}
                color="secondary"
                onScaleChange={selectedValue => {
                  updateCalculatorData('summerEnrollUnit', selectedValue);
                }}
                aria-label={t('transferCalculator.planToEnrollSummer')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={9} sm={12}>
          <Stack direction="column" sx={{ gap: 1 }}>
            <Typography component="span" variant="body3">
              {t('transferCalculator.footerNotes1')}
            </Typography>
            <Typography component="span" variant="body3">
              {t('transferCalculator.footerNotes2')}
            </Typography>
            <Typography component="span" variant="body3">
              {t('transferCalculator.footerNotes3')}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default TimelineCalculator;
