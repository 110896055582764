/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  InputLabel,
  Box,
  Switch,
  Typography,
  Checkbox,
  Button,
  useMediaQuery,
  Theme,
  Divider,
  IconButton,
  Badge,
} from '@mui/material';
import {
  Search,
  IconRevert,
  IconCaretDownSolid,
  IconCaretUpSolid,
  IconFilter,
  IconCaretDownDouble,
} from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { nameSpace } from 'transferPlanner/constants/general';
import { selectCampuses } from 'transferPlanner/store/campuses/campuses.selectors';
import { selectCampusIds, selectIsEligible } from 'transferPlanner/store/searchProgram/searchProgram.selectors';
import {
  setSelectedCampusIds,
  toggleIsEligible,
  setIsEligible,
} from 'transferPlanner/store/searchProgram/searchProgram.slice';
import { ICampus } from 'transferPlanner/pages/CampusesAndPrograms/CampusesAndPrograms.utils';
import { isCsuTenant } from 'utils/utilities';
import { searchDebounced } from 'transferPlanner/pages/CampusesAndPrograms/Programs/ProgramSearchHeader';

const setSelectedCampusIdsDebounced = debounce((campusIds, dispatch) => {
  dispatch(setSelectedCampusIds(campusIds));
}, 100);
interface IProgramFiltersProps {
  setIsSwipe?: (isSwipe: boolean) => void;
  isSwipe?: boolean;
}

const LIMIT_CAMPUS_COLLAPSED = 5;

const ProgramFilters = ({ setIsSwipe, isSwipe }: IProgramFiltersProps): ReactElement => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(nameSpace);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const campuses = useAppSelector(selectCampuses);
  const [campusList, setCampusList] = useState(campuses || []);
  const [searchValue, setSearchValue] = useState('');
  const [searchProgramText, setSearchProgramText] = useState('');

  const [isSeeMoreOpen, setIsSeeMoreOpen] = useState(false);
  const [collapsibleButtonSettings, setCollapsibleButtonSettings] = useState({
    title: t('see_more_label'),
    icon: <IconCaretDownSolid />,
  });

  const campusIds = useAppSelector(selectCampusIds);
  const isEligible = useAppSelector(selectIsEligible);

  const totalFilterCount = useMemo(() => {
    return campusIds.length + (searchProgramText !== '' ? 1 : 0) + (searchValue !== '' ? 1 : 0) + (isEligible ? 1 : 0);
  }, [campusIds, isEligible, searchProgramText, searchValue]);

  useEffect(() => {
    setCollapsibleButtonSettings(
      isSeeMoreOpen
        ? {
            title: t('see_less_label'),
            icon: <IconCaretUpSolid />,
          }
        : {
            title: t('see_more_label'),
            icon: <IconCaretDownSolid />,
          }
    );
  }, [isSeeMoreOpen, t]);

  useEffect(() => {
    if (campuses && campusIds.length) {
      const newListState: ICampus[] = campuses.map(campus => {
        if (campusIds.includes(campus.id)) {
          return { ...campus, checked: true };
        }
        return campus;
      });
      setCampusList(newListState);
    }
  }, [campusIds, campuses]);

  const selectHandler = (campusId: number) => {
    const campusSelectedIds = [...campusIds];
    setSelectedCampusIdsDebounced([...campusSelectedIds, campusId], dispatch);
  };

  const unselectHandler = (campusId: number) => {
    const campusSelectedIds = campusIds.filter(id => id !== campusId);
    setSelectedCampusIdsDebounced(campusSelectedIds, dispatch);
  };

  const searchHandler = (value: string) => {
    const newSearchResults = campuses
      ?.filter(campus => campus.name.toLowerCase().includes(value.toLowerCase()))
      .map(campus => {
        if (campusIds.includes(campus.id)) {
          return { ...campus, checked: true };
        }
        return campus;
      });
    setCampusList(newSearchResults || []);
    setSearchValue(value);
  };

  const switchEligibleFlag = () => {
    dispatch(toggleIsEligible());
  };

  const onChangeCheckboxHandler = (event: React.ChangeEvent<HTMLInputElement>, selectedCampus: ICampus) => {
    const newListState: ICampus[] = campusList.map(campus => {
      if (campus.id === selectedCampus.id) {
        return { ...campus, checked: event.target.checked };
      }

      return campus;
    });
    setCampusList(newListState);
    if (event.target.checked) {
      selectHandler(selectedCampus.id);
    } else {
      unselectHandler(selectedCampus.id);
    }
  };

  const resetFilters = () => {
    dispatch(setIsEligible(false));
    dispatch(setSelectedCampusIds([]));
    setCampusList(campuses || []);
    setSearchValue('');
    setSearchProgramText('');
  };

  const searchProgram = (value: string) => {
    searchDebounced(value, dispatch);
    setSearchProgramText(value);
  };

  return (
    <Grid container>
      <Grid sx={{ width: '100%', p: { xs: '0 1rem 1rem 1rem', sm: '1rem' }, mb: '1rem' }}>
        {!isMobile && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="h2" sx={{ pb: '1.5rem' }}>
              {t('programs.filters.title')}
            </Typography>
            <Button
              variant="text"
              startIcon={<IconRevert />}
              color={isCsuTenant() ? 'error' : 'secondary'}
              sx={{ textDecoration: 'none' }}
              onClick={() => resetFilters()}
              disabled={totalFilterCount === 0}
            >
              {t('reset')}
            </Button>
          </Box>
        )}
        <Grid item flexDirection="column" sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, mb: '1rem' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Search
              expanded
              searchOffset={1}
              value={searchProgramText}
              searchHandler={searchProgram}
              placeholder={t('programs.filters.search_placeholder')}
              inputProps={{ 'aria-label': 'search programs; Search result updated' }}
              sx={{
                width: '100%',
              }}
            />
            <IconButton aria-label="program filter" onClick={() => setIsSwipe?.(!isSwipe)}>
              {isSwipe ? (
                <IconCaretDownDouble />
              ) : (
                <Badge color="error" badgeContent={totalFilterCount}>
                  <IconFilter />
                </Badge>
              )}
            </IconButton>
          </Box>

          <Divider
            sx={{
              borderStyle: 'solid',
              borderColor: '#A6A6A6',
              mt: '1rem',
            }}
          />
        </Grid>

        <InputLabel sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          {t('programs.filters.eligible')}
          <Switch name="eligible-checkbox" checked={isEligible} onChange={() => switchEligibleFlag()} />
        </InputLabel>
        <Typography variant="body1" sx={{ fontWeight: 'bold', my: '0.5rem' }}>
          {t('programs.filters.universities')}
        </Typography>
        <Search
          fullWidth
          expanded
          searchOffset={1}
          searchHandler={searchHandler}
          value={searchValue}
          sx={{ mb: '0.5rem' }}
        />
        <Grid sx={{ width: '100%', overflowY: 'auto', maxHeight: '50vh' }}>
          <ul style={{ listStyleType: 'none', padding: '0', margin: '0', width: '100%' }}>
            {campusList?.slice(0, isSeeMoreOpen ? campusList.length : LIMIT_CAMPUS_COLLAPSED).map(campus => (
              <li key={campus.id}>
                <InputLabel id={`${campus.id}-campus-checkbox`} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    name={`${campus.id}-campus-checkbox`}
                    checked={campus.checked || false}
                    onChange={event => onChangeCheckboxHandler(event, campus)}
                    sx={{ my: '0.25rem' }}
                  />
                  <Typography variant="body2" title={campus.name}>
                    {campus.name}
                  </Typography>
                </InputLabel>
              </li>
            ))}
          </ul>
          {campusList?.length > LIMIT_CAMPUS_COLLAPSED && (
            <Button
              variant="text"
              endIcon={collapsibleButtonSettings.icon}
              color="error"
              sx={{ textDecoration: 'none', ml: '0.5rem' }}
              onClick={() => setIsSeeMoreOpen(!isSeeMoreOpen)}
            >
              {collapsibleButtonSettings.title}
            </Button>
          )}
        </Grid>
      </Grid>
      {isMobile && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            position: 'absolute',
            bottom: '0px',
            borderTop: '1px solid #CAD2E1',
            p: '0.5rem',
            background: '#ECECEC',
          }}
        >
          <Button
            variant="text"
            color={isCsuTenant() ? 'error' : 'secondary'}
            sx={{ textDecoration: 'none' }}
            startIcon={<IconRevert />}
            onClick={() => resetFilters()}
            disabled={totalFilterCount === 0}
          >
            {t('reset')}
          </Button>
          <Button variant="contained" color="primary" onClick={() => setIsSwipe?.(false)}>
            {t('done')}
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default ProgramFilters;
