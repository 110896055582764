import { ReactElement, Ref, useEffect, useRef } from 'react';

type TUseToggleTabIndex = {
  elementRef: ReactElement | Ref<HTMLDivElement> | null | undefined;
};

const useToggleTabIndex = (nextTabIndex = 0): TUseToggleTabIndex => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      (elementRef?.current?.tabIndex || elementRef?.current?.tabIndex === 0) &&
      elementRef?.current?.tabIndex !== nextTabIndex
    ) {
      elementRef.current.tabIndex = nextTabIndex;
    }
  }, [nextTabIndex]);
  return { elementRef };
};

export default useToggleTabIndex;
