/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useMemo, useEffect } from 'react';
import { Box, Typography, FormControl, InputLabel, InputBase } from '@mui/material';
import { RadioControl } from 'components/RadioControl';
import { Controller, useFormContext } from 'react-hook-form';
import MasterData from 'userProfile/constants/master';
import type { TBiographicalInfoField } from 'userProfile/store/biographicalInfo/biographicalInfo.slice';
import { StyledFormHelperText } from 'pages/Pages.styles';
import { useTranslation } from 'react-i18next';
import type { TBiographicFormErrors } from '../BiographicalInformationForm.validation';

interface IBiographicalInfoProps {
  data: TBiographicalInfoField | null;
}

const BackgroundInformation = ({ data }: IBiographicalInfoProps): ReactElement => {
  const {
    control,
    reset,
    setValue,
    register,
    formState: { errors: formErrors },
  } = useFormContext();
  const errors = formErrors as unknown as TBiographicFormErrors;
  const groupName = 'background';
  const answerOptions = useMemo(() => MasterData?.answerOptions, []);
  const { t } = useTranslation();
  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [reset, data]);

  return (
    <Box sx={{ mt: '2rem' }}>
      <Typography
        variant="h2"
        sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px', fontSize: '1.125rem' }}
      >
        {t('backgroundInfo.title')}
      </Typography>
      <FormControl fullWidth>
        <InputLabel htmlFor="firstGenerationCollegeStudent">
          {t('backgroundInfo.firstGenerationCollegeStudent')}
        </InputLabel>

        <Controller
          name={`${groupName}.firstGenerationCollegeStudent.code`}
          render={({ field: { ref, ...field } }) => (
            <RadioControl
              {...field}
              inputRef={ref}
              id="firstGenerationCollegeStudent"
              options={answerOptions}
              inline={true}
              onClick={event => {
                let displayName;
                if ((event.target as HTMLInputElement).value === 'YES') {
                  displayName = 'Yes';
                } else {
                  displayName = 'No';
                }
                setValue(`${groupName}.firstGenerationCollegeStudent.displayName`, displayName);
              }}
              error={!!errors?.[groupName]?.firstGenerationCollegeStudent?.code?.message}
              aria-describedby="firstGenerationCollegeStudent-error"
            />
          )}
          control={control}
          defaultValue=""
        />
        <StyledFormHelperText role="alert" id="firstGenerationCollegeStudent-error">
          {errors?.[groupName]?.firstGenerationCollegeStudent?.code?.message}
        </StyledFormHelperText>
      </FormControl>
      <InputBase
        inputProps={{ type: 'hidden' }}
        {...register(`${groupName}.firstGenerationCollegeStudent.displayName`)}
      />
    </Box>
  );
};

export default BackgroundInformation;
