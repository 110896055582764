/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';

import { MultiValuedView } from 'userProfile/components/ViewBuilder/CardView';
import { sxSectionsItem } from '../LivingProfile.styles';

type TSimpleSectionProps = {
  sectionTitle: string;
  pills?: string[];
};

export const SimpleSection = ({ sectionTitle, pills }: TSimpleSectionProps): ReactElement => {
  return (
    <Box sx={{ ...sxSectionsItem, px: 2, py: 3 }}>
      <Stack spacing={2}>
        <Typography variant="h2" sx={{ pl: 1 }}>
          {sectionTitle}
        </Typography>
        <Grid container spacing={1}>
          <Grid item>
            <MultiValuedView multiValued={pills} />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};
