/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ICollege } from 'store/academicHistory/academicHistory.slice';

type TuseCollegeIndex = {
  newCollegeIndex: number;
  setNewCollegeIndex: Dispatch<SetStateAction<number>>;
  setNewCollege: Dispatch<SetStateAction<ICollege | undefined>>;
};

export const useCollegeIndex = (collegeList: ICollege[] | null | undefined): TuseCollegeIndex => {
  const [newCollege, setNewCollege] = useState<ICollege>();
  const [newCollegeIndex, setNewCollegeIndex] = useState<number>(-1);

  useEffect(() => {
    if (collegeList && collegeList?.length && newCollege) {
      setNewCollegeIndex(
        collegeList.findIndex(
          college =>
            college.name === newCollege.name &&
            college.ceeb === newCollege.ceeb &&
            college.termType?.code === newCollege.termType?.code
        )
      );
    }
  }, [collegeList, newCollege]);

  return { newCollegeIndex, setNewCollegeIndex, setNewCollege };
};
