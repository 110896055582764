/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { memo, ReactElement, useEffect, useState, useMemo } from 'react';
import { Grid, Typography, Box, FormControl, InputLabel, InputBase, FormHelperText } from '@mui/material';
import { TAccomplishmentsAndExperienceField } from 'userProfile/store/accomplishmentAndExperience/accomplishmentAndExperience.slice';
import { useFormContext, Controller, FormProvider, useForm } from 'react-hook-form';
import { StyledAddButton, StyledDeleteButton, StyledFormHelperText, sxSidePanel } from 'pages/Pages.styles';
import { ExperienceList } from 'userProfile/components/ViewBuilder/CardView';
import {
  DISABLE_AUTO_FILL,
  FIELD_LENGTH_100,
  FIELD_LENGTH_200,
  MAX_MEDIUM_LENGTH_FIELD,
  TEXT_AREA_LENGTH_FIELD,
} from 'constants/field';
import { DateField, Dropdown, SidePanel, Textarea } from '@liaison/liaison-ui';
import { emptyOptions, getButtonColor, getDropDownOptions, isCsuTenant } from 'utils/utilities';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectExperienceTypesLookup } from 'store/common/commonInfo.selectors';
import { IAddress } from 'userProfile/store/personalInfo/personalInfo.slice';
import { resetlookupData } from 'store/common/commonInfo.slice';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { AttachedFiles } from 'userProfile/components/AttachedFiles';
import { SkillsMultiselect } from 'userProfile/components/SkillsMutliselect';
import { selectSectionMediaDocs } from 'userProfile/store/mediaDocuments/mediaDocuments.selectors';
import { getTotalHours } from '../AccomplishmentsAndExperiences.utils';
import { validationSchemaAddress } from '../AccomplishmentsAndExperiencesForm.validation';
import { Address, AddressViewCard } from './Address';

const groupName = 'experiences';
interface IExperienceProps {
  data: TAccomplishmentsAndExperienceField | null;
  openChildDrawer: (isAddNew: boolean, childPosition: number, selector: string, formTitle: string) => void;
  isOpenChildDrawer?: boolean;
  childPosition?: number;
  deleteRecord?: ((childPosition: number, tag?: string) => void) | null;
  selector: string;
  formTitle: string;
}

type TFormErrors = {
  experiences: {
    type: { code: { message: string } };
    name: { message: string };
    description: { message: string };
    startDate: { message: string };
    endDate: { message: string };
    hours: { weeklyAverage: { message: string }; weeks: { message: string }; total: { message: string } };
    organization: { name: { message: string }; position: { message: string } };
  }[];
};
const ExperienceForm = ({
  data,
  openChildDrawer,
  isOpenChildDrawer,
  childPosition = 0,
  deleteRecord,
  selector,
  formTitle,
}: IExperienceProps): ReactElement => {
  const [showAlert, setShowAlert] = useState(false);
  const {
    control,
    reset,
    setValue,
    getValues,
    register,
    formState: { errors: formErrors, isValid },
    getFieldState,
    trigger,
    watch,
  } = useFormContext();

  const errors = formErrors as unknown as TFormErrors;
  const { t } = useTranslation();
  const [isOpenAddressPanel, setIsOpenAddressPanel] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});

  const experienceTypesLookup = useSelector(selectExperienceTypesLookup);
  const courseId = data?.[groupName]?.[childPosition]?.id;
  const sectionMediaDocs = useSelector(state => selectSectionMediaDocs(state, courseId));
  const tag = `experiences/${courseId}`;

  const experienceOptions = useMemo(
    () => getDropDownOptions(experienceTypesLookup || emptyOptions),
    [experienceTypesLookup]
  );
  const dispatch = useDispatch();
  const weeklyAverage = watch(`${groupName}.${childPosition}.hours.weeklyAverage`);
  const weeks = watch(`${groupName}.${childPosition}.hours.weeks`);
  const address = getValues(`${groupName}.${childPosition}.organization.address`);

  useEffect(() => {
    if (data) {
      reset(data);
    }
    return () => reset({});
  }, [reset, data]);

  useEffect(() => {
    if (
      getFieldState(`${groupName}.${childPosition}.hours.weeklyAverage`).isDirty ||
      getFieldState(`${groupName}.${childPosition}.hours.weeklyAverage`).isTouched ||
      getFieldState(`${groupName}.${childPosition}.hours.weeks`).isDirty ||
      getFieldState(`${groupName}.${childPosition}.hours.weeks`).isTouched
    ) {
      trigger(`${groupName}.${childPosition}.hours.weeklyAverage`);
      trigger(`${groupName}.${childPosition}.hours.weeks`);
    }
  }, [weeklyAverage, weeks, setValue, childPosition, trigger, getFieldState]);

  const methods = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(validationSchemaAddress, { abortEarly: false })(...args),
  });

  const closeAddressPanel = () => {
    setIsOpenAddressPanel(false);
    dispatch(resetlookupData(['states', 'counties']));
  };

  const onSubmit = (addressData: IAddress) => {
    setValue(`${groupName}.${childPosition}.organization.address`, addressData);
    closeAddressPanel();
  };
  const deleteAddress = () => {
    setValue(`${groupName}.${childPosition}.organization.address`, null);
    closeAddressPanel();
    setSelectedAddress({});
  };

  const onViewAddress = (currentAddress: IAddress) => {
    setSelectedAddress(currentAddress);
    setIsOpenAddressPanel(true);
  };

  const handleAddressPanel = () => {
    return (
      <FormProvider {...methods}>
        <form>
          <SidePanel
            size="small"
            open={isOpenAddressPanel}
            onClose={closeAddressPanel}
            title={Object.keys(selectedAddress).length ? t('experiences.editAddress') : t('experiences.addAddress')}
            isBackdropClickEnabled={true}
            footerButtonConfig={{
              primary: {
                title: t('add_label'),
                props: {
                  'aria-label': t('address.saveAddress'),
                  color: getButtonColor(),
                  variant: 'contained',
                  disabled: !methods.formState.isValid,
                  onClick: methods.handleSubmit(onSubmit),
                },
              },
              tertiary: {
                title: t('cancel_label'),
                props: {
                  'aria-label': t('address.cancelAddress'),
                  color: getButtonColor(),
                  onClick: closeAddressPanel,
                },
              },
            }}
            sx={sxSidePanel}
          >
            <Address
              address={selectedAddress}
              requiredFields={['country', 'region']}
              deleteAddress={Object.keys(selectedAddress).length ? deleteAddress : null}
            />
          </SidePanel>
        </form>
      </FormProvider>
    );
  };

  return (
    <Grid>
      {!isOpenChildDrawer ? (
        <Box>
          <Typography
            variant="subtitle1"
            component="h1"
            sx={{ textTransform: 'uppercase', mb: '0.625rem', letterSpacing: '0.6px' }}
          >
            {t('experiences.title')}
          </Typography>
          {data && data?.experiences && (
            <ExperienceList
              mediumLayout={12}
              experiences={data?.experiences}
              openChildDrawer={openChildDrawer}
              selector={selector}
              formTitle={formTitle}
            />
          )}

          {JSON.stringify(data?.[groupName]) && (
            <InputBase
              inputProps={{ type: 'hidden' }}
              {...register(`${groupName}`)}
              defaultValue={JSON.stringify(data?.[groupName])}
            />
          )}

          <Box sx={{ justifyContent: 'center', display: 'flex' }}>
            <StyledAddButton
              onClick={() =>
                openChildDrawer(true, data?.experiences?.length || 0, selector, t(`${groupName}.addExperience`))
              }
            >
              {t('add_label')}
            </StyledAddButton>
          </Box>
        </Box>
      ) : (
        <Box>
          <FormControl fullWidth required error={!!errors?.[groupName]?.[childPosition]?.type?.code}>
            <InputLabel htmlFor="type">{t('experiences.type')}</InputLabel>
            <Controller
              render={({ field: { ref, onChange, ...field } }) => (
                <Dropdown
                  id="type"
                  {...field}
                  inputRef={ref}
                  options={experienceOptions}
                  fullWidth
                  inputProps={{
                    'aria-label': t('experiences.type'),
                    'aria-describedby': 'type-error',
                    ...DISABLE_AUTO_FILL,
                  }}
                  onChange={option => {
                    setValue(`${groupName}.${childPosition}.type.displayName`, option?.text);
                    onChange(option?.id ?? '');
                  }}
                />
              )}
              control={control}
              name={`${groupName}.${childPosition}.type.code`}
            />
            <StyledFormHelperText role="alert" id="type-error">
              {errors?.[groupName]?.[childPosition]?.type?.code?.message}
            </StyledFormHelperText>
          </FormControl>
          <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.${childPosition}.type.displayName`)} />
          <FormControl fullWidth required error={!!errors?.[groupName]?.[childPosition]?.name}>
            <InputLabel htmlFor="expeienceTitle">{t('experiences.experienceTitle')}</InputLabel>
            <InputBase
              inputProps={{
                'aria-describedby': 'experienceTitle-error',
                'aria-label': 'experience title',
                maxLength: FIELD_LENGTH_200,
                ...DISABLE_AUTO_FILL,
              }}
              {...register(`${groupName}.${childPosition}.name`)}
            />
            <FormHelperText role="alert" id="experienceTitle-error">
              {errors?.[groupName]?.[childPosition]?.name?.message}
            </FormHelperText>
          </FormControl>

          <Controller
            name={`${groupName}.${childPosition}.description`}
            render={({ field, fieldState: { error } }) => (
              <Grid item sm={12}>
                <FormControl fullWidth error={!!errors?.[groupName]?.[childPosition]?.description}>
                  <InputLabel htmlFor="description">{t('experiences.description')}</InputLabel>
                  <Textarea
                    {...field}
                    error={!!error}
                    id="description"
                    maxChars={TEXT_AREA_LENGTH_FIELD}
                    inputProps={{
                      maxLength: TEXT_AREA_LENGTH_FIELD,
                      maxRows: 1,
                      'aria-label': t('experiences.description'),
                    }}
                  />
                  <FormHelperText role="alert">
                    {errors?.[groupName]?.[childPosition]?.description?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
            )}
            control={control}
            defaultValue=""
          />

          <Grid container spacing={2}>
            <Controller
              name={`${groupName}.${childPosition}.startDate`}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Grid item sm={12}>
                  <FormControl fullWidth required error={!!errors?.[groupName]?.[childPosition]?.startDate}>
                    <InputLabel htmlFor="startDate">{t('experiences.startDate')}</InputLabel>
                    <DateField
                      {...field}
                      ref={ref}
                      error={!!error}
                      id="startDate"
                      hidePart="day"
                      onChange={dateVal => {
                        field.onChange(dateVal);
                        setTimeout(() => {
                          if (!errors?.[groupName]?.[childPosition]?.startDate) {
                            trigger(`${groupName}.${childPosition}.endDate`);
                          }
                        }, 0);
                      }}
                      aria-describedby="startDate-error"
                      accessibilityLabelPrefix={t('experiences.startDate')}
                    />
                    <FormHelperText role="alert" id="startDate-error">
                      {errors?.[groupName]?.[childPosition]?.startDate?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}
              control={control}
            />
          </Grid>
          <Grid container spacing={2}>
            <Controller
              name={`${groupName}.${childPosition}.endDate`}
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <Grid item sm={12}>
                  <FormControl fullWidth error={!!errors?.[groupName]?.[childPosition]?.endDate}>
                    <InputLabel htmlFor="endDate">{t('experiences.endDate')}</InputLabel>
                    <DateField
                      {...field}
                      ref={ref}
                      error={!!error}
                      id="endDate"
                      hidePart="day"
                      aria-describedby="endDate-error"
                      accessibilityLabelPrefix={t('experiences.endDate')}
                    />
                    <FormHelperText role="alert" id="endDate-error">
                      {errors?.[groupName]?.[childPosition]?.endDate?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}
              control={control}
            />
          </Grid>
          <FormControl fullWidth error={!!errors?.[groupName]?.[childPosition]?.hours?.weeklyAverage}>
            <InputLabel htmlFor="weeklyAverage">{t('experiences.weeklyAverage')}</InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => {
                return (
                  <NumberFormat
                    {...field}
                    decimalScale={0}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    customInput={InputBase}
                    onValueChange={values => {
                      onChange(values?.floatValue);
                      trigger(`${groupName}.${childPosition}.hours.weeks`);
                      setValue(`${groupName}.${childPosition}.hours.total`, getTotalHours(values?.floatValue, weeks));
                    }}
                    inputProps={{ 'aria-label': t('experiences.weeklyAverage') }}
                  />
                );
              }}
              name={`${groupName}.${childPosition}.hours.weeklyAverage`}
              control={control}
            />
            <FormHelperText role="alert" id="weeklyAverage-error">
              {errors?.[groupName]?.[childPosition]?.hours?.weeklyAverage?.message}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth error={!!errors?.[groupName]?.[childPosition]?.hours?.weeks}>
            <InputLabel htmlFor="weeks">{t('experiences.weeks')}</InputLabel>
            <Controller
              render={({ field: { onChange, ...field } }) => {
                return (
                  <NumberFormat
                    {...field}
                    decimalScale={0}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    customInput={InputBase}
                    onValueChange={values => {
                      onChange(values?.floatValue);
                      trigger(`${groupName}.${childPosition}.hours.weeklyAverage`);
                      setValue(
                        `${groupName}.${childPosition}.hours.total`,
                        getTotalHours(weeklyAverage, values?.floatValue)
                      );
                    }}
                    inputProps={{ 'aria-label': t('experiences.weeks') }}
                  />
                );
              }}
              name={`${groupName}.${childPosition}.hours.weeks`}
              control={control}
            />
            <FormHelperText role="alert" id="weeks-error">
              {errors?.[groupName]?.[childPosition]?.hours?.weeks?.message}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth error={!!errors?.[groupName]?.[childPosition]?.hours?.total}>
            <InputLabel htmlFor="total">{t('experiences.total')}</InputLabel>
            <Controller
              render={({ field: { ...field } }) => {
                return (
                  <NumberFormat
                    {...field}
                    disabled
                    decimalScale={0}
                    allowNegative={false}
                    fixedDecimalScale={true}
                    customInput={InputBase}
                    inputProps={{ 'aria-label': t('experiences.total') }}
                  />
                );
              }}
              defaultValue=""
              name={`${groupName}.${childPosition}.hours.total`}
              control={control}
            />
          </FormControl>
          <Typography component="div" variant="subtitle3" sx={{ textTransform: 'uppercase', py: '0.5rem' }}>
            {t('experiences.org_title')}
          </Typography>

          <FormControl fullWidth error={!!errors?.[groupName]?.[childPosition]?.organization?.name}>
            <InputLabel htmlFor="orgName">{t('experiences.org_name')}</InputLabel>
            <InputBase
              inputProps={{
                'aria-describedby': 'orgName-error',
                'aria-label': 'organization name',
                maxLength: MAX_MEDIUM_LENGTH_FIELD,
                ...DISABLE_AUTO_FILL,
              }}
              {...register(`${groupName}.${childPosition}.organization.name`)}
              fullWidth={true}
            />
            <FormHelperText role="alert" id="orgName-error">
              {errors?.[groupName]?.[childPosition]?.organization?.name?.message}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth error={!!errors?.[groupName]?.[childPosition]?.organization?.position}>
            <InputLabel htmlFor="position">{t('experiences.org_position')}</InputLabel>
            <InputBase
              inputProps={{
                'aria-describedby': 'position-error',
                'aria-label': 'organization position',
                maxLength: FIELD_LENGTH_100,
                ...DISABLE_AUTO_FILL,
              }}
              {...register(`${groupName}.${childPosition}.organization.position`)}
            />
            <FormHelperText role="alert" id="relationship-error">
              {errors?.[groupName]?.[childPosition]?.organization?.position?.message}
            </FormHelperText>
          </FormControl>

          {handleAddressPanel()}
          <Box sx={{ mt: '1rem' }}>
            {address ? (
              <>
                <AddressViewCard addresses={[address]} mediumLayout={12} viewAddress={onViewAddress} />
                {JSON.stringify(address) && (
                  <InputBase
                    inputProps={{ type: 'hidden' }}
                    {...register(`${groupName}.${childPosition}.organization.address`)}
                    defaultValue={JSON.stringify(address)}
                  />
                )}
              </>
            ) : (
              <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                <StyledAddButton onClick={() => setIsOpenAddressPanel(true)} disabled={!isValid}>
                  {t('experiences.addAddress')}
                </StyledAddButton>
              </Box>
            )}
          </Box>
          <InputBase inputProps={{ type: 'hidden' }} {...register(`${groupName}.${childPosition}.id`)} />
          <AttachedFiles sectionName="experiences" tag={tag} disabled={!isValid} />
          {!isCsuTenant() && <SkillsMultiselect tag={tag} disabled={!isValid} />}
          {deleteRecord && (
            <Box sx={{ mt: '1rem', justifyContent: 'center', display: 'flex' }}>
              <StyledDeleteButton onClick={() => setShowAlert(true)}>{t('experiences.remove')}</StyledDeleteButton>
            </Box>
          )}
          <ConfirmationDialog
            open={showAlert}
            text={t('experiences.delete_title')}
            confirmationText={t('experiences.delete_content')}
            onClose={() => {
              setShowAlert(false);
            }}
            footerButtonConfig={{
              primary: {
                title: t('remove_label'),
                props: {
                  onClick: () => {
                    setShowAlert(false);
                    deleteRecord?.(childPosition || 0, sectionMediaDocs?.length ? tag : undefined);
                  },
                },
              },
              tertiary: {
                title: t('cancel_label'),
              },
            }}
          />
        </Box>
      )}
    </Grid>
  );
};

export default memo(ExperienceForm);
