/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export const sxBoxWrapper = {
  mt: { xs: 'calc(33vh - 330px)', sm: 2 },
  p: { sm: 0, md: '0.5rem' },
  border: { sm: 'none', md: '1px solid #AFBBCC' },
  borderRadius: '1.375rem',
} as const;
