/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect, useState, memo } from 'react';
import { Snackbar } from '@liaison/liaison-ui';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getGpaCalculation } from 'transferPlanner/pages/GeneralEducation/GeneralEducation.utils';
import { nameSpace } from 'transferPlanner/constants/general';
import { gpaCalculationSelector } from 'transferPlanner/store/genEdProcess/genEdProcess.selectors';

const GpaCalculationErrorAlert = memo((): ReactElement => {
  const [isGPAOutdated, setIsGPAOutdated] = useState(false);
  const gpaCalculation = useSelector(gpaCalculationSelector);
  const { t } = useTranslation(nameSpace);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  useEffect(() => {
    if (gpaCalculation?.transferGPA?.status?.code === 'OUTDATED') {
      setIsGPAOutdated(true);
    } else if (gpaCalculation?.transferGPA?.status?.code === 'LATEST') {
      setIsGPAOutdated(false);
    }
  }, [dispatch, gpaCalculation]);

  return (
    <Snackbar
      open={isGPAOutdated}
      /* istanbul ignore next */
      onClose={() => setIsGPAOutdated(false)}
      type="error"
      sx={isMobile ? { width: '100%', left: '0', right: '0' } : { width: '50%' }}
      autoHideDuration={10000}
    >
      <Typography tabIndex={0}>
        {t('transferProgress.outDatedTransferGpaMsg1')}
        <Typography
          onClick={() => dispatch(getGpaCalculation())}
          display="inline"
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          tabIndex={0}
          data-testid="gpa-calculation-outdated-alert"
        >
          {t('transferProgress.outDatedTransferGpaMsgLink')}
        </Typography>
        {t('transferProgress.outDatedTransferGpaMsg2')}
      </Typography>
    </Snackbar>
  );
});

export default GpaCalculationErrorAlert;
