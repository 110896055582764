/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { useState, useEffect, useMemo, memo, ReactElement } from 'react';
import { Modal, Button, Grid, Divider, Typography, Theme, useMediaQuery } from '@mui/material';
import Confetti from 'react-confetti';
import {
  Csu6Units,
  Csu12Units,
  Csu18Units,
  Csu39Units,
  CsuA2Completed,
  CsuB4Completed,
  CsuGolden4,
} from 'transferPlanner/assets/svgs/genEd';
import { TFunction, useTranslation } from 'react-i18next';
import { nameSpace } from 'transferPlanner/constants/general';
import { postGenEdMilestoneModalStatus } from 'transferPlanner/pages/GeneralEducation/GeneralEducation.utils';
import { useDispatch } from 'react-redux';
import { IGenEdMilestone } from 'transferPlanner/store/genEdProcess/genEdProcess.slice';
import { Box } from '@mui/system';

interface ModalContainerProps {
  milestones: IGenEdMilestone[];
}

export const getMilestoneIconOrText = (
  milestoneCode: string,
  isIcon: boolean,
  size: string,
  t: TFunction<'tp', undefined>,
  isTabIndex = true
): ReactElement | null => {
  const tabIndex = isTabIndex ? { tabIndex: 1 } : {};
  switch (milestoneCode) {
    case 'A2_COMPLETE':
      return isIcon ? (
        <CsuA2Completed width={size} height={size} aria-label={t('milestoneModal_A2_COMPLETE')} {...tabIndex} />
      ) : (
        t('milestoneModal_A2_COMPLETE')
      );
    case 'B4_COMPLETE':
      return isIcon ? (
        <CsuB4Completed width={size} height={size} aria-label={t('milestoneModal_B4_COMPLETE')} {...tabIndex} />
      ) : (
        t('milestoneModal_B4_COMPLETE')
      );
    case '6_UNITS_COMPLETE':
      return isIcon ? (
        <Csu6Units width={size} height={size} aria-label={t('milestoneModal_6_UNITS_COMPLETE')} {...tabIndex} />
      ) : (
        t('milestoneModal_6_UNITS_COMPLETE')
      );
    case '12_UNITS_COMPLETE':
      return isIcon ? (
        <Csu12Units width={size} height={size} aria-label={t('milestoneModal_12_UNITS_COMPLETE')} {...tabIndex} />
      ) : (
        t('milestoneModal_12_UNITS_COMPLETE')
      );
    case 'GOLDEN_4_COMPLETE':
      return isIcon ? (
        <CsuGolden4 width={size} height={size} aria-label={t('milestoneModal_GOLDEN_4_COMPLETE')} {...tabIndex} />
      ) : (
        t('milestoneModal_GOLDEN_4_COMPLETE')
      );
    case '18_UNITS_COMPLETE':
      return isIcon ? (
        <Csu18Units width={size} height={size} aria-label={t('milestoneModal_18_UNITS_COMPLETE')} {...tabIndex} />
      ) : (
        t('milestoneModal_18_UNITS_COMPLETE')
      );
    case '39_UNITS_COMPLETE':
      return isIcon ? (
        <Csu39Units width={size} height={size} aria-label={t('milestoneModal_39_UNITS_COMPLETE')} {...tabIndex} />
      ) : (
        t('milestoneModal_39_UNITS_COMPLETE')
      );
    default:
      return null;
  }
};

const MilestoneCompletedModal: React.FC<ModalContainerProps> = ({ milestones }) => {
  const [currentMilestoneIndex, setCurrentMilestoneIndex] = useState(0);
  const completedAndNotShownMilestones = useMemo(
    () => milestones.filter(({ status, hasBeenShown }) => status === 'COMPLETE' && !hasBeenShown),
    [milestones]
  );
  const [hasBeenShownData, setHasBeenShownData] = useState<IGenEdMilestone[]>([]);
  const [startConfetti, setStartConfetti] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation(nameSpace);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const milestone = completedAndNotShownMilestones[currentMilestoneIndex];
  const size = isMobile ? '150' : '200';
  const headerText = useMemo(() => {
    return `${t('milestoneModalHeader')} ${getMilestoneIconOrText(milestone?.name?.code, false, size, t)} ${t(
      'milestoneModalHeaderEndPart'
    )}`;
  }, [milestone?.name?.code, size, t]);

  useEffect(() => {
    if (startConfetti) {
      const timeout = setTimeout(() => {
        setStartConfetti(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [startConfetti]);

  useEffect(() => {
    setCurrentMilestoneIndex(0);
  }, [milestones]);

  const handleOpenNextModal = () => {
    const currentMilestone = { ...completedAndNotShownMilestones[currentMilestoneIndex], hasBeenShown: true };
    setHasBeenShownData((prevState: IGenEdMilestone[]) => [...prevState, currentMilestone]);
    const nextIndex = currentMilestoneIndex + 1;
    if (nextIndex === completedAndNotShownMilestones.length) {
      setCurrentMilestoneIndex(-1);
      dispatch(
        postGenEdMilestoneModalStatus([
          ...milestones.filter(({ status }) => status !== 'COMPLETE'),
          ...milestones.filter(({ status, hasBeenShown }) => status === 'COMPLETE' && hasBeenShown),
          ...hasBeenShownData,
          currentMilestone,
        ])
      );
    } else if (nextIndex < completedAndNotShownMilestones.length) {
      setCurrentMilestoneIndex(nextIndex);
    }
    setStartConfetti(true);
  };

  if (milestones?.length <= 0) return null;

  return (
    <Modal
      open={milestone?.status === 'COMPLETE' && !milestone?.hasBeenShown}
      onClose={handleOpenNextModal}
      aria-labelledby="milestone-modal-header"
      role="dialog"
    >
      <Grid container alignItems="center" justifyContent="center" style={{ height: '100%', padding: '10px' }}>
        <Grid item xs={12} md={6}>
          <Box
            component="div"
            role="dialog"
            aria-modal="true"
            style={{
              backgroundColor: 'white',
              padding: '20px 20px 5px 20px',
              textAlign: 'center',
              borderRadius: '1rem',
            }}
            aria-label="milestone-modal-content"
          >
            <Confetti
              confettiSource={{ x: 0, y: 0, w: window.innerWidth, h: window.innerHeight }}
              numberOfPieces={250}
              recycle={startConfetti}
            />
            {getMilestoneIconOrText(milestone?.name?.code, true, size, t)}
            <Typography variant="h2" id="milestone-modal-header" aria-label={headerText}>
              {headerText}
            </Typography>
            <Typography
              component="p"
              sx={{ mt: '.5rem', fontSize: '1rem' }}
              aria-label={t('milestoneModalHeaderLastSentence')}
            >
              {t('milestoneModalHeaderLastSentence')}
            </Typography>
            <Typography
              component="p"
              sx={{ mt: '.5rem', fontSize: '1rem' }}
              aria-label={t('milestoneModalDescription')}
            >
              {t('milestoneModalDescription')}
            </Typography>
            <Divider sx={{ borderStyle: 'solid', borderColor: '#dedede', mt: '2rem' }} />
            <Box component="div" style={{ textAlign: 'center', padding: '0.5rem 0rem' }}>
              <Button variant="text" onClick={handleOpenNextModal} role="button" aria-label={t('close_label')}>
                {t('close_label')}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default memo(MilestoneCompletedModal);
