/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export const { AE_URL: AEURL } = window.env;

export const API = {
  accomplishmentAndExperience: `${AEURL}/profile/`,
  skills: `${AEURL}/skills`,
  enumTypes: `${AEURL}/enumTypes`,
};
