/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import type { IFeature } from 'store/features/features.slice';
import { allowedEnrollmentStatuses } from 'transferPlanner/constants/general';
import {
  TSP_QUESTIONNAIRE,
  TSP_ENROLLMENT,
  RESEARCH_CAMPUS_AND_PROGRAMS,
  PROGRAM_PROGRESS,
} from 'transferPlanner/constants/routeNames';
import { ITspEligibility } from 'transferPlanner/store/eligibility/eligibility.slice';
import { ITspEnrollment } from 'transferPlanner/store/enrollment/enrollment.slice';
import { isFeatureActive } from 'utils/features.utils';

interface IGetStatusObj {
  isQuestionnaire: boolean;
  isNotEligible: boolean;
  isEnrolled: boolean;
  isEnrolledPending: boolean;
  isTspEnrollmentOpen: boolean;
}

export interface IStatusConfig {
  title: string;
  titleColor: string;
  instruction: string;
  buttonText: string;
  buttonActionLink: string;
  enableCSUTile?: boolean;
}

export const statusConfig = {
  notQuestionnaireCompleted: {
    title: 'dashboard.statusNoQuestionnaire',
    titleColor: '#BF5300',
    instruction: '',
    buttonText: 'begin_questionnaire_label',
    buttonActionLink: TSP_QUESTIONNAIRE,
  },
  notEnrolled: {
    title: 'dashboard.statusReadyToEnrollmentTitle',
    titleColor: '#BF5300',
    instruction: 'dashboard.statusReadyToEnrollmentInstruction1',
    buttonText: 'enroll_in_tsp_label',
    buttonActionLink: TSP_ENROLLMENT,
  },
  enrolled: {
    title: 'dashboard.statusEnrolled',
    titleColor: '#1B5E20',
    instruction: '',
    buttonText: 'view_full_details',
    buttonActionLink: PROGRAM_PROGRESS,
  },
  enrolledPending: {
    title: 'dashboard.statusEnrolledPendingTitle',
    titleColor: '#BF5300',
    instruction: 'dashboard.statusEnrolledPendingInstruction',
    buttonText: 'programs.explore_programs',
    buttonActionLink: RESEARCH_CAMPUS_AND_PROGRAMS,
  },
  notEligible: {
    title: 'dashboard.statusNotEligibleTitle',
    titleColor: '#CC0B2A',
    instruction: 'dashboard.statusNotEligibleInstruction',
    buttonText: 'programs.explore_programs',
    buttonActionLink: RESEARCH_CAMPUS_AND_PROGRAMS,
  },
  enrollmentClosed: {
    title: 'dashboard.statusEnrollmentClosed',
    titleColor: '#CC0B2A',
    instruction: 'dashboard.statusEnrollmentClosedInstruction',
    buttonText: 'programs.explore_programs',
    buttonActionLink: RESEARCH_CAMPUS_AND_PROGRAMS,
    enableCSUTile: true,
  },
};

// can be temporary solution as we still haven't implemented statuses
export const getStatusConfig = ({
  isQuestionnaire,
  isNotEligible,
  isEnrolled,
  isEnrolledPending,
  isTspEnrollmentOpen,
}: IGetStatusObj): IStatusConfig => {
  if (!isTspEnrollmentOpen && !isEnrolled) return statusConfig.enrollmentClosed;
  if (isEnrolled) return statusConfig.enrolled;
  if (isEnrolledPending) return statusConfig.enrolledPending;
  if (!isQuestionnaire) return statusConfig.notQuestionnaireCompleted;

  return isNotEligible ? statusConfig.notEligible : statusConfig.notEnrolled;
};

export const isTspEnrolled = (tspEnrollment: ITspEnrollment[] | undefined): boolean => {
  return !!tspEnrollment?.some(
    enrollment =>
      enrollment.active && allowedEnrollmentStatuses.includes(enrollment?.enrollmentStatus?.status.code || '')
  );
};

export const isRevokedAndNotEligibleToEnroll = (tspEnrollment: ITspEnrollment[] | undefined): boolean => {
  return !!tspEnrollment?.some(
    enrollment =>
      enrollment?.enrollmentStatus?.status.code === 'REVOKED' &&
      enrollment?.enrollmentStatus?.reason?.code === 'NOT_ELIGIBLE'
  );
};

export const isNotEligibleToEnroll = (
  tspEligibility: ITspEligibility[] | undefined,
  tspEnrollment: ITspEnrollment[] | undefined
): boolean => {
  return !!(
    (tspEligibility?.[0] && tspEligibility?.[0].status.code === 'INELIGIBLE') ||
    tspEnrollment?.some(
      enrollment =>
        enrollment?.enrollmentStatus?.status.code === 'REVOKED' &&
        enrollment?.enrollmentStatus?.reason?.code === 'NOT_ELIGIBLE'
    )
  );
};

export const isTspEnrollmentPending = (tspEnrollment: ITspEnrollment[] | undefined): boolean => {
  return !!tspEnrollment?.some(enrollment => enrollment?.enrollmentStatus?.status.code === 'REQUEST_RELEASE');
};

export const activeEligibility = (
  tspEligibility: ITspEligibility[] | null,
  features: IFeature[] | null,
  configVersion?: number | undefined
): ITspEligibility | null => {
  const isHideJulyAnswersInActive = isFeatureActive(features, 'HIDE_JULY_TP_ANSWERS');
  const currentVersion = configVersion || (isHideJulyAnswersInActive ? 2 : 1);
  const currentEligibility =
    tspEligibility?.find((eligibility: ITspEligibility) => eligibility.questionnaireVersion === currentVersion) || null;

  return currentEligibility;
};
