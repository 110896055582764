/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectLanguages } from 'userProfile/store/profileView/profileView.selectors';
import { SimpleSection } from '../Common';

export const Languages = (): ReactElement => {
  const languages = useSelector(selectLanguages);
  const { t } = useTranslation();
  const title = t('livingProfile.sections.languages');

  return <SimpleSection sectionTitle={title} pills={languages} />;
};
