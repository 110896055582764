/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Box, Card, CardContent, Grid, Divider, Link, Stack, Typography } from '@mui/material';
import { IconLink, IconMailSolid, IconPhone } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'transferPlanner/constants/general';
import { sxCard } from 'pages/Pages.styles';

type TDetails = {
  email?: string;
  phone?: string;
  link?: string;
};

export type TInfoCardProps = {
  heading: string;
  subHeading: string;
  showDetails?: boolean;
  details?: TDetails;
};

export const InfoCard = ({
  heading,
  subHeading,
  showDetails = false,
  details = { email: '', phone: '', link: 'https://www.calstate.edu' },
}: TInfoCardProps): ReactElement => {
  const { email, phone, link } = details;
  const { t } = useTranslation(nameSpace);

  return (
    <Card variant="outlined" sx={sxCard}>
      <CardContent
        sx={{
          p: 2,
          '&:last-child': {
            pb: 2,
          },
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} sx={{ mr: '0.5rem', display: 'flex' }}>
            <Box>
              <Box>
                <Typography variant="subtitle3" sx={{ overflowWrap: 'anywhere' }}>
                  {heading}
                </Typography>
              </Box>
              <Typography variant="subtitle8">{subHeading}</Typography>
            </Box>
          </Grid>
        </Grid>
        {showDetails && (
          <>
            <Divider sx={{ borderStyle: 'solid', mt: 1, mb: 2 }} />
            <Stack direction="column" alignItems="flex-start" spacing={1.5}>
              {email && (
                <Stack direction="row" alignItems="flex-start" spacing={1}>
                  <IconMailSolid sx={{ fontSize: '0.85rem' }} />
                  <Link href={`mailto:${email}`} target="_blank" rel="noopener" underline="none">
                    <Typography variant="subtitle8" component="div">
                      {t('contact_advisor')}
                    </Typography>
                  </Link>
                </Stack>
              )}
              {phone && (
                <Stack direction="row" alignItems="flex-start" spacing={1}>
                  <IconPhone sx={{ fontSize: '0.85rem' }} />
                  <Typography variant="caption" component="div">
                    {phone}
                  </Typography>
                </Stack>
              )}

              {link && (
                <Stack direction="row" alignItems="flex-start" spacing={1}>
                  <IconLink sx={{ fontSize: '0.85rem' }} />
                  <Link href={link} target="_blank" rel="noopener" underline="none">
                    <Typography variant="caption" component="div" color="text.primary">
                      {link}
                    </Typography>
                  </Link>
                </Stack>
              )}
            </Stack>
          </>
        )}
      </CardContent>
    </Card>
  );
};
