/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CssBaseline } from '@mui/material';
import axios from 'axios';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { ThemeProvider } from 'components/ThemeProvider';
import { Spinner } from 'components/Spinner';
import { keycloak, initOptions } from 'constants/keyCloak';
import { getObData } from 'utils/commonUtils';
import { setUi } from 'store/ui/ui.slice';
import { selectFeaturesLoading } from 'store/features/features.selectors';
import AppRoutes from './Routes';

const WithKeycloakProvider = (): ReactElement => {
  const dispatch = useDispatch();
  const isFeaturesLoading = useSelector(selectFeaturesLoading);
  const handleOnEvent = async (eventType: string) => {
    if (eventType === 'onAuthSuccess') {
      /* istanbul ignore next */
      if (keycloak.authenticated) {
        localStorage.setItem('kc_user_token', keycloak?.tokenParsed?.['LP-STUDENT-ID']);
        dispatch(getObData(keycloak.token || ''));
      }
    }

    if (eventType === 'onReady') {
      /* istanbul ignore next */
      dispatch(setUi({ name: 'keycloakInitiated', state: true }));
    }
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onTokens={({ token }) => {
        /* istanbul ignore next */
        if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        localStorage.setItem('kcToken', token || '');
      }}
      initOptions={initOptions}
      onEvent={handleOnEvent}
    >
      <ThemeProvider>
        <CssBaseline />
        {isFeaturesLoading ? <Spinner backdrop /> : <AppRoutes />}
      </ThemeProvider>
    </ReactKeycloakProvider>
  );
};

export default WithKeycloakProvider;
