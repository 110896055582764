/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';
import { IAccomplishmentsAndExperienceState, TSkillsAttachments } from './accomplishmentAndExperience.slice';

const accomplishmentAndExperience = (state: TRootState): IAccomplishmentsAndExperienceState =>
  state.accomplishmentAndExperience;

export const selectAccomplishmentAndExperience = createSelector(
  accomplishmentAndExperience,
  (stateData: IAccomplishmentsAndExperienceState) => stateData?.accomplishmentsAndExperienceData
);

export const selectSkillsAttachments = createSelector(
  accomplishmentAndExperience,
  (stateData: IAccomplishmentsAndExperienceState) => stateData?.skillsAttachments || []
);

export const selectLoading = createSelector(
  accomplishmentAndExperience,
  (stateData: IAccomplishmentsAndExperienceState) => stateData?.loading
);

export const selectAccomplishmentAndExperienceLocalData = createSelector(
  accomplishmentAndExperience,
  (stateData: IAccomplishmentsAndExperienceState) => stateData?.accomplishmentsAndExperienceLocalData
);

export const selectSectionSkills = createSelector(
  [selectAccomplishmentAndExperience, (_state, sectionId) => sectionId],
  (accomplishmentsAndExperienceData, sectionId) =>
    accomplishmentsAndExperienceData?.competencies?.filter(c => c.attachedTo?.some(attach => attach.id === sectionId))
);

export const selectSectionSkillsAttachments = createSelector(
  [selectSkillsAttachments, (_state, tag) => tag],
  (sectionSkillsAttachments, tag) => sectionSkillsAttachments?.find(s => s.tag === tag) || ({} as TSkillsAttachments)
);
