/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useCallback, memo, useState } from 'react';
import {
  Grid,
  IconButton,
  Typography,
  AppBar,
  Toolbar,
  useMediaQuery,
  Theme,
  Stack,
  Divider,
  Box,
} from '@mui/material';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Icon, Footer } from '@liaison/liaison-ui';
import { useDispatch, useSelector } from 'react-redux';
import { setUi } from 'store/ui/ui.slice';
import { selectTenantConfig } from 'store/common/commonInfo.selectors';
import { StyledPageGrid, StyledToolBar } from 'pages/Pages.styles';
import { useTranslation } from 'react-i18next';
import { FooterSection } from 'components/FooterSection';
import { useImpersonation } from 'hooks/useImpersonation';
import { selectUserName } from 'userProfile/store/personalInfo/personalInfo.selectors';
import { isCsuTenant } from 'utils/utilities';
import { UserAvatar } from 'userProfile/components/UserAvatar';
import { StyledSectionBox } from 'userProfile/components/UserNav/UserNav.styles';
import { handleExit } from 'utils/commonUtils';
import { footerColor } from 'transferPlanner/constants/styles';

const ManageAccountLayout = (): ReactElement => {
  const { pathname } = useLocation();
  const [activePage, setActivePage] = useState(pathname.replace(/^\//, ''));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const { t } = useTranslation();
  const impersonationToken = useImpersonation();
  const prefix = localStorage.getItem('tenant');
  const { userFirstName, userLastName, userFullName } = useSelector(selectUserName);

  const handleItemClick = (location: string) => {
    setActivePage(location);
    navigate(`/${location}`);
    window.scrollTo(0, 0);
  };

  const setOpenSidebar = useCallback(() => {
    dispatch(setUi({ name: 'sidebar', state: { open: true } }));
  }, [dispatch]);

  const tenantConfig = useSelector(selectTenantConfig);
  const { footerLinks, socialMediaLinks, copyright, logo, logoMobile, logoNoBG } = tenantConfig || {
    footerLinks: undefined,
    socialMediaLinks: undefined,
    copyright: '',
  };

  const accountMenus = [
    {
      label: t('account.myAccount'),
      icon: 'IconUserProfile',
      ariaLabel: 'my account',
      location: 'my-account',
    },
    {
      label: t('privacy.heading'),
      icon: 'IconShield',
      ariaLabel: 'privacy and security',
      location: 'privacy-security',
    },
    {
      label: t('logOut'),
      icon: 'IconSendOut',
      ariaLabel: 'log out',
      onClick: () => handleExit(),
    },
  ];

  return (
    <Grid container direction="column">
      {isMobile ? (
        <AppBar position="fixed">
          <StyledToolBar>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton
                size="large"
                edge="start"
                aria-label="open drawer"
                onClick={setOpenSidebar}
                sx={{ color: theme => (isCsuTenant() ? theme.palette.common.black : theme.palette.common.white) }}
              >
                <Icon>IconMenu</Icon>
              </IconButton>
              <Box
                component="img"
                sx={{
                  height: 36,
                }}
                src={logoMobile}
                aria-hidden="true"
                alt="logo"
              />
            </Box>
          </StyledToolBar>
        </AppBar>
      ) : (
        <AppBar
          position="static"
          sx={{
            bgcolor: theme => theme.palette.primary.main,
            boxShadow: 'none',
          }}
        >
          <Toolbar
            variant="dense"
            sx={{
              height: '60px',
              ml: '3rem',
            }}
          >
            <Grid container component="div" direction="row" justifyContent="space-between">
              <Grid item display="flex" alignItems="center" component="a" tabIndex={0}>
                <Box
                  component="img"
                  sx={{
                    height: isCsuTenant() ? 30 : 42,
                  }}
                  src={isCsuTenant() ? logoNoBG : logo}
                  alt="logo"
                  aria-label={t('account.myAccount.logo_label')}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      )}
      <Grid
        sx={{ height: 'calc( 100vh - 48px )', mt: { sm: 0, xs: impersonationToken ? 11 : 1 } }}
        container
        direction="row"
        component="main"
      >
        <Grid
          container
          component="section"
          sx={{
            bgcolor: theme => theme.palette.primary.main,
            padding: ['48px 0 0 0', '0 0 0 80px', '0 0 0 80px'],
            flexDirection: 'row',
            flexWrap: 'nowrap',
          }}
        >
          {!isMobile ? (
            <Grid container direction="column" sx={{ width: '25%', mr: '1rem', marginTop: '1rem' }}>
              <Stack direction="row" alignItems="center" gap={1}>
                <UserAvatar
                  firstName={userFirstName}
                  lastName={userLastName}
                  width="2.5rem"
                  height="2.5rem"
                  initialsFontSize="1.5rem"
                  hideName
                />
                <Typography
                  variant="subtitle3"
                  sx={{
                    color: theme => theme.palette.primary.contrastText,
                    overflowWrap: 'anywhere',
                  }}
                >
                  {userFullName}
                </Typography>
              </Stack>
              <Divider sx={{ borderStyle: 'solid', mt: '1rem' }} flexItem />
              <Grid container item direction="column" justifyContent="space-between" sx={{ marginTop: '1rem' }}>
                {accountMenus?.map(menu => {
                  return (
                    <StyledSectionBox
                      onClick={menu.onClick ? menu.onClick : () => handleItemClick(menu?.location)}
                      key={menu?.icon}
                      className={activePage === menu.location ? `${prefix}-buttonActive` : `${prefix}-button`}
                    >
                      <Icon
                        aria-label={menu?.label}
                        sx={{
                          padding: '0.2rem',
                          fontSize: '2rem',
                          color: theme => theme.palette.primary.contrastText,
                        }}
                      >
                        {menu?.icon}
                      </Icon>
                      <Typography align="left" variant="body1" color="text" sx={{ margin: '5px 0px 5px 10px' }}>
                        {menu?.label}
                      </Typography>
                    </StyledSectionBox>
                  );
                })}
              </Grid>
            </Grid>
          ) : null}
          <StyledPageGrid>
            <Outlet />
            <Footer
              copyrightDangerouslySetInnerHTML={{
                __html: copyright,
              }}
              footerLinks={footerLinks}
              socialLinks={socialMediaLinks}
              svgIconProps={{ sx: { color: (theme: Theme) => theme.palette.text.primary } }}
              sxTextProps={{ fontSize: '0.75rem', color: `${footerColor} !important` }}
            />
          </StyledPageGrid>
        </Grid>
      </Grid>
      {isMobile && <FooterSection footerSectionMenus={accountMenus} />}
    </Grid>
  );
};

export default memo(ManageAccountLayout);
