/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Grid, IconButton, Theme } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

type TStepperDotsProps = {
  steps?: number;
  activeStep?: number;
  onStepChange?: (value: number) => void;
  tipsDotLabel?: string[];
};

export const StepperDots = ({
  steps = 4,
  activeStep = 0,
  onStepChange = () => {},
  tipsDotLabel = [],
}: TStepperDotsProps): ReactElement => {
  const dots = Array.from(Array(steps).keys());

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        {dots.map((dot, index) => {
          const isStepActive = activeStep === dot;
          return (
            <IconButton
              key={dot}
              disabled={isStepActive}
              size="small"
              onClick={() => onStepChange(dot)}
              sx={{ m: '1px', p: 0 }}
              aria-label={tipsDotLabel[index] || `carousel dot button ${dot}`}
            >
              <CircleIcon
                sx={{
                  color: isStepActive
                    ? (theme: Theme) => theme.palette.success.main
                    : (theme: Theme) => theme.palette.grey.inactive,
                  '@media(hover: hover)': {
                    '&:hover': { color: (theme: Theme) => theme.palette.grey.inactive },
                  },
                  '&:focus': {
                    color: (theme: Theme) => theme.palette.text.secondary,
                  },
                }}
              />
            </IconButton>
          );
        })}
      </Grid>
    </Grid>
  );
};
