/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useCallback, useEffect, useState } from 'react';
import { Button, Grid, IconButton, LinearProgress, Stack, Typography, Theme, useMediaQuery, Box } from '@mui/material';
import { setTitle } from 'utils/commonUtils';
import { useTranslation } from 'react-i18next';
import { sanitizePayload } from 'utils/utilities';
import { nameSpace } from 'transferPlanner/constants/general';
import { IconEdit, IconInfoSolid, SidePanel, Accordion } from '@liaison/liaison-ui';
import type { ISidePanelFooterProps } from '@liaison/liaison-ui/dist/components/SidePanel/SidePanelFooter';
import { useDispatch, useSelector } from 'react-redux';
import { TGenEdReq } from 'store/common/commonInfo.slice';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { selectGeneralEducationRequirements } from 'store/common/commonInfo.selectors';
import {
  selectGenEdProcessData,
  selectAchievedMilestone,
  selectGenEdLoading,
} from 'transferPlanner/store/genEdProcess/genEdProcess.selectors';
import { getGeneralEducationRequirements } from 'transferPlanner/components/GeneralEducationReqTable/GeneralEducationReqTable.utils';
import { Spinner } from 'components/Spinner';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { sxSidePanel } from 'pages/Pages.styles';
import Cart from 'transferPlanner/components/MyProgramsCart/Cart';
import {
  IGenEdCategories,
  IGenEdRequirementsConfig,
  IRequirements,
} from 'transferPlanner/store/genEdProcess/genEdProcess.slice';
import { getAcademicHistory } from 'utils/AcademicHistory.utils';
import { getStandardTests } from 'utils/standardTests.utils';
import { selectStandardTests } from 'store/standardTests/standardTests.selectors';
import { selectAcademicHistory } from 'store/academicHistory/academicHistory.selectors';
import { useFirstRenderStatus } from 'hooks/useFirstRenderStatus';
import { GoldenFour, SubjectRequirement } from './AboutGenEd';
import GenEdAccordianHeading from './GenEdAccordianHeading';
import GenEdAccordianBody, { StatusChip } from './GenEdAccordianBody';
import {
  getGenEdProcess,
  postGenEdProcess,
  getCoursesAndTests,
  generateDefaultAssignments,
  getUnitsRequired,
} from './GeneralEducation.utils';
import CourseDetails from './GenEdSidePanels/CourseDetails';
import About, { getMilestoneIcon } from './AboutGenEd/About';
import CourseAssignment from './GenEdSidePanels/CourseAssignment';

const GeneralEducation = (): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const firstRender = useFirstRenderStatus();
  const [showDuplicateError, setShowDuplicateError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [submittedData, setSubmittedData] = useState<IGenEdRequirementsConfig>();
  const [assignments, setAssignments] = useState([] as IGenEdCategories[]);
  const [sidePanelInformation, setSidePanelInformation] = useState<{
    isSidePanelOpen: boolean;
    title: string;
    data?: IRequirements;
    screenName: string;
    showSaveButton?: boolean;
  }>({
    isSidePanelOpen: false,
    title: '',
    screenName: '',
    showSaveButton: false,
  });
  const academicHistoryData = useSelector(selectAcademicHistory);
  const standardTests = useSelector(selectStandardTests);
  const genEdProcessData = useSelector(selectGenEdProcessData);
  const genEdCategories = genEdProcessData?.genEdCategories as IGenEdCategories[];
  const genEdReqData = useSelector(selectGeneralEducationRequirements);
  const achievedMilestone = useSelector(selectAchievedMilestone);
  const genEdLoading = useSelector(selectGenEdLoading);

  useEffect(() => {
    if (!academicHistoryData) dispatch(getAcademicHistory());
    if (!standardTests) dispatch(getStandardTests());
    if ((academicHistoryData && standardTests) || standardTests === '') {
      dispatch(getCoursesAndTests(academicHistoryData, standardTests));
    }
  }, [dispatch, academicHistoryData, standardTests]);

  const openSidePanel = useCallback(
    (title: string, screenName: string, showSaveButton: boolean, data?: IRequirements) => {
      setSidePanelInformation(prevState => ({
        ...prevState,
        isSidePanelOpen: true,
        title,
        data,
        screenName,
        showSaveButton,
      }));
      setTitle(title);
    },
    []
  );

  const methods = useForm({
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    defaultValues: {
      genEdCategories: assignments,
    },
  });

  const { dirtyFields } = useFormState({
    control: methods.control,
  });

  const closeSidePanel = () => {
    setShowDuplicateError(false);
    setSidePanelInformation({
      ...sidePanelInformation,
      isSidePanelOpen: false,
      showSaveButton: false,
      screenName: '',
    });
  };

  const onBackClicked = () => {
    if (sidePanelInformation.screenName === 'GoldenFour' || sidePanelInformation.screenName === 'SubjectRequirement') {
      openSidePanel(t('generalEducation.aboutTitle'), 'About', false);
    } else {
      closeSidePanel();
    }
  };

  useEffect(() => {
    if (firstRender) {
      setTitle(t('generalEducation.title'));
    }
  }, [firstRender, t]);

  useEffect(() => {
    if (!genEdReqData) {
      dispatch(getGeneralEducationRequirements());
    }
  }, [dispatch, genEdReqData]);

  useEffect(() => {
    dispatch(getGenEdProcess());
  }, [dispatch]);

  useEffect(() => {
    if (genEdReqData) {
      const assignmentsList = generateDefaultAssignments(genEdReqData, genEdCategories);
      setAssignments(assignmentsList);
    }
  }, [genEdReqData, genEdCategories]);

  /* istanbul ignore next */
  const handleApiFailure = (msg: string) => {
    if (msg && msg.includes('duplicated assignments')) {
      setShowDuplicateError(true);
    }
  };
  const postData = (data: IGenEdRequirementsConfig) => {
    dispatch(postGenEdProcess(sanitizePayload(Object.assign(data)), () => closeSidePanel(), handleApiFailure));
  };
  const onSubmitSidePanel = (data: IGenEdRequirementsConfig) => {
    setShowDuplicateError(false);
    if (Object.keys(dirtyFields).length) {
      setShowAlert(true);
      setSubmittedData(data);
    } else {
      postData(data);
    }
  };

  const generatefooterButtonConfig = () => {
    if (!sidePanelInformation?.showSaveButton) {
      return {
        justifyContent: 'center',
        tertiary: {
          title: t('close_label'),
          props: {
            'aria-label': t('close_label'),
            variant: 'text',
            onClick: closeSidePanel,
          },
        },
      };
    }

    return {
      primary: {
        title: t('save_label'),
        props: {
          'aria-label': t('save_label'),
          disabled: !methods.formState.isValid,
          onClick: methods.handleSubmit(onSubmitSidePanel),
        },
      },
      tertiary: {
        title: t('cancel_label'),
        props: {
          'aria-label': t('cancel_label'),
          onClick: closeSidePanel,
        },
      },
    };
  };

  const sidePanelProperties = () => {
    return {
      footerButtonConfig: { ...generatefooterButtonConfig() } as ISidePanelFooterProps['footerButtonConfig'],
    };
  };

  return (
    <Box>
      {genEdLoading && <Spinner backdrop />}
      <Box sx={{ width: '100%' }}>
        <FormProvider {...methods}>
          <form>
            <SidePanel
              title={sidePanelInformation?.title || ''}
              open={sidePanelInformation.isSidePanelOpen}
              onClose={closeSidePanel}
              isBackdropClickEnabled={true}
              onBackClick={sidePanelInformation.screenName === 'About' ? undefined : onBackClicked}
              {...sidePanelProperties()}
              sx={sxSidePanel}
            >
              {sidePanelInformation.screenName === 'About' && <About onViewDetailsClicked={openSidePanel} />}
              {sidePanelInformation.screenName === 'CourseAssignment' && (
                <CourseAssignment
                  assignments={assignments}
                  setShowDuplicateError={setShowDuplicateError}
                  showDuplicateError={showDuplicateError}
                />
              )}
              {sidePanelInformation.screenName === 'GoldenFour' && <GoldenFour />}
              {sidePanelInformation.screenName === 'SubjectRequirement' && (
                <SubjectRequirement openSidePanel={openSidePanel} closeSidePanel={closeSidePanel} />
              )}
              {sidePanelInformation.screenName === 'CourseDetails' && (
                <CourseDetails data={sidePanelInformation.data} />
              )}
            </SidePanel>
          </form>
        </FormProvider>
      </Box>
      <Grid container sx={{ pb: '1rem' }}>
        <Grid item md={1.5} sm={2.5} xs={3} xl={1} sx={{ display: 'flex', alignItems: 'flex-start' }}>
          {getMilestoneIcon(achievedMilestone?.name?.code, achievedMilestone?.name?.displayName || 'empty')}
        </Grid>
        <Grid item md={10.5} sm={9.5} xs={9} xl={11}>
          <Stack sx={{ height: '100%' }} direction="column" justifyContent="center">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              {isMobile ? (
                <Typography component="h1" variant="subtitle3">
                  {t('generalEducation.mobileHeading')}
                </Typography>
              ) : (
                <Typography component="h1" variant="h3">
                  {t('generalEducation.heading')}
                </Typography>
              )}
              <Button
                color="secondary"
                onClick={() => openSidePanel(t('generalEducation.aboutTitle'), 'About', false)}
                size={isSmallScreen ? 'small' : 'medium'}
                startIcon={<IconInfoSolid />}
                sx={{ p: 0 }}
              >
                {t('generalEducation.about')}
              </Button>
            </Stack>
            <LinearProgress
              sx={{ mt: 1 }}
              aria-label="LinearProgress"
              color="inherit"
              value={genEdProcessData?.totalCompletionPercentage || 0}
              variant="determinate"
            />
            <Typography sx={{ mt: 1 }} variant="body2">
              {`${genEdProcessData?.totalUnitsEarned || 0}/${getUnitsRequired(genEdProcessData, genEdReqData)} ${t(
                'generalEducationReqTable.units'
              )}`}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item>
          <StatusChip statusCode="notComplete" />
        </Grid>
        <Grid item>
          <StatusChip statusCode="inprogress" />
        </Grid>
        <Grid item>
          <StatusChip statusCode="completed" />
        </Grid>
        <Grid item>
          <StatusChip statusCode="goldenFour" />
        </Grid>

        {!isMobile ? (
          <Button
            variant="text"
            sx={{
              display: 'flex',
              height: '100% !important',
              alignItems: 'center',
              marginLeft: 'auto',
              paddingRight: isSmallScreen ? '0px' : 'inherit',
              marginTop: isSmallScreen ? '1rem' : 'inherit',
            }}
            onClick={() => openSidePanel(t('generalEducation.courseAssignmentsHeading'), 'CourseAssignment', true)}
          >
            {t('generalEducation.edit.courseAssignments').toUpperCase()}
          </Button>
        ) : (
          <IconButton
            size="small"
            edge="start"
            aria-label={t('generalEducation.courseAssignmentsEditAriaLabel')}
            color="inherit"
            sx={{ display: 'flex', alignItems: 'center', ml: 'auto', background: '#E3F2FD', mt: 1 }}
            onClick={() => openSidePanel(t('generalEducation.courseAssignmentsHeading'), 'CourseAssignment', true)}
          >
            <IconEdit />
          </IconButton>
        )}
      </Grid>
      <Grid container item>
        <Typography component="div" variant="body2" sx={{ pt: '1rem' }}>
          {t('generalEducation.chipInstructionalText')}
        </Typography>
      </Grid>
      <Grid container sx={{ mt: 2 }} spacing={1}>
        {(genEdReqData || [])?.map((reqConfig: TGenEdReq, index: number) => {
          const categoryDetail = genEdCategories?.[index];
          return (
            <Grid key={reqConfig.code} item sx={{ width: '100%' }}>
              <Accordion
                accordionHeader={<GenEdAccordianHeading config={reqConfig} categoryDetail={categoryDetail} />}
                accordionSummary={
                  <GenEdAccordianBody
                    config={reqConfig}
                    categoryDetail={categoryDetail}
                    onViewDetailsClicked={openSidePanel}
                  />
                }
                label={`${reqConfig?.code} - ${reqConfig?.category}`}
                id={`${reqConfig?.code?.split(' ').join('-')}`}
                expanded={false}
              />
            </Grid>
          );
        })}
      </Grid>
      <ConfirmationDialog
        open={showAlert}
        text={t('generalEducation.editCourseAssignment.confirmTitle')}
        confirmationText={t('generalEducation.editCourseAssignment.confirmContent')}
        onClose={() => {
          /* istanbul ignore next */
          setShowAlert(false);
        }}
        footerButtonConfig={{
          primary: {
            title: t('continue_label'),
            props: {
              onClick: () => {
                setShowAlert(false);
                postData(submittedData as IGenEdRequirementsConfig);
              },
              startIcon: null,
            },
          },
          tertiary: {
            title: t('goback_label'),
          },
        }}
      />
      <Cart />
    </Box>
  );
};

export default memo(GeneralEducation);
