/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { API } from 'constants/api';
import axios from 'axios';

export interface IImpersonationResponse {
  token: string;
  refreshToken: string;
  expiresIn: number;
}

export const refreshTokenImpersonation = (payload: IImpersonationResponse): Promise<IImpersonationResponse> => {
  const modifiedPayload = {
    refreshToken: payload?.refreshToken,
    accessToken: payload?.token,
  };
  return axios.post(`${API.auth}living-profile/impersonate/refresh`, modifiedPayload).then(tokenRefreshResponse => {
    localStorage.setItem('kc_impersonation_token', tokenRefreshResponse.data.accessToken);
    localStorage.setItem('kc_refresh_token', tokenRefreshResponse.data.refreshToken);
    axios.defaults.headers.common.Authorization = `Bearer ${tokenRefreshResponse.data.accessToken}`;
    return Promise.resolve(tokenRefreshResponse.data.accessToken);
  });
};

export const tokenImpersonation = async (payload: string): Promise<IImpersonationResponse> => {
  const modifiedPayload = {
    jwtToken: payload,
  };

  const impersonationToken = await axios.post(`${API.auth}living-profile/impersonate/`, modifiedPayload);
  return {
    token: impersonationToken?.data?.accessToken || '',
    refreshToken: impersonationToken?.data?.refreshToken || '',
    expiresIn: impersonationToken?.data?.expiresIn || '',
  };
};

export const refreshImpToken = (expiresIn: number): Promise<IImpersonationResponse> => {
  const impersonationTokens = {
    token: localStorage.getItem('kc_impersonation_token') as string,
    refreshToken: localStorage.getItem('kc_refresh_token') as string,
    expiresIn,
  };
  return refreshTokenImpersonation(impersonationTokens);
};
