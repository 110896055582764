/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useState, useMemo, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography, FormControl, Grid, InputLabel, Box, IconButton, InputBase } from '@mui/material';
import { Dropdown, MultiSelect, type TMultiSelectOption } from '@liaison/liaison-ui';

import { ReactComponent as Plus } from 'assets/svgs/plus.svg';
import { ReactComponent as Minus } from 'assets/svgs/minus.svg';
import { getDropDownOptions, getMultiSelectColor, isEmpty } from 'utils/utilities';
import { StyledFormHelperText } from 'pages/Pages.styles';
import { DISABLE_AUTO_FILL, MAX_LANGUAGE_SELECTION_ALLOWED } from 'constants/field';
import type {
  TAccomplishmentsAndExperienceField,
  TCompetency,
} from 'userProfile/store/accomplishmentAndExperience/accomplishmentAndExperience.slice';
import { selectLanguagesLookup, selectSkillsLookup, selectLoading } from 'store/common/commonInfo.selectors';
import { resetlookupData } from 'store/common/commonInfo.slice';
import { DEFAULT_LANGUAGES } from 'userProfile/constants/general';
import {
  defaultLanguage,
  getDropDownOptionsForAC,
  groupName,
  getFilteredLanguageOptions,
  getOptionsFromTags,
  getSkills,
  proficiencyLevelOptions,
} from '../AccomplishmentsAndExperiences.utils';

interface ILanguageInfoProps {
  data: TAccomplishmentsAndExperienceField | null;
}

type TFormErrors = {
  languages: {
    language: { code: { message: string } };
    proficiencyLevel: { code: { message: string } };
  }[];
};
const LanguagesAndSkills = (props: ILanguageInfoProps): ReactElement => {
  const { data } = props;
  const {
    control,
    reset,
    watch,
    setValue,
    getValues,
    register,
    formState: { errors: formErrors },
    trigger,
  } = useFormContext();
  const errors = formErrors as unknown as TFormErrors;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const watchedFieldsList = watch(['languages']);
  const languagesLookup = useSelector(selectLanguagesLookup);
  const skillsLookup = useSelector(selectSkillsLookup);
  const isLoading = useSelector(selectLoading);

  const [hideLanguagePlusButton, setHideLanguagePlusButton] = useState(false);
  const [skillsOptions, setSkillsOptions] = useState<TMultiSelectOption[]>([]);
  let languageCount = 0;
  const languageList = useFieldArray({
    control,
    name: 'languages',
  });
  const languageOptions = useMemo(() => getDropDownOptions(languagesLookup || []), [languagesLookup]);

  useEffect(() => {
    if (data) {
      reset(data);
      setValue('languages', data?.languages);
    } else {
      reset(DEFAULT_LANGUAGES);
    }
    return () => {
      reset({});
      dispatch(resetlookupData(['skills']));
    };
  }, [reset, data, setValue, dispatch]);

  useEffect(() => {
    if (!getValues('languages') && languageList?.fields?.length < 1) {
      languageList?.insert(
        0,
        {
          language: {},
          proficiencyLevel: { code: '', displayName: '' },
        },
        { shouldFocus: false }
      );
    }
  }, [getValues, languageList]);

  useEffect(() => {
    setSkillsOptions(getDropDownOptionsForAC(skillsLookup || []));
  }, [skillsLookup]);

  const handleSearch = (searchParam: string) => {
    if (searchParam?.length >= 2) {
      dispatch(getSkills(`?startsWith=${searchParam}&pageSize=10000`));
    }
  };

  const languageAddMoreClick = () => {
    languageList?.append(defaultLanguage);
    if (languageCount + 1 === MAX_LANGUAGE_SELECTION_ALLOWED) {
      setHideLanguagePlusButton(true);
    }
  };

  const LanguageContent = (languageIndex: number) => {
    languageCount += 1;
    const noLanguages = languageIndex === 0;
    return (
      <>
        {noLanguages && (
          <FormControl fullWidth>
            <Box sx={{ mt: 1, mb: -1, display: 'flex', flexDirection: 'row' }}>
              <InputLabel>{t('languagesAndSkills.languages')}</InputLabel>
            </Box>
          </FormControl>
        )}
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: 0.9 }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl fullWidth error={!!errors?.[groupName]?.[languageIndex]?.language?.code}>
                  <Controller
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Dropdown
                        id={`language-${languageIndex}`}
                        {...field}
                        inputRef={ref}
                        options={getFilteredLanguageOptions(languageIndex, watchedFieldsList[0], languageOptions)}
                        fullWidth
                        inputProps={{
                          'aria-label': t('languagesAndSkills.language'),
                          'aria-describedby': `language-error-${languageIndex}`,
                          ...DISABLE_AUTO_FILL,
                        }}
                        onChange={option => {
                          if (
                            isEmpty(option?.id) &&
                            isEmpty(getValues(`${groupName}.${languageIndex}.proficiencyLevel.code`))
                          ) {
                            setValue(`${groupName}.${languageIndex}.id`, undefined);
                          }
                          setValue(`${groupName}.${languageIndex}.language.displayName`, option?.text);
                          trigger(`${groupName}.${languageIndex}.proficiencyLevel.code`);
                          onChange(option?.id ?? '');
                        }}
                        placeholder={t('languagesAndSkills.language')}
                      />
                    )}
                    control={control}
                    name={`${groupName}.${languageIndex}.language.code`}
                  />
                  <StyledFormHelperText role="alert" id={`language-error-${languageIndex}`}>
                    {errors?.[groupName]?.[languageIndex]?.language?.code?.message}
                  </StyledFormHelperText>
                </FormControl>
                <InputBase
                  inputProps={{ type: 'hidden' }}
                  {...register(`${groupName}.${languageIndex}.language.displayName`)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth error={!!errors?.[groupName]?.[languageIndex]?.proficiencyLevel?.code}>
                  <Controller
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Dropdown
                        id={`proficiencyLevel-${languageIndex}`}
                        {...field}
                        inputRef={ref}
                        options={proficiencyLevelOptions}
                        fullWidth
                        inputProps={{
                          'aria-label': t('languagesAndSkills.proficiency'),
                          'aria-describedby': `proficiencyLevel-error-${languageIndex}`,
                          ...DISABLE_AUTO_FILL,
                        }}
                        onChange={option => {
                          if (
                            isEmpty(option?.id) &&
                            isEmpty(getValues(`${groupName}.${languageIndex}.language.code`))
                          ) {
                            setValue(`${groupName}.${languageIndex}.id`, undefined);
                          }
                          setValue(`${groupName}.${languageIndex}.proficiencyLevel.displayName`, option?.text);
                          trigger(`${groupName}.${languageIndex}.language.code`);
                          return onChange(option?.id ?? '');
                        }}
                        placeholder={t('languagesAndSkills.proficiency')}
                      />
                    )}
                    control={control}
                    name={`${groupName}.${languageIndex}.proficiencyLevel.code`}
                  />
                  <StyledFormHelperText role="alert" id={`proficiencyLevel-error-${languageIndex}`}>
                    {errors?.[groupName]?.[languageIndex]?.proficiencyLevel?.code?.message}
                  </StyledFormHelperText>
                </FormControl>
                <InputBase
                  inputProps={{ type: 'hidden' }}
                  {...register(`${groupName}.${languageIndex}.proficiencyLevel.displayName`)}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flex: 0.1 }}>
            {noLanguages ? (
              <>
                {!hideLanguagePlusButton ? (
                  <IconButton onClick={languageAddMoreClick} sx={{ p: 0, ml: '0.625rem' }}>
                    <Plus aria-label={t('languagesAndSkills.addLanguage')} />
                  </IconButton>
                ) : (
                  <Box sx={{ visibility: 'hidden' }}>
                    <IconButton sx={{ p: 0, ml: '0.625rem' }}>
                      <Plus aria-label={t('languagesAndSkills.addLanguage')} />
                    </IconButton>
                  </Box>
                )}
              </>
            ) : (
              <IconButton
                onClick={() => {
                  setHideLanguagePlusButton(false);
                  languageList?.remove(languageIndex);
                }}
                sx={{ p: 0, ml: '0.625rem' }}
              >
                <Minus aria-label={t('languagesAndSkills.remove')} />
              </IconButton>
            )}
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Grid sx={{ mt: '2rem' }}>
      <Typography
        variant="subtitle1"
        component="h1"
        sx={{
          textTransform: 'uppercase',
          mb: '0.625rem',
          letterSpacing: '0.6px',
          fontSize: '1.125rem',
        }}
      >
        {t('languagesAndSkills.title')}
      </Typography>
      {languageList?.fields?.map((language, index) => (
        <React.Fragment key={language?.id}>{LanguageContent(index)}</React.Fragment>
      ))}
      <FormControl fullWidth sx={{}}>
        <Typography
          variant="subtitle1"
          component="h1"
          sx={{
            textTransform: 'uppercase',
            mb: '0.625rem',
            letterSpacing: '0.6px',
            fontSize: '1.125rem',
          }}
        >
          {t('competenciesAndSkills.title')}
        </Typography>
        <InputLabel htmlFor="competencies">{t('competenciesAndSkills.competencies')}</InputLabel>
        <Controller
          // eslint-disable-next-line no-unused-vars
          render={({ field: { ref, onChange, value, ...field } }) => {
            const combinedOptions = [...skillsOptions, ...getOptionsFromTags(value || [])];
            const uniqueOptions = Array.from(new Set(combinedOptions.map(a => a.id))).map(id =>
              combinedOptions.find(a => a.id === id)
            );
            const selectedValues = value?.map((item: TCompetency) => item.competency);
            return (
              <MultiSelect
                {...field}
                id="competencies"
                freeSolo
                loading={isLoading}
                loadingText={t('languagesAndSkills.loading')}
                title={t('competenciesAndSkills.competencies')}
                options={uniqueOptions as TMultiSelectOption[]}
                value={selectedValues}
                aria-label={t('competenciesAndSkills.title')}
                maxOptionSelection={Infinity}
                onInputChange={handleSearch}
                onChange={options => {
                  const allIds = options?.map(item => ({ competency: item.id }));
                  return onChange(allIds);
                }}
                colorTheme={getMultiSelectColor()}
              />
            );
          }}
          control={control}
          name="competencies"
        />
      </FormControl>
    </Grid>
  );
};

export default memo(LanguagesAndSkills);
