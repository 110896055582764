/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import eligibility from 'transferPlanner/store/eligibility/eligibility.slice';
import programs from 'transferPlanner/store/programs/programs.slice';
import campuses from 'transferPlanner/store/campuses/campuses.slice';
import programCart from 'transferPlanner/store/programCart/programCart.slice';
import searchProgram from 'transferPlanner/store/searchProgram/searchProgram.slice';
import enrollment from 'transferPlanner/store/enrollment/enrollment.slice';
import adtPrograms from 'transferPlanner/store/adtPrograms/programs.slice';
import additionalDetails from 'transferPlanner/store/additionalDetails/additionalDetails.slice';
import genEd from 'transferPlanner/store/genEdProcess/genEdProcess.slice';
import timelineInfo from 'transferPlanner/store/timelineInfo/timelineInfo.slice';
import timelineCalculation from 'transferPlanner/store/timelineCalculation/timelineCalculation.slice';

export default {
  eligibility,
  enrollment,
  programs,
  searchProgram,
  programCart,
  campuses,
  adtPrograms,
  additionalDetails,
  genEd,
  timelineInfo,
  timelineCalculation,
};
