/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect, useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';
import { DEFAULT_SANITIZE_CONFIG } from 'constants/sanitize';
import { useDispatch } from 'react-redux';

import { nameSpace } from 'transferPlanner/constants/general';
import { Spinner } from 'components/Spinner';
import type { ICollege } from 'store/academicHistory/academicHistory.slice';
import { TFollowedProgramModified } from 'transferPlanner/store/programCart/programCart.slice';
import noFollowedProgram from 'assets/noFollowedProgram.png';
import { setUi } from 'store/ui/ui.slice';

import useTooltip from 'transferPlanner/hooks/useTooltip';
import { fetchAgreements } from './AdditionalAgreements.utils';
import { AgreementsCard } from './AgreementsCard';
import EmptySlate from '../EmptySlate/EmptySlate';

interface IAdditionalAgreements {
  followedProgram: TFollowedProgramModified | undefined;
  additionalCommunityColleges: ICollege[] | null | undefined;
  isEnrolled: boolean;
}

const AdditionalAgreements = ({
  followedProgram,
  additionalCommunityColleges = [],
  isEnrolled,
}: IAdditionalAgreements): ReactElement | null => {
  const { t } = useTranslation(nameSpace);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [agreementsData, setAgreementsData] = useState<(ICollege & { archivedArtifactUrl: string })[]>([]);
  const programId = followedProgram?.program?.id || 0;
  const campusName = followedProgram?.campus?.name || '';
  const { RenderTooltip } = useTooltip(
    <Typography
      variant="body2"
      sx={{ pt: '0.5rem' }}
      dangerouslySetInnerHTML={{
        __html: sanitize(t('additionalArticulation.subtitle2'), DEFAULT_SANITIZE_CONFIG),
      }}
    />,
    t('additionalArticulation.subtitle')
  );

  let content;
  useEffect(() => {
    if (additionalCommunityColleges?.length && programId) {
      setIsLoading(true);
      (async () => {
        const agreements = await fetchAgreements(
          programId,
          additionalCommunityColleges?.map(college => college.alternateId?.code).join(',')
        );
        if (agreements) {
          const communityColleges = additionalCommunityColleges.map(college => ({
            ...college,
            archivedArtifactUrl:
              agreements?.find(agreement => agreement.communityCollegeAlternateId === college.alternateId?.code)
                ?.archivedArtifactUrl || '',
          }));
          setAgreementsData(communityColleges);
        } else {
          setAgreementsData([]);
        }
        setIsLoading(false);
      })();
    }
  }, [additionalCommunityColleges, programId]);

  if (isLoading)
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Spinner />
      </Box>
    );

  if (additionalCommunityColleges && additionalCommunityColleges.length > 0) {
    content = agreementsData.map(({ name, address, archivedArtifactUrl, alternateId }) => (
      <Grid item xs={12} key={`${alternateId?.code}-${name}`} data-testid="additional-agreements">
        <AgreementsCard
          campusName={campusName.split(',').shift()}
          campusAddress={campusName.split(',').pop()}
          communityCollegeName={name as string}
          communityCollegeAddress={`${address?.city} ${address?.region.code}, ${address?.postalCode}`}
          artifactUrl={archivedArtifactUrl}
        />
      </Grid>
    ));
  } else {
    content = (
      <Grid item xs={12} columnSpacing={2}>
        <EmptySlate
          imageSource={noFollowedProgram}
          title={t('additionalArticulation.noCommunityCollege')}
          buttonText={t('add_college_label')}
          onClick={() => dispatch(setUi({ name: 'collegeSidebar', state: { open: true } }))}
        />
      </Grid>
    );
  }
  return (
    <Grid item xs={12} container rowSpacing={2}>
      <Grid item xs={12} columnSpacing={2}>
        <Typography variant="subtitle3">
          {isEnrolled ? t('additionalArticulation.enrolled_title') : t('additionalArticulation.nonEnrolled_title')}
        </Typography>
        {RenderTooltip()}
        <Typography
          variant="body2"
          sx={{ pt: '0.5rem' }}
          dangerouslySetInnerHTML={{
            __html: sanitize(t('additionalArticulation.subtitle'), DEFAULT_SANITIZE_CONFIG),
          }}
        />
      </Grid>
      {content}
      <Typography
        variant="body2"
        sx={{ pt: '0.5rem' }}
        dangerouslySetInnerHTML={{
          __html: sanitize(t('additionalArticulation.moreInfo'), DEFAULT_SANITIZE_CONFIG),
        }}
      />
    </Grid>
  );
};

export default AdditionalAgreements;
