/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { useState, useMemo, useCallback, memo, MouseEvent } from 'react';
import { IconButton } from '@mui/material';
import { MenuActions, IconTrash, IconEllipsisVertical, IconList, IconStocksSolid, IconStar } from '@liaison/liaison-ui';
import { ISelectedProgram, TFollowedProgramModified } from 'transferPlanner/store/programCart/programCart.slice';
import { selectActiveTspEnrollment } from 'transferPlanner/store/enrollment/enrollment.selectors';
import { useAppSelector } from 'redux/hooks';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'transferPlanner/constants/general';
import { IconStarOff } from 'transferPlanner/assets/svgs';
import { IMenuActionsItemProps } from '@liaison/liaison-ui/dist/components/MenuActions';
import { selectTspEligibleFlags } from 'transferPlanner/store/programs/programs.selectors';

interface IMenuProps {
  onClick: (menuOption: string, currentProgram: ISelectedProgram | TFollowedProgramModified) => void;
  currentProgram: ISelectedProgram | TFollowedProgramModified;
  isSavedProgram: boolean;
}

const Menu = ({ onClick, currentProgram, isSavedProgram }: IMenuProps) => {
  const { t } = useTranslation(nameSpace);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const activeEnrolledProgramId = useAppSelector(selectActiveTspEnrollment)?.program?.id;
  const tspEligibleFlags = useAppSelector(selectTspEligibleFlags);

  const isProgramEnrolled = useMemo(() => {
    return (activeEnrolledProgramId && currentProgram?.program?.id === activeEnrolledProgramId) as boolean;
  }, [activeEnrolledProgramId, currentProgram]);

  const isProgramDeleted = useMemo(
    () => (tspEligibleFlags || []).some(enrollment => enrollment?.id === currentProgram?.program?.id),
    [currentProgram, tspEligibleFlags]
  );

  const onClicked = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMenuItems = useCallback(() => {
    let itemList: IMenuActionsItemProps[] = [];
    itemList = [
      {
        icon: <IconList data-testid="programDetails" />,
        text: 'Program Details',
        onClick: () => {
          onClick('Program Details', currentProgram);
          handleClose();
        },
        disabled: !isProgramDeleted,
      },
    ];

    if (isSavedProgram) {
      itemList.push({
        icon: <IconStar data-testid="follow" />,
        text: 'Follow',
        onClick: () => {
          onClick('Follow', currentProgram);
          handleClose();
        },
      });
    } else {
      itemList.push({
        icon: <IconStocksSolid data-testid="viewProgress" />,
        text: 'View Progress',
        onClick: () => {
          onClick('View Progress', currentProgram);
          handleClose();
        },
      });
      itemList.push({
        icon: <IconStarOff data-testid="unfollow" />,
        text: 'Unfollow',
        onClick: () => {
          onClick('Unfollow', currentProgram);
          handleClose();
        },
        disabled: isProgramEnrolled,
      });
    }

    itemList.push({
      icon: <IconTrash data-testid="remove" />,
      text: 'Remove',
      onClick: () => {
        onClick('Remove', currentProgram);
        handleClose();
      },
      disabled: isProgramEnrolled,
    });
    return itemList;
  }, [isProgramDeleted, isSavedProgram, isProgramEnrolled, onClick, currentProgram]);

  return (
    <>
      <IconButton
        aria-label={`${currentProgram.program.name} ${t('programs.more_options')}`}
        id={`menu-btn-${currentProgram.program.id}`}
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={onClicked}
        color="secondary"
      >
        <IconEllipsisVertical id={currentProgram.program.name} />
      </IconButton>
      <MenuActions open={open} onClose={handleClose} anchorEl={anchorEl} menuItems={getMenuItems()} />
    </>
  );
};

export default memo(Menu);
