/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Components, Theme } from '@mui/material';

export default ({ palette }: Theme): Components => ({
  MuiFormLabel: {
    styleOverrides: {
      root: {
        [`&.Mui-error`]: {
          color: palette.text.primary,
        },
      },
    },
  },
});
