/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TError = string | null;

export interface Program {
  id?: string;
  text?: string;
  name?: string;
  alternateIds?: {
    id: number;
    source: string;
  }[];
}

export interface Programs {
  [i: string]: Program[] | undefined;
}

export interface IProgramsState {
  loading: boolean;
  programs: Programs | null;
  error: TError;
}

export interface Payload {
  i: string;
  data: Program[];
}

export const initialState: IProgramsState = {
  loading: false,
  programs: null,
  error: null,
};

const programSlice = createSlice({
  name: 'adtPrograms',
  initialState,
  reducers: {
    programsStart: (state): IProgramsState => ({
      ...state,
      loading: true,
      error: null,
    }),
    programsSuccess: (state, action: PayloadAction<Payload>): IProgramsState => {
      const { i, data } = action.payload;
      const programs = { ...state.programs, [i]: data };

      return {
        ...state,
        programs,
        loading: false,
        error: null,
      };
    },
    programsFailure: (state, action: PayloadAction<TError>): IProgramsState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    resetProgram: (state, action?: PayloadAction<string>): IProgramsState => {
      let programs: Programs | null = { ...state.programs };

      /* istanbul ignore next */
      if (action?.payload) {
        delete programs[action?.payload];
      } else {
        programs = null;
      }

      return {
        ...state,
        programs,
      };
    },
  },
});

export const { programsStart, programsFailure, programsSuccess, resetProgram } = programSlice.actions;

export default programSlice.reducer;
