/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Components } from '@mui/material';

export default (): Components => ({
  MuiBackdrop: {
    styleOverrides: {
      root: {
        backdropFilter: 'blur(0.1rem)',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
    },
  },
});
