/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, SyntheticEvent } from 'react';
import { Box } from '@mui/material';
import { IMediaDoc } from 'userProfile/store/mediaDocuments/mediaDocuments.slice';
import MediaDocumentCardItem from './MediaDocumentCardItem';

type TMediaDocumentListViewProps = {
  mediaDocs: IMediaDoc[];
  onToggleDrawer: (e: SyntheticEvent, mediaDoc: IMediaDoc | null) => void;
  onClickDeleteMediaDoc: (mediaDoc: IMediaDoc) => void;
  onToggleMediaDocPreview: (index: number) => void;
};

const MediaDocumentCardView = ({
  mediaDocs,
  onToggleDrawer,
  onClickDeleteMediaDoc,
  onToggleMediaDocPreview,
}: TMediaDocumentListViewProps): ReactElement => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 1fr))',
        gap: '30px',
      }}
    >
      {mediaDocs?.map((mediaDoc, index) => (
        <MediaDocumentCardItem
          key={mediaDoc.id}
          mediaDoc={mediaDoc}
          onToggleDrawer={onToggleDrawer}
          onClickDeleteMediaDoc={onClickDeleteMediaDoc}
          onToggleMediaDocPreview={onToggleMediaDocPreview}
          index={index}
        />
      ))}
    </Box>
  );
};

export default MediaDocumentCardView;
