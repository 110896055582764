import { IAdtInformation } from 'transferPlanner/store/additionalDetails/additionalDetails.slice';
import { IAdtDetails } from 'transferPlanner/store/programCart/programCart.slice';

export const getRemainingTerms = (enrollmentStartTerm: string, enrollmentStartYear: number): number => {
  const today = new Date();
  const validEnrollmentYears = 3;
  const termTypes = ['SPRING', 'FALL'];
  const enrollmentEndYear =
    enrollmentStartYear + (enrollmentStartTerm === termTypes[1] ? validEnrollmentYears : validEnrollmentYears - 1);
  const currentTerm = today.getMonth() >= 5 ? termTypes[1] : termTypes[0];
  const currentYear = today.getFullYear();
  const posibleTerms = [];

  if (enrollmentStartYear > currentYear) {
    return validEnrollmentYears * termTypes.length;
  }

  if (enrollmentStartYear <= currentYear && enrollmentEndYear >= currentYear) {
    for (let i = enrollmentStartYear; i <= enrollmentEndYear; i += 1) {
      let flag = true;
      if (i === enrollmentStartYear && enrollmentStartTerm === termTypes[1]) {
        posibleTerms.push(`${termTypes[1]} ${enrollmentStartYear}`);
        flag = false;
      }
      if (i === enrollmentEndYear && enrollmentStartTerm === termTypes[1]) {
        posibleTerms.push(`${termTypes[0]} ${enrollmentEndYear}`);
        flag = false;
      }
      if (flag) {
        termTypes.forEach(term => {
          posibleTerms.push(`${term} ${i}`);
        });
      }
    }
  }

  const currentTermIndex = posibleTerms.findIndex(term => term === `${currentTerm} ${currentYear}`);
  return currentTermIndex === -1 ? 0 : validEnrollmentYears * termTypes.length - currentTermIndex;
};

export interface IProgramAccordionStatus {
  pid: number;
  isSimilar: boolean;
  collegeName: string | undefined;
  programName: string | undefined;
}

export interface IProgramStatus {
  currentProgramStatus: IProgramAccordionStatus[] | undefined;
  adtInformation: IAdtInformation[] | undefined;
}

export const processSimilarityObj = (
  similarityObj: IAdtDetails[],
  adtInformation: IAdtInformation[]
): IProgramAccordionStatus[] => {
  const accordionData: IProgramAccordionStatus[] = [];
  similarityObj?.forEach(smObj => {
    const adtObject = adtInformation?.find(
      item =>
        item?.communityCollege?.alternateIds?.some(
          (alt: { id: number }) => alt.id === smObj?.communityCollegeAssistId
        ) && item?.program?.alternateIds?.some((alt: { id: number }) => alt.id === smObj?.programTMCKey)
    );
    if (adtObject?.communityCollege.name !== undefined && adtObject?.program.name !== undefined) {
      accordionData.push({
        pid: smObj.programTMCKey,
        isSimilar: smObj.isSimilar,
        collegeName: adtObject?.communityCollege.name,
        programName: adtObject?.program.name,
      });
    }
  });
  return accordionData;
};
