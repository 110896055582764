/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export const EXTENDED_SANITIZE_CONFIG = {
  ADD_ATTR: ['target'],
  FORBID_ATTR: ['color', 'style'],
  FORBID_TAGS: ['font'],
  ADD_TAGS: ['iframe'],
};

export const DEFAULT_SANITIZE_CONFIG = {
  ADD_ATTR: ['target'],
};
