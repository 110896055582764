/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, ReactNode } from 'react';
import { Grid, Skeleton, Typography } from '@mui/material';

type TOverviewItem = {
  title: string;
  icon: ReactNode;
  value?: number;
  isLoading?: boolean;
};

export const OverviewItem = ({ title, icon, value = 0, isLoading = false }: TOverviewItem): ReactElement => (
  <Grid item container>
    <Grid item sm={12} md={5.1}>
      {icon}
    </Grid>
    <Grid item sm={12} md={0.8} />
    <Grid item sm={12} md={5.5}>
      <Typography variant="body2" sx={{ fontSize: 14 }}>
        {title}
      </Typography>
      {isLoading ? (
        <Skeleton animation="wave" height={50} width="100%" data-testid="OverviewItem" />
      ) : (
        <Typography
          variant="body2"
          sx={{
            fontSize: 36,
            '@media (min-width:1500px)': {
              fontSize: 48,
            },
          }}
        >
          {value}
        </Typography>
      )}
    </Grid>
  </Grid>
);
