/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */
import { Theme } from '@mui/material';
import { isCsuTenant } from 'utils/utilities';
import MuiAutocomplete from '../overrides/MuiAutocomplete';
import MuiCssBaseline from '../overrides/MuiCssBaseline';
import MuiFormControl from '../overrides/MuiFormControl';
import MuiFormLabel from '../overrides/MuiFormLabel';
import MuiBackdrop from '../overrides/MuiBackdrop';
import MuiSwitch from '../overrides/MuiSwitch';
import MuiInputBaseCSU from '../overrides/MuiInputBaseCSU';
import MuiInputBase from '../overrides/MuiInputBase';
import MuiCard from '../overrides/MuiCard';

export default (theme: Theme): Theme => {
  return {
    ...theme,
    components: {
      ...theme.components,
      ...MuiCssBaseline(theme),
      ...MuiFormControl(),
      ...MuiFormLabel(theme),
      ...MuiAutocomplete(theme),
      ...MuiBackdrop(),
      ...MuiSwitch(theme),
      ...(isCsuTenant() ? MuiInputBaseCSU(theme) : MuiInputBase(theme)),
      ...MuiCard(theme),
    },
  };
};
