/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { styled } from '@mui/material';
import PhoneInput from 'react-phone-number-input';

export const StyledPhoneInput = styled(PhoneInput)(({ theme: { palette } }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .PhoneInputCountry': {
    height: '2rem',
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    marginRight: '0.6rem',
    position: 'relative',
    '&SelectArrow': {
      display: 'block',
      content: '""',
      width: '0.5rem',
      height: '0.5rem',
      marginLeft: '0.6rem',
      borderStyle: 'solid',
      borderColor: 'inherit',
      borderTopWidth: 0,
      borderBottomWidth: '0.1rem',
      borderLeftWidth: 0,
      borderRightWidth: '0.1rem',
      transform: 'rotate(45deg)',
      opacity: 0.45,
      marginBottom: '0.2rem',
    },
    '&Icon': {
      width: '2rem',
      height: '1.3rem',
      '&--square': {
        width: '1.9rem',
      },
      '& .PhoneInputInternationalIconGlobe': {
        opacity: 0.65,
      },
    },
    '&Select': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      zIndex: 1,
      border: 0,
      opacity: 0,
      '& + .PhoneInputCountryIcon--border': {
        outline: `1px solid ${palette.grey[600]}`,
      },
      '&:focus + .PhoneInputCountryIcon': {
        color: palette.primary.main,
        '&--border': {
          outline: `1px solid ${palette.primary.main}`,
        },
        '& + .PhoneInputCountrySelectArrow': {
          borderColor: palette.primary.main,
          opacity: 1,
          borderRightWidth: '0.12rem',
          borderBottomWidth: '0.12rem',
        },
      },
    },
  },
}));
