/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { lazy } from 'react';

export const dynamicImport = <P extends {}>(
  path: string,
  parentPath: string
): Promise<{ default: React.ComponentType<P> }> => {
  return parentPath
    ? import(`../../${parentPath}/${path}`).then(module => ({
        default: module[path],
      }))
    : import(`../${path}`).then(module => ({
        default: module[path],
      }));
};

export const withDynamicImport = <P extends {}>(path: string, parentPath = ''): React.ComponentType<P> => {
  return lazy(() => dynamicImport(path, parentPath));
};

export const nonKeycloakPathsRegex = [/^\/lp-student-activation$/, /^\/shared-profile-view\/[^/]+$/];
