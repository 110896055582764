/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import React, { useState, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import type { UseFormRegister } from 'react-hook-form';
import {
  Box,
  Button,
  Grid,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  tableCellClasses,
  IconButton,
  useMediaQuery,
  type Theme,
} from '@mui/material';
import { IconEdit, IconTrash, IconCaretDownDouble, IconCaretUpDouble } from '@liaison/liaison-ui';

import { tableStyles } from 'pages/Pages.styles';
import { getButtonColor } from 'utils/utilities';
import { textEllipsis, wrapText } from 'userProfile/components/ViewBuilder/ViewBuilder.styles';
import { getThumbUrl } from 'userProfile/pages/MediaDocuments/MediaDocuments.utils';
import type { IMediaDoc } from 'userProfile/store/mediaDocuments/mediaDocuments.slice';

import { DEFAULT, EFileSource } from './ImageList.utils';

type ImageListProps = {
  options: IMediaDoc[];
  hasRadioButton?: boolean;
  imageFileId?: string;
  register?: UseFormRegister<{
    fileSource: EFileSource;
    imageFileId: string;
  }>;
  onEdit?: (doc: IMediaDoc) => void;
  onDelete?: (doc: IMediaDoc) => void;
};

export const ImageList = ({
  options,
  hasRadioButton,
  imageFileId,
  register,
  onEdit,
  onDelete,
}: ImageListProps): ReactElement => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  const optionsToShow = isExpanded ? options : options.slice(0, 3);

  const onShowMore = () => {
    setIsExpanded(value => !value);
  };

  return (
    <Table
      aria-label="table header"
      sx={{
        ...tableStyles.font,
        borderCollapse: 'unset',
        border: '1px solid',
        borderColor: theme => theme.palette.grey[300],
        borderRadius: '8px',
        mt: 0.5,
      }}
      tabIndex={0}
    >
      <TableBody
        sx={{
          [`& .${tableCellClasses.root}`]: {
            py: 1,
          },
        }}
      >
        {optionsToShow?.map(option => {
          const { id, name, variants } = option;
          return (
            <TableRow key={id}>
              <TableCell align="left" sx={{ px: { xs: 1, sm: 2 } }}>
                <Grid container spacing={{ xs: 0.5, sm: 2 }} alignItems="center">
                  {hasRadioButton && (
                    <Grid item xs="auto">
                      <Radio
                        id={id}
                        value={id}
                        checked={imageFileId === id}
                        inputProps={{ 'aria-label': 'select banner image' }}
                        {...register?.('imageFileId')}
                      />
                    </Grid>
                  )}
                  <Grid item xs="auto">
                    <Box
                      component="img"
                      src={getThumbUrl(variants)}
                      alt={`${name} picture`}
                      sx={{
                        height: { xs: 50, sm: 70 },
                        width: { xs: 50, sm: 70 },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="subtitle3"
                      component="p"
                      sx={{
                        ml: { xs: 0.5, sm: 0 },
                        wordBreak: 'break-word',
                        ...(isMobile ? { ...wrapText, ...textEllipsis } : {}),
                      }}
                    >
                      {name}
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    {hasRadioButton && id !== DEFAULT && (
                      <IconButton color="error" size="medium" aria-label="delete" onClick={() => onDelete?.(option)}>
                        <IconTrash />
                      </IconButton>
                    )}
                    {id !== DEFAULT && (
                      <IconButton color="primary" size="medium" aria-label="edit" onClick={() => onEdit?.(option)}>
                        <IconEdit />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          );
        })}
        {options?.length > 3 && (
          <TableRow>
            <TableCell>
              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    variant="text"
                    color={getButtonColor()}
                    endIcon={isExpanded ? <IconCaretUpDouble /> : <IconCaretDownDouble />}
                    onClick={onShowMore}
                  >
                    {t(`show.${isExpanded ? 'less' : 'more'}`)}
                  </Button>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
