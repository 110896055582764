/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ForwardedRef, forwardRef, ReactElement, useEffect } from 'react';
import { SliderProps, Typography, Stack, Grid, Tooltip, IconButton, Slider } from '@mui/material';
import { Icon } from '@liaison/liaison-ui';

export interface IScaleProps extends SliderProps {
  title?: string;
  labelDirection?: 'top' | 'bottom';
  formatValue?: (value: number) => string;
  onScaleChange?: (value: number) => void;
  tooltipText?: string;
  initialValue?: number;
}

const Scale = forwardRef(
  (
    { title, labelDirection, formatValue, tooltipText, onScaleChange, initialValue = 10, ...props }: IScaleProps,
    ref: ForwardedRef<HTMLSpanElement>
  ): ReactElement => {
    const [value, setValue] = React.useState<number>(initialValue);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const handleChange = (_event: Event, newValue: number | number[]) => {
      if (typeof newValue === 'number') {
        setValue(newValue);
        onScaleChange?.(newValue);
      }
    };

    return (
      <Stack
        // data-testid={generateTestId('Scale')}
        flexDirection={labelDirection === 'bottom' ? 'column-reverse' : 'column'}
      >
        {title && (
          <Grid container spacing={0.5} alignItems="center">
            <Stack direction="row" aria-live="polite" role="alert" tabIndex={0}>
              <Grid item>
                <Typography variant="subtitle3" role="none" gutterBottom sx={{ mr: 0.5 }}>
                  {title}:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle4" id="slider-title" gutterBottom>
                  {formatValue ? formatValue(value) : value}
                </Typography>
              </Grid>
            </Stack>
            {tooltipText && (
              <Grid item>
                <Tooltip
                  aria-label={tooltipText}
                  title={<Typography variant="body3">{tooltipText}</Typography>}
                  placement="top-end"
                  arrow
                >
                  <IconButton>
                    <Icon>IconInfoSolid</Icon>
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        )}
        <Slider
          aria-label="LCL Slider"
          valueLabelDisplay="auto"
          onChange={handleChange}
          ref={ref}
          value={value}
          color="primary"
          sx={{ '& .MuiSlider-valueLabelLabel': { fontSize: '0.85rem' } }}
          {...props}
        />
      </Stack>
    );
  }
);

export default Scale;
