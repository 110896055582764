/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { useAppSelector } from 'redux/hooks';
import { selectFeatures } from 'store/features/features.selectors';
import { MainNavigation } from 'pages/MainNavigation';
import { transferPlannerRoutes } from 'transferPlanner/routes';
import { lpStudentRoutes, lpStudentOnlyRoutes } from 'userProfile/routes';
import { RequireAuth } from 'components/RequireAuth';
import { BackgroundImgLayout } from 'components/BackgroundImgLayout';
import { AlternateIdLinking } from 'pages/AlternateIdLinking';
import { ProfileSetup } from 'pages/ProfileSetup';
import { isCsuTenant, isLiaisonTenant } from 'utils/utilities';
import Logout from 'pages/KeyCloakRedirections/Logout';
import { withDynamicImport } from './Routes.util';

const CompleteProfile = withDynamicImport('CompleteProfile');
const NotFound = withDynamicImport('NotFound');
const EmailVerified = withDynamicImport('EmailVerified');
const Terms = withDynamicImport('Terms');
const Impersonation = withDynamicImport('Impersonation');

const LoginRedirecting = React.lazy(() => import('../KeyCloakRedirections/LoginRedirecting'));

const AppRoutes = (): ReactElement => {
  const features = useAppSelector(selectFeatures);

  const mainRoutes = {
    path: '/',
    children: [
      { path: '*', element: <Navigate to="/404-not-found" /> },
      { path: '/', element: <LoginRedirecting /> },
      { path: 'email-verified', element: <EmailVerified /> },
      { path: '404-not-found', element: <NotFound /> },
      { path: 'impersonation', element: <Impersonation /> },
      { path: 'logout', element: <Logout /> },
    ],
  };

  const commonRoutesWithAuth = {
    element: <RequireAuth />,
    children: [
      {
        element: <BackgroundImgLayout />,
        children: [
          { path: '/terms', element: <Terms /> },
          { path: '/linking', element: <AlternateIdLinking /> },
          { path: '/profile-setup', element: <ProfileSetup /> },
        ],
      },
      { path: '/onboarding', element: <CompleteProfile /> },
    ],
  };

  const appRoutes = {
    element: <RequireAuth />,
    children: [
      { path: '*', element: <Navigate to="/404-not-found" /> },
      {
        children: [
          {
            element: <MainNavigation />,
            children: [
              ...lpStudentRoutes(features),
              ...(isLiaisonTenant() ? lpStudentOnlyRoutes(features) : []),
              ...(isCsuTenant() ? transferPlannerRoutes(features) : []),
            ],
          },
        ],
      },
    ],
  };

  const routing = useRoutes([mainRoutes, appRoutes, commonRoutesWithAuth]);
  return <Suspense>{routing}</Suspense>;
};

export default AppRoutes;
