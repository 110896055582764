/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */
import React, { ReactElement } from 'react';
import { Box, Grid, Stack, Typography, Card, CardContent, Divider, IconButton, useMediaQuery } from '@mui/material';

import type { TAchievement, TExperience } from 'userProfile/store/profileView/profileView.slice';
import { sxCard } from 'pages/Pages.styles';
import { textEllipsis, wrapText } from 'userProfile/components/ViewBuilder/ViewBuilder.styles';
import { displayDateRange } from 'userProfile/components/ViewBuilder/ViewBuilder.utils';
import { displayDate, trimHeader } from 'utils/utilities';
import { Icon, IconCaretRight } from '@liaison/liaison-ui';
import { FIELD_LENGTH_20, FIELD_LENGTH_25, FIELD_LENGTH_40, MAX_LENGTH_COMMUNITY_VIEW } from 'constants/field';
import { Theme } from '@mui/system';

type TItemCardProps = {
  item?: TAchievement | TExperience;
  toggleDrawer: (item?: TAchievement | TExperience, toggle?: string) => void;
};

export const ItemCard = ({ item, toggleDrawer }: TItemCardProps): ReactElement => {
  const isExperience = Boolean((item as TExperience)?.startDate);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  return (
    <Grid item xs={12} sm={12} md={6} lg={4}>
      <Card variant="outlined" sx={sxCard}>
        <CardContent style={{ height: '180px', overflow: 'hidden' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ height: '85px' }}>
            <Stack spacing={0.5} padding={0.5} flexGrow={1}>
              <Typography variant="subtitle3" sx={{ pt: '0.3rem', display: 'block', ...wrapText }}>
                {trimHeader(
                  isExperience ? (item?.name as string) : (item as TAchievement)?.source,
                  isMobile && item?.attachedCount ? FIELD_LENGTH_25 : FIELD_LENGTH_40
                )}
              </Typography>
              <Typography variant="caption" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {trimHeader(
                  (item as TExperience)?.type?.displayName || item?.name || '',
                  isMobile && item?.attachedCount ? FIELD_LENGTH_20 : MAX_LENGTH_COMMUNITY_VIEW
                )}
              </Typography>
              <Typography variant="caption" sx={{ pt: '0.3rem', fontWeight: '600' }}>
                {isExperience
                  ? displayDateRange((item as TExperience)?.startDate, (item as TExperience)?.endDate)
                  : (item as TAchievement).receivedDate && displayDate((item as TAchievement).receivedDate)}
              </Typography>
            </Stack>
            {!!item?.attachedCount && (
              <IconButton sx={{ p: 0.5 }} size="medium" aria-label="icon-files" onClick={() => toggleDrawer(item)}>
                <Icon sx={{ color: '#311B92' }}>IconFiles</Icon>
              </IconButton>
            )}
            {!!item?.competencyAttachedCount && (
              <IconButton sx={{ p: 0.5 }} size="medium" aria-label="icon-light-bulb" onClick={() => toggleDrawer(item)}>
                <Icon sx={{ color: '#0277BD' }}>IconLightbulb</Icon>
              </IconButton>
            )}
            <IconButton
              sx={{
                p: 0.9,
                alignSelf: 'center',
              }}
              role="button"
              aria-label={isExperience ? 'OpenExperienceDetails' : 'OpenAchievementDetails'}
              onClick={() => {
                toggleDrawer(item);
              }}
            >
              <IconCaretRight />
            </IconButton>
          </Box>

          <Divider
            sx={{
              borderStyle: 'solid',
              mt: '0.7rem',
              mb: '0.7rem',
              borderColor: theme => theme.palette.grey[500],
            }}
            flexItem
          />
          <Typography variant="caption" sx={{ pt: '0.3rem', ...wrapText, ...textEllipsis }}>
            {item?.description || 'No description.'}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};
