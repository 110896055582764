import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  gpaCalculationSelector,
  selectGenEdLoading,
  selectMilestonesData,
} from 'transferPlanner/store/genEdProcess/genEdProcess.selectors';
import {
  getGenEdMilestoneProcess,
  getGpaCalculation,
} from 'transferPlanner/pages/GeneralEducation/GeneralEducation.utils';
import { getAcademicHistory, getAllCourseExtensions } from 'utils/AcademicHistory.utils';
import { getStandardTests } from 'utils/standardTests.utils';
import { selectAcademicHistory, selectCoursesExtensions } from 'store/academicHistory/academicHistory.selectors';
import { selectStandardTests } from 'store/standardTests/standardTests.selectors';
import { IGenEdMilestone, IGpaCalculation } from 'transferPlanner/store/genEdProcess/genEdProcess.slice';
import { selectGeneralEducationRequirements } from 'store/common/commonInfo.selectors';
import { getGeneralEducationRequirements } from 'transferPlanner/components/GeneralEducationReqTable/GeneralEducationReqTable.utils';
import { isFeatureActive } from 'utils/features.utils';
import { selectFeatures } from 'store/features/features.selectors';

type TUseTransferProgress = {
  getActiveSteps: () => number;
  gpaCalculation: IGpaCalculation | null;
  isLoading: boolean;
  milestoneData: IGenEdMilestone[] | null;
  isFeatureActiveForGPA: boolean;
  unitsEarnedOrCompleted: number;
  transferUnitLabel: string;
};

export const useTransferProgress = (isTransferProgressTile = false): TUseTransferProgress => {
  const [unitsEarnedOrCompleted, setUnitsEarnedOrCompleted] = useState(0);
  const [transferUnitLabel, setTransferUnitLabel] = useState('transfer_units_completed_label');
  const gpaCalculation = useSelector(gpaCalculationSelector);
  const academicHistoryData = useSelector(selectAcademicHistory);
  const standardTests = useSelector(selectStandardTests);
  const milestoneData = useSelector(selectMilestonesData);
  const coursesExtensions = useSelector(selectCoursesExtensions);
  const genEdReqData = useSelector(selectGeneralEducationRequirements);
  const dispatch = useDispatch();
  const isLoading = useSelector(selectGenEdLoading);
  const getMileStoneStatus = useCallback(
    (name: string) =>
      (milestoneData || [])?.some(
        ({ status, name: { code } }: IGenEdMilestone): boolean => name === code && status === 'COMPLETE'
      ),
    [milestoneData]
  );
  const features = useSelector(selectFeatures);
  const isFeatureActiveForGPA = !isFeatureActive(features, 'UNITS_COMPLETED_HIDDEN');

  useEffect(() => {
    if (isFeatureActiveForGPA) {
      setUnitsEarnedOrCompleted(gpaCalculation?.transferGPA?.unitsEarned || 0);
      setTransferUnitLabel('transfer_units_earned_label');
    } else {
      setUnitsEarnedOrCompleted(gpaCalculation?.transferGPA?.unitsCompleted || 0);
      setTransferUnitLabel('transfer_units_completed_label');
    }
  }, [gpaCalculation?.transferGPA?.unitsCompleted, gpaCalculation?.transferGPA?.unitsEarned, isFeatureActiveForGPA]);

  useEffect(() => {
    if (!academicHistoryData && !isTransferProgressTile) dispatch(getAcademicHistory());
  }, [dispatch, academicHistoryData, isTransferProgressTile]);

  useEffect(() => {
    if (!standardTests) dispatch(getStandardTests());
  }, [dispatch, standardTests]);

  useEffect(() => {
    if (academicHistoryData) {
      dispatch(getGenEdMilestoneProcess());
    }
  }, [dispatch, academicHistoryData]);

  useEffect(() => {
    if (academicHistoryData) {
      dispatch(getGpaCalculation());
    }
  }, [dispatch, academicHistoryData]);

  useEffect(() => {
    if (!genEdReqData && !isTransferProgressTile) {
      dispatch(getGeneralEducationRequirements());
    }
  }, [dispatch, genEdReqData, isTransferProgressTile]);

  useEffect(() => {
    if (!coursesExtensions && isTransferProgressTile) {
      dispatch(getAllCourseExtensions());
    }
  }, [dispatch, coursesExtensions, isTransferProgressTile]);

  const getActiveSteps = useCallback((): number => {
    const isA2Completed = getMileStoneStatus('A2_COMPLETE');
    const isB4Completed = getMileStoneStatus('B4_COMPLETE');
    const isGolden4Completed = getMileStoneStatus('GOLDEN_4_COMPLETE');
    const isAllStatusCompleted = isGolden4Completed && unitsEarnedOrCompleted >= 30;
    const isA2B4Completed = isA2Completed && isB4Completed;
    /* istanbul ignore next */
    if (isTransferProgressTile) {
      if (isAllStatusCompleted) return 2;
      if (isGolden4Completed) return 1.4;
      if (isA2B4Completed) return 1;
    } else {
      if (isAllStatusCompleted) return 3;
      if (isGolden4Completed) return 2;
      if (isA2B4Completed) return 1;
    }
    return 0;
  }, [getMileStoneStatus, unitsEarnedOrCompleted, isTransferProgressTile]);

  return {
    getActiveSteps,
    gpaCalculation,
    isLoading,
    milestoneData,
    isFeatureActiveForGPA,
    unitsEarnedOrCompleted,
    transferUnitLabel,
  };
};
