/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, accordionSummaryClasses } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { SidePanel, CollapsibleSection as Accordion, IconAdd } from '@liaison/liaison-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { sxSidePanel, sxIconButton } from 'pages/Pages.styles';
import {
  selectAccomplishmentAndExperience,
  selectLoading,
  selectAccomplishmentAndExperienceLocalData,
} from 'userProfile/store/accomplishmentAndExperience/accomplishmentAndExperience.selectors';
import { ViewBuilder } from 'userProfile/components/ViewBuilder';
import { PageTitle } from 'components/PageTitle';
import { Spinner } from 'components/Spinner';
import {
  TAccomplishmentsAndExperienceField,
  accomplishmentAndExperienceSaveLocalData,
  accomplishmentAndExperienceResetLocalData,
  resetSkillsAttachments,
} from 'userProfile/store/accomplishmentAndExperience/accomplishmentAndExperience.slice';
import { getButtonColor, sanitizePayload } from 'utils/utilities';
import { getLookUps, setTitle } from 'utils/commonUtils';
import {
  selectLanguagesLookup,
  selectCountriesLookup,
  selectExperienceTypesLookup,
  selectAchievementEnumTypes,
} from 'store/common/commonInfo.selectors';
import { DEFAULT_LANGUAGES } from 'userProfile/constants/general';
import config from 'userProfile/appConfig/accomplishments.json';
import { resetPendingAttachments } from 'userProfile/store/mediaDocuments/mediaDocuments.slice';
import { attachMediaDocs } from 'userProfile/pages/MediaDocuments/MediaDocuments.utils';
import type { IIconSectionProps } from '@liaison/liaison-ui/dist/components/SectionHeader';
import { HonorsAndAwardsForm } from './AccomplishmentsAndExperiencesForm';
import {
  getAchievementEnumTypes,
  getAccomplishmentsAndExperience,
  postAccomplishmentsAndExperience,
  postChildData,
} from './AccomplishmentsAndExperiences.utils';
import { validationSchema } from './AccomplishmentsAndExperiencesForm.validation';
import LanguagesAndSkillsForm from './AccomplishmentsAndExperiencesForm/LanguagesAndSkillsForm';
import ExperienceForm from './AccomplishmentsAndExperiencesForm/ExperienceForm';

const AccomplishmentsAndExperiences = (): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sections } = config;
  const [accomplishmentAndExperienceData, setAccomplishmentAndExperienceData] = useState(
    useSelector(selectAccomplishmentAndExperience)
  );
  const [isOpenRightSideDrawer, setIsOpenRightSideDrawer] = useState(false);
  const [childDrawerInformation, setChildDrawerInformation] = useState({
    isOpenChildDrawer: false,
    isAddNew: false,
    titleName: '',
    childPosition: 0,
    parent: '',
  });
  const accomplishmentAndExperienceActualData = useSelector(selectAccomplishmentAndExperience);
  const accomplishmentAndExperienceLocalData = useSelector(selectAccomplishmentAndExperienceLocalData);

  const languagesLookup = useSelector(selectLanguagesLookup);
  const isLoading = useSelector(selectLoading);
  const countriesLookup = useSelector(selectCountriesLookup);
  const experienceTypesLookup = useSelector(selectExperienceTypesLookup);
  const achievementEnumTypes = useSelector(selectAchievementEnumTypes);

  const methods = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(validationSchema, { abortEarly: false })(...args),
    defaultValues: DEFAULT_LANGUAGES,
  });
  const methods2 = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(validationSchema, { abortEarly: false })(...args),
  });

  useEffect(() => {
    setTitle(t('achievements.title'));
  }, [t]);

  useEffect(() => {
    dispatch(getAccomplishmentsAndExperience());
  }, [dispatch]);

  useEffect(() => {
    if (!languagesLookup) {
      dispatch(getLookUps('languages'));
    }
  }, [dispatch, languagesLookup]);

  useEffect(() => {
    if (!countriesLookup) {
      dispatch(getLookUps('countries'));
    }
  }, [dispatch, countriesLookup]);

  useEffect(() => {
    if (!experienceTypesLookup) {
      dispatch(getLookUps('experiences'));
    }
  }, [dispatch, experienceTypesLookup]);

  useEffect(() => {
    if (!achievementEnumTypes) {
      dispatch(getAchievementEnumTypes());
    }
  }, [dispatch, achievementEnumTypes]);

  useEffect(() => {
    const parentName = childDrawerInformation?.parent as keyof TAccomplishmentsAndExperienceField;
    if (accomplishmentAndExperienceLocalData) {
      if (!childDrawerInformation.isOpenChildDrawer) {
        setAccomplishmentAndExperienceData({
          ...accomplishmentAndExperienceLocalData,
          [parentName]: accomplishmentAndExperienceActualData?.[parentName],
        });
      }
    } else {
      setAccomplishmentAndExperienceData(accomplishmentAndExperienceActualData);
    }
  }, [accomplishmentAndExperienceActualData, accomplishmentAndExperienceLocalData, childDrawerInformation]);

  const openDrawer = () => {
    setIsOpenRightSideDrawer(true);
  };
  const openChildDrawer = (isNew: boolean, childPosition: number, parentSelector = '', formTitle = '') => {
    if (isOpenRightSideDrawer) {
      dispatch(accomplishmentAndExperienceSaveLocalData(methods.getValues()));
    }
    setChildDrawerInformation({
      ...childDrawerInformation,
      isOpenChildDrawer: true,
      isAddNew: isNew === undefined ? true : isNew,
      titleName: formTitle,
      childPosition,
      parent: parentSelector,
    });
  };

  const childProperties = (
    addmore = false,
    selector: keyof TAccomplishmentsAndExperienceField,
    formTitle: string | undefined
  ) => {
    if (addmore) {
      return {
        actions: [
          {
            icon: <IconAdd />,
            label: selector,
            size: 'small',
            sx: sxIconButton,
            onClick: () =>
              openChildDrawer(true, accomplishmentAndExperienceData?.[selector]?.length || 0, selector, formTitle),
          },
        ] as IIconSectionProps[],
      };
    }
    return {};
  };

  const closeParent = () => {
    setIsOpenRightSideDrawer(false);
    dispatch(accomplishmentAndExperienceResetLocalData());
  };
  const closeChild = () => {
    setChildDrawerInformation({
      ...childDrawerInformation,
      isOpenChildDrawer: false,
    });
    dispatch(resetPendingAttachments());
    dispatch(resetSkillsAttachments());
  };

  const onSubmit = (data: TAccomplishmentsAndExperienceField) => {
    dispatch(
      postAccomplishmentsAndExperience(sanitizePayload(Object.assign(data)), () => {
        setIsOpenRightSideDrawer(false);
      })
    );
  };
  const onSubmitChildDrawer = (data: TAccomplishmentsAndExperienceField) => {
    const parentName = childDrawerInformation?.parent as keyof TAccomplishmentsAndExperienceField;

    const selectedNode = [...(accomplishmentAndExperienceActualData?.[parentName] || [])];
    if (selectedNode != null) {
      if (data?.[parentName]?.[childDrawerInformation.childPosition] !== undefined) {
        selectedNode[childDrawerInformation.childPosition] = (data[parentName] as typeof selectedNode)[
          childDrawerInformation.childPosition
        ];
      }
    }
    const payload = {
      ...accomplishmentAndExperienceActualData,
      [parentName]: selectedNode,
    };
    dispatch(
      postChildData(sanitizePayload(Object.assign(payload)), () => {
        setChildDrawerInformation({
          ...childDrawerInformation,
          isOpenChildDrawer: false,
        });
      })
    );
  };

  const deleteRecord = (indexPosition: number, tag?: string) => {
    const parentName = childDrawerInformation?.parent as keyof TAccomplishmentsAndExperienceField;

    const selectedNode = [...(accomplishmentAndExperienceActualData?.[parentName] || [])];

    if (selectedNode != null) {
      selectedNode.splice(indexPosition, 1);
    }

    const payload = {
      ...accomplishmentAndExperienceActualData,
      [parentName]: selectedNode,
    };
    dispatch(
      postChildData(sanitizePayload(Object.assign(payload)), () => {
        if (tag) {
          dispatch(attachMediaDocs({ tag, resource: 'achievements' }));
        }
        setChildDrawerInformation({
          ...childDrawerInformation,
          isOpenChildDrawer: false,
        });
      })
    );
  };

  return (
    <Grid container>
      {isLoading && <Spinner backdrop />}
      <Box sx={{ width: '100%' }}>
        <PageTitle title={t('achievements.title')} handleClick={openDrawer} />

        <FormProvider {...methods}>
          <form>
            <SidePanel
              size="small"
              open={isOpenRightSideDrawer}
              onClose={closeParent}
              title={t('achievements.title')}
              isBackdropClickEnabled={true}
              footerButtonConfig={{
                primary: {
                  title: t('save_label'),
                  props: {
                    'aria-label': t('save_label'),
                    color: getButtonColor(),
                    variant: 'contained',
                    disabled: !methods.formState.isValid,
                    onClick: methods.handleSubmit(onSubmit),
                  },
                },
                tertiary: {
                  title: t('cancel_label'),
                  props: {
                    'aria-label': t('cancel_label'),
                    color: getButtonColor(),
                    onClick: closeParent,
                  },
                },
              }}
              sx={sxSidePanel}
            >
              <HonorsAndAwardsForm
                data={accomplishmentAndExperienceData}
                openChildDrawer={openChildDrawer}
                isOpenChildDrawer={false}
                childPosition={childDrawerInformation?.childPosition}
                selector={sections?.honorsOrAwards?.selector}
                formTitle={t(`${sections?.honorsOrAwards.title}.${sections?.honorsOrAwards.editTitle}`)}
              />
              <ExperienceForm
                data={accomplishmentAndExperienceData}
                openChildDrawer={openChildDrawer}
                isOpenChildDrawer={false}
                childPosition={childDrawerInformation?.childPosition}
                selector={sections?.experiences?.selector}
                formTitle={t(`${sections?.experiences.title}.${sections?.experiences.editTitle}`)}
              />
              <LanguagesAndSkillsForm data={accomplishmentAndExperienceData} />
            </SidePanel>
          </form>
        </FormProvider>
        <FormProvider {...methods2}>
          <form>
            <SidePanel
              size="small"
              title={childDrawerInformation?.titleName || ''}
              open={childDrawerInformation.isOpenChildDrawer}
              onClose={closeChild}
              isBackdropClickEnabled={true}
              footerButtonConfig={{
                primary: {
                  title: t('save_label'),
                  props: {
                    'aria-label': t('save_label'),
                    color: getButtonColor(),
                    variant: 'contained',
                    disabled: !methods2.formState.isValid,
                    onClick: methods2.handleSubmit(onSubmitChildDrawer),
                  },
                },
                tertiary: {
                  title: t('cancel_label'),
                  props: {
                    'aria-label': t('cancel_label'),
                    color: getButtonColor(),
                    onClick: closeChild,
                  },
                },
              }}
              sx={sxSidePanel}
            >
              {childDrawerInformation.isOpenChildDrawer && childDrawerInformation?.parent === 'honorsOrAwards' && (
                <HonorsAndAwardsForm
                  data={accomplishmentAndExperienceData}
                  openChildDrawer={openChildDrawer}
                  isOpenChildDrawer={true}
                  childPosition={childDrawerInformation.childPosition}
                  deleteRecord={!childDrawerInformation.isAddNew ? deleteRecord : null}
                  formTitle={t(`${sections?.honorsOrAwards.title}.${sections?.honorsOrAwards.editTitle}`)}
                  selector=""
                />
              )}
              {childDrawerInformation.isOpenChildDrawer && childDrawerInformation?.parent === 'experiences' && (
                <ExperienceForm
                  data={accomplishmentAndExperienceData}
                  openChildDrawer={openChildDrawer}
                  isOpenChildDrawer={true}
                  childPosition={childDrawerInformation?.childPosition}
                  deleteRecord={!childDrawerInformation.isAddNew ? deleteRecord : null}
                  formTitle={t(`${sections?.experiences.title}.${sections?.experiences.editTitle}`)}
                  selector=""
                />
              )}
            </SidePanel>
          </form>
        </FormProvider>

        {Object.keys(sections).map(section => {
          const accordionValues = Object(sections)[section];
          const responseData = Object(accomplishmentAndExperienceActualData);
          return (
            <React.Fragment key={section}>
              {accordionValues?.visibility && (
                <Box sx={{ mb: '1rem', mt: '1rem' }}>
                  <Accordion
                    expanded
                    groupId=""
                    title={t(`${accordionValues.title}.title`).toUpperCase()}
                    {...childProperties(
                      accordionValues?.addMore,
                      accordionValues.selector,
                      t(`${accordionValues.title}.${accordionValues.formTitle}`)
                    )}
                    sx={{
                      [`.${accordionSummaryClasses.root}`]: {
                        pb: 2,
                      },
                    }}
                  >
                    <ViewBuilder
                      fieldDetails={accordionValues?.fields}
                      responseData={responseData}
                      cardView={accordionValues?.cardView || false}
                      openChildDrawer={openChildDrawer}
                      selector={accordionValues.selector}
                      formTitle={t(`${accordionValues.title}.${accordionValues.editTitle}`)}
                      localeKey={accordionValues.title}
                    />
                  </Accordion>
                </Box>
              )}
            </React.Fragment>
          );
        })}
      </Box>
    </Grid>
  );
};

export default memo(AccomplishmentsAndExperiences);
