/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, useMediaQuery, type IconButtonProps } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SidePanel, CollapsibleSection as Accordion, IconAdd } from '@liaison/liaison-ui';
import { Theme } from '@mui/system';
import { sxIconButton, sxSidePanel, sxSidePanelMobile } from 'pages/Pages.styles';
import { selectFeatures } from 'store/features/features.selectors';
import { selectCommonFieldsConfig } from 'store/standardTests/standardTests.selectors';
import {
  selectAcademicHistory,
  selectLoading,
  selectCoursesExtensions,
} from 'store/academicHistory/academicHistory.selectors';
import { ViewBuilder } from 'userProfile/components/ViewBuilder';
import { PageTitle } from 'components/PageTitle';
import { Spinner } from 'components/Spinner';
import type {
  ICollege,
  ICollegeCourse,
  IHighSchool,
  ITerm,
  TAcademicHistoryField,
  TGradeLevel,
  TGradeLevelAndCourse,
  TTermsAndCourse,
} from 'store/academicHistory/academicHistory.slice';
import type { IHeaderIconConfig } from '@liaison/liaison-ui/dist/components/SidePanel/SidePanelHeader';
import type { ISidePanelFooterProps } from '@liaison/liaison-ui/dist/components/SidePanel/SidePanelFooter';
import { resetPendingAttachments, setPendingAttachments } from 'userProfile/store/mediaDocuments/mediaDocuments.slice';
import {
  setSkillsAttachments,
  resetSkillsAttachments,
} from 'userProfile/store/accomplishmentAndExperience/accomplishmentAndExperience.slice';
import { selectSkillsAttachments } from 'userProfile/store/accomplishmentAndExperience/accomplishmentAndExperience.selectors';
import { selectCountriesLookup } from 'store/common/commonInfo.selectors';
import { getButtonColor, isCsuTenant, sanitizePayload } from 'utils/utilities';
import { getLookUps, setTitle } from 'utils/commonUtils';
import { isFeatureActive } from 'utils/features.utils';
import { getStandardTests, getStandardTestsConfig } from 'utils/standardTests.utils';
import { resetlookupData } from 'store/common/commonInfo.slice';
import { useTranslation } from 'react-i18next';
import config from 'userProfile/appConfig/academicHistory.json';
import {
  getAcademicHistory,
  postAcademicHistory,
  generateGrandChildPayload,
  getCourseExtensionsPayload,
  getAllCourseExtensions,
  postCourseExtensions,
  findNewCoursePosition,
  findNextCourseGrade,
  filterCourseExtensions,
} from 'utils/AcademicHistory.utils';
import { selectGradeLevelPosition } from 'store/ui/ui.selectors';
import { setUi } from 'store/ui/ui.slice';
import { attachMediaDocs } from 'userProfile/pages/MediaDocuments/MediaDocuments.utils';
import {
  HighSchoolForm,
  HighSchoolCourseForm,
  CollegeForm,
  CollegeDegreeForm,
  CollegeCourseForm,
} from './AcademicHistoryForm';
import { TestsForm } from './TestsForm';
import { validationSchema, childValidionSchema } from './AcademicHistoryForm.validation';

const { sections } = config;

const AcademicHistory = (): ReactElement => {
  const dispatch = useDispatch();
  const [selectedCourse, setSelectedCourse] = useState<ITerm[] | TGradeLevel[]>([]);
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const [childDrawerInformation, setChildDrawerInformation] = useState({
    isOpenChildDrawer: false,
    isAddNew: false,
    titleName: '',
    childPosition: 0,
    parent: '',
    showCourses: false,
  });
  const [grandChildDrawerInformation, setGrandChildDrawerInformation] = useState({
    isOpenChildDrawer: false,
    isAddNew: false,
    titleName: '',
    childPosition: 0,
    parent: '',
    parentPosition: 0,
    prefix: '',
    coursePosition: 0,
    isAddAnother: false,
    isEditAnother: false,
  });
  const academicHistoryData = useSelector(selectAcademicHistory);
  const isLoading = useSelector(selectLoading);
  const countriesLookup = useSelector(selectCountriesLookup);
  const features = useSelector(selectFeatures);
  const commonFieldsConfig = useSelector(selectCommonFieldsConfig);
  const coursesExtensions = useSelector(selectCoursesExtensions);
  const gradeLevelPosition = useSelector(selectGradeLevelPosition);
  const skillsAttachments = useSelector(selectSkillsAttachments);
  const isHighSchollActive = isFeatureActive(features, 'HIGHSCHOOL');
  const isStandardTestsActive = isFeatureActive(features, 'STANDARD_TESTS');
  const sxForMobileSidePanel = isMobile ? { ...sxSidePanel, ...sxSidePanelMobile } : sxSidePanel;

  useEffect(() => {
    if (!countriesLookup) {
      dispatch(getLookUps('countries'));
    }
  }, [dispatch, countriesLookup]);
  const methods = useForm({
    shouldUnregister: true,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(validationSchema, { abortEarly: false })(...args),
  });

  const methods2 = useForm({
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(childValidionSchema, { abortEarly: false })(...args),
    context: { formName: grandChildDrawerInformation.prefix },
  });

  useEffect(() => {
    setTitle(t('academicHistory.title'));
    dispatch(getAcademicHistory());
    if (isCsuTenant()) {
      dispatch(getAllCourseExtensions());
    }
  }, [dispatch, t]);

  useEffect(() => {
    if (features && isStandardTestsActive) {
      dispatch(getStandardTests());
    }
  }, [dispatch, features, isStandardTestsActive]);

  useEffect(() => {
    if (features && isStandardTestsActive && !commonFieldsConfig) {
      dispatch(getStandardTestsConfig('COMMON_FIELDS'));
    }
  }, [dispatch, features, isStandardTestsActive, commonFieldsConfig]);

  useEffect(() => {
    if (childDrawerInformation?.titleName) {
      const sectionDetails =
        academicHistoryData?.[childDrawerInformation?.parent as 'highSchools' | 'colleges']?.[
          childDrawerInformation?.childPosition
        ];
      if (childDrawerInformation?.parent === 'colleges') {
        setSelectedCourse((sectionDetails as ICollege)?.terms);
      } else {
        setSelectedCourse((sectionDetails as IHighSchool)?.gradeLevels);
      }
    }
  }, [childDrawerInformation, academicHistoryData]);

  const openChildDrawer = (
    isNew: boolean,
    childPosition: number,
    parentSelector: string,
    formTitle: string,
    showCourse?: boolean
  ) => {
    setSelectedCourse([]);
    methods.setValue('country.code', 'US');
    setChildDrawerInformation({
      ...childDrawerInformation,
      isOpenChildDrawer: true,
      isAddNew: isNew,
      titleName: formTitle,
      childPosition,
      parent: parentSelector,
      showCourses: showCourse || false,
    });
    setTitle(formTitle === t('colleges.add') ? t('colleges.addPageTitle') : t('colleges.editPageTitle'));
  };

  const childProperties = (selector: 'highSchools' | 'colleges', formTitle: string | undefined, addmore = false) => {
    if (addmore) {
      return {
        actions: [
          {
            icon: <IconAdd />,
            label: `Add ${selector}`,
            size: 'small' as IconButtonProps['size'],
            sx: sxIconButton,
            onClick: () =>
              openChildDrawer(true, academicHistoryData?.[selector]?.length || 0, selector, formTitle as string),
          },
        ],
      };
    }
    return {};
  };

  const closeChild = () => {
    setChildDrawerInformation({
      ...childDrawerInformation,
      isOpenChildDrawer: false,
      showCourses: false,
    });
    setTitle(t('academicHistory.title'));
    dispatch(resetlookupData(['states']));
    dispatch(resetPendingAttachments());
    if (skillsAttachments.length) {
      dispatch(resetSkillsAttachments());
    }
  };

  const onSubmitChildDrawer = (data: TAcademicHistoryField) => {
    const parentName = childDrawerInformation?.parent;
    const childIndex = childDrawerInformation.childPosition;
    const updatedData = data;
    const selectedData = [...(academicHistoryData?.[parentName as keyof typeof academicHistoryData] || [])];
    if (selectedData != null) {
      if (updatedData?.[parentName as keyof typeof updatedData]?.[childIndex] !== undefined) {
        selectedData[childIndex] = updatedData?.[parentName as keyof typeof updatedData]?.[childIndex] as unknown as
          | IHighSchool
          | ICollege;
      }
    }

    let payload = academicHistoryData;
    if (selectedData[childIndex]?.primary) {
      const updatedColleges = selectedData?.map((collegeData, i) => {
        let primaryStatus = false;
        if (i === childIndex) primaryStatus = true;
        return { ...collegeData, primary: primaryStatus };
      });

      payload = {
        ...academicHistoryData,
        [parentName]: updatedColleges,
      };
    } else {
      payload = {
        ...academicHistoryData,
        [parentName]: selectedData,
      };
    }

    dispatch(
      postAcademicHistory(sanitizePayload(Object.assign(payload)), () => {
        closeChild();
      })
    );
  };

  const openGrandChildDrawer = (
    isNew: boolean,
    childPosition: number,
    parentSelector: string,
    formTitle?: string,
    parentPosition = 0,
    sectionPrefix = 'gradeLevels',
    coursePosition = 0,
    isEditAnother = false
  ) => {
    setGrandChildDrawerInformation({
      ...grandChildDrawerInformation,
      isOpenChildDrawer: true,
      isAddNew: isNew,
      titleName: formTitle || t('highSchool.addSchoolCourse'),
      childPosition,
      parent: parentSelector,
      prefix: sectionPrefix,
      parentPosition,
      coursePosition,
      isAddAnother: isEditAnother,
      isEditAnother,
    });
  };

  const setNewParentIndex = (newCollegeIndex: number) => {
    /* istanbul ignore next */
    setGrandChildDrawerInformation({
      ...grandChildDrawerInformation,
      parentPosition: newCollegeIndex,
    });
  };

  const setNewChildIndex = (newTermIndex: number) => {
    /* istanbul ignore next */
    setGrandChildDrawerInformation({
      ...grandChildDrawerInformation,
      childPosition: newTermIndex,
    });
  };

  const closeGrandChild = () => {
    setGrandChildDrawerInformation({
      ...grandChildDrawerInformation,
      isOpenChildDrawer: false,
    });
    setTitle(t('academicHistory.title'));
    methods.trigger();
    dispatch(setPendingAttachments({ hsCourse: undefined, degrees: undefined }));
    dispatch(setSkillsAttachments(skillsAttachments.slice(0, -1)));
  };

  const closeSidePanel = (selector: string) => {
    if (selector === 'colleges' || selector === 'highSchools') {
      closeChild();
    } else {
      closeGrandChild();
    }
  };
  const deleteCollegeAndCoursesWithRevertLogic = (
    academicHistoryPayload: TAcademicHistoryField,
    selector: string,
    removeCourse: ICollegeCourse[],
    revertAcademicHistoryPayload: TAcademicHistoryField,
    attachMediaDocsParams: { tag?: string; updateChildNodes?: boolean }
  ) => {
    dispatch(
      postAcademicHistory(sanitizePayload(Object.assign(academicHistoryPayload)), () => {
        if (attachMediaDocsParams.tag) {
          dispatch(attachMediaDocs({ ...attachMediaDocsParams, resource: 'academicHistory' }));
        }
        if ((isCsuTenant() && selector === 'terms') || selector === 'colleges') {
          const coursesExtensionsPayload = filterCourseExtensions(coursesExtensions, removeCourse);
          if (coursesExtensionsPayload) {
            dispatch(
              postCourseExtensions(
                sanitizePayload(Object.assign(coursesExtensionsPayload)),
                () => {
                  closeSidePanel(selector);
                },
                () => {
                  // revert academic history in the case of postCourseExtensions failure
                  dispatch(setUi({ name: 'succesSnackbar', state: { open: false } }));
                  dispatch(
                    postAcademicHistory(sanitizePayload(Object.assign(revertAcademicHistoryPayload)), () => {}, true)
                  );
                }
              )
            );
          } else {
            closeSidePanel(selector);
          }
        } else {
          closeSidePanel(selector);
        }
      })
    );
  };

  const deleteChildProperty = (indexPosition: number, parent: string, tag?: string) => {
    const selectedObject = [...(academicHistoryData?.[parent as keyof typeof academicHistoryData] || [])];
    const revertAcademicHistoryPayload = { ...academicHistoryData };
    let removeCourse: ICollegeCourse[] = [];

    if (selectedObject != null) {
      removeCourse = (selectedObject[indexPosition] as ICollege)?.terms?.reduce(
        (total: ICollegeCourse[], term: ITerm) => {
          return [...total, ...(term?.courses || [])];
        },
        []
      );
      selectedObject.splice(indexPosition, 1);
    }

    const payload = {
      ...academicHistoryData,
      [parent]: selectedObject,
    };
    deleteCollegeAndCoursesWithRevertLogic(payload, parent, removeCourse, revertAcademicHistoryPayload, {
      tag,
      updateChildNodes: true,
    });
  };

  const deleteGrandChild = (
    indexPosition: number,
    parent: string,
    parentPosition: number,
    selector: string,
    coursePosition = 0,
    tag?: string
  ) => {
    const selectedObject = [...(academicHistoryData?.[parent as keyof typeof academicHistoryData] || [])];
    const revertAcademicHistoryPayload = { ...academicHistoryData };
    let currentData = selectedObject?.[parentPosition] as IHighSchool | ICollege;
    let removeCourse: ICollegeCourse[] = [];
    if (currentData && currentData?.[selector as keyof typeof currentData]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const selectedList = [...(currentData?.[selector] || [])];
      if (selector === 'terms' || selector === 'gradeLevels') {
        if (selectedList?.[indexPosition]?.courses?.length === 1) {
          removeCourse = selectedList[indexPosition].courses;
          selectedList.splice(indexPosition, 1);
        } else {
          const currentParent = { ...selectedList?.[indexPosition] };
          const allCourseList = [...(currentParent?.courses || [])];
          removeCourse = allCourseList?.splice(coursePosition, 1);
          currentParent.courses = allCourseList;
          selectedList[indexPosition] = currentParent;
        }
      } else {
        selectedList.splice(indexPosition, 1);
      }

      currentData = { ...currentData, [selector]: selectedList };
    }

    selectedObject[parentPosition] = currentData;
    const payload = {
      ...academicHistoryData,
      [parent]: selectedObject,
    };

    deleteCollegeAndCoursesWithRevertLogic(payload, selector, removeCourse, revertAcademicHistoryPayload, {
      tag,
    });
  };

  const getCurrentFormData = (currentFormName: string) => {
    const currentFormData = methods.getValues();
    const parentFormData = currentFormData?.[currentFormName]?.[grandChildDrawerInformation?.parentPosition];
    if (parentFormData && Object.keys(parentFormData).length) {
      return parentFormData;
    }

    const parentSelector = academicHistoryData?.[currentFormName as 'highSchools' | 'colleges'];
    return parentSelector?.[grandChildDrawerInformation?.parentPosition];
  };

  const onSubmitGrandChildDrawer = (data: TAcademicHistoryField | TTermsAndCourse | TGradeLevelAndCourse) => {
    const parentFormData = methods.getValues();
    const payload = generateGrandChildPayload(
      data,
      grandChildDrawerInformation,
      childDrawerInformation,
      academicHistoryData,
      parentFormData
    );
    const revertAcademicHistoryPayload = { ...academicHistoryData };
    dispatch(
      postAcademicHistory(sanitizePayload(Object.assign(payload)), (academicHistoryResponse: TAcademicHistoryField) => {
        if (childDrawerInformation?.isAddNew) {
          setChildDrawerInformation({
            ...childDrawerInformation,
            isOpenChildDrawer: false,
          });
        }
        const pendingCourse = localStorage.getItem('pendingCourses');

        const { prefix, childPosition, isAddNew, parentPosition, coursePosition, parent, isEditAnother, isAddAnother } =
          grandChildDrawerInformation;
        const currentData = getCurrentFormData(parent);
        const coursesList = currentData?.[prefix]?.[childPosition]?.courses;
        const shouldOpenGrandChild =
          (isAddNew && isAddAnother) || (isEditAnother && coursesList?.length > coursePosition + 1);

        if (isCsuTenant() && pendingCourse) {
          const courseExtensionPayload = getCourseExtensionsPayload(
            academicHistoryResponse,
            JSON.parse(pendingCourse),
            coursesExtensions
          );
          if (courseExtensionPayload) {
            dispatch(setUi({ name: 'succesSnackbar', state: { open: false } }));
            dispatch(
              postCourseExtensions(
                sanitizePayload(Object.assign(courseExtensionPayload)),
                () => {
                  localStorage.removeItem('pendingCourses');
                  closeGrandChild();

                  if (shouldOpenGrandChild) {
                    setTimeout(() => {
                      let isNoneGrade = false;
                      if (parent === 'colleges' && prefix === 'terms') {
                        const nextCourseGrade = findNextCourseGrade(
                          payload,
                          parent,
                          parentPosition,
                          prefix,
                          childPosition,
                          coursePosition
                        );
                        isNoneGrade = nextCourseGrade === 'None';
                      }

                      openGrandChildDrawer(
                        !isEditAnother,
                        childPosition,
                        parent,
                        !isEditAnother ? t('academicHistory.addCourse') : t('colleges.editCollegeCourse'),
                        parentPosition,
                        parent === 'colleges' ? 'terms' : 'gradeLevels',
                        !isEditAnother
                          ? findNewCoursePosition(payload, childPosition, parentPosition, parent)
                          : grandChildDrawerInformation?.coursePosition + 1,
                        isNoneGrade
                      );
                    }, 10);
                  }
                },
                () => {
                  // revert academic history in the case of postCourseExtensions failure
                  dispatch(
                    postAcademicHistory(sanitizePayload(Object.assign(revertAcademicHistoryPayload)), () => {}, true)
                  );
                }
              )
            );
          } else {
            closeGrandChild();
          }
        } else if (shouldOpenGrandChild) {
          closeGrandChild();
          setTimeout(() => {
            openGrandChildDrawer(
              !isEditAnother,
              parent === 'highSchools' ? gradeLevelPosition.index : childPosition,
              parent,
              !isEditAnother ? t('academicHistory.addCourse') : t('colleges.editCollegeCourse'),
              parentPosition,
              parent === 'colleges' ? 'terms' : 'gradeLevels',
              !isEditAnother
                ? findNewCoursePosition(payload, childPosition, parentPosition, parent)
                : coursePosition + 1
            );
          }, 10);
        } else {
          closeGrandChild();
        }
      })
    );
  };

  const generateCurseHeaderIconConfig = () => {
    if (childDrawerInformation?.showCourses) {
      return {
        icons: [
          {
            icon: (<IconAdd />) as ReactElement,
            label: t('academicHistory.addCourse'),
            color: getButtonColor(),
            onClick: () =>
              openGrandChildDrawer(
                true,
                selectedCourse?.length || 0,
                childDrawerInformation?.parent,
                t('academicHistory.addCourse'),
                childDrawerInformation?.childPosition,
                childDrawerInformation?.parent === 'colleges' ? 'terms' : 'gradeLevels'
              ),
          },
        ],
      };
    }
    return {};
  };

  const generatefooterButtonConfig = () => {
    if (childDrawerInformation?.showCourses) {
      return {
        tertiary: {
          title: t('cancel_label'),
          props: {
            'aria-label': t('cancel_label'),
            color: getButtonColor(),
            onClick: closeChild,
          },
        },
      };
    }

    return {
      primary: {
        title: t('save_label'),
        props: {
          'aria-label': t('save_label'),
          variant: 'contained',
          color: getButtonColor(),
          disabled: !methods.formState.isValid,
          onClick: methods.handleSubmit(onSubmitChildDrawer),
        },
      },
      tertiary: {
        title: t('cancel_label'),
        props: {
          'aria-label': t('cancel_label'),
          color: getButtonColor(),
          onClick: closeChild,
        },
      },
    };
  };

  const childCourseProperties = () => {
    return {
      headerIconConfig: { ...generateCurseHeaderIconConfig() } as IHeaderIconConfig,
      footerButtonConfig: { ...generatefooterButtonConfig() } as ISidePanelFooterProps['footerButtonConfig'],
    };
  };

  const updateFooterCheckboxProps = () => {
    const { prefix, childPosition, isAddNew, isAddAnother, isEditAnother, coursePosition, parent } =
      grandChildDrawerInformation;
    if (prefix === 'terms' || prefix === 'gradeLevels') {
      const currentData = getCurrentFormData(parent);
      const coursesList = currentData?.[prefix]?.[childPosition]?.courses;
      if (!isAddNew && (coursesList?.length === 1 || coursesList?.length === coursePosition + 1)) {
        return {};
      }

      if (isAddNew) {
        return {
          footerCheckBoxConfig: {
            title: t('colleges.course.addAnother'),
            props: {
              'aria-label': `${t('colleges.course.addAnother')} ${t('course_label')}`,
              onClick: () => {
                setGrandChildDrawerInformation({
                  ...grandChildDrawerInformation,
                  isAddAnother: !isAddAnother,
                });
              },
              checked: isAddAnother,
            },
          },
        };
      }

      return {
        footerCheckBoxConfig: {
          title: t('colleges.course.editAnother'),
          props: {
            'aria-label': `${t('colleges.course.editAnother')} ${t('course_label')}`,
            onClick: () => {
              setGrandChildDrawerInformation({
                ...grandChildDrawerInformation,
                isEditAnother: !isEditAnother,
                isAddAnother: !isAddAnother,
              });
            },
            checked: isEditAnother,
          },
        },
      };
    }
    return {};
  };

  return (
    <Grid container>
      {isLoading && <Spinner backdrop />}
      <Box sx={{ width: '100%' }}>
        <PageTitle title={t('academicHistory.title')} />
        <FormProvider {...methods}>
          <form>
            <SidePanel
              size="small"
              title={childDrawerInformation?.titleName || ''}
              open={childDrawerInformation.isOpenChildDrawer}
              onClose={closeChild}
              isBackdropClickEnabled={true}
              {...childCourseProperties()}
              sx={sxForMobileSidePanel}
            >
              {childDrawerInformation.isOpenChildDrawer && childDrawerInformation?.parent === 'highSchools' && (
                <HighSchoolForm
                  data={academicHistoryData || null}
                  openChildDrawer={openChildDrawer}
                  isOpenChildDrawer={true}
                  childPosition={childDrawerInformation.childPosition}
                  deleteSchool={!childDrawerInformation.isAddNew ? deleteChildProperty : null}
                  openGrandChildDrawer={openGrandChildDrawer}
                  showCourses={childDrawerInformation?.showCourses}
                  closeChildDrawer={closeChild}
                />
              )}
              {childDrawerInformation.isOpenChildDrawer && childDrawerInformation?.parent === 'colleges' && (
                <CollegeForm
                  data={academicHistoryData || null}
                  openChildDrawer={openChildDrawer}
                  isOpenChildDrawer={true}
                  childPosition={childDrawerInformation.childPosition}
                  deleteProperty={!childDrawerInformation.isAddNew ? deleteChildProperty : null}
                  openGrandChildDrawer={openGrandChildDrawer}
                  showCourses={childDrawerInformation?.showCourses}
                  closeChildDrawer={closeChild}
                />
              )}
              {childDrawerInformation.isOpenChildDrawer && childDrawerInformation?.parent === 'tests' && (
                <TestsForm
                  onCloseSidebar={closeChild}
                  isAddNew={childDrawerInformation.isAddNew}
                  childPosition={childDrawerInformation.childPosition}
                />
              )}
            </SidePanel>
          </form>
        </FormProvider>

        <FormProvider {...methods2}>
          <form>
            <SidePanel
              size="small"
              onClose={closeGrandChild}
              title={grandChildDrawerInformation?.titleName || ''}
              open={grandChildDrawerInformation.isOpenChildDrawer}
              isBackdropClickEnabled={true}
              footerButtonConfig={{
                primary: {
                  title: t('save_label'),
                  props: {
                    color: getButtonColor(),
                    'aria-label': t('save_label'),
                    variant: 'contained',
                    disabled: !methods2.formState.isValid,
                    onClick: methods2.handleSubmit(onSubmitGrandChildDrawer),
                  },
                },
                tertiary: {
                  title: t('cancel_label'),
                  props: {
                    color: getButtonColor(),
                    'aria-label': t('cancel_label'),
                    onClick: closeGrandChild,
                  },
                },
              }}
              {...updateFooterCheckboxProps()}
              sx={sxForMobileSidePanel}
            >
              {grandChildDrawerInformation.isOpenChildDrawer &&
                grandChildDrawerInformation?.parent === 'highSchools' &&
                grandChildDrawerInformation?.prefix === 'gradeLevels' && (
                  <HighSchoolCourseForm
                    data={getCurrentFormData(grandChildDrawerInformation?.parent)}
                    isNewCourse={grandChildDrawerInformation.isAddNew}
                    childPosition={grandChildDrawerInformation.childPosition}
                    deleteCourse={!grandChildDrawerInformation.isAddNew ? deleteGrandChild : null}
                    parentPosition={grandChildDrawerInformation.parentPosition}
                    coursePosition={grandChildDrawerInformation.coursePosition}
                  />
                )}

              {grandChildDrawerInformation.isOpenChildDrawer &&
                grandChildDrawerInformation?.parent === 'colleges' &&
                grandChildDrawerInformation?.prefix === 'degrees' && (
                  <CollegeDegreeForm
                    data={getCurrentFormData(grandChildDrawerInformation?.parent)}
                    childPosition={grandChildDrawerInformation.childPosition}
                    deleteDegree={!grandChildDrawerInformation.isAddNew ? deleteGrandChild : null}
                    parentPosition={grandChildDrawerInformation.parentPosition}
                    prefix={grandChildDrawerInformation.prefix}
                  />
                )}

              {grandChildDrawerInformation.isOpenChildDrawer &&
                grandChildDrawerInformation?.parent === 'colleges' &&
                grandChildDrawerInformation?.prefix === 'terms' && (
                  <CollegeCourseForm
                    data={getCurrentFormData(grandChildDrawerInformation?.parent)}
                    isNewCourse={grandChildDrawerInformation.isAddNew}
                    childPosition={grandChildDrawerInformation.childPosition}
                    deleteCourse={!grandChildDrawerInformation.isAddNew ? deleteGrandChild : null}
                    parentPosition={grandChildDrawerInformation.parentPosition}
                    coursePosition={grandChildDrawerInformation.coursePosition}
                    setNewParentIndex={setNewParentIndex}
                    setNewChildIndex={setNewChildIndex}
                  />
                )}
            </SidePanel>
          </form>
        </FormProvider>

        {Object.keys(sections || {}).map(key => {
          if (!isHighSchollActive && key === 'highSchoolsAttended') {
            return null;
          }
          if (!isStandardTestsActive && key === 'standardizedTests') {
            return null;
          }
          const accordionValues = Object(sections)[key];
          const responseData = Object(academicHistoryData);
          return (
            <React.Fragment key={key}>
              <Box sx={{ mb: '1rem', mt: '1rem' }}>
                <Accordion
                  expanded
                  title={t(accordionValues.title).toUpperCase()}
                  groupId={t(accordionValues.title).toUpperCase()}
                  {...childProperties(accordionValues.selector, t(accordionValues.formTitle), accordionValues?.addMore)}
                >
                  <ViewBuilder
                    fieldDetails={accordionValues?.fields}
                    responseData={responseData}
                    cardView={accordionValues?.cardView || false}
                    openChildDrawer={openChildDrawer}
                    selector={accordionValues?.selector}
                    formTitle={t(accordionValues?.formTitle)}
                    openGrandChildDrawer={openGrandChildDrawer}
                  />
                </Accordion>
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
    </Grid>
  );
};

export default memo(AcademicHistory);
