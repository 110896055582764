/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import * as yup from 'yup';
import { TEXT_REGEX, MD_URL_REG } from 'constants/regex';
import { t } from 'i18next';

export const enum EFileSource {
  UPLOAD = 'upload',
  URL = 'url',
}

export const fileSourceOptions = [
  { text: t('mediaDocuments.fileUpload.upload.label'), val: EFileSource.UPLOAD },
  { text: t('mediaDocuments.fileUpload.url.label'), val: EFileSource.URL },
];

export const defaultValues = {
  name: '',
  description: '',
  shareable: false,
  fileSource: EFileSource.UPLOAD,
  externalURL: '',
};

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(t('mediaDocuments.fileUpload.error.name'))
    .matches(RegExp(TEXT_REGEX), t('error.notAllowed')),
  description: yup
    .string()
    .max(500)
    .required(t('mediaDocuments.fileUpload.error.description'))
    .matches(RegExp(TEXT_REGEX), t('error.notAllowed')),
  shareable: yup.bool().required(t('mediaDocuments.error.shareable.required')),
  externalURL: yup.string().test({
    name: 'is correct URL',
    test(val, ctx) {
      const fileSource = ctx?.parent?.fileSource;
      if (fileSource === EFileSource.URL) {
        if (!val) {
          return ctx.createError({ message: t('mediaDocuments.fileUpload.url.errorEmty') });
        }
        if (!val.match(RegExp(MD_URL_REG))) {
          return ctx.createError({ message: t('mediaDocuments.fileUpload.url.error') });
        }
      }
      return true;
    },
  }),
});
