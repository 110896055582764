/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

export const sxFooterBox = {
  bgcolor: 'rgba(255, 255, 255, 0.5)',
  backdropFilter: 'blur(4px)',
  borderTop: '1px solid #CAD2E1',
  position: 'fixed',
  bottom: 0,
  px: 2,
  py: 1.5,
  zIndex: 3,
} as const;

export const sxButton = {
  width: '95%',
  bgcolor: '#E3F2FD',
  color: '#383D45',
  [`&:hover, &:focus, &:active`]: {
    bgcolor: '#E3F2FD !important',
  },
} as const;
