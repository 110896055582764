import React, { ReactElement, useState, useMemo, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Button,
  InputAdornment,
  Autocomplete,
  AutocompleteCloseReason,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  useMediaQuery,
  Theme,
  Alert,
  Grid,
  Switch,
  Divider,
  FormHelperText,
} from '@mui/material';
import { SidePanel, IconSearch, IconCheckCircle, IconCancelCircleSolid } from '@liaison/liaison-ui';

import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { getCheckboxColor } from 'utils/utilities';
import {
  TMultiSelectOption,
  IMultiSelectAutocompleteProps,
  customFilterOptions,
  getOptionsByIds,
  defaultGetOptionLabel,
  isOptionEqualToValue,
} from './MultiSelectWithSidePanel.utils';
import { StyledAutocompletePopper, StyledInput, listBoxStyle, newTagStyle } from './MultiSelectWithSidePanel.styles';
import Tags from './Tags/Tags';

export const MultiSelectWithSidePanel = ({
  fieldsOfStudyCodes,
  fieldsOfStudyDisplay,
  value,
  options,
  limitTags = 3,
  onChange,
  title,
  id,
  getOptionLabel,
  maxOptionSelection = options.length,
  colorTheme = 'primary',
  ...restProps
}: IMultiSelectAutocompleteProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const multiSelectRef = useRef<HTMLInputElement>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [pendingValues, setPendingValues] = useState<TMultiSelectOption[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [newOptions, setNewOptions] = useState<TMultiSelectOption[]>([]);

  const { control } = useFormContext();

  const [showAlert, setShowAlert] = useState(false);
  const [shouldFilterOptions, setShouldFilterOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [message, setMessage] = useState('');

  const open = Boolean(anchorEl);

  const optionLabelGetter = useMemo(() => getOptionLabel ?? defaultGetOptionLabel, [getOptionLabel]);

  useEffect(() => {
    /* istanbul ignore next */
    if (shouldFilterOptions) {
      const filtered = options.filter(obj => fieldsOfStudyCodes?.includes(obj.fieldsOfStudyCode || ''));
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options);
    }
  }, [fieldsOfStudyCodes, options, shouldFilterOptions]);
  /* istanbul ignore next */
  useEffect(() => {
    if (value) {
      if (maxOptionSelection && value.length > maxOptionSelection) {
        // Check if maxOptionSelection is provided and not null
        setShowAlert(true);
        /* istanbul ignore next */
        setMessage(`Maximum ${maxOptionSelection} options can be selected`);
        // Take the first maxOptionSelection items from value
        const initialValues = value.slice(0, maxOptionSelection);
        setPendingValues(getOptionsByIds(options, initialValues));
      } else {
        setPendingValues(getOptionsByIds(options, value));
      }
    }
  }, [maxOptionSelection, options, value]);

  const openAutoComplete = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  /* istanbul ignore next */
  const handleClose = () => {
    onChange(pendingValues);

    if (anchorEl) {
      anchorEl.focus();
    }
    /* istanbul ignore next */
    setAnchorEl(null);
    setSearchValue('');
    setNewOptions([]);
    setShouldFilterOptions(false);
  };
  /* istanbul ignore next */
  const findPendingValues = (newValues: (string | TMultiSelectOption)[]): TMultiSelectOption[] => {
    const newTagsTemp = [...newOptions];
    const pending = newValues.map((item: TMultiSelectOption | string) => {
      if (typeof item === 'string') {
        newTagsTemp.push({ id: item, text: item });
        return { id: item, text: item };
      }
      const { id: itemId, text, newTag } = item;
      if (newTag) {
        newTagsTemp.push({ id: itemId, text });
      }
      return { id: itemId, text };
    });
    setNewOptions(newTagsTemp);
    return pending;
  };
  /* istanbul ignore next */
  const onAutoCompleteClose = (_event: React.SyntheticEvent, reason: AutocompleteCloseReason) => {
    if (reason === 'escape') {
      handleClose();
    }
  };
  /* istanbul ignore next */
  const handleChange = (
    event: React.SyntheticEvent<Element>,
    newValue: (string | TMultiSelectOption)[],
    reason: string
  ) => {
    if (
      event.type === 'keydown' &&
      (event as unknown as KeyboardEvent).key === 'Backspace' &&
      reason === 'removeOption'
    ) {
      return;
    }
    if (maxOptionSelection && newValue.length > maxOptionSelection) {
      setShowAlert(true);
      setMessage(`Maximum ${maxOptionSelection} options can be selected`);
      return;
    }
    const newPendingValues = findPendingValues(newValue);
    setPendingValues(newPendingValues);
    onChange(newPendingValues);
    setShowAlert(false);
  };
  /* istanbul ignore next */
  const handleDelete = (values: TMultiSelectOption[]) => {
    onChange(values);
    setShowAlert(false);
  };
  const searchAdornment = () => (
    <InputAdornment position="start">
      <IconSearch aria-label="Show Search" />
    </InputAdornment>
  );

  const clearAdornment = () => {
    if (!searchValue) {
      return null;
    }
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="Clear Search"
          onClick={() => {
            /* istanbul ignore next */
            setSearchValue('');
            /* istanbul ignore next */
            onChange(pendingValues);
          }}
        >
          <IconCancelCircleSolid />
        </IconButton>
      </InputAdornment>
    );
  };

  const renderSearchInput = (params: AutocompleteRenderInputParams) => {
    return (
      <StyledInput
        ref={params.InputProps.ref}
        inputProps={params.inputProps}
        placeholder="Search"
        startAdornment={searchAdornment()}
        endAdornment={clearAdornment()}
        onChange={e => {
          setSearchValue(e.target.value);
        }}
      />
    );
  };

  const renderOption = (
    optionProps: React.HTMLAttributes<HTMLLIElement>,
    option: TMultiSelectOption,
    { selected }: AutocompleteRenderOptionState
  ) => (
    <Box component="li" {...optionProps} sx={option.newTag ? newTagStyle(isMobile) : undefined}>
      <Box sx={listBoxStyle}>
        {selected && <IconCheckCircle color={colorTheme} style={{ width: 16, height: 16, marginRight: '0.5rem' }} />}
        {option.newTag ? (
          <Button
            variant="text"
            color={colorTheme}
            aria-label="ADD NEW ITEM"
            sx={{ textDecoration: 'none', textTransform: 'uppercase' }}
          >
            ADD NEW ITEM
          </Button>
        ) : (
          <Typography
            component="span"
            variant={selected ? 'subtitle4' : 'body2'}
            sx={{ overflowWrap: 'anywhere', lineHeight: '1.3' }}
          >
            {option.text}
          </Typography>
        )}
      </Box>
    </Box>
  );

  const renderAutoComplete = () => {
    return (
      <>
        <Grid container spacing={1} sx={{ mb: 2, justifyContent: 'center', alignItems: 'center' }}>
          <Grid item xs={9}>
            <Typography variant="subtitle4">{t('academicGoalsAreasOfStudy.subtitle')}</Typography>
            <FormHelperText sx={{ whiteSpace: 'initial' }} role="alert" id="name-error">
              {fieldsOfStudyDisplay?.join(', ')}
            </FormHelperText>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Controller
              control={control}
              name="filterList"
              defaultValue={false}
              render={({ field: { onChange: filterListOnChange, value: filterListValue, ...field } }) => {
                return (
                  <Switch
                    color={getCheckboxColor()}
                    {...field}
                    checked={filterListValue === 'Yes'}
                    onChange={(_event, val) => {
                      /* istanbul ignore next */
                      setShouldFilterOptions(val);
                      /* istanbul ignore next */
                      return filterListOnChange(val ? 'Yes' : 'No');
                    }}
                    inputProps={{
                      'aria-label': 'filter based on fields of study',
                    }}
                  />
                );
              }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ borderStyle: 'solid', mt: '0.7rem', mb: '1rem' }} flexItem />
        <Autocomplete
          {...restProps}
          open
          multiple
          id={id}
          inputValue={searchValue}
          options={[...filteredOptions, ...newOptions]}
          isOptionEqualToValue={isOptionEqualToValue}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          getOptionLabel={optionLabelGetter}
          renderOption={renderOption}
          filterOptions={restProps.freeSolo && !restProps.loading ? customFilterOptions : undefined}
          value={pendingValues}
          onClose={onAutoCompleteClose}
          onChange={handleChange}
          disableCloseOnSelect
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          PopperComponent={StyledAutocompletePopper}
          noOptionsText="No results"
          renderInput={renderSearchInput}
          ListboxProps={{
            'aria-label': `${id}-listbox`,
            'aria-labelledby': undefined,
          }}
        />
      </>
    );
  };

  const getOverlay = () => {
    return (
      <SidePanel size="small" open={open} onClose={handleClose} title={title} onBackClick={handleClose}>
        {showAlert && (
          <Alert severity="warning" onClose={() => setShowAlert(false)}>
            {message}
          </Alert>
        )}
        <Box sx={{ marginTop: `${showAlert ? '10px' : 0}` }}>
          <Tags
            selectedOptions={pendingValues}
            limitTags={limitTags}
            multiSelectRef={multiSelectRef}
            open={open}
            title={title}
            handleClick={openAutoComplete}
            handleClose={handleClose}
            onDelete={handleDelete as (option: TMultiSelectOption[] | null | undefined) => void}
            isMobileView
            colorTheme={colorTheme}
          />
          {renderAutoComplete()}
        </Box>
      </SidePanel>
    );
  };

  return (
    <Box data-testid="MultiSelect-test-id">
      {showAlert && (
        <Alert severity="warning" onClose={() => setShowAlert(false)}>
          {message}
        </Alert>
      )}
      <Tags
        selectedOptions={pendingValues}
        limitTags={limitTags}
        multiSelectRef={multiSelectRef}
        open={open}
        title={title}
        handleClick={openAutoComplete}
        handleClose={handleClose}
        onDelete={handleDelete as (option: TMultiSelectOption[] | null | undefined) => void}
        colorTheme={colorTheme}
      />
      {getOverlay()}
    </Box>
  );
};
