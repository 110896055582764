import { ITspEnrollment } from 'transferPlanner/store/enrollment/enrollment.slice';

export const isEligibleToShowQuestionnaire = (tspEnrollment: ITspEnrollment[] | null): boolean => {
  return !tspEnrollment?.some(
    enrollment =>
      enrollment.active ||
      enrollment.enrollmentStatus?.status.code === 'REQUEST_RELEASE' ||
      (enrollment.enrollmentStatus?.status.code === 'REVOKED' &&
        enrollment.enrollmentStatus?.reason?.code === 'NOT_ELIGIBLE')
  );
};
