/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import React, { useCallback, type ReactElement } from 'react';
import { Button, Grid } from '@mui/material';
import { IconShare, IconSettings } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setUi } from 'store/ui/ui.slice';
import { sxButton, sxFooterBox } from './FooterButtons.styles';

const FooterButtons = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const openSidePanel = useCallback(
    (name: string) => {
      return () => dispatch(setUi({ name, state: { open: true } }));
    },
    [dispatch]
  );
  return (
    <Grid container justifyContent="space-around" alignItems="center" sx={sxFooterBox}>
      <Grid item container xs={6} justifyContent="flex-start">
        <Button
          onClick={openSidePanel('shareProfileSidebar')}
          variant="contained"
          startIcon={<IconShare />}
          sx={sxButton}
        >
          {t('share')}
        </Button>
      </Grid>
      <Grid item container xs={6} justifyContent="flex-end">
        <Button
          onClick={openSidePanel('profileViewSidebar')}
          variant="contained"
          startIcon={<IconSettings />}
          sx={sxButton}
        >
          {t('livingProfile.editSettings')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FooterButtons;
