/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, SyntheticEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DocumentThumbnail, IconTrash, IconEdit, IconDownload } from '@liaison/liaison-ui';
import { IMediaDoc } from 'userProfile/store/mediaDocuments/mediaDocuments.slice';
import { getThumbUrl, downloadMediaDoc } from './MediaDocuments.utils';

type TMediaDocumentThumbnailProps = {
  mediaDoc: IMediaDoc;
  onToggleDrawer: (e: SyntheticEvent, mediaDoc: IMediaDoc | null) => void;
  onClickDeleteMediaDoc: (mediaDoc: IMediaDoc) => void;
  onToggleMediaDocPreview: (index: number) => void;
  index: number;
};

const MediaDocumentCardItem = ({
  mediaDoc,
  onToggleDrawer,
  onClickDeleteMediaDoc,
  onToggleMediaDocPreview,
  index,
}: TMediaDocumentThumbnailProps): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isActionsExpanded, setIsActionsExpanded] = useState<boolean>(false);

  const handleDownloadMediaDoc = () => {
    dispatch(downloadMediaDoc(mediaDoc));
  };

  return (
    <DocumentThumbnail
      key={mediaDoc.id}
      name={mediaDoc.name}
      type={mediaDoc.mediaType}
      size={mediaDoc.sizeDisplay}
      imageUrl={getThumbUrl(mediaDoc.variants)}
      sharesNumber={mediaDoc.attachedCount}
      setIsActionsExpanded={setIsActionsExpanded}
      onImageClick={() => onToggleMediaDocPreview(index)}
      isActionsExpanded={isActionsExpanded}
      actionMenuItems={[
        {
          icon: <IconEdit sx={{ mr: 1 }} />,
          text: t('mediaDocuments.edit'),
          onClick: e => {
            onToggleDrawer(e, mediaDoc);
            setIsActionsExpanded(false);
          },
        },
        ...(mediaDoc.externalURL
          ? /* istanbul ignore next */
            []
          : [
              {
                icon: <IconDownload sx={{ mr: 1 }} />,
                text: t('mediaDocuments.download'),
                onClick: () => {
                  handleDownloadMediaDoc();
                  setIsActionsExpanded(false);
                },
              },
            ]),
        {
          icon: <IconTrash sx={{ mr: 1 }} />,
          text: t('mediaDocuments.delete'),
          onClick: () => {
            onClickDeleteMediaDoc(mediaDoc);
            setIsActionsExpanded(false);
          },
        },
      ]}
    />
  );
};

export default MediaDocumentCardItem;
