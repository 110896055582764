/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export const MIN_LENGTH_FIELD = 8;
export const MAX_SMALL_LENGTH_FIELD = 10;
export const MAX_MEDIUM_LENGTH_FIELD = 50;
export const MAX_BIG_LENGTH_FIELD = 255;
export const MAX_HUGE_LENGTH_FIELD = 500;
export const FIELD_LENGTH_100 = 100;
export const DISABLE_AUTO_FILL = { autoComplete: 'none' };
export const DISABLE_AUTO_FILL_AUTOCOMPLETE = { 'aria-autocomplete': 'none' } as const;
export const DISABLE_ADDRESS_AUTO_FILL = { autoComplete: 'none' };
export const FIELD_LENGTH_20 = 20;
export const FIELD_LENGTH_25 = 25;
export const FIELD_LENGTH_40 = 40;
export const FIELD_LENGTH_50 = 50;
export const FIELD_LENGTH_60 = 60;
export const FIELD_LENGTH_70 = 70;
export const FIELD_LENGTH_80 = 80;
export const FIELD_LENGTH_200 = 200;
export const FIELD_LENGTH_250 = 250;
export const TEXT_AREA_LENGTH_FIELD = 5000;
export const MAX_LANGUAGE_SELECTION_ALLOWED = 100;
export const MAX_TITLE_LENGTH = 70;
export const MAX_LENGTH_COMMUNITY_VIEW = 35;
export const DISABLE_ADDRESS_FILLING = { autoComplete: 'new-password' };
