/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';

import { Box, Button, Typography } from '@mui/material';

interface IEmptySlate {
  imageSource: string;
  title: string;
  buttonText: string;
  imgStyle?: { width: string };
  onClick?: () => void;
}

const EmptySlate = ({
  imageSource,
  title,
  buttonText,
  imgStyle = { width: 'inherit' },
  onClick,
}: IEmptySlate): ReactElement => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
      <img style={{ ...imgStyle }} src={imageSource} alt={title} />
      <Typography variant="body2">{title}</Typography>
      <Button onClick={onClick} variant="contained" sx={{ mt: '1rem' }}>
        {buttonText}
      </Button>
    </Box>
  );
};

export default EmptySlate;
