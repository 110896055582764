/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { API } from 'constants/api';
import { t } from 'i18next';
import { TAppThunk } from 'redux/store';
import {
  personalInfoStart,
  personalInfoSuccess,
  personalInfoFailure,
  TPersonalInfoField,
  IAddress,
  personalInfoAddressSuccess,
  IEmail,
  IPhone,
} from 'userProfile/store/personalInfo/personalInfo.slice';
import { setUi } from 'store/ui/ui.slice';
import { getLoggedUser } from 'utils/keyCloakUtils';
import axios from 'axios';
import { Ioption } from 'store/common/commonInfo.slice';

export const getPersonalInformation = (): TAppThunk => async dispatch => {
  try {
    dispatch(personalInfoStart());
    const { data } = await axios.get(`${API?.profileInformation}${getLoggedUser()}/personalInformation`);
    dispatch(personalInfoSuccess(data));
  } catch (err) {
    dispatch(personalInfoFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  }
};

export const postPersonalInformation =
  (payload: TPersonalInfoField, successCallback?: () => void, failureCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      const loggedUserId = getLoggedUser();
      dispatch(personalInfoStart());
      const modifiedPayload = {
        ...payload,
        profileId: loggedUserId,
      };
      const { data } = await axios.post(
        `${API?.profileInformation}${loggedUserId}/personalInformation`,
        modifiedPayload
      );
      dispatch(personalInfoSuccess(data));
      dispatch(
        setUi({ name: 'succesSnackbar', state: { open: true, message: t('success_message'), hideTitle: true } })
      );
      successCallback?.();
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      dispatch(personalInfoFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
      failureCallback?.();
    }
  };

export const postPersonalInformationForAddress =
  (payload: TPersonalInfoField, successCallback?: () => void): TAppThunk =>
  async dispatch => {
    try {
      const loggedUserId = getLoggedUser();
      dispatch(personalInfoStart());
      const modifiedPayload = {
        ...payload,
        profileId: loggedUserId,
      };
      const { data } = await axios.post(
        `${API?.profileInformation}${loggedUserId}/personalInformation`,
        modifiedPayload
      );
      dispatch(personalInfoAddressSuccess(data));
      dispatch(
        setUi({ name: 'succesSnackbar', state: { open: true, message: t('success_message'), hideTitle: true } })
      );
      successCallback?.();
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      dispatch(personalInfoFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };
interface IEmailObj {
  type?: {
    code: string;
    displayName: string;
  };
  email: string;
}

interface IOptionModified {
  id: string;
  text: string;
}

export const getFilteredEmailOptions = (
  mailIndex: number,
  selectedEmailList: IEmailObj[],
  emailOptions: IOptionModified[]
): IOptionModified[] => {
  const current = selectedEmailList?.[mailIndex]?.type?.code;
  return emailOptions?.filter(el => {
    return (
      (current && el.id === current) ||
      !selectedEmailList?.find((selected: IEmailObj) => {
        return selected.type?.code === el.id;
      })
    );
  });
};

export const getFilteredAddressOptions = (
  addressIndex: number,
  selectedAddressList: IAddress[] | undefined,
  addressOptions: IOptionModified[]
): IOptionModified[] => {
  const current = selectedAddressList?.[addressIndex]?.type?.code;

  return addressOptions?.filter(el => {
    return (
      (current && el.id === current) ||
      !selectedAddressList?.find((selected: IAddress) => {
        return selected?.type?.code === el.id;
      })
    );
  });
};

export const validateDuplicates = (
  list: Record<string, string>[] | undefined,
  mapper: (data: Record<string, string>) => string
): boolean => {
  if (list) {
    const filteredList = list.filter(mapper);
    return filteredList.length === new Set(filteredList.map(mapper)).size;
  }
  return true;
};

export const getPrefferedEmailIndex = (emails: IEmail[]): number => {
  return emails.findIndex(email => email.preferred);
};
export const getPrefferedPhoneIndex = (phones: IPhone[]): number => {
  return phones.findIndex(phone => phone.preferred);
};

export const socialMedia: Record<string, Ioption> = {
  0: { code: 'LINKEDIN', displayName: 'LinkedIn' },
  1: { code: 'CUSTOM', displayName: 'Custom' },
};

export const updateAccountEmail = (payload: TPersonalInfoField | null, newEmail: string): TPersonalInfoField => {
  const emails = [...(payload?.contact?.emails || [])];
  const prefferedEmailndex = getPrefferedEmailIndex(emails);
  emails[prefferedEmailndex] = {
    ...emails[prefferedEmailndex],
    email: newEmail,
  };
  const modifiedData = {
    ...payload,
    contact: {
      ...payload?.contact,
      emails,
    },
  };
  return modifiedData;
};

export type TUpdateEmail = {
  email: string;
  confirmEmail?: string;
};
