/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledBoxWithImage = styled(Box)<{ background?: string }>(({ background }) => ({
  minHeight: '8.5rem',
  height: '100%',
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: background,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
}));

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '60%',
  backgroundColor: 'white',
  padding: '1rem',
  borderRadius: '0.5rem',
}));
