/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, SyntheticEvent, MouseEvent, useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Grid, Button, IconButton, TableCell, TableRow } from '@mui/material';
import { IconEllipsisVertical, MenuActions, IconTrash, IconEdit, IconShare, IconDownload } from '@liaison/liaison-ui';
import { IMediaDoc } from 'userProfile/store/mediaDocuments/mediaDocuments.slice';
import { tableStyles } from 'pages/Pages.styles';
import { sxIcon } from './MediaDocuments.styles';
import { getThumbUrl, downloadMediaDoc } from './MediaDocuments.utils';

type TMediaDocumentListItemProps = {
  mediaDoc: IMediaDoc;
  onToggleDrawer: (e: SyntheticEvent, mediaDoc: IMediaDoc | null) => void;
  onClickDeleteMediaDoc: (mediaDoc: IMediaDoc) => void;
  onToggleMediaDocPreview: (index: number) => void;
  index: number;
};

const MediaDocumentListItem = ({
  mediaDoc,
  onToggleDrawer,
  onClickDeleteMediaDoc,
  onToggleMediaDocPreview,
  index,
}: TMediaDocumentListItemProps): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isExpandedList, setIsExpandedList] = useState<{ [id: string]: boolean }>({});
  /* istanbul ignore next */
  const { variants, id, name = '', sizeDisplay = '', mediaType = '', attachedCount, updatedTimestamp } = mediaDoc;
  /* istanbul ignore next */
  const onActionsClose = (itemId: string) => () => {
    setAnchorEl(null);
    setIsExpandedList(prevIsExpandedList => ({
      ...prevIsExpandedList,
      [itemId]: false,
    }));
  };

  const onActionsExpand = (itemId: string) => (event: MouseEvent<HTMLButtonElement>) => {
    /* istanbul ignore next */
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setIsExpandedList(prevIsExpandedList => ({
      ...prevIsExpandedList,
      [itemId]: true,
    }));
    event.stopPropagation();
  };

  const handleDownloadMediaDoc = () => {
    dispatch(downloadMediaDoc(mediaDoc));
  };

  const imageUrl = getThumbUrl(variants);

  return (
    <TableRow key={id} sx={tableStyles.rowShadow}>
      <TableCell align="left">
        <Grid container columnSpacing={1} alignItems="center">
          <Grid item>
            <Box sx={{ height: 29, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {imageUrl ? (
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    onToggleMediaDocPreview(index);
                  }}
                >
                  <img src={imageUrl} height="29" alt="document preview" />
                </Box>
              ) : null}
            </Box>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle3" sx={{ wordBreak: 'break-word' }}>
                  {name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption" color="text.secondary" textTransform="uppercase">
                  {`${sizeDisplay} ${`${sizeDisplay && mediaType && '•'}`} ${mediaType}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="left">
        <Button
          id={`share-btn-${name}`}
          startIcon={<IconShare id={`share-icon-${name}`} />}
          sx={{
            ...sxIcon('info.background', 'info.main'),
            px: '0.75rem',
          }}
          variant="contained"
          aria-label="share"
        >
          {attachedCount}
        </Button>
      </TableCell>
      <TableCell align="left">{updatedTimestamp}</TableCell>
      <TableCell align="left">
        <IconButton
          id={`menu-btn-${name}`}
          sx={{
            ...sxIcon('primary.background', 'primary.main'),
            px: '0.75rem',
            ml: '0.25rem',
          }}
          onClick={onActionsExpand(id as string)}
          aria-haspopup="true"
          aria-label="open menu"
        >
          <IconEllipsisVertical id={`menu-icon-${name}`} />
        </IconButton>
        <MenuActions
          open={isExpandedList[id as string] || false}
          onClose={onActionsClose(id as string)}
          anchorEl={anchorEl}
          menuItems={[
            {
              icon: <IconEdit sx={{ mr: 1 }} />,
              text: t('mediaDocuments.edit'),
              onClick: e => {
                onToggleDrawer(e, mediaDoc);
                onActionsClose(id as string)();
              },
            },
            ...(mediaDoc.externalURL
              ? /* istanbul ignore next */
                []
              : [
                  {
                    icon: <IconDownload sx={{ mr: 1 }} />,
                    text: t('mediaDocuments.download'),
                    onClick: () => {
                      handleDownloadMediaDoc();
                      onActionsClose(id as string)();
                    },
                  },
                ]),
            {
              icon: <IconTrash sx={{ mr: 1 }} />,
              text: t('mediaDocuments.delete'),
              onClick: () => {
                onClickDeleteMediaDoc(mediaDoc);
                onActionsClose(id as string)();
              },
            },
          ]}
        />
      </TableCell>
    </TableRow>
  );
};

export default memo(MediaDocumentListItem);
