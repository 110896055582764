/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect, useState, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { MultiSelect, type TMultiSelectOption } from '@liaison/liaison-ui';
import { getMultiSelectColor, resourceMap } from 'utils/utilities';
import { resetlookupData } from 'store/common/commonInfo.slice';
import { selectSkillsLookup, selectLoading } from 'store/common/commonInfo.selectors';
import {
  getAccomplishmentsAndExperience,
  getDropDownOptionsForAC,
  getOptionsFromTags,
  getSkills,
} from 'userProfile/pages/AccomplishmentsAndExperiences/AccomplishmentsAndExperiences.utils';
import {
  selectAccomplishmentAndExperience,
  selectSectionSkills,
  selectSkillsAttachments,
  selectSectionSkillsAttachments,
} from 'userProfile/store/accomplishmentAndExperience/accomplishmentAndExperience.selectors';
import {
  TCompetency,
  setSkillsAttachments,
} from 'userProfile/store/accomplishmentAndExperience/accomplishmentAndExperience.slice';

type TSkillsMultielectProps = {
  tag: string;
  disabled?: boolean;
};

const SkillsMultiselect = ({ tag, disabled }: TSkillsMultielectProps): ReactElement | null => {
  const [skillsOptions, setSkillsOptions] = useState<TMultiSelectOption[]>([]);
  const accomplishmentAndExperienceData = useSelector(selectAccomplishmentAndExperience);
  const skillsAttachments = useSelector(selectSkillsAttachments);
  const skillsLookup = useSelector(selectSkillsLookup);
  const isLoading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const sectionName = tag.split('/').shift() as string;
  const sectionId = tag.split('/').pop();
  const sectionExistingSkills = useSelector(state => selectSectionSkills(state, sectionId));
  const sectionSkillsAttachments = useSelector(state => selectSectionSkillsAttachments(state, tag));

  const combinedOptions = [...skillsOptions, ...getOptionsFromTags(sectionSkillsAttachments?.competencies || [])];
  const uniqueOptions = Array.from(new Set(combinedOptions.map(a => a.id))).map(id =>
    combinedOptions.find(a => a.id === id)
  );
  const selectedValues = sectionSkillsAttachments?.competencies?.map((item: TCompetency) => item.competency);

  const dispatchSetSkillsAttachments = (skillIds: TCompetency[]) => {
    const updatedSkillsAttachments =
      skillsAttachments?.map(item => {
        if (item.tag === tag) {
          return { ...item, competencies: skillIds };
        }
        return item;
      }) || [];
    if (!skillsAttachments?.some(item => item.tag === tag)) {
      updatedSkillsAttachments?.push({ tag, resource: resourceMap[sectionName], competencies: skillIds });
    }
    dispatch(setSkillsAttachments(updatedSkillsAttachments));
  };

  useEffect(() => {
    if (!accomplishmentAndExperienceData) {
      dispatch(getAccomplishmentsAndExperience());
    }
  }, [accomplishmentAndExperienceData, dispatch]);

  useEffect(() => {
    if (accomplishmentAndExperienceData && sectionExistingSkills) {
      dispatchSetSkillsAttachments(sectionExistingSkills);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accomplishmentAndExperienceData]);

  useEffect(() => {
    return () => {
      dispatch(resetlookupData(['skills']));
    };
  }, [dispatch]);

  useEffect(() => {
    setSkillsOptions(getDropDownOptionsForAC(skillsLookup || []));
  }, [skillsLookup]);

  const handleSearch = (searchParam: string) => {
    if (searchParam?.length >= 2) {
      dispatch(getSkills(`?startsWith=${searchParam}&pageSize=10000`));
    }
  };

  return (
    <Box>
      <Typography variant="h6" component="p">
        {t('competenciesAndSkills.title')}
      </Typography>
      <MultiSelect
        id="competencies"
        freeSolo
        loading={isLoading}
        loadingText={t('languagesAndSkills.loading')}
        title={t('competenciesAndSkills.competencies')}
        options={uniqueOptions as TMultiSelectOption[]}
        value={selectedValues || null}
        aria-label={t('competenciesAndSkills.title')}
        maxOptionSelection={Infinity}
        onInputChange={handleSearch}
        onChange={options => {
          const allIds = options?.map(item => ({ competency: item.id })) || [];
          dispatchSetSkillsAttachments(allIds);
        }}
        colorTheme={getMultiSelectColor()}
        disabled={disabled}
      />
    </Box>
  );
};

export default memo(SkillsMultiselect);
