/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { IProgram } from 'transferPlanner/store/searchProgram/searchProgram.slice';

export type TProgramWithPosition = IProgram & { first?: boolean; last?: boolean };

const borderRadius = '8px';

export const sxCard = {
  width: 333,
  bgcolor: '#fff',
  border: '1px solid #AFBBCC',
  borderRadius,
  boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06)',
  p: 0,
} as const;

export const sxCardListView = ({ first, last }: TProgramWithPosition) =>
  ({
    width: '100%',
    borderRadius: 0,
    boxShadow: 'none',
    borderBottomWidth: last ? '1px' : 0,
    borderBottomLeftRadius: last ? borderRadius : 0,
    borderBottomRightRadius: last ? borderRadius : 0,
    borderTopLeftRadius: first ? borderRadius : 0,
    borderTopRightRadius: first ? borderRadius : 0,
  } as const);

export const buttonStyle = {
  textDecoration: 'underline',
  background: 'none',
  color: 'inherit',
  border: 'inherit',
  padding: 0,
  cursor: 'pointer',
  textAlign: 'inherit',
} as const;
