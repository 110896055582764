/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';
import { IAdditionalDetailsState, IAdditionalDetailsResponse, IAdtInformation } from './additionalDetails.slice';

const selectAdditionalDetailsState = (state: TRootState): IAdditionalDetailsState => state.additionalDetails;

export const selectAdditionalDetails = createSelector(
  selectAdditionalDetailsState,
  (additionalDetailsState: IAdditionalDetailsState): IAdditionalDetailsResponse => additionalDetailsState?.data
);

export const selectAdtInformation = createSelector(
  selectAdditionalDetailsState,
  (additionalDetailsState: IAdditionalDetailsState): IAdtInformation[] | undefined =>
    additionalDetailsState?.data?.additionalDetails?.adtInformation
);

export const selectAdditionalDetailsLoading = createSelector(
  selectAdditionalDetailsState,
  (stateData: IAdditionalDetailsState) => stateData?.loading
);
