/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTenantConfig } from 'store/common/commonInfo.selectors';
import { resetGlobalErrorState } from 'redux/rootReducer';
import { getTenantConfig, TOKEN_EXPIRATION_IN_SEC } from 'utils/commonUtils';
import { refreshImpToken } from 'pages/Impersonation/Impersonation.util';
import WithKeycloakProvider from './WithKeycloakProvider';
import WithoutKeycloakProvider from './WithoutKeycloakProvider';
import { nonKeycloakPathsRegex } from './Routes.util';

const Root = (): ReactElement => {
  const dispatch = useDispatch();
  const tenantConfig = useSelector(selectTenantConfig);

  useEffect(() => {
    if (!tenantConfig) {
      dispatch(getTenantConfig());
    }
  }, [tenantConfig, dispatch]);

  useEffect(() => {
    dispatch(resetGlobalErrorState());
  }, [dispatch]);

  useEffect(() => {
    if (localStorage.getItem('impersonation')) {
      const expIn = localStorage.getItem('kc_impersonation_expiresIn') as string;
      const expiresIn = parseInt(expIn, 10);
      refreshImpToken(expiresIn);
      const tokenInterval = setInterval(() => {
        refreshImpToken(expiresIn).catch(() => clearInterval(tokenInterval));
      }, (expiresIn || TOKEN_EXPIRATION_IN_SEC) * 900);
    }
  }, []);

  return (
    <>
      {nonKeycloakPathsRegex.some(rx => rx.test(window.location?.pathname)) ? (
        <WithoutKeycloakProvider />
      ) : (
        <WithKeycloakProvider />
      )}
    </>
  );
};

export default Root;
