/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { useState, ReactElement, useEffect, useMemo } from 'react';
import { AppBar, Box, Divider, Grid, IconButton, Typography, Theme, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IconArrowLeft, ToggleButton } from '@liaison/liaison-ui';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useImpersonation } from 'hooks/useImpersonation';
import { DASHBOARD } from 'transferPlanner/constants/routeNames';
import { selectCampuses } from 'transferPlanner/store/campuses/campuses.selectors';
import {
  setSelectedCampusIds,
  setIsEligible,
  setSearchQuery,
} from 'transferPlanner/store/searchProgram/searchProgram.slice';
import { ProgramFilters } from 'transferPlanner/components/ProgramFilters';
import { MyProgramsButton } from 'transferPlanner/components/MyProgramsButton';
import { selectAdditionalDetails } from 'transferPlanner/store/additionalDetails/additionalDetails.selectors';
import { setTitle } from 'utils/commonUtils';
import { googleMapZoomLevel, markerZoomOffset, nameSpace } from 'transferPlanner/constants/general';
import { tspDeadlineExtensionTimestamp } from 'transferPlanner/store/enrollment/enrollment.selectors';
import { getTpAdditionalDetails } from 'transferPlanner/pages/TpAdditionalDetails/TpAdditionalDetails.utils';
import Cart from 'transferPlanner/components/MyProgramsCart/Cart';
import { StyledToolBar } from 'pages/Pages.styles';
import { useFixedItemInView } from 'hooks/useFixedItemInView';
import useDeadlineExtensionBanner from 'hooks/useDeadlineExtensionBanner/useDeadlineExtensionBanner';
import { getCampuses, ICampus } from './CampusesAndPrograms.utils';
import { CampusList, CampusMapView } from './Campus';
import {
  divider,
  leftContainer,
  searchBarSmallerDivice,
  StyledAbsoluteToggleViewBox,
  StyledBox,
  StyledGrid,
  StyledImpersonationGrid,
} from './CampusesAndPrograms.styles';
import { ProgramsSearchView } from './Programs';
import { EViewType } from './ToggleView';

const CampusesAndPrograms = (): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(nameSpace);
  const dispatch = useDispatch();

  const impersonationToken = useImpersonation();
  const campusesData = useSelector(selectCampuses);
  const { additionalDetails } = useSelector(selectAdditionalDetails);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [type, setType] = useState(location?.state?.type || EViewType.campus);
  const [filteredData, setFilteredData] = useState<ICampus[] | []>([]);
  const tspDeadlineExtensionTime = useSelector(tspDeadlineExtensionTimestamp);
  const [focusedItemIndex, setFocusedItemIndex] = useState<number | undefined>(undefined);
  const { isFixed } = useFixedItemInView();
  const shouldDisplayTheBanner = useDeadlineExtensionBanner();

  const [center, setCenter] = useState({
    lat: 36.778259,
    lng: -119.417931,
  });
  const [isSwipe, setIsSwipe] = useState(false);

  const [zoomLevel, setZoomLevel] = useState(googleMapZoomLevel);

  setTitle(t('campusesAndProgram.pageTitle'));

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'scroll';
    };
  }, []);

  useEffect(() => {
    if (!campusesData) {
      dispatch(getCampuses());
    }
  }, [dispatch, campusesData]);

  useEffect(() => {
    if (!additionalDetails) {
      dispatch(getTpAdditionalDetails());
    }
  }, [dispatch, additionalDetails]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedCampusIds([]));
      dispatch(setIsEligible(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (campusesData && campusesData?.length > 0) {
      setFilteredData(campusesData);
    }
  }, [campusesData]);

  useEffect(() => {
    if (filteredData && filteredData?.length > 0) {
      const selectedCampusId = filteredData.find(campus => campus.show)?.id;
      if (selectedCampusId) dispatch(setSelectedCampusIds([selectedCampusId]));
    }
  }, [dispatch, filteredData]);

  const changeType = (toggleType: EViewType) => {
    setType(toggleType);
    dispatch(setSelectedCampusIds([]));
    dispatch(setSearchQuery(''));
  };

  const onClickMap = (index: number) => {
    const modifiedObj = filteredData.map((currentData, idx) => {
      const modified = {
        ...currentData,
      };
      if (index === idx) {
        if (!currentData.show && currentData?.contact) {
          /* istanbul ignore next */
          setCenter({
            lat: (currentData.contact.latitude || 0) + markerZoomOffset[zoomLevel],
            lng: currentData.contact.longitude || 0,
          });
        }
        modified.show = !currentData.show;
      } else {
        modified.show = false;
      }
      return modified;
    });
    setFilteredData(modifiedObj);
  };

  const isImpersonated = useMemo(() => (!!impersonationToken).toString(), [impersonationToken]);
  return (
    <>
      <StyledImpersonationGrid
        impersonation={isImpersonated}
        tspdeadlineextensiontime={tspDeadlineExtensionTime || ''}
        iscampusesandprograms="true"
      >
        <AppBar position={isTablet && (impersonationToken || shouldDisplayTheBanner) && isFixed ? 'fixed' : 'static'}>
          <StyledToolBar variant="dense" sx={{ minHeight: { sm: 40 } }}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <IconButton
                  edge="start"
                  aria-label="back to dashboard"
                  sx={{
                    ml: '-1.1rem',
                    mr: { xs: -0.5, sm: 'auto' },
                    color: (theme: Theme) => theme.palette.common.black,
                  }}
                  onClick={() => {
                    navigate(`/${DASHBOARD}`);
                  }}
                >
                  <IconArrowLeft />
                </IconButton>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ flexGrow: 1, display: { sm: 'block' } }}
                  role="heading"
                  aria-level={1}
                >
                  {t(`campusesAndProgram.title${isMobile ? 'Mobile' : ''}`)}
                </Typography>
              </Grid>
              <Grid item>
                <MyProgramsButton />
              </Grid>
            </Grid>
          </StyledToolBar>
        </AppBar>
      </StyledImpersonationGrid>
      <StyledGrid container role="main">
        <Grid item sm={3} xs={12} sx={leftContainer}>
          <ToggleButton
            options={[
              {
                text: t('campusesAndProgram.campuses'),
                val: EViewType.campus,
              },
              {
                text: t('campusesAndProgram.programs'),
                val: EViewType.program,
              },
            ]}
            onChange={val => changeType(val as unknown as EViewType)}
            selectedValue={type}
          />
          <Divider
            sx={{
              borderStyle: 'solid',
              borderColor: '#A6A6A6',
              mx: '0.5rem',
              py: '0.2rem',
            }}
          />
          {type === EViewType.campus && !isMobile && (
            <CampusList
              campuses={campusesData}
              filteredCampuses={filteredData}
              setCenter={setCenter}
              onClickMap={onClickMap}
              setFilteredData={setFilteredData}
              setFocusedItemIndex={setFocusedItemIndex}
              focusedItemIndex={focusedItemIndex}
            />
          )}
          {type === EViewType.program && !isMobile && <ProgramFilters />}
        </Grid>
        <Grid item xs={12} md={9} sx={{ bgcolor: '#ECECEC' }}>
          <StyledBox>
            {type === EViewType.campus && (
              <CampusMapView
                data={filteredData}
                center={center}
                onClickMap={onClickMap}
                setType={setType}
                setZoomLevel={setZoomLevel}
                focusedItemIndex={focusedItemIndex}
              />
            )}
            {type === EViewType.program && <ProgramsSearchView />}
          </StyledBox>
        </Grid>
        {isMobile && (
          <>
            {(!isTablet || (isTablet && type === EViewType.campus)) && (
              <StyledAbsoluteToggleViewBox
                impersonation={isImpersonated}
                tspdeadlineextensiontime={tspDeadlineExtensionTime || ''}
                data-testid="absoluteToggleView"
              >
                <ToggleButton
                  options={[
                    {
                      text: t('campusesAndProgram.campuses'),
                      val: EViewType.campus,
                    },
                    {
                      text: t('campusesAndProgram.programs'),
                      val: EViewType.program,
                    },
                  ]}
                  onChange={val => changeType(val as unknown as EViewType)}
                  selectedValue={type}
                  sx={{ mb: '1rem', mx: '0.5rem' }}
                />
              </StyledAbsoluteToggleViewBox>
            )}
            <Grid sx={[searchBarSmallerDivice, isSwipe && { top: '20%' }]}>
              <Box
                sx={{ p: '1rem' }}
                role="button"
                onClick={() => setIsSwipe(!isSwipe)}
                aria-label="pull campus search"
              >
                <Divider sx={divider} />
              </Box>
              {type === EViewType.campus && (
                <CampusList
                  campuses={campusesData}
                  filteredCampuses={filteredData}
                  setCenter={setCenter}
                  onClickMap={onClickMap}
                  setFilteredData={setFilteredData}
                  setFocusedItemIndex={setFocusedItemIndex}
                  focusedItemIndex={focusedItemIndex}
                />
              )}
              {type === EViewType.program && (
                <>
                  <Box sx={{ maxHeight: '85%', overflowY: 'scroll' }}>
                    <ProgramFilters setIsSwipe={setIsSwipe} isSwipe={isSwipe} />
                  </Box>
                </>
              )}
            </Grid>
          </>
        )}
      </StyledGrid>
      <Cart />
    </>
  );
};

export default CampusesAndPrograms;
