/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { t } from 'i18next';
import { csuNameSpace } from 'utils/utilities';
import { IEnrollment, ITspEnrollment } from 'transferPlanner/store/enrollment/enrollment.slice';

export const defaultValues = {
  reason: '',
  noPlanningReason: '',
  otherReason: '',
};

export const reasonOptions = [
  {
    val: 'I picked the wrong program or university by mistake',
    text: t('tspRequestRelease.option1', csuNameSpace),
  },
  {
    val: 'I am no longer planning to transfer to a CSU campus',
    text: t('tspRequestRelease.option2', csuNameSpace),
  },
  {
    val: 'I am choosing a different career path',
    text: t('tspRequestRelease.option3', csuNameSpace),
  },
  {
    val: 'I am deferring my start at the community college',
    text: t('tspRequestRelease.option4', csuNameSpace),
  },
  {
    val: 'I am taking a leave of absence or break from attendance at community college',
    text: t('tspRequestRelease.option5', csuNameSpace),
  },
  { val: 'Other', text: t('tspRequestRelease.option6', csuNameSpace) },
];

export const noPlanningOptions = [
  {
    val: 'because I am moving',
    text: t('tspRequestRelease.noPlanning.option1', csuNameSpace),
  },
  {
    val: 'because I am going to a school out of state',
    text: t('tspRequestRelease.noPlanning.option2', csuNameSpace),
  },
  {
    val: 'for personal reasons',
    text: t('tspRequestRelease.noPlanning.option3', csuNameSpace),
  },
  {
    val: 'for financial reasons',
    text: t('tspRequestRelease.noPlanning.option4', csuNameSpace),
  },
];

const reasonCodes = {
  [reasonOptions[0].val]: 'MISTAKE',
  [`${reasonOptions[1].val} ${noPlanningOptions[0].val}`]: 'NOT_TRANSFERRING_CSU_MOVING',
  [`${reasonOptions[1].val} ${noPlanningOptions[1].val}`]: 'NOT_TRANSFERRING_CSU_OUT_OF_STATE',
  [`${reasonOptions[1].val} ${noPlanningOptions[2].val}`]: 'NOT_TRANSFERRING_CSU_PERSONAL',
  [`${reasonOptions[1].val} ${noPlanningOptions[3].val}`]: 'NOT_TRANSFERRING_CSU_FINANCIAL',
  [reasonOptions[2].val]: 'DIFFERENT_CAREER_PATH',
  [reasonOptions[3].val]: 'DEFERRING_COMMUNITY_COLLEGE_START',
  [reasonOptions[4].val]: 'COMMUNITY_COLLEGE_BREAK',
  [reasonOptions[5].val]: 'OTHER',
};

export const composePayload = (
  tspEnrollment: IEnrollment,
  activeTspEnrollment: ITspEnrollment,
  reason: string,
  other?: string
): ITspEnrollment[] => {
  const enrollments = [...tspEnrollment.tspEnrollment];
  const activeTspEnrollmentReleased = {
    ...activeTspEnrollment,
    enrollmentStatus: {
      status: {
        code: 'REQUEST_RELEASE',
        displayName: 'Request for release',
      },
      requestRelease: {
        reason: {
          code: reasonCodes[reason],
          displayName: reason,
          other,
        },
      },
    },
  };

  const activeTspEnrollmentIndex = enrollments.findIndex(enrollment => enrollment.active);
  enrollments.splice(activeTspEnrollmentIndex, 1, activeTspEnrollmentReleased);

  return enrollments;
};
