/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import React, { Fragment, ReactElement, memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { Box, Stack } from '@mui/material';

import { setTitle } from 'utils/commonUtils';
import {
  selectError,
  selectLoading,
  selectProfileView,
  selectAllProfileViews,
  selectCurrentProfileViewId,
} from 'userProfile/store/profileView/profileView.selectors';
import { Spinner } from 'components/Spinner';
import { PosterSidePanel } from 'userProfile/components/PosterSidePanel';
import { Intro } from './Intro';
import { CompetenciesSkills } from './CompetenciesSkills';
import { Achievements } from './Achievements';
import { Languages } from './Languages';
import { getAllProfileViews, getProfileView, getSharedProfileView } from './LivingProfile.utils';
import { AcademicHistory } from './AcademicHistory';
import { Experiences } from './Experiences';
import { MediaAndDocuments } from './MediaAndDocuments';
import { SettingsSidePanel } from './SettingsSidePanel';
import { ShareProfileSidePanel } from './ShareProfileSidePanel';
import { NotAvailablePage } from './NotAvailablePage';
import { TProfileView, type TProfileViewSections } from '../../store/profileView/profileView.slice';

const LivingProfile = (): ReactElement => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoading);
  const profileView = useSelector(selectProfileView);
  const allProfileViews = useSelector(selectAllProfileViews);
  const currentProfileViewId = useSelector(selectCurrentProfileViewId);
  const error = useSelector(selectError);
  const isSharedProfileView = location.pathname.includes('shared-profile-view');

  useEffect(() => {
    setTitle(t('livingProfile.pageTitle'));
  }, [t]);

  useEffect(() => {
    if (isSharedProfileView) {
      dispatch(getSharedProfileView(params.id));
    } else if (!allProfileViews) {
      dispatch(getAllProfileViews(currentProfileViewId));
    } else {
      dispatch(getProfileView(currentProfileViewId));
    }
  }, [dispatch, isSharedProfileView, allProfileViews, currentProfileViewId, params.id]);

  const createSection = (view: TProfileView, key: TProfileViewSections, Component: React.ComponentType) => {
    const section = view[key];
    return section && !section.hidden ? [{ order: section.order, element: <Component /> }] : [];
  };

  const sections = useMemo(() => {
    if (!profileView) {
      return [];
    }
    return [
      ...createSection(profileView, 'academicHistory', AcademicHistory),
      ...createSection(profileView, 'experiences', Experiences),
      ...createSection(profileView, 'achievements', Achievements),
      ...createSection(profileView, 'languages', Languages),
      ...createSection(profileView, 'competencies', CompetenciesSkills),
      ...createSection(profileView, 'mediaAndDocuments', MediaAndDocuments),
    ].sort((a, b) => a.order - b.order);
  }, [profileView]);

  if (isSharedProfileView && isLoading) {
    return <Spinner backdrop />;
  }

  if (isSharedProfileView && error) {
    return <NotAvailablePage />;
  }

  return (
    <Box sx={{ px: { xs: '1rem', sm: '3.5rem', md: '6.5rem' }, py: '1.5rem' }}>
      {!isSharedProfileView && isLoading && <Spinner backdrop />}
      <Stack spacing={3}>
        <Intro />
        {profileView && sections.map(item => <Fragment key={String(item.order)}>{item.element}</Fragment>)}
      </Stack>
      {!isSharedProfileView && (
        <>
          <SettingsSidePanel />
          <ShareProfileSidePanel />
          <PosterSidePanel />
        </>
      )}
    </Box>
  );
};

export default memo(LivingProfile);
