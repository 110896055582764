/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Chip, chipClasses, Grid, Card, CardContent, Typography } from '@mui/material';
import { IconCaretRight, IconCheckCircle } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';
import type { IProgram } from 'transferPlanner/store/programCart/programCart.slice';
import { sxCard } from 'pages/Pages.styles';
import { nameSpace } from 'transferPlanner/constants/general';
import { useNavigate } from 'react-router-dom';
import { PROGRAM_PROGRESS } from 'transferPlanner/constants/routeNames';
import { encryptWithEncode } from 'utils/forgeUtils';

export type TProgram = IProgram & {
  isEnrolled?: boolean;
};
type TProgramCardProps = {
  program: TProgram;
};

export const ProgramCard = ({ program }: TProgramCardProps): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const navigate = useNavigate();

  return (
    <Card
      variant="outlined"
      role="button"
      sx={{
        ...sxCard,
        my: 1,
        color: 'text.primary',
        ...(program?.isEnrolled ? { borderColor: 'success.main' } : {}),
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      tabIndex={0}
      data-testid="followed-program-progress-testid"
      onClick={() => {
        navigate(`/${PROGRAM_PROGRESS}/${encryptWithEncode(`${program.id}`)}`);
      }}
      onKeyUp={event => {
        if (event.code === 'Enter') {
          navigate(`/${PROGRAM_PROGRESS}/${encryptWithEncode(`${program.id}`)}`);
        }
      }}
    >
      <CardContent
        sx={{
          py: 1,
          '&:last-child': {
            pb: 1,
          },
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={10.6} md={11.5} lg={10.6} container>
            {program?.isEnrolled && (
              <Grid item xs={12}>
                <Chip
                  sx={{
                    [`&.${chipClasses.root}`]: {
                      borderRadius: '2px',
                      color: 'secondary.contrastText',
                      backgroundColor: 'success.main',
                    },
                  }}
                  size="small"
                  icon={<IconCheckCircle style={{ color: '#FFFFFF' }} />}
                  label={t('programs.enrolled.label')}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography
                variant="subtitle7"
                sx={{
                  textDecoration: 'underline',
                }}
              >
                {program.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1.4} md={0.5} lg={1.4} sx={{ p: 1 }}>
            <IconCaretRight aria-label={program.name} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
