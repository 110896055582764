/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { useCallback, ReactElement, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, Button, IconButton, Drawer, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon, IconHelp, IconCaretDownDouble, IconLightbulb } from '@liaison/liaison-ui';

import { StyledButton, linkStyle } from 'pages/Pages.styles';
import { setUi } from 'store/ui/ui.slice';
import { ACADEMIC_HISTORY } from 'userProfile/constants/routeNames';
import { TSP_QUESTIONNAIRE } from 'transferPlanner/constants/routeNames';
import { nameSpace } from 'transferPlanner/constants/general';
import { IMenuItem } from 'transferPlanner/constants/menuItems';
import { mailTo } from 'utils/utilities';
import { selectTspEnrollment, selectTspEnrollmentPeriod } from 'transferPlanner/store/enrollment/enrollment.selectors';
import { isEligibleToShowQuestionnaire } from 'transferPlanner/pages/Dashboard/Dashboard.utils';

import { WelcomeSection } from 'transferPlanner/pages/Dashboard';
import { sxFooterBox, sxHeadingMenu, sxDrawerBox, StyledNavBox, StyledInfoBox } from './MobileFooter.styles';

type TMobileFooter = { label: string; icon: string; location?: string; action?: string };

type TMobileFooterProps = {
  footerSectionMenus: TMobileFooter[];
};

const enum ELabels {
  next = 'whats_next_label',
  explore = 'explore_label',
}

const MobileFooter = ({ footerSectionMenus }: TMobileFooterProps): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const tspEnrollment = useSelector(selectTspEnrollment);
  const isTspEnrollmentOpen = useSelector(selectTspEnrollmentPeriod)?.enrollmentOpen;

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [drawerTitle, setDrawerTitle] = useState<ELabels | null>(null);
  const navigate = useNavigate();
  const toggleDrawer = (newOpen: boolean, title?: ELabels) => () => {
    setOpen(newOpen);
    if (title) setDrawerTitle(title);
  };

  const handleItemClick = (location: string) => {
    setOpen(false);
    navigate(`/${location}`);
    window.scrollTo(0, 0);
  };

  const showQuestionnaire = useMemo(() => isEligibleToShowQuestionnaire(tspEnrollment), [tspEnrollment]);

  const setOpenSidebar = useCallback(
    (name?: string) => () => {
      setOpen(false);
      dispatch(setUi({ name, state: { open: true } }));
    },
    [dispatch]
  );

  const onClickHandler = (menu: IMenuItem) => {
    if (menu?.location) handleItemClick(menu?.location);
    if (menu?.mailTo) mailTo(menu?.mailTo);
  };

  return (
    <Box sx={sxFooterBox}>
      <Grid container rowSpacing={2}>
        <Grid item xs={5.75}>
          <StyledButton
            onClick={toggleDrawer(true, ELabels.next)}
            fullWidth
            startIcon={<IconLightbulb />}
            sx={{ height: 34 }}
          >
            {t(ELabels.next)}
          </StyledButton>
        </Grid>
        <Grid item xs={0.5} />
        <Grid item xs={5.75}>
          <Button sx={{ height: 34 }} onClick={toggleDrawer(true, ELabels.explore)} fullWidth variant="contained">
            {t(ELabels.explore)}
          </Button>
        </Grid>
      </Grid>
      <Drawer
        PaperProps={{
          sx: { ...sxDrawerBox },
        }}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        role="contentinfo"
      >
        <Box sx={{ p: 2.5, pb: 1 }}>
          <Box sx={{ display: 'flex' }}>
            {drawerTitle === ELabels.next && (
              <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                <IconHelp
                  sx={{
                    color: theme => theme.palette.custom.drawer,
                    height: 28,
                    width: 28,
                  }}
                />
              </Box>
            )}
            <Typography sx={sxHeadingMenu} component="h2" variant="h3">
              {t(drawerTitle || '')}
            </Typography>
            <IconButton
              onClick={toggleDrawer(false)}
              aria-label="close section bar"
              sx={{
                color: theme => theme.palette.primary.contrastText,
              }}
            >
              <IconCaretDownDouble />
            </IconButton>
          </Box>
          <Box>
            {drawerTitle === ELabels.next && (
              <>
                <WelcomeSection />
                {isTspEnrollmentOpen ? (
                  showQuestionnaire && (
                    <StyledInfoBox>
                      <Typography variant="subtitle7" component="span">
                        {t('dashboard.checkEligibility')}
                      </Typography>
                      <Button
                        onClick={() => handleItemClick(TSP_QUESTIONNAIRE)}
                        style={{ ...linkStyle, textTransform: 'inherit' }}
                      >
                        {t('begin_questionnaire_label')}
                      </Button>
                    </StyledInfoBox>
                  )
                ) : (
                  <StyledInfoBox>
                    <Typography variant="subtitle7" component="span">
                      {t('dashboard.alreadyEnrolled')}
                    </Typography>
                    <Button
                      onClick={setOpenSidebar('collegeSidebar')}
                      style={{ ...linkStyle, textTransform: 'inherit' }}
                    >
                      {t('add_college_label')}
                    </Button>
                    <Button
                      onClick={() => handleItemClick(ACADEMIC_HISTORY.path)}
                      style={{ ...linkStyle, textTransform: 'inherit' }}
                    >
                      {t('edit_academic_history_label')}
                    </Button>
                  </StyledInfoBox>
                )}

                <StyledInfoBox>
                  <Typography variant="subtitle7" component="span">
                    {t('dashboard.newTerm')}
                  </Typography>
                  <Button
                    onClick={() => handleItemClick(ACADEMIC_HISTORY.path)}
                    style={{ ...linkStyle, textTransform: 'inherit' }}
                  >
                    {t('go_to_academic_history_label')}
                  </Button>
                </StyledInfoBox>
              </>
            )}
            {drawerTitle === ELabels.explore &&
              footerSectionMenus.map(menu => {
                return (
                  <StyledNavBox
                    onClick={menu?.action ? setOpenSidebar(menu?.action) : () => onClickHandler(menu)}
                    key={menu.icon}
                  >
                    <Icon style={{ color: '#CC0B2A' }}>{menu?.icon}</Icon>
                    <Typography
                      align="left"
                      variant="subtitle7"
                      component="h6"
                      sx={{
                        margin: '5px 0px 5px 10px',
                      }}
                      role="presentation"
                    >
                      {menu?.label}
                    </Typography>
                    <KeyboardArrowRightIcon />
                  </StyledNavBox>
                );
              })}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileFooter;
