/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect, Suspense, lazy, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Divider, FormControl, InputLabel, Typography } from '@mui/material';
import { useForm, Controller, FormProvider, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dropdown, SidePanel, TDropdownOption } from '@liaison/liaison-ui';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { useAppSelector } from 'redux/hooks';
import { StyledDeleteButton, sxSidePanel } from 'pages/Pages.styles';
import { DISABLE_AUTO_FILL } from 'constants/field';
import { setTitle } from 'utils/commonUtils';
import { getStandardTestsConfig, postStandardTest } from 'utils/standardTests.utils';
import {
  selectStandardTestsState,
  selectStandardTests,
  selectAllTestTypes,
  selectTest,
  selectTestCategories,
  selectCategoryTestTypes,
  selectTestTypes,
} from 'store/standardTests/standardTests.selectors';
import type { TStandardTests, TStandardTestsStateKeys } from 'store/standardTests/standardTests.slice';
import { type FieldError, getButtonColor } from 'utils/utilities';
import {
  AP,
  CLEP,
  IB,
  TOEFL,
  IELTS,
  GRE,
  GRE_SUBJECT,
  GMAT,
  ACT,
  MCAT,
  PTE,
  LSAT,
  SAT,
  composePayload,
  groupName,
  TOption,
  validationSchema,
} from './TestsForm.utils';

const APExam = lazy(() => import('./STests/AP/APExam'));
const CLEPExam = lazy(() => import('./STests/CLEP/CLEPExam'));
const IBExam = lazy(() => import('./STests/IB/IBExam'));
const TOEFLExam = lazy(() => import('./STests/TOEFL/TOEFLExam'));
const IELTSExam = lazy(() => import('./STests/IELTS/IELTSExam'));
const GREExam = lazy(() => import('./STests/GRE/GREExam'));
const GRESubjectExam = lazy(() => import('./STests/GRESubject/GRESubjectExam'));
const GMATExam = lazy(() => import('./STests/GMAT/GMATExam'));
const ACTExam = lazy(() => import('./STests/ACT/ACTExam'));
const MCATExam = lazy(() => import('./STests/MCAT/MCATExam'));
const PTEExam = lazy(() => import('./STests/PTE/PTEExam'));
const LSATExam = lazy(() => import('./STests/LSAT/LSATExam'));
const SATExam = lazy(() => import('./STests/SAT/SATExam'));

type TTestsFormProps = {
  onCloseSidebar: () => void;
  isAddNew: boolean;
  childPosition: number;
};

type TErrorsType = {
  [type: string]: { code: FieldError };
};

export const TestsForm = ({ onCloseSidebar, isAddNew, childPosition }: TTestsFormProps): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const standardTestsState = useAppSelector(selectStandardTestsState);
  const testCategories = useAppSelector(selectTestCategories);
  const allTestTypes = useAppSelector(selectAllTestTypes);
  const definedTestTypes = useAppSelector(selectTestTypes);
  const standardTests = useAppSelector(selectStandardTests);
  const tests = (standardTests as TStandardTests)?.tests;
  const testData = useAppSelector(state => selectTest(state, childPosition));
  const [showAlert, setShowAlert] = useState(false);
  const [testCategory, setTestCategory] = useState<string | number>();
  const categoryTestTypes = useAppSelector(state => selectCategoryTestTypes(state, testCategory));

  const testTypes = useMemo(
    () => (isAddNew ? allTestTypes : definedTestTypes),
    [allTestTypes, definedTestTypes, isAddNew]
  );

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema, { abortEarly: false }),
  });

  const {
    control,
    reset,
    setValue,
    formState: { errors: formErrors, isValid },
    handleSubmit,
    watch,
  } = methods;

  const errors = formErrors as unknown as TErrorsType;

  /* istanbul ignore next */
  useEffect(() => {
    if (testData && !isAddNew) {
      const testType = testData.type.code as TStandardTestsStateKeys;
      if (testType && !standardTestsState[testType]) {
        dispatch(getStandardTestsConfig(testType));
      }
      reset(testData);
      if (testData?.status.code === 'NOT_RECEIVED') {
        setValue('noScore', true);
        setValue('status', {
          code: 'TAKEN',
          displayName: "Yes, I've taken this exam",
        });
      }
    }
    return () => reset({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, testData, isAddNew, setValue]);

  useEffect(() => {
    setTitle(t(isAddNew ? `${groupName}.addPageTitle` : `${groupName}.edit`));
  }, [t, isAddNew]);

  const testTypeCode = watch('type.code');

  const handleChangeTestType = (option: TOption) => {
    const testType = option?.id as TStandardTestsStateKeys;
    if (option?.id && !standardTestsState[testType]) {
      dispatch(getStandardTestsConfig(testType));
    }

    reset({ type: { code: option?.id ?? '', displayName: option?.text || '' } });
    return option?.id || '';
  };

  const onSave = (data: FieldValues) => {
    dispatch(
      postStandardTest(composePayload(data, tests, childPosition, isAddNew), () => {
        onCloseSidebar();
      })
    );
  };

  const onClose = () => {
    onCloseSidebar();
  };

  /* istanbul ignore next */
  const deleteTest = () => {
    const newTests = [...(tests || [])];
    newTests.splice(childPosition, 1);
    dispatch(
      postStandardTest(
        {
          ...standardTests,
          tests: newTests,
        },
        onClose
      )
    );
  };

  return (
    <SidePanel
      size="small"
      title={t(isAddNew ? `${groupName}.add` : `${groupName}.edit`)}
      open={true}
      onClose={onClose}
      footerButtonConfig={{
        primary: {
          title: t('save_label'),
          props: {
            'aria-label': t('save_label'),
            variant: 'contained',
            color: getButtonColor(),
            disabled: !isValid || !testTypes,
            onClick: handleSubmit(onSave),
          },
        },
        tertiary: {
          title: t('cancel_label'),
          props: {
            color: getButtonColor(),
            'aria-label': t('cancel_label'),
            onClick: onClose,
          },
        },
      }}
      sx={{ ...sxSidePanel, p: 0, m: 0 }}
    >
      {!testTypes ? (
        <Typography variant="subtitle6" component="h4">
          {t('error.genericErrorMsg')}
        </Typography>
      ) : (
        <FormProvider {...methods}>
          <form>
            <Box sx={{ mx: -2, mt: -2 }}>
              <Box sx={{ p: 2, bgcolor: '#DDE3ED' }}>
                {isAddNew && (
                  <FormControl fullWidth>
                    <InputLabel htmlFor="testCategory">{t(`${groupName}.testCategory`)}</InputLabel>
                    <Dropdown
                      id="testCategory"
                      name="testCategory"
                      value={testCategory}
                      options={testCategories as TOption[]}
                      onChange={option => {
                        setTestCategory(option?.id || '');
                        setValue('type.code', '');
                      }}
                      inputProps={{
                        'aria-label': t(`${groupName}.testCategory`),
                        ...DISABLE_AUTO_FILL,
                      }}
                    />
                  </FormControl>
                )}
                <FormControl required fullWidth error={!!errors?.type?.code}>
                  <InputLabel htmlFor={groupName}>{t(`${groupName}.testType`)}</InputLabel>
                  <Controller
                    name="type.code"
                    render={({ field: { ref, onChange, ...field } }) => (
                      <Dropdown
                        {...field}
                        inputRef={ref}
                        id={groupName}
                        options={(categoryTestTypes?.length ? categoryTestTypes : testTypes) as TOption[]}
                        onChange={option => {
                          onChange(handleChangeTestType(option as TDropdownOption<string>));
                        }}
                        placeholder={t(`${groupName}.typeHelperText`)}
                        inputProps={{
                          'aria-label': t(`${groupName}.testType`),
                          ...DISABLE_AUTO_FILL,
                        }}
                        disabled={!isAddNew}
                      />
                    )}
                    control={control}
                  />
                </FormControl>
              </Box>
              {testTypeCode && (
                <Box sx={{ p: 2 }}>
                  <Typography variant="subtitle6" component="h1" sx={{ mb: 2.5, mt: 1.5 }}>
                    {t(`${groupName}.examDetails`)}
                  </Typography>
                  <Suspense fallback="Loading...">
                    {testTypeCode === AP && standardTestsState.AP && <APExam />}
                    {testTypeCode === CLEP && standardTestsState.CLEP && <CLEPExam />}
                    {testTypeCode === IB && standardTestsState.IB && <IBExam />}
                    {testTypeCode === TOEFL && standardTestsState.TOEFL && <TOEFLExam />}
                    {testTypeCode === IELTS && standardTestsState.IELTS && <IELTSExam />}
                    {testTypeCode === GRE && standardTestsState.GRE && <GREExam />}
                    {testTypeCode === GMAT && standardTestsState.GMAT && <GMATExam />}
                    {testTypeCode === ACT && standardTestsState.ACT && <ACTExam />}
                    {testTypeCode === MCAT && standardTestsState.MCAT && <MCATExam />}
                    {testTypeCode === PTE && standardTestsState.PTE && <PTEExam />}
                    {testTypeCode === LSAT && standardTestsState.LSAT && <LSATExam />}
                    {testTypeCode === SAT && standardTestsState.SAT && <SATExam />}
                    {testTypeCode === GRE_SUBJECT && standardTestsState.GRE_SUBJECT && <GRESubjectExam />}
                  </Suspense>
                </Box>
              )}
              {!isAddNew && (
                <>
                  <Box sx={{ px: 2 }}>
                    <Divider sx={{ borderStyle: 'solid', mt: 1, mb: 2 }} flexItem />{' '}
                    <Box sx={{ mt: '1rem', justifyContent: 'center', display: 'flex' }}>
                      <StyledDeleteButton onClick={() => setShowAlert(true)}>
                        {t(`${groupName}.remove`)}
                      </StyledDeleteButton>
                    </Box>
                  </Box>
                  <ConfirmationDialog
                    open={showAlert}
                    text={t(`${groupName}.delete`)}
                    confirmationText={t('academicHistory.deleteContent')}
                    onClose={() => {
                      setShowAlert(false);
                    }}
                    footerButtonConfig={{
                      primary: {
                        title: t('remove_label'),
                        props: {
                          onClick: () => {
                            setShowAlert(false);
                            deleteTest();
                          },
                        },
                      },
                      tertiary: {
                        title: t('cancel_label'),
                      },
                    }}
                  />
                </>
              )}
            </Box>
          </form>
        </FormProvider>
      )}
    </SidePanel>
  );
};
