/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';

import { selectActiveTspEnrollment } from 'transferPlanner/store/enrollment/enrollment.selectors';
import { termsOrdered } from 'userProfile/constants/general';
import type { IAcademicHistoryState, ICollege } from './academicHistory.slice';

export const academicHistory = (state: TRootState): IAcademicHistoryState => state.academicHistory;

export const selectAcademicHistory = createSelector(
  academicHistory,
  (stateData: IAcademicHistoryState) => stateData?.academicHistoryData
);

export const selectAcademicHistoryLocalData = createSelector(
  academicHistory,
  (stateData: IAcademicHistoryState) => stateData?.academicHistoryLocalData
);

export const selectLoading = createSelector(academicHistory, (stateData: IAcademicHistoryState) => stateData?.loading);

export const selectCommunityColleges = createSelector(selectAcademicHistory, academicHistoryData => {
  if (academicHistoryData?.colleges) {
    return academicHistoryData.colleges.filter(({ alternateId }) => alternateId?.source === 'Assist');
  }
  return null;
});

export const selectAdditionalCommunityColleges = createSelector(
  [selectCommunityColleges, selectActiveTspEnrollment],
  (communityColleges, tspEnrollment) => {
    if (communityColleges && tspEnrollment) {
      return communityColleges.filter(
        ({ alternateId }) => alternateId?.code !== tspEnrollment.communityCollege.alternateId.code
      );
    }
    return null;
  }
);

export const selectCoursesExtensions = createSelector(
  academicHistory,
  (stateData: IAcademicHistoryState) => stateData?.collegesCourseExtensionData?.collegesTermsCoursesExtensions
);

export const transferableCourseSelector = createSelector(academicHistory, (stateData: IAcademicHistoryState) =>
  stateData?.collegesCourseExtensionData?.collegesTermsCoursesExtensions
    ?.filter(course => course?.transferable)
    ?.map(college => college?.id)
);

export const transferableCompletedCourseSelector = createSelector(selectAcademicHistory, academicHistoryData => {
  return academicHistoryData?.colleges
    ?.filter((college: ICollege) => college?.terms && college?.terms?.length > 0)
    ?.map((college: ICollege) => {
      return {
        ...college,
        terms: college?.terms?.filter(term => term?.completionStatus?.code === 'COMPLETED'),
      };
    })
    ?.flatMap((college: ICollege) => college?.terms?.flatMap(term => term.courses?.map(course => course?.id)));
});

export const selectCollegesTerms = createSelector(selectAcademicHistory, academicHistoryData => {
  if (academicHistoryData?.colleges) {
    const colleges = academicHistoryData.colleges.filter(({ terms }) => terms?.some(term => term.courses?.length));
    const sortedTerms = colleges
      .map(({ terms }) => terms)
      .flat()
      .sort(
        (a, b) =>
          (b?.academicYear as number) - (a?.academicYear as number) ||
          termsOrdered.indexOf(a?.type.displayName as string) - termsOrdered.indexOf(b?.type.displayName as string)
      );
    const firstTerm = sortedTerms?.[0];
    if (!firstTerm) return null;

    const matchingTerms = sortedTerms.reduce((acc, term) => {
      if (term?.academicYear === firstTerm?.academicYear && term?.type.code === firstTerm?.type.code) {
        acc.push(term as never);
      }
      return acc;
    }, []);
    return matchingTerms;
  }
  return null;
});
