/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconStar, IconClipboardContent, IconTransfer } from '@liaison/liaison-ui';
import {
  academicHistory,
  transferableCompletedCourseSelector,
  transferableCourseSelector,
} from 'store/academicHistory/academicHistory.selectors';
import { nameSpace } from 'transferPlanner/constants/general';
import {
  selectActiveFollowedPrograms,
  selectProgramCart,
} from 'transferPlanner/store/programCart/programCart.selectors';
import { selectGenEdLoading } from 'transferPlanner/store/genEdProcess/genEdProcess.selectors';
import { useTransferProgress } from 'transferPlanner/hooks/useTransferProgress';
import { OverviewItem } from './OverviewItem';

const iconSx = { borderRadius: 2, fontSize: 84, p: 3 };

export const Overview = memo((): ReactElement => {
  const { t } = useTranslation(nameSpace);
  const activeFollowedPrograms = useSelector(selectActiveFollowedPrograms);
  const { loading: isProgramCartLoading } = useSelector(selectProgramCart);
  const { unitsEarnedOrCompleted, transferUnitLabel } = useTransferProgress();
  const isGenEdLoading = useSelector(selectGenEdLoading);
  const transferableCourse = useSelector(transferableCourseSelector);
  const { loading: isAcademicHistoryLoading } = useSelector(academicHistory);
  const transferableCompletedCourse = useSelector(transferableCompletedCourseSelector);
  /* istanbul ignore next */
  const filteredTransferableCompletedCourse = useMemo(
    (): number =>
      (transferableCompletedCourse || [])?.filter(course => transferableCourse?.includes(course || ''))?.length,
    [transferableCompletedCourse, transferableCourse]
  );

  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 600,
            fontSize: 14,
            mb: { xs: 1, sm: 1, md: 1, lg: 0, xl: 0 },
            mt: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 },
          }}
          role="heading"
          aria-level={1}
        >
          {t('overview_label')}
        </Typography>
      </Grid>
      <Grid item container spacing={1} xs={12}>
        <Grid item xs={4}>
          <OverviewItem
            icon={
              <IconStar
                sx={{
                  ...iconSx,
                  color: '#FFA000',
                  bgcolor: '#FFF8E1',
                }}
              />
            }
            title={t('followed_programs_label')}
            value={activeFollowedPrograms?.length}
            isLoading={isProgramCartLoading}
          />
        </Grid>
        <Grid item xs={4}>
          <OverviewItem
            icon={
              <IconClipboardContent
                sx={{
                  ...iconSx,
                  color: '#0277BD',
                  bgcolor: '#E1F5FE',
                }}
              />
            }
            title={t('courses_completed_label')}
            value={filteredTransferableCompletedCourse}
            isLoading={isAcademicHistoryLoading}
          />
        </Grid>
        <Grid item xs={4}>
          <OverviewItem
            icon={
              <IconTransfer
                sx={{
                  ...iconSx,
                  color: '#CC0B2A',
                  bgcolor: '#D9485F4D',
                }}
              />
            }
            title={t(transferUnitLabel)}
            value={Number(unitsEarnedOrCompleted.toFixed(2))}
            isLoading={isGenEdLoading}
          />
        </Grid>
      </Grid>
    </>
  );
});
