/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';

import { TRootState } from 'redux/store';
import { IAcademicGoalsInfoState } from './academicInfo.slice';

const academicGoalsInfo = (state: TRootState): IAcademicGoalsInfoState => state.academicGoalsInfo;

export const selectAcademicGoals = createSelector(
  academicGoalsInfo,
  (stateData: IAcademicGoalsInfoState) => stateData?.academicGoalsInfoData
);

export const selectAcademicGoalsLocalData = createSelector(
  academicGoalsInfo,
  (stateData: IAcademicGoalsInfoState) => stateData?.academicGoalsInfoLocalData
);

export const selectLoading = createSelector(
  academicGoalsInfo,
  (stateData: IAcademicGoalsInfoState) => stateData?.loading
);
