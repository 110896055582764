/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TError = string | null;

export type TCompetency = { id?: string; competency: string; attachedCount?: string; attachedTo?: TAttachedTo[] };

export type TSkillsAttachments = {
  resource: string;
  tag: string;
  competencies?: TCompetency[];
  competencyIds?: string[];
};

export type TAttachedTo = {
  id: string;
  path: string;
  resource: string;
  type: string;
};

export interface IHonorsAndAward {
  id?: string;
  name: string;
  source: string;
  receivedDate: string;
  description: string;
}
export interface IExperience {
  id?: string;
  type: {
    code: string;
    displayName: string;
  };
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  organization: {
    name: string;
    position: string;
    address: {
      address1?: string;
      address2?: string;
      city?: string;
      postalCode?: string;
      country: {
        code: string;
        displayName: string;
      };
      region: {
        code: string;
        displayName: string;
      };
      county?: {
        code: string;
        displayName: string;
      };
    };
  };
  hours: {
    weeklyAverageu?: number;
    weeks?: number;
    total?: number;
  };
}
export interface ILanguage {
  language: {
    code: string;
    displayName: string;
  };
  proficiencyLevel: {
    code: string;
    displayName: string;
  };
}
export interface TAccomplishmentsAndExperienceField {
  honorsOrAwards?: IHonorsAndAward[] | null;
  experiences?: IExperience[] | null;
  languages?: ILanguage[] | null;
  competencies?: TCompetency[] | null;
}

export interface IAccomplishmentsAndExperienceState {
  loading: boolean;
  accomplishmentsAndExperienceData: TAccomplishmentsAndExperienceField | null;
  accomplishmentsAndExperienceLocalData: TAccomplishmentsAndExperienceField | null;
  skillsAttachments: TSkillsAttachments[] | null;
  error: TError;
}
export const initialState: IAccomplishmentsAndExperienceState = {
  loading: false,
  accomplishmentsAndExperienceData: null,
  accomplishmentsAndExperienceLocalData: null,
  skillsAttachments: null,
  error: null,
};

const accomplishmentAndExperienceSlice = createSlice({
  name: 'biographicalInfo',
  initialState,
  reducers: {
    accomplishmentAndExperienceStart: (state): IAccomplishmentsAndExperienceState => ({
      ...state,
      loading: true,
      error: null,
    }),
    accomplishmentAndExperienceSuccess: (
      state,
      action: PayloadAction<TAccomplishmentsAndExperienceField>
    ): IAccomplishmentsAndExperienceState => ({
      ...state,
      loading: false,
      accomplishmentsAndExperienceData: action.payload,
      accomplishmentsAndExperienceLocalData: null,
    }),
    accomplishmentAndExperienceSaveLocalData: (
      state,
      action: PayloadAction<TAccomplishmentsAndExperienceField>
    ): IAccomplishmentsAndExperienceState => ({
      ...state,
      loading: false,
      accomplishmentsAndExperienceLocalData: action.payload,
    }),
    accomplishmentAndExperienceResetLocalData: (state): IAccomplishmentsAndExperienceState => ({
      ...state,
      loading: false,
      accomplishmentsAndExperienceLocalData: null,
    }),
    accomplishmentAndExperienceChildSuccess: (
      state,
      action: PayloadAction<TAccomplishmentsAndExperienceField>
    ): IAccomplishmentsAndExperienceState => ({
      ...state,
      loading: false,
      accomplishmentsAndExperienceData: action.payload,
    }),
    accomplishmentAndExperienceFailure: (state, action: PayloadAction<TError>): IAccomplishmentsAndExperienceState => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    setSkillsAttachments: (
      state,
      action: PayloadAction<TSkillsAttachments[] | null>
    ): IAccomplishmentsAndExperienceState => ({
      ...state,
      skillsAttachments: action.payload,
    }),
    resetSkillsAttachments: (state): IAccomplishmentsAndExperienceState => ({
      ...state,
      skillsAttachments: null,
    }),
  },
});

export const {
  accomplishmentAndExperienceStart,
  accomplishmentAndExperienceSaveLocalData,
  accomplishmentAndExperienceResetLocalData,
  accomplishmentAndExperienceChildSuccess,
  accomplishmentAndExperienceSuccess,
  accomplishmentAndExperienceFailure,
  setSkillsAttachments,
  resetSkillsAttachments,
} = accomplishmentAndExperienceSlice.actions;

export default accomplishmentAndExperienceSlice.reducer;
