/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { InputBase, FormControl, Grid, Box, FormHelperText, Card, IconButton, Typography } from '@mui/material';
import { Dropdown, IconCaretRight } from '@liaison/liaison-ui';
import { useFormContext, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getDropDownOptions } from 'utils/utilities';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { getLookUps } from 'utils/commonUtils';
import { useTranslation } from 'react-i18next';
import { selectCountriesLookup, selectStatesLookup, selectCountiesLookup } from 'store/common/commonInfo.selectors';
import { resetlookupData } from 'store/common/commonInfo.slice';
import { IAddress } from 'userProfile/store/personalInfo/personalInfo.slice';
import {
  DISABLE_ADDRESS_AUTO_FILL,
  FIELD_LENGTH_100,
  MAX_MEDIUM_LENGTH_FIELD,
  MAX_SMALL_LENGTH_FIELD,
} from 'constants/field';
import { getAddressString } from 'userProfile/components/ViewBuilder/ViewBuilder.utils';
import { StyledDeleteButton } from 'pages/Pages.styles';

export interface IAddressProps {
  address: IAddress | undefined;
  deleteAddress?: (() => void) | null;
  requiredFields?: string[];
}

const emptyOptions = { code: '', displayName: '' };

type TAddressErrors = {
  type: { code: { message: string } };
  address1: { message: string };
  address2: { message: string };
  country: { code: { message: string } };
  region: { code: { message: string } };
  city: { message: string };
  postalCode: { message: string };
};

const Address = (props: IAddressProps): ReactElement => {
  const {
    address,
    deleteAddress,
    requiredFields = ['addressType', 'address1', 'city', 'region', 'country', 'postalCode'],
  } = props;
  const {
    register,
    control,
    reset,
    setValue,
    getFieldState,
    formState: { errors: formErrors, dirtyFields },
    trigger,
    watch,
    getValues,
  } = useFormContext();

  const errors = formErrors as unknown as TAddressErrors;
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();

  const watchCountry = watch('country.code');
  const watchState = watch('region.code');

  const countriesLookup = useSelector(selectCountriesLookup);
  const statesLookup = useSelector(selectStatesLookup);
  const countiesLookup = useSelector(selectCountiesLookup);

  const abortStateController = useRef<AbortController>();
  const getStateList = useCallback(
    (countryId: string | undefined) => {
      abortStateController?.current?.abort();
      abortStateController.current = new AbortController();
      const controllerSignal = { signal: abortStateController.current.signal };
      dispatch(getLookUps('states', `?countryCode=${countryId}`, '', controllerSignal));
    },
    [dispatch, abortStateController]
  );

  const abortCountyController = useRef<AbortController>();
  const getCountyList = useCallback(
    (stateId: string | undefined) => {
      abortCountyController?.current?.abort();
      abortCountyController.current = new AbortController();
      const controllerSignal = { signal: abortCountyController.current.signal };
      dispatch(getLookUps('counties', `?stateCode=${stateId}`, '', controllerSignal));
    },
    [dispatch, abortCountyController]
  );

  const countryOptions = useMemo(() => getDropDownOptions(countriesLookup || []), [countriesLookup]);
  const governingDistrictOptions = useMemo(() => getDropDownOptions(statesLookup || []), [statesLookup]);
  const countyOptions = useMemo(() => getDropDownOptions(countiesLookup || []), [countiesLookup]);

  useEffect(() => {
    if (address) {
      reset(address);
      if (Object.keys(address).length > 0) {
        const countryCode = address?.country?.code;
        const stateCode = address?.region?.code;

        if (countryCode) {
          getStateList(countryCode);
        }
        if (stateCode) {
          getCountyList(stateCode);
        }
      }
    }
    return () => reset({});
  }, [reset, address, getStateList, getCountyList]);

  useEffect(() => {
    if (getFieldState('country').isDirty || getFieldState('country').isTouched) {
      if (getValues('region.code')) {
        /* istanbul ignore next */
        setValue('region', emptyOptions, { shouldValidate: true });
      } else {
        setValue('region', emptyOptions);
      }
      setValue('county', emptyOptions);

      if (watchCountry) {
        dispatch(resetlookupData(['states', 'counties']));
        getStateList(watchCountry);
      } else {
        /* istanbul ignore next */
        dispatch(resetlookupData(['states', 'counties']));
      }
    }
  }, [watchCountry, getStateList, setValue, dispatch, getValues, getFieldState, dirtyFields]);

  useEffect(() => {
    if (getFieldState('region').isDirty || getFieldState('region').isTouched) {
      setValue('county', emptyOptions);
      if (watchState) {
        dispatch(resetlookupData(['counties']));
        getCountyList(watchState);
      } else {
        /* istanbul ignore next */
        dispatch(resetlookupData(['counties']));
      }
    }
  }, [watchState, getCountyList, setValue, dispatch, getFieldState]);

  return (
    <Grid sx={{ mt: 1 }}>
      <FormControl fullWidth error={!!errors?.address1}>
        <InputBase
          placeholder={requiredFields.includes('address1') ? `${t('address.address1')}*` : t('address.address1')}
          inputProps={{
            'aria-label': t('address.address1'),
            'aria-describedby': 'address1-error',
            'data-testid': 'address1',
            ...DISABLE_ADDRESS_AUTO_FILL,
            maxLength: FIELD_LENGTH_100,
          }}
          {...register('address1')}
        />
        <FormHelperText role="alert" id="address1-error">
          {errors?.address1?.message}
        </FormHelperText>
      </FormControl>
      <FormControl fullWidth error={!!errors?.address2}>
        <InputBase
          placeholder={t('address.address2')}
          inputProps={{
            'aria-label': t('address.address2'),
            'aria-describedby': 'address2-error',
            'data-testid': 'address2',
            ...DISABLE_ADDRESS_AUTO_FILL,
            maxLength: FIELD_LENGTH_100,
          }}
          {...register('address2')}
        />
        <FormHelperText role="alert" id="address2-error">
          {errors?.address2?.message}
        </FormHelperText>
      </FormControl>
      <Grid container>
        <FormControl fullWidth error={!!errors?.country?.code}>
          <Controller
            render={({ field: { onChange, ...field } }) => (
              <Dropdown
                id="country"
                {...field}
                options={countryOptions}
                onChange={option => {
                  if (getValues('postalCode') !== '') {
                    /* istanbul ignore next */
                    trigger('postalCode');
                  }
                  setValue('country.displayName', option?.text);
                  return onChange(option?.id ?? '');
                }}
                placeholder={requiredFields.includes('country') ? `${t('address.country')}*` : t('address.country')}
                inputProps={{
                  'aria-label': t('address.country'),
                  'aria-describedby': 'country-error',
                  ...DISABLE_ADDRESS_AUTO_FILL,
                }}
              />
            )}
            control={control}
            name="country.code"
          />
          <FormHelperText role="alert" id="country-error">
            {errors?.country?.code?.message}
          </FormHelperText>
        </FormControl>
        <InputBase inputProps={{ type: 'hidden' }} {...register('country.displayName')} />
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6} md={6}>
          <FormControl fullWidth error={!!errors?.city}>
            <InputBase
              placeholder={requiredFields.includes('city') ? `${t('address.city')}*` : t('address.city')}
              inputProps={{
                'aria-label': t('address.city'),
                'aria-describedby': 'city-error',
                ...DISABLE_ADDRESS_AUTO_FILL,
                maxLength: MAX_MEDIUM_LENGTH_FIELD,
              }}
              {...register('city')}
            />
            <FormHelperText role="alert" id="city-error">
              {errors?.city?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={6}>
          <FormControl fullWidth error={!!errors?.region?.code}>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  id="region"
                  {...field}
                  options={governingDistrictOptions}
                  onChange={option => {
                    setValue('region.displayName', option?.text);
                    return onChange(option?.id ?? '');
                  }}
                  placeholder={requiredFields.includes('region') ? `${t('address.region')}*` : t('address.region')}
                  inputProps={{
                    'aria-label': t('address.region'),
                    'aria-describedby': 'region-error',
                    ...DISABLE_ADDRESS_AUTO_FILL,
                  }}
                />
              )}
              control={control}
              name="region.code"
            />
            <FormHelperText role="alert" id="region-error">
              {errors?.region?.code?.message}
            </FormHelperText>
          </FormControl>
          <InputBase inputProps={{ type: 'hidden' }} {...register('region.displayName')} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6} md={6}>
          <FormControl fullWidth>
            <Controller
              render={({ field: { onChange, ...field } }) => (
                <Dropdown
                  id="countyValueId"
                  {...field}
                  options={countyOptions}
                  onChange={option => {
                    setValue('county.displayName', option?.text);
                    return onChange(option?.id ?? null);
                  }}
                  placeholder={t('address.county')}
                  inputProps={{
                    'aria-label': t('address.county'),
                    ...DISABLE_ADDRESS_AUTO_FILL,
                  }}
                />
              )}
              control={control}
              name="county.code"
            />
          </FormControl>
          <InputBase inputProps={{ type: 'hidden' }} {...register('county.displayName')} />
        </Grid>
        <Grid item xs={6} md={6}>
          <FormControl fullWidth error={!!errors?.postalCode}>
            <InputBase
              placeholder={
                requiredFields.includes('postalCode') ? `${t('address.postalCode')}*` : t('address.postalCode')
              }
              autoComplete="off"
              inputProps={{
                'aria-label': t('address.postalCode'),
                'aria-describedby': 'postalCode-error',
                maxLength: MAX_SMALL_LENGTH_FIELD,
                ...DISABLE_ADDRESS_AUTO_FILL,
              }}
              {...register('postalCode')}
            />
            <FormHelperText role="alert" id="postalCode-error">
              {errors?.postalCode?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      {deleteAddress && (
        <Box sx={{ mt: '1rem', justifyContent: 'center', display: 'flex' }}>
          <StyledDeleteButton onClick={() => setShowAlert(true)}>{t('address.removeAddress')}</StyledDeleteButton>
        </Box>
      )}
      <ConfirmationDialog
        open={showAlert}
        text={t('address.deleteTitle')}
        confirmationText={t('address.deleteContent')}
        onClose={() => {
          setShowAlert(false);
        }}
        footerButtonConfig={{
          primary: {
            title: t('remove_label'),
            props: {
              onClick: () => {
                setShowAlert(false);
                deleteAddress?.();
              },
            },
          },
          tertiary: {
            title: t('cancel_label'),
          },
        }}
      />
    </Grid>
  );
};

export default Address;

interface IAddressViewCardProps {
  addresses: IAddress[] | undefined;
  xtraSmallLayout?: number;
  smallLayout?: number;
  mediumLayout?: number;
  viewAddress: (address: IAddress) => void;
}
export const AddressViewCard = (props: IAddressViewCardProps): ReactElement => {
  const { addresses, xtraSmallLayout = 12, smallLayout = 12, mediumLayout = 6, viewAddress } = props;
  return (
    <Grid container spacing={2}>
      {(addresses || []).map((address, position) => {
        return (
          <Grid key={address?.type?.code} item xs={xtraSmallLayout} sm={smallLayout} md={mediumLayout}>
            <Card variant="outlined">
              <Box sx={{ p: '1rem' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    cursor: 'pointer',
                    justifyContent: 'center',
                  }}
                  onClick={() => viewAddress(address)}
                >
                  <Box sx={{ flex: 0.9 }}>
                    <Typography variant="subtitle3">{`${address.type?.displayName || ''} Address`}</Typography>
                  </Box>
                  <Box sx={{ flex: 0.1, display: 'flex', justifyContent: 'center' }}>
                    <IconButton
                      id={`organization address ${position}`}
                      aria-label={`organization address ${position}`}
                      role="button"
                    >
                      <IconCaretRight />
                    </IconButton>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Box>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem', width: '95%', overflowWrap: 'anywhere' }}>
                      {getAddressString(address, 'line1')}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem', width: '95%', overflowWrap: 'anywhere' }}>
                      {getAddressString(address, 'line2')}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                      {getAddressString(address, 'line3')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};
