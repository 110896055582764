/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { Box, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { isLiaisonTenant } from 'utils/utilities';

export const StyledBox = styled(Box)(({ theme: { breakpoints } }) => ({
  [breakpoints.only('xs')]: {
    position: 'relative',
  },
  bottom: 0,
  ...(isLiaisonTenant() ? {} : { background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 25%)' }),
  '& a': {
    color: '#FFFFFF !important',
  },
}));

export const StyledImg = styled('img')(({ theme: { breakpoints } }) => ({
  width: '50%',
  height: 'fit-content',
  margin: '2rem',
  ...(isLiaisonTenant()
    ? {
        width: '40%',
        alignSelf: 'center',
        margin: 'auto',
        [breakpoints.down('md')]: {
          width: '80%',
        },
      }
    : {}),
}));

export const StyledGridBackground = styled(Grid)<{ background?: string }>(
  ({ theme: { breakpoints, palette }, background }) => ({
    backgroundColor: 'white',
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [breakpoints.down('md')]: {
      height: 'auto',
      backgroundSize: 'contain',
      backgroundPosition: 'inherit',
    },
    backgroundImage: background,
    ...(isLiaisonTenant()
      ? {
          backgroundImage: 'none',
          background: palette.primary.background,
        }
      : {}),
  })
);

export const StyledOutlet = styled(Grid)(({ theme: { breakpoints } }) => ({
  backgroundColor: 'white',
  alignItems: 'center',
  [breakpoints.up('md')]: {
    display: 'flex',
    height: '100vh',
    overflow: 'scroll',
  },
}));

export const StyledGrid = styled(Grid)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  minHeight: '40vh',
  [breakpoints.only('xs')]: {
    minHeight: '30vh',
  },
  [breakpoints.down('md')]: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
