/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Box, Divider, Grid, Modal, Theme, Typography, useMediaQuery } from '@mui/material';
import Confetti from 'react-confetti';
import { getMilestoneIconOrText } from 'transferPlanner/components/MilestoneCompletedModal/MilestoneCompletedModal';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'transferPlanner/constants/general';

const MileStoneModal = ({
  code,
  openStates,
  toggleModal,
  milestoneOpacity,
}: {
  code: string;
  openStates: boolean;
  toggleModal: (code: string) => void;
  milestoneOpacity: string;
}): ReactElement | null => {
  const [startConfetti, setStartConfetti] = useState(false);
  const { t } = useTranslation(nameSpace);

  /* istanbul ignore next */
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const size = isMobile ? '150' : '200';
  const headerText = useMemo(() => {
    return `${t('milestoneModalHeader')} ${getMilestoneIconOrText(code, false, size, t)} ${t(
      'milestoneModalHeaderEndPart'
    )}`;
  }, [size, t, code]);

  /* istanbul ignore next */
  useEffect(() => {
    if (startConfetti) {
      const timeout = setTimeout(() => {
        setStartConfetti(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [startConfetti]);

  const handleKeyPress = (event: { code: string }) => {
    if (event.code === 'Enter') {
      document.getElementById('close-milestone-modal')?.click();
    }
  };

  const handleOnClick = () => {
    toggleModal(code);
  };

  return (
    <Modal open={openStates && milestoneOpacity === '1'} aria-labelledby="milestone-modal-header" role="dialog">
      <Grid container alignItems="center" justifyContent="center" style={{ height: '100%', padding: '10px' }}>
        <Grid item xs={12} md={6}>
          <Box
            component="div"
            role="dialog"
            aria-modal="true"
            style={{
              backgroundColor: 'white',
              padding: '20px 20px 5px 20px',
              textAlign: 'center',
              borderRadius: '1rem',
            }}
            aria-label="milestone-modal-content"
          >
            <Confetti
              confettiSource={{ x: 0, y: 0, w: window.innerWidth, h: window.innerHeight }}
              numberOfPieces={250}
              recycle={startConfetti}
            />
            {getMilestoneIconOrText(code, true, size, t, false)}
            <Typography variant="h2" id="milestone-modal-header" aria-label={headerText}>
              {headerText}
            </Typography>
            <Typography
              component="p"
              sx={{ mt: '.5rem', fontSize: '1rem' }}
              aria-label={t('milestoneModalHeaderLastSentence')}
            >
              {t('milestoneModalHeaderLastSentence')}
            </Typography>
            <Typography
              component="p"
              sx={{ mt: '.5rem', fontSize: '1rem' }}
              aria-label={t('milestoneModalDescription')}
            >
              {t('milestoneModalDescription')}
            </Typography>
            <Divider
              sx={{ borderStyle: 'solid', borderColor: (theme: Theme) => theme.palette.grey[300], mt: '2rem' }}
            />
            <Box component="div" style={{ display: 'flex', justifyContent: 'center', padding: '0.5rem 0rem' }}>
              <Box
                component="div"
                onClick={handleOnClick}
                onKeyUp={handleKeyPress}
                role="button"
                aria-label={t('close_label')}
                id="close-milestone-modal"
                tabIndex={0}
                sx={{ cursor: 'pointer', width: 'fit-content' }}
              >
                {t('close_label')}
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default MileStoneModal;
