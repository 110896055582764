/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */
import * as yup from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { showDependent, type TQuestion } from './DynamicQuestionnaire.utils';

export const useDynamicValidation = (
  formData: Record<string, string>,
  questions: TQuestion[]
): { validationSchema: yup.AnyObjectSchema } => {
  function createYupSchema(schema: ObjectShape, config: TQuestion): ObjectShape {
    const {
      code,
      meta: { type, requiredError, format, formatError },
      required,
      dependencies,
    } = config;

    const schemaClone = { ...schema };
    const validationType = type === 'TEXT' ? 'string' : 'number';

    if (!yup[validationType] || !showDependent(dependencies, formData)) {
      return schemaClone;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let validator = (yup as any)[validationType]();
    if (required) {
      validator = validator.required(requiredError || 'This is a required field');
    }
    if (format) {
      validator = validator.matches(RegExp(format), formatError);
    }
    schemaClone[code] = validator;

    return schemaClone;
  }

  const validationSchema = yup.object().shape(questions?.reduce(createYupSchema, {}));
  return { validationSchema };
};
