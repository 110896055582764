/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement } from 'react';
import { Typography } from '@mui/material';
import { Banner } from '@liaison/liaison-ui';
import { t } from 'i18next';
import { csuNameSpace } from 'utils/utilities';
import { useFixedItemInView } from 'hooks/useFixedItemInView';
import useDeadlineExtensionBanner from 'hooks/useDeadlineExtensionBanner/useDeadlineExtensionBanner';
import { Moment } from 'moment-timezone';
import { StyledRootBox } from './BannerLayout.styles';

export interface IBannerLayoutProps {
  isImpersonateLayout?: boolean;
}

const BannerLayout = ({ isImpersonateLayout = false }: IBannerLayoutProps): ReactElement | null => {
  const { isFixed } = useFixedItemInView();
  const shouldDisplayTheBanner: Moment | null = useDeadlineExtensionBanner();

  return shouldDisplayTheBanner ? (
    <StyledRootBox
      isimpersonatelayout={isImpersonateLayout.toString()}
      isinviewport={isFixed.toString()}
      shoulddisplaythebanner={shouldDisplayTheBanner ? 'true' : ''}
    >
      <Banner
        type="info"
        id="lcl-banner-top-header"
        sx={{
          '@media (max-width: 599px)': { marginLeft: '0rem', position: 'fixed', zIndex: 11 },
          marginLeft: '3.8rem',
        }}
      >
        <Typography sx={{ fontSize: '1rem', '@media (max-width: 599px)': { fontSize: '0.87rem' } }}>
          {t('bannerFirstHalfMsg', csuNameSpace) as string}
          <Typography sx={{ fontWeight: 'bold' }} component="span">
            {shouldDisplayTheBanner.format('MMMM D, YYYY')}
          </Typography>{' '}
          at{' '}
          <Typography sx={{ fontWeight: 'bold' }} component="span">{`${shouldDisplayTheBanner.format(
            'hh:mm A'
          )} PT. `}</Typography>
          {t('bannerSecondHalfMsg', csuNameSpace) as string}
        </Typography>
      </Banner>
    </StyledRootBox>
  ) : null;
};

export default BannerLayout;
