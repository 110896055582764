/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import React, { memo, useCallback, useMemo, useState, type ReactElement, type MouseEvent } from 'react';
import { Button, Grid, IconButton, AppBar, Toolbar, useMediaQuery, Box, type Theme } from '@mui/material';
import {
  Dropdown,
  IconEllipsisVertical,
  IconMenu,
  IconShare,
  IconSettings,
  IconWriting,
  IconDuplicate,
  IconFileAdd,
  IconTrash,
  MenuActions,
} from '@liaison/liaison-ui';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { selectTenantConfig } from 'store/common/commonInfo.selectors';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import {
  setSelectedProfileViewId,
  deleteProfileView,
  CREATE_NEW,
  SAVE_AS,
  RENAME,
  type TProfileDialogType,
} from 'userProfile/pages/LivingProfile/LivingProfile.utils';
import {
  selectAllProfileViews,
  selectCurrentProfileViewId,
  selectProfileView,
} from 'userProfile/store/profileView/profileView.selectors';
import { DISABLE_AUTO_FILL } from 'constants/field';
import { useFixedItemInView } from 'hooks/useFixedItemInView';
import { setUi } from 'store/ui/ui.slice';
import { ProfileDialog } from '../ProfileDialog';

function LivingProfileHeader(): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [profileDialogType, setProfileDialogType] = useState('' as TProfileDialogType | '');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const isSharedProfileView = location.pathname.includes('shared-profile-view');
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
  const tenantConfig = useSelector(selectTenantConfig);
  const allProfileViews = useSelector(selectAllProfileViews);
  const profileView = useSelector(selectProfileView);
  const currentProfileViewId = useSelector(selectCurrentProfileViewId);
  const { logo, logoMobile } = tenantConfig || {};
  const { isFixed } = useFixedItemInView();

  const defaultView = useMemo(() => allProfileViews?.find(view => view.defaultView), [allProfileViews]);

  const profileViewOptions = useMemo(
    () => allProfileViews?.map(view => ({ id: view.id, text: view.name })) ?? [],
    [allProfileViews]
  );

  const handleClickLogo = useCallback(() => {
    if (isSharedProfileView) {
      window.open('http://liaisonedu.com/', '_blank');
    }
  }, [isSharedProfileView]);

  const handleMenuClick = useCallback(() => {
    dispatch(setUi({ name: 'sidebar', state: { open: true } }));
  }, [dispatch]);

  const openSidePanel = useCallback(
    (name: string) => {
      return () => dispatch(setUi({ name, state: { open: true } }));
    },
    [dispatch]
  );

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickDeleteProfile = () => {
    setShowConfirmationDialog(true);
    handleCloseMenu();
  };

  const handleDeleteProfile = () => {
    dispatch(deleteProfileView(currentProfileViewId ?? '', () => setShowConfirmationDialog(false)));
  };

  return (
    <>
      {isMobile && !isSharedProfileView ? (
        <AppBar position={isFixed ? 'fixed' : 'static'}>
          <Toolbar
            sx={{
              bgcolor: theme => theme.palette.custom.drawer,
            }}
          >
            <Box component="div" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '80%' }}>
              {!isSharedProfileView && (
                <IconButton
                  size="large"
                  edge="start"
                  aria-label="open drawer"
                  onClick={handleMenuClick}
                  sx={{ color: '#ffffff' }}
                >
                  <IconMenu />
                </IconButton>
              )}
              <Box
                onClick={handleClickLogo}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: isSharedProfileView ? 'pointer' : 'cursor',
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 40,
                  }}
                  src={logoMobile}
                  aria-hidden="true"
                />
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      ) : (
        <AppBar
          sx={{
            bgcolor: theme => theme.palette.primary.main,
          }}
        >
          <Toolbar
            sx={{
              height: isMobile ? '56px' : '88px',
              ml: isSharedProfileView ? 0 : '60px',
            }}
          >
            <Grid
              container
              component="div"
              direction="row"
              wrap="nowrap"
              justifyContent={isSharedProfileView ? 'center' : 'space-between'}
              alignItems="center"
            >
              <Grid container item alignItems="center" spacing={3}>
                <Grid item>
                  <Box
                    data-testid="LivingProfileHeader-logo-test-id"
                    tabIndex={0}
                    onClick={handleClickLogo}
                    sx={{ display: 'flex', alignItems: 'center', cursor: isSharedProfileView ? 'pointer' : 'cursor' }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 50,
                      }}
                      src={logo}
                      aria-hidden="true"
                    />
                  </Box>
                </Grid>
                {!isSharedProfileView && (
                  <Grid item>
                    <Dropdown
                      id="profile-views"
                      value={currentProfileViewId}
                      options={profileViewOptions}
                      onChange={option => {
                        dispatch(setSelectedProfileViewId(option?.id ?? defaultView?.id ?? ''));
                      }}
                      inputProps={{
                        'aria-label': 'profile-view',
                        ...DISABLE_AUTO_FILL,
                      }}
                    />
                  </Grid>
                )}
              </Grid>
              {!isSharedProfileView && (
                <Grid container item xs="auto" spacing={2} alignItems="center">
                  <Grid item>
                    <Button variant="ghost" startIcon={<IconShare />} onClick={openSidePanel('shareProfileSidebar')}>
                      {t('share')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="ghost" startIcon={<IconSettings />} onClick={openSidePanel('profileViewSidebar')}>
                      {t('livingProfile.editSettings')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="open menu"
                      onClick={handleOpenMenu}
                      sx={{
                        color: 'primary.title',
                        bgcolor: 'secondary.background',
                        [`&:hover, &:focus, &:active`]: {
                          color: 'primary.title',
                          bgcolor: 'secondary.background',
                        },
                        height: 32,
                        width: 32,
                      }}
                    >
                      <IconEllipsisVertical />
                    </IconButton>
                    <MenuActions
                      open={open}
                      onClose={handleCloseMenu}
                      anchorEl={anchorEl}
                      menuItems={[
                        {
                          text: t('livingProfile.renameProfile'),
                          icon: <IconWriting />,
                          onClick: () => {
                            setProfileDialogType(RENAME);
                            handleCloseMenu();
                          },
                        },
                        {
                          text: t('livingProfile.saveAsProfile'),
                          icon: <IconDuplicate />,
                          onClick: () => {
                            setProfileDialogType(SAVE_AS);
                            handleCloseMenu();
                          },
                        },
                        {
                          text: t('livingProfile.createProfile'),
                          icon: <IconFileAdd />,
                          onClick: () => {
                            setProfileDialogType(CREATE_NEW);
                            handleCloseMenu();
                          },
                        },
                        ...(!profileView?.defaultView
                          ? [
                              {
                                text: 'Delete',
                                icon: <IconTrash />,
                                onClick: handleClickDeleteProfile,
                              },
                            ]
                          : []),
                      ]}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      )}
      <ProfileDialog open={profileDialogType} setIsOpen={setProfileDialogType} />
      <ConfirmationDialog
        open={showConfirmationDialog}
        text={t('livingProfile.deleteProfile.message')}
        confirmationText={t('livingProfile.deleteProfile.confirmationText')}
        onClose={() => {
          setShowConfirmationDialog(false);
        }}
        footerButtonConfig={{
          primary: {
            title: t('delete_label'),
            props: {
              onClick: handleDeleteProfile,
            },
          },
          tertiary: {
            title: t('cancel_label'),
          },
        }}
      />
    </>
  );
}

export default memo(LivingProfileHeader);
