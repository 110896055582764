/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

export const DASHBOARD = 'dashboard';
export const TSP_QUESTIONNAIRE = 'tsp';
export const RESEARCH_CAMPUS_AND_PROGRAMS = 'research-campuses-and-programs';
export const MY_ACCOUNT = 'my-account';
export const PRIVACY_SECURITY = 'privacy-security';
export const TSP_ENROLLMENT = 'tsp-enrollment';
export const TSP_ENROLLMENT_SUCCESS = 'tsp-enrollment-success';
export const SKIP_CURRENT_ROUTE = { replace: true };
export const TP_ADDITIONAL_DETAILS = { featureName: 'CSU_ADDITIONAL_DETAILS', path: 'csu-additional-details' };
export const GENERAL_EDUCATION = 'general-education';
export const PROGRAM_PROGRESS = 'program-progress';
export const TRANSFER_PROGRESS = 'transfer-progress';
export const TIMELINE_CALCULATOR = {
  featureName: 'HIDE_TRANSFER_TIMELINE_CALCULATOR',
  path: 'transfer-timeline-calculator',
};
