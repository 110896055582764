/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectKeycloakInitStatus } from 'store/ui/ui.selectors';
import { doLogout } from 'utils/keyCloakUtils';

const Logout = (): ReactElement => {
  const initStatus = useSelector(selectKeycloakInitStatus);

  useEffect(() => {
    if (initStatus) {
      localStorage.clear();
      sessionStorage.clear();
      doLogout();
    }
  }, [initStatus]);

  return <></>;
};

export default Logout;
