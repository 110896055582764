/*
 * Copyright 2023-2024 Liaison International. All Rights Reserved
 */

import React, { useState, useEffect, Fragment, type DragEvent, type ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Grid,
  Paper,
  Stack,
  Switch,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
  Button,
  useMediaQuery,
  type Theme,
} from '@mui/material';
import { SidePanel, ToggleButton, Textarea } from '@liaison/liaison-ui';
import merge from 'lodash.merge';

import { MAX_BIG_LENGTH_FIELD } from 'constants/field';
import { sxSidePanel, tableStyles } from 'pages/Pages.styles';
import { selectProfileViewSidebar } from 'store/ui/ui.selectors';
import { setUi } from 'store/ui/ui.slice';
import { selectProfileView, selectSavedProfileView } from 'userProfile/store/profileView/profileView.selectors';
import {
  profileViewSuccess,
  type TProfileView,
  type TProfileViewSections,
} from 'userProfile/store/profileView/profileView.slice';
import { DEFAULT_POSTER_IMAGE } from 'userProfile/components/PosterSidePanel/PosterSidePanel.utils';

import { postProfileView } from '../LivingProfile.utils';
import { validationSchema } from './SettingsSidePanel.validation';
import {
  DETAILS,
  VISIBILITY,
  buildMediaAndDocumentsRow,
  buildPersonalInformationSection,
  buildRow,
  buildSection,
  contactSubsections,
  makeArrows,
  swapDocs,
} from './SettingsSidePanel.utils';

type TSettingsError = {
  bio: { message: string };
};

export const SettingsSidePanel = (): ReactElement => {
  const [selectedToggle, setSelectedToggle] = useState(DETAILS);
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { open } = useSelector(selectProfileViewSidebar);
  const profileView = useSelector(selectProfileView);
  const originalProfileView = useSelector(selectSavedProfileView);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  const methods = useForm({
    shouldUnregister: false,
    shouldFocusError: false,
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: async (...args) => yupResolver(validationSchema, { abortEarly: false })(...args),
  });
  const {
    control,
    getValues,
    formState: { errors },
    setValue,
    reset,
  } = methods;

  const settingsErrors = errors as unknown as TSettingsError;

  useEffect(() => {
    if (!open) {
      setIsOrderChanged(false);
    }
  }, [open]);

  useEffect(() => {
    if (profileView) {
      reset({
        ...profileView,
        posterFileId: profileView?.posterFileId || /* istanbul ignore next */ DEFAULT_POSTER_IMAGE.id,
      });
    }
  }, [reset, profileView]);

  useEffect(() => {
    if (profileView) {
      contactSubsections.forEach(subsection => {
        const contactSubsection = profileView?.personalInformation?.data?.contact?.[subsection] as {
          hidden: boolean;
        }[];
        const isEveryItemInContactSubsectionHidden = contactSubsection?.every(
          (item: { hidden: boolean }) => item.hidden
        );
        if (profileView?.personalInformation?.data?.contact?.[subsection]) {
          setValue(`personalInformation.data.contact.${subsection}.hidden`, isEveryItemInContactSubsectionHidden);
        }
      });
      const linkedInIndex =
        profileView?.personalInformation?.data?.socialMedia?.findIndex(item => item.type.code === 'LINKEDIN') ?? -1;
      const isLinkedInSocialMediaHidden = profileView?.personalInformation?.data?.socialMedia?.[linkedInIndex]?.hidden;
      if (linkedInIndex !== -1) {
        /* istanbul ignore next */
        setValue(`personalInformation.data.socialMedia.${linkedInIndex}.hidden`, isLinkedInSocialMediaHidden);
      }
    }
  }, [setValue, profileView]);

  const onToggleChange = (value: string) => {
    setSelectedToggle(value);
  };

  const onCancel = () => {
    if (originalProfileView) {
      reset({
        ...originalProfileView,
        posterFileId: originalProfileView?.posterFileId || /* istanbul ignore next */ DEFAULT_POSTER_IMAGE.id,
      });
      dispatch(profileViewSuccess(originalProfileView as TProfileView));
    }
    setIsOrderChanged(false);
    dispatch(setUi({ name: 'profileViewSidebar', state: { open: false } }));
  };

  const onSubmit = (data: FieldValues) => {
    const dataCopy = JSON.parse(JSON.stringify(data)) as FieldValues;
    const payload = {
      ...dataCopy,
      posterFileId: data.posterFileId === DEFAULT_POSTER_IMAGE.id ? /* istanbul ignore next */ null : data.posterFileId,
      poster: data.posterFileId === DEFAULT_POSTER_IMAGE.id ? /* istanbul ignore next */ null : profileView?.poster,
    };

    dispatch(
      postProfileView(merge({}, profileView, payload), () => {
        dispatch(setUi({ name: 'profileViewSidebar', state: { open: false } }));
        setIsOrderChanged(false);
      })
    );
  };

  const changeOrder = (value: 1 | -1, sectionName: string) => () => {
    const existingProfileView = profileView as TProfileView;
    const sourceSectionData = existingProfileView[sectionName as TProfileViewSections];
    const sourceSectionNewOrder = sourceSectionData?.order + value;
    const targetSectionName = Object.entries(existingProfileView).find(
      item => (item[1] as TProfileView[TProfileViewSections])?.order === sourceSectionNewOrder
    )?.[0] as TProfileViewSections;
    const targetSectionNewOrder =
      targetSectionName && targetSectionName !== 'personalInformation'
        ? existingProfileView[targetSectionName].order - value
        : /* istanbul ignore next */
          null;

    if (targetSectionNewOrder) {
      setIsOrderChanged(true);
      const formData = getValues();
      const newProfileView = merge({}, existingProfileView, formData, {
        ...(targetSectionNewOrder
          ? {
              [sectionName]: { order: sourceSectionNewOrder },
              [targetSectionName]: { order: targetSectionNewOrder },
            }
          : /* istanbul ignore next */
            {}),
      });
      dispatch(profileViewSuccess(newProfileView));
    }
  };

  const changeDocumentOrder = (value: 1 | -1, order: number) => () => {
    const currentProfileView = profileView as TProfileView;
    const sourceDocumentIndex = order;
    const sourceDocumentNewIndex = sourceDocumentIndex + value;
    if (sourceDocumentNewIndex > -1 && sourceDocumentNewIndex < currentProfileView.mediaAndDocuments.data.length) {
      const formData = getValues();
      const newData = swapDocs({
        profileView: currentProfileView,
        formData,
        sourceDocumentIndex,
        sourceDocumentNewIndex,
        setIsOrderChanged,
      });
      dispatch(profileViewSuccess(newData));
    }
  };

  const checkAcademicHistory = () => {
    const sectionValues = getValues('academicHistory');
    if (sectionValues.schoolAttended.hidden && sectionValues.standardizedTests.hidden) {
      setValue('academicHistory.hidden', true);
    }
    if (!sectionValues.schoolAttended.hidden || !sectionValues.standardizedTests.hidden) {
      setValue('academicHistory.hidden', false);
    }
  };

  const checkParent = (section: string) => {
    const sectionValues = getValues(section);
    if (section === 'personalInformation') {
      const isEveryContactSubsectionHidden = contactSubsections
        .filter(subsection => sectionValues.data?.contact?.[subsection]?.hidden !== undefined)
        .every(subsection => sectionValues.data?.contact?.[subsection]?.hidden);

      const linkedInIndex =
        profileView?.personalInformation?.data?.socialMedia?.findIndex(item => item.type.code === 'LINKEDIN') ?? -1;
      const isLinkedInSocialMediaHidden = sectionValues.data?.socialMedia?.[linkedInIndex]?.hidden ?? true;
      if (isEveryContactSubsectionHidden && isLinkedInSocialMediaHidden) {
        setValue(`${section}.hidden`, true);
      }

      if (sectionValues.hidden && (!isEveryContactSubsectionHidden || !isLinkedInSocialMediaHidden)) {
        /* istanbul ignore next */
        setValue(`${section}.hidden`, false);
      }
      return;
    }
    if (sectionValues.data.every((item: { hidden: boolean }) => item.hidden)) {
      setValue(`${section}.hidden`, true);
    }
    if (sectionValues.hidden && sectionValues.data.some((item: { hidden: boolean }) => !item.hidden)) {
      setValue(`${section}.hidden`, false);
    }
  };

  const checkSection = (section: string, value: boolean) => {
    const sectionValues = getValues(section);
    if (section === 'personalInformation') {
      contactSubsections.forEach(subsection => {
        if (sectionValues.data?.contact?.[subsection]?.hidden !== undefined) {
          setValue(`${section}.data.contact.${subsection}.hidden`, !value);
          profileView?.personalInformation?.data?.contact?.[subsection]?.forEach((_item, i) => {
            setValue(`personalInformation.data.contact.${subsection}.${i}.hidden`, !value);
          });
        }
      });
      const linkedInIndex =
        profileView?.personalInformation?.data?.socialMedia?.findIndex(item => item.type.code === 'LINKEDIN') ?? -1;
      if (linkedInIndex !== -1) {
        setValue(`personalInformation.data.socialMedia.${linkedInIndex}.hidden`, !value);
      }
      return;
    }
    sectionValues.data?.forEach((_item: { hidden: boolean }, i: number) => {
      setValue(`${section}.data.${i}.hidden`, !value);
    });
    if (section === 'academicHistory') {
      const subsectionsNames = Object.keys(sectionValues).filter(name => name !== 'hidden' && name !== 'order');
      if (subsectionsNames.length) {
        subsectionsNames.forEach(item => {
          setValue(`${section}.${item}.hidden`, !value);
          checkSection(`${section}.${item}`, value);
        });
      }
    }
  };

  const openImageSelectionSidePanel = () => {
    const formData = getValues();
    const currentProfileView = merge({}, profileView, formData);
    dispatch(profileViewSuccess(currentProfileView));
    dispatch(setUi({ name: 'posterSidebar', state: { open: true } }));
  };
  /* istanbul ignore next */
  const handleOnDragOver = (e: DragEvent) => {
    e.preventDefault();
  };

  const handleOnDrag = (e: DragEvent, sourceSectionName: string, sourceSectionOrder: number) => {
    /* istanbul ignore next */
    e.dataTransfer.setData('item', JSON.stringify({ sourceSectionName, sourceSectionOrder }));
  };

  const handleOnDrop = (e: DragEvent, targetSectionName: string, targetSectionOrder: number) => {
    const { sourceSectionName, sourceSectionOrder } = JSON.parse(e.dataTransfer.getData('item'));

    setIsOrderChanged(true);
    const formData = getValues();
    const existingProfileView = profileView as TProfileView;
    const newProfileView = merge({}, existingProfileView, formData, {
      [sourceSectionName]: { order: targetSectionOrder },
      [targetSectionName]: { order: sourceSectionOrder },
    });
    dispatch(profileViewSuccess(newProfileView));
  };

  const handleOnDocDrop = (e: DragEvent, targetDocumentOrder: number) => {
    const currentProfileView = profileView as TProfileView;
    const sourceDocumentIndex = JSON.parse(e.dataTransfer.getData('sourceDocumentOrder'));
    const sourceDocumentNewIndex = targetDocumentOrder;
    const formData = getValues();
    const newData = swapDocs({
      profileView: currentProfileView,
      formData,
      sourceDocumentIndex,
      sourceDocumentNewIndex,
      setIsOrderChanged,
    });
    dispatch(profileViewSuccess(newData));
  };

  const sections = profileView
    ? [
        {
          order: profileView.academicHistory.order,
          element: (
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table
                  aria-label="table header"
                  sx={{
                    ...tableStyles.font,
                    borderCollapse: 'unset',
                    '&:hover': { cursor: 'move' },
                  }}
                  tabIndex={0}
                  draggable
                  onDragStart={e => handleOnDrag(e, 'academicHistory', profileView.academicHistory.order)}
                  onDrop={e => handleOnDrop(e, 'academicHistory', profileView.academicHistory.order)}
                  onDragOver={handleOnDragOver}
                >
                  <TableHead>
                    <TableRow sx={tableStyles.header}>
                      <TableCell align="left">
                        <Grid container>
                          {makeArrows(changeOrder, 'academicHistory')}
                          <Grid item xs container alignItems="center">
                            <Typography variant="subtitle4">{t('livingProfile.sections.academicHistory')}</Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="right">
                        <Controller
                          control={control}
                          name="academicHistory.hidden"
                          defaultValue={true}
                          render={({ field: { onChange, value, ...field } }) => {
                            return (
                              <Switch
                                {...field}
                                checked={!value}
                                onChange={(_e, val) => {
                                  checkSection('academicHistory', val);
                                  return onChange(!val);
                                }}
                                inputProps={{
                                  'aria-label': 'hide academic history',
                                }}
                                color="primary"
                              />
                            );
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        py: 1,
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell align="left">
                        <Typography variant="subtitle6">
                          {t('livingProfile.sections.academicHistory.schoolAttended')}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Controller
                          control={control}
                          name="academicHistory.schoolAttended.hidden"
                          defaultValue={true}
                          render={({ field: { onChange, value, ...field } }) => {
                            return (
                              <Switch
                                {...field}
                                checked={!value}
                                onChange={(_e, val) => {
                                  checkSection('academicHistory.schoolAttended', val);
                                  setTimeout(() => {
                                    checkAcademicHistory();
                                  }, 10);
                                  return onChange(!val);
                                }}
                                inputProps={{
                                  'aria-label': 'hide school attended',
                                }}
                                color="primary"
                              />
                            );
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    {profileView?.academicHistory?.schoolAttended?.data?.map((item, i) =>
                      buildRow(
                        item.name,
                        i,
                        control,
                        'academicHistory.schoolAttended',
                        checkParent,
                        checkAcademicHistory
                      )
                    )}
                    <TableRow>
                      <TableCell align="left">
                        <Typography variant="subtitle6">
                          {t('livingProfile.sections.academicHistory.standardizedTests')}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Controller
                          control={control}
                          name="academicHistory.standardizedTests.hidden"
                          defaultValue={true}
                          render={({ field: { onChange, value, ...field } }) => {
                            return (
                              <Switch
                                {...field}
                                checked={!value}
                                onChange={(_e, val) => {
                                  checkSection('academicHistory.standardizedTests', val);
                                  setTimeout(() => {
                                    checkAcademicHistory();
                                  }, 10);
                                  return onChange(!val);
                                }}
                                inputProps={{
                                  'aria-label': 'hide standardized tests',
                                }}
                                color="primary"
                              />
                            );
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    {profileView?.academicHistory?.standardizedTests?.data?.map((item, i) =>
                      buildRow(
                        item.type.displayName,
                        i,
                        control,
                        'academicHistory.standardizedTests',
                        checkParent,
                        checkAcademicHistory,
                        item.testDate,
                        item.status.code
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ),
        },
        {
          order: profileView.experiences.order,
          element: (
            <Grid item xs={12}>
              {buildSection({
                title: t('livingProfile.sections.experiences'),
                name: 'experiences',
                control,
                order: profileView.experiences.order,
                changeOrder,
                handleOnDrop,
                profileView,
                checkParent,
                checkSection,
              })}
            </Grid>
          ),
        },
        {
          order: profileView.achievements.order,
          element: (
            <Grid item xs={12}>
              {buildSection({
                title: t('livingProfile.sections.achievements'),
                name: 'achievements',
                control,
                order: profileView.achievements.order,
                changeOrder,
                handleOnDrop,
                profileView,
                checkParent,
                checkSection,
              })}
            </Grid>
          ),
        },
        {
          order: profileView.languages.order,
          element: (
            <Grid item xs={12}>
              {buildSection({
                title: t('livingProfile.sections.languages'),
                name: 'languages',
                control,
                order: profileView.languages.order,
                changeOrder,
                handleOnDrop,
              })}
            </Grid>
          ),
        },
        {
          order: profileView.competencies.order,
          element: (
            <Grid item xs={12}>
              {buildSection({
                title: t('livingProfile.sections.competenciesSkills'),
                name: 'competencies',
                control,
                order: profileView.competencies.order,
                changeOrder,
                handleOnDrop,
              })}
            </Grid>
          ),
        },
        {
          order: profileView.mediaAndDocuments.order,
          element: (
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table
                  aria-label="table header"
                  sx={{
                    ...tableStyles.font,
                    borderCollapse: 'unset',
                    '&:hover': { cursor: 'move' },
                  }}
                  tabIndex={0}
                  draggable
                  onDragStart={e => handleOnDrag(e, 'mediaAndDocuments', profileView.mediaAndDocuments.order)}
                  onDrop={e => handleOnDrop(e, 'mediaAndDocuments', profileView.mediaAndDocuments.order)}
                  onDragOver={handleOnDragOver}
                >
                  <TableHead>
                    <TableRow sx={tableStyles.header}>
                      <TableCell align="left">
                        <Grid container>
                          {makeArrows(changeOrder, 'mediaAndDocuments')}
                          <Grid item xs container alignItems="center">
                            <Typography variant="subtitle4">{t('livingProfile.sections.mediaAndDocuments')}</Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="right">
                        <Controller
                          control={control}
                          name="mediaAndDocuments.hidden"
                          defaultValue={true}
                          render={({ field: { onChange, value, ...field } }) => {
                            return (
                              <Switch
                                {...field}
                                checked={!value}
                                onChange={(_e, val) => {
                                  checkSection('mediaAndDocuments', val);
                                  return onChange(!val);
                                }}
                                inputProps={{
                                  'aria-label': 'hide media and documents',
                                }}
                                color="primary"
                              />
                            );
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        py: 1,
                      },
                    }}
                  >
                    {profileView?.mediaAndDocuments?.data.map((item, i) =>
                      buildMediaAndDocumentsRow(
                        item,
                        i,
                        control,
                        'mediaAndDocuments',
                        checkParent,
                        isMobile,
                        changeDocumentOrder,
                        handleOnDocDrop
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ),
        },
      ].sort((a, b) => a.order - b.order)
    : [];

  return (
    <SidePanel
      size="small"
      open={open}
      onClose={onCancel}
      title={t('livingProfile.editSettings')}
      isBackdropClickEnabled={true}
      footerButtonConfig={{
        primary: {
          title: t('save_changes_label'),
          props: {
            'aria-label': t('save_changes_label'),
            variant: 'contained',
            disabled: !methods.formState.isValid || (!methods.formState.isDirty && !isOrderChanged),
            color: 'primary',
            onClick: methods.handleSubmit(onSubmit),
          },
        },
        tertiary: {
          title: t('cancel_label'),
          props: {
            'aria-label': t('cancel_label'),
            color: 'primary',
            onClick: onCancel,
          },
        },
      }}
      sx={sxSidePanel}
    >
      <FormProvider {...methods}>
        <form>
          <Box sx={{ mx: -2, mb: 3 }}>
            <ToggleButton
              options={[
                {
                  text: t('livingProfile.toggle.details'),
                  val: DETAILS,
                },
                {
                  text: t('livingProfile.toggle.visibility'),
                  val: VISIBILITY,
                },
              ]}
              onChange={onToggleChange as (value: string | number | boolean) => void}
              selectedValue={selectedToggle}
            />
          </Box>
          {selectedToggle === DETAILS && (
            <Stack spacing={3}>
              <Typography variant="body2">{t('livingProfile.visibility.instruction')}</Typography>
              {profileView?.personalInformation?.data?.personal?.preferredName && (
                <Grid container alignItems="center">
                  <Grid item xs={9}>
                    <Typography variant="subtitle4">{t('livingProfile.visibility.useLegalName')}</Typography>
                    <FormHelperText sx={{ whiteSpace: 'initial' }} role="alert" id="useLegalName-error">
                      {t('livingProfile.visibility.legalNameHelper')}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={3} container justifyContent="flex-end">
                    <Controller
                      control={control}
                      name="personalInformation.data.personal.useGivenName"
                      render={({ field: { value, ...field } }) => {
                        return (
                          <Switch
                            {...field}
                            checked={value}
                            inputProps={{
                              'aria-label': t('livingProfile.visibility.useLegalName'),
                            }}
                            color="primary"
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              <Controller
                name="bio"
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!errors?.bio}>
                    <InputLabel htmlFor="bio">{t('livingProfile.bio')}</InputLabel>
                    <Textarea
                      {...field}
                      error={!!error}
                      id="bio"
                      maxChars={MAX_BIG_LENGTH_FIELD}
                      inputProps={{
                        maxLength: MAX_BIG_LENGTH_FIELD,
                        maxRows: 8,
                        'aria-label': t('livingProfile.bio'),
                      }}
                    />
                    <FormHelperText role="alert">{settingsErrors?.bio?.message}</FormHelperText>
                  </FormControl>
                )}
                control={control}
                defaultValue=""
              />
              <Box>
                <Typography variant="subtitle4" component="p" gutterBottom>
                  {t('livingProfile.bgImage')}
                </Typography>
                <Box
                  component="img"
                  sx={{
                    width: '100%',
                  }}
                  alt={profileView?.poster?.name ?? DEFAULT_POSTER_IMAGE.name}
                  src={profileView?.poster?.ephemeralURL ?? DEFAULT_POSTER_IMAGE.ephemeralURL}
                />
                <Grid container>
                  <Grid item>
                    <Button variant="ghost" onClick={openImageSelectionSidePanel}>
                      {t('livingProfile.changeImage')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          )}
          {selectedToggle === VISIBILITY && (
            <Grid container spacing={3} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <Typography variant="body2">{t('livingProfile.visibility.instruction')}</Typography>
              </Grid>
              {/* header */}
              <Grid item xs={12}>
                {buildPersonalInformationSection(
                  t('livingProfile.sections.header'),
                  'personalInformation',
                  profileView,
                  control,
                  setValue,
                  checkSection,
                  checkParent
                )}
              </Grid>
              {profileView && sections.map(item => <Fragment key={String(item.order)}>{item.element}</Fragment>)}
            </Grid>
          )}
        </form>
      </FormProvider>
    </SidePanel>
  );
};
