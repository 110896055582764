/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Pluralize from 'pluralize';
import {
  Box,
  Grid,
  Stack,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from '@mui/material';
import { SidePanel, Search } from '@liaison/liaison-ui';

import { setPendingAttachments, IMediaDoc, TSectionName } from 'userProfile/store/mediaDocuments/mediaDocuments.slice';
import { getButtonColor, isTheSameStringsArray, resourceMap } from 'utils/utilities';
import { StyledCheckbox, sxSidePanel, tableStyles } from 'pages/Pages.styles';
import { textEllipsis, wrapText } from 'userProfile/components/ViewBuilder/ViewBuilder.styles';
import { attachMediaDocs, getThumbUrl } from 'userProfile/pages/MediaDocuments/MediaDocuments.utils';
import { selectMediaDocs, selectPendingDocIds } from 'userProfile/store/mediaDocuments/mediaDocuments.selectors';

export type TFilesSidePanelProps = {
  isOpen: boolean;
  setIsOpen: (value: SetStateAction<boolean>) => void;
  sectionName: TSectionName;
  tag: string;
  mediaDocs?: IMediaDoc[];
};

export const FilesSidePanel = ({
  isOpen,
  setIsOpen,
  sectionName,
  tag,
  mediaDocs,
}: TFilesSidePanelProps): ReactElement => {
  const { t } = useTranslation();
  const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);
  const allMediaDocs = useSelector(selectMediaDocs);
  const pendingDocIds = useSelector(state => selectPendingDocIds(state, sectionName));
  const [filteredData, setFilteredData] = useState<IMediaDoc[]>([]);
  const dispatch = useDispatch();
  const alreadySelectedIds = useMemo(
    () => [...(mediaDocs?.map(doc => doc.id as string) ?? []), ...(pendingDocIds ?? [])],
    [mediaDocs, pendingDocIds]
  );
  const isSectionIdExist = tag.split('/').pop() !== 'undefined';
  const sectionParentName = tag.split('/').shift() as string;
  const filterData = (searchValue: string) => {
    setFilteredData(allMediaDocs?.filter(doc => doc.name?.toLowerCase().includes(searchValue.toLowerCase())) || []);
  };

  useEffect(() => {
    if (allMediaDocs?.length) {
      setFilteredData(allMediaDocs.filter(doc => doc.shareable));
    }
  }, [allMediaDocs]);

  useEffect(() => {
    if (alreadySelectedIds) {
      setSelectedFileIds(alreadySelectedIds);
    }
  }, [alreadySelectedIds]);

  const handleRemoveItem = (id: string) => {
    setSelectedFileIds(selectedFileIds?.filter(item => item !== id));
  };

  const addAllFiles = () => {
    setSelectedFileIds(selected =>
      filteredData?.reduce(
        (acc, next) => {
          if (!acc.includes(next.id as string)) {
            acc.push(next.id as string);
          }
          return acc;
        },
        [...selected]
      )
    );
  };

  const isFileSelected = (id: string) => selectedFileIds?.includes(id);

  const isAlFilteredSelected = () =>
    Boolean(filteredData?.length) && filteredData?.every(file => selectedFileIds?.includes(file.id as string));

  const onCancel = () => {
    setIsOpen(false);
    setSelectedFileIds(alreadySelectedIds);
  };

  const onSubmit = () => {
    if (isSectionIdExist) {
      dispatch(
        attachMediaDocs({ resource: resourceMap[sectionParentName], tag, documentIds: selectedFileIds }, () => {
          setIsOpen(false);
        })
      );
    } else {
      dispatch(setPendingAttachments({ [sectionName as string]: [...selectedFileIds] }));
      setIsOpen(false);
    }
  };

  return (
    <SidePanel
      size="small"
      open={isOpen}
      onClose={onCancel}
      title={t('mediaDocuments.attaching.title')}
      isBackdropClickEnabled={true}
      footerButtonConfig={{
        primary: {
          title: t('save_label'),
          props: {
            'aria-label': t('save_label'),
            disabled: !allMediaDocs?.length || isTheSameStringsArray(alreadySelectedIds, selectedFileIds),
            variant: 'contained',
            color: getButtonColor(),
            onClick: onSubmit,
          },
        },
        tertiary: {
          title: t('cancel_label'),
          props: {
            'aria-label': t('cancel_label'),
            color: getButtonColor(),
            onClick: onCancel,
          },
        },
      }}
      sx={sxSidePanel}
    >
      <>
        <Box sx={{ mx: -2, mt: -2 }}>
          <Box sx={{ p: 3, bgcolor: theme => theme.palette.grey[200] }}>
            <Typography variant="subtitle4">
              {!allMediaDocs?.length
                ? t('mediaDocuments.attaching.nothing')
                : `${Pluralize(t('mediaDocuments.attaching.filesSidePanel.file'), selectedFileIds?.length, true)} ${t(
                    'mediaDocuments.attaching.filesSidePanel.selected'
                  )}`}
            </Typography>
          </Box>
        </Box>
        {Boolean(allMediaDocs?.length) && (
          <Stack spacing={3} sx={{ mt: 2 }}>
            <Search searchHandler={filterData} expanded searchOffset={2} />
            <TableContainer>
              <Table
                aria-label="table header"
                sx={{
                  ...tableStyles.font,
                  borderCollapse: 'unset',
                  border: '1px solid',
                  borderColor: theme => theme.palette.grey[300],
                  borderRadius: 1,
                  mt: 0.5,
                }}
                tabIndex={0}
              >
                <TableHead>
                  <TableRow sx={tableStyles.header}>
                    <TableCell width={30} align="left">
                      <StyledCheckbox
                        name="checkbox-all"
                        checked={isAlFilteredSelected()}
                        disabled={!filteredData?.length}
                        onChange={event => {
                          if (event.target.checked) {
                            addAllFiles();
                          } else {
                            setSelectedFileIds([]);
                          }
                        }}
                        inputProps={{
                          'aria-label': 'checkbox-all',
                        }}
                      />
                    </TableCell>
                    <TableCell align="left">{t('mediaDocuments.listView.file')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      py: 1,
                    },
                  }}
                >
                  {filteredData?.map(mediaDoc => {
                    const { variants, id, name = '', sizeDisplay = '', mediaType = '' } = mediaDoc;
                    return (
                      <TableRow key={id}>
                        <TableCell align="left">
                          <StyledCheckbox
                            name={`checkbox-${id}`}
                            checked={isFileSelected(id as string)}
                            onChange={event => {
                              if (id) {
                                if (event.target.checked) {
                                  setSelectedFileIds([...(selectedFileIds as string[]), id]);
                                } else {
                                  handleRemoveItem(id);
                                }
                              }
                            }}
                            inputProps={{
                              'aria-label': `checkbox-${id}`,
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Grid container columnSpacing={1} alignItems="center">
                            <Grid item xs="auto">
                              <Box
                                component="img"
                                src={getThumbUrl(variants)}
                                alt={`${name} picture`}
                                sx={{
                                  height: { xs: 30, sm: 40 },
                                  width: { xs: 30, sm: 40 },
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  overflow: 'hidden',
                                }}
                              />
                            </Grid>
                            <Grid item container xs>
                              <Grid item xs={12}>
                                <Typography
                                  sx={{
                                    wordBreak: 'break-word',
                                    typography: { xs: 'body3', sm: 'subtitle3' },
                                    ...wrapText,
                                    ...textEllipsis,
                                  }}
                                >
                                  {name}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="caption" color="text.secondary" textTransform="uppercase">
                                  {`${sizeDisplay} ${`${sizeDisplay && mediaType && '•'}`} ${mediaType}`}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        )}
      </>
    </SidePanel>
  );
};
