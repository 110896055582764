/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { DASHBOARD } from 'transferPlanner/constants/routeNames';
import { PERSONAL_INFORMATION, LIVING_PROFILE } from 'userProfile/constants/routeNames';

export const header = {
  liaison: {
    data: {
      path: PERSONAL_INFORMATION.path,
    },
  },
  csu: {
    data: {
      path: DASHBOARD,
    },
  },
};
export const footerMenuIcons = [
  {
    active: true,
    data: {
      path: 'helpcenter',
    },
    icon: 'IconHelp',
    label: 'Help',
    title: 'Help Menu',
  },
  {
    label: 'myAccount',
    secondaryLabel: '',
    icon: 'IconUserProfile',
    title: 'My Account',
    active: false,
    isCustom: true,
    data: {
      path: 'userinfo',
    },
  },
];

export const bodyMenuItems = {
  liaison: [
    {
      label: 'Home',
      icon: 'IconUserListSolid',
      title: 'Home',
      active: true,
      data: {
        path: PERSONAL_INFORMATION.path,
      },
    },
    {
      label: 'Living Profile',
      icon: 'IconCommViewSolid',
      title: 'Living Profile',
      active: true,
      data: {
        path: LIVING_PROFILE.path,
      },
    },
  ],
  csu: [
    {
      label: 'Transfer Planner Dashboard',
      icon: 'IconHomeSolid',
      title: 'Transfer Planner Dashboard',
      active: true,
      data: {
        path: DASHBOARD,
      },
    },
    {
      label: 'Profile',
      icon: 'IconUserListSolid',
      title: 'User Profile',
      active: false,
      data: {
        path: PERSONAL_INFORMATION.path,
      },
    },
  ],
};
