/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import React, { ReactElement, useEffect } from 'react';
import {
  Box,
  Card,
  Divider,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Stack,
  Grid,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@liaison/liaison-ui';
import { RadioControl } from 'components/RadioControl';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectAlternateIDLinkingData, selectObData } from 'store/common/commonInfo.selectors';
import { keycloak } from 'constants/keyCloak';
import { type IAlternateIdLinking, type IObData, obFlowSuccess } from 'store/common/commonInfo.slice';
import { csuNameSpace } from 'utils/utilities';
import { getModifiedObFlow } from 'utils/commonUtils';
import MasterData from 'transferPlanner/constants/master';
import {
  getAlternateIdLinking,
  getUniqueCasApplications,
  groupName,
  postAlternateIdLinking,
  sxButton,
} from './AlternateIdLinking.utils';

const AlternateIdLinking = (): ReactElement => {
  const [acceptLinking, setAcceptLinking] = React.useState<string>('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const obData = useSelector(selectObData);
  const alternateIdLinkingData = useSelector(selectAlternateIDLinkingData);
  const casNames = alternateIdLinkingData?.applicationCycles || [];
  const uniqueCasApplications = getUniqueCasApplications(casNames);

  useEffect(() => {
    dispatch(getAlternateIdLinking(keycloak?.tokenParsed?.email));
  }, [dispatch]);

  useEffect(() => {
    if (alternateIdLinkingData) {
      if (
        alternateIdLinkingData.alternateIdFound === false ||
        (alternateIdLinkingData.alternateIdFound && typeof alternateIdLinkingData.linkingAccepted === 'boolean')
      ) {
        const routingStep = obData?.nextSteps[0]?.routing;
        navigate(`/${routingStep}`);
      }
    }
  }, [alternateIdLinkingData, navigate, obData]);

  const onSubmit = () => {
    const data = { ...alternateIdLinkingData } as IAlternateIdLinking;
    data.linkingAccepted = acceptLinking === 'Yes';
    const modifiedObData = getModifiedObFlow(obData as IObData);
    dispatch(
      postAlternateIdLinking(keycloak?.tokenParsed?.email, data, () => {
        dispatch(obFlowSuccess(modifiedObData));
      })
    );
  };

  return (
    <Stack
      component="main"
      justifyContent="space-between"
      sx={{
        margin: theme => (theme.breakpoints.down('md') ? '1.5rem' : '2.5rem'),
      }}
    >
      <Typography variant="h1" sx={{ pb: '1.5rem' }}>
        {t(`${groupName}.header`, csuNameSpace)}
      </Typography>
      <Typography variant="body2" sx={{ pb: '1rem' }}>
        {t(`${groupName}.content1`, csuNameSpace)}
      </Typography>
      <Typography variant="body2">{t(`${groupName}.content2`, csuNameSpace)}</Typography>
      <Card sx={{ mt: '1rem' }}>
        <Box sx={{ p: '1rem' }}>
          <Box sx={{ display: 'flex' }}>
            <Icon>IconMail</Icon>
            <Typography gutterBottom variant="subtitle1" sx={{ pl: '0.5rem', wordBreak: 'break-word' }} component="div">
              {alternateIdLinkingData?.email || ''}
            </Typography>
          </Box>

          <Divider sx={{ borderStyle: 'solid' }} />
          <Typography variant="body3" component="p" sx={{ pt: '0.5rem' }}>{`${t(`${groupName}.casid`, csuNameSpace)}#${
            alternateIdLinkingData?.alternateId || ''
          }`}</Typography>
        </Box>
      </Card>

      <TableContainer component={Paper} sx={{ borderRadius: '0.375rem', mt: '2rem', maxHeight: '20vh' }}>
        <Table aria-label="simple table">
          <TableHead sx={{ bgcolor: '#DDE3ED' }}>
            <TableRow>
              <TableCell sx={{ p: '0.4rem 1rem' }}>
                <Typography variant="subtitle7">{t(`${groupName}.serviceHeader`, csuNameSpace)}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uniqueCasApplications.map(row => (
              <TableRow
                key={(row?.casName as string) + (row?.status as string)}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={{ p: '0.4rem 1rem' }}>
                  <Typography variant="body3">{row?.casName}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ pt: '1.5rem', pl: '0.3rem' }}>
        <RadioControl
          id={groupName}
          name={groupName}
          options={MasterData.linkingOptions}
          disabled={false}
          inline={true}
          value={acceptLinking}
          onChange={event => {
            setAcceptLinking(event.target.value);
          }}
        />
      </Box>
      <Grid container direction="row" sx={{ my: '1rem' }}>
        <Button
          disabled={acceptLinking === ''}
          sx={sxButton}
          type="submit"
          fullWidth
          variant="contained"
          onClick={onSubmit}
        >
          {t('continue_label', csuNameSpace)}
        </Button>
      </Grid>
    </Stack>
  );
};

export default AlternateIdLinking;
