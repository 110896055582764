/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'redux/store';
import { allowedEnrollmentStatuses } from 'transferPlanner/constants/general';
import { IEnrollmentState, ITspEnrollment, IEnrollment } from './enrollment.slice';

const selectEnrollmentState = (state: TRootState): IEnrollmentState => state.enrollment;

export const selectEnrollment = createSelector(
  selectEnrollmentState,
  (enrollmentState: IEnrollmentState) => enrollmentState?.enrollment
);

export const selectTspEnrollment = createSelector(selectEnrollment, (enrollment: IEnrollment | null) => {
  if (enrollment) {
    return Object.keys(enrollment).length ? enrollment.tspEnrollment : [];
  }
  return null;
});

export const selectActiveTspEnrollment = createSelector(
  selectTspEnrollment,
  (enrollments: ITspEnrollment[] | undefined | null) => {
    if (enrollments) {
      return enrollments.find(
        ({ active, enrollmentStatus }) =>
          active && allowedEnrollmentStatuses?.includes(enrollmentStatus?.status?.code as string)
      );
    }
    return null;
  }
);

export const selectEnrollLoading = createSelector(
  selectEnrollmentState,
  (stateData: IEnrollmentState) => stateData?.loading
);

export const selectTspEnrollmentPeriod = createSelector(
  selectEnrollmentState,
  (enrollment: IEnrollmentState | null) => enrollment?.tspEnrollmentPeriod
);

export const tspDeadlineExtensionTimestamp = createSelector(
  selectEnrollmentState,
  (enrollment: IEnrollmentState | null) => enrollment?.tspEnrollmentPeriod?.tspDeadlineExtensionTimestamp
);
