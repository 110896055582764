/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import * as yup from 'yup';
import moment from 'moment';
import { t } from 'i18next';

import { NAME_REGEX, TEXT_REGEX } from 'constants/regex';
import { yearValidator } from 'utils/utilities';

export type TBiographicFormErrors = {
  background: {
    firstGenerationCollegeStudent: { code: { message: 'string' } };
  };
  citizenship: {
    status: { code: { message: 'string' } };
    dualCitizenship: { message: 'string' };
  };
  gender: {
    legalSex: { code: { message: 'string' } };
    genderIdentity: { message: 'string' };
    pronouns: { otherText: { message: 'string' }; code: { message: 'string' } };
  };
  military: {
    status: { otherText: { message: 'string' } };
    serviceFrom: { message: 'string' };
    serviceTo: { message: 'string' };
  };
  raceEthnicity: {
    hispanicLatino: { code: { message: 'string' } };
  };
  relationships: {
    relationship: { code: { message: 'string' } };
    givenName: { message: 'string' };
    familyName: { message: 'string' };
    living: { message: 'string' };
    inPrimaryHousehold: { message: 'string' };
  }[];
  visa: {
    issuer: { message: 'string' };
    validFrom: { message: 'string' };
    validTo: { message: 'string' };
  };
};
export const validationSchema = yup.object().shape({
  gender: yup.object().shape({
    genderIdentity: yup.string().matches(RegExp(TEXT_REGEX), t('error.notAllowed')),
  }),
  visa: yup.object().shape({
    issuer: yup.string().matches(RegExp(TEXT_REGEX), t('error.notAllowed')),
    validFrom: yup
      .string()
      .matches(/^\d{4}([./-])\d{2}\1\d{2}$/, t('error.invalidDate'))
      .test('Is date valid', t('error.invalidDate'), value => {
        if (!value) return true;
        return moment(value, 'YYYY-MM-DD', true).isValid();
      })
      .test('Is date in correct range', t('error.dateRange'), value => {
        if (value && moment(value, 'YYYY-MM-DD', true).isValid()) {
          return yearValidator(value);
        }
        return true;
      }),
    validTo: yup
      .string()
      .matches(/^\d{4}([./-])\d{2}\1\d{2}$/, t('error.invalidDate'))
      .test('Is date valid', t('error.invalidDate'), value => {
        if (!value) return true;
        return moment(value, 'YYYY-MM-DD', true).isValid();
      })
      .test('match', t('visaInfo.error.validToShouldAfterOrEqualToFromDate'), function callback(value) {
        const { parent } = this;
        if (value && parent?.validFrom && moment(parent?.validFrom, 'YYYY-MM-DD', true).isValid()) {
          const endDate = new Date(value);
          const startDate = new Date(parent?.validFrom);
          return endDate.getTime() >= startDate.getTime();
        }
        return true;
      })
      .test('Is date in correct range', t('error.dateRange'), value => {
        if (value && moment(value, 'YYYY-MM-DD', true).isValid()) {
          return yearValidator(value);
        }
        return true;
      }),
  }),
  military: yup.object().shape({
    serviceFrom: yup
      .string()
      .matches(/^\d{4}([./-])\d{2}\1\d{2}$/, t('error.invalidDate'))
      .test('Is date valid', t('error.invalidDate'), value => {
        if (!value) return true;
        return moment(value, 'YYYY-MM-DD', true).isValid();
      })
      .test('Is date is not feature', t('militaryStatus.error.serviceBeganDateCannotbeFuture'), value => {
        if (value && moment(value, 'YYYY-MM-DD', true).isValid()) {
          const serviceFromDate = new Date(value);
          const today = new Date();
          return today.getTime() >= serviceFromDate.getTime();
        }
        return true;
      })
      .test('Is date in correct range', t('militaryStatus.error.fromDateCannotbeGreaterToday'), value => {
        if (value && moment(value, 'YYYY-MM-DD', true).isValid()) {
          return yearValidator(value);
        }
        return true;
      }),
    serviceTo: yup
      .string()
      .matches(/^\d{4}([./-])\d{2}\1\d{2}$/, t('error.invalidDate'))
      .test('Is date valid', t('error.invalidDate'), value => {
        if (!value) return true;
        return moment(value, 'YYYY-MM-DD', true).isValid();
      })
      .test('match', t('militaryStatus.error.endDateCanbeAfterOrEqualToServiceStartDate'), function callback(value) {
        const { parent } = this;
        if (value && parent?.serviceFrom && moment(parent?.serviceFrom, 'YYYY-MM-DD', true).isValid()) {
          const endDate = new Date(value);
          const startDate = new Date(parent?.serviceFrom);
          return endDate.getTime() >= startDate.getTime();
        }
        return true;
      })
      .test('Is date in correct range', t('error.dateRange'), value => {
        if (value && moment(value, 'YYYY-MM-DD', true).isValid()) {
          return yearValidator(value);
        }
        return true;
      }),
  }),
  relationships: yup.array().of(
    yup.object().shape({
      relationship: yup.object().shape({
        code: yup.string().required(t('relationship.error.code_required')),
      }),
      givenName: yup
        .string()
        .required(t('relationship.error.givenName_required'))
        .matches(RegExp(NAME_REGEX), t('error.notAllowed')),
      familyName: yup
        .string()
        .required(t('relationship.error.familyName_required'))
        .matches(RegExp(NAME_REGEX), t('error.notAllowed')),
    })
  ),
});
