/*
 * Copyright 2022-2023 Liaison International. All Rights Reserved
 */

import { TAppThunk } from 'redux/store';
import { t } from 'i18next';
import axios from 'axios';

import { getLoggedUser } from 'utils/keyCloakUtils';
import { API } from 'constants/api';
import { setUi } from 'store/ui/ui.slice';
import {
  standardTestsConfigStart,
  standardTestsConfigSuccess,
  standardTestsConfigFailure,
  standardTestsStart,
  standardTestsSuccess,
  standardTestsFailure,
  TStandardTests,
  TStandardTestsStateKeys,
} from 'store/standardTests/standardTests.slice';

export const getStandardTestsConfig =
  (testType: TStandardTestsStateKeys): TAppThunk =>
  async dispatch => {
    try {
      dispatch(standardTestsConfigStart());
      const { data } = await axios.get(`${API?.testResults}standardTestsConfig/${testType}`);
      dispatch(standardTestsConfigSuccess({ testType, data }));
    } catch (err) {
      dispatch(standardTestsConfigFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };

export const getStandardTests = (): TAppThunk => async dispatch => {
  try {
    dispatch(standardTestsStart());
    const { data } = await axios.get(`${API?.testResults}standardTests/${getLoggedUser()}`);
    dispatch(standardTestsSuccess(data));
  } catch (err) {
    dispatch(standardTestsFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
  }
};

export const postStandardTest =
  (payload: TStandardTests, onSuccess?: () => void): TAppThunk =>
  async dispatch => {
    try {
      dispatch(standardTestsStart());
      const { data } = await axios.post(`${API?.testResults}standardTests/${getLoggedUser()}`, payload);
      dispatch(standardTestsSuccess(data));
      dispatch(
        setUi({ name: 'succesSnackbar', state: { open: true, message: t('success_message'), hideTitle: true } })
      );
      onSuccess?.();
    } catch (err) {
      dispatch(setUi({ name: 'apiStatus', state: { failure: true } }));
      dispatch(standardTestsFailure(err?.response?.data?.message ?? t('error.genericErrorMsg')));
    }
  };
